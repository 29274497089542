// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Constants.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
export default class Role {
    public static readonly PUBLIC: number = 1;
    public static readonly IECDB_ADMINISTRATOR: number = 2;
    public static readonly IECDB_AUDITOR: number = 3;
    public static readonly FILER: number = 4;
    public static readonly MANUAL_FILER: number = 5;
    public static readonly PREFILER: number = 6;
    public static readonly GIFTBEQUEST_DONOR: number = 7;
}
