import {
  Badge,
  Button,
  Modal,
} from 'antd';
import * as React from 'react';
import AuditorNotesApiService from '../../api/AuditorNotesApiService';
import Colors from '../../consts/Colors';
import NoteEntityParents from '../../consts/NoteEntityParents';
import NoteEntryList from './NoteEntryList';

interface NotesModalProps {
  parentId: string;
  parent: string;
  seqNum?: number | 0;
  auditorsNoteId?: string;
  scheduleCd?: string | '';
  canFlag?: boolean;
  onClose?: () => void;
}

interface NotesModalState {
  showModal: boolean;
  noteId: string;
  auditorsNoteId: string;
  noteCount: number;
}

class NotesModal extends React.Component<NotesModalProps, NotesModalState>{
  constructor(props: NotesModalProps) {
    super(props);

    this.state = {
      showModal: this.props.canFlag || false,
      noteId: '',
      auditorsNoteId: this.props.auditorsNoteId || '',
      noteCount: 0
    };
  }

  componentDidMount() {
    if (!this.props.auditorsNoteId) {
      this.getNoteId();
    }
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (this.props.auditorsNoteId !== prevProps.auditorsNoteId) {
      this.getCount(this.props.auditorsNoteId || '');
    }
    if (this.state.noteId !== prevState.noteId) {
      this.getCount(this.state.noteId);
    }
  }

  render() {
    const { parentId, parent, seqNum, auditorsNoteId, scheduleCd, canFlag } = this.props;
    const { noteId, showModal, noteCount } = this.state;

    return (
      <>
        {!canFlag &&
          <Badge count={noteCount} color={Colors.PRIMARY}>
            <Button
              onClick={() => this.handleModal(true)}
              size={scheduleCd == undefined ? 'middle' : 'small'}
              className="note-button">
              Notes
            </Button>
          </Badge>
        }
        <Modal
          title={canFlag ? 'Flagged Note' : 'Notes'}
          visible={showModal}
          onCancel={() => this.handleModal(false)}
          okButtonProps={{ style: { display: 'none' } }}
          width={800}
          centered={true}
        >
          <NoteEntryList
            parent={parent}
            parentId={parentId}
            auditorsNoteId={auditorsNoteId != undefined ? auditorsNoteId : noteId}
            seqNum={seqNum || 0}
            onChange={this.handleOnChange}
            scheduleCd={scheduleCd}
            saveFlag={canFlag} />
        </Modal>
      </>
    );
  }

  private getNoteId = () => {
    if (!this.props.scheduleCd) {
      return;
    }
    AuditorNotesApiService.getSchNoteId(this.props.parentId, this.props.scheduleCd)
        .then((noteId) => {
            if (noteId) {
                this.setState({ noteId });
            }
      });
  }

  private getNote = () => {
    const { parent, auditorsNoteId } = this.props;
    if (parent !== NoteEntityParents.SCHEDULE) {
      this.getCount(auditorsNoteId || '');
    }
    else {
      AuditorNotesApiService.getSchNoteId(this.props.parentId, this.props.scheduleCd || '')
        .then((noteId) => {
          this.getCount(noteId ?? '');
        });
    }
  }

  private getCount = (noteId: string) => {
    if (noteId) {
      AuditorNotesApiService.getNoteEntries(noteId)
        .then(notes => {
          if (notes && notes.length > 0) {
            if (this.state.noteCount !== notes.length) {
              this.setState({ noteCount: notes.length });
            }
          }
        });
    }
  }

  private handleModal = (showModal: boolean) => {
    this.setState({ showModal });
    if (showModal) { this.componentDidMount(); }
    else {
      this.getNote();
      if (this.props.onClose) {
        this.props.onClose();
      }
    }
  }

  private handleOnChange = (currNoteId: string | null) => {
    if (currNoteId) {
      if (this.state.noteId) {
        this.setState({ noteId: currNoteId });
      }
      else {
        this.setState({ auditorsNoteId: currNoteId });
      }
    }
  }
}

export default NotesModal;