// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';

interface DueDateDTO { 
  beginDueDate: moment.Moment | string | null;
  beginAdjFactor: number | null;
  endDueDate: moment.Moment | string | null;
  endAdjFactor: number | null;
  filingDueDate: moment.Moment | string | null;
  dueDateAdjFactor: number | null;
  adjustedDueDate: moment.Moment | string | null;
  periodMasterId: number | null;
}
const DueDateDTO = {
  create: (initValues?: Partial<DueDateDTO> | Record<string, unknown> | null) => {
    return Object.assign(
    {
      beginDueDate: null,
      beginAdjFactor: null,
      endDueDate: null,
      endAdjFactor: null,
      filingDueDate: null,
      dueDateAdjFactor: null,
      adjustedDueDate: null,
      periodMasterId: null,
    },
    initValues);
  }
};

export default DueDateDTO;