import FormItem from 'antd/lib/form/FormItem';
import { notification, Select, Spin } from 'antd';
import * as React from 'react';
import SearchApiService from '../../api/SearchApiService';
import debounce from 'lodash/debounce';
import PersonInfoDTO from '../../models/PersonInfoDTO';
import { Rule } from 'antd/lib/form';

const { Option } = Select;

interface PersonAutoSearchProps {
  name: string;
  label?: string;
  hidden?: boolean;
  required?: boolean;
  disabled?: boolean;
  committeeId: string;
  tooltip?: string;
  onSelect?: (perorgId: string) => void;
  onChange?: (value: string) => void;
  perorgs?: PersonInfoDTO[];
}

interface PersonAutoSearchState {
  perorgs: PersonInfoDTO[];
  loading: boolean;
}

class PersonAutoSearch extends React.Component<PersonAutoSearchProps, PersonAutoSearchState> {
  constructor(props: PersonAutoSearchProps) {
    super(props);

    this.searchPersonInfo = debounce(this.searchPersonInfo, 400);

    this.state = {
        perorgs: this.props.perorgs || [],
        loading: false,
    };
  }

  render() {
    const { perorgs, loading } = this.state;
      const { name, label, hidden, required, disabled, tooltip } = this.props;

    const PersonSearchNameRules: Rule[] = [];

    if (required !== false) {
      PersonSearchNameRules.push({ required: true, message: (label || 'Individual') + ' is required' });
    }

    const options: PersonInfoDTO[] = loading ? [PersonInfoDTO.create()] : perorgs;

    return (
        <FormItem name={name} label={label || 'Individual'} hidden={hidden || false} rules={PersonSearchNameRules}
            tooltip={ tooltip || 'Search for individuals from the \'Contacts\' page'} >
        <Select
          allowClear={true}
          filterOption={false}
          showSearch={true}
          optionFilterProp="children"
          notFoundContent={false}
          showArrow={false}
          disabled={disabled || false}
          placeholder={'Search for an individual...'}
          onSearch={this.handlePersonSearch}
          onClear={this.handleClear}
          onChange={this.handlePersonChange}
          dropdownRender={(a) => {
            if (loading) {
              return <div className="center-spinner"><Spin spinning={loading}>{a}</Spin></div>;
            }
            return a;
          }}
        >
          {options.map((p: PersonInfoDTO) => (
            <Option
              key={p.id || ''}
              value={p.id || ''}
            >
              {p.firstName && (
                p.middleInitial ? p.firstName + ' ' + p.middleInitial + '. ' + p.lastName : p.firstName + ' ' + p.lastName)}
              < br />
              {p.city && (
                <small>{p.city + ', ' + p.state}</small>
              )}
            </Option>
          ))}
        </Select>
      </FormItem>
    );
    }

    private searchPersonInfo = (committeeId: string, name: string) => {
        if (!this.props.perorgs) {
            SearchApiService.getPersonByName(committeeId, name)
                .then((perorgs) => {
                    this.setState({ perorgs: perorgs, loading: false });
                })
                .catch(() => {
                    notification.error({
                        message: 'Failed to retrieve individuals.',
                    });
                });
        }
        else {
            const perorgs = this.props.perorgs?.filter((p) => (
                p.firstName?.toLowerCase().includes(name.toLowerCase()) ||
                p.lastName?.toLowerCase().includes(name.toLowerCase())
            ));

            this.setState({
                perorgs: perorgs,
                loading: false
            });
        }
    }

    private handleClear = () => {
        this.setState({
            perorgs: this.props.perorgs || []
        });

        if (this.props.onSelect) {
          this.props.onSelect('');
        }
     }

    private handlePersonSearch = (value: string) => {
        if (!value || value.length > 0) {
            if (this.props.onChange) {
                this.props.onChange(value);
            }
        }
        if (!value || value.length < 3) {
            if (this.state.perorgs !== [] && !this.props.perorgs) {
                this.setState({ perorgs: [], loading: false });
            }
            return;
        }

        this.setState({ loading: true });
        this.searchPersonInfo(this.props.committeeId, value);
    }

    private handlePersonChange = (value: string) => {
        const selectedPerson = this.state.perorgs.find(p => p.id === value);
        if (selectedPerson) {
            this.setState({ perorgs: [selectedPerson] });
            if (this.props.onSelect) {
                this.props.onSelect(selectedPerson?.id || '');
            }
        }
    }
}

export default PersonAutoSearch;
