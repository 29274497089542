// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
import BaseApi from './BaseApi';
import TableRequestDTO from '../models/TableRequestDTO';
import ContactEditFormDTO from '../models/ContactEditFormDTO';
import ContactTableExportDTO from '../models/ContactTableExportDTO';
import TableResponseDTO from '../models/TableResponseDTO';
import ContactTableInfoDTO from '../models/ContactTableInfoDTO';

export class ContactApiService extends BaseApi {

  // post: api/contact/table?dr2Id=${encodeURIComponent(dr2Id)}
  public getContactList(tableRequest: TableRequestDTO, dr2Id: string): Promise<TableResponseDTO<ContactTableInfoDTO>> {
    let url = `api/contact/table`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (dr2Id != null) {
      url += `${prefix}dr2Id=${encodeURIComponent(dr2Id)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, TableResponseDTO<ContactTableInfoDTO>>(tableRequest, 'post', url, true, false);
  }

  // get: api/contact/${encodeURIComponent(perorgId)}?committeeId=${encodeURIComponent(committeeId)}
  public getContact(perorgId: string, committeeId: string): Promise<ContactEditFormDTO> {
    let url = `api/contact/${encodeURIComponent(perorgId)}`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (committeeId != null) {
      url += `${prefix}committeeId=${encodeURIComponent(committeeId)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, ContactEditFormDTO>(null, 'get', url, true, false);
  }

  // post: api/contact?committeeId=${encodeURIComponent(committeeId)}
  public save(newContact: ContactEditFormDTO, committeeId: string): Promise<void> {
    let url = `api/contact`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (committeeId != null) {
      url += `${prefix}committeeId=${encodeURIComponent(committeeId)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, void>(newContact, 'post', url, true, false);
  }

  // delete: api/contact?perorgId=${encodeURIComponent(perorgId)}&committeeId=${encodeURIComponent(committeeId)}
  public delete(perorgId: string, committeeId: string): Promise<void> {
    let url = `api/contact`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (perorgId != null) {
      url += `${prefix}perorgId=${encodeURIComponent(perorgId)}`;
      prefix = '&';
    }
    if (committeeId != null) {
      url += `${prefix}committeeId=${encodeURIComponent(committeeId)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, void>(null, 'delete', url, true, false);
  }

  // get: api/contact/export
  public export(tableRequest: ContactTableExportDTO): Promise<void> {
    let url = `api/contact/export`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, void>(tableRequest, 'get', url, true, true);
  }
}
var service = new ContactApiService();
export default service;
