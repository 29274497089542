import {
  Button,
  Checkbox,
  Col,
  ColProps,
  FormInstance,
  Input,
  notification,
  Modal,
  Row,
  Select,
  Space,
  Typography
} from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import TextArea from 'antd/lib/input/TextArea';
import AddressForm from '../shared/AddressFormFields';
import * as React from 'react';
import LookupsApiService from '../../api/LookupsApiService';
import SchH2PropertySalesApiService from '../../api/SchH2PropertySalesApiService';
import ScheduleApiService from '../../api/ScheduleApiService';
import CodeLookupTableDTO from '../../models/CodeLookupTableDTO';
import SchH2PropertySalesFormDTO from '../../models/SchH2PropertySalesFormDTO';
import CommitteeInfoDTO from '../../models/CommitteeInfoDTO';
import FormValidationUtil from '../../utils/FormValidationUtil';
import Dr2GroupTypes from '../../consts/Dr2GroupTypes';
import CurrentUser from '../../utils/CurrentUser';
import HistoryUtil from '../../utils/HistoryUtil';
import Routes from '../../consts/Routes';
import { RouteComponentProps } from 'react-router';
import { Content } from 'antd/lib/layout/layout';
import moment from 'moment';
import PersonSearch from '../shared/PersonSearch';
import BaseScheduleInfoDTO from '../../models/BaseScheduleInfoDTO';
import ScheduleAbbreviationCodes from '../../consts/ScheduleAbbreviationCodes';
import SchH2PropertySaleHistoryTable from './SchH2PropertySaleHistoryTable';
import CommitteeAutoSearch from '../shared/CommitteeSearch';
import NotesModal from '../shared/NotesModal';
import NoteEntityParents from '../../consts/NoteEntityParents';
import CurrencyInput from '../shared/CurrencyInput';
import CustomDatePicker from '../shared/CustomDatePicker';
import CustomForm from '../shared/CustomForm';
import OrganizationAutoSearch from '../shared/OrganizationSearch';

const Option = Select.Option;
const confirm = Modal.confirm;

interface ScH2PropertySalesFormProps {
  id?: string;
  seqNum?: string;
}

interface ScH2PropertySalesFormState {
  relations: CodeLookupTableDTO[];
  currPurchaserType: string;
  currExpenseType: string;
  currComm: CommitteeInfoDTO;
  btnDisable: boolean;
  propertySaleInfo: SchH2PropertySalesFormDTO;
  propertySaleId?: string;
  startDt: moment.Moment;
  endDt: moment.Moment;
  loading: boolean;
  trusteeTrustorReq: boolean;
  autoFillPerorg: boolean;
  seqNum: number;
}

class ScH2PropertySalesForm extends React.Component<RouteComponentProps<ScH2PropertySalesFormProps>, ScH2PropertySalesFormState> {
  private readonly _formRef = React.createRef<FormInstance>();

  constructor(props: RouteComponentProps<ScH2PropertySalesFormProps>) {
    super(props);
    this.state = {
      relations: [],
      currPurchaserType: '',
      currExpenseType: '',
      currComm: CommitteeInfoDTO.create(),
      btnDisable: false,
      propertySaleId: this.props.match.params.id,
      propertySaleInfo: SchH2PropertySalesFormDTO.create({ purchaserType: '', state: 'IA', relationshipCode: '', amount: null }),
      startDt: moment.utc(),
      endDt: moment.utc(),
      loading: !!this.props.match.params.id,
      trusteeTrustorReq: false,
      autoFillPerorg: false,
      seqNum: Number(this.props.match.params.seqNum) || 0,
    };
  }

  componentDidMount() {
    this.loadLookups();
    this.getUserCommittee();
    this.getSchedule();
    if (this.props.match.params.id) {
      this.getPropertySale(this.props.match.params.id || '', this.props.match.params.seqNum || '');
    }
  }

  render() {
    const { currPurchaserType, currComm, btnDisable, propertySaleId, propertySaleInfo,
      startDt, endDt, loading, trusteeTrustorReq, autoFillPerorg, seqNum } = this.state;
    const columnSizingProps: ColProps = { xs: 24, sm: 24, md: 12 };

    const purchaserTypes = [
      Dr2GroupTypes.INDIVIDUAL,
      Dr2GroupTypes.COMMITTEE,
      Dr2GroupTypes.TRUST,
      Dr2GroupTypes.COMPANYOTHER
    ];

    return (
      <>
        {!loading &&
          <Content className="content-pad">
            <Typography.Title level={4}>Schedule H2 - Property Sales</Typography.Title>
            <CustomForm formRef={this._formRef} initialValues={propertySaleInfo} layout="vertical" onFinish={this.handleSave}>
              <Row gutter={24}>
                <Col {...columnSizingProps}>
                  <FormItem name="purchaserType" label="Purchaser Type"
                    rules={[FormValidationUtil.Required('Purchaser Type is required')]} >
                    <Select
                      showSearch
                      optionFilterProp="children"
                      onChange={this.handlePurchaserTypeChange}>
                      <Option value="" disabled={true}>-- Select Type --</Option>
                      {purchaserTypes.map(pt => (
                        <Option key={pt} value={pt}>{pt}</Option>
                      ))}
                    </Select>
                  </FormItem>
                  <FormItem name="date" label="Date"
                    rules={[FormValidationUtil.RequiredDate('Date is required', true), FormValidationUtil.DateRange(startDt, endDt)]}>
                  <CustomDatePicker onChange={(d) => d?.startOf('day')}/>
                  </FormItem>
                  <FormItem name="amount" label="Amount"
                    rules={[
                      FormValidationUtil.RequiredNumber('Amount is required'),
                      FormValidationUtil.NotNegativeNumber('Amount must be nonnegative')
                    ]}>
                    <CurrencyInput />
                  </FormItem>
                  <Row>
                    <Col>
                      <Space>
                        <FormItem name="notDonated" valuePropName="checked">
                          <Checkbox>Not Donated</Checkbox>
                        </FormItem>
                      </Space>
                    </Col>
                  </Row>
                  <FormItem name="explanation" label="Explanation"
                    rules={[FormValidationUtil.Required(
                      'Explanation is required',
                      false
                    )]}>
                    <TextArea maxLength={2000} />
                  </FormItem>
                </Col>
                <Col {...columnSizingProps}>
                  {currPurchaserType === Dr2GroupTypes.INDIVIDUAL && (
                    <>
                      <PersonSearch
                        name={'person'}
                        label={'Search for an Individual'}
                        required={false}
                        committeeId={currComm.id || ''}
                        onSelect={this.getPerson}
                        onChange={this.handlePurchaserChange}
                      />
                      <AddressForm allDisabled={autoFillPerorg}
                        firstName={{ name: 'firstName' }}
                        middleInitial={{ name: 'middleInitial' }}
                        lastName={{ name: 'lastName' }}
                        address1={{ name: 'addressLine1' }}
                        address2={{ name: 'addressLine2' }}
                        city={{ name: 'city' }}
                        state={{ name: 'state' }}
                        zip={{ name: 'zip' }}
                        phone={{ name: 'phoneNumber', required: false }}
                      />
                    </>
                  )}
                  {currPurchaserType === Dr2GroupTypes.COMMITTEE && (
                    <>
                      <CommitteeAutoSearch
                        name="purchaserName"
                        label="Name"
                        required={true}
                        requireSelection={true}
                        onSelectCommittee={this.handleCommitteeSelect}
                      />
                    </>
                  )}
                  {(currPurchaserType === Dr2GroupTypes.COMPANYOTHER || currPurchaserType === Dr2GroupTypes.TRUST) && (
                    <>
                      <OrganizationAutoSearch
                        name={'company'}
                        label={'Search for a Company'}
                        required={false}
                        committeeId={currComm.id || ''}
                        perorgType={currPurchaserType}
                        onSelect={this.getOrganization}
                        onChange={this.handlePurchaserChange}
                      />
                      <AddressForm allDisabled={autoFillPerorg}
                        name={{ name: 'purchaserName', label: 'Company' }}
                        address1={{ name: 'addressLine1' }}
                        address2={{ name: 'addressLine2' }}
                        city={{ name: 'city' }}
                        state={{ name: 'state' }}
                        zip={{ name: 'zip' }}
                        phone={{ name: 'phoneNumber', required: false }}
                      />
                    </>
                  )}
                  {currPurchaserType === Dr2GroupTypes.TRUST &&
                    <>
                      <FormItem name="isLivingOrRevocableTrust" valuePropName="checked">
                        <Checkbox onChange={this.handleRevocableTrustCheckboxChange}>Living or Revocable Trust</Checkbox>
                      </FormItem>
                      <FormItem name="trustee" label="Trustee"
                        rules={[FormValidationUtil.Required('Trustee is required', trusteeTrustorReq)]}>
                        <Input disabled={autoFillPerorg} />
                      </FormItem>
                      <FormItem name="trustor" label="Trustor"
                        rules={[FormValidationUtil.Required('Trustor is required', trusteeTrustorReq)]}>
                        <Input disabled={autoFillPerorg} />
                      </FormItem>
                    </>
                  }
                </Col>
              </Row>
              <Row>
                <Col {...columnSizingProps}>
                  <FormItem name="purchaserAutoFillId">
                    <Input hidden={true} disabled={true} aria-hidden={true}/>
                  </FormItem>
                </Col>
              </Row>
              <Space>
                <Button type="primary" disabled={btnDisable} htmlType="submit">Save Payment</Button>
                <Button type="default" disabled={btnDisable} onClick={this.handleCancel}>Cancel</Button>
                {propertySaleId &&
                  <NotesModal
                    parentId={propertySaleId}
                    parent={NoteEntityParents.CAMPAIGNPROPERTYSALE}
                    seqNum={seqNum || 0}
                    auditorsNoteId={propertySaleInfo.auditorsNoteId || ''}
                  />
                }
              </Space>
            </CustomForm >
            {propertySaleId &&
              <SchH2PropertySaleHistoryTable
                propertySaleId={propertySaleId || ''}
              />
            }
          </Content >
        }
      </>
    );
  }

  private handlePurchaserTypeChange = (purchaserType: string) => {
    this.setState({
      currPurchaserType: purchaserType,
      autoFillPerorg: false,
      trusteeTrustorReq: purchaserType == Dr2GroupTypes.TRUST
    });

    this._formRef.current?.setFieldsValue({
      'firstName': '',
      'middleInitial': '',
      'lastName': '',
      'addressLine1': '',
      'addressLine2': '',
      'city': '',
      'state': '',
      'zip': '',
      'phoneNumber': '',
      'trustee': '',
      'trustor': '',
      'person': null,
      'purchaserName': null,
      'company': null,
      'isLivingOrRevocableTrust': false,
      'purchaserAutoFillId': ''
    });

    if ([Dr2GroupTypes.COMPANYOTHER, Dr2GroupTypes.INDIVIDUAL, Dr2GroupTypes.TRUST].includes(purchaserType)) {
      this._formRef.current?.setFieldsValue({ state: 'IA' });
    }
  }

  private handlePurchaserChange = (value: string) => {
    this._formRef.current?.setFieldsValue({ purchaserAutoFillId: '' });
    this.setState({ autoFillPerorg: !!value });
  }

  private handleCommitteeSelect = (committee: CommitteeInfoDTO | undefined) => {
    this._formRef.current?.setFieldsValue({ purchaserAutoFillId: committee?.id });
  }

  private setCompanyFields(form?: BaseScheduleInfoDTO) {
    if (form) {
      this._formRef.current?.setFieldsValue({
        purchaserName: form.committeeOrCompanyName,
        addressLine1: form.addressLine1,
        addressLine2: form.addressLine2,
        city: form.city,
        state: form.state,
        zip: form.zip,
        phoneNumber: form.phoneNumber,
        trustee: form.trustee,
        trustor: form.trustor
      });
    }
    else {
      this._formRef.current?.setFieldsValue({
        purchaserName: '',
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        zip: '',
        phoneNumber: '',
        trustee: '',
        trustor: '',
        purchaserAutoFillId: ''
      });
      this.setState({ autoFillPerorg: false });
    }
  }

  private setIndividualFields(form?: BaseScheduleInfoDTO) {
    if (form) {
      this._formRef.current?.setFieldsValue({
        purchaserType: form.groupType,
        firstName: form.firstName,
        middleInitial: form.middleInitial,
        lastName: form.lastName,
        addressLine1: form.addressLine1,
        addressLine2: form.addressLine2,
        city: form.city,
        state: form.state,
        zip: form.zip,
        phoneNumber: form.phoneNumber,
        purchaserAutoFillId: ''
      });
    }
    else {
      this._formRef.current?.setFieldsValue({
        purchaserType: Dr2GroupTypes.INDIVIDUAL,
        firstName: '',
        middleInitial: '',
        lastName: '',
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        zip: '',
        phoneNumber: '',
        purchaserAutoFillId: ''
      });
      this.setState({ autoFillPerorg: false });
    }
    this.setState({ currPurchaserType: Dr2GroupTypes.INDIVIDUAL });
  }

  private handleRevocableTrustCheckboxChange = (e: any) => {
    this.setState({ trusteeTrustorReq: !e.target.checked });
  }

  private loadLookups = () => {
    const relationPromise = LookupsApiService.getRelationTypes();
    Promise.all([relationPromise])
      .then(result => {
        const [relations] = result;
        this.setState({ relations });
      });
  }

  private getSchedule = () => {
    ScheduleApiService.getSchedule(CurrentUser.Get()?.dr2Id || '', ScheduleAbbreviationCodes.SCHEDULEH2)
      .then(schedule => {
        this.setState({ startDt: moment.utc(schedule.startDate), endDt: moment.utc(schedule.endDate) });
      });
  }

  private getPerson = (perorgId: string) => {
    if (perorgId != '') {
      ScheduleApiService.getPerson(perorgId, CurrentUser.Get()?.committeeId || '')
        .then(personInfo => {
          if (personInfo.groupType) {
            this.setIndividualFields(personInfo);
            this._formRef.current?.setFieldsValue({ purchaserAutoFillId: perorgId });
          }
        });
    }
    else {
      this.setIndividualFields();
    }
  }

  private getOrganization = (perorgId: string) => {
    if (perorgId != '') {
      ScheduleApiService.getOrganization(perorgId, CurrentUser.Get()?.committeeId || '')
        .then(orgInfo => {
          if (orgInfo.groupType) {
            this.setCompanyFields(orgInfo);
            this._formRef.current?.setFieldsValue({ purchaserAutoFillId: perorgId });
          }
        });
    }
    else {
      this.setCompanyFields();
    }
  }

  private getUserCommittee = () => {
    ScheduleApiService.getCommittee(CurrentUser.Get()?.committeeId || '')
      .then(committee => {
        if (committee) {
          this.setState({ currComm: { ...committee } });
        }
      });
  }

  private getPropertySale = (propertySaleId: string, seqNum: string) => {
    SchH2PropertySalesApiService.getPropertySale(propertySaleId, Number(seqNum))
      .then(propertySale => {
        if (propertySale) {
          propertySale.date = moment(propertySale.date);

          const propertySaleInfo: SchH2PropertySalesFormDTO & { person?: string, company?: string } = propertySale;
          if (propertySaleInfo.firstName && propertySaleInfo.lastName) {
            if (propertySaleInfo.middleInitial) {
              propertySaleInfo.person = propertySale.firstName + ' ' + propertySale.middleInitial + '. ' + propertySale.lastName;
            }
            else {
              propertySaleInfo.person = propertySale.firstName + ' ' + propertySale.lastName;
            }
          }
          else if (propertySaleInfo.purchaserType == Dr2GroupTypes.TRUST || propertySaleInfo.purchaserType == Dr2GroupTypes.COMPANYOTHER) {
            if (propertySaleInfo.purchaserName) {
              propertySaleInfo.company = propertySaleInfo.purchaserName;
            }
          }

          this.setState({
            propertySaleInfo: propertySale,
            currPurchaserType: propertySale.purchaserType || '',
            trusteeTrustorReq: propertySale.purchaserType == Dr2GroupTypes.TRUST && !propertySale.isLivingOrRevocableTrust,
            autoFillPerorg: propertySale.purchaserType != Dr2GroupTypes.COMMITTEE
          });
          this._formRef.current?.setFieldsValue(propertySaleInfo);
          this.setState({ loading: false });
        }
        else {
          notification.error({
            message: 'Error while fetching property sale',
            description: 'Not found'
          });
          this.setState({ loading: false });
        }
      }).catch(() => {
        notification.error({
          message: 'Error while fetching property sale'
        });
        this.setState({ loading: false });
      });
  }

  private handleSave = (values: SchH2PropertySalesFormDTO) => {
    if (values.purchaserAutoFillId === '') {
      values.purchaserAutoFillId = null;
    }
    this.setState({ btnDisable: true });
    if (!this.state.propertySaleId) {
      SchH2PropertySalesApiService.add(values, CurrentUser.Get()?.committeeId || '', CurrentUser.Get()?.dr2Id || '')
        .then(() => {
          notification.success({
            message: 'Saved Successfully',
            description: ''
          });
          HistoryUtil.push(Routes.SCHEDULE_H2_PROPERTY_SALES);
        }).catch(() => {
          notification.error({
            message: 'Your property sale failed to save',
            description: ''
          });
          HistoryUtil.push(Routes.SCHEDULE_H2_PROPERTY_SALES);
        });
    }
    else {
      SchH2PropertySalesApiService.edit(
        values,
        CurrentUser.Get()?.committeeId || '',
        CurrentUser.Get()?.dr2Id || '',
        this.props.match.params.id || '',
        Number(this.props.match.params.seqNum)
      )
        .then(() => {
          notification.success({
            message: 'Saved Successfully',
            description: ''
          });
          HistoryUtil.push(Routes.SCHEDULE_H2_PROPERTY_SALES);
        }).catch(() => {
          notification.error({
            message: 'Your Property Sale failed to save',
            description: ''
          });
          HistoryUtil.push(Routes.SCHEDULE_H2_PROPERTY_SALES);
        });
    }
  }

  private handleCancel = () => {
    confirm({
      title: 'Are you sure you want to leave?',
      okText: 'Yes',
      cancelText: 'No',
      onOk: () => {
        HistoryUtil.push(Routes.SCHEDULE_H2_PROPERTY_SALES);
      }
    });
  }
}

export default ScH2PropertySalesForm;
