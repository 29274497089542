// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import EntitySelectorDTO from './EntitySelectorDTO';

interface NoteEntryDTO extends EntitySelectorDTO { 
  noteEntryId: number | null;
  noteId: string | null;
  noteTxt: string | null;
  createdByUserNm: string | null;
  createdOn: moment.Moment | string | null;
  updatedByUserNm: string | null;
  updatedOn: moment.Moment | string | null;
}
const NoteEntryDTO = {
  create: (initValues?: Partial<NoteEntryDTO> | Record<string, unknown> | null) => {
    return Object.assign(EntitySelectorDTO.create(),
    {
      noteEntryId: null,
      noteId: null,
      noteTxt: null,
      createdByUserNm: null,
      createdOn: null,
      updatedByUserNm: null,
      updatedOn: null,
    },
    initValues);
  }
};

export default NoteEntryDTO;