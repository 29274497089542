import {
  Button,
  Col,
  ColProps,
  Divider,
  Input,
  Layout,
  Modal,
  notification,
  Row,
  Select,
  Space,
  Typography
  } from 'antd';
import { FormInstance, FormProps } from 'antd/lib/form/Form';
import FormItem from 'antd/lib/form/FormItem';
import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import AccountApiService from '../../api/AccountApiService';
import LookupsApiService from '../../api/LookupsApiService';
import UsersApiService from '../../api/UsersApiService';
import Role from '../../consts/Role';
import Routes from '../../consts/Routes';
import AdminManageUsersListDTO from '../../models/AdminManageUsersListDTO';
import CommitteeInfoDTO from '../../models/CommitteeInfoDTO';
import LookupTableDTO from '../../models/LookupTableDTO';
import CurrentUser from '../../utils/CurrentUser';
import FormValidationUtil from '../../utils/FormValidationUtil';
import HistoryUtil from '../../utils/HistoryUtil';
import CommitteeAutoSearch from '../shared/CommitteeSearch';
import CustomForm from '../shared/CustomForm';

const { Content } = Layout;
const { Option } = Select;
const { confirm } = Modal;

interface EditUserRouteProps {
  id?: string;
}

interface EditUserProps {
  forCommittee: boolean;
}

interface EditUserState {
  userId: string;
  userDTO: AdminManageUsersListDTO;
  statuses: LookupTableDTO[];
  roles: LookupTableDTO[];
  filerRoleSelected: boolean;
  passwordFilled: boolean;
  loading: boolean;
}

class EditUser extends React.Component<RouteComponentProps<EditUserRouteProps> & EditUserProps & FormProps, EditUserState> {
  private readonly _formRef = React.createRef<FormInstance>();

  constructor(props: RouteComponentProps<EditUserRouteProps> & EditUserProps & FormProps) {
    super(props);
    this.state = {
      userId: props.match.params.id || '',
      userDTO: AdminManageUsersListDTO.create({ statusId: '', role: '' }),
      statuses: [],
      roles: [],
      filerRoleSelected: false,
      passwordFilled: false,
      loading: true
    };
  }

  componentDidMount() {
    this.getLookups(!this.state.userId);
    this.getUser(this.props.match.params.id || '');
  }

  render() {
    const columnSizingProps: ColProps = { xs: 24, sm: 24, md: 12 };
    const { userId, userDTO, statuses, roles, filerRoleSelected, passwordFilled, loading } = this.state;
    const { forCommittee } = this.props;
    const committeeReq = ![Role.IECDB_ADMINISTRATOR, Role.GIFTBEQUEST_DONOR].includes(userDTO.role);

    const currentUser = CurrentUser.Get();
    const ownAccount = currentUser?.userName == userDTO.username;
    const isAddPage = !userId;
    const passwordRequired = passwordFilled || isAddPage;
    const pageTitle = isAddPage ? 'Add User' : 'Edit User';

    return (
      <Content className="content-pad">
        {!loading &&
          <>
            <Typography.Title level={4}>{pageTitle}</Typography.Title>
            <CustomForm
              formRef={this._formRef}
              initialValues={userDTO}
              layout="vertical"
              onFinish={this.confirmSave}
              validateTrigger={['onChange', 'onBlur']}>
              <Row gutter={24}>
              <FormItem name="userId" hidden>
                <Input disabled hidden aria-hidden />
                </FormItem>
                <FormItem name="committeeId" hidden>
                  <Input disabled hidden aria-hidden/>
                </FormItem>
                <Col {...columnSizingProps}>
                  <FormItem
                    name="username"
                    label="Username"
                    rules={[
                      FormValidationUtil.Required('Username is required'),
                      FormValidationUtil.ValidateUsername(isAddPage)
                    ]}>
                    <Input disabled={!isAddPage} />
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col {...columnSizingProps}>
                  <FormItem name="password" label="Password" rules={[
                    FormValidationUtil.Required('Password is required', passwordRequired),
                    FormValidationUtil.ValidatePassword()
                  ]}>
                    <Input.Password onChange={this.onPasswordChange} />
                  </FormItem>
                </Col>
                <Col {...columnSizingProps}>
                  <FormItem name="confirmPassword" label="Confirm Password" rules={[
                    FormValidationUtil.Required('Confirm password is required', passwordRequired),
                    FormValidationUtil.CheckConfirmPassword('password')
                  ]}>
                    <Input.Password onChange={this.onPasswordChange} />
                  </FormItem>
                </Col>
              </Row>
              <Divider />
              <Row gutter={24}>
                <Col {...columnSizingProps}>
                  <FormItem name="firstName" label="First Name">
                    <Input />
                  </FormItem>
                </Col>
                <Col {...columnSizingProps}>
                  <FormItem name="lastName" label="Last Name">
                    <Input />
                  </FormItem>
                </Col>
                <Col {...columnSizingProps} >
                  <FormItem name="email" label="Email"
                    rules={
                      [
                        FormValidationUtil.Required('Email is required'),
                        FormValidationUtil.Email('Invalid Email')
                      ]
                    }>
                    <Input />
                  </FormItem>
                </Col>
                <Col {...columnSizingProps} >
                  <FormItem name="statusId" label="Status" rules={[FormValidationUtil.RequiredNumber('Status is required')]}>
                    <Select disabled={ownAccount}>
                      <Option value="" disabled={true}>-- Select Status --</Option>
                      {statuses.map(s => (
                        <Option key={s.id || ''} value={s.id || ''}>{s.name}</Option>
                      ))}
                    </Select>
                  </FormItem>
                </Col>
                <Col {...columnSizingProps} >
                  <FormItem
                    name="role"
                    label="Role"
                    hidden={forCommittee}
                    rules={[FormValidationUtil.RequiredNumber('Role is required')]}>
                    <Select
                      disabled={ownAccount || forCommittee || !isAddPage}
                      onChange={this.handleRoleChange}>
                      <Option value="" disabled={true}>-- Select Role --</Option>
                      {roles.map(s => (
                        <Option key={s.id || ''} value={s.id || ''}>{s.name}</Option>
                      ))}
                    </Select>
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col {...columnSizingProps}>
                  <CommitteeAutoSearch
                    name="committeeName"
                    label="Committee"
                    requireSelection={true}
                    required={committeeReq}
                    onSelectCommittee={this.onCommitteeSelect}
                    disabled={forCommittee || !isAddPage || !filerRoleSelected}
                  />
                </Col>
                <Col {...columnSizingProps}>
                  <FormItem
                    name="committeeCode"
                    label="Committee Code">
                    <Input disabled />
                  </FormItem>
                </Col>
              </Row>
              <Space>
                <Button type="primary" htmlType="submit">Save</Button>
                <Button type="default" onClick={this.confirmCancel}>Cancel</Button>
                {!isAddPage &&
                  <Button type="default" onClick={this.resendConfirmationEmail}>Resend Confirmation Email</Button>
                }
              </Space>
            </CustomForm>
          </>
        }
      </Content>
    );
  }

  private handleRoleChange = (selectedRole: string) => {
    const roleInt: number = parseInt(selectedRole); 
    this.setState({ userDTO: { ...this.state.userDTO, role: roleInt }, filerRoleSelected: roleInt == Role.FILER });
    this._formRef.current?.setFieldsValue({ 'committeeName': null, 'committeeCode': null });
  }

  private getLookups = (isAddPage: boolean) => {
    this.setState({ loading: true });

    LookupsApiService.getUserAccountStatuses()
      .then(statuses => {
        this.setState({ statuses });
      }).catch(() => {
        notification.error({
          message: 'Error while getting lookups'
        });
      });

    LookupsApiService.getRoles()
      .then(allRoles => {
        let allowedRoles = allRoles.filter(
          role => role.name == 'IECDB Administrator'
          || role.name == 'Filer'
          || role.name == 'Gift/Bequest Donor'
        );
        if (isAddPage) {
          allowedRoles = allRoles.filter(
            role => role.name == 'IECDB Administrator'
            || role.name == 'Filer'
          );
        }
        this.setState({ roles: allowedRoles });
      }).catch(() => {
        notification.error({
          message: 'Error while getting lookups'
        });
      });
  }

  private getUser = (userId: string) => {
    const currentUser = CurrentUser.Get();
    const forCommittee = this.props.forCommittee;

    if (userId) {
      UsersApiService.getUser(Number(userId))
        .then(userDTO => {
          this.setState({ userDTO });
          this._formRef.current?.setFieldsValue(this.state.userDTO);
          this.setState({ loading: false });
        }).catch(() => {
          notification.error({
            message: 'Error while fetching user'
          });
          this.setState({ loading: false });
        });
    }
    else if (forCommittee && currentUser && currentUser.committeeId) {
      UsersApiService.getCommitteeById(currentUser.committeeId)
        .then(committeeDTO => {
          this.setState({
            userDTO: AdminManageUsersListDTO.create({
              committeeId: committeeDTO.committeeId,
              committeeCode: committeeDTO.committeeCode,
              committeeName: committeeDTO.committeeName,
              statusId: '',
              role: Role.FILER
            }),
          });
          this._formRef.current?.setFieldsValue(this.state.userDTO);
          this.setState({ loading: false });
        }).catch(() => {
          notification.error({
            message: 'Error while fetching user'
          });
          this.setState({ loading: false });
        });
    }
    else {
      this.setState({ loading: false });
    }
  }

  private onCommitteeSelect = (committee?: CommitteeInfoDTO) => {
    if (committee) {
      this._formRef.current?.setFieldsValue({
        committeeId: committee.id,
        committeeCode: committee.committeeCode,
        committeeName: committee.name
      });
    }
  }

  private onPasswordChange = () => {
    if (this._formRef.current) {
      const password = this._formRef.current?.getFieldValue('password');
      const confirmPassword = this._formRef.current?.getFieldValue('confirmPassword');

      if (!password && !confirmPassword) {
        this.setState({ passwordFilled: false });
      }

      if (password || confirmPassword) {
        this.setState({ passwordFilled: true });
      }
    }
  }

  private confirmSave = () => {
    const userId = this.state.userId;
    const isAddPage = !userId;

    if (isAddPage) {
      Modal.confirm({
        title: 'Are you sure you want to save this new user?',
        onOk: this.handleSubmit,
      });
    }
    else {
      Modal.confirm({
        title: 'Are you sure you want to update this user?',
        onOk: this.handleSubmit,
      });
    }
  }

  private handleSubmit = () => {
    if (this._formRef.current) {
      this._formRef.current.validateFields()
        .then(result => {
          UsersApiService.saveUser(result)
            .then(result => {
              if (result.succeeded) {
                notification.success({
                  message: 'Saved Successfully'
                });
              }
              else {
                notification.error({
                  message: 'Error while saving'
                });
              }
              this.redirectUser();
            });
        });
    }
  }

  private confirmCancel = () => {
    confirm({
      title: 'Are you sure you want to leave?',
      okText: 'Yes',
      cancelText: 'No',
      onOk: () => {
        this.redirectUser();
      },
    });
  }

  private redirectUser = () => {
    const currentUser = CurrentUser.Get();
    const forCommittee = this.props.forCommittee;
    if (currentUser?.isInRole(Role.IECDB_ADMINISTRATOR) && !forCommittee) {
      HistoryUtil.push(Routes.generate(Routes.MANAGE_USERS));
    }
    else if (forCommittee) {
      HistoryUtil.push(Routes.generate(Routes.FILER_MANAGE_USERS));
    }
    else {
      HistoryUtil.push(Routes.generate(Routes.HOME_ROUTE));
    }
  }

  private resendConfirmationEmail = () => {
    const formRef = this._formRef.current;
    if (formRef) {
      const userId = formRef.getFieldValue('userId');
      AccountApiService.adminResendConfirmEmail(userId)
        .then(() => {
          notification.success({
            message: 'Confirmation email resent successfully.'
          });
        }).catch(() => {
          notification.error({
            message: 'Error while resending confirmation email.'
          });
        });
    }
  }
}

export default EditUser;

