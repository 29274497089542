import { ColProps, FormProps, FormInstance, Row, Col, Input, Select } from 'antd';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import FormItem from 'antd/lib/form/FormItem';
import * as React from 'react';
import BankAccountDTO from '../../models/BankAccountDTO';
import FormValidationUtil from '../../utils/FormValidationUtil';
import BankAccountTypes from '../../consts/BankAccountTypes';
import FormInputLen from '../../consts/FormInputLengths';
import AddressFormFields from './AddressFormFields';
import { Content } from 'antd/lib/layout/layout';
import CustomForm from './CustomForm';

const Option = Select.Option;

interface BankAccountFormProps {
  bankAccount: BankAccountDTO;
  committeeName?: string;
}

interface BankAccountFormState {
  bankAccount: BankAccountDTO;
}

class BankAccountForm extends React.Component<BankAccountFormProps & FormProps, BankAccountFormState> {
  private readonly _formRef = React.createRef<FormInstance>();

  constructor(props: BankAccountFormProps & FormProps) {
    super(props);

    this.state = {
      bankAccount: this.props.bankAccount as BankAccountDTO,
    };
  }

  validate = async (): Promise<{ model: BankAccountDTO, errorFields: any }> => {
    try {
      const fields = await this._formRef.current?.validateFields();
      return { model: fields, errorFields: null };
    }
    catch (errorInfo) {
      return Promise.reject({ model: errorInfo.values, errorFields: errorInfo.errorFields });
    }
  }

  render() {
    const { bankAccount,committeeName, ...formProps } = this.props;
    const columnSizingProps: ColProps = { xs: 24, sm: 24, md: 12 };

    const accountTypes = [
      BankAccountTypes.CHECKING,
      BankAccountTypes.SAVING,
      BankAccountTypes.CD,
      BankAccountTypes.OTHER
    ];

    return (
      <Content className="content-pad">
        <CustomForm {...formProps} formRef={this._formRef} initialValues={{ ...bankAccount, accountName: committeeName }} layout="vertical">
          <Row gutter={24}>
            <Col {...columnSizingProps} >
              <AddressFormFields
                name={{ name: 'bankName', label: 'Bank Name'}}
                address1={{ name: 'addressLine1' }}
                address2={{ name: 'addressLine2' }}
                city={{ name: 'city' }}
                state={{ name: 'state' }}
                zip={{ name: 'zipCode' }}
                phone={{ name: 'phoneNumber', required: false }}
                email={{ name: 'email', required: false }}
              />
            </Col>
            <Col {...columnSizingProps}>
              <FormItem
                name="accountName"
                label="Account Name"
                rules={[FormValidationUtil.Required('Account Name is required')]}
                tooltip="The account name must match the committee name." >
                  <Input className="" maxLength={FormInputLen.BANKACCOUNTNAME} disabled={true} />
              </FormItem>
              <FormItem name="accountNumber" label="Account Number">
                <Input className="" maxLength={FormInputLen.BANKACCOUNTNUMBER} />
              </FormItem>
              <FormItem name="accountType" label="Account Type" rules={[FormValidationUtil.Required('Account Type is required')]}>
                <Select
                  showSearch
                  optionFilterProp="children">
                  <Option value="" disabled={true}>-- Select Type --</Option>
                  {accountTypes.map(at => (
                    <Option key={at} value={at}>{at}</Option>
                  ))}
                </Select>
              </FormItem>
              <FormItem name="primaryAccount" valuePropName="checked">
                <Checkbox> Primary Account </Checkbox>
              </FormItem>
              <FormItem name="bankAccountId">
                <Input disabled={true} hidden={true} />
              </FormItem>
            </Col>
          </Row>
        </CustomForm>
      </Content>
    );
  }
}

export default BankAccountForm;