// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
import BaseApi from './BaseApi';
import TableRequestDTO from '../models/TableRequestDTO';
import AdminEscheatFormDTO from '../models/AdminEscheatFormDTO';
import TableResponseDTO from '../models/TableResponseDTO';
import AdminEscheatDTO from '../models/AdminEscheatDTO';
import PersonInfoDTO from '../models/PersonInfoDTO';

export class AdminEscheatApiService extends BaseApi {

  // post: api/admin/escheat/table
  public getEscheatList(tableRequest: TableRequestDTO): Promise<TableResponseDTO<AdminEscheatDTO>> {
    let url = `api/admin/escheat/table`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, TableResponseDTO<AdminEscheatDTO>>(tableRequest, 'post', url, true, false);
  }

  // get: api/admin/escheat/${escheatId}
  public getEscheat(escheatId: number): Promise<AdminEscheatFormDTO> {
    let url = `api/admin/escheat/${escheatId}`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, AdminEscheatFormDTO>(null, 'get', url, true, false);
  }

  // get: api/admin/escheat/individuals-list
  public getEscheatIndividualList(): Promise<PersonInfoDTO[]> {
    let url = `api/admin/escheat/individuals-list`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, PersonInfoDTO[]>(null, 'get', url, true, false);
  }

  // post: api/admin/escheat
  public saveEscheat(escheat: AdminEscheatFormDTO): Promise<void> {
    let url = `api/admin/escheat`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, void>(escheat, 'post', url, true, false);
  }

  // delete: api/admin/escheat/${escheatId}
  public delete(escheatId: number): Promise<void> {
    let url = `api/admin/escheat/${escheatId}`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, void>(null, 'delete', url, true, false);
  }
}
var service = new AdminEscheatApiService();
export default service;
