import {
  Button,
  Checkbox,
  Col,
  ColProps,
  FormInstance,
  Input,
  notification,
  Modal,
  Row,
  Select,
  Space,
  Typography,
  Tooltip,
} from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import TextArea from 'antd/lib/input/TextArea';
import AddressForm from '../shared/AddressFormFields';
import * as React from 'react';
import LookupsApiService from '../../api/LookupsApiService';
import SchAContributionsApiService from '../../api/SchAContributionsApiService';
import ScheduleApiService from '../../api/ScheduleApiService';
import CodeLookupTableDTO from '../../models/CodeLookupTableDTO';
import SchAContributionsFormDTO from '../../models/SchAContributionsFormDTO';
import CommitteeInfoDTO from '../../models/CommitteeInfoDTO';
import FormValidationUtil from '../../utils/FormValidationUtil';
import Dr2GroupTypes from '../../consts/Dr2GroupTypes';
import CommitteeType from '../../consts/CommitteeType';
import UnitemizedAmounts from '../../consts/UnitemizedAmounts';
import CurrentUser from '../../utils/CurrentUser';
import HistoryUtil from '../../utils/HistoryUtil';
import Routes from '../../consts/Routes';
import { RouteComponentProps } from 'react-router';
import { Content } from 'antd/lib/layout/layout';
import SchAContributionHistoryTable from './SchAContributionHistoryTable';
import moment from 'moment';
import PersonSearch from '../shared/PersonSearch';
import BaseScheduleInfoDTO from '../../models/BaseScheduleInfoDTO';
import ScheduleAbbreviationCodes from '../../consts/ScheduleAbbreviationCodes';
import CommitteeAutoSearch from '../shared/CommitteeSearch';
import NotesModal from '../shared/NotesModal';
import NoteEntityParents from '../../consts/NoteEntityParents';
import CurrencyInput from '../shared/CurrencyInput';
import CustomDatePicker from '../shared/CustomDatePicker';
import CustomForm from '../shared/CustomForm';
import OrganizationAutoSearch from '../shared/OrganizationSearch';

const Option = Select.Option;
const confirm = Modal.confirm;

interface SchAContributionsFormProps {
  id?: string;
  seqNum?: string;
}

interface SchAContributionsFormState {
  relations: CodeLookupTableDTO[];
  candidate: BaseScheduleInfoDTO;
  currContrType: string;
  currComm: CommitteeInfoDTO;
  isEarmarked: boolean;
  isEarmarkedTo: boolean;
  isEarmarkedThrough: boolean;
  unitemizedAffirmationChecked: boolean;
  companyOtherAffirmationChecked: boolean;
  btnDisable: boolean;
  contributionInfo: SchAContributionsFormDTO & { person?: string };
  contributionId?: string;
  startDt: moment.Moment;
  endDt: moment.Moment;
  loading: boolean;
  trusteeTrustorReq: boolean;
  autoFillPerorg: boolean;
  candidatesOwnFunds: boolean;
  seqNum: number;
  auditorsNoteId: string;
  hideRelationship: boolean;
}

class SchAContributionsForm extends React.Component<RouteComponentProps<SchAContributionsFormProps>, SchAContributionsFormState> {
  private readonly _formRef = React.createRef<FormInstance>();

  constructor(props: RouteComponentProps<SchAContributionsFormProps>) {
    super(props);
    this.state = {
      relations: [],
      candidate: BaseScheduleInfoDTO.create(),
      currContrType: '',
      currComm: CommitteeInfoDTO.create(),
      isEarmarked: false,
      isEarmarkedTo: false,
      isEarmarkedThrough: false,
      unitemizedAffirmationChecked: false,
      companyOtherAffirmationChecked: false,
      btnDisable: false,
      contributionId: this.props.match.params.id,
      contributionInfo: SchAContributionsFormDTO.create({ contributionType: '', state: 'IA', relationshipCode: '', amount: null }),
      startDt: moment.utc(),
      endDt: moment.utc(),
      loading: true,
      trusteeTrustorReq: false,
      autoFillPerorg: false,
      candidatesOwnFunds: false,
      seqNum: Number(this.props.match.params.seqNum) || 0,
      auditorsNoteId: '',
      hideRelationship: false,
    };
  }

  componentDidMount() {
    this.loadLookups();
    this.getCandidate();
    this.getSchedule();
    this.getUserCommittee();

    if (this.props.match.params.id) {
      this.getContribution(this.props.match.params.id || '', this.props.match.params.seqNum || '');
    }
  }

  render() {
    const { relations, currContrType, currComm, isEarmarked, isEarmarkedTo,
      isEarmarkedThrough, unitemizedAffirmationChecked, companyOtherAffirmationChecked, btnDisable,
      contributionInfo, contributionId, startDt, endDt, loading, trusteeTrustorReq, autoFillPerorg,
      candidatesOwnFunds, seqNum, auditorsNoteId, hideRelationship } = this.state;

    const columnSizingProps: ColProps = { xs: 24, sm: 24, md: 12 };
    const isCandidateCommittee = currComm.filerTypeName == 'Candidate';
    const checkNumReq = currContrType === Dr2GroupTypes.COMMITTEE;
    const relationReq = currContrType === Dr2GroupTypes.INDIVIDUAL && isCandidateCommittee;
    const isUnitemized = currContrType === Dr2GroupTypes.UNITEMIZED;
    const isCompanyOther = currContrType === Dr2GroupTypes.COMPANYOTHER && currComm.committeeTypeCd != CommitteeType.LOCAL_BALLOT_ISSUE;
    let unitemizedAmt = 0;

    const contributorTypes = [
      Dr2GroupTypes.INDIVIDUAL,
      Dr2GroupTypes.COMMITTEE,
      Dr2GroupTypes.TRUST,
      Dr2GroupTypes.COMPANYOTHER,
      Dr2GroupTypes.UNITEMIZED
    ];

    if (currComm.type == CommitteeType.COUNTY_CENTRAL_COMMITTEE) {
      unitemizedAmt = UnitemizedAmounts.STATUTORY_COUNTY_CENTRAL_COMMITTEE_UNITEMIZED_AMOUNT;
    }
    else if (currComm.type == CommitteeType.STATE_CENTRAL_COMMITTEE) {
      unitemizedAmt = UnitemizedAmounts.STATE_PARTY_UNITEMIZED_AMOUNT;
    }
    else {
      unitemizedAmt = UnitemizedAmounts.ALL_OTHER_UNITEMIZED_AMOUNT;
    }

    return (
      <>
        {!loading &&
          <Content className="content-pad">
            <Typography.Title level={4}>Schedule A - Contributions</Typography.Title>
            <CustomForm formRef={this._formRef} initialValues={contributionInfo} layout="vertical" onFinish={this.handleSave}>
              <Row gutter={24}>
                <Col {...columnSizingProps}>
                  <FormItem name="contributionType" label="Contributor Type"
                    rules={[FormValidationUtil.Required('Contributor Type is required')]} >
                    <Select
                      showSearch
                      optionFilterProp="children"
                      disabled={candidatesOwnFunds}
                      onChange={this.handleContributorTypeChange}>
                      <Option value="" disabled={true}>-- Select Type --</Option>
                      {contributorTypes.map(ct => (
                        <Option key={ct} value={ct}>{ct}</Option>
                      ))}
                    </Select>
                  </FormItem>
                  {isUnitemized &&
                    <FormItem name="unitemizedAgreement" valuePropName="checked"
                      rules={[FormValidationUtil.Checkbox(unitemizedAffirmationChecked, isUnitemized,
                        'Affirmation checkbox must be checked for unitemized contributions')]}>
                      <Checkbox onChange={this.handleUnitemizedAffirmationCheckboxChange}>
                        I affirm that I have separately disclosed
                        any contributor who has given in excess of ${unitemizedAmt} in the calendar year. Contact
                        the Board&apos;s staff at 515-281-4028 if you have any questions.
                      </Checkbox>
                    </FormItem>
                  }
                  {isCompanyOther &&
                    <FormItem name="companyOtherAgreement" valuePropName="checked"
                      rules={[FormValidationUtil.Checkbox(companyOtherAffirmationChecked, isCompanyOther,
                        'Affirmation checkbox must be checked for company/other contributions')]}>
                      <Checkbox onChange={this.handleCompanyOtherAffirmationCheckboxChange}>
                        I affirm that no contributions have been accepted from an incorporated business.
                        Contact the Board&apos;s staff at 515-281-4028 if you have any questions.
                      </Checkbox>
                    </FormItem>
                  }
                  <FormItem name="date" label="Date"
                  rules={[FormValidationUtil.RequiredDate('Date is required'), FormValidationUtil.DateRange(startDt, endDt)]}>
                  <CustomDatePicker onChange={(d) => d?.startOf('day')} />
                  </FormItem>
                  <FormItem name="amount" label="Amount"
                    rules={[FormValidationUtil.RequiredNumber('Amount is required')]}>
                    <CurrencyInput min={Number.MIN_SAFE_INTEGER} />
                  </FormItem>
                  {!isUnitemized &&
                    <FormItem name="checkNumber" label="Check Number"
                      rules={[FormValidationUtil.Number('Number is invalid'),
                      FormValidationUtil.Required('Check Number is required', checkNumReq)]}>
                      <Input maxLength={12} />
                    </FormItem>
                  }
                  <Row>
                    <Col>
                      <Space>
                        <Tooltip title="Contribution was the result of a fundraising event">
                          <FormItem name="isFromFundraiser" valuePropName="checked">
                            <Checkbox > From Fundraiser </Checkbox>
                          </FormItem>
                        </Tooltip>
                        {isCandidateCommittee &&
                          <Tooltip title="Contribution to bank account by candidate">
                            <FormItem name="isCandidatesOwnFunds" valuePropName="checked">
                              <Checkbox onChange={this.handleCandidateFundsCheckboxChange}> Candidate&apos;s Own Funds </Checkbox>
                            </FormItem>
                          </Tooltip>
                        }
                        <Tooltip title="Interest earned from bank or security">
                          <FormItem name="isInterest" valuePropName="checked">
                            <Checkbox onChange={this.handleInterestCheckboxChange}> Interest </Checkbox>
                          </FormItem>
                        </Tooltip>
                      </Space>
                    </Col>
                  </Row>
                  <FormItem name="explanation" label="Explanation" >
                    <TextArea maxLength={2000} />
                  </FormItem>
                </Col>
                <Col {...columnSizingProps}>
                  {currContrType === Dr2GroupTypes.INDIVIDUAL && (
                    <>
                      <PersonSearch
                        name={'person'}
                        label={'Search for an Individual'}
                        required={false}
                        disabled={candidatesOwnFunds}
                        committeeId={currComm.id || ''}
                        onSelect={this.getPerson}
                        onChange={this.handleContributorChange}
                      />
                      <AddressForm allDisabled={autoFillPerorg || candidatesOwnFunds}
                        firstName={{ name: 'firstName' }}
                        middleInitial={{ name: 'middleInitial' }}
                        lastName={{ name: 'lastName' }}
                        address1={{ name: 'addressLine1' }}
                        address2={{ name: 'addressLine2' }}
                        city={{ name: 'city' }}
                        state={{ name: 'state' }}
                        zip={{ name: 'zip' }}
                        phone={{ name: 'phoneNumber', required: false }}
                      />
                      <FormItem name="relationshipCode" label="Relationship" hidden={hideRelationship}
                        rules={[FormValidationUtil.Required('Relationship is required', relationReq)]}>
                        <Select
                          showSearch
                          disabled={autoFillPerorg || candidatesOwnFunds}
                          optionFilterProp="children">
                          <Option value="" disabled={true}>-- Select Relationship --</Option>
                          {relations.map(rt => (
                            <Option key={rt.code || ''} value={rt.code || ''}>{rt.name}</Option>
                          ))}
                        </Select>
                      </FormItem>
                    </>
                  )}
                  {currContrType === Dr2GroupTypes.COMMITTEE && (
                    <CommitteeAutoSearch
                      name="contributorName"
                      label="Name"
                      required={true}
                      requireSelection={true}
                      onSelectCommittee={this.handleCommitteeSelect}
                    />
                  )}
                  {(currContrType === Dr2GroupTypes.COMPANYOTHER || currContrType === Dr2GroupTypes.TRUST) && (
                    <>
                      <OrganizationAutoSearch
                        name={'company'}
                        label={'Search for a Company'}
                        required={false}
                        committeeId={currComm.id || ''}
                        perorgType={currContrType}
                        onSelect={this.getOrganization}
                        onChange={this.handleContributorChange}
                      />
                      <AddressForm allDisabled={autoFillPerorg}
                        name={{ name: 'contributorName', label: 'Company' }}
                        phone={{ name: 'phoneNumber', required: false }}
                        address1={{ name: 'addressLine1' }}
                        address2={{ name: 'addressLine2' }}
                        city={{ name: 'city' }}
                        state={{ name: 'state' }}
                        zip={{ name: 'zip' }}
                      />
                    </>
                  )}
                  {currContrType === Dr2GroupTypes.TRUST &&
                    <>
                      <FormItem name="isLivingOrRevocableTrust" valuePropName="checked">
                        <Checkbox onChange={this.handleRevocableTrustCheckboxChange}>Living or Revocable Trust</Checkbox>
                      </FormItem>
                      <FormItem name="trustee" label="Trustee"
                        rules={[FormValidationUtil.Required('Trustee is required', trusteeTrustorReq)]}>
                        <Input disabled={autoFillPerorg} />
                      </FormItem>
                      <FormItem name="trustor" label="Trustor"
                        rules={[FormValidationUtil.Required('Trustor is required', trusteeTrustorReq)]}>
                        <Input disabled={autoFillPerorg} />
                      </FormItem>
                    </>
                  }
                </Col>
              </Row>
              <Row>
                <Col {...columnSizingProps}>
                  <FormItem name="isEarmarked" valuePropName="checked">
                    <Checkbox onChange={this.handleEarmarkCheckboxChange}>Earmarked</Checkbox>
                  </FormItem>
                  {isEarmarked &&
                    <>
                      <Typography.Paragraph>Pick either &apos;Earmarked To&apos; or &apos;Earmarked Through&apos;</Typography.Paragraph>
                      <CommitteeAutoSearch
                        name="earmarkedTo"
                        label="To"
                        required={isEarmarked && !isEarmarkedThrough}
                        disabled={isEarmarkedThrough}
                        onSelect={this.handleEarmarkToSelect}
                        onChange={this.handleEarmarkToChange}
                      />
                      {isEarmarkedTo &&
                        <FormItem name="earmarkedToAddress" label="Address"
                          rules={[FormValidationUtil.Required('Earmarked To is required', isEarmarkedTo)]}>
                          <Input />
                        </FormItem>
                      }
                      <CommitteeAutoSearch
                        name="earmarkedFrom"
                        label="Through"
                        required={isEarmarked && !isEarmarkedTo}
                        disabled={isEarmarkedTo}
                        onSelect={this.handleEarmarkThroughSelect}
                        onChange={this.handleEarmarkThroughChange}
                      />
                      {isEarmarkedThrough &&
                        <FormItem name="earmarkedFromAddress" label="Address"
                          rules={[FormValidationUtil.Required('Earmarked Through is required', isEarmarkedThrough)]}>
                          <Input />
                        </FormItem>
                      }
                    </>
                  }
                  <FormItem name="contributorAutoFillId" hidden={true}>
                    <Input hidden={true} disabled={true} aria-hidden={true}/>
                  </FormItem>
                </Col>
              </Row>
              <Space>
                <Button type="primary" disabled={btnDisable} htmlType="submit" > Save Contribution </Button>
                <Button type="default" disabled={btnDisable} onClick={this.handleCancel}> Cancel </Button>
                {contributionId &&
                  <NotesModal
                    parentId={contributionId}
                    parent={NoteEntityParents.CONTRIBUTION}
                    seqNum={seqNum || 0}
                    auditorsNoteId={auditorsNoteId} />
                }
              </Space>
            </CustomForm >
            {contributionId &&
              <>
                <br /><br />
                <SchAContributionHistoryTable
                  contributionId={contributionId || ''}
                />
              </>
            }
          </Content>
        }
      </>
    );
  }

  private handleContributorTypeChange = (contributorType: string) => {
    this.setState({
      currContrType: contributorType,
      autoFillPerorg: false,
      trusteeTrustorReq: contributorType == Dr2GroupTypes.TRUST
    });

    this._formRef.current?.setFieldsValue({
      'firstName': '',
      'middleInitial': '',
      'lastName': '',
      'relationshipCode': '',
      'addressLine1': '',
      'addressLine2': '',
      'city': '',
      'state': '',
      'zip': '',
      'phoneNumber': '',
      'trustee': '',
      'trustor': '',
      'person': null,
      'contributorName': null,
      'company': null,
      'isLivingOrRevocableTrust': false,
      'contributorAutoFillId': ''
    });

    if ([Dr2GroupTypes.COMPANYOTHER, Dr2GroupTypes.INDIVIDUAL, Dr2GroupTypes.TRUST].includes(contributorType)) {
      this._formRef.current?.setFieldsValue({ state: 'IA' });
    }
  }

  private handleContributorChange = (value: string) => {
    this._formRef.current?.setFieldsValue({ contributorAutoFillId: '' });
    this.setState({ autoFillPerorg: !!value });
  }

  private handleCommitteeSelect = (committee: CommitteeInfoDTO | undefined) => {
    this._formRef.current?.setFieldsValue({ contributorAutoFillId: committee?.id });
  }

  private handleUnitemizedAffirmationCheckboxChange = (e: any) => {
    this.setState({ unitemizedAffirmationChecked: e.target.checked });
  }

  private handleCompanyOtherAffirmationCheckboxChange = (e: any) => {
    this.setState({ companyOtherAffirmationChecked: e.target.checked });
  }

  private handleCandidateFundsCheckboxChange = (e: any) => {
    if (e.target.checked) {
      if (this._formRef.current != null) {
        const { candidate } = this.state;
        if (candidate.firstName !== null) {
          this.setIndividualFields(candidate);
        }
        else {
          this._formRef.current.setFieldsValue({
            contributionType: Dr2GroupTypes.INDIVIDUAL,
            contributorAutoFillId: ''
          });
          this.setState({ currContrType: Dr2GroupTypes.INDIVIDUAL });
          notification.error({
            message: 'Candidate was not found',
            description: ''
          });
        }

      }
    }
    else {
      if (this._formRef.current != null) {
        this.setIndividualFields();
      }
    }
    this.setState({ candidatesOwnFunds: e.target.checked });
    this._formRef.current?.setFieldsValue({ 'person': null });
  }

  private setCompanyFields(form?: BaseScheduleInfoDTO) {
    if (form) {
      this._formRef.current?.setFieldsValue({
        contributorName: form.committeeOrCompanyName,
        addressLine1: form.addressLine1,
        addressLine2: form.addressLine2,
        city: form.city,
        state: form.state,
        zip: form.zip,
        phoneNumber: form.phoneNumber,
        trustee: form.trustee,
        trustor: form.trustor
      });
    }
    else {
      this._formRef.current?.setFieldsValue({
        contributorName: '',
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        zip: '',
        phoneNumber: '',
        trustee: '',
        trustor: '',
        contributorAutoFillId: ''
      });
      this.setState({ autoFillPerorg: false });
    }
  }

  private setIndividualFields(form?: BaseScheduleInfoDTO) {
    if (form) {
      this._formRef.current?.setFieldsValue({
        contributionType: form.groupType,
        firstName: form.firstName,
        middleInitial: form.middleInitial,
        lastName: form.lastName,
        addressLine1: form.addressLine1,
        addressLine2: form.addressLine2,
        city: form.city,
        state: form.state,
        zip: form.zip,
        phoneNumber: form.phoneNumber,
        relationshipCode: form.relationshipCode,
        contributorAutoFillId: ''
      });
    }
    else {
      this._formRef.current?.setFieldsValue({
        contributionType: Dr2GroupTypes.INDIVIDUAL,
        firstName: '',
        middleInitial: '',
        lastName: '',
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        zip: '',
        phoneNumber: '',
        relationshipCode: '',
        contributorAutoFillId: ''
      });
      this.setState({ autoFillPerorg: false });
    }
    this.setState({ currContrType: Dr2GroupTypes.INDIVIDUAL });
  }

  private handleInterestCheckboxChange = (e: any) => {
    if (e.target.checked) {
      if (this._formRef.current != null) {
        this._formRef.current.setFieldsValue({ explanation: 'Interest Earned' });
      }
    }
    else {
      if (this._formRef.current != null) {
        this._formRef.current.setFieldsValue({ explanation: '' });
      }
    }
  }

  private handleRevocableTrustCheckboxChange = (e: any) => {
    this.setState({ trusteeTrustorReq: !e.target.checked });
  }

  private handleEarmarkToChange = (value: string) => {
    if (value) {
      this.setState({ isEarmarkedTo: true });
      this._formRef.current?.setFieldsValue({ earmarkedFrom: null });
    }
    else {
      this.setState({ isEarmarkedTo: false });
    }
  }

  private handleEarmarkThroughChange = (value: string) => {
    if (value) {
      this.setState({ isEarmarkedThrough: true });
      this._formRef.current?.setFieldsValue({ earmarkedTo: null });
    }
    else {
      this.setState({ isEarmarkedThrough: false });
    }
  }

  private handleEarmarkToSelect = (committeeId: string) => {
    if (committeeId !== '') {
      ScheduleApiService.getCommitteeAddress(committeeId)
        .then(earmarkInfo => {
          const addr = earmarkInfo.addressLine1 + ', ' + earmarkInfo.city + ', ' + earmarkInfo.state?.trim() + ' ' + earmarkInfo.zip;
          this._formRef.current?.setFieldsValue({ earmarkedToAddress: addr });
        }).catch(() => {
          this._formRef.current?.setFieldsValue({ earmarkedToAddress: 'Not Found' });
        });
    }
    else {
      this._formRef.current?.setFieldsValue({ earmarkedToAddress: '' });
    }
  }

  private handleEarmarkThroughSelect = (committeeId: string) => {
    if (committeeId !== '') {
      ScheduleApiService.getCommitteeAddress(committeeId)
        .then(earmarkInfo => {
          const addr = earmarkInfo.addressLine1 + ', ' + earmarkInfo.city + ', ' + earmarkInfo.state?.trim() + ' ' + earmarkInfo.zip;
          this._formRef.current?.setFieldsValue({ earmarkedFromAddress: addr });
        }).catch(() => {
          this._formRef.current?.setFieldsValue({ earmarkedFromAddress: 'Not Found' });
        });
    }
    else {
      this._formRef.current?.setFieldsValue({ earmarkedFromAddress: '' });
    }
  }

  private handleEarmarkCheckboxChange = () => {
    this.setState({ isEarmarked: !this.state.isEarmarked });
  }

  private loadLookups = () => {
    const relationPromise = LookupsApiService.getRelationTypes();
    Promise.all([relationPromise])
      .then(result => {
        const [relations] = result;
        this.setState({ relations: relations });
      });
  }

  private getSchedule = () => {
    ScheduleApiService.getSchedule(CurrentUser.Get()?.dr2Id || '', ScheduleAbbreviationCodes.SCHEDULEA)
      .then(schedule => {
        this.setState({ startDt: moment.utc(schedule.startDate), endDt: moment.utc(schedule.endDate) });
      });
  }

  private getCandidate = () => {
    ScheduleApiService.getCandidate(CurrentUser.Get()?.committeeId || '')
      .then(personInfo => {
        if (personInfo.groupType == null) {
          return;
        }

        this.setState({ candidate: personInfo });
      });
  }

  private getPerson = (perorgId: string) => {
    if (perorgId != '') {
      ScheduleApiService.getPerson(perorgId, CurrentUser.Get()?.committeeId || '')
        .then(personInfo => {
          if (personInfo) {
            this.setIndividualFields(personInfo);
            this._formRef.current?.setFieldsValue({ contributorAutoFillId: perorgId });
          }
        });
    }
    else {
      this.setIndividualFields();
    }
  }

  private getOrganization = (perorgId: string) => {
    if (perorgId != '') {
      ScheduleApiService.getOrganization(perorgId, CurrentUser.Get()?.committeeId || '')
        .then(orgInfo => {
          if (orgInfo) {
            this.setCompanyFields(orgInfo);
            this._formRef.current?.setFieldsValue({ contributorAutoFillId: perorgId });
          }
        });
    }
    else {
      this.setCompanyFields();
    }
  }

  private getUserCommittee(): Promise<void> {
    return ScheduleApiService.getCommittee(CurrentUser.Get()?.committeeId || '')
      .then(committee => {
        if (committee) {
          const hideRelationshipCodes = [
            CommitteeType.CITY_PAC,
            CommitteeType.COUNTY_PAC,
            CommitteeType.IOWA_PAC,
            CommitteeType.SCHOOL_BOARD_OR_OTHER_POLITICAL_SUBDIVISION_PAC,
            CommitteeType.COUNTY_CENTRAL_COMMITTEE,
            CommitteeType.STATE_CENTRAL_COMMITTEE,
            CommitteeType.LOCAL_BALLOT_ISSUE];
          this.setState({ currComm: { ...committee }, hideRelationship: hideRelationshipCodes.includes(committee.committeeTypeCd || '') });
        }
      }).finally(() => {
        this.setState({ loading: false });
      });
  }

  private getContribution = (contributionId: string, seqNum: string) => {
    const contributionPromise = SchAContributionsApiService.getContribution(contributionId, Number(seqNum));
    const committeePromise = ScheduleApiService.getCommittee(CurrentUser.Get()?.committeeId || '');

    Promise.all([contributionPromise, committeePromise])
      .then(results => {
        const [contribution, committee] = results;

        if (contribution) {
          contribution.date = moment(contribution.date);

          const contributionInfo: SchAContributionsFormDTO & { person?: string, company?: string } = contribution;
          if (contributionInfo.firstName && contributionInfo.lastName) {
            if (contributionInfo.middleInitial) {
              contributionInfo.person = contribution.firstName + ' ' + contribution.middleInitial + '. ' + contribution.lastName;
            }
            else {
              contributionInfo.person = contribution.firstName + ' ' + contribution.lastName;
            }
          }
          else if (contributionInfo.contributionType == Dr2GroupTypes.TRUST || contributionInfo.contributionType == Dr2GroupTypes.COMPANYOTHER) {
            if (contributionInfo.contributorName) {
              contributionInfo.company = contributionInfo.contributorName;
            }
          }

          this.setState({
            contributionInfo: contribution,
            currContrType: contribution.contributionType || '',
            isEarmarked: contribution.isEarmarked,
            isEarmarkedTo: contribution.earmarkedTo != null,
            isEarmarkedThrough: contribution.earmarkedFrom != null,
            trusteeTrustorReq: contribution.contributionType == Dr2GroupTypes.TRUST && !contribution.isLivingOrRevocableTrust,
            autoFillPerorg: contribution.contributionType != Dr2GroupTypes.COMMITTEE,
            candidatesOwnFunds: contribution.isCandidatesOwnFunds,
            auditorsNoteId: contribution.auditorsNoteId || ''
          });
          this._formRef.current?.setFieldsValue(contributionInfo);
        }
        else {
          notification.error({
            message: 'Error while fetching contribution',
            description: 'Not found'
          });
        }

        if (committee) {
          this.setState({ currComm: { ...committee } });
        }

      }).catch(() => {
        notification.error({
          message: 'Error while fetching contribution',
          description: ''
        });
      }).finally(() => {
        this.setState({ loading: false });
      });
  }

  private handleSave = (values: SchAContributionsFormDTO) => {
    if (values.contributorAutoFillId === '') {
      values.contributorAutoFillId = null;
    }
    this.setState({ btnDisable: true });
    if (!this.state.contributionId) {
      SchAContributionsApiService.add(values, CurrentUser.Get()?.committeeId || '', CurrentUser.Get()?.dr2Id || '')
        .then(() => {
          notification.success({
            message: 'Saved Successfully',
            description: ''
          });
          HistoryUtil.push(Routes.SCHEDULE_A_CONTRIBUTIONS);
        }).catch(error => {
          notification.error({
            message: 'Your contribution failed to save',
            description: error.message
          });
          HistoryUtil.push(Routes.SCHEDULE_A_CONTRIBUTIONS);
        });
    }
    else {
      SchAContributionsApiService.edit(
        values,
        CurrentUser.Get()?.committeeId || '',
        CurrentUser.Get()?.dr2Id || '',
        this.props.match.params.id || '',
        Number(this.props.match.params.seqNum)
      )
        .then(() => {
          notification.success({
            message: 'Saved Successfully',
            description: ''
          });
          HistoryUtil.push(Routes.SCHEDULE_A_CONTRIBUTIONS);
        }).catch(() => {
          notification.error({
            message: 'Your contribution failed to save',
            description: ''
          });
          HistoryUtil.push(Routes.SCHEDULE_A_CONTRIBUTIONS);
        });
    }
  }

  private handleCancel = () => {
    confirm({
      title: 'Are you sure you want to leave?',
      okText: 'Yes',
      cancelText: 'No',
      onOk: () => {
        HistoryUtil.push(Routes.SCHEDULE_A_CONTRIBUTIONS);
      }
    });
  }

}

export default SchAContributionsForm;