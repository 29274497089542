// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import EntitySelectorDTO from './EntitySelectorDTO';

interface DesignatedPositionDTO extends EntitySelectorDTO { 
  contactId: number;
  lastName: string | null;
  firstName: string | null;
  address1: string | null;
  address2: string | null;
  city: string | null;
  state: string | null;
  zip: string | null;
  phone: string | null;
  email: string | null;
  yearCovered: moment.Moment | string | null;
}
const DesignatedPositionDTO = {
  create: (initValues?: Partial<DesignatedPositionDTO> | Record<string, unknown> | null) => {
    return Object.assign(EntitySelectorDTO.create(),
    {
      contactId: 0,
      lastName: null,
      firstName: null,
      address1: null,
      address2: null,
      city: null,
      state: null,
      zip: null,
      phone: null,
      email: null,
      yearCovered: null,
    },
    initValues);
  }
};

export default DesignatedPositionDTO;