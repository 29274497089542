import {
  Button,
  Col,
  ColProps,
  FormInstance,
  Input,
  notification,
  Modal,
  Row,
  Select,
  Space,
  Typography,
  Checkbox,
} from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import TextArea from 'antd/lib/input/TextArea';
import AddressForm from '../shared/AddressFormFields';
import * as React from 'react';
import LookupsApiService from '../../api/LookupsApiService';
import SchDDebtsApiService from '../../api/SchDDebtsApiService';
import ScheduleApiService from '../../api/ScheduleApiService';
import CodeLookupTableDTO from '../../models/CodeLookupTableDTO';
import SchDDebtsFormDTO from '../../models/SchDDebtsFormDTO';
import CommitteeInfoDTO from '../../models/CommitteeInfoDTO';
import FormValidationUtil from '../../utils/FormValidationUtil';
import Dr2GroupTypes from '../../consts/Dr2GroupTypes';
import CurrentUser from '../../utils/CurrentUser';
import HistoryUtil from '../../utils/HistoryUtil';
import Routes from '../../consts/Routes';
import { RouteComponentProps } from 'react-router';
import { Content } from 'antd/lib/layout/layout';
import moment from 'moment';
import PersonSearch from '../shared/PersonSearch';
import BaseScheduleInfoDTO from '../../models/BaseScheduleInfoDTO';
import ExpenditureType from '../../consts/ExpenditureType';
import ExpenditureTypeDTO from '../../models/ExpenditureTypeDTO';
import SchDDebtHistoryTable from './SchDDebtHistoryTable';
import CommitteeAutoSearch from '../shared/CommitteeSearch';
import NotesModal from '../shared/NotesModal';
import NoteEntityParents from '../../consts/NoteEntityParents';
import CurrencyInput from '../shared/CurrencyInput';
import CustomDatePicker from '../shared/CustomDatePicker';
import CustomForm from '../shared/CustomForm';
import OrganizationAutoSearch from '../shared/OrganizationSearch';
import CommitteeType from '../../consts/CommitteeType';

const Option = Select.Option;
const confirm = Modal.confirm;

interface SchDDebtsFormProps {
  id?: string;
  seqNum?: string;
}

interface SchDDebtsFormState {
  relations: CodeLookupTableDTO[];
  expenseTypes: ExpenditureTypeDTO[];
  currPayeeType: string;
  currExpenseType: string;
  currComm: CommitteeInfoDTO;
  btnDisable: boolean;
  debtInfo: SchDDebtsFormDTO;
  formType: 'New' | 'Edit';
  debtId: string;
  loading: boolean;
  trusteeTrustorReq: boolean;
  autoFillPerorg: boolean;
  seqNum: number;
  hideRelationship: boolean;
}

class SchDDebtsForm extends React.Component<RouteComponentProps<SchDDebtsFormProps>, SchDDebtsFormState> {
  private readonly _formRef = React.createRef<FormInstance>();

  constructor(props: RouteComponentProps<SchDDebtsFormProps>) {
    super(props);
    this.state = {
      relations: [],
      expenseTypes: [],
      currPayeeType: '',
      currExpenseType: '',
      currComm: CommitteeInfoDTO.create(),
      btnDisable: false,
      formType: this.props.match.params.id ? 'Edit' : 'New',
      debtId: this.props.match.params.id || '',
      debtInfo: SchDDebtsFormDTO.create({
        payeeType: '',
        state: 'IA',
        relationshipCode: '',
        amount: null,
        expenseType: '',
      }),
      loading: !!this.props.match.params.id,
      trusteeTrustorReq: false,
      seqNum: Number(this.props.match.params.seqNum) || 0,
      autoFillPerorg: false,
      hideRelationship: false,
    };
  }

  componentDidMount() {
    this.loadLookups();
    this.getUserCommittee();
    if (this.props.match.params.id) {
      this.getDebt(this.props.match.params.id || '', this.props.match.params.seqNum || '');
    }
  }

  render() {
    const { relations, expenseTypes, currPayeeType, currExpenseType, currComm,
      btnDisable, formType, debtId, debtInfo, loading,
      trusteeTrustorReq, seqNum, autoFillPerorg, hideRelationship } = this.state;
    const columnSizingProps: ColProps = { xs: 24, sm: 24, md: 12 };
    const isCandidateCommittee = currComm.filerTypeName == 'Candidate';
    const relationReq = currPayeeType === Dr2GroupTypes.INDIVIDUAL && isCandidateCommittee;

    const explantionReqCodeArr: string[] = [];
    expenseTypes.forEach(e => {
      if (e.explanationRequired) {
        explantionReqCodeArr.push(e.code || '');
      }
    });

    const explanationReq = (currExpenseType && explantionReqCodeArr.indexOf(currExpenseType || '') >= 0);

    const payeeTypes = [
      Dr2GroupTypes.INDIVIDUAL,
      Dr2GroupTypes.COMMITTEE,
      Dr2GroupTypes.TRUST,
      Dr2GroupTypes.COMPANYOTHER,
    ];

    const typeTooltip = expenseTypes.filter(e => e.code === currExpenseType)[0];

    return (
      <>
        {!loading &&
          <Content className="content-pad">
            <Typography.Title level={4}>Schedule D - Debt</Typography.Title>
            <CustomForm formRef={this._formRef} initialValues={debtInfo} layout="vertical" onFinish={this.handleSave}>
              <Row gutter={24}>
                <Col {...columnSizingProps}>
                  <FormItem name="payeeType" label="Payee Type"
                    rules={[FormValidationUtil.Required('Payee Type is required')]} >
                    <Select
                      showSearch
                      optionFilterProp="children"
                      onChange={this.handlePayeeTypeChange}>
                      <Option value="" disabled={true}>-- Select Type --</Option>
                      {payeeTypes.map(pt => (
                        <Option key={pt} value={pt}>{pt}</Option>
                      ))}
                    </Select>
                  </FormItem>
                  <FormItem name="date" label="Date"
                  rules={[FormValidationUtil.RequiredDate('Date is required')]}>
                  <CustomDatePicker onChange={(d) => d?.startOf('day')}/>
                  </FormItem>
                  <FormItem name="expenseType" label="Type"
                    rules={[FormValidationUtil.Required('Type is required')]}
                    tooltip={typeTooltip ? typeTooltip.explanationDescription : 'Select a type'}
                  >
                    <Select
                      showSearch
                      optionFilterProp="children"
                      onSelect={this.handleExpenseTypeChange}
                    >
                      <Option value="" disabled={true}>-- Select Type --</Option>
                      {expenseTypes.map(et => (
                        <Option key={et.code || ''} value={et.code || ''}>{et.name}</Option>
                      ))}
                    </Select>
                  </FormItem>
                  <FormItem name="amount" label="Amount"
                    rules={[
                      FormValidationUtil.RequiredNumber('Amount is required'),
                      FormValidationUtil.NotNegativeNumber('Amount must be nonnegative')
                    ]}>
                    <CurrencyInput />
                  </FormItem>
                  <FormItem name="explanation" label="Explanation"
                    rules={[FormValidationUtil.Required(
                      'Explanation is required',
                      explanationReq || currExpenseType === ExpenditureType.MISCELLANEOUS_OR_UNITEMIZED
                    )]}>
                    <TextArea
                      placeholder={currExpenseType === ExpenditureType.MILEAGE ? 'Please enter the date range for mileage incurred' : ''}
                      maxLength={2000}
                    />
                  </FormItem>

                </Col>
                <Col {...columnSizingProps}>
                  {currPayeeType === Dr2GroupTypes.INDIVIDUAL && (
                    <>
                      <PersonSearch
                        name={'person'}
                        label={'Search for an Individual'}
                        required={false}
                        committeeId={currComm.id || ''}
                        onSelect={this.getPerson}
                        onChange={this.handlePayeeChange}
                      />
                      <AddressForm allDisabled={autoFillPerorg}
                        firstName={{ name: 'firstName' }}
                        middleInitial={{ name: 'middleInitial' }}
                        lastName={{ name: 'lastName' }}
                        address1={{ name: 'addressLine1' }}
                        address2={{ name: 'addressLine2' }}
                        city={{ name: 'city' }}
                        state={{ name: 'state' }}
                        zip={{ name: 'zip' }}
                        phone={{ name: 'phoneNumber', required: false }}
                      />
                      <FormItem name="relationshipCode" label="Relationship" hidden={hideRelationship}
                        rules={[FormValidationUtil.Required('Relationship is required', relationReq)]}>
                        <Select
                          showSearch disabled={autoFillPerorg}
                          optionFilterProp="children">
                          <Option value="" disabled={true}>-- Select Relationship --</Option>
                          {relations.map(rt => (
                            <Option key={rt.code || ''} value={rt.code || ''}>{rt.name}</Option>
                          ))}
                        </Select>
                      </FormItem>
                    </>
                  )}
                  {currPayeeType === Dr2GroupTypes.COMMITTEE && (
                    <CommitteeAutoSearch
                      name="payeeName"
                      label="Committee Name"
                      required={true}
                      requireSelection={true}
                      onSelectCommittee={this.handleCommitteeSelect}
                    />
                  )}
                  {(currPayeeType === Dr2GroupTypes.COMPANYOTHER || currPayeeType === Dr2GroupTypes.TRUST) && (
                    <>
                      <OrganizationAutoSearch
                        name={'company'}
                        label={'Search for a Company'}
                        required={false}
                        committeeId={currComm.id || ''}
                        perorgType={currPayeeType}
                        onSelect={this.getOrganization}
                        onChange={this.handlePayeeChange}
                      />
                      <AddressForm allDisabled={autoFillPerorg}
                        name={{ name: 'payeeName', label: 'Company' }}
                        address1={{ name: 'addressLine1' }}
                        address2={{ name: 'addressLine2' }}
                        city={{ name: 'city' }}
                        state={{ name: 'state' }}
                        zip={{ name: 'zip' }}
                        phone={{ name: 'phoneNumber', required: false }}
                      />
                    </>
                  )}
                  {currPayeeType === Dr2GroupTypes.TRUST &&
                    <>
                      <FormItem name="isLivingOrRevocableTrust" valuePropName="checked">
                        <Checkbox onChange={this.handleRevocableTrustCheckboxChange}>Living or Revocable Trust</Checkbox>
                      </FormItem>
                      <FormItem name="trustee" label="Trustee"
                        rules={[FormValidationUtil.Required('Trustee is required', trusteeTrustorReq)]}>
                        <Input disabled={autoFillPerorg} />
                      </FormItem>
                      <FormItem name="trustor" label="Trustor"
                        rules={[FormValidationUtil.Required('Trustor is required', trusteeTrustorReq)]}>
                        <Input disabled={autoFillPerorg} />
                      </FormItem>
                    </>
                  }
                </Col>
              </Row>
              <Row>
                <Col {...columnSizingProps}>
                  <FormItem name="payeeAutoFillId">
                    <Input hidden={true} disabled={true} aria-hidden={true}/>
                  </FormItem>
                </Col>
              </Row>
              <Space>
                <Button type="primary" disabled={btnDisable} htmlType="submit"> Save Debt </Button>
                <Button type="default" disabled={btnDisable} onClick={this.handleCancel}> Cancel </Button>
                {formType == 'Edit' &&
                  <NotesModal
                    parentId={debtId}
                    parent={NoteEntityParents.DEBT}
                    seqNum={seqNum || 0}
                    auditorsNoteId={debtInfo.auditorsNoteId || ''}
                  />
                }
              </Space>
            </CustomForm >
            {formType == 'Edit' &&
              <SchDDebtHistoryTable
                debtId={debtId}
              />
            }
          </Content>
        }
      </>
    );
  }

  private handlePayeeTypeChange = (payeeType: string) => {
    this.setState({
      currPayeeType: payeeType,
      autoFillPerorg: false,
      trusteeTrustorReq: payeeType == Dr2GroupTypes.TRUST
    });

    this._formRef.current?.setFieldsValue({
      'firstName': '',
      'middleInitial': '',
      'lastName': '',
      'relationshipCode': '',
      'addressLine1': '',
      'addressLine2': '',
      'city': '',
      'state': '',
      'zip': '',
      'phoneNumber': '',
      'trustee': '',
      'trustor': '',
      'person': null,
      'payeeName': null,
      'company': null,
      'isLivingOrRevocableTrust': false,
      'payeeAutoFillId': ''
    });

    if ([Dr2GroupTypes.COMPANYOTHER, Dr2GroupTypes.INDIVIDUAL, Dr2GroupTypes.TRUST].includes(payeeType)) {
      this._formRef.current?.setFieldsValue({ state: 'IA' });
    }
  }

  private handlePayeeChange = (value: string) => {
    this._formRef.current?.setFieldsValue({ payeeAutoFillId: '' });
    this.setState({ autoFillPerorg: !!value });
  }

  private handleCommitteeSelect = (committee: CommitteeInfoDTO | undefined) => {
    this._formRef.current?.setFieldsValue({ payeeAutoFillId: committee?.id });
  }

  private handleExpenseTypeChange = (expenseType: string) => {
    this.setState({ currExpenseType: expenseType });
  }

  private setCompanyFields(form?: BaseScheduleInfoDTO) {
    if (form) {
      this._formRef.current?.setFieldsValue({
        payeeName: form.committeeOrCompanyName,
        addressLine1: form.addressLine1,
        addressLine2: form.addressLine2,
        city: form.city,
        state: form.state,
        zip: form.zip,
        phoneNumber: form.phoneNumber,
        trustee: form.trustee,
        trustor: form.trustor
      });
    }
    else {
      this._formRef.current?.setFieldsValue({
        payeeName: '',
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        zip: '',
        phoneNumber: '',
        trustee: '',
        trustor: '',
        payeeAutoFillId: ''
      });
      this.setState({ autoFillPerorg: false });
    }
  }

  private setIndividualFields(form?: BaseScheduleInfoDTO) {
    if (form) {
      this._formRef.current?.setFieldsValue({
        payeeType: form.groupType,
        firstName: form.firstName,
        middleInitial: form.middleInitial,
        lastName: form.lastName,
        addressLine1: form.addressLine1,
        addressLine2: form.addressLine2,
        city: form.city,
        state: form.state,
        zip: form.zip,
        phoneNumber: form.phoneNumber,
        relationshipCode: form.relationshipCode,
        payeeAutoFillId: ''
      });
    }
    else {
      this._formRef.current?.setFieldsValue({
        payeeType: Dr2GroupTypes.INDIVIDUAL,
        firstName: '',
        middleInitial: '',
        lastName: '',
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        zip: '',
        phoneNumber: '',
        relationshipCode: '',
        payeeAutoFillId: ''
      });
      this.setState({ autoFillPerorg: false });
    }
    this.setState({ currPayeeType: Dr2GroupTypes.INDIVIDUAL });
  }

  private handleRevocableTrustCheckboxChange = (e: any) => {
    this.setState({ trusteeTrustorReq: !e.target.checked });
  }

  private loadLookups = () => {
    const relationPromise = LookupsApiService.getRelationTypes();
    const expTypesPromise = LookupsApiService.getExpenditureTypes();
    Promise.all([relationPromise, expTypesPromise])
      .then(result => {
        const relations = result[0];
        let expenseTypes = result[1];
        if (this.state.formType === 'Edit') {
          // this.state.currExpenseType doesn't exist at this point...
          SchDDebtsApiService.getDebt(this.props.match.params.id || '', Number(this.props.match.params.seqNum))
            .then(result => {
              expenseTypes = expenseTypes.filter(et => et.code != ExpenditureType.REVERSE_TRANSACTION);
              if (result && result.expenseType !== ExpenditureType.MISCELLANEOUS_OR_UNITEMIZED) {
                expenseTypes = expenseTypes.filter(et => et.code != ExpenditureType.MISCELLANEOUS_OR_UNITEMIZED);
                this.setState({ relations: relations, expenseTypes: expenseTypes });
              }
            });
        }
        else {
          expenseTypes = expenseTypes.filter(et => et.code != ExpenditureType.MISCELLANEOUS_OR_UNITEMIZED
            && et.code != ExpenditureType.REVERSE_TRANSACTION);
        }
        this.setState({ relations: relations, expenseTypes: expenseTypes });
      });
    }

  private getPerson = (perorgId: string) => {
    if (perorgId != '') {
      ScheduleApiService.getPerson(perorgId, CurrentUser.Get()?.committeeId || '')
        .then(result => {
          if (result.groupType) {
            this.setIndividualFields(result);
            this._formRef.current?.setFieldsValue({ payeeAutoFillId: perorgId });
          }
        });
    }
    else {
      this.setIndividualFields();
    }
  }

  private getOrganization = (perorgId: string) => {
    if (perorgId != '') {
      ScheduleApiService.getOrganization(perorgId, CurrentUser.Get()?.committeeId || '')
        .then(result => {
          if (result.groupType) {
            this.setCompanyFields(result);
            this._formRef.current?.setFieldsValue({ payeeAutoFillId: perorgId });
          }
        });
    }
    else {
      this.setCompanyFields();
    }
  }

  private getUserCommittee = () => {
    ScheduleApiService.getCommittee(CurrentUser.Get()?.committeeId || '')
      .then(committee => {
        if (committee != null) {
          const hideRelationshipCodes = [
            CommitteeType.CITY_PAC,
            CommitteeType.COUNTY_PAC,
            CommitteeType.IOWA_PAC,
            CommitteeType.SCHOOL_BOARD_OR_OTHER_POLITICAL_SUBDIVISION_PAC,
            CommitteeType.COUNTY_CENTRAL_COMMITTEE,
            CommitteeType.STATE_CENTRAL_COMMITTEE,
            CommitteeType.LOCAL_BALLOT_ISSUE];
          this.setState({ currComm: { ...committee }, hideRelationship: hideRelationshipCodes.includes(committee.committeeTypeCd || '') });
        }
      });
  }

  private getDebt = (debtId: string, seqNum: string) => {
    SchDDebtsApiService.getDebt(debtId, Number(seqNum))
      .then(debt => {
        if (debt) {
          debt.date = moment(debt.date);

          const debtInfo: SchDDebtsFormDTO & { person?: string, company?: string } = debt;
          if (debtInfo.firstName && debtInfo.lastName) {
            if (debtInfo.middleInitial) {
              debtInfo.person = debt.firstName + ' ' + debt.middleInitial + '. ' + debt.lastName;
            }
            else {
              debtInfo.person = debt.firstName + ' ' + debt.lastName;
            }
          }
          else if (debtInfo.payeeType == Dr2GroupTypes.TRUST || debtInfo.payeeType == Dr2GroupTypes.COMPANYOTHER) {
            if (debtInfo.payeeName) {
              debtInfo.company = debtInfo.payeeName;
            }
          }

          this.setState({
            debtInfo: debt,
            currPayeeType: debt.payeeType || '',
            currExpenseType: debt.expenseType || '',
            trusteeTrustorReq: debt.payeeType == Dr2GroupTypes.TRUST && !debt.isLivingOrRevocableTrust,
            autoFillPerorg: debt.payeeType != Dr2GroupTypes.COMMITTEE
          });
          this._formRef.current?.setFieldsValue(this.state.debtInfo);
          this.setState({ loading: false });
        }
        else {
          notification.error({
            message: 'Error while fetching debt',
            description: 'Not found'
          });
          this.setState({ loading: false });
        }
      }).catch(() => {
        notification.error({
          message: 'Error while fetching debt'
        });
        this.setState({ loading: false });
      });
  }

  private handleSave = (values: SchDDebtsFormDTO) => {
    if (values.payeeAutoFillId === '') {
      values.payeeAutoFillId = null;
    }
    this.setState({ btnDisable: true });
    if (this.state.formType === 'New') {
      SchDDebtsApiService.add(values, CurrentUser.Get()?.committeeId || '', CurrentUser.Get()?.dr2Id || '')
        .then(() => {
          notification.success({
            message: 'Saved Successfully',
            description: ''
          });
          HistoryUtil.push(Routes.SCHEDULE_D_DEBTS);
        }).catch(() => {
          notification.error({
            message: 'Your debt failed to save',
            description: ''
          });
          HistoryUtil.push(Routes.SCHEDULE_D_DEBTS);
        });
    }
    else {
      SchDDebtsApiService.edit(
        values,
        CurrentUser.Get()?.committeeId || '',
        CurrentUser.Get()?.dr2Id || '',
        this.props.match.params.id || '',
        Number(this.props.match.params.seqNum)
      )
        .then(() => {
          notification.success({
            message: 'Saved Successfully',
            description: ''
          });
          HistoryUtil.push(Routes.SCHEDULE_D_DEBTS);
        }).catch(() => {
          notification.error({
            message: 'Your debt failed to save',
            description: ''
          });
          HistoryUtil.push(Routes.SCHEDULE_D_DEBTS);
        });
    }
  }

  private handleCancel = () => {
    confirm({
      title: 'Are you sure you want to leave?',
      okText: 'Yes',
      cancelText: 'No',
      onOk: () => {
        HistoryUtil.push(Routes.SCHEDULE_D_DEBTS);
      }
    });
  }

}

export default SchDDebtsForm;
