// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import EntitySelectorDTO from './EntitySelectorDTO';

interface AdminEscheatFormDTO extends EntitySelectorDTO { 
  id: number;
  contributionType: string | null;
  committeeName: string | null;
  firstName: string | null;
  middleInitial: string | null;
  lastName: string | null;
  contributorAutoFillId: string | null | null;
  filedDate: moment.Moment | string;
  amount: number;
  addressLine1: string | null;
  addressLine2: string | null;
  city: string | null;
  state: string | null;
  zip: string | null;
  phoneNumber: string | null;
}
const AdminEscheatFormDTO = {
  create: (initValues?: Partial<AdminEscheatFormDTO> | Record<string, unknown> | null) => {
    return Object.assign(EntitySelectorDTO.create(),
    {
      id: 0,
      contributionType: "",
      committeeName: "",
      firstName: "",
      middleInitial: "",
      lastName: "",
      contributorAutoFillId: null,
      filedDate: new Date(0).toISOString(),
      amount: 0,
      addressLine1: "",
      addressLine2: "",
      city: "",
      state: "",
      zip: "",
      phoneNumber: "",
    },
    initValues);
  }
};

export default AdminEscheatFormDTO;