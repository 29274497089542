import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Space, Typography } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import * as React from 'react';
import Routes from '../../consts/Routes';
import HistoryUtil from '../../utils/HistoryUtil';
import SearchableDatabase from '../shared/SearchableDatabase';

interface PublicSearchProps {
}

interface PublicSearchState {
}

class PublicSearch extends React.Component<PublicSearchProps, PublicSearchState> {
  constructor(props: PublicSearchProps) {
    super(props);
    this.state = {
    };
  }

  render() {
    const title = (
      <Button type="link" onClick={() => HistoryUtil.push(Routes.generate(Routes.PUBLIC_REPORTS))}>
        <Typography.Title level={4}>
          <Space size="large">
            <ArrowLeftOutlined className="public-report-links" /> IECDB State/Local Campaign Disclosure Database
          </Space>
        </Typography.Title>
      </Button>
    );

    return (
      <Content className="content-pad">
        {title}
        <SearchableDatabase title={<></>} />
      </Content>
    );
  }
}

export default PublicSearch;
