import {
  Button,
  Comment,
  Divider,
  FormInstance,
  List,
  notification,
  Space,
  Tooltip
} from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import TextArea from 'antd/lib/input/TextArea';
import moment from 'moment';
import * as React from 'react';
import AuditorNotesApi from '../../api/AuditorNotesApiService';
import Dr2ApiService from '../../api/Dr2ApiService';
import Role from '../../consts/Role';
import NoteEntryDTO from '../../models/NoteEntryDTO';
import CurrentUser from '../../utils/CurrentUser';
import DateUtil from '../../utils/DateUtil';
import FormValidationUtil from '../../utils/FormValidationUtil';
import CustomForm from './CustomForm';

interface NoteEntryListProps {
  parent: string;
  parentId: string;
  seqNum?: number | 0;
  auditorsNoteId?: string | null;
  scheduleCd?: string | '';
  onChange?: (auditorsNoteId: string | null) => void;
  saveFlag?: boolean;
}

interface NoteEntryListState {
  noteEntries: NoteEntryDTO[];
  noteEntry: NoteEntryDTO;
  defaultText: string;
  currentUser: string;
  noteEntryId: number;
  isEditing: boolean;
  loadNotes: boolean;
  auditorsNoteId: string;
  oneNote: boolean;
}

class NoteEntryList extends React.Component<NoteEntryListProps, NoteEntryListState>{
  private _noteForm = React.createRef<FormInstance>();
  constructor(props: NoteEntryListProps) {
    super(props);

    this.state = {
      noteEntries: [],
      noteEntry: NoteEntryDTO.create(),
      defaultText: ' ',
      currentUser: CurrentUser.Get()?.userName || '',
      auditorsNoteId: this.props.auditorsNoteId || '',
      noteEntryId: 0,
      isEditing: false,
      loadNotes: true,
      oneNote: false,
    };
  }

  componentDidMount() {
    this.loadNoteEntries();
  }

  render() {
    const { noteEntries, defaultText, isEditing, currentUser, loadNotes, oneNote } = this.state;
    const { saveFlag } = this.props;
    const buttonTitle = isEditing ? 'Save Note' : 'Add Note';
    const viewOnly = (saveFlag && CurrentUser.Get()?.isInRole(Role.FILER));
    let data: any[] | undefined = [];
    data = noteEntries.map((note) =>
    ({
      key: note.noteEntryId,
      actions:
        (saveFlag &&
          CurrentUser.Get()?.isInRole(Role.IECDB_ADMINISTRATOR)) ?
            [ <span key="edit" onClick={() => this.handleEdit(note.noteEntryId || 0)}>edit</span>,
              <span key="delete" onClick={() => this.handleDelete(note)}>delete</span>
            ] :
            (note?.createdByUserNm == currentUser && note.noteTxt != '') ?
            [<span key="edit" onClick={() => this.handleEdit(note.noteEntryId || 0)}>edit</span>]
            : [],
      author: note.updatedByUserNm ?? note.createdByUserNm,
      content: note.noteTxt != '' ? note.noteTxt : '[deleted note]',
      datetime: (
        <Tooltip title={DateUtil.toTimeLocal(moment.utc(note.updatedOn ?? note.createdOn))} >
          <span>
            {moment(DateUtil.toTimeLocal(moment.utc(note.updatedOn ?? note.createdOn))).fromNow()}
          </span>
        </Tooltip>
      ),
    })
    );
    return (
      <>
        <List
          style={{ maxHeight: 300, overflow: 'auto' }}
          dataSource={data}
          loading={loadNotes}
          renderItem={item => (
            <Comment {...item} />)} />
        {!(viewOnly || oneNote) &&
          <>
            <Divider />
          <CustomForm formRef={this._noteForm}  >
            <label htmlFor="noteTxt" aria-label="note-entry-text-area">
              <FormItem name="noteTxt" rules={!isEditing ? [FormValidationUtil.Required('Cannot add an empty note')] : []}>
                <TextArea rows={6} value={defaultText} allowClear={isEditing} />
              </FormItem>
              </label>
              <Space>
                <Button onClick={this.handleAddNote} type="primary" >{buttonTitle}</Button>
                {isEditing && <Button onClick={this.handleCancel} >Cancel</Button>}
              </Space>
            </CustomForm>
            <Divider />
           </>
          }
      </>
    );
  }

  private handleCancel = () => {
    this.setState({ isEditing: false, noteEntryId: 0, oneNote: this.props.saveFlag ? true : false });
    this._noteForm.current?.resetFields();
  }

  private handleEdit = (noteEntryId: number) => {
    this.setState({ oneNote: false });
    const note = this.state.noteEntries.find(ne => ne.noteEntryId == noteEntryId);
    if (note) {
      
      this.setState({ noteEntryId: noteEntryId, isEditing: true, noteEntry: note }, () => {
        this._noteForm.current?.setFieldsValue({ noteTxt: note?.noteTxt });
      });
    }

  }

  private handleDelete = (noteEntry: NoteEntryDTO) => {
    Dr2ApiService.deleteFlaggedNote(
      noteEntry, this.props.parentId,
      this.props.parent, this.props.seqNum || 0,
      this.state.auditorsNoteId,
      this.props.scheduleCd || '')
      .then((result) => {
        if (result.succeeded) {
          notification.success({ message: 'Successfully deleted note' });
          this.setState({ noteEntries: [], noteEntryId: 0, auditorsNoteId: '' });
        }
      });
  }

  private handleAddNote = () => {
    let noteEntry = NoteEntryDTO.create();
    if (this.state.noteEntryId != 0) {
      const tempEntry = this.state.noteEntries.find(ne => ne.noteEntryId == this.state.noteEntryId) || null;
      noteEntry = NoteEntryDTO.create({
        noteEntryId: tempEntry?.noteEntryId,
        noteId: tempEntry?.noteId,
        noteTxt: this._noteForm.current?.getFieldValue('noteTxt'),
      });
    }
    else {
      noteEntry = NoteEntryDTO.create({
        noteId: this.state.auditorsNoteId || null,
        noteTxt: this._noteForm.current?.getFieldValue('noteTxt'),
      });
    }
    if (this.state.isEditing) {
      this.saveNotes(
        noteEntry,
        this.props.parentId,
        this.props.parent,
        this.props.seqNum || 0,
        this.state.auditorsNoteId || '',
        this.props.scheduleCd || '');
    }
    else {
      this._noteForm.current?.validateFields().then(() => {
        this.saveNotes(
          noteEntry,
          this.props.parentId,
          this.props.parent,
          this.props.seqNum || 0,
          this.state.auditorsNoteId || '',
          this.props.scheduleCd || '');
      });
    }
    this.setState({ isEditing: false });
  }

  private saveNotes = (noteEntry: NoteEntryDTO, parentsId: string, parent: string, seqNum: number, auditorsNoteId: string, scheduleCd: string) => {
    if (this.props.saveFlag) {
      Dr2ApiService.saveFlaggedNotes(noteEntry, parentsId, parent, seqNum, auditorsNoteId, scheduleCd)
        .then((savedNoteEntry) => {
          if (noteEntry) {
            notification.success({ message: 'Notes saved successfully.' });
            this._noteForm.current?.resetFields();
            if (this.state.noteEntryId == 0) {
              this.setState({
                noteEntries: [...this.state.noteEntries, savedNoteEntry],
                auditorsNoteId: savedNoteEntry.noteId || '',
                oneNote: this.props.saveFlag ? true : false
              });
            }
            else {
              const noteEntries = this.state.noteEntries.map(ne => ne.noteEntryId == savedNoteEntry.noteEntryId ?
                { ...ne, ...savedNoteEntry } : ne);
              this.setState({
                noteEntries,
                noteEntryId: 0,
                auditorsNoteId: savedNoteEntry.noteId || '',
                oneNote: this.props.saveFlag ? true : false
              });
            }
            if (this.props.onChange) {
              this.props.onChange(savedNoteEntry.noteId);
            }
          }
        }).catch(() => {
          notification.error({ message: 'Notes failed to save.' });
        });
    }
    else {
      AuditorNotesApi.saveNotes(noteEntry, parentsId, parent, seqNum, auditorsNoteId, scheduleCd)
        .then((savedNoteEntry) => {
          if (noteEntry) {
            notification.success({ message: 'Notes saved successfully.' });
            this._noteForm.current?.resetFields();
            if (this.state.noteEntryId == 0) {
              this.setState({ noteEntries: [...this.state.noteEntries, savedNoteEntry], auditorsNoteId: savedNoteEntry.noteId || '' });
            }
            else {
              const noteEntries = this.state.noteEntries.map(ne => ne.noteEntryId == savedNoteEntry.noteEntryId ?
                { ...ne, ...savedNoteEntry } : ne);
              this.setState({ noteEntries, noteEntryId: 0, auditorsNoteId: savedNoteEntry.noteId || '' });
            }
            if (this.props.onChange) {
              this.props.onChange(savedNoteEntry.noteId);
            }
          }
        }).catch(() => {
          notification.error({ message: 'Notes failed to saved.' });
        });
    }
  }

  private loadNoteEntries = () => {
    if (!this.props.auditorsNoteId) {
      this.setState({ loadNotes: false });
      return;
    }
    this.setState({ loadNotes: true });
    AuditorNotesApi.getNoteEntries(this.props.auditorsNoteId).then((noteEntries) => {
      let oneNote = false;
      if (noteEntries.length == 1 && CurrentUser.Get()?.isInRole(Role.IECDB_ADMINISTRATOR) && this.props.saveFlag) {
        oneNote = true;
      }
      this.setState({ noteEntries, loadNotes: false, oneNote });
    }).catch(() => {
      notification.error({ message: 'Failed to load auditor notes.' });
    });
  }
}

export default NoteEntryList;