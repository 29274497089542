// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import TableRequestDTO from './TableRequestDTO';

interface ReportTableExportDTO { 
  tableRequest: TableRequestDTO | null;
  committeeType: string | null;
  periodMasterId: number;
  firstName: string | null;
  lastName: string | null;
  startDate: moment.Moment | string;
  endDate: moment.Moment | string;
  fileName: string | null;
  districtCommitteeType: number;
  commFilingPeriodCatType: string | null;
  unfiled: boolean;
  export: boolean;
  coveredYear: number;
}
const ReportTableExportDTO = {
  create: (initValues?: Partial<ReportTableExportDTO> | Record<string, unknown> | null) => {
    return Object.assign(
    {
      tableRequest: null,
      committeeType: null,
      periodMasterId: 0,
      firstName: null,
      lastName: null,
      startDate: new Date(0).toISOString(),
      endDate: new Date(0).toISOString(),
      fileName: null,
      districtCommitteeType: 0,
      commFilingPeriodCatType: null,
      unfiled: false,
      export: false,
      coveredYear: 0,
    },
    initValues);
  }
};

export default ReportTableExportDTO;