import {
    Button,
    Layout,
    notification,
    Typography,
    Space,
    Modal
} from 'antd';
import { FormInstance } from 'antd/lib/form/Form';
import { RouteComponentProps } from 'react-router-dom';
import * as React from 'react';
import HistoryUtil from '../../utils/HistoryUtil';
import Routes from '../../consts/Routes';
import FilerTypeSearch from '../shared/FilerTypeSearch';
import FormItem from 'antd/lib/form/FormItem';
import CurrencyInput from '../shared/CurrencyInput';
import FormValidationUtil from '../../utils/FormValidationUtil';
import CustomForm from '../shared/CustomForm';
import CustomDatePicker from '../shared/CustomDatePicker';
import PersonInfoDTO from '../../models/PersonInfoDTO';
import AdminEscheatApiService from '../../api/AdminEscheatApiService';
import AdminEscheatFormDTO from '../../models/AdminEscheatFormDTO';
import moment from 'moment';

const { Content } = Layout;
const { confirm } = Modal;

interface EscheatProps {
    id: string | undefined;
}

interface EscheatState {
    id?: number,
    loading: boolean;
    btnDisable: boolean;
    escheatContr: AdminEscheatFormDTO;
    perorgs: PersonInfoDTO[];
    currContrType: string | null;
}

class Escheat extends React.Component<RouteComponentProps<EscheatProps>, EscheatState>{
    private readonly _formRef = React.createRef<FormInstance>();

    constructor(props: RouteComponentProps<EscheatProps>) {
        super(props);
        this.state = {
            id: props.match.params.id ? parseInt(props.match.params.id) : undefined,
            loading: true,
            btnDisable: false,
            escheatContr: AdminEscheatFormDTO.create({ filedDate: null, state: 'IA' }),
            perorgs: [],
            currContrType: ''
        };
    }

    componentDidMount() {
        this.loadInitialValues();
    }

    render() {
        const { loading, btnDisable, perorgs, currContrType, escheatContr } = this.state;

        const pageTitle = this.state.id ? 'Edit Escheat' : 'Add Escheat';

        return (
            <>
                {!loading && 
                    <Content className="content-pad">
                        <Typography.Title level={4}>{pageTitle}</Typography.Title>
                        <CustomForm formRef={this._formRef} layout="vertical" onFinish={this.submitForm} initialValues={{...escheatContr}}>
                            <FilerTypeSearch
                                label="Committee or Individual"
                                onChange={contributorType => this.handleFilerTypeSearch(contributorType)}
                                formRef={this._formRef}
                                perorgs={perorgs}
                                currContrType={currContrType}
                                allDisabled={false}
                            />
                            <FormItem name="filedDate" label="Filed Date"
                                rules={[FormValidationUtil.RequiredDate('Date is required')]}>
                                <CustomDatePicker onChange={(d) => d?.startOf('day')} />
                            </FormItem>
                            <FormItem name="amount" label="Amount"
                                rules={[
                                    FormValidationUtil.RequiredNumber('Amount of the Escheat is required'),
                                    FormValidationUtil.PositiveNumber('Amount of the Escheat must be positive')
                                ]}>
                                <CurrencyInput min={Number.MIN_SAFE_INTEGER} />
                            </FormItem>
                            <Space>
                                <Button type="primary" disabled={btnDisable} htmlType="submit" > Save Escheat </Button>
                                <Button type="default" disabled={btnDisable} onClick={this.cancelForm}> Cancel </Button>
                            </Space>
                        </CustomForm>
                    </Content>
                }
            </>
        );
    }

    private handleFilerTypeSearch = (contributorType: string | null) => {
        const escheat = this.state.escheatContr;
        escheat.contributionType = contributorType;

        this.setState({
            currContrType: contributorType,
            escheatContr: escheat
        });
    }

    private submitForm = (values: any) => {
        this.setState({ loading: true });
        return AdminEscheatApiService.saveEscheat({...this.state.escheatContr, ...values})
            .then(() => {
                this.setState({ loading: false });
                HistoryUtil.push(Routes.MANAGE_ESCHEATS);
                notification.success({
                    message: 'Your Escheat submitted successfully.',
                });
            })
            .catch(error => {
                HistoryUtil.push(Routes.MANAGE_ESCHEATS);
                notification.error({ message: error.message });
                this.setState({ loading: false });
            });
    }

    private loadInitialValues = () => {
        this.setState({ loading: true });

        const escheatIndListPromise = AdminEscheatApiService.getEscheatIndividualList();
        const escheatContrPromise = this.state.id ? AdminEscheatApiService.getEscheat(this.state.id) : null;

        Promise.all([escheatIndListPromise, escheatContrPromise]).then(response => {
            const [escheatIndList, escheatContr] = response;

            if (escheatContr != null) {
                const escheatInfo: AdminEscheatFormDTO & { person?: string } = escheatContr;
                if (escheatInfo.firstName && escheatInfo.lastName) {
                    if (escheatInfo.middleInitial) {
                        escheatInfo.person = escheatContr.firstName + ' ' + escheatContr.middleInitial + '. ' + escheatContr.lastName;
                    }
                    else {
                        escheatInfo.person = escheatContr.firstName + ' ' + escheatContr.lastName;
                    }
                }

                escheatContr.filedDate = moment(escheatContr.filedDate);

                this.setState({
                    escheatContr,
                    currContrType: escheatContr.contributionType
                });
            }

            this.setState({
                perorgs: escheatIndList
            });
        }).catch((error) => {
            notification.error({ message: error.message });
        }).finally(() => {
            this.setState({ loading: false });
        });
    }

    private cancelForm = () => {
        confirm({
            title: 'Are you sure you want to leave?',
            okText: 'Yes',
            cancelText: 'No',
            onOk: () => {
                HistoryUtil.push(Routes.MANAGE_ESCHEATS);
            }
        });
    }
}
export default Escheat;