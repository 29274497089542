// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';

interface AdminDR1ApproveDTO { 
  committeeId: string | null;
  committeeName: string | null;
  committeeTypeNm: string | null;
  filerTypeNm: string | null;
  committeeCategory: string | null;
  committeeCode: string | null;
  committeeStatusTypeNm: string | null;
  userId: string | null;
  userStatus: string | null;
}
const AdminDR1ApproveDTO = {
  create: (initValues?: Partial<AdminDR1ApproveDTO> | Record<string, unknown> | null) => {
    return Object.assign(
    {
      committeeId: "00000000-0000-0000-0000-000000000000",
      committeeName: null,
      committeeTypeNm: null,
      filerTypeNm: null,
      committeeCategory: null,
      committeeCode: null,
      committeeStatusTypeNm: null,
      userId: null,
      userStatus: null,
    },
    initValues);
  }
};

export default AdminDR1ApproveDTO;