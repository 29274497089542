// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import DesignatedPositionDTO from './DesignatedPositionDTO';
import PoliticalPositionsDTO from './PoliticalPositionsDTO';
import OtherBusinessDTO from './OtherBusinessDTO';
import SourcesDTO from './SourcesDTO';
import PfdTrustDTO from './PfdTrustDTO';
import PfdRetirementDTO from './PfdRetirementDTO';
import PfdCommissionsDTO from './PfdCommissionsDTO';

interface PfdDTO { 
  filingId: number | null;
  statusId: string | null;
  designatedPosition: DesignatedPositionDTO | null;
  politicalPositions: PoliticalPositionsDTO[] | null;
  otherBusinesses: OtherBusinessDTO[] | null;
  securities: SourcesDTO[] | null;
  financialInstitutions: SourcesDTO[] | null;
  trusts: PfdTrustDTO[] | null;
  realEstate: SourcesDTO[] | null;
  retirements: PfdRetirementDTO[] | null;
  otherSources: SourcesDTO[] | null;
  commissions: PfdCommissionsDTO[] | null;
  filedDate: moment.Moment | string | null;
  postmarkDate: moment.Moment | string | null;
  amendedDate: moment.Moment | string | null;
  filedManually: boolean | null;
}
const PfdDTO = {
  create: (initValues?: Partial<PfdDTO> | Record<string, unknown> | null) => {
    return Object.assign(
    {
      filingId: null,
      statusId: null,
      designatedPosition: null,
      politicalPositions: [],
      otherBusinesses: [],
      securities: [],
      financialInstitutions: [],
      trusts: [],
      realEstate: [],
      retirements: [],
      otherSources: [],
      commissions: [],
      filedDate: null,
      postmarkDate: null,
      amendedDate: null,
      filedManually: null,
    },
    initValues);
  }
};

export default PfdDTO;