import { ColumnType, FilterType } from '../components/shared/DataTable/DataTable';

interface TableColumnState {
  columnProp: string;
  columnType?: ColumnType;
  filterType?: FilterType;
  initialSearchValue: string | boolean | null;
  filterVisible: boolean | undefined;
  searchText: string | null;
  filtered: boolean | undefined;
  // eslint-disable-next-line @typescript-eslint/ban-types
  renderDataTransform: Function | undefined | null;
}

const TableColumnState = {
  create: (initValues?: Partial<TableColumnState> | Record<string, unknown> | null) => {
    return Object.assign(
      {
        columnProp: '',
        initialSearchValue: null,
        filterVisible: false,
        searchText: null,
        filtered: false,
        renderDataTransform: null,
      },
      initValues
    );
  },
};

export default TableColumnState;
