// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Constants.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
export default class TransactionOptions {
    public static readonly TO_A_COMMITTEE: number = 1;
    public static readonly FROM_A_COMMITTEE: number = 2;
    public static readonly FROM_AN_INDIVIDUAL: number = 3;
    public static readonly FROM_AN_ORGANIZATION: number = 4;
    public static readonly TO_AN_INDIVIDUAL: number = 5;
    public static readonly TO_AN_ORGANIZATION: number = 6;
}
