// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
import BaseApi from './BaseApi';
import ResultDTO from '../models/ResultDTO';
import DissolutionInfoDTO from '../models/DissolutionInfoDTO';

export class DissolutionApiService extends BaseApi {

  // post: api/dissolution/dissolve?committeeId=${encodeURIComponent(committeeId)}&dissolveDate=${encodeURIComponent(String(dissolveDate))}
  public dissolveDR3(committeeId: string, dissolveDate: string): Promise<ResultDTO> {
    let url = `api/dissolution/dissolve`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (committeeId != null) {
      url += `${prefix}committeeId=${encodeURIComponent(committeeId)}`;
      prefix = '&';
    }
    if (dissolveDate != null) {
      url += `${prefix}dissolveDate=${encodeURIComponent(dissolveDate)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, ResultDTO>(null, 'post', url, true, false);
  }

  // get: api/dissolution?committeId=${encodeURIComponent(committeId)}
  public getDissolutionInfo(committeId: string): Promise<DissolutionInfoDTO> {
    let url = `api/dissolution`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (committeId != null) {
      url += `${prefix}committeId=${encodeURIComponent(committeId)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, DissolutionInfoDTO>(null, 'get', url, true, false);
  }

  // get: api/dissolution/check?committeId=${encodeURIComponent(committeId)}&deleteDR2Id=${encodeURIComponent(deleteDR2Id)}
  public checkCommDissolution(committeId: string, deleteDR2Id: string | null): Promise<ResultDTO> {
    let url = `api/dissolution/check`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (committeId != null) {
      url += `${prefix}committeId=${encodeURIComponent(committeId)}`;
      prefix = '&';
    }
    if (deleteDR2Id != null) {
      url += `${prefix}deleteDR2Id=${encodeURIComponent(deleteDR2Id)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, ResultDTO>(null, 'get', url, true, false);
  }

  // post: api/dissolution/unCheckDissolve?committeeId=${encodeURIComponent(committeeId)}&dissolveDate=${encodeURIComponent(dissolveDate)}
  public dissolveWithNoCommCheck(committeeId: string, dissolveDate: string): Promise<ResultDTO> {
    let url = `api/dissolution/unCheckDissolve`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (committeeId != null) {
      url += `${prefix}committeeId=${encodeURIComponent(committeeId)}`;
      prefix = '&';
    }
    if (dissolveDate != null) {
      url += `${prefix}dissolveDate=${encodeURIComponent(dissolveDate)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, ResultDTO>(null, 'post', url, true, false);
  }
}
var service = new DissolutionApiService();
export default service;
