import * as React from 'react';
import { Link } from 'react-router-dom';

class NotFoundComponent extends React.Component<unknown, unknown> {
  constructor(props: unknown) {
    super(props);
  }

  render() {
    return (
      <div className="content-pad">
        <h2>404 - Page not Found</h2>
        <p>We are sorry but the page you are looking for does not exist.</p>
        <Link to="/">Go to Homepage</Link>
      </div>
    );
  }
}

export default NotFoundComponent;
