// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import EntitySelectorDTO from './EntitySelectorDTO';

interface PersonInfoDTO extends EntitySelectorDTO { 
  id: string | null;
  firstName: string | null;
  middleInitial: string | null;
  lastName: string | null;
  title: string | null;
  addressLine1: string | null;
  addressLine2: string | null;
  city: string | null;
  state: string | null;
  zipCode: string | null;
  email: string | null;
  phoneNumber: string | null;
  registeredWithFEC: boolean;
}
const PersonInfoDTO = {
  create: (initValues?: Partial<PersonInfoDTO> | Record<string, unknown> | null) => {
    return Object.assign(EntitySelectorDTO.create(),
    {
      id: "00000000-0000-0000-0000-000000000000",
      firstName: null,
      middleInitial: null,
      lastName: null,
      title: null,
      addressLine1: null,
      addressLine2: null,
      city: null,
      state: null,
      zipCode: null,
      email: null,
      phoneNumber: null,
      registeredWithFEC: false,
    },
    initValues);
  }
};

export default PersonInfoDTO;