import { Layout, Modal, notification } from 'antd';
import * as React from 'react';
import DataTableColumnUtil from '../shared/DataTable/DataTableColumnUtil';
import DataTable, { DataTableColumnProps } from '../shared/DataTable/DataTable';
import DataTableButtonUtil from '../shared/DataTable/DataTableButtonUtil';
import CurrentUser from '../../utils/CurrentUser';
import FilingPeriodDTO from '../../models/FilingPeriodDTO';
import Dr2ApiService from '../../api/Dr2ApiService';
import ElectionYear from '../../consts/ElectionYear';
import ReportType from '../../consts/ReportType';
import DueDateDTO from '../../models/DueDateDTO';
import HistoryUtil from '../../utils/HistoryUtil';
import Routes from '../../consts/Routes';
import FilingPeriodHistoryApiService from '../../api/FilingPeriodHistoryApiService';
import { Moment } from 'moment';
import moment from 'moment';

const { Content } = Layout;
const { confirm } = Modal;

interface NewFilingPeriodTableProps {
  selectFiling?: boolean;
  deleteFiling?: boolean;
  CanSelectFiling?: boolean;
  onChange?: (filingPeriod: FilingPeriodDTO) => void;
  onDissolve?: () => void;
  committeeId?: string;
  adminShowAll?: boolean;
  electionType?: string | null;
  prevEndDt?: string | Moment | null;
  prevBeginDt?: string | Moment | null;
  dr2Id?: string;
  routeToSummary?: boolean;
  loadParent?: (value: boolean) => void;
}

interface NewFilingPeriodTableState {
  tableColumns: DataTableColumnProps<FilingPeriodDTO>[];
  committeeId: string;
  filingPeriods: FilingPeriodDTO[];
  newFilingPeriods: FilingPeriodDTO[];
  loading: boolean;
  dr2Id: string;
  contributions: number;
  commType: string | null | undefined;
  nextDesc: string;
}

class NewFilingPeriodTable extends React.Component<NewFilingPeriodTableProps, NewFilingPeriodTableState> {
  private dataTable: DataTable<NewFilingPeriodTable> | undefined;

  constructor(props: NewFilingPeriodTableProps) {
    super(props);

    this.state = {
      tableColumns: this.getTableColumns(),
      committeeId: (this.props.committeeId ?? CurrentUser.Get()?.committeeId) || '',
      filingPeriods: [],
      newFilingPeriods: [],
      loading: false,
      dr2Id: this.props.dr2Id || '',
      contributions: 0,
      commType: null,
      nextDesc: ''
    };
  }

  componentDidMount() {
    this.getFilingPeriods(true);
    this.getNextFilingPeriodDescription();
    window.scrollTo(0, 0);
  }

  refreshTable = () => {
    this.getFilingPeriods(false);
  }

  render() {
    const actionButtons = [];
    const { selectFiling, deleteFiling, CanSelectFiling } = this.props;
    const { filingPeriods, loading } = this.state;
    const newFilingPeriods = this.modifyData(filingPeriods);
    actionButtons.push(DataTableButtonUtil.Reset());
    if ((selectFiling || deleteFiling) && !CanSelectFiling) {
      actionButtons.push(DataTableButtonUtil.Primary(
        'Dissolve Committee',
        () => {
          if (this.props.onDissolve) {
            this.props.onDissolve();
          }
        }
      ));
    }

    return (
      <Content>
        {newFilingPeriods != null && (
          <div className="content-pad">
            <DataTable
              buttonBar={actionButtons}
              columns={this.state.tableColumns}
              data={newFilingPeriods}
              globalSearch={true}
              ref={(element: any) => (this.dataTable = element)}
              serverSide={false}
              styleOptions={{ compact: true, alternatingRowHighlight: true }}
              tableProps={{
                rowKey: 'periodMasterId',
                sortDirections: ['ascend', 'descend'],
                locale: { emptyText: 'No Upcoming Filing Periods, Please Contact IECDB Staff' },
                loading: loading
              }}
              title={this.modifyTitle()} />
          </div>
        )}
      </Content>
    );
  }

  private modifyData = (filingPeriods: FilingPeriodDTO[]) => {
    switch (this.props.electionType) {
      case ElectionYear.ELECTION:
        return filingPeriods.filter(item => item.electionType == ElectionYear.ELECTION && item.reportType != ReportType.SUPPLEMENTAL &&
          (item.beginDate != null && this.props.prevEndDt != null && item.endDate != null) ? (
          (item.beginDate.valueOf() == this.props.prevBeginDt?.valueOf() && item.endDate > this.props.prevEndDt)
          || item.beginDate > this.props.prevEndDt) : '');
      case ReportType.SUPPLEMENTAL:
        return filingPeriods.filter(item => item.reportType == ReportType.SUPPLEMENTAL &&
          (item.beginDate != null && this.props.prevEndDt != null) ? item.beginDate > this.props.prevEndDt : '');
      case ElectionYear.SPECIAL:
        return filingPeriods.filter(item => item.electionType == ElectionYear.SPECIAL &&
          (item.beginDate != null && this.props.prevEndDt != null) ? item.beginDate > this.props.prevEndDt : '');
      case ElectionYear.NONELECTION || ElectionYear.NA:
        return filingPeriods.filter(item => (item.electionType == ElectionYear.NONELECTION || item.electionType == ElectionYear.NA) &&
          (item.beginDate != null && this.props.prevEndDt != null) ? item.beginDate > this.props.prevEndDt : '');
      case '':
        return filingPeriods.filter(item => (item.beginDate != null && this.props.prevEndDt != null) ?
          (item.beginDate < this.props.prevEndDt && moment(item.beginDate).year() == moment(this.props.prevEndDt).year()) : '');
    }
    return filingPeriods;
  }

  private modifyTitle = () => {
    switch (this.props.electionType) {
      case ElectionYear.ELECTION:
        return 'Upcoming Election Periods';
      case ReportType.SUPPLEMENTAL:
        return 'Upcoming Supplemental Periods';
      case ElectionYear.SPECIAL:
        return 'Upcoming Special Election Periods';
      case ElectionYear.NONELECTION || ElectionYear.NA:
        return 'Upcoming Non-Election Periods';
      case '':
        return 'Previous Periods';
    }
    return 'Upcoming Filing Periods';
  }

  private getTableColumns() {
    const tableColumns: DataTableColumnProps<FilingPeriodDTO>[] = [
      DataTableColumnUtil.Text('Period Description', 'description'),
      DataTableColumnUtil.Date('Begin Date', 'beginDate', null, { defaultSortOrder: 'ascend' }),
      DataTableColumnUtil.Date('End Date', 'endDate'),
      DataTableColumnUtil.Date('Due Date', 'dueDate'),
      DataTableColumnUtil.DropdownMulti('Election Year', 'electionType',
        [
          { text: ElectionYear.ELECTION, value: ElectionYear.ELECTION },
          { text: ElectionYear.NONELECTION, value: ElectionYear.NONELECTION },
          { text: ElectionYear.SPECIAL, value: ElectionYear.SPECIAL },
          { text: ElectionYear.NA, value: ElectionYear.NA }
        ]
      ),
      DataTableColumnUtil.DropdownMulti('Report Type', 'reportType',
        [
          { text: ReportType.STANDARD, value: ReportType.STANDARD },
          { text: ReportType.SPECIALELECTION, value: ReportType.SPECIALELECTION },
          { text: ReportType.SUPPLEMENTAL, value: ReportType.SUPPLEMENTAL }
        ])
    ];
    if (this.props.selectFiling || this.props.deleteFiling) {
      tableColumns.push(
        DataTableColumnUtil.Buttons('periodMasterId',
          [
            {
              text: 'Select filing',
              onClick: (rowData) => {
                this.selectFilingPeriod(rowData);
              }
            }
          ],
          150));
    }
    else {
      tableColumns.push(
        DataTableColumnUtil.Buttons('periodMasterId',
          [
            {
              text: 'Create filing',
              onClick: (rowData) => {
                this.saveNewFilingPeriod(rowData);
              }
            }
          ],
          150));
    }
    return tableColumns;
  }

  private getFilingPeriods = (showMessage: boolean) => {
    this.setState({ loading: true });
    Dr2ApiService.getNextUpcomingFilingPeriods(
      CurrentUser.Get()?.committeeId || this.state.committeeId || '',
      !!this.props.CanSelectFiling,
      !!this.props.adminShowAll,
      this.state.dr2Id || null
    )
      .then(filingPeriods => {
        this.setState({ filingPeriods, loading: false });
        this.dataTable?.refresh();

        if (showMessage && (this.props.electionType == ElectionYear.ELECTION || this.props.electionType == null)) {
          Dr2ApiService.checkSWGAElection(CurrentUser.Get()?.committeeId || this.state.committeeId)
            .then(result => {
              if (result) {
                confirm({
                  closable: false,
                  cancelButtonProps: { hidden: true },
                  okText: 'I understand',
                  onOk: () => { this.suppCriteriaCheck(); },
                  title:
                    <>
                      Iowa Code 68A.402(b)
                    </>,
                  content:
                    <>
                      A candidate&apos;s committee of a candidate for statewide office or the general assembly shall file
                      a supplementary report in a year in which a primary, general, or special election for that office is held.
                      The supplementary reports shall be filed if contributions are received after the close of the period
                      covered by the last report filed prior to that primary, general, or special election if any of the following applies:
                      <br /><br />
                      &emsp;(a) The committee of a candidate for governor received ten thousand dollars or more.<br />
                      &emsp;(b) The committee of a candidate for any other statewide office received five thousand dollars or more.<br />
                      &emsp;(c) The committee of a candidate for the general assembly received one thousand dollars or more.<br />
                      <br />
                      The amount of any contribution causing a supplementary report under this paragraph &quot;b&quot; shall include the estimated
                      fair market value of any in-kind contribution. The report shall be filed by the Friday
                      immediately preceding the election and be current through the Tuesday immediately preceding the election.
                      <br /><br />
                      If you have any questions please contact IECDB&apos;s staff at (515) 281-4028 or ethicsboard@iowa.gov before you continue.
                    </>,
                  width: '80%'
                });
              }
            });
        }
      })
      .catch(error => {
        notification.error({
          message: error.message
        });
      });
  }

  private suppCriteriaCheck = () => {
    const commType = CurrentUser.Get()?.committeeTypeName;
    Dr2ApiService.getDr2Summary(this.state.dr2Id).then(result => {
      this.setState({ contributions: result.cashContributions + result.inKindContributions + result.loansReceived });
      Dr2ApiService.checkSupplemental(this.state.dr2Id, this.state.contributions, commType || '').then(result => {
        if (!result) {
          confirm({
            closable: false,
            cancelButtonProps: { hidden: true },
            okText: this.state.nextDesc != null ? 'Confirm' : 'Ok',
            onOk: () => { this.transferFilingPeriod(); },
            title:
              <>
                NOTICE
              </>,
            content:
              <>
                You do not meet the reporting threshold for this supplemental report.
                {this.state.nextDesc &&
                  <>By confirming, all entries will be copied to the {this.state.nextDesc} report.</>
                }
              </>,
            width: '60%'
          });
        }
      });
    });
  }

  private transferFilingPeriod = () => {
    this.setState({ loading: true });
    if (this.props.loadParent) {
      this.props.loadParent(true);
    }

    Dr2ApiService.getReportForSuppFail(CurrentUser.Get()?.committeeId || this.state.committeeId || '', this.state.dr2Id).then(result => {
      if (result != null && this.state.nextDesc != null) {
        this.handleSave(result);
      }
      else {
        this.setState({ loading: false });
        if (this.props.loadParent) {
          this.props.loadParent(false);
        }

        if (this.props.routeToSummary) {
          HistoryUtil.push(Routes.generate(Routes.SCHEDULE_SUMMARY));
        }
      }
    });
  }

  private handleSave = (values: DueDateDTO) => {
    Dr2ApiService.saveAdjustEntirePeriod(
      values,
      CurrentUser.Get()?.committeeId || '',
      CurrentUser.Get()?.dr2Id || '',
      values.periodMasterId || -1)
      .then(result => {
        if (!result.succeeded) {
          notification.error({
            message: result.errors[0].toString()
          });
        }
        this.deleteFilingPeriod(CurrentUser.Get()?.dr2Id || '', CurrentUser.Get()?.committeeId || '');
      }).catch(() => {
        notification.error({
          message: 'Failed to adjust period'
        });
        HistoryUtil.push(Routes.generate(Routes.SCHEDULE_SUMMARY));
      });
  }

  private deleteFilingPeriod = (dr2Id: string, committeeId: string) => {
    this.setState({ loading: true });
    FilingPeriodHistoryApiService.deleteFph(dr2Id, committeeId)
      .then(() => {
        Dr2ApiService.getLastFiledDr2Id(CurrentUser.Get()?.committeeId || '')
          .then((dr2Id) => {
            CurrentUser.ChangeDr2(dr2Id);
            notification.success({ message: 'Filing was transferred successfully.' });
            HistoryUtil.push(Routes.generate(Routes.SCHEDULE_SUMMARY));
          });
        this.setState({ loading: false });
      }).catch(() => {
        notification.error({ message: 'Failed to transfer filing.' });
      });
  }

  private selectFilingPeriod = (rowData: FilingPeriodDTO) => {
    if (this.props.onChange) {
      this.props.onChange(rowData);
    }
  }

  private saveNewFilingPeriod = (rowData: FilingPeriodDTO) => {
    this.setState({ loading: true });
    Dr2ApiService.saveNewFilingPeriod(rowData.periodMasterId, this.state.committeeId, this.state.dr2Id || '')
      .then((result) => {
        if (result.succeeded) {
          notification.success({
            message: 'Committee filing created successfully.'
          });
          if (this.dataTable) {
            this.dataTable.refresh();
          }
          Dr2ApiService.getLastFiledDr2Id(CurrentUser.Get()?.committeeId || '')
            .then((dr2Id) => {
              CurrentUser.ChangeDr2(dr2Id);
            });
        }
        else {
          notification.error({ message: result.errors[0] });
        }
      }).catch(() => {
        notification.error({
          message: 'Error while creating committee filing'
        });
      });
  }

  private getNextFilingPeriodDescription = () => {
    if (this.state.dr2Id != '') {
      Dr2ApiService.getSuppPeriodDescription(CurrentUser.Get()?.committeeId || this.state.committeeId || '', this.state.dr2Id).then(result => {
        this.setState({ nextDesc: result });
      });
    }
  }
}

export default NewFilingPeriodTable;