// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';

interface SchF1LoansReceivedFormDTO { 
  lenderType: string | null;
  lenderName: string | null;
  firstName: string | null;
  middleInitial: string | null;
  lastName: string | null;
  lenderAutoFillId: string | null;
  date: moment.Moment | string | null;
  amount: number;
  checkNumber: string | null;
  explanation: string | null;
  addressLine1: string | null;
  addressLine2: string | null;
  city: string | null;
  state: string | null;
  zip: string | null;
  phoneNumber: string | null;
  relationshipCode: string | null;
  isFromFundraiser: boolean;
  isCandidatesOwnFunds: boolean;
  isLivingOrRevocableTrust: boolean;
  trustee: string | null;
  trustor: string | null;
  auditorsNoteId: string | null;
}
const SchF1LoansReceivedFormDTO = {
  create: (initValues?: Partial<SchF1LoansReceivedFormDTO> | Record<string, unknown> | null) => {
    return Object.assign(
    {
      lenderType: null,
      lenderName: null,
      firstName: null,
      middleInitial: null,
      lastName: null,
      lenderAutoFillId: null,
      date: null,
      amount: 0,
      checkNumber: null,
      explanation: null,
      addressLine1: null,
      addressLine2: null,
      city: null,
      state: null,
      zip: null,
      phoneNumber: null,
      relationshipCode: null,
      isFromFundraiser: false,
      isCandidatesOwnFunds: false,
      isLivingOrRevocableTrust: false,
      trustee: null,
      trustor: null,
      auditorsNoteId: null,
    },
    initValues);
  }
};

export default SchF1LoansReceivedFormDTO;