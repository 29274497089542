import {
    Button,
  Layout,
  Space,
  Typography,
} from 'antd';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import DataTable, { DataTableColumnProps } from '../../shared/DataTable/DataTable';
import DataTableButtonUtil from '../../shared/DataTable/DataTableButtonUtil';
import OneTimeContributionsDTO from '../../../models/OneTimeContributionsDTO';
import DataTableColumnUtil from '../../shared/DataTable/DataTableColumnUtil';
import PublicReportsApiService from '../../../api/PublicReportsApiService';
import HistoryUtil from '../../../utils/HistoryUtil';
import Routes from '../../../consts/Routes';
import { ArrowLeftOutlined } from '@ant-design/icons';
import DataTableCellRendererUtil from '../../shared/DataTable/DataTableCellRendererUtil';
import moment from 'moment';

const { Content } = Layout;

interface OneTimeContributionsProps {

}

interface OneTimeContributionsState {
  tableColumns: DataTableColumnProps<OneTimeContributionsDTO>[];
}

class OneTimeContributions extends React.Component<RouteComponentProps<OneTimeContributionsProps>, OneTimeContributionsState> {

  constructor(props: RouteComponentProps<OneTimeContributionsProps>) {
    super(props);

    this.state = {
      tableColumns: this.getTableColumns()
    };
  }

  render() {
    const actionButtons = [];
    actionButtons.push(DataTableButtonUtil.Reset());
    return (
      <Content className="content-pad">
        <DataTable
          globalSearch={true}
          serverSide={true}
          buttonBar={actionButtons}
          columns={this.state.tableColumns}
          tableProps={{
            rowKey: 'id',
            sortDirections: ['ascend', 'descend'],
            locale: { emptyText: 'No Reports Currently Match Your Filter' }
          }}
          fetchData={{
            fetch: function (tableRequest) {
              return PublicReportsApiService.getOTC(tableRequest)
                .then(res => {
                  res.results?.forEach(r => {
                    if (r.filedOn && !r.filedOn.toString().includes('T00:00:00')) {
                      r.filedOn = moment.utc(r.filedOn).local();
                    }
                  });
                  return res;
                });
            },
            failureMessage: 'Failed to retrieve public reports'
          }}
          styleOptions={{ compact: true, alternatingRowHighlight: true }}
          title={
            <Button type="link" onClick={() => HistoryUtil.push(Routes.generate(Routes.PUBLIC_REPORTS))}>
              <Typography.Title level={4}>
                <Space size="large">
                  <ArrowLeftOutlined className="public-report-links"/> One-Time Contributions
                </Space>
              </Typography.Title>
            </Button>
          }
        />
      </Content>
    );
  }

  private getTableColumns = (): DataTableColumnProps<OneTimeContributionsDTO>[] => {
    const filedOn = DataTableColumnUtil.Date<OneTimeContributionsDTO>('Filed On', 'filedOn');
    filedOn.render = DataTableCellRendererUtil.DateAndTime;
    return [
      DataTableColumnUtil.Address('Donating Organization', 'organizationName',
        (c) => ({
          name: c.organizationName,
          line1: c.organizationAddress1,
          line2: c.organizationAddress2,
          city: c.organizationCity,
          state: c.organizationState,
          zip: c.organizationZip
        })),
      DataTableColumnUtil.Address('Receiving Committee', 'committeeName',
        (c) => ({
          name: c.committeeName,
          line1: c.committeeAddress1,
          line2: c.committeeAddress2,
          city: c.committeeCity,
          state: c.committeeState,
          zip: c.committeeZip
        })),
      DataTableColumnUtil.Date('Date', 'dateOfContribution', null, { defaultSortOrder: 'descend' }),
      filedOn,
      DataTableColumnUtil.Buttons('fileName',
        [
          { text: 'View', onClick: (rowData) => window.open(rowData.fileUrl || '') },
        ])
    ];
  }
}

export default OneTimeContributions;