import { LoginOutlined } from '@ant-design/icons';
import { Button, Layout, Menu, Typography } from 'antd';
import { Footer } from 'antd/lib/layout/layout';
import * as React from 'react';
import { getActiveLanguage, Language, setActiveLanguage } from 'react-localize-redux';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import UnexpectedError from '../components/UnexpectedError';
import Routes from '../consts/Routes';
import { StateStoreModel } from '../redux/state/StateStoreModel';
import HistoryUtil from '../utils/HistoryUtil';
import HelpButton from './HelpButton';

const { Header, Content } = Layout;


interface PublicLayoutProps {
  availableLanguages: Language[];
  activeLanguage: string;
  setActiveLanguage: (code: string) => void;
}

class PublicLayout extends React.Component<PublicLayoutProps & RouteComponentProps<Record<string, string | undefined>>, unknown> {
  constructor(props: PublicLayoutProps & RouteComponentProps<Record<string, string | undefined>>) {
    super(props);
  }

  render() {
    return (
      <Layout>
        <Header className="public">
          <div className="header-logo">
            <Link to="/">
              <img src={process.env.PUBLIC_URL + '/iecdb-logo.svg'} alt="IECDB.Portal" />
            </Link>
            <Typography.Title level={3} className="header-logo-text">Web Reporting System</Typography.Title>
          </div>
          <Menu mode="horizontal">
            {/* <Menu.Item>
              <LanguageSelect 
                availableLanguages={this.props.availableLanguages} 
                selectedLanguage={this.props.activeLanguage} 
                onSelectionChange={this.props.setActiveLanguage} />
            </Menu.Item> */}
            <Menu.Item>
              <Button type="link" onClick={() => HistoryUtil.push(Routes.HOME_ROUTE)}>
                <LoginOutlined /> Login
              </Button>
            </Menu.Item>
            <HelpButton />
          </Menu>
        </Header>
        <Layout className="page-content">
          <Content id="app-main" className="public">
            <Content className="public-content">
              <UnexpectedError>{this.props.children}</UnexpectedError>
            </Content>
          </Content>
          <Footer style={{ textAlign: 'center' }}>
            Iowa Ethics Campaign Disclosure Board, Copyright &copy;{new Date().getFullYear()}, All rights reserved
          </Footer>
        </Layout>
      </Layout>
    );
  }
}

function mapDispatchToProps(dispatch: any) {
  return {
    setActiveLanguage: (code: string) => {
      window.localStorage.setItem('LANGUAGE_CHANGE', Date.now().toString());
      window.localStorage.removeItem('LANGUAGE_CHANGE');
      dispatch(setActiveLanguage(code));
    }
  };
}

function mapStateToProps(state: StateStoreModel) {
  return {
    activeLanguage: getActiveLanguage(state.localize).code,
    availableLanguages: state.localize.languages
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PublicLayout);
