// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import EntitySelectorDTO from './EntitySelectorDTO';

interface AdminPeriodListDTO extends EntitySelectorDTO { 
  periodMasterId: number | null;
  filingPeriodId: number | null;
  filingPeriodSeqNbr: number;
  filingPeriodDesc: string | null;
  year: string | null;
  commFilingPeriodCatTypeCd: string | null;
  reportType: string | null;
  electionYearInd: string | null;
  beginDateDesc: string | null;
  endDateDesc: string | null;
  dueDateDesc: string | null;
  filingPeriodHelpNoteId: string | null;
  suppSpecialElectionType: string | null;
  suppSpecialElectionTypeDt: moment.Moment | string | null;
  dueDt: moment.Moment | string | null;
  adjDueDt: moment.Moment | string | null;
  beginDt: moment.Moment | string | null;
  endDt: moment.Moment | string | null;
  dueDateAdjFactor: number | null;
  beginDateAdjFactor: number | null;
  endDateAdjFactor: number | null;
  note: string | null;
}
const AdminPeriodListDTO = {
  create: (initValues?: Partial<AdminPeriodListDTO> | Record<string, unknown> | null) => {
    return Object.assign(EntitySelectorDTO.create(),
    {
      periodMasterId: null,
      filingPeriodId: null,
      filingPeriodSeqNbr: 0,
      filingPeriodDesc: null,
      year: null,
      commFilingPeriodCatTypeCd: null,
      reportType: null,
      electionYearInd: null,
      beginDateDesc: null,
      endDateDesc: null,
      dueDateDesc: null,
      filingPeriodHelpNoteId: null,
      suppSpecialElectionType: null,
      suppSpecialElectionTypeDt: null,
      dueDt: null,
      adjDueDt: null,
      beginDt: null,
      endDt: null,
      dueDateAdjFactor: null,
      beginDateAdjFactor: null,
      endDateAdjFactor: null,
      note: null,
    },
    initValues);
  }
};

export default AdminPeriodListDTO;