// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
import BaseApi from './BaseApi';
import TableRequestDTO from '../models/TableRequestDTO';
import AdminOtcPenaltyDTO from '../models/AdminOtcPenaltyDTO';
import TableResponseDTO from '../models/TableResponseDTO';
import AdminOtcListDTO from '../models/AdminOtcListDTO';
import OtcDTO from '../models/OtcDTO';
import ResultDTO from '../models/ResultDTO';

export class AdminOtcApiService extends BaseApi {

  // post: api/admin/otc?filingStatusType=${encodeURIComponent(filingStatusType)}
  public getOtcList(tableRequest: TableRequestDTO, filingStatusType: string): Promise<TableResponseDTO<AdminOtcListDTO>> {
    let url = `api/admin/otc`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (filingStatusType != null) {
      url += `${prefix}filingStatusType=${encodeURIComponent(filingStatusType)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, TableResponseDTO<AdminOtcListDTO>>(tableRequest, 'post', url, true, false);
  }

  // delete: api/admin/otc/${encodeURIComponent(contributionId)}
  public deleteOtc(contributionId: string): Promise<void> {
    let url = `api/admin/otc/${encodeURIComponent(contributionId)}`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, void>(null, 'delete', url, true, false);
  }

  // get: api/admin/otc/${encodeURIComponent(contributionId)}
  public getOtcContribution(contributionId: string): Promise<OtcDTO> {
    let url = `api/admin/otc/${encodeURIComponent(contributionId)}`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, OtcDTO>(null, 'get', url, true, false);
  }

  // get: api/admin/otc/${encodeURIComponent(contributionId)}/penalty
  public getOtcPenalty(contributionId: string): Promise<AdminOtcPenaltyDTO> {
    let url = `api/admin/otc/${encodeURIComponent(contributionId)}/penalty`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, AdminOtcPenaltyDTO>(null, 'get', url, true, false);
  }

  // post: api/admin/otc/${encodeURIComponent(contributionId)}/penalty?sendNotice=${sendNotice}
  public saveOtcPenalty(contributionId: string, penaltyData: AdminOtcPenaltyDTO, sendNotice: boolean): Promise<ResultDTO> {
    let url = `api/admin/otc/${encodeURIComponent(contributionId)}/penalty`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (sendNotice != null) {
      url += `${prefix}sendNotice=${sendNotice}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, ResultDTO>(penaltyData, 'post', url, true, false);
  }

  // post: api/admin/otc/${encodeURIComponent(contributionId)}/status?status=${encodeURIComponent(status)}
  public updateStatus(contributionId: string, status: string): Promise<void> {
    let url = `api/admin/otc/${encodeURIComponent(contributionId)}/status`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (status != null) {
      url += `${prefix}status=${encodeURIComponent(status)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, void>(null, 'post', url, true, false);
  }

  // post: api/admin/otc/penaltyNotice?penaltyId=${encodeURIComponent(penaltyId)}
  public sendOtcLatePenaltyNotice(penaltyId: string): Promise<ResultDTO> {
    let url = `api/admin/otc/penaltyNotice`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (penaltyId != null) {
      url += `${prefix}penaltyId=${encodeURIComponent(penaltyId)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, ResultDTO>(null, 'post', url, true, false);
  }
}
var service = new AdminOtcApiService();
export default service;
