// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';

interface Dr2StatusBarDTO { 
  filingPeriodName: string | null;
  beginningDate: moment.Moment | string | null;
  endingDate: moment.Moment | string | null;
  filingDueDate: moment.Moment | string | null;
  filingStatus: string | null;
  adjustedDueDate: moment.Moment | string | null;
  auditedDate: moment.Moment | string | null;
  filedDate: moment.Moment | string | null;
  lastChanged: moment.Moment | string | null;
  changedBy: string | null;
  updatedOnDate: moment.Moment | string | null;
}
const Dr2StatusBarDTO = {
  create: (initValues?: Partial<Dr2StatusBarDTO> | Record<string, unknown> | null) => {
    return Object.assign(
    {
      filingPeriodName: null,
      beginningDate: null,
      endingDate: null,
      filingDueDate: null,
      filingStatus: null,
      adjustedDueDate: null,
      auditedDate: null,
      filedDate: null,
      lastChanged: null,
      changedBy: null,
      updatedOnDate: null,
    },
    initValues);
  }
};

export default Dr2StatusBarDTO;