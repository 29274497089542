import { Divider, Layout, Modal, notification, Typography } from 'antd';
import * as React from 'react';
import ScheduleApiService from '../../api/ScheduleApiService';
import SchH2PropertySalesApiService from '../../api/SchH2PropertySalesApiService';
import Dr2AmendedIndicators from '../../consts/Dr2AmendedIndicators';
import Dr2AmendedStatuses from '../../consts/Dr2AmendedStatuses';
import NoteEntityParents from '../../consts/NoteEntityParents';
import PropertySaleIndicators from '../../consts/PropertySaleIndicators';
import PropertySaleStatuses from '../../consts/PropertySaleStatuses';
import Role from '../../consts/Role';
import Routes from '../../consts/Routes';
import ScheduleAbbreviationCodes from '../../consts/ScheduleAbbreviationCodes';
import SchH2PropertySalesDTO from '../../models/SchH2PropertySalesDTO';
import CurrentUser from '../../utils/CurrentUser';
import HistoryUtil from '../../utils/HistoryUtil';
import NumberFormatUtil from '../../utils/NumberFormatUtil';
import DataTable, { DataTableColumnProps } from '../shared/DataTable/DataTable';
import DataTableButtonUtil from '../shared/DataTable/DataTableButtonUtil';
import DataTableColumnUtil from '../shared/DataTable/DataTableColumnUtil';
import NotesModal from '../shared/NotesModal';
import Dr2StatusBar from './Dr2StatusBar';

const { Content } = Layout;
const { Text } = Typography;
const { confirm } = Modal;

interface SchH2PropertySalesProps {
}

interface SchH2PropertySalesState {
  tableColumns: DataTableColumnProps<SchH2PropertySalesDTO>[];
  numPropertySales: number;
  totalOfSched: number;
  flagNoteId: string;
  salePropId: string;
  showModal: boolean;
}

class SchH2PropertySales extends React.Component<SchH2PropertySalesProps, SchH2PropertySalesState> {
  constructor(props: SchH2PropertySalesProps) {
    super(props);

    this.state = {
      tableColumns: this.getTableColumns(),
      numPropertySales: 0,
      totalOfSched: 0,
      flagNoteId: '',
      salePropId: '',
      showModal: false,
    };
  }

  private dataTable: DataTable<SchH2PropertySales> | undefined;

  componentDidMount() {
    this.getStatistics();
  }

  render() {
    const { tableColumns, numPropertySales, totalOfSched, flagNoteId, salePropId, showModal } = this.state;
    const actionButtons = [];
    actionButtons.push(DataTableButtonUtil.Reset());
    actionButtons.push(DataTableButtonUtil.Primary(
      'New Sale',
      () => HistoryUtil.push(Routes.generate(Routes.ADD_SCHEDULE_H2_PROPERTY_SALE, {}))
    ));

    const currUser = CurrentUser.Get();

    return (
     <>
      <Content className="content-pad">
        <Dr2StatusBar dr2Id={CurrentUser.Get()?.dr2Id || ''} />
        <Text strong> Number of Payments: {numPropertySales}&emsp;|&emsp;Total of Schedule: {NumberFormatUtil.currency(totalOfSched)}&emsp;|</Text>
        <NotesModal parentId={CurrentUser.Get()?.dr2Id|| ''} parent={NoteEntityParents.SCHEDULE} scheduleCd={ScheduleAbbreviationCodes.SCHEDULEH2} />
        <Divider />
        <DataTable
          buttonBar={actionButtons}
          columns={tableColumns}
          fetchData={{
            fetch: function (tableRequest) {
              return SchH2PropertySalesApiService.getSchH2PropertySalesList(tableRequest, currUser?.dr2Id || '');
            },
            failureMessage: 'Failed to retrieve Property Sales'
          }}
          globalSearch={true}
          ref={(element: any) => (this.dataTable = element)}
          serverSide={true}
          styleOptions={{ compact: true, alternatingRowHighlight: true }}
          tableProps={{
            rowKey: 'propertySaleIdAndSeq',
            sortDirections: ['ascend', 'descend'],
            locale: { emptyText: 'Currently there are no property sales to manage.' }
          }}
          title="Schedule H2 - Property Sales"
          stateSaving={{
              enabled: true,
              tableUniqueKey: 'manageSchH2',
              perSession: true,
          }}
        />
        </Content>
        {showModal &&
          <NotesModal
            parentId={salePropId || ''}
            parent={NoteEntityParents.CAMPAIGNPROPERTYSALE}
            auditorsNoteId={flagNoteId}
            canFlag={true}
            onClose={() => { this.setState({ showModal: false }, () => this.dataTable?.refresh()); }} />
        }
      </>
    );
  }

  private getStatistics = () => {
    ScheduleApiService.getScheduleCount(CurrentUser.Get()?.dr2Id || '', ScheduleAbbreviationCodes.SCHEDULEH2)
      .then(numPropertySales => {
        this.setState({ numPropertySales });
      }).catch(error => {
        notification.error({
          message: 'Error while fetching the number of property sales',
          description: error
        });
      });

    ScheduleApiService.getScheduleTotal(CurrentUser.Get()?.dr2Id || '', ScheduleAbbreviationCodes.SCHEDULEH2)
      .then(totalOfSched => {
        this.setState({ totalOfSched });
      }).catch(error => {
        notification.error({
          message: 'Error while fetching the total of schedule',
          description: error
        });
      });
  }

  private getTableColumns = (): DataTableColumnProps<SchH2PropertySalesDTO>[] => {
    return [
      DataTableColumnUtil.Date('Date', 'propertySaleDate', 100, { defaultSortOrder: 'ascend' }),
      DataTableColumnUtil.Address('Purchaser', 'propertySaleName',
        (c) => ({
          name: c.propertySaleName,
          line1: c.propertySaleAddressLine1,
          line2: c.propertySaleAddressLine2,
          city: c.propertySaleCity,
          state: c.propertySaleState,
          zip: c.propertySaleZip
        })
      ),
      DataTableColumnUtil.Text('Property', 'propertyExplanation'),
      DataTableColumnUtil.Currency('Amount', 'propertyAmount'),
      DataTableColumnUtil.DropdownMulti('Sale or Donation', 'saleOrDonation',
        [
          { text: PropertySaleStatuses.SALE, value: PropertySaleIndicators.SALE },
          { text: PropertySaleStatuses.DONATION, value: PropertySaleIndicators.DONATION }
        ]),
      DataTableColumnUtil.DropdownMulti('Status', 'status',
        [
          { text: Dr2AmendedStatuses.ORIGINAL, value: Dr2AmendedIndicators.ORIGINAL },
          { text: Dr2AmendedStatuses.AMENDED, value: Dr2AmendedIndicators.AMENDED },
          { text: Dr2AmendedStatuses.ADJUSTED, value: Dr2AmendedIndicators.ADJUSTED },
          { text: Dr2AmendedStatuses.DELETED, value: Dr2AmendedIndicators.DELETED }
        ]),
      DataTableColumnUtil.FlagButton('Flagged', 'flaggedNoteId', CurrentUser.Get()?.isInRole(Role.IECDB_ADMINISTRATOR) || false, [
        {
          onClick: (rowData) => this.openFlagModal(rowData.flaggedNoteId || '', rowData.propertySaleId || ''),
          visible: (rowData) => rowData.flaggedNoteId != null
        }
      ]),
      DataTableColumnUtil.Buttons('propertySaleIdAndSeq',
        [
          {
            text: 'Edit',
            onClick: (rowData) =>
              HistoryUtil.push(Routes.generate(
                Routes.EDIT_SCHEDULE_H2_PROPERTY_SALE,
                {
                  id: rowData.propertySaleId || '',
                  seqNum: rowData.seqNumber || ''
                }
              ))
          },
          {
            text: 'Delete',
            onClick: (rowData) => this.confirmDelete(
              rowData.propertySaleId || '',
              rowData.seqNumber || 0,
              rowData.propertySaleName || '',
              rowData.propertyAmount || 0
            )
          }
        ],
        150)
    ];
  }

  private openFlagModal = (flagNoteId: string, salePropId: string) => {
    this.setState({ showModal: true, flagNoteId, salePropId }, () => this.dataTable?.refresh());
  }

  private showTableAndRefresh = () => {
    if (this.dataTable) {
      this.dataTable.refresh();
    }
    this.getStatistics();
  }

  private confirmDelete = (propertySaleId: string, seqNum: number, payeeName: string, amount: number) => {
    confirm({
      title: 'Are you sure you want to delete this property sale?',
      content: 'The following record will be permanently deleted: ' + payeeName + ' - $' + amount,
      onOk: () => {
        this.deleteContribution(propertySaleId, seqNum);
      },
    });
  }

  private deleteContribution = (propertySaleId: string, seqNum: number) => {
    SchH2PropertySalesApiService.delete(propertySaleId, seqNum)
      .then(() => {
        this.showTableAndRefresh();
        notification.success({
          message: 'Deleted Successfully'
        });
      }).catch(() => {
        notification.error({
          message: 'Error while deleting property sale',
          description: '',
        });
      });
  }
}

export default SchH2PropertySales;