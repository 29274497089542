import { LoadingOutlined } from '@ant-design/icons';
import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  Input,
  Layout,
  Row
  } from 'antd';
import { FormInstance } from 'antd/lib/form';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import AccountApiService from '../../api/AccountApiService';
import { StateStoreModel } from '../../redux/state/StateStoreModel';
import CustomForm from '../shared/CustomForm';
import PageTitle from '../shared/PageTitle';

const FormItem = Form.Item;
const Content = Layout.Content;

interface ResendConfirmationEmailState {
  error: any;
  resendSuccess: boolean;
  loading: boolean;
}

interface ResendConfirmationEmailProps { }

class ResendConfirmationEmailPage extends React.Component<
  ResendConfirmationEmailProps & RouteComponentProps<RouteObject>,
  ResendConfirmationEmailState
> {
  private readonly _formRef = React.createRef<FormInstance>();

  constructor(props: ResendConfirmationEmailProps & RouteComponentProps<RouteObject>) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      error: null,
      resendSuccess: false,
      loading: false,
    };
  }

  handleSubmit() {
    this.setState({ loading: true });

    const model = this._formRef ? (this._formRef.current as any).getFieldsValue() : null;

    AccountApiService.resendConfirmEmail(model.email).then(
      () => {
        this.setState({
          loading: false,
          resendSuccess: true,
          error: null,
        });
      },
      (error) => {
        this.setState({
          loading: false,
          error: error,
          resendSuccess: false,
        });
      }
    );
  }

  render() {
    let alert: JSX.Element | null = null;
    const { error, loading, resendSuccess } = this.state;
    if (error !== null) {
      let message = 'An error occurred while trying to confirm email.';
      if (error.message) {
        message = error.message;
      }
      alert = (
        <Alert message="Error" description={message} type="error" showIcon={true} style={{ marginBottom: '12px' }} />
      );
    }
    if (resendSuccess) {
      const message = 'Confirmation email sent.';
      alert = (
        <Alert
          message="Success"
          description={message}
          type="success"
          showIcon={true}
          style={{ marginBottom: '12px' }}
        />
      );
    }

    const emptyCol = { xs: 0, sm: 3, md: 3 };
    const wrapperCol = { xs: 24, sm: 18, md: 18 };
    const wrapperColNoLabel = {};
    Object.keys(wrapperCol).forEach((val) => {
      wrapperColNoLabel[val] = {
        span: wrapperCol[val],
        offset: wrapperCol[val] + emptyCol[val] > 24 ? 0 : emptyCol[val],
      };
    });

    return (
      // type="flex"
      <Content className="content-background-login">
        <Row justify="center" align="middle">
          <Col className="flex-grow" />
          <Col xxl={6} xl={8} lg={10} md={14} sm={18} xs={24}>
            <Card>
              <img src={process.env.PUBLIC_URL + '/iecdb-logo.svg'} alt="IECDB.Portal" className="full-logo" />
              <div
                style={{
                  textAlign: 'center',
                }}
              >
                <PageTitle title="Resend Confirmation Email" />
                <p>Please enter the email associated with your new account.</p>
              </div>
              {alert}
              <CustomForm formRef={this._formRef} layout="vertical" onFinish={this.handleSubmit} requiredMark={false}>
                <FormItem
                  name="email"
                  label="Email Address"
                  rules={[{ required: true, message: 'Email is required' }]}
                  labelCol={wrapperColNoLabel}
                  wrapperCol={wrapperColNoLabel}
                >
                  <Input placeholder="Email" />
                </FormItem>
                <FormItem wrapperCol={wrapperColNoLabel}>
                  <Button type="primary" htmlType="submit" size="large" disabled={loading} shape="round" block>
                    {loading ? (
                      <span>
                        <LoadingOutlined /> Processing...
                      </span>
                    ) : (
                      <span>Send Email</span>
                    )}
                  </Button>
                </FormItem>
              </CustomForm>
            </Card>
          </Col>
          <Col className="flex-grow" />
        </Row>
      </Content>
    );
  }
}

function mapStateToProps(state: StateStoreModel) {
  let userName: string | null = null;
  if (state.UserSession.Value !== null) {
    userName = state.UserSession.Value.userName;
  }
  return {
    loading: state.UserSession.Loading,
    error: state.UserSession.Error,
    userName: userName,
  };
}

export default withRouter(connect(mapStateToProps)(ResendConfirmationEmailPage));
