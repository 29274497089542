import { Col, Layout, Row } from 'antd';
import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import LoginDTO from '../models/LoginDTO';
import CurrentUser from '../utils/CurrentUser';
import TranslationUtil from '../utils/TranslationUtil';
import LoginComponent from './account/LoginComponent';
import NewFilers from './NewFilers';

const Content = Layout.Content;

interface DashBoardState {
  model: LoginDTO;
  isLoggedIn: boolean; //TODO RJ - temporary variable to handle login testing
}

interface DashboardProps {
}

class DashboardPage extends React.Component<DashboardProps & RouteComponentProps<RouteObject>, DashBoardState> {
  constructor(props: DashboardProps & RouteComponentProps<RouteObject>) {
    super(props);

    this.state = {
      model: LoginDTO.create(),
      isLoggedIn: false,
    };
  }

  componentDidMount() {
    document.title = TranslationUtil.translate('appTitle');

    if (CurrentUser.Get()) {
      this.setState({ isLoggedIn: true });
    } else {
      this.setState({ isLoggedIn: false });
    }
  }

  render() {
    return (
      // type="flex"
      <Content>
        {this.state.isLoggedIn ?
          <div className="content-pad"></div> : (
            <Row gutter={20}>
              <Col span={12}>
                <LoginComponent standalone={true} />
              </Col>
              <Col span={12}>
                <NewFilers />
              </Col>
            </Row>
          )}
      </Content>
    );
  }
}

export default withRouter(DashboardPage);
