import { combineReducers, AnyAction, Reducer } from 'redux';
import { StateStoreModel } from '../state/StateStoreModel';
import { UserSession } from './UserSessionReducer';
import { Version } from './VersionReducer';
import { localizeReducer } from 'react-localize-redux';

const rootReducer = combineReducers<StateStoreModel>({
    UserSession,
    Version,
    localize: localizeReducer,
});

export default (function freezeState(state: StateStoreModel, action: AnyAction): StateStoreModel {
  const nextState = rootReducer(state, action);
  return Object.isFrozen(nextState) ? nextState : Object.freeze(nextState);
} as Reducer<StateStoreModel, AnyAction>);
