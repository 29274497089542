// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
import BaseApi from './BaseApi';
import DesignatedPositionDTO from '../models/DesignatedPositionDTO';

export class PfdApiService extends BaseApi {

  // get: api/pfd/${contactId}
  public getPfdContact(contactId: number): Promise<DesignatedPositionDTO> {
    let url = `api/pfd/${contactId}`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, DesignatedPositionDTO>(null, 'get', url, true, false);
  }

  // get: api/pfd/contact?name=${encodeURIComponent(name)}
  public searchContactByName(name: string): Promise<DesignatedPositionDTO[]> {
    let url = `api/pfd/contact`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (name != null) {
      url += `${prefix}name=${encodeURIComponent(name)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, DesignatedPositionDTO[]>(null, 'get', url, true, false);
  }

  // get: api/pfd/contactById?contactId=${contactId}
  public searchContactById(contactId: number): Promise<DesignatedPositionDTO[]> {
    let url = `api/pfd/contactById`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (contactId != null) {
      url += `${prefix}contactId=${contactId}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, DesignatedPositionDTO[]>(null, 'get', url, true, false);
  }

  // get: api/pfd/checkCoveredYear?contactId=${contactId}&coveredYear=${encodeURIComponent(coveredYear)}
  public checkContactHasCoveredYear(contactId: number, coveredYear: string): Promise<boolean> {
    let url = `api/pfd/checkCoveredYear`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (contactId != null) {
      url += `${prefix}contactId=${contactId}`;
      prefix = '&';
    }
    if (coveredYear != null) {
      url += `${prefix}coveredYear=${encodeURIComponent(coveredYear)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, boolean>(null, 'get', url, true, false);
  }
}
var service = new PfdApiService();
export default service;
