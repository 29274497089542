// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Constants.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
export default class ReportTypeDescriptions {
    public static readonly DR1: string = 'Statement of Organization';
    public static readonly DR2: string = 'DR2';
    public static readonly DR3: string = 'Statement of Dissolution';
    public static readonly DRSFA: string = 'DRSFA';
    public static readonly GB: string = 'Gift Bequest';
    public static readonly IEDR3: string = 'Independent Expenditure Statement of Dissolution';
    public static readonly IIE: string = 'Individual Independent Expenditure';
    public static readonly OIE: string = 'Organization Independent Expenditure';
    public static readonly OTC: string = 'One Time Contributions';
    public static readonly PFD: string = 'Personal Financial Disclosure';
    public static readonly VSR: string = 'Verified Statement of Registration';
    public static readonly SCHA: string = 'Schedule A - Contributions';
    public static readonly SCHB: string = 'Schedule B - Expenditures';
    public static readonly SCHD: string = 'Schedule D - Debts';
    public static readonly SCHE: string = 'Schedule E - In Kind Contributions';
    public static readonly SCHF1: string = 'Schedule F1 - Loans Received';
    public static readonly SCHF2: string = 'Schedule F2 - Loan Repayments';
    public static readonly SCHG: string = 'Schedule G - Consultant';
    public static readonly SCHH1: string = 'Schedule H1 - Campaign Property';
    public static readonly SCHH2: string = 'Schedule H2 - Property Sales';
}
