import { Divider} from 'antd';
import * as React from 'react';
import SchH1CampaignPropertyApiService from '../../api/SchH1CampaignPropertyApiService';
import SchH1CampaignPropertyDTO from '../../models/SchH1CampaignPropertyDTO';
import DataTableColumnUtil from '../shared/DataTable/DataTableColumnUtil';
import DataTable, { DataTableColumnProps } from '../shared/DataTable/DataTable';
import Dr2AmendedStatuses from '../../consts/Dr2AmendedStatuses';
import Dr2AmendedIndicators from '../../consts/Dr2AmendedIndicators';
import YesNoStatuses from '../../consts/YesNoStatuses';
import YesNoIndicators from '../../consts/YesNoIndicators';

interface SchH1CampaignPropertyHistoryTableProps {
  campaignPropertyId: string;
}

interface SchH1CampaignPropertyHistoryTableState {
}

class SchH1CampaignPropertyHistoryTable extends React.Component<SchH1CampaignPropertyHistoryTableProps, SchH1CampaignPropertyHistoryTableState> {
  constructor(props: SchH1CampaignPropertyHistoryTableProps) {
    super(props);

    this.state = {

    };
  }

  render() {
    const { campaignPropertyId } = this.props;
    return (
      <>
        <Divider/>
        <DataTable
          columns={this.getTableColumns()}
          fetchData={{
            fetch: function (tableRequest) { return SchH1CampaignPropertyApiService.getCampaignPropertyHistory(tableRequest, campaignPropertyId); },
            failureMessage: 'Failed to retrieve campaign property history'
          }}
          serverSide={true}
          styleOptions={{ compact: true, alternatingRowHighlight: true }}

          tableProps={{
            rowKey: 'campaignPropertyIdAndSeq',
            sortDirections: ['ascend', 'descend'],
            locale: { emptyText: 'Campaign property history not available.' }
          }}
          title="Campaign Property"
        />
      </>
    );
  }

  private getTableColumns = (): DataTableColumnProps<SchH1CampaignPropertyDTO>[] => {
    const tableColumns: DataTableColumnProps<SchH1CampaignPropertyDTO>[] = [
      DataTableColumnUtil.Date('Date', 'acquiredDate', 100),
      DataTableColumnUtil.Text('Property', 'property'),
      DataTableColumnUtil.Currency('Paid Amount', 'paidAmount'),
      DataTableColumnUtil.Currency('Current Value', 'currentValue'),
      DataTableColumnUtil.DropdownMulti('Value Estimated', 'valueEstimatedInd',
        [
          { text: YesNoStatuses.YES, value: YesNoIndicators.YES },
          { text: YesNoStatuses.NO, value: YesNoIndicators.NO }
        ]),
      DataTableColumnUtil.DropdownMulti('Status', 'status',
        [
          { text: Dr2AmendedStatuses.ORIGINAL, value: Dr2AmendedIndicators.ORIGINAL },
          { text: Dr2AmendedStatuses.AMENDED, value: Dr2AmendedIndicators.AMENDED },
          { text: Dr2AmendedStatuses.ADJUSTED, value: Dr2AmendedIndicators.ADJUSTED },
          { text: Dr2AmendedStatuses.DELETED, value: Dr2AmendedIndicators.DELETED }
        ])
    ];
    return tableColumns;
  }
}

export default SchH1CampaignPropertyHistoryTable;
