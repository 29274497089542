import { Button, FormInstance, notification, Select, Row, Col, ColProps, Space, Checkbox } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import * as React from 'react';
import CommitteeTypeDTO from '../../../models/CommitteeTypeDTO';
import DataTable, { DataTableColumnProps } from '../../shared/DataTable/DataTable';
import DataTableColumnUtil from '../../shared/DataTable/DataTableColumnUtil';
import AdminReportApiService from '../../../api/AdminReportApiService';
import DataTableButtonUtil from '../../shared/DataTable/DataTableButtonUtil';
import FormValidationUtil from '../../../utils/FormValidationUtil';
import PeriodReportFormDTO from '../../../models/PeriodReportFormDTO';
import DR2FilingReportDTO from '../../../models/DR2FilingReportDTO';
import PeriodInfoDTO from '../../../models/PeriodInfoDTO';
import CustomForm from '../../shared/CustomForm';
import FilingStatus from '../../../consts/FilingStatus';
import CurrentUser from '../../../utils/CurrentUser';
import Routes from '../../../consts/Routes';
import * as StorageUtil from '../../../storage/StorageUtil';
import TableRequestDTO from '../../../models/TableRequestDTO';
import TableResponseDTO from '../../../models/TableResponseDTO';
import ReportTableExportDTO from '../../../models/ReportTableExportDTO';
import CommFilingPeriodCatTypeCode from '../../../consts/CommFilingPeriodCatTypeCode';
import CommFilingPeriodCatType from '../../../consts/CommFilingPeriodCatType';


const Option = Select.Option;

interface DR2FilingReportProps {
  committeeTypes: CommitteeTypeDTO[];
  yearOptions: number[];
}

interface DR2FilingReportState {
  filingPeriods: PeriodInfoDTO[];
  dr2FilingForm: PeriodReportFormDTO;
  dr2FilingTableColumns: DataTableColumnProps<DR2FilingReportDTO>[];
  dr2FilingData: DR2FilingReportDTO[];
  dr2FilingLoading: boolean;
  dr2FilingExporting: boolean;
  isUnfiled: boolean;
}

class DR2FilingReport extends React.Component<DR2FilingReportProps, DR2FilingReportState> {
  private readonly _dr2FilingFormRef = React.createRef<FormInstance>();
  constructor(props: DR2FilingReportProps) {
    super(props);

    this.state = {
      filingPeriods: [],
      dr2FilingForm:
        (
          PeriodReportFormDTO.create({
            commFilingPeriodCatType: { ...JSON.parse(StorageUtil.getDR2FilingReportColumnFilters()) }.commFilingPeriodCatType || '',
            year: { ...JSON.parse(StorageUtil.getDR2FilingReportColumnFilters()) }.year || new Date().getFullYear().toString(),
            filingPeriod: { ...JSON.parse(StorageUtil.getDR2FilingReportColumnFilters()) }.filingPeriod || '',
          })
        ),
      dr2FilingTableColumns: this.getdr2FilingTableColumns(),
      dr2FilingData: [],
      dr2FilingLoading: false,
      dr2FilingExporting: false,
      isUnfiled: {...JSON.parse(StorageUtil.getDR2FilingReportColumnFilters())}.isUnfiled || false,
    };
  }
  private dr2FilingDataTable: DataTable<DR2FilingReportDTO> | undefined;

  componentDidMount() {
    if (StorageUtil.getDR2FilingReportColumnFilters() != '{}') {
      this.fetchFilingPeriods();
    }
  }

  render() {
    const { filingPeriods, dr2FilingForm, dr2FilingTableColumns, dr2FilingLoading, dr2FilingExporting } = this.state;
    const { yearOptions } = this.props;
    const threeFieldColumnSizingProps: ColProps = { xs: 8, sm: 8, md: 8 };
    const dataTableButtons = [];
    dataTableButtons.push(DataTableButtonUtil.Reset());
    dataTableButtons.push(DataTableButtonUtil.Export(
      (tableRequest: TableRequestDTO) => {
        this.setState({ dr2FilingExporting: true });
     
        const exportDTO = ReportTableExportDTO.create({
          TableRequest: tableRequest,
          CommFilingPeriodCatType: this._dr2FilingFormRef.current?.getFieldValue('commFilingPeriodCatType'),
          PeriodMasterId: this._dr2FilingFormRef.current?.getFieldValue('filingPeriod'),
          Unfiled: this.state.isUnfiled,
          Export: true
        });
        return AdminReportApiService.exportDR2FilingList(exportDTO)
          .finally(() => {
            this.setState({ dr2FilingExporting: false });
          }).catch(() => {
            notification.error({
              message: 'Error while exporting DR2 filing reports'
            });
            this.setState({ dr2FilingExporting: false });
          });
      }, dr2FilingExporting));

    return (
      <>
        <CustomForm
          formRef={this._dr2FilingFormRef}
          layout="vertical"
          initialValues={{ ...dr2FilingForm }}
          onFinish={() => this.dr2FilingDataTable?.refresh()}
          validateTrigger={['onChange', 'onBlur']}>
          <Row gutter={24}>
            <Col {...threeFieldColumnSizingProps} >
              <FormItem name="commFilingPeriodCatType" label="Committee Filing Type Category"
                rules={[FormValidationUtil.RequiredNumber('Committee Filing Type Category is required')]}>
                <Select showSearch={true} optionFilterProp="children" onChange={this.onYearOrCommitteeChangedr2Filing}>
                  <Option value="" disabled={true}>-- Select Committee Category Type --</Option>
                  <Option value={CommFilingPeriodCatTypeCode.CENT}>
                    {CommFilingPeriodCatType.CENT}
                  </Option>
                  <Option value={CommFilingPeriodCatTypeCode.CTY}>
                    {CommFilingPeriodCatType.CTY}
                  </Option>
                  <Option value={CommFilingPeriodCatTypeCode.LOCL}>
                    {CommFilingPeriodCatType.LOCL}
                  </Option>
                  <Option value={CommFilingPeriodCatTypeCode.NA}>
                    {CommFilingPeriodCatType.NA}
                  </Option>
                  <Option value={CommFilingPeriodCatTypeCode.SCPA}>
                    {CommFilingPeriodCatType.SCPA}
                  </Option>
                  <Option value={CommFilingPeriodCatTypeCode.SWGA}>
                    {CommFilingPeriodCatType.SWGA}
                  </Option>
                  <Option value={CommFilingPeriodCatTypeCode.BALLOT}>
                    {CommFilingPeriodCatType.BALLOT}
                  </Option>
                </Select>
              </FormItem>
            </Col>
            <Col {...threeFieldColumnSizingProps}>
              <FormItem name="year" label="Year" rules={[FormValidationUtil.RequiredNumber('Year is required')]}>
                <Select showSearch={true} optionFilterProp="children" onChange={this.onYearOrCommitteeChangedr2Filing}>
                  {yearOptions.map(y => (
                    <Option key={y} value={y}>{y}</Option>
                  ))}
                </Select>
              </FormItem>
            </Col>
            <Col {...threeFieldColumnSizingProps}>
              <FormItem name="filingPeriod" label="Filing Period" rules={[FormValidationUtil.RequiredNumber('Filing Period is required')]}>
                <Select showSearch={true} optionFilterProp="children">
                  <Option key="" value="" disabled={true}>-- Select Filing Period --</Option>
                  {filingPeriods.map(fp => (
                    <Option key={fp.id} value={fp.id}>{fp.name}</Option>
                  ))}
                </Select>
              </FormItem>
            </Col>
            <Col {...threeFieldColumnSizingProps}>
              <FormItem name="isUnfiled" valuePropName="checked">
                Unfiled <Checkbox onChange={(e) => this.setState({ isUnfiled: e.target.checked })} checked={this.state.isUnfiled} />
              </FormItem>
            </Col>
          </Row>
          <Space>
            <Button type="primary" htmlType="submit">Search</Button>
          </Space>
        </CustomForm>
        <br /> <br />
        <DataTable
          ref={(element: any) => (this.dr2FilingDataTable = element)}
          globalSearch={true}
          columns={dr2FilingTableColumns}
          buttonBar={dataTableButtons}
          serverSide={true}
          fetchData={this.fetchDR2FilingReportData}
          tableProps={{
            rowKey: 'committeeName',
            sortDirections: ['ascend', 'descend'],
            locale: {
              emptyText: 'No records were found that match your criteria'
            },
            loading: dr2FilingLoading
          }}
          styleOptions={{ compact: true, alternatingRowHighlight: true }}
          title={'DR2 Filing Report'}
          stateSaving={{
            enabled: true,
            tableUniqueKey: 'dr2FilingReports',
            perSession: true,
          }}
        />
      </>
    );
  }

  private getdr2FilingTableColumns = (): DataTableColumnProps<DR2FilingReportDTO>[] => {
    const { committeeTypes } = this.props;
    return [
      DataTableColumnUtil.Text('Committee Code', 'committeeCode'),
      DataTableColumnUtil.Text('Committee Name', 'committeeName'),
      DataTableColumnUtil.DropdownMulti('Committee Type', 'committeeType', committeeTypes.map(ct => ({ text: ct.name || '', value: ct.name || '' }))),
      DataTableColumnUtil.Text('County', 'county'),
      DataTableColumnUtil.Date('Begin Date', 'beginDate'),
      DataTableColumnUtil.Date('End Date', 'endDate'),
      DataTableColumnUtil.Date('Due Date', 'filingDueDate'),
      DataTableColumnUtil.Date('Audited Date', 'auditedDate'),
      DataTableColumnUtil.Date('Last Updated', 'lastUpdated'),
      DataTableColumnUtil.DropdownMulti('Filing Status', 'filingStatusType', [
        { text: FilingStatus.ADJUSTED, value: FilingStatus.ADJUSTED },
        { text: FilingStatus.AMENDED, value: FilingStatus.AMENDED },
        { text: FilingStatus.AUDITED, value: FilingStatus.AUDITED },
        { text: FilingStatus.FILED, value: FilingStatus.FILED },
        { text: FilingStatus.NOT_FILED, value: FilingStatus.NOT_FILED }
      ]),
      DataTableColumnUtil.Buttons('dr2Id', [
        { text: 'View', onClick: (record) => this.openFilingPeriod(record) }
      ])
    ];
  }

  private openFilingPeriod = (record: DR2FilingReportDTO) => {
    AdminReportApiService.getCommitteeInfo(record.committeeId || '').then((impersonationInfo) => {
      CurrentUser.Release();
      CurrentUser.Impersonate({
        committeeId: impersonationInfo.committeeId,
        committeeName: impersonationInfo.committeeName || null,
        committeeTypeName: impersonationInfo.committeeTypeName || null,
        committeeType: impersonationInfo.committeeTypeCode || null,
        filerTypeId: impersonationInfo.filerTypeId || null,
        dr2Id: record.dr2Id,
        committeeFilingCatType: impersonationInfo.committeeFilingCatType,
        auditing: true
      });
      window.open(Routes.generate(Routes.SCHEDULE_SUMMARY));
    });
  }

  private fetchDR2FilingReportData = (requestState: TableRequestDTO,
    checkEcho: () => boolean,
    callback: (response: TableResponseDTO<DR2FilingReportDTO>) => void
  ) => {
    const filingPeriod = this._dr2FilingFormRef.current?.getFieldValue('filingPeriod');
    const commFilingPeriodCatType = this._dr2FilingFormRef.current?.getFieldValue('commFilingPeriodCatType');

    if (filingPeriod != '' && commFilingPeriodCatType != '') {
      this.setState({ dr2FilingLoading: true });
      return AdminReportApiService.getDR2FilingList(requestState, commFilingPeriodCatType.toString(), filingPeriod, this.state.isUnfiled, false)
       .then((res) => {
         if (!checkEcho()) {
           return;
         }
         callback(res);
          this.setState({ dr2FilingLoading: false });
          const newValues = { ...(this._dr2FilingFormRef.current?.getFieldsValue() as DR2FilingReportDTO), ...{ isUnfiled: this.state.isUnfiled } };
          const filterString = JSON.stringify(newValues);
          StorageUtil.setDR2FilingReportColumnFilters(filterString);
        }).catch(() => {
          notification.error({
            message: 'Error while fetching dr2 filing committees'
          });
          this.setState({ dr2FilingLoading: false });
        });
    }
    this.setState({ dr2FilingLoading: false });
  }


  private onYearOrCommitteeChangedr2Filing = () => {
    if (this._dr2FilingFormRef.current) {
      this._dr2FilingFormRef.current.setFieldsValue({ 'filingPeriod': '' });
      const selectedFilingPeriodCatType = this._dr2FilingFormRef.current.getFieldValue('commFilingPeriodCatType');
      const year = this._dr2FilingFormRef.current.getFieldValue('year');
      AdminReportApiService.getFilingPeriodsFromFilingType(selectedFilingPeriodCatType, year)
        .then(filingPeriods => {
          const sortedFilingPeriods = filingPeriods.sort((a, b) => { return (a?.name || '') < (b?.name || '') ? -1 : 1; });
          this.setState({ filingPeriods: sortedFilingPeriods }, () => this.dr2FilingDataTable?.refresh());
        });
    }
  }

  private fetchFilingPeriods = () => {
    AdminReportApiService.getFilingPeriodsFromFilingType(
      { ...JSON.parse(StorageUtil.getDR2FilingReportColumnFilters()) }.commFilingPeriodCatType || '',
      { ...JSON.parse(StorageUtil.getDR2FilingReportColumnFilters()) }.year || new Date().getFullYear().toString())
      .then(filingPeriods => {
        const sortedFilingPeriods = filingPeriods.sort((a, b) => { return (a?.name || '') < (b?.name || '') ? -1 : 1; });
        this.setState({ filingPeriods: sortedFilingPeriods },() => this.dr2FilingDataTable?.refresh());
      });
  }
}

export default DR2FilingReport;
