import { ColProps, FormProps, FormInstance, Row, Col, Checkbox, Typography, notification } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import * as React from 'react';
import Dr1ApiService from '../../api/Dr1ApiService';
import FilerType from '../../consts/FilerType';
import SecondaryCommitteeInfoDTO from '../../models/SecondaryCommitteeInfoDTO';
import DR1FormTypes from '../../consts/DR1FormTypes';
import AddressFormFields from './AddressFormFields';
import CustomForm from './CustomForm';

interface SecondaryCommitteeInfoProps {
  filerType: FilerType;
  formType: DR1FormTypes;
  disabled: boolean;
  committeeValues: SecondaryCommitteeInfoDTO;
  goToTopOnLoad?: boolean;
}

interface SecondaryCommitteeInfoState {
}

class SecondaryCommitteeInfo extends React.Component<SecondaryCommitteeInfoProps & FormProps, SecondaryCommitteeInfoState> {
  private readonly _formRef = React.createRef<FormInstance>();

  constructor(props: SecondaryCommitteeInfoProps & FormProps) {
    super(props);

    this.state = {
      
    };
  }

  validate = async (): Promise<{ model: SecondaryCommitteeInfoDTO & { registeredWithFEC: boolean }, errorFields: any }> => {
    try {
      const fields = await this._formRef.current?.validateFields();
      return { model: fields, errorFields: null };
    }
    catch (errorInfo) {
      return Promise.reject({ model: errorInfo.values, errorFields: errorInfo.errorFields });
    }
  }

  checkEmptyFields = (): boolean => {
    const model = this._formRef.current?.getFieldsValue();
    if (!model.addressLine1 && !model.addressLine2 && !model.city &&
      !model.email && !model.name && !model.phone &&
      !model.zipCode) {
      return true;
    }
    return false;
  }

  componentDidMount() {
    if (this.props.goToTopOnLoad) {
      window.scrollTo(0, 0);
    }
  }

  private handleCheckboxChange = (e: any) => {
    if (e.target.checked) {
      this.getFECInfo();
    }
    else {
      this.clearFECInfo();
    }
  }

  private getFECInfo = (): void => {
    Dr1ApiService.getFECInfo()
      .then((FECInfo) => {
        if (FECInfo.zipCode) {
          FECInfo.zipCode = FECInfo.zipCode.trim();
        }

        if (FECInfo.phoneNumber) {
          FECInfo.phoneNumber = FECInfo.phoneNumber.trim();
        }

        if (this._formRef.current != null) {
          this._formRef.current.setFieldsValue(FECInfo);
        }
      })
      .catch(() => {
        notification.error({ message: 'Error: Failed to retrieve FEC Information' });
      });
  }

  private clearFECInfo = (): void => {
    if (this._formRef.current != null) {
      this._formRef.current.setFieldsValue({
        name: null,
        addressLine1: null,
        addressLine2: null,
        city: null,
        state: 'IA',
        zipCode: null,
        email: null,
        phoneNumber: null
      });
    }
  }

  render() {
    const { filerType, formType, disabled, committeeValues, ...formProps } = this.props;
    const columnSizingProps: ColProps = { xs: 24, sm: 24, md: 16 };

    let pageTitle = '';
    let pageSubTitle = '';
    let enableCheckbox = false;

    if (FilerType.PAC == filerType && DR1FormTypes.PARENT == formType) {
      pageTitle = DR1FormTypes.PARENT + ' - Optional';
    }
    else if (FilerType.OSF == filerType) {
      switch (formType) {
        case DR1FormTypes.PARENT:
          pageTitle = DR1FormTypes.PARENT;
          pageSubTitle = 'You are required to identify the committee\'s parent or ' +
            'sponsoring entity, if the committee has one. If the committee does not have ' +
            'a parent or sponsoring entity, then click on "Next" to go to the next page.';
          break;
        case DR1FormTypes.JURISDICTION:
          pageTitle = DR1FormTypes.JURISDICTION + ' - Required';
          pageSubTitle = 'An out of state or federal PAC making a contribution to an ' +
            'Iowa committee is required to disclose that it is filing campaign disclosure ' +
            'reports with the Federal Election Commission or in a jurisdiction with reporting ' +
            'requirements substantially similar to Iowa\'s requirements.';
          enableCheckbox = true;
          break;
        default:
          break;
      }
    }


    return (
      <CustomForm {...formProps}
        formRef={this._formRef}
        initialValues={committeeValues}
        layout="vertical">
        <Typography.Title level={3}>{pageTitle} </Typography.Title>
        <Row>
          <Col {...columnSizingProps}>
            {pageSubTitle && (
              <Typography.Title level={5}>{pageSubTitle} </Typography.Title>
            )}
            {enableCheckbox && (
              <FormItem name="registeredWithFEC" label="Committee is registered with the FEC." valuePropName="checked">
                <Checkbox disabled={disabled} onChange={this.handleCheckboxChange} />
              </FormItem>
            )}
            <AddressFormFields
              name={{ name: 'name', disabled: disabled }}
              address1={{ name: 'addressLine1', disabled: disabled }}
              address2={{ name: 'addressLine2', disabled: disabled }}
              city={{ name: 'city', disabled: disabled }}
              state={{ name: 'state', disabled: disabled }}
              zip={{ name: 'zipCode', disabled: disabled }}
              email={{ name: 'email', required: false, disabled: disabled }}
              phone={{ name: 'phoneNumber', disabled: disabled }}
            />
          </Col>
        </Row>
      </CustomForm >
    );
  }
}

export default SecondaryCommitteeInfo;