import { Descriptions, Divider, Row, Space, Spin, Typography } from 'antd';
import moment from 'moment';
import * as React from 'react';
import Dr2StatusBarDTO from '../../models/Dr2StatusBarDTO';
import DateUtil from '../../utils/DateUtil';
import Colors from '../../consts/Colors';
import BuildingFundsStatusBarButtons from './BuildingFundStatusBarButtons';
import { Content } from 'antd/lib/layout/layout';
import BuildingFundFilingDTO from '../../models/BuildingFundFilingDTO';
import AdjustBuildingFundBalance from '../buildingfunds/AdjustBuildingFundBalance';
import FilingStatusType from '../../consts/FilingStatusType';

const { Item } = Descriptions;

interface BuildingFundsStatusBarProps {
    summaryInfo: BuildingFundFilingDTO;
    onRefresh: () => void;
    filed?: boolean;
}

interface BuildingFundsStatusBarState {
    statusBarFields: Dr2StatusBarDTO;
    loading: boolean;
    showAdjust: boolean;
    extraSmallWindow: boolean;
}

class BuildingFundsStatusBar extends React.Component<BuildingFundsStatusBarProps, BuildingFundsStatusBarState> {
    constructor(props: BuildingFundsStatusBarProps) {
        super(props);

        this.state = {
            statusBarFields: Dr2StatusBarDTO.create(),
            loading: true,
            showAdjust: false,
            extraSmallWindow: this.isExtraSmallWindow()
        };
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleWindowResize);
    }

    componentWillUnmount() {
        window.onresize = null;
    }

    render() {
        const { summaryInfo, onRefresh, filed } = this.props;
        const { statusBarFields, extraSmallWindow, showAdjust } = this.state;
        const dateFormat = DateUtil.getDateFormatString();
        const lblStyle: React.CSSProperties = { fontWeight: 'bold' };
        return (
            <>
                <Spin spinning={false} delay={300}>
                    <Descriptions
                        title={extraSmallWindow ? (
                            <Space size="large">
                                {this.checkUnfiled(statusBarFields.filingStatus || '', statusBarFields.filingPeriodName || '')}
                                <BuildingFundsStatusBarButtons
                                    refreshStatusBar={() => onRefresh()}
                                    showAdjust={() => this.setState({ showAdjust: true })}
                                    filed={filed}
                                    summaryInfo={summaryInfo} />
                            </Space>)
                            : (<Content>
                                <Row style={{ backgroundColor: Colors.IOWA_BLUE }}>
                                    <Space size="large" style={{ margin: '8px' }}>
                                        <BuildingFundsStatusBarButtons
                                            refreshStatusBar={() => onRefresh()}
                                            showAdjust={() => this.setState({ showAdjust: true })}
                                            filed={filed}
                                            summaryInfo={summaryInfo} />
                                    </Space>
                                </Row>
                                <Row>
                                    <Space size="large" style={{ marginTop: '8px' }}>
                                        {this.checkUnfiled(summaryInfo.filingStatusNm || '', summaryInfo.coveredYear || '')}
                                    </Space>
                                </Row>
                            </Content >
                            )
                        }
                        column={{ xs: 1, sm: 2, md: 3, lg: 3, xl: 4 }}
                        size="small"
                    >
                        <Item className="dr2-status-items" labelStyle={lblStyle} label="Filing Due Date">
                            {moment(summaryInfo.dueDate).format(dateFormat)}
                        </Item>
                        <Item className="dr2-status-items" labelStyle={lblStyle} label="Filing Status">
                                {summaryInfo.filingStatusNm}
                        </Item>
                        <Item className="dr2-status-items" labelStyle={lblStyle} label="Filed Date">
                            {this.getFilingDate(dateFormat)}
                        </Item>
                        <Item className="dr2-status-items" labelStyle={lblStyle} label="Changed By">
                            {summaryInfo.lastUpdateBy}
                        </Item>
                        <Item className="dr2-status-items" labelStyle={lblStyle} label="Last Changed">
                                {moment(summaryInfo.lastUpdatedOn).format(dateFormat)}
                        </Item>
                    </Descriptions>
                </Spin>
                <Divider />
                { showAdjust && <AdjustBuildingFundBalance close={() => this.onBalanceAdjusted()} buildingFundId={summaryInfo.buildingFundFilingId}/>}
            </>
        );
    }

    private checkUnfiled = (status: string, year: string): any => {
        if (status === 'Not Filed') {
            const newTitle = year + ' - Unfiled Report';
            return <Typography.Text style={{ color: Colors.PRIMARY }}>{newTitle}</Typography.Text>;
        }

        return <Typography.Text>{year || <>&nbsp;</>}</Typography.Text>;
    }

    private onBalanceAdjusted = () => {
        this.setState({ showAdjust: false });
        if (this.props.onRefresh) {
            this.props.onRefresh();
        }
    }

    private handleWindowResize = () => {
        if (this.state.extraSmallWindow !== this.isExtraSmallWindow()) {
            this.setState({ extraSmallWindow: !this.state.extraSmallWindow });
        }
    }

    private isExtraSmallWindow = () => {
        return !window.matchMedia('(min-width: 768px)').matches;
    }

    private getFilingDate = (dateFormat: string) => {
        if (this.props.summaryInfo.filingStatusId == FilingStatusType.FILED &&
            this.props.summaryInfo.filedDate != null) {
            return moment(this.props.summaryInfo.filedDate).format(dateFormat);
        }
        else if (this.props.summaryInfo.filingStatusId == FilingStatusType.AUDITED &&
            this.props.summaryInfo.filedDate != null) {
            return moment(this.props.summaryInfo.filedDate).format(dateFormat);
        }
        else {
            return '';
        }
    }
}

export default BuildingFundsStatusBar;
