import * as React from 'react';
import { Route, Switch } from 'react-router';
import Routes from '../../consts/Routes';
import { RedirectAs404 } from '../../utils/RouteErrors';
import DissolutionPage from './DissolutionPage';


const Dissolution = () => (
  <Switch>
    <Route exact={true} path={Routes.DISSOLUTION_BASE} component={DissolutionPage} />
      <Route component={RedirectAs404} />
  </Switch>
);

export default Dissolution;