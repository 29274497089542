// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';

interface PeriodReportFormDTO { 
  committeeType: number;
  year: string | null;
  filingPeriod: number;
}
const PeriodReportFormDTO = {
  create: (initValues?: Partial<PeriodReportFormDTO> | Record<string, unknown> | null) => {
    return Object.assign(
    {
      committeeType: 0,
      year: null,
      filingPeriod: 0,
    },
    initValues);
  }
};

export default PeriodReportFormDTO;