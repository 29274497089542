// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
import BaseApi from './BaseApi';
import TableRequestDTO from '../models/TableRequestDTO';
import SchDDebtsFormDTO from '../models/SchDDebtsFormDTO';
import TableResponseDTO from '../models/TableResponseDTO';
import SchDDebtsDTO from '../models/SchDDebtsDTO';

export class SchDDebtsApiService extends BaseApi {

  // post: api/schD/table?dr2Id=${encodeURIComponent(dr2Id)}
  public getSchDDebts(tableRequest: TableRequestDTO, dr2Id: string): Promise<TableResponseDTO<SchDDebtsDTO>> {
    let url = `api/schD/table`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (dr2Id != null) {
      url += `${prefix}dr2Id=${encodeURIComponent(dr2Id)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, TableResponseDTO<SchDDebtsDTO>>(tableRequest, 'post', url, true, false);
  }

  // get: api/schD/${encodeURIComponent(debtId)}/${seqNum}
  public getDebt(debtId: string, seqNum: number): Promise<SchDDebtsFormDTO> {
    let url = `api/schD/${encodeURIComponent(debtId)}/${seqNum}`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, SchDDebtsFormDTO>(null, 'get', url, true, false);
  }

  // post: api/schD/${encodeURIComponent(debtId)}/history
  public getDebtHistory(tableRequest: TableRequestDTO, debtId: string): Promise<TableResponseDTO<SchDDebtsDTO>> {
    let url = `api/schD/${encodeURIComponent(debtId)}/history`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, TableResponseDTO<SchDDebtsDTO>>(tableRequest, 'post', url, true, false);
  }

  // post: api/schD?committeeId=${encodeURIComponent(committeeId)}&dr2Id=${encodeURIComponent(dr2Id)}
  public add(newDebt: SchDDebtsFormDTO, committeeId: string, dr2Id: string): Promise<void> {
    let url = `api/schD`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (committeeId != null) {
      url += `${prefix}committeeId=${encodeURIComponent(committeeId)}`;
      prefix = '&';
    }
    if (dr2Id != null) {
      url += `${prefix}dr2Id=${encodeURIComponent(dr2Id)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, void>(newDebt, 'post', url, true, false);
  }

  // put: api/schD?committeeId=${encodeURIComponent(committeeId)}&dr2Id=${encodeURIComponent(dr2Id)}&debtId=${encodeURIComponent(debtId)}&seqNum=${seqNum}
  public edit(newDebt: SchDDebtsFormDTO, committeeId: string, dr2Id: string, debtId: string, seqNum: number): Promise<void> {
    let url = `api/schD`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (committeeId != null) {
      url += `${prefix}committeeId=${encodeURIComponent(committeeId)}`;
      prefix = '&';
    }
    if (dr2Id != null) {
      url += `${prefix}dr2Id=${encodeURIComponent(dr2Id)}`;
      prefix = '&';
    }
    if (debtId != null) {
      url += `${prefix}debtId=${encodeURIComponent(debtId)}`;
      prefix = '&';
    }
    if (seqNum != null) {
      url += `${prefix}seqNum=${seqNum}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, void>(newDebt, 'put', url, true, false);
  }

  // delete: api/schD?debtId=${encodeURIComponent(debtId)}&seqNum=${seqNum}
  public delete(debtId: string, seqNum: number): Promise<void> {
    let url = `api/schD`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (debtId != null) {
      url += `${prefix}debtId=${encodeURIComponent(debtId)}`;
      prefix = '&';
    }
    if (seqNum != null) {
      url += `${prefix}seqNum=${seqNum}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, void>(null, 'delete', url, true, false);
  }

  // get: api/schD/checkConnectedRecords?debtId=${encodeURIComponent(debtId)}
  public getConnectedDebtRecords(debtId: string): Promise<number> {
    let url = `api/schD/checkConnectedRecords`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (debtId != null) {
      url += `${prefix}debtId=${encodeURIComponent(debtId)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, number>(null, 'get', url, true, false);
  }

  // get: api/schD/checkConnectedRecordsForSchedule?dr2Id=${encodeURIComponent(dr2Id)}
  public getConnectedDebtRecordsForPeriod(dr2Id: string): Promise<number> {
    let url = `api/schD/checkConnectedRecordsForSchedule`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (dr2Id != null) {
      url += `${prefix}dr2Id=${encodeURIComponent(dr2Id)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, number>(null, 'get', url, true, false);
  }
}
var service = new SchDDebtsApiService();
export default service;
