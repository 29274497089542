import { Divider, Layout, Modal, notification, Typography } from 'antd';
import * as React from 'react';
import ScheduleApiService from '../../api/ScheduleApiService';
import SchF2LoansRepaidApiService from '../../api/SchF2LoansRepaidApiService';
import Dr2AmendedIndicators from '../../consts/Dr2AmendedIndicators';
import Dr2AmendedStatuses from '../../consts/Dr2AmendedStatuses';
import NoteEntityParents from '../../consts/NoteEntityParents';
import Role from '../../consts/Role';
import Routes from '../../consts/Routes';
import ScheduleAbbreviationCodes from '../../consts/ScheduleAbbreviationCodes';
import TransactionTypeIndicators from '../../consts/TransactionTypeIndicators';
import SchF2LoansRepaidDTO from '../../models/SchF2LoansRepaidDTO';
import CurrentUser from '../../utils/CurrentUser';
import HistoryUtil from '../../utils/HistoryUtil';
import NumberFormatUtil from '../../utils/NumberFormatUtil';
import CrossReferenceModal from '../shared/CrossReferenceModal';
import DataTable, { ColumnType, DataTableColumnProps, FilterType } from '../shared/DataTable/DataTable';
import DataTableButtonUtil from '../shared/DataTable/DataTableButtonUtil';
import DataTableColumnUtil from '../shared/DataTable/DataTableColumnUtil';
import NotesModal from '../shared/NotesModal';
import Dr2StatusBar from './Dr2StatusBar';

const { Content } = Layout;
const { Text } = Typography;
const { confirm } = Modal;

interface SchF2LoansRepaidProps {
}

interface SchF2LoansRepaidState {
  tableColumns: DataTableColumnProps<SchF2LoansRepaidDTO>[];
  numLoansRepaid: number;
  totalOfSched: number;
  flagNoteId: string;
  loanRepId: string;
  showModal: boolean;
  showReferenceModal: boolean;
  referenceId: string;
}

class SchF2LoansRepaid extends React.Component<SchF2LoansRepaidProps, SchF2LoansRepaidState> {
  constructor(props: SchF2LoansRepaidProps) {
    super(props);

    this.state = {
      tableColumns: this.getTableColumns(),
      numLoansRepaid: 0,
      totalOfSched: 0,
      flagNoteId: '',
      loanRepId: '',
      showModal: false,
      showReferenceModal: false,
      referenceId: ''
    };
  }

  private dataTable: DataTable<SchF2LoansRepaid> | undefined;

  componentDidMount() {
    this.getStatistics();
  }

  render() {
    const { tableColumns, numLoansRepaid, totalOfSched, flagNoteId, loanRepId, showModal, showReferenceModal,referenceId} = this.state;
    const actionButtons = [];

    actionButtons.push(DataTableButtonUtil.Reset());
    actionButtons.push(DataTableButtonUtil.Primary(
      'New Loan Payment',
      () => HistoryUtil.push(Routes.generate(Routes.ADD_SCHEDULE_F2_LOAN_REPAID, {}))
    ));

    const currUser = CurrentUser.Get();

    return (
      <>
        <Content className="content-pad">
          <Dr2StatusBar dr2Id={CurrentUser.Get()?.dr2Id || ''} />
          <Text strong> Number of Payments: {numLoansRepaid}&emsp;|&emsp;Total of Schedule: {NumberFormatUtil.currency(totalOfSched)}&emsp;| </Text>
          <NotesModal
            parentId={CurrentUser.Get()?.dr2Id || ''}
            parent={NoteEntityParents.SCHEDULE}
            scheduleCd={ScheduleAbbreviationCodes.SCHEDULEF2}
          />
          <Divider />
          <DataTable
            buttonBar={actionButtons}
            columns={tableColumns}
            fetchData={{
              fetch: function (tableRequest) {
                return SchF2LoansRepaidApiService.getSchF2LoansRepaidList(tableRequest, currUser?.dr2Id || '');
              },
              failureMessage: 'Failed to retrieve Loans Repaid'
            }}
            globalSearch={true}
            ref={(element: any) => (this.dataTable = element)}
            serverSide={true}
            styleOptions={{ compact: true, alternatingRowHighlight: true }}
            tableProps={{
              rowKey: 'loanRepaidIdAndSeq',
              sortDirections: ['ascend', 'descend'],
              locale: { emptyText: 'Currently there are no loans repaid to manage.' }
            }}
            title="Schedule F2 - Loans Repaid"
            stateSaving={{
                enabled: true,
                tableUniqueKey: 'manageSchF2',
                perSession: true,
            }}
          />
        </Content>
        {showModal &&
          <NotesModal
            parentId={loanRepId|| ''}
            parent={NoteEntityParents.LOANREPAID}
            auditorsNoteId={flagNoteId}
            canFlag={true}
            onClose={() => { this.setState({ showModal: false }, () => this.dataTable?.refresh()); }} />
            }
            {showReferenceModal &&
                <CrossReferenceModal
                referenceType={TransactionTypeIndicators.LOANRECEIVED}
                referenceId={referenceId}
                onClose={() => { this.setState({ showReferenceModal: false, referenceId: '' }); }} />
            }
      </>
    );
    }


  private getStatistics = () => {
    ScheduleApiService.getScheduleCount(CurrentUser.Get()?.dr2Id || '', ScheduleAbbreviationCodes.SCHEDULEF2)
      .then(numLoansRepaid => {
        this.setState({ numLoansRepaid });
      }).catch(error => {
        notification.error({
          message: 'Error while fetching the number of loans repaid',
          description: error
        });
      });

    ScheduleApiService.getScheduleTotal(CurrentUser.Get()?.dr2Id || '', ScheduleAbbreviationCodes.SCHEDULEF2)
      .then(totalOfSched => {
        this.setState({ totalOfSched });
      }).catch(error => {
        notification.error({
          message: 'Error while fetching the total of schedule',
          description: error
        });
      });
  }

  private getTableColumns() {
    const columns: DataTableColumnProps<SchF2LoansRepaidDTO>[] = [];

    const columnLoanRepaidDate: DataTableColumnProps<SchF2LoansRepaidDTO> =
        DataTableColumnUtil.Date('Date', 'loanRepaidDt', 100, { defaultSortOrder: 'ascend' });
    columns.push(columnLoanRepaidDate);

    const columnAddress: DataTableColumnProps<SchF2LoansRepaidDTO> =  DataTableColumnUtil.Address('Payee', 'loanRepaidName',
            (c) => ({
                name: c.loanRepaidName,
                line1: c.loanRepaidAddressLine1,
                line2: c.loanRepaidAddressLine2,
                city: c.loanRepaidCity,
                state: c.loanRepaidState,
                zip: c.loanRepaidZip
            })
    );
    columns.push(columnAddress);

    const columnAmount: DataTableColumnProps<SchF2LoansRepaidDTO> = DataTableColumnUtil.Currency('Amount', 'loanRepaidAmt');
    columns.push(columnAmount);

    const columnStatus: DataTableColumnProps<SchF2LoansRepaidDTO> = DataTableColumnUtil.DropdownMulti('Status', 'status',
        [
            { text: Dr2AmendedStatuses.ORIGINAL, value: Dr2AmendedIndicators.ORIGINAL },
            { text: Dr2AmendedStatuses.AMENDED, value: Dr2AmendedIndicators.AMENDED },
            { text: Dr2AmendedStatuses.ADJUSTED, value: Dr2AmendedIndicators.ADJUSTED },
            { text: Dr2AmendedStatuses.DELETED, value: Dr2AmendedIndicators.DELETED }
        ]);
    columns.push(columnStatus);

    const columnDescription: DataTableColumnProps<SchF2LoansRepaidDTO> = DataTableColumnUtil.Description('Description', 'explanation');
    columns.push(columnDescription);

    const columnFlagNote: DataTableColumnProps<SchF2LoansRepaidDTO> = DataTableColumnUtil.FlagButton(
        'Flagged', 'flaggedNoteId', CurrentUser.Get()?.isInRole(Role.IECDB_ADMINISTRATOR) || false,
        [
            {
                onClick: (rowData) => this.openFlagModal(rowData.flaggedNoteId || '', rowData.loanRepaidId || ''),
                visible: (rowData) => rowData.flaggedNoteId != null
            }
        ]);
    columns.push(columnFlagNote);

    if (CurrentUser.Get()?.isInRole(Role.IECDB_ADMINISTRATOR)) {
        const columnReference: DataTableColumnProps<SchF2LoansRepaidDTO> = DataTableColumnUtil.CheckmarkButton('hasReference',
            [
                {
                    onClick: (rowData) => this.openReferenceModal(rowData.referenceId || ''),
                    visible: (rowData) => rowData.hasReference,
                }
            ]);
        columnReference.title = 'Reference';
        columnReference.filterType = FilterType.BooleanRadio;
        columnReference.columnType = ColumnType.Boolean;
        columns.push(columnReference);
     }

    const columnManageButtons: DataTableColumnProps<SchF2LoansRepaidDTO> = DataTableColumnUtil.Buttons('loanRepaidIdAndSeq',
        [
            {
                text: 'Edit',
                onClick: (rowData) =>
                    HistoryUtil.push(Routes.generate(
                        Routes.EDIT_SCHEDULE_F2_LOAN_REPAID,
                        {
                            id: rowData.loanRepaidId || '',
                            seqNum: rowData.seqNumber || ''
                        }
                    ))
            },
            {
                text: 'Delete',
                onClick: (rowData) => this.confirmDelete(
                    rowData.loanRepaidId || '',
                    rowData.seqNumber || 0,
                    rowData.loanRepaidName || '',
                    rowData.loanRepaidAmt || 0
                )
            }
        ],
        150);
    columns.push(columnManageButtons);

    return columns;
  }

  private openFlagModal = (flagNoteId: string, loanRepId: string) => {
    this.setState({ showModal: true, flagNoteId, loanRepId }, () => this.dataTable?.refresh());
   }

   private openReferenceModal = (refId: string) => {
    this.setState({ showReferenceModal: true, referenceId: refId });
   }

  private showTableAndRefresh = () => {
    if (this.dataTable) {
      this.dataTable.refresh();
    }
    this.getStatistics();
  }

  private confirmDelete = (loanRepaidId: string, seqNum: number, payeeName: string, amount: number) => {
    confirm({
      title: 'Are you sure you want to delete this loan repaid?',
      content: 'The following record will be permanently deleted: ' + payeeName + ' - $' + amount,
      onOk: () => {
        this.deleteContribution(loanRepaidId, seqNum);
      },
    });
  }

  private deleteContribution = (loanRepaidId: string, seqNum: number) => {
    SchF2LoansRepaidApiService.delete(loanRepaidId, seqNum)
      .then(() => {
        this.showTableAndRefresh();
        notification.success({
          message: 'Deleted Successfully'
        });
      }).catch(() => {
        notification.error({
          message: 'Error while deleting loan repaid',
          description: '',
        });
      });
  }
}

export default SchF2LoansRepaid;