import { Descriptions, Divider, Layout, Space, Typography } from 'antd';
import Item from 'antd/lib/descriptions/Item';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import AdminDr1ApiService from '../../api/AdminDr1ApiService';
import Routes from '../../consts/Routes';
import AdminDR1ApproveDTO from '../../models/AdminDR1ApproveDTO';
import HistoryUtil from '../../utils/HistoryUtil';
import {
  Button,
  notification,
} from 'antd';

const { Content } = Layout;

interface ApproveDr1EditProps {
  committeeId: string;
}

interface ApproveDR1EditState {
  dr1Status: AdminDR1ApproveDTO;
  committeeId: string;
  loadingDr1Status: boolean;
}

class ApproveDr1Edit extends React.Component<RouteComponentProps<ApproveDr1EditProps>, ApproveDR1EditState>
{
  constructor(props: RouteComponentProps<ApproveDr1EditProps>) {
    super(props);

    this.state = {
      dr1Status: AdminDR1ApproveDTO.create(),
      committeeId: this.props.match.params.committeeId,
      loadingDr1Status: false,
    };
  }

  componentDidMount() {
    this.getDr1Approve();
  }

  render() {
    const { dr1Status, loadingDr1Status } = this.state;

    return (
      <Content className="content-pad">
        {loadingDr1Status &&
          <>
          <Typography.Title level={4}>Approve DR1</Typography.Title>
          <Divider/>
            <Descriptions column={2} >
              <Item label="Name">{dr1Status.committeeName} </Item>
              <Item label="Code">{dr1Status.committeeCode || 'Not Assigned'}</Item>
              <Item label="Type">{dr1Status.committeeTypeNm}</Item>
              <Item label="Status">{dr1Status.committeeStatusTypeNm}</Item>
              <Item label="Filing Type">{dr1Status.filerTypeNm}</Item>
              <Item label="UserID">{dr1Status.userId}</Item>
              <Item label="Category">{dr1Status.committeeCategory}</Item>
              <Item label="User Status">{dr1Status.userStatus}</Item>
          </Descriptions>
          <Divider />
          <Space>
            <Button type="primary" onClick={this.handleApprove}>Approve</Button>
            <Button type="default" onClick={this.handleCancel}>Cancel</Button>
          </Space>
          </>
        }
      </Content>
    );
  }

  private handleCancel = () => {
    HistoryUtil.push(Routes.generate(Routes.MANAGE_DR1));
  }

  private handleApprove = () => {
    AdminDr1ApiService.approveDR1(this.state.dr1Status)
      .then(() => {
        HistoryUtil.push(Routes.generate(Routes.MANAGE_DR1));
        notification.success({ message: 'DR-1 was approved successfully.' });
      })
      .catch(() => {
        notification.error({ message: 'Failed to Approve DR1.' });
        this.setState({ loadingDr1Status: false });
      });
  }

  private getDr1Approve = () => {
    if (!this.state.committeeId) {
      this.setState({ loadingDr1Status: false });
      return;
    }
    AdminDr1ApiService.getApproveDR1(this.state.committeeId)
      .then(dr1Status => {
        this.setState({ dr1Status, loadingDr1Status: true });
      }).catch(() => {
        notification.error({ message: 'Failed to load DR1 Status Data.' });
        this.setState({ loadingDr1Status: false });
      });
  }
}

export default ApproveDr1Edit;