// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';

interface CommitteeImportFileDTO { 
  file: any[] | null;
}
const CommitteeImportFileDTO = {
  create: (initValues?: Partial<CommitteeImportFileDTO> | Record<string, unknown> | null) => {
    return Object.assign(
    {
      file: [],
    },
    initValues);
  }
};

export default CommitteeImportFileDTO;