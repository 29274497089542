// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';

interface Dr2SummaryDTO { 
  startingBalance: number;
  cashContributions: number;
  loansReceived: number;
  campaignPropertySales: number;
  subtotal: number;
  expenditures: number;
  loanRepayments: number;
  endingBalance: number;
  loansAtStartOfPeriod: number;
  unpaidBills: number;
  inKindContributions: number;
  forgivenLoans: number;
  outstandingLoans: number;
  consultantBreakdown: boolean;
  campaignPropertyValue: number;
  auditorsNoteId: string | null;
  filingStatusCode: string | null;
  schA_isAmended: boolean;
  schB_isAmended: boolean;
  schD_isAmended: boolean;
  schE_isAmended: boolean;
  schF1_isAmended: boolean;
  schF2_isAmended: boolean;
  schG_isAmended: boolean;
  schH1_isAmended: boolean;
  schH2_isAmended: boolean;
}
const Dr2SummaryDTO = {
  create: (initValues?: Partial<Dr2SummaryDTO> | Record<string, unknown> | null) => {
    return Object.assign(
    {
      startingBalance: 0,
      cashContributions: 0,
      loansReceived: 0,
      campaignPropertySales: 0,
      subtotal: 0,
      expenditures: 0,
      loanRepayments: 0,
      endingBalance: 0,
      loansAtStartOfPeriod: 0,
      unpaidBills: 0,
      inKindContributions: 0,
      forgivenLoans: 0,
      outstandingLoans: 0,
      consultantBreakdown: false,
      campaignPropertyValue: 0,
      auditorsNoteId: null,
      filingStatusCode: null,
      schA_isAmended: false,
      schB_isAmended: false,
      schD_isAmended: false,
      schE_isAmended: false,
      schF1_isAmended: false,
      schF2_isAmended: false,
      schG_isAmended: false,
      schH1_isAmended: false,
      schH2_isAmended: false,
    },
    initValues);
  }
};

export default Dr2SummaryDTO;