// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import EntitySelectorDTO from './EntitySelectorDTO';

interface AdminSalesByStateDTO extends EntitySelectorDTO { 
  id: number;
  officialName: string | null;
  firstName: string | null;
  middleInitial: string | null;
  lastName: string | null;
  executiveBranchAgencyId: number;
  executiveBranchAgencyName: string | null;
  stateAgencyOfSaleName: string | null;
  officialPhone: string | null;
  officialEmail: string | null;
  dateOfSale: moment.Moment | string;
  saleAmount: number;
  saleDescription: string | null;
  filedDate: moment.Moment | string;
  filingStatus: string | null;
  signatureName: string | null;
  signatureDate: moment.Moment | string;
}
const AdminSalesByStateDTO = {
  create: (initValues?: Partial<AdminSalesByStateDTO> | Record<string, unknown> | null) => {
    return Object.assign(EntitySelectorDTO.create(),
    {
      id: 0,
      officialName: "",
      firstName: "",
      middleInitial: "",
      lastName: "",
      executiveBranchAgencyId: 0,
      executiveBranchAgencyName: "",
      stateAgencyOfSaleName: "",
      officialPhone: "",
      officialEmail: "",
      dateOfSale: new Date(0).toISOString(),
      saleAmount: 0,
      saleDescription: "",
      filedDate: new Date(0).toISOString(),
      filingStatus: "",
      signatureName: "",
      signatureDate: new Date(0).toISOString(),
    },
    initValues);
  }
};

export default AdminSalesByStateDTO;