import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import Routes from '../../consts/Routes';
import { RedirectAs404 } from '../../utils/RouteErrors';
import EditGiftBequests from '../donor/EditGiftBequests';
import DonorRegistration from '../public/campaigns/DonorRegistration';
import DonorSummary from '../public/DonorSummary';

const Donor = () => (
  <Switch>
    <Route exact={true} path={Routes.DONOR_SUMMARY} component={DonorSummary} />
    <Route exact={true} path={Routes.GIFT_BEQUEST} component={EditGiftBequests} />
    <Route exact={true} path={Routes.EDIT_GIFT_BEQUEST} render={(props) => <EditGiftBequests {...props} key={props.match.params.giftId} /> } />
    <Route exact={true} path={Routes.DONOR_PROFILE} component={DonorRegistration} />

    {/* This needs to be the last item */}
    <Route component={RedirectAs404} />
  </Switch>
);

export default Donor;
