// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';

interface SchH1CampaignPropertyFormDTO { 
  acquiredDate: moment.Moment | string | null;
  currentValueAmount: number;
  pricePaidAmount: number;
  property: string | null;
  isValueEstimated: boolean;
  auditorsNoteId: string | null;
}
const SchH1CampaignPropertyFormDTO = {
  create: (initValues?: Partial<SchH1CampaignPropertyFormDTO> | Record<string, unknown> | null) => {
    return Object.assign(
    {
      acquiredDate: null,
      currentValueAmount: 0,
      pricePaidAmount: 0,
      property: null,
      isValueEstimated: false,
      auditorsNoteId: null,
    },
    initValues);
  }
};

export default SchH1CampaignPropertyFormDTO;