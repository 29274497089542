import { Modal, Divider, Layout, notification, Typography } from 'antd';
import * as React from 'react';
import VsrContributionApiService from '../../api/VsrContributionApiService';
import Dr2AmendedIndicators from '../../consts/Dr2AmendedIndicators';
import Dr2AmendedStatuses from '../../consts/Dr2AmendedStatuses';
import NoteEntityParents from '../../consts/NoteEntityParents';
import Role from '../../consts/Role';
import Routes from '../../consts/Routes';
import VsrContributionsDTO from '../../models/VsrContributionsDTO';
import CurrentUser from '../../utils/CurrentUser';
import HistoryUtil from '../../utils/HistoryUtil';
import NumberFormatUtil from '../../utils/NumberFormatUtil';
import DataTable, { DataTableColumnProps, FilterType } from '../shared/DataTable/DataTable';
import DataTableButtonUtil from '../shared/DataTable/DataTableButtonUtil';
import DataTableColumnUtil from '../shared/DataTable/DataTableColumnUtil';
import NotesModal from '../shared/NotesModal';

const { Content } = Layout;
const { Text } = Typography;
const { confirm } = Modal;

interface VsrContributionsProps {
}

interface VsrContributionsState {
  tableColumns: DataTableColumnProps<VsrContributionsDTO>[];
  numContributions: number;
  totalOfSched: number;
  flagNoteId: string;
  vsrId: string;
  showModal: boolean;
}

class VsrContributions extends React.Component<VsrContributionsProps, VsrContributionsState> {
  constructor(props: VsrContributionsProps) {
    super(props);

    this.state = {
      tableColumns: this.getTableColumns(),
      numContributions: 0,
      totalOfSched: 0,
      flagNoteId: '',
      vsrId: '',
      showModal: false,
    };
  }

  private dataTable: DataTable<VsrContributionsDTO> | undefined;

  componentDidMount() {
    this.getStatistics();
  }

  render() {
    const { numContributions, totalOfSched, flagNoteId, vsrId, showModal } = this.state;
    const actionButtons = [];
    actionButtons.push(DataTableButtonUtil.Reset());
    actionButtons.push(DataTableButtonUtil.Primary(
      'New Expenditure',
      () => HistoryUtil.push(Routes.generate(Routes.ADD_VSR_CONTRIBUTION, {}))
    ));

    const currUser = CurrentUser.Get();

    return (
     <>
      <Content className="content-pad">
        <Text strong> Number of Contributions: {numContributions}&emsp;|&emsp;Total of Schedule: {NumberFormatUtil.currency(totalOfSched)} </Text>
        <Divider />
        <DataTable
          buttonBar={actionButtons}
          columns={this.state.tableColumns}
          fetchData={{
            fetch: function (tableRequest) {
              return VsrContributionApiService.getVsrList(tableRequest, currUser?.committeeId || '');
            },
            failureMessage: 'Failed to retrieve expenditures'
          }}
          globalSearch={true}
          ref={(element: any) => (this.dataTable = element)}
          serverSide={true}
          styleOptions={{ compact: true, alternatingRowHighlight: true }}
          tableProps={{
            rowKey: 'contributionIdAndSeq',
            sortDirections: ['ascend', 'descend'],
            locale: { emptyText: 'Currently there are no Expenditures to manage.' }
          }}
          title="VSR - Expenditures"
        />
        </Content>
        {showModal &&
          <NotesModal
            parentId={vsrId || ''}
            parent={NoteEntityParents.VSR}
            auditorsNoteId={flagNoteId}
            canFlag={true}
            onClose={() => { this.setState({ showModal: false }, () => this.dataTable?.refresh()); }} />
        }
      </>
    );
  }

  private getStatistics = () => {
    VsrContributionApiService.getVsrCount(CurrentUser.Get()?.committeeId || '')
      .then(numContributions => {
        this.setState({ numContributions });
      }).catch(error => {
        notification.error({
          message: 'Error while fetching the number of contributions',
          description: error.message
        });
      });

    VsrContributionApiService.getVsrTotal(CurrentUser.Get()?.committeeId || '')
      .then(totalOfSched => {
        this.setState({ totalOfSched });
      }).catch(error => {
        notification.error({
          message: 'Error while fetching the total of schedule',
          description: error.message
        });
      });
  }

  private getTableColumns = (): DataTableColumnProps<VsrContributionsDTO>[] => {
    let buttons: DataTableColumnProps<VsrContributionsDTO> = DataTableColumnUtil.Buttons<VsrContributionsDTO>('', []);
    if (CurrentUser.Get()?.isInRole(Role.IECDB_ADMINISTRATOR)) {
      buttons = DataTableColumnUtil.Buttons<VsrContributionsDTO>('contributionIdAndSeq',
        [
          {
            text: 'Edit',
            onClick: (rowData) =>
              HistoryUtil.push(Routes.generate(
                Routes.EDIT_VSR_CONTRIBUTION,
                {
                  id: rowData.vsrId || '',
                  seqNum: rowData.vsrSeqNbr || ''
                }))
          },
          {
            text: 'Delete',
            onClick: (rowData) => this.confirmDelete(
              rowData.vsrId || '',
              rowData.vsrSeqNbr || 0,
              rowData.contributorName || '',
              rowData.contributionAmt || 0)
          },
          {
            text: 'Penalty',
            onClick: (rowData) =>
              HistoryUtil.push(Routes.generate(
                Routes.VSR_PENALTY,
                {
                  id: rowData.vsrId || '',
                  from: `VSR Contribution to ${rowData.contributorName}`
                }))
          },
        ],
        150);
    }
    else {
      buttons = DataTableColumnUtil.Buttons<VsrContributionsDTO>('contributionIdAndSeq',
        [
          {
            text: 'Edit',
            onClick: (rowData) =>
              HistoryUtil.push(Routes.generate(
                Routes.EDIT_VSR_CONTRIBUTION,
                {
                  id: rowData.vsrId || '',
                  seqNum: rowData.vsrSeqNbr || ''
                }))
          },
          {
            text: 'Delete',
            onClick: (rowData) => this.confirmDelete(
              rowData.vsrId || '',
              rowData.vsrSeqNbr || 0,
              rowData.contributorName || '',
              rowData.contributionAmt || 0)
          }
        ],
        150);
    }
    return [
      DataTableColumnUtil.Date('Date', 'contributionDt', 100, { defaultSortOrder: 'descend' }),
      DataTableColumnUtil.Text('Given To', 'contributorName'),
      DataTableColumnUtil.Currency('Amount', 'contributionAmt'),
      DataTableColumnUtil.Text('Check #', 'checkNumber', 125, { align: 'center' }),
      DataTableColumnUtil.DropdownMulti('Status', 'status',
        [
          { text: Dr2AmendedStatuses.ORIGINAL, value: Dr2AmendedIndicators.ORIGINAL },
          { text: Dr2AmendedStatuses.AMENDED, value: Dr2AmendedIndicators.AMENDED },
          { text: Dr2AmendedStatuses.ADJUSTED, value: Dr2AmendedIndicators.ADJUSTED },
          { text: Dr2AmendedStatuses.DELETED, value: Dr2AmendedIndicators.DELETED }
        ]),
      DataTableColumnUtil.Description('Description', 'explanation'),
      DataTableColumnUtil.FlagButton('Flagged', 'flaggedNoteId', CurrentUser.Get()?.isInRole(Role.IECDB_ADMINISTRATOR) || false, [
        {
          onClick: (rowData) => this.openFlagModal(rowData.flaggedNoteId || '', rowData.vsrId || ''),
          visible: (rowData) => rowData.flaggedNoteId != null
        }
      ]),
      DataTableColumnUtil.BooleanCheckbox('Penalty', 'penaltyInd', 10, FilterType.BooleanRadio),
      DataTableColumnUtil.Currency('Penalty Amount', 'penaltyAmount', 10),
      DataTableColumnUtil.Currency('Penalty Paid', 'penaltyAmountPaid', 10),
      DataTableColumnUtil.BooleanCheckbox('In Kind', 'inKindInd', 10, FilterType.BooleanRadio),
      buttons
    ];
  }

  private openFlagModal = (flagNoteId: string, vsrId: string) => {
    this.setState({ showModal: true, flagNoteId, vsrId }, () => this.dataTable?.refresh());
  }

  private showTableAndRefresh = () => {
    if (this.dataTable) {
      this.dataTable.refresh();
    }
    this.getStatistics();
  }

  private confirmDelete = (vsrId: string, vsrSeqNum: number, contributorName: string, amount: number) => {
    confirm({
      title: 'Are you sure you want to delete this contribution?',
      content: 'The following record will be permanently deleted: ' + contributorName + ' - $' + amount,
      onOk: () => {
        this.deleteContribution(vsrId, vsrSeqNum);
      },
    });
  }

  private deleteContribution = (vsrId: string, vsrSeqNum: number) => {
    VsrContributionApiService.deleteVsrContribution(vsrId, vsrSeqNum)
      .then(() => {
        this.showTableAndRefresh();
        notification.success({
          message: 'Deleted Successfully'
        });
      }).catch(() => {
        notification.error({
          message: 'Error while deleting contribution',
          description: '',
        });
      });
  }
}

export default VsrContributions;
