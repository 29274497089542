import { notification, FormInstance, Row, Col, Input, Checkbox, Button, Table, Typography, Tooltip, } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import * as React from 'react';
import FormValidationUtil from '../../../../utils/FormValidationUtil';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import SourcesDTO from '../../../../models/SourcesDTO';
import CustomForm from '../../../shared/CustomForm';

const { Paragraph, Title } = Typography;
const { Column } = Table;

interface NameTypeSourcesProps {
  sources: SourcesDTO[];
  noOtherSource: boolean;
  description: string;
  checkBoxLabel: string;
  nameLabel: string;
  typeLabel: string;
  nameToolTip?: string;
  typeToolTip?: string;
  rejectMessage: string;
  nameTitle: string;
  pageTitle: string;
}

interface NameTypeSourcesState {
  sources: SourcesDTO[];
  noOtherSource: boolean;
}

class NameTypeSources extends React.Component<NameTypeSourcesProps, NameTypeSourcesState>
{
  private readonly _formRef = React.createRef<FormInstance>();

  constructor(props: NameTypeSourcesProps) {
    super(props);

    this.state = {
      sources: this.props.sources || [],
      noOtherSource: this.props.noOtherSource,
    };
  }

  moveBack = async (): Promise<{ model: { sources: SourcesDTO[], noOtherSource: boolean }, errorFields: any }> => {
    return {
      model: { sources: this.state.sources, noOtherSource: this.state.noOtherSource }, errorFields: null
    };
  }

  validate = async (): Promise<{ model: { sources: SourcesDTO[], noOtherSource: boolean }, errorFields: any }> => {
    if (this.state.sources.length > 0 || this.state.noOtherSource) {
      return {
        model: { sources: this.state.sources, noOtherSource: this.state.noOtherSource }, errorFields: null
      };
    } else {
      notification.error({
        message: this.props.rejectMessage
      });
      return Promise.reject();
    }
  }

  render() {
    return (
      <>
        <Title level={4}>
          { this.props.pageTitle }
        </Title>
        <Paragraph>
          {this.props.description}
        </Paragraph>
        <Row>
          <Col xs={24}>
            <CustomForm formRef={this._formRef} onFinish={this.addSource} layout="vertical">
              <Tooltip title={this.props.nameToolTip}>
                <FormItem name="name"
                    label={this.props.nameLabel}
                    rules={[FormValidationUtil.Required(this.props.nameLabel + ' is required'),
                    FormValidationUtil.Max(250, 'Max 250 characters allowed.')]}>
                  <Input type="text" />
                </FormItem>
              </Tooltip>
              <Tooltip title={this.props.typeToolTip}>
                <FormItem name="type"
                    label={this.props.typeLabel}
                    rules={[FormValidationUtil.Required(this.props.typeLabel + ' is required'),
                    FormValidationUtil.Max(250, 'Max 250 characters allowed.')]}>
                  <Input type="text" />
                </FormItem>
              </Tooltip>
              <Button type="primary" htmlType="submit">Add</Button>
            </CustomForm>
          </Col>
        </Row>
        <br />
        {
          this.state.sources.length > 0 ?
            <Row>
              <Col xs={24}>
                <Table dataSource={this.state.sources}>
                  <Column title={this.props.nameTitle} dataIndex="name" key="name" />
                  <Column title="Type" dataIndex="type" key="type" />
                  <Column
                    title="Delete"
                    dataIndex=""
                    render={(text, record, index) =>
                      <Button onClick={() => { this.deleteSource(index); }}>Delete</Button>
                    }
                  />
                </Table>
              </Col>
            </Row>
            :
            <>
              <Row>
                <Col>
                  <Checkbox className="bold-checkbox" checked={this.state.noOtherSource} onChange={this.toggleSource}>
                    {this.props.checkBoxLabel}
                  </Checkbox>
                </Col>
              </Row>
              <br />
            </>
        }
      </>
    );
  }

  private addSource = (values: any) => {
    this._formRef.current?.resetFields();
    this.setState({ sources: [...this.state.sources, values] });
  }

  private toggleSource = (checkBox: CheckboxChangeEvent) => {
    this.setState({ noOtherSource: checkBox.target.checked });
  }

  private deleteSource = (index: any) => {
    const dataSource = [...this.state.sources];
    const value = dataSource[index];
    this.setState({ sources: dataSource.filter(item => item !== value) });
  }
}

export default NameTypeSources;