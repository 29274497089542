import { notification, Row, Col, Checkbox, Table, Typography } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import * as React from 'react';
import LookupsApiService from '../../../../api/LookupsApiService';
import DesignatedPositionDTO from '../../../../models/DesignatedPositionDTO';
import CodeLookupTableDTO from '../../../../models/CodeLookupTableDTO';
import PoliticalPositionsDTO from '../../../../models/PoliticalPositionsDTO';
import FormValidationUtil from '../../../../utils/FormValidationUtil';
import OtherBusinessDTO from '../../../../models/OtherBusinessDTO';
import PfdDTO from '../../../../models/PfdDTO';
import AddressFormFields from '../../../shared/AddressFormFields';
import SourcesDTO from '../../../../models/SourcesDTO';
import PfdTrustDTO from '../../../../models/PfdTrustDTO';
import PfdRetirementDTO from '../../../../models/PfdRetirementDTO';
import PfdCommissionsDTO from '../../../../models/PfdCommissionsDTO';
import CustomDatePicker from '../../../shared/CustomDatePicker';
import CustomForm from '../../../shared/CustomForm';

const { Column } = Table;
const { Paragraph, Title } = Typography;

interface PfdReviewProps {
  pfd: PfdDTO;
}

interface PfdReviewState {
  states: CodeLookupTableDTO[];
  designatedPosition: DesignatedPositionDTO;
  politicalPosition: PoliticalPositionsDTO[];
  otherBusinesses: OtherBusinessDTO[];
  securities: SourcesDTO[];
  financialInstitutions: SourcesDTO[];
  trusts: PfdTrustDTO[];
  realEstate: SourcesDTO[];
  retirements: PfdRetirementDTO[];
  otherSources: SourcesDTO[];
  commissions: PfdCommissionsDTO[];
}

class PfdReview extends React.Component<PfdReviewProps, PfdReviewState>
{
  constructor(props: PfdReviewProps) {
    super(props);

    this.state = {
      states: [],
      designatedPosition: this.props.pfd.designatedPosition || DesignatedPositionDTO.create(),
      politicalPosition: this.props.pfd.politicalPositions || [],
      otherBusinesses: this.props.pfd.otherBusinesses || [],
      securities: this.props.pfd.securities || [],
      financialInstitutions: this.props.pfd.financialInstitutions || [],
      trusts: this.props.pfd.trusts || [],
      realEstate: this.props.pfd.realEstate || [],
      retirements: this.props.pfd.retirements || [],
      otherSources: this.props.pfd.otherSources || [],
      commissions: this.props.pfd.commissions || [],
    };
  }

  componentDidMount() {
    this.fetchStates();
  }

  render() {
    return (
      <>
        <Title level={4}>
          Review
        </Title>
        <Row>
          <Col xs={24}>
            <Paragraph>
              Please review the information you have disclosed to ensure you have 
              completely and accurately disclosed all of the information requested.
            </Paragraph>
            <Paragraph>
              If you need to make changes, click the previous button to return to the page that you need to change.
            </Paragraph>
          </Col>
        </Row>
        <br />
        <Row>
          <Col xs={24}>
            <Title level={4}>
              Designated position(s) in the Executive Branch
            </Title>
            <Row>
              <Col xs={24}>
                <CustomForm initialValues={this.props.pfd.designatedPosition || undefined} layout="vertical">
                  <AddressFormFields
                    firstName={{ name: 'firstName', disabled: true}}
                    lastName={{ name: 'lastName', disabled: true }}
                    address1={{ name: 'address1', required: false, disabled: true }}
                    address2={{ name: 'address2', required: false, disabled: true }}
                    city={{ name: 'city', required: false, disabled: true }}
                    state={{ name: 'state', required: false, disabled: true }}
                    zip={{ name: 'zip', required: false, disabled: true }}
                    email={{ name: 'email', disabled: true }}
                    phone={{ name: 'phone', disabled: true }}
                  />
                  <FormItem name="yearCovered" label="Covered Year" rules={[FormValidationUtil.RequiredDate('Covered year is required')]}>
                    <CustomDatePicker picker="year" format="YYYY" disabled />
                  </FormItem>
                </CustomForm>
              </Col>
            </Row>
          </Col>
        </Row>
        <br/>
        <Row>
          <Col xs={24}>
            <Title level={4}>
              Government / Political Positions
            </Title>
            <Table dataSource={this.props.pfd.politicalPositions || undefined}>
              <Column title="State Agency" dataIndex="departmentOther" key="departmentOther" />
              <Column title="Division / Department" dataIndex="divisionOther" key="divisionOther" />
              <Column title="Position" dataIndex="positionOther" key="positionOther" />
            </Table>
          </Col>
        </Row>
        <br />
        <Row>
          <Col xs={24}>
            <Title level={4}>
              Other business, occupation or profession
            </Title>
            {
              this.state.otherBusinesses.length > 0 ?
                <Table dataSource={this.state.otherBusinesses}>
                  <Column title="Name" dataIndex="name" key="name" />
                  <Column title="Nature" dataIndex="nature" key="nature" />
                  <Column title="Position" dataIndex="position" key="position" />
                </Table>
                :
                <Checkbox checked={true} disabled>
                  If you had no other business, occupation or profession, click here
                </Checkbox>
            }
          </Col>
        </Row>
        <br />
        <Row>
          <Col xs={24}>
            <Title level={4}>
              Securities
            </Title>
            {
              this.state.securities.length > 0 ?
                <Table dataSource={this.state.securities}>
                  <Column title="Name" dataIndex="name" key="name" />
                  <Column title="Type" dataIndex="type" key="type" />
                </Table>
                :
                <Checkbox checked={true} disabled>
                  Click here if you had no securities income in excess of $1,000
                </Checkbox>
            }
          </Col>
        </Row>
        <br />
        <Row>
          <Col xs={24}>
            <Title level={4}>
              Instruments of Financial Institutions
            </Title>
            {
              this.state.financialInstitutions.length > 0 ?
                <Table dataSource={this.state.financialInstitutions}>
                  <Column title="Name" dataIndex="name" key="name" />
                  <Column title="Type" dataIndex="type" key="type" />
                </Table>
                :
                <Checkbox checked={true} disabled>
                  Click here if you had no income in excess of $1,000 from instruments of financial institutions
                </Checkbox>
            }
          </Col>
        </Row>
        <br />
        <Row>
          <Col xs={24}>
            <Title level={4}>
              Trusts
            </Title>
            {
              this.state.trusts.length > 0 ?
                <Table dataSource={this.state.trusts}>
                  <Column title="Name" dataIndex="name" key="name" />
                  <Column title="Trustor" dataIndex="trustor" key="trustor" />
                  <Column title="Type" dataIndex="type" key="key" />
                </Table>
                :
                <Checkbox checked={true} disabled>
                  Click here if you had no trust income in excess of $1,000
                </Checkbox>
            }
          </Col>
        </Row>
        <br />
        <Row>
          <Col xs={24}>
            <Title level={4}>
              Real Estate
            </Title>
            {
              this.state.realEstate.length > 0 ?
                <Table dataSource={this.state.realEstate}>
                  <Column title="Source" dataIndex="name" key="name" />
                  <Column title="Type" dataIndex="type" key="key" />
                </Table>
                :
                <Checkbox checked={true} disabled>
                  Click here if you had no income from real estate in excess of $1,000
                </Checkbox>
            }
          </Col>
        </Row>
        <br />
        <Row>
          <Col xs={24}>
            <Title level={4}>
              Retirement Systems
            </Title>
            {
              this.state.retirements.length > 0 ?
                <Table dataSource={this.state.retirements}>
                  <Column title="Name" dataIndex="name" key="name" />
                </Table>
                :
                <Checkbox checked={true} disabled>
                  Click here if you had no income from any retirement systems in excess of $1,000
                </Checkbox>
            }
          </Col>
        </Row>
        <br />
        <Row>
          <Col xs={24}>
            <Title level={4}>
              Other Sources of Income
            </Title>
            {
              this.state.otherSources.length > 0 ?
                <Table dataSource={this.state.otherSources}>
                  <Column title="Source" dataIndex="name" key="name" />
                  <Column title="Type" dataIndex="type" key="key" />
                </Table>
                :
                <Checkbox checked={true} disabled>
                  Click here if you had no other income in excess of $1,000
                </Checkbox>
            }
          </Col>
        </Row>
        <br />
        <Row>
          <Col xs={24}>
            <Title level={4}>
              Commissions from the sale of goods or services to a political division of the State
            </Title>
            {
              this.state.commissions.length > 0 ?
                <Table dataSource={this.state.commissions}>
                  <Column title="Name" dataIndex="name" key="name" />
                  <Column title="Description" dataIndex="description" key="description" />
                </Table>
                :
                <Checkbox checked={true} disabled>
                  Click here if you did not receive a commission from the sale of 
                  goods or services to a political subdivision during the covered year.
                </Checkbox>
            }
          </Col>
        </Row>
        <br />
      </>
    );
  }

  private fetchStates = () => {
    LookupsApiService.getStates()
      .then((states) => {
        states.sort((a, b) => (((a.code || '') > (b.code || '')) ? 1 : -1));

        this.setState({ states });
      })
      .catch(() => {
        notification.error({
          message: 'Failed to retrieve states.',
        });
      });
  }
}

export default PfdReview;