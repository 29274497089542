import {
  Button,
  Col,
  ColProps,
  Input,
  Layout,
  Modal,
  notification,
  Row,
  Select,
  Space,
  Typography,
  Table,
} from 'antd';
import Checkbox, { CheckboxChangeEvent } from 'antd/lib/checkbox/Checkbox';
import { FormInstance, FormProps } from 'antd/lib/form/Form';
import FormItem from 'antd/lib/form/FormItem';
import moment from 'moment';
import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import AdminOtcApiService from '../../../api/AdminOtcApiService';
import CampaignApiService from '../../../api/CampaignApiService';
import LookupsApiService from '../../../api/LookupsApiService';
import FilingStatusType from '../../../consts/FilingStatusType';
import Role from '../../../consts/Role';
import Routes from '../../../consts/Routes';
import CodeLookupTableDTO from '../../../models/CodeLookupTableDTO';
import OtcDTO from '../../../models/OtcDTO';
import OtcFunderDTO from '../../../models/OtcFunderDTO';
import CurrentUser from '../../../utils/CurrentUser';
import FormValidationUtil from '../../../utils/FormValidationUtil';
import HistoryUtil from '../../../utils/HistoryUtil';
import AddressFormFields from '../../shared/AddressFormFields';
import CurrencyInput from '../../shared/CurrencyInput';
import CustomDatePicker from '../../shared/CustomDatePicker';
import CustomForm from '../../shared/CustomForm';

const { Content } = Layout;
const { Option } = Select;
const { TextArea } = Input;
const { confirm } = Modal;
const { Column, ColumnGroup } = Table;

interface OtcProps {
  id: string;
}

interface OtcState {
  id: string,
  counties: CodeLookupTableDTO[];
  funderList: OtcFunderDTO[];
  loading: boolean;
  trustInfo: boolean;
  funderInfo: boolean;
  otcContribution: OtcDTO;
  funderLoading: boolean;
}

class OtcPage extends React.Component<RouteComponentProps<OtcProps> & FormProps, OtcState>{
  private readonly _formRef = React.createRef<FormInstance>();

  constructor(props: RouteComponentProps<OtcProps> & FormProps) {
    super(props);

    this.state = {
      id: props.match.params.id,
      counties: [],
      funderList: [],
      loading: true,
      trustInfo: false,
      funderInfo: false,
      otcContribution: OtcDTO.create({
        organizationState: 'IA',
        contactState: 'IA',
        committeeState: 'IA',
        trusteeState: 'IA',
        trustorState: 'IA',
        writtenFilingDate: moment.utc(),
      }),
      funderLoading: false,
    };
  }

  componentDidMount() {
    this.getInitialValues();
    this.fetchCounties();
  }

  private funderInfoFormRef = React.createRef<FormInstance>();

  render() {
    const columnSizingProps: ColProps = { xs: 24, sm: 24, md: 12 };
    const { loading, counties, otcContribution, id, } = this.state;

    return (
      <Content className="content-pad">
        <Typography.Title level={2}>OTC - Contribution</Typography.Title>
        {loading ? undefined :
          <CustomForm
            formRef={this._formRef}
            onFinish={this.submitForm}
            initialValues={otcContribution}
            layout="vertical"
            validateTrigger={['onChange', 'onBlur']}>
            <Row gutter={24}>
              <Col {...columnSizingProps}>
                <Typography.Title level={4}>Organization Details</Typography.Title>
                <AddressFormFields
                  name={{ name: 'organizationName' }}
                  address1={{ name: 'organizationAddress1' }}
                  address2={{ name: 'organizationAddress2' }}
                  city={{ name: 'organizationCity' }}
                  state={{ name: 'organizationState' }}
                  zip={{ name: 'organizationZip' }}
                  email={{ name: 'organizationEmail', required: false }}
                  phone={{ name: 'organizationPhone' }} />
              </Col>
              <Col {...columnSizingProps}>
                <Typography.Title level={4}>Organization Contact Details</Typography.Title>
                <AddressFormFields
                  firstName={{ name: 'contactFirstName' }}
                  middleInitial={{ name: 'contactMi' }}
                  lastName={{ name: 'contactLastName' }}
                  address1={{ name: 'contactAddress1' }}
                  address2={{ name: 'contactAddress2' }}
                  city={{ name: 'contactCity' }}
                  state={{ name: 'contactState' }}
                  zip={{ name: 'contactZip' }}
                  email={{ name: 'contactEmail' }}
                  phone={{ name: 'contactPhone' }} />
              </Col>
            </Row>
            <Row gutter={24}>
              <Col {...columnSizingProps}>
                <Typography.Title level={4}>Receiving Committee Details</Typography.Title>
                <AddressFormFields
                  name={{ name: 'committeeName' }}
                  address1={{ name: 'committeeAddress1' }}
                  address2={{ name: 'committeeAddress2' }}
                  city={{ name: 'committeeCity' }}
                  state={{ name: 'committeeState' }}
                  zip={{ name: 'committeeZip' }}
                  email={{ name: 'committeeEmail', required: false }}
                  phone={{ name: 'committeePhone' }} />
              </Col>
              <Col {...columnSizingProps}>
                <Typography.Title level={4}>Contribution Details</Typography.Title>
                <Row gutter={8}>
                  <Col flex="1">
                    <FormItem
                      name="dateOfContribution"
                      label="Date"
                      rules={[FormValidationUtil.RequiredDate('Date of the contribution is required')]}>
                      <CustomDatePicker onChange={(d) => d?.startOf('day')}/>
                    </FormItem>
                  </Col>
                  <Col flex="1">
                    <FormItem
                      name="amount"
                      label="Amount"
                      rules={[
                        FormValidationUtil.RequiredNumber('Amount of the contribution is required'),
                        FormValidationUtil.PositiveNumber('Amount of the contribution must be positive')
                      ]}>
                      <CurrencyInput />
                    </FormItem>
                  </Col>
                </Row>
                <FormItem name="dateOfElection" label="Election Date">
                  <CustomDatePicker onChange={(d) => d?.startOf('day')}/>
                </FormItem>
                <FormItem name="county" label="County">
                  <Select>
                    <Option value="">-- Select County --</Option>
                    {counties.map(c => (
                      <Option key={c.code || ''} value={c.code || ''}>{c.name}</Option>
                    ))}
                  </Select>
                </FormItem>
                <FormItem name="corpFundInd" valuePropName="checked">
                  <Checkbox>Corporate Funded</Checkbox>
                </FormItem>
                <FormItem name="inKindInd" valuePropName="checked">
                  <Checkbox>In-Kind Contribution</Checkbox>
                </FormItem>
                <FormItem name="inKindDescription" label="In-Kind Description">
                  <TextArea rows={3} />
                </FormItem>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col {...columnSizingProps}>
                <Typography.Title level={4}>Trust Information</Typography.Title>
                <Checkbox checked={this.state.trustInfo} onChange={this.toggleTrust} style={{ marginBottom: 20 }}>
                  Click here if the trust used funds from a person or entity other than the trustor to make the contribution
                </Checkbox>
                {
                  !this.state.trustInfo ? undefined :
                    <Row gutter={24}>
                      <Col {...columnSizingProps}>
                        <Typography.Title level={5}>Trustee Information</Typography.Title>
                        <AddressFormFields
                          name={{ name: 'trusteeName', maxLength: 255 }}
                          address1={{ name: 'trusteeAddress1' }}
                          address2={{ name: 'trusteeAddress2' }}
                          city={{ name: 'trusteeCity' }}
                          state={{ name: 'trusteeState' }}
                          zip={{ name: 'trusteeZip' }} />
                      </Col>
                      <Col {...columnSizingProps}>
                        <Typography.Title level={5}>Trustor Information</Typography.Title>
                        <AddressFormFields
                          name={{ name: 'trustorName', maxLength: 255 }}
                          address1={{ name: 'trustorAddress1' }}
                          address2={{ name: 'trustorAddress2' }}
                          city={{ name: 'trustorCity' }}
                          state={{ name: 'trustorState' }}
                          zip={{ name: 'trustorZip' }} />
                      </Col>
                    </Row>
                }
              </Col>
              <Col {...columnSizingProps}>
                {
                  this.state.trustInfo &&
                  <>
                    <Typography.Title level={4}>Funder Information</Typography.Title>
                    <Checkbox checked={this.state.funderInfo} onChange={this.toggleFunder} style={{ marginBottom: 20 }}>
                      Click here if funds received other than trustor
                    </Checkbox>
                    {
                      this.state.funderInfo &&
                      <>
                        <CustomForm
                          formRef={this.funderInfoFormRef}
                          initialValues={OtcFunderDTO.create({ funderState: 'IA' })}
                          layout="vertical" onFinish={this.addFunder}>
                          <AddressFormFields
                            name={{ name: 'funderName', maxLength: 255 }}
                            address1={{ name: 'funderAddress1' }}
                            address2={{ name: 'funderAddress2' }}
                            city={{ name: 'funderCity' }}
                            state={{ name: 'funderState' }}
                            zip={{ name: 'funderZip' }} />
                          <FormItem
                            name="funderAmount"
                            label="Amount"
                            rules={[
                              FormValidationUtil.RequiredNumber('Funder amount is required'),
                              FormValidationUtil.PositiveNumber('Amount of the contribution must be positive')]
                            }>
                            <CurrencyInput />
                          </FormItem>
                          <Button type="primary" onClick={this.funderFinished}>Add a source</Button>
                        </CustomForm>
                        {
                          this.state.funderList.length > 0 &&
                          <>
                            <br />
                            <Table
                              dataSource={this.state.funderList}
                              locale={{ emptyText: 'Currently there are no funders on this contribution.' }}
                              size="small"
                              pagination={false}>
                              <Column title="Name" dataIndex="funderName" key="funderName" />
                              <ColumnGroup title="Address">
                                <Column dataIndex="funderAddress1" key="funderAddress1" />
                                <Column dataIndex="funderAddress2" key="funderAddress2" />
                                <Column dataIndex="funderCity" key="funderCity" />
                                <Column dataIndex="funderState" key="funderState" />
                                <Column dataIndex="funderZip" key="funderZip" />
                              </ColumnGroup>
                              <Column title="Amount" dataIndex="funderAmount" key="funderAmount" />
                            </Table>
                          </>
                        }
                      </>
                    }
                  </>
                }
              </Col>
            </Row>
            {
              otcContribution.filingStatus !== FilingStatusType.AUDITED
                && otcContribution.filingStatus !== FilingStatusType.ADJUSTED?
                <>
                  <br />
                  <Typography.Title level={4}>Review the Terms of Agreement</Typography.Title>
                  <Typography.Paragraph>Criteria to use this form:</Typography.Paragraph>
                  <ol>
                    <li>
                      One campaign contribution in excess on $1000 was made to a state
                      or local candidate, party, political committee (PAC)
                      for either a candidate or a ballot issue, or any other organization that
                      advocates the election or defeat of a candidate or ballot issue.
                    </li>
                    <li>
                      Must be a permanent organization not established for election purposes.
                    </li>
                    <li>
                      No person gave money to the organization for the purpose of
                      the organization then using the money to make the contribution.
                    </li>
                    <li>
                      If the contribution was made to a candidate, no funds from a corporation
                      or other prohibited contributor under Iowa Code section 68A.15
                      were used to make the contribution (this does not apply to a contribution
                      made to a ballot issue committee).
                    </li>
                    <li>
                      Only one contribution to any campaign committee except for a ballot issue
                      committee was made in a calendar year. No more than one contribution
                      may be made to any one ballot issue committee during a ballot issue election.
                      If more than one contribution and the total amount of the contributions
                      exceeds $1000, the organization must register with the Board as a political
                      committee (PAC) within 10 days of crossing the $1000 threshold.
                    </li>
                  </ol>
                  <Typography.Title level={4}>Statement of Affirmation:</Typography.Title>
                  I affirm that the contribution reported above is accurate.
                  I further affirm that the information concerning the organization is correct and true to the best
                  of my knowledge. I understand that by filing this form, I am subject to the laws found in Iowa Code
                  chapter 68A and administrative rules found in chapter 351.
                  I also understand that the failure to timely file this form leads to the imposition of civil penalties
                  and the intentional failure to file the form may lead to
                  additional civil and criminal sanctions.
                  <br /><br />
                  <Row>
                    <Col>
                      {
                        id == null ?
                          <>
                            <Space>
                              <Button type="primary" htmlType="submit">I Agree</Button>
                              <Button onClick={this.cancelEdit}>Cancel</Button>
                            </Space>
                          </> :
                          <>
                            <Space>
                              <Button type="primary" onClick={() => this.updateStatus(id || '', FilingStatusType.ADJUSTED)}>Adjust</Button>
                              <Button type="primary" onClick={() => this.updateStatus(id || '', FilingStatusType.AUDITED)}>Approve</Button>
                              <Button onClick={() => this.confirmReject(id || '', otcContribution.organizationName || '')}>Reject</Button>
                              <Button onClick={this.cancelEdit}>Cancel</Button>
                            </Space>
                          </>
                      }
                    </Col>
                  </Row>
                </> :
                <>
                  <br /><br />
                  <Row>
                    <Col span={24}>
                      <FormItem
                        name="writtenFilingDate"
                        label="Received Date"
                        rules={[FormValidationUtil.RequiredDate('Received date is required')]}>
                        <CustomDatePicker onChange={(d) => d?.startOf('day')}/>
                      </FormItem>
                      <FormItem name="note" label="Note">
                        <TextArea rows={3} />
                      </FormItem>
                      <Space>
                        <Button type="primary" onClick={() => this.updateStatus(id || '', FilingStatusType.ADJUSTED)}>Adjust</Button>
                        <Button onClick={() => this.editPenalty(id)}>Penalty</Button>
                        <Button danger onClick={() => this.confirmDelete(id, otcContribution.organizationName || '')}>Delete</Button>
                        <Button onClick={this.cancelEdit}>Cancel</Button>
                      </Space>
                    </Col>
                  </Row>
                </>
            }
          </CustomForm>
        }
      </Content>
    );
  }

  private submitForm = (values: any) => {
    if (this.state.funderInfo && this.state.funderList.length == 0) {
      notification.error({ message: 'If Funder Information is selected, at least one funder must be listed' });
    } else {
      const funderList = OtcFunderDTO.create({ funderList: this.state.funderList });
      return CampaignApiService.addOtc({ ...this.state.otcContribution, ...values, ...funderList })
        .then(() => {
          if (!this.state.id) {
            if (CurrentUser.Get()?.isInRole(Role.IECDB_ADMINISTRATOR)) {
              HistoryUtil.push(Routes.MANUAL_REGISTRATION);
            }
            else {
              HistoryUtil.push(Routes.LOGIN);
            }
              notification.success({
                message: 'Your report submitted successfully.',
                description: 'You will be contacted by the IECDB staff upon approval. Thank You.'
              });
          }
        })
        .catch(error => {
          notification.error({ message: error.message });
        });
    }

    return Promise.resolve();
  }

  private addFunder = (values: any) => {
    this.setState({ funderList: [...this.state.funderList, values] });
    this.funderInfoFormRef.current?.resetFields();
  }

  private fetchCounties = () => {
    LookupsApiService.getCounties()
      .then((counties) => {
        this.setState({ counties });
      })
      .catch(() => {
        notification.error({
          message: 'Failed to retrieve counties.',
        });
      });
  }

  private toggleTrust = (trust: CheckboxChangeEvent) => {
    if (this.state.trustInfo) {
      this.setState({ funderInfo: false });
      this.funderInfoFormRef.current?.resetFields();
    }
    this.setState({ trustInfo: trust.target.checked });
  }

  private toggleFunder = (fund: CheckboxChangeEvent) => {
    this.setState({ funderInfo: fund.target.checked });
    this.funderInfoFormRef.current?.resetFields();
  }

  private funderFinished = () => {
    this.funderInfoFormRef.current?.submit();
  }

  private getInitialValues = () => {
    if (!this.state.id) {
      this.setState({ loading: false });
      return;
    }

    this.setState({ loading: true });
    AdminOtcApiService.getOtcContribution(this.state.id).then(otcContribution => {
      if (otcContribution.dateOfContribution) {
        otcContribution.dateOfContribution = moment(otcContribution.dateOfContribution);
      }

      if (otcContribution.dateOfElection) {
        otcContribution.dateOfElection = moment(otcContribution.dateOfElection);
      }

      if (otcContribution.writtenFilingDate) {
        otcContribution.writtenFilingDate = moment(otcContribution.writtenFilingDate);
      }

      this.setState({
        otcContribution: otcContribution,
        loading: false,
        trustInfo: (!!otcContribution.trusteeName),
        funderList: otcContribution.funderList || [],
        funderInfo: !!otcContribution.funderList && otcContribution.funderList.length > 0
      });
    }).catch(() => {
      this.setState({ loading: false });
    });
  }

  private editPenalty = async (id: string) => {
    HistoryUtil.push(Routes.generate(Routes.OTC_PENALTY, { id: id }));
  }

  private updateStatus = (contributionId: string, status: string) => {
    this._formRef.current?.validateFields().then(() => {
      this.submitForm(this._formRef.current?.getFieldsValue()).then(() =>
        AdminOtcApiService.updateStatus(contributionId, status)
          .then(() => {
            HistoryUtil.push(Routes.MANAGE_OTC);
            notification.success({
              message: 'Your changes were saved sucessfully',
            });
          }).catch(() => {
            notification.error({
              message: 'Failed to update status.'
            });
          }));
    }).catch(reason => {
      this._formRef.current?.scrollToField(reason.errorFields[0].name);
      window.scrollBy(0, -150);
    });
  };

  private confirmReject = (contributionId: string, organizationName: string) => {
    confirm({
      title: 'Are you sure you want to reject this OTC?',
      content: 'The following record will be rejected: ' + organizationName,
      onOk: () => {
        this.updateStatus(contributionId, FilingStatusType.DELETED);
      },
    });
  }

  private confirmDelete = (contributionId: string, organizationName: string) => {
    confirm({
      title: 'Are you sure you want to delete this OTC?',
      content: 'The following record will be permanently deleted: ' + organizationName,
      onOk: () => {
        this.deleteOtc(contributionId);
      },
    });
  }

  private deleteOtc = (contributionId: string) => {
    AdminOtcApiService.deleteOtc(contributionId)
      .then(() => {
        HistoryUtil.push(Routes.MANAGE_OTC);
        notification.success({ message: 'OTC was deleted successfully.' });
      }).catch(() => {
        notification.error({ message: 'Failed to delete OTC.' });
      });
  }

  private cancelEdit = () => {
    confirm({
      title: 'Are you sure you want to leave?',
      okText: 'Yes',
      cancelText: 'No',
      onOk: () => {
        HistoryUtil.push(this.props.match.params.id ? Routes.MANAGE_OTC :
            CurrentUser.Get()?.isInRole(Role.IECDB_ADMINISTRATOR) ? Routes.MANUAL_REGISTRATION : Routes.LOGIN);
      }
    });
  }
}

export default OtcPage;
