// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import EntitySelectorDTO from './EntitySelectorDTO';

interface CommitteeUploadReportDTO extends EntitySelectorDTO { 
  id: number;
  reportType: number;
  committeeId: string | null;
  blobFileName: string | null;
  userFileName: string | null;
  description: string | null;
  generatedOn: moment.Moment | string;
}
const CommitteeUploadReportDTO = {
  create: (initValues?: Partial<CommitteeUploadReportDTO> | Record<string, unknown> | null) => {
    return Object.assign(EntitySelectorDTO.create(),
    {
      id: 0,
      reportType: 0,
      committeeId: null,
      blobFileName: null,
      userFileName: null,
      description: null,
      generatedOn: new Date(0).toISOString(),
    },
    initValues);
  }
};

export default CommitteeUploadReportDTO;