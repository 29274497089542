import { Button, Col, Layout, Modal, notification, Row } from 'antd';
import * as React from 'react';
import GiftBequestApiService from '../../api/GiftBequestApiService';
import GbStatus from '../../consts/GbStatus';
import Routes from '../../consts/Routes';
import AdminGiftBequestListDTO from '../../models/AdminGiftBequestListDTO';
import HistoryUtil from '../../utils/HistoryUtil';
import DataTable, { DataTableColumnProps } from '../shared/DataTable/DataTable';
import DataTableButtonUtil from '../shared/DataTable/DataTableButtonUtil';
import DataTableColumnUtil from '../shared/DataTable/DataTableColumnUtil';

const { Content } = Layout;
const { confirm } = Modal;

interface DonorSummaryProps {

}

interface DonorSummaryState {
  tableColumns: DataTableColumnProps<AdminGiftBequestListDTO>[];
}

class DonorSummary extends React.Component<DonorSummaryProps, DonorSummaryState> {
  private dataTable: DataTable<AdminGiftBequestListDTO> | undefined;

  constructor(props: DonorSummaryProps) {
    super(props);

    this.state = {
      tableColumns: this.getTableColumns(),
    };
  }

  render() {
    const actionButtons: any = [];
    actionButtons.push(DataTableButtonUtil.Reset());

    return (
      <Content className="content-pad">
        <Row>
          <Col xs={24}>
            <DataTable
              ref={(element: any) => (this.dataTable = element)}
              serverSide={true}
              tableProps={{
                rowKey: 'giftId',
                sortDirections: ['ascend', 'descend'],
                locale: {
                  emptyText: 'Currently there are no Gift Bequests that need to be approved'
                }
              }}
              globalSearch={true}
              title="GB - Gift/Bequest Summary"
              buttonBar={actionButtons}
              columns={this.state.tableColumns}
              fetchData={{
                fetch: function (tableRequest) { return GiftBequestApiService.getGiftBequestList(tableRequest); },
                failureMessage: 'Failed to retrieve Gift Bequests'
              }}
              styleOptions={{ compact: true, alternatingRowHighlight: true }}
            />
          </Col>
        </Row>
      </Content>
    );
  }

  private getTableColumns() {
    const columns: DataTableColumnProps<AdminGiftBequestListDTO>[] = [];

    const columnDate: DataTableColumnProps<AdminGiftBequestListDTO> = DataTableColumnUtil.Date('Date', 'giftDate');
    columns.push(columnDate);

    const columnDonorName: DataTableColumnProps<AdminGiftBequestListDTO> = DataTableColumnUtil.Text('Donor', 'donorName');
    columns.push(columnDonorName);

    const columnAmount: DataTableColumnProps<AdminGiftBequestListDTO> = DataTableColumnUtil.Currency('Amount', 'amount');
    columns.push(columnAmount);

    const columnDescription: DataTableColumnProps<AdminGiftBequestListDTO> = DataTableColumnUtil.Text('Description', 'description');
    columns.push(columnDescription);

    const columnStatus: DataTableColumnProps<AdminGiftBequestListDTO> = DataTableColumnUtil.DropdownMulti('Status', 'status', [
      { text: 'Saved', value: GbStatus.SAVED.toString() },
      { text: 'Submitted', value: GbStatus.SUBMITTED.toString() },
      { text: 'Approved', value: GbStatus.APPROVED.toString() },
      { text: 'Rejected', value: GbStatus.REJECTED.toString() },
      { text: 'Adjusted', value: GbStatus.ADJUSTED.toString() }
    ]);
    columns.push(columnStatus);

    const columnButtons: DataTableColumnProps<AdminGiftBequestListDTO> = { // DataTableColumnUtil.Buttons('giftId', tableButtons, 220);
      dataIndex: 'giftId',
      render: (text, record) => {
        const tableButtons = [];
        if (record.status != 'Submitted' && record.status != 'Rejected') {
          tableButtons.push({ text: 'View', onClick: (rowData: AdminGiftBequestListDTO) => this.view(rowData.giftId || '') });
        }
        else {
          tableButtons.push({ text: 'Edit', onClick: (rowData: AdminGiftBequestListDTO) => this.edit(rowData.giftId || '') });
          tableButtons.push({
            text: 'Delete',
            onClick: (rowData: AdminGiftBequestListDTO) => this.confirmDelete(rowData.giftId || '', rowData.donorName || '')
          });
        }

        return (
          <div className="table-row-button-group">
            {tableButtons.map((b, index) => (
              <Button key={index} onClick={() => b.onClick(record)} size={'small'}>
                {b.text}
              </Button>
            ))}
          </div>
        );
      },
      width: 150,
      align: 'center'
    };

    columns.push(columnButtons);

    return columns as DataTableColumnProps<AdminGiftBequestListDTO>[];
  }

  private view = async (giftId: string) => {
    HistoryUtil.push(Routes.generate(Routes.EDIT_GIFT_BEQUEST, { 'giftId': giftId }), { disabled: 'true' });
  }

  private edit = async (giftId: string) => {
    HistoryUtil.push(Routes.generate(Routes.EDIT_GIFT_BEQUEST, { 'giftId': giftId }));
  }

  private delete = (giftId: string) => {
    GiftBequestApiService.delete(giftId)
      .then(() => {
        if (this.dataTable) {
          this.dataTable.refresh();
        }
        notification.success({
          message: 'The Gift/Bequest was deleted successfully.'
        });
      }).catch(() => {
        notification.error({
          message: 'Failed to delete the Gift/Bequest.'
        });
      });
  }

  private confirmDelete = (giftId: string, commiteeName: string) => {
    confirm({
      title: 'Are you sure you want to delete this gift bequest?',
      content: 'The following record will be permanently deleted: ' + commiteeName,
      onOk: () => {
        this.delete(giftId);
      },
    });
  }

}

export default DonorSummary;

