import { Modal, notification } from 'antd';
import * as React from 'react';
import TransactionTypeIndicators from '../../consts/TransactionTypeIndicators';
import SearchableDatabaseDTO from '../../models/SearchableDatabaseDTO';
import SearchableDatabaseApiService  from '../../api/SearchableDatabaseApiService';
import moment from 'moment';
import TransactionTypeStatuses from '../../consts/TransactionTypeStatuses';
import LoanReceivedRefDataTable from './LoanReceivedRefDataTable';
import TransactionRefDesc from './TransactionRefDesc';
import CurrentUser from '../../utils/CurrentUser';

interface CrossReferenceModalProps {
  referenceId: string;
  referenceType: string;
  onClose: () => void;
}

interface CrossReferenceModalState {
  transactionInfo: SearchableDatabaseDTO[];
  loading: boolean;
}

class CrossReferenceModal extends React.Component<CrossReferenceModalProps, CrossReferenceModalState> {
  constructor(props: CrossReferenceModalProps) {
    super(props);
    this.state = {
      transactionInfo: [SearchableDatabaseDTO.create()],
      loading: false,
    };
  }

  componentDidMount() {
    const { referenceId, referenceType } = this.props;
    this.getTransaction(referenceId, referenceType);
  }

  render() {
    const { transactionInfo, loading } = this.state;
    const { referenceType } = this.props;

    let title = '';
      if (referenceType === TransactionTypeIndicators.CONTRIBUTION) {
          title = TransactionTypeStatuses.CONTRIBUTION;
      }
      else if (referenceType === TransactionTypeIndicators.INKINDCONTRIBUTION) {
          title = TransactionTypeStatuses.INKINDCONTRIBUTION;
      }
      else if (referenceType === TransactionTypeIndicators.EXPENDITURE) {
          title = TransactionTypeStatuses.EXPENDITURE;
      }
      else {
          title = TransactionTypeStatuses.LOANRECEIVED;
      }

    return (
      <>
        {
          !loading && (
            <Modal
              title={title}
              cancelButtonProps={{ style: { display: 'none' } }}
              onOk={this.props.onClose}
              destroyOnClose={true}
              closable={false}
              visible={true}
              width={900}
            >
                { referenceType == TransactionTypeIndicators.LOANRECEIVED ?
                    <LoanReceivedRefDataTable transactionInfo={transactionInfo} />
                    : <TransactionRefDesc referenceType={referenceType} transactionInfo={transactionInfo[0]} />
                }
            </Modal > )
        }
      </>
    );
  }

  private getTransaction = (referenceId: string, referenceType: string) => {
      this.setState({ loading: true });
        SearchableDatabaseApiService.getReference(referenceId, referenceType, CurrentUser.Get()?.dr2Id || '')
            .then(transactionInfo => {
                transactionInfo.forEach(t => {
                    t.date = moment.utc(t.date).format('MM/DD/YYYY');
                });
                this.setState({ transactionInfo, loading: false });
            }).catch(() => {
                notification.error({
                    message: 'Unable to find cross reference with id: ' + referenceId
                });
                this.setState({ loading: false });
                this.props.onClose();
            });
  }
}

export default CrossReferenceModal;