// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
import BaseApi from './BaseApi';
import DonorRegistrationDTO from '../models/DonorRegistrationDTO';
import GbReceivingDepartmentDTO from '../models/GbReceivingDepartmentDTO';

export class DonorProfileApiService extends BaseApi {

  // get: api/donor/profile?donorId=${encodeURIComponent(donorId)}
  public get(donorId: string): Promise<GbReceivingDepartmentDTO> {
    let url = `api/donor/profile`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (donorId != null) {
      url += `${prefix}donorId=${encodeURIComponent(donorId)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, GbReceivingDepartmentDTO>(null, 'get', url, true, false);
  }

  // put: api/donor/profile
  public edit(donorRegistrationEntry: DonorRegistrationDTO): Promise<void> {
    let url = `api/donor/profile`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, void>(donorRegistrationEntry, 'put', url, true, false);
  }
}
var service = new DonorProfileApiService();
export default service;
