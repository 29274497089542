import { notification, FormInstance, Row, Col, Input, Checkbox, Button, Table, Typography, Tooltip, } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import * as React from 'react';
import FormValidationUtil from '../../../../utils/FormValidationUtil';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import PfdTrustDTO from '../../../../models/PfdTrustDTO';
import CustomForm from '../../../shared/CustomForm';

const { Paragraph, Title } = Typography;
const { Column } = Table;

interface TrustProps {
  noOtherTrusts: boolean;
  trusts: PfdTrustDTO[];
}

interface TrustState {
  noOtherTrust: boolean;
  trusts: PfdTrustDTO[];
}

class Trust extends React.Component<TrustProps, TrustState>
{
  private readonly _formRef = React.createRef<FormInstance>();

  constructor(props: TrustProps) {
    super(props);

    this.state = {
      noOtherTrust: this.props.noOtherTrusts,
      trusts: this.props.trusts || [],
    };
  }

  moveBack = async (): Promise<{ model: { trusts: PfdTrustDTO[], noOtherTrust: boolean }, errorFields: any }> => {
    return {
      model: { trusts: this.state.trusts, noOtherTrust: this.state.noOtherTrust }, errorFields: null
    };
  }

  validate = async (): Promise<{ model: { trusts: PfdTrustDTO[], noOtherTrust: boolean }, errorFields: any }> => {
    if (this.state.trusts.length > 0 || this.state.noOtherTrust) {
      return {
        model: { trusts: this.state.trusts, noOtherTrust: this.state.noOtherTrust }, errorFields: null
      };
    } else {
      notification.error({
        message: 'You must list at least one trust, or select no trust'
      });
      return Promise.reject();
    }
  }

  render() {
    return (
      <>
        <Title level={4}>
         Trusts
        </Title>
        <Paragraph>
          Enter the name of each trust that generated gross income in excess of $1,000 in the covered year.
        </Paragraph>
        <Row>
          <Col xs={24}>
            <CustomForm formRef={this._formRef} onFinish={this.addTrust} layout="vertical">
              <Tooltip title="e.g. John Doe Family Trust">
                <FormItem name="name" label="Name of Trust" rules={[FormValidationUtil.Required('Name of Trust is required')]}>
                  <Input type="text" />
                </FormItem>
              </Tooltip>
              <Tooltip title="i.e. person or entity that created trust">
                <FormItem name="trustor" label="Trustor" rules={[FormValidationUtil.Required('Trustor is required')]}>
                  <Input type="text" />
                </FormItem>
              </Tooltip>
              <Tooltip title="e.g. testamentary, irrevocable">
                <FormItem name="type" label="Type of Trust" rules={[FormValidationUtil.Required('Type of Trust is required')]}>
                  <Input type="text" />
                </FormItem>
              </Tooltip>
              <Button type="primary" htmlType="submit">Add</Button>
            </CustomForm>
          </Col>
        </Row>
        <br />
        {
          this.state.trusts.length > 0 ?
            <Row>
              <Col xs={24}>
                <Table dataSource={this.state.trusts}>
                  <Column title="Name" dataIndex="name" key="name" />
                  <Column title="Trustor" dataIndex="trustor" key="trustor" />
                  <Column title="Type" dataIndex="type" key="key" />
                  <Column
                    title="Delete"
                    dataIndex=""
                    render={(text, record, index) =>
                      <Button onClick={() => { this.deleteTrust(index); }}>Delete</Button>
                    }
                  />
                </Table>
              </Col>
            </Row>
            :
            <>
              <Row>
                <Col>
                  <Checkbox className="bold-checkbox" checked={this.state.noOtherTrust} onChange={this.toggleTrust}>
                    Click here if you had no trust income in excess of $1,000
                  </Checkbox>
                </Col>
              </Row>
              <br />
            </>
        }
      </>
    );
  }

  private addTrust = (values: any) => {
    this._formRef.current?.resetFields();
    this.setState({ trusts: [...this.state.trusts, values] });
  }

  private toggleTrust = (checkBox: CheckboxChangeEvent) => {
    this.setState({ noOtherTrust: checkBox.target.checked });
  }

  private deleteTrust = (index: any) => {
    const dataSource = [...this.state.trusts];
    const value = dataSource[index];
    this.setState({ trusts: dataSource.filter(item => item !== value) });
  }
}

export default Trust;