import { Divider } from 'antd';
import * as React from 'react';
import SchGConsultantsApiService from '../../api/SchGConsultantsApiService';
import SchGConsultantsDTO from '../../models/SchGConsultantsDTO';
import DataTableColumnUtil from '../shared/DataTable/DataTableColumnUtil';
import DataTable, { DataTableColumnProps } from '../shared/DataTable/DataTable';
import Dr2AmendedStatuses from '../../consts/Dr2AmendedStatuses';
import Dr2AmendedIndicators from '../../consts/Dr2AmendedIndicators';

interface SchGConsultantHistoryTableProps {
  consultantSummaryId: string;
}

interface SchGConsultantHistoryTableState {
}

class SchGConsultantHistoryTable extends React.Component<SchGConsultantHistoryTableProps, SchGConsultantHistoryTableState> {
  constructor(props: SchGConsultantHistoryTableProps) {
    super(props);

    this.state = {
    };
  }

  render() {
    const { consultantSummaryId } = this.props;
    return (
      <>
        <Divider/>
        <DataTable
          columns={this.getTableColumns()}
          fetchData={{
            fetch: function (tableRequest) { return SchGConsultantsApiService.getConsultantHistory(tableRequest, consultantSummaryId); },
            failureMessage: 'Failed to retrieve consultant history'
          }}
          serverSide={true}
          styleOptions={{ compact: true, alternatingRowHighlight: true }}

          tableProps={{
            rowKey: 'consultantSummaryIdAndSeq',
            sortDirections: ['ascend', 'descend'],
            locale: { emptyText: 'Consultant history not available.' }
          }}
          title="Consultants"
        />
      </>
    );
  }

  private getTableColumns = (): DataTableColumnProps<SchGConsultantsDTO>[] => {
    const tableColumns: DataTableColumnProps<SchGConsultantsDTO>[] = [
      DataTableColumnUtil.Date('From Date', 'fromDate', 100),
      DataTableColumnUtil.Date('To Date', 'toDate', 100),
      DataTableColumnUtil.Address('Consultant', 'consultantName',
        (c) => ({
          name: c.consultantName,
          line1: c.consultantAddressLine1,
          line2: c.consultantAddressLine2,
          city: c.consultantCity,
          state: c.consultantState,
          zip: c.consultantZip
        })
      ),
      DataTableColumnUtil.Currency('Anticipated Compensation', 'compensationAmt'),
      DataTableColumnUtil.DropdownMulti('Status', 'status',
        [
          { text: Dr2AmendedStatuses.ORIGINAL, value: Dr2AmendedIndicators.ORIGINAL },
          { text: Dr2AmendedStatuses.AMENDED, value: Dr2AmendedIndicators.AMENDED },
          { text: Dr2AmendedStatuses.ADJUSTED, value: Dr2AmendedIndicators.ADJUSTED },
          { text: Dr2AmendedStatuses.DELETED, value: Dr2AmendedIndicators.DELETED }
        ])
    ];
    return tableColumns;
  }
}

export default SchGConsultantHistoryTable;
