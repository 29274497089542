import UserSecurityDTO from './UserSecurityDTO';

export default class   {
  public readonly userId: number;

  public readonly userName: string | null;

  public readonly email: string | null;

  public readonly firstName: string | null;

  public readonly lastName: string | null;

  public readonly committeeName: string | null;

  public readonly committeeId: string | null;

  public readonly receivingDepartmentId: string | null;

  public readonly originalCommitteeId: string | null;

  public readonly committeeType: string | null;

  public readonly committeeTypeName: string | null;

  public readonly filerTypeId: number | null;

  public readonly dr2Id: string | null;

  public readonly auditing: boolean | null;

  public readonly committeeStatusType: string | null;

  public readonly committeeFilingCatType: string | null;

  public readonly hasPenalties: boolean | null; 

  private readonly roles: ReadonlyArray<number>;

  constructor(userinfo: UserSecurityDTO) {
    this.userId = userinfo.userId;
    this.userName = userinfo.userName;
    this.email = userinfo.email;
    this.firstName = userinfo.firstName;
    this.lastName = userinfo.lastName;
    this.committeeId = userinfo.committeeId;
    this.receivingDepartmentId = userinfo.receivingDepartmentId;
    this.originalCommitteeId = userinfo.originalCommitteeId;
    this.committeeName = userinfo.committeeName;
    this.committeeType = userinfo.committeeType;
    this.committeeTypeName = userinfo.committeeTypeName;
    this.filerTypeId = userinfo.filerTypeId;
    this.dr2Id = userinfo.dr2Id;
    this.roles = Object.freeze(userinfo.roles);
    this.auditing = userinfo.auditing;
    this.committeeStatusType = userinfo.committeeStatusType;
    this.committeeFilingCatType = userinfo.committeeFilingCatType;
    this.hasPenalties = userinfo.hasPenalties;
  }

  isInRole(role: number) {
    return this.roles.indexOf(role) >= 0;
  }

  getRoles() {
    return this.roles.slice(0);
  }
}
