import {
  Button,
  Modal,
} from 'antd';
import { FormInstance } from 'antd/lib/form/Form';
import FormItem from 'antd/lib/form/FormItem';
import * as React from 'react';
import FormValidationUtil from '../../../utils/FormValidationUtil';
import OieContributionDTO from '../../../models/OieContributionDTO';
import DataTable, { DataTableColumnProps } from '../../shared/DataTable/DataTable';
import Guid from '../../../utils/Guid';
import DataTableColumnUtil from '../../shared/DataTable/DataTableColumnUtil';
import AddressFormFields from '../../shared/AddressFormFields';
import CurrencyInput from '../../shared/CurrencyInput';
import CustomDatePicker from '../../shared/CustomDatePicker';
import CustomForm from '../../shared/CustomForm';

const { confirm } = Modal;

interface OieContributionProps {
  contributionList: OieContributionDTO[];
  onChange?: (contributionList: OieContributionDTO[] | []) => void;
}

interface OieContributionState {
  isModalVisible: boolean;
  expendId: string;
  indContributionsId: string;
  contributionList: OieContributionDTO[];
  columnList: [];
}

class OieContribution extends React.Component<OieContributionProps, OieContributionState>
{
  private formRef = React.createRef<FormInstance>();
  private dataTable: DataTable<OieContributionDTO> | undefined;

  constructor(props: OieContributionProps) {
    super(props);

    this.state = {
      indContributionsId: '',
      expendId: '',
      isModalVisible: false,
      contributionList: [...props.contributionList],
      columnList: [],
    };
  }

  render() {
    const { isModalVisible } = this.state;
    const Buttons = [
      { text: 'Edit', onClick: (rowData: OieContributionDTO) => this.edit(rowData.indContributionId || '') },
      { text: 'Delete', onClick: (rowData: OieContributionDTO) => this.confirmDelete(rowData.indContributionId || '', rowData.name || '') }
    ];

    const columnList: DataTableColumnProps<OieContributionDTO>[] =[
      DataTableColumnUtil.Date('Date', 'date', 80),
      DataTableColumnUtil.Text('Name', 'name', null),
      DataTableColumnUtil.Text('Address', 'address1', null),
      DataTableColumnUtil.Text('Address', 'address2', null),
      DataTableColumnUtil.Text('City', 'city', null),
      DataTableColumnUtil.Text('State', 'state', null),
      DataTableColumnUtil.Text('Zip Code', 'zip', null),
      DataTableColumnUtil.Text('Amount', 'amount', null),
      DataTableColumnUtil.Buttons('indContributionId ', Buttons, 150), 
    ];
    
    return (
      <>
        <Modal title="Add Source" visible={isModalVisible} onOk={this.submitContForm} onCancel={this.handleCancel}  >
          <CustomForm initialValues={{state: 'IA'}}
            formRef={this.formRef} onFinish={this.submitContForm} layout="vertical">
            <FormItem name="date" label="Date" rules={[FormValidationUtil.RequiredDate('Contribution date is required')]} >
              <CustomDatePicker onChange={(d) => d?.startOf('day')}/>
            </FormItem>
            <AddressFormFields
              name={{ name: 'name' }}
              address1={{ name: 'address1' }}
              address2={{ name: 'address2' }}
              city={{ name: 'city' }}
              state={{ name: 'state' }}
              zip={{ name: 'zip' }} />
            <FormItem
              name="amount"
              label="Amount"
              rules={[
                FormValidationUtil.RequiredNumber('Amount of the Expenditure is required'),
                FormValidationUtil.PositiveNumber('Amount of the Expenditure must be positive')
              ]}>
              <CurrencyInput />
            </FormItem>
          </CustomForm>
        </Modal>
        <Button type="primary" onClick={this.showModal} >Add Source </Button>
        <br /><br/>
        <DataTable
          ref={(element: any) => this.dataTable = element}
          serverSide={false}
          data={this.state.contributionList}
          columns={columnList}
          tableProps={{
            rowKey: 'indContributionId',
            sortDirections: ['ascend', 'descend'],
          }}
        />
        <br /><br />
      </>
    );
  }

  private confirmDelete = (id: string, name: string) => {
    confirm({
      title: 'Are you sure you want to delete this source of funding?',
      content: 'The following record will be permanently deleted: ' + name,
      onOk: () => {
        this.delete(id);
      },
    });
  }

  private delete = (indContributionId: string) => {
    const contList = this.state.contributionList.filter(c => c.indContributionId !== indContributionId); 
    this.setState({ contributionList: contList }, () => {
      this.dataTable?.refresh();
      if (this.props.onChange) {
        this.props.onChange(this.state.contributionList);
      }
    });
  }

  private showModal = () => {
    this.setState({ isModalVisible: true });
  }

  private handleCancel = () => {
    this.setState({ isModalVisible: false });
  }

  private edit = (indContributionId: string) => {
    this.setState({ indContributionsId: indContributionId, isModalVisible: true }, () => {
      if (this.formRef.current) {
        const contribution = this.state.contributionList.find(c => c.indContributionId === indContributionId);
        if (contribution) {
          this.formRef.current.setFieldsValue(contribution);
        }
      }
    });
  }

  private submitContForm = () => {
    if (this.formRef.current) {
      this.formRef.current.validateFields().then(values => {
        if (!this.state.indContributionsId) {
          values.contributionsId = Guid.NewGuid();
          values.indContributionId = Guid.NewGuid();
          this.setState({ contributionList: [...this.state.contributionList, values] }, () => this.dataTable?.refresh());
        }
        else {
          const contributionList = this.state.contributionList.map(
            c => c.indContributionId === this.state.indContributionsId ? { ...c, ...values } : c);
          this.setState({ contributionList, indContributionsId: '' }, () => this.dataTable?.refresh());
        }
        if (this.props.onChange) {
          this.props.onChange(this.state.contributionList);
        }
        this.formRef.current?.resetFields();
        this.setState({ isModalVisible: false });
      });
    }
  }
}

export default OieContribution;