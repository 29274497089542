import { Button, Col, FormInstance, Input, Layout, Modal, Row, Space, Typography, notification } from 'antd';
import * as React from 'react';
import CustomForm from '../shared/CustomForm';
import FormItem from 'antd/lib/form/FormItem';
import FormValidationUtil from '../../utils/FormValidationUtil';
import CustomDatePicker from '../shared/CustomDatePicker';
import CurrencyInput from '../shared/CurrencyInput';
import TextArea from 'antd/lib/input/TextArea';
import HistoryUtil from '../../utils/HistoryUtil';
import Routes from '../../consts/Routes';
import AdminSalesByStateService from '../../api/AdminSalesByStateService';
import moment from 'moment';
import { RouteComponentProps } from 'react-router';
import OriginalAgencySearch from '../shared/OriginalAgencySearch';
import FilingStatusType from '../../consts/FilingStatusType';
import OriginalExecAgencyDTO from '../../models/OriginalExecAgencyDTO';
import CampaignApiService from '../../api/CampaignApiService';
import AdminSalesByStateDTO from '../../models/AdminSalesByStateDTO';
import { MaskedInput } from 'antd-mask-input';

const { Content } = Layout;
const { confirm } = Modal;

interface EditSalesByStateProps {
    sbsId: string | undefined;
}

interface EditSalesByStateState {
    loading: boolean;
    sbsId?: string;
    editFormDTO: AdminSalesByStateDTO;
    isAudited: boolean;
}

class EditSalesByState extends React.Component<RouteComponentProps<EditSalesByStateProps>, EditSalesByStateState> {
    private readonly _formRef = React.createRef<FormInstance>();
    constructor(props: RouteComponentProps<EditSalesByStateProps>) {
        super(props);
        this.state = {
            loading: false,
            sbsId: props.match.params.sbsId || '',
            editFormDTO: AdminSalesByStateDTO.create({ dateOfSale: null,
                                                       filedDate: moment(),
                                                       signatureDate: moment().startOf('day')}),
            isAudited: true
        };
    }

    componentDidMount() {
        this.loadInitialValues();
    }

    render() {
        const { loading, editFormDTO } = this.state;
        const columnSizing = { xs: 24, md: 12 };

        return (
            <>
                {!loading &&
                    <Content className="content-pad">
                        <Typography.Title
                            level={4}> Sale by Official/Employee
                        </Typography.Title>
                        <CustomForm
                            formRef={this._formRef}
                            layout={'vertical'}
                            initialValues={editFormDTO}
                            onFinish={this.submitForm}
                            validateTrigger={['onChange', 'onBlur']}>
                            <FormItem name="id" hidden>
                                <Input disabled hidden aria-hidden />
                            </FormItem>
                            <Row gutter={8}>
                                <Col flex={1}>
                                    <FormItem
                                        name="firstName"
                                        label="First Name:"
                                        rules={[
                                            FormValidationUtil.Required('First Name is required'),
                                        ]}>
                                        <Input></Input>
                                    </FormItem>
                                </Col>
                                <Col flex="50px">
                                    <FormItem
                                        name="middleInitial"
                                        label="MI:">
                                        <Input maxLength={1}></Input>
                                    </FormItem>
                                </Col>
                                <Col flex={1}>
                                    <FormItem
                                        name="lastName"
                                        label="Last Name:"
                                        rules={[
                                            FormValidationUtil.Required('Last Name is required'),
                                        ]}>
                                        <Input></Input>
                                    </FormItem>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col flex={1}>
                                    <FormItem name="executiveBranchAgencyId" hidden={true}>
                                        <Input hidden={true} disabled={true} aria-hidden={true} />
                                    </FormItem>
                                    <OriginalAgencySearch
                                        name={'executiveBranchAgencyName'}
                                        label={'Executive Branch Agency'}
                                        required={true}
                                        onSelect={this.selectAgency}
                                    />
                                </Col>
                            </Row>
                            <Row gutter={8}>
                                <Col flex={1}>
                                    <FormItem
                                        name="stateAgencyOfSaleName"
                                        label="State Agency of Sale Name"
                                        rules={[
                                            FormValidationUtil.Required('State Agency of Sale Name is required'),
                                        ]}>
                                        <Input></Input>
                                    </FormItem>
                                </Col>
                            </Row>
                            <Row gutter={8}>
                                <Col flex={1}>
                                    <FormItem
                                        name="officialPhone"
                                        label="Official Phone"
                                        rules={[
                                            FormValidationUtil.PhoneOrNumber(),
                                            FormValidationUtil.Required('Official Phone is required'),
                                        ]}>
                                        <MaskedInput mask="(111) 111-1111" />
                                    </FormItem>
                                </Col>
                            </Row>
                            <Row gutter={8}>
                                <Col flex={1}>
                                    <FormItem
                                        name="officialEmail"
                                        label="Official Email"
                                        rules={[
                                            FormValidationUtil.Required('Official Email is required'),
                                            FormValidationUtil.Email('Official Email must be in a valid email format'),
                                        ]}>
                                        <Input type="email" />
                                    </FormItem>
                                </Col>
                            </Row>
                                <Row gutter={8}>
                                <Col flex={1}>
                                    <FormItem
                                        name="dateOfSale"
                                        label="Date of Sale"
                                        rules={[
                                            FormValidationUtil.RequiredDate('Date of Sale is required')
                                        ]}>
                                        <CustomDatePicker onChange={(d) => d?.startOf('day')} />
                                    </FormItem>
                                </Col>
                            </Row>
                            <Row gutter={8}>
                                <Col flex={1}>
                                    <FormItem
                                        name="saleAmount"
                                        label="Amount of Sale:"
                                        rules={[
                                            FormValidationUtil.RequiredNumber('Amount of Sale is required'),
                                            FormValidationUtil.PositiveNumber('Amount of Sale is required')
                                        ]}>
                                        <CurrencyInput></CurrencyInput>
                                    </FormItem>
                                </Col>
                            </Row>
                            <Row gutter={8}>
                                <Col flex={1}>
                                    <FormItem
                                        name="saleDescription"
                                        label="Description of Sale:"
                                        rules={[
                                            FormValidationUtil.Required('Description of Sale is required'),
                                        ]}>
                                        <TextArea maxLength={256} showCount={true}></TextArea>
                                    </FormItem>
                                </Col>
                            </Row>
                            <Row gutter={8}>
                                <Col {...columnSizing}>
                                    <FormItem
                                        name={'signatureName'}
                                        label={'Signature'}
                                        rules={[FormValidationUtil.Required('Signature is required')]}>
                                        <Input></Input>
                                    </FormItem>
                                </Col>
                                <Col {...columnSizing}>
                                    <FormItem
                                        name={'signatureDate'}
                                        label={'Date Signed'}
                                        rules={[FormValidationUtil.RequiredDate('Date Signed is required')]}>
                                        <CustomDatePicker disabled={true} />
                                    </FormItem>
                                </Col>
                            </Row>
                            <Space>
                                {this.state.isAudited ?
                                    <>
                                        <Button type="primary" htmlType="submit"> Save </Button>
                                    </> :
                                    <>
                                        <Button type="primary" htmlType="submit"> Approve </Button>
                                        <Button type="default" onClick={this.confirmReject} htmlType="button"> Reject</Button>
                                    </>
                                }
                                <Button type="default" onClick={this.confirmCancel}> Cancel </Button>
                            </Space>
                        </CustomForm>
                    </Content>
                }
            </>
        );
    }

    private submitForm = (values: any) => {
        const submittedForm = { ...this.state.editFormDTO, ...values };

        if (!(this.state.sbsId)) {
            this.setState({ loading: true });
            CampaignApiService.saveSalesByState(submittedForm)
                .then(() => {
                    this.setState({ loading: false });
                    notification.success({ message: 'Successfully saved Sale by Official/Employee.' });
                    HistoryUtil.push(Routes.LOGIN);
                })
                .catch(() => {
                    this.setState({ loading: false });
                    notification.error({ message: 'Failed to approve Sale by Official/Employee.' });
                });
        }
        else {
            submittedForm.filingStatus = FilingStatusType.AUDITED;
            AdminSalesByStateService.updateSaleByState(submittedForm)
                .then(() => {
                    this.setState({ loading: false });
                    notification.success({
                        message: this.state.isAudited ? 'Successfully saved Sale by Official/Employee.'
                                                      : 'Your changes were saved successfully.'
                    });
                    HistoryUtil.push(Routes.MANAGE_SALES_BY_STATE);
                })
                .catch(() => {
                    this.setState({ loading: false });
                    notification.error({
                        message: this.state.isAudited ? 'Failed to save Sale by Official/Employee.' : 'Failed to approve Sale by Official/Employee.'
                    });
                });
        }
    }

    private confirmCancel = () => {
        confirm({
            title: 'Are you sure you want to leave?',
            okText: 'Yes',
            cancelText: 'No',
            onOk: () => {
                this.state.sbsId ? HistoryUtil.push(Routes.generate(Routes.MANAGE_SALES_BY_STATE)) : HistoryUtil.push(Routes.generate(Routes.LOGIN));
            }
        });
    }

    private loadInitialValues = () => {
        if (this.state.sbsId) {
            this.setState({ loading: true });
            AdminSalesByStateService.getSaleByState(Number(this.state.sbsId))
                .then(editFormDTO => {
                    editFormDTO.dateOfSale = moment(editFormDTO.dateOfSale);
                    editFormDTO.signatureDate = moment(editFormDTO.signatureDate);
                    this.setState({
                        editFormDTO,
                        isAudited: editFormDTO.filingStatus == FilingStatusType.AUDITED,
                        loading: false
                    });
                })
                .catch(() => {
                        notification.error({ message: 'Failed to load Sale by Official/Employee.' });
                        this.setState({ loading: false });
                    });
        }
    }

    private confirmReject = () => {
        confirm({
            title: 'Are you sure you want to reject this Sale by Official/Employee?',
            content: 'The following record will be rejected: ' + this.state.editFormDTO.lastName + ', ' + this.state.editFormDTO.firstName,
            onOk: () => {
                this.reject(Number(this.state.sbsId));
            },
        });
    }

    private reject = (id: number) => {
        this.setState({ loading: true });
        AdminSalesByStateService.rejectSaleByState(id)
            .then(() => {
                notification.success({
                    message: 'Sale by Official/Employee was rejected successfully.'
                });
                HistoryUtil.push(Routes.generate(Routes.MANAGE_SALES_BY_STATE));
                this.setState({ loading: false });
            }).catch(() => {
                notification.error({
                    message: 'Failed to reject Sale by Official/Employee.'
                });
                this.setState({ loading: false });
            });
    }

    private selectAgency = (agency: OriginalExecAgencyDTO | undefined) => {
        if (agency != null) {
            this._formRef.current?.setFieldsValue({ executiveBranchAgencyId: agency.id });
        }
    }
}
export default EditSalesByState;