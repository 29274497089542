// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';

interface UserSecurityDTO { 
  userId: number;
  userName: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  committeeName: string | null;
  committeeId: string | null;
  originalCommitteeId: string | null;
  committeeType: string | null;
  committeeTypeName: string | null;
  filerTypeId: number | null;
  roles: number[];
  auditing: boolean | null;
  receivingDepartmentId: string | null;
  dr2Id: string | null;
  committeeStatusType: string | null;
  committeeFilingCatType: string | null;
  hasPenalties: boolean | null;
}
const UserSecurityDTO = {
  create: (initValues?: Partial<UserSecurityDTO> | Record<string, unknown> | null) => {
    return Object.assign(
    {
      userId: 0,
      userName: null,
      email: null,
      firstName: null,
      lastName: null,
      committeeName: null,
      committeeId: null,
      originalCommitteeId: null,
      committeeType: null,
      committeeTypeName: null,
      filerTypeId: null,
      roles: [],
      auditing: null,
      receivingDepartmentId: null,
      dr2Id: null,
      committeeStatusType: null,
      committeeFilingCatType: null,
      hasPenalties: null,
    },
    initValues);
  }
};

export default UserSecurityDTO;