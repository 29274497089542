import { Divider, Modal, notification, Spin, Steps, Typography } from 'antd';
import moment, { Moment } from 'moment';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import FilerType from '../../consts/FilerType';
import DR1DTO from '../../models/DR1DTO';
import Stepper, { Step } from '../shared/Stepper';
import UserIdAffirmation from '../shared/UserAffirmation';
import NewFilingPeriodTable from './NewFilingPeriodTable';
import OrganizationApiService from '../../api/OrganizationApiServices';
import DateUtil from '../../utils/DateUtil';
import Dr2Submit from './Dr2Submit';
import { Content } from 'antd/lib/layout/layout';
import Dr2ApiService from '../../api/Dr2ApiService';
import CommitteeFilingDTO from '../../models/CommitteeFilingDTO';
import HistoryUtil from '../../utils/HistoryUtil';
import Routes from '../../consts/Routes';
import CurrentUser from '../../utils/CurrentUser';
import Role from '../../consts/Role';
import DissolutionPage from '../dissolution/DissolutionPage';
import DissolutionApiService from '../../api/DissolutionApiService';
import FilingPeriodDTO from '../../models/FilingPeriodDTO';
import ElectionYear from '../../consts/ElectionYear';
import ElectionInfoModal from './ElectionInfoModal';
import CommitteeTypeDTO from '../../models/CommitteeTypeDTO';
import ElectionInfoRequiredUtil from '../../utils/ElectionInfoRequiredUtil';
import CommFilingPeriodCatType from '../../consts/CommFilingPeriodCatType';
import ReportType from '../../consts/ReportType';
import DueDateDTO from '../../models/DueDateDTO';
import FilingPeriodHistoryApiService from '../../api/FilingPeriodHistoryApiService';

const { confirm } = Modal;

const { Step } = Steps;

interface Dr2SubmitStepperProps {
}

interface Dr2SubmitStepperState {
  id: string;
  filerType: number;
  currentStepKey: DR1Step;
  dr1: DR1DTO;
  commFiling: CommitteeFilingDTO;
  currDr2Id: string;
  periodMasterId: number;
  committeeFilingCatType: string;
  completedSteps: boolean[];
  loadingDR1Data: boolean;
  confirmChecked: boolean;
  dissolveCommittee: boolean;
  dissolveDate: Moment | null | string;
  showElectionModal: boolean;
  commtype: CommitteeTypeDTO;
  saving: boolean;
  skipSelectPeriod: boolean;
  loading: boolean;
  filingPeriod: string | null;
  isIncumbent: boolean | null;
  prevEndDt: string | Moment | null;
  prevBeginDt: string | Moment | null;
  prevElType: string;
  elYear: number;
  contributions: number;
  failSupCriteria: boolean;
  nextDesc: string
}

enum DR1Step {
  STEP1,
  STEP2,
  STEP3,
}

class Dr2SubmitStepper extends React.Component<RouteComponentProps<Dr2SubmitStepperProps>, Dr2SubmitStepperState> {
  private _userIDAffirmationRef: UserIdAffirmation | null = null;
  private _newFilingPeriodTableRef: NewFilingPeriodTable | null = null;
  private _dr2SubmitCompletionRef: Dr2Submit | null = null;
  private _dissolutionRef: DissolutionPage | null = null;
  private _nextRequestedStepKey?: DR1Step;
  private steps: Step[] = [
    {
      key: DR1Step.STEP1,
      title: 'Review Committee Info'
    },
    {
      key: DR1Step.STEP2,
      title: 'Select Next Filing Period',
      hideNext: true,
    },
    {
      key: DR1Step.STEP3,
      title: 'Submit',
    },
  ];

  constructor(props: RouteComponentProps<Dr2SubmitStepperProps>) {
    super(props);
    this.state = {
      id: CurrentUser.Get()?.committeeId || '',
      filerType: CurrentUser.Get()?.filerTypeId || 0,
      currDr2Id: CurrentUser.Get()?.dr2Id || '',
      committeeFilingCatType: CurrentUser.Get()?.committeeFilingCatType || '',
      currentStepKey: DR1Step.STEP1,
      dr1: DR1DTO.create(),
      commFiling: CommitteeFilingDTO.create(),
      completedSteps: [false, false, false],
      loadingDR1Data: false,
      periodMasterId: 0,
      confirmChecked: false,
      dissolveCommittee: false,
      dissolveDate: moment(),
      showElectionModal: false,
      commtype: CommitteeTypeDTO.create(),
      saving: false,
      skipSelectPeriod: false,
      loading: false,
      filingPeriod: '',
      isIncumbent: false,
      prevEndDt: '',
      prevBeginDt: '',
      prevElType: '',
      elYear: 0,
      contributions: 0,
      failSupCriteria: false,
      nextDesc: ''
    };
  }

  componentDidMount = () => {
    this.loadDR1Data();
    this.checkSkipNewFilingPeriod();
    this.getNextFilingPeriodDescription();
    this.suppCriteriaCheck();
    Dr2ApiService.getDueDatesByDr2(this.state.currDr2Id).then(results => {
      this.setState({
        prevEndDt: results.endDueDate,
        prevBeginDt: results.beginDueDate
      });
    });
    Dr2ApiService.getReportType(this.state.currDr2Id).then(result => { this.setState({ prevElType: result }); });
    Dr2ApiService.getElectionYear(this.state.id).then(result => { this.setState({ elYear: result }); });
  }

  render() {
    const {
      currentStepKey,
      dr1,
      loadingDR1Data,
      currDr2Id,
      filerType,
      dissolveCommittee,
      showElectionModal,
      commtype,
      saving,
      skipSelectPeriod,
      loading } = this.state;

    const showElectionDate = ElectionInfoRequiredUtil.showElectionDate(commtype?.code || '');
    const electionDateRequired = ElectionInfoRequiredUtil.electionDateRequired(commtype?.code || '');
    let currentStep: React.ReactElement<any> = <></>;
    switch (currentStepKey) {
      case DR1Step.STEP1:
        currentStep =
          <UserIdAffirmation
            ref={(element) => this._userIDAffirmationRef = element}
            key={DR1Step.STEP1}
            DR1Ref={dr1}
            filerType={filerType}
            disable={true}
            confirmBox={this.handleConfirmCheckbox}
            confirmCheck={this.state.confirmChecked}
            onEmailSave={() => {
              this.setComplete(DR1Step.STEP1);
              this.goToStep(DR1Step.STEP2);
              this.loadDR1Data();
            }}
          />;

        break;

      case DR1Step.STEP2:
        if (!skipSelectPeriod) {
          currentStep = (
            <>
              <h4 className="ant-typography">Please select your NEXT filing period. Your
                current report that you are filing has already been created. You must now select the filing period for your next report.
              </h4>
              {(this.state.failSupCriteria) ?
                <NewFilingPeriodTable
                  ref={(element) => this._newFilingPeriodTableRef = element}
                  selectFiling={true}
                  electionType={ElectionYear.ELECTION}
                  prevEndDt={this.state.prevEndDt}
                  prevBeginDt={this.state.prevBeginDt}
                  dr2Id={currDr2Id}
                  onChange={this.handlePeriodMasterId}
                  onDissolve={this.handleDissolve}
                  loadParent={this.setLoading}
                /> :
                <>
                  <NewFilingPeriodTable
                    ref={(element) => this._newFilingPeriodTableRef = element}
                    selectFiling={true}
                    electionType={this.checkFilerType(this.state.prevElType)}
                    prevEndDt={this.state.prevEndDt}
                    prevBeginDt={this.state.prevBeginDt}
                    dr2Id={currDr2Id}
                    routeToSummary={true}
                    onChange={this.handlePeriodMasterId}
                    onDissolve={this.handleDissolve}
                    loadParent={this.setLoading} />
                  <NewFilingPeriodTable
                    ref={(element) => this._newFilingPeriodTableRef = element}
                    selectFiling={true}
                    electionType={this.checkPrevElection(ElectionYear.NONELECTION)}
                    prevEndDt={this.state.prevEndDt}
                    dr2Id={currDr2Id}
                    routeToSummary={true}
                    onChange={this.handlePeriodMasterId}
                    onDissolve={this.handleDissolve}
                    loadParent={this.setLoading} />
                  <NewFilingPeriodTable
                    ref={(element) => this._newFilingPeriodTableRef = element}
                    selectFiling={true}
                    electionType={this.checkPrevElection(ReportType.SUPPLEMENTAL)}
                    prevEndDt={this.state.prevEndDt}
                    dr2Id={currDr2Id}
                    routeToSummary={true}
                    onChange={this.handlePeriodMasterId}
                    onDissolve={this.handleDissolve}
                    loadParent={this.setLoading} />
                  <NewFilingPeriodTable
                    ref={(element) => this._newFilingPeriodTableRef = element}
                    selectFiling={true}
                    electionType={this.checkPrevElection(ElectionYear.SPECIAL)}
                    prevEndDt={this.state.prevEndDt}
                    dr2Id={currDr2Id}
                    routeToSummary={true}
                    onChange={this.handlePeriodMasterId}
                    onDissolve={this.handleDissolve}
                    loadParent={this.setLoading} />
                  <NewFilingPeriodTable
                    ref={(element) => this._newFilingPeriodTableRef = element}
                    selectFiling={true}
                    electionType={this.checkPrevElection('')}
                    prevEndDt={this.state.prevEndDt}
                    prevBeginDt={this.state.prevBeginDt}
                    dr2Id={currDr2Id}
                    routeToSummary={true}
                    onChange={this.handlePeriodMasterId}
                    onDissolve={this.handleDissolve}
                    loadParent={this.setLoading} />
                </>}
              {(showElectionModal && (showElectionDate || CurrentUser.Get()?.filerTypeId == FilerType.CANDIDATE)) &&
                <ElectionInfoModal
                  onClose={() => this.setState({ showElectionModal: false })}
                  dateRequired={(showElectionDate && electionDateRequired)}
                  dr1={dr1}
                  dr2Id={currDr2Id}
                  onSubmit={this.handleSubmit}
                  filingPeriod={this.state.filingPeriod}
                />
              }
            </>
          );
        }
        else {
          currentStep = (
            <>
              <Dr2Submit
                ref={(element) => this._dr2SubmitCompletionRef = element}
                dr2Id={currDr2Id}
                isDissolving={this.state.dissolveCommittee}
                isSaving={saving}
              />
              {dissolveCommittee &&
                <>
                  <Divider />
                  <DissolutionPage ref={(element) => this._dissolutionRef = element} inDr2Submit={true} />
                  <Divider />
                </>
              }
            </>
          );
        }

        break;

      case DR1Step.STEP3:
        if (!skipSelectPeriod) {
          currentStep = (
            <>
              <Dr2Submit
                ref={(element) => this._dr2SubmitCompletionRef = element}
                dr2Id={currDr2Id}
                isDissolving={this.state.dissolveCommittee}
                isSaving={saving}
              />
              {dissolveCommittee &&
                <>
                  <Divider />
                  <DissolutionPage ref={(element) => this._dissolutionRef = element} inDr2Submit={true} />
                  <Divider />
                </>
              }
            </>
          );
        }

        break;

      default:
        currentStep = <>Error</>;
        break;
    }

    return (
      <Content className="content-pad">
        <Typography.Title level={4}>DR-2 Submit</Typography.Title>
        <Spin spinning={loadingDR1Data || loading || saving}>
          {(!loadingDR1Data && !loading) && (
            <>
              <Stepper
                steps={this.steps}
                currentStepKey={currentStepKey}
                allowStepClick={true}
                onStepChangeRequested={this.handleStepChangeRequested}
                onSaveRequested={this.handleValidation}
                minVerticalStepWidth={250}
                titleSubmit={'Continue Submitting Report'}
                buttonsDisabled={saving}>
                {currentStep}
              </Stepper>
            </>
          )}
        </Spin>
      </Content>
    );
  }

  private checkFilerType = (prevType: string) => {
    if (CurrentUser.Get()?.filerTypeId != FilerType.CANDIDATE || this.state.elYear != (new Date().getUTCFullYear())) {
      return ElectionYear.NONELECTION;
    }
    if (prevType == ReportType.SUPPLEMENTAL) {
      return ElectionYear.ELECTION;
    }
    return prevType;
  }

  private checkPrevElection = (currType: string) => {
    if (currType == this.state.prevElType && currType != ReportType.SUPPLEMENTAL ||
      ((CurrentUser.Get()?.filerTypeId != FilerType.CANDIDATE || this.state.elYear != (new Date().getUTCFullYear()))
        && currType == ElectionYear.NONELECTION)) {
      return ElectionYear.ELECTION;
    } else {
      return currType;
    }
  }

  private checkSkipNewFilingPeriod = () => {
    this.setState({ loading: true });
    const multipleUnfiledPromise = Dr2ApiService.checkMultipleUnfiled(CurrentUser.Get()?.committeeId || '');
    const periodFiled = Dr2ApiService.checkAmendingFiling(CurrentUser.Get()?.dr2Id || '');
    const committeeDissolved = Dr2ApiService.checkCommitteeDissolve(CurrentUser.Get()?.committeeId || '');

    Promise.all([multipleUnfiledPromise, periodFiled, committeeDissolved])
      .then((result) => {
        if (result.includes(true)) {
          this.steps = [
            { key: DR1Step.STEP1, title: 'Review Committee Info' },
            { key: DR1Step.STEP2, title: 'Submit' },
          ];
          this.setState({ skipSelectPeriod: true });
        }
        this.setState({ loading: false });
      })
      .catch((error) => {
        notification.error({
          message: 'Failed to check if committee must select a new period',
          description: error.description
        });
      });

  }

  private handleDissolve = () => {
    DissolutionApiService.checkCommDissolution(CurrentUser.Get()?.committeeId || '', '').then((result) => {
      if (result.succeeded) {
        this.setState({ dissolveCommittee: true });
        this.setComplete(DR1Step.STEP2);
        this.goToStep(DR1Step.STEP3);
      }
      else {
        notification.error({ message: result.errors[0] });
      }
    }).catch(() => {
      notification.error({ message: 'Failed to check if committee can dissolve' });
    });
  }

  private handleSubmit = (dr1: DR1DTO, isIncumbent: boolean | null) => {
    this.setState({ showElectionModal: false, dr1, isIncumbent }, () => {
      this.setComplete(DR1Step.STEP2);
      this.goToStep(DR1Step.STEP3);
    });
  }

  private handlePeriodMasterId = (filingPeriod: FilingPeriodDTO) => {
    const showElectionDate = ElectionInfoRequiredUtil.showElectionDate(this.state.commtype?.code || '');
    if ((filingPeriod.electionType == ElectionYear.ELECTION || filingPeriod.electionType == ElectionYear.SPECIAL
      || ((filingPeriod.electionType == ElectionYear.NONELECTION) && (this.state.committeeFilingCatType == CommFilingPeriodCatType.SWGA))) &&
      (showElectionDate || CurrentUser.Get()?.filerTypeId == FilerType.CANDIDATE)) {
      this.setState({ showElectionModal: true, periodMasterId: filingPeriod.periodMasterId, filingPeriod: filingPeriod.electionType });
    }
    else {
      this.setState({ periodMasterId: filingPeriod.periodMasterId, filingPeriod: filingPeriod.electionType });
      this.setComplete(DR1Step.STEP2);
      this.goToStep(DR1Step.STEP3);
    }
  }

  private handleStepChangeRequested = (requestedStep: Step) => {
    this._nextRequestedStepKey = requestedStep.key;
    switch (this.state.currentStepKey) {
      case DR1Step.STEP1:
        if (!this._userIDAffirmationRef) {
          return;
        }

        if (requestedStep.key == DR1Step.STEP2 && this.state.confirmChecked) {

          this.setComplete(DR1Step.STEP1);
          this.goToStep(requestedStep.key);;
          return;
        }
        else {
          notification.error({ message: 'Please confirm all information is correct.' });
        }

        return;

      case DR1Step.STEP2:
        if (this.state.skipSelectPeriod === false) {
          if (!this._newFilingPeriodTableRef) {
            return;
          }


          if (this.state.currentStepKey > requestedStep.key) {
            this.goToStep(requestedStep.key);
            break;
          }
        }
        else {
          if (!this._dr2SubmitCompletionRef) {
            return;
          }

          if (this.state.currentStepKey > requestedStep.key) {
            this._dr2SubmitCompletionRef.validate().then(validResult => {
              this.setState({ commFiling: { ...validResult.model } });
            });

            this._dissolutionRef?.validate().then(validResult => {
              this.setState({ dissolveDate: moment(validResult.model.dissolveDate) });
            });

            this.setComplete(DR1Step.STEP2);
            this.goToStep(requestedStep.key);
            break;
          }
        }


        return;

      case DR1Step.STEP3:
        if (!this._dr2SubmitCompletionRef) {
          return;
        }

        if (this.state.currentStepKey > requestedStep.key) {
          this._dr2SubmitCompletionRef.validate().then(validateResult => {
            this.setState({ commFiling: { ...validateResult.model } });
          });
          this._dissolutionRef?.validate().then(validateResult => {
            this.setState({ dissolveDate: moment(validateResult.model.dissolveDate) });
          });

          this.setComplete(DR1Step.STEP3);
          this.goToStep(requestedStep.key);
          break;
        }

        return;

      default:
        return;
    }
  }

  private handleValidation = () => {
    if (this._dr2SubmitCompletionRef && CurrentUser.Get()?.isInRole(Role.IECDB_ADMINISTRATOR) && this._dissolutionRef) {

      const dr2Promise = this._dr2SubmitCompletionRef.validate().then(validateResult => {
        this.setState({ commFiling: { ...validateResult.model } });
      });

      const dissolutionPromise = this._dissolutionRef?.validate().then(validateResult => {
        this.setState({ dissolveDate: validateResult.model.dissolveDate });
      });

      return Promise.all([dr2Promise, dissolutionPromise]).then(() => {
        this.saveReport();
      });
    }
    if (this._dr2SubmitCompletionRef && !this._dissolutionRef) {
      this._dr2SubmitCompletionRef.validate().then(validateResult => {
        this.setState({ commFiling: { ...validateResult.model } }, () => { this.saveReport(); });
      });
    }
    else { this.saveReport(); }
  }

  private saveReport() {
    this.setState({ saving: true });
    if (this.state.dissolveCommittee) {
      if (!CurrentUser.Get()?.committeeId) { return; }
      Dr2ApiService.saveDr2CommFiling(this.state.commFiling, this.state.currDr2Id,).then(result => {
        if (result.succeeded) {
          DissolutionApiService.dissolveWithNoCommCheck(
            CurrentUser.Get()?.committeeId || '',
            DateUtil.toShortDateString(moment(this.state.dissolveDate))).then((result) => {
              if (result.succeeded) {
                notification.success({ message: 'Committee was dissolved successfully.' });
                Dr2ApiService.getLastFiledDr2Id(this.state.id || '')
                  .then((dr2Id) => {
                    CurrentUser.ChangeDr2(dr2Id);
                    HistoryUtil.push(Routes.generate(Routes.DR2_SUBMIT_COMPLETION));
                  });
              }
              else {
                notification.error({ message: result.errors[0] });
              }
            }).catch(() => {
              notification.error({ message: 'Failed to dissolve committee.' });
            });
        }
        else {
          notification.error({ message: result.errors[0] });
        }
      }).catch(() => {
        notification.error({ message: 'Failed to save committee Filing.' });
      });
    }
    else {
      const isIncumbent = this.state.isIncumbent;
      Dr2ApiService.saveDr2CommFiling(this.state.commFiling, this.state.currDr2Id,).then(result => {
        if (result.succeeded) {
          if (!this.state.skipSelectPeriod) {
            Dr2ApiService.saveNewFilingPeriod(this.state.periodMasterId, this.state.id || '', this.state.currDr2Id || '', isIncumbent)
              .then((result) => {
                if (result.succeeded) {
                  Dr2ApiService.getLastFiledDr2Id(this.state.id || '')
                    .then((dr2Id) => {
                      notification.success({ message: 'Committee filing was saved successfully.' });
                      CurrentUser.ChangeDr2(dr2Id);
                      HistoryUtil.push(Routes.generate(Routes.DR2_SUBMIT_COMPLETION));
                    }).catch(() => {
                      notification.error({
                        message: 'Error while creating committee filing'
                      });
                    });
                }
                else {
                  notification.error({ message: result.errors[0] });
                }
              });
          }
          else {
            Dr2ApiService.getLastFiledDr2Id(this.state.id || '')
              .then((dr2Id) => {
                notification.success({ message: 'Committee filing was saved successfully.' });
                CurrentUser.ChangeDr2(dr2Id);
                HistoryUtil.push(Routes.generate(Routes.DR2_SUBMIT_COMPLETION));
              }).catch(() => {
                notification.error({
                  message: 'Error while creating committee filing'
                });
              });
          }
        }
        else {
          notification.error({ message: result.errors[0] });
        }
      }).catch(() => {
        notification.error({ message: 'Failed to save committee Filing.' });
      });
    }
  }

  private goToStep = (stepKey: DR1Step) => {
    this.setState({ currentStepKey: stepKey });
  }

  private setComplete = (stepKey: DR1Step) => {
    const completed = this.state.completedSteps;
    completed[stepKey] = true;
    this.setState({ completedSteps: completed });
  }

  private handleConfirmCheckbox = (checked: boolean) => {
    this.setState({ confirmChecked: checked });

  }

  private loadDR1Data = () => {
    if (!this.state.id || !this.state.filerType) {
      this.setState({ loadingDR1Data: false });
      return;
    }

    this.setState({ loadingDR1Data: true });
    OrganizationApiService
      .getDr1OrgDetails(this.state.id, DateUtil.toTimeStamp(moment()), this.state.filerType)
      .then(dr1 => {
        if (dr1.committeeInfo) {
          if (dr1.committeeInfo.electionDate) {
            dr1.committeeInfo.electionDate = moment(dr1.committeeInfo.electionDate);
          }
          if (dr1.committeeInfo.type) {
            this.setState({ commtype: CommitteeTypeDTO.create({ code: dr1.committeeInfo.type }) });
          }
        }

        this.setState({ dr1, loadingDR1Data: false });
      }).catch(() => {
        notification.error({ message: 'Failed to load DR1 data.' });
        this.setState({ loadingDR1Data: false });
      });
  }

  private suppCriteriaCheck = () => {
    const commType = CurrentUser.Get()?.committeeTypeName;
    Dr2ApiService.getDr2Summary(this.state.currDr2Id).then(result => {
      this.setState({ contributions: result.cashContributions + result.inKindContributions + result.loansReceived });
      Dr2ApiService.checkSupplemental(this.state.currDr2Id, this.state.contributions, commType || '').then(result => {
        this.setState({ failSupCriteria: CurrentUser.Get()?.committeeFilingCatType == CommFilingPeriodCatType.SWGA && !result });
        if (!result && this.state.skipSelectPeriod) {
          confirm({
            okText: this.state.nextDesc != null ? 'Confirm' : 'Ok',
            onCancel: () => { HistoryUtil.push(Routes.generate(Routes.SCHEDULE_SUMMARY)); },
            onOk: () => { this.transferFilingPeriod(); },
            title:
              <>
                NOTICE
              </>,
            content:
              <>
                You do not meet the reporting threshold for this supplemental report.
                {this.state.nextDesc &&
                  <>By confirming, all entries will be copied to the {this.state.nextDesc} report.</>
                }
              </>,
            width: '60%'
          });
        }
      });
    });
  }

  private transferFilingPeriod = () => {
    this.setState({ loading: true });
    Dr2ApiService.getReportForSuppFail(CurrentUser.Get()?.committeeId || '', this.state.currDr2Id || '').then(result => {
      if (result != null) {
        this.handleSave(result);
      }
      else {
        this.setState({ loading: false });
        HistoryUtil.push(Routes.generate(Routes.SCHEDULE_SUMMARY));
      }
    });
  }

  private handleSave = (values: DueDateDTO) => {
    Dr2ApiService.saveAdjustEntirePeriod(
      values,
      CurrentUser.Get()?.committeeId || '',
      CurrentUser.Get()?.dr2Id || '',
      values.periodMasterId || -1)
      .then(result => {
        if (!result.succeeded) {
          notification.error({
            message: result.errors[0].toString()
          });
        }
        this.deleteFilingPeriod(CurrentUser.Get()?.dr2Id || '', CurrentUser.Get()?.committeeId || '');
      }).catch(() => {
        notification.error({
          message: 'Failed to adjust period'
        });
        HistoryUtil.push(Routes.generate(Routes.SCHEDULE_SUMMARY));
      });
  }

  private deleteFilingPeriod = (dr2Id: string, committeeId: string) => {
    this.setState({ loading: true });
    FilingPeriodHistoryApiService.deleteFph(dr2Id, committeeId)
      .then(() => {
        Dr2ApiService.getLastFiledDr2Id(CurrentUser.Get()?.committeeId || '')
          .then((dr2Id) => {
            CurrentUser.ChangeDr2(dr2Id);
            notification.success({ message: 'Filing was transferred successfully.' });
            HistoryUtil.push(Routes.generate(Routes.SCHEDULE_SUMMARY));
          });
        this.setState({ loading: false });
      }).catch(() => {
        notification.error({ message: 'Failed to transfer filing.' });
      });
  }

  private getNextFilingPeriodDescription = () => {
    if (this.state.currDr2Id != '') {
      Dr2ApiService.getSuppPeriodDescription(CurrentUser.Get()?.committeeId || '', this.state.currDr2Id).then(result => {
        this.setState({ nextDesc: result });
      });
    }
  }

  private setLoading = (value: boolean) => {
    this.setState({ loading: value });
  }
}

export default Dr2SubmitStepper;