// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
import BaseApi from './BaseApi';
import OtcDTO from '../models/OtcDTO';
import PfdDTO from '../models/PfdDTO';
import OieExpendDTO from '../models/OieExpendDTO';
import IieDTO from '../models/IieDTO';
import DualCompDTO from '../models/DualCompDTO';
import SaleByStateDTO from '../models/SaleByStateDTO';
import PetitionForWaiverDTO from '../models/PetitionForWaiverDTO';

export class CampaignApiService extends BaseApi {

  // post: api/campaign/otc/add
  public addOtc(otcData: OtcDTO): Promise<void> {
    let url = `api/campaign/otc/add`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, void>(otcData, 'post', url, true, false);
  }

  // post: api/campaign/pfd/add?filingType=${encodeURIComponent(filingType)}
  public addPfd(pfdData: PfdDTO, filingType: string): Promise<void> {
    let url = `api/campaign/pfd/add`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (filingType != null) {
      url += `${prefix}filingType=${encodeURIComponent(filingType)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, void>(pfdData, 'post', url, true, false);
  }

  // post: api/campaign/${encodeURIComponent(expendId)}
  public getOieExpend(expendId: string): Promise<OieExpendDTO> {
    let url = `api/campaign/${encodeURIComponent(expendId)}`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, OieExpendDTO>(null, 'post', url, true, false);
  }

  // post: api/campaign/oie/save
  public addOieExpend(oieExpend: OieExpendDTO): Promise<void> {
    let url = `api/campaign/oie/save`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, void>(oieExpend, 'post', url, true, false);
  }

  // post: api/campaign/iie/add
  public addIie(iieData: IieDTO): Promise<void> {
    let url = `api/campaign/iie/add`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, void>(iieData, 'post', url, true, false);
  }

  // post: api/campaign/dual-comp/add
  public saveDualComp(dualComp: DualCompDTO): Promise<void> {
    let url = `api/campaign/dual-comp/add`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, void>(dualComp, 'post', url, true, false);
  }

  // post: api/campaign/salesbystate/add
  public saveSalesByState(sbs: SaleByStateDTO): Promise<void> {
    let url = `api/campaign/salesbystate/add`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, void>(sbs, 'post', url, true, false);
  }

  // post: api/campaign/petitionWaiver/add
  public savePetitionForWaiver(petitionForWaiver: PetitionForWaiverDTO): Promise<void> {
    let url = `api/campaign/petitionWaiver/add`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, void>(petitionForWaiver, 'post', url, true, false);
  }
}
var service = new CampaignApiService();
export default service;
