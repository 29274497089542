import FormItem from 'antd/lib/form/FormItem';
import { notification, AutoComplete, Select, Spin } from 'antd';
import * as React from 'react';
import SearchApiService from '../../api/SearchApiService';
import CommitteeInfoDTO from '../../models/CommitteeInfoDTO';
import debounce from 'lodash/debounce';
import { FormInstance, Rule } from 'antd/lib/form';

const { Option } = Select;

interface CommitteeAutoSearchProps {
  name: string;
  label?: React.ReactNode | string;
  required?: boolean;
  disabled?: boolean;
  value?: string;
  hidden?: boolean;
  noMargin?: boolean;
  requireSelection?: boolean;
  onFocus?: () => void;
  onBlur?: () => void;
  onSelect?: (committeeId: string) => void;
  onChange?: (value: string) => void;
  onSelectCommittee?: (committee: CommitteeInfoDTO | undefined) => void;
  filerTypeCodes?: number[];
}

interface CommitteeAutoSearchState {
  committees: CommitteeInfoDTO[];
  loading: boolean;
}

class CommitteeAutoSearch extends React.Component<CommitteeAutoSearchProps, CommitteeAutoSearchState> {
  private readonly _formRef = React.createRef<FormInstance>();

  constructor(props: CommitteeAutoSearchProps) {
    super(props);

    this.searchCommitteeInfo = debounce(this.searchCommitteeInfo, 400);
    this.state = {
      committees: [],
      loading: false,
    };
  }

  render() {
    const { committees, loading } = this.state;
    const { name, label, required, disabled, hidden, noMargin, requireSelection, value } = this.props;
    const CommitteeSearchNameRules: Rule[] = [];

    if (required !== false) {
      CommitteeSearchNameRules.push({ required: true, message: (label || 'Committee') + ' is required' });
    }

    const style: React.CSSProperties = {};
    if (noMargin) {
      style.marginBottom = '0';
    }

    const options = loading ? [{ key: '', value: '', label: '' }] : committees.map(c => ({
      key: c.id || '',
      value: (c.committeeCode ? '#' + c.committeeCode + ' - ' : '') + c.name,
      label: (c.committeeCode ? '#' + c.committeeCode + ' - ' : '') + c.name
    }));

    return (
      <>
        {requireSelection ? (
          <FormItem
            name={name}
            label={label || ''}
            rules={CommitteeSearchNameRules}
            hidden={hidden}
            style={style}
          >
            <Select
              filterOption={false /*this option allows loading spinner to work*/}
              aria-label="Search for a committee"
              showSearch={true}
              optionFilterProp="children"
              notFoundContent={false}
              showArrow={false}
              disabled={disabled || false}
              placeholder={'Search for a committee...'}
              allowClear={true}
              onSearch={this.handleCommitteeSearch}
              onClear={this.handleClear}
              onFocus={this.handleFocus}
              onBlur={this.handleBlur}
              onChange={this.handleCommitteeChange}
              value={value}
              dropdownRender={(a) => {
                if (loading) {
                  return <div className="center-spinner"><Spin spinning={loading}>{a}</Spin></div>;
                }
                else {
                  return a;
                }
              }}>
              {/*we need value to be committeeId. We pick committee by id in onChange*/}
              {options.map(c => (
                <Option key={c.key || ''} value={c.key || ''}>
                  {c.label ? (
                    c.value
                  ) :
                    ('')
                  }
                </Option>
              ))}
            </Select>
          </FormItem >
        ) : (
          <FormItem name={name} label={label || ''} rules={CommitteeSearchNameRules} hidden={hidden} style={style}>
              <AutoComplete
              filterOption={false /*this option allows loading spinner to work */}
              aria-label="Search for a committee"
              disabled={disabled || false}
              placeholder={'Search for a committee...'}
              allowClear={true}
              onSearch={this.handleCommitteeSearch}
              onClear={this.handleClear}
              onFocus={this.handleFocus}
              onBlur={this.handleBlur}
              onChange={this.handleCommitteeChange}
              options={options}
              value={value}
              dropdownRender={(a) => {
                if (loading) {
                  return <div className="center-spinner"><Spin spinning={loading}>{a}</Spin></div>;
                }
                else {
                  return a;
                }
              }}>
            </AutoComplete>
          </FormItem>
        )}
      </>
    );
  }

  private searchCommitteeInfo = (committeeNameOrId: any) => {
    SearchApiService.getCommitteeByNameOrId({criteria: committeeNameOrId, filerTypeIds: this.props.filerTypeCodes || [] })
      .then((committees) => {
        this.setState({ committees, loading: false });
      })
      .catch(() => {
        notification.error({
          message: 'Failed to retrieve committees.',
        });
      });
  }

  private handleClear = () => {
    this.setState({ committees: [] });
    if (this.props.onSelect) {
      this.props.onSelect('');
    }
    if (this.props.onSelectCommittee) {
      this.props.onSelectCommittee(undefined);
    }
  }

  private handleCommitteeSearch = (value: string) => {
    if (!value || value.length > 0) {
      if (this.props.onChange) {
        this.props.onChange(value);
      }
    }
    if (!value || value.length < 3) {
      if (this.state.committees !== []) {
        this.setState({ committees: [], loading: false });
      }
      return;
    }
    this.setState({ loading: true });
    this.searchCommitteeInfo(value);
  }

  private handleCommitteeChange = (value: string) => {
    let selectedCommittee;
    if (!this.props.requireSelection) {
      selectedCommittee = this.state.committees.find(c => ((c.committeeCode ? '#' + c.committeeCode + ' - ' : '') + c.name) === value);
    }
    else { //Select passes out id instead of name
      selectedCommittee = this.state.committees.find(c => c.id === value);
    }

    if (selectedCommittee) {
      this.setState({ committees: [selectedCommittee] });
      if (this.props.onSelect) {
        this.props.onSelect(selectedCommittee?.id || '');
      }
      if (this.props.onSelectCommittee) {
        this.props.onSelectCommittee(selectedCommittee);
      }
    }
  }

  private handleFocus = () => {
    if (this.props.onFocus) {
      this.props.onFocus();
    }
  }

  private handleBlur = () => {
    if (this.props.onBlur) {
      this.props.onBlur();
    }
  }
}

export default CommitteeAutoSearch;
