import {
    Button,
  Layout,
  Space,
  Typography,
} from 'antd';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import DataTable, { DataTableColumnProps, FilterType } from '../../shared/DataTable/DataTable';
import DataTableButtonUtil from '../../shared/DataTable/DataTableButtonUtil';
import StatementsOfOrganizationAndDissolutionDTO from '../../../models/StatementsOfOrganizationAndDissolutionDTO';
import DataTableColumnUtil from '../../shared/DataTable/DataTableColumnUtil';
import ReportingOrganizationTypes from '../../../consts/ReportingOrganizationTypes';
import PublicReportsApiService from '../../../api/PublicReportsApiService';
import ReportTypeDescriptions from '../../../consts/ReportTypeDescriptions';
import HistoryUtil from '../../../utils/HistoryUtil';
import Routes from '../../../consts/Routes';
import { ArrowLeftOutlined } from '@ant-design/icons';
import DataTableCellRendererUtil from '../../shared/DataTable/DataTableCellRendererUtil';
import moment from 'moment';
import Modal from 'antd/lib/modal/Modal';
import Dr1HistoryDTO from '../../../models/Dr1HistoryDTO';

const { Content } = Layout;

interface SODProps {
}

interface SODState {
  tableColumns: DataTableColumnProps<StatementsOfOrganizationAndDissolutionDTO>[];
  showModal: boolean;
  fileData: StatementsOfOrganizationAndDissolutionDTO | null;
}

class StatementsOfOrganizationAndDissolution extends React.Component<RouteComponentProps<SODProps>, SODState> {

  constructor(props: RouteComponentProps<SODProps>) {
    super(props);

    this.state = {
      tableColumns: this.getTableColumns(),
      showModal: false,
      fileData: null
    };
  }

  render() {
    const fileData = this.state.fileData;
    const actionButtons = [];
    const { showModal } = this.state;
    actionButtons.push(DataTableButtonUtil.Reset());
    return (
      <Content className="content-pad">
        <DataTable
          globalSearch={true}
          serverSide={true}
          buttonBar={actionButtons}
          columns={this.state.tableColumns}
          tableProps={{
            rowKey: 'id',
            sortDirections: ['ascend', 'descend'],
            locale: { emptyText: 'No Reports Currently Match Your Filter' }
          }}
          fetchData={{
            fetch: function (tableRequest) {
              const dto = Dr1HistoryDTO.create({ table: tableRequest, row: null });
              return PublicReportsApiService.getStatementsOfOrganizationsAndDissolution(dto)
                .then(res => {
                  res.results?.forEach(r => {
                    if (r.filedOn) {
                      r.filedOn = moment.utc(r.filedOn).local();
                    }
                  });
                  return res;
                });
            },
            failureMessage: 'Failed to retrieve public reports'
          }}
          styleOptions={{ compact: true, alternatingRowHighlight: true }}
          title={
            <Button type="link" onClick={() => HistoryUtil.push(Routes.generate(Routes.PUBLIC_REPORTS))}>
              <Typography.Title level={4}>
                <Space size="large">
                  <ArrowLeftOutlined className="public-report-links"/> Statements of Organization and Dissolution
                </Space>
              </Typography.Title>
            </Button>
          }
        />
        <Modal
          title={
            <Typography.Title level={4}>
              <Space size="large">
                Past Filings
              </Space>
            </Typography.Title>
          }
          visible={showModal}
          onCancel={this.handleCancel}
          footer={null}
          width={1500}
          destroyOnClose={true}
        >
          <DataTable
            globalSearch={true}
            serverSide={true}
            buttonBar={actionButtons}
            columns={this.state.tableColumns}
            tableProps={{
              rowKey: 'id',
              sortDirections: ['ascend', 'descend'],
              locale: { emptyText: 'No Reports Currently Match Your Filter' }
            }}
            fetchData={{
              fetch: function (tableRequest) {
                const dto = Dr1HistoryDTO.create({ table: tableRequest, row: fileData });
                return PublicReportsApiService.getStatementsOfOrganizationsAndDissolution(dto)
                  .then(res => {
                    res.results?.forEach(r => {
                      if (r.filedOn) {
                        r.filedOn = moment.utc(r.filedOn).local();
                      }
                    });
                    return res;
                  });
              },
              failureMessage: 'Failed to retrieve public reports'
            }}
            styleOptions={{ compact: true, alternatingRowHighlight: true }}
            title={
                <Typography.Title level={4}>
                  <Space size="large">
                  Statements of Organization and Dissolution
                  </Space>
                </Typography.Title>
            }
          />
        </Modal>
      </Content>
    );
  }

  private handleCancel = () => {
    this.setState({ showModal: false, fileData: null });
  }

  private getTableColumns = (): DataTableColumnProps<StatementsOfOrganizationAndDissolutionDTO>[] => {
    const filedOn = DataTableColumnUtil.Date<StatementsOfOrganizationAndDissolutionDTO>('Filed On', 'filedOn');
    filedOn.render = DataTableCellRendererUtil.DateAndTime;
    return [
      DataTableColumnUtil.DropdownMulti('Report Type', 'reportType',
        [
          { text: ReportTypeDescriptions.DR1, value: ReportTypeDescriptions.DR1 },
          { text: ReportTypeDescriptions.DR3, value: ReportTypeDescriptions.DR3 },
          { text: ReportTypeDescriptions.DRSFA, value: ReportTypeDescriptions.DRSFA }
        ]),
      DataTableColumnUtil.DropdownMulti('Organization Type', 'organizationType',
        [
          { text: ReportingOrganizationTypes.CANDIDATE, value: ReportingOrganizationTypes.CANDIDATE },
          { text: ReportingOrganizationTypes.PAC, value: ReportingOrganizationTypes.PAC },
          { text: ReportingOrganizationTypes.PARTY, value: ReportingOrganizationTypes.PARTY }
        ]),
      DataTableColumnUtil.Text('Candidate', 'candidateName'),
      DataTableColumnUtil.Text('Committee Code', 'committeeCode'),
      DataTableColumnUtil.Text('Committee', 'committeeName', null, { defaultSortOrder: 'ascend' }),
      filedOn,
      DataTableColumnUtil.BooleanCheckbox('Closed', 'isClosed', null, FilterType.BooleanRadio),
      DataTableColumnUtil.Text('Year', 'yearClosed'),
      DataTableColumnUtil.Buttons('fileName',
        [
          { text: 'View', onClick: (rowData) => window.open(rowData.fileUrl || '') },
          {
            text: 'History', onClick: (rowData) => this.setState({ showModal: true, fileData: rowData }),
            visible: (rowData) => (rowData.reportType != 'DRSFA' && this.state.fileData == null)
          },
        ]
      ),
    ];
  }

}

export default StatementsOfOrganizationAndDissolution;