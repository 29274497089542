import { DatePicker, DatePickerProps } from 'antd';
import * as React from 'react';
import moment from 'moment';
import DateUtil from '../../utils/DateUtil';

interface CustomDatePickerProps {
    disabledDates?: (current: moment.Moment) => boolean;
    format?: string;
}

class CustomDatePicker extends React.Component<CustomDatePickerProps & DatePickerProps, unknown> {
  constructor(props: CustomDatePickerProps & DatePickerProps) {
    super(props);
  }

  render() {
    return (
      <DatePicker
        format={this.props.format ?? DateUtil.getDateFormatString()}
        {...this.props}
        disabledDate={this.disabledDates}
      />
    );
  }

  // Disable dates outside of sql datetime range
  private disabledDates = (current: moment.Moment): boolean => {
    return (
      (this.props.disabledDates && this.props.disabledDates(current)) ||
      (current && (!current.isBefore(moment().add(10, 'years')) || !current.isAfter('1752-12-31')))
    );
  }
}

export default CustomDatePicker;
