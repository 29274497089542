// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import EntitySelectorDTO from './EntitySelectorDTO';

interface BuildingFundFilingDTO extends EntitySelectorDTO { 
  buildingFundFilingId: number;
  committeeID: string | null;
  coveredYear: string | null;
  filingStatusId: string | null;
  filingStatusNm: string | null;
  filedDate: moment.Moment | string | null;
  dueDate: moment.Moment | string | null;
  startingBalance: number | null;
  totalContributions: number | null;
  totalExpenditures: number | null;
  subtotal: number | null;
  endingBalance: number | null;
  createdOn: moment.Moment | string | null;
  lastUpdatedOn: moment.Moment | string | null;
  lastUpdateBy: string | null;
  auditDate: moment.Moment | string | null;
}
const BuildingFundFilingDTO = {
  create: (initValues?: Partial<BuildingFundFilingDTO> | Record<string, unknown> | null) => {
    return Object.assign(EntitySelectorDTO.create(),
    {
      buildingFundFilingId: 0,
      committeeID: "00000000-0000-0000-0000-000000000000",
      coveredYear: null,
      filingStatusId: null,
      filingStatusNm: null,
      filedDate: null,
      dueDate: null,
      startingBalance: null,
      totalContributions: null,
      totalExpenditures: null,
      subtotal: null,
      endingBalance: null,
      createdOn: null,
      lastUpdatedOn: null,
      lastUpdateBy: null,
      auditDate: null,
    },
    initValues);
  }
};

export default BuildingFundFilingDTO;