import { Divider, Layout, Modal, notification, Typography } from 'antd';
import * as React from 'react';
import ScheduleApiService from '../../api/ScheduleApiService';
import SchH1CampaignPropertyApiService from '../../api/SchH1CampaignPropertyApiService';
import Dr2AmendedIndicators from '../../consts/Dr2AmendedIndicators';
import Dr2AmendedStatuses from '../../consts/Dr2AmendedStatuses';
import NoteEntityParents from '../../consts/NoteEntityParents';
import Role from '../../consts/Role';
import Routes from '../../consts/Routes';
import ScheduleAbbreviationCodes from '../../consts/ScheduleAbbreviationCodes';
import YesNoIndicators from '../../consts/YesNoIndicators';
import YesNoStatuses from '../../consts/YesNoStatuses';
import SchH1CampaignPropertyDTO from '../../models/SchH1CampaignPropertyDTO';
import CurrentUser from '../../utils/CurrentUser';
import HistoryUtil from '../../utils/HistoryUtil';
import NumberFormatUtil from '../../utils/NumberFormatUtil';
import DataTable, { DataTableColumnProps } from '../shared/DataTable/DataTable';
import DataTableButtonUtil from '../shared/DataTable/DataTableButtonUtil';
import DataTableColumnUtil from '../shared/DataTable/DataTableColumnUtil';
import NotesModal from '../shared/NotesModal';
import Dr2StatusBar from './Dr2StatusBar';

const { Content } = Layout;
const { Text } = Typography;
const { confirm } = Modal;

interface SchH1CampaignPropertyProps {
}

interface SchH1CampaignPropertyState {
  tableColumns: DataTableColumnProps<SchH1CampaignPropertyDTO>[];
  numLoansRepaid: number;
  totalOfSched: number;
  flagNoteId: string;
  camPropId: string;
  showModal: boolean;
}

class SchH1CampaignProperty extends React.Component<SchH1CampaignPropertyProps, SchH1CampaignPropertyState> {
  constructor(props: SchH1CampaignPropertyProps) {
    super(props);

    this.state = {
      tableColumns: this.getTableColumns(),
      numLoansRepaid: 0,
      totalOfSched: 0,
      flagNoteId: '',
      camPropId: '',
      showModal: false,
    };
  }

  private dataTable: DataTable<SchH1CampaignProperty> | undefined;

  componentDidMount() {
    this.getStatistics();
  }

  render() {
    const { tableColumns, numLoansRepaid, totalOfSched, flagNoteId, camPropId, showModal } = this.state;
    const actionButtons = [];
    actionButtons.push(DataTableButtonUtil.Reset());
    actionButtons.push(DataTableButtonUtil.Primary(
      'New Property',
      () => HistoryUtil.push(Routes.generate(Routes.ADD_SCHEDULE_H1_CAMPAIGN_PROPERTY, {}))
    ));

    const currUser = CurrentUser.Get();

    return (
    <>
      <Content className="content-pad">
        <Dr2StatusBar dr2Id={CurrentUser.Get()?.dr2Id || ''} />
        <Text strong> Number of Payments: {numLoansRepaid}&emsp;|&emsp;Total of Schedule: {NumberFormatUtil.currency(totalOfSched)}&emsp;| </Text>
        <NotesModal parentId={currUser?.dr2Id || ''} parent={NoteEntityParents.SCHEDULE} scheduleCd={ScheduleAbbreviationCodes.SCHEDULEH1} />
        <Divider />
        <DataTable
          buttonBar={actionButtons}
          columns={tableColumns}
          fetchData={{
            fetch: function (tableRequest) {
              return SchH1CampaignPropertyApiService.getSchH1CampaignPropertiesList(tableRequest, currUser?.dr2Id || '');
            },
            failureMessage: 'Failed to retrieve Campaign Properties'
          }}
          globalSearch={true}
          ref={(element: any) => (this.dataTable = element)}
          serverSide={true}
          styleOptions={{ compact: true, alternatingRowHighlight: true }}
          tableProps={{
            rowKey: 'campaignPropertyIdAndSeq',
            sortDirections: ['ascend', 'descend'],
            locale: { emptyText: 'Currently there are no campaign properties to manage.' }
          }}
          title="Schedule H1 - Campaign Property"
          stateSaving={{
              enabled: true,
              tableUniqueKey: 'manageSchH1',
              perSession: true,
          }}
        />
        </Content>
        {showModal &&
          <NotesModal
            parentId={camPropId || ''}
            parent={NoteEntityParents.CAMPAIGNPROPERTY}
            auditorsNoteId={flagNoteId}
            canFlag={true}
            onClose={() => { this.setState({ showModal: false }, () => this.dataTable?.refresh()); }} />
        }
      </>
    );
  }

  private getStatistics = () => {
    ScheduleApiService.getScheduleCount(CurrentUser.Get()?.dr2Id || '', ScheduleAbbreviationCodes.SCHEDULEH1)
      .then(numLoansRepaid => {
        this.setState({ numLoansRepaid });
      }).catch(error => {
        notification.error({
          message: 'Error while fetching the number of campaign properties',
          description: error
        });
      });

    ScheduleApiService.getScheduleTotal(CurrentUser.Get()?.dr2Id || '', ScheduleAbbreviationCodes.SCHEDULEH1)
      .then(totalOfSched => {
        this.setState({ totalOfSched });
      }).catch(error => {
        notification.error({
          message: 'Error while fetching the total of schedule',
          description: error
        });
      });
  }

  private getTableColumns = (): DataTableColumnProps<SchH1CampaignPropertyDTO>[] => {
    return [
      DataTableColumnUtil.Date('Date', 'acquiredDate', 100, { defaultSortOrder: 'ascend' }),
      DataTableColumnUtil.Text('Property', 'property'),
      DataTableColumnUtil.Currency('Paid Amount', 'paidAmount'),
      DataTableColumnUtil.Currency('Current Value', 'currentValue'),
      DataTableColumnUtil.DropdownMulti('Value Estimated', 'valueEstimatedInd',
        [
          { text: YesNoStatuses.YES, value: YesNoIndicators.YES },
          { text: YesNoStatuses.NO, value: YesNoIndicators.NO }
        ]),
      DataTableColumnUtil.DropdownMulti('Status', 'status',
        [
          { text: Dr2AmendedStatuses.ORIGINAL, value: Dr2AmendedIndicators.ORIGINAL },
          { text: Dr2AmendedStatuses.AMENDED, value: Dr2AmendedIndicators.AMENDED },
          { text: Dr2AmendedStatuses.ADJUSTED, value: Dr2AmendedIndicators.ADJUSTED },
          { text: Dr2AmendedStatuses.DELETED, value: Dr2AmendedIndicators.DELETED }
        ]),
      DataTableColumnUtil.FlagButton('Flagged', 'flaggedNoteId', CurrentUser.Get()?.isInRole(Role.IECDB_ADMINISTRATOR) || false, [
        {
          onClick: (rowData) => this.openFlagModal(rowData.flaggedNoteId || '', rowData.campaignPropertyId || ''),
          visible: (rowData) => rowData.flaggedNoteId != null
        }
      ]),
      DataTableColumnUtil.Buttons('campaignPropertyIdAndSeq',
        [
          {
            text: 'Edit',
            onClick: (rowData) =>
              HistoryUtil.push(Routes.generate(
                Routes.EDIT_SCHEDULE_H1_CAMPAIGN_PROPERTY,
                {
                  id: rowData.campaignPropertyId || '',
                  seqNum: rowData.seqNumber || ''
                }
              ))
          },
          {
            text: 'Delete',
            onClick: (rowData) => this.confirmDelete(
              rowData.campaignPropertyId || '',
              rowData.seqNumber || 0,
              rowData.property || '',
              rowData.currentValue || 0
            )
          }
        ],
        150)
    ];
  }

  private openFlagModal = (flagNoteId: string, camPropId: string) => {
    this.setState({ showModal: true, flagNoteId, camPropId }, () => this.dataTable?.refresh());
  }

  private showTableAndRefresh = () => {
    if (this.dataTable) {
      this.dataTable.refresh();
    }
    this.getStatistics();
  }

  private confirmDelete = (campaignPropertyId: string, seqNum: number, payeeName: string, amount: number) => {
    confirm({
      title: 'Are you sure you want to delete this campaign property?',
      content: 'The following record will be permanently deleted: ' + payeeName + ' - $' + amount,
      onOk: () => {
        this.deleteCampaignProperty(campaignPropertyId, seqNum);
      },
    });
  }

  private deleteCampaignProperty = (campaignPropertyId: string, seqNum: number) => {
    SchH1CampaignPropertyApiService.delete(campaignPropertyId, seqNum)
      .then(() => {
        this.showTableAndRefresh();
        notification.success({
          message: 'Deleted Successfully'
        });
      }).catch(() => {
        notification.error({
          message: 'Error while deleting campaign property',
          description: '',
        });
      });
  }
}

export default SchH1CampaignProperty;