import {
  Button,
  Col,
  Layout,
  Radio,
  Row,
  Typography,
  Space,
} from 'antd';
import { RouteComponentProps } from 'react-router-dom';
import FormItem from 'antd/lib/form/FormItem';
import * as React from 'react';
import Routes from '../../consts/Routes';
import HistoryUtil from '../../utils/HistoryUtil';
import CustomForm from '../shared/CustomForm';

const { Content } = Layout;

interface ManualRegistrationProps {
}

interface ManualRegistrationState {
  radioValue: number;
  loading: boolean;
}

class ManualRegistrationPage extends React.Component<RouteComponentProps<ManualRegistrationProps>, ManualRegistrationState>{
  constructor(props: RouteComponentProps<ManualRegistrationProps>) {
    super(props);

    this.state = {
      radioValue: 1,
      loading: false,
    };
  }

  onChangeRadioButtons = (e: any) => {
    this.setState({
      radioValue: e.target.value,
    });
  };

  render() {
    const { loading, radioValue } = this.state;

    return (
      <Content className="content-pad">
        <Typography.Title level={2}>New Statement of Org or VSR - Committee Type</Typography.Title>
        {loading ? undefined :
          <CustomForm
            name="ManualRegistration"
            layout="vertical">
            <Row>
              <Col >
                <FormItem label="Select One" required={true} style={{ marginBottom: '0' }}>
                  <Radio.Group value={radioValue} onChange={this.onChangeRadioButtons} style={{ width: '100%' }}>
                    <Space direction="vertical" style={{ width: '100%' }}>

                      <Radio value={1}>Candidate Committee</Radio>

                      <Radio value={2}>PAC / Political Committee</Radio>

                      <Radio value={3}>Out of State / Federal PAC or Candidate</Radio>

                      <Radio value={4}>OTC</Radio>

                      <Radio value={5}>PFD</Radio>

                    </Space>
                  </Radio.Group>
                </FormItem>
              </Col>
            </Row>
            <br />
            <Space>
              <Button type="primary" onClick={this.nextPage}>Next</Button>
              <Button onClick={this.cancelForm}>Cancel</Button>
            </Space>
          </CustomForm>
        }
      </Content>
    );
  }

  private cancelForm = () => {
    HistoryUtil.push(Routes.ADMIN_REVIEWACCOUNT);
  }

  private nextPage = () => {
    if (this.state.radioValue === 1) {
      HistoryUtil.push(Routes.DR1);
    }
    else if (this.state.radioValue === 2) {
      HistoryUtil.push(Routes.DR1);
    }
    else if (this.state.radioValue === 3) {
      HistoryUtil.push(Routes.VSR);
    }
    else if (this.state.radioValue === 4) {
      HistoryUtil.push(Routes.OTC);
    }
    else if (this.state.radioValue === 5) {
      HistoryUtil.push(Routes.PFD);
    }
  }
}

export default ManualRegistrationPage;