import { Col, Layout, Modal, notification, Row} from 'antd';
import * as React from 'react';
import AdminOtcApiService from '../../api/AdminOtcApiService';
import FilingStatusType from '../../consts/FilingStatusType';
import Routes from '../../consts/Routes';
import AdminOtcListDTO from '../../models/AdminOtcListDTO';
import HistoryUtil from '../../utils/HistoryUtil';
import DataTable, { DataTableColumnProps, FilterType } from '../shared/DataTable/DataTable';
import DataTableButtonUtil from '../shared/DataTable/DataTableButtonUtil';
import DataTableColumnUtil from '../shared/DataTable/DataTableColumnUtil';

const { Content } = Layout;
const { confirm } = Modal;

interface ManageOtcProps {
}

interface ManageOtcState {
  tableManageColumns: DataTableColumnProps<AdminOtcListDTO>[];
  tableApprovalColumns: DataTableColumnProps<AdminOtcListDTO>[];
}

class ManageOtcPage extends React.Component<ManageOtcProps, ManageOtcState> {
  private dataManageTable: DataTable<AdminOtcListDTO> | undefined;
  private dataApproveTable: DataTable<AdminOtcListDTO> | undefined;

  constructor(props: ManageOtcProps) {
    super(props);
    this.state = {
      tableManageColumns: this.getManageTableColumns(),
      tableApprovalColumns: this.getApprovalTableColumns(),
    };
  }

  render() {
    const manageActionButtons = [];
    manageActionButtons.push(DataTableButtonUtil.Reset());

    const approveActionButtons = [];
    approveActionButtons.push(DataTableButtonUtil.Reset());

    return (
      <Content className="content-pad">
        <Row>
          <Col xs={24}>
            <DataTable
              ref={(element: any) => (this.dataApproveTable = element)}
              serverSide={true}
              tableProps={{
                rowKey: 'contributionId',
                sortDirections: ['ascend', 'descend'],
                locale: {
                  emptyText: 'Currently there are no OTCs that need to be approved.'
                },
              }}
              globalSearch={true}
              title="Approve OTC"
              buttonBar={approveActionButtons}
              columns={this.state.tableApprovalColumns}
              fetchData={{
                fetch: function (tableRequest) { return AdminOtcApiService.getOtcList(tableRequest, FilingStatusType.FILED); },
                failureMessage: 'Failed to retrieve One Time Contributions'
              }}
              styleOptions={{ compact: true, alternatingRowHighlight: true }}
              stateSaving={{
                enabled: true,
                tableUniqueKey: 'adminApproveOtc',
                perSession: true,
              }}
            />
          </Col>
        </Row>
        <br />
        <Row>
          <Col xs={24}>
            <DataTable
              ref={(element: any) => (this.dataManageTable = element)}
              serverSide={true}
              tableProps={{
                rowKey: 'contributionId',
                sortDirections: ['ascend', 'descend']
              }}
              globalSearch={true}
              title="Manage OTC"
              buttonBar={manageActionButtons}
              columns={this.state.tableManageColumns}
              fetchData={{
                fetch: function (tableRequest) {
                  return AdminOtcApiService.getOtcList(tableRequest, FilingStatusType.AUDITED + ',' + FilingStatusType.ADJUSTED);
                },
                failureMessage: 'Failed to retrieve One Time Contributions'
              }}
              styleOptions={{ compact: true, alternatingRowHighlight: true }}
              stateSaving={{
                enabled: true,
                tableUniqueKey: 'adminManageOtc',
                perSession: true,
              }}
            />
          </Col>
          </Row>
      </Content>
    );
  }

  private getTableColumns = (): DataTableColumnProps<AdminOtcListDTO>[] => {
    return [
      DataTableColumnUtil.Date('Date', 'dateOfContribution', 100, { defaultSortOrder: 'descend' }),
      DataTableColumnUtil.Currency('Amount', 'amount', 50),
      DataTableColumnUtil.Address(
        'Organization',
        'organizationName',
        (r) => ({
          name: r.organizationName,
          line1: r.organizationAddress1,
          line2: r.organizationAddress2,
          city: r.organizationAddressCity,
          state: r.organizationAddressState,
          zip: r.organizationAddressZip
        })
      ),
      DataTableColumnUtil.Address(
        'Committee',
        'committeeName',
        (r) => ({
          name: r.committeeName,
          line1: r.committeeAddress1,
          line2: r.committeeAddress2,
          city: r.committeeCity,
          state: r.committeeState,
          zip: r.committeeZip
        })
      ),
    ];
  };

  private getManageTableColumns = (): DataTableColumnProps<AdminOtcListDTO>[] => {
    return this.getTableColumns().concat([
      DataTableColumnUtil.BooleanCheckbox('Penalty', 'penalty', 10, FilterType.BooleanRadio),
      DataTableColumnUtil.Buttons(
        'contributionId',
        [
          { text: 'Edit', onClick: (rowData) => this.editOtc(rowData.contributionId || '') },
          { text: 'Delete', onClick: (rowData) => this.confirmDelete(rowData.contributionId || '', rowData.organizationName || '') },
          { text: 'Penalty', onClick: (rowData) => this.editPenalty(rowData.contributionId || '') },
        ],
        215)
    ]);
  }

  private getApprovalTableColumns = (): DataTableColumnProps<AdminOtcListDTO>[] => {
    return this.getTableColumns().concat([
      DataTableColumnUtil.Buttons(
        'contributionId',
        [
          { text: 'View', onClick: (rowData) => this.editOtc(rowData.contributionId || '') },
          { text: 'Approve', onClick: (rowData) => this.updateStatus(rowData.contributionId || '', FilingStatusType.AUDITED) },
          { text: 'Reject', onClick: (rowData) => this.confirmReject(rowData.contributionId || '', rowData.organizationName || '') },
        ],
        215)
    ]);
  };

  private updateStatus = (contributionId: string, status: string) => {
    AdminOtcApiService.updateStatus(contributionId, status)
      .then(() => {
        if (this.dataApproveTable) {
          this.dataApproveTable.refresh();
        }
        if (this.dataManageTable) {
          this.dataManageTable.refresh();
        }
        notification.success({ message: 'Your changes were saved sucessfully' });
      }).catch(() => {
        notification.error({
          message: 'Failed to update status.'
        });
      });
  }

  private confirmReject = (contributionId: string, organizationName: string) => {
    confirm({
      title: 'Are you sure you want to reject this OTC?',
      content: 'The following record will be rejected: ' + organizationName,
      onOk: () => {
        this.deleteOtc(contributionId);
      },
    });
  }

  private confirmDelete = (contributionId: string, organizationName: string) => {
    confirm({
      title: 'Are you sure you want to delete this OTC?',
      content: 'The following record will be permanently deleted: ' + organizationName,
      onOk: () => {
        this.deleteOtc(contributionId);
      },
    });
  }

  private deleteOtc = (contributionId: string) => {
    AdminOtcApiService.deleteOtc(contributionId)
      .then(() => {
        if (this.dataManageTable) {
          this.dataManageTable.refresh();
        }
          if (this.dataApproveTable) {
            this.dataApproveTable.refresh();
          }
          notification.success({ message: 'OTC was deleted successfully.' });
      }).catch(() => {
        notification.error({ message: 'Failed to delete OTC.' });
      });
  }

  private editPenalty = async (id: string) => {
    HistoryUtil.push(Routes.generate(Routes.OTC_PENALTY, { id: id }));
  }

  private editOtc = async (id: string) => {
    HistoryUtil.push(Routes.generate(Routes.EDIT_OTC, { id: id }));
  }
}

export default ManageOtcPage;