import { Col, Layout, Modal, notification, Row } from 'antd';
import * as React from 'react';
import AdminGiftBequestApiService from '../../api/AdminGiftBequestApiService';
import AdminGiftBequestListDTO from '../../models/AdminGiftBequestListDTO';
import DataTableColumnUtil from '../../components/shared/DataTable/DataTableColumnUtil';
import DataTableButtonUtil from '../shared/DataTable/DataTableButtonUtil';
import GbStatus from '../../consts/GbStatus';
import DataTable, { DataTableColumnProps } from '../shared/DataTable/DataTable';
import HistoryUtil from '../../utils/HistoryUtil';
import Routes from '../../consts/Routes';

const { Content } = Layout;
const { confirm } = Modal;
//In Order: Department, Date, Donor, Amount, Description, Status, Edit Link

interface ManageGiftBequestsProps {
}

interface ManageGiftBequestsState {
  tableApproveColumns: DataTableColumnProps<AdminGiftBequestListDTO>[];
  tableManageColumns: DataTableColumnProps<AdminGiftBequestListDTO>[];
}

class ManageGiftBequests extends React.Component<ManageGiftBequestsProps, ManageGiftBequestsState> {
  private dataApproveTable: DataTable<AdminGiftBequestListDTO> | undefined;
  private dataManageTable: DataTable<AdminGiftBequestListDTO> | undefined;

  constructor(props: ManageGiftBequestsProps) {
    super(props);

    this.state = {
      tableApproveColumns: this.getTableColumns('approve'),
      tableManageColumns: this.getTableColumns('manage')
    };
  }

  render() {
    const approveActionButtons = [];
    approveActionButtons.push(DataTableButtonUtil.Reset());

    const manageActionButtons = [];
    manageActionButtons.push(DataTableButtonUtil.Reset());

    return (
      <Content className="content-pad">
        <Row>
          <Col xs={24}>
            <DataTable
              buttonBar={approveActionButtons}
              columns={this.state.tableApproveColumns}
              fetchData={{
                fetch: function (tableRequest) { return AdminGiftBequestApiService.getGiftBequestList(tableRequest, true); },
                failureMessage: 'Failed to retrieve Gifts / Bequests'
              }}
              globalSearch={true}
              ref={(element: any) => (this.dataApproveTable = element)}
              serverSide={true}
              styleOptions={{ compact: true, alternatingRowHighlight: true }}
              tableProps={{
                rowKey: 'giftId',
                sortDirections: ['ascend', 'descend'],
                locale: { emptyText: 'Currently there are no gifts/bequests to approve.' }
              }}
              title="Approve Gift Bequests"
              stateSaving={{
                enabled: true,
                tableUniqueKey: 'giftId',
                perSession: true,
              }}
            />
          </Col>
        </Row>
        <br />
        <Row>
          <Col xs={24}>
            <DataTable
              buttonBar={manageActionButtons}
              columns={this.state.tableManageColumns}
              fetchData={{
                fetch: function (tableRequest) { return AdminGiftBequestApiService.getGiftBequestList(tableRequest, false); },
                failureMessage: 'Failed to retrieve Gifts / Bequests'
              }}
              globalSearch={true}
              ref={(element: any) => (this.dataManageTable = element)}
              serverSide={true}
              styleOptions={{ compact: true, alternatingRowHighlight: true }}
              tableProps={{
                rowKey: 'giftId',
                sortDirections: ['ascend', 'descend'],
                locale: { emptyText: 'Currently there are no Gifts / Bequests to manage.' }
              }}
              title="Manage Gift Bequests"
              stateSaving={{
                enabled: true,
                tableUniqueKey: 'giftId',
                perSession: true,
              }}
            />
          </Col>
        </Row>
      </Content>

    );
  }

  private getTableColumns = (tableName: string): DataTableColumnProps<AdminGiftBequestListDTO>[] => {
    const columns: DataTableColumnProps<AdminGiftBequestListDTO>[] = [];

    const columnDepartment: DataTableColumnProps<AdminGiftBequestListDTO> = DataTableColumnUtil.Text('Department', 'department');
    columns.push(columnDepartment);

    const columnDate: DataTableColumnProps<AdminGiftBequestListDTO> = DataTableColumnUtil.Date('Date',
      'giftDate', 100, { defaultSortOrder: 'descend' });
    columns.push(columnDate);

    const columnDonor: DataTableColumnProps<AdminGiftBequestListDTO> = DataTableColumnUtil.Text('Donor', 'donorName');
    columns.push(columnDonor);

    const columnAmount: DataTableColumnProps<AdminGiftBequestListDTO> = DataTableColumnUtil.Currency('Amount', 'amount');
    columns.push(columnAmount);

    const columnDescription: DataTableColumnProps<AdminGiftBequestListDTO> = DataTableColumnUtil.Text('Description', 'description');
    columns.push(columnDescription);

    if (tableName == 'approve') {
      const columnDropdown: DataTableColumnProps<AdminGiftBequestListDTO> = DataTableColumnUtil.DropdownMulti('Status', 'status', [
        { text: 'Submitted', value: GbStatus.SUBMITTED.toString() }
      ]);
      columns.push(columnDropdown);

      const columnApproveButtons: DataTableColumnProps<AdminGiftBequestListDTO> = DataTableColumnUtil.Buttons(
        'giftId',
        [
          { text: 'View', onClick: (rowData: AdminGiftBequestListDTO) => this.view(rowData.giftId || '') },
          { text: 'Approve', onClick: (rowData: AdminGiftBequestListDTO) => this.approve(rowData.giftId || '') },
          { text: 'Reject', onClick: (rowData: AdminGiftBequestListDTO) => this.confirmReject(rowData.giftId || '', rowData.donorName || '') }
        ],
        220);
      columns.push(columnApproveButtons);
    }
    else {
      const columnDropdown: DataTableColumnProps<AdminGiftBequestListDTO> = DataTableColumnUtil.DropdownMulti('Status', 'status', [
        { text: 'Approved', value: GbStatus.APPROVED.toString() },
        { text: 'Adjusted', value: GbStatus.ADJUSTED.toString() },
        { text: 'Rejected', value: GbStatus.REJECTED.toString() }
      ]);
      columns.push(columnDropdown);

      const columnManageButtons: DataTableColumnProps<AdminGiftBequestListDTO> = DataTableColumnUtil.Buttons(
        'giftId',
        [
          { text: 'Edit', onClick: (rowData: AdminGiftBequestListDTO) => this.edit(rowData.giftId || '') }
        ],
        70);
      columns.push(columnManageButtons);
    }

    return columns;

  }

  private edit = (giftId: string) => {
    HistoryUtil.push(Routes.generate(Routes.ADMIN_EDIT_GIFT_BEQUEST, { 'giftId': giftId }));
  }

  private view = (giftId: string) => {
    HistoryUtil.push(Routes.generate(Routes.ADMIN_EDIT_GIFT_BEQUEST, { 'giftId': giftId }));
  }

  private approve = (drsfaId: string) => {
    AdminGiftBequestApiService.approve(drsfaId)
      .then(() => {
        if (this.dataApproveTable) {
          this.dataApproveTable.refresh();
        }
        if (this.dataManageTable) {
          this.dataManageTable.refresh();
        }
        notification.success({
          message: 'The Gift/Bequest was approved successfully'
        });
      }).catch(() => {
        notification.error({
          message: 'Failed to approve Gift/Bequest'
        });
      });
  }

  private reject = (drsfaId: string) => {
    AdminGiftBequestApiService.reject(drsfaId)
      .then(() => {
        if (this.dataApproveTable) {
          this.dataApproveTable.refresh();
        }
        notification.success({
          message: 'The Gift/Bequest was rejected successfully'
        });
      }).catch(() => {
        notification.error({
          message: 'Failed to reject Gift/Bequest'
        });
      });
  }

  private confirmReject = (giftId: string, donor: string) => {
    confirm({
      title: 'Are you sure you want to reject this Gift/Bequest?',
      content: 'The following record will be rejected: ' + donor,
      onOk: () => {
        this.reject(giftId);
      },
    });
  }
}

export default ManageGiftBequests;