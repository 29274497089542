import {
  Button,
  Checkbox,
  Col,
  ColProps,
  FormInstance,
  notification,
  Modal,
  Row,
  Space,
  Typography,
} from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import TextArea from 'antd/lib/input/TextArea';
import * as React from 'react';
import SchH1CampaignPropertyApiService from '../../api/SchH1CampaignPropertyApiService';
import SchH1CampaignPropertyFormDTO from '../../models/SchH1CampaignPropertyFormDTO';
import FormValidationUtil from '../../utils/FormValidationUtil';
import CurrentUser from '../../utils/CurrentUser';
import HistoryUtil from '../../utils/HistoryUtil';
import Routes from '../../consts/Routes';
import { RouteComponentProps } from 'react-router';
import { Content } from 'antd/lib/layout/layout';
import moment from 'moment';
import SchH1CampaignPropertyHistoryTable from './SchH1CampaignPropertyHistoryTable';
import NotesModal from '../shared/NotesModal';
import NoteEntityParents from '../../consts/NoteEntityParents';
import CurrencyInput from '../shared/CurrencyInput';
import CustomDatePicker from '../shared/CustomDatePicker';
import CustomForm from '../shared/CustomForm';

const confirm = Modal.confirm;

interface SchH1CampaignPropertyFormProps {
  id?: string;
  seqNum?: string;
}

interface SchH1CampaignPropertyFormState {
  btnDisable: boolean;
  campaignPropertyInfo: SchH1CampaignPropertyFormDTO | undefined;
  campaignPropertyId?: string;
  loading: boolean;
  seqNum: number;
}

class SchH1CampaignPropertyForm extends React.Component<RouteComponentProps<SchH1CampaignPropertyFormProps>, SchH1CampaignPropertyFormState> {
  private readonly _formRef = React.createRef<FormInstance>();

  constructor(props: RouteComponentProps<SchH1CampaignPropertyFormProps>) {
    super(props);
    this.state = {
      btnDisable: false,
      campaignPropertyId: this.props.match.params.id,
      campaignPropertyInfo: SchH1CampaignPropertyFormDTO.create({
        currentValueAmount: null,
        pricePaidAmount: null
      }),
      loading: !!this.props.match.params.id,
      seqNum: Number(this.props.match.params.seqNum) || 0,
    };
  }

 componentDidMount() {
    if (this.props.match.params.id) {
      this.getCampaignProperty(this.props.match.params.id || '', this.props.match.params.seqNum || '');
    }
  }

  render() {
    const { btnDisable, campaignPropertyId, campaignPropertyInfo, loading, seqNum } = this.state;
    const columnSizingProps: ColProps = { xs: 24, sm: 24, md: 12 };

    return (
      <>
        {!loading &&
          <Content className="content-pad">
            <Typography.Title level={4}>Schedule H1 Campaign Property</Typography.Title>
            <CustomForm formRef={this._formRef} initialValues={campaignPropertyInfo} layout="vertical" onFinish={this.handleSave}>
              <Row gutter={24}>
                <Col {...columnSizingProps}>
                  <FormItem name="acquiredDate" label="Date"
                  rules={[FormValidationUtil.RequiredDate('Date is required')]}>
                  <CustomDatePicker onChange={(d) => d?.startOf('day')}/>
                  </FormItem>
                  <FormItem name="currentValueAmount" label="Current Value Amount"
                    rules={[
                      FormValidationUtil.RequiredNumber('Current Value Amount is required'),
                      FormValidationUtil.NotNegativeNumber('Current Value Amount must be nonnegative')
                    ]}>
                    <CurrencyInput />
                  </FormItem>
                  <FormItem name="pricePaidAmount" label="Price Paid Amount"
                    rules={[
                      FormValidationUtil.RequiredNumber('Price Paid Amount is required'),
                      FormValidationUtil.NotNegativeNumber('Price Paid Amount must be nonnegative')
                    ]}>
                    <CurrencyInput />
                  </FormItem>
                  <Row>
                    <Col>
                      <Space>
                        <FormItem name="isValueEstimated" valuePropName="checked">
                          <Checkbox>Value Estimated</Checkbox>
                        </FormItem>
                      </Space>
                    </Col>
                  </Row>
                  <FormItem name="property" label="Property"
                    rules={[FormValidationUtil.Required('Property is required',)]}>
                    <TextArea maxLength={2000} />
                  </FormItem>
                </Col>
              </Row>
              <Space>
                <Button type="primary" disabled={btnDisable} htmlType="submit">Save Payment</Button>
                <Button type="default" disabled={btnDisable} onClick={this.handleCancel}>Cancel</Button>
                {campaignPropertyId &&
                  <NotesModal
                    parentId={campaignPropertyId}
                    parent={NoteEntityParents.CAMPAIGNPROPERTY}
                    seqNum={seqNum || 0}
                    auditorsNoteId={campaignPropertyInfo?.auditorsNoteId || ''}
                  />
                }
              </Space>
            </CustomForm >
            {campaignPropertyId &&
              <SchH1CampaignPropertyHistoryTable
                campaignPropertyId={campaignPropertyId || ''}
              />
            }
          </Content >
        }
      </>
    );
  }

  private getCampaignProperty = (campaignPropertyId: string, seqNum: string) => {
    SchH1CampaignPropertyApiService.getCampaignProperty(campaignPropertyId, Number(seqNum))
      .then(campaignProperty => {
        if (campaignProperty) {
          campaignProperty.acquiredDate = moment(campaignProperty.acquiredDate);
          this.setState({
            campaignPropertyInfo: campaignProperty
          });
          this._formRef.current?.setFieldsValue(this.state.campaignPropertyInfo);
          this.setState({ loading: false });
        }
        else {
          notification.error({
            message: 'Error while fetching campaign property',
            description: 'Not found'
          });
          this.setState({ loading: false });
        }
      }).catch(() => {
        notification.error({
          message: 'Error while fetching campaign property'
        });
        this.setState({ loading: false });
      });
  }

  private handleSave = (values: SchH1CampaignPropertyFormDTO) => {
    this.setState({ btnDisable: true });
    if (!this.state.campaignPropertyId) {
      SchH1CampaignPropertyApiService.add(values, CurrentUser.Get()?.committeeId || '', CurrentUser.Get()?.dr2Id || '')
        .then(() => {
          notification.success({
            message: 'Saved Successfully',
            description: ''
          });
          HistoryUtil.push(Routes.SCHEDULE_H1_CAMPAIGN_PROPERTIES);
        }).catch(() => {
          notification.error({
            message: 'Your campaign property failed to save',
            description: ''
          });
          HistoryUtil.push(Routes.SCHEDULE_H1_CAMPAIGN_PROPERTIES);
        });
    }
    else {
      SchH1CampaignPropertyApiService.edit(
        values,
        CurrentUser.Get()?.committeeId || '',
        CurrentUser.Get()?.dr2Id || '',
        this.props.match.params.id || '',
        Number(this.props.match.params.seqNum)
      )
        .then(() => {
          notification.success({
            message: 'Saved Successfully',
            description: ''
          });
          HistoryUtil.push(Routes.SCHEDULE_H1_CAMPAIGN_PROPERTIES);
        }).catch(() => {
          notification.error({
            message: 'Your campaign property failed to save',
            description: ''
          });
          HistoryUtil.push(Routes.SCHEDULE_H1_CAMPAIGN_PROPERTIES);
        });
    }
  }

  private handleCancel = () => {
    confirm({
      title: 'Are you sure you want to leave?',
      okText: 'Yes',
      cancelText: 'No',
      onOk: () => {
        HistoryUtil.push(Routes.SCHEDULE_H1_CAMPAIGN_PROPERTIES);
      }
    });
  }
}

export default SchH1CampaignPropertyForm;
