// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
import BaseApi from './BaseApi';
import CommitteeFilingDTO from '../models/CommitteeFilingDTO';
import Dr2PenaltyDTO from '../models/Dr2PenaltyDTO';
import TableRequestDTO from '../models/TableRequestDTO';
import DueDateDTO from '../models/DueDateDTO';
import PeriodDueDateDTO from '../models/PeriodDueDateDTO';
import NoteEntryDTO from '../models/NoteEntryDTO';
import Dr2SummaryDTO from '../models/Dr2SummaryDTO';
import Dr2StatusBarDTO from '../models/Dr2StatusBarDTO';
import ResultDTO from '../models/ResultDTO';
import FilingPeriodDTO from '../models/FilingPeriodDTO';
import TableResponseDTO from '../models/TableResponseDTO';
import AdjustEntirePeriodDTO from '../models/AdjustEntirePeriodDTO';

export class Dr2ApiService extends BaseApi {

  // get: api/dr2/summary?dr2Id=${encodeURIComponent(dr2Id)}
  public getDr2Summary(dr2Id: string): Promise<Dr2SummaryDTO> {
    let url = `api/dr2/summary`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (dr2Id != null) {
      url += `${prefix}dr2Id=${encodeURIComponent(dr2Id)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, Dr2SummaryDTO>(null, 'get', url, true, false);
  }

  // get: api/dr2/statusbar?dr2Id=${encodeURIComponent(dr2Id)}
  public getStatusBar(dr2Id: string): Promise<Dr2StatusBarDTO> {
    let url = `api/dr2/statusbar`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (dr2Id != null) {
      url += `${prefix}dr2Id=${encodeURIComponent(dr2Id)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, Dr2StatusBarDTO>(null, 'get', url, true, false);
  }

  // get: api/dr2/unfiled?dr2Id=${encodeURIComponent(dr2Id)}&isAdmin=${isAdmin}
  public checkUnfiledReport(dr2Id: string, isAdmin: boolean): Promise<boolean> {
    let url = `api/dr2/unfiled`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (dr2Id != null) {
      url += `${prefix}dr2Id=${encodeURIComponent(dr2Id)}`;
      prefix = '&';
    }
    if (isAdmin != null) {
      url += `${prefix}isAdmin=${isAdmin}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, boolean>(null, 'get', url, true, false);
  }

  // get: api/dr2/committeeFiling?dr2Id=${encodeURIComponent(dr2Id)}
  public getDr2CommFiling(dr2Id: string): Promise<CommitteeFilingDTO> {
    let url = `api/dr2/committeeFiling`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (dr2Id != null) {
      url += `${prefix}dr2Id=${encodeURIComponent(dr2Id)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, CommitteeFilingDTO>(null, 'get', url, true, false);
  }

  // post: api/dr2/committeeFiling/save?dr2Id=${encodeURIComponent(dr2Id)}
  public saveDr2CommFiling(model: CommitteeFilingDTO, dr2Id: string): Promise<ResultDTO> {
    let url = `api/dr2/committeeFiling/save`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (dr2Id != null) {
      url += `${prefix}dr2Id=${encodeURIComponent(dr2Id)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, ResultDTO>(model, 'post', url, true, false);
  }

  // post: api/dr2/committeeFiling/audit?dr2Id=${encodeURIComponent(dr2Id)}
  public auditDr2(dr2Id: string): Promise<ResultDTO> {
    let url = `api/dr2/committeeFiling/audit`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (dr2Id != null) {
      url += `${prefix}dr2Id=${encodeURIComponent(dr2Id)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, ResultDTO>(null, 'post', url, true, false);
  }

  // get: api/dr2/penalty?committeeId=${encodeURIComponent(committeeId)}&id=${encodeURIComponent(id)}&isDr2=${isDr2}
  public getDr2Penalty(committeeId: string, id: string, isDr2: boolean): Promise<Dr2PenaltyDTO> {
    let url = `api/dr2/penalty`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (committeeId != null) {
      url += `${prefix}committeeId=${encodeURIComponent(committeeId)}`;
      prefix = '&';
    }
    if (id != null) {
      url += `${prefix}id=${encodeURIComponent(id)}`;
      prefix = '&';
    }
    if (isDr2 != null) {
      url += `${prefix}isDr2=${isDr2}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, Dr2PenaltyDTO>(null, 'get', url, true, false);
  }

  // post: api/dr2/${encodeURIComponent(committeeId)}/penalty?id=${encodeURIComponent(id)}&isDr2=${isDr2}&sendNotice=${sendNotice}
  public saveDr2Penalty(committeeId: string, id: string, isDr2: boolean, penaltyData: Dr2PenaltyDTO, sendNotice: boolean): Promise<ResultDTO> {
    let url = `api/dr2/${encodeURIComponent(committeeId)}/penalty`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (id != null) {
      url += `${prefix}id=${encodeURIComponent(id)}`;
      prefix = '&';
    }
    if (isDr2 != null) {
      url += `${prefix}isDr2=${isDr2}`;
      prefix = '&';
    }
    if (sendNotice != null) {
      url += `${prefix}sendNotice=${sendNotice}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, ResultDTO>(penaltyData, 'post', url, true, false);
  }

  // post: api/dr2/nextFilingPeriods?committeeId=${encodeURIComponent(committeeId)}&canSelectFiling=${canSelectFiling}&adminShowAll=${adminShowAll}&dr2Id=${encodeURIComponent(dr2Id)}
  public getNextUpcomingFilingPeriods(committeeId: string, canSelectFiling: boolean, adminShowAll: boolean, dr2Id?: string | null | null): Promise<FilingPeriodDTO[]> {
    let url = `api/dr2/nextFilingPeriods`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (committeeId != null) {
      url += `${prefix}committeeId=${encodeURIComponent(committeeId)}`;
      prefix = '&';
    }
    if (canSelectFiling != null) {
      url += `${prefix}canSelectFiling=${canSelectFiling}`;
      prefix = '&';
    }
    if (adminShowAll != null) {
      url += `${prefix}adminShowAll=${adminShowAll}`;
      prefix = '&';
    }
    if (dr2Id != null) {
      url += `${prefix}dr2Id=${encodeURIComponent(dr2Id || '')}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, FilingPeriodDTO[]>(null, 'post', url, true, false);
  }

  // post: api/dr2/newFilingPeriod?periodMasterId=${periodMasterId}&committeeId=${encodeURIComponent(committeeId)}&prevDr2Id=${encodeURIComponent(prevDr2Id)}&isIncumbent=${isIncumbent}
  public saveNewFilingPeriod(periodMasterId: number, committeeId: string, prevDr2Id?: string | null | null, isIncumbent?: boolean | null | null): Promise<ResultDTO> {
    let url = `api/dr2/newFilingPeriod`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (periodMasterId != null) {
      url += `${prefix}periodMasterId=${periodMasterId}`;
      prefix = '&';
    }
    if (committeeId != null) {
      url += `${prefix}committeeId=${encodeURIComponent(committeeId)}`;
      prefix = '&';
    }
    if (prevDr2Id != null) {
      url += `${prefix}prevDr2Id=${encodeURIComponent(prevDr2Id || '')}`;
      prefix = '&';
    }
    if (isIncumbent != null) {
      url += `${prefix}isIncumbent=${isIncumbent}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, ResultDTO>(null, 'post', url, true, false);
  }

  // get: api/dr2/dr2?committeeId=${encodeURIComponent(committeeId)}
  public getLastFiledDr2Id(committeeId: string): Promise<string | null> {
    let url = `api/dr2/dr2`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (committeeId != null) {
      url += `${prefix}committeeId=${encodeURIComponent(committeeId)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, string | null>(null, 'get', url, true, false);
  }

  // post: api/dr2/adjustFilingTable?committeeId=${encodeURIComponent(committeeId)}&dr2Id=${encodeURIComponent(dr2Id)}
  public getAdjustFilingTable(tableRequest: TableRequestDTO, committeeId: string, dr2Id: string): Promise<TableResponseDTO<AdjustEntirePeriodDTO>> {
    let url = `api/dr2/adjustFilingTable`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (committeeId != null) {
      url += `${prefix}committeeId=${encodeURIComponent(committeeId)}`;
      prefix = '&';
    }
    if (dr2Id != null) {
      url += `${prefix}dr2Id=${encodeURIComponent(dr2Id)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, TableResponseDTO<AdjustEntirePeriodDTO>>(tableRequest, 'post', url, true, false);
  }

  // get: api/dr2/getDueDates?committeeId=${encodeURIComponent(committeeId)}&periodMasterId=${periodMasterId}
  public getDueDates(committeeId: string, periodMasterId: number): Promise<DueDateDTO> {
    let url = `api/dr2/getDueDates`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (committeeId != null) {
      url += `${prefix}committeeId=${encodeURIComponent(committeeId)}`;
      prefix = '&';
    }
    if (periodMasterId != null) {
      url += `${prefix}periodMasterId=${periodMasterId}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, DueDateDTO>(null, 'get', url, true, false);
  }

  // get: api/dr2/getDueDatesByDr2?dr2Id=${encodeURIComponent(dr2Id)}
  public getDueDatesByDr2(dr2Id: string): Promise<PeriodDueDateDTO> {
    let url = `api/dr2/getDueDatesByDr2`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (dr2Id != null) {
      url += `${prefix}dr2Id=${encodeURIComponent(dr2Id)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, PeriodDueDateDTO>(null, 'get', url, true, false);
  }

  // post: api/dr2/adjustEntirePeriodSelect?committeeId=${encodeURIComponent(committeeId)}&dr2Id=${encodeURIComponent(dr2Id)}&periodMasterId=${periodMasterId}
  public saveAdjustEntirePeriod(dueDateDTO: DueDateDTO, committeeId: string, dr2Id: string, periodMasterId: number): Promise<ResultDTO> {
    let url = `api/dr2/adjustEntirePeriodSelect`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (committeeId != null) {
      url += `${prefix}committeeId=${encodeURIComponent(committeeId)}`;
      prefix = '&';
    }
    if (dr2Id != null) {
      url += `${prefix}dr2Id=${encodeURIComponent(dr2Id)}`;
      prefix = '&';
    }
    if (periodMasterId != null) {
      url += `${prefix}periodMasterId=${periodMasterId}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, ResultDTO>(dueDateDTO, 'post', url, true, false);
  }

  // post: api/dr2/adjustPeriodDates?committeeId=${encodeURIComponent(committeeId)}&dr2Id=${encodeURIComponent(dr2Id)}
  public saveAdjustPeriodDates(dueDateDTO: PeriodDueDateDTO, committeeId: string, dr2Id: string): Promise<ResultDTO> {
    let url = `api/dr2/adjustPeriodDates`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (committeeId != null) {
      url += `${prefix}committeeId=${encodeURIComponent(committeeId)}`;
      prefix = '&';
    }
    if (dr2Id != null) {
      url += `${prefix}dr2Id=${encodeURIComponent(dr2Id)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, ResultDTO>(dueDateDTO, 'post', url, true, false);
  }

  // get: api/dr2/beginningBalance?dr2Id=${encodeURIComponent(dr2Id)}
  public getBeginningBalance(dr2Id: string): Promise<number> {
    let url = `api/dr2/beginningBalance`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (dr2Id != null) {
      url += `${prefix}dr2Id=${encodeURIComponent(dr2Id)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, number>(null, 'get', url, true, false);
  }

  // post: api/dr2/adjustBalance?newBalance=${newBalance}&dr2Id=${encodeURIComponent(dr2Id)}
  public saveAdjustBalance(newBalance: number, dr2Id: string): Promise<ResultDTO> {
    let url = `api/dr2/adjustBalance`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (newBalance != null) {
      url += `${prefix}newBalance=${newBalance}`;
      prefix = '&';
    }
    if (dr2Id != null) {
      url += `${prefix}dr2Id=${encodeURIComponent(dr2Id)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, ResultDTO>(null, 'post', url, true, false);
  }

  // get: api/dr2/cascade?dr2Id=${encodeURIComponent(dr2Id)}
  public performCascade(dr2Id: string): Promise<ResultDTO> {
    let url = `api/dr2/cascade`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (dr2Id != null) {
      url += `${prefix}dr2Id=${encodeURIComponent(dr2Id)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, ResultDTO>(null, 'get', url, true, false);
  }

  // get: api/dr2/printSummary?dr2Id=${encodeURIComponent(dr2Id)}&commName=${encodeURIComponent(commName)}&dueDate=${encodeURIComponent(dueDate)}
  public printSummaryReport(dr2Id: string, commName: string, dueDate: string): Promise<void> {
    let url = `api/dr2/printSummary`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (dr2Id != null) {
      url += `${prefix}dr2Id=${encodeURIComponent(dr2Id)}`;
      prefix = '&';
    }
    if (commName != null) {
      url += `${prefix}commName=${encodeURIComponent(commName)}`;
      prefix = '&';
    }
    if (dueDate != null) {
      url += `${prefix}dueDate=${encodeURIComponent(dueDate)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, void>(null, 'get', url, true, true);
  }

  // get: api/dr2/printComplete?dr2Id=${encodeURIComponent(dr2Id)}&commName=${encodeURIComponent(commName)}&dueDate=${encodeURIComponent(dueDate)}
  public printCompleteReport(dr2Id: string, commName: string, dueDate: string): Promise<void> {
    let url = `api/dr2/printComplete`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (dr2Id != null) {
      url += `${prefix}dr2Id=${encodeURIComponent(dr2Id)}`;
      prefix = '&';
    }
    if (commName != null) {
      url += `${prefix}commName=${encodeURIComponent(commName)}`;
      prefix = '&';
    }
    if (dueDate != null) {
      url += `${prefix}dueDate=${encodeURIComponent(dueDate)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, void>(null, 'get', url, true, true);
  }

  // post: api/dr2/save?id=${encodeURIComponent(id)}&parent=${encodeURIComponent(parent)}&seqNum=${seqNum}&flaggedNoteId=${encodeURIComponent(flaggedNoteId)}&scheduleCd=${encodeURIComponent(scheduleCd)}
  public saveFlaggedNotes(noteEntry: NoteEntryDTO, id: string, parent: string, seqNum: number | null, flaggedNoteId: string | null, scheduleCd: string): Promise<NoteEntryDTO> {
    let url = `api/dr2/save`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (id != null) {
      url += `${prefix}id=${encodeURIComponent(id)}`;
      prefix = '&';
    }
    if (parent != null) {
      url += `${prefix}parent=${encodeURIComponent(parent)}`;
      prefix = '&';
    }
    if (seqNum != null) {
      url += `${prefix}seqNum=${seqNum}`;
      prefix = '&';
    }
    if (flaggedNoteId != null) {
      url += `${prefix}flaggedNoteId=${encodeURIComponent(flaggedNoteId)}`;
      prefix = '&';
    }
    if (scheduleCd != null) {
      url += `${prefix}scheduleCd=${encodeURIComponent(scheduleCd)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, NoteEntryDTO>(noteEntry, 'post', url, true, false);
  }

  // post: api/dr2/deleteNote?id=${encodeURIComponent(id)}&parent=${encodeURIComponent(parent)}&seqNum=${seqNum}&flaggedNoteId=${encodeURIComponent(flaggedNoteId)}&scheduleCd=${encodeURIComponent(scheduleCd)}
  public deleteFlaggedNote(noteEntry: NoteEntryDTO, id: string, parent: string, seqNum: number | null, flaggedNoteId: string | null, scheduleCd: string): Promise<ResultDTO> {
    let url = `api/dr2/deleteNote`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (id != null) {
      url += `${prefix}id=${encodeURIComponent(id)}`;
      prefix = '&';
    }
    if (parent != null) {
      url += `${prefix}parent=${encodeURIComponent(parent)}`;
      prefix = '&';
    }
    if (seqNum != null) {
      url += `${prefix}seqNum=${seqNum}`;
      prefix = '&';
    }
    if (flaggedNoteId != null) {
      url += `${prefix}flaggedNoteId=${encodeURIComponent(flaggedNoteId)}`;
      prefix = '&';
    }
    if (scheduleCd != null) {
      url += `${prefix}scheduleCd=${encodeURIComponent(scheduleCd)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, ResultDTO>(noteEntry, 'post', url, true, false);
  }

  // get: api/dr2/checkMultipleUnfiled?committeeId=${encodeURIComponent(committeeId)}
  public checkMultipleUnfiled(committeeId: string): Promise<boolean> {
    let url = `api/dr2/checkMultipleUnfiled`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (committeeId != null) {
      url += `${prefix}committeeId=${encodeURIComponent(committeeId)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, boolean>(null, 'get', url, true, false);
  }

  // get: api/dr2/checkAmendingFiling?dr2Id=${encodeURIComponent(dr2Id)}
  public checkAmendingFiling(dr2Id: string): Promise<boolean> {
    let url = `api/dr2/checkAmendingFiling`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (dr2Id != null) {
      url += `${prefix}dr2Id=${encodeURIComponent(dr2Id)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, boolean>(null, 'get', url, true, false);
  }

  // get: api/dr2/checkSwgaElection?committeeId=${encodeURIComponent(committeeId)}
  public checkSWGAElection(committeeId: string): Promise<boolean> {
    let url = `api/dr2/checkSwgaElection`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (committeeId != null) {
      url += `${prefix}committeeId=${encodeURIComponent(committeeId)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, boolean>(null, 'get', url, true, false);
  }

  // get: api/dr2/getElectionYear?committeeId=${encodeURIComponent(committeeId)}
  public getElectionYear(committeeId: string): Promise<number> {
    let url = `api/dr2/getElectionYear`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (committeeId != null) {
      url += `${prefix}committeeId=${encodeURIComponent(committeeId)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, number>(null, 'get', url, true, false);
  }

  // get: api/dr2/checkCommitteeDissolved?committeeId=${encodeURIComponent(committeeId)}
  public checkCommitteeDissolve(committeeId: string): Promise<boolean> {
    let url = `api/dr2/checkCommitteeDissolved`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (committeeId != null) {
      url += `${prefix}committeeId=${encodeURIComponent(committeeId)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, boolean>(null, 'get', url, true, false);
  }

  // get: api/dr2/getReportType?dr2Id=${encodeURIComponent(dr2Id)}
  public getReportType(dr2Id: string): Promise<string> {
    let url = `api/dr2/getReportType`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (dr2Id != null) {
      url += `${prefix}dr2Id=${encodeURIComponent(dr2Id)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, string>(null, 'get', url, true, false);
  }

  // get: api/dr2/checkSupplemental?dr2Id=${encodeURIComponent(dr2Id)}&contributions=${contributions}&commTypeName=${encodeURIComponent(commTypeName)}
  public checkSupplemental(dr2Id: string, contributions: number, commTypeName: string): Promise<boolean> {
    let url = `api/dr2/checkSupplemental`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (dr2Id != null) {
      url += `${prefix}dr2Id=${encodeURIComponent(dr2Id)}`;
      prefix = '&';
    }
    if (contributions != null) {
      url += `${prefix}contributions=${contributions}`;
      prefix = '&';
    }
    if (commTypeName != null) {
      url += `${prefix}commTypeName=${encodeURIComponent(commTypeName)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, boolean>(null, 'get', url, true, false);
  }

  // get: api/dr2/getReportForSuppFail?committeeId=${encodeURIComponent(committeeId)}&dr2Id=${encodeURIComponent(dr2Id)}
  public getReportForSuppFail(committeeId: string, dr2Id: string): Promise<DueDateDTO> {
    let url = `api/dr2/getReportForSuppFail`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (committeeId != null) {
      url += `${prefix}committeeId=${encodeURIComponent(committeeId)}`;
      prefix = '&';
    }
    if (dr2Id != null) {
      url += `${prefix}dr2Id=${encodeURIComponent(dr2Id)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, DueDateDTO>(null, 'get', url, true, false);
  }

  // get: api/dr2/getSuppPeriodDescription?committeeId=${encodeURIComponent(committeeId)}&dr2Id=${encodeURIComponent(dr2Id)}
  public getSuppPeriodDescription(committeeId: string, dr2Id: string): Promise<string> {
    let url = `api/dr2/getSuppPeriodDescription`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (committeeId != null) {
      url += `${prefix}committeeId=${encodeURIComponent(committeeId)}`;
      prefix = '&';
    }
    if (dr2Id != null) {
      url += `${prefix}dr2Id=${encodeURIComponent(dr2Id)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, string>(null, 'get', url, true, false);
  }

  // post: api/dr2/penaltyNotice?uid=${uid}
  public sendCommitteeFilingLatePenaltyNotice(uid: number): Promise<ResultDTO> {
    let url = `api/dr2/penaltyNotice`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (uid != null) {
      url += `${prefix}uid=${uid}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, ResultDTO>(null, 'post', url, true, false);
  }

  // get: api/dr2/checkForUnpaidPenalties?committeeId=${encodeURIComponent(committeeId)}&committeeType=${encodeURIComponent(committeeType)}
  public checkForUnpaidPenalties(committeeId: string, committeeType: string): Promise<boolean> {
    let url = `api/dr2/checkForUnpaidPenalties`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (committeeId != null) {
      url += `${prefix}committeeId=${encodeURIComponent(committeeId)}`;
      prefix = '&';
    }
    if (committeeType != null) {
      url += `${prefix}committeeType=${encodeURIComponent(committeeType)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, boolean>(null, 'get', url, true, false);
  }
}
var service = new Dr2ApiService();
export default service;
