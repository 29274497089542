import { CheckCircleFilled, LoadingOutlined } from '@ant-design/icons';
import {
  Alert,
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  Layout,
  Row,
  Typography
  } from 'antd';
import { FormInstance } from 'antd/lib/form';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import AccountApiService from '../../api/AccountApiService';
import LookupsApiService from '../../api/LookupsApiService';
import PageTitle from '../../components/shared/PageTitle';
import Routes from '../../consts/Routes';
import LookupTableDTO from '../../models/LookupTableDTO';
import RegisterDTO from '../../models/RegisterDTO';
import RegisterResultDTO from '../../models/RegisterResultDTO';
import ResultDTO from '../../models/ResultDTO';
import { StateStoreModel } from '../../redux/state/StateStoreModel';
import HistoryUtil from '../../utils/HistoryUtil';
import CustomForm from '../shared/CustomForm';

const FormItem = Form.Item;
const Content = Layout.Content;

interface RegisterState {
  model: RegisterDTO;
  roles: LookupTableDTO[];
  accountSubmitted: boolean;
  error: any;
  loading: boolean;
}

interface RegisterProps {
  userName: string | null;
  loading: boolean;
  registerAction: (dto: RegisterDTO) => void;
}

class RegisterPage extends React.Component<RegisterProps & RouteComponentProps<RouteObject>, RegisterState> {
  private readonly _formRef = React.createRef<FormInstance>();

  constructor(props: RegisterProps & RouteComponentProps<RouteObject>) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      model: RegisterDTO.create(),
      roles: [],
      accountSubmitted: false,
      error: null,
      loading: false,
    };
  }

  componentDidMount() {
    const rolePromise = LookupsApiService.getRoles().then((result) => result);

    const updateRoleSelect = async () => {
      let roleList = await rolePromise;
      if (roleList !== null && roleList !== undefined) {
        roleList = roleList.filter(
          (r) => r !== null && r !== undefined && r.name !== null && r.name.indexOf('IECDB') < 0
        );
      }

      this.setState({ roles: roleList });
    };

    updateRoleSelect();
  }

  handleSubmit() {
    this.setState({ loading: true });

    const model = this._formRef ? (this._formRef.current as any).getFieldsValue() : null;

    AccountApiService.register(model)
      .then((registerResponse: RegisterResultDTO | null) => {
        if (registerResponse?.succeeded) {
          this.setState({ accountSubmitted: true, loading: false });
        } else {
          this.setState({
            error: { message: registerResponse?.errors.join('\n') },
            loading: false,
          });
        }
      })
      .catch((results: any) => {
        this.setState({
          error: results,
          loading: false,
        });
      });
  }

  validatePassword = (e: any) => {
    if (e.target.value !== null && e.target.value !== '') {
      AccountApiService.validatePassword(e.target.value).then((response: ResultDTO | null) => {
        if (response?.succeeded) {
          this.setState({ error: null });
        } else {
          this.setState({ error: { message: response?.errors.join('\n') } });
        }
      });
    }
  };

  render() {
    let alert: JSX.Element | null = null;
    const { error } = this.state;
    if (error !== null) {
      let message = 'An error occurred while trying to register new user.';
      if (error) {
        message = error.message;
      }
      alert = (
        <Alert
          message="Error"
          description={message}
          type="error"
          showIcon={true}
          style={{ marginBottom: '12px', whiteSpace: 'pre-wrap' }}
        />
      );
    }

    const emptyCol = { xs: 0, sm: 3, md: 3 };
    const wrapperCol = { xs: 24, sm: 18, md: 18 };
    const wrapperColNoLabel = {};
    Object.keys(wrapperCol).forEach((val) => {
      wrapperColNoLabel[val] = {
        span: wrapperCol[val],
        offset: wrapperCol[val] + emptyCol[val] > 24 ? 0 : emptyCol[val],
      };
    });

    return (
      // type="flex"
      <Content className="content-background-register">
        <Row justify="center" align="middle">
          <Col className="flex-grow" />
          <Col xxl={6} xl={8} lg={10} md={14} sm={18} xs={24}>
            {!this.state.accountSubmitted ? (
              <Card>
                <img src={process.env.PUBLIC_URL + '/iecdb-logo.svg'} alt="IECDB.Portal" className="full-logo" />
                <PageTitle title="Create a IECDB.Portal Account" style={{ textAlign: 'center', marginBottom: '24px' }} />
                {alert}
                <CustomForm formRef={this._formRef} layout="vertical" onFinish={this.handleSubmit} requiredMark={false}>
                  <FormItem
                    name="username"
                    label="Username"
                    rules={[
                      {
                        required: true,
                        message: 'Username is required',
                      },
                    ]}
                    labelCol={wrapperColNoLabel}
                    wrapperCol={wrapperColNoLabel}
                  >
                    <Input />
                  </FormItem>
                  <FormItem
                    name="email"
                    label="Email Address"
                    rules={[
                      {
                        required: true,
                        message: 'Email Address is required',
                      },
                    ]}
                    labelCol={wrapperColNoLabel}
                    wrapperCol={wrapperColNoLabel}
                  >
                    <Input />
                  </FormItem>
                  <FormItem
                    name="password"
                    label="Password"
                    rules={[{ required: true, message: 'Password is required' }]}
                    labelCol={wrapperColNoLabel}
                    wrapperCol={wrapperColNoLabel}
                  >
                    <Input.Password onBlur={this.validatePassword} />
                  </FormItem>
                  <FormItem
                    name="confirmPassword"
                    label="Confirm Password"
                    rules={[
                      {
                        required: true,
                        message: 'Confirm password is required',
                      },
                      ({ getFieldValue }) => ({
                        validator(rule: any, value: string | null) {
                          if (!value || getFieldValue('password') === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject('The two passwords that you entered do not match');
                        },
                      }),
                    ]}
                    labelCol={wrapperColNoLabel}
                    wrapperCol={wrapperColNoLabel}
                  >
                    <Input.Password />
                  </FormItem>
                  <FormItem wrapperCol={wrapperColNoLabel}>
                    <Button
                      htmlType="submit"
                      size="large"
                      disabled={this.state.loading}
                      className="grey-button"
                      shape="round"
                      block
                    >
                      {this.state.loading ? (
                        <span>
                          <LoadingOutlined /> Creating account...
                        </span>
                      ) : (
                        <span>Create Account</span>
                      )}
                    </Button>
                  </FormItem>
                </CustomForm>
                <Divider />
                <span>
                  <Typography.Title level={3} style={{ textAlign: 'center' }}>
                    Already have an account?
                  </Typography.Title>
                  <CustomForm>
                    <FormItem wrapperCol={wrapperColNoLabel}>
                      <Button
                        onClick={this.goToLogin}
                        htmlType="button"
                        size="large"
                        disabled={this.state.loading}
                        shape="round"
                        block
                      >
                        Login
                      </Button>
                    </FormItem>
                  </CustomForm>
                </span>
              </Card>
            ) : (
              <Card
                style={{
                  textAlign: 'center',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <img src={process.env.PUBLIC_URL + '/iecdb-logo.svg'} alt="IECDB.Portal" className="full-logo" />
                <CheckCircleFilled style={{ fontSize: '64px', color: '#52c41a' }} />
                <Typography.Title level={2}>Account submitted for approval</Typography.Title>
                <p>
                  Your account request has been sent for approval. You will be notified via email when your account has
                  been approved and created.
                </p>
                <Button
                  onClick={this.goToLogin}
                  htmlType="button"
                  size="large"
                  disabled={this.state.loading}
                  shape="round"
                >
                  Back to Login
                </Button>
              </Card>
            )}
          </Col>
          <Col className="flex-grow" />
        </Row>
      </Content>
    );
  }

  private goToLogin = () => {
    HistoryUtil.push(Routes.generate(Routes.LOGIN));
  };
}

function mapStateToProps(state: StateStoreModel) {
  let userName: string | null = null;
  if (state.UserSession.Value !== null) {
    userName = state.UserSession.Value.userName;
  }
  return {
    loading: state.UserSession.Loading,
    error: state.UserSession.Error,
    userName: userName,
  };
}

export default withRouter(connect(mapStateToProps)(RegisterPage));
