// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import TableRequestDTO from './TableRequestDTO';

interface TableExportDTO { 
  tableRequest: TableRequestDTO | null;
  year: number;
  convertToCST: boolean;
}
const TableExportDTO = {
  create: (initValues?: Partial<TableExportDTO> | Record<string, unknown> | null) => {
    return Object.assign(
    {
      tableRequest: null,
      year: 0,
      convertToCST: false,
    },
    initValues);
  }
};

export default TableExportDTO;