// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
import BaseApi from './BaseApi';
import LoginDTO from '../models/LoginDTO';
import RegisterDTO from '../models/RegisterDTO';
import RegisterMigrationDTO from '../models/RegisterMigrationDTO';
import ResetPasswordDTO from '../models/ResetPasswordDTO';
import ChangePasswordDTO from '../models/ChangePasswordDTO';
import LoginResultDTO from '../models/LoginResultDTO';
import RegisterResultDTO from '../models/RegisterResultDTO';
import ResultDTO from '../models/ResultDTO';
import UserSettingsDataDTO from '../models/UserSettingsDataDTO';
import UserSecurityDTO from '../models/UserSecurityDTO';

export class AccountApiService extends BaseApi {

  // post: api/Account/Login
  public login(model: LoginDTO): Promise<LoginResultDTO> {
    let url = `api/Account/Login`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, LoginResultDTO>(model, 'post', url, true, false);
  }

  // get: api/Account/ExternalLogin?provider=${encodeURIComponent(provider)}&login=${encodeURIComponent(login)}&returnUrl=${encodeURIComponent(returnUrl)}
  public externalLogin(provider: string, login?: string | null, returnUrl?: string | null): Promise<void> {
    let url = `api/Account/ExternalLogin`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (provider != null) {
      url += `${prefix}provider=${encodeURIComponent(provider)}`;
      prefix = '&';
    }
    if (login != null) {
      url += `${prefix}login=${encodeURIComponent(login || '')}`;
      prefix = '&';
    }
    if (returnUrl != null) {
      url += `${prefix}returnUrl=${encodeURIComponent(returnUrl || '')}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, void>(null, 'get', url, true, false);
  }

  // get: api/Account/ExternalLoginCallback?returnUrl=${encodeURIComponent(returnUrl)}&remoteError=${encodeURIComponent(remoteError)}
  public externalLoginCallback(returnUrl?: string | null, remoteError?: string | null): Promise<void> {
    let url = `api/Account/ExternalLoginCallback`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (returnUrl != null) {
      url += `${prefix}returnUrl=${encodeURIComponent(returnUrl || '')}`;
      prefix = '&';
    }
    if (remoteError != null) {
      url += `${prefix}remoteError=${encodeURIComponent(remoteError || '')}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, void>(null, 'get', url, true, false);
  }

  // post: api/Account/Register
  public register(model: RegisterDTO): Promise<RegisterResultDTO> {
    let url = `api/Account/Register`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, RegisterResultDTO>(model, 'post', url, true, false);
  }

  // post: api/Account/RegisterMigration
  public registerMigration(model: RegisterMigrationDTO): Promise<ResultDTO> {
    let url = `api/Account/RegisterMigration`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, ResultDTO>(model, 'post', url, true, false);
  }

  // get: api/Account/ConfirmEmail?userId=${userId}&code=${encodeURIComponent(code)}
  public confirmEmail(userId: number, code: string): Promise<ResultDTO> {
    let url = `api/Account/ConfirmEmail`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (userId != null) {
      url += `${prefix}userId=${userId}`;
      prefix = '&';
    }
    if (code != null) {
      url += `${prefix}code=${encodeURIComponent(code)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, ResultDTO>(null, 'get', url, true, false);
  }

  // post: api/Account/AdminResendConfirmEmail?userId=${userId}
  public adminResendConfirmEmail(userId: number): Promise<void> {
    let url = `api/Account/AdminResendConfirmEmail`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (userId != null) {
      url += `${prefix}userId=${userId}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, void>(null, 'post', url, true, false);
  }

  // post: api/Account/ResendConfirmEmail?email=${encodeURIComponent(email)}
  public resendConfirmEmail(email: string): Promise<void> {
    let url = `api/Account/ResendConfirmEmail`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (email != null) {
      url += `${prefix}email=${encodeURIComponent(email)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, void>(null, 'post', url, true, false);
  }

  // post: api/Account/ForgotPassword?username=${encodeURIComponent(username)}&email=${encodeURIComponent(email)}
  public forgotPassword(username: string, email: string): Promise<void> {
    let url = `api/Account/ForgotPassword`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (username != null) {
      url += `${prefix}username=${encodeURIComponent(username)}`;
      prefix = '&';
    }
    if (email != null) {
      url += `${prefix}email=${encodeURIComponent(email)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, void>(null, 'post', url, true, false);
  }

  // post: api/Account/CanResetPassword?userId=${userId}&code=${encodeURIComponent(code)}
  public canResetPassword(userId: number, code: string): Promise<ResultDTO> {
    let url = `api/Account/CanResetPassword`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (userId != null) {
      url += `${prefix}userId=${userId}`;
      prefix = '&';
    }
    if (code != null) {
      url += `${prefix}code=${encodeURIComponent(code)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, ResultDTO>(null, 'post', url, true, false);
  }

  // post: api/Account/ResetPassword?userId=${userId}&code=${encodeURIComponent(code)}
  public resetPassword(userId: number, code: string, model: ResetPasswordDTO): Promise<ResultDTO> {
    let url = `api/Account/ResetPassword`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (userId != null) {
      url += `${prefix}userId=${userId}`;
      prefix = '&';
    }
    if (code != null) {
      url += `${prefix}code=${encodeURIComponent(code)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, ResultDTO>(model, 'post', url, true, false);
  }

  // post: api/Account/ValidateUsername?username=${encodeURIComponent(username)}
  public validateUsername(username: string): Promise<ResultDTO> {
    let url = `api/Account/ValidateUsername`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (username != null) {
      url += `${prefix}username=${encodeURIComponent(username)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, ResultDTO>(null, 'post', url, true, false);
  }

  // post: api/Account/ValidatePassword?password=${encodeURIComponent(password)}
  public validatePassword(password: string): Promise<ResultDTO> {
    let url = `api/Account/ValidatePassword`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (password != null) {
      url += `${prefix}password=${encodeURIComponent(password)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, ResultDTO>(null, 'post', url, true, false);
  }

  // post: api/Account/ChangePassword
  public changePassword(model: ChangePasswordDTO): Promise<ResultDTO> {
    let url = `api/Account/ChangePassword`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, ResultDTO>(model, 'post', url, true, false);
  }

  // get: api/Account/Logout
  public logout(): Promise<void> {
    let url = `api/Account/Logout`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, void>(null, 'get', url, true, false);
  }

  // get: api/Account/GetCurrentUser
  public getCurrentUser(): Promise<UserSecurityDTO> {
    let url = `api/Account/GetCurrentUser`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, UserSecurityDTO>(null, 'get', url, true, false);
  }

  // get: api/Account/GetCurrentUserSettings
  public getCurrentUserSettings(): Promise<UserSettingsDataDTO> {
    let url = `api/Account/GetCurrentUserSettings`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, UserSettingsDataDTO>(null, 'get', url, true, false);
  }

  // get: api/Account/Ping
  public ping(): Promise<string> {
    let url = `api/Account/Ping`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, string>(null, 'get', url, true, false);
  }
}
var service = new AccountApiService();
export default service;
