import { Steps, notification, FormInstance, Typography, Spin } from 'antd';
import * as React from 'react';
import FilerType from '../../../../consts/FilerType';
import DR1FormTypes from '../../../../consts/DR1FormTypes';
import Routes from '../../../../consts/Routes';
import FilerTypeDTO from '../../../../models/FilerTypeDTO';
import CommitteeInfoDTO from '../../../../models/CommitteeInfoDTO';
import DR1DTO from '../../../../models/DR1DTO';
import PersonInfoDTO from '../../../../models/PersonInfoDTO';
import SecondaryCommitteeInfoDTO from '../../../../models/SecondaryCommitteeInfoDTO';
import CommitteeInfo from '../../../shared/CommitteeInfo';
import PersonInfo from '../../../shared/PersonInfo';
import SecondaryCommitteeInfo from '../../../shared/SecondaryCommitteeInfo';
import Stepper, { Step } from '../../../shared/Stepper';
import UserIdAffirmation from '../../../shared/UserAffirmation';
import Dr1ApiService from '../../../../api/Dr1ApiService';
import HistoryUtil from '../../../../utils/HistoryUtil';
import RegisterFormItems from '../../../account/RegisterFormItems';
import CurrentUser from '../../../../utils/CurrentUser';
import Role from '../../../../consts/Role';
import CustomForm from '../../../shared/CustomForm';

const { Step } = Steps;

interface VSRProps {
}

interface VSRState {
  currentStepKey: VSRStep;
  dr1: DR1DTO;
  completedSteps: boolean[];
  isJurisdictionFEC: boolean;
  saving: boolean;
}

enum VSRStep {
  STEP1,
  STEP2,
  STEP3,
  STEP4,
  STEP5,
  STEP6,
  STEP7
}

class VSR extends React.Component<VSRProps, VSRState> {
  private _committeeInfoRef: CommitteeInfo | null = null;
  private _personRef: PersonInfo | null = null;
  private _secondaryCommitteeRef: SecondaryCommitteeInfo | null = null;
  private _registerFormRef = React.createRef<FormInstance>();
  private _userIDAffirmationRef: UserIdAffirmation | null = null;

  private steps: Step[] = [
    {
      key: VSRStep.STEP1,
      title: 'Committee',
    },
    {
      key: VSRStep.STEP2,
      title: 'Parent',
    },
    {
      key: VSRStep.STEP3,
      title: 'Resident Agents',
    },
    {
      key: VSRStep.STEP4,
      title: 'Contact'
    },
    {
      key: VSRStep.STEP5,
      title: 'Jurisdiction'
    },
    {
      key: VSRStep.STEP6,
      title: 'Register User'
    },
    {
      key: VSRStep.STEP7,
      title: 'Review / Affirmation'
    }
  ];

  constructor(props: VSRProps) {
    super(props);

    this.state = {
      currentStepKey: VSRStep.STEP1,
      dr1: DR1DTO.create({
        committeeFilerType: FilerTypeDTO.create({ id: FilerType.OSF })
      }),
      completedSteps: [false, false, false, false, false, false],
      isJurisdictionFEC: false,
      saving: false
    };
  }

  render() {
    const { currentStepKey, dr1, saving } = this.state;
    let currentStep: React.ReactElement<any>;

    switch (currentStepKey) {
      case VSRStep.STEP1:
        currentStep =
          <CommitteeInfo
            ref={(element) => this._committeeInfoRef = element}
            key={VSRStep.STEP1}
            filerType={FilerType.OSF}
            disabled={false}
            committeeInfo={dr1.committeeInfo || CommitteeInfoDTO.create({
              type: '12',
              politicalParty: '',
              county: '',
            })}
            goToTopOnLoad={true} />;
        break;

      case VSRStep.STEP2:
        currentStep =
          <SecondaryCommitteeInfo
            ref={(element) => this._secondaryCommitteeRef = element}
            key={VSRStep.STEP2}
            disabled={false}
            filerType={FilerType.OSF}
            formType={DR1FormTypes.PARENT}
            committeeValues={dr1.secondaryCommittee1 || SecondaryCommitteeInfoDTO.create({
              state: 'IA'
            })}
            goToTopOnLoad={true} />;
        break;

      case VSRStep.STEP3:
        currentStep =
          <PersonInfo
            ref={(element) => this._personRef = element}
            key={VSRStep.STEP3}
            disabled={false}
            filerType={FilerType.OSF}
            formType={DR1FormTypes.RESIDENTAGENT}
            personValues={dr1.personInfo2 || PersonInfoDTO.create({
              state: 'IA'
            })}
            goToTopOnLoad={true} />;
        break;

      case VSRStep.STEP4:
        currentStep =
          <PersonInfo
            ref={(element) => this._personRef = element}
            key={VSRStep.STEP4}
            disabled={false}
            filerType={FilerType.OSF}
            formType={DR1FormTypes.CONTACT}
            personValues={dr1.personInfo3 || PersonInfoDTO.create({
              title: '',
              state: 'IA'
            })}
            goToTopOnLoad={true} />;
        break;

      case VSRStep.STEP5:
        currentStep =
          <SecondaryCommitteeInfo
            ref={(element) => this._secondaryCommitteeRef = element}
            key={VSRStep.STEP5}
            disabled={false}
            filerType={FilerType.OSF}
            formType={DR1FormTypes.JURISDICTION}
            committeeValues={dr1.secondaryCommittee2 || SecondaryCommitteeInfoDTO.create({
              state: 'IA'
            })}
            goToTopOnLoad={true} />;
        break;

      case VSRStep.STEP6:
        currentStep = (
          <>
            <Typography.Title level={3}>Register User Account</Typography.Title>
            <CustomForm
              formRef={this._registerFormRef}
              key={VSRStep.STEP6}
              initialValues={dr1.register || undefined}
              layout="vertical"
              validateTrigger={['onChange', 'onBlur']}>
              <RegisterFormItems />
            </CustomForm>
          </>
        );
        break;

      case VSRStep.STEP7:
        currentStep =
          <Spin tip="Submitting Verified Statement Registration" spinning={saving}>
            <UserIdAffirmation
              ref={(element) => this._userIDAffirmationRef = element}
              key={VSRStep.STEP7}
              DR1Ref={dr1}
              filerType={FilerType.OSF}
              disable={false} />
          </Spin>;
        break;

      default:
        currentStep = <>Error</>;
        break;
    }

    return (
      <Stepper
        steps={this.steps}
        currentStepKey={currentStepKey}
        allowStepClick={true}
        cancelReturnUrl={CurrentUser.Get()?.isInRole(Role.IECDB_ADMINISTRATOR) ?
          Routes.generate(Routes.MANUAL_REGISTRATION) : Routes.generate(Routes.HOME_ROUTE)}
        onStepChangeRequested={this.handleStepChangeRequested}
        onSaveRequested={this.saveVSR}
        minVerticalStepWidth={250}
        buttonsDisabled={saving}
      >
        {currentStep}
      </Stepper>
    );
  }

  private handleStepChangeRequested = (requestedStep: Step) => {
    switch (this.state.currentStepKey) {
      case VSRStep.STEP1:

        if (!this._committeeInfoRef) {
          return;
        }

        if (this.state.currentStepKey > requestedStep.key) {
          this.goToStep(requestedStep.key);
          break;
        }

        if (requestedStep.key === VSRStep.STEP2 || this.state.completedSteps[requestedStep.key - 1]) {
          if (this._committeeInfoRef) {
            this._committeeInfoRef.validate().then(validResult => {
              const dr1 = {
                ...this.state.dr1, committeeInfo: { ...validResult.model }
              } as DR1DTO;
              this.setComplete(VSRStep.STEP1);
              this.goToStep(requestedStep.key, dr1);
            }).catch(errorInfo => {
              console.log(errorInfo);
            });
          }
        }

        return;
      case VSRStep.STEP2:
        if (this._secondaryCommitteeRef) {
          if (this.state.currentStepKey > requestedStep.key) {
            this.goToStep(requestedStep.key);
          }

          if (requestedStep.key === VSRStep.STEP3 || this.state.completedSteps[requestedStep.key - 1]) {
            if (this._secondaryCommitteeRef.checkEmptyFields()) {
              this.setComplete(VSRStep.STEP2);
              this.goToStep(requestedStep.key);
            }
            else {
              this._secondaryCommitteeRef.validate().then(validResult => {
                const dr1 = {
                  ...this.state.dr1, secondaryCommittee1: { ...validResult.model }
                } as DR1DTO;
                this.setComplete(VSRStep.STEP2);
                this.goToStep(requestedStep.key, dr1);
              }).catch(errorInfo => {
                console.log(errorInfo);
              });
            }
          }
        }

        return;

      case VSRStep.STEP3:
        if (!this._personRef) {
          return;
        }

        if (this.state.currentStepKey > requestedStep.key) {
          this.goToStep(requestedStep.key);
          break;
        }

        if (requestedStep.key === VSRStep.STEP4 || this.state.completedSteps[requestedStep.key - 1]) {
          this._personRef.validate().then(validResult => {
            const dr1 = {
              ...this.state.dr1, personInfo2: { ...validResult.model }
            } as DR1DTO;
            this.setComplete(VSRStep.STEP3);
            this.goToStep(requestedStep.key, dr1);
          }).catch(errorInfo => {
            console.log(errorInfo);
          });
        }

        return;

      case VSRStep.STEP4:
        if (!this._personRef) {
          return;
        }

        if (this.state.currentStepKey > requestedStep.key) {
          this.goToStep(requestedStep.key);
          break;
        }

        if (requestedStep.key === VSRStep.STEP5 || this.state.completedSteps[requestedStep.key - 1]) {
          this._personRef.validate().then(validResult => {
            const dr1 = {
              ...this.state.dr1, personInfo3: { ...validResult.model }
            } as DR1DTO;
            this.setComplete(VSRStep.STEP4);
            this.goToStep(requestedStep.key, dr1);
          }).catch(errorInfo => {
            console.log(errorInfo);
          });
        }

        return;

      case VSRStep.STEP5:
        if (this.state.currentStepKey > requestedStep.key) {
          this.goToStep(requestedStep.key);
        }

        if (this._secondaryCommitteeRef) {
          if (requestedStep.key === VSRStep.STEP6 || this.state.completedSteps[requestedStep.key - 1]) {
            this._secondaryCommitteeRef.validate().then(validResult => {
              const dr1 = {
                ...this.state.dr1, secondaryCommittee2: { ...validResult.model },
              } as DR1DTO;
              const isCheckboxChecked = validResult.model.registeredWithFEC;
              this.setComplete(VSRStep.STEP5);
              this.goToStep(requestedStep.key, dr1, isCheckboxChecked);
            }).catch(errorInfo => {
              console.log(errorInfo);
            });
          }
        }

        return;

      case VSRStep.STEP6:
        if (!this._registerFormRef) {
          return;
        }

        if (this.state.currentStepKey > requestedStep.key) {
          this.goToStep(requestedStep.key);
          break;
        }

        if (requestedStep.key === VSRStep.STEP7 || this.state.completedSteps[requestedStep.key - 1]) {
          this._registerFormRef.current?.validateFields().then(validResult => {
            const dr1 = {
              ...this.state.dr1, register: { ...validResult }
            } as DR1DTO;
            this.setComplete(VSRStep.STEP6);
            this.goToStep(requestedStep.key, dr1);
          }).catch(errorInfo => {
            console.log(errorInfo);
          });
        }

        return;

      case VSRStep.STEP7:
        if (!this._userIDAffirmationRef) {
          return;
        }

        if (this.state.currentStepKey > requestedStep.key) {
          this.setComplete(VSRStep.STEP7);
          this.goToStep(requestedStep.key);
          return;
        }

        return;

      default:
        return;
    }
  }

  private goToStep = (stepKey: VSRStep, VSRUpdate?: DR1DTO, isCheckboxChecked?: boolean) => {
    this.setState({
      currentStepKey: stepKey,
      dr1: VSRUpdate || this.state.dr1,
      isJurisdictionFEC: isCheckboxChecked || this.state.isJurisdictionFEC
    });
  }

  private setComplete = (stepKey: VSRStep) => {
    const completed = this.state.completedSteps;
    completed[stepKey] = true;
    this.setState({ completedSteps: completed });
  }

  private saveVSR = () => {
    this.setState({ saving: true }, () => {
      Dr1ApiService.add(this.state.dr1).then((result) => {
        if (result.succeeded) {
          if (CurrentUser.Get()?.isInRole(Role.IECDB_ADMINISTRATOR)) {
            HistoryUtil.push(Routes.MANUAL_REGISTRATION);
          }
          else {
            HistoryUtil.push(Routes.LOGIN);
          }
          notification.success({
            message: 'Your report was submitted successfully.',
            description: 'You will be contacted by the IECDB staff upon approval. Thank you.'
          });
        } else {
          notification.error({
            message: 'Submission failed',
            description: result.errors.length > 0 ? result.errors[0] : undefined
          });
        }
      }).catch(error => {
        notification.error({ message: error.message });
      }).finally(() => {
        this.setState({ saving: false });
      });
    });
  }
}

export default VSR;
