import {
  Button,
  FormInstance,
  Input,
  Modal,
  notification,
  Select,
  Space,
  Typography
} from 'antd';
import * as React from 'react';
import LookupsApiService from '../../api/LookupsApiService';
import ContactApiService from '../../api/ContactApiService';
import { Content } from 'antd/lib/layout/layout';
import CodeLookupTableDTO from '../../models/CodeLookupTableDTO';
import ContactEditFormDTO from '../../models/ContactEditFormDTO';
import HistoryUtil from '../../utils/HistoryUtil';
import Routes from '../../consts/Routes';
import { RouteComponentProps } from 'react-router';
import DateUtil from '../../utils/DateUtil';
import AddressFormFields from '../shared/AddressFormFields';
import FormItem from 'antd/lib/form/FormItem';
import FormValidationUtil from '../../utils/FormValidationUtil';
import Dr2GroupTypes from '../../consts/Dr2GroupTypes';
import CurrentUser from '../../utils/CurrentUser';
import CustomForm from '../shared/CustomForm';

const Option = Select.Option;
const confirm = Modal.confirm;

interface EditContactsProps {
  perorgId: string;
}

interface EditContactsState {
  relations: CodeLookupTableDTO[];
  currContactType: string;
  contactInfo: ContactEditFormDTO;
  perorgId: string;
  btnDisable: boolean;
  loading: boolean;
}

class EditContacts extends React.Component<RouteComponentProps<EditContactsProps>, EditContactsState> {
  private readonly _formRef = React.createRef<FormInstance>();
  private readonly dateFormat = DateUtil.getDateFormatString();

  constructor(props: RouteComponentProps<EditContactsProps>) {
    super(props);
    this.state = {
      relations: [],
      currContactType: '',
      contactInfo: ContactEditFormDTO.create({ contactType: '', addressState: 'IA', relationshipCode: '' }),
      perorgId: this.props.match.params.perorgId,
      btnDisable: false,
      loading: !!this.props.match.params.perorgId
    };
  }

  componentDidMount() {
    this.loadLookups();
    if (this.props.match.params.perorgId) {
      this.getContact(this.props.match.params.perorgId || '', CurrentUser.Get()?.committeeId || '');
    }
  }

  render() {
    const { relations, currContactType, contactInfo, perorgId, btnDisable, loading } = this.state;
    const { ...formProps } = this.props;
    const isIndividual = currContactType === Dr2GroupTypes.INDIVIDUAL;
    const isAddPage = !perorgId;

    const contributorTypes = [
      Dr2GroupTypes.INDIVIDUAL,
      Dr2GroupTypes.TRUST,
      Dr2GroupTypes.COMPANYOTHER
    ];

    return (
      <>
        {!loading &&
          <Content className="content-pad">
            <Typography.Title level={4}>Contacts</Typography.Title>
            <CustomForm {...formProps}
              formRef={this._formRef}
              initialValues={contactInfo}
              layout="vertical"
              onFinish={this.handleSave}>
              <FormItem name="contactType" label="Contact Type"
                rules={[FormValidationUtil.Required('Contact Type is required')]} >
                <Select
                  showSearch
                  optionFilterProp="children"
                  disabled={!isAddPage}
                  onChange={this.handleContactTypeChange}>
                  <Option value="" disabled={true}>-- Select Type --</Option>
                  {contributorTypes.map(ct => (
                    <Option key={ct} value={ct}>{ct}</Option>
                  ))}
                </Select>
              </FormItem>
              <AddressFormFields
                firstName={{ name: 'firstName', required: isIndividual, hidden: !isIndividual }}
                middleInitial={{ name: 'middleInitial', required: isIndividual, hidden: !isIndividual }}
                lastName={{ name: 'lastName', required: isIndividual, hidden: !isIndividual }}
                name={{ name: 'contactName', required: !isIndividual, hidden: isIndividual }}
                address1={{ name: 'addressLine1' }}
                address2={{ name: 'addressLine2' }}
                city={{ name: 'addressCity' }}
                state={{ name: 'addressState' }}
                zip={{ name: 'addressZip' }}
                phone={{ name: 'phoneNumber', required: false }}
                email={{ name: 'email', required: false }}
              />
              {currContactType === Dr2GroupTypes.INDIVIDUAL &&
                <FormItem name="relationshipCode" label="Relationship"
                  rules={[FormValidationUtil.Required('Relationship is required', isIndividual)]}>
                  <Select
                    showSearch
                    optionFilterProp="children">
                    <Option value="" disabled={true}>-- Select Relationship --</Option>
                    {relations.map(rt => (
                      <Option key={rt.code || ''} value={rt.code || ''}>{rt.name}</Option>
                    ))}
                  </Select>
                </FormItem>
              }
              {currContactType === Dr2GroupTypes.TRUST &&
                <>
                  <FormItem name="trustee" label="Trustee">
                    <Input />
                  </FormItem>
                  <FormItem name="trustor" label="Trustor">
                    <Input />
                  </FormItem>
                </>
              }
              <Space>
                <Button type="primary" disabled={btnDisable} htmlType="submit">Save Contact</Button>
                <Button type="default" disabled={btnDisable} onClick={this.handleCancel}>Cancel</Button>
              </Space>
            </CustomForm >
          </Content>
        }
      </>
    );
  }

  private handleContactTypeChange = (contactType: string) => {
    this.setState({ currContactType: contactType });
    this._formRef.current?.resetFields([
      'firstName',
      'middleInitial',
      'lastName',
      'contactName',
      'addressLine1',
      'addressLine2',
      'addressCity',
      'addressState',
      'addressZip',
      'email',
      'phoneNumber',
      'relationshipCode',
      'trustee',
      'trustor'
    ]);
  }

  private loadLookups = () => {
    const relationPromise = LookupsApiService.getRelationTypes();
    Promise.all([relationPromise])
      .then(result => {
        const [relations] = result;
        this.setState({ relations: relations });
      });
  }

  private getContact = (perorgId: string, committeeId: string) => {
    ContactApiService.getContact(perorgId, committeeId)
      .then(contact => {
        if (contact) {
          this.setState({
            contactInfo: contact,
            currContactType: contact.contactType || ''
          });
          this._formRef.current?.setFieldsValue(contact);
          this.setState({ loading: false });
    }
        else {
          notification.error({
            message: 'Error while fetching contact',
            description: 'Not found'
          });
          this.setState({ loading: false });
        }
      }).catch(() => {
          notification.error({
          message: 'Error while fetching contact',
          description: ''
          });
        this.setState({ loading: false });
        });
    }

  private handleSave = (values: ContactEditFormDTO) => {
    this.setState({ btnDisable: true });

    if (this.state.perorgId) {
      values.perorgId = this.state.perorgId;
    }

    ContactApiService.save(
        values,
      CurrentUser.Get()?.committeeId || ''
      )
        .then(() => {
          notification.success({
          message: 'Saved successfully',
            description: ''
          });
          HistoryUtil.push(Routes.CONTACTS_BASE);
        })
        .catch((error) => {
          notification.error({
            message: error.message,
            description: error.description
          });
          HistoryUtil.push(Routes.CONTACTS_BASE);
        });
    }

  private handleCancel = () => {
    confirm({
      title: 'Are you sure you want to leave?',
      onOk: () => {
        HistoryUtil.push(Routes.CONTACTS_BASE);
      }
    });
  }
}

export default EditContacts;