import {
  Col,
  Form,
  Input,
  Row
} from 'antd';
import * as React from 'react';
import AccountApiService from '../../api/AccountApiService';
import ResultDTO from '../../models/ResultDTO';
import FormValidationUtil from '../../utils/FormValidationUtil';

const FormItem = Form.Item;

interface RegisterFormItemsProps {
  review?: boolean;
}

interface RegisterFormItemsState {
  error: any;
  review: boolean;
}

class RegisterFormItems extends React.Component<RegisterFormItemsProps, RegisterFormItemsState> {
  constructor(props: RegisterFormItemsProps) {
    super(props);

    this.state = {
      error: null,
      review: this.props.review || false,
    };
  }

  validatePassword = (e: any) => {
    if (e.target.value !== null && e.target.value !== '') {
      AccountApiService.validatePassword(e.target.value).then((response: ResultDTO | null) => {
        if (response?.succeeded) {
          this.setState({ error: null });
        } else {
          this.setState({ error: { message: response?.errors.join('\n') } });
        }
      });
    }
  };

  render() {
    const { review } = this.state;
    const emptyCol = { xs: 0, sm: 0, md: 0 };
    const wrapperCol = { xs: 24, sm: 24, md: 24 };
    const wrapperColNoLabel = {};
    Object.keys(wrapperCol).forEach((val) => {
      wrapperColNoLabel[val] = {
        span: wrapperCol[val],
        offset: wrapperCol[val] + emptyCol[val] > 24 ? 0 : emptyCol[val],
      };
    });

    return (
      <>
        <FormItem
          name="username"
          label="Username"
          rules={[
            FormValidationUtil.Required('Username is required'),
            FormValidationUtil.ValidateUsername(true)
          ]}
          labelCol={wrapperColNoLabel}
          wrapperCol={wrapperColNoLabel}
        >
          <Input disabled={review} />
        </FormItem>
        <Row gutter={8}>
          <Col flex="1">
            <FormItem name="firstName" label="First Name" rules={[FormValidationUtil.Required('First Name is required')]} >
              <Input type="text" maxLength={20} disabled={review} />
            </FormItem>
          </Col>
          <Col flex="1">
            <FormItem name="lastName" label="Last Name" rules={[FormValidationUtil.Required('Last Name is required')]}>
              <Input type="text" maxLength={25} disabled={review} />
            </FormItem>
          </Col>
        </Row>
        <FormItem
          name="email"
          label="Email Address"
          rules={[
            FormValidationUtil.Required('Email Address is required'),
            { ...FormValidationUtil.Email('Email Address must be in a valid email format'), validateTrigger: ['onBlur'] }
          ]}
          labelCol={wrapperColNoLabel}
          wrapperCol={wrapperColNoLabel}
        >
          <Input disabled={review} />
        </FormItem>
        {!review &&
          (
            <>
              <FormItem
                name="password"
                label="Password"
                labelCol={wrapperColNoLabel}
                wrapperCol={wrapperColNoLabel}
                rules={[
                  FormValidationUtil.Required('Password is required'),
                  { ...FormValidationUtil.ValidatePassword() }
                ]}
              >
                <Input.Password />
              </FormItem>
              <FormItem
                name="confirmPassword"
                label="Confirm Password"
                rules={[
                  FormValidationUtil.Required('Confirm password is required'),
                  FormValidationUtil.CheckConfirmPassword('password')
                ]}
                labelCol={wrapperColNoLabel}
                wrapperCol={wrapperColNoLabel}
              >
                <Input.Password />
              </FormItem>
            </>
          )}
      </>
    );
  }
}

export default RegisterFormItems;
