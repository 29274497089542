import {
    notification,
    FormInstance,
    Row,
    Col,
    Input,
    Select,
    Button,
    Table,
    Tooltip,
    Typography,
    Divider,
} from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import * as React from 'react';
import LookupsApiService from '../../../../api/LookupsApiService';
import LookupTableDTO from '../../../../models/LookupTableDTO';
import PfdDivisionDTO from '../../../../models/PfdDivisionDTO';
import PfdPositionDTO from '../../../../models/PfdPositionDTO';
import PoliticalPositionsDTO from '../../../../models/PoliticalPositionsDTO';
import FormValidationUtil from '../../../../utils/FormValidationUtil';
import CustomForm from '../../../shared/CustomForm';

const { Option } = Select;
const { Column } = Table;
const { Title } = Typography;

interface PoliticalPositionsProps {
    politicalPositions: PoliticalPositionsDTO[];
}

interface PoliticalPositionsState {
    departments: LookupTableDTO[];
    divisions: PfdDivisionDTO[];
    positions: PfdPositionDTO[];
    politicalPositions: PoliticalPositionsDTO[];
    departmentName: string;
    divisionName: string;
    positionName: string;
    blankDept: boolean;
    blankDiv: boolean;
    divisionOtherNotUsed: boolean;
    positionOtherNotUsed: boolean;
}

class PoliticalPositions extends React.Component<PoliticalPositionsProps, PoliticalPositionsState>
{
    private readonly _formRef = React.createRef<FormInstance>();

    constructor(props: PoliticalPositionsProps) {
        super(props);

        this.state = {
            departments: [],
            divisions: [],
            positions: [],
            politicalPositions: this.props.politicalPositions || [],
            departmentName: '',
            divisionName: '',
            positionName: '',
            blankDept: true,
            blankDiv: true,
            divisionOtherNotUsed: true,
            positionOtherNotUsed: true,
        };
    }

    moveBack = async (): Promise<{ model: PoliticalPositionsDTO[], errorFields: any }> => {
        return {
            model: this.state.politicalPositions, errorFields: null
        };
    }

    validate = async (): Promise<{ model: PoliticalPositionsDTO[], errorFields: any }> => {
        if (this.state.politicalPositions.length > 0) {
            return {
                model: this.state.politicalPositions, errorFields: null
            };
        } else {
            notification.error({
                message: 'At least one Government / Political Position must be listed',
            });
            return Promise.reject();
        }
    }

    componentDidMount() {
        this.fetchDepartments();
    }

    render() {
        const { departments, divisions, positions, blankDept, blankDiv } = this.state;
        return (
            <>
                <Title level={4}>
                    Government / Political Positions
                </Title>
                <Row>
                    <Col xs={24}>
                        <CustomForm formRef={this._formRef} onFinish={this.addPosition} layout="vertical">
                            <FormItem name="departmentId"
                                label="State Agency"
                                rules={[FormValidationUtil.RequiredNumber('State Agency is required')]}>
                                <Select defaultValue={0} onChange={this.departmentChanged}>
                                    <Option value={0}>-- Select Agency / Department --</Option>
                                    {departments.map(d => (
                                        <Option key={d.id || ''} value={d.id || ''}>{d.name}</Option>
                                    ))}
                                </Select>
                            </FormItem>
                            <Divider />
                            <Tooltip title="If there is no division, choose None/Not Applicable">
                                <FormItem
                                    style={{ marginTop: '10px' }}
                                    name="divisionId"
                                    label="Division / Department"
                                    required
                                    rules={[
                                        FormValidationUtil.RequiredNumber('Division / Department is required', this.state.divisionOtherNotUsed)
                                    ]}
                                >
                                    <Select defaultValue={0} disabled={this.state.divisions.length < 1 || blankDept} onChange={this.divisionChanged}>
                                        <Option value={0}>-- Select Division --</Option>
                                        {divisions.map(d => (
                                            <Option key={d.divisionId || ''} value={d.divisionId || ''}>{d.name}</Option>
                                        ))}
                                    </Select>
                                </FormItem>
                            </Tooltip>
                            <Typography.Text >
                                If your division/department is not found in the drop down menu above,
                                type in your division/department in the field below.
                            </Typography.Text>
                            <FormItem name="divisionOther" label="Other Division / Department"
                                style={{ marginTop: '10px' }}
                                rules={[FormValidationUtil.Max(100, 'Max Length: 100 characters')]}>
                                <Input type="text" onChange={this.divisionOtherChanged} />
                            </FormItem>
                            <Divider />
                            <FormItem
                                name="positionId"
                                label="Position Held"
                                required rules={[FormValidationUtil.RequiredNumber('Position held is required', this.state.positionOtherNotUsed)]}
                            >
                                <Select defaultValue={0}
                                    disabled={this.state.positions.length < 1 || blankDept || blankDiv}
                                    onChange={this.positionChanged}>
                                    <Option value={0}>-- Select Position --</Option>
                                    {positions.map(p => (
                                        <Option key={p.positionId || ''} value={p.positionId || ''}>{p.name}</Option>
                                    ))}
                                </Select>
                            </FormItem>
                            <Typography.Text >
                                If your position held is not found in the drop down menu above,
                                type in your position held in the field below.
                            </Typography.Text>
                            <FormItem name="positionOther"
                                label="Other Position Held"
                                style={{ marginTop: '10px' }}
                                rules={[FormValidationUtil.Max(100, 'Max Length: 100 characters')]}>
                                <Input type="text" onChange={this.positionOtherChanged} />
                            </FormItem>
                            <Button type="primary" htmlType="submit">Add</Button>
                            <Divider />
                        </CustomForm>
                    </Col>
                </Row>
                <br />
                {
                    this.state.politicalPositions.length > 0 &&
                    <Row>
                        <Col xs={24}>
                            <Table dataSource={this.state.politicalPositions}>
                                <Column title="State Agency" dataIndex="departmentOther" key="departmentOther" />
                                <Column title="Division / Department" dataIndex="divisionOther" key="divisionOther" />
                                <Column title="Position" dataIndex="positionOther" key="positionOther" />
                                <Column
                                    title="Delete"
                                    dataIndex=""
                                    render={(text, record, index) =>
                                        <Button onClick={() => { this.deletePosition(index); }}>Delete</Button>
                                    }
                                />
                            </Table>
                        </Col>
                    </Row >
                }

            </>
        );
    }
    private divisionOtherChanged = () => {
        const value: string = this._formRef.current?.getFieldValue('divisionOther');
        this.setState({ divisionOtherNotUsed: value.length < 1 });
        this._formRef.current?.setFieldsValue({ divisionId: 0, positionId: 0 });
        this._formRef.current?.validateFields(['divisionId']);
        this.setState({ blankDiv: true });
    }

    private positionOtherChanged = () => {
        const value: string = this._formRef.current?.getFieldValue('positionOther');
        this.setState({ positionOtherNotUsed: value.length < 1 });
        this._formRef.current?.setFieldsValue({ positionId: 0 });
        this._formRef.current?.validateFields(['positionId']);
    }

    private departmentChanged = (value: any) => {
        this.setState({ blankDept: value === 0, blankDiv: true });
        for (let i = 0; i < this.state.departments.length; i++) {
            if (this.state.departments[i].id == value) {
                this.setState({ departmentName: this.state.departments[i].name || '' });
            }
        }
        this.fetchDivisions();
        this._formRef.current?.setFieldsValue({ divisionId: 0, positionId: 0, divisionOther: '', positionOther: '' });
    }

    private divisionChanged = (value: any) => {
        this.setState({ blankDiv: value === 0 });
        for (let i = 0; i < this.state.divisions.length; i++) {
            if (this.state.divisions[i].divisionId == value) {
                this.setState({ divisionName: this.state.divisions[i].name || '' });
            }
        }
        this.fetchPositions();
        this._formRef.current?.setFieldsValue({ positionId: 0, divisionOther: '', positionOther: '' });
    }

    private positionChanged = (value: any) => {
        for (let i = 0; i < this.state.positions.length; i++) {
            if (this.state.positions[i].positionId == value) {
                this.setState({ positionName: this.state.positions[i].name || '' });
            }
        }
        this._formRef.current?.setFieldsValue({ positionOther: '' });
    }

    private fetchDepartments = () => {
        LookupsApiService.getDepartments()
            .then((departments) => {
                this.setState({ departments });
            })
            .catch(() => {
                notification.error({
                    message: 'Failed to retrieve departments.',
                });
            });
    }

    private fetchDivisions = () => {
        LookupsApiService.getDivisionsByDepartment(this._formRef.current?.getFieldValue('departmentId'))
            .then((divisions) => {
                this.setState({ divisions });
            })
            .catch(() => {
                notification.error({
                    message: 'Failed to retrieve divisions.',
                });
            });
    }

    private fetchPositions = () => {
        LookupsApiService.getPositionsByDepartmentAndDivision(
            this._formRef.current?.getFieldValue('departmentId'),
            this._formRef.current?.getFieldValue('divisionId'))
            .then((positions) => {
                this.setState({ positions });
            })
            .catch(() => {
                notification.error({
                    message: 'Failed to retrieve positions.',
                });
            });
    }

    private addPosition = (values: any) => {
        values.departmentOther = this.state.departmentName;

        if ((values.divisionOther == '' && values.divisionId == 0)
            || (values.positionId == 0 && values.positionOther == '')) {
            this.validate();
        }
        else {

            if (values.divisionId != '') {
                values.divisionOther = this.state.divisionName;
            } else {
                values.divisionId = '0';
            }

            if (values.positionId != '') {
                values.positionOther = this.state.positionName;
            } else {
                values.positionId = '0';
            }

            this._formRef.current?.resetFields();
            this.setState({ politicalPositions: [...this.state.politicalPositions, values], divisions: [], positions: [] });
        }
    }

    private deletePosition = (index: any) => {
        const dataSource = [...this.state.politicalPositions];
        const value = dataSource[index];
        this.setState({ politicalPositions: dataSource.filter(item => item !== value) });
    }
}

export default PoliticalPositions;