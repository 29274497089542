import { Layout, Modal, notification} from 'antd';
import * as React from 'react';
import AdminEscheatApiService from '../../api/AdminEscheatApiService';
import Routes from '../../consts/Routes';
import HistoryUtil from '../../utils/HistoryUtil';
import DataTable, { DataTableColumnProps } from '../shared/DataTable/DataTable';
import DataTableButtonUtil from '../shared/DataTable/DataTableButtonUtil';
import DataTableColumnUtil from '../shared/DataTable/DataTableColumnUtil';
import AdminEscheatDTO from '../../models/AdminEscheatDTO';

const Content = Layout.Content;
const { confirm } = Modal;

interface ManageEscheatsProps {

}

interface ManageEscheatsState {
    loading: boolean
}

class ManageEscheats extends React.Component<ManageEscheatsProps, ManageEscheatsState> {
    private dataTable: DataTable<AdminEscheatDTO> | undefined;
    constructor(props: ManageEscheatsProps) {
        super(props);
        this.state = {
            loading: false
        };
    }

    render() {
        const actionButtons = [];
        actionButtons.push(DataTableButtonUtil.Reset());
        actionButtons.push(DataTableButtonUtil.Primary('Add Escheat', () => this.goToEscheatsDetails()));

        return (
            <Content className="content-pad">
                <DataTable
                    ref={(element: any) => (this.dataTable = element)}
                    serverSide={true}
                    tableProps={{
                        rowKey: 'Id',
                        sortDirections: ['descend', 'ascend']
                    }}
                    globalSearch={true}
                    title="Manage Escheats"
                    buttonBar={actionButtons}
                    columns={this.getEscheatsTableColumns()}
                    styleOptions={{ compact: true, alternatingRowHighlight: true }}
                    fetchData={{
                        fetch: function (tableRequest) { return AdminEscheatApiService.getEscheatList(tableRequest); },
                        failureMessage: 'Failed to retrieve Escheat Data'
                    }}
                    stateSaving={{
                        enabled: true,
                        tableUniqueKey: 'adminManageEscheats',
                        perSession: true,
                    }}
                />
            </Content>
        );
    }

    private goToEscheatsDetails() {
        HistoryUtil.push(
            Routes.generate(Routes.ADD_ESCHEAT)
        );
    }

    private getEscheatsTableColumns = (): DataTableColumnProps<AdminEscheatDTO>[] => {
        const columns: DataTableColumnProps<AdminEscheatDTO>[] = [];
        columns.push(DataTableColumnUtil.Text('Committee Or Individual', 'name'));
        columns.push(DataTableColumnUtil.Date('Filed Date', 'filedDate', null, { defaultSortOrder: 'descend' }));
        columns.push(DataTableColumnUtil.Currency('Amount', 'amount'));
        const manageButtons = [
            { text: 'Edit', onClick: (rowData: AdminEscheatDTO) => this.edit(rowData.id) },
            { text: 'Delete', onClick: (rowData: AdminEscheatDTO) => this.confirmDelete(rowData.id, rowData.name || '') }
        ];
        const columnManage: DataTableColumnProps<AdminEscheatDTO> = DataTableColumnUtil.Buttons('route', manageButtons, 150);
        columns.push(columnManage);
        return columns as DataTableColumnProps<AdminEscheatDTO>[];
    }

    private confirmDelete = (id: number, name: string) => {
        if (!id) {
            return;
        }

        confirm({
            title: 'Are you sure you want to delete this Escheat record?',
            content: 'The following record will be permanently deleted: ' + name,
            onOk: () => {
                this.delete(id);
            },
        });
    }

    private delete = (id: number) => {
        AdminEscheatApiService.delete(id)
            .then(() => {
                this.dataTable?.refresh();
                notification.success({
                    message: 'Escheat was deleted Successfully'
                });
            }).catch(() => {
                notification.error({
                    message: 'Failed to delete Escheat'
                });
            });
    }

    private edit = (id: number) => {
        HistoryUtil.push(Routes.generate(
            Routes.EDIT_ESCHEAT, {
                id: id
            }));
    }
}

export default ManageEscheats;