// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';

interface ContactEditFormDTO { 
  perorgId: string | null;
  contactType: string | null;
  contactName: string | null;
  firstName: string | null;
  middleInitial: string | null;
  lastName: string | null;
  addressLine1: string | null;
  addressLine2: string | null;
  addressCity: string | null;
  addressState: string | null;
  addressZip: string | null;
  email: string | null;
  phoneNumber: string | null;
  relationshipCode: string | null;
  trustee: string | null;
  trustor: string | null;
}
const ContactEditFormDTO = {
  create: (initValues?: Partial<ContactEditFormDTO> | Record<string, unknown> | null) => {
    return Object.assign(
    {
      perorgId: "00000000-0000-0000-0000-000000000000",
      contactType: "",
      contactName: "",
      firstName: "",
      middleInitial: "",
      lastName: "",
      addressLine1: "",
      addressLine2: "",
      addressCity: "",
      addressState: "",
      addressZip: "",
      email: "",
      phoneNumber: "",
      relationshipCode: "",
      trustee: "",
      trustor: "",
    },
    initValues);
  }
};

export default ContactEditFormDTO;