// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
import BaseApi from './BaseApi';
import DonorRegistrationDTO from '../models/DonorRegistrationDTO';
import ResultDTO from '../models/ResultDTO';

export class RegisterDonorApiService extends BaseApi {

  // post: api/giftBequest
  public add(donorRegistrationEntry: DonorRegistrationDTO): Promise<ResultDTO> {
    let url = `api/giftBequest`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, ResultDTO>(donorRegistrationEntry, 'post', url, true, false);
  }
}
var service = new RegisterDonorApiService();
export default service;
