// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
import BaseApi from './BaseApi';
import TableRequestDTO from '../models/TableRequestDTO';
import ManageUsersListDTO from '../models/ManageUsersListDTO';
import TableResponseDTO from '../models/TableResponseDTO';
import FilerUserCommitteeInfoDTO from '../models/FilerUserCommitteeInfoDTO';
import ResultDTO from '../models/ResultDTO';

export class UsersApiService extends BaseApi {

  // post: api/users/table?committeeId=${encodeURIComponent(committeeId)}
  public getUsersList(tableRequest: TableRequestDTO, committeeId: string | null): Promise<TableResponseDTO<ManageUsersListDTO>> {
    let url = `api/users/table`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (committeeId != null) {
      url += `${prefix}committeeId=${encodeURIComponent(committeeId)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, TableResponseDTO<ManageUsersListDTO>>(tableRequest, 'post', url, true, false);
  }

  // get: api/users?userId=${userId}
  public getUser(userId: number): Promise<ManageUsersListDTO> {
    let url = `api/users`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (userId != null) {
      url += `${prefix}userId=${userId}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, ManageUsersListDTO>(null, 'get', url, true, false);
  }

  // get: api/users/committee?committeeId=${encodeURIComponent(committeeId)}
  public getCommitteeById(committeeId: string): Promise<FilerUserCommitteeInfoDTO> {
    let url = `api/users/committee`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (committeeId != null) {
      url += `${prefix}committeeId=${encodeURIComponent(committeeId)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, FilerUserCommitteeInfoDTO>(null, 'get', url, true, false);
  }

  // post: api/users
  public saveUser(user: ManageUsersListDTO): Promise<ResultDTO> {
    let url = `api/users`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, ResultDTO>(user, 'post', url, true, false);
  }
}
var service = new UsersApiService();
export default service;
