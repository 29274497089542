import MaskedInput from 'antd-mask-input';
import { Rule } from 'antd/lib/form';
import FormItem from 'antd/lib/form/FormItem';
import * as React from 'react';
import LookupsApiService from '../../api/LookupsApiService';
import CodeLookupTableDTO from '../../models/CodeLookupTableDTO';
import FormValidationUtil from '../../utils/FormValidationUtil';
import CommitteeAutoSearch from './CommitteeSearch';
import OrganizationAutoSearch from './OrganizationSearch';
import {
  Col,
  Input,
  notification,
  Row,
  Select,
} from 'antd';

const { Option } = Select;

interface AddressFormInput {
  name: string;
  label?: string;
  maxLength?: number;
  required?: boolean;
  disabled?: boolean;
  hidden?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

interface SearchAddressFormInput extends AddressFormInput {
  committeeId?: string;
  perorgType?: string;
  onSelect?: (Id: string) => void;
  onSearchChange?: (value: string) => void;
}

interface AddressFormFieldsProps {
  firstName?: AddressFormInput;
  middleInitial?: AddressFormInput;
  lastName?: AddressFormInput;
  name?: AddressFormInput;
  committeeSearchName?: SearchAddressFormInput;
  organizationSearchName?: SearchAddressFormInput;
  address1: AddressFormInput;
  address2: AddressFormInput;
  city: AddressFormInput;
  state: AddressFormInput;
  zip: AddressFormInput;
  email?: AddressFormInput;
  phone?: AddressFormInput;
  allDisabled?: boolean;
  ariaPrefix?: string;
}

interface AddressFormFieldsState {
  states: CodeLookupTableDTO[];
}

class AddressFormFields extends React.Component<AddressFormFieldsProps, AddressFormFieldsState>{
  constructor(props: AddressFormFieldsProps) {
    super(props);

    this.state = {
      states: []
    };
  }

  componentDidMount() {
    this.fetchStates();
  }

  render() {
    const { firstName, middleInitial, lastName, name, committeeSearchName, organizationSearchName,
      address1, address2, city, state, zip, email, phone, allDisabled, ariaPrefix } = this.props;
    const { states } = this.state;

    const firstNameRules: Rule[] = [];
    const middleInitialRules: Rule[] = [FormValidationUtil.Max(1)];
    const lastNameRules: Rule[] = [];
    const nameRules: Rule[] = [];
    const address1Rules: Rule[] = [];
    const cityRules: Rule[] = [];
    const stateRules: Rule[] = [];
    const zipRules: Rule[] = [FormValidationUtil.Zip()];
    const emailRules: Rule[] = [];
    const phoneRules: Rule[] = [FormValidationUtil.PhoneOrNumber()];

    if (firstName && firstName?.required !== false) {
      firstNameRules.push(FormValidationUtil.Required((firstName.label || 'First Name') + ' is required'));
    }
    if (lastName && lastName?.required !== false) {
      lastNameRules.push(FormValidationUtil.Required((lastName.label || 'Last Name') + ' is required'));
    }
    if (name && name?.required !== false) {
      nameRules.push(FormValidationUtil.Required((name.label || 'Name') + ' is required'));
    }
    if (address1 && address1?.required !== false) {
      address1Rules.push(FormValidationUtil.Required((address1.label || 'Address 1') + ' is required'));
    }
    if (city && city?.required !== false) {
      cityRules.push(FormValidationUtil.Required((city.label || 'City') + ' is required'));
    }
    if (state && state?.required !== false) {
      stateRules.push(FormValidationUtil.Required((state.label || 'State') + ' is required'));
    }
    if (zip && zip?.required !== false) {
      zipRules.push(FormValidationUtil.Required((zip.label || 'Zip code') + ' is required'));
    }
    if (email && email?.required !== false) {
      emailRules.push(FormValidationUtil.Required((email.label || 'Email') + ' is required'));
    }
    if (phone && phone?.required !== false) {
      phoneRules.push(FormValidationUtil.Required((phone.label || 'Phone Number') + ' is required'));
    }

    return (
      <>
        {(firstName || middleInitial || lastName) && (
          <Row gutter={8}>
            {firstName && (
              <Col flex="1">
                <FormItem
                  name={firstName.name}
                  label={this.getLabel(ariaPrefix, firstName.label || 'First Name')}
                  hidden={firstName.hidden || false}
                  rules={firstNameRules} >
                  <Input
                    type="text"
                    maxLength={firstName.maxLength || 20}
                    disabled={firstName.disabled || allDisabled || false}
                    onChange={(e) => firstName?.onChange && firstName?.onChange(e) }
                  />
                </FormItem>
              </Col>
            )}
            {middleInitial && (
              <Col flex="50px">
                <FormItem
                  name={middleInitial.name}
                  label={this.getLabel(ariaPrefix, middleInitial.label || 'MI')}
                  hidden={middleInitial.hidden || false}
                  rules={middleInitialRules} >
                  <Input
                    type="text"
                    maxLength={middleInitial.maxLength || 1}
                    disabled={middleInitial.disabled || allDisabled || false} />
                </FormItem>
              </Col>
            )}
            {lastName && (
              <Col flex="1">
                <FormItem
                  name={lastName.name}
                  label={this.getLabel(ariaPrefix, lastName.label || 'Last Name')}
                  hidden={lastName.hidden || false}
                  rules={lastNameRules}>
                  <Input
                    type="text"
                    maxLength={lastName.maxLength || 25}
                    disabled={lastName.disabled || allDisabled || false}
                    onChange={(e) => lastName?.onChange && lastName?.onChange(e)}
                  />
                </FormItem>
              </Col>
            )}
          </Row>
        )}
        {name && (
          <FormItem
            name={name.name}
            label={this.getLabel(ariaPrefix, name.label || 'Name')}
            hidden={name.hidden || false} rules={nameRules} >
            <Input
              type="text"
              maxLength={name.maxLength || 60}
              disabled={name.disabled || allDisabled || false}
              onChange={(e) => name?.onChange && name?.onChange(e)}
            />
          </FormItem>
        )}
        {committeeSearchName && (
          <CommitteeAutoSearch
            name={committeeSearchName.name}
            label={this.getLabel(ariaPrefix, committeeSearchName.label)}
            hidden={committeeSearchName.hidden}
            required={committeeSearchName.required}
            disabled={committeeSearchName.disabled || allDisabled || false}
            onSelect={committeeSearchName.onSelect}
            onChange={committeeSearchName.onSearchChange}
          />
        )}
        {organizationSearchName && (
          <OrganizationAutoSearch
            name={organizationSearchName.name}
            label={this.getLabel(ariaPrefix, organizationSearchName.label)}
            hidden={organizationSearchName.hidden}
            required={organizationSearchName.required}
            disabled={organizationSearchName.disabled || allDisabled || false}
            committeeId={organizationSearchName.committeeId || ''}
            perorgType={organizationSearchName.perorgType || ''}
            onSelect={organizationSearchName.onSelect}
            onChange={organizationSearchName.onSearchChange}
          />
        )}
        <FormItem
          name={address1.name}
          label={this.getLabel(ariaPrefix, address1.label || 'Address 1')}
          hidden={address1.hidden || false} rules={address1Rules}>
          <Input
            type="text"
            maxLength={address1.maxLength || 35}
            disabled={address1.disabled || allDisabled || false}
            onChange={(e) => address1?.onChange && address1.onChange(e)}
          />
        </FormItem>
        <FormItem
          name={address2.name}
          label={this.getLabel(ariaPrefix, address2.label || 'Address 2')}
          hidden={address2.hidden || false}>
          <Input
            type="text"
            maxLength={address2.maxLength || 35}
            disabled={address2.disabled || allDisabled || false}
            onChange={(e) => address2?.onChange && address2.onChange(e)}
          />
        </FormItem>
        <Row gutter={8}>
          <Col flex="1" style={{ minWidth: 160 }}>
            <FormItem
              name={city.name}
              label={this.getLabel(ariaPrefix, city.label || 'City')}
              hidden={city.hidden || false} rules={cityRules}>
              <Input
                type="text"
                maxLength={city.maxLength || 20}
                disabled={city.disabled || allDisabled || false}
                onChange={(e) => city?.onChange && city.onChange(e)}
              />
            </FormItem>
          </Col>
          <Col flex="70px">
            <FormItem
              name={state.name}
              label={this.getLabel(ariaPrefix, state.label || 'State')}
              hidden={state.hidden || false}
              rules={stateRules}
            >
              <Select
                showSearch={true}
                optionFilterProp="children"
                disabled={state.disabled || allDisabled || false}
                onChange={(e) => state?.onChange && state.onChange(e)}
              >
                {!state.required &&
                  <Option value="">----</Option>
                }
                {states.map(s => (
                  <Option key={s.code || ''} value={s.code || ''}>{s.code}</Option>
                ))}
              </Select>
            </FormItem>
          </Col>
          <Col flex="108px">
            <FormItem
              name={zip.name}
              label={this.getLabel(ariaPrefix, zip.label || 'Zip')}
              hidden={zip.hidden || false} rules={zipRules} >
              <Input
                type="text"
                disabled={zip.disabled || allDisabled || false}
                onChange={(e) => zip?.onChange && zip.onChange(e)}
              />
            </FormItem>
          </Col>
        </Row>
        {email && (
          <FormItem
            name={email.name}
            label={this.getLabel(ariaPrefix, email.label || 'Email')}
            hidden={email.hidden || false}
            rules={[...emailRules, FormValidationUtil.Email((email.label || 'Email') + ' must be in a valid email format')]}>
            <Input
              type="email"
              maxLength={email.maxLength || 50}
              disabled={email.disabled || allDisabled || false}
              onChange={(e) => email?.onChange && email.onChange(e)}
            />
          </FormItem>
        )}
        {phone && (
          <FormItem
            name={phone.name}
            label={this.getLabel(ariaPrefix, phone.label || 'Phone Number')}
            hidden={phone.hidden || false} rules={phoneRules} >
            <MaskedInput
              mask="(111) 111-1111"
              disabled={phone.disabled || allDisabled || false}
              onChange={(e) => phone?.onChange && phone.onChange(e)}
            />
          </FormItem>
        )}
      </>
    );
  }

  /**
   * Allow us to have aria friendly labels without showing the ariaPrefix
   * @param ariaPrefix - the part of the label that will not be shown
   * @param label - the part of the label that will be shown
   */
  private getLabel = (ariaPrefix?: string, label?: string) => {
    if (!ariaPrefix) {
      ariaPrefix = '';
    }
    return (
      <>
        <span className="visuallyhidden">
          {ariaPrefix + ' '}
        </span>
        {label}
      </>
    );
  }

  private fetchStates = () => {
    LookupsApiService.getStates()
      .then((states) => {
        states.sort((a, b) => (((a.code || '') > (b.code || '')) ? 1 : -1));
        this.setState({ states });
      })
      .catch(() => {
        notification.error({
          message: 'Failed to retrieve states.',
        });
      });
  }
}

export default AddressFormFields;
