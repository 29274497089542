import * as React from 'react';
import DataTable, { DataTableColumnProps } from '../shared/DataTable/DataTable';
import SearchableDatabaseDTO from '../../models/SearchableDatabaseDTO';
import DataTableColumnUtil from '../shared/DataTable/DataTableColumnUtil';
import CurrentUser from '../../utils/CurrentUser';
import HistoryUtil from '../../utils/HistoryUtil';
import Routes from '../../consts/Routes';

interface LoanReceivedRefDataTableProps {
    transactionInfo: SearchableDatabaseDTO[];
}

interface LoanReceivedRefDataTableState {
}

class LoanReceivedRefDataTable extends React.Component<LoanReceivedRefDataTableProps, LoanReceivedRefDataTableState> {
    constructor(props: LoanReceivedRefDataTableProps) {
        super(props);
        this.state = {};
    }

    render() {
        const { transactionInfo } = this.props;

        return (
            <>
                <DataTable
                    data={transactionInfo}
                    columns={this.getLoansReceivedColumns()}
                    serverSide={false}
                    styleOptions={{ compact: true, alternatingRowHighlight: true }}
                    tableProps={{
                        rowKey: 'loansReceivedIdAndSeq',
                        sortDirections: ['ascend', 'descend'],
                        locale: { emptyText: 'Loans Received history not available.' }
                    }}
                    title="Loan Received"
                />
            </>
        );
    }

    private getLoansReceivedColumns = (): DataTableColumnProps<SearchableDatabaseDTO>[] => {
        const tableColumns: DataTableColumnProps<SearchableDatabaseDTO>[] = [
            DataTableColumnUtil.Date('Date', 'date', 100, { defaultSortOrder: 'descend'}),
            DataTableColumnUtil.Address('Lender', 'primaryName',
                (c) => ({
                    name: c.primaryName,
                    line1: c.primaryAddress1,
                    line2: c.primaryAddress2,
                    city: c.primaryCity,
                    state: c.primaryState,
                    zip: c.primaryZip
                })
            ),
            DataTableColumnUtil.Currency('Orignal Amount', 'amount'),
            DataTableColumnUtil.LinkButton('Filing Period', 'filingPeriodDescription', (record) => this.summary(record.referenceDR2Id), 275)
        ];
        return tableColumns;
    }

    private summary = (dr2Id: string | null) => {
        CurrentUser.ChangeDr2(dr2Id);
        HistoryUtil.push(Routes.generate(Routes.SCHEDULE_SUMMARY));
    }
}

export default LoanReceivedRefDataTable;