import {
  FormInstance,
  Col,
  ColProps,
  notification,
  Typography,
  Input,
  Button,
} from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import moment from 'moment';
import * as React from 'react';
import Dr2ApiService from '../../api/Dr2ApiService';
import Role from '../../consts/Role';
import Routes from '../../consts/Routes';
import CommitteeFilingDTO from '../../models/CommitteeFilingDTO';
import CurrentUser from '../../utils/CurrentUser';
import DateUtil from '../../utils/DateUtil';
import HistoryUtil from '../../utils/HistoryUtil';
import CustomDatePicker from '../shared/CustomDatePicker';
import CustomForm from '../shared/CustomForm';

interface Dr2SubmitProps {
  dr2Id: string;
  isDissolving: boolean;
  isSaving: boolean;
}

interface Dr2SubmitState {
  dr2SubmitLoading: boolean;
  commFiling: CommitteeFilingDTO;
  filedDtTime: string;
}

class Dr2Submit extends React.Component<Dr2SubmitProps, Dr2SubmitState>{
  private _dr2FormRef = React.createRef<FormInstance>();

  constructor(props: Dr2SubmitProps) {
    super(props);
    this.state = {
      dr2SubmitLoading: true,
      commFiling: CommitteeFilingDTO.create({ filedDate: moment.utc().format('M/D/YYYY hh:mm:ss a')}),
      filedDtTime: '',
    };
  }

   validate = async (): Promise<{ model: CommitteeFilingDTO, errorFields: any }> => {
    try {
      const fields = await this._dr2FormRef.current?.validateFields();
      return { model: fields, errorFields: null };
    }
    catch (errorInfo) {
      return Promise.reject({ model: errorInfo.values, errorFields: errorInfo.errorFields });
    }
  }

  componentDidMount() {
    this.loadDr2CommitteeFiling();
  }

  render() {
    const columnSizingProps: ColProps = { xs: 24, sm: 24, md: 12 };
    const { dr2SubmitLoading, filedDtTime, commFiling } = this.state;
    const { isDissolving, isSaving } = this.props;
    return (
      <>
        <Typography.Title level={4}>DR-2 Submit</Typography.Title>
        {!dr2SubmitLoading &&
          <Col {...columnSizingProps}>
            <Typography.Paragraph>Are you sure you want to file this report? Once filed,
              all information is available to the public.</Typography.Paragraph>
          { CurrentUser.Get()?.isInRole(Role.IECDB_ADMINISTRATOR) &&
            <CustomForm
              layout="vertical"
              formRef={this._dr2FormRef}
              initialValues={{ ...commFiling, filedDtTime }}>
              <FormItem
                name="filedDate"
                label="Filed Date">
              <CustomDatePicker showTime format={DateUtil.getDateTimeFormatString()}/>
              </FormItem>
              <FormItem
                name="amendedDate"
                label="Amended Date">
                <CustomDatePicker />
              </FormItem>
              <FormItem
                name="filedDtTime"
                label="Time" >
                <Input type="text" disabled={true} />
              </FormItem>
            {!isDissolving &&
              <Button onClick={this.handleAuditButton} style={{ marginBottom: 15 }} type="primary" disabled={isSaving}>Audit DR-2</Button>
            }
            </CustomForm>
          }
          </Col>
        }
      </>
    );
  }

  private handleAuditButton = () => {
    Dr2ApiService.auditDr2(this.props.dr2Id).then(() => {
      notification.success({ message: 'Successfully audited dr-2' });
      HistoryUtil.push(Routes.generate(Routes.SCHEDULE_SUMMARY));
    }).catch(() => {
      notification.error({ message: 'Failed to audit dr-2.' });
    });
  }

  private loadDr2CommitteeFiling = () => {
    if (!this.props.dr2Id) {
      this.setState({ dr2SubmitLoading: false });
      return;
    }

    this.setState({ dr2SubmitLoading: true });
    Dr2ApiService.getDr2CommFiling(this.props.dr2Id).then((commFiling) => {
      let filedDtTime = '';
      if (commFiling.filedDate) {
        commFiling.filedDate = moment.utc(commFiling.filedDate);
        filedDtTime = moment.utc(commFiling.filedDate).local().format('hh:mm a');
      }

      if (commFiling.amendedDate) {
        commFiling.amendedDate = moment.utc(commFiling.amendedDate);
      }

      this.setState({
        commFiling,
        dr2SubmitLoading: false,
        filedDtTime,
      });
    }).catch(() => {
      notification.error({ message: 'Failed to load Committee Filing data.' });
      this.setState({ dr2SubmitLoading: false });
    });
  }
}

export default Dr2Submit;