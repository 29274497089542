import {
    Button,
    Col,
    Layout,
    Menu,
    Row,
    Tooltip,
    Typography
} from 'antd';
import * as React from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { connect } from 'react-redux';
import { Link, NavLink, RouteComponentProps, withRouter } from 'react-router-dom';
import { OrganizationIcon } from '../components/shared/CustomIcons';
import UnexpectedError from '../components/UnexpectedError';
import FilerType from '../consts/FilerType';
import Role from '../consts/Role';
import Routes from '../consts/Routes';
import { UserSessionActions } from '../redux/actions/UserSessionActions';
import CurrentUser from '../utils/CurrentUser';
import HistoryUtil from '../utils/HistoryUtil';
import RoleUtil from '../utils/RoleUtil';
import HelpButton from './HelpButton';
import {
    AccountBookOutlined,
    GiftOutlined,
    LogoutOutlined,
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    ScheduleOutlined,
    SettingOutlined,
    StopOutlined,
    ToolOutlined,
    UserOutlined,
    WarningTwoTone,
} from '@ant-design/icons';
import { StateStoreModel } from '../redux/state/StateStoreModel';
import FeatureManagementApi from '../api/FeatureManagementApiService';
import FeatureFlags from '../consts/FeatureFlags'; 
import CommitteeType from '../consts/CommitteeType';

const { Header, Sider, Content, Footer } = Layout;
const { SubMenu } = Menu;
const { Text } = Typography;

interface FullscreenLayoutState {
    collapsed: boolean;
    featureFlags: string[];
}

interface FullscreenLayoutProps {
    logoutAction: () => void;
    hasPenalties: boolean;
}

class FullscreenLayout extends React.Component<
    FullscreenLayoutProps & RouteComponentProps<Record<string, string | undefined>>,
    FullscreenLayoutState
> {
    private urls: any = {};

    constructor(props: FullscreenLayoutProps & RouteComponentProps<Record<string, string | undefined>>) {
        super(props);
        this.logoutUser = this.logoutUser.bind(this);

        this.urls.home = Routes.generate(Routes.HOME_ROUTE);
        this.urls.login = Routes.generate(Routes.LOGIN);
        this.urls.certifyDr3 = Routes.generate(Routes.CERTIFY_DR3);
        this.urls.manageOtc = Routes.generate(Routes.MANAGE_OTC);
        this.urls.managePfd = Routes.generate(Routes.MANAGE_PFD);
        this.urls.manageDrsfa = Routes.generate(Routes.MANAGE_DRSFA);
        this.urls.manageIie = Routes.generate(Routes.MANAGE_IIE);
        this.urls.manageOie = Routes.generate(Routes.MANAGE_OIE);
        this.urls.manageDr1 = Routes.generate(Routes.MANAGE_DR1);
        this.urls.manageGb = Routes.generate(Routes.MANAGE_GIFT_BEQUEST);
        this.urls.reviewAccount = Routes.generate(Routes.ADMIN_REVIEWACCOUNT);
        this.urls.sqlReports = Routes.generate(Routes.ADMIN_REPORTS);
        this.urls.organization = Routes.generate(Routes.ORGANIZATIONS_BASE);
        this.urls.filerUsers = Routes.generate(Routes.FILER_MANAGE_USERS);
        this.urls.dr2Summary = Routes.generate(Routes.SCHEDULE_SUMMARY);
        this.urls.dr2Contributions = Routes.generate(Routes.SCHEDULE_A_CONTRIBUTIONS);
        this.urls.dr2Expenditures = Routes.generate(Routes.SCHEDULE_B_EXPENDITURES);
        this.urls.dr2Debts = Routes.generate(Routes.SCHEDULE_D_DEBTS);
        this.urls.dr2InKind = Routes.generate(Routes.SCHEDULE_E_IN_KIND_CONTRIBUTIONS);
        this.urls.dr2LoansReceived = Routes.generate(Routes.SCHEDULE_F1_LOANS_RECEIVED);
        this.urls.dr2LoansRepaid = Routes.generate(Routes.SCHEDULE_F2_LOANS_REPAID);
        this.urls.dr2Consultant = Routes.generate(Routes.SCHEDULE_G_CONSULTANTS);
        this.urls.dr2CampaignProperties = Routes.generate(Routes.SCHEDULE_H1_CAMPAIGN_PROPERTIES);
        this.urls.dr2PropertySales = Routes.generate(Routes.SCHEDULE_H2_PROPERTY_SALES);
        this.urls.dr2Dissolution = Routes.generate(Routes.DISSOLUTION_BASE);
        this.urls.donorSummary = Routes.generate(Routes.DONOR_SUMMARY);
        this.urls.giftBequest = Routes.generate(Routes.GIFT_BEQUEST);
        this.urls.donorProfile = Routes.generate(Routes.DONOR_PROFILE);
        this.urls.periodMain = Routes.generate(Routes.PERIOD_MAIN);
        this.urls.helpPages = Routes.generate(Routes.MANAGE_HELP_PAGES);
        this.urls.adminSearch = Routes.generate(Routes.ADMIN_SEARCH);
        this.urls.users = Routes.generate(Routes.MANAGE_USERS);
        this.urls.internalNotes = Routes.generate(Routes.INTERNAL_NOTES);
        this.urls.contribution = Routes.generate(Routes.VSR_CONTRIBUTIONS);
        this.urls.viewEditContacts = Routes.generate(Routes.CONTACTS_BASE);
        this.urls.addContacts = Routes.generate(Routes.ADD_CONTACT);
        this.urls.selectFiling = Routes.generate(Routes.SELECT_FILING);
        this.urls.manageEscheats = Routes.generate(Routes.MANAGE_ESCHEATS);
        this.urls.manageSalesAndLeases = Routes.generate(Routes.MANAGE_CONSENT_FOR_SALES_AND_LEASES);
        this.urls.reportsSalesAndLeases = Routes.generate(Routes.CONSENT_FOR_SALES_AND_LEASES_REPORTS);
        this.urls.manageSalesByState = Routes.generate(Routes.MANAGE_SALES_BY_STATE);
        this.urls.manageDualComp = Routes.generate(Routes.MANAGE_DUAL_COMP);
        this.urls.reportsSalesAndLeases = Routes.generate(Routes.CONSENT_FOR_SALES_AND_LEASES_REPORTS);
        this.urls.buildingFundsSummary = Routes.generate(Routes.BUILDINGFUNDS_SUMMARY);
        this.urls.managePetitionForWaiver = Routes.generate(Routes.MANAGE_PETITION_FOR_WAIVER);

        this.state = {
            collapsed: false,
            featureFlags: [],
        };
    }

    toggle = () => {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    };

    getFeatureFlags = () => {
        FeatureManagementApi.getEnabledFeatures().then((response) => {
            this.setState({ featureFlags: response});
        }).catch((e) => {
            console.log(e);
        });
    }

    logoutUser = () => {
        this.props.logoutAction();
        HistoryUtil.push('/');
    };

    headerAccountContent = (
        <Menu>
            <Menu.Item>
                <NavLink to={Routes.PROFILE}>
                    <Text>
                        <span>Profile</span>
                    </Text>
                </NavLink>
            </Menu.Item>
            <Menu.Item onClick={this.logoutUser}>
                <Text>Logout</Text>
            </Menu.Item>
        </Menu>
    );

    componentDidMount()
    {
        this.getFeatureFlags();
    }

    render() {
        const currentUser = CurrentUser.Get();
        const auditing = currentUser?.auditing;
        const { featureFlags } = this.state; 
        return (
            <Layout className="layout-fullscreen">
                <Header >
                    <div className="header-logo">
                        <Link to="/">
                            <img src={process.env.PUBLIC_URL + '/iecdb-logo.svg'} alt="IECDB.Portal" />
                        </Link>
                        <Typography.Title level={5} className="header-logo-text">IECDB Web Reporting System</Typography.Title>
                    </div>
                    {currentUser?.committeeId ? this.renderPenaltyBanner() : undefined}
                    <Menu mode="horizontal">
                        {currentUser ?
                            <>
                                <Menu.Item key="user">
                                    <Button type="link">
                                        <UserOutlined /> {currentUser.userName}
                                    </Button>
                                </Menu.Item>
                                <Menu.Item key="logout">
                                    <Button type="link" onClick={this.logoutUser}>
                                        <LogoutOutlined /> Logout
                                    </Button>
                                </Menu.Item>
                            </>
                            : undefined}
                        <HelpButton />
                        <Menu.Item key="menu">
                            <Button type="link" onClick={this.toggle}>
                                {this.state.collapsed ? (
                                    <MenuUnfoldOutlined className="trigger" />
                                ) : (
                                    <MenuFoldOutlined className="trigger" />
                                )}
                            </Button>
                        </Menu.Item>
                    </Menu>
                </Header>
                <Layout>
                    <Sider
                        trigger={null}
                        collapsible={true}
                        collapsed={this.state.collapsed}
                        width={248}
                        collapsedWidth={0}
                        breakpoint="lg"
                        onBreakpoint={(broken) => {
                            this.setState({ collapsed: broken });
                        }}
                    >
                        <Scrollbars autoHide={true} className="scrollbar-sidenav">
                            {currentUser?.committeeName && (
                                <div className="committee-header">
                                    <Row>
                                        <Col flex={1}>
                                            <Typography.Text className="committee-label">Committee</Typography.Text>
                                        </Col>
                                        <Col style={{ marginRight: 12 }}>
                                            {auditing &&
                                                <Tooltip title="Release Committee">
                                                    <StopOutlined className="clickable" shape="circle" onClick={this.releaseCommitteeOnClick} />
                                                </Tooltip>
                                            }
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Typography.Text className="committee">{currentUser.committeeName}</Typography.Text>
                                        </Col>
                                    </Row>
                                </div>
                            )}
                            <Menu
                                mode="inline"
                                className="sider-menu"
                                defaultOpenKeys={['admin', 'reports', 'dr1', 'schedule', 'account', 'donor','buildingFunds', 'contacts']}
                                selectedKeys={[this.getMenuItemKeyFromUrl(HistoryUtil.location.pathname)]}>
                                {RoleUtil.currentUserHasRoles([Role.IECDB_ADMINISTRATOR]) ? (
                                    <SubMenu key="admin" title={<><ToolOutlined />Admin</>}>
                                        {this.menuItemPeriodMain()}
                                        {this.menuItemHelpPages()}
                                        {this.menuItemManageGiftBequests()}
                                        {this.menuItemManagePFD()}
                                        {this.menuItemManageIIE()}
                                        {this.menuItemManageOIE()}
                                        {this.menuItemManageOTC()}
                                        {this.menuItemManageDRSFA()}
                                        {featureFlags.includes(FeatureFlags.Escheats) ? this.menuItemManageEscheats() : undefined}
                                        {featureFlags.includes(FeatureFlags.ManageSalesAndLeases) ? this.menuItemManageSalesAndLeases() : undefined}
                                        {featureFlags.includes(FeatureFlags.DualComp) ? this.menuItemDualComp() : undefined}
                                        {featureFlags.includes(FeatureFlags.SalesByState) ? this.menuItemManageSalesByState() : undefined}
                                        {featureFlags.includes(FeatureFlags.PetitionForWaiver) ? this.menuItemPetitionForWaiver() : undefined }
                                        {this.menuItemCertifyDR3()}
                                        {this.menuItemUsers()}
                                        {this.menuItemDr1OtherUsers()}
                                        {this.menuItemReports()}
                                        {this.menuItemSearch()}
                                        {this.menuItemReviewAccount()}
                                    </SubMenu>
                                ) : undefined}
                                {/* TODO: Hide all if DR1ReviewStatus is Pending */}
                                {RoleUtil.currentUserHasRoles([Role.GIFTBEQUEST_DONOR]) ?
                                    (this.menuSectionDonor()) :
                                    currentUser?.committeeType && (
                                        <>
                                            {this.renderDr1Menu(currentUser.filerTypeId)}
                                            {this.renderSchMenu(currentUser.filerTypeId)}
                                        </>
                                    )}
                                <SubMenu key="account" title={<><SettingOutlined />My Account</>}>
                                    <Menu.Item role="presentation" key="settings">
                                        <NavLink to={Routes.ACCOUNT_BASE}>
                                            <span>Settings</span>
                                        </NavLink>
                                    </Menu.Item>
                                    <Menu.Item role="presentation" key="logout">
                                        <NavLink to="" onClick={this.logoutUser}>
                                            <span>Logout</span>
                                        </NavLink>
                                    </Menu.Item>
                                </SubMenu>
                            </Menu>
                        </Scrollbars>
                    </Sider>
                    <Layout className={'page-content' + (this.state.collapsed ? ' sider-collapsed' : '')}>
                        <Content id="app-main">
                            <UnexpectedError>{this.props.children}</UnexpectedError>
                        </Content>
                        <Footer style={{ textAlign: 'center' }}>
                            Iowa Ethics Campaign Disclosure Board, Copyright &copy;{new Date().getFullYear()}, All rights reserved
                        </Footer>
                    </Layout>
                </Layout>
            </Layout>
        );
    }

    private renderDr1Menu = (filerTypeId: number | null | undefined) => {
        switch (filerTypeId) {
            case FilerType.CANDIDATE:
                return this.menuSectionDr1Candidate();
            case FilerType.PAC:
                return this.menuSectionDr1Pac();
            case FilerType.OSF:
                return this.menuSectionDr1Osf();
            default:
                return undefined;
        }
    }

    private renderSchMenu = (filerTypeId: number | null | undefined) => {
        switch (filerTypeId) {
            case FilerType.CANDIDATE:
                return this.menuSectionSchCandidate();
            case FilerType.PAC:
                return this.menuSectionSchPac();
            case FilerType.OSF:
                return this.menuSectionSchOsf();
            default:
                return undefined;
        }
    }

    private menuSectionDonor = () => {
        return (
            <SubMenu key="donor" title={<><GiftOutlined />Gifts/Bequests</>}>
                {this.menuItemGbSummary()}
                {this.menuItemGbNewGift()}
                {this.menuItemGbProfile()}
            </SubMenu>
        );
    }

    private menuSectionDr1Candidate = () => {
        return (
            <SubMenu key="dr1" title={<><OrganizationIcon />Organization</>}>
                {CurrentUser.Get()?.committeeId && (
                    <>
                        {this.menuItemOrganizationDetails()}
                        {RoleUtil.currentUserHasRoles([Role.IECDB_ADMINISTRATOR]) && (this.menuItemInternalNotes())}
                        {RoleUtil.currentUserHasRoles([Role.IECDB_ADMINISTRATOR]) && (this.menuItemSelectFiling())}
                        {this.menuItemFilerUsers()}
                    </>
                )}
            </SubMenu>
        );
    }

    private menuSectionDr1Pac = () => {
        return (
            <SubMenu key="dr1" title={<><OrganizationIcon />Organization</>}>
                {CurrentUser.Get()?.committeeId && (
                    <>
                        {this.menuItemOrganizationDetails()}
                        {RoleUtil.currentUserHasRoles([Role.IECDB_ADMINISTRATOR]) && (this.menuItemInternalNotes())}
                        {RoleUtil.currentUserHasRoles([Role.IECDB_ADMINISTRATOR]) && (this.menuItemSelectFiling())}
                        {this.menuItemFilerUsers()}
                    </>
                )}
            </SubMenu>
        );
    }

    private menuSectionDr1Osf = () => {
        return (
            <SubMenu key="dr1" title={<><OrganizationIcon />Organization</>}>
                {CurrentUser.Get()?.committeeId && (
                    <>
                        {this.menuItemOrganizationDetails()}
                        {RoleUtil.currentUserHasRoles([Role.IECDB_ADMINISTRATOR]) && (this.menuItemInternalNotes())}
                        {this.menuItemFilerUsers()}
                    </>
                )}
            </SubMenu>
        );
    }

    private menuSectionSchCandidate = () => {
        return (
            <>
                <SubMenu key="schedule" title={<><ScheduleOutlined />Schedules</>}>
                    {this.menuItemDR2Summary()}
                    {CurrentUser.Get()?.dr2Id && (
                        <>
                            {this.menuItemDR2Contributions()}
                            {this.menuItemDR2Expenditures()}
                            {this.menuItemDR2Debt()}
                            {this.menuItemDR2InKind()}
                            {this.menuItemDR2LoansReceived()}
                            {this.menuItemDR2LoansRepaid()}
                            {this.menuItemDR2Consultant()}
                            {this.menuItemDR2Inventory()}
                            {this.menuItemDR2PropertySales()}
                        </>
                    )}
                </SubMenu>
                <SubMenu key="contacts" title={<><UserOutlined />Contacts</>}>
                    {this.menuItemContactsAdd()}
                    {this.menuItemContactsViewEdit()}
                </SubMenu>
            </>
        );
    }

    private menuSectionSchPac = () => {
        return (
            <>
                <SubMenu key="schedule" title={<><ScheduleOutlined />Schedules</>}>
                    {this.menuItemDR2Summary()}
                    {CurrentUser.Get()?.dr2Id && (
                        <>
                            {this.menuItemDR2Contributions()}
                            {this.menuItemDR2Expenditures()}
                            {this.menuItemDR2Debt()}
                            {this.menuItemDR2InKind()}
                            {this.menuItemDR2LoansReceived()}
                            {this.menuItemDR2LoansRepaid()}
                            {this.menuItemDR2Consultant()}
                            {this.menuItemDR2Inventory()}
                            {this.menuItemDR2PropertySales()}
                        </>
                    )}
                </SubMenu>
                {CurrentUser.Get()?.committeeType == CommitteeType.STATE_CENTRAL_COMMITTEE &&
                    this.state.featureFlags.includes(FeatureFlags.BuildingFunds) ? this.menuItemBuildingFund() : undefined}
                <SubMenu key="contacts" title={<><UserOutlined />Contacts</>}>
                    {this.menuItemContactsAdd()}
                    {this.menuItemContactsViewEdit()}
                </SubMenu>
            </>
        );
    }

    private menuSectionSchOsf = () => {
        return (
            <>
                {this.menuItemVsrContributions()}
                {this.menuItemDissolution()}
                <SubMenu key="contacts" title={<><UserOutlined />Contacts</>}>
                    {this.menuItemContactsAdd()}
                    {this.menuItemContactsViewEdit()}
                </SubMenu>
            </>
        );
    }

    private menuItemBuildingFund = () => {
        return (
            <>
                <SubMenu key="buildingFunds" title={<><AccountBookOutlined/>Building Funds</>}>
                    {this.menuItemBuildingFundsSummary()}
                </SubMenu>
            </>
        ); 
    }

    private menuItemCertifyDR3 = () => this.renderMenuItem(this.urls.certifyDr3, 'Certify DR3');
    private menuItemManageOTC = () => this.renderMenuItem(this.urls.manageOtc, 'Manage OTC');
    private menuItemManagePFD = () => this.renderMenuItem(this.urls.managePfd, 'Manage PFD');
    private menuItemManageDRSFA = () => this.renderMenuItem(this.urls.manageDrsfa, 'Manage DRSFA');
    private menuItemManageIIE = () => this.renderMenuItem(this.urls.manageIie, 'Manage IIE');
    private menuItemManageOIE = () => this.renderMenuItem(this.urls.manageOie, 'Manage OIE');
    private menuItemManageGiftBequests = () => this.renderMenuItem(this.urls.manageGb, 'Manage Gift/Bequests');
    private menuItemReviewAccount = () => this.renderMenuItem(this.urls.reviewAccount, 'Review Account');
    private menuItemDr1OtherUsers = () => this.renderMenuItem(this.urls.manageDr1, 'DR-1/Other Users');
    private menuItemPeriodMain = () => this.renderMenuItem(this.urls.periodMain, 'Period Main');
    private menuItemHelpPages = () => this.renderMenuItem(this.urls.helpPages, 'Help Pages');
    private menuItemSearch = () => this.renderMenuItem(this.urls.adminSearch, 'Search');
    private menuItemReports = () => this.renderMenuItem(this.urls.sqlReports, 'Reports');
    private menuItemOrganizationDetails = () => this.renderMenuItem(this.urls.organization, 'Organization Details');
    private menuItemFilerUsers = () => this.renderMenuItem(this.urls.filerUsers, 'Users');
    private menuItemInternalNotes = () => this.renderMenuItem(this.urls.internalNotes, 'Internal Notes');
    private menuItemDR2Summary = () => this.renderMenuItem(this.urls.dr2Summary, 'Summary');
    private menuItemDR2Contributions = () => this.renderMenuItem(this.urls.dr2Contributions, 'Contributions');
    private menuItemDR2Expenditures = () => this.renderMenuItem(this.urls.dr2Expenditures, 'Expenditures');
    private menuItemDR2Debt = () => this.renderMenuItem(this.urls.dr2Debts, 'Debt');
    private menuItemDR2InKind = () => this.renderMenuItem(this.urls.dr2InKind, 'In Kind');
    private menuItemDR2LoansReceived = () => this.renderMenuItem(this.urls.dr2LoansReceived, 'Loans Received');
    private menuItemDR2LoansRepaid = () => this.renderMenuItem(this.urls.dr2LoansRepaid, 'Loans Repaid');
    private menuItemDR2Consultant = () => this.renderMenuItem(this.urls.dr2Consultant, 'Consultant');
    private menuItemDR2Inventory = () => this.renderMenuItem(this.urls.dr2CampaignProperties, 'Inventory');
    private menuItemDR2PropertySales = () => this.renderMenuItem(this.urls.dr2PropertySales, 'Property Sales');
    private menuItemDissolution = () => this.renderDissolutionMenuItem();
    private menuItemContactsAdd = () => this.renderMenuItem(this.urls.addContacts, 'Add');
    private menuItemContactsViewEdit = () => this.renderMenuItem(this.urls.viewEditContacts, 'View/Edit');
    private menuItemVsrContributions = () => this.renderVsrContributionsMenuItem();
    private menuItemGbSummary = () => this.renderMenuItem(this.urls.donorSummary, 'Summary');
    private menuItemGbNewGift = () => this.renderMenuItem(this.urls.giftBequest, 'New Gift/Bequest');
    private menuItemGbProfile = () => this.renderMenuItem(this.urls.donorProfile, 'Profile');
    private menuItemUsers = () => this.renderMenuItem(this.urls.users, 'Users');
    private menuItemSelectFiling = () => this.renderMenuItem(this.urls.selectFiling, 'Select Filing');
    private menuItemManageEscheats = () => this.renderMenuItem(this.urls.manageEscheats, 'Manage Escheats')
    private menuItemManageSalesAndLeases = () => this.renderMenuItem(this.urls.manageSalesAndLeases, 'Manage Sales/Leases')
    private menuItemDualComp = () => this.renderMenuItem(this.urls.manageDualComp, 'Manage Dual Comp')
    private menuItemBuildingFundsSummary = () => this.renderMenuItem(this.urls.buildingFundsSummary, 'Details');
    private menuItemManageSalesByState = () => this.renderMenuItem(this.urls.manageSalesByState, 'Manage Sales by Officials');
    private menuItemPetitionForWaiver = () => this.renderMenuItem(this.urls.managePetitionForWaiver, 'Manage Petition for Waiver')

    private homeRouteNumber = 1;
    private renderMenuItem = (to: string, label: string) => {
        let key = to;
        // Can't have duplicate keys so rename home route keys for now
        if (to === this.urls.home) {
            key = to + (this.homeRouteNumber++);
        }

        return (
            <Menu.Item role="presentation" key={key}>
                    <NavLink to={to}>{label}</NavLink>
            </Menu.Item>
        );
    }

    private renderVsrContributionsMenuItem = () => {
        const to = this.urls.contribution;
        return (
            <Menu.Item role="presentation" key={to} className="menu-item-standalone bold">
                <NavLink to={to}>Expenditures</NavLink>
            </Menu.Item>
        );
    }

    private renderDissolutionMenuItem = () => {
        const to = this.urls.dr2Dissolution;

        return (
            <Menu.Item role="presentation" key={to} className="menu-item-standalone danger">
                <NavLink to={to}>Dissolution</NavLink>
            </Menu.Item>
        );
    }

    private renderPenaltyBanner = () => {
        const { hasPenalties } = this.props;
        const lateReportType = CurrentUser.Get()?.filerTypeId == 4 ? '2' : '1';
        const url = Routes.generate
            (
                Routes.FILTERED_LATE_REPORTS,
                { committeeId: CurrentUser.Get()?.committeeId || '', lateReportTypeId: lateReportType }
            );

        return (
            <>
                {hasPenalties == true ?
                    <div className="header-logo" style={{ marginLeft: '30px', flex: 1 }}>
                        <Typography.Link type="danger" href={url} target="_blank">
                            <WarningTwoTone className="header-icon-mobile" twoToneColor="#EB0004" style={{ fontSize: '24px' }} />
                        </Typography.Link>
                        <Typography.Title level={5} className="header-logo-text" type="danger" style={{ marginTop: '0px' }}>
                            <WarningTwoTone twoToneColor="#EB0004" style={{ fontSize: '24px' }} />
                            You have an unpaid civil penalty!&nbsp;
                            <Typography.Link type="danger" href={url} target="_blank">
                                Click here for more information
                            </Typography.Link>
                        </Typography.Title>
                    </div> : undefined
                }
            </>
        );
    }

    private getMenuItemKeyFromUrl = (url: string) => {
        let key = '';

        for (const property in this.urls) {
            const value = this.urls[property];
            if (url.startsWith(value) && value.length > key.length) {
                key = value;
            }
        }

        return key;
    }

    private releaseCommitteeOnClick = () => {
        CurrentUser.Release();
        CurrentUser.UpdateHasPenaltie(false);
        HistoryUtil.push(Routes.generate(Routes.ADMIN_REVIEWACCOUNT));
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        logoutAction: () => dispatch(UserSessionActions.logout()),
    };
}

function mapStateToProps(state: StateStoreModel) {
    return {
        hasPenalties: state.UserSession.Value?.hasPenalties || false,
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FullscreenLayout));
