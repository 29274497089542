// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import EntitySelectorDTO from './EntitySelectorDTO';

interface AdminConsentForSalesAndLeasesFormDTO extends EntitySelectorDTO { 
  id: number;
  agency: string | null;
  firstName: string | null;
  middleInitial: string | null;
  lastName: string | null;
  saleDate: moment.Moment | string;
  saleAmount: number;
  saleDescription: string | null;
  userFileName: string | null;
  blobFileName: string | null;
  file: any[] | null;
}
const AdminConsentForSalesAndLeasesFormDTO = {
  create: (initValues?: Partial<AdminConsentForSalesAndLeasesFormDTO> | Record<string, unknown> | null) => {
    return Object.assign(EntitySelectorDTO.create(),
    {
      id: 0,
      agency: "",
      firstName: "",
      middleInitial: "",
      lastName: "",
      saleDate: new Date(0).toISOString(),
      saleAmount: 0,
      saleDescription: "",
      userFileName: "",
      blobFileName: "",
      file: [],
    },
    initValues);
  }
};

export default AdminConsentForSalesAndLeasesFormDTO;