import { Col, Layout, Modal, notification, Row } from 'antd';
import * as React from 'react';
import AdminIieApiService from '../../api/AdminIieApiService';
import DataTableColumnUtil from '../../components/shared/DataTable/DataTableColumnUtil';
import AdminIieListDTO from '../../models/AdminIieListDTO';
import DataTable, { DataTableColumnProps, FilterType } from '../shared/DataTable/DataTable';
import DataTableButtonUtil from '../shared/DataTable/DataTableButtonUtil';
import FilingStatusType from '../../consts/FilingStatusType';
import HistoryUtil from '../../utils/HistoryUtil';
import Routes from '../../consts/Routes';

const { Content } = Layout;
const { confirm } = Modal;

interface ManageIieProps {
}

interface ManageIieState {
  tableApproveColumns: DataTableColumnProps<AdminIieListDTO>[];
  tableManageColumns: DataTableColumnProps<AdminIieListDTO>[];
}

class ManageIie extends React.Component<ManageIieProps, ManageIieState> {
  private dataApproveTable: DataTable<AdminIieListDTO> | undefined;
  private dataManageTable: DataTable<AdminIieListDTO> | undefined;

  constructor(props: ManageIieProps) {
    super(props);

    this.state = {
      tableApproveColumns: this.getTableColumns('approve'),
      tableManageColumns: this.getTableColumns('manage'),
    };
  }

  render() {
    const manageActionButtons = [];
    manageActionButtons.push(DataTableButtonUtil.Reset());

    const approveActionButtons = [];
    approveActionButtons.push(DataTableButtonUtil.Reset());

    return (
      <Content className="content-pad">
        <Row>
          <Col xs={24}>
            <DataTable
              ref={(element: any) => (this.dataApproveTable = element)}
              serverSide={true}
              tableProps={{
                rowKey: 'expendId',
                sortDirections: ['ascend', 'descend'],
                locale: {
                  emptyText: 'Currently there are no IIE\'s that need to be approved'
                }
              }}
              globalSearch={true}
              title="Approve IIE"
              buttonBar={manageActionButtons}
              columns={this.state.tableApproveColumns}
              fetchData={{
                fetch: function (tableRequest) { return AdminIieApiService.getIieList(tableRequest, FilingStatusType.FILED); },
                failureMessage: 'Failed to Retrieve Individual Independent Expenditure Filings'
              }}
              styleOptions={{ compact: true, alternatingRowHighlight: true }}
              stateSaving={{
                enabled: true,
                tableUniqueKey: 'adminApproveIie',
                perSession: true,
              }}
            />
          </Col>
        </Row>
        <br />
        <Row>
          <Col xs={24}>
            <DataTable
              ref={(element: any) => (this.dataManageTable = element)}
              serverSide={true}
              tableProps={{
                rowKey: 'expendId',
                sortDirections: ['ascend', 'descend'],
                locale: {
                  emptyText: 'Currently there are no IIE\'s that need to be reviewed'
                }
              }}
              globalSearch={true}
              title="Manage IIE"
              buttonBar={manageActionButtons}
              columns={this.state.tableManageColumns}
              fetchData={{
                fetch: function (tableRequest) {
                  return AdminIieApiService.getIieList(tableRequest, FilingStatusType.CERTIFIED + ',' + FilingStatusType.ADJUSTED);
                },
                failureMessage: 'Failed to Retrieve Individual Independent Expenditure Filings'
              }}
              styleOptions={{ compact: true, alternatingRowHighlight: true }}
              stateSaving={{
                enabled: true,
                tableUniqueKey: 'adminManageIie',
                perSession: true,
              }}
            />
          </Col>

        </Row>
      </Content>
    );
  }

  private getTableColumns(tableName: 'approve' | 'manage') {
    const columns: DataTableColumnProps<AdminIieListDTO>[] = [];

    const columnDate: DataTableColumnProps<AdminIieListDTO> = DataTableColumnUtil.Date('Date', 'date', 100, { defaultSortOrder: 'descend' });
    columns.push(columnDate);

    const columnAmount: DataTableColumnProps<AdminIieListDTO> = DataTableColumnUtil.Currency('Amount', 'amount', 50);
    columns.push(columnAmount);

    const columnIndividual: DataTableColumnProps<AdminIieListDTO> = DataTableColumnUtil.Address('Individual', 'individualName',
      (r) => ({
        name: r.individualName,
        line1: r.individualAddress1,
        line2: r.individualAddress2,
        city: r.individualCity,
        state: r.individualState,
        zip: r.individualZip

      })
    );
    columns.push(columnIndividual);

    const columnCommittee: DataTableColumnProps<AdminIieListDTO> = DataTableColumnUtil.Address('Committee', 'committeeName',
      (r) => ({
        name: r.committeeName,
        line1: r.committeeAddress1,
        line2: r.committeeAddress2,
        city: r.committeeCity,
        state: r.committeeState,
        zip: r.committeeZip
      })
    );
    columns.push(columnCommittee);

    const columnPosition: DataTableColumnProps<AdminIieListDTO> = DataTableColumnUtil.Text('Position', 'position');
    columns.push(columnPosition);

    const columnAdvocates: DataTableColumnProps<AdminIieListDTO> = DataTableColumnUtil.Text('Advocate', 'advocate');
    columns.push(columnAdvocates);

    if (tableName === 'approve') {

      const approveButtons = [
        { text: 'View', onClick: (rowData: AdminIieListDTO) => this.editIie(rowData.expendId || '') },
        { text: 'Approve', onClick: (rowData: AdminIieListDTO) => this.approve(rowData.expendId || '') },
        { text: 'Reject', onClick: (rowData: AdminIieListDTO) => this.confirmReject(rowData.expendId || '', rowData.individualName || '') },
      ];

      const columnButtons: DataTableColumnProps<AdminIieListDTO>
        = DataTableColumnUtil.Buttons('ExpendId', approveButtons, 210);
      columns.push(columnButtons);
    }

    else if (tableName === 'manage') {

      const columnPenalty: DataTableColumnProps<AdminIieListDTO>
        = DataTableColumnUtil.BooleanCheckbox('Penalty', 'penalty', 10, FilterType.BooleanRadio);
      columns.push(columnPenalty);

      const manageButtons = [
        { text: 'Edit', onClick: (rowData: AdminIieListDTO) => this.editIie(rowData.expendId || '') },
        { text: 'Delete', onClick: (rowData: AdminIieListDTO) => this.confirmDelete(rowData.expendId || '', rowData.individualName || '') },
        { text: 'Penalty', onClick: (rowData: AdminIieListDTO) => this.editPenalty(rowData.expendId || '') },
      ];

      const columnButtons: DataTableColumnProps<AdminIieListDTO>
        = DataTableColumnUtil.Buttons('ExpendId', manageButtons, 210);
      columns.push(columnButtons);
    }

    return columns as DataTableColumnProps<AdminIieListDTO>[];
  };

  private confirmDelete = (expendId: string, individualName: string) => {
    confirm({
      title: 'Are you sure you want to delete this IIE?',
      content: 'The following record will be permanently deleted: ' + individualName,
      onOk: () => {
        this.deleteIie(expendId);
      },
    });
  }

  private deleteIie = (expendId: string) => {
    AdminIieApiService.deleteIie(expendId)
      .then(() => {
        if (this.dataManageTable) {
          this.dataManageTable.refresh();
          notification.success({ message: 'IIE was deleted successfully.' });
        }
      }).catch(() => {
        notification.error({ message: 'Failed to delete IIE.' });
      });
  }

  private approve = (expendId: string) => {
    AdminIieApiService.approve(expendId)
      .then(() => {
        if (this.dataApproveTable) {
          this.dataApproveTable.refresh();
        }
        if (this.dataManageTable) {
          this.dataManageTable.refresh();
        }
        notification.success({ message: 'Your changes were saved sucessfully.' });
      }).catch(() => {
        notification.error({
          message: 'Failed to approve filing.'
        });
      });
  }

  private reject = (expendId: string) => {
    AdminIieApiService.reject(expendId)
      .then(() => {
        if (this.dataApproveTable) {
          this.dataApproveTable.refresh();
        }
        notification.success({ message: 'IIE was rejected successfully.' });
      }).catch(() => {
        notification.error({
          message: 'Failed to reject filing.'
        });
      });
  }

  private confirmReject = (expendId: string, individualName: string) => {
    confirm({
      title: 'Are you sure you want to reject this IIE?',
      content: 'The following record will be rejected: ' + individualName,
      onOk: () => {
        this.reject(expendId);
      },
    });
  }

  private editPenalty = async (expendId: string) => {
    HistoryUtil.push(Routes.generate(Routes.IIE_PENALTY, { id: expendId }));
  }

  private editIie = async (expendId: string) => {
    HistoryUtil.push(Routes.generate(Routes.EDIT_IIE, { id: expendId }));
  }
}

export default ManageIie;