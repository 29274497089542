import {
  Button,
  Col,
  ColProps,
  FormInstance,
  Input,
  notification,
  Modal,
  Row,
  Select,
  Space,
  Typography,
  Checkbox
} from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import TextArea from 'antd/lib/input/TextArea';
import AddressForm from '../shared/AddressFormFields';
import * as React from 'react';
import LookupsApiService from '../../api/LookupsApiService';
import SchGConsultantExpensesApiService from '../../api/SchGConsultantExpensesApiService';
import ScheduleApiService from '../../api/ScheduleApiService';
import CodeLookupTableDTO from '../../models/CodeLookupTableDTO';
import SchGConsultantExpensesFormDTO from '../../models/SchGConsultantExpensesFormDTO';
import CommitteeInfoDTO from '../../models/CommitteeInfoDTO';
import FormValidationUtil from '../../utils/FormValidationUtil';
import Dr2GroupTypes from '../../consts/Dr2GroupTypes';
import CurrentUser from '../../utils/CurrentUser';
import HistoryUtil from '../../utils/HistoryUtil';
import Routes from '../../consts/Routes';
import { RouteComponentProps } from 'react-router';
import { Content } from 'antd/lib/layout/layout';
import SchGConsultantExpenseHistoryTable from './SchGConsultantExpenseHistoryTable';
import moment from 'moment';
import PersonSearch from '../shared/PersonSearch';
import BaseScheduleInfoDTO from '../../models/BaseScheduleInfoDTO';
import ScheduleAbbreviationCodes from '../../consts/ScheduleAbbreviationCodes';
import CommitteeAutoSearch from '../shared/CommitteeSearch';
import NotesModal from '../shared/NotesModal';
import NoteEntityParents from '../../consts/NoteEntityParents';
import CurrencyInput from '../shared/CurrencyInput';
import CustomDatePicker from '../shared/CustomDatePicker';
import CustomForm from '../shared/CustomForm';
import OrganizationAutoSearch from '../shared/OrganizationSearch';
import CommitteeType from '../../consts/CommitteeType';

const Option = Select.Option;
const confirm = Modal.confirm;

interface SchGConsultantExpensesFormProps {
  summaryId: string;
  summarySeqNum: string;
  expenditureId: string;
  expenditureSeqNum: string;
}

interface SchGConsultantExpensesFormState {
  relations: CodeLookupTableDTO[];
  currConsultantType: string;
  currComm: CommitteeInfoDTO;
  btnDisable: boolean;
  consultantInfo: SchGConsultantExpensesFormDTO;
  consultantExpenditureId?: string;
  startDt: moment.Moment;
  endDt: moment.Moment;
  loading: boolean;
  trusteeTrustorReq: boolean;
  autoFillPerorg: boolean;
  seqNum: number;
  hideRelationship: boolean;
}

class SchGConsultantExpensesForm extends React.Component<RouteComponentProps<SchGConsultantExpensesFormProps>, SchGConsultantExpensesFormState> {
  private readonly _formRef = React.createRef<FormInstance>();

  constructor(props: RouteComponentProps<SchGConsultantExpensesFormProps>) {
    super(props);
    this.state = {
      relations: [],
      currConsultantType: '',
      currComm: CommitteeInfoDTO.create(),
      btnDisable: false,
      consultantExpenditureId: this.props.match.params.expenditureId,
      consultantInfo: SchGConsultantExpensesFormDTO.create({ consultantType: '', state: 'IA', relationshipCode: '', paymentAmount: null }),
      startDt: moment.utc(),
      endDt: moment.utc(),
      loading: !!this.props.match.params.expenditureId,
      trusteeTrustorReq: false,
      autoFillPerorg: false,
      seqNum: Number(this.props.match.params.expenditureSeqNum) || 0,
      hideRelationship: false,
    };
  }

  componentDidMount() {
    this.loadLookups();
    this.getUserCommittee();
    this.getSchedule();
    if (this.props.match.params.expenditureId) {
      this.getConsultant(this.props.match.params.expenditureId || '', this.props.match.params.expenditureSeqNum || '');
    }
  }

  render() {
    const {
      relations,
      currConsultantType,
      currComm,
      btnDisable,
      consultantInfo,
      consultantExpenditureId,
      startDt,
      endDt,
      loading,
      trusteeTrustorReq,
      autoFillPerorg,
      seqNum,
      hideRelationship
    } = this.state;

    const columnSizingProps: ColProps = { xs: 24, sm: 24, md: 12 };
    const isCandidateCommittee = currComm.filerTypeName == 'Candidate';
    const relationReq = currConsultantType === Dr2GroupTypes.INDIVIDUAL && isCandidateCommittee;

    const consultantTypes = [
      Dr2GroupTypes.INDIVIDUAL,
      Dr2GroupTypes.COMMITTEE,
      Dr2GroupTypes.TRUST,
      Dr2GroupTypes.COMPANYOTHER,
      Dr2GroupTypes.UNITEMIZED
    ];

    return (
      <>
        {!loading &&
          <Content className="content-pad">
            <Typography.Title level={4}>Schedule G - Consultant Expense</Typography.Title>
            <CustomForm formRef={this._formRef} initialValues={consultantInfo} layout="vertical" onFinish={this.handleSave}>
              <Row gutter={24}>
                <Col {...columnSizingProps}>
                  <FormItem name="consultantType" label="Consultant Type"
                    rules={[FormValidationUtil.Required('Consultant Type is required')]} >
                    <Select
                      showSearch
                      optionFilterProp="children"
                      onChange={this.handleConsultantTypeChange}>
                      <Option value="" disabled={true}>-- Select Type --</Option>
                      {consultantTypes.map(ct => (
                        <Option key={ct} value={ct}>{ct}</Option>
                      ))}
                    </Select>
                  </FormItem>
                  <FormItem name="paymentDate" label="Payment Date"
                    rules={[FormValidationUtil.RequiredDate('Payment Date is required'), FormValidationUtil.DateRange(startDt, endDt)]}>
                  <CustomDatePicker onChange={(d) => d?.startOf('day')}/>
                  </FormItem>
                  <FormItem name="paymentAmount" label="Amount"
                    rules={[
                      FormValidationUtil.RequiredNumber('Amount is required'),
                      FormValidationUtil.NotNegativeNumber('Amount must be nonnegative')
                    ]}>
                    <CurrencyInput />
                  </FormItem>
                  <FormItem name="explanation" label="Explanation"
                    rules={[FormValidationUtil.Required('Explanation is required')]}>
                    <TextArea maxLength={2000} />
                  </FormItem>
                </Col>
                <Col {...columnSizingProps}>
                  {currConsultantType === Dr2GroupTypes.INDIVIDUAL && (
                    <>
                      <PersonSearch
                        name={'person'}
                        label={'Search for an Individual'}
                        required={false}
                        committeeId={currComm.id || ''}
                        onSelect={this.getPerson}
                        onChange={this.handleConsultantChange}
                      />
                      <AddressForm allDisabled={autoFillPerorg}
                        firstName={{ name: 'firstName' }}
                        middleInitial={{ name: 'middleInitial' }}
                        lastName={{ name: 'lastName' }}
                        address1={{ name: 'addressLine1' }}
                        address2={{ name: 'addressLine2' }}
                        city={{ name: 'city' }}
                        state={{ name: 'state' }}
                        zip={{ name: 'zip' }}
                        phone={{ name: 'phoneNumber', required: false }}
                      />
                      <FormItem name="relationshipCode" label="Relationship" hidden={hideRelationship}
                        rules={[FormValidationUtil.Required('Relationship is required', relationReq)]}>
                        <Select
                          showSearch disabled={autoFillPerorg}
                          optionFilterProp="children">
                          <Option value="" disabled={true}>-- Select Relationship --</Option>
                          {relations.map(rt => (
                            <Option key={rt.code || ''} value={rt.code || ''}>{rt.name}</Option>
                          ))}
                        </Select>
                      </FormItem>
                    </>
                  )}
                  {currConsultantType === Dr2GroupTypes.COMMITTEE && (
                      <CommitteeAutoSearch
                        name="consultantName"
                        label="Name"
                        required={true}
                        requireSelection={true}
                        onSelectCommittee={this.handleCommitteeSelect}
                    />
                  )}
                  {(currConsultantType === Dr2GroupTypes.COMPANYOTHER || currConsultantType === Dr2GroupTypes.TRUST) && (
                    <>
                      <OrganizationAutoSearch
                        name={'company'}
                        label={'Search for a Company'}
                        required={false}
                        committeeId={currComm.id || ''}
                        perorgType={currConsultantType}
                        onSelect={this.getOrganization}
                        onChange={this.handleConsultantChange}
                      />
                      <AddressForm allDisabled={autoFillPerorg}
                        name={{ name: 'consultantName', label: 'Company' }}
                        address1={{ name: 'addressLine1' }}
                        address2={{ name: 'addressLine2' }}
                        city={{ name: 'city' }}
                        state={{ name: 'state' }}
                        zip={{ name: 'zip' }}
                        phone={{ name: 'phoneNumber', required: false }}
                      />
                    </>
                  )}
                  {currConsultantType === Dr2GroupTypes.TRUST &&
                    <>
                      <FormItem name="isLivingOrRevocableTrust" valuePropName="checked">
                        <Checkbox onChange={this.handleRevocableTrustCheckboxChange}>Living or Revocable Trust</Checkbox>
                      </FormItem>
                      <FormItem name="trustee" label="Trustee"
                        rules={[FormValidationUtil.Required('Trustee is required', trusteeTrustorReq)]}>
                        <Input disabled={autoFillPerorg} />
                      </FormItem>
                      <FormItem name="trustor" label="Trustor"
                        rules={[FormValidationUtil.Required('Trustor is required', trusteeTrustorReq)]}>
                        <Input disabled={autoFillPerorg} />
                      </FormItem>
                    </>
                  }
                </Col>
              </Row>
              <Row>
                <Col {...columnSizingProps}>
                  <FormItem name="consultantAutoFillId" hidden={true}>
                    <Input hidden={true} disabled={true} aria-hidden={true}/>
                  </FormItem>
                </Col>
              </Row>
              <Space>
                <Button type="primary" disabled={btnDisable} htmlType="submit"> Save Expense </Button>
              <Button type="default" disabled={btnDisable} onClick={this.handleCancel}> Cancel </Button>
              {consultantExpenditureId &&
                <NotesModal
                  parentId={consultantExpenditureId}
                  parent={NoteEntityParents.CONSULTANTEXPENDITURE}
                  seqNum={seqNum || 0}
                  auditorsNoteId={consultantInfo.auditorsNoteId || ''} />
              }
              </Space>
            </CustomForm >
            {consultantExpenditureId &&
              <SchGConsultantExpenseHistoryTable
                consultantExpenditureId={consultantExpenditureId || ''}
              />
            }
          </Content>
        }
      </>
    );
  }

  private handleConsultantTypeChange = (consultantType: string) => {
    this.setState({
      currConsultantType: consultantType,
      autoFillPerorg: false,
      trusteeTrustorReq: consultantType == Dr2GroupTypes.TRUST
    });

    this._formRef.current?.setFieldsValue({
      'firstName': '',
      'middleInitial': '',
      'lastName': '',
      'relationshipCode': '',
      'addressLine1': '',
      'addressLine2': '',
      'city': '',
      'state': '',
      'zip': '',
      'phoneNumber': '',
      'trustee': '',
      'trustor': '',
      'person': null,
      'consultantName': null,
      'company': null,
      'isLivingOrRevocableTrust': false,
      'consultantAutoFillId': ''
    });

    if ([Dr2GroupTypes.COMPANYOTHER, Dr2GroupTypes.INDIVIDUAL, Dr2GroupTypes.TRUST].includes(consultantType)) {
      this._formRef.current?.setFieldsValue({ state: 'IA' });
    }
  }

  private handleConsultantChange = (value: string) => {
    this._formRef.current?.setFieldsValue({ consultantAutoFillId: '' });
    this.setState({ autoFillPerorg: !!value });
  }

  private handleCommitteeSelect = (committee: CommitteeInfoDTO | undefined) => {
    this._formRef.current?.setFieldsValue({ consultantAutoFillId: committee?.id });
  }

  private setCompanyFields(form?: BaseScheduleInfoDTO) {
    if (form) {
      this._formRef.current?.setFieldsValue({
        consultantName: form.committeeOrCompanyName,
        addressLine1: form.addressLine1,
        addressLine2: form.addressLine2,
        city: form.city,
        state: form.state,
        zip: form.zip,
        phoneNumber: form.phoneNumber,
        trustee: form.trustee,
        trustor: form.trustor
      });
    }
    else {
      this._formRef.current?.setFieldsValue({
        consultantName: '',
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        zip: '',
        phoneNumber: '',
        trustee: '',
        trustor: '',
        consultantAutoFillId: ''
      });
      this.setState({ autoFillPerorg: false });
    }
  }

  private setIndividualFields(form?: BaseScheduleInfoDTO) {
    if (form) {
      this._formRef.current?.setFieldsValue({
        consultantType: form.groupType,
        firstName: form.firstName,
        middleInitial: form.middleInitial,
        lastName: form.lastName,
        addressLine1: form.addressLine1,
        addressLine2: form.addressLine2,
        city: form.city,
        state: form.state,
        zip: form.zip,
        phoneNumber: form.phoneNumber,
        relationshipCode: form.relationshipCode,
        consultantAutoFillId: '',
      });
    }
    else {
      this._formRef.current?.setFieldsValue({
        consultantType: Dr2GroupTypes.INDIVIDUAL,
        firstName: '',
        middleInitial: '',
        lastName: '',
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        zip: '',
        phoneNumber: '',
        relationshipCode: '',
        consultantAutoFillId: '',
      });
      this.setState({ autoFillPerorg: false });
    }
    this.setState({ currConsultantType: Dr2GroupTypes.INDIVIDUAL });
  }

  private handleRevocableTrustCheckboxChange = (e: any) => {
    this.setState({ trusteeTrustorReq: !e.target.checked });
  }

  private loadLookups = () => {
    this._formRef.current?.setFieldsValue({ summaryId: this.props.match.params.summaryId });

    const relationPromise = LookupsApiService.getRelationTypes();
    Promise.all([relationPromise])
      .then(result => {
        const [relations] = result;
        this.setState({ relations: relations });
      });
  }

  private getSchedule = () => {
    ScheduleApiService.getSchedule(CurrentUser.Get()?.dr2Id || '', ScheduleAbbreviationCodes.SCHEDULEG)
      .then(schedule => {
        this.setState({ startDt: moment.utc(schedule.startDate), endDt: moment.utc(schedule.endDate) });
      });
  }

  private getPerson = (perorgId: string) => {
    if (perorgId != '') {
      ScheduleApiService.getPerson(perorgId, CurrentUser.Get()?.committeeId || '')
        .then(personInfo => {
          if (personInfo) {
            this.setIndividualFields(personInfo);
            this._formRef.current?.setFieldsValue({ consultantAutoFillId: perorgId });
          }
        });
    }
    else {
      this.setIndividualFields();
    }
  }

  private getOrganization = (perorgId: string) => {
    if (perorgId != '') {
      ScheduleApiService.getOrganization(perorgId, CurrentUser.Get()?.committeeId || '')
        .then(orgInfo => {
          if (orgInfo) {
            this.setCompanyFields(orgInfo);
            this._formRef.current?.setFieldsValue({ consultantAutoFillId: perorgId });
          }
        });
    }
    else {
      this.setCompanyFields();
    }
  }

  private getUserCommittee = () => {
    ScheduleApiService.getCommittee(CurrentUser.Get()?.committeeId || '')
      .then(committee => {
        if (committee) {
          const hideRelationshipCodes = [
            CommitteeType.CITY_PAC,
            CommitteeType.COUNTY_PAC,
            CommitteeType.IOWA_PAC,
            CommitteeType.SCHOOL_BOARD_OR_OTHER_POLITICAL_SUBDIVISION_PAC,
            CommitteeType.COUNTY_CENTRAL_COMMITTEE,
            CommitteeType.STATE_CENTRAL_COMMITTEE,
            CommitteeType.LOCAL_BALLOT_ISSUE];
          this.setState({ currComm: { ...committee }, hideRelationship: hideRelationshipCodes.includes(committee.committeeTypeCd || '') });
        }
      });
  }

  private getConsultant = (consultantExpenditureId: string, seqNum: string) => {
    SchGConsultantExpensesApiService.getConsultantExpense(consultantExpenditureId, Number(seqNum))
      .then(consultant => {
        if (consultant) {
          consultant.paymentDate = moment(consultant.paymentDate);

          const consultantInfo: SchGConsultantExpensesFormDTO & { person?: string, company?: string } = consultant;
          if (consultantInfo.firstName && consultantInfo.lastName) {
            if (consultantInfo.middleInitial) {
              consultantInfo.person = consultant.firstName + ' ' + consultant.middleInitial + '. ' + consultant.lastName;
            }
            else {
              consultantInfo.person = consultant.firstName + ' ' + consultant.lastName;
            }
          }
          else if (consultantInfo.consultantType == Dr2GroupTypes.TRUST || consultantInfo.consultantType == Dr2GroupTypes.COMPANYOTHER) {
            if (consultantInfo.consultantName) {
              consultantInfo.company = consultantInfo.consultantName;
            }
          }

          this.setState({
            consultantInfo: consultant,
            currConsultantType: consultant.consultantType || '',
            trusteeTrustorReq: consultant.consultantType == Dr2GroupTypes.TRUST && !consultant.isLivingOrRevocableTrust,
            autoFillPerorg: consultant.consultantType != Dr2GroupTypes.COMMITTEE
          });
          this._formRef.current?.setFieldsValue(consultantInfo);
          this.setState({ loading: false });
        }
        else {
          notification.error({
            message: 'Error while fetching consultant',
            description: 'Not found'
          });
          this.setState({ loading: false });
        }
      }).catch(() => {
        notification.error({
          message: 'Error while fetching consultant',
          description: ''
        });
        this.setState({ loading: false });
      });
  }

  private handleSave = (values: SchGConsultantExpensesFormDTO) => {
    if (values.consultantAutoFillId === '') {
      values.consultantAutoFillId = null;
    }
    this.setState({ btnDisable: true });

      if (!this.state.consultantExpenditureId) {
        SchGConsultantExpensesApiService.add(
          values,
          CurrentUser.Get()?.committeeId || '',
          CurrentUser.Get()?.dr2Id || '',
          this.props.match.params.summaryId
        )
          .then(() => {
            notification.success({
              message: 'Saved Successfully',
              description: ''
            });
            this.expensesTableRedirect();
          }).catch(error => {
            notification.error({
              message: 'Your consultant failed to save',
              description: error.message
            });
            this.expensesTableRedirect();
          });
      }
      else {
        SchGConsultantExpensesApiService.edit(
          values,
          CurrentUser.Get()?.committeeId || '',
          CurrentUser.Get()?.dr2Id || '',
          this.props.match.params.expenditureId || '',
          Number(this.props.match.params.expenditureSeqNum),
          this.props.match.params.summaryId
        )
          .then(() => {
            notification.success({
              message: 'Saved Successfully',
              description: ''
            });
            this.expensesTableRedirect();
          }).catch(() => {
            notification.error({
              message: 'Your consultant failed to save',
              description: ''
            });
            this.expensesTableRedirect();
          });
      }
  }

  private handleCancel = () => {
    confirm({
      title: 'Are you sure you want to leave?',
      okText: 'Yes',
      cancelText: 'No',
      onOk: () => {
        this.expensesTableRedirect();
      }
    });
  }

  private expensesTableRedirect = () => {
    HistoryUtil.push(Routes.generate(Routes.SCHEDULE_G_CONSULTANT_EXPENSES, {
      summaryId: this.props.match.params.summaryId,
      summarySeqNum: this.props.match.params.summarySeqNum
    }));
  }
}

export default SchGConsultantExpensesForm;