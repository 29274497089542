import { Row, Col, notification, Typography, Checkbox } from 'antd';
import * as React from 'react';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

const { Paragraph, Title } = Typography;

interface AffirmPfdProps {
}

interface AffirmPfdState {
  affirm: boolean;
}

class AffirmPfd extends React.Component<AffirmPfdProps, AffirmPfdState>{

  constructor(props: AffirmPfdProps) {
    super(props);

    this.state = {
      affirm: false
    };
  }

  validate = async (): Promise<{ model: boolean, errorFields: any }> => {
    if (this.state.affirm) {
      return {
        model: true, errorFields: null
      };
    } else {
      notification.error({
        message: 'You must affirm the information is accurate.'
      });
      return Promise.reject();
    }
  }

  render() {
    return (
      <>
        <Title level={4} >
          Affirmation
        </Title>
        <Paragraph>
          By clicking the &quot;submit&quot; button below I affirm this statement is complete and accurate to the best of my knowledge.
          I understand I may be subject to civil and criminal penalties for failing to file a complete and accurate statement
          or for failing to file this statement by the required due date.
        </Paragraph>
        <Row>
          <Col xs={24}>
            <Checkbox className="bold-checkbox" checked={this.state.affirm} onChange={this.toggleAffirm}>
              Click here to affirm the information is accurate and complete to the best of your knowledge.
            </Checkbox>
          </Col>
        </Row>
        <br />
      </>
    );
  }

  private toggleAffirm = (checkBox: CheckboxChangeEvent) => {
    this.setState({ affirm: checkBox.target.checked });
  }
}

export default AffirmPfd;