import {
  Button,
    Checkbox,
  Col,
  ColProps,
  FormInstance,
  Input,
  InputNumber,
  Modal,
  notification,
  Row,
  Select,
  Space,
  Spin,
  Typography
} from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import * as React from 'react';
import SchBExpendituresImportApiService from '../../api/SchBExpendituresImportApiService';
import SchBExpendituresImportDTO from '../../models/SchBExpendituresImportDTO';
import SchABImportFileDTO from '../../models/SchABImportFileDTO';
import DataTableColumnUtil from '../shared/DataTable/DataTableColumnUtil';
import DataTable, { DataTableColumnProps, FilterType } from '../shared/DataTable/DataTable';
import CurrentUser from '../../utils/CurrentUser';
import { Content } from 'antd/lib/layout/layout';
import FileUploadUtil from '../../utils/FileUploadUtil';
import FileUpload from '../shared/FileUpload';
import SchBExpendituresImportFormDTO from '../../models/SchBExpendituresImportFormDTO';
import moment from 'moment';
import CodeLookupTableDTO from '../../models/CodeLookupTableDTO';
import BaseScheduleInfoDTO from '../../models/BaseScheduleInfoDTO';
import CommitteeInfoDTO from '../../models/CommitteeInfoDTO';
import FormValidationUtil from '../../utils/FormValidationUtil';
import Dr2GroupTypes from '../../consts/Dr2GroupTypes';
import ScheduleApiService from '../../api/ScheduleApiService';
import ScheduleAbbreviationCodes from '../../consts/ScheduleAbbreviationCodes';
import LookupsApiService from '../../api/LookupsApiService';
import AddressForm from '../shared/AddressFormFields';
import CommitteeAutoSearch from '../shared/CommitteeSearch';
import PersonSearch from '../shared/PersonSearch';
import TextArea from 'antd/lib/input/TextArea';
import HistoryUtil from '../../utils/HistoryUtil';
import Routes from '../../consts/Routes';
import ExpenditureType from '../../consts/ExpenditureType';
import ExpenditureTypeDTO from '../../models/ExpenditureTypeDTO';
import CurrencyInput from '../shared/CurrencyInput';
import CustomDatePicker from '../shared/CustomDatePicker';
import CustomForm from '../shared/CustomForm';
import OrganizationAutoSearch from '../shared/OrganizationSearch';

const Option = Select.Option;
const { confirm } = Modal;
const { Link } = Typography;

interface SchBExpendituresImportProps {

}

interface SchBExpendituresImportState {
  tableColumns: DataTableColumnProps<SchBExpendituresImportDTO>[];
  batchNumber: string | null;
  transNumber: string | null;
  userId: number;

  // Modal Stuff
  relations: CodeLookupTableDTO[];
  candidate: BaseScheduleInfoDTO;
  expenditureInfo: SchBExpendituresImportFormDTO;
  fullExpenseTypeList: ExpenditureTypeDTO[];
  expenseTypes: ExpenditureTypeDTO[];
  currPayeeType: string;
  currExpenseType: string;
  currComm: CommitteeInfoDTO;
  payeeAutoFillId: string;
  unitemizedAffirmationChecked: boolean;
  btnDisable: boolean;
  startDt: moment.Moment;
  endDt: moment.Moment;
  total: number;

  // Booleans
  hasImportedData: boolean;
  showModal: boolean;
  pageLoading: boolean;
  tableLoading: boolean;
  expendLoading: boolean;
  saving: boolean;
  autoFillPerorg: boolean;
  trusteeTrustorReq: boolean;
}

class SchBExpendituresImport extends React.Component<SchBExpendituresImportProps, SchBExpendituresImportState> {
  private readonly _importFormRef = React.createRef<FormInstance>();
  private readonly _modalFormRef = React.createRef<FormInstance>();
  private dataTable: DataTable<SchBExpendituresImport> | undefined;

  constructor(props: SchBExpendituresImportProps) {
    super(props);
    this.state = {
      tableColumns: this.getTableColumns(),
      batchNumber: null,
      transNumber: null,
      userId: CurrentUser.Get()?.userId || 0,

      // Modal Stuff
      relations: [],
      candidate: BaseScheduleInfoDTO.create(),
      expenditureInfo: SchBExpendituresImportFormDTO.create({ expenditureType: '', state: '', relationship: '', amount: null }),
      fullExpenseTypeList: [],
      expenseTypes: [],
      currPayeeType: '',
      currExpenseType: '',
      currComm: CommitteeInfoDTO.create(),
      payeeAutoFillId: '',
      unitemizedAffirmationChecked: false,
      btnDisable: false,
      startDt: moment.utc(),
      endDt: moment.utc(),
      total: 0,

      // Booleans
      hasImportedData: false,
      showModal: false,
      pageLoading: false,
      tableLoading: false,
      expendLoading: false,
      saving: false,
      autoFillPerorg: false,
      trusteeTrustorReq: false
    };
  }

  componentDidMount() {
    this.loadLookups();
    this.getCandidate();
    this.getUserCommittee();
    this.getSchedule();
  }

  render() {
    const { batchNumber, hasImportedData, pageLoading, tableLoading, expendLoading,
      saving, autoFillPerorg, trusteeTrustorReq } = this.state;
    const columnSizingProps: ColProps = { xs: 24, sm: 24, md: 12 };
    const currUser = CurrentUser.Get();
    const showTable = hasImportedData && !tableLoading && !saving;

    // Modal variables
    const { relations, expenditureInfo, expenseTypes, currPayeeType,
      currExpenseType, currComm, startDt, endDt } = this.state;

    const checkReq = currPayeeType === Dr2GroupTypes.COMMITTEE;
    const relationReq = currPayeeType === Dr2GroupTypes.INDIVIDUAL;
    const typeTooltip = expenseTypes.find(e => e.code === currExpenseType);

    const payeeTypes = [
      Dr2GroupTypes.INDIVIDUAL,
      Dr2GroupTypes.COMMITTEE,
      Dr2GroupTypes.TRUST,
      Dr2GroupTypes.COMPANYOTHER,
    ];

    const hasInvalidDate = !expenditureInfo.date && expenditureInfo.dateString;
    const hasInvalidCommitteeName = !expenditureInfo.committeeName && expenditureInfo.invalidCommitteeName;
    const hasInvalidPhoneNumber = !expenditureInfo.contactPrimaryPhone
      && expenditureInfo.contactPhoneString;
    const explantionReqCodeArr: string[] = [];
    expenseTypes.forEach(e => {
      if (e.explanationRequired) {
        explantionReqCodeArr.push(e.code || '');
      }
    });
    const explanationReq = (currExpenseType && explantionReqCodeArr.indexOf(currExpenseType || '') >= 0);

    return (
      <>
        {!pageLoading &&
          <Content className="content-pad">
            <Spin size="large" spinning={tableLoading || saving}>
              {!hasImportedData && !tableLoading &&
                <>
                  <Typography.Title level={4}>Schedule B - Expenditure Import</Typography.Title>
                  <CustomForm formRef={this._importFormRef} initialValues={expenditureInfo} layout="vertical">
                    <Row gutter={24}>
                      <Col {...columnSizingProps}>
                        <Typography.Title level={5}>
                          This page allows you to upload a file (must be in XML or CSV format) containing schedule transactions.
                        </Typography.Title>
                        <FormItem name="file" label="Name/Path of File">
                          <FileUpload accept=".xml,.csv" maxCount={1} />
                        </FormItem>
                      </Col>
                    </Row>
                    <Row gutter={24}>
                      <Col {...columnSizingProps}>
                        <Space size="middle">
                          <Button onClick={this.import}>Import</Button>
                          <Link onClick={this.downloadTemplate}>Download Template</Link>
                        </Space>
                      </Col>
                    </Row>
                    <br />
                  </CustomForm>
                </>
              }
              {showTable &&
                <>
                  <DataTable
                    columns={this.state.tableColumns}
                    fetchData={{
                      fetch: function (tableRequest) {
                        return SchBExpendituresImportApiService.getStagingExpenditures(
                          tableRequest,
                          batchNumber || '',
                          currUser?.userId || 0
                        );
                      },
                      failureMessage: 'Failed to retrieve staging expenditures'
                    }}
                    globalSearch={true}
                    ref={(element: any) => (this.dataTable = element)}
                    serverSide={true}
                    styleOptions={{ compact: true }}
                    tableProps={{
                      rowKey: 'expenditureIdAndSeq',
                      sortDirections: ['ascend', 'descend'],
                      locale: { emptyText: 'Currently there are no Expenditures to manage.' }
                    }}
                    title="Schedule B - Expenditure Import"
              />
              <Space>
                    <Button type="primary" onClick={this.confirmSaveExpenditures}> Save </Button>
                  <Button type="default" onClick={this.cancelImportingExpenditures}> Cancel </Button>
                  <Content className="total">
                  Total: ${this.state.total}
                </Content>
                  </Space>
                </>
              }
              {!expendLoading &&
                <Modal
                  title="Schedule B - Expenditures"
                  visible={this.state.showModal}
                  onOk={this.saveStagingExpenditure}
                  onCancel={this.cancelStagingExpenditureEdit}
                  okText="Save"
                  cancelText="Cancel"
                  width={1000}
                  destroyOnClose={true}
                >
                  <>
                    <CustomForm
                      formRef={this._modalFormRef}
                      initialValues={expenditureInfo}
                      layout="vertical"
                      onFinish={this.confirmSaveExpenditures}>
                      <Row gutter={24}>
                        <Col {...columnSizingProps}>
                          <FormItem name="contactType" label="Payee Type"
                            rules={[FormValidationUtil.Required('Payee Type is required')]} >
                            <Select
                              showSearch
                              optionFilterProp="children"
                              onChange={this.handlePayeeTypeChange}>
                              <Option value="" disabled={true}>-- Select Type --</Option>
                              {payeeTypes.map(pt => (
                                <Option key={pt} value={pt}>{pt}</Option>
                              ))}
                            </Select>
                          </FormItem>
                          <FormItem name="date" label="Date"
                            rules={[FormValidationUtil.RequiredDate('Date is required'), FormValidationUtil.DateRange(startDt, endDt)]}>
                          <CustomDatePicker onChange={(d) => d?.startOf('day')}/>
                          </FormItem>
                          {hasInvalidDate &&
                            <label>Invalid date: {expenditureInfo.dateString}</label>
                          }
                          <FormItem name="expenseType" label="Expense Type"
                            rules={[FormValidationUtil.Required('Expense Type is required')]}
                            tooltip={typeTooltip ? typeTooltip.explanationDescription : 'Select an expense type'}
                          >
                            <Select
                              showSearch
                              optionFilterProp="children"
                              onSelect={this.handleExpenseTypeChange}
                            >
                              <Option value="" disabled={true}>-- Select Type --</Option>
                              {expenseTypes.map(et => (
                                <Option key={et.code || ''} value={et.code || ''}>{et.name}</Option>
                              ))}
                            </Select>
                          </FormItem>
                          {currExpenseType === ExpenditureType.MILEAGE && (
                            <>
                              <FormItem name="numMiles" label="Number of Miles"
                                rules={[
                                  FormValidationUtil.RequiredNumber('Number of Miles is required')
                                ]}>
                                <InputNumber
                                  style={{ width: '100%' }}
                                  precision={0}
                                  max={1000000}
                                  min={0}
                                  onChange={this.handleNumberOfMilesChange}
                                />
                              </FormItem>
                              <FormItem
                                name="mileageRate"
                                label="Mileage Rate"
                                rules={[
                                  FormValidationUtil.RequiredNumber('Mileage Rate is required')
                                ]}>
                                <InputNumber
                                  style={{ width: '100%' }}
                                  precision={3}
                                  defaultValue={.000}
                                  step={.05}
                                  max={1000}
                                  min={0}
                                  onChange={this.handleMileageRateChange}
                                />
                              </FormItem>
                            </>
                          )}
                          <FormItem name="amount" label="Amount"
                            rules={[FormValidationUtil.RequiredNumber('Amount is required')]}>
                            <CurrencyInput min={Number.MIN_SAFE_INTEGER} readOnly={currExpenseType === ExpenditureType.MILEAGE} />
                          </FormItem>
                          <FormItem name="checkNumber" label="Check Number"
                            rules={[
                              FormValidationUtil.Number('Number is invalid'),
                              FormValidationUtil.Required('Check Number is required', checkReq)
                            ]}>
                            <Input maxLength={12} />
                          </FormItem>
                      <FormItem name="explanation" label="Explanation"
                        rules={[FormValidationUtil.Required(
                          'Explanation is required',
                          explanationReq || currExpenseType === ExpenditureType.MISCELLANEOUS_OR_UNITEMIZED
                        )]}>
                            <TextArea
                              placeholder={currExpenseType === ExpenditureType.MILEAGE ? 'Please enter the date range for mileage incurred' : ''}
                              maxLength={2000}
                            />
                          </FormItem>
                        </Col>
                        <Col {...columnSizingProps}>
                          {currPayeeType === Dr2GroupTypes.INDIVIDUAL && (
                            <>
                              <PersonSearch
                                name={'person'}
                                label={'Search for an Individual'}
                                required={false}
                                committeeId={currComm.id || ''}
                                onSelect={this.getPerson}
                                onChange={this.handlePayeeChange}
                              />
                              <AddressForm allDisabled={autoFillPerorg}
                                firstName={{ name: 'contactFirstName' }}
                                middleInitial={{ name: 'contactMiddleInitial' }}
                                lastName={{ name: 'contactLastName' }}
                                address1={{ name: 'contactAddress1' }}
                                address2={{ name: 'contactAddress2' }}
                                city={{ name: 'contactCity' }}
                                state={{ name: 'contactState' }}
                                zip={{ name: 'contactZip' }}
                                phone={{ name: 'contactPrimaryPhone', required: false }}
                              />
                              {hasInvalidPhoneNumber &&
                                <label>Invalid phone number: {expenditureInfo.contactPhoneString}</label>
                              }
                              <FormItem name="contactRelationship" label="Relationship"
                                rules={[FormValidationUtil.Required('Relationship is required', relationReq)]}>
                                <Select
                                  showSearch disabled={autoFillPerorg}
                                  optionFilterProp="children">
                                  <Option value="" disabled={true}>-- Select Relationship --</Option>
                                  {relations.map(rt => (
                                    <Option key={rt.code || ''} value={rt.code || ''}>{rt.name}</Option>
                                  ))}
                                </Select>
                              </FormItem>
                            </>
                          )}
                          {currPayeeType === Dr2GroupTypes.COMMITTEE && (
                            <>
                              <CommitteeAutoSearch
                                name="committeeName"
                                label="Name"
                                required={true}
                                requireSelection={true}
                                onSelectCommittee={this.handleCommitteeSelect}
                              />
                              {hasInvalidCommitteeName &&
                                <label>Invalid Committee Name: {expenditureInfo.invalidCommitteeName}</label>
                              }
                              <FormItem name="committeeCode" hidden={true}>
                                <Input hidden={true} disabled={true} />
                              </FormItem>
                              <FormItem name="contactAddress1" hidden={true}>
                                <Input hidden={true} disabled={true} />
                              </FormItem>
                              <FormItem name="contactAddress2" hidden={true}>
                                <Input hidden={true} disabled={true} />
                              </FormItem>
                              <FormItem name="contactCity" hidden={true}>
                                <Input hidden={true} disabled={true} />
                              </FormItem>
                              <FormItem name="contactState" hidden={true}>
                                <Input hidden={true} disabled={true} />
                              </FormItem>
                              <FormItem name="contactZip" hidden={true}>
                                <Input hidden={true} disabled={true} />
                              </FormItem>
                            </>
                          )}
                          {(currPayeeType === Dr2GroupTypes.COMPANYOTHER || currPayeeType === Dr2GroupTypes.TRUST) && (
                            <>
                              <OrganizationAutoSearch
                                name={'company'}
                                label={'Search for a Company'}
                                required={false}
                                committeeId={currComm.id || ''}
                                perorgType={currPayeeType}
                                onSelect={this.getOrganization}
                                onChange={this.handlePayeeChange}
                              />
                              <AddressForm allDisabled={autoFillPerorg}
                                name={{ name: 'contactName', label: 'Company' }}
                                address1={{ name: 'contactAddress1' }}
                                address2={{ name: 'contactAddress2' }}
                                city={{ name: 'contactCity' }}
                                state={{ name: 'contactState' }}
                                zip={{ name: 'contactZip' }}
                                phone={{ name: 'contactPrimaryPhone', required: false }}
                              />
                              {hasInvalidPhoneNumber &&
                                <label>Invalid phone number: {expenditureInfo.contactPhoneString}</label>
                              }
                            </>
                          )}
                          {currPayeeType === Dr2GroupTypes.TRUST &&
                            <>
                              <FormItem name="isLivingOrRevocableTrust" valuePropName="checked">
                                <Checkbox onChange={this.handleRevocableTrustCheckboxChange}>Living or Revocable Trust</Checkbox>
                              </FormItem>
                              <FormItem name="trustee" label="Trustee"
                                rules={[FormValidationUtil.Required('Trustee is required', trusteeTrustorReq)]}>
                                <Input disabled={autoFillPerorg} />
                              </FormItem>
                              <FormItem name="trustor" label="Trustor"
                                rules={[FormValidationUtil.Required('Trustor is required', trusteeTrustorReq)]}>
                                <Input disabled={autoFillPerorg} />
                              </FormItem>
                            </>
                          }
                        </Col>
                      </Row>
                      <Row>
                        <Col {...columnSizingProps}>
                          <FormItem name="payeeAutoFillId" hidden={true}>
                            <Input hidden={true} disabled={true} aria-hidden={true}/>
                          </FormItem>
                        </Col>
                      </Row>
                    </CustomForm>
                  </>
                </Modal>
              }
            </Spin>
          </Content>
        }
      </>
    );
  }

  private getTableColumns = (): DataTableColumnProps<SchBExpendituresImportDTO>[] => {

    const tableColumns: DataTableColumnProps<SchBExpendituresImportDTO>[] = [
      DataTableColumnUtil.Text('Date', 'expenditureDt', 100, { filterType: FilterType.NONE, sorter: false }),
      DataTableColumnUtil.Address('Expenditure', 'expenditureName',
        (c) => ({
          name: c.expenditureName,
          line1: c.expenditureAddressLine1,
          line2: c.expenditureAddressLine2,
          city: c.expenditureCity,
          state: c.expenditureState,
          zip: c.expenditureZip
        }),
        null,
        { sorter: false }
      ),
      DataTableColumnUtil.Text('Amount', 'expenditureAmt'),
      DataTableColumnUtil.Text('Check #', 'checkNumber', 125, { align: 'center' }),
      DataTableColumnUtil.BooleanCheckbox('Contains Error(s)', 'hasError', 125, FilterType.BooleanRadio, { defaultSortOrder: 'descend' }),
      DataTableColumnUtil.Buttons('expenditureIdAndSeq',
        [
          {
            text: 'Edit',
            onClick: (rowData) => this.editExpenditure(rowData)
          },
          {
            text: 'Delete',
            onClick: (rowData) => this.confirmDelete(rowData)
          }
        ],
        150)
    ];

    return tableColumns;
  }

  // Data Retrieval
  private handlePayeeTypeChange = (payeeType: string) => {
    this.setState({
      currPayeeType: payeeType,
      autoFillPerorg: false,
      trusteeTrustorReq: payeeType == Dr2GroupTypes.TRUST
    });

    this._modalFormRef.current?.setFieldsValue({
      'contactFirstName': '',
      'contactMiddleInitial': '',
      'contactLastName': '',
      'contactRelationship': '',
      'contactAddress1': '',
      'contactAddress2': '',
      'contactCity': '',
      'contactState': '',
      'contactZip': '',
      'contactPrimaryPhone': '',
      'trustee': '',
      'trustor': '',
      'person': null,
      'committeeName': null,
      'contactName': null,
      'company': null,
      'isLivingOrRevocableTrust': false,
      'payeeAutoFillId': ''
    });
  }

  private handlePayeeChange = (value: string) => {
    this._modalFormRef.current?.setFieldsValue({ payeeAutoFillId: '' });
    this.setState({ autoFillPerorg: !!value });
  }

  private handleCommitteeSelect = (committee: CommitteeInfoDTO | undefined) => {
    this._modalFormRef.current?.setFieldsValue({ payeeAutoFillId: committee?.id });
  }

  private handleExpenseTypeChange = (expenseType: string) => {
    this.setState({ currExpenseType: expenseType });

    // Set the fields if the expense type is mileage
    if (expenseType == '015') {
      this._modalFormRef.current?.setFieldsValue({ numMiles: 0, mileageRate: 0.00, amount: 0 });
    }
  }

  private setCompanyFields(form?: BaseScheduleInfoDTO) {
    if (form) {
      this._modalFormRef.current?.setFieldsValue({
        contactName: form.committeeOrCompanyName,
        contactAddress1: form.addressLine1,
        contactAddress2: form.addressLine2,
        contactCity: form.city,
        contactState: form.state,
        contactZip: form.zip,
        contactPrimaryPhone: form.phoneNumber,
        trustee: form.trustee,
        trustor: form.trustor
      });
    }
    else {
      this._modalFormRef.current?.setFieldsValue({
        contactName: '',
        contactAddress1: '',
        contactAddress2: '',
        contactCity: '',
        contactState: '',
        contactZip: '',
        contactPrimaryPhone: '',
        trustee: '',
        trustor: '',
        payeeAutoFillId: ''
      });
      this.setState({ autoFillPerorg: false });
    }
  }

  private setIndividualFields(form?: BaseScheduleInfoDTO) {
    if (form) {
      this._modalFormRef.current?.setFieldsValue({
        contactType: form.groupType,
        contactFirstName: form.firstName,
        contactMiddleInitial: form.middleInitial,
        contactLastName: form.lastName,
        contactAddress1: form.addressLine1,
        contactAddress2: form.addressLine2,
        contactCity: form.city,
        contactState: form.state,
        contactZip: form.zip,
        contactPrimaryPhone: form.phoneNumber,
        contactRelationship: form.relationshipCode,
        payeeAutoFillId: ''
      });
    }
    else {
      this._modalFormRef.current?.setFieldsValue({
        contactType: Dr2GroupTypes.INDIVIDUAL,
        contactFirstName: '',
        contactMiddleInitial: '',
        contactLastName: '',
        contactAddress1: '',
        contactAddress2: '',
        contactCity: '',
        contactState: '',
        contactZip: '',
        contactPrimaryPhone: '',
        contactRelationship: '',
        payeeAutoFillId: ''
      });
      this.setState({ autoFillPerorg: false });
    }
    this.setState({ currPayeeType: Dr2GroupTypes.INDIVIDUAL });
  }

  private handleRevocableTrustCheckboxChange = (e: any) => {
    this.setState({ trusteeTrustorReq: !e.target.checked });
  }

  private handleMileageRateChange = (mileageRate: number) => {
    const numMiles = this._modalFormRef.current?.getFieldValue('numMiles');
    const amt = (numMiles * mileageRate).toFixed(2);
    this._modalFormRef.current?.setFieldsValue({
      amount: amt
    });
  }

  private handleNumberOfMilesChange = (numMiles: number) => {
    const mileageRate = this._modalFormRef.current?.getFieldValue('mileageRate');
    const amt = (numMiles * mileageRate).toFixed(2);
    this._modalFormRef.current?.setFieldsValue({
      amount: amt
    });
  }

  private loadLookups = () => {
    const relationPromise = LookupsApiService.getRelationTypes();
    const expTypesPromise = LookupsApiService.getExpenditureTypes();
    Promise.all([relationPromise, expTypesPromise])
      .then(result => {
        const relations = result[0];
        const expenseTypes = result[1];
        this.setState({ relations: relations, fullExpenseTypeList: expenseTypes });
      });
  }

  private getSchedule = () => {
    ScheduleApiService.getSchedule(CurrentUser.Get()?.dr2Id || '', ScheduleAbbreviationCodes.SCHEDULEA)
      .then(schedule => {
        this.setState({ startDt: moment.utc(schedule.startDate), endDt: moment.utc(schedule.endDate) });
      });
  }

  private getCandidate = () => {
    ScheduleApiService.getCandidate(CurrentUser.Get()?.committeeId || '')
      .then(personInfo => {
        if (personInfo.groupType == null) {
          return;
        }

        this.setState({ candidate: personInfo });
      });
  }

  private getPerson = (perorgId: string) => {
    if (perorgId != '') {
      ScheduleApiService.getPerson(perorgId, CurrentUser.Get()?.committeeId || '')
        .then(personInfo => {
          if (personInfo) {
            this.setIndividualFields(personInfo);
            this._modalFormRef.current?.setFieldsValue({ payeeAutoFillId: perorgId });
          }
        });
    }
    else {
      this.setIndividualFields();
    }
  }

  private getOrganization = (perorgId: string) => {
    if (perorgId != '') {
      ScheduleApiService.getOrganization(perorgId, CurrentUser.Get()?.committeeId || '')
        .then(orgInfo => {
          if (orgInfo) {
            this.setCompanyFields(orgInfo);
            this._modalFormRef.current?.setFieldsValue({ payeeAutoFillId: perorgId });
          }
        });
    }
    else {
      this.setCompanyFields();
    }
  }

  private getUserCommittee = () => {
    ScheduleApiService.getCommittee(CurrentUser.Get()?.committeeId || '')
      .then(committee => {
        if (committee) {
          this.setState({ currComm: { ...committee } });
        }
      });
  }

  // Button methods
  private getExpenditure = (batchNumber: string, transNumber: number, userId: number) => {
    this.setState({ expendLoading: true });

    SchBExpendituresImportApiService.getStagingExpenditure(batchNumber, transNumber, userId)
      .then(stagingExpend => {
        if (stagingExpend) {
          if (stagingExpend.date != null) {
            stagingExpend.date = moment(stagingExpend.date);
          }

          const expenditureInfo: SchBExpendituresImportFormDTO & { person?: string, company?: string } = stagingExpend;
          if (expenditureInfo.contactFirstName && expenditureInfo.contactLastName) {
            if (expenditureInfo.contactMiddleInitial) {
              expenditureInfo.person = stagingExpend.contactFirstName + ' '
                + stagingExpend.contactMiddleInitial + '. ' + stagingExpend.contactLastName;
            }
            else {
              expenditureInfo.person = stagingExpend.contactFirstName + ' ' + stagingExpend.contactLastName;
            }
          }
          else if (expenditureInfo.contactType == Dr2GroupTypes.TRUST || expenditureInfo.contactType == Dr2GroupTypes.COMPANYOTHER) {
            if (expenditureInfo.contactName) {
              expenditureInfo.company = expenditureInfo.contactName;
            }
          }

          let expenseTypes = this.state.fullExpenseTypeList.filter(et => et.code != ExpenditureType.REVERSE_TRANSACTION);
          if (!stagingExpend || stagingExpend.expenseType !== ExpenditureType.MISCELLANEOUS_OR_UNITEMIZED) {
            expenseTypes = expenseTypes.filter(et => et.code != ExpenditureType.MISCELLANEOUS_OR_UNITEMIZED);
          }

          this.setState({
            expenditureInfo: stagingExpend,
            expenseTypes: expenseTypes,
            currPayeeType: stagingExpend.contactType || '',
            currExpenseType: stagingExpend.expenseType || '',
            trusteeTrustorReq: stagingExpend.contactType == Dr2GroupTypes.TRUST && !stagingExpend.isLivingOrRevocableTrust,
            autoFillPerorg: stagingExpend.contactType != Dr2GroupTypes.COMMITTEE
          }, () => {
            this._modalFormRef.current?.setFieldsValue(this.state.expenditureInfo);
            this.setState({ expendLoading: false, showModal: true });
          });
        }
        else {
          notification.error({
            message: 'Error while fetching expenditure',
            description: 'Not found'
          });
          this.setState({ expendLoading: false });
        }
      }).catch(() => {
        notification.error({
          message: 'Error while fetching expenditure',
          description: ''
        });
        this.setState({ expendLoading: false });
      });
  }

  private editExpenditure = (rowData: SchBExpendituresImportDTO) => {
    this.setState({
      batchNumber: rowData.batchNumber || '',
      transNumber: rowData.transactionNumber.toString() || '',
      userId: rowData.userID || 0,
      showModal: true
    }, () => {
      if (this.state.batchNumber) {
        this.getExpenditure(this.state.batchNumber || '', Number(this.state.transNumber) || 0, this.state.userId || 0);
      }
    });
  }

  private confirmDelete = (rowData: SchBExpendituresImportDTO) => {
    confirm({
      title: 'Are you sure you want to delete this expenditure?',
      content: 'The following record will be permanently deleted: ' + rowData.expenditureName + ' - $' + rowData.expenditureAmt,
      onOk: () => {
        this.deleteExpenditure(rowData);
      },
    });
  }

  private deleteExpenditure = (rowData: SchBExpendituresImportDTO) => {
    if (rowData.batchNumber != null && rowData.userID != null) {
      SchBExpendituresImportApiService.deleteStagingExpenditure(rowData.batchNumber, rowData.transactionNumber, rowData.userID)
        .then(() => {
          if (this.dataTable) {
            this.dataTable.refresh();
          }
        }).catch(() => {
          notification.error({
            message: 'Error while deleting expenditure',
            description: '',
          });
        });
    }
  }

  private downloadTemplate = (): void => {
    SchBExpendituresImportApiService.downloadTemplate()
      .catch((error) => {
        notification.error({
          message: 'Template Download Failed',
          description: error.message
        });
      });
  }

  private import = (): void => {
    const files = this._importFormRef.current?.getFieldValue('file')?.newFiles;
    if (!files) {
      notification.info({
        message: 'Please select a file'
      });
      return;
    }

    const fileDTO = SchABImportFileDTO.create();
    const request = FileUploadUtil.attatchFileToModel('request', fileDTO, 'file', files[0]);

    const currentUser = CurrentUser.Get();
    if (currentUser != null) {
      this.setState({ tableLoading: true });

      SchBExpendituresImportApiService.import(request, currentUser?.committeeId || '', currentUser?.dr2Id || '')
        .then(batchNumber => {
          if (batchNumber.errorMessage) {
            notification.error({
              message: 'The Import File Contained an Error',
              description: batchNumber.errorMessage
            });
          }
          else {
            this.setState({ batchNumber: batchNumber.batchNumber || '', hasImportedData: true }, () => {
              if (this.dataTable) {
                this.dataTable.refresh();
              }
              this.getImportedSum();
            });
          }
        })
        .catch((error) => {
          notification.error({
            message: 'Import Failed',
            description: error.message
          });
        })
        .finally(() => {
          this.setState({ tableLoading: false });
        });
    }
  }

  private saveStagingExpenditure = () => {
    if (this._modalFormRef.current) {

      this._modalFormRef.current.validateFields().then(values => {

        this.setState({ saving: true });

        if (values.payeeAutoFillId === '' || values.payeeAutoFillId === undefined) {
          values.payeeAutoFillId = null;
        }

        SchBExpendituresImportApiService.saveStagingExpenditure(
          values,
          this.state.currPayeeType,
          CurrentUser.Get()?.committeeId || '',
          this.state.batchNumber || '',
          Number(this.state.transNumber) || 0,
          this.state.userId || 0
        )
          .then(() => {
            notification.success({
              message: 'Saved Successfully'
            });
            this.setState({ saving: false, showModal: false });
            if (this.dataTable) {
              this.dataTable.refresh();
            }
          })
          .catch(() => {
            notification.error({ message: 'Failed to save the Staging Expenditure.' });
            this.setState({ saving: false, showModal: false });
            if (this.dataTable) {
              this.dataTable.refresh();
            }
          });

      });
    }
  }

  private cancelStagingExpenditureEdit = () => {
    this.setState({ showModal: false });
  }

  private confirmSaveExpenditures = () => {
    confirm({
      title: 'Are you sure you want to save the imported expenditures?',
      onOk: () => {
        this.saveImportedExpenditures();
      },
    });
  }

  private getImportedSum = () => {
    const currentUser = CurrentUser.Get();

    if (currentUser != null) {

      SchBExpendituresImportApiService.sumImportedAmount(this.state.batchNumber || '',
        this.state.userId || 0,
        currentUser.committeeId || '',
        currentUser.dr2Id || ''
      )
        .then(results => this.setState({ total: results }));

    }
    return this.state.total;
  }

  private saveImportedExpenditures = () => {
    const currentUser = CurrentUser.Get();

    if (currentUser != null) {

      this.setState({ saving: true });

      SchBExpendituresImportApiService.stagingExpenditureTableHasErrors(
        this.state.batchNumber || '',
        this.state.userId || 0
      )
        .then((hasErrors) => {

          if (hasErrors) {
            notification.info({
              message: 'The imported expenditures still contain errors. Please resolve the errors to save the expenditures.',
              duration: 6
            });
            if (this.dataTable) {
              this.dataTable.refresh();
            }
            this.setState({ saving: false });
          }
          else {
            SchBExpendituresImportApiService.saveImportedExpenditures(
              this.state.batchNumber || '',
              this.state.userId || 0,
              currentUser.committeeId || '',
              currentUser.dr2Id || ''
            )
              .then(() => {
                notification.success({ message: 'Saved Successfully' });
                this.setState({ saving: false });
                if (this.dataTable) {
                  this.dataTable.refresh();
                }
                HistoryUtil.push(Routes.SCHEDULE_B_EXPENDITURES);
              })
              .catch(() => {
                notification.error({ message: 'Failed to save the Expenditures.' });
                this.setState({ saving: false });
                if (this.dataTable) {
                  this.dataTable.refresh();
                }
              });
          }

        })
        .catch(() => {
          notification.error({ message: 'Failed to check the table for errors.' });
          this.setState({ saving: false });
        });

    }
  }

  private cancelImportingExpenditures = () => {
    SchBExpendituresImportApiService.deleteStagingExpendituresByUserId(this.state.userId || 0)
      .finally(() => {
        HistoryUtil.push(Routes.SCHEDULE_B_EXPENDITURES);
      });
  }
}

export default SchBExpendituresImport;