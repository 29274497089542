// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
import BaseApi from './BaseApi';
import TableRequestDTO from '../models/TableRequestDTO';
import SchAContributionsFormDTO from '../models/SchAContributionsFormDTO';
import TableResponseDTO from '../models/TableResponseDTO';
import SchAContributionsDTO from '../models/SchAContributionsDTO';

export class SchAContributionsApiService extends BaseApi {

  // post: api/schacontributions/table?dr2Id=${encodeURIComponent(dr2Id)}
  public getSchAContributions(tableRequest: TableRequestDTO, dr2Id: string): Promise<TableResponseDTO<SchAContributionsDTO>> {
    let url = `api/schacontributions/table`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (dr2Id != null) {
      url += `${prefix}dr2Id=${encodeURIComponent(dr2Id)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, TableResponseDTO<SchAContributionsDTO>>(tableRequest, 'post', url, true, false);
  }

  // get: api/schacontributions/${encodeURIComponent(contributionId)}/${seqNum}
  public getContribution(contributionId: string, seqNum: number): Promise<SchAContributionsFormDTO> {
    let url = `api/schacontributions/${encodeURIComponent(contributionId)}/${seqNum}`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, SchAContributionsFormDTO>(null, 'get', url, true, false);
  }

  // post: api/schacontributions/${encodeURIComponent(contributionId)}/history
  public getContributionHistory(tableRequest: TableRequestDTO, contributionId: string): Promise<TableResponseDTO<SchAContributionsDTO>> {
    let url = `api/schacontributions/${encodeURIComponent(contributionId)}/history`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, TableResponseDTO<SchAContributionsDTO>>(tableRequest, 'post', url, true, false);
  }

  // post: api/schacontributions?committeeId=${encodeURIComponent(committeeId)}&dr2Id=${encodeURIComponent(dr2Id)}
  public add(newContribution: SchAContributionsFormDTO, committeeId: string, dr2Id: string): Promise<void> {
    let url = `api/schacontributions`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (committeeId != null) {
      url += `${prefix}committeeId=${encodeURIComponent(committeeId)}`;
      prefix = '&';
    }
    if (dr2Id != null) {
      url += `${prefix}dr2Id=${encodeURIComponent(dr2Id)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, void>(newContribution, 'post', url, true, false);
  }

  // put: api/schacontributions?committeeId=${encodeURIComponent(committeeId)}&dr2Id=${encodeURIComponent(dr2Id)}&contributionId=${encodeURIComponent(contributionId)}&seqNum=${seqNum}
  public edit(newContribution: SchAContributionsFormDTO, committeeId: string, dr2Id: string, contributionId: string, seqNum: number): Promise<void> {
    let url = `api/schacontributions`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (committeeId != null) {
      url += `${prefix}committeeId=${encodeURIComponent(committeeId)}`;
      prefix = '&';
    }
    if (dr2Id != null) {
      url += `${prefix}dr2Id=${encodeURIComponent(dr2Id)}`;
      prefix = '&';
    }
    if (contributionId != null) {
      url += `${prefix}contributionId=${encodeURIComponent(contributionId)}`;
      prefix = '&';
    }
    if (seqNum != null) {
      url += `${prefix}seqNum=${seqNum}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, void>(newContribution, 'put', url, true, false);
  }

  // delete: api/schacontributions?contributionId=${encodeURIComponent(contributionId)}&seqNum=${seqNum}
  public delete(contributionId: string, seqNum: number): Promise<void> {
    let url = `api/schacontributions`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (contributionId != null) {
      url += `${prefix}contributionId=${encodeURIComponent(contributionId)}`;
      prefix = '&';
    }
    if (seqNum != null) {
      url += `${prefix}seqNum=${seqNum}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, void>(null, 'delete', url, true, false);
  }
}
var service = new SchAContributionsApiService();
export default service;
