import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import rootReducer from './reducers/RootReducer';
import { saveState, loadState } from './StateStorage';

const persistedState = loadState();
export const store = createStore(rootReducer, persistedState, applyMiddleware(thunkMiddleware));

store.subscribe(() => {
    saveState(store.getState());
});
