import FormItem from 'antd/lib/form/FormItem';
import { notification, Select, Spin } from 'antd';
import * as React from 'react';
import SearchApiService from '../../api/SearchApiService';
import OrganizationInfoDTO from '../../models/OrganizationInfoDTO';
import debounce from 'lodash/debounce';
import { Rule } from 'antd/lib/form';

const { Option } = Select;

interface OrganizationAutoSearchProps {
  name: string;
  label?: React.ReactNode | string;
  hidden?: boolean;
  required?: boolean;
  disabled?: boolean;
  // CommitteeId of committee that you are trying to pull organizations from
  committeeId: string;
  // Type of perorg to pull
  perorgType: string;
  // What to do in the outside form when option selected (Ex: fill in address fields)
  onSelect?: (perorgId: string) => void;
  onChange?: (value: string) => void;
}

interface OrganizationAutoSearchState {
  perorgs: OrganizationInfoDTO[];
  loading: boolean;
}

class OrganizationAutoSearch extends React.Component<OrganizationAutoSearchProps, OrganizationAutoSearchState> {
  constructor(props: OrganizationAutoSearchProps) {
    super(props);

    this.searchOrganizationInfo = debounce(this.searchOrganizationInfo, 400);

    this.state = {
      perorgs: [],
      loading: false,
    };
  }

  render() {
    const { perorgs, loading } = this.state;
    const { name, label, hidden, required, disabled } = this.props;

    const organizationSearchNameRules: Rule[] = [];

    if (required !== false) {
      organizationSearchNameRules.push({ required: true, message: (label || 'Company') + ' is required' });
    }

    const options: OrganizationInfoDTO[] = loading ? [OrganizationInfoDTO.create()] : perorgs;

    return (
      <FormItem name={name} label={label || 'Company'} hidden={hidden || false} rules={organizationSearchNameRules} >
        <Select
          showSearch={true}
          filterOption={false /*this option allows the loading spinner to work*/}
          optionFilterProp="children"
          notFoundContent={false}
          showArrow={false}
          disabled={disabled || false}
          placeholder={'Search for a company...'}
          allowClear={true}
          onSearch={this.handleSearch}
          onClear={this.handleClear}
          onChange={this.handleChange}
          dropdownRender={(a) => {
            if (loading) {
              return <div className="center-spinner"><Spin spinning={loading}>{a}</Spin></div>;
            }
            else {
              return a;
            }
          }}
        >
          {options.map(p => (
            <Option key={p.id || ''} value={p.id || ''}>
              {p.organizationName || ''}
              <br />
              {p.city && (
                <small>{p.city + ', ' + p.state}</small>
              )}
            </Option>
          ))}
        </Select>
      </FormItem>
    );
  }

  private searchOrganizationInfo = (committeeId: string, name: string, perorgType: string) => {
    SearchApiService.getOrganizationByName(committeeId, name, perorgType)
      .then((perorgs) => {
        this.setState({ perorgs: perorgs, loading: false });
      })
      .catch(() => {
        notification.error({
          message: 'Failed to retrieve organizations.',
        });
      });
  }

  private handleClear = () => {
    this.setState({ perorgs: [] });
    if (this.props.onSelect) {
      this.props.onSelect('');
    }
  }

  private handleSearch = (value: string) => {
    if (!value || value.length > 0) {
      if (this.props.onChange) {
        this.props.onChange(value);
      }
    }
    if (!value || value.length < 3) {
      if (this.state.perorgs !== []) {
        this.setState({ perorgs: [], loading: false });
      }
      return;
    }
    this.setState({ loading: true });
    this.searchOrganizationInfo(this.props.committeeId, value, this.props.perorgType);
  }

  private handleChange = (value: string) => {
    const selectedCompany = (this.state.perorgs || []).find(c => c.id === value);
    if (selectedCompany) {
      this.setState({ perorgs: [selectedCompany] });
      if (this.props.onSelect) {
        this.props.onSelect(selectedCompany?.id || '');
      }
    }
  }
}

export default OrganizationAutoSearch;
