import { Dispatch } from 'redux';
import BaseAction from './BaseAction';
import { StateStoreModel } from '../../redux/state/StateStoreModel';

export type VersionAction = BaseAction<VersionActionTypes, any>;

export enum VersionActionTypes {
  VERSION_CHECK = 'VERSION_CHECK',
  VERSION_OUTDATED = 'VERSION_OUTDATED',
  VERSION_RESET = 'VERSION_RESET',
}

let resetTimer: NodeJS.Timeout;

export const VersionActions = {
  check: VersionCheckAction,
  reset: VersionResetAction,
};

function VersionResetAction() {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return (dispatch: Dispatch<any>, getState: () => StateStoreModel) => {
    if (resetTimer) {
      clearTimeout(resetTimer);
    }
    resetTimer = setTimeout(() => {
      dispatch(_VersionResetAction());
    }, 300000);
  };
}

function VersionCheckAction(client: string, api: string) {
  return (dispatch: Dispatch<any>, getState: () => StateStoreModel) => {
    const outdated = getState().Version.Outdated;
    if (!outdated && client !== api) {
      dispatch(VersionOutdatedAction(client, api));
    }
  };
}

function VersionOutdatedAction(client: string, api: string): VersionAction {
  return {
    type: VersionActionTypes.VERSION_OUTDATED,
    data: { Client: client, Api: api },
  };
}
function _VersionResetAction(): VersionAction {
  return {
    type: VersionActionTypes.VERSION_RESET,
    data: null,
  };
}
