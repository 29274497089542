import {
  Button,
  Checkbox,
  Col,
  ColProps,
  FormInstance,
  Input,
  notification,
  Modal,
  Row,
  Select,
  Space,
  Typography,
  Tooltip,
} from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import TextArea from 'antd/lib/input/TextArea';
import AddressForm from '../shared/AddressFormFields';
import * as React from 'react';
import LookupsApiService from '../../api/LookupsApiService';
import SchF2LoansRepaidApiService from '../../api/SchF2LoansRepaidApiService';
import ScheduleApiService from '../../api/ScheduleApiService';
import CodeLookupTableDTO from '../../models/CodeLookupTableDTO';
import SchF2LoansRepaidFormDTO from '../../models/SchF2LoansRepaidFormDTO';
import CommitteeInfoDTO from '../../models/CommitteeInfoDTO';
import FormValidationUtil from '../../utils/FormValidationUtil';
import Dr2GroupTypes from '../../consts/Dr2GroupTypes';
import CurrentUser from '../../utils/CurrentUser';
import HistoryUtil from '../../utils/HistoryUtil';
import Routes from '../../consts/Routes';
import { RouteComponentProps } from 'react-router';
import { Content } from 'antd/lib/layout/layout';
import moment from 'moment';
import PersonSearch from '../shared/PersonSearch';
import BaseScheduleInfoDTO from '../../models/BaseScheduleInfoDTO';
import ScheduleAbbreviationCodes from '../../consts/ScheduleAbbreviationCodes';
import SchF2LoansRepaidHistoryTable from './SchF2LoansRepaidHistoryTable';
import CommitteeAutoSearch from '../shared/CommitteeSearch';
import NoteEntityParents from '../../consts/NoteEntityParents';
import NotesModal from '../shared/NotesModal';
import CurrencyInput from '../shared/CurrencyInput';
import CustomDatePicker from '../shared/CustomDatePicker';
import CustomForm from '../shared/CustomForm';
import OrganizationAutoSearch from '../shared/OrganizationSearch';
import CommitteeType from '../../consts/CommitteeType';

const Option = Select.Option;
const confirm = Modal.confirm;

interface SchF2LoansRepaidFormProps {
  id?: string;
  seqNum?: string;
}

interface SchF2LoansRepaidFormState {
  relations: CodeLookupTableDTO[];
  currPayeeType: string;
  currExpenseType: string;
  currComm: CommitteeInfoDTO;
  btnDisable: boolean;
  loanRepaidInfo: SchF2LoansRepaidFormDTO;
  loanRepaidId?: string;
  loanAlreadyForgiven: boolean;
  explanationReq: boolean;
  startDt: moment.Moment;
  endDt: moment.Moment;
  loading: boolean;
  trusteeTrustorReq: boolean;
  autoFillPerorg: boolean;
  seqNum: number;
  hideRelationship: boolean;
}

class SchF2LoansRepaidForm extends React.Component<RouteComponentProps<SchF2LoansRepaidFormProps>, SchF2LoansRepaidFormState> {
  private readonly _formRef = React.createRef<FormInstance>();

  constructor(props: RouteComponentProps<SchF2LoansRepaidFormProps>) {
    super(props);
    this.state = {
      relations: [],
      currPayeeType: '',
      currExpenseType: '',
      currComm: CommitteeInfoDTO.create(),
      btnDisable: false,
      loanRepaidId: this.props.match.params.id,
      loanRepaidInfo: SchF2LoansRepaidFormDTO.create({ payeeType: '', state: 'IA', relationshipCode: '', amount: null }),
      loanAlreadyForgiven: false,
      explanationReq: false,
      startDt: moment.utc(),
      endDt: moment.utc(),
      loading: !!this.props.match.params.id,
      trusteeTrustorReq: false,
      seqNum: Number(this.props.match.params.seqNum) || 0,
      autoFillPerorg: false,
      hideRelationship: false,
    };
  }

  componentDidMount() {
    this.loadLookups();
    this.getUserCommittee();
    this.getSchedule();
    if (this.props.match.params.id) {
      this.getLoanRepaid(this.props.match.params.id || '', this.props.match.params.seqNum || '');
    }
  }

  render() {
    const { relations, currPayeeType, currComm, btnDisable, loanRepaidId, loanRepaidInfo, loanAlreadyForgiven,
      explanationReq, startDt, endDt, loading, trusteeTrustorReq, seqNum, autoFillPerorg, hideRelationship } = this.state;
    const columnSizingProps: ColProps = { xs: 24, sm: 24, md: 12 };
    const isCandidateCommittee = currComm.filerTypeName == 'Candidate';
    const relationReq = currPayeeType === Dr2GroupTypes.INDIVIDUAL && isCandidateCommittee;

    const payeeTypes = [
      Dr2GroupTypes.INDIVIDUAL,
      Dr2GroupTypes.COMMITTEE,
      Dr2GroupTypes.TRUST,
      Dr2GroupTypes.COMPANYOTHER
    ];

    return (
      <>
        {!loading &&
          <Content className="content-pad">
            <Typography.Title level={4}>Schedule F2 Loan Repaid</Typography.Title>
            <CustomForm formRef={this._formRef} initialValues={loanRepaidInfo} layout="vertical" onFinish={this.handleSave}>
              <Row gutter={24}>
                <Col {...columnSizingProps}>
                  <FormItem name="payeeType" label="Payee Type"
                    rules={[FormValidationUtil.Required('Payee Type is required')]} >
                    <Select
                      showSearch
                      optionFilterProp="children"
                      onChange={this.handlePayeeTypeChange}>
                      <Option value="" disabled={true}>-- Select Type --</Option>
                      {payeeTypes.map(pt => (
                        <Option key={pt} value={pt}>{pt}</Option>
                      ))}
                    </Select>
                  </FormItem>
                  <FormItem name="date" label="Date"
                    rules={[FormValidationUtil.RequiredDate('Date is required', true), FormValidationUtil.DateRange(startDt, endDt)]}>
                  <CustomDatePicker onChange={(d) => d?.startOf('day')}/>
                  </FormItem>
                  <FormItem name="amount" label="Amount"
                    rules={[
                      FormValidationUtil.RequiredNumber('Amount is required'),
                      FormValidationUtil.NotNegativeNumber('Amount must be nonnegative')
                    ]}>
                    <CurrencyInput />
                  </FormItem>
                  <Row>
                    <Col>
                      <Space>
                        <Tooltip title="The loan has been forgiven">
                          <FormItem name="isForgiven" valuePropName="checked">
                            <Checkbox onChange={this.handleForgivenCheckboxChange} disabled={loanAlreadyForgiven}>Forgiven</Checkbox>
                          </FormItem>
                        </Tooltip>
                      </Space>
                    </Col>
                  </Row>
                  <FormItem name="explanation" label="Explanation"
                    rules={[FormValidationUtil.Required(
                      'Explanation is required',
                      explanationReq
                    )]}>
                    <TextArea maxLength={2000} />
                  </FormItem>
                </Col>
                <Col {...columnSizingProps}>
                  {currPayeeType === Dr2GroupTypes.INDIVIDUAL && (
                    <>
                      <PersonSearch
                        name={'person'}
                        label={'Search for an Individual'}
                        required={false}
                        committeeId={currComm.id || ''}
                        onSelect={this.getPerson}
                        onChange={this.handlePayeeChange}
                      />
                      <AddressForm allDisabled={autoFillPerorg}
                        firstName={{ name: 'firstName' }}
                        middleInitial={{ name: 'middleInitial' }}
                        lastName={{ name: 'lastName' }}
                        address1={{ name: 'addressLine1' }}
                        address2={{ name: 'addressLine2' }}
                        city={{ name: 'city' }}
                        state={{ name: 'state' }}
                        zip={{ name: 'zip' }}
                        phone={{ name: 'phoneNumber', required: false }}
                    />
                      <FormItem name="relationshipCode" label="Relationship" hidden={hideRelationship}
                        rules={[FormValidationUtil.Required('Relationship is required', relationReq)]}>
                        <Select
                          showSearch disabled={autoFillPerorg}
                          optionFilterProp="children">
                          <Option value="" disabled={true}>-- Select Relationship --</Option>
                          {relations.map(rt => (
                            <Option key={rt.code || ''} value={rt.code || ''}>{rt.name}</Option>
                          ))}
                        </Select>
                      </FormItem>
                    </>
                  )}
                  {currPayeeType === Dr2GroupTypes.COMMITTEE && (
                    <CommitteeAutoSearch
                      name="payeeName"
                      label="Name"
                      required={true}
                      requireSelection={true}
                      onSelectCommittee={this.handleCommitteeSelect}
                    />
                  )}
                  {(currPayeeType === Dr2GroupTypes.COMPANYOTHER || currPayeeType === Dr2GroupTypes.TRUST) && (
                    <>
                      <OrganizationAutoSearch
                        name={'company'}
                        label={'Search for a Company'}
                        required={false}
                        committeeId={currComm.id || ''}
                        perorgType={currPayeeType}
                        onSelect={this.getOrganization}
                        onChange={this.handlePayeeChange}
                      />
                      <AddressForm allDisabled={autoFillPerorg}
                        name={{ name: 'payeeName', label: 'Company' }}
                        address1={{ name: 'addressLine1' }}
                        address2={{ name: 'addressLine2' }}
                        city={{ name: 'city' }}
                        state={{ name: 'state' }}
                        zip={{ name: 'zip' }}
                        phone={{ name: 'phoneNumber', required: false }}
                      />
                    </>
                  )}
                  {currPayeeType === Dr2GroupTypes.TRUST &&
                    <>
                      <FormItem name="isLivingOrRevocableTrust" valuePropName="checked">
                        <Checkbox onChange={this.handleRevocableTrustCheckboxChange}>Living or Revocable Trust</Checkbox>
                      </FormItem>
                      <FormItem name="trustee" label="Trustee"
                        rules={[FormValidationUtil.Required('Trustee is required', trusteeTrustorReq)]}>
                        <Input disabled={autoFillPerorg} />
                      </FormItem>
                      <FormItem name="trustor" label="Trustor"
                        rules={[FormValidationUtil.Required('Trustor is required', trusteeTrustorReq)]}>
                        <Input disabled={autoFillPerorg} />
                      </FormItem>
                    </>
                  }
                </Col>
              </Row>
              <Row>
                <Col {...columnSizingProps}>
                  <FormItem name="payeeAutoFillId">
                    <Input hidden={true} disabled={true} aria-hidden={true}/>
                  </FormItem>
                </Col>
              </Row>
              <Space>
                <Button type="primary" disabled={btnDisable} htmlType="submit">Save Payment</Button>
                <Button type="default" disabled={btnDisable} onClick={this.handleCancel}>Cancel</Button>
                {loanRepaidId &&
                  <NotesModal
                    parentId={loanRepaidId}
                    parent={NoteEntityParents.LOANREPAID}
                    seqNum={seqNum || 0}
                    auditorsNoteId={loanRepaidInfo.auditorsNoteId || ''}
                  />
                }
              </Space>
            </CustomForm >
            {loanRepaidId &&
              <SchF2LoansRepaidHistoryTable loanRepaidId={loanRepaidId || ''} />
            }
          </Content >
        }
      </>
    );
  }

  private handlePayeeTypeChange = (payeeType: string) => {
    this.setState({
      currPayeeType: payeeType,
      autoFillPerorg: false,
      trusteeTrustorReq: payeeType == Dr2GroupTypes.TRUST
    });

    this._formRef.current?.setFieldsValue({
      'firstName': '',
      'middleInitial': '',
      'lastName': '',
      'relationshipCode': '',
      'addressLine1': '',
      'addressLine2': '',
      'city': '',
      'state': '',
      'zip': '',
      'phoneNumber': '',
      'trustee': '',
      'trustor': '',
      'person': null,
      'payeeName': null,
      'company': null,
      'isLivingOrRevocableTrust': false,
      'payeeAutoFillId': ''
    });

    if ([Dr2GroupTypes.COMPANYOTHER, Dr2GroupTypes.INDIVIDUAL, Dr2GroupTypes.TRUST].includes(payeeType)) {
      this._formRef.current?.setFieldsValue({ state: 'IA' });
    }
  }

  private handlePayeeChange = (value: string) => {
    this._formRef.current?.setFieldsValue({ payeeAutoFillId: '' });
    this.setState({ autoFillPerorg: !!value });
  }

  private handleCommitteeSelect = (committee: CommitteeInfoDTO | undefined) => {
    this._formRef.current?.setFieldsValue({ payeeAutoFillId: committee?.id , payeeName: committee?.name});
  }

  private setCompanyFields(form?: BaseScheduleInfoDTO) {
    if (form) {
      this._formRef.current?.setFieldsValue({
        payeeName: form.committeeOrCompanyName,
        addressLine1: form.addressLine1,
        addressLine2: form.addressLine2,
        city: form.city,
        state: form.state,
        zip: form.zip,
        phoneNumber: form.phoneNumber,
        trustee: form.trustee,
        trustor: form.trustor
      });
    }
    else {
      this._formRef.current?.setFieldsValue({
        payeeName: '',
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        zip: '',
        phoneNumber: '',
        trustee: '',
        trustor: '',
        payeeAutoFillId: ''
      });
      this.setState({ autoFillPerorg: false });
    }
  }

  private setIndividualFields(form?: BaseScheduleInfoDTO) {
    if (form) {
      this._formRef.current?.setFieldsValue({
        payeeType: form.groupType,
        firstName: form.firstName,
        middleInitial: form.middleInitial,
        lastName: form.lastName,
        addressLine1: form.addressLine1,
        addressLine2: form.addressLine2,
        city: form.city,
        state: form.state,
        zip: form.zip,
        phoneNumber: form.phoneNumber,
        relationshipCode: form.relationshipCode,
        payeeAutoFillId: ''
      });
    }
    else {
      this._formRef.current?.setFieldsValue({
        payeeType: Dr2GroupTypes.INDIVIDUAL,
        firstName: '',
        middleInitial: '',
        lastName: '',
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        zip: '',
        phoneNumber: '',
        relationshipCode: '',
        payeeAutoFillId: ''
      });
      this.setState({ autoFillPerorg: false });
    }
    this.setState({ currPayeeType: Dr2GroupTypes.INDIVIDUAL });
  }

  private handleRevocableTrustCheckboxChange = (e: any) => {
    this.setState({ trusteeTrustorReq: !e.target.checked });
  }

  private handleForgivenCheckboxChange = (e: any) => {
    this.setState({ explanationReq: e.target.checked });
  }

  private loadLookups = () => {
    const relationPromise = LookupsApiService.getRelationTypes();
    Promise.all([relationPromise])
      .then(result => {
        const [relations] = result;
        this.setState({ relations });
      });
  }

  private getSchedule = () => {
    ScheduleApiService.getSchedule(CurrentUser.Get()?.dr2Id || '', ScheduleAbbreviationCodes.SCHEDULEF2)
      .then(schedule => {
        this.setState({ startDt: moment.utc(schedule.startDate), endDt: moment.utc(schedule.endDate) });
      });
  }

  private getPerson = (perorgId: string) => {
    if (perorgId != '') {
      ScheduleApiService.getPerson(perorgId, CurrentUser.Get()?.committeeId || '')
        .then(personInfo => {
          if (personInfo.groupType) {
            this.setIndividualFields(personInfo);
            this._formRef.current?.setFieldsValue({ payeeAutoFillId: perorgId });
          }
        });
    }
    else {
      this.setIndividualFields();
    }
  }

  private getOrganization = (perorgId: string) => {
    if (perorgId != '') {
      ScheduleApiService.getOrganization(perorgId, CurrentUser.Get()?.committeeId || '')
        .then(orgInfo => {
          if (orgInfo.groupType) {
            this.setCompanyFields(orgInfo);
            this._formRef.current?.setFieldsValue({ payeeAutoFillId: perorgId });
          }
        });
    }
    else {
      this.setCompanyFields();
    }
  }

  private getUserCommittee = () => {
    ScheduleApiService.getCommittee(CurrentUser.Get()?.committeeId || '')
      .then(committee => {
        if (committee) {
          const hideRelationshipCodes = [
            CommitteeType.CITY_PAC,
            CommitteeType.COUNTY_PAC,
            CommitteeType.IOWA_PAC,
            CommitteeType.SCHOOL_BOARD_OR_OTHER_POLITICAL_SUBDIVISION_PAC,
            CommitteeType.COUNTY_CENTRAL_COMMITTEE,
            CommitteeType.STATE_CENTRAL_COMMITTEE,
            CommitteeType.LOCAL_BALLOT_ISSUE];
          this.setState({ currComm: { ...committee }, hideRelationship: hideRelationshipCodes.includes(committee.committeeTypeCd || '') });
        }
      });
  }

  private getLoanRepaid = (loanRepaidId: string, seqNum: string) => {
    SchF2LoansRepaidApiService.getLoanRepaid(loanRepaidId, Number(seqNum))
      .then(loanRepaid => {
        if (loanRepaid) {
          loanRepaid.date = moment(loanRepaid.date);

          const loanRepaidInfo: SchF2LoansRepaidFormDTO & { person?: string, company?: string } = loanRepaid;
          if (loanRepaidInfo.firstName && loanRepaidInfo.lastName) {
            if (loanRepaidInfo.middleInitial) {
              loanRepaidInfo.person = loanRepaid.firstName + ' ' + loanRepaid.middleInitial + '. ' + loanRepaid.lastName;
            }
            else {
              loanRepaidInfo.person = loanRepaid.firstName + ' ' + loanRepaid.lastName;
            }
          }
          else if (loanRepaidInfo.payeeType == Dr2GroupTypes.TRUST || loanRepaidInfo.payeeType == Dr2GroupTypes.COMPANYOTHER) {
            if (loanRepaidInfo.payeeName) {
              loanRepaidInfo.company = loanRepaidInfo.payeeName;
            }
          }

          this.setState({
            loanRepaidInfo: loanRepaid,
            currPayeeType: loanRepaid.payeeType || '',
            loanAlreadyForgiven: loanRepaid.isForgiven,
            explanationReq: loanRepaid.isForgiven,
            trusteeTrustorReq: loanRepaid.payeeType == Dr2GroupTypes.TRUST && !loanRepaid.isLivingOrRevocableTrust,
            autoFillPerorg: loanRepaid.payeeType != Dr2GroupTypes.COMMITTEE
          });
          this._formRef.current?.setFieldsValue(loanRepaidInfo);
          this.setState({ loading: false });
        }
        else {
          notification.error({
            message: 'Error while fetching loan repaid',
            description: 'Not found'
          });
          this.setState({ loading: false });
        }
      }).catch(() => {
        notification.error({
          message: 'Error while fetching loan repaid'
        });
        this.setState({ loading: false });
      });
  }

  private handleSave = (values: SchF2LoansRepaidFormDTO) => {
    if (values.payeeAutoFillId === '') {
      values.payeeAutoFillId = null;
    }
    this.setState({ btnDisable: true });
    if (!this.state.loanRepaidId) {
      SchF2LoansRepaidApiService.add(values, CurrentUser.Get()?.committeeId || '', CurrentUser.Get()?.dr2Id || '')
        .then(() => {
          notification.success({
            message: 'Saved successfully',
            description: ''
          });
          HistoryUtil.push(Routes.SCHEDULE_F2_LOANS_REPAID);
        }).catch(() => {
          notification.error({
            message: 'Your loan repaid failed to save',
            description: ''
          });
          HistoryUtil.push(Routes.SCHEDULE_F2_LOANS_REPAID);
        });
    }
    else {
      SchF2LoansRepaidApiService.edit(
        values,
        CurrentUser.Get()?.committeeId || '',
        CurrentUser.Get()?.dr2Id || '',
        this.props.match.params.id || '',
        Number(this.props.match.params.seqNum)
      )
        .then(() => {
          notification.success({
            message: 'Saved successfully',
            description: ''
          });
          HistoryUtil.push(Routes.SCHEDULE_F2_LOANS_REPAID);
        }).catch(() => {
          notification.error({
            message: 'Your Loan Repaid failed to save',
            description: ''
          });
          HistoryUtil.push(Routes.SCHEDULE_F2_LOANS_REPAID);
        });
    }
  }

  private handleCancel = () => {
    confirm({
      title: 'Are you sure you want to leave?',
      okText: 'Yes',
      cancelText: 'No',
      onOk: () => {
        HistoryUtil.push(Routes.SCHEDULE_F2_LOANS_REPAID);
      }
    });
  }
}

export default SchF2LoansRepaidForm;
