import {
    Input,
    Select,
} from 'antd';
import * as React from 'react';
import PersonInfoDTO from '../../models/PersonInfoDTO';
import FormItem from 'antd/lib/form/FormItem';
import { Rule } from 'antd/lib/form';
import Dr2GroupTypes from '../../consts/Dr2GroupTypes';
import PersonSearch from './PersonSearch';
import CommitteeAutoSearch from './CommitteeSearch';
import CommitteeInfoDTO from '../../models/CommitteeInfoDTO';
import AddressForm from './AddressFormFields';
import FormValidationUtil from '../../utils/FormValidationUtil';

const Option = Select.Option;

interface FilerTypeSearchProps {
    id?: string
    label?: React.ReactNode | string;
    required?: boolean;
    perorgs?: PersonInfoDTO[];
    formRef?: any;
    person?: PersonInfoDTO;
    onChange?: (value: string | null) => void;
    onSearch?: (value: string) => void;
    currContrType?: string | null;
    allDisabled?: boolean;
}

interface FilerTypeSearchState {
    currContrType: string;
    currComm: CommitteeInfoDTO;
    person: PersonInfoDTO | null;
    perorgs: PersonInfoDTO[];
    autoFillPerorg: boolean;
}

class FilerTypeSearch extends React.Component<FilerTypeSearchProps, FilerTypeSearchState> {
    constructor(props: FilerTypeSearchProps) {
        super(props);
        this.state = {
            currContrType: '',
            currComm: CommitteeInfoDTO.create(),
            person: this.props.person || null,
            perorgs: this.props.perorgs || [],
            autoFillPerorg: false,
        };
    }

    render() {
        const { currComm, autoFillPerorg } = this.state;
        const { currContrType, label, required, allDisabled } = this.props;

        const FileTypeSearchRules: Rule[] = [];
        const contributorTypes = [
            Dr2GroupTypes.INDIVIDUAL,
            Dr2GroupTypes.COMMITTEE
        ];

        if (required !== false) {
            FileTypeSearchRules.push({ required: true, message: (label || 'Contributor Type') + ' is required' });
        }

        return (
            <>
                <FormItem name="contributionType" label={label || 'Contributor Type'}
                    rules={[FormValidationUtil.Required(`${label || 'Contributor Type'} is required`)]} >
                    <Select
                        showSearch
                        optionFilterProp="children"
                        onChange={this.handleContributorTypeChange}
                    >
                        <Option value="" disabled={true}>-- Select Type --</Option>
                        {contributorTypes.map(ct => (
                            <Option key={ct} value={ct}>{ct}</Option>
                        ))}
                    </Select>
                </FormItem>
                <FormItem name="contributorAutoFillId" hidden={true}>
                    <Input hidden={true} disabled={true} aria-hidden={true} />
                </FormItem>
                {currContrType === Dr2GroupTypes.INDIVIDUAL && (
                    <>
                        <PersonSearch
                            name={'person'}
                            label={'Search for an Individual'}
                            required={false}
                            committeeId={currComm.id || ''}
                            onSelect={this.getPerson}
                            onChange={this.handleContributorChange}
                            perorgs={this.props.perorgs}
                            tooltip={'Search for individuals.'}
                        />
                        <AddressForm allDisabled={allDisabled && autoFillPerorg}
                            firstName={{ name: 'firstName' }}
                            middleInitial={{ name: 'middleInitial' }}
                            lastName={{ name: 'lastName' }}
                            address1={{ name: 'addressLine1' }}
                            address2={{ name: 'addressLine2' }}
                            city={{ name: 'city' }}
                            state={{ name: 'state' }}
                            zip={{ name: 'zip' }}
                            phone={{ name: 'phoneNumber', required: false }}
                        />
                    </>
                )}
                {currContrType === Dr2GroupTypes.COMMITTEE && (
                    <CommitteeAutoSearch
                        name={'committeeName'}
                        label="Committee Name"
                        required={true}
                        requireSelection={true}
                        onSelectCommittee={this.handleCommitteeSelect}
                    />
                )}
            </>
        );
    }

    private handleContributorTypeChange = (contributorType: string) => {
        this.setState({
            currContrType: contributorType,
            autoFillPerorg: false,
        });

        this.props.formRef.current?.setFieldsValue({
            'firstName': '',
            'middleInitial': '',
            'lastName': '',
            'addressLine1': '',
            'addressLine2': '',
            'city': '',
            'state': '',
            'zip': '',
            'phoneNumber': '',
            'person': null,
            'comitteeName': null,
            'contributorAutoFillId': undefined
        });

        if (this.props.onChange) {
            this.props.onChange(contributorType);
        }
    }

    private handleContributorChange = (value: string) => {
        this.props.formRef.current?.setFieldsValue({ contributorAutoFillId: undefined });
        this.setState({ autoFillPerorg: !!value });
    }

    private handleCommitteeSelect = (committee: CommitteeInfoDTO | undefined) => {
        this.props.formRef.current?.setFieldsValue({ contributorAutoFillId: committee?.id });
    }

    private getPerson = (perorgId: string) => {
        if (perorgId != '') {
            if (this.props.perorgs) {
                const per = this.props.perorgs?.find((ele) => ele.id == perorgId);

                this.setIndividualFields(per);

                this.setState({ autoFillPerorg: !!perorgId });
                if (this.props.onChange) {
                    this.props.onChange(Dr2GroupTypes.INDIVIDUAL);
                }
            }
        }
        else {
            this.setIndividualFields();
        }
    }

    private setIndividualFields(form?: PersonInfoDTO) {
        if (form) {
            this.props.formRef.current?.setFieldsValue({
                firstName: form.firstName,
                middleInitial: form.middleInitial,
                lastName: form.lastName,
                addressLine1: form.addressLine1,
                addressLine2: form.addressLine2,
                city: form.city,
                state: form.state,
                zip: form.zipCode,
                phoneNumber: form.phoneNumber,
                contributorAutoFillId: form.id,
            });
        }
        else {
            this.props.formRef.current?.setFieldsValue({
                firstName: '',
                middleInitial: '',
                lastName: '',
                addressLine1: '',
                addressLine2: '',
                city: '',
                state: 'IA',
                zip: '',
                phoneNumber: '',
                contributorAutoFillId: undefined
            });
            this.setState({ autoFillPerorg: false });
        }
    }
}
export default FilerTypeSearch;