// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
import BaseApi from './BaseApi';
import TableRequestDTO from '../models/TableRequestDTO';
import SchH1CampaignPropertyFormDTO from '../models/SchH1CampaignPropertyFormDTO';
import TableResponseDTO from '../models/TableResponseDTO';
import SchH1CampaignPropertyDTO from '../models/SchH1CampaignPropertyDTO';

export class SchH1CampaignPropertyApiService extends BaseApi {

  // post: api/schH1CampaignProperties/table?dr2Id=${encodeURIComponent(dr2Id)}
  public getSchH1CampaignPropertiesList(tableRequest: TableRequestDTO, dr2Id: string): Promise<TableResponseDTO<SchH1CampaignPropertyDTO>> {
    let url = `api/schH1CampaignProperties/table`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (dr2Id != null) {
      url += `${prefix}dr2Id=${encodeURIComponent(dr2Id)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, TableResponseDTO<SchH1CampaignPropertyDTO>>(tableRequest, 'post', url, true, false);
  }

  // get: api/schH1CampaignProperties/${encodeURIComponent(campaignPropertyId)}/${seqNum}
  public getCampaignProperty(campaignPropertyId: string, seqNum: number): Promise<SchH1CampaignPropertyFormDTO> {
    let url = `api/schH1CampaignProperties/${encodeURIComponent(campaignPropertyId)}/${seqNum}`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, SchH1CampaignPropertyFormDTO>(null, 'get', url, true, false);
  }

  // post: api/schH1CampaignProperties/${encodeURIComponent(campaignPropertyId)}/history
  public getCampaignPropertyHistory(tableRequest: TableRequestDTO, campaignPropertyId: string): Promise<TableResponseDTO<SchH1CampaignPropertyDTO>> {
    let url = `api/schH1CampaignProperties/${encodeURIComponent(campaignPropertyId)}/history`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, TableResponseDTO<SchH1CampaignPropertyDTO>>(tableRequest, 'post', url, true, false);
  }

  // post: api/schH1CampaignProperties?committeeId=${encodeURIComponent(committeeId)}&dr2Id=${encodeURIComponent(dr2Id)}
  public add(newCampaignProperty: SchH1CampaignPropertyFormDTO, committeeId: string, dr2Id: string): Promise<void> {
    let url = `api/schH1CampaignProperties`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (committeeId != null) {
      url += `${prefix}committeeId=${encodeURIComponent(committeeId)}`;
      prefix = '&';
    }
    if (dr2Id != null) {
      url += `${prefix}dr2Id=${encodeURIComponent(dr2Id)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, void>(newCampaignProperty, 'post', url, true, false);
  }

  // put: api/schH1CampaignProperties?committeeId=${encodeURIComponent(committeeId)}&dr2Id=${encodeURIComponent(dr2Id)}&campaignPropertyId=${encodeURIComponent(campaignPropertyId)}&seqNum=${seqNum}
  public edit(newCampaignProperty: SchH1CampaignPropertyFormDTO, committeeId: string, dr2Id: string, campaignPropertyId: string, seqNum: number): Promise<void> {
    let url = `api/schH1CampaignProperties`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (committeeId != null) {
      url += `${prefix}committeeId=${encodeURIComponent(committeeId)}`;
      prefix = '&';
    }
    if (dr2Id != null) {
      url += `${prefix}dr2Id=${encodeURIComponent(dr2Id)}`;
      prefix = '&';
    }
    if (campaignPropertyId != null) {
      url += `${prefix}campaignPropertyId=${encodeURIComponent(campaignPropertyId)}`;
      prefix = '&';
    }
    if (seqNum != null) {
      url += `${prefix}seqNum=${seqNum}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, void>(newCampaignProperty, 'put', url, true, false);
  }

  // delete: api/schH1CampaignProperties?campaignPropertyId=${encodeURIComponent(campaignPropertyId)}&seqNum=${seqNum}
  public delete(campaignPropertyId: string, seqNum: number): Promise<void> {
    let url = `api/schH1CampaignProperties`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (campaignPropertyId != null) {
      url += `${prefix}campaignPropertyId=${encodeURIComponent(campaignPropertyId)}`;
      prefix = '&';
    }
    if (seqNum != null) {
      url += `${prefix}seqNum=${seqNum}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, void>(null, 'delete', url, true, false);
  }
}
var service = new SchH1CampaignPropertyApiService();
export default service;
