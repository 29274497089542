import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import Routes from '../../consts/Routes';
import CertifyDR3 from './CertifyDr3';
import ManageOie from './ManageOie';
import ManageOtc from './ManageOtc';
import OtcPenalties from './OtcPenalty';
import ManageDrsfa from './ManageDrsfa';
import EditDrsfa from '../public/campaigns/EditDrsfa';
import ManageGiftBequests from './ManageGiftBequests';
import EditGiftBequests from '../donor/EditGiftBequests';
import ManageDr1 from './ManageDr1';
import HelpPage from './HelpPage';
import ManageIie from './ManageIie';
import IiePenalty from './IiePenalty';
import OiePenalty from './OiePenalty'; 
import PfdPenalty from './PfdPenalty'; 
import ManagePfd from './ManagePfd';
import ReviewAccountPage from './ReviewAccountPage';
import Pfd from '../public/campaigns/Pfd/Pfd';
import Otc from '../public/campaigns/Otc';
import Iie from '../public/campaigns/Iie';
import Oie from '../public/campaigns/Oie';
import PeriodMain from './PeriodMain';
import AdminSearch from './AdminSearch';
import PeriodAdd from './Period';
import Reports from './Reports';
import ApproveDr1Edit from '../organizations/ApproveDR1Edit';
import ManualRegistrationPage from './ManualRegistration';
import ManageUsers from '../users/ManageUsers';
import EditUser from '../users/EditUser';
import RejectDR3 from './RejectDR3';
import PfdContact from './PfdContact';
import ManageEscheats from './ManageEscheats';
import Escheat from './Escheat';
import ManageConsentForSalesAndLeases from './ManageConsentForSalesAndLeases';
import EditManageConsentForSalesAndLeases from './EditManageConsentForSalesAndLeases';
import ManageDualComp from './ManageDualComp';
import ManageSalesByState from './ManageSalesByState';
import ManagePetitionForWaiver from './ManagePetitionForWaiver';
import DualComp from '../public/campaigns/DualComp';
import EditSalesByState from './EditSalesByState';
import PetitionForWaiver from '../public/PetitionForWaiver';

const Admin = () => (
  <Switch>
    {/* <Route exact={true} path={Routes.ADMIN_BASE} component={Dashboard} /> */}
    <Route exact={true} path={Routes.HOME_ROUTE} component={ReviewAccountPage} />
    <Route exact={true} path={Routes.ADMIN_REVIEWACCOUNT} component={ReviewAccountPage} />
    <Route exact={true} path={Routes.CERTIFY_DR3} component={CertifyDR3} />
    <Route exact={true} path={Routes.MANAGE_OTC} component={ManageOtc} />
    <Route exact={true} path={Routes.OTC_PENALTY} component={OtcPenalties} />
    <Route exact={true} path={Routes.EDIT_OTC} component={Otc} />
    <Route exact={true} path={Routes.MANAGE_DRSFA} component={ManageDrsfa} />
    <Route exact={true} path={Routes.EDIT_DRSFA} component={EditDrsfa} />
    <Route exact={true} path={Routes.MANAGE_IIE} component={ManageIie} />
    <Route exact={true} path={Routes.IIE_PENALTY} component={IiePenalty} />
    <Route exact={true} path={Routes.IIE} component ={Iie} />
    <Route exact={true} path={Routes.MANAGE_GIFT_BEQUEST} component={ManageGiftBequests} />
    <Route exact={true} path={Routes.ADMIN_EDIT_GIFT_BEQUEST} component={EditGiftBequests} />
    <Route exact={true} path={Routes.MANAGE_DR1} component={ManageDr1} />
    <Route exact={true} path={Routes.MANAGE_HELP_PAGES} component={HelpPage} />
    <Route exact={true} path={Routes.MANAGE_OIE} component={ManageOie} />
    <Route exact={true} path={Routes.OIE_PENALTY} component={OiePenalty} />
    <Route exact={true} path={Routes.MANAGE_PFD} component={ManagePfd} />
    <Route exact={true} path={Routes.EDIT_PFD} component={Pfd} />
    <Route exact={true} path={Routes.ADD_PFD_CONTACT} component={PfdContact} />
    <Route exact={true} path={Routes.EDIT_PFD_CONTACT} component={PfdContact} />
    <Route exact={true} path={Routes.PFD_PENALTY} component={PfdPenalty} />
    <Route exact={true} path={Routes.PERIOD_MAIN} component={PeriodMain} />
    <Route exact={true} path={Routes.PERIOD_ADD} component={PeriodAdd} />
    <Route exact={true} path={Routes.EDIT_PERIOD} component={PeriodAdd} />
    <Route exact={true} path={Routes.EDIT_IIE} component={Iie} />
    <Route exact={true} path={Routes.EDIT_OIE} component={Oie} />
    <Route exact={true} path={Routes.ADMIN_SEARCH} component={AdminSearch} />
    <Route exact={true} path={Routes.APPROVE_DR1_EDIT} component={ApproveDr1Edit} />
    <Route exact={true} path={Routes.ADMIN_REPORTS} component={Reports} />
    <Route exact={true} path={Routes.MANUAL_REGISTRATION} component={ManualRegistrationPage} />
    <Route exact={true} path={Routes.MANAGE_USERS} render={() => <ManageUsers forCommittee={false} />} />
    <Route exact={true} path={Routes.EDIT_USER} render={(props) => <EditUser forCommittee={false} {...props} />} />
    <Route exact={true} path={Routes.ADD_USER} render={(props) => <EditUser forCommittee={false} {...props} />} />
    <Route exact={true} path={Routes.REJECT_DR3} component={RejectDR3} />
    <Route exact={true} path={Routes.MANAGE_ESCHEATS} component={ManageEscheats} />
    <Route exact={true} path={Routes.ADD_ESCHEAT} component={Escheat} />
    <Route exact={true} path={Routes.EDIT_ESCHEAT} component={Escheat} />
    <Route exact={true} path={Routes.MANAGE_SALES_BY_STATE} component={ManageSalesByState} />
    <Route exact={true} path={Routes.EDIT_SALES_BY_STATE} component={EditSalesByState} />
    <Route exact={true} path={Routes.MANAGE_CONSENT_FOR_SALES_AND_LEASES} component={ManageConsentForSalesAndLeases} />
    <Route exact={true} path={Routes.ADD_CONSENT_FOR_SALES_AND_LEASES} component={EditManageConsentForSalesAndLeases} />
    <Route exact={true} path={Routes.EDIT_CONSENT_FOR_SALES_AND_LEASES} component={EditManageConsentForSalesAndLeases} />
    <Route exact={true} path={Routes.MANAGE_DUAL_COMP} component={ManageDualComp} />
    <Route exact={true} path={Routes.EDIT_DUAL_COMP} component={DualComp} />
    <Route exact={true} path={Routes.MANAGE_SALES_BY_STATE} component={ManageSalesByState} />
    <Route exact={true} path={Routes.MANAGE_PETITION_FOR_WAIVER} component={ManagePetitionForWaiver} />
    <Route exact={true} path={Routes.EDIT_PETITION_FOR_WAIVER} component={PetitionForWaiver} />

    {/* This needs to be the last item */}
    {/* <Route component={RedirectAs404} /> */}
  </Switch>
);

export default Admin;
