// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Constants.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
export default class ScheduleAbbreviationCodes {
    public static readonly SCHEDULEA: string = 'scA';
    public static readonly SCHEDULEB: string = 'scB';
    public static readonly SCHEDULED: string = 'scD';
    public static readonly SCHEDULEE: string = 'scE';
    public static readonly SCHEDULEF1: string = 'scF1';
    public static readonly SCHEDULEF2: string = 'scF2';
    public static readonly SCHEDULEG: string = 'scG';
    public static readonly SCHEDULEH1: string = 'scH1';
    public static readonly SCHEDULEH2: string = 'scH2';
}
