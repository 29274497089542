// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Constants.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
export default class FilingStatusType {
    public static readonly FILED: string = '1';
    public static readonly NOT_FILED: string = '2';
    public static readonly AMENDED: string = '3';
    public static readonly AUDITED: string = '4';
    public static readonly ADJUSTED: string = '5';
    public static readonly DELETED: string = '6';
    public static readonly NEVER_FILED: string = '7';
    public static readonly CERTIFIED: string = '8';
}
