import {
  Button,
  FormInstance,
  Layout,
  Modal,
  notification,
  Space
} from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import moment from 'moment';
import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import AdminPfdApiService from '../../api/AdminPfdApiService';
import Routes from '../../consts/Routes';
import AdminPfdPenaltyDTO from '../../models/AdminPfdPenaltyDTO';
import DateUtil from '../../utils/DateUtil';
import HistoryUtil from '../../utils/HistoryUtil';
import CustomForm from '../shared/CustomForm';
import PenaltyForm from './PenaltyForm';

const { Content } = Layout;
const { confirm } = Modal;


interface PfdPenaltiesProps {
  id: string;
}

interface PfdPenaltiesState {
  pfdPenalty: AdminPfdPenaltyDTO;
  id: string,
  loading: boolean,
  penaltyNoticeLoading: boolean,
}

class PfdPenalty extends React.Component<RouteComponentProps<PfdPenaltiesProps>, PfdPenaltiesState> {
  private readonly formRef = React.createRef<FormInstance>();
  constructor(props: RouteComponentProps<PfdPenaltiesProps>) {
    super(props);
    this.state = {
      pfdPenalty: AdminPfdPenaltyDTO.create(),
      id: props.match.params.id,
      loading: true,
      penaltyNoticeLoading: false,
    };
  }

  componentDidMount() {
    this.loadPfdPenalty();
  }

  render() {
    const { loading, pfdPenalty, penaltyNoticeLoading } = this.state;

    return (
      <Content className="content-pad">
        <h3 className="ant-typography">PFD Penalties</h3>
        {loading ? undefined :
          <CustomForm name="PfdPenalties" onFinish={this.submitForm} initialValues={pfdPenalty} layout="vertical" formRef={this.formRef}>
            <PenaltyForm penaltyNoticeDate={pfdPenalty.penaltyIssuedDate != null ? DateUtil.toTimeLocal(moment(pfdPenalty.penaltyIssuedDate)) : ''}/>
            <Space>
              <Button type="primary" htmlType="submit">Save Penalty</Button>
              <FormItem noStyle shouldUpdate>
                {({ getFieldValue }) =>
                  getFieldValue('penaltyPaidDate') === undefined || getFieldValue('penaltyPaidDate') === null ? (
                    <Button type="primary" loading={penaltyNoticeLoading}
                      onClick={() => this.sendLateNotice(this.formRef.current?.getFieldsValue())}>Save and Send Penalty Notice</Button>
                  ) : undefined}
              </FormItem>
              <Button onClick={this.cancelForm}>Cancel</Button>
            </Space>
          </CustomForm>
        }
      </Content>
    );
  }

  private submitForm = (values: any) => {
    AdminPfdApiService.savePfdPenalty(this.state.id, {...this.state.pfdPenalty, ...values }, false)
      .then(() => {
        notification.success({
          message: 'Saved Penalty Successfully'
        });
        HistoryUtil.push(Routes.MANAGE_PFD);
      })
      .catch(error => {
        notification.error({ message: error.message, description: error.description });
      });
  }

  private sendLateNotice = (values: any) => {
    this.setState({ penaltyNoticeLoading: true });
    AdminPfdApiService.savePfdPenalty(this.state.id, { ...this.state.pfdPenalty, ...values }, true)
      .then((result) => {
        if (result.succeeded) {
          HistoryUtil.push(Routes.MANAGE_PFD);
          notification.success({ message: 'Penalty notice sent successfully.' });
        }
        else {
          if (result.errors[0] != null) {
            notification.error({ message: result.errors[0] });
          }
          else {
            notification.error({ message: 'Failed to send penalty notice.' });
          }
        }
        this.setState({ penaltyNoticeLoading: false });
      })
      .catch(error => {
        notification.error({ message: error.message, description: error.description });
        this.setState({ penaltyNoticeLoading: false });
      });
  }

  private cancelForm = () => {
    confirm({
      title: 'Are you sure you want to leave?',
      okText: 'Yes',
      cancelText: 'No',
      onOk: () => {
        HistoryUtil.push(Routes.MANAGE_PFD);
      }
    });
  }

  private loadPfdPenalty = () => {
    AdminPfdApiService.getPfdPenalty(Number(this.state.id)).then(pfdPenalty => {
      if (pfdPenalty.penaltyDueDate) {
        pfdPenalty.penaltyDueDate = moment(pfdPenalty.penaltyDueDate);
      }

      if (pfdPenalty.penaltyIssuedDate) {
        pfdPenalty.penaltyIssuedDate = moment.utc(pfdPenalty.penaltyIssuedDate);
      }

      if (pfdPenalty.penaltyPaidDate) {
        pfdPenalty.penaltyPaidDate = moment(pfdPenalty.penaltyPaidDate);
      }

      if (pfdPenalty.waivedDate) {
        pfdPenalty.waivedDate = moment(pfdPenalty.waivedDate);
      }

      this.setState({ pfdPenalty, loading: false });
    }).catch(() => {
      this.setState({ loading: false });
    });
  }
}

export default PfdPenalty;