import { Alert, Button, Card, Col, Layout, Row } from 'antd';
import * as React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import AccountApiService from '../../api/AccountApiService';
import Routes from '../../consts/Routes';
import ResultDTO from '../../models/ResultDTO';
import { StateStoreModel } from '../../redux/state/StateStoreModel';
import PageTitle from '../shared/PageTitle';

const Content = Layout.Content;

interface ConfirmEmailState {
  error: any;
  confirmSuccess: boolean;
  loading: boolean;
}

interface ConfirmEmailProps { }

class ConfirmEmailPage extends React.Component<
  ConfirmEmailProps & RouteComponentProps<RouteObject>,
  ConfirmEmailState
> {
  private code: string | null = null;
  private userId: number | null = null;

  constructor(props: ConfirmEmailProps & RouteComponentProps<RouteObject>) {
    super(props);

    this.state = {
      error: null,
      confirmSuccess: false,
      loading: true,
    };
  }

  componentDidMount() {
    const urlParams = new URLSearchParams(location.search);
    this.code = urlParams.get('code');
    const userId = urlParams.get('userId');
    if (userId !== null) {
      this.userId = parseInt(userId);
    }

    if (this.userId !== null && this.code !== null) {
      AccountApiService.confirmEmail(this.userId, this.code).then(
        (response: ResultDTO) => {
          if (response.succeeded) {
            this.setState({
              loading: false,
              confirmSuccess: true,
              error: null,
            });
          } else {
            this.setState({
              loading: false,
              error: response.errors.join('\n'),
              confirmSuccess: false,
            });
          }
        },
        (error) => {
          this.setState({
            loading: false,
            error: error,
            confirmSuccess: false,
          });
        }
      );
    } else {
      this.setState({
        loading: false,
        error: { message: 'Invalid link.' },
        confirmSuccess: false,
      });
    }
  }

  render() {
    let alert: JSX.Element | null = null;
    const { error, confirmSuccess } = this.state;
    if (error !== null) {
      let message = 'Invalid link. Please request another and try again.';
      if (error.message) {
        message = error.message;
      }
      alert = (
        <Alert
          message="Error"
          description={message}
          type="error"
          showIcon={true}
          style={{ marginBottom: '12px', whiteSpace: 'pre-wrap' }}
        />
      );
    }
    if (confirmSuccess) {
      const message = 'Email confirmed successfully!';
      alert = (
        <Alert
          message="Success"
          description={message}
          type="success"
          showIcon={true}
          style={{ marginBottom: '12px' }}
        />
      );
    }

    return (
      // type="flex"
      <Content className="content-background-login">
        <Row justify="center" align="middle">
          <Col className="flex-grow" />
          <Col xxl={6} xl={8} lg={10} md={14} sm={18} xs={24}>
            <Card>
              <img src={process.env.PUBLIC_URL + '/iecdb-logo.svg'} alt="IECDB.Portal" className="full-logo" />
              <PageTitle title="Email Confirmation" style={{ textAlign: 'center', marginBottom: '24px' }} />
              {alert}
              <div
                style={{
                  textAlign: 'center',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {!this.state.loading ? (
                  !this.state.confirmSuccess ? (
                    <Button htmlType="button" size="large" disabled={this.state.loading} shape="round">
                      <Link to={Routes.RESEND_CONFIRMATION_EMAIL}>Resend Confirmation Email</Link>
                    </Button>
                  ) : (
                    <Button htmlType="button" size="large" disabled={this.state.loading} shape="round">
                      <Link to={Routes.LOGIN}>Go to Login</Link>
                    </Button>
                  )
                ) : null}
              </div>
            </Card>
          </Col>
          <Col className="flex-grow" />
        </Row>
      </Content>
    );
  }
}

function mapStateToProps(state: StateStoreModel) {
  let userName: string | null = null;
  if (state.UserSession.Value !== null) {
    userName = state.UserSession.Value.userName;
  }
  return {
    loading: state.UserSession.Loading,
    error: state.UserSession.Error,
    userName: userName,
  };
}

export default withRouter(connect(mapStateToProps)(ConfirmEmailPage));
