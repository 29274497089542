// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the LookupApiTemplate.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
import LookupsApiBase from './LookupsApiBase';
import LookupTableDTO from '../models/LookupTableDTO';
import CodeLookupTableDTO from '../models/CodeLookupTableDTO';
import CommitteeTypeDTO from '../models/CommitteeTypeDTO';
import PfdDivisionDTO from '../models/PfdDivisionDTO';
import PfdPositionDTO from '../models/PfdPositionDTO';
import ExpenditureTypeDTO from '../models/ExpenditureTypeDTO';

export class LookupsApiService extends LookupsApiBase {

    private _getRoles: LookupTableDTO[] = null as any;
    // get: api/lookups/roles?include=${include}
    public getRoles(include?: number | null | null): Promise<LookupTableDTO[]> {
        if(this._getRoles != null) {
            return this.fromLocal(this._getRoles, "Role");
        }
        else {
            let url = `api/lookups/roles`;
            let prefix = url.indexOf('?') > 0 ? '&' : '?';
            if (include != null) {
                 url += `${prefix}include=${include}`;
                 prefix = '&';
            }
            url = url.replace(/null|undefined/gi, '');
            return this.SendRequest<any, LookupTableDTO[]>(null, 'get', url, true, false).
                   then(r=> {this._getRoles = r; return this._getRoles.slice(0);});
        }

    }

    private _getCounties: CodeLookupTableDTO[] = null as any;
    // get: api/lookups/counties
    public getCounties(): Promise<CodeLookupTableDTO[]> {
        if(this._getCounties != null) {
            return this.codeLookupFromLocal(this._getCounties, "CountyType");
        }
        else {
            let url = `api/lookups/counties`;
            url = url.replace(/null|undefined/gi, '');
            return this.SendRequest<any, CodeLookupTableDTO[]>(null, 'get', url, true, false).
                   then(r=> {this._getCounties = r; return this._getCounties.slice(0);});
        }

    }

    private _getStates: CodeLookupTableDTO[] = null as any;
    // get: api/lookups/states
    public getStates(): Promise<CodeLookupTableDTO[]> {
        if(this._getStates != null) {
            return this.codeLookupFromLocal(this._getStates, "StateType");
        }
        else {
            let url = `api/lookups/states`;
            url = url.replace(/null|undefined/gi, '');
            return this.SendRequest<any, CodeLookupTableDTO[]>(null, 'get', url, true, false).
                   then(r=> {this._getStates = r; return this._getStates.slice(0);});
        }

    }

    private _getTitles: CodeLookupTableDTO[] = null as any;
    // get: api/lookups/titles
    public getTitles(): Promise<CodeLookupTableDTO[]> {
        if(this._getTitles != null) {
            return this.codeLookupFromLocal(this._getTitles, "TitleType");
        }
        else {
            let url = `api/lookups/titles`;
            url = url.replace(/null|undefined/gi, '');
            return this.SendRequest<any, CodeLookupTableDTO[]>(null, 'get', url, true, false).
                   then(r=> {this._getTitles = r; return this._getTitles.slice(0);});
        }

    }

    private _getPoliticalParties: CodeLookupTableDTO[] = null as any;
    // get: api/lookups/politicalParties
    public getPoliticalParties(): Promise<CodeLookupTableDTO[]> {
        if(this._getPoliticalParties != null) {
            return this.codeLookupFromLocal(this._getPoliticalParties, "PoliticalPartyType");
        }
        else {
            let url = `api/lookups/politicalParties`;
            url = url.replace(/null|undefined/gi, '');
            return this.SendRequest<any, CodeLookupTableDTO[]>(null, 'get', url, true, false).
                   then(r=> {this._getPoliticalParties = r; return this._getPoliticalParties.slice(0);});
        }

    }

    private _getCommitteeStatuses: CodeLookupTableDTO[] = null as any;
    // get: api/lookups/committee/statuses
    public getCommitteeStatuses(): Promise<CodeLookupTableDTO[]> {
        if(this._getCommitteeStatuses != null) {
            return this.codeLookupFromLocal(this._getCommitteeStatuses, "CommitteeStatusType");
        }
        else {
            let url = `api/lookups/committee/statuses`;
            url = url.replace(/null|undefined/gi, '');
            return this.SendRequest<any, CodeLookupTableDTO[]>(null, 'get', url, true, false).
                   then(r=> {this._getCommitteeStatuses = r; return this._getCommitteeStatuses.slice(0);});
        }

    }

    private _getCommitteeTypes: CommitteeTypeDTO[] = null as any;
    // get: api/lookups/committee/types
    public getCommitteeTypes(): Promise<CommitteeTypeDTO[]> {
        if(this._getCommitteeTypes != null) {
            return this.genericFromLocal(this._getCommitteeTypes, "CommitteeType");
        }
        else {
            let url = `api/lookups/committee/types`;
            url = url.replace(/null|undefined/gi, '');
            return this.SendRequest<any, CommitteeTypeDTO[]>(null, 'get', url, true, false).
                   then(r=> {this._getCommitteeTypes = r; return this._getCommitteeTypes.slice(0);});
        }

    }

    private _getGiftStatuses: LookupTableDTO[] = null as any;
    // get: api/lookups/gift/statuses?include=${include}
    public getGiftStatuses(include?: number | null | null): Promise<LookupTableDTO[]> {
        if(this._getGiftStatuses != null) {
            return this.fromLocal(this._getGiftStatuses, "GbStatus");
        }
        else {
            let url = `api/lookups/gift/statuses`;
            let prefix = url.indexOf('?') > 0 ? '&' : '?';
            if (include != null) {
                 url += `${prefix}include=${include}`;
                 prefix = '&';
            }
            url = url.replace(/null|undefined/gi, '');
            return this.SendRequest<any, LookupTableDTO[]>(null, 'get', url, true, false).
                   then(r=> {this._getGiftStatuses = r; return this._getGiftStatuses.slice(0);});
        }

    }

    private _getDepartments: LookupTableDTO[] = null as any;
    // get: api/lookups/departments?include=${include}
    public getDepartments(include?: number | null | null): Promise<LookupTableDTO[]> {
        if(this._getDepartments != null) {
            return this.fromLocal(this._getDepartments, "PfdDepartment");
        }
        else {
            let url = `api/lookups/departments`;
            let prefix = url.indexOf('?') > 0 ? '&' : '?';
            if (include != null) {
                 url += `${prefix}include=${include}`;
                 prefix = '&';
            }
            url = url.replace(/null|undefined/gi, '');
            return this.SendRequest<any, LookupTableDTO[]>(null, 'get', url, true, false).
                   then(r=> {this._getDepartments = r; return this._getDepartments.slice(0);});
        }

    }

    // get: api/lookups/divisions/${departmentId}
    public getDivisionsByDepartment(departmentId: number): Promise<PfdDivisionDTO[]> {
        let url = `api/lookups/divisions/${departmentId}`;
        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, PfdDivisionDTO[]>(null, 'get', url, true, false);
    }

    // get: api/lookups/positions/${departmentId}/${divisionId}
    public getPositionsByDepartmentAndDivision(departmentId: number, divisionId: number): Promise<PfdPositionDTO[]> {
        let url = `api/lookups/positions/${departmentId}/${divisionId}`;
        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, PfdPositionDTO[]>(null, 'get', url, true, false);
    }

    private _getRelationTypes: CodeLookupTableDTO[] = null as any;
    // get: api/lookups/relationtypes
    public getRelationTypes(): Promise<CodeLookupTableDTO[]> {
        if(this._getRelationTypes != null) {
            return this.codeLookupFromLocal(this._getRelationTypes, "RelationType");
        }
        else {
            let url = `api/lookups/relationtypes`;
            url = url.replace(/null|undefined/gi, '');
            return this.SendRequest<any, CodeLookupTableDTO[]>(null, 'get', url, true, false).
                   then(r=> {this._getRelationTypes = r; return this._getRelationTypes.slice(0);});
        }

    }

    private _getExpenditureTypes: ExpenditureTypeDTO[] = null as any;
    // get: api/lookups/expendituretypes
    public getExpenditureTypes(): Promise<ExpenditureTypeDTO[]> {
        if(this._getExpenditureTypes != null) {
            return this.genericFromLocal(this._getExpenditureTypes, "ExpenditureType");
        }
        else {
            let url = `api/lookups/expendituretypes`;
            url = url.replace(/null|undefined/gi, '');
            return this.SendRequest<any, ExpenditureTypeDTO[]>(null, 'get', url, true, false).
                   then(r=> {this._getExpenditureTypes = r; return this._getExpenditureTypes.slice(0);});
        }

    }

    private _getUserAccountStatuses: LookupTableDTO[] = null as any;
    // get: api/lookups/useraccountstatus
    public getUserAccountStatuses(): Promise<LookupTableDTO[]> {
        if(this._getUserAccountStatuses != null) {
            return this.fromLocal(this._getUserAccountStatuses, "UserAccountStatus");
        }
        else {
            let url = `api/lookups/useraccountstatus`;
            url = url.replace(/null|undefined/gi, '');
            return this.SendRequest<any, LookupTableDTO[]>(null, 'get', url, true, false).
                   then(r=> {this._getUserAccountStatuses = r; return this._getUserAccountStatuses.slice(0);});
        }

    }

    private _getPenaltyTypes: CodeLookupTableDTO[] = null as any;
    // get: api/lookups/penaltytypes
    public getPenaltyTypes(): Promise<CodeLookupTableDTO[]> {
        if(this._getPenaltyTypes != null) {
            return this.codeLookupFromLocal(this._getPenaltyTypes, "PenaltyType");
        }
        else {
            let url = `api/lookups/penaltytypes`;
            url = url.replace(/null|undefined/gi, '');
            return this.SendRequest<any, CodeLookupTableDTO[]>(null, 'get', url, true, false).
                   then(r=> {this._getPenaltyTypes = r; return this._getPenaltyTypes.slice(0);});
        }

    }
}
var service = new LookupsApiService();
export default service;
