import { Divider, Layout, Modal, notification, Typography } from 'antd';
import moment from 'moment';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import SchGConsultantExpensesApiService from '../../api/SchGConsultantExpensesApiService';
import SchGConsultantsApiService from '../../api/SchGConsultantsApiService';
import Dr2AmendedIndicators from '../../consts/Dr2AmendedIndicators';
import Dr2AmendedStatuses from '../../consts/Dr2AmendedStatuses';
import Dr2GroupTypes from '../../consts/Dr2GroupTypes';
import NoteEntityParents from '../../consts/NoteEntityParents';
import Role from '../../consts/Role';
import Routes from '../../consts/Routes';
import ScheduleAbbreviationCodes from '../../consts/ScheduleAbbreviationCodes';
import SchGConsultantExpensesDTO from '../../models/SchGConsultantExpensesDTO';
import CurrentUser from '../../utils/CurrentUser';
import HistoryUtil from '../../utils/HistoryUtil';
import DataTable, { DataTableColumnProps } from '../shared/DataTable/DataTable';
import DataTableButtonUtil from '../shared/DataTable/DataTableButtonUtil';
import DataTableColumnUtil from '../shared/DataTable/DataTableColumnUtil';
import NotesModal from '../shared/NotesModal';
import Dr2StatusBar from './Dr2StatusBar';

const { Content } = Layout;
const { Text } = Typography;
const { confirm } = Modal;

interface SchGConsultantExpensesProps {
  summaryId: string;
  summarySeqNum: string;

}

interface SchGConsultantExpensesState {
  tableColumns: DataTableColumnProps<SchGConsultantExpensesDTO>[];
  summaryId: string;
  summarySeqNum: string;
  summaryConsultantName: string;
  summaryConsultantBeginDate: string;
  summaryConsultantEndDate: string;
  flagNoteId: string;
  conExpId: string;
  showModal: boolean;
}

class SchGConsultantExpenses extends React.Component<RouteComponentProps<SchGConsultantExpensesProps>, SchGConsultantExpensesState> {
  constructor(props: RouteComponentProps<SchGConsultantExpensesProps>) {
    super(props);

    this.state = {
      tableColumns: this.getTableColumns(),
      summaryId: this.props.match.params.summaryId,
      summarySeqNum: this.props.match.params.summarySeqNum,
      summaryConsultantName: '',
      summaryConsultantBeginDate: '',
      summaryConsultantEndDate: '',
      flagNoteId: '',
      conExpId: '',
      showModal: false,
    };
  }

  private dataTable: DataTable<SchGConsultantExpenses> | undefined;

  componentDidMount() {
    this.getSummaryConsultantInfo();
  }

  render() {
    const actionButtons = [];
    actionButtons.push(DataTableButtonUtil.Reset());
    actionButtons.push(DataTableButtonUtil.Primary(
      'New Consultant Expense',
      () => HistoryUtil.push(Routes.generate(Routes.ADD_SCHEDULE_G_CONSULTANT_EXPENSE, {
        summaryId: this.state.summaryId,
        summarySeqNum: this.state.summarySeqNum
      }))
    ));

    const currUser = CurrentUser.Get();
    const { summaryId, summaryConsultantName, summaryConsultantBeginDate, summaryConsultantEndDate, flagNoteId, showModal, conExpId } = this.state;

    return (
     <>
      <Content className="content-pad">
        <Dr2StatusBar dr2Id={currUser?.dr2Id || ''} />
        <Text strong>
          Consultant: {summaryConsultantName}&emsp;|&emsp;
          Beginning Date: {summaryConsultantBeginDate}&emsp;|&emsp;
          Ending Date: {summaryConsultantEndDate} &emsp;|
        </Text>
        <NotesModal parentId={currUser?.dr2Id || ''} parent={NoteEntityParents.SCHEDULE} scheduleCd={ScheduleAbbreviationCodes.SCHEDULEG} />
        <Divider />
        <DataTable
          buttonBar={actionButtons}
          columns={this.state.tableColumns}
          fetchData={{
            fetch: function (tableRequest) {
              return SchGConsultantExpensesApiService.getSchGConsultantExpenses(tableRequest, currUser?.dr2Id || '', summaryId);
            },
            failureMessage: 'Failed to retrieve consultants'
          }}
          globalSearch={true}
          ref={(element: any) => (this.dataTable = element)}
          serverSide={true}
          styleOptions={{ compact: true, alternatingRowHighlight: true }}
          tableProps={{
            rowKey: 'consultantExpenditureIdAndSeq',
            sortDirections: ['ascend', 'descend'],
            locale: { emptyText: 'Currently there are no Consultants to manage.' }
          }}
          title="Schedule G - Consultant Expense"
          stateSaving={{
              enabled: true,
              tableUniqueKey: 'manageSchGExpenses',
              perSession: true,
          }}
        />
        </Content>
        {showModal &&
          <NotesModal
            parentId={conExpId || ''}
            parent={NoteEntityParents.CONSULTANTEXPENDITURE}
            auditorsNoteId={flagNoteId}
            canFlag={true}
            onClose={() => { this.setState({ showModal: false }, () => this.dataTable?.refresh()); }} />
        }
      </>
    );
  }

  private getSummaryConsultantInfo = () => {
    SchGConsultantsApiService.getConsultant(this.state.summaryId, Number(this.state.summarySeqNum))
      .then(summaryConsultant => {
        let summaryConsultantName = '';

        if (summaryConsultant.consultantType == Dr2GroupTypes.INDIVIDUAL) {
          summaryConsultantName = summaryConsultant.lastName + ', ' + summaryConsultant.firstName;

          if (summaryConsultant.middleInitial) {
            summaryConsultantName += ' ' + summaryConsultant.middleInitial;
          }
        }
        else {
          summaryConsultantName = summaryConsultant.consultantName || '';
        }

        this.setState({
          summaryConsultantName,
          summaryConsultantBeginDate: moment.utc(summaryConsultant.fromDate).format('M/D/YYYY').toString(),
          summaryConsultantEndDate: moment.utc(summaryConsultant.toDate).format('M/D/YYYY').toString()
        });
      }).catch(error => {
        notification.error({
          message: 'Error while fetching the summary consultant info',
          description: error.message
        });
      });
  }

  private getTableColumns = (): DataTableColumnProps<SchGConsultantExpensesDTO>[] => {
    return [
      DataTableColumnUtil.Date('Payment Date', 'paymentDate', 100, { defaultSortOrder: 'ascend' }),
      DataTableColumnUtil.Address('Given To', 'givenToName',
        (c) => ({
          name: c.givenToName,
          line1: c.consultantAddressLine1,
          line2: c.consultantAddressLine2,
          city: c.consultantCity,
          state: c.consultantState,
          zip: c.consultantZip
        })
      ),
      DataTableColumnUtil.Currency('Amount', 'paymentAmt'),
      DataTableColumnUtil.DropdownMulti('Status', 'status',
        [
          { text: Dr2AmendedStatuses.ORIGINAL, value: Dr2AmendedIndicators.ORIGINAL },
          { text: Dr2AmendedStatuses.AMENDED, value: Dr2AmendedIndicators.AMENDED },
          { text: Dr2AmendedStatuses.ADJUSTED, value: Dr2AmendedIndicators.ADJUSTED },
          { text: Dr2AmendedStatuses.DELETED, value: Dr2AmendedIndicators.DELETED }
        ]),
      DataTableColumnUtil.Description('Description', 'explanation'),
      DataTableColumnUtil.FlagButton('Flagged', 'flaggedNoteId', CurrentUser.Get()?.isInRole(Role.IECDB_ADMINISTRATOR) || false, [
        {
          onClick: (rowData) => this.openFlagModal(rowData.flaggedNoteId || '', rowData.consultantExpenditureId || ''),
          visible: (rowData) => rowData.flaggedNoteId != null
        }
      ]),
      DataTableColumnUtil.Buttons('consultantExpenditureIdAndSeq',
        [
          {
            text: 'Edit',
            onClick: (rowData) =>
              HistoryUtil.push(Routes.generate(
                Routes.EDIT_SCHEDULE_G_CONSULTANT_EXPENSE,
                {
                  summaryId: this.state.summaryId || '',
                  summarySeqNum: this.state.summarySeqNum || '',
                  expenditureId: rowData.consultantExpenditureId || '',
                  expenditureSeqNum: rowData.seqNumber || ''
                }))
          },
          {
            text: 'Delete',
            onClick: (rowData) => this.confirmDelete(
              rowData.consultantExpenditureId || '',
              rowData.seqNumber || 0,
              rowData.givenToName || '',
              rowData.paymentAmt || 0)
          }
        ],
        150)
    ];
  }

  private openFlagModal = (flagNoteId: string, conExpId: string) => {
    this.setState({ showModal: true, flagNoteId, conExpId }, () => this.dataTable?.refresh());
  }

  private showTableAndRefresh = () => {
    if (this.dataTable) {
      this.dataTable.refresh();
    }
  }

  private confirmDelete = (consultantExpenditureId: string, seqNum: number, consultantName: string, amount: number) => {
    confirm({
      title: 'Are you sure you want to delete this consultant?',
      content: 'The following record will be permanently deleted: ' + consultantName + ' - $' + amount,
      onOk: () => {
        this.deleteContribution(consultantExpenditureId, seqNum);
      },
    });
  }

  private deleteContribution = (consultantExpenditureId: string, seqNum: number) => {
    SchGConsultantExpensesApiService.delete(consultantExpenditureId, seqNum)
      .then(() => {
        this.showTableAndRefresh();
        notification.success({
          message: 'Deleted Successfully'
        });
      }).catch(() => {
        notification.error({
          message: 'Error while deleting consultant',
          description: '',
        });
      });
  }
}

export default SchGConsultantExpenses;