import * as React from 'react';
import { Route, RouteComponentProps, RouteProps } from 'react-router-dom';
import FullscreenLayout from '../../layouts/FullscreenLayout';

interface RoutePropsEx extends RouteProps {
  layout?: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
}

class RouteWithLayout extends React.Component<RoutePropsEx, unknown> {
  constructor(props: RoutePropsEx) {
    super(props);
  }

  getComponent(routerContext: RouteComponentProps<any>) {
    let Layout = this.props.layout;
    const Component = this.props.component;

    if (!Component) {
      throw new Error('component prop must be set!');
    }
    if (!Layout) {
      Layout = FullscreenLayout; // default;
    }
    return (
      <Layout {...routerContext}>
        <Component {...routerContext} />
      </Layout>
    );
  }

  render() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { component, ...props } = this.props;
    return <Route {...props} render={(routerContext) => this.getComponent(routerContext)} />;
  }
}

export default RouteWithLayout;
