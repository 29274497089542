// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
import BaseApi from './BaseApi';
import TableRequestDTO from '../models/TableRequestDTO';
import AdminPeriodListDTO from '../models/AdminPeriodListDTO';
import TableResponseDTO from '../models/TableResponseDTO';
import ResultDTO from '../models/ResultDTO';

export class AdminPeriodMainApiService extends BaseApi {

  // post: api/admin/periodMain/table
  public getPeriodMasterList(tableRequest: TableRequestDTO): Promise<TableResponseDTO<AdminPeriodListDTO>> {
    let url = `api/admin/periodMain/table`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, TableResponseDTO<AdminPeriodListDTO>>(tableRequest, 'post', url, true, false);
  }

  // post: api/admin/periodMain
  public addPeriod(pmData: AdminPeriodListDTO): Promise<void> {
    let url = `api/admin/periodMain`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, void>(pmData, 'post', url, true, false);
  }

  // get: api/admin/periodMain/${periodMasterId}
  public getPeriodEdit(periodMasterId: number): Promise<AdminPeriodListDTO> {
    let url = `api/admin/periodMain/${periodMasterId}`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, AdminPeriodListDTO>(null, 'get', url, true, false);
  }

  // delete: api/admin/periodMain/${periodMasterId}
  public deletePeriod(periodMasterId: number): Promise<ResultDTO> {
    let url = `api/admin/periodMain/${periodMasterId}`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, ResultDTO>(null, 'delete', url, true, false);
  }
}
var service = new AdminPeriodMainApiService();
export default service;
