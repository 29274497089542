// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
import BaseApi from './BaseApi';
import TableRequestDTO from '../models/TableRequestDTO';
import SchGConsultantsFormDTO from '../models/SchGConsultantsFormDTO';
import TableResponseDTO from '../models/TableResponseDTO';
import SchGConsultantsDTO from '../models/SchGConsultantsDTO';

export class SchGConsultantsApiService extends BaseApi {

  // post: api/schGConsultants/table?dr2Id=${encodeURIComponent(dr2Id)}
  public getSchGConsultants(tableRequest: TableRequestDTO, dr2Id: string): Promise<TableResponseDTO<SchGConsultantsDTO>> {
    let url = `api/schGConsultants/table`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (dr2Id != null) {
      url += `${prefix}dr2Id=${encodeURIComponent(dr2Id)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, TableResponseDTO<SchGConsultantsDTO>>(tableRequest, 'post', url, true, false);
  }

  // get: api/schGConsultants/${encodeURIComponent(consultantSummaryId)}/${seqNum}
  public getConsultant(consultantSummaryId: string, seqNum: number): Promise<SchGConsultantsFormDTO> {
    let url = `api/schGConsultants/${encodeURIComponent(consultantSummaryId)}/${seqNum}`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, SchGConsultantsFormDTO>(null, 'get', url, true, false);
  }

  // post: api/schGConsultants/${encodeURIComponent(consultantSummaryId)}/history
  public getConsultantHistory(tableRequest: TableRequestDTO, consultantSummaryId: string): Promise<TableResponseDTO<SchGConsultantsDTO>> {
    let url = `api/schGConsultants/${encodeURIComponent(consultantSummaryId)}/history`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, TableResponseDTO<SchGConsultantsDTO>>(tableRequest, 'post', url, true, false);
  }

  // post: api/schGConsultants?committeeId=${encodeURIComponent(committeeId)}&dr2Id=${encodeURIComponent(dr2Id)}
  public add(newConsultant: SchGConsultantsFormDTO, committeeId: string, dr2Id: string): Promise<string> {
    let url = `api/schGConsultants`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (committeeId != null) {
      url += `${prefix}committeeId=${encodeURIComponent(committeeId)}`;
      prefix = '&';
    }
    if (dr2Id != null) {
      url += `${prefix}dr2Id=${encodeURIComponent(dr2Id)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, string>(newConsultant, 'post', url, true, false);
  }

  // put: api/schGConsultants?committeeId=${encodeURIComponent(committeeId)}&dr2Id=${encodeURIComponent(dr2Id)}&consultantSummaryId=${encodeURIComponent(consultantSummaryId)}&seqNum=${seqNum}
  public edit(newConsultant: SchGConsultantsFormDTO, committeeId: string, dr2Id: string, consultantSummaryId: string, seqNum: number): Promise<void> {
    let url = `api/schGConsultants`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (committeeId != null) {
      url += `${prefix}committeeId=${encodeURIComponent(committeeId)}`;
      prefix = '&';
    }
    if (dr2Id != null) {
      url += `${prefix}dr2Id=${encodeURIComponent(dr2Id)}`;
      prefix = '&';
    }
    if (consultantSummaryId != null) {
      url += `${prefix}consultantSummaryId=${encodeURIComponent(consultantSummaryId)}`;
      prefix = '&';
    }
    if (seqNum != null) {
      url += `${prefix}seqNum=${seqNum}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, void>(newConsultant, 'put', url, true, false);
  }

  // delete: api/schGConsultants?consultantSummaryId=${encodeURIComponent(consultantSummaryId)}&seqNum=${seqNum}
  public delete(consultantSummaryId: string, seqNum: number): Promise<void> {
    let url = `api/schGConsultants`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (consultantSummaryId != null) {
      url += `${prefix}consultantSummaryId=${encodeURIComponent(consultantSummaryId)}`;
      prefix = '&';
    }
    if (seqNum != null) {
      url += `${prefix}seqNum=${seqNum}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, void>(null, 'delete', url, true, false);
  }
}
var service = new SchGConsultantsApiService();
export default service;
