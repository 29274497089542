// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import EntitySelectorDTO from './EntitySelectorDTO';

interface UserSettingsDataDTO extends EntitySelectorDTO { 
  committeeName: string | null;
  committeeType: string | null;
  category: string | null;
  committeeCode: string | null;
  filingType: string | null;
  userName: string | null;
}
const UserSettingsDataDTO = {
  create: (initValues?: Partial<UserSettingsDataDTO> | Record<string, unknown> | null) => {
    return Object.assign(EntitySelectorDTO.create(),
    {
      committeeName: null,
      committeeType: null,
      category: null,
      committeeCode: null,
      filingType: null,
      userName: null,
    },
    initValues);
  }
};

export default UserSettingsDataDTO;