// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
import BaseApi from './BaseApi';
import DrsfaDTO from '../models/DrsfaDTO';

export class DrsfaApiService extends BaseApi {

  // post: api/drsfa?filingStatus=${encodeURIComponent(filingStatus)}
  public add(newDrsfaEntry: DrsfaDTO, filingStatus: string): Promise<void> {
    let url = `api/drsfa`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (filingStatus != null) {
      url += `${prefix}filingStatus=${encodeURIComponent(filingStatus)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, void>(newDrsfaEntry, 'post', url, true, false);
  }
}
var service = new DrsfaApiService();
export default service;
