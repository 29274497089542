import { Modal, Spin } from 'antd';
import * as React from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { getActiveLanguage, Language, LocalizeContextProps, setActiveLanguage, withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';
import { Route, RouteComponentProps, Switch, withRouter } from 'react-router-dom';
import './App.less';
import ConfirmEmailPage from './components/account/ConfirmEmailPage';
import RegisterPage from './components/account/RegisterPage';
import RequestResetPasswordPage from './components/account/RequestResetPasswordPage';
import ResendConfirmationEmailPage from './components/account/ResendConfirmationEmailPage';
import ResetPasswordPage from './components/account/ResetPasswordPage';
import Admin from './components/admin/Admin';
import Contribution from './components/contribution/contribution';
import Dissolution from './components/dissolution/Dissolution';
import DashboardPage from './components/DashboardPage';
import Donor from './components/donor/Donor';
import Organization from './components/organizations/Organization';
import ProfilePage from './components/ProfilePage';
import DONOR_REGISTER from './components/public/campaigns/DonorRegistration';
import DR1 from './components/public/campaigns/DR1/DR1';
import DRSFA from './components/public/campaigns/EditDrsfa';
import IIE from './components/public/campaigns/Iie';
import OIE from './components/public/campaigns/Oie';
import OTC from './components/public/campaigns/Otc';
import PFD from './components/public/campaigns/Pfd/Pfd';
import VSR from './components/public/campaigns/VSR/VSR';
import DualComp from './components/public/campaigns/DualComp';
import Datasets from './components/public/filed_reports/Datasets';
import PublicReports from './components/public/filed_reports/PublicReports';
import PublicDashboard from './components/public/PublicDashboard';
import Schedule from './components/schedule/Schedule';
import Contact from './components/contact/Contact';
import Account from './components/account/Account';
import RouteWithLayout from './components/shared/RouteWithLayout';
import WithAuthorization from './components/shared/WithAuthorization';
import { DefaultLanguage, GetLanguages, OnMissingTranslation, SupportedLanguages } from './config/localizationConfiguration';
import Role from './consts/Role';
import Routes from './consts/Routes';
import FullscreenLayout from './layouts/FullscreenLayout';
import PublicLayout from './layouts/PublicLayout';
import UserSecurity from './models/UserSecurity';
import { UserSessionActions } from './redux/actions/UserSessionActions';
import { VersionActions } from './redux/actions/VersionActions';
import { StateStoreModel } from './redux/state/StateStoreModel';
import CurrentUser from './utils/CurrentUser';
import GoogleAnalytics from './utils/GoogleAnalytics';
import History from './utils/HistoryUtil';
import RoleUtil from './utils/RoleUtil';
import { RedirectAs404 } from './utils/RouteErrors';
import TranslationUtil from './utils/TranslationUtil';
import BuildingFunds from './components/buildingfunds/BuildingFunds';
import EditSalesByState from './components/admin/EditSalesByState';
import PetitionForWaiver from './components/public/PetitionForWaiver';
import NewFiledReports from './components/public/filed_reports/NewFiledReports';

const dashboardPage = WithAuthorization()(DashboardPage);

const orgDetailsPage = WithAuthorization([Role.FILER, Role.IECDB_ADMINISTRATOR])(Organization);
const vsrContributionsPage = WithAuthorization([Role.FILER, Role.IECDB_ADMINISTRATOR])(Contribution);
const dissolutionpage = WithAuthorization([Role.FILER, Role.IECDB_ADMINISTRATOR])(Dissolution);
const profilePage = WithAuthorization()(ProfilePage);
const adminPage = WithAuthorization([Role.IECDB_ADMINISTRATOR, Role.IECDB_AUDITOR, Role.MANUAL_FILER])(Admin);
const donorPage = WithAuthorization([Role.GIFTBEQUEST_DONOR, Role.IECDB_ADMINISTRATOR])(Donor);
const schedulePage = WithAuthorization([Role.IECDB_ADMINISTRATOR, Role.MANUAL_FILER, Role.FILER])(Schedule);
const contactPage = WithAuthorization([Role.IECDB_ADMINISTRATOR, Role.FILER])(Contact);
const accountPage = WithAuthorization([Role.IECDB_ADMINISTRATOR, Role.FILER, Role.GIFTBEQUEST_DONOR])(Account);
const buildingFundsPage = WithAuthorization([Role.IECDB_ADMINISTRATOR, Role.MANUAL_FILER, Role.FILER])(BuildingFunds);

interface AppComponentProps extends LocalizeContextProps {
  userSessionChecking: boolean;
  versionOutdated: boolean;
  versionResetAction: () => void;
  userSessionCheckAction: () => void;
  user: UserSecurity | null;
  activeLanguage: Language;
}

interface AppComponentState {
  firstLoad: boolean;
}

class App extends React.Component<AppComponentProps & RouteComponentProps<RouteObject>, AppComponentState> {
  currentPath = '';
  unlisten: any;

  constructor(props: AppComponentProps & RouteComponentProps<Record<string, string | undefined>>) {
    super(props);

    this.configureLocalization();

    this.state = {
      firstLoad: true
    };
  }

  configureLocalization() {
    const activeLanguage = this.props.activeLanguage;
    let activeLanguageCode = DefaultLanguage;

    if (activeLanguage !== null && activeLanguage !== undefined) {
      activeLanguageCode = activeLanguage.code;
    }

    this.props.initialize({
      languages: SupportedLanguages,
      options: {
        renderToStaticMarkup: renderToStaticMarkup,
        defaultLanguage: activeLanguageCode,
        onMissingTranslation: OnMissingTranslation,
        renderInnerHtml: true
      },
    });

    GetLanguages().forEach((x) => {
      this.props.addTranslationForLanguage(x.translation, x.code);
    });

    if (activeLanguage != null) {
      setActiveLanguage(activeLanguage.code);
    }
    else {
      setActiveLanguage(DefaultLanguage);
    }
  }

  componentDidMount() {
    this.props.userSessionCheckAction();
    this.setState({ firstLoad: false });

    this.currentPath = window.location.pathname;
    this.unlisten = History.listen((location, action) => {

      if (DEBUG) {
        console.groupCollapsed('Route Change');
        console.info('Location', location);
        console.log('Action:', action);
        console.log('User:', CurrentUser.Get());
        console.groupEnd();
      }

      const currentPathTokens = this.currentPath ? this.currentPath.split('/').filter((s) => !!s) : [];
      const nextPathTokens = location.pathname ? location.pathname.split('/').filter((s) => !!s) : [];
      if (currentPathTokens.length > 0 && nextPathTokens.length > 0 && currentPathTokens[0] !== nextPathTokens[0]) {
        if ((document.getElementById('app-main')?.firstChild?.firstChild as any)?.scrollTo) {
          (document.getElementById('app-main')?.firstChild?.firstChild as any)?.scrollTo(0, 0);
        }
      }

      this.currentPath = location.pathname;
      if ($environment === 'Prod') {
        GoogleAnalytics.PageView(location.pathname);
      }
    });
  }

  componentDidUpdate(prevProps: AppComponentProps) {
    if (prevProps.versionOutdated === false && this.props.versionOutdated === true) {
      Modal.confirm({
        title: TranslationUtil.translate('app.outOfDate'),
        content: TranslationUtil.translate('app.outOfDateMessage'),
        onOk: () => {
          this.props.versionResetAction();
          location.reload();
        },
        onCancel: () => {
          this.props.versionResetAction();
        },
      });
    }
  }

  componentWillUnmount() {
    this.unlisten();
  }

  render() {
    if (this.props.userSessionChecking || this.state.firstLoad) {
      return (
        <div className="app-spinner">
          <Spin tip={TranslationUtil.translate('app.loading')} size="large" />
        </div>
      );
    } else {
      let homePage = dashboardPage;
      if (this.props.user !== null) {
        if (RoleUtil.currentUserHasRoles([Role.IECDB_ADMINISTRATOR])) {
          homePage = adminPage;
        }
        else if (RoleUtil.currentUserHasRoles([Role.GIFTBEQUEST_DONOR])) {
          homePage = donorPage;
        }
      }
      return (
        <Switch>
          <RouteWithLayout path={Routes.LOGIN} component={PublicDashboard} layout={PublicLayout} />
          <RouteWithLayout exact={true} path={Routes.HOME_ROUTE} component={homePage} layout={FullscreenLayout} />
          {<RouteWithLayout exact={true} path={Routes.DR1} component={DR1} layout={this.props.user ? FullscreenLayout : PublicLayout} />}
          {<RouteWithLayout exact={true} path={Routes.VSR} component={VSR} layout={this.props.user ? FullscreenLayout : PublicLayout} />}
          {<RouteWithLayout exact={true} path={Routes.OTC} component={OTC} layout={this.props.user ? FullscreenLayout : PublicLayout} />}
          {<RouteWithLayout exact={true} path={Routes.PFD} component={PFD} layout={this.props.user ? FullscreenLayout : PublicLayout} />}
          {!this.props.user ? <RouteWithLayout exact={true} path={Routes.IIE} component={IIE} layout={PublicLayout} /> : undefined}
          {!this.props.user ? <RouteWithLayout exact={true} path={Routes.OIE} component={OIE} layout={PublicLayout} /> : undefined}
          <RouteWithLayout exact={true} path={Routes.DUAL_COMP} component={DualComp} layout={PublicLayout} />
          <RouteWithLayout exact={true} path={Routes.SALES_BY_STATE} component={EditSalesByState} layout={PublicLayout} />
          <RouteWithLayout exact={true} path={Routes.DRSFA} component={DRSFA} layout={PublicLayout} />
          <RouteWithLayout exact={true} path={Routes.DONOR_REGISTER} component={DONOR_REGISTER} layout={PublicLayout} />
          {<RouteWithLayout exact={true}
              path={Routes.PETITION_FOR_WAIVER}
              component={PetitionForWaiver}
              layout={this.props.user ? FullscreenLayout : PublicLayout}
          />}
          <RouteWithLayout path={Routes.REGISTER} component={RegisterPage} layout={PublicLayout} />
          <RouteWithLayout path={Routes.REQUEST_RESET_PASSWORD} component={RequestResetPasswordPage} layout={PublicLayout} />
          <RouteWithLayout path={Routes.RESET_PASSWORD_LINK} component={ResetPasswordPage} layout={PublicLayout} />
          <RouteWithLayout path={Routes.CONFIRM_EMAIL} component={ConfirmEmailPage} layout={PublicLayout} />
          <RouteWithLayout path={Routes.RESEND_CONFIRMATION_EMAIL} component={ResendConfirmationEmailPage} layout={PublicLayout} />
          <RouteWithLayout path={Routes.PROFILE} component={profilePage} />
          <RouteWithLayout path={Routes.ADMIN_BASE} component={adminPage} layout={FullscreenLayout} />
          <RouteWithLayout path={Routes.ORGANIZATIONS_BASE} component={orgDetailsPage} layout={FullscreenLayout} />
          <RouteWithLayout path={Routes.DONOR_BASE} component={donorPage} layout={FullscreenLayout} />
          <RouteWithLayout path={Routes.SCHEDULE_BASE} component={schedulePage} layout={FullscreenLayout} />
          <RouteWithLayout path={Routes.CONTACTS_BASE} component={contactPage} layout={FullscreenLayout} />
          <RouteWithLayout path={Routes.ACCOUNT_BASE} component={accountPage} layout={FullscreenLayout} />
          <RouteWithLayout path={Routes.PUBLIC_REPORTS} component={PublicReports} layout={PublicLayout} />
          <RouteWithLayout path={Routes.VSR_CONTRIBUTIONS} component={vsrContributionsPage} layout={FullscreenLayout} />
          <RouteWithLayout path={Routes.DISSOLUTION_BASE} component={dissolutionpage} layout={FullscreenLayout} />
          <RouteWithLayout path={Routes.BUILDINGFUNDS_BASE} component={buildingFundsPage} layout={FullscreenLayout} />

          {/* Temporary until ethics.iowa.gov is updated with new links */}
          <RouteWithLayout exact={true} path={Routes.__OLD_PUBLIC_REPORTS} component={NewFiledReports} layout={PublicLayout} />
          <RouteWithLayout exact={true} path={Routes.__OLD_DEFAULT} component={PublicDashboard} layout={PublicLayout} />
          <RouteWithLayout exact={true} path={Routes.__OLD_DRSFA} component={DRSFA} layout={PublicLayout} />
          <RouteWithLayout exact={true} path={Routes.__OLD_OTC} component={OTC} layout={PublicLayout} />
          <RouteWithLayout exact={true} path={Routes.__OLD_PFD} component={PFD} layout={PublicLayout} />
          <RouteWithLayout exact={true} path={Routes.__OLD_SEARCHABLE_DATASET} component={Datasets} layout={PublicLayout} />
          <RouteWithLayout exact={true} path={Routes.__OLD_INTRO} component={NewFiledReports} layout={PublicLayout} />
          <RouteWithLayout exact={true} path={Routes.__OLD_DR1} component={DR1} layout={PublicLayout} />
          <RouteWithLayout exact={true} path={Routes.__OLD_REPORTS_SEARCH} component={NewFiledReports} layout={PublicLayout} />

          {/* This needs to be the last item */}
          <Route component={RedirectAs404} />
        </Switch>
      );
    }
  }
}

function mapDispatchToProps(dispatch: any) {
  return {
    userSessionCheckAction: () => dispatch(UserSessionActions.check()),
    versionResetAction: () => dispatch(VersionActions.reset()),
  };
}

function mapStateToProps(state: StateStoreModel) {
  return {
    activeLanguage: getActiveLanguage(state.localize),
    userSessionChecking: state.UserSession.Checking,
    versionOutdated: state.Version.Outdated,
    user: state.UserSession.Value,
  };
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withLocalize(App)));
