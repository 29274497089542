// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Constants.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
export default class Dr2GroupTypes {
    public static readonly INDIVIDUAL: string = 'Individual';
    public static readonly COMMITTEE: string = 'Committee';
    public static readonly TRUST: string = 'Trust';
    public static readonly COMPANYOTHER: string = 'Company/Other';
    public static readonly UNITEMIZED: string = 'Unitemized';
}
