// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Constants.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
export default class CommFilingPeriodCatTypeCode {
    public static readonly SWGA: string = 'SWGA';
    public static readonly SCPA: string = 'SCPA';
    public static readonly CENT: string = 'CENT';
    public static readonly CTY: string = 'CTY';
    public static readonly LOCL: string = 'LOCL';
    public static readonly NA: string = 'N/A';
    public static readonly BALLOT: string = 'BALLOT';
}
