// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
import BaseApi from './BaseApi';
import TableRequestDTO from '../models/TableRequestDTO';
import SchGConsultantExpensesFormDTO from '../models/SchGConsultantExpensesFormDTO';
import TableResponseDTO from '../models/TableResponseDTO';
import SchGConsultantExpensesDTO from '../models/SchGConsultantExpensesDTO';

export class SchGConsultantExpensesApiService extends BaseApi {

  // post: api/schGConsultantExpenses/table?dr2Id=${encodeURIComponent(dr2Id)}&consultantSummaryId=${encodeURIComponent(consultantSummaryId)}
  public getSchGConsultantExpenses(tableRequest: TableRequestDTO, dr2Id: string, consultantSummaryId: string): Promise<TableResponseDTO<SchGConsultantExpensesDTO>> {
    let url = `api/schGConsultantExpenses/table`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (dr2Id != null) {
      url += `${prefix}dr2Id=${encodeURIComponent(dr2Id)}`;
      prefix = '&';
    }
    if (consultantSummaryId != null) {
      url += `${prefix}consultantSummaryId=${encodeURIComponent(consultantSummaryId)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, TableResponseDTO<SchGConsultantExpensesDTO>>(tableRequest, 'post', url, true, false);
  }

  // get: api/schGConsultantExpenses/${encodeURIComponent(consultantExpenseId)}/${seqNum}
  public getConsultantExpense(consultantExpenseId: string, seqNum: number): Promise<SchGConsultantExpensesFormDTO> {
    let url = `api/schGConsultantExpenses/${encodeURIComponent(consultantExpenseId)}/${seqNum}`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, SchGConsultantExpensesFormDTO>(null, 'get', url, true, false);
  }

  // post: api/schGConsultantExpenses/${encodeURIComponent(consultantExpenseId)}/history
  public getConsultantExpenseHistory(tableRequest: TableRequestDTO, consultantExpenseId: string): Promise<TableResponseDTO<SchGConsultantExpensesDTO>> {
    let url = `api/schGConsultantExpenses/${encodeURIComponent(consultantExpenseId)}/history`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, TableResponseDTO<SchGConsultantExpensesDTO>>(tableRequest, 'post', url, true, false);
  }

  // post: api/schGConsultantExpenses?committeeId=${encodeURIComponent(committeeId)}&dr2Id=${encodeURIComponent(dr2Id)}&consultantSummaryId=${encodeURIComponent(consultantSummaryId)}
  public add(newConsultant: SchGConsultantExpensesFormDTO, committeeId: string, dr2Id: string, consultantSummaryId: string): Promise<void> {
    let url = `api/schGConsultantExpenses`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (committeeId != null) {
      url += `${prefix}committeeId=${encodeURIComponent(committeeId)}`;
      prefix = '&';
    }
    if (dr2Id != null) {
      url += `${prefix}dr2Id=${encodeURIComponent(dr2Id)}`;
      prefix = '&';
    }
    if (consultantSummaryId != null) {
      url += `${prefix}consultantSummaryId=${encodeURIComponent(consultantSummaryId)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, void>(newConsultant, 'post', url, true, false);
  }

  // put: api/schGConsultantExpenses?committeeId=${encodeURIComponent(committeeId)}&dr2Id=${encodeURIComponent(dr2Id)}&consultantExpenseId=${encodeURIComponent(consultantExpenseId)}&seqNum=${seqNum}&consultantSummaryId=${encodeURIComponent(consultantSummaryId)}
  public edit(newConsultant: SchGConsultantExpensesFormDTO, committeeId: string, dr2Id: string, consultantExpenseId: string, seqNum: number, consultantSummaryId: string): Promise<void> {
    let url = `api/schGConsultantExpenses`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (committeeId != null) {
      url += `${prefix}committeeId=${encodeURIComponent(committeeId)}`;
      prefix = '&';
    }
    if (dr2Id != null) {
      url += `${prefix}dr2Id=${encodeURIComponent(dr2Id)}`;
      prefix = '&';
    }
    if (consultantExpenseId != null) {
      url += `${prefix}consultantExpenseId=${encodeURIComponent(consultantExpenseId)}`;
      prefix = '&';
    }
    if (seqNum != null) {
      url += `${prefix}seqNum=${seqNum}`;
      prefix = '&';
    }
    if (consultantSummaryId != null) {
      url += `${prefix}consultantSummaryId=${encodeURIComponent(consultantSummaryId)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, void>(newConsultant, 'put', url, true, false);
  }

  // delete: api/schGConsultantExpenses?consultantExpenseId=${encodeURIComponent(consultantExpenseId)}&seqNum=${seqNum}
  public delete(consultantExpenseId: string, seqNum: number): Promise<void> {
    let url = `api/schGConsultantExpenses`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (consultantExpenseId != null) {
      url += `${prefix}consultantExpenseId=${encodeURIComponent(consultantExpenseId)}`;
      prefix = '&';
    }
    if (seqNum != null) {
      url += `${prefix}seqNum=${seqNum}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, void>(null, 'delete', url, true, false);
  }
}
var service = new SchGConsultantExpensesApiService();
export default service;
