// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
import BaseApi from './BaseApi';
import Dr1HistoryDTO from '../models/Dr1HistoryDTO';
import TableRequestDTO from '../models/TableRequestDTO';
import TableExportDTO from '../models/TableExportDTO';
import TableResponseDTO from '../models/TableResponseDTO';
import StatementsOfOrganizationAndDissolutionDTO from '../models/StatementsOfOrganizationAndDissolutionDTO';
import StateReportsDTO from '../models/StateReportsDTO';
import OneTimeContributionsDTO from '../models/OneTimeContributionsDTO';
import VerifiedStatementsOfRegistrationDTO from '../models/VerifiedStatementsOfRegistrationDTO';
import IndependentExpendituresDTO from '../models/IndependentExpendituresDTO';
import CountyReportsDTO from '../models/CountyReportsDTO';
import GbReportsDTO from '../models/GbReportsDTO';
import DualCompReportsDTO from '../models/DualCompReportsDTO';
import PfdReportsDTO from '../models/PfdReportsDTO';
import AdminEscheatDTO from '../models/AdminEscheatDTO';
import CommitteeLateReportsDTO from '../models/CommitteeLateReportsDTO';
import IndependentExpenditureLateReportsDTO from '../models/IndependentExpenditureLateReportsDTO';
import OTCLateReportsDTO from '../models/OTCLateReportsDTO';
import PFDLateReportsDTO from '../models/PFDLateReportsDTO';
import VSRLateReportsDTO from '../models/VSRLateReportsDTO';
import FilingPeriodColumnLookupDTO from '../models/FilingPeriodColumnLookupDTO';
import LateReportFilterDTO from '../models/LateReportFilterDTO';
import BuildingFundReportDTO from '../models/BuildingFundReportDTO';
import AdminConsentForSalesAndLeasesDTO from '../models/AdminConsentForSalesAndLeasesDTO';
import SalesByStateReportsDTO from '../models/SalesByStateReportsDTO';

export class PublicReportsApiService extends BaseApi {

  // post: api/publicreports/view?fileName=${encodeURIComponent(fileName)}
  public getReportUrl(fileName: string): Promise<string> {
    let url = `api/publicreports/view`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (fileName != null) {
      url += `${prefix}fileName=${encodeURIComponent(fileName)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, string>(null, 'post', url, true, false);
  }

  // post: api/publicreports/statements
  public getStatementsOfOrganizationsAndDissolution(history: Dr1HistoryDTO): Promise<TableResponseDTO<StatementsOfOrganizationAndDissolutionDTO>> {
    let url = `api/publicreports/statements`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, TableResponseDTO<StatementsOfOrganizationAndDissolutionDTO>>(history, 'post', url, true, false);
  }

  // post: api/publicreports/state
  public getStateReports(tableRequest: TableRequestDTO): Promise<TableResponseDTO<StateReportsDTO>> {
    let url = `api/publicreports/state`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, TableResponseDTO<StateReportsDTO>>(tableRequest, 'post', url, true, false);
  }

  // post: api/publicreports/otc
  public getOTC(tableRequest: TableRequestDTO): Promise<TableResponseDTO<OneTimeContributionsDTO>> {
    let url = `api/publicreports/otc`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, TableResponseDTO<OneTimeContributionsDTO>>(tableRequest, 'post', url, true, false);
  }

  // post: api/publicreports/vsr
  public getVSR(tableRequest: TableRequestDTO): Promise<TableResponseDTO<VerifiedStatementsOfRegistrationDTO>> {
    let url = `api/publicreports/vsr`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, TableResponseDTO<VerifiedStatementsOfRegistrationDTO>>(tableRequest, 'post', url, true, false);
  }

  // post: api/publicreports/ie
  public getIE(tableRequest: TableRequestDTO): Promise<TableResponseDTO<IndependentExpendituresDTO>> {
    let url = `api/publicreports/ie`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, TableResponseDTO<IndependentExpendituresDTO>>(tableRequest, 'post', url, true, false);
  }

  // post: api/publicreports/countyReports
  public getCountyReports(tableRequest: TableRequestDTO): Promise<TableResponseDTO<CountyReportsDTO>> {
    let url = `api/publicreports/countyReports`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, TableResponseDTO<CountyReportsDTO>>(tableRequest, 'post', url, true, false);
  }

  // post: api/publicreports/gb
  public getGbReports(tableRequest: TableRequestDTO): Promise<TableResponseDTO<GbReportsDTO>> {
    let url = `api/publicreports/gb`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, TableResponseDTO<GbReportsDTO>>(tableRequest, 'post', url, true, false);
  }

  // post: api/publicreports/dual-comp
  public getDualCompReports(tableRequest: TableRequestDTO): Promise<TableResponseDTO<DualCompReportsDTO>> {
    let url = `api/publicreports/dual-comp`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, TableResponseDTO<DualCompReportsDTO>>(tableRequest, 'post', url, true, false);
  }

  // post: api/publicreports/pfd
  public getPfdReports(tableRequest: TableRequestDTO): Promise<TableResponseDTO<PfdReportsDTO>> {
    let url = `api/publicreports/pfd`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, TableResponseDTO<PfdReportsDTO>>(tableRequest, 'post', url, true, false);
  }

  // post: api/publicreports/escheat
  public getEscheatReports(tableRequest: TableRequestDTO): Promise<TableResponseDTO<AdminEscheatDTO>> {
    let url = `api/publicreports/escheat`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, TableResponseDTO<AdminEscheatDTO>>(tableRequest, 'post', url, true, false);
  }

  // post: api/publicreports/committeeLateReport?convertToCST=${convertToCST}
  public getCommitteeLateReports(tableRequest: TableRequestDTO, convertToCST: boolean): Promise<TableResponseDTO<CommitteeLateReportsDTO>> {
    let url = `api/publicreports/committeeLateReport`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (convertToCST != null) {
      url += `${prefix}convertToCST=${convertToCST}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, TableResponseDTO<CommitteeLateReportsDTO>>(tableRequest, 'post', url, true, false);
  }

  // post: api/publicreports/committeeLateReportList?year=${year}
  public getCommitteeLateReportsList(year: number): Promise<CommitteeLateReportsDTO[]> {
    let url = `api/publicreports/committeeLateReportList`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (year != null) {
      url += `${prefix}year=${year}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, CommitteeLateReportsDTO[]>(null, 'post', url, true, false);
  }

  // post: api/publicreports/independentExpendituresLateReport?convertToCST=${convertToCST}
  public getIndependentExpenditureLateReports(tableRequest: TableRequestDTO, convertToCST: boolean): Promise<TableResponseDTO<IndependentExpenditureLateReportsDTO>> {
    let url = `api/publicreports/independentExpendituresLateReport`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (convertToCST != null) {
      url += `${prefix}convertToCST=${convertToCST}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, TableResponseDTO<IndependentExpenditureLateReportsDTO>>(tableRequest, 'post', url, true, false);
  }

  // post: api/publicreports/otcLateReport?convertToCST=${convertToCST}
  public getOTCLateReports(tableRequest: TableRequestDTO, convertToCST: boolean): Promise<TableResponseDTO<OTCLateReportsDTO>> {
    let url = `api/publicreports/otcLateReport`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (convertToCST != null) {
      url += `${prefix}convertToCST=${convertToCST}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, TableResponseDTO<OTCLateReportsDTO>>(tableRequest, 'post', url, true, false);
  }

  // post: api/publicreports/pfdLateReport?convertToCST=${convertToCST}
  public getPFDLateReports(tableRequest: TableRequestDTO, convertToCST: boolean): Promise<TableResponseDTO<PFDLateReportsDTO>> {
    let url = `api/publicreports/pfdLateReport`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (convertToCST != null) {
      url += `${prefix}convertToCST=${convertToCST}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, TableResponseDTO<PFDLateReportsDTO>>(tableRequest, 'post', url, true, false);
  }

  // get: api/publicreports/exportCommitteeLateReport
  public exportCommitteeLateReports(tableExportDTO: TableExportDTO): Promise<void> {
    let url = `api/publicreports/exportCommitteeLateReport`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, void>(tableExportDTO, 'get', url, true, true);
  }

  // get: api/publicreports/exportIndependentExpenditureLateReport
  public exportIndependentExpenditureLateReports(tableExportDTO: TableExportDTO): Promise<void> {
    let url = `api/publicreports/exportIndependentExpenditureLateReport`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, void>(tableExportDTO, 'get', url, true, true);
  }

  // get: api/publicreports/exportOtcLateReport
  public exportOtcLateReports(tableExportDTO: TableExportDTO): Promise<void> {
    let url = `api/publicreports/exportOtcLateReport`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, void>(tableExportDTO, 'get', url, true, true);
  }

  // get: api/publicreports/exportPfdLateReport
  public exportPfdLateReports(tableExportDTO: TableExportDTO): Promise<void> {
    let url = `api/publicreports/exportPfdLateReport`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, void>(tableExportDTO, 'get', url, true, true);
  }

  // post: api/publicreports/vsrLateReport?convertToCST=${convertToCST}
  public getVSRLateReports(tableRequest: TableRequestDTO, convertToCST: boolean): Promise<TableResponseDTO<VSRLateReportsDTO>> {
    let url = `api/publicreports/vsrLateReport`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (convertToCST != null) {
      url += `${prefix}convertToCST=${convertToCST}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, TableResponseDTO<VSRLateReportsDTO>>(tableRequest, 'post', url, true, false);
  }

  // get: api/publicreports/exportVSRLateReport
  public exportVSRLateReports(tableExportDTO: TableExportDTO): Promise<void> {
    let url = `api/publicreports/exportVSRLateReport`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, void>(tableExportDTO, 'get', url, true, true);
  }

  // get: api/publicreports/filingPeriods?year=${encodeURIComponent(year)}&startYear=${encodeURIComponent(startYear)}&isState=${isState}
  public getFilingPeriodsByYear(year: string, startYear: string, isState: boolean): Promise<FilingPeriodColumnLookupDTO[]> {
    let url = `api/publicreports/filingPeriods`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (year != null) {
      url += `${prefix}year=${encodeURIComponent(year)}`;
      prefix = '&';
    }
    if (startYear != null) {
      url += `${prefix}startYear=${encodeURIComponent(startYear)}`;
      prefix = '&';
    }
    if (isState != null) {
      url += `${prefix}isState=${isState}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, FilingPeriodColumnLookupDTO[]>(null, 'get', url, true, false);
  }

  // get: api/publicreports/filteredLateReports?committeeId=${encodeURIComponent(committeeId)}
  public getCommitteelateReportFilterInfo(committeeId: string): Promise<LateReportFilterDTO> {
    let url = `api/publicreports/filteredLateReports`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (committeeId != null) {
      url += `${prefix}committeeId=${encodeURIComponent(committeeId)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, LateReportFilterDTO>(null, 'get', url, true, false);
  }

  // get: api/publicreports/downloadLateNotice?fileName=${encodeURIComponent(fileName)}
  public downloadFirstLateNoticeReport(fileName: string): Promise<void> {
    let url = `api/publicreports/downloadLateNotice`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (fileName != null) {
      url += `${prefix}fileName=${encodeURIComponent(fileName)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, void>(null, 'get', url, true, true);
  }

  // post: api/publicreports/buildingFund
  public getBuildingFundReports(tableRequest: TableRequestDTO): Promise<TableResponseDTO<BuildingFundReportDTO>> {
    let url = `api/publicreports/buildingFund`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, TableResponseDTO<BuildingFundReportDTO>>(tableRequest, 'post', url, true, false);
  }

  // post: api/publicreports/cfsl
  public getConsentForSaleAndLeases(tableRequest: TableRequestDTO): Promise<TableResponseDTO<AdminConsentForSalesAndLeasesDTO>> {
    let url = `api/publicreports/cfsl`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, TableResponseDTO<AdminConsentForSalesAndLeasesDTO>>(tableRequest, 'post', url, true, false);
  }

  // post: api/publicreports/salesByState
  public getSalesByStateReports(tableRequest: TableRequestDTO): Promise<TableResponseDTO<SalesByStateReportsDTO>> {
    let url = `api/publicreports/salesByState`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, TableResponseDTO<SalesByStateReportsDTO>>(tableRequest, 'post', url, true, false);
  }
}
var service = new PublicReportsApiService();
export default service;
