import { InputNumber, InputNumberProps } from 'antd';
import * as React from 'react';

interface CurrencyInputProps extends InputNumberProps {
}

class CurrencyInput extends React.Component<CurrencyInputProps, unknown> {
  constructor(props: CurrencyInputProps) {
    super(props);
  }

  render() {
    return (
      <InputNumber
        maxLength={12}
        min={0}
        {...this.props}
        precision={2}
        style={{ width: '100%', ...(this.props.style || {}) }}
        formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
        parser={value => value ? value.replace(/\$\s?|(,*)/g, '') : ''}
      />
    );
  }
}

export default CurrencyInput;
