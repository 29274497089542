import { LoadingOutlined, MenuOutlined } from '@ant-design/icons';
import { Button, Col, Dropdown, Menu, Modal, notification, Row, Space } from 'antd';
import moment from 'moment';
import * as React from 'react';
import BuildingFundFilingDTO from '../../models/BuildingFundFilingDTO';
import PeriodDueDateDTO from '../../models/PeriodDueDateDTO';
import BuildingFundApiService from '../../api/BuildingFundApiService';
import FilingStatusType from '../../consts/FilingStatusType';
import CurrentUser from '../../utils/CurrentUser';
import Role from '../../consts/Role';
import HistoryUtil from '../../utils/HistoryUtil';
import Routes from '../../consts/Routes';

interface BuildingFundsStatusBarButtonsProps {
    showAdjust: () => void;
    summaryInfo: BuildingFundFilingDTO;
    refreshStatusBar: () => void;
    filed?: boolean;
}

interface BuildingFundsStatusBarButtonsState {
    loading: boolean;
    completeDisabled: boolean;
    extraSmallWindow: boolean;
    dueDates: PeriodDueDateDTO;
    filingDueDate: string;
}

class BuildingFundsStatusBarButtons extends React.Component<BuildingFundsStatusBarButtonsProps, BuildingFundsStatusBarButtonsState> {
    constructor(props: BuildingFundsStatusBarButtonsProps) {
        super(props);

        this.state = {
            filingDueDate: '',
            loading: false,
            completeDisabled: false,
            extraSmallWindow: this.isExtraSmallWindow(),
            dueDates: PeriodDueDateDTO.create(
                {
                    beginDueDate: moment.utc(),
                    endDueDate: moment.utc(),
                    adjustedDueDate: moment.utc(),
                    filingDueDate: moment.utc(),
                    filedDate: moment.utc(),
                    amendedDate: moment.utc()
                })
        };
    }

    componentDidMount() {
        window.onresize = this.handleWindowResize;
    }

    componentWillUnmount() {
        window.onresize = null;
    }

    render() {
        const isAdmin = CurrentUser.Get()?.isInRole(Role.IECDB_ADMINISTRATOR);
        const { showAdjust, summaryInfo, filed } = this.props;
        const { loading, completeDisabled, extraSmallWindow } = this.state;

        const menu = (
            <Menu>
                <Menu.Item onClick={() => {
                    HistoryUtil.push((
                        Routes.generate(Routes.BUILDINGFUNDS_HISTORY, { id: CurrentUser.Get()?.committeeId || '' })
                    ));
                }}>
                    Filing History
                </Menu.Item>
                {(filed && isAdmin) &&
                    <Menu.Item onClick=
                        {
                            () => this.confimDelete()
                        }
                    > Delete Filing
                    </Menu.Item>
                }
                {isAdmin &&
                    <>
                        <Menu.Item onClick={() => {this.confirmAudit();}}>
                            Audit
                        </Menu.Item>
                        <Menu.Item onClick={() => showAdjust()}>
                            Adjust Balance
                        </Menu.Item>
                    </>
                }
                <Menu.Item onClick={() => this.printComplete()} disabled={completeDisabled}>
                    Print Complete Report {completeDisabled && <LoadingOutlined />}
                </Menu.Item>
            </Menu>
        );

        const normalWindowList = (<Row style={{ flexDirection: 'row' }}>
            <Space style={{ flex: 1, flexWrap: 'wrap' }}>
                <Col>
                    <Button className="option-menu-button"
                        onClick={() => {
                            HistoryUtil.push((
                                Routes.generate(Routes.BUILDINGFUNDS_HISTORY, { id: CurrentUser.Get()?.committeeId || '' })
                            ));
                        }}>
                        Filing History
                    </Button>
                </Col>
                {(filed && isAdmin) &&
                    <Col>
                        <Button className="option-menu-button"
                            onClick={() => this.confimDelete()}> Delete Filing
                        </Button>
                    </Col>
                }
                {isAdmin &&
                    <>

                    {summaryInfo.filingStatusId != FilingStatusType.AUDITED && 
                        <Col>
                            <Button className="option-menu-button" onClick={() => this.confirmAudit()}>
                                Audit
                            </Button>
                        </Col>
                    }
                        <Col>
                            <Button className="option-menu-button" onClick={() => showAdjust()}>
                                Adjust Balance
                            </Button>
                        </Col>
                    </>
                }
                <Col>
                    <Button className="option-menu-button" onClick={() => this.printComplete()} disabled={completeDisabled}>
                        Print Complete Report {completeDisabled && <LoadingOutlined />}
                    </Button>
                </Col>
                {loading && <LoadingOutlined />}
            </Space>
        </Row>);

        //set trigger to "click" on mobile/touchscreen devices
        let dropdownTrigger: 'hover' | 'click' | 'contextMenu' = 'hover';
        if ('ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0) {
            dropdownTrigger = 'click';
        }

        return (
            extraSmallWindow ? (<Space>
                <Dropdown overlay={menu} trigger={[dropdownTrigger]}>
                    <MenuOutlined style={{ fontSize: '24px' }} />
                </Dropdown>
                {loading && <LoadingOutlined />}
            </Space>) : normalWindowList
        );
    }

    private handleWindowResize = () => {
        if (this.state.extraSmallWindow !== this.isExtraSmallWindow()) {
            this.setState({ extraSmallWindow: !this.state.extraSmallWindow });
        }
    }

    private isExtraSmallWindow = () => {
        return !window.matchMedia('(min-width: 768px)').matches;
    }

    private confirmAudit = () => {
        if (this.props.summaryInfo != null) {
            Modal.confirm({
                title: 'Are you sure you want to mark this filing as audited?',
                onOk: () => {
                    BuildingFundApiService.auditBuildingFund(this.props.summaryInfo.buildingFundFilingId)
                        .then(result => {
                            if (result && result.succeeded) {
                                notification.success({ message: 'Successfully audited filing.' });
                                this.props.refreshStatusBar();
                                return;
                            }
                            notification.error({ message: 'Failed to audit filing.', description: result.errors[0] });
                        });
                }
            });
        }
    }

    private confimDelete = () => {
        if (this.props.summaryInfo != null) {
            Modal.confirm({
                title: 'Are you sure you want to delete this filing for the covered year of ' + this.props.summaryInfo.coveredYear,
                onOk: () => {
                    BuildingFundApiService.deleteBuildingFund(this.props.summaryInfo.buildingFundFilingId)
                        .then(result => {
                            if (result && result.succeeded) {
                                notification.success({ message: 'Successfully deleted filing.' });
                                this.props.refreshStatusBar();
                                return;
                            }
                            notification.error({ message: 'Failed to delete filing.', description: result.errors});
                        });
                }
            });
        }
    }

    private printComplete = () => {
        this.setState({ completeDisabled: true });
        if (this.props.summaryInfo.buildingFundFilingId != null && this.props.summaryInfo.coveredYear) {
            BuildingFundApiService.printBuildingFundReport(
                this.props.summaryInfo.buildingFundFilingId,
                CurrentUser.Get()?.committeeName || '',
                this.props.summaryInfo.coveredYear
            ).catch(() => {
                notification.error({
                    message: 'Error while generating report.'
                });
            });
        }
        this.setState({ completeDisabled: false });
    }
}

export default BuildingFundsStatusBarButtons;