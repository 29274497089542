// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Constants.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
export default class PenaltyType {
    public static readonly DR2: string = 'DR2';
    public static readonly INDIVIDUAL_INDEPENDENT_EXPENDITURE: string = 'IIE';
    public static readonly ORGANIZATION_INDEPENDENT_EXPENDITURE: string = 'OIE';
    public static readonly ONETIME_CONTRIBUTIONS: string = 'OTC';
    public static readonly PERSONAL_FINANCIAL_DISCLOSURES: string = 'PFD';
    public static readonly VERIFIED_STATEMENT_OF_REGISTRATION: string = 'VSR';
}
