// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
import BaseApi from './BaseApi';
import OriginalExecAgencyDTO from '../models/OriginalExecAgencyDTO';

export class OriginalExecAgencyApiService extends BaseApi {

  // get: api/original-exec-agency/${encodeURIComponent(name)}
  public getAgencyByName(name: string): Promise<OriginalExecAgencyDTO[]> {
    let url = `api/original-exec-agency/${encodeURIComponent(name)}`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, OriginalExecAgencyDTO[]>(null, 'get', url, true, false);
  }

  // get: api/original-exec-agency
  public getAgencyList(): Promise<OriginalExecAgencyDTO[]> {
    let url = `api/original-exec-agency`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, OriginalExecAgencyDTO[]>(null, 'get', url, true, false);
  }
}
var service = new OriginalExecAgencyApiService();
export default service;
