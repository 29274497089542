// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Constants.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
export default class CommitteeStatusType {
    public static readonly APPROVED: string = '1';
    public static readonly DISSOLVED: string = '2';
    public static readonly PENDING_APPROVAL: string = '3';
    public static readonly REJECTED: string = '4';
    public static readonly INACTIVE: string = '5';
    public static readonly PENDING_USER_APPROVAL: string = '6';
    public static readonly PENDING_DISSOLUTION: string = '7';
    public static readonly DELETED: string = '8';
}
