// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Constants.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
export default class TransactionTypeIndicators {
    public static readonly CONTRIBUTION: string = 'CON';
    public static readonly EXPENDITURE: string = 'EXP';
    public static readonly INKINDCONTRIBUTION: string = 'INK';
    public static readonly LOANRECEIVED: string = 'LOANREC';
    public static readonly LOANREPAID: string = 'LOANREP';
}
