import * as React from 'react';
import SearchableDatabase from '../shared/SearchableDatabase';

interface AdminSearchProps {
}

interface AdminSearchState {
}

class AdminSearch extends React.Component<AdminSearchProps, AdminSearchState> {
  constructor(props: AdminSearchProps) {
    super(props);
    this.state = {
    };
  }

  render() {
    return (
      <SearchableDatabase />
    );
  }
}

export default AdminSearch;
