import { LoadingOutlined, MenuOutlined } from '@ant-design/icons';
import { Button, Col, Dropdown, Menu, Modal, notification, Row, Space } from 'antd';
import moment from 'moment';
import * as React from 'react';
import Dr2ApiService from '../../api/Dr2ApiService';
import Role from '../../consts/Role';
import Routes from '../../consts/Routes';
import PeriodDueDateDTO from '../../models/PeriodDueDateDTO';
import CurrentUser from '../../utils/CurrentUser';
import DateUtil from '../../utils/DateUtil';
import HistoryUtil from '../../utils/HistoryUtil';


interface Dr2StatusBarButtonsProps {
  showAdjust: () => void;
  unfiled: boolean;
  filingPeriodName?: string;
  refreshStatusBar: (dr2Id: string) => void;
}

interface Dr2StatusBarButtonsState {
  loading: boolean;
  dr2CompleteDisabled: boolean;
  dr2SummaryDisabled: boolean;
  filingPeriodName: string;
  extraSmallWindow: boolean;
  dueDates: PeriodDueDateDTO;
  filingDueDate: string;
}

class Dr2StatusBarButtons extends React.Component<Dr2StatusBarButtonsProps, Dr2StatusBarButtonsState> {
  constructor(props: Dr2StatusBarButtonsProps) {
    super(props);

    this.state = {
      filingDueDate: '',
      loading: false,
      dr2CompleteDisabled: false,
      dr2SummaryDisabled: false,
      filingPeriodName: this.props.filingPeriodName || '',
      extraSmallWindow: this.isExtraSmallWindow(),
      dueDates: PeriodDueDateDTO.create(
        {
          beginDueDate: moment.utc(),
          endDueDate: moment.utc(),
          adjustedDueDate: moment.utc(),
          filingDueDate: moment.utc(),
          filedDate: moment.utc(),
          amendedDate: moment.utc()
        })
    };
  }

  componentDidMount() {
    window.onresize = this.handleWindowResize;
    this.getPeriodDueDates(CurrentUser.Get()?.dr2Id || '');
  }

  componentWillUnmount() {
    window.onresize = null;
  }

  render() {
    const isAdmin = CurrentUser.Get()?.isInRole(Role.IECDB_ADMINISTRATOR);
    const { showAdjust, unfiled, filingPeriodName } = this.props;
    const { loading, dr2SummaryDisabled, dr2CompleteDisabled, extraSmallWindow } = this.state;

    const menu = (
      <Menu>
        <Menu.Item onClick={() => HistoryUtil.push(Routes.generate(Routes.FILING_PERIOD_HISTORY))}>
          Filing History
        </Menu.Item>
        {unfiled &&
          <Menu.Item onClick=
            {
              () => HistoryUtil.push(Routes.generate(Routes.DELETE_FILING), { filingPeriodName, currDr2Id: CurrentUser.Get()?.dr2Id })
            }
          > Delete Filing
          </Menu.Item>
        }
        {isAdmin &&
          <>
            <Menu.Item onClick={() => HistoryUtil.push(Routes.generate(Routes.DR2_PENALTY, { from: `Filing Period: ${filingPeriodName || ''}` }))}>
              Penalty
            </Menu.Item>
            <Menu.Item onClick={() => this.confirmAudit()}>
              Audit
            </Menu.Item>
            <Menu.Item onClick={() => HistoryUtil.push(Routes.generate(Routes.ADJUST_ENTIRE_PERIOD))}>
              Adjust Entire Period
            </Menu.Item>
            <Menu.Item onClick={() => HistoryUtil.push(Routes.generate(Routes.ADJUST_PERIOD_DATES))}>
              Change Dates
            </Menu.Item>
            <Menu.Item onClick={() => showAdjust()}>
              Adjust Balance
            </Menu.Item>
            <Menu.Item onClick={() => this.confirmCascade()}>
              Cascade
            </Menu.Item>
          </>
        }
        <Menu.Item onClick={() => this.printSummary()} disabled={dr2SummaryDisabled}>
          Print Summary Report {dr2SummaryDisabled && <LoadingOutlined />}
        </Menu.Item>
        <Menu.Item onClick={() => this.printComplete()} disabled={dr2CompleteDisabled}>
          Print Complete Report {dr2CompleteDisabled && <LoadingOutlined />}
        </Menu.Item>
      </Menu>
    );

    const normalWindowList = (<Row style={{ flexDirection: 'row' }}>
      <Space style={{ flex: 1, flexWrap: 'wrap' }}>
        <Col>
          <Button className="option-menu-button"
            onClick={() => HistoryUtil.push(Routes.generate(Routes.FILING_PERIOD_HISTORY))}>Filing History</Button>
        </Col>
        {unfiled &&
          <Col>
          <Button className="option-menu-button"
            onClick={() => HistoryUtil.push(Routes.generate(Routes.DELETE_FILING),
                        { filingPeriodName, currDr2Id: CurrentUser.Get()?.dr2Id })}> Delete Filing
            </Button>
          </Col>
        }
        {isAdmin &&
          <>
            <Col>
            <Button className="option-menu-button"
              onClick={() => HistoryUtil.push(Routes.generate(Routes.DR2_PENALTY, { from: `Filing Period: ${filingPeriodName || ''}` }))}>
                Penalty
              </Button>
            </Col>
            <Col>
            <Button className="option-menu-button" onClick={() => this.confirmAudit()}>
                Audit
              </Button>
            </Col>
            <Col>
            <Button className="option-menu-button" onClick={() => HistoryUtil.push(Routes.generate(Routes.ADJUST_ENTIRE_PERIOD))}>
                Adjust Entire Period
              </Button>
            </Col>
            <Col>
            <Button className="option-menu-button" onClick={() => HistoryUtil.push(Routes.generate(Routes.ADJUST_PERIOD_DATES))}>
                Change Dates
              </Button>
            </Col>
            <Col>
            <Button className="option-menu-button" onClick={() => showAdjust()}>
                Adjust Balance
              </Button>
            </Col>
            <Col>
            <Button className="option-menu-button" onClick={() => this.confirmCascade()}>
                Cascade
              </Button>
            </Col>
          </>
        }
        <Col>
          <Button className="option-menu-button" onClick={() => this.printSummary()} disabled={dr2SummaryDisabled}>
            Print Summary Report {dr2SummaryDisabled && <LoadingOutlined />}
          </Button>
        </Col>
        <Col>
          <Button className="option-menu-button" onClick={() => this.printComplete()} disabled={dr2CompleteDisabled}>
            Print Complete Report {dr2CompleteDisabled && <LoadingOutlined />}
          </Button>
        </Col>
        {loading && <LoadingOutlined />}
      </Space>
    </Row>);

    //set trigger to "click" on mobile/touchscreen devices
    let dropdownTrigger: 'hover' | 'click' | 'contextMenu' = 'hover';
    if ('ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0) {
      dropdownTrigger = 'click';
    }

    return (
      extraSmallWindow ? (<Space>
        <Dropdown overlay={menu} trigger={[dropdownTrigger]}>
          <MenuOutlined style={{ fontSize: '24px' }} />
        </Dropdown>
        {loading && <LoadingOutlined />}
      </Space>) : normalWindowList
    );
  }

  private handleWindowResize = () => {
    if (this.state.extraSmallWindow !== this.isExtraSmallWindow()) {
      this.setState({ extraSmallWindow: !this.state.extraSmallWindow });
    }
  }

  private isExtraSmallWindow = () => {
    return !window.matchMedia('(min-width: 768px)').matches;
  }

  private confirmAudit = () => {
    Modal.confirm({
      title: 'Are you sure you want to mark this filing as audited?',
      onOk: () => {
        Dr2ApiService.auditDr2(CurrentUser.Get()?.dr2Id || '')
          .then(result => {
            if (result && result.succeeded) {
              notification.success({ message: 'Successfully audited filing.' });
              this.props.refreshStatusBar(CurrentUser.Get()?.dr2Id || '');
              return;
            }
            notification.error({ message: 'Failed to audit filing.', description: result.errors[0] });
          });
      }
    });
  }

  private confirmCascade = () => {
    Modal.confirm({
      title: 'This will transfer your balance to all subsequent periods. Do you want to continue?',
      onOk: () => {
        this.setState({ loading: true });
        Dr2ApiService.performCascade(CurrentUser.Get()?.dr2Id || '')
          .then(() => {
            this.setState({ loading: false });
          }).catch(() => {
            this.setState({ loading: false });
            notification.error({
              message: 'Error while cascading'
            });
          });
      },
    });
  }

  private printSummary = () => {
    this.setState({ dr2SummaryDisabled: true });
    Dr2ApiService.printSummaryReport(CurrentUser.Get()?.dr2Id || '', CurrentUser.Get()?.committeeName || '', this.state.filingDueDate || '')
      .then(() => {
        this.setState({ dr2SummaryDisabled: false});
      }).catch(() => {
        this.setState({ dr2SummaryDisabled: false});
        notification.error({
          message: 'Error while generating report.'
        });
      });
  }

  private printComplete = () => {
    this.setState({ dr2CompleteDisabled: true });
    Dr2ApiService.printCompleteReport(CurrentUser.Get()?.dr2Id || '', CurrentUser.Get()?.committeeName || '', this.state.filingDueDate || '')
      .then(() => {
        this.setState({ dr2CompleteDisabled: false});
      }).catch(() => {
        this.setState({ dr2CompleteDisabled: false});
        notification.error({
          message: 'Error while generating report.'
        });
      });
  }

  private getPeriodDueDates = (dr2Id: string) => {
    Dr2ApiService.getDueDatesByDr2(dr2Id)
      .then(dueDatesU => {
        const dueDates = {
          beginDueDate: moment.utc(dueDatesU.beginDueDate),
          endDueDate: moment.utc(dueDatesU.endDueDate),
          filingDueDate: moment.utc(dueDatesU.filingDueDate),
          adjustedDueDate: dueDatesU.adjustedDueDate ? moment.utc(dueDatesU.adjustedDueDate) : null,
          filedDate: dueDatesU.filedDate ? moment.utc(dueDatesU.filedDate).local() : null,
          amendedDate: dueDatesU.amendedDate ? moment.utc(dueDatesU.amendedDate) : null
        } as PeriodDueDateDTO;
        this.setState({ dueDates, loading: false, filingDueDate: DateUtil.toShortDateString(moment(dueDates.filingDueDate)) });

      }).catch(() => {
        notification.error({
          message: 'Unable to obtain due dates.'
        });
      });
  }
}

export default Dr2StatusBarButtons;