import * as React from 'react';
import CustomForm from '../shared/CustomForm';
import { Button, Col, ColProps, FormInstance, notification, Space, Typography } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import FormValidationUtil from '../../utils/FormValidationUtil';
import CurrencyInput from '../shared/CurrencyInput';
import BuildingFundFilingDTO from '../../models/BuildingFundFilingDTO';
import BuildingFundApiService from '../../api/BuildingFundApiService';

interface BuildingFundFilingFormProps {
    committeeId: string;
    coveredYear: string;
    onRefresh?: () => void;
}

interface BuildingFundFilingFormState {
    btnDisable: boolean;
    prevStartingBalance: number;
    loading: boolean;
}

class BuildingFundFilingForm extends React.Component<BuildingFundFilingFormProps, BuildingFundFilingFormState> {
    private readonly _formRef = React.createRef<FormInstance>();

    constructor(props: BuildingFundFilingFormProps) {
        super(props);

        this.state = {
            btnDisable: false,
            prevStartingBalance: 0.00,
            loading: true
        };
    }

    componentDidMount() {
       this.getPrevFilingBalance();
    }

    render() {
        const { btnDisable, prevStartingBalance, loading } = this.state;
        const columnSizingProps: ColProps = { xs: 24, sm: 24, md: 12 };
        return (
            <>
                {!loading &&
                    <Col {...columnSizingProps}>
                        <Typography.Title level={4}>New Filing</Typography.Title>
                        <CustomForm
                            formRef={this._formRef}
                            layout="vertical"
                            initialValues={{ startingBalance: prevStartingBalance }}
                            onFinish={this.handleSave}>
                            <FormItem name="startingBalance" label="Starting Balance" required={true}
                                rules={[FormValidationUtil.Currency()]} >
                                <CurrencyInput></CurrencyInput>
                            </FormItem>
                            <Space>
                                <Button type="primary" disabled={btnDisable} htmlType="submit">Save</Button>
                            </Space>
                        </CustomForm >
                    </Col>
                }
            </>);
    }

    private getPrevFilingBalance() {
        if (this.props.committeeId && this.props.coveredYear) {
            this.setState({ loading: true });
            BuildingFundApiService.getPrevFilingBalance(this.props.committeeId, this.props.coveredYear)
                .then(result => {
                    this.setState({ prevStartingBalance: result, loading: false });
                }).catch(() => {
                    notification.error({message: 'Failed to load previous building fund balance.'});
                    this.setState({ loading: false });
                });
        }
    }

    private handleSave = (values: any) => {
        this.setState({ btnDisable: true });

        const buildingFundFiling = BuildingFundFilingDTO.create({
            coveredYear: this.props.coveredYear,
            committeeID: this.props.committeeId,
            startingBalance: values.startingBalance
        });

        BuildingFundApiService.saveBuildingFund(buildingFundFiling)
            .then(result => {
                if (result.succeeded) {
                    notification.success({
                        message: ' Building fund filing saved successfully.',
                        description: ''
                    });
                    if (this.props.onRefresh) {
                        this.props.onRefresh();
                    }
                }
                else
                {
                    notification.error({
                        message: result.errors[0],
                        description: ''
                    });
                }
            }).catch(() => {
                notification.error({
                    message: 'Failed getting previous building fund balance.',
                    description: ''
                });
            });
        this.setState({ btnDisable: false });
    }
}

export default BuildingFundFilingForm;