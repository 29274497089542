// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
import BaseApi from './BaseApi';
import TableRequestDTO from '../models/TableRequestDTO';
import SchAContributionsImportFormDTO from '../models/SchAContributionsImportFormDTO';
import SchABImportBatchNumberDTO from '../models/SchABImportBatchNumberDTO';
import TableResponseDTO from '../models/TableResponseDTO';
import SchAContributionsImportDTO from '../models/SchAContributionsImportDTO';

export class SchAContributionsImportApiService extends BaseApi {

  // post: api/schacontributions/import/import?committeeId=${encodeURIComponent(committeeId)}&dr2Id=${encodeURIComponent(dr2Id)}
  public import(request: FormData, committeeId: string, dr2Id: string): Promise<SchABImportBatchNumberDTO> {
    let url = `api/schacontributions/import/import`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (committeeId != null) {
      url += `${prefix}committeeId=${encodeURIComponent(committeeId)}`;
      prefix = '&';
    }
    if (dr2Id != null) {
      url += `${prefix}dr2Id=${encodeURIComponent(dr2Id)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, SchABImportBatchNumberDTO>(request, 'post', url, false, false);
  }

  // get: api/schacontributions/import/template
  public downloadTemplate(): Promise<void> {
    let url = `api/schacontributions/import/template`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, void>(null, 'get', url, true, true);
  }

  // put: api/schacontributions/import/importSave?batchNumber=${encodeURIComponent(batchNumber)}&userId=${userId}&committeeId=${encodeURIComponent(committeeId)}&dr2Id=${encodeURIComponent(dr2Id)}
  public saveImportedContributions(batchNumber: string, userId: number, committeeId: string, dr2Id: string): Promise<void> {
    let url = `api/schacontributions/import/importSave`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (batchNumber != null) {
      url += `${prefix}batchNumber=${encodeURIComponent(batchNumber)}`;
      prefix = '&';
    }
    if (userId != null) {
      url += `${prefix}userId=${userId}`;
      prefix = '&';
    }
    if (committeeId != null) {
      url += `${prefix}committeeId=${encodeURIComponent(committeeId)}`;
      prefix = '&';
    }
    if (dr2Id != null) {
      url += `${prefix}dr2Id=${encodeURIComponent(dr2Id)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, void>(null, 'put', url, true, false);
  }

  // put: api/schacontributions/import/sumAmount?batchNumber=${encodeURIComponent(batchNumber)}&userId=${userId}&committeeId=${encodeURIComponent(committeeId)}&dr2Id=${encodeURIComponent(dr2Id)}
  public sumImportedAmount(batchNumber: string, userId: number, committeeId: string, dr2Id: string): Promise<number> {
    let url = `api/schacontributions/import/sumAmount`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (batchNumber != null) {
      url += `${prefix}batchNumber=${encodeURIComponent(batchNumber)}`;
      prefix = '&';
    }
    if (userId != null) {
      url += `${prefix}userId=${userId}`;
      prefix = '&';
    }
    if (committeeId != null) {
      url += `${prefix}committeeId=${encodeURIComponent(committeeId)}`;
      prefix = '&';
    }
    if (dr2Id != null) {
      url += `${prefix}dr2Id=${encodeURIComponent(dr2Id)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, number>(null, 'put', url, true, false);
  }

  // post: api/schacontributions/import/table?batchNumber=${encodeURIComponent(batchNumber)}&userId=${userId}
  public getStagingContributions(tableRequest: TableRequestDTO, batchNumber: string, userId: number): Promise<TableResponseDTO<SchAContributionsImportDTO>> {
    let url = `api/schacontributions/import/table`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (batchNumber != null) {
      url += `${prefix}batchNumber=${encodeURIComponent(batchNumber)}`;
      prefix = '&';
    }
    if (userId != null) {
      url += `${prefix}userId=${userId}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, TableResponseDTO<SchAContributionsImportDTO>>(tableRequest, 'post', url, true, false);
  }

  // get: api/schacontributions/import?batchNumber=${encodeURIComponent(batchNumber)}&transNumber=${transNumber}&userId=${userId}
  public getStagingContribution(batchNumber: string, transNumber: number, userId: number): Promise<SchAContributionsImportFormDTO> {
    let url = `api/schacontributions/import`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (batchNumber != null) {
      url += `${prefix}batchNumber=${encodeURIComponent(batchNumber)}`;
      prefix = '&';
    }
    if (transNumber != null) {
      url += `${prefix}transNumber=${transNumber}`;
      prefix = '&';
    }
    if (userId != null) {
      url += `${prefix}userId=${userId}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, SchAContributionsImportFormDTO>(null, 'get', url, true, false);
  }

  // put: api/schacontributions/import?contactType=${encodeURIComponent(contactType)}&committeeId=${encodeURIComponent(committeeId)}&batchNumber=${encodeURIComponent(batchNumber)}&transNumber=${transNumber}&userId=${userId}
  public saveStagingContribution(stagingContr: SchAContributionsImportFormDTO, contactType: string, committeeId: string, batchNumber: string, transNumber: number, userId: number): Promise<void> {
    let url = `api/schacontributions/import`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (contactType != null) {
      url += `${prefix}contactType=${encodeURIComponent(contactType)}`;
      prefix = '&';
    }
    if (committeeId != null) {
      url += `${prefix}committeeId=${encodeURIComponent(committeeId)}`;
      prefix = '&';
    }
    if (batchNumber != null) {
      url += `${prefix}batchNumber=${encodeURIComponent(batchNumber)}`;
      prefix = '&';
    }
    if (transNumber != null) {
      url += `${prefix}transNumber=${transNumber}`;
      prefix = '&';
    }
    if (userId != null) {
      url += `${prefix}userId=${userId}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, void>(stagingContr, 'put', url, true, false);
  }

  // delete: api/schacontributions/import?batchNumber=${encodeURIComponent(batchNumber)}&transNumber=${transNumber}&userId=${userId}
  public deleteStagingContribution(batchNumber: string, transNumber: number, userId: number): Promise<void> {
    let url = `api/schacontributions/import`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (batchNumber != null) {
      url += `${prefix}batchNumber=${encodeURIComponent(batchNumber)}`;
      prefix = '&';
    }
    if (transNumber != null) {
      url += `${prefix}transNumber=${transNumber}`;
      prefix = '&';
    }
    if (userId != null) {
      url += `${prefix}userId=${userId}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, void>(null, 'delete', url, true, false);
  }

  // delete: api/schacontributions/import/${userId}
  public deleteStagingContributionsByUserId(userId: number): Promise<void> {
    let url = `api/schacontributions/import/${userId}`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, void>(null, 'delete', url, true, false);
  }

  // get: api/schacontributions/import/hasErrors?batchNumber=${encodeURIComponent(batchNumber)}&userId=${userId}
  public stagingContributionTableHasErrors(batchNumber: string, userId: number): Promise<boolean> {
    let url = `api/schacontributions/import/hasErrors`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (batchNumber != null) {
      url += `${prefix}batchNumber=${encodeURIComponent(batchNumber)}`;
      prefix = '&';
    }
    if (userId != null) {
      url += `${prefix}userId=${userId}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, boolean>(null, 'get', url, true, false);
  }
}
var service = new SchAContributionsImportApiService();
export default service;
