import {
  Button,
  Col,
  ColProps,
  FormInstance,
  FormProps,
  Input,
  Layout,
  Modal,
  notification,
  Radio,
  Row,
  Select,
  Space,
  Typography
} from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import TextArea from 'antd/lib/input/TextArea';
import moment from 'moment';
import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import AdminDrsfaApiService from '../../../api/AdminDrsfaApiService';
import DrsfaApiService from '../../../api/DrsfaApiService';
import LookupsApiService from '../../../api/LookupsApiService';
import CommitteeType from '../../../consts/CommitteeType';
import DrsfaPurposes from '../../../consts/DrsfaPurposes';
import FilingStatusType from '../../../consts/FilingStatusType';
import Routes from '../../../consts/Routes';
import CodeLookupTableDTO from '../../../models/CodeLookupTableDTO';
import CommitteeTypeDTO from '../../../models/CommitteeTypeDTO';
import DrsfaDTO from '../../../models/DrsfaDTO';
import FormValidationUtil from '../../../utils/FormValidationUtil';
import HistoryUtil from '../../../utils/HistoryUtil';
import AddressFormFields from '../../shared/AddressFormFields';
import CustomDatePicker from '../../shared/CustomDatePicker';
import CustomForm from '../../shared/CustomForm';

const Option = Select.Option;
const { Title } = Typography;
const { Content } = Layout;
const { confirm } = Modal;

interface EditDrsfaProps {
  drsfaId: string;
  filingStatusType: string;
}

interface EditDrsfaState {
  drsfaId: string;
  loading: boolean;
  drsfa: DrsfaDTO;
  committeeTypes: CommitteeTypeDTO[];
  selectedCommitteeType?: CommitteeTypeDTO | null;
  politicalParties: CodeLookupTableDTO[];
  counties: CodeLookupTableDTO[];
  radioValue: number;
}

class EditDrsfa extends React.Component<RouteComponentProps<EditDrsfaProps> & FormProps, EditDrsfaState> {
  private readonly _formRef = React.createRef<FormInstance>();

  constructor(props: RouteComponentProps<EditDrsfaProps> & FormProps) {
    super(props);

    this.state = {
      drsfaId: this.props.match.params.drsfaId,
      loading: true,
      drsfa: DrsfaDTO.create({ committeeType: '', state: 'IA', politicalParty: '', county: '' }),
      committeeTypes: [],
      politicalParties: [],
      counties: [],
      radioValue: 1,
    };
  }

  componentDidMount() {
    this.loadLookups();
    this.loadDrsfaData();
  }

  onChangeCommitteeType = (value: CommitteeType) => {
    const selectedCommitteeType = this.state.committeeTypes.find(ct => ct.code === value);
    this.setState(
      {
        selectedCommitteeType,
      }
    );
  };

  onChangeRadioButtons = (e: any) => {
    this.setState({
      radioValue: e.target.value
    });
  };

  render() {
    const columnSizingProps: ColProps = { xs: 24, sm: 24, md: 12 };
    const { drsfaId, loading, drsfa, committeeTypes, politicalParties, counties, selectedCommitteeType } = this.state;
    const buttonText = !drsfaId ? 'Submit' : 'Adjust';
    const rowGutterLen = 64;
    const isPanelCandidateVisible = selectedCommitteeType?.filerTypeName === 'Candidate';
    const isCommitteeTypeSelected = !!selectedCommitteeType;
    const isCountyRequired = selectedCommitteeType?.locationFiledName === 'County';
    const isApproveTableDrsfa = this.props.match.params.filingStatusType === FilingStatusType.FILED;
    const isManageTableDrsfa = this.props.match.params.filingStatusType === FilingStatusType.AUDITED;
    let cpyCommitteeTypes = committeeTypes.map(a => a);
    if (drsfa) {
      const commType = committeeTypes.filter(d => d.code == drsfa.committeeType)[0];
      if (isApproveTableDrsfa || isManageTableDrsfa) {
        cpyCommitteeTypes = cpyCommitteeTypes.filter(d => d.commFilingPeriodCatTypeCode == commType?.commFilingPeriodCatTypeCode);
      }
    }


    return (
      <Content className="content-pad">
        {!loading && (
          <CustomForm formRef={this._formRef} initialValues={drsfa} layout="vertical">
            <Row gutter={rowGutterLen}>
              <Col {...columnSizingProps}>
                <Title level={3}>DR-SFA</Title>
                <Title level={4}>Committee Name</Title>
                <FormItem name="drsfaId" hidden={true}>
                  <Input hidden={true} disabled={true} aria-hidden={true} />
                </FormItem>
                <FormItem name="committeeName" label="Name" rules={[FormValidationUtil.Required('Committee Name is required')]}>
                  <Input />
                </FormItem>
                <FormItem name="committeeType" label="CommitteeType" rules={[FormValidationUtil.Required('Committee Type is required')]}>
                  <Select onChange={(value) => this.onChangeCommitteeType(value as CommitteeType | '')}>
                    <Option value="" disabled={true}>-- Select Committee Type --</Option>
                    {cpyCommitteeTypes.map(ct => (
                      <Option key={ct.code || ''} value={ct.code || ''}>{ct.name}</Option>
                    ))}
                  </Select>
                </FormItem>
              </Col>
            </Row>
            <Row gutter={rowGutterLen}>
              <Col {...columnSizingProps}>
                <Title level={4}>Candidate or Committee Chair Contact Details</Title>
                <AddressFormFields
                  firstName={{ name: 'firstName' }}
                  middleInitial={{ name: 'middleInitial' }}
                  lastName={{ name: 'lastName' }}
                  address1={{ name: 'address1' }}
                  address2={{ name: 'address2' }}
                  city={{ name: 'city' }}
                  state={{ name: 'state' }}
                  zip={{ name: 'zip' }}
                  email={{ name: 'committeeEmail' }}
                  phone={{ name: 'phone' }} />
              </Col>
              <Col {...columnSizingProps}>
                <Title level={4}>Purpose of Committee</Title>
                <FormItem name="purpose" label="Select One" rules={[FormValidationUtil.Required('Purpose of Committee is required')]}>
                  <Radio.Group onChange={this.onChangeRadioButtons}>
                    <Space direction="vertical">
                      <Radio value={DrsfaPurposes.ADVOCATECANDIDATE}>{DrsfaPurposes.ADVOCATECANDIDATE}</Radio>
                      <Radio value={DrsfaPurposes.BALLOTFOR}>{DrsfaPurposes.BALLOTFOR}</Radio>
                      <Radio value={DrsfaPurposes.BALLOTAGAINST}>{DrsfaPurposes.BALLOTAGAINST}</Radio>
                    </Space>
                  </Radio.Group>
                </FormItem>
                <FormItem name="description" label="Comment or description">
                  <TextArea rows={2} />
                </FormItem>
                <Title level={4}>Election Information</Title>
                {!isCommitteeTypeSelected &&
                  <label>Election Information fields will display after committee type is selcted</label>
                }
                {isCommitteeTypeSelected && isPanelCandidateVisible &&
                  <>
                    <FormItem name="officeSought" label="Office Sought">
                      <Input />
                    </FormItem>
                    <FormItem name="politicalParty" label="Political Party">
                      <Select>
                        <Option value="" disabled={true}>-- Select Party --</Option>
                        {politicalParties.map(party => (
                          <Option key={party.code || ''} value={party.code || ''}>{party.name}</Option>
                        ))}
                      </Select>
                    </FormItem>
                    <FormItem name="district" label="District">
                      <Input />
                    </FormItem>
                    <FormItem name="electionYear" label="Election Year" rules={[FormValidationUtil.Year()]}>
                      <Input maxLength={4} />
                    </FormItem>
                  </>
                }
                {isCommitteeTypeSelected && isCountyRequired &&
                  <>
                    <FormItem name="county" label="County" rules={[{ required: isCountyRequired, message: 'County is required' }]}>
                      <Select>
                        <Option value="" disabled={true}>-- Select County --</Option>
                        {counties.map(c => (
                          <Option key={c.code || ''} value={c.code || ''}>{c.name}</Option>
                        ))}
                      </Select>
                    </FormItem>
                    <FormItem name="electionDate" label="Election Date">
                      <CustomDatePicker onChange={(d) => d?.startOf('day')} />
                    </FormItem>
                  </>
                }
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Title level={5}>STATEMENT OF AFFIRMATION: By filing this document the committee affirms the following:</Title>
                <ol>
                  <li>
                    The committee and all persons connected with the committee understand that they are subject to
                    the laws in Iowa Code chapters 68A and 68B and the administrative rules in Chapter 351 of the
                    Iowa Administrative Code.
                  </li>
                  <li>
                    That Iowa Code section 68A.405 and rules 351—4.38 through 4.43 require the placement of the
                    words “paid for by” and the name of the committee on all political materials except for those
                    items exempted by statute or rule.
                  </li>
                  <li>
                    That Iowa Code section 68A.503 and rules 351—4.44 through 4.52 prohibit the receipt of corporate
                    contributions by all committees except for statewide and local ballot issue PACs.
                  </li>
                  <li>
                    That if the committee exceeds $1000 in campaign activity, a DR-1 Statement of Organization must
                    be filed within 10 days and the committee is required to file campaign disclosure reports.
                  </li>
                  <li>
                    That this form is filed prior to the distribution or posting of political material requiring
                    the “paid for by” attribution.
                  </li>
                  <li>
                    A new form or amended form is required to be filed for each subsequent election that I am
                    involved.
                  </li>
                </ol>
                <Space>
                  <Button type="primary" onClick={() => this.saveValidation(!drsfaId ? FilingStatusType.FILED : FilingStatusType.ADJUSTED)}>
                    {buttonText}
                  </Button>
                  {(isApproveTableDrsfa) &&
                    <Button type="primary" onClick={() => this.saveValidation(FilingStatusType.AUDITED)}>
                      Approve
                    </Button>
                  }
                  {isManageTableDrsfa &&
                    <Button danger={true} onClick={() => this.confirmDelete(drsfaId, drsfa.committeeName || '')}>
                      Delete
                    </Button>
                  }
                  {isApproveTableDrsfa &&
                    <Button danger={true} onClick={() => this.confirmReject(drsfaId, drsfa.committeeName || '')}>
                      Reject
                    </Button>
                  }
                  <Button onClick={this.handleCancelClicked}>Cancel</Button>
                </Space>
              </Col>
            </Row>
          </CustomForm>
        )}
      </Content>
    );
  }

  private loadLookups = () => {
    const committeeTypesPromise = LookupsApiService.getCommitteeTypes();
    const politicalPartiesPromise = LookupsApiService.getPoliticalParties();
    const countiesPromise = LookupsApiService.getCounties();
    const committeeTypesToRemove = [
      CommitteeType.UNASSIGNED,
      CommitteeType.STATE_CENTRAL_COMMITTEE,
      CommitteeType.COUNTY_CENTRAL_COMMITTEE,
      CommitteeType.LOBBYIST,
      CommitteeType.LOBBYIST_CLIENT
    ];

    Promise.all([committeeTypesPromise, politicalPartiesPromise, countiesPromise])
      .then(results => {
        let [committeeTypes] = results;
        const [, politicalParties, counties] = results;
        committeeTypes = committeeTypes.filter(ct => !committeeTypesToRemove.some(x => x === ct.code));

        this.setState({ committeeTypes, politicalParties, counties });
      });
  }

  private loadDrsfaData = () => {
    if (!this.state.drsfaId) {
      this.setState({ loading: false });
      return;
    }

    this.setState({ loading: true });

    AdminDrsfaApiService.get(this.state.drsfaId)
      .then(drsfa => {
        let selectedCommitteeType;

        if (drsfa) {
          if (drsfa.committeeType) {
            selectedCommitteeType = this.state.committeeTypes.find(ct => ct.code === drsfa.committeeType);
          }

          if (drsfa.electionDate) {
            drsfa.electionDate = moment(drsfa.electionDate);
          }

          if (!drsfa.politicalParty) {
            drsfa.politicalParty = '';
          }
        }

        this.setState({ drsfa, selectedCommitteeType, loading: false });
      }).catch(() => {
        notification.error({ message: 'Failed to load DRSFA Data.' });
        this.setState({ loading: false });
      });

  }

  private delete = (drsfaId: string) => {
    AdminDrsfaApiService.delete(drsfaId)
      .then(() => {
        HistoryUtil.push(Routes.generate(Routes.MANAGE_DRSFA));
        notification.success({
          message: 'Deleted Successfully'
        });
      }).catch(() => {
        notification.error({
          message: 'Failed to delete filing.'
        });
      });
  }

  private confirmDelete = (drsfaId: string, commiteeName: string) => {
    confirm({
      title: 'Are you sure you want to delete this DRSFA?',
      content: 'The following record will be permanently deleted: ' + commiteeName,
      onOk: () => {
        this.delete(drsfaId);
      },
    });
  }

  private reject = (drsfaId: string) => {
    AdminDrsfaApiService.reject(drsfaId)
      .then(() => {
        HistoryUtil.push(Routes.generate(Routes.MANAGE_DRSFA));
        notification.success({
          message: 'Rejected Successfully'
        });
      }).catch(() => {
        notification.error({
          message: 'Failed to reject filing.'
        });
      });
  }

  private confirmReject = (drsfaId: string, commiteeName: string) => {
    confirm({
      title: 'Are you sure you want to reject this DRSFA?',
      content: 'The following record will be rejected: ' + commiteeName,
      onOk: () => {
        this.reject(drsfaId);
      },
    });
  }

  private saveValidation = (filingStatus: string) => {
    if (this._formRef) {
      this._formRef.current?.validateFields().then(validResult => {
        this.adjustDrsfa(validResult, filingStatus);
      });
    }
  }

  private adjustDrsfa = (values: any, filingStatus: string) => {
    if (!this.state.drsfaId) {
      DrsfaApiService.add(values, filingStatus)
        .then(() => {
          HistoryUtil.push(Routes.HOME_ROUTE);
          notification.success({
            message: 'Saved Successfully'
          });
        })
        .catch((error) => {
          notification.error({ message: error.message, description: error.description });
        });
    }
    else {
      AdminDrsfaApiService.edit(values, filingStatus)
        .then(() => {
          HistoryUtil.push(Routes.MANAGE_DRSFA);
          if (filingStatus == FilingStatusType.FILED) {
            notification.success({
              message: 'Saved Successfully'
            });
          }
          else {
            notification.success({
              message: 'Approved Successfully'
            });
          }
        })
        .catch((error) => {
          notification.error({ message: error.message, description: error.description });
        });
    }
  }

  private handleCancelClicked = () => {
    confirm({
      title: 'Are you sure you want to leave?',
      okText: 'Yes',
      cancelText: 'No',
      onOk: () => {
        HistoryUtil.push(this.props.match.params.drsfaId ? Routes.MANAGE_DRSFA : Routes.LOGIN);
      }
    });
  }

}

export default EditDrsfa;
