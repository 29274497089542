import { Modal, notification } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import * as React from 'react';
import AdminPeriodMainApiService from '../../api/AdminPeriodMainApiService';
import DataTableColumnUtil from '../../components/shared/DataTable/DataTableColumnUtil';
import CommFilingPeriodCatTypeCode from '../../consts/CommFilingPeriodCatTypeCode';
import ElectionYear from '../../consts/ElectionYear';
import ReportTypes from '../../consts/ReportType';
import Routes from '../../consts/Routes';
import SuppSpecElecType from '../../consts/SuppSpecElecType';
import AdminPeriodListDTO from '../../models/AdminPeriodListDTO';
import HistoryUtil from '../../utils/HistoryUtil';
import DataTable, { DataTableColumnProps } from '../shared/DataTable/DataTable';
import DataTableButtonUtil from '../shared/DataTable/DataTableButtonUtil';

const ReportType = [
  { text: ReportTypes.STANDARD, value: ReportTypes.STANDARD },
  { text: ReportTypes.SPECIALELECTION, value: ReportTypes.SPECIALELECTION },
  { text: ReportTypes.SUPPLEMENTAL, value: ReportTypes.SUPPLEMENTAL }
];

const Type = [
  { text: CommFilingPeriodCatTypeCode.CENT, value: CommFilingPeriodCatTypeCode.CENT },
  { text: CommFilingPeriodCatTypeCode.CTY , value: CommFilingPeriodCatTypeCode.CTY },
  { text: CommFilingPeriodCatTypeCode.LOCL, value: CommFilingPeriodCatTypeCode.LOCL },
  { text: CommFilingPeriodCatTypeCode.NA, value: CommFilingPeriodCatTypeCode.NA },
  { text: CommFilingPeriodCatTypeCode.SCPA, value: CommFilingPeriodCatTypeCode.SCPA },
  { text: CommFilingPeriodCatTypeCode.SWGA, value: CommFilingPeriodCatTypeCode.SWGA },
  { text: CommFilingPeriodCatTypeCode.BALLOT, value: CommFilingPeriodCatTypeCode.BALLOT },
];

const SuppSpecElectionTypes = [
  { text: SuppSpecElecType.GENERAL, value: SuppSpecElecType.GENERAL },
  { text: SuppSpecElecType.PRIMARY, value: SuppSpecElecType.PRIMARY },
  { text: SuppSpecElecType.SPECIAL, value: SuppSpecElecType.SPECIAL }
];

const ElectionYears = [
  { text: ElectionYear.ELECTION, value: ElectionYear.ELECTION },
  { text: ElectionYear.NONELECTION, value: ElectionYear.NONELECTION },
  { text: ElectionYear.SPECIAL, value: ElectionYear.SPECIAL },
  { text: ElectionYear.NA, value: ElectionYear.NA }
];

const { confirm } = Modal;

interface PeriodMainProps {
}

interface PeriodMainState {
  filingDataColumns: DataTableColumnProps<AdminPeriodListDTO>[];
}

class PeriodMain extends React.Component<PeriodMainProps, PeriodMainState> {
  private filingDataTable: DataTable<AdminPeriodListDTO> | undefined;
  constructor(props: PeriodMainProps) {
    super(props);

    this.state = {
      filingDataColumns: this.getTableColoumns(),
    };
  }

  render() {
    const manageActionButtons = [];
    manageActionButtons.push(DataTableButtonUtil.Reset());
    manageActionButtons.push(DataTableButtonUtil.Primary('New Period', this.addPeriod));

    return (
      <Content className="content-pad">
        <DataTable
          ref={(element: any) => (this.filingDataTable = element)}
          serverSide={true}
          tableProps={{
                rowKey: 'periodMasterId',
            sortDirections: ['ascend', 'descend'],
            locale: {
              emptyText: 'Currently there are no Filing Periods to View'
            }
          }}
          globalSearch={true}
          title="Filing Period"
          buttonBar={manageActionButtons}
          columns={this.state.filingDataColumns}
          fetchData={{
            fetch: function (tableRequest) {
              return AdminPeriodMainApiService.getPeriodMasterList(tableRequest);
            },
            failureMessage: 'Failed to Retrieve Period Filings'
          }}
          styleOptions={{ compact: true, alternatingRowHighlight: true }}
          stateSaving={{
            enabled: true,
            tableUniqueKey: 'adminManagePeriodMaster',
            perSession: true,
          }}
        />
      </Content>
    );
  }

  private getTableColoumns() {
    const columns: DataTableColumnProps<AdminPeriodListDTO>[] = [];

    const columnYear: DataTableColumnProps<AdminPeriodListDTO>
      = DataTableColumnUtil.Text('Year', 'year', 100, { defaultSortOrder: 'descend' });
    columns.push(columnYear);

    const columnFilingPeriod: DataTableColumnProps<AdminPeriodListDTO>
      = DataTableColumnUtil.Text('Filing Period', 'filingPeriodDesc');
    columns.push(columnFilingPeriod);

    const columnReportType: DataTableColumnProps<AdminPeriodListDTO>
      = DataTableColumnUtil.DropdownMulti('Report Type', 'reportType', ReportType, 250);
    columns.push(columnReportType);

    const columnElectionYears: DataTableColumnProps<AdminPeriodListDTO>
      = DataTableColumnUtil.DropdownMulti('Election Year', 'electionYearInd', ElectionYears, 250);
    columns.push(columnElectionYears);

    const columnType: DataTableColumnProps<AdminPeriodListDTO>
      = DataTableColumnUtil.DropdownMulti('Type', 'commFilingPeriodCatTypeCd', Type, 250);
    columns.push(columnType);

    const columnSuppSpecialElecType: DataTableColumnProps<AdminPeriodListDTO> =
      DataTableColumnUtil.DropdownMulti('Special Election Type', 'suppSpecialElectionType', SuppSpecElectionTypes, 250);
    columns.push(columnSuppSpecialElecType);

    const manageButtons = [
      { text: 'Edit', onClick: (rowData: AdminPeriodListDTO) => this.editPeriod(rowData.periodMasterId || 0) },
      { text: 'Delete', onClick: (rowData: AdminPeriodListDTO) => this.confirmDelete(rowData.periodMasterId || 0, rowData.filingPeriodDesc || '' )},
    ];

    const columnButtons: DataTableColumnProps<AdminPeriodListDTO>
      = DataTableColumnUtil.Buttons('PeriodMasterId', manageButtons, 150);
    columns.push(columnButtons);

    return columns as DataTableColumnProps<AdminPeriodListDTO>[];
  };

  private addPeriod = () => {
    HistoryUtil.push(Routes.generate(Routes.PERIOD_ADD));
  }

  private editPeriod = async (periodMasterId: number) => {
    HistoryUtil.push(Routes.generate(Routes.EDIT_PERIOD, { id: periodMasterId }));
  }

  private confirmDelete = (periodMasterId: number, filingPeriodDesc: string) => {
    confirm({
      title: 'Are you sure you want to delete this period: ' + filingPeriodDesc + '?',
      onOk: () => {
        this.deletePeriod(periodMasterId);
      },
    });
  }

  private deletePeriod = (periodMasterId: number) => {
    AdminPeriodMainApiService.deletePeriod(periodMasterId)
      .then((result) => {
        if (result.succeeded) {
          notification.success({ message: 'Period has been deleted.' });
          if (this.filingDataTable) {
            this.filingDataTable.refresh();
          }
        }
        else {
          notification.error({ message: result.errors[0]});
        }
      }).catch(() => {
        notification.error({ message: 'Failed to delete Period.' });
      });
  }

}

export default PeriodMain;

