import { Layout } from 'antd';
import * as React from 'react';
import UsersApiService from '../../api/UsersApiService';
import Routes from '../../consts/Routes';
import ManageUsersListDTO from '../../models/AdminManageUsersListDTO';
import HistoryUtil from '../../utils/HistoryUtil';
import DataTable, { DataTableColumnProps } from '../shared/DataTable/DataTable';
import DataTableButtonUtil from '../shared/DataTable/DataTableButtonUtil';
import DataTableColumnUtil from '../shared/DataTable/DataTableColumnUtil';
import UserAccountStatusNames from '../../consts/UserAccountStatusNames';
import CurrentUser from '../../utils/CurrentUser';
import Role from '../../consts/Role';

const Content = Layout.Content;

interface ManageUsersProps {
  forCommittee: boolean
}

interface ManageUsersState {
  loading: boolean
}

class ManageUsers extends React.Component<ManageUsersProps, ManageUsersState> {
  private dataTable: DataTable<ManageUsersListDTO> | undefined;
  constructor(props: ManageUsersProps) {
    super(props);

    this.state = {
      loading: false
    };
  }

  render() {
    const actionButtons = [];
    actionButtons.push(DataTableButtonUtil.Reset());
    actionButtons.push(DataTableButtonUtil.Primary('Add User', () => this.goToUsersDetails()));
    const currentUser = CurrentUser.Get();
    const committeeId = this.props.forCommittee ? currentUser?.committeeId : '';

    return (
      <Content className="content-pad">
        <DataTable
          ref={(element: any) => (this.dataTable = element)}
          serverSide={true}
          tableProps={{
            rowKey: 'userId',
            sortDirections: ['ascend', 'descend'],
          }}
          globalSearch={true}
          title="Users"
          buttonBar={actionButtons}
          columns={this.getUsersTableColumns()}
          styleOptions={{ compact: true, alternatingRowHighlight: true }}
          fetchData={{
            fetch: function (tableRequest) { return UsersApiService.getUsersList(tableRequest, committeeId || ''); },
            failureMessage: 'Failed to retrieve User Data'
          }}
          stateSaving={{
              enabled: true,
              tableUniqueKey: 'adminManageUsers',
              perSession: true,
          }}
        />
      </Content>
    );
  }

  private getUsersTableColumns = (): DataTableColumnProps<ManageUsersListDTO>[] => {
    return [
      DataTableColumnUtil.Text('Username', 'username'),
      DataTableColumnUtil.Text('First Name', 'firstName'),
      DataTableColumnUtil.Text('Last Name', 'lastName', null, { defaultSortOrder: 'ascend' }),
      DataTableColumnUtil.Text('Email', 'email'),
      DataTableColumnUtil.DropdownMulti('Status', 'status', [
        { text: UserAccountStatusNames.PENDING_APPROVAL, value: UserAccountStatusNames.PENDING_APPROVAL },
        { text: UserAccountStatusNames.APPROVED, value: UserAccountStatusNames.APPROVED },
        { text: UserAccountStatusNames.REJECTED, value: UserAccountStatusNames.REJECTED },
        { text: UserAccountStatusNames.INACTIVE, value: UserAccountStatusNames.INACTIVE },
      ]),
      DataTableColumnUtil.Text('Committee Code', 'committeeCode'),
      DataTableColumnUtil.Text('Committee Name', 'committeeName'),
      DataTableColumnUtil.Buttons('userId',
        [
          { text: 'Edit', onClick: (rowData) => this.goToUsersDetails(rowData.userId) }
        ])
    ];
  }

  private goToUsersDetails(usersId?: number) {
    const currentUser = CurrentUser.Get();
    const forCommittee = this.props.forCommittee;

    if (currentUser?.isInRole(Role.IECDB_ADMINISTRATOR) && !forCommittee) {
      if (usersId) {
        HistoryUtil.push(
          Routes.generate(Routes.EDIT_USER, {
            id: usersId
          })
        );
      }
      else {
        HistoryUtil.push(Routes.generate(Routes.ADD_USER));
      }
    }
    else if (forCommittee) {
      if (usersId) {
        HistoryUtil.push(
          Routes.generate(Routes.FILER_EDIT_USER, {
            id: usersId
          })
        );
      }
      else {
        HistoryUtil.push(Routes.generate(Routes.FILER_ADD_USER));
      }
    }
  }
}

export default ManageUsers;
