import { Select, Spin, notification } from 'antd';
import { Rule } from 'antd/lib/form';
import FormItem from 'antd/lib/form/FormItem';
import * as React from 'react';
import FormValidationUtil from '../../utils/FormValidationUtil';
import LookupsApiService from '../../api/LookupsApiService';
import CodeLookupTableDTO from '../../models/CodeLookupTableDTO';
import PenaltyType from '../../consts/PenaltyType';
import PenaltySearchDTO from '../../models/PenaltySearchDTO';
import SearchApiService from '../../api/SearchApiService';
import { debounce, isEmpty } from 'lodash';

const { Option } = Select;

interface WaiverTypeSearchProps {
    name?: string;
    label?: string;
    hidden?: boolean;
    required?: boolean;
    disabled?: boolean;
    penalties?: CodeLookupTableDTO[];
    currContriType: string;
    onContributorChange?: (contriType: string) => void;
    onChange?: (contriType: string) => void;
    onSelect?: (contributorId: string, contributorName: string) => void;
}

interface WaiverTypeSearchState {
    loading: boolean;
    penalties: CodeLookupTableDTO[];
    penaltyInfoList: PenaltySearchDTO[];
    contributorLabel: string;
}

class WaiverTypeSearch extends React.Component<WaiverTypeSearchProps, WaiverTypeSearchState> {
    constructor(props: WaiverTypeSearchProps) {
        super(props);

        this.loadPenaltyList = debounce(this.loadPenaltyList, 400);

        this.state = {
            loading: false,
            penalties: this.props.penalties || [],
            penaltyInfoList: [],
            contributorLabel: ''
        };
    }

    componentDidMount() {
        this.lookupPenaltyTypes();
    }

    render() {
        const { loading, penalties, penaltyInfoList } = this.state;
        const { label, required, disabled, hidden, currContriType } = this.props;
        const WaiverTypeSearchRules: Rule[] = [];
        const penaltySearchRules: Rule[] = [];

        const labels = new Map([
            [PenaltyType.VERIFIED_STATEMENT_OF_REGISTRATION, 'Committee'],
            [PenaltyType.INDIVIDUAL_INDEPENDENT_EXPENDITURE, 'Individual'],
            [PenaltyType.ORGANIZATION_INDEPENDENT_EXPENDITURE, 'Organization'],
            [PenaltyType.ONETIME_CONTRIBUTIONS, 'Organization'],
            [PenaltyType.PERSONAL_FINANCIAL_DISCLOSURES, 'Designated Position'],
            [PenaltyType.DR2, 'Committee']
        ]);

        if (required !== false) {
            WaiverTypeSearchRules.push({ required: true, message: (label || 'Waiver Type') + ' is required' });
            penaltySearchRules.push({ required: true, message: (label || labels.get(currContriType)) + ' is required' });
        }

        const options: PenaltySearchDTO[] = loading ? [PenaltySearchDTO.create()] : penaltyInfoList;

        return (
            <>
                <FormItem name="penaltyType" label={'Waiver Type'}
                    rules={[FormValidationUtil.Required('Waiver Type is required')]} >
                    <Select
                        showSearch
                        optionFilterProp="children"
                        placeholder={'Select Type...'}
                        allowClear={true}
                        onChange={this.handlePenaltyTypeChange}
                    >
                        {!loading &&
                            <Option value={0} disabled={true} >Select Waiver Type...</Option>
                        }
                        {penalties.map((s: CodeLookupTableDTO) => (
                            <Option key={s.code} value={s.code}>{s.name}</Option>
                        ))}
                    </Select>
                </FormItem>
                {!isEmpty(currContriType) &&
                    <FormItem name={'contributorName'} label={labels.get(currContriType)} hidden={hidden || false} rules={penaltySearchRules} >
                        <Select
                            showSearch={true}
                            filterOption={false}
                            optionFilterProp="children"
                            notFoundContent={false}
                            showArrow={false}
                            disabled={disabled || false}
                            placeholder={'Search name...'}
                            allowClear={true}
                            onSearch={this.handleSearch}
                            onClear={this.handleClear}
                            onChange={this.handleChange}
                            dropdownRender={(a) => {
                                if (loading) {
                                    return <div className="center-spinner"><Spin spinning={loading}>{a}</Spin></div>;
                                }
                                else {
                                    return a;
                                }
                            }}
                        >
                            {options.map((p: PenaltySearchDTO) => (
                                <Option
                                    key={p.penaltyId || p.id || ''}
                                    value={p.penaltyId || p.id|| ''}
                                >
                                    {p.name || ''}
                                    <br />
                                    {p.city && (
                                        <small>{p.city + ', ' + p.state}</small>
                                    )}
                                </Option>
                            ))}
                        </Select>
                    </FormItem>
                }
            </>
        );
    }

    private handlePenaltyTypeChange = (value: string) => {
        this.setState({penaltyInfoList: []});
        if (this.props.onContributorChange) {
            this.props.onContributorChange(value);
        }
    }

    private searchPenaltyInfo = (name: string) => {
        switch (this.props.currContriType) {
            case PenaltyType.PERSONAL_FINANCIAL_DISCLOSURES:
                this.loadPenaltyList(SearchApiService.getPfdPenalties(name), name);
                break;
            case PenaltyType.ONETIME_CONTRIBUTIONS:
                this.loadPenaltyList(SearchApiService.getOtcPenalties(name), name);
                break;
            case PenaltyType.INDIVIDUAL_INDEPENDENT_EXPENDITURE:
                this.loadPenaltyList(SearchApiService.getIiePenalties(name), name);
                break;
            case PenaltyType.ORGANIZATION_INDEPENDENT_EXPENDITURE:
                this.loadPenaltyList(SearchApiService.getOiePenalties(name), name);
                break;
            case PenaltyType.DR2:
                this.loadPenaltyList(SearchApiService.getDr2Penalties(name), name);
                break;
            case PenaltyType.VERIFIED_STATEMENT_OF_REGISTRATION:
                this.loadPenaltyList(SearchApiService.getVsrPenalties(name), name);
                break;
            default:
                break;
        }
    }

    private loadPenaltyList = (promise: Promise<PenaltySearchDTO[]>, name: string) => {
        promise.then((values: PenaltySearchDTO[]) => {
            this.setState({ penaltyInfoList: [...values, PenaltySearchDTO.create({ name })] });
        }).catch(() => {
            notification.error({
                message: 'Failed to retrieve individuals.',
            });
        }).finally(() => {
            this.setState({ loading: false });
        });
    }

    private handleClear = () => {
        this.setState({ penaltyInfoList: [] });
        if (this.props.onSelect) {
            this.props.onSelect('', '');
        }
    }

    private handleSearch = (value: string) => {
        if (!value || value.length > 0) {
            if (this.props.onChange) {
                this.props.onChange(value);
            }
        }
        if (!value || value.length < 3) {
            if (this.state.penaltyInfoList !== []) {
                this.setState({ penaltyInfoList: [], loading: false });
            }
            return;
        }
        this.setState({ loading: true });
        this.searchPenaltyInfo(value);
    }

    private handleChange = (value: any) => {
        const selectedPenalty: PenaltySearchDTO | undefined = (this.state.penaltyInfoList || []).find(c => {
            return c.penaltyId === value || c.id === value;
        });
        if (selectedPenalty) {
            this.setState({ penaltyInfoList: [selectedPenalty] });
            if (this.props.onSelect) {
                if (selectedPenalty?.penaltyId > 0) {
                    this.props.onSelect(selectedPenalty?.penaltyId.toString() || '', selectedPenalty?.name || '');
                } else {
                    this.props.onSelect(selectedPenalty?.id || '', selectedPenalty?.name || '');
                }
            }
        }
    }

    private lookupPenaltyTypes = () => {
        LookupsApiService.getPenaltyTypes().then((penalties) => {
            this.setState({ penalties });
        }).catch((error: any) => {
            notification.error({
                message: error.message,
                description: error.description,
            });
        });
    }
}
export default WaiverTypeSearch;