import { Col, ColProps, FormInstance, Layout, notification, Row, Select, Table } from 'antd';
import * as React from 'react';
import FormItem from 'antd/lib/form/FormItem';
import AddressFormFields from '../shared/AddressFormFields';
import CustomForm from '../shared/CustomForm';
import CustomDatePicker from '../shared/CustomDatePicker';
import AdminPfdApiService from '../../api/AdminPfdApiService';
import AdminPfdContactDTO from '../../models/AdminPfdContactDTO';
import moment from 'moment';
import Column from 'antd/lib/table/Column';
import AdminPfdContactDepartmentDTO from '../../models/AdminPfdContactDepartmentDTO';
import LookupsApiService from '../../api/LookupsApiService';
import LookupTableDTO from '../../models/LookupTableDTO';
import FormValidationUtil from '../../utils/FormValidationUtil';

const { Content } = Layout;
const { Option } = Select;


interface PfdContactProps {
  id?: number;
}

interface PfdContactState {
  contactId?: number;
  contact: AdminPfdContactDTO;
  contactDept: AdminPfdContactDepartmentDTO[];
  departments: LookupTableDTO[];
}

class PfdContact extends React.Component<PfdContactProps, PfdContactState> {
  private readonly _formRef = React.createRef<FormInstance>();

  constructor(props: PfdContactProps) {
    super(props);
    this.state = {
      contactId: this.props.id,
      contact: AdminPfdContactDTO.create({ state: 'IA' }),
      contactDept: [],
      departments: []
    };
  }

  validate = async ():
    Promise<{ model: AdminPfdContactDTO & { department: number | null, coveredYear: moment.Moment | null }, errorFields: any }> => {
    try {
      const fields = await this._formRef.current?.validateFields();
      return { model: fields, errorFields: null };
    }
    catch (errorInfo) {
      return Promise.reject({ model: errorInfo.values, errorFields: errorInfo.errorFields });
    }
  }

  componentDidMount() {
    this.getInitialValues();
    if (this.props.id) {
      this.getContact(this.props.id);
    }
  }

  render() {
    const { contactId, contact, departments } = this.state;
    const columnSizingProps: ColProps = { xs: 24, sm: 24, md: 12 };
    return (
      <Content className="content-pad">
        <CustomForm formRef={this._formRef} initialValues={contact} layout="vertical">
          <Row gutter={24}>
            <Col {...columnSizingProps} >
              <FormItem name="contactId" hidden={true}>
              </FormItem>
              <AddressFormFields
                firstName={{ name: 'firstName' }}
                lastName={{ name: 'lastName' }}
                address1={{ name: 'address1', required: false }}
                address2={{ name: 'address2', required: false }}
                city={{ name: 'city', required: false }}
                state={{ name: 'state', required: false }}
                zip={{ name: 'zip', required: false }}
                email={{ name: 'email' }}
                phone={{ name: 'phone', required: false }}
              />
            </Col>
            <Col {...columnSizingProps}>
              <FormItem name="terminationDate" label="Termination Date">
                <CustomDatePicker onChange={(d) => d?.startOf('day')} />
              </FormItem>
              {!contactId &&
                <>
                  <FormItem name="department" label="State Agency" rules={[FormValidationUtil.RequiredNumber('State Agency is required')]}>
                    <Select defaultValue={0}
                      showSearch
                      optionFilterProp="children">
                      <Option value={0}>-- Select Agency / Department --</Option>
                      {departments.map(d => (
                        <Option key={d.id || ''} value={d.id || ''}>{d.name}</Option>
                      ))}
                    </Select>
                  </FormItem>
                  <FormItem name="coveredYear" label="Covered Year" rules={[FormValidationUtil.RequiredNumber('Covered Year is required')]}>
                    <CustomDatePicker picker="year" format="YYYY" />
                  </FormItem>
                </>
              }
              <br />
              <Table dataSource={this.state.contactDept} scroll={{ y: 250, x: 450 }}>
                <Column title="State Agency" dataIndex="departmentName" key="departmentName" />
                <Column title="Division / Department" dataIndex="divisionName" key="divisionName" />
                <Column title="Position" dataIndex="positionName" key="positionName" />
                <Column title="Covered Year" dataIndex="coveredYear" key="coveredYear" />
              </Table>
            </Col>
          </Row>
        </CustomForm>
      </Content>

    );
  }

  private getContact = (contactId: number) => {
    AdminPfdApiService.getPfdContact(contactId)
      .then(contact => {
        if (contact.terminationDate) {
          contact.terminationDate = moment(contact.terminationDate);
        }

        contact.contactDepartmentDivisionPositition?.sort((a, b) => {
          return Number(b.coveredYear) - Number(a.coveredYear);
        });

        this.setState({ contact, contactDept: contact.contactDepartmentDivisionPositition || [] });
        this._formRef.current?.setFieldsValue(contact);
      })
      .catch(() => {
        notification.error({
          message: 'Error while fetching contact information'
        });
      });
  }

  private getInitialValues = () => {
    LookupsApiService.getDepartments()
      .then((departments) => {
        this.setState({ departments });
      })
      .catch(() => {
        notification.error({
          message: 'Failed to retrieve departments.',
        });
      });
  }
}

export default PfdContact;