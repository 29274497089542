import { Col, Divider, Layout, Row, Space, Typography } from 'antd';
import * as queryString from 'query-string';
import * as React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import Role from '../../consts/Role';
import Routes from '../../consts/Routes';
import LoginDTO from '../../models/LoginDTO';
import { UserSessionActions } from '../../redux/actions/UserSessionActions';
import { StateStoreModel } from '../../redux/state/StateStoreModel';
import HistoryUtil from '../../utils/HistoryUtil';
import RoleUtil from '../../utils/RoleUtil';
import TranslationUtil from '../../utils/TranslationUtil';
import LoginComponent from '../account/LoginComponent';

const Content = Layout.Content;

interface DashBoardState {
  model: LoginDTO;
  returnUrl?: string;
  isLoggedIn: boolean; //TODO RJ - temporary variable to handle login testing
}

interface PublicDashboardProps {
  userName: string | null;
  loading: boolean;
  error: any;
  logoutAction: () => void;
}

class PublicDashboard extends React.Component<PublicDashboardProps & RouteComponentProps<RouteObject>, DashBoardState> {
  constructor(props: PublicDashboardProps & RouteComponentProps<RouteObject>) {
    super(props);

    const deepLinkState = (queryString.parse(props.location.search) as unknown) as DashBoardState;
    this.state = {
      model: LoginDTO.create(),
      returnUrl: deepLinkState.returnUrl === '/' ? undefined : deepLinkState.returnUrl,
      isLoggedIn: false,
    };
    }

  componentDidMount() {
    document.title = TranslationUtil.translate('appTitle');

    if (this.props.userName === null || this.props.userName === undefined) {
      this.setState({ isLoggedIn: false });
    } else {
      this.setState({ isLoggedIn: true });
      if (RoleUtil.currentUserHasRoles([Role.IECDB_ADMINISTRATOR])) {
        HistoryUtil.push(Routes.ADMIN_REVIEWACCOUNT);
      }
      else if (RoleUtil.currentUserHasRoles([Role.FILER])) {
        HistoryUtil.push(Routes.SCHEDULE_SUMMARY);
      }
      else if (RoleUtil.currentUserHasRoles([Role.GIFTBEQUEST_DONOR])) {
        HistoryUtil.push(Routes.DONOR_SUMMARY);
      }
    }
  }

  render() {
    const columnOptions = { sm: 24, md: 12, lg: 8 };

    return (
      // type="flex"
        <Content className="content-pad">
            <Row>
                <Space size={'large'} direction="vertical" style={{flex: 1}}>
                    <Row>
                        <Col>
                            <Typography.Title level={4}>
                                Welcome to Iowa Ethics and Campaign Disclosure Board&apos;s Web Reporting System.
                            </Typography.Title>
                            <Typography.Paragraph style={{ marginBottom: 0 }}>
                                The Web Reporting System is used to electronically file ethics and campaign reports
                                through your internet browser and contains government data.  This system is monitored and 
                                any unauthorized use of the system is prohibited.  Misuse of the system is subject
                                to disciplinary action, including criminal and civil penalties from the state and 
                                federal government.
                            </Typography.Paragraph>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col {...columnOptions} >
                            <Typography.Title level={4}>
                                File an Ethics Report
                            </Typography.Title>
                            <Typography.Paragraph>
                                <span>Choose an ethics report to file, or </span>
                                <a
                                    onClick={() =>
                                        window.open('https://ethics.iowa.gov/ethics/ethics-reports-and-due-dates')
                                    }
                                    style={{
                                        color: '#111111',
                                        fontWeight: 'bold'
                                    }}>learn more about the types of ethics reports, who must file, when reports are due, and more.
                                </a>
                            </Typography.Paragraph>
                            <Typography.Paragraph>
                                <Link to={Routes.PFD}>Personal Financial Disclosure</Link>
                            </Typography.Paragraph>
                            <Typography.Paragraph>
                                <Link to={Routes.DONOR_REGISTER}>Agency Gift reports for New Users</Link>
                            </Typography.Paragraph>
                            <Typography.Paragraph>
                                <Link to={Routes.DUAL_COMP}>Dual Executive Branch Compensation</Link>
                            </Typography.Paragraph>
                            <Typography.Paragraph>
                                <Link to={Routes.SALES_BY_STATE}> Sales by Officials/Employees to State Agencies</Link>
                            </Typography.Paragraph>
                        </Col>
                        <Col className="column-divider" md={1}><Divider type="vertical" style={{ height: '100%' }} /></Col>
                        <Col {...columnOptions}>
                            <Typography.Title level={4}>
                                File a Campaign Report
                            </Typography.Title>
                            <Typography.Paragraph>
                                Choose a campaign report to file if you do not already have a registered committee.  If you have
                                a registered committee, sign in to manage your filings.
                            </Typography.Paragraph>
                            <Typography.Paragraph>
                                <a
                                    onClick={() =>
                                        window.open('https://ethics.iowa.gov/campaigns/campaign-reports-and-due-dates')
                                    }
                                    style={{
                                        color: '#111111',
                                        fontWeight: 'bold'
                                    }}>
                                    Learn more about the types of campaign reports, who must file, when reports are due, and more.
                                </a>
                            </Typography.Paragraph>
                            <Typography.Paragraph>
                                <Link to={Routes.DR1}>Statement of Organization (DR-1)</Link>
                            </Typography.Paragraph>
                            <Typography.Paragraph>
                                <Link to={Routes.VSR}>Verified Statement of Registration</Link>
                            </Typography.Paragraph>
                            <Typography.Paragraph>
                                <Link to={Routes.OTC}>One-Time Campaign Contribution from a Permanent Organization</Link>
                            </Typography.Paragraph>
                            <Typography.Paragraph>
                                <Link to={Routes.IIE}>Independent Expenditure from an Individual</Link>
                            </Typography.Paragraph>
                            <Typography.Paragraph>
                                <Link to={Routes.OIE}>Independent Expenditure from an Organization</Link>
                            </Typography.Paragraph>
                            <Typography.Paragraph>
                                <Link to={Routes.DRSFA}>Short-Form Attribution Statement (DR-SFA)</Link>
                            </Typography.Paragraph>
                        </Col>
                        <Col className="column-divider" md={1}><Divider type="vertical" style={{ height: '100%' }} /></Col>
                        <Col style={{ flex: 1}}>
                            <Typography.Title level={4}>Sign In</Typography.Title>
                            <Typography.Paragraph>If you already have a username and password, please sign in below.</Typography.Paragraph>
                            <Typography.Paragraph>
                                You DO NOT need to sign in to file any of the reports linked to the left.
                            </Typography.Paragraph>
                            <LoginComponent standalone={false} returnUrl={this.state.returnUrl} />
                        </Col>
                    </Row>
                </Space>
            </Row>
            <Divider />
            <Row gutter={20}>
                <Col {...columnOptions}>
                    <Typography.Title level={4}>
                        <Link to={Routes.PUBLIC_REPORTS} className="public-report-links">View Filed Reports</Link>
                    </Typography.Title>
                    <Typography.Paragraph>
                        View all filed ethics and campaign reports, search through data in our system and view late-filed
                        reports and associated penalties.
                    </Typography.Paragraph>
                </Col>
                <Col className="column-divider" md={1}><Divider type="vertical" style={{ height: '100%' }} /></Col>
                <Col {...columnOptions}>
                    <Typography.Title level={4}>
                        <Link to={Routes.PETITION_FOR_WAIVER} className="public-report-links">Request a Waiver</Link> 
                    </Typography.Title>
                    <Typography.Paragraph>
                        If you have received notice of a civil penalty for a late-filed ethics or campaign report, you may request
                        a reduction or waiver of the penalty.  The request must be submitted within 30 days of receiving a civil 
                        penalty assessment order from the Board.
                    </Typography.Paragraph>
                </Col>
                <Col className="column-divider" md={1}><Divider type="vertical" style={{ height: '100%' }} /></Col>
                <Col style={{ flex: 1 }}>
                    <Typography.Title level={4} >
                        <a className="public-report-links" onClick={() => window.open('https://ethics.iowa.gov/about/contact-us')}>
                            Questions?
                        </a>
                    </Typography.Title>
                    <Typography.Paragraph>
                        Contact the Iowa Ethics and Campaign Disclosure Board for assistance.
                    </Typography.Paragraph>
                </Col>
            </Row>
      </Content>
    );
   }
}

function mapDispatchToProps(dispatch: any) {
  return {
    logoutAction: () => dispatch(UserSessionActions.logout()),
  };
}

function mapStateToProps(state: StateStoreModel) {
  let userName: string | null = null;
  if (state.UserSession.Value !== null) {
    userName = state.UserSession.Value.userName;
  }
  return {
    loading: state.UserSession.Loading,
    error: state.UserSession.Error,
    userName: userName,
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PublicDashboard));
