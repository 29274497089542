// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
import BaseApi from './BaseApi';
import TableRequestDTO from '../models/TableRequestDTO';
import TableResponseDTO from '../models/TableResponseDTO';
import CommitteeUploadReportDTO from '../models/CommitteeUploadReportDTO';

export class CommitteeImportApiService extends BaseApi {

  // post: api/committee/import/import?committeeId=${encodeURIComponent(committeeId)}
  public import(request: FormData, committeeId: string): Promise<void> {
    let url = `api/committee/import/import`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (committeeId != null) {
      url += `${prefix}committeeId=${encodeURIComponent(committeeId)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, void>(request, 'post', url, false, false);
  }

  // post: api/committee/import/table?committeeId=${encodeURIComponent(committeeId)}
  public getUploadList(tableRequest: TableRequestDTO, committeeId: string): Promise<TableResponseDTO<CommitteeUploadReportDTO>> {
    let url = `api/committee/import/table`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (committeeId != null) {
      url += `${prefix}committeeId=${encodeURIComponent(committeeId)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, TableResponseDTO<CommitteeUploadReportDTO>>(tableRequest, 'post', url, true, false);
  }

  // get: api/committee/import?blobFileName=${encodeURIComponent(blobFileName)}
  public getBlob(blobFileName: string): Promise<string> {
    let url = `api/committee/import`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (blobFileName != null) {
      url += `${prefix}blobFileName=${encodeURIComponent(blobFileName)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, string>(null, 'get', url, true, false);
  }

  // delete: api/committee/import?id=${id}
  public deleteImport(id: number): Promise<void> {
    let url = `api/committee/import`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (id != null) {
      url += `${prefix}id=${id}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, void>(null, 'delete', url, true, false);
  }

  // put: api/committee/import/importSave?id=${id}&description=${encodeURIComponent(description)}
  public saveDescription(id: number, description: string): Promise<void> {
    let url = `api/committee/import/importSave`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (id != null) {
      url += `${prefix}id=${id}`;
      prefix = '&';
    }
    if (description != null) {
      url += `${prefix}description=${encodeURIComponent(description)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, void>(null, 'put', url, true, false);
  }
}
var service = new CommitteeImportApiService();
export default service;
