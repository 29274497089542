import { notification, Modal } from 'antd';
import * as React from 'react';
import DataTableColumnUtil from '../../shared/DataTable/DataTableColumnUtil';
import DataTable, { DataTableButton, DataTableColumnProps } from '../../shared/DataTable/DataTable';
import BuildingFundApiService from '../../../api/BuildingFundApiService';
import BuildingFundContributionListDTO from '../../../models/BuildingFundContributionListDTO';
import DataTableButtonUtil from '../../shared/DataTable/DataTableButtonUtil';
import HistoryUtil from '../../../utils/HistoryUtil';
import Routes from '../../../consts/Routes';

const { confirm } = Modal;

interface BuildingFundContributionTableProps {
  buildingFundId: number;
  onRefresh?: () => void;
  filed?: boolean;
}

interface BuildingFundContributionTableState {
}

class BuildingFundContributionTable extends React.Component<BuildingFundContributionTableProps, BuildingFundContributionTableState> {
  private dataTable: DataTable<BuildingFundContributionTable> | undefined;

  constructor(props: BuildingFundContributionTableProps) {
    super(props);

    this.state = {};
  }

  render() {
    const { buildingFundId } = this.props;

    const actionButtons: DataTableButton[] = [];

    actionButtons.push(DataTableButtonUtil.Reset());
    actionButtons.push(DataTableButtonUtil.Primary(
      'New Contribution',
      () => HistoryUtil.push(Routes.generate(
        Routes.BUILDINGFUND_CONTRIBUTIONS, { buildingFundId: buildingFundId.toString(), contributionId: '0' }
      ))
    ));

    return (
      <>
        <DataTable
          buttonBar={actionButtons}
          columns={this.getTableColumns()}
          fetchData={{
            fetch: function (tableRequest) {
              return BuildingFundApiService.getBuildingFundContributionList(tableRequest, buildingFundId);
            },
            failureMessage: 'Failed to retrieve contribution history'
          }}
          globalSearch={true}
          ref={(element: any) => (this.dataTable = element)}
          serverSide={true}
          styleOptions={{ compact: true, alternatingRowHighlight: true }}
          tableProps={{
            rowKey: 'contributionId',
            sortDirections: ['ascend', 'descend'],
            locale: { emptyText: 'Currently there are no contributions to view' }
          }}
          title="Contributions"
        />
      </>
    );
  }

  private getTableColumns = (): DataTableColumnProps<BuildingFundContributionListDTO>[] => {
    const tableColumns: DataTableColumnProps<BuildingFundContributionListDTO>[] = [
      DataTableColumnUtil.Date('Date', 'contributionDate', 100),
      DataTableColumnUtil.Address('Contributor', 'contributorName',
        (c) => ({
          name: c.contributorName,
          line1: c.contributorAddressLine1,
          line2: c.contributorAddressLine2,
          city: c.contributorCity,
          state: c.contributorState,
          zip: c.contributorZip
        })
      ),
      DataTableColumnUtil.Text('Description', 'description'),
      DataTableColumnUtil.Currency('Amount', 'contributionAmount'),
    ];

    tableColumns.push(
      DataTableColumnUtil.Buttons('contributionId',
        [
          {
            text: 'Edit',
            onClick: (rowData) =>
              HistoryUtil.push(Routes.generate(
                Routes.BUILDINGFUND_CONTRIBUTIONS,
                {
                  buildingFundId: this.props.buildingFundId,
                  contributionId: rowData.contributionId || '',
                }))
          },
          {
            text: 'Delete',
            onClick: (rowData) => this.confirmDelete(
              rowData.contributionId.toString() || '',
              rowData.contributorName || '',
              rowData.contributionAmount || 0)
          }
        ],
        150)
    );

    return tableColumns;
  }

  private showTableAndRefresh = () => {
    if (this.dataTable) {
      this.dataTable.refresh();
    }
  }

  private confirmDelete = (contributionId: string, contributorName: string, amount: number) => {
    confirm({
      title: 'Are you sure you want to delete this contribution?',
      content: 'The following record will be permanently deleted: ' + contributorName + ' - $' + amount,
      onOk: () => {
        this.deleteContribution(contributionId);
      },
    });
  }

  private deleteContribution = (contributionId: string) => {
    BuildingFundApiService.deleteBuildingFundContribution(parseInt(contributionId))
      .then(() => {
        this.showTableAndRefresh();
        notification.success({
          message: 'Deleted contribution successfully.'
        });

        if (this.props.onRefresh) {
          this.props.onRefresh();
        }
      }).catch(() => {
        notification.error({
          message: 'Failed to delete contribution.',
          description: '',
        });
      });
  }
}

export default BuildingFundContributionTable;