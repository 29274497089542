import { LoadingOutlined } from '@ant-design/icons';
import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Typography
} from 'antd';
import { FormInstance } from 'antd/lib/form';
import * as React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Role from '../../consts/Role';
import Routes from '../../consts/Routes';
import LoginDTO from '../../models/LoginDTO';
import { UserSessionActions } from '../../redux/actions/UserSessionActions';
import { StateStoreModel } from '../../redux/state/StateStoreModel';
import HistoryUtil from '../../utils/HistoryUtil';
import RoleUtil from '../../utils/RoleUtil';
import CustomForm from '../shared/CustomForm';
import PageTitle from '../shared/PageTitle';

const FormItem = Form.Item;

interface LoginState {
  model: LoginDTO;
  shouldRequestPassword: boolean;
  errors?: string[] | null;
}

interface LoginProps {
  standalone?: boolean;
  userName: string | null;
  returnUrl?: string;
  loading: boolean;
  error: any;
  loginAction: (dto: LoginDTO) => void;
}

class LoginComponent extends React.Component<LoginProps, LoginState> {
  private readonly _formRef = React.createRef<FormInstance>();
  private _clickedSubmit = false;
  private passwordInput: any;
  // private passwordInput: ((instance: any) => void) | React.RefObject<any> | null | undefined;

  constructor(props: LoginProps) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.passwordInput = React.createRef();
    this.focusPassword = this.focusPassword.bind(this);

    this.state = {
      model: LoginDTO.create(),
      shouldRequestPassword: false,
    };
  }

  focusPassword() {
    if (this.passwordInput !== null && this.passwordInput !== undefined) {
      this.passwordInput.current.focus();
    }
  }

  componentDidMount() {
    const urlParams = new URLSearchParams(location.search);
    const errors = urlParams.get('errors');
    if (errors) {
      this.setState({ errors: errors.split('|') });
    }
  }

  componentDidUpdate() {
    if (this.props.userName !== null && this.props.error === null && !this.props.loading && this._clickedSubmit) {
      if (this.props.returnUrl) {
        HistoryUtil.push(this.props.returnUrl);
      } else {
        if (RoleUtil.currentUserHasRoles([Role.IECDB_ADMINISTRATOR])) {
          HistoryUtil.push(Routes.ADMIN_REVIEWACCOUNT);
        }
        else if (RoleUtil.currentUserHasRoles([Role.FILER])) {
          HistoryUtil.push(Routes.SCHEDULE_SUMMARY);
        }
        else if (RoleUtil.currentUserHasRoles([Role.GIFTBEQUEST_DONOR])) {
          HistoryUtil.push(Routes.DONOR_SUMMARY);
        }
        else {
          HistoryUtil.push(Routes.HOME_ROUTE); // TODO: Change to some other route
        }
      }
    }
    if (this._clickedSubmit && this.state.errors) {
      this.setState({ errors: null });
    }
  }

  handleSubmit() {
    const model = this._formRef ? (this._formRef.current as any).getFieldsValue() : null;

    // TODO: Remove all external login code
    // if (model.userName && /@claim-doc.com\s*$/.test(model.userName)) {
    //   this.redirectForExternalLogin(model.userName);
    //   this._clickedSubmit = true;
    // } else if (this.state.shouldRequestPassword) {
    //   this.props.loginAction(model);
    //   this._clickedSubmit = true;
    // } else {
    //   this.setState({ shouldRequestPassword: true }, () => {
    //     this.focusPassword();
    //   });
    // }

    this.props.loginAction(model);
    this._clickedSubmit = true;
  }

  redirectForExternalLogin(userName: string) {
    if (typeof window !== undefined) {
      let url = 'api/Account/ExternalLogin';
      let prefix = url.indexOf('?') > 0 ? '&' : '?';

      url += `${prefix}provider=${encodeURIComponent('AzureAD')}`;
      prefix = '&';
      url += `${prefix}login=${encodeURIComponent(userName)}`;

      if (this.props.returnUrl != null) {
        url += `${prefix}returnUrl=${encodeURIComponent(this.props.returnUrl)}`;
      }

      window.location.href = url;
    }
  }

  render() {
    return (
      this.props.standalone ? (
        // type="flex"
        <Row justify="center" align="middle">
          <Col className="flex-grow" />
          <Col xxl={6} xl={8} lg={10} md={14} sm={18} xs={24}>
            <Card>
              {this.renderLoginForm()}
            </Card>
          </Col>
          <Col className="flex-grow" />
        </Row>
      ) : this.renderLoginForm()
    );
  }

  private renderLoginForm = () => {
    const { loading, error } = this.props;
    let alert: JSX.Element | null = null;
    if (error !== null || this.state.errors) {
      let message = 'An error occurred while trying to log you in.';
      if (error?.message || this.state.errors) {
        message = error?.message ?? this.state.errors?.join('\n');
      }
      alert = (
        <Alert
          message="Error"
          description={message}
          type="error"
          showIcon={true}
          style={{ marginBottom: '12px', whiteSpace: 'pre-wrap' }}
        />
      );
    }

    const emptyCol = { xs: 0, sm: 3, md: 3 };
    const wrapperCol = { xs: 24, sm: 18, md: 18 };
    const wrapperColNoLabel = {};
    Object.keys(wrapperCol).forEach((val) => {
      wrapperColNoLabel[val] = {
        span: wrapperCol[val],
        offset: wrapperCol[val] + emptyCol[val] > 24 ? 0 : emptyCol[val],
      };
    });

    return (
      <>
        {this.props.standalone ? (
          <>
            <div className="full-logo">
              <img src={process.env.PUBLIC_URL + '/iecdb-logo.svg'} alt="IECDB Web Reporting System" />
              <Typography.Title level={5}>IECDB Web Reporting System</Typography.Title>
            </div>
            <span style={{ textAlign: 'center' }}>
              <PageTitle title="Login" hide={true} />
              <Typography.Title level={3} style={{ whiteSpace: 'pre-wrap', marginBottom: '24px' }}>
                Welcome to the IECDB Web Reporting System!
                <br /> Please login or sign up below:
              </Typography.Title>
            </span>
          </>
        ) : undefined}
        {alert}
        <CustomForm formRef={this._formRef} layout="vertical" onFinish={this.handleSubmit} requiredMark={false}>
          <FormItem
            name="userName"
            label="Username"
            rules={[{ required: true, message: 'Username is required' }]}
            labelCol={wrapperColNoLabel}
            wrapperCol={wrapperColNoLabel}
          >
            <Input />
          </FormItem>
          <FormItem
            name="password"
            label="Password"
            rules={[{ required: true, message: 'Password is required' }]}
            labelCol={wrapperColNoLabel}
            wrapperCol={wrapperColNoLabel}
          >
            <Input.Password ref={this.passwordInput} />
          </FormItem>
          <FormItem wrapperCol={wrapperColNoLabel}>
            <Button type="primary" htmlType="submit" size="large" disabled={loading} shape="round" block>
              {loading ? (
                <span>
                  <LoadingOutlined /> Logging in...
                </span>
              ) : (
                <span>Login</span>
              )}
            </Button>
          </FormItem>
          <FormItem name="forgotPassword" style={{ textAlign: 'center', marginBottom: 0 }}>
            <Link to={Routes.REQUEST_RESET_PASSWORD}>Forgot Password?</Link>
          </FormItem>
          <FormItem name="resendConfirmationEmail" style={{ textAlign: 'center', marginBottom: 0 }}>
            <Link to={Routes.RESEND_CONFIRMATION_EMAIL}>Resend Confirmation Email</Link>
          </FormItem>
        </CustomForm>
      </>
    );
  }

  private goToRegister = () => {
    HistoryUtil.push(Routes.generate(Routes.REGISTER));
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    loginAction: (dto: LoginDTO) => dispatch(UserSessionActions.login(dto)),
  };
}

function mapStateToProps(state: StateStoreModel) {
  let userName: string | null = null;
  if (state.UserSession.Value !== null) {
    userName = state.UserSession.Value.userName;
  }
  return {
    loading: state.UserSession.Loading,
    error: state.UserSession.Error,
    userName: userName,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginComponent);
