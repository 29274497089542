import { Checkbox, Col, Divider, Form, FormInstance, FormProps, Input, Modal, notification, Typography } from 'antd';
import * as React from 'react';
import DR1FormTypes from '../../consts/DR1FormTypes';
import FilerType from '../../consts/FilerType';
import BankAccountDTO from '../../models/BankAccountDTO';
import CommitteeInfoDTO from '../../models/CommitteeInfoDTO';
import DR1DTO from '../../models/DR1DTO';
import PersonInfoDTO from '../../models/PersonInfoDTO';
import SecondaryCommitteeInfoDTO from '../../models/SecondaryCommitteeInfoDTO';
import BankAccount from './BankAccount';
import PersonInfo from './PersonInfo';
import SecondaryCommitteeInfo from './SecondaryCommitteeInfo';
import CommitteeInfo from './CommitteeInfo';
import { Link } from 'react-router-dom';
import Routes from '../../consts/Routes';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import FormItem from 'antd/lib/form/FormItem';
import FormValidationUtil from '../../utils/FormValidationUtil';
import FormInputLen from '../../consts/FormInputLengths';
import FilerTypeDTO from '../../models/FilerTypeDTO';
import FilingStatusType from '../../consts/FilingStatusType';
import CurrentUser from '../../utils/CurrentUser';
import DateUtil from '../../utils/DateUtil';
import moment from 'moment';
import OrganizationApiService from '../../api/OrganizationApiServices';
import RegisterFormItems from '../account/RegisterFormItems';
import CustomForm from './CustomForm';

interface UserIdAffirmationProps {
  DR1Ref: DR1DTO;
  filerType: FilerType;
  disable?: boolean;
  confirmBox?: (checked: boolean) => void;
  confirmCheck?: boolean;
  onEmailSave?: () => void;
}

interface UserIdAffirmationState {
  toggleCheck: boolean;
  showEmailModal: boolean;
  dr1: DR1DTO;
}

class UserIdAffirmation extends React.Component<UserIdAffirmationProps & FormProps, UserIdAffirmationState> {
  private _committeeInfoRef: CommitteeInfo | null = null;
  private readonly _formRef = React.createRef<FormInstance>();
  constructor(props: UserIdAffirmationProps & FormProps) {
    super(props);

    this.state = {
      toggleCheck: this.props.confirmCheck || false,
      showEmailModal: false,
      dr1: this.props.DR1Ref,
    };
  }

  componentDidMount = () => {
    this.setState({});
  }
  render() {
    const { filerType, disable } = this.props;
    const { showEmailModal, dr1 } = this.state;
    let reviewScreenType = FilerType.CANDIDATE;

    if (filerType == FilerType.PAC) {
      reviewScreenType = FilerType.PAC;
    }
    else if (filerType == FilerType.OSF) {
      reviewScreenType = FilerType.OSF;
    }

    return (
      <Col span={24}>
        <Typography.Title level={3}>Please Review the following information</Typography.Title>
        {reviewScreenType === FilerType.CANDIDATE && (
          <Col>
            <Divider />
            <CommitteeInfo
              ref={(element) => this._committeeInfoRef = element}
              disabled={true}
              filerType={FilerType.CANDIDATE}
              committeeInfo={dr1.committeeInfo || CommitteeInfoDTO.create()}
              filterTypes={false} />
            {disable &&
              <Typography.Paragraph>
                <Link to={{
                  pathname: Routes.generate(Routes.ORGANIZATIONS_BASE),
                  state: { showTabKey: 'committeeInfo' }
                }}>Update Committee Details </Link>
              </Typography.Paragraph>}
            <Divider />
            <PersonInfo
              disabled={true}
              filerType={FilerType.CANDIDATE}
              formType={DR1FormTypes.CANDIDATE}
              personValues={dr1.personInfo1 || PersonInfoDTO.create()} />
            {disable &&
              <Typography.Paragraph>
                <Link to={{
                  pathname: Routes.generate(Routes.ORGANIZATIONS_BASE),
                  state: { showTabKey: 'personInfo1' }
                }}>Update Candidate Details </Link>
              </Typography.Paragraph>}
            <Divider />
            <PersonInfo
              disabled={true}
              filerType={FilerType.CANDIDATE}
              formType={DR1FormTypes.CHAIRHOLDER}
              personValues={dr1.personInfo2 || PersonInfoDTO.create()} />
            {disable &&
              <Typography.Paragraph>
                <Link to={{
                  pathname: Routes.generate(Routes.ORGANIZATIONS_BASE),
                  state: { showTabKey: 'personInfo2' }
                }}>Update Chairperson Details </Link>
              </Typography.Paragraph>}
            <Divider />
            <PersonInfo
              disabled={true}
              filerType={FilerType.CANDIDATE}
              formType={DR1FormTypes.TREASURER}
              personValues={dr1.personInfo3 || PersonInfoDTO.create()} />
            {disable &&
              <Typography.Paragraph>
                <Link to={{
                  pathname: Routes.generate(Routes.ORGANIZATIONS_BASE),
                  state: { showTabKey: 'personInfo3' }
                }}>Update Treasurer Details </Link>
              </Typography.Paragraph>}
            <Divider />
            <BankAccount
              disabled={true}
              bankAccounts={dr1.bankAccounts || BankAccountDTO[0].add(BankAccountDTO.create({ state: '', accountType: '' }))} />
            {disable &&
              <Typography.Paragraph>
                <Link to={{
                  pathname: Routes.generate(Routes.ORGANIZATIONS_BASE),
                  state: { showTabKey: 'bankAccounts' }
                }}>Update Bank Account Details </Link>
              </Typography.Paragraph>}
            {!disable &&
              <>
              <Divider />
              <Typography.Title level={3}>User Account</Typography.Title>
                <CustomForm layout="vertical" initialValues={dr1.register || undefined} >
                  <RegisterFormItems review={true} />
                </CustomForm>
              </>
            }
            <Divider />
          </Col>
        )}
        {reviewScreenType === FilerType.PAC && (
          <Col>
            <Divider />
            <CommitteeInfo
              ref={(element) => this._committeeInfoRef = element}
              disabled={true}
              filerType={FilerType.PAC}
              committeeInfo={dr1.committeeInfo || CommitteeInfoDTO.create()}
              filterTypes={false} />
            {disable &&
              <Typography.Paragraph>
                <Link to={{
                  pathname: Routes.generate(Routes.ORGANIZATIONS_BASE),
                  state: { showTabKey: 'committeeInfo' }
                }}>Update Committee Details </Link>
              </Typography.Paragraph>}
            <Divider />
            <SecondaryCommitteeInfo
              disabled={true}
              filerType={FilerType.PAC}
              formType={DR1FormTypes.PARENT}
              committeeValues={dr1.secondaryCommittee1 || SecondaryCommitteeInfoDTO.create()} />
            {disable &&
              <Typography.Paragraph>
                <Link to={{
                  pathname: Routes.generate(Routes.ORGANIZATIONS_BASE),
                  state: { showTabKey: 'parentEntity' }
                }}>Update Parent Details </Link>
              </Typography.Paragraph>}
            <Divider />
            <PersonInfo
              disabled={true}
              filerType={FilerType.PAC}
              formType={DR1FormTypes.CHAIRHOLDER}
              personValues={dr1.personInfo2 || PersonInfoDTO.create()} />
            {disable &&
              <Typography.Paragraph>
                <Link to={{
                  pathname: Routes.generate(Routes.ORGANIZATIONS_BASE),
                  state: { showTabKey: 'personInfo2' }
                }}>Update Chairperson Details </Link>
              </Typography.Paragraph>}
            <Divider />
            <PersonInfo
              disabled={true}
              filerType={FilerType.PAC}
              formType={DR1FormTypes.TREASURER}
              personValues={dr1.personInfo3 || PersonInfoDTO.create()} />
            {disable &&
              <Typography.Paragraph>
                <Link to={{
                  pathname: Routes.generate(Routes.ORGANIZATIONS_BASE),
                  state: { showTabKey: 'personInfo3' }
                }}>Update Treasurer Details </Link>
              </Typography.Paragraph>}
            <Divider />
            <BankAccount
              disabled={true}
              bankAccounts={dr1.bankAccounts || BankAccountDTO[0].add(BankAccountDTO.create({ state: '', accountType: '' }))} />
            {disable &&
              <Typography.Paragraph>
                <Link to={{
                  pathname: Routes.generate(Routes.ORGANIZATIONS_BASE),
                  state: { showTabKey: 'bankAccounts' }
                }}>Update Bank Account Details </Link>
              </Typography.Paragraph>}
            {!disable &&
              <>
              <Divider />
                <Typography.Title level={3}>User Account</Typography.Title>
                <CustomForm layout="vertical" initialValues={dr1.register || undefined} >
                  <RegisterFormItems review={true} />
                </CustomForm>
              </>
            }
          </Col>
        )}
        {reviewScreenType === FilerType.OSF && (
          <Col>
            <Divider />
            <CommitteeInfo
              disabled={true}
              filerType={FilerType.OSF}
              committeeInfo={dr1.committeeInfo || CommitteeInfoDTO.create()}
              filterTypes={false} />
            {disable &&
              <Typography.Paragraph>
                <Link to={{
                  pathname: Routes.generate(Routes.ORGANIZATIONS_BASE),
                  state: { showTabKey: 'committeeInfo' }
                }}>Update Committee Details </Link>
              </Typography.Paragraph>}
            <Divider />
            <SecondaryCommitteeInfo
              disabled={true}
              filerType={FilerType.OSF}
              formType={DR1FormTypes.PARENT}
              committeeValues={dr1.secondaryCommittee1 || SecondaryCommitteeInfoDTO.create()} />
            {disable &&
              <Typography.Paragraph>
                <Link to={{
                  pathname: Routes.generate(Routes.ORGANIZATIONS_BASE),
                  state: { showTabKey: 'parentEntity' }
                }}>Update Parent Details </Link>
              </Typography.Paragraph>}
            <Divider />
            <PersonInfo
              disabled={true}
              filerType={FilerType.OSF}
              formType={DR1FormTypes.RESIDENTAGENT}
              personValues={dr1.personInfo2 || PersonInfoDTO.create()} />
            {disable &&
              <Typography.Paragraph>
                <Link to={{
                  pathname: Routes.generate(Routes.ORGANIZATIONS_BASE),
                  state: { showTabKey: 'personInfo2' }
                }}>Update Resident Agent Details </Link>
              </Typography.Paragraph>}
            <Divider />
            <PersonInfo
              disabled={true}
              filerType={FilerType.OSF}
              formType={DR1FormTypes.CONTACT}
              personValues={dr1.personInfo3 || PersonInfoDTO.create()} />
            {disable &&
              <Typography.Paragraph>
                <Link to={{
                  pathname: Routes.generate(Routes.ORGANIZATIONS_BASE),
                  state: { showTabKey: 'personInfo3' }
                }} >Update Contact Person Details </Link>
              </Typography.Paragraph>}
            <Divider />
            <SecondaryCommitteeInfo
              disabled={true}
              filerType={FilerType.OSF}
              formType={DR1FormTypes.JURISDICTION}
              committeeValues={dr1.secondaryCommittee2 || dr1.jurisdictionInfo || SecondaryCommitteeInfoDTO.create()} />
            {disable &&
              <Typography.Paragraph>
                <Link to={{
                  pathname: Routes.generate(Routes.ORGANIZATIONS_BASE),
                  state: { showTabKey: 'jurisdictionInfo' }
                }}>Update Jurisdiction Details </Link>
              </Typography.Paragraph>}
            {!disable &&
              <>
              <Divider />
                <Typography.Title level={3}>User Account</Typography.Title>
                <CustomForm layout="vertical" initialValues={dr1.register || undefined} >
                  <RegisterFormItems review={true} />
                </CustomForm>
              </>
            }
          </Col>)}
        {disable &&
          <Checkbox onChange={this.handleCheckbox} checked={this.state.toggleCheck} >
            I confirm  that all information above is current and correct</Checkbox>}<br /><br />
        {!disable &&
          <div className="review-affirmation">
            <Typography.Title level={3}>STATEMENT OF AFFIRMATION: By filing this document the committee affirms the following:</Typography.Title>
            {(reviewScreenType === FilerType.CANDIDATE || reviewScreenType === FilerType.PAC) && (
              <ol>
                <li>
                  <Typography.Paragraph>
                    The committee and all persons connected with the committee understand
                    that they are subject to the laws in Iowa Code chapters 68A and 68B
                    and the administrative rules in Chapter 351 of the Iowa Administrative Code.
                  </Typography.Paragraph>
                </li>
                <li>
                  <Typography.Paragraph>
                    That Iowa Code section 68A.402 and rule 351-4.9 require
                    the filing of disclosure reports and that the failure to file these
                    reports on or before the required due dates subjects the candidate or
                    chairperson (in the case of committees other than a candidate&apos;s committee)
                    to the automatic assessment of a civil penalty and the possible imposition
                    of other criminal and civil sanctions.
                  </Typography.Paragraph>
                </li>
                <li>
                  <Typography.Paragraph>
                    That Iowa Code section 68A.405 and rules 351-4.38
                    through 4.43 require the placement of the words &quot;paid for by&quot; and
                    the name of the committee on all political materials except for those items
                    exempted by statute or rule. A committee filing this statement for purposes
                    of using the shorter &quot;paid for by&quot; and who have not crossed the
                    $1000 shall notify the Board that the $750 threshold will not be crossed.
                  </Typography.Paragraph>
                </li>
                <li>
                  <Typography.Paragraph>
                    That Iowa Code section 68A.503 and rules 351-4.44
                    through 4.52 prohibit the receipt of corporate contributions by all committees
                    except for statewide and local ballot issue PACs.
                  </Typography.Paragraph>
                </li>
                <li>
                  <Typography.Paragraph>
                    A candidate and a candidate&apos;s committee may
                    only expend campaign funds as permitted by Iowa code sections 68A.301 through
                    68A.303 and rule 351-4.25.
                  </Typography.Paragraph>
                </li>
                <li>
                  <Typography.Paragraph>
                    That the committee will continue to file disclosure
                    reports until all activity has ceased, committee funds spent, debts resolved,
                    a final report, and a statement of dissolution (DR-3) has been filed.
                  </Typography.Paragraph>
                </li>
              </ol>
            )}
            {reviewScreenType === FilerType.OSF && (
              <ol>
                <li>
                  <Typography.Paragraph>
                    The committee and all persons connected with the committee understand by
                    making contributions to Iowa committees that they are subject to the laws
                    in Iowa Code chapters 68A and 68B and the administrative rules in Chapter 351
                    of the Iowa Administrative Code. That the committee and all persons connected
                    with the committee are familiar with Iowa Code section 68A.201(5) and rule 351-4.32.
                  </Typography.Paragraph>
                </li>
                <li>
                  <Typography.Paragraph>
                    That by filing this statement the committee files campaign disclosure reports in the
                    named jurisdiction and that the disclosure reports comply with requirements that are
                    substantially similar to Iowa Code section 68A.402A.
                  </Typography.Paragraph>
                </li>
                <li>
                  <Typography.Paragraph>
                    That the contribution made to the Iowa candidate&apos;s committee, political party committee,
                    or state political committee (State PAC) was made from an account that does not include
                    funds from a corporation, insurance company, or financial institution as prohibited in
                    Iowa Code section 68A.503. This prohibition does not apply to ballot issue committees.
                  </Typography.Paragraph>
                </li>
                <li>
                  <Typography.Paragraph>
                    That this statement is required to be filed within 15 days of the date of the contribution
                    and that a late-filed statement is subject to an automatic civil penalty. That intentionally
                    failing to timely file a statement can lead to the imposition of additional civil and
                    criminal penalties.
                  </Typography.Paragraph>
                </li>
              </ol>
            )}
          </div>
        }
        {showEmailModal &&
          <Modal
            title="Committee Email"
            visible={showEmailModal}
            onCancel={this.handleCancel}
            onOk={this.handleOk}>
            <Typography.Paragraph>Please enter a committee email.</Typography.Paragraph>
            <Form ref={this._formRef} >
              <FormItem
                name="committeeEmail"
                label="Committee Email"
                rules={[FormValidationUtil.Required('Committee Email is required'), FormValidationUtil.Email('Email is invalid')]}>
                <Input maxLength={FormInputLen.COMMITTEEEMAIL} />
              </FormItem>
            </Form>
          </Modal>
        }
      </Col>
    );
  }

  private handleCancel = () => {
    this.setState({ showEmailModal: false, toggleCheck: false });
  }

  private handleOk = () => {
    const DR1 = { ...this.state.dr1 };
    if (DR1) {
      this._formRef.current?.validateFields().then((values) => {
        const fileTypeDTO = FilerTypeDTO.create({});
        fileTypeDTO.id = CurrentUser.Get()?.filerTypeId || 0;
        DR1.committeeFilerType = { ...this.state.dr1.committeeFilerType, ...fileTypeDTO };
        DR1.committeeInfo = { ...this.state.dr1.committeeInfo, ...values };
        OrganizationApiService
          .editDR1(DR1, CurrentUser.Get()?.committeeId || '', FilingStatusType.AMENDED, DateUtil.toTimeStamp(moment()))
          .then(result => {
            if (result.succeeded) {
              notification.success({
                message: 'Your changes were saved successfully.'
              });
              this.setState({ showEmailModal: false, toggleCheck: true });
              if (this.props.onEmailSave) {
                this.props.onEmailSave();
              }
            }
          }).catch(() => {
            notification.error({
              message: 'Failed to update DR1.'
            });
          });
      });

    }
  }

  private handleCheckbox = (e: CheckboxChangeEvent) => {
    this._committeeInfoRef?.validate().then(() => {
      this.setState({ toggleCheck: e.target.checked }, () => {
        if (this.props.confirmBox) {
          this.props.confirmBox(this.state.toggleCheck);
        }
      });
    }).catch(() => {
      this.setState({ showEmailModal: true });
    });
  }
}

export default UserIdAffirmation;