import {
  Col,
  FormInstance,
  Row,
  Select,
  Typography
} from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import moment from 'moment';
import * as React from 'react';
import CommitteeFilingDTO from '../../models/CommitteeFilingDTO';
import CustomForm from './CustomForm';

const Option = Select.Option;
const { Title } = Typography;

interface Dr1StatusBarProps {
  committeeFilings: CommitteeFilingDTO[];
  effectiveDate: string | moment.Moment;
  disabled: boolean;
  onSelect?: (committeeFilings: CommitteeFilingDTO | undefined) => void;
  onChange?: (isHistoryText: string) => void;
}

interface Dr1StatusBarState {
  historyText: string;
  committeeFiling?: CommitteeFilingDTO;
}

class Dr1StatusBar extends React.Component<Dr1StatusBarProps, Dr1StatusBarState> {
  private readonly _formRef = React.createRef<FormInstance>();

  constructor(props: Dr1StatusBarProps) {
    super(props);
    this.state = {
      historyText: '',
      committeeFiling: CommitteeFilingDTO.create(),
    };
  }

  componentDidMount() {
    this.setInitialFilingHistoryValue(this._formRef);
  }

  render() {
    const { disabled, committeeFilings, effectiveDate } = this.props;
    const { historyText } = this.state;
    const style: React.CSSProperties = { color: 'red'};
 
    return (
      <CustomForm
        formRef={this._formRef}
        layout="vertical"
      >
        <Row gutter={24}>
          <Col >
            <label htmlFor="filingHistory" aria-label="filing-history-select">
            <FormItem name="filingHistory" label="">
              <Select
                disabled={disabled}
                showSearch
                optionFilterProp="children"
                onChange={this.loadFiledText}>
                {committeeFilings.length > 0 && committeeFilings.map(cf => (
                  <Option key={cf.id} value={cf.id}>{cf.filingStatusTypeName + ' On: ' + cf.submittedOn.toString()}</Option>
                ))}
                {committeeFilings.length <= 0 &&
                  <Option key="" value="">{'Filed On: ' + effectiveDate}</Option>
                }
              </Select>
              </FormItem>
            </label>
          </Col>
          <Col>
            <Title level={4}>Filed Electronically</Title>
          </Col>
          <Col>
            {!!historyText && (
              <Title level={4} style={style}>{historyText}</Title>
            )}
          </Col>
        </Row>
      </CustomForm>
    );
  }

  private setInitialFilingHistoryValue = (form: React.RefObject<FormInstance<any>> | null) => {
    if (form && form.current) {
      form.current.setFieldsValue({ filingHistory: this.props.committeeFilings.length > 0 ? this.props.committeeFilings[0].id : '' });
      this.loadFiledText(this.props.committeeFilings.length > 0 ? this.props.committeeFilings[0].id : 0);
    }
  }

  private loadFiledText = (uId: number) => {
    if (!uId) {
      return;
    }
    let historyText = '';
    const committeeFiling = this.props.committeeFilings.find(op => op.id == uId);
    if (committeeFiling) {
      if (this.props.committeeFilings.length > 0 && moment(committeeFiling?.submittedOn) < moment(this.props.committeeFilings[0].submittedOn)) {
        historyText = 'History View';
      }
    }
    this.setState({ committeeFiling, historyText});
    if (this.props.onSelect) {
      this.props.onSelect(committeeFiling);
    }
    if (this.props.onChange) {
      this.props.onChange(historyText);
    }
  }
}

export default Dr1StatusBar;
