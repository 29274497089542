import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import Routes from '../../consts/Routes';
import { RedirectAs404 } from '../../utils/RouteErrors';
import VsrContributions from './VsrContributions';
import ContributionForm from './ContributionForm';
import Dr2Penalty from '../schedule/Dr2Penalty';


const Contribution = () => (
  <Switch>
    <Route exact={true} path={Routes.VSR_CONTRIBUTIONS} component={VsrContributions} />
    <Route exact={true} path={Routes.ADD_VSR_CONTRIBUTION} component={ContributionForm} />
    <Route exact={true} path={Routes.VSR_PENALTY} component={Dr2Penalty} />
    <Route exact={true} path={Routes.EDIT_VSR_CONTRIBUTION} component={ContributionForm} />
    <Route component={RedirectAs404} />
  </Switch>
);

export default Contribution;