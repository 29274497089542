import { Divider, Layout, Modal, notification, Typography } from 'antd';
import * as React from 'react';
import SchBExpendituresApiService from '../../api/SchBExpendituresApiService';
import ScheduleApiService from '../../api/ScheduleApiService';
import Dr2AmendedIndicators from '../../consts/Dr2AmendedIndicators';
import Dr2AmendedStatuses from '../../consts/Dr2AmendedStatuses';
import NoteEntityParents from '../../consts/NoteEntityParents';
import Role from '../../consts/Role';
import Routes from '../../consts/Routes';
import ScheduleAbbreviationCodes from '../../consts/ScheduleAbbreviationCodes';
import SchBExpendituresDTO from '../../models/SchBExpendituresDTO';
import CurrentUser from '../../utils/CurrentUser';
import HistoryUtil from '../../utils/HistoryUtil';
import NumberFormatUtil from '../../utils/NumberFormatUtil';
import CrossReferenceModal from '../shared/CrossReferenceModal';
import DataTable, { ColumnType, DataTableColumnProps, FilterType } from '../shared/DataTable/DataTable';
import DataTableButtonUtil from '../shared/DataTable/DataTableButtonUtil';
import DataTableColumnUtil from '../shared/DataTable/DataTableColumnUtil';
import NotesModal from '../shared/NotesModal';
import Dr2StatusBar from './Dr2StatusBar';

const { Content } = Layout;
const { Text } = Typography;
const { confirm } = Modal;

interface SchBExpendituresProps {
}

interface SchBExpendituresState {
    tableColumns: DataTableColumnProps<SchBExpendituresDTO>[];
    numExpenditures: number;
    totalOfSched: number;
    flagNoteId: string;
    expendId: string;
    showModal: boolean;
    showReferenceModal: boolean;
    referenceId: string;
    referenceType: string;
}

class SchBExpenditures extends React.Component<SchBExpendituresProps, SchBExpendituresState> {
    constructor(props: SchBExpendituresProps) {
        super(props);

        this.state = {
            tableColumns: this.getTableColumns(),
            numExpenditures: 0,
            totalOfSched: 0,
            flagNoteId: '',
            expendId: '',
            showModal: false,
            showReferenceModal: false,
            referenceId: '',
            referenceType: '',
        };
    }

    private dataTable: DataTable<SchBExpenditures> | undefined;

    componentDidMount() {
        this.getStatistics();
    }

    render() {
        const { numExpenditures, totalOfSched, showModal, flagNoteId, expendId, referenceType, referenceId, showReferenceModal } = this.state;
        const actionButtons = [];
        actionButtons.push(DataTableButtonUtil.Reset());
        actionButtons.push(DataTableButtonUtil.Primary(
            'New Expenditure',
            () => HistoryUtil.push(Routes.generate(Routes.ADD_SCHEDULE_B_EXPENDITURE, {}))
        ));
        actionButtons.push(DataTableButtonUtil.Default(
            'Import',
            () => HistoryUtil.push(Routes.generate(Routes.IMPORT_SCHEDULE_B_EXPENDITURES, {}))
        ));

        const currUser = CurrentUser.Get();

        return (
            <>
                <Content className="content-pad">
                    <Dr2StatusBar dr2Id={currUser?.dr2Id || ''} />
                    <Text strong>
                        Number of Expenditures: {numExpenditures}&emsp;|&emsp;Total of Schedule: {NumberFormatUtil.currency(totalOfSched)}&emsp;|
                    </Text>
                    <NotesModal
                        parentId={currUser?.dr2Id || ''}
                        parent={NoteEntityParents.SCHEDULE}
                        scheduleCd={ScheduleAbbreviationCodes.SCHEDULEB} />
                    <Divider />
                    <DataTable
                        buttonBar={actionButtons}
                        columns={this.state.tableColumns}
                        fetchData={{
                            fetch: function (tableRequest) {
                                return SchBExpendituresApiService.getSchBExpenditures(tableRequest, currUser?.dr2Id || '');
                            },
                            failureMessage: 'Failed to retrieve expenditures'
                        }}
                        globalSearch={true}
                        ref={(element: any) => (this.dataTable = element)}
                        serverSide={true}
                        styleOptions={{ compact: true, alternatingRowHighlight: true }}
                        tableProps={{
                            rowKey: 'expenditureIdAndSeq',
                            sortDirections: ['ascend', 'descend'],
                            locale: { emptyText: 'Currently there are no expenditures to manage.' }
                        }}
                        title="Schedule B - Expenditures"
                        stateSaving={{
                            enabled: true,
                            tableUniqueKey: 'manageSchB',
                            perSession: true,
                        }}
                    />
                </Content>
                {showModal &&
                    <NotesModal
                        parentId={expendId || ''}
                        parent={NoteEntityParents.EXPENDITURE}
                        auditorsNoteId={flagNoteId}
                        canFlag={true}
                        onClose={() => { this.setState({ showModal: false }, () => this.dataTable?.refresh()); }} />
                }
                {showReferenceModal &&
                    <CrossReferenceModal
                    referenceId={referenceId}
                    referenceType={referenceType}
                    onClose={() => this.setState({ showReferenceModal: false }, () => this.dataTable?.refresh())} />
                }
            </>
        );
    }

    private getStatistics = () => {
        ScheduleApiService.getScheduleCount(CurrentUser.Get()?.dr2Id || '', ScheduleAbbreviationCodes.SCHEDULEB)
            .then(numExpenditures => {
                this.setState({ numExpenditures });
            }).catch(error => {
                notification.error({
                    message: 'Error while fetching the number of expenditures',
                    description: error.message
                });
            });

        ScheduleApiService.getScheduleTotal(CurrentUser.Get()?.dr2Id || '', ScheduleAbbreviationCodes.SCHEDULEB)
            .then(totalOfSched => {
                this.setState({ totalOfSched });
            }).catch(error => {
                notification.error({
                    message: 'Error while fetching the total of schedule',
                    description: error.message
                });
            });
    }

    private getTableColumns = (): DataTableColumnProps<SchBExpendituresDTO>[] => {
        const columnReference: DataTableColumnProps<SchBExpendituresDTO> = DataTableColumnUtil.CheckmarkButton('referenceId',
            [
                {
                    onClick: (rowData) => this.openReferenceModal(rowData.referenceId || '', rowData.referenceType || ''),
                    visible: (rowData) => rowData.referenceId != null
                }
            ]);
        columnReference.filterType = FilterType.BooleanRadio;
        columnReference.columnType = ColumnType.Boolean;
        columnReference.title = 'Reference';
        return [
            DataTableColumnUtil.Date('Date', 'expenditureDt', 100, { defaultSortOrder: 'ascend' }),
            DataTableColumnUtil.Address('Payee', 'expenditureName',
                (c) => ({
                    name: c.expenditureName,
                    line1: c.expenditureAddressLine1,
                    line2: c.expenditureAddressLine2,
                    city: c.expenditureCity,
                    state: c.expenditureState,
                    zip: c.expenditureZip
                })
            ),
            DataTableColumnUtil.Currency('Amount', 'expenditureAmt'),
            DataTableColumnUtil.Text('Check #', 'checkNumber', 125, { align: 'center' }),
            DataTableColumnUtil.DropdownMulti('Status', 'status',
                [
                    { text: Dr2AmendedStatuses.ORIGINAL, value: Dr2AmendedIndicators.ORIGINAL },
                    { text: Dr2AmendedStatuses.AMENDED, value: Dr2AmendedIndicators.AMENDED },
                    { text: Dr2AmendedStatuses.ADJUSTED, value: Dr2AmendedIndicators.ADJUSTED },
                    { text: Dr2AmendedStatuses.DELETED, value: Dr2AmendedIndicators.DELETED }
                ]),
            DataTableColumnUtil.Description('Description', 'explanation'),
            columnReference,
            DataTableColumnUtil.FlagButton('Flagged', 'flaggedNoteId', CurrentUser.Get()?.isInRole(Role.IECDB_ADMINISTRATOR) || false, [
                {
                    onClick: (rowData) => this.openFlagModal(rowData.flaggedNoteId || '', rowData.expenditureId || ''),
                    visible: (rowData) => rowData.flaggedNoteId != null
                }
            ]),
            DataTableColumnUtil.Buttons('expenditureIdAndSeq',
                [
                    {
                        text: 'Edit',
                        onClick: (rowData) =>
                            HistoryUtil.push(Routes.generate(
                                Routes.EDIT_SCHEDULE_B_EXPENDITURE,
                                {
                                    id: rowData.expenditureId || '',
                                    seqNum: rowData.seqNumber || '',
                                }
                            ))
                    },
                    {
                        text: 'Delete',
                        onClick: (rowData) => this.confirmDelete(
                            rowData.expenditureId || '',
                            rowData.seqNumber || 0,
                            rowData.expenditureName || '',
                            rowData.expenditureAmt || 0
                        )
                    }
                ],
                150)
        ];
    }

    private openFlagModal = (flagNoteId: string, expendId: string) => {
        this.setState({ showModal: true, flagNoteId, expendId }, () => this.dataTable?.refresh());
    }

    private showTableAndRefresh = () => {
        if (this.dataTable) {
            this.dataTable.refresh();
        }
        this.getStatistics();
    }

    private confirmDelete = (expenditureId: string, seqNum: number, payeeName: string, amount: number) => {
        confirm({
            title: 'Are you sure you want to delete this expenditure?',
            content: 'The following record will be permanently deleted: ' + payeeName + ' - $' + amount,
            onOk: () => {
                this.deleteContribution(expenditureId, seqNum);
            },
        });
    }

    private deleteContribution = (expenditureId: string, seqNum: number) => {
        SchBExpendituresApiService.delete(expenditureId, seqNum)
            .then(() => {
                this.showTableAndRefresh();
                notification.success({
                    message: 'Deleted Successfully'
                });
            }).catch(() => {
                notification.error({
                    message: 'Error while deleting expenditure',
                    description: '',
                });
            });
    }

    private openReferenceModal = (referenceId: string, referenceType: string) => {
        if (referenceType && referenceId) {
            this.setState({ referenceId, referenceType, showReferenceModal: true });
        }
    }
}

export default SchBExpenditures;