import { MissingTranslationOptions, SingleLanguageTranslation } from 'react-localize-redux';
import english from '../translations/en-us.json';

export interface Language {
  name: string;
  code: string;
  translation: SingleLanguageTranslation;
}

/** For when you need to know what language your translation is */
export interface LanguageWithTranslation {
  language: Language;
  translation: SingleLanguageTranslation;
}

/****** CONFIGURATION START ******/

//import spanish from '../translations/es.json';

const supportedLanguages: Language[] = [
  { name: 'English', code: 'en-US', translation: english },
  //{ name: 'Español', code: 'es', translation: spanish },
];

const defaultLanguage = 'en-US';

/****** CONFIGURATION END ******/

function GetLanguages() {
  return supportedLanguages;
}

function OnMissingTranslation(options: MissingTranslationOptions) {
  console.warn(`Missing translation: Lang: ${options.languageCode} | ID: ${options.translationId}`);
  return options.defaultTranslation || '';
}

export {
  supportedLanguages as SupportedLanguages,
  defaultLanguage as DefaultLanguage,
  GetLanguages,
  OnMissingTranslation
};
