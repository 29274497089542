import { Layout } from 'antd';
import * as React from 'react';
import SchH2PropertySalesApiService from '../../api/SchH2PropertySalesApiService';
import SchH2PropertySalesDTO from '../../models/SchH2PropertySalesDTO';
import DataTableColumnUtil from '../shared/DataTable/DataTableColumnUtil';
import DataTable, { DataTableColumnProps } from '../shared/DataTable/DataTable';
import Dr2AmendedStatuses from '../../consts/Dr2AmendedStatuses';
import Dr2AmendedIndicators from '../../consts/Dr2AmendedIndicators';
import PropertySaleStatuses from '../../consts/PropertySaleStatuses';
import PropertySaleIndicators from '../../consts/PropertySaleIndicators';

const { Content } = Layout;

interface SchH2PropertySaleHistoryTableProps {
  propertySaleId: string;
}

interface SchH2PropertySaleHistoryTableState {
}

class SchH2PropertySaleHistoryTable extends React.Component<SchH2PropertySaleHistoryTableProps, SchH2PropertySaleHistoryTableState> {
  constructor(props: SchH2PropertySaleHistoryTableProps) {
    super(props);

    this.state = {

    };
  }

  render() {
    const { propertySaleId } = this.props;
    return (
      <Content className="content-pad">
        <DataTable
          columns={this.getTableColumns()}
          fetchData={{
            fetch: function (tableRequest) { return SchH2PropertySalesApiService.getPropertySaleHistory(tableRequest, propertySaleId); },
            failureMessage: 'Failed to retrieve property sale history'
          }}
          serverSide={true}
          styleOptions={{ compact: true, alternatingRowHighlight: true }}

          tableProps={{
            rowKey: 'propertySaleIdAndSeq',
            sortDirections: ['ascend', 'descend'],
            locale: { emptyText: 'Property sale history not available.' }
          }}
        />
      </Content>
    );
  }

  private getTableColumns = (): DataTableColumnProps<SchH2PropertySalesDTO>[] => {
    const tableColumns: DataTableColumnProps<SchH2PropertySalesDTO>[] = [
      DataTableColumnUtil.Date('Date', 'propertySaleDate', 100),
      DataTableColumnUtil.Address('Payee', 'propertySaleName',
        (c) => ({
          name: c.propertySaleName,
          line1: c.propertySaleAddressLine1,
          line2: c.propertySaleAddressLine2,
          city: c.propertySaleCity,
          state: c.propertySaleState,
          zip: c.propertySaleZip
        })
      ),
      DataTableColumnUtil.Text('Property', 'propertyExplanation'),
      DataTableColumnUtil.Currency('Amount', 'propertyAmount'),
      DataTableColumnUtil.DropdownMulti('Sale or Donation', 'saleOrDonation',
        [
          { text: PropertySaleStatuses.SALE, value: PropertySaleIndicators.SALE },
          { text: PropertySaleStatuses.DONATION, value: PropertySaleIndicators.DONATION }
        ]),
      DataTableColumnUtil.DropdownMulti('Status', 'status',
        [
          { text: Dr2AmendedStatuses.ORIGINAL, value: Dr2AmendedIndicators.ORIGINAL },
          { text: Dr2AmendedStatuses.AMENDED, value: Dr2AmendedIndicators.AMENDED },
          { text: Dr2AmendedStatuses.ADJUSTED, value: Dr2AmendedIndicators.ADJUSTED },
          { text: Dr2AmendedStatuses.DELETED, value: Dr2AmendedIndicators.DELETED }
        ])
    ];
    return tableColumns;
  }
}

export default SchH2PropertySaleHistoryTable;
