// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import EntitySelectorDTO from './EntitySelectorDTO';

interface CommitteeTypeDTO extends EntitySelectorDTO { 
  code: string | null;
  committeeCategoryTypeCode: string | null;
  name: string | null;
  commFilingPeriodCatTypeCode: string | null;
  filerTypeName: string | null;
  locationFiledName: string | null;
  itemizedLevelLimitAmount: number | null;
  requiredPoliticalParty: boolean;
  requiredDistrict: boolean;
  requiredCounty: boolean;
  requiredCity: boolean;
  suppRptThresholdAmt: number | null;
  filerTypeCd: number | null;
}
const CommitteeTypeDTO = {
  create: (initValues?: Partial<CommitteeTypeDTO> | Record<string, unknown> | null) => {
    return Object.assign(EntitySelectorDTO.create(),
    {
      code: null,
      committeeCategoryTypeCode: null,
      name: null,
      commFilingPeriodCatTypeCode: null,
      filerTypeName: null,
      locationFiledName: null,
      itemizedLevelLimitAmount: null,
      requiredPoliticalParty: false,
      requiredDistrict: false,
      requiredCounty: false,
      requiredCity: false,
      suppRptThresholdAmt: null,
      filerTypeCd: null,
    },
    initValues);
  }
};

export default CommitteeTypeDTO;