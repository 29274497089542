import { Button, FormInstance, notification, Select, Row, Col, ColProps, Space, DatePicker } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import * as React from 'react';
import CommitteeTypeDTO from '../../../models/CommitteeTypeDTO';
import DataTable, { DataTableColumnProps } from '../../shared/DataTable/DataTable';
import DataTableColumnUtil from '../../shared/DataTable/DataTableColumnUtil';
import AdminReportApiService from '../../../api/AdminReportApiService';
import DataTableButtonUtil from '../../shared/DataTable/DataTableButtonUtil';
import FormValidationUtil from '../../../utils/FormValidationUtil';
import SupplementaryFilingComplianceReportDTO from '../../../models/SupplementaryFilingComplianceReportDTO';
import PeriodInfoDTO from '../../../models/PeriodInfoDTO';
import CustomForm from '../../shared/CustomForm';
import CurrentUser from '../../../utils/CurrentUser';
import Routes from '../../../consts/Routes';
import DateUtil from '../../../utils/DateUtil';
import moment, { Moment } from 'moment';
import * as StorageUtil from '../../../storage/StorageUtil';
import TableResponseDTO from '../../../models/TableResponseDTO';
import TableRequestDTO from '../../../models/TableRequestDTO';
import ReportTableExportDTO from '../../../models/ReportTableExportDTO';

const Option = Select.Option;
const { RangePicker } = DatePicker;

interface SupplementaryFilingComplianceReportProps {
  committeeTypes: CommitteeTypeDTO[];
  yearOptions: number[];
}

interface SupplementaryFilingComplianceReportState {
  filingPeriods: PeriodInfoDTO[];
  SupplementaryFilingComplianceForm: { year: string, filingPeriod: string, dateRange: any[] }
  SupplementaryFilingComplianceTableColumns: DataTableColumnProps<SupplementaryFilingComplianceReportDTO>[];
  SupplementaryFilingComplianceData: SupplementaryFilingComplianceReportDTO[];
  SupplementaryFilingComplianceLoading: boolean;
  SupplementaryFilingComplianceExporting: boolean;
}

class SupplementaryFilingComplianceReport extends React.Component<SupplementaryFilingComplianceReportProps,
  SupplementaryFilingComplianceReportState> {
  private readonly _SupplementaryFilingComplianceFormRef = React.createRef<FormInstance>();
  constructor(props: SupplementaryFilingComplianceReportProps) {
    super(props);

    this.state = {
      filingPeriods: [],
      SupplementaryFilingComplianceForm:
      {
        year: { ...JSON.parse(StorageUtil.getSupplementaryFilingReportColumnFilters()) }.year || new Date().getFullYear().toString(),
        filingPeriod: { ...JSON.parse(StorageUtil.getSupplementaryFilingReportColumnFilters()) }.filingPeriod || '',
        dateRange:
          (
            ({ ...JSON.parse(StorageUtil.getSupplementaryFilingReportColumnFilters()) }.dateRange as Array<Moment>)?.map(d => (moment(d))) || []
          )
      },
      SupplementaryFilingComplianceTableColumns: this.getSupplementaryFilingComplianceTableColumns(),
      SupplementaryFilingComplianceData: [],
      SupplementaryFilingComplianceLoading: false,
      SupplementaryFilingComplianceExporting: false,
    };
  }

  private SupplementaryFilingComplianceDataTable: DataTable<SupplementaryFilingComplianceReportDTO> | undefined;

  componentDidMount() {
    this.fetchFilingPeriods();
  }

  render() {
    const { filingPeriods, SupplementaryFilingComplianceForm, SupplementaryFilingComplianceTableColumns,
      SupplementaryFilingComplianceLoading, SupplementaryFilingComplianceExporting } = this.state;
    const { yearOptions } = this.props;
    const threeFieldColumnSizingProps: ColProps = { xs: 8, sm: 8, md: 8 };
    const dataTableButtons = [];
    dataTableButtons.push(DataTableButtonUtil.Reset());
    dataTableButtons.push(DataTableButtonUtil.Export(
      (tableRequest: TableRequestDTO) => {
        this.setState({ SupplementaryFilingComplianceExporting: true });
        const formValues = { ...this._SupplementaryFilingComplianceFormRef.current?.getFieldsValue() };
        const exportDTO = ReportTableExportDTO.create({
          TableRequest: tableRequest,
          periodMasterId: formValues.filingPeriod,
          startDate: DateUtil.toTimeStamp(moment(formValues.dateRange[0])),
          endDate: DateUtil.toTimeStamp(moment(formValues.dateRange[1]))
        });

        return AdminReportApiService.exportSupplementaryFilingComplianceList(exportDTO)
          .finally(() => {
            this.setState({ SupplementaryFilingComplianceExporting: false });
          }).catch(() => {
            notification.error({
              message: 'Error while exporting supplementary filing compliance reports'
            });
            this.setState({ SupplementaryFilingComplianceExporting: false });
          });
      }, SupplementaryFilingComplianceExporting));

    return (
      <>
        <CustomForm
          formRef={this._SupplementaryFilingComplianceFormRef}
          layout="vertical"
          initialValues={{ ...SupplementaryFilingComplianceForm }}
          onFinish={() => this.SupplementaryFilingComplianceDataTable?.refresh()}
          validateTrigger={['onChange', 'onBlur']}>
          <Row gutter={24}>
            <Col {...threeFieldColumnSizingProps}>
              <FormItem name="year" label="Year" rules={[FormValidationUtil.RequiredNumber('Year is required')]}>
                <Select showSearch={true} optionFilterProp="children" onChange={this.onYearChange}>
                  {yearOptions.map(y => (
                    <Option key={y} value={y}>{y}</Option>
                  ))}
                </Select>
              </FormItem>
            </Col>
            <Col {...threeFieldColumnSizingProps}>
              <FormItem name="filingPeriod" label="Filing Period" rules={[FormValidationUtil.RequiredNumber('Filing Period is required')]}>
                <Select showSearch={true} optionFilterProp="children">
                  <Option key="" value="" disabled={true}>-- Select Filing Period --</Option>
                  {filingPeriods.map(fp => (
                    <Option key={fp.id} value={fp.id}>{fp.name}</Option>
                  ))}
                </Select>
              </FormItem>
            </Col>
            <Col {...threeFieldColumnSizingProps}>
              <FormItem name="dateRange" label="Date Range" rules={[FormValidationUtil.RequiredDate('Date Range is required')]}>
                <RangePicker format={DateUtil.getDateFormatString()} />
              </FormItem>
            </Col>
          </Row>
          <Space>
            <Button type="primary" htmlType="submit">Search</Button>
          </Space>
        </CustomForm>
        <br /> <br />
        <DataTable
          ref={(element: any) => (this.SupplementaryFilingComplianceDataTable = element)}
          globalSearch={true}
          columns={SupplementaryFilingComplianceTableColumns}
          buttonBar={dataTableButtons}
          serverSide={true}
          tableProps={{
            rowKey: 'committeeName',
            sortDirections: ['ascend', 'descend'],
            locale: {
              emptyText: 'No records were found that match your criteria'
            },
            loading: SupplementaryFilingComplianceLoading
          }}
          fetchData={this.fetchSupplementaryFilingComplianceReportData}
          styleOptions={{ compact: true, alternatingRowHighlight: true }}
          title={'Supplementary Filing Compliance Report'}
          stateSaving={{
            enabled: true,
            tableUniqueKey: 'supplementaryFilingReports',
            perSession: true,
          }}
        />
      </>
    );
  }

  private getSupplementaryFilingComplianceTableColumns = (): DataTableColumnProps<SupplementaryFilingComplianceReportDTO>[] => {
    const { committeeTypes } = this.props;
    return [
      DataTableColumnUtil.Text('Committee Code', 'committeeCode'),
      DataTableColumnUtil.Text('Committee Name', 'committeeName'),
      DataTableColumnUtil.DropdownMulti('Committee Type', 'committeeType', committeeTypes.map(ct => ({ text: ct.name || '', value: ct.code || '' }))),
      DataTableColumnUtil.Currency('Contributions Total', 'contributionsTotal'),
      DataTableColumnUtil.Currency('In Kind Contributions Total', 'inKindContributionsTotal'),
      DataTableColumnUtil.Currency('Loans Received Total', 'loansReceivedTotal'),
      DataTableColumnUtil.Currency('Total', 'total', null, { defaultSortOrder: 'descend' }),
      DataTableColumnUtil.Buttons('dr2Id',
        [
          { text: 'View', onClick: (record) => this.openFilingPeriod(record) }
        ])
    ];
  }

  private openFilingPeriod = (record: SupplementaryFilingComplianceReportDTO) => {
    AdminReportApiService.getCommitteeInfo(record.committeeId || '').then((impersonationInfo) => {
      CurrentUser.Release();
      CurrentUser.Impersonate({
        committeeId: impersonationInfo.committeeId,
        committeeName: impersonationInfo.committeeName || null,
        committeeTypeName: impersonationInfo.committeeTypeName || null,
        committeeType: impersonationInfo.committeeTypeCode || null,
        filerTypeId: impersonationInfo.filerTypeId || null,
        dr2Id: record.dr2Id,
        committeeFilingCatType: impersonationInfo.committeeFilingCatType,
        auditing: true
      });
      window.open(Routes.generate(Routes.SCHEDULE_SUMMARY));
    });
  }

  private fetchSupplementaryFilingComplianceReportData = (requestState: TableRequestDTO,
    checkEcho: () => boolean,
    callback: (response: TableResponseDTO<SupplementaryFilingComplianceReportDTO>) => void
  ) => {
    const formValues = { ...this._SupplementaryFilingComplianceFormRef.current?.getFieldsValue() };
    if (formValues.dateRange != null && formValues.filingPeriod != '') {
      this.setState({ SupplementaryFilingComplianceLoading: true });
      return AdminReportApiService.getSupplementaryFilingComplianceList(requestState,
        formValues.filingPeriod,
        DateUtil.toTimeStamp(moment(formValues.dateRange[0])),
        DateUtil.toTimeStamp(moment(formValues.dateRange[1])))
        .then((res) => {
          if (!checkEcho()) {
            return;
          }
          callback(res);
          this.setState({ SupplementaryFilingComplianceLoading: false });
          const filterString = JSON.stringify(formValues);
          StorageUtil.setSupplementaryFilingReportColumnFilters(filterString);
        }).catch(() => {
          notification.error({
            message: 'Error while fetching supplementary filing compliance committees'
          });
          this.setState({ SupplementaryFilingComplianceLoading: false });
        });
    }
  }

  private onYearChange = () => {
    if (this._SupplementaryFilingComplianceFormRef.current) {
      this._SupplementaryFilingComplianceFormRef.current.setFieldsValue({ 'filingPeriod': '' });
      const year = this._SupplementaryFilingComplianceFormRef.current.getFieldValue('year');
      AdminReportApiService.getFilingPeriodsFromFilingType('SWGA', year)
        .then(filingPeriods => {
          const sortedFilingPeriods = filingPeriods.sort((a, b) => { return (a?.name || '') < (b?.name || '') ? -1 : 1; });
          this.setState({ filingPeriods: sortedFilingPeriods });
        });
    }
  }

  private fetchFilingPeriods = () => {
    if (this._SupplementaryFilingComplianceFormRef.current) {
      const year = this._SupplementaryFilingComplianceFormRef.current.getFieldValue('year');
      AdminReportApiService.getFilingPeriodsFromFilingType('SWGA', year)
        .then(filingPeriods => {
          const sortedFilingPeriods = filingPeriods.sort((a, b) => { return (a?.name || '') < (b?.name || '') ? -1 : 1; });
          this.setState({ filingPeriods: sortedFilingPeriods });
          if (StorageUtil.getSupplementaryFilingReportColumnFilters() != '{}') {
            this.SupplementaryFilingComplianceDataTable?.refresh();
          }
        });
    }
  }
}

export default SupplementaryFilingComplianceReport;
