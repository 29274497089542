import { Button, FormInstance, notification, Select, Row, Col, ColProps, Space } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import * as React from 'react';
import CommitteeTypeDTO from '../../../models/CommitteeTypeDTO';
import DataTable, { DataTableColumnProps } from '../../shared/DataTable/DataTable';
import PeriodReportDTO from '../../../models/PeriodReportDTO';
import DataTableColumnUtil from '../../shared/DataTable/DataTableColumnUtil';
import AdminReportApiService from '../../../api/AdminReportApiService';
import DataTableButtonUtil from '../../shared/DataTable/DataTableButtonUtil';
import FormValidationUtil from '../../../utils/FormValidationUtil';
import PeriodReportFormDTO from '../../../models/PeriodReportFormDTO';
import CommitteeTypeExtras from '../../../consts/CommitteeTypeExtras';
import PeriodInfoDTO from '../../../models/PeriodInfoDTO';
import CustomForm from '../../shared/CustomForm';
import * as StorageUtil from '../../../storage/StorageUtil';
import TableRequestDTO from '../../../models/TableRequestDTO';
import TableResponseDTO from '../../../models/TableResponseDTO';
import ReportTableExportDTO from '../../../models/ReportTableExportDTO';

const Option = Select.Option;

interface PeriodReportProps {
  committeeTypes: CommitteeTypeDTO[];
  yearOptions: number[];
}

interface PeriodReportState {
  filingPeriods: PeriodInfoDTO[];
  periodForm: PeriodReportFormDTO;
  periodTableColumns: DataTableColumnProps<PeriodReportDTO>[];
  periodData: PeriodReportDTO[];
  periodLoading: boolean;
  periodExporting: boolean;
}

class PeriodReport extends React.Component<PeriodReportProps, PeriodReportState> {
  private readonly _periodFormRef = React.createRef<FormInstance>();
  constructor(props: PeriodReportProps) {
    super(props);

    this.state = {
      filingPeriods: [],
      periodForm:
        (
          PeriodReportFormDTO.create({
            committeeType: { ...JSON.parse(StorageUtil.getPeriodReportColumnFilters()) }.committeeType || 0,
            year: { ...JSON.parse(StorageUtil.getPeriodReportColumnFilters()) }.year || new Date().getFullYear().toString(),
            filingPeriod: { ...JSON.parse(StorageUtil.getPeriodReportColumnFilters()) }.filingPeriod || ''
          })
        ),
      periodTableColumns: this.getPeriodTableColumns(),
      periodData: [],
      periodLoading: false,
      periodExporting: false,
    };
  }
  private periodDataTable: DataTable<PeriodReportDTO> | undefined;

  componentDidMount() {
    if (StorageUtil.getPeriodReportColumnFilters() != '{}') {
      this.fetchFilingPeriods();
    }
  }

  render() {
    const { filingPeriods, periodForm, periodTableColumns, periodLoading } = this.state;
    const { committeeTypes, yearOptions } = this.props;
    const threeFieldColumnSizingProps: ColProps = { xs: 8, sm: 8, md: 8 };
    const dataTableButtons = [];
    dataTableButtons.push(DataTableButtonUtil.Reset());
    dataTableButtons.push(DataTableButtonUtil.Export(
      (tableRequest: TableRequestDTO) => {
        this.setState({ periodExporting: true });
        const filingPeriodDesc = this.state.filingPeriods.find(s => s.id == this._periodFormRef.current?.getFieldValue('filingPeriod'))?.name;
        const districtNum = this._periodFormRef.current?.getFieldValue('committeeType') == CommitteeTypeExtras.ALLHOUSEDISTRICTS
          ? CommitteeTypeExtras.ALLHOUSEDISTRICTS
          : this.state.periodForm.committeeType == CommitteeTypeExtras.ALLSENATEDISTRICTS ? CommitteeTypeExtras.ALLSENATEDISTRICTS : 0;
        const exportDTO = ReportTableExportDTO.create({
          TableRequest: tableRequest,
          CommitteeType: districtNum != -1 && districtNum != -2 ?  this._periodFormRef.current?.getFieldValue('committeeType') : '0',
          PeriodMasterId: this._periodFormRef.current?.getFieldValue('filingPeriod'),
          FileName: filingPeriodDesc + ' ' + this._periodFormRef.current?.getFieldValue('year'),
          DistrictCommitteeType: districtNum,
        });
        return AdminReportApiService.exportSinglePeriodList(exportDTO)
          .finally(() => {
            this.setState({ periodExporting: false });
          }).catch(() => {
            notification.error({
              message: 'Error while exporting period reports'
            });
            this.setState({ periodExporting: false });
          });
      }, this.state.periodExporting));

    return (
      <>
        <CustomForm
          formRef={this._periodFormRef}
          layout="vertical"
          initialValues={{ ...periodForm }}
          onFinish={() => this.periodDataTable?.refresh()}
          validateTrigger={['onChange', 'onBlur']}>
          <Row gutter={24}>
            <Col {...threeFieldColumnSizingProps} >
              <FormItem name="committeeType" label="Committee Type" rules={[FormValidationUtil.RequiredNumber('Committee Type is required')]}>
                <Select showSearch={true} optionFilterProp="children" onChange={this.onYearOrCommitteeChangeSinglePeriod}>
                  <Option key={0} value={0} disabled={true}>-- Select Committee Type --</Option>
                  <Option key={CommitteeTypeExtras.ALLHOUSEDISTRICTS} value={CommitteeTypeExtras.ALLHOUSEDISTRICTS}> All House Districts </Option>
                  <Option key={CommitteeTypeExtras.ALLSENATEDISTRICTS} value={CommitteeTypeExtras.ALLSENATEDISTRICTS}> All Senate Districts </Option>
                  {committeeTypes.map(ct => (
                    <Option key={ct.code || ''} value={ct.code || ''}>{ct.name}</Option>
                  ))}
                </Select>
              </FormItem>
            </Col>
            <Col {...threeFieldColumnSizingProps}>
              <FormItem name="year" label="Year" rules={[FormValidationUtil.RequiredNumber('Year is required')]}>
                <Select showSearch={true} optionFilterProp="children" onChange={this.onYearOrCommitteeChangeSinglePeriod}>
                  {yearOptions.map(y => (
                    <Option key={y} value={y}>{y}</Option>
                  ))}
                </Select>
              </FormItem>
            </Col>
            <Col {...threeFieldColumnSizingProps}>
              <FormItem name="filingPeriod" label="Filing Period" rules={[FormValidationUtil.RequiredNumber('Filing Period is required')]}>
                <Select showSearch={true} optionFilterProp="children">
                  <Option key="" value="" disabled={true}>-- Select Filing Period --</Option>
                  {filingPeriods.map(fp => (
                    <Option key={fp.id} value={fp.id}>{fp.name}</Option>
                  ))}
                </Select>
              </FormItem>
            </Col>
          </Row>
          <Space>
            <Button type="primary" htmlType="submit">Search</Button>
          </Space>
        </CustomForm>
        <br /> <br />
        <DataTable
          ref={(element: any) => (this.periodDataTable = element)}
          globalSearch={true}
          columns={periodTableColumns}
          buttonBar={dataTableButtons}
          serverSide={true}
          tableProps={{
            rowKey: 'id',
            sortDirections: ['ascend', 'descend'],
            locale: {
              emptyText: 'No records were found that match your criteria'
            },
            loading: periodLoading
          }}
          fetchData={this.fetchPeriodReportData}
          styleOptions={{ compact: true, alternatingRowHighlight: true }}
          title={'Period Due Date Summary Report'}
          stateSaving={{
            enabled: true,
            tableUniqueKey: 'periodReports',
            perSession: true,
          }}
        />
      </>
    );
  }

  private onYearOrCommitteeChangeSinglePeriod = () => {
    if (this._periodFormRef.current) {
      this._periodFormRef.current.setFieldsValue({ 'filingPeriod': '' });
      const selectedCommittee = this._periodFormRef.current.getFieldValue('committeeType');
      const year = this._periodFormRef.current.getFieldValue('year');
      AdminReportApiService.getFilingPeriods(selectedCommittee, year)
        .then(filingPeriods => {
          const sortedFilingPeriods = filingPeriods.sort((a, b) => { return (a?.name || '') < (b?.name || '') ? -1 : 1; });
          this.setState({ filingPeriods: sortedFilingPeriods });
        });
    }
  }

  private fetchFilingPeriods = () => {
    AdminReportApiService.getFilingPeriods(
      { ...JSON.parse(StorageUtil.getPeriodReportColumnFilters()) }.committeeType|| '',
      { ...JSON.parse(StorageUtil.getPeriodReportColumnFilters()) }.year || new Date().getFullYear().toString())
      .then(filingPeriods => {
        const sortedFilingPeriods = filingPeriods.sort((a, b) => { return (a?.name || '') < (b?.name || '') ? -1 : 1; });
        this.setState({ filingPeriods: sortedFilingPeriods }, () => this.periodDataTable?.refresh());
      });
  }

  private getPeriodTableColumns = (): DataTableColumnProps<PeriodReportDTO>[] => {
    return [
      DataTableColumnUtil.Text('Committee Code', 'committeeCode'),
      DataTableColumnUtil.Text('Committee Name', 'committeeName'),
      DataTableColumnUtil.Number('District', 'district', 0, { defaultSortOrder: 'ascend' }),
      DataTableColumnUtil.Currency('Beginning Balance', 'beginningBalance'),
      DataTableColumnUtil.Currency('Total Contributions', 'totalContributions'),
      DataTableColumnUtil.Currency('Total In-Kind Contributions', 'totalInKindContributions'),
      DataTableColumnUtil.Currency('Total Expenditures', 'totalExpenditures'),
      DataTableColumnUtil.Currency('Ending Balance', 'endingBalance'),
    ];
  }

  private fetchPeriodReportData = (requestState: TableRequestDTO,
    checkEcho: () => boolean,
    callback: (response: TableResponseDTO<PeriodReportDTO>) => void
  ) => {
    this.setState({ periodLoading: true });
    const filingPeriod = this._periodFormRef.current?.getFieldValue('filingPeriod');
    const districtNum = this._periodFormRef.current?.getFieldValue('committeeType') == CommitteeTypeExtras.ALLHOUSEDISTRICTS
    ? CommitteeTypeExtras.ALLHOUSEDISTRICTS
        : this._periodFormRef.current?.getFieldValue('committeeType') == CommitteeTypeExtras.ALLSENATEDISTRICTS
         ? CommitteeTypeExtras.ALLSENATEDISTRICTS : 0;
    const commType = districtNum != -1 && districtNum != -2 ? this._periodFormRef.current?.getFieldValue('committeeType') : '0';

    if (filingPeriod != '' && commType != '') {
      return AdminReportApiService.getSinglePeriodList(requestState, commType, filingPeriod, districtNum)
        .then((res) => {
          if (!checkEcho()) {
            return;
          }
          callback(res);
          const filterString = JSON.stringify({...this._periodFormRef.current?.getFieldsValue()});
          StorageUtil.setPeriodReportColumnFilters(filterString);
            this.setState({ periodLoading: false });
        }).catch(() => {
          notification.error({
            message: 'Error while fetching period reports'
          });
          this.setState({ periodLoading: false });
        });
    }
    this.setState({ periodLoading: false });
  }
}

export default PeriodReport;
