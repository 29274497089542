import {
  Col,
  Divider,
  Layout,
  Modal,
  notification,
  Row,
  Typography
  } from 'antd';
import * as React from 'react';
import SchDDebtsApiService from '../../api/SchDDebtsApiService';
import ScheduleApiService from '../../api/ScheduleApiService';
import Dr2AmendedIndicators from '../../consts/Dr2AmendedIndicators';
import Dr2AmendedStatuses from '../../consts/Dr2AmendedStatuses';
import NoteEntityParents from '../../consts/NoteEntityParents';
import Role from '../../consts/Role';
import Routes from '../../consts/Routes';
import ScheduleAbbreviationCodes from '../../consts/ScheduleAbbreviationCodes';
import SchDDebtsDTO from '../../models/SchDDebtsDTO';
import CurrentUser from '../../utils/CurrentUser';
import HistoryUtil from '../../utils/HistoryUtil';
import NumberFormatUtil from '../../utils/NumberFormatUtil';
import DataTable, { DataTableColumnProps } from '../shared/DataTable/DataTable';
import DataTableButtonUtil from '../shared/DataTable/DataTableButtonUtil';
import DataTableColumnUtil from '../shared/DataTable/DataTableColumnUtil';
import NotesModal from '../shared/NotesModal';
import Dr2StatusBar from './Dr2StatusBar';

const { Content } = Layout;
const { Text } = Typography;
const { confirm } = Modal;

interface SchDDebtProps {
}

interface SchDDebtState {
  tableColumns: DataTableColumnProps<SchDDebtsDTO>[];
  numDebts: number;
  totalOfSched: number;
  flagNoteId: string;
  debtId: string;
  showModal: boolean;

}

class SchDDebt extends React.Component<SchDDebtProps, SchDDebtState> {
  constructor(props: SchDDebtProps) {
    super(props);

    this.state = {
      tableColumns: this.getTableColumns(),
      numDebts: 0,
      totalOfSched: 0,
      flagNoteId: '',
      debtId: '',
      showModal: false,
    };
  }

  private dataTable: DataTable<SchDDebt> | undefined;

  componentDidMount() {
    this.getStatistics();
  }

  render() {
    const { numDebts, totalOfSched, showModal, flagNoteId, debtId } = this.state;
    const actionButtons = [];
    actionButtons.push(DataTableButtonUtil.Reset());
    actionButtons.push(DataTableButtonUtil.Primary(
      'New Debt',
      () => HistoryUtil.push(Routes.generate(Routes.ADD_SCHEDULE_D_DEBT))
    ));

    const currUser = CurrentUser.Get();

    return (
      <>
      <Content className="content-pad">
        <Dr2StatusBar dr2Id={currUser?.dr2Id || ''} />
        <Text strong> Number of Debts: {numDebts}&emsp;|&emsp;Total of Schedule: {NumberFormatUtil.currency(totalOfSched)}&emsp;|</Text>
        <NotesModal parentId={currUser?.dr2Id || ''} parent={NoteEntityParents.SCHEDULE} scheduleCd={ScheduleAbbreviationCodes.SCHEDULED} />
        <Divider />
        <Row>
          <Col xs={24}>
            <DataTable
              buttonBar={actionButtons}
              columns={this.state.tableColumns}
              fetchData={{
                fetch: function (tableRequest) {
                  return SchDDebtsApiService.getSchDDebts(tableRequest, currUser?.dr2Id || '');
                },
                failureMessage: 'Failed to retrieve debts'
              }}
              globalSearch={true}
              ref={(element: any) => (this.dataTable = element)}
              serverSide={true}
              styleOptions={{ compact: true, alternatingRowHighlight: true }}
              tableProps={{
                rowKey: 'debtIdAndSeq',
                sortDirections: ['ascend', 'descend'],
                locale: { emptyText: 'Currently there are no debts to manage.' }
              }}
              title="Schedule D - Debts"
              stateSaving={{
                  enabled: true,
                  tableUniqueKey: 'manageSchD',
                  perSession: true,
              }}
            />
          </Col>
        </Row>
        </Content>
        {showModal &&
          <NotesModal
            parentId={debtId || ''}
            parent={NoteEntityParents.DEBT}
            auditorsNoteId={flagNoteId}
            canFlag={true}
            onClose={() => { this.setState({ showModal: false }, () => this.dataTable?.refresh()); }} />
        }
      </>
    );
  }

  private getStatistics = () => {
    ScheduleApiService.getScheduleCount(CurrentUser.Get()?.dr2Id || '', ScheduleAbbreviationCodes.SCHEDULED)
      .then(numDebts => {
        this.setState({ numDebts });
      }).catch(error => {
        notification.error({
          message: 'Error while fetching the number of debts',
          description: error.message
        });
      });

    ScheduleApiService.getScheduleTotal(CurrentUser.Get()?.dr2Id || '', ScheduleAbbreviationCodes.SCHEDULED)
      .then(totalOfSched => {
        this.setState({ totalOfSched });
      }).catch(error => {
        notification.error({
          message: 'Error while fetching the total of schedule',
          description: error.message
        });
      });
  }

  private getTableColumns = (): DataTableColumnProps<SchDDebtsDTO>[] => {
    return [
      DataTableColumnUtil.Date('Date', 'debtDt', 100, { defaultSortOrder: 'ascend' }),
      DataTableColumnUtil.Address('Owed To', 'owedToName',
        (d) => ({
          name: d.owedToName,
          line1: d.owedToAddressLine1,
          line2: d.owedToAddressLine2,
          city: d.owedToCity,
          state: d.owedToState,
          zip: d.owedToZip
        })
      ),
      DataTableColumnUtil.Currency('Amount', 'debtAmount'),
      DataTableColumnUtil.DropdownMulti('Status', 'status',
        [
          { text: Dr2AmendedStatuses.ORIGINAL, value: Dr2AmendedIndicators.ORIGINAL },
          { text: Dr2AmendedStatuses.AMENDED, value: Dr2AmendedIndicators.AMENDED },
          { text: Dr2AmendedStatuses.ADJUSTED, value: Dr2AmendedIndicators.ADJUSTED },
          { text: Dr2AmendedStatuses.DELETED, value: Dr2AmendedIndicators.DELETED }
        ]),
      DataTableColumnUtil.Description('Description', 'explanation'),
      DataTableColumnUtil.FlagButton('Flagged', 'flaggedNoteId', CurrentUser.Get()?.isInRole(Role.IECDB_ADMINISTRATOR) || false, [
        {
          onClick: (rowData) => this.openFlagModal(rowData.flaggedNoteId || '', rowData.debtId || ''),
          visible: (rowData) => rowData.flaggedNoteId != null
        }
      ]),
      DataTableColumnUtil.Buttons('debtIdAndSeq',
        [
          {
            text: 'Forgive',
            visible: (rowData: SchDDebtsDTO) => this.canForgiveOrPay(rowData.debtAmount != null ? rowData.debtAmount : -1),
            onClick: (rowData) =>
              HistoryUtil.push(Routes.generate(
                Routes.ADD_SCHEDULE_E_IN_KIND_CONTRIBUTION_FROM_DEBT,
                {
                  debtId: rowData.debtId || '',
                  seqNum: rowData.seqNumber || ''
                }
              ))
          },
          {
            text: 'Pay',
            visible: (rowData: SchDDebtsDTO) => this.canForgiveOrPay(rowData.debtAmount != null ? rowData.debtAmount : -1),
            onClick: (rowData) =>
              HistoryUtil.push(Routes.generate(
                Routes.ADD_SCHEDULE_B_EXPENDITURE_FROM_DEBT,
                {
                  debtId: rowData.debtId || '',
                  seqNum: rowData.seqNumber || ''
                }
              ))
          },
          {
            text: 'Edit',
            onClick: (rowData) =>
              HistoryUtil.push(Routes.generate(
                Routes.EDIT_SCHEDULE_D_DEBT,
                {
                  id: rowData.debtId || '',
                  seqNum: rowData.seqNumber || ''
                }
              ))
          },
          {
            text: 'Delete',
            onClick: (rowData) => this.confirmDelete(
              rowData.debtId || '',
              rowData.seqNumber || 0
            )
          }
        ],
        285)
    ];
  }

  private openFlagModal = (flagNoteId: string, debtId: string) => {
    this.setState({ showModal: true, flagNoteId, debtId }, () => this.dataTable?.refresh());
  }

  private showTableAndRefresh = () => {
    if (this.dataTable) {
      this.dataTable.refresh();
    }
    this.getStatistics();
  }

  private confirmDelete = (debtId: string, seqNum: number) => {
    SchDDebtsApiService.getConnectedDebtRecords(debtId)
      .then(numRecords => {
        confirm({
          title: 'Are you sure you want to delete this debt?',
          content: `${numRecords} connected expenditures / in kind contributions in the current period will be deleted. 
                    All debts, expenditures, and in kind contributions in future periods will also be removed.`,
          onOk: () => {
            this.deleteDebt(debtId, seqNum);
          },
        });
      })
      .catch(() => {
        notification.error({ message: 'Error fetching connected records' });
      });
  }

  private deleteDebt = (debtId: string, seqNum: number) => {
    SchDDebtsApiService.delete(debtId, seqNum)
      .then(() => {
        this.showTableAndRefresh();
        notification.success({
          message: 'Deleted Successfully'
        });
      }).catch(() => {
        notification.error({
          message: 'Error while deleting debt',
          description: '',
        });
      });
  }

  private canForgiveOrPay = (debtAmount: number) => {
    return debtAmount >= 0;
  }
}

export default SchDDebt;