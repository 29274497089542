// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import OieContributionDTO from './OieContributionDTO';
import EntitySelectorDTO from './EntitySelectorDTO';

interface OieExpendDTO extends EntitySelectorDTO { 
  expendId: string | null;
  organizationName: string | null;
  organizationAddress1: string | null;
  organizationAddress2: string | null;
  organizationCity: string | null;
  organizationState: string | null;
  organizationZip: string | null;
  organizationEmail: string | null;
  committeeName: string | null;
  committeeAddress1: string | null;
  committeeAddress2: string | null;
  committeeCity: string | null;
  committeeState: string | null;
  committeeZip: string | null;
  organizationPhone: string | null;
  contactFirstName: string | null;
  contactLastName: string | null;
  contactMi: string | null;
  dateOfExpenditure: moment.Moment | string;
  amount: number;
  typeOfCommunication: string | null;
  createdOn: moment.Moment | string;
  contributionsId: string | null;
  filingStatusType: string | null;
  isCorp: boolean | null;
  vendor: string | null;
  distDate: moment.Moment | string | null;
  forCandidate: string | null;
  againstCandidate: string | null;
  forBallot: string | null;
  againstBallot: string | null;
  signedName: string | null;
  position: string | null;
  signedDate: moment.Moment | string | null;
  contributions: OieContributionDTO[] | null;
  committeeId: string | null;
}
const OieExpendDTO = {
  create: (initValues?: Partial<OieExpendDTO> | Record<string, unknown> | null) => {
    return Object.assign(EntitySelectorDTO.create(),
    {
      expendId: "00000000-0000-0000-0000-000000000000",
      organizationName: null,
      organizationAddress1: null,
      organizationAddress2: null,
      organizationCity: null,
      organizationState: null,
      organizationZip: null,
      organizationEmail: null,
      committeeName: null,
      committeeAddress1: null,
      committeeAddress2: null,
      committeeCity: null,
      committeeState: null,
      committeeZip: null,
      organizationPhone: null,
      contactFirstName: null,
      contactLastName: null,
      contactMi: null,
      dateOfExpenditure: new Date(0).toISOString(),
      amount: 0,
      typeOfCommunication: null,
      createdOn: new Date(0).toISOString(),
      contributionsId: "00000000-0000-0000-0000-000000000000",
      filingStatusType: null,
      isCorp: null,
      vendor: null,
      distDate: null,
      forCandidate: null,
      againstCandidate: null,
      forBallot: null,
      againstBallot: null,
      signedName: null,
      position: null,
      signedDate: null,
      contributions: [],
      committeeId: null,
    },
    initValues);
  }
};

export default OieExpendDTO;