// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Constants.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
export default class GbStatus {
    public static readonly SAVED: number = 1;
    public static readonly SUBMITTED: number = 2;
    public static readonly APPROVED: number = 3;
    public static readonly REJECTED: number = 4;
    public static readonly ADJUSTED: number = 5;
}
