import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import Routes from '../../consts/Routes';
import { RedirectAs404 } from '../../utils/RouteErrors';
import InternalNotes from './InternalNotes';
import OrgDetails from './OrgDetails';
import ApproveDR1Edit from './ApproveDR1Edit';
import ManageDr1 from '../admin/ManageDr1';
import ManageUsers from '../users/ManageUsers';
import EditUser from '../users/EditUser';
import SelectFiling from './SelectFiling';

const Organization = () => (
  <Switch>
    <Route exact={true} path={Routes.INTERNAL_NOTES} component={InternalNotes} />
    <Route exact={true} path={Routes.ORGANIZATIONS_BASE} component={OrgDetails} />
    <Route exact={true} path={Routes.APPROVE_DR1_EDIT} component={ApproveDR1Edit} />
    <Route exact={true} path={Routes.MANAGE_DR1} component={ManageDr1} />
    <Route exact={true} path={Routes.FILER_MANAGE_USERS} render={() => <ManageUsers forCommittee={true} />} />
    <Route exact={true} path={Routes.FILER_EDIT_USER} render={(props) => <EditUser forCommittee={true} {...props} />} />
    <Route exact={true} path={Routes.FILER_ADD_USER} render={(props) => <EditUser forCommittee={true} {...props} />} />
    <Route exact={true} path={Routes.SELECT_FILING} component={SelectFiling} />

    <Route component={RedirectAs404} />
  </Switch>
);

export default Organization;
