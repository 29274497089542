import FormItem from 'antd/lib/form/FormItem';
import { notification, Select, Spin } from 'antd';
import * as React from 'react';
import debounce from 'lodash/debounce';
import { Rule } from 'antd/lib/form';
import OriginalExecAgencyDTO from '../../models/OriginalExecAgencyDTO';
import OriginalExecAgencyApiService from '../../api/OriginalExecAgencyApiService';

const { Option } = Select;

interface OriginalAgencySearchProps {
    name: string;
    label?: string;
    hidden?: boolean;
    required?: boolean;
    disabled?: boolean;
    onSelect?: (agency: OriginalExecAgencyDTO | undefined) => void;
    onChange?: (value: number) => void;
    agencies?: OriginalExecAgencyDTO[];
}

interface OriginalAgencySearchState {
    agencies: OriginalExecAgencyDTO[];
    loading: boolean;
}

class OriginalAgencySearch extends React.Component<OriginalAgencySearchProps, OriginalAgencySearchState> {
    constructor(props: OriginalAgencySearchProps) {
        super(props);

        this.handleAgencySearch = debounce(this.handleAgencySearch, 400);

        this.state = {
            agencies: this.props.agencies || [],
            loading: false,
        };
    }

    componentDidMount() {
        if (!this.props.agencies) {
            this.fetchAgencies();
        }
    }

    render() {
        const { agencies, loading } = this.state;
        const { name, label, hidden, required, disabled } = this.props;

        const OriginalAgencyNameRules: Rule[] = [];

        if (required !== false) {
            OriginalAgencyNameRules.push({ required: true, message: (label || 'Original Agency') + ' is required' });
        }

        const options = loading ? [OriginalExecAgencyDTO.create()] : agencies;

        return (
            <FormItem name={name} label={label || 'Original Agency'} hidden={hidden || false} rules={OriginalAgencyNameRules}>
                <Select
                    allowClear={true}
                    filterOption={false}
                    showSearch={true}
                    optionFilterProp="children"
                    notFoundContent={false}
                    showArrow={false}
                    disabled={disabled || false}
                    placeholder={'Search for an agency...'}
                    onSearch={this.searchAgency}
                    onClear={this.handleClear}
                    onChange={this.handleAgencyChange}
                    dropdownRender={(a) => {
                        if (loading) {
                            return <div className="center-spinner"><Spin spinning={loading}>{a}</Spin></div>;
                        }
                        return a;
                    }}
                >
                    {!loading &&
                        <Option value={0} disabled={true} >Search for an agency...</Option>
                    }
                    {options.map((agency: OriginalExecAgencyDTO) => (
                        <Option
                            key={agency.id || ''}
                            value={agency.name || ''}
                        >
                            {agency.name}
                        </Option>
                    ))}
                </Select>
            </FormItem>
        );
    }

    private handleAgencySearch = (name: string) => {
        if (!this.props.agencies) {
            OriginalExecAgencyApiService.getAgencyByName(name).then((agencies) => {
                    this.setState({ agencies });
                })
                .catch(() => {
                    notification.error({
                        message: 'Failed to retrieve agencies.',
                    });
                }).finally(() => {
                    this.setState({ loading: false });
                });
        }
        else {
            const agencies = this.props.agencies?.filter((a) => (
                a.name?.toLowerCase().includes(name)
            ));

            this.setState({
                agencies: agencies,
                loading: false
            });
        }
    }

    private searchAgency = (name: string) => {
        this.setState({ loading: true });
        this.handleAgencySearch(name);
    }

    private handleClear = () => {
        this.setState({
            agencies: this.props.agencies || []
        });

        if (!this.props.agencies) {
            this.fetchAgencies();
        }

        if (this.props.onSelect) {
            this.props.onSelect(undefined);
        }
    }

    private handleAgencyChange = (value: string) => {
        const selectedAgency = this.state.agencies.find(a => a.name === value);

        if (selectedAgency) {
            this.setState({ agencies: [selectedAgency] });
            if (this.props.onSelect) {
                this.props.onSelect(selectedAgency);
            }
        }
    }

    private fetchAgencies = () => {
        this.setState({ loading: true });
        OriginalExecAgencyApiService.getAgencyList().then((agencies) => {
            this.setState({ agencies });
        }).catch(() => {
            notification.error({
                message: 'Failed to retrieve agencies.',
            });
        }).finally(() => {
            this.setState({ loading: false });
        });
    }
}

export default OriginalAgencySearch;
