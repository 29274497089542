// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
import BaseApi from './BaseApi';
import SearchableDatabaseFilterCriteriaDTO from '../models/SearchableDatabaseFilterCriteriaDTO';
import SearchableDatabaseDTO from '../models/SearchableDatabaseDTO';

export class SearchableDatabaseApiService extends BaseApi {

  // post: api/search/table
  public getSearchableDatabaseList(criteria: SearchableDatabaseFilterCriteriaDTO): Promise<SearchableDatabaseDTO[]> {
    let url = `api/search/table`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, SearchableDatabaseDTO[]>(criteria, 'post', url, true, false);
  }

  // get: api/search/export-contributions
  public exportContributionList(criteria: SearchableDatabaseFilterCriteriaDTO): Promise<void> {
    let url = `api/search/export-contributions`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, void>(criteria, 'get', url, true, true);
  }

  // get: api/search/export-expenditures
  public exportExpendituresList(criteria: SearchableDatabaseFilterCriteriaDTO): Promise<void> {
    let url = `api/search/export-expenditures`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, void>(criteria, 'get', url, true, true);
  }

  // get: api/search/reference?referenceId=${encodeURIComponent(referenceId)}&referenceType=${encodeURIComponent(referenceType)}&dr2Id=${encodeURIComponent(dr2Id)}
  public getReference(referenceId: string, referenceType: string, dr2Id: string): Promise<SearchableDatabaseDTO[]> {
    let url = `api/search/reference`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (referenceId != null) {
      url += `${prefix}referenceId=${encodeURIComponent(referenceId)}`;
      prefix = '&';
    }
    if (referenceType != null) {
      url += `${prefix}referenceType=${encodeURIComponent(referenceType)}`;
      prefix = '&';
    }
    if (dr2Id != null) {
      url += `${prefix}dr2Id=${encodeURIComponent(dr2Id)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, SearchableDatabaseDTO[]>(null, 'get', url, true, false);
  }
}
var service = new SearchableDatabaseApiService();
export default service;
