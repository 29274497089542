// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Constants.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
export default class RoleType {
    public static readonly USER: string = '1';
    public static readonly OWED_TO: string = '10';
    public static readonly LENDER: string = '11';
    public static readonly CONSULTANT: string = '12';
    public static readonly THIRD_PARTY: string = '13';
    public static readonly PURCHASER: string = '14';
    public static readonly CONTACT: string = '15';
    public static readonly JURISDICTION: string = '16';
    public static readonly TREASURER: string = '2';
    public static readonly COMMITTEE_CHAIRPERSON: string = '3';
    public static readonly CANDIDATE: string = '4';
    public static readonly PARENT_ENTITY: string = '5';
    public static readonly BANK: string = '6';
    public static readonly COMMITTEE_OFFICER: string = '7';
    public static readonly CONTRIBUTOR: string = '8';
    public static readonly PAYEE: string = '9';
}
