// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
import BaseApi from './BaseApi';
import NoteEntryDTO from '../models/NoteEntryDTO';

export class AuditorNotesApiService extends BaseApi {

  // get: api/auditorNotes/noteEntries?noteId=${encodeURIComponent(noteId)}
  public getNoteEntries(noteId: string): Promise<NoteEntryDTO[]> {
    let url = `api/auditorNotes/noteEntries`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (noteId != null) {
      url += `${prefix}noteId=${encodeURIComponent(noteId)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, NoteEntryDTO[]>(null, 'get', url, true, false);
  }

  // post: api/auditorNotes/save?id=${encodeURIComponent(id)}&parent=${encodeURIComponent(parent)}&seqNum=${seqNum}&auditorsNoteId=${encodeURIComponent(auditorsNoteId)}&scheduleCd=${encodeURIComponent(scheduleCd)}
  public saveNotes(noteEntry: NoteEntryDTO, id: string, parent: string, seqNum: number | null, auditorsNoteId: string | null, scheduleCd: string): Promise<NoteEntryDTO> {
    let url = `api/auditorNotes/save`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (id != null) {
      url += `${prefix}id=${encodeURIComponent(id)}`;
      prefix = '&';
    }
    if (parent != null) {
      url += `${prefix}parent=${encodeURIComponent(parent)}`;
      prefix = '&';
    }
    if (seqNum != null) {
      url += `${prefix}seqNum=${seqNum}`;
      prefix = '&';
    }
    if (auditorsNoteId != null) {
      url += `${prefix}auditorsNoteId=${encodeURIComponent(auditorsNoteId)}`;
      prefix = '&';
    }
    if (scheduleCd != null) {
      url += `${prefix}scheduleCd=${encodeURIComponent(scheduleCd)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, NoteEntryDTO>(noteEntry, 'post', url, true, false);
  }

  // get: api/auditorNotes/auditorNotesId?parentId=${encodeURIComponent(parentId)}&scheduleCd=${encodeURIComponent(scheduleCd)}
  public getSchNoteId(parentId: string, scheduleCd: string): Promise<string | null> {
    let url = `api/auditorNotes/auditorNotesId`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (parentId != null) {
      url += `${prefix}parentId=${encodeURIComponent(parentId)}`;
      prefix = '&';
    }
    if (scheduleCd != null) {
      url += `${prefix}scheduleCd=${encodeURIComponent(scheduleCd)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, string | null>(null, 'get', url, true, false);
  }
}
var service = new AuditorNotesApiService();
export default service;
