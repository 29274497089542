// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import EntitySelectorDTO from './EntitySelectorDTO';

interface FilingPeriodDTO extends EntitySelectorDTO { 
  periodMasterId: number;
  description: string | null;
  dueDate: moment.Moment | string | null;
  beginDate: moment.Moment | string | null;
  endDate: moment.Moment | string | null;
  electionType: string | null;
  reportType: string | null;
}
const FilingPeriodDTO = {
  create: (initValues?: Partial<FilingPeriodDTO> | Record<string, unknown> | null) => {
    return Object.assign(EntitySelectorDTO.create(),
    {
      periodMasterId: 0,
      description: null,
      dueDate: null,
      beginDate: null,
      endDate: null,
      electionType: null,
      reportType: null,
    },
    initValues);
  }
};

export default FilingPeriodDTO;