// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import TableRequestDTO from './TableRequestDTO';
import EntitySelectorDTO from './EntitySelectorDTO';

interface ContactTableExportDTO extends EntitySelectorDTO { 
  tableRequest: TableRequestDTO | null;
  committeeId: string | null;
}
const ContactTableExportDTO = {
  create: (initValues?: Partial<ContactTableExportDTO> | Record<string, unknown> | null) => {
    return Object.assign(EntitySelectorDTO.create(),
    {
      tableRequest: null,
      committeeId: "00000000-0000-0000-0000-000000000000",
    },
    initValues);
  }
};

export default ContactTableExportDTO;