import FormItem from 'antd/lib/form/FormItem';
import { notification, Select, Spin } from 'antd';
import * as React from 'react';
import PfdApiService from '../../../../api/PfdApiService';
import debounce from 'lodash/debounce';
import DesignatedPositionDTO from '../../../../models/DesignatedPositionDTO';

const { Option } = Select;

interface PfdContactAutoSearchProps {
  name: string;
  label?: string;
  hidden?: boolean;
  maxLength?: number;
  disabled?: boolean;
  onSelect?: (perorgId: string) => void;
  onChange?: (value: string) => void;
  initialValue?: number;
}

interface PfdContactAutoSearchState {
  contacts: DesignatedPositionDTO[];
  loading: boolean;
}

class PfdContactAutoSearch extends React.Component<PfdContactAutoSearchProps, PfdContactAutoSearchState> {
  constructor(props: PfdContactAutoSearchProps) {
    super(props);

    this.searchPersonInfo = debounce(this.searchPersonInfo, 400);

    this.state = {
      contacts: [],
      loading: false,
    };
  }

  componentDidMount() {
    if (this.props.initialValue) {
      this.getInitialValue(this.props.initialValue);
    }
  }

  render() {
    const { contacts, loading } = this.state;
    const { name, label, hidden, disabled } = this.props;

    const options: DesignatedPositionDTO[] = loading ? [DesignatedPositionDTO.create()] : contacts;

    return (
      <FormItem name={name} label={label || 'Individual'} hidden={hidden || false}>
        <Select
          filterOption={false /*this option allows loading spinner to work*/}
          showSearch={true}
          optionFilterProp="children"
          showArrow={false}
          disabled={disabled || false}
          placeholder={'Search for a contact...'}
          allowClear={true}
          onSearch={this.handlePersonSearch}
          onClear={this.handleClear}
          onChange={this.handlePersonChange}
          notFoundContent={false}
          dropdownRender={(a) => {
            if (loading) {
              return <div className="center-spinner"><Spin spinning={loading} /></div>;
            }
            else {
              return a;
            }
          }}
        >
          {options.map((p: DesignatedPositionDTO) => (
            <Option
              key={p.contactId || ''}
              value={p.contactId || ''}
            >
              {p.firstName && (
                p.firstName + ' ' + p.lastName
              )}
              <br />
              {p.city && p.state &&
                <small>{p.city + ', ' + p.state}</small>
              }
            </Option>
          ))}
        </Select>
      </FormItem>
    );
  }

  private getInitialValue = (contactId: number) => {
    PfdApiService.searchContactById(contactId)
      .then((contacts) => {
        this.setState({ contacts });
      })
      .catch(() => {
        notification.error({
          message: 'Failed to retrieve individuals.',
        });
      });
  }

  private searchPersonInfo = (name: string) => {
    PfdApiService.searchContactByName(name)
      .then((contacts: any) => {
        this.setState({ contacts: contacts, loading: false });
      })
      .catch(() => {
        notification.error({
          message: 'Failed to retrieve individuals.',
        });
      });
  }

  private handleClear = () => {
    this.setState({ contacts: [] });
    if (this.props.onSelect) {
      this.props.onSelect('');
    }
  }

  private handlePersonSearch = (value: string) => {
    if (!value || value.length > 0) {
      if (this.props.onChange) {
        this.props.onChange(value);
      }
    }
    if (!value || value.length < 3) {
      if (this.state.contacts !== []) {
        this.setState({ contacts: [], loading: false });
      }
      return;
    }
    this.setState({ loading: true });
    this.searchPersonInfo(value);
  }

  private handlePersonChange = (value: string) => {
    const selectedPerson = this.state.contacts.find(p => p.contactId === Number(value));
    if (selectedPerson) {
      this.setState({ contacts: [selectedPerson] });
      if (this.props.onSelect) {
        this.props.onSelect(selectedPerson?.contactId.toString() || '');
      }
    }
  }
}

export default PfdContactAutoSearch;
