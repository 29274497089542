// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';

interface SearchableDatabaseDTO { 
  transactionId: string | null;
  date: moment.Moment | string | null;
  primaryCommitteeCode: string | null;
  primaryName: string | null;
  primaryAddress1: string | null;
  primaryAddress2: string | null;
  primaryCity: string | null;
  primaryState: string | null;
  primaryZip: string | null;
  secondaryCommitteeCode: string | null;
  secondaryName: string | null;
  secondaryAddress1: string | null;
  secondaryAddress2: string | null;
  secondaryCity: string | null;
  secondaryState: string | null;
  secondaryZip: string | null;
  transactionType: string | null;
  amount: number | null;
  checkNumber: string | null;
  referenceId: string | null;
  referenceType: string | null;
  referenceDR2Id: string | null;
  filingPeriodDescription: string | null;
}
const SearchableDatabaseDTO = {
  create: (initValues?: Partial<SearchableDatabaseDTO> | Record<string, unknown> | null) => {
    return Object.assign(
    {
      transactionId: null,
      date: null,
      primaryCommitteeCode: null,
      primaryName: null,
      primaryAddress1: null,
      primaryAddress2: null,
      primaryCity: null,
      primaryState: null,
      primaryZip: null,
      secondaryCommitteeCode: null,
      secondaryName: null,
      secondaryAddress1: null,
      secondaryAddress2: null,
      secondaryCity: null,
      secondaryState: null,
      secondaryZip: null,
      transactionType: null,
      amount: null,
      checkNumber: null,
      referenceId: null,
      referenceType: null,
      referenceDR2Id: "00000000-0000-0000-0000-000000000000",
      filingPeriodDescription: null,
    },
    initValues);
  }
};

export default SearchableDatabaseDTO;