// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
import BaseApi from './BaseApi';
import TableRequestDTO from '../models/TableRequestDTO';
import AdminPfdPenaltyDTO from '../models/AdminPfdPenaltyDTO';
import AdminPfdContactDTO from '../models/AdminPfdContactDTO';
import TableResponseDTO from '../models/TableResponseDTO';
import AdminPfdListDTO from '../models/AdminPfdListDTO';
import PfdDTO from '../models/PfdDTO';
import ResultDTO from '../models/ResultDTO';
import AdminPfdContactListDTO from '../models/AdminPfdContactListDTO';

export class AdminPfdApiService extends BaseApi {

  // post: api/admin/pfd/manage
  public getManagePfdList(tableRequest: TableRequestDTO): Promise<TableResponseDTO<AdminPfdListDTO>> {
    let url = `api/admin/pfd/manage`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, TableResponseDTO<AdminPfdListDTO>>(tableRequest, 'post', url, true, false);
  }

  // post: api/admin/pfd/approval
  public getApprovalPfdList(tableRequest: TableRequestDTO): Promise<TableResponseDTO<AdminPfdListDTO>> {
    let url = `api/admin/pfd/approval`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, TableResponseDTO<AdminPfdListDTO>>(tableRequest, 'post', url, true, false);
  }

  // get: api/admin/pfd/${filingId}
  public getPfdFiling(filingId: number): Promise<PfdDTO> {
    let url = `api/admin/pfd/${filingId}`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, PfdDTO>(null, 'get', url, true, false);
  }

  // delete: api/admin/pfd/${filingId}
  public deletePfd(filingId: number): Promise<void> {
    let url = `api/admin/pfd/${filingId}`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, void>(null, 'delete', url, true, false);
  }

  // get: api/admin/pfd/${filingId}/penalty
  public getPfdPenalty(filingId: number): Promise<AdminPfdPenaltyDTO> {
    let url = `api/admin/pfd/${filingId}/penalty`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, AdminPfdPenaltyDTO>(null, 'get', url, true, false);
  }

  // post: api/admin/pfd/${encodeURIComponent(filingId)}/penalty?sendNotice=${sendNotice}
  public savePfdPenalty(filingId: string, penaltyData: AdminPfdPenaltyDTO, sendNotice: boolean): Promise<ResultDTO> {
    let url = `api/admin/pfd/${encodeURIComponent(filingId)}/penalty`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (sendNotice != null) {
      url += `${prefix}sendNotice=${sendNotice}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, ResultDTO>(penaltyData, 'post', url, true, false);
  }

  // post: api/admin/pfd/${filingId}/approve
  public approve(filingId: number): Promise<void> {
    let url = `api/admin/pfd/${filingId}/approve`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, void>(null, 'post', url, true, false);
  }

  // post: api/admin/pfd/getPfdContacts
  public getPfdContactsList(tableRequest: TableRequestDTO): Promise<TableResponseDTO<AdminPfdContactListDTO>> {
    let url = `api/admin/pfd/getPfdContacts`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, TableResponseDTO<AdminPfdContactListDTO>>(tableRequest, 'post', url, true, false);
  }

  // get: api/admin/pfd/contact/${contactId}
  public getPfdContact(contactId: number): Promise<AdminPfdContactDTO> {
    let url = `api/admin/pfd/contact/${contactId}`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, AdminPfdContactDTO>(null, 'get', url, true, false);
  }

  // post: api/admin/pfd/saveContact
  public savePfdContact(contact: AdminPfdContactDTO): Promise<void> {
    let url = `api/admin/pfd/saveContact`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, void>(contact, 'post', url, true, false);
  }

  // get: api/admin/pfd/exportContacts
  public exportPfdContactList(tableRequest: TableRequestDTO): Promise<void> {
    let url = `api/admin/pfd/exportContacts`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, void>(tableRequest, 'get', url, true, true);
  }

  // post: api/admin/pfd/penaltyNotice?filingId=${filingId}
  public sendPfdLatePenaltyNotice(filingId: number): Promise<ResultDTO> {
    let url = `api/admin/pfd/penaltyNotice`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (filingId != null) {
      url += `${prefix}filingId=${filingId}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, ResultDTO>(null, 'post', url, true, false);
  }
}
var service = new AdminPfdApiService();
export default service;
