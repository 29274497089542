import { Typography } from 'antd';
import * as React from 'react';
import { Link } from 'react-router-dom';
import Routes from '../consts/Routes';

class NewFilers extends React.Component<unknown, unknown> {
  constructor(props: unknown) {
    super(props);
  }

  render() {
    return (
      <>
        <Typography.Title level={4}>New Filers</Typography.Title>
        <Typography.Paragraph>
          <Typography.Text>Please click on one of the options below:</Typography.Text>
        </Typography.Paragraph>
        <Typography.Title level={5}>CAMPAIGNS</Typography.Title>
        <Typography.Paragraph>
          <Link to={Routes.DR1}>Committees filing a Statement of Organization (DR1) for the first time.</Link>
        </Typography.Paragraph>
        <Typography.Paragraph>
          <Link to={Routes.VSR}>Committees filing a Verified Statement Registration (VSR) for the first time.</Link>
        </Typography.Paragraph>
        <Typography.Paragraph>
          <Link to={Routes.OTC}>Permanent organizations making a one time campaign contribution (OTC).</Link>
        </Typography.Paragraph>
        <Typography.Paragraph>
          <Link to={Routes.IIE}>Sole Individual making independent expenditures (IIE).</Link>
        </Typography.Paragraph>
        <Typography.Paragraph>
          <Link to={Routes.OIE}>Organizations making independent expenditures (OIE).</Link>
        </Typography.Paragraph>
        <Typography.Paragraph>
          <Link to={Routes.DRSFA}>Register a committee name ONLY for attribution purposes (DR-SFA).</Link>
        </Typography.Paragraph>
        <Typography.Paragraph>
          <Link to={Routes.DUAL_COMP}>Dual Executive Branch Compensation</Link>
        </Typography.Paragraph>
        <Typography.Title level={5}>PERSONAL FINANCIAL DISCLOSURE</Typography.Title>
        <Typography.Paragraph>
          <Link to={Routes.PFD}>Personal Financial Disclosure always use this link.</Link>
        </Typography.Paragraph>
        <Typography.Title level={5}>GIFT/BEQUEST TO STATE AGENCIES</Typography.Title>
        <Typography.Paragraph>
          <Link to={Routes.DONOR_REGISTER}>Use this link to set up new user for agency gift reporting.</Link>
        </Typography.Paragraph>
      </>
    );
  }
}

export default NewFilers;
