import {
  Button,
  Col,
  ColProps,
  FormInstance,
  notification,
  Typography
} from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import * as React from 'react';
import Role from '../../consts/Role';
import DissolutionApiService from '../../api/DissolutionApiService';
import CurrentUser from '../../utils/CurrentUser';
import DateUtil from '../../utils/DateUtil';
import moment from 'moment';
import CustomDatePicker from '../shared/CustomDatePicker';
import CustomForm from '../shared/CustomForm';
import DissolutionInfoDTO from '../../models/DissolutionInfoDTO';

const { Text } = Typography;

interface DissolutionProps {
  inDr2Submit: boolean;
}

interface DissolutionState {
  committeeId: string;
  loadDissolutionDates: boolean;
  dissolutionInfo: DissolutionInfoDTO;
}

class DissolutionPage extends React.Component<DissolutionProps, DissolutionState>{
  private _dissolutionRef = React.createRef<FormInstance>();

  constructor(props: DissolutionProps) {
    super(props);
    this.state = {
      committeeId: CurrentUser.Get()?.committeeId || '',
      dissolutionInfo: DissolutionInfoDTO.create({ dissolveDate: moment(), filedDate: moment() }),
      loadDissolutionDates: false,
    };
  }

  validate = async (): Promise<{ model: DissolutionInfoDTO, errorFields: any }> => {
    try {
      const fields = await this._dissolutionRef.current?.validateFields();
      return { model: fields, errorFields: null };
    }
    catch (errorInfo) {
      return Promise.reject({ model: errorInfo.values, errorFields: errorInfo.errorFields });
    }
  }

  componentDidMount = () => {
    this.getDissolveDate();
  }

  render() {
    const { loadDissolutionDates, dissolutionInfo } = this.state;
    const columnSizingProps: ColProps = { xs: 24, sm: 24, md: 12 };
    const { inDr2Submit } = this.props;
    return (
      <Col {...columnSizingProps} offset={inDr2Submit ? 0 : 1}>
        {!inDr2Submit && <br />}
        <Typography.Title level={4}>DR-3 Dissolution</Typography.Title>
        <ul>
          <li> A committee may end its filing obligation by filing a Notice of Dissolution when it has </li>
          <ol>
            <li>
              Paid or transferred all of its debts or obligations.
            </li>
            <li>
              Reduced its cash balance to zero.
            </li>
            <li>
              If a candidate&apos;s committee, sold or transferred its campaign property.
            </li>
            <li>
              Filed a final report showing these transactions.
            </li>
          </ol>
        </ul >
        <ul >
          <li>
            The Notice of Dissolution is to be filed within thirty (30) days of the committee&apos;s dissolution, with a copy
            of the final bank statement sent as soon as it is available.
          </li>
        </ul >
        <ul>
          <li>
            Leftover funds (including, for candidates&apos; committees, receipts from the sale of campaign property) must be
            distributed in a manner permitted by Iowa Code Chapter 68A.
          </li>
        </ul>
        <p>If all of the statements listed above have been met, then you may continue submitting this DR3.</p>
        <p>Statewide/General Assembly candidates, state PACs, and state parties must submit a copy of the final bank statement
          as soon as possible.
        </p>
        <br />
        <CustomForm formRef={this._dissolutionRef} >
          {CurrentUser.Get()?.isInRole(Role.IECDB_ADMINISTRATOR) ?
            <>
              {(dissolutionInfo.filedDate && !loadDissolutionDates) &&
                <>
                  <Text strong>
                    Status : &emsp;&emsp;&emsp;&emsp;
                    Filed Electronically&emsp;{DateUtil.toShortDateString(moment(dissolutionInfo.filedDate))}
                  </Text>
                  <br /><br />
                </>
              }
              <FormItem
                name="dissolveDate"
                label="Dissolve Date"
                initialValue={moment()}
              >
                <CustomDatePicker />
              </FormItem>
              {!inDr2Submit &&
                <Button type="primary" onClick={() => this.submit(this._dissolutionRef.current?.getFieldValue('dissolveDate'))}>Submit</Button>
              }
            </> :
            <>
              {!inDr2Submit &&
                <Button
                  type="primary"
                  onClick={() => this.submit(this._dissolutionRef.current?.getFieldValue('dissolveDate'))}
                  style={{ marginTop: '0' }}>
                  Submit
                </Button>
              }
            </>
          }
        </CustomForm>
      </Col>
    );
  }

  private submit = (dissolveDate: any) => {
    DissolutionApiService.dissolveDR3(this.state.committeeId, DateUtil.toTimeStamp(moment(dissolveDate))).then((result) => {
      if (result.succeeded) {
        notification.success({ message: 'Committee was dissolved successfully.' });
      }
      else {
        notification.error({ message: result.errors[0] });
      }
    }).catch(() => {
      notification.error({ message: 'Failed to dissolve committee.' });
    });
  }

  private getDissolveDate = () => {
    this.setState({ loadDissolutionDates: true });
    DissolutionApiService.getDissolutionInfo(this.state.committeeId).then((dissolutionInfo) => {
      if (dissolutionInfo.dissolveDate) {
        dissolutionInfo.dissolveDate = moment.utc(dissolutionInfo.dissolveDate);
      }
      if (dissolutionInfo.filedDate) {
        dissolutionInfo.filedDate = moment.utc(dissolutionInfo.filedDate);
      }
      this.setState({
        dissolutionInfo,
        loadDissolutionDates: false
      });
    }).catch(() => {
      notification.error({ message: 'Failed to load dissolve date.' });
    });
  }
}

export default DissolutionPage;