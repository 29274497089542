// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Constants.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
export default class FilerType {
    public static readonly CANDIDATE: number = 1;
    public static readonly PAC: number = 3;
    public static readonly OSF: number = 4;
    public static readonly LOBBYIST: number = 5;
    public static readonly LOBBYIST_CLIENT: number = 6;
}
