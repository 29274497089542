import {
  Button,
  Checkbox,
  Col,
  ColProps,
  FormInstance,
  Input,
  Modal,
  notification,
  Row,
  Select,
  Space,
  Spin,
  Tooltip,
  Typography
} from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import * as React from 'react';
import SchAContributionsImportApiService from '../../api/SchAContributionsImportApiService';
import SchAContributionsImportDTO from '../../models/SchAContributionsImportDTO';
import SchABImportFileDTO from '../../models/SchABImportFileDTO';
import DataTableColumnUtil from '../shared/DataTable/DataTableColumnUtil';
import DataTable, { DataTableColumnProps, FilterType } from '../shared/DataTable/DataTable';
import CurrentUser from '../../utils/CurrentUser';
import { Content } from 'antd/lib/layout/layout';
import FileUploadUtil from '../../utils/FileUploadUtil';
import FileUpload from '../shared/FileUpload';
import SchAContributionsImportFormDTO from '../../models/SchAContributionsImportFormDTO';
import moment from 'moment';
import CodeLookupTableDTO from '../../models/CodeLookupTableDTO';
import BaseScheduleInfoDTO from '../../models/BaseScheduleInfoDTO';
import CommitteeInfoDTO from '../../models/CommitteeInfoDTO';
import FormValidationUtil from '../../utils/FormValidationUtil';
import Dr2GroupTypes from '../../consts/Dr2GroupTypes';
import CommitteeType from '../../consts/CommitteeType';
import UnitemizedAmounts from '../../consts/UnitemizedAmounts';
import ScheduleApiService from '../../api/ScheduleApiService';
import ScheduleAbbreviationCodes from '../../consts/ScheduleAbbreviationCodes';
import LookupsApiService from '../../api/LookupsApiService';
import AddressForm from '../shared/AddressFormFields';
import CommitteeAutoSearch from '../shared/CommitteeSearch';
import PersonSearch from '../shared/PersonSearch';
import TextArea from 'antd/lib/input/TextArea';
import HistoryUtil from '../../utils/HistoryUtil';
import Routes from '../../consts/Routes';
import CurrencyInput from '../shared/CurrencyInput';
import CustomDatePicker from '../shared/CustomDatePicker';
import CustomForm from '../shared/CustomForm';
import OrganizationAutoSearch from '../shared/OrganizationSearch';

const Option = Select.Option;
const { confirm } = Modal;
const { Link } = Typography;

interface SchAContributionsImportProps {

}

interface SchAContributionsImportState {
  tableColumns: DataTableColumnProps<SchAContributionsImportDTO>[];
  batchNumber: string | null;
  transNumber: string | null;
  userId: number;

  // Modal Stuff
  relations: CodeLookupTableDTO[];
  candidate: BaseScheduleInfoDTO;
  contributionInfo: SchAContributionsImportFormDTO;
  currContrType: string;
  currComm: CommitteeInfoDTO;
  contributorAutoFillId: string;
  unitemizedAffirmationChecked: boolean;
  btnDisable: boolean;
  startDt: moment.Moment;
  endDt: moment.Moment;
  trusteeTrustorReq: boolean;
  autoFillPerorg: boolean;
  candidatesOwnFunds: boolean;
  total: number;

  // Booleans
  hasImportedData: boolean;
  showModal: boolean;
  pageLoading: boolean;
  tableLoading: boolean;
  contrLoading: boolean;
  saving: boolean;
}

class SchAContributionsImport extends React.Component<SchAContributionsImportProps, SchAContributionsImportState> {
  private readonly _importFormRef = React.createRef<FormInstance>();
  private readonly _modalFormRef = React.createRef<FormInstance>();
  private dataTable: DataTable<SchAContributionsImport> | undefined;

  constructor(props: SchAContributionsImportProps) {
    super(props);
    this.state = {
      tableColumns: this.getTableColumns(),
      batchNumber: null,
      transNumber: null,
      userId: CurrentUser.Get()?.userId || 0,

      // Modal Stuff
      relations: [],
      candidate: BaseScheduleInfoDTO.create(),
      contributionInfo: SchAContributionsImportFormDTO.create({ contributionType: '', state: '', relationship: '', amount: null }),
      currContrType: '',
      currComm: CommitteeInfoDTO.create(),
      contributorAutoFillId: '',
      unitemizedAffirmationChecked: false,
      btnDisable: false,
      startDt: moment.utc(),
      endDt: moment.utc(),
      total: 0,

      // Booleans
      hasImportedData: false,
      showModal: false,
      pageLoading: false,
      tableLoading: false,
      contrLoading: false,
      saving: false,
      autoFillPerorg: false,
      trusteeTrustorReq: false,
      candidatesOwnFunds: false
    };
  }

  componentDidMount() {
    this.loadLookups();
    this.getCandidate();
    this.getUserCommittee();
    this.getSchedule();
  }

  render() {
    const { batchNumber, hasImportedData, pageLoading, tableLoading, contrLoading, saving, autoFillPerorg,
      trusteeTrustorReq, candidatesOwnFunds } = this.state;
    const columnSizingProps: ColProps = { xs: 24, sm: 24, md: 12 };
    const currUser = CurrentUser.Get();
    const showTable = hasImportedData && !tableLoading && !saving;

    // Modal variables
    const { relations, contributionInfo, currContrType, currComm,
      unitemizedAffirmationChecked, startDt, endDt } = this.state;

    const checkNumReq = currContrType === Dr2GroupTypes.COMMITTEE;
    const relationReq = currContrType === Dr2GroupTypes.INDIVIDUAL;
    const isUnitemized = currContrType === Dr2GroupTypes.UNITEMIZED;
    const isCandidateCommittee = currComm.filerTypeName == 'Candidate';
    let unitemizedAmt = 0;

    const contributorTypes = [
      Dr2GroupTypes.INDIVIDUAL,
      Dr2GroupTypes.COMMITTEE,
      Dr2GroupTypes.TRUST,
      Dr2GroupTypes.COMPANYOTHER,
      Dr2GroupTypes.UNITEMIZED
    ];

    const hasInvalidDate = !contributionInfo.date && contributionInfo.dateString;
    const hasInvalidCommitteeName = !contributionInfo.committeeName && contributionInfo.invalidCommitteeName;
    const hasInvalidPhoneNumber = !contributionInfo.contactPrimaryPhone
      && contributionInfo.contactPhoneString;

    if (currComm.type == CommitteeType.COUNTY_CENTRAL_COMMITTEE) {
      unitemizedAmt = UnitemizedAmounts.STATUTORY_COUNTY_CENTRAL_COMMITTEE_UNITEMIZED_AMOUNT;
    }
    else if (currComm.type == CommitteeType.STATE_CENTRAL_COMMITTEE) {
      unitemizedAmt = UnitemizedAmounts.STATE_PARTY_UNITEMIZED_AMOUNT;
    }
    else {
      unitemizedAmt = UnitemizedAmounts.ALL_OTHER_UNITEMIZED_AMOUNT;
    }

    return (
      <>
        {!pageLoading &&
          <Content className="content-pad">
            <Spin size="large" spinning={tableLoading || saving}>
              {!hasImportedData && !tableLoading &&
                <>
                  <Typography.Title level={4}>Schedule A - Contribution Import</Typography.Title>
                  <CustomForm formRef={this._importFormRef} initialValues={contributionInfo} layout="vertical">
                    <Row gutter={24}>
                      <Col {...columnSizingProps}>
                        <Typography.Title level={5}>
                          This page allows you to upload a file (must be in XML or CSV format) containing schedule transactions.
                        </Typography.Title>
                        <FormItem name="file" label="Name/Path of File">
                          <FileUpload accept=".xml,.csv" maxCount={1} />
                        </FormItem>
                      </Col>
                    </Row>
                    <Row gutter={24}>
                      <Col {...columnSizingProps}>
                        <Space size="middle">
                          <Button onClick={this.import}>Import</Button>
                          <Link onClick={this.downloadTemplate}>Download Template</Link>
                        </Space>
                      </Col>
                    </Row>
                    <br />
                  </CustomForm>
                </>
              }
              {showTable &&
              <>
                  <DataTable
                columns={this.state.tableColumns}
                fetchData={{
                  fetch: function (tableRequest) {
                    return SchAContributionsImportApiService.getStagingContributions(
                      tableRequest,
                      batchNumber || '',
                      currUser?.userId || 0
                    );
                  },
                  failureMessage: 'Failed to retrieve staging contributions'
                }}
                globalSearch={true}
                ref={(element: any) => (this.dataTable = element)}
                serverSide={true}
                styleOptions={{ compact: true }}
                tableProps={{
                  rowKey: 'contributionIdAndSeq',
                  sortDirections: ['ascend', 'descend'],
                  locale: { emptyText: 'Currently there are no Contributions to manage.' }
                }}
                title="Schedule A - Contribution Import"
              />
              <Space>
                <Button type="primary" onClick={this.confirmSaveContributions}> Save </Button>
                <Button type="default" onClick={this.cancelImportingContributions}> Cancel </Button>
                <Content className="total">
                  Total: ${this.state.total}
                </Content>
              </Space>
                </>
              }
              {!contrLoading &&
                <Modal
                  title="Schedule A - Contributions"
                  visible={this.state.showModal}
                  onOk={this.saveStagingContribution}
                  onCancel={this.cancelStagingContributionEdit}
                  okText="Save"
                  cancelText="Cancel"
                  width={1000}
                  destroyOnClose={true}
                >
                  <>
                    <CustomForm
                      formRef={this._modalFormRef}
                      initialValues={contributionInfo}
                      layout="vertical"
                      onFinish={this.confirmSaveContributions}>
                      <Row gutter={24}>
                        <Col {...columnSizingProps}>
                          <FormItem name="contactType" label="Contributor Type"
                            rules={[FormValidationUtil.Required('Contributor Type is required')]} >
                            <Select
                              showSearch
                              optionFilterProp="children"
                              disabled={candidatesOwnFunds}
                              onChange={this.handleContributorTypeChange}>
                              <Option value="" disabled={true}>-- Select Type --</Option>
                              {contributorTypes.map(ct => (
                                <Option key={ct} value={ct}>{ct}</Option>
                              ))}
                            </Select>
                          </FormItem>
                          {isUnitemized &&
                            <FormItem name="unitemizedAgreement" valuePropName="checked"
                              rules={[FormValidationUtil.Checkbox(unitemizedAffirmationChecked, isUnitemized,
                                'Affirmation checkbox must be checked for unitemized contributions')]}>
                              <Checkbox onChange={this.handleUnitemizedAffirmationCheckboxChange}>
                                I affirm that I have separately disclosed
                                any contributor who has given in excess of ${unitemizedAmt} in the calendar year. Contact
                                the Board&apos;s staff at 515-281-4028 if you have any questions.
                              </Checkbox>
                            </FormItem>
                          }
                          <FormItem name="date" label="Date"
                            rules={[FormValidationUtil.RequiredDate('Date is required'), FormValidationUtil.DateRange(startDt, endDt)]}>
                          <CustomDatePicker onChange={(d) => d?.startOf('day')}/>
                          </FormItem>
                          {hasInvalidDate &&
                            <label>Invalid date: {contributionInfo.dateString}</label>
                          }
                          <FormItem name="amount" label="Amount"
                            rules={[FormValidationUtil.RequiredNumber('Amount is required')]}>
                            <CurrencyInput min={Number.MIN_SAFE_INTEGER} />
                          </FormItem>
                          {!isUnitemized &&
                            <FormItem name="checkNumber" label="Check Number"
                              rules={[FormValidationUtil.Number('Number is invalid'),
                              FormValidationUtil.Required('Check Number is required', checkNumReq)]}>
                              <Input maxLength={12} />
                            </FormItem>
                          }
                          <Row>
                            <Col >
                              <Space>
                                <Tooltip title="Contribution was the result of a fundraising event">
                                  <FormItem name="fundraiser" valuePropName="checked">
                                    <Checkbox > From Fundraiser </Checkbox>
                                  </FormItem>
                                </Tooltip>
                                {isCandidateCommittee &&
                                  <Tooltip title="Contribution to bank account by candidate">
                                    <FormItem name="candidatesFunds" valuePropName="checked">
                                      <Checkbox onChange={this.handleCandidateFundsCheckboxChange}> Candidate&apos;s Own Funds </Checkbox>
                                    </FormItem>
                                  </Tooltip>
                                }
                                <Tooltip title="Interest earned from bank or security">
                                  <FormItem name="interest" valuePropName="checked">
                                    <Checkbox onChange={this.handleInterestCheckboxChange}> Interest </Checkbox>
                                  </FormItem>
                                </Tooltip>
                              </Space>
                            </Col>
                          </Row>
                          <FormItem name="explanation" label="Explanation" >
                            <TextArea maxLength={2000} />
                          </FormItem>
                        </Col>
                        <Col {...columnSizingProps}>
                          {currContrType === Dr2GroupTypes.INDIVIDUAL && (
                            <>
                              <PersonSearch
                                name={'person'}
                                label={'Search for an Individual'}
                                required={false}
                                disabled={candidatesOwnFunds}
                                committeeId={currComm.id || ''}
                                onSelect={this.getPerson}
                                onChange={this.handleContributorChange}
                              />
                              <AddressForm allDisabled={autoFillPerorg || candidatesOwnFunds}
                                firstName={{ name: 'contactFirstName' }}
                                middleInitial={{ name: 'contactMiddleInitial' }}
                                lastName={{ name: 'contactLastName' }}
                                address1={{ name: 'contactAddress1' }}
                                address2={{ name: 'contactAddress2' }}
                                city={{ name: 'contactCity' }}
                                state={{ name: 'contactState' }}
                                zip={{ name: 'contactZip' }}
                                phone={{ name: 'contactPrimaryPhone', required: false }}
                              />
                              {hasInvalidPhoneNumber &&
                                <label>Invalid phone number: {contributionInfo.contactPhoneString}</label>
                              }
                              <FormItem name="contactRelationship" label="Relationship"
                                rules={[FormValidationUtil.Required('Relationship is required', relationReq)]}>
                                <Select
                                  showSearch
                                  disabled={autoFillPerorg || candidatesOwnFunds}
                                  optionFilterProp="children">
                                  <Option value="" disabled={true}>-- Select Relationship --</Option>
                                  {relations.map(rt => (
                                    <Option key={rt.code || ''} value={rt.code || ''}>{rt.name}</Option>
                                  ))}
                                </Select>
                              </FormItem>
                            </>
                          )}
                          {currContrType === Dr2GroupTypes.COMMITTEE && (
                            <>
                              <CommitteeAutoSearch
                                name="committeeName"
                                label="Name"
                                required={true}
                                requireSelection={true}
                                onSelectCommittee={this.handleCommitteeSelect}
                              />
                              {hasInvalidCommitteeName &&
                                <label>Invalid Committee Name: {contributionInfo.invalidCommitteeName}</label>
                              }
                              <FormItem name="committeeCode" hidden={true}>
                                <Input hidden={true} disabled={true} />
                              </FormItem>
                              <FormItem name="contactAddress1" hidden={true}>
                                <Input hidden={true} disabled={true} />
                              </FormItem>
                              <FormItem name="contactAddress2" hidden={true}>
                                <Input hidden={true} disabled={true} />
                              </FormItem>
                              <FormItem name="contactCity" hidden={true}>
                                <Input hidden={true} disabled={true} />
                              </FormItem>
                              <FormItem name="contactState" hidden={true}>
                                <Input hidden={true} disabled={true} />
                              </FormItem>
                              <FormItem name="contactZip" hidden={true}>
                                <Input hidden={true} disabled={true} />
                              </FormItem>
                            </>
                          )}
                          {(currContrType === Dr2GroupTypes.COMPANYOTHER || currContrType === Dr2GroupTypes.TRUST) && (
                            <>
                              <OrganizationAutoSearch
                                name={'company'}
                                label={'Search for a Company'}
                                required={false}
                                committeeId={currComm.id || ''}
                                perorgType={currContrType}
                                onSelect={this.getOrganization}
                                onChange={this.handleContributorChange}
                              />
                              <AddressForm allDisabled={autoFillPerorg}
                                name={{ name: 'contactName', label: 'Company' }}
                                address1={{ name: 'contactAddress1' }}
                                address2={{ name: 'contactAddress2' }}
                                city={{ name: 'contactCity' }}
                                state={{ name: 'contactState' }}
                                zip={{ name: 'contactZip' }}
                                phone={{ name: 'contactPrimaryPhone', required: false }}
                              />
                              {hasInvalidPhoneNumber &&
                                <label>Invalid phone number: {contributionInfo.contactPhoneString}</label>
                              }
                            </>
                          )}
                          {currContrType === Dr2GroupTypes.TRUST &&
                            <>
                              <FormItem name="isLivingOrRevocableTrust" valuePropName="checked">
                                <Checkbox onChange={this.handleRevocableTrustCheckboxChange}>Living or Revocable Trust</Checkbox>
                              </FormItem>
                              <FormItem name="trustee" label="Trustee"
                                rules={[FormValidationUtil.Required('Trustee is required', trusteeTrustorReq)]}>
                                <Input disabled={autoFillPerorg} />
                              </FormItem>
                              <FormItem name="trustor" label="Trustor"
                                rules={[FormValidationUtil.Required('Trustor is required', trusteeTrustorReq)]}>
                                <Input disabled={autoFillPerorg} />
                              </FormItem>
                            </>
                          }
                        </Col>
                      </Row>
                      <Row>
                        <Col {...columnSizingProps}>
                          <FormItem name="contributorAutoFillId" hidden={true}>
                            <Input hidden={true} disabled={true} aria-hidden={true}/>
                          </FormItem>
                        </Col>
                      </Row>
                    </CustomForm >
                  </>
                </Modal>
              }
            </Spin>
          </Content>
        }
      </>
    );
  }

  private getTableColumns = (): DataTableColumnProps<SchAContributionsImportDTO>[] => {

    const tableColumns: DataTableColumnProps<SchAContributionsImportDTO>[] = [
      DataTableColumnUtil.Text('Date', 'contributionDt', 100, { filterType: FilterType.NONE, sorter: false }),
      DataTableColumnUtil.Address('Contributor', 'contributorName',
        (c) => ({
          name: c.contributorName,
          line1: c.contributorAddressLine1,
          line2: c.contributorAddressLine2,
          city: c.contributorCity,
          state: c.contributorState,
          zip: c.contributorZip
        }),
        null,
        { sorter: false }
      ),
      DataTableColumnUtil.Text('Amount', 'contributionAmt'),
      DataTableColumnUtil.Text('Check #', 'checkNumber', 125, { align: 'center' }),
      DataTableColumnUtil.BooleanCheckbox('Contains Error(s)', 'hasError', 125, FilterType.BooleanRadio, { defaultSortOrder: 'descend' }),
      DataTableColumnUtil.Buttons('contributionIdAndSeq',
        [
          {
            text: 'Edit',
            onClick: (rowData) => this.editContribution(rowData)
          },
          {
            text: 'Delete',
            onClick: (rowData) => this.confirmDelete(rowData)
          }
        ],
        150)
    ];

    return tableColumns;
  }

  // Data Retrieval
  private handleContributorTypeChange = (contributorType: string) => {
    this.setState({
      currContrType: contributorType,
      autoFillPerorg: false,
      trusteeTrustorReq: contributorType == Dr2GroupTypes.TRUST
    });

    this._modalFormRef.current?.setFieldsValue({
      'contactFirstName': '',
      'contactMiddleInitial': '',
      'contactLastName': '',
      'contactRelationship': '',
      'contactAddress1': '',
      'contactAddress2': '',
      'contactCity': '',
      'contactState': '',
      'contactZip': '',
      'contactPrimaryPhone': '',
      'trustee': '',
      'trustor': '',
      'person': null,
      'committeeName': null,
      'contactName': null,
      'company': null,
      'isLivingOrRevocableTrust': false,
      'contributorAutoFillId': ''
    });
  }

  private handleContributorChange = (value: string) => {
    this._modalFormRef.current?.setFieldsValue({ contributorAutoFillId: '' });
    this.setState({ autoFillPerorg: !!value });
  }

  private handleCommitteeSelect = (committee: CommitteeInfoDTO | undefined) => {
    this._modalFormRef.current?.setFieldsValue({ contributorAutoFillId: committee?.id });
  }

  private handleUnitemizedAffirmationCheckboxChange = (e: any) => {
    this.setState({ unitemizedAffirmationChecked: e.target.checked });
  }

  private handleCandidateFundsCheckboxChange = (e: any) => {
    if (e.target.checked) {
      if (this._modalFormRef.current != null) {
        const { candidate } = this.state;
        if (candidate.firstName !== null) {
          this.setIndividualFields(candidate);
        }
        else {
          this._modalFormRef.current.setFieldsValue({
            contactType: Dr2GroupTypes.INDIVIDUAL,
            contributorAutoFillId: ''
          });
          this.setState({ currContrType: Dr2GroupTypes.INDIVIDUAL });
          notification.error({
            message: 'Candidate was not found',
            description: ''
          });
        }

      }
    }
    else {
      if (this._modalFormRef.current != null) {
        this.setIndividualFields();
      }
    }
    this.setState({ candidatesOwnFunds: e.target.checked });
    this._modalFormRef.current?.setFieldsValue({ 'person': null });
  }

  private setCompanyFields(form?: BaseScheduleInfoDTO) {
    if (form) {
      this._modalFormRef.current?.setFieldsValue({
        contactName: form.committeeOrCompanyName,
        contactAddress1: form.addressLine1,
        contactAddress2: form.addressLine2,
        contactCity: form.city,
        contactState: form.state,
        contactZip: form.zip,
        contactPrimaryPhone: form.phoneNumber,
        trustee: form.trustee,
        trustor: form.trustor
      });
    }
    else {
      this._modalFormRef.current?.setFieldsValue({
        contactName: '',
        contactAddress1: '',
        contactAddress2: '',
        contactCity: '',
        contactState: '',
        contactZip: '',
        contactPrimaryPhone: '',
        trustee: '',
        trustor: '',
        contributorAutoFillId: ''
      });
      this.setState({ autoFillPerorg: false });
    }
  }

  private setIndividualFields(form?: BaseScheduleInfoDTO) {
    if (form) {
      this._modalFormRef.current?.setFieldsValue({
        contactType: form.groupType,
        contactFirstName: form.firstName,
        contactMiddleInitial: form.middleInitial,
        contactLastName: form.lastName,
        contactAddress1: form.addressLine1,
        contactAddress2: form.addressLine2,
        contactCity: form.city,
        contactState: form.state,
        contactZip: form.zip,
        contactPrimaryPhone: form.phoneNumber,
        contactRelationship: form.relationshipCode,
        contributorAutoFillId: ''
      });
    }
    else {
      this._modalFormRef.current?.setFieldsValue({
        contactType: Dr2GroupTypes.INDIVIDUAL,
        contactFirstName: '',
        contactMiddleInitial: '',
        contactLastName: '',
        contactAddress1: '',
        contactAddress2: '',
        contactCity: '',
        contactState: '',
        contactZip: '',
        contactPrimaryPhone: '',
        contactRelationship: '',
        contributorAutoFillId: ''
      });
      this.setState({ autoFillPerorg: false });
    }
    this.setState({ currContrType: Dr2GroupTypes.INDIVIDUAL });
  }

  private handleRevocableTrustCheckboxChange = (e: any) => {
    this.setState({ trusteeTrustorReq: !e.target.checked });
  }

  private handleInterestCheckboxChange = (e: any) => {
    if (e.target.checked) {
      if (this._modalFormRef.current != null) {
        this._modalFormRef.current.setFieldsValue({ explanation: 'Interest Earned' });
      }
    }
    else {
      if (this._modalFormRef.current != null) {
        this._modalFormRef.current.setFieldsValue({ explanation: '' });
      }
    }
  }

  private loadLookups = () => {
    const relationPromise = LookupsApiService.getRelationTypes();
    Promise.all([relationPromise])
      .then(result => {
        const [relations] = result;
        this.setState({ relations: relations });
      });
  }

  private getSchedule = () => {
    ScheduleApiService.getSchedule(CurrentUser.Get()?.dr2Id || '', ScheduleAbbreviationCodes.SCHEDULEA)
      .then(schedule => {
        this.setState({ startDt: moment.utc(schedule.startDate), endDt: moment.utc(schedule.endDate) });
      });
  }

  private getCandidate = () => {
    ScheduleApiService.getCandidate(CurrentUser.Get()?.committeeId || '')
      .then(personInfo => {
        if (personInfo.groupType == null) {
          return;
        }

        this.setState({ candidate: personInfo });
      });
  }

  private getPerson = (perorgId: string) => {
    if (perorgId != '') {
      ScheduleApiService.getPerson(perorgId, CurrentUser.Get()?.committeeId || '')
        .then(personInfo => {
          if (personInfo) {
            this.setIndividualFields(personInfo);
            this._modalFormRef.current?.setFieldsValue({ contributorAutoFillId: perorgId });
          }
        });
    }
    else {
      this.setIndividualFields();
    }
  }

  private getOrganization = (perorgId: string) => {
    if (perorgId != '') {
      ScheduleApiService.getOrganization(perorgId, CurrentUser.Get()?.committeeId || '')
        .then(orgInfo => {
          if (orgInfo) {
            this.setCompanyFields(orgInfo);
            this._modalFormRef.current?.setFieldsValue({ contributorAutoFillId: perorgId });
          }
        });
    }
    else {
      this.setCompanyFields();
    }
  }

  private getUserCommittee = () => {
    ScheduleApiService.getCommittee(CurrentUser.Get()?.committeeId || '')
      .then(committee => {
        if (committee) {
          this.setState({ currComm: { ...committee } });
        }
      });
  }

  // Button methods
  private getContribution = (batchNumber: string, transNumber: number, userId: number) => {
    this.setState({ contrLoading: true });

    SchAContributionsImportApiService.getStagingContribution(batchNumber, transNumber, userId)
      .then(stagingContr => {
        if (stagingContr) {
          if (stagingContr.date != null) {
            stagingContr.date = moment(stagingContr.date);
          }

          const contributionInfo: SchAContributionsImportFormDTO & { person?: string, company?: string } = stagingContr;
          if (contributionInfo.contactFirstName && contributionInfo.contactLastName) {
            if (contributionInfo.contactMiddleInitial) {
              contributionInfo.person = stagingContr.contactFirstName + ' '
                + stagingContr.contactMiddleInitial + '. ' + stagingContr.contactLastName;
            }
            else {
              contributionInfo.person = stagingContr.contactFirstName + ' ' + stagingContr.contactLastName;
            }
          }
          else if (contributionInfo.contactType == Dr2GroupTypes.TRUST || contributionInfo.contactType == Dr2GroupTypes.COMPANYOTHER) {
            if (contributionInfo.contactName) {
              contributionInfo.company = contributionInfo.contactName;
            }
          }

          this.setState({
            contributionInfo: stagingContr,
            currContrType: stagingContr.contactType || '',
            trusteeTrustorReq: stagingContr.contactType == Dr2GroupTypes.TRUST && !stagingContr.isLivingOrRevocableTrust,
            autoFillPerorg: stagingContr.contactType != Dr2GroupTypes.COMMITTEE,
            candidatesOwnFunds: stagingContr.candidatesFunds
          }, () => {
            this._modalFormRef.current?.setFieldsValue(this.state.contributionInfo);
            this.setState({ contrLoading: false, showModal: true });
          });
        }
        else {
          notification.error({
            message: 'Error while fetching contribution',
            description: 'Not found'
          });
          this.setState({ contrLoading: false });
        }
      }).catch(() => {
        notification.error({
          message: 'Error while fetching contribution',
          description: ''
        });
        this.setState({ contrLoading: false });
      });
  }

  private editContribution = (rowData: SchAContributionsImportDTO) => {
    this.setState({
      batchNumber: rowData.batchNumber || '',
      transNumber: rowData.transactionNumber.toString() || '',
      userId: rowData.userID || 0,
      showModal: true
    }, () => {
      if (this.state.batchNumber) {
        this.getContribution(this.state.batchNumber || '', Number(this.state.transNumber) || 0, this.state.userId || 0);
      }
    });
  }

  private confirmDelete = (rowData: SchAContributionsImportDTO) => {
    confirm({
      title: 'Are you sure you want to delete this contribution?',
      content: 'The following record will be permanently deleted: ' + rowData.contributorName + ' - $' + rowData.contributionAmt,
      onOk: () => {
        this.deleteContribution(rowData);
      },
    });
  }

  private deleteContribution = (rowData: SchAContributionsImportDTO) => {
    if (rowData.batchNumber != null && rowData.userID != null) {
      SchAContributionsImportApiService.deleteStagingContribution(rowData.batchNumber, rowData.transactionNumber, rowData.userID)
        .then(() => {
          if (this.dataTable) {
            this.dataTable.refresh();
          }
        }).catch(() => {
          notification.error({
            message: 'Error while deleting contribution',
            description: '',
          });
        });
    }
  }

  private downloadTemplate = (): void => {
    SchAContributionsImportApiService.downloadTemplate()
      .catch((error) => {
        notification.error({
          message: 'Template Download Failed',
          description: error.message
        });
      });
  }

  private import = (): void => {
    const files = this._importFormRef.current?.getFieldValue('file')?.newFiles;
    if (!files) {
      notification.info({
        message: 'Please select a file'
      });
      return;
    }

    const fileDTO = SchABImportFileDTO.create();
    const request = FileUploadUtil.attatchFileToModel('request', fileDTO, 'file', files[0]);

    const currentUser = CurrentUser.Get();
    if (currentUser != null) {
      this.setState({ tableLoading: true });

      SchAContributionsImportApiService.import(request, currentUser?.committeeId || '', currentUser?.dr2Id || '')
        .then(batchNumber => {
          if (batchNumber.errorMessage) {
            notification.error({
              message: 'The Import File Contained an Error',
              description: batchNumber.errorMessage
            });
          }
          else {
            this.setState({ batchNumber: batchNumber.batchNumber || '', hasImportedData: true }, () => {
              if (this.dataTable) {
                this.dataTable.refresh();
              }
              this.getImportedSum();
            });
          }
        })
        .catch((error) => {
          notification.error({
            message: 'Import Failed',
            description: error.message
          });
        })
        .finally(() => {
          this.setState({ tableLoading: false });
        });
    }
  }

  private saveStagingContribution = () => {
    if (this._modalFormRef.current) {

      this._modalFormRef.current.validateFields().then(values => {

        this.setState({ saving: true });

        if (values.contributorAutoFillId === '' || values.contributorAutoFillId === undefined) {
          values.contributorAutoFillId = null;
        }

        SchAContributionsImportApiService.saveStagingContribution(
          values,
          this.state.currContrType,
          CurrentUser.Get()?.committeeId || '',
          this.state.batchNumber || '',
          Number(this.state.transNumber) || 0,
          this.state.userId || 0
        )
          .then(() => {
            notification.success({
              message: 'Saved Successfully'
            });
            this.setState({ saving: false, showModal: false });
            if (this.dataTable) {
              this.dataTable.refresh();
            }
          })
          .catch(() => {
            notification.error({ message: 'Failed to save the Staging Contribution.' });
            this.setState({ saving: false, showModal: false });
            if (this.dataTable) {
              this.dataTable.refresh();
            }
          });

      });
    }
  }

  private cancelStagingContributionEdit = () => {
    this.setState({ showModal: false });
  }

  private confirmSaveContributions = () => {
    confirm({
      title: 'Are you sure you want to save the imported contributions?',
      onOk: () => {
        this.saveImportedContributions();
      },
    });
  }

  private getImportedSum = () => {
    const currentUser = CurrentUser.Get();

    if (currentUser != null) {

      SchAContributionsImportApiService.sumImportedAmount(this.state.batchNumber || '',
        this.state.userId || 0,
        currentUser.committeeId || '',
        currentUser.dr2Id || ''
      )
        .then(results => this.setState({ total: results }));

    }
    return this.state.total;
  }

  private saveImportedContributions = () => {
    const currentUser = CurrentUser.Get();

    if (currentUser != null) {

      this.setState({ saving: true });

      SchAContributionsImportApiService.stagingContributionTableHasErrors(
        this.state.batchNumber || '',
        this.state.userId || 0
      )
        .then((hasErrors) => {

          if (hasErrors) {
            notification.info({
              message: 'The imported contributions still contain errors. Please resolve the errors to save the contributions.',
              duration: 6
            });
            if (this.dataTable) {
              this.dataTable.refresh();
            }
            this.setState({ saving: false });
          }
          else {
            SchAContributionsImportApiService.saveImportedContributions(
              this.state.batchNumber || '',
              this.state.userId || 0,
              currentUser.committeeId || '',
              currentUser.dr2Id || ''
            )
              .then(() => {
                notification.success({ message: 'Saved Successfully' });
                this.setState({ saving: false });
                if (this.dataTable) {
                  this.dataTable.refresh();
                }
                HistoryUtil.push(Routes.SCHEDULE_A_CONTRIBUTIONS);
              })
              .catch(() => {
                notification.error({ message: 'Failed to save the Contributions.' });
                this.setState({ saving: false });
                if (this.dataTable) {
                  this.dataTable.refresh();
                }
              });
          }

        })
        .catch(() => {
          notification.error({ message: 'Failed to check the table for errors.' });
          this.setState({ saving: false });
        });

    }
  }

  private cancelImportingContributions = () => {
    SchAContributionsImportApiService.deleteStagingContributionsByUserId(this.state.userId || 0)
      .finally(() => {
        HistoryUtil.push(Routes.SCHEDULE_A_CONTRIBUTIONS);
      });
  }
}

export default SchAContributionsImport;