// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';

interface SchAContributionsFormDTO { 
  contributionType: string | null;
  contributorName: string | null;
  firstName: string | null;
  middleInitial: string | null;
  lastName: string | null;
  contributorAutoFillId: string | null;
  date: moment.Moment | string | null;
  amount: number;
  checkNumber: string | null;
  explanation: string | null;
  addressLine1: string | null;
  addressLine2: string | null;
  city: string | null;
  state: string | null;
  zip: string | null;
  phoneNumber: string | null;
  relationshipCode: string | null;
  isEarmarked: boolean;
  isFromFundraiser: boolean;
  isCandidatesOwnFunds: boolean;
  isInterest: boolean;
  isUnItemized: boolean;
  isLivingOrRevocableTrust: boolean;
  trustee: string | null;
  trustor: string | null;
  earmarkedTo: string | null;
  earmarkedFrom: string | null;
  earmarkedToAddress: string | null;
  earmarkedFromAddress: string | null;
  unitemizedAgreement: boolean;
  auditorsNoteId: string | null;
}
const SchAContributionsFormDTO = {
  create: (initValues?: Partial<SchAContributionsFormDTO> | Record<string, unknown> | null) => {
    return Object.assign(
    {
      contributionType: null,
      contributorName: null,
      firstName: null,
      middleInitial: null,
      lastName: null,
      contributorAutoFillId: null,
      date: null,
      amount: 0,
      checkNumber: null,
      explanation: null,
      addressLine1: null,
      addressLine2: null,
      city: null,
      state: null,
      zip: null,
      phoneNumber: null,
      relationshipCode: null,
      isEarmarked: false,
      isFromFundraiser: false,
      isCandidatesOwnFunds: false,
      isInterest: false,
      isUnItemized: false,
      isLivingOrRevocableTrust: false,
      trustee: null,
      trustor: null,
      earmarkedTo: null,
      earmarkedFrom: null,
      earmarkedToAddress: null,
      earmarkedFromAddress: null,
      unitemizedAgreement: false,
      auditorsNoteId: null,
    },
    initValues);
  }
};

export default SchAContributionsFormDTO;