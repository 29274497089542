import { Button, Col, Divider, Layout, Row, Steps, Space, Modal } from 'antd';
import * as React from 'react';
import HistoryUtil from '../../utils/HistoryUtil';

const { Content } = Layout;
const { Step } = Steps;
const { confirm } = Modal;

export interface Step {
  key: number;
  title: string;
  description?: React.ReactNode | string | null;
  hideNext?: boolean;
}

interface StepperProps {
  steps: Step[];
  currentStepKey: number;
  defaultStep?: number;
  cancelReturnUrl?: string | null;
  onStepChangeRequested: (requestedStep: Step) => void;
  allowStepClick?: boolean;
  onSaveRequested: () => void;
  minVerticalStepWidth: number;
  hideSave?: boolean;
  additionalContent?: JSX.Element | null | '' | false;
  titleSubmit?: string;
  buttonsDisabled?: boolean;
}

interface StepperState {
  smallWindow: boolean;
  extraSmallWindow: boolean;
}

class Stepper extends React.Component<StepperProps, StepperState> {
  stepperRef: React.RefObject<HTMLDivElement>;
  constructor(props: StepperProps) {
    super(props);
    this.state = {
      smallWindow: this.isSmallWindow(),
      extraSmallWindow: this.isExtraSmallWindow()
    };
    this.stepperRef = React.createRef();
  }

  static defaultProps: Partial<StepperProps> = {
    titleSubmit: 'Agree and Save'
  };

  componentDidMount() {
    window.onresize = this.handleWindowResize;
    window.addEventListener('keyup', this.handleKeyUp);
  }

  componentWillUnmount() {
    window.onresize = null;
    window.removeEventListener('keyup', this.handleKeyUp);
  }

  render() {
    const { steps, currentStepKey, minVerticalStepWidth, additionalContent, titleSubmit, buttonsDisabled } = this.props;
    const { smallWindow, extraSmallWindow } = this.state;

    const stepsRender = (
      <Content className="content-pad">
        <Steps
          direction={smallWindow ? 'horizontal' : 'vertical'}
          labelPlacement={smallWindow ? 'vertical' : undefined}
          current={currentStepKey}
          size={smallWindow ? 'small' : 'default'}
          className={this.props.allowStepClick ? 'clickable' : ''}
          style={extraSmallWindow || smallWindow ? { flexFlow: 'row wrap' } : undefined}>
          {steps.map((step) => (
            <Step
              key={step.title}
              title={extraSmallWindow ? '' : step.title}
              description={step.description}
              onClick={() => {
                if (this.props.allowStepClick) {
                  this.handleStepClicked(step);
                }
              }}
            />
          ))}
        </Steps>
      </Content>
    );

    const stepContents = (
      <Content className="content-pad">
        {this.props.children}
        <div>
          <Space>
            {currentStepKey + 1 < this.props.steps.length ? (
              !steps[currentStepKey].hideNext &&
              <Button type="primary" disabled={buttonsDisabled} onClick={() => this.handlNavButtonClicked(currentStepKey + 1)}>Next</Button>
            ) : !this.props.hideSave &&
              <Button type="primary" disabled={buttonsDisabled} onClick={() => this.props.onSaveRequested()}>{titleSubmit}</Button>}
            {currentStepKey > 0 &&
              <Button disabled={buttonsDisabled} onClick={() => this.handlNavButtonClicked(currentStepKey - 1)}>Previous</Button>}
            {this.props.cancelReturnUrl &&
              <Button disabled={buttonsDisabled} onClick={this.handleCancelClicked}>Cancel</Button>}
          </Space>
        </div>
      </Content>
    );

    return (
      <div ref={this.stepperRef} >
        {smallWindow ? (
          <>
            {stepsRender}
            {stepContents}
          </>
        ) : (
            <Row gutter={5} style={{ width: '100%', flexFlow: 'row nowrap' }}>
              <Col style={{ minWidth: minVerticalStepWidth }}>
                {stepsRender}
              </Col>
              <Col><Divider type="vertical" style={{ height: '100%' }} /></Col>
              <Col flex={1}>
                {stepContents}
                {additionalContent}

              </Col>
            </Row>
          )}
      </div>
    );
  }

  private handleWindowResize = () => {
    if (this.state.smallWindow !== this.isSmallWindow()) {
      this.setState({ smallWindow: !this.state.smallWindow });
    }
    if (this.state.extraSmallWindow !== this.isExtraSmallWindow()) {
      this.setState({ extraSmallWindow: !this.state.extraSmallWindow });
    }
  }

  private isSmallWindow = () => {
    return !window.matchMedia('(min-width: 992px)').matches;
  }

  private isExtraSmallWindow = () => {
    return !window.matchMedia('(min-width: 768px)').matches;
  }

  private handleStepClicked = (step: Step) => {
    this.props.onStepChangeRequested(step);
  }

  private handlNavButtonClicked = (stepKey: number) => {
    const step = this.props.steps.find(s => s.key === stepKey);
    if (!step) {
      return;
    }

    this.props.onStepChangeRequested(step);
  }

  private handleCancelClicked = () => {
    confirm({
      title: 'Are you sure you want to leave?',
      okText: 'Yes',
      cancelText: 'No',
      onOk: () => {
        HistoryUtil.push(this.props.cancelReturnUrl || '');
      }
    });
  }

  private handleKeyUp = (event: any) => {
    if (event.code === 'Enter' || event.key === 'Enter') {
      if (!document.activeElement?.className.includes('ant-select') && this.stepperRef.current?.contains(document.activeElement)) {
        this.handlNavButtonClicked(this.props.currentStepKey + 1);
      }
    }
  }
}

export default Stepper;
