// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import EntitySelectorDTO from './EntitySelectorDTO';

interface CommitteeInfoDTO extends EntitySelectorDTO { 
  id: string | null;
  name: string | null;
  type: string | null;
  committeeTypeCd: string | null;
  description: string | null;
  descriptionId: string | null;
  politicalParty: string | null;
  districtNumber: number | null;
  county: string | null;
  electionYear: number | null;
  nameOfOfficeSought: string | null;
  electionDate: moment.Moment | string | null;
  committeeEmail: string | null;
  committeeCode: string | null;
  filerTypeName: string | null;
}
const CommitteeInfoDTO = {
  create: (initValues?: Partial<CommitteeInfoDTO> | Record<string, unknown> | null) => {
    return Object.assign(EntitySelectorDTO.create(),
    {
      id: "00000000-0000-0000-0000-000000000000",
      name: null,
      type: null,
      committeeTypeCd: null,
      description: null,
      descriptionId: null,
      politicalParty: null,
      districtNumber: null,
      county: null,
      electionYear: null,
      nameOfOfficeSought: null,
      electionDate: null,
      committeeEmail: null,
      committeeCode: null,
      filerTypeName: null,
    },
    initValues);
  }
};

export default CommitteeInfoDTO;