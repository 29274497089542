import { Layout, Modal, notification } from 'antd';
import * as React from 'react';
import DataTable, { DataTableColumnProps } from '../shared/DataTable/DataTable';
import DataTableButtonUtil from '../shared/DataTable/DataTableButtonUtil';
import DataTableColumnUtil from '../shared/DataTable/DataTableColumnUtil';
import AdminConsentForSalesAndLeasesDTO from '../../models/AdminConsentForSalesAndLeasesDTO';
import HistoryUtil from '../../utils/HistoryUtil';
import Routes from '../../consts/Routes';
import AdminConsentForSalesAndLeasesApiService from '../../api/AdminConsentForSalesAndLeasesApiService';

const Content = Layout.Content;
const { confirm } = Modal;

interface ManageSalesAndLeasesProps {
    csflId?: number
}

interface ManageSalesAndLeasesState {
    loading: boolean
}

class ManageSalesAndLeases extends React.Component<ManageSalesAndLeasesProps, ManageSalesAndLeasesState> {
    private dataTable: DataTable<AdminConsentForSalesAndLeasesDTO> | undefined
    constructor(props: ManageSalesAndLeasesProps) {
        super(props);
        this.state = {
            loading: false
        };
    }

    render() {
        const actionButtons = [];

        actionButtons.push(DataTableButtonUtil.Reset());
        actionButtons.push(DataTableButtonUtil.Primary('Add New', () => this.goToSaleAndLeasesDetails()));

        return (
            <Content className="content-pad">
                <DataTable
                    ref={(element: any) => (this.dataTable = element)}
                    serverSide={true}
                    tableProps={{
                        rowKey: 'Id',
                        sortDirections: ['descend', 'ascend']
                    }}
                    title={'Manage Sales/Leases'}
                    globalSearch={true}
                    buttonBar={actionButtons}
                    columns={this.getSalesAndLeasesTableColumns()}
                    styleOptions={{ compact: true, alternatingRowHighlight: true }}
                    fetchData={{
                        fetch: function (tableRequest) { return AdminConsentForSalesAndLeasesApiService.getConsentForSaleAndLease(tableRequest); },
                        failureMessage: 'Failed to retrieve Sale And Lease Data'
                    }}
                    stateSaving={{
                        enabled: true,
                        tableUniqueKey: 'adminManageSalesAndLeases',
                        perSession: true
                    }}
                />
            </Content>
        );
    }

    private getSalesAndLeasesTableColumns = (): DataTableColumnProps<AdminConsentForSalesAndLeasesDTO>[] => {
        const columns: DataTableColumnProps<AdminConsentForSalesAndLeasesDTO>[] = [];
        columns.push(DataTableColumnUtil.Text('Individual Name', 'name'));
        columns.push(DataTableColumnUtil.Text('Agency Name', 'agency'));
        columns.push(DataTableColumnUtil.Date('Date of Sale', 'saleDate', null, { defaultSortOrder: 'descend' }));
        columns.push(DataTableColumnUtil.Currency('Sale Amount', 'saleAmount'));
        columns.push(DataTableColumnUtil.Text('Description of Sale', 'saleDescription'));
        const manageButtons = [
            { text: 'Edit', onClick: (rowData: AdminConsentForSalesAndLeasesDTO) => this.goToSaleAndLeasesDetails(rowData.id) },
            { text: 'Delete', onClick: (rowData: AdminConsentForSalesAndLeasesDTO) => this.confirmDelete(rowData.id, rowData.name || '') },
            { text: 'View', onClick: (rowData: AdminConsentForSalesAndLeasesDTO) => window.open(rowData.fileUrl || null || '') },
        ];
        const columnManage: DataTableColumnProps<AdminConsentForSalesAndLeasesDTO> = DataTableColumnUtil.Buttons('route', manageButtons, 150);
        columns.push(columnManage);
        return columns as DataTableColumnProps<AdminConsentForSalesAndLeasesDTO>[];
    }

    private goToSaleAndLeasesDetails(cfslId?: number) {
        if (cfslId) {
            HistoryUtil.push(Routes.generate(Routes.EDIT_CONSENT_FOR_SALES_AND_LEASES, { cfslId: cfslId }));
        }
        else {
            HistoryUtil.push(Routes.generate(Routes.ADD_CONSENT_FOR_SALES_AND_LEASES));
        }
    }

    private confirmDelete = (id: number, name: string) => {
        if (!id) {
            return;
        }

        confirm({
            title: 'Are you sure you want to delete this Consent for Sales/Leases record?',
            content: 'The following record will be deleted permanently: ' + name,
            onOk: () => {
                this.delete(id);
            },
        });
    }

    private delete = (id: number) => {
        AdminConsentForSalesAndLeasesApiService.delete(id)
            .then(() => {
                this.dataTable?.refresh();
                notification.success({
                    message: 'Consent for Sales/Leases filing was deleted Successfully'
                });
            }).catch(() => {
                notification.error({
                    message: 'Failed to delete Consent For Sales/Leases'
                });
            });
    }
}

export default ManageSalesAndLeases;