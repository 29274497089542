import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import Routes from '../../../consts/Routes';
import { RedirectAs404 } from '../../../utils/RouteErrors';
import Datasets from './Datasets';
import OneTimeContributions from './OneTimeContributions';
import VerifiedStatementsOfRegistration from './VerifiedStatementsOfRegistration';
import IndependentExpenditures from './IndependentExpenditures';
import StatementsOfOrganizationAndDissolution from './StatementsOfOrganizationAndDissolution';
import StateReports from './StateReports';
import CountyReports from './CountyReports';
import PfdReports from './PfdReports';
import GbReports from './GbReports';
import CampaignLateReports from './CampaignLateReports';
import PublicSearch from '../PublicSearch';
import EthicsLateReports from './EthicsLateReports';
import EscheatReports from './EscheatReports';
import ConsentForSalesAndLeasesReports from './ConsentForSalesAndLeasesReports';
import DualCompReports from './DualCompReports';
import SalesByStateReports from './SalesByStateReports';
import BuildingFundReports from './BuildingFundReports';
import NewFiledReports from './NewFiledReports';

const PublicReports = () => (
  <Switch>
    <Route exact={true} path={Routes.PUBLIC_REPORTS} component={NewFiledReports} />
    <Route exact={true} path={Routes.DATASETS} component={Datasets} />
    <Route exact={true} path={Routes.REPORTS_STATEMENT_OF_ORGANIZATION_AND_DISSOLUTION} component={StatementsOfOrganizationAndDissolution} />
    <Route exact={true} path={Routes.STATE_REPORTS} component={StateReports} />
    <Route exact={true} path={Routes.OTC_REPORTS} component={OneTimeContributions} />
    <Route exact={true} path={Routes.VSR_REPORTS} component={VerifiedStatementsOfRegistration} />
    <Route exact={true} path={Routes.IE_REPORTS} component={IndependentExpenditures} />
    <Route exact={true} path={Routes.COUNTY_REPORTS} component={CountyReports} />
    <Route exact={true} path={Routes.PFD_REPORTS} component={PfdReports} />
    <Route exact={true} path={Routes.GB_REPORTS} component={GbReports} />
    <Route exact={true} path={Routes.CAMPAIGN_LATE_REPORTS} render={(props) => <CampaignLateReports {...props} />} />
    <Route exact={true} path={Routes.ETHICS_LATE_REPORTS} render={(props) => <EthicsLateReports {...props} />} />
    <Route exact={true} path={Routes.FILTERED_LATE_REPORTS} render={(props) => <CampaignLateReports {...props}/>} />
    <Route exact={true} path={Routes.SEARCHABLE_DATABASE} component={PublicSearch} />
    <Route exact={true} path={Routes.ESCHEAT_REPORTS} component={EscheatReports} />
    <Route exact={true} path={Routes.CONSENT_FOR_SALES_AND_LEASES_REPORTS} component={ConsentForSalesAndLeasesReports} />
    <Route exact={true} path={Routes.SALES_BY_STATE_REPORTS} component={SalesByStateReports} />
    <Route exact={true} path={Routes.DUAL_COMP_REPORTS} component={DualCompReports} />
    <Route exact={true} path={Routes.BUILDING_FUND_REPORTS} component={BuildingFundReports} />

    {/* This needs to be the last item */}
    <Route component={RedirectAs404} />
  </Switch>
);

export default PublicReports;