// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
import BaseApi from './BaseApi';
import CommitteeSearchDTO from '../models/CommitteeSearchDTO';
import CommitteeInfoDTO from '../models/CommitteeInfoDTO';
import PersonInfoDTO from '../models/PersonInfoDTO';
import OrganizationInfoDTO from '../models/OrganizationInfoDTO';
import PenaltySearchDTO from '../models/PenaltySearchDTO';

export class SearchApiService extends BaseApi {

  // post: api/search/committee
  public getCommitteeByNameOrId(search: CommitteeSearchDTO): Promise<CommitteeInfoDTO[]> {
    let url = `api/search/committee`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, CommitteeInfoDTO[]>(search, 'post', url, true, false);
  }

  // get: api/search/person?committeeId=${encodeURIComponent(committeeId)}&name=${encodeURIComponent(name)}
  public getPersonByName(committeeId: string, name: string): Promise<PersonInfoDTO[]> {
    let url = `api/search/person`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (committeeId != null) {
      url += `${prefix}committeeId=${encodeURIComponent(committeeId)}`;
      prefix = '&';
    }
    if (name != null) {
      url += `${prefix}name=${encodeURIComponent(name)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, PersonInfoDTO[]>(null, 'get', url, true, false);
  }

  // get: api/search/organization?committeeId=${encodeURIComponent(committeeId)}&name=${encodeURIComponent(name)}&perorgType=${encodeURIComponent(perorgType)}
  public getOrganizationByName(committeeId: string, name: string, perorgType: string): Promise<OrganizationInfoDTO[]> {
    let url = `api/search/organization`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (committeeId != null) {
      url += `${prefix}committeeId=${encodeURIComponent(committeeId)}`;
      prefix = '&';
    }
    if (name != null) {
      url += `${prefix}name=${encodeURIComponent(name)}`;
      prefix = '&';
    }
    if (perorgType != null) {
      url += `${prefix}perorgType=${encodeURIComponent(perorgType)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, OrganizationInfoDTO[]>(null, 'get', url, true, false);
  }

  // get: api/search/iiepenalties?name=${encodeURIComponent(name)}
  public getIiePenalties(name: string): Promise<PenaltySearchDTO[]> {
    let url = `api/search/iiepenalties`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (name != null) {
      url += `${prefix}name=${encodeURIComponent(name)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, PenaltySearchDTO[]>(null, 'get', url, true, false);
  }

  // get: api/search/oiepenalties?name=${encodeURIComponent(name)}
  public getOiePenalties(name: string): Promise<PenaltySearchDTO[]> {
    let url = `api/search/oiepenalties`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (name != null) {
      url += `${prefix}name=${encodeURIComponent(name)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, PenaltySearchDTO[]>(null, 'get', url, true, false);
  }

  // get: api/search/otcpenalties?name=${encodeURIComponent(name)}
  public getOtcPenalties(name: string): Promise<PenaltySearchDTO[]> {
    let url = `api/search/otcpenalties`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (name != null) {
      url += `${prefix}name=${encodeURIComponent(name)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, PenaltySearchDTO[]>(null, 'get', url, true, false);
  }

  // get: api/search/pfdpenalties?name=${encodeURIComponent(name)}
  public getPfdPenalties(name: string): Promise<PenaltySearchDTO[]> {
    let url = `api/search/pfdpenalties`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (name != null) {
      url += `${prefix}name=${encodeURIComponent(name)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, PenaltySearchDTO[]>(null, 'get', url, true, false);
  }

  // get: api/search/dr2penalties?name=${encodeURIComponent(name)}
  public getDr2Penalties(name: string): Promise<PenaltySearchDTO[]> {
    let url = `api/search/dr2penalties`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (name != null) {
      url += `${prefix}name=${encodeURIComponent(name)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, PenaltySearchDTO[]>(null, 'get', url, true, false);
  }

  // get: api/search/vsrpenalties?name=${encodeURIComponent(name)}
  public getVsrPenalties(name: string): Promise<PenaltySearchDTO[]> {
    let url = `api/search/vsrpenalties`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (name != null) {
      url += `${prefix}name=${encodeURIComponent(name)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, PenaltySearchDTO[]>(null, 'get', url, true, false);
  }
}
var service = new SearchApiService();
export default service;
