// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
import BaseApi from './BaseApi';
import TableRequestDTO from '../models/TableRequestDTO';
import SchH2PropertySalesFormDTO from '../models/SchH2PropertySalesFormDTO';
import TableResponseDTO from '../models/TableResponseDTO';
import SchH2PropertySalesDTO from '../models/SchH2PropertySalesDTO';

export class SchH2PropertySalesApiService extends BaseApi {

  // post: api/schH2PropertySales/table?dr2Id=${encodeURIComponent(dr2Id)}
  public getSchH2PropertySalesList(tableRequest: TableRequestDTO, dr2Id: string): Promise<TableResponseDTO<SchH2PropertySalesDTO>> {
    let url = `api/schH2PropertySales/table`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (dr2Id != null) {
      url += `${prefix}dr2Id=${encodeURIComponent(dr2Id)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, TableResponseDTO<SchH2PropertySalesDTO>>(tableRequest, 'post', url, true, false);
  }

  // get: api/schH2PropertySales/${encodeURIComponent(propertySaleId)}/${seqNum}
  public getPropertySale(propertySaleId: string, seqNum: number): Promise<SchH2PropertySalesFormDTO> {
    let url = `api/schH2PropertySales/${encodeURIComponent(propertySaleId)}/${seqNum}`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, SchH2PropertySalesFormDTO>(null, 'get', url, true, false);
  }

  // post: api/schH2PropertySales/${encodeURIComponent(propertySaleId)}/history
  public getPropertySaleHistory(tableRequest: TableRequestDTO, propertySaleId: string): Promise<TableResponseDTO<SchH2PropertySalesDTO>> {
    let url = `api/schH2PropertySales/${encodeURIComponent(propertySaleId)}/history`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, TableResponseDTO<SchH2PropertySalesDTO>>(tableRequest, 'post', url, true, false);
  }

  // post: api/schH2PropertySales?committeeId=${encodeURIComponent(committeeId)}&dr2Id=${encodeURIComponent(dr2Id)}
  public add(newPropertySale: SchH2PropertySalesFormDTO, committeeId: string, dr2Id: string): Promise<void> {
    let url = `api/schH2PropertySales`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (committeeId != null) {
      url += `${prefix}committeeId=${encodeURIComponent(committeeId)}`;
      prefix = '&';
    }
    if (dr2Id != null) {
      url += `${prefix}dr2Id=${encodeURIComponent(dr2Id)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, void>(newPropertySale, 'post', url, true, false);
  }

  // put: api/schH2PropertySales?committeeId=${encodeURIComponent(committeeId)}&dr2Id=${encodeURIComponent(dr2Id)}&propertySaleId=${encodeURIComponent(propertySaleId)}&seqNum=${seqNum}
  public edit(newPropertySale: SchH2PropertySalesFormDTO, committeeId: string, dr2Id: string, propertySaleId: string, seqNum: number): Promise<void> {
    let url = `api/schH2PropertySales`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (committeeId != null) {
      url += `${prefix}committeeId=${encodeURIComponent(committeeId)}`;
      prefix = '&';
    }
    if (dr2Id != null) {
      url += `${prefix}dr2Id=${encodeURIComponent(dr2Id)}`;
      prefix = '&';
    }
    if (propertySaleId != null) {
      url += `${prefix}propertySaleId=${encodeURIComponent(propertySaleId)}`;
      prefix = '&';
    }
    if (seqNum != null) {
      url += `${prefix}seqNum=${seqNum}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, void>(newPropertySale, 'put', url, true, false);
  }

  // delete: api/schH2PropertySales?propertySaleId=${encodeURIComponent(propertySaleId)}&seqNum=${seqNum}
  public delete(propertySaleId: string, seqNum: number): Promise<void> {
    let url = `api/schH2PropertySales`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (propertySaleId != null) {
      url += `${prefix}propertySaleId=${encodeURIComponent(propertySaleId)}`;
      prefix = '&';
    }
    if (seqNum != null) {
      url += `${prefix}seqNum=${seqNum}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, void>(null, 'delete', url, true, false);
  }
}
var service = new SchH2PropertySalesApiService();
export default service;
