import { ColProps, FormProps, FormInstance, Row, Col, Typography } from 'antd';
import * as React from 'react';
import FilerType from '../../consts/FilerType';
import PersonInfoDTO from '../../models/PersonInfoDTO';
import DR1FormTypes from '../../consts/DR1FormTypes';
import AddressFormFields from './AddressFormFields';
import CustomForm from './CustomForm';

interface PersonInfoProps {
  filerType: FilerType;
  formType: DR1FormTypes;
  disabled?: boolean;
  personValues: PersonInfoDTO;
  goToTopOnLoad?: boolean;
}

interface PersonInfoState {
  personValues: PersonInfoDTO;
}

class PersonInfo extends React.Component<PersonInfoProps & FormProps, PersonInfoState> {
  private readonly _formRef = React.createRef<FormInstance>();

  constructor(props: PersonInfoProps & FormProps) {
    super(props);

    this.state = {
      personValues: this.props.personValues as PersonInfoDTO || PersonInfoDTO.create({ state: 'IA' }),
    };
  }

  validate = async (): Promise<{ model: PersonInfoDTO, errorFields: any }> => {
    try {
      const fields = await this._formRef.current?.validateFields();
      return { model: fields, errorFields: null };
    }
    catch (errorInfo) {
      return Promise.reject({ model: errorInfo.values, errorFields: errorInfo.errorFields });
    }
  }

  checkEmptyFields = (): boolean => {
    const model = this._formRef.current?.getFieldsValue();
    if (!model.firstName && !model.middleInitial && !model.lastName &&
      !model.email && !model.phone && !model.addressLine1 &&
      !model.addressLine2 && !model.city && !model.zipCode) {
      return true;
    }
    return false;
  }

  componentDidMount() {
    if (this.props.goToTopOnLoad) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    const { filerType, formType, disabled, ...formProps } = this.props;
    const { personValues } = this.state;
    const columnSizingProps: ColProps = { xs: 24, sm: 24, md: 16 };

    let pageTitle = '';
    let pageSubTitle = '';
    let emailReq = false;

    if (FilerType.CANDIDATE == filerType) {
      switch (formType) {
        case DR1FormTypes.CANDIDATE:
          pageTitle = DR1FormTypes.CANDIDATE;
          emailReq = true;
          break;
        case DR1FormTypes.CHAIRHOLDER:
          pageTitle = DR1FormTypes.CHAIRHOLDER + ' - Optional';
          emailReq = true;
          break;
        case DR1FormTypes.TREASURER:
          pageTitle = DR1FormTypes.TREASURER;
          emailReq = true;
          break;
      }
    }
    else if (FilerType.PAC == filerType) {
      switch (formType) {
        case DR1FormTypes.CHAIRHOLDER:
          pageTitle = DR1FormTypes.CHAIRHOLDER;
          emailReq = true;
          break;
        case DR1FormTypes.TREASURER:
          pageTitle = DR1FormTypes.TREASURER;
          emailReq = true;
          break;
      }
    }
    else if (FilerType.OSF == filerType) {
      switch (formType) {
        case DR1FormTypes.RESIDENTAGENT:
          pageTitle = DR1FormTypes.RESIDENTAGENT;
          pageSubTitle = 'An out of state or federal committee making a contribution to an ' +
          'Iowa committee is required to provide the name and address of an Iowa resident ' +
          'authorized to receive service of original notice on behalf of the out of state or federal committee.';
          break;
        case DR1FormTypes.CONTACT:
          pageTitle = DR1FormTypes.CONTACT;
          break;
      }
    }

    return (
      <CustomForm {...formProps}
        formRef={this._formRef}
        initialValues={personValues}
        layout="vertical">
        <Typography.Title level={3}>{pageTitle} </Typography.Title>
        <Row>
          <Col {...columnSizingProps} >
            {pageSubTitle && (
              <Typography.Title level={5}>{pageSubTitle} </Typography.Title>
            )}
            <AddressFormFields
              firstName={{ name: 'firstName', disabled: disabled }}
              middleInitial={{ name: 'middleInitial', disabled: disabled }}
              lastName={{ name: 'lastName', disabled: disabled }}
              address1={{ name: 'addressLine1', disabled: disabled }}
              address2={{ name: 'addressLine2', disabled: disabled }}
              city={{ name: 'city', disabled: disabled }}
              state={{ name: 'state', disabled: disabled }}
              zip={{ name: 'zipCode', disabled: disabled }}
              phone={{ name: 'phoneNumber', disabled: disabled }}
              email={{ name: 'email', required: emailReq, disabled: disabled }}
            />
          </Col>
        </Row>
      </CustomForm >
    );
  }
}

export default PersonInfo;