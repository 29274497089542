import { Col, Row, Typography, Input, ColProps, FormInstance, Layout, Modal, notification, Space, Button} from 'antd';
import * as React from 'react';
import FormItem from 'antd/lib/form/FormItem';
import FormValidationUtil from '../../utils/FormValidationUtil';
import Routes from '../../consts/Routes';
import HistoryUtil from '../../utils/HistoryUtil';
import AdminConsentForSalesAndLeasesApiService from '../../api/AdminConsentForSalesAndLeasesApiService';
import CustomForm from '../shared/CustomForm';
import CustomDatePicker from '../shared/CustomDatePicker';
import CurrencyInput from '../shared/CurrencyInput';
import TextArea from 'antd/lib/input/TextArea';
import { RouteComponentProps } from 'react-router';
import FileUpload, { FileUploadValue } from '../shared/FileUpload';
import moment from 'moment';
import FileUploadUtil from '../../utils/FileUploadUtil';
import AdminConsentForSalesAndLeasesFormDTO from '../../models/AdminConsentForSalesAndLeasesFormDTO';

const { Content } = Layout;
const { confirm } = Modal;

interface EditManageConsentForSalesAndLeasesProps {
    cfslId: string | undefined

}
interface EditManageConsentForSalesAndLeasesState {
    loading: boolean;
    cfslId?: string;
    fileName: string;
    fileLabel: string;
    uploadFormDTO: AdminConsentForSalesAndLeasesFormDTO;
}

class EditManageConsentForSalesAndLeases extends React.Component<RouteComponentProps<EditManageConsentForSalesAndLeasesProps>,
    EditManageConsentForSalesAndLeasesState> {
    private readonly _formRef = React.createRef<FormInstance>();

    constructor(props: RouteComponentProps<EditManageConsentForSalesAndLeasesProps>) {
        super(props);
        this.state = {
            loading: false,
            cfslId: props.match.params.cfslId || '',
            fileName: '',
            fileLabel: 'Consent Document:',
            uploadFormDTO: AdminConsentForSalesAndLeasesFormDTO.create({ saleDate: null })
        };
    }

    componentDidMount() {
        this.loadInitialValues();
    }

    render() {
        const columnSizingProps: ColProps = { xs: 24, sm: 24, md: 12 };
        const { loading, cfslId, uploadFormDTO } = this.state;

        return (
            <>
                {!loading &&
                    <Content className="content-pad">
                        <Typography.Title level={4}> {!cfslId ? 'Add Consent for Sales/Leases' : 'Edit Consent for Sales/Leases'} </Typography.Title>
                        <CustomForm
                            formRef={this._formRef}
                            layout="vertical"
                            initialValues={uploadFormDTO}
                            onFinish={this.submitForm}
                            validateTrigger={['onChange', 'onBlur']}>
                            <FormItem name="id" hidden>
                                <Input disabled hidden aria-hidden />
                            </FormItem>
                            <Row gutter={24}>
                                <Col {...columnSizingProps}>
                                    <FormItem
                                        name="firstName"
                                        label="First Name:"
                                        rules={[
                                            FormValidationUtil.Required('First Name is required'),
                                        ]}>
                                        <Input></Input>
                                    </FormItem>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col {...columnSizingProps}>
                                    <FormItem
                                        name="middleInitial"
                                        label="Middle Initial:">
                                        <Input maxLength={1}></Input>
                                    </FormItem>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col {...columnSizingProps}>
                                    <FormItem
                                        name="lastName"
                                        label="Last Name:"
                                        rules={[
                                            FormValidationUtil.Required('Last Name is required'),
                                        ]}>
                                        <Input></Input>
                                    </FormItem>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col {...columnSizingProps}>
                                    <FormItem
                                        name="agency"
                                        label="Agency Name:"
                                        rules={[
                                            FormValidationUtil.Required('Agency Name is required'),
                                        ]}>
                                        <Input></Input>
                                    </FormItem>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col {...columnSizingProps}>
                                    <FormItem
                                        name="saleDate"
                                        label="Date of Sale"
                                        rules={[
                                            FormValidationUtil.RequiredDate('Date of Sale is required')
                                        ]}>
                                        <CustomDatePicker onChange={(d) => d?.startOf('day')} />
                                    </FormItem>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col {...columnSizingProps}>
                                    <FormItem
                                        name="saleAmount"
                                        label="Amount of Sale:"
                                        rules={[
                                            FormValidationUtil.PositiveNumber('Amount of Sale is required'),
                                            FormValidationUtil.RequiredNumber('Amount of Sale is required'),
                                        ]}>
                                        <CurrencyInput></CurrencyInput>
                                    </FormItem>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col {...columnSizingProps} >
                                    <FormItem
                                        name="saleDescription"
                                        label="Description of Sale:"
                                        rules={[
                                            FormValidationUtil.Required('Description of Sale is required'),
                                        ]}>
                                        <TextArea maxLength={256} showCount={true}></TextArea>
                                    </FormItem>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col {...columnSizingProps} >
                                    <FormItem name="file" label={this.state.fileLabel}>
                                        <FileUpload
                                            onChange={x => this.check(x)}
                                            maxCount={1}
                                            fileType=".pdf"
                                            accept="application/pdf"
                                            fileSize={20}
                                        />
                                    </FormItem>
                                </Col>
                            </Row>
                            <Space>
                                <Button type="primary" htmlType="submit"> Save </Button>
                                <Button type="default" onClick={this.confirmCancel}> Cancel </Button>
                            </Space>
                        </CustomForm>
                    </Content>
                }
            </>
        );
    }

    private submitForm = (values: any) => {
        let files = this._formRef.current?.getFieldValue('file')?.newFiles;
        if (!files && !(this.state.fileName)) {
            notification.info({
                message: 'Please select a file.'
            });

            return;
        }

        this.setState({ loading: true });
        values.id = Number(this.state.cfslId);
        let request = new FormData();
        const submittedForm = { ...this.state.uploadFormDTO, ...values };
        submittedForm.file = [];

        if (files) {
            request = FileUploadUtil.attatchFilesToModel('request', submittedForm, 'file', files);
        }
        else {
            files = '';
            request = FileUploadUtil.attatchFileToModel('request', submittedForm, 'file', files);
        }

        AdminConsentForSalesAndLeasesApiService.upload(request as FormData)
            .then(() => {
                this.setState({ loading: false });
                notification.success({ message: 'Successfully saved Consent for Sales/Leases filing.' });
                HistoryUtil.push(Routes.MANAGE_CONSENT_FOR_SALES_AND_LEASES);
            }).catch(() => {
                this.setState({ loading: false });
                notification.error({ message: 'Failed to save Consent for Sales/Leases filing.' });
            });
    }

    private confirmCancel = () => {
        confirm({
            title: 'Are you sure you want to leave?',
            okText: 'Yes',
            cancelText: 'No',
            onOk: () => {
                HistoryUtil.push(Routes.generate(Routes.MANAGE_CONSENT_FOR_SALES_AND_LEASES));
            }
        });
    }

    private loadInitialValues = () => {
        if (this.state.cfslId) {
            this.setState({ loading: true });
            AdminConsentForSalesAndLeasesApiService.getConsentForSalesAndLeasesForm(Number(this.state.cfslId))
                .then(uploadFormDTO => {
                    uploadFormDTO.saleDate = moment(uploadFormDTO.saleDate);

                    this.setState({
                        uploadFormDTO,
                        fileLabel: 'Consent Document: ' + uploadFormDTO.userFileName,
                        fileName: uploadFormDTO.userFileName || '',
                        loading: false
                    });
                }).catch(() => {
                    notification.error({ message: 'Failed to load Consent for Sales/Leases.' });
                    this.setState({ loading: false });
                });
        }
    }

    private check = (x: FileUploadValue) => {
        if (x.newFiles[0] != null && x.newFiles[0].type != 'application/pdf') {
            x.newFiles[0] = null;
            HistoryUtil.push(Routes.MANAGE_CONSENT_FOR_SALES_AND_LEASES);
            notification.error({ message: 'Failed to save Consent for Sales/Leases filing.' });
        }
    }
}
export default EditManageConsentForSalesAndLeases;