// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
import BaseApi from './BaseApi';
import TableRequestDTO from '../models/TableRequestDTO';
import TableResponseDTO from '../models/TableResponseDTO';
import AdminPetitionForWaiverDTO from '../models/AdminPetitionForWaiverDTO';
import PetitionForWaiverDTO from '../models/PetitionForWaiverDTO';

export class AdminPetitionForWaiverApiService extends BaseApi {

  // post: api/admin/petitionWaiver/table
  public getPetitionForWaiverList(tableRequest: TableRequestDTO): Promise<TableResponseDTO<AdminPetitionForWaiverDTO>> {
    let url = `api/admin/petitionWaiver/table`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, TableResponseDTO<AdminPetitionForWaiverDTO>>(tableRequest, 'post', url, true, false);
  }

  // get: api/admin/petitionWaiver/${id}
  public getPetitionForWaiver(id: number): Promise<PetitionForWaiverDTO> {
    let url = `api/admin/petitionWaiver/${id}`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, PetitionForWaiverDTO>(null, 'get', url, true, false);
  }

  // delete: api/admin/petitionWaiver/${id}
  public deletePetitionForWaiver(id: number): Promise<void> {
    let url = `api/admin/petitionWaiver/${id}`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, void>(null, 'delete', url, true, false);
  }
}
var service = new AdminPetitionForWaiverApiService();
export default service;
