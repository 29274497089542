import { store } from '../redux/Store';
import { UserSessionActions } from '../redux/actions/UserSessionActions';
import UserSecurity from '../models/UserSecurity';
import UserSecurityDTO from '../models/UserSecurityDTO';

interface CommitteeFields {
  committeeId?: string | null,
  committeeName: string | null,
  committeeTypeName: string | null,
  committeeType: string | null,
  filerTypeId: number | null,
  dr2Id?: string | null,
  auditing: boolean,
  committeeStatusType?: string | null,
  committeeFilingCatType: string | null,
  hasPenalties?: boolean | null, 
}

export default class CurrentUser {
  public static Get(): UserSecurity | null {
    return store.getState().UserSession.Value;
  }

  public static Impersonate(committeeFields: CommitteeFields): UserSecurity {
    const userSecurityDTO = UserSecurityDTO.create({
      ...CurrentUser.Get(),
      committeeId: committeeFields.committeeId,
      committeeName: committeeFields.committeeName || null,
      committeeTypeName: committeeFields.committeeTypeName || null,
      committeeType: committeeFields.committeeType || null,
      filerTypeId: committeeFields.filerTypeId || null,
      dr2Id: committeeFields.dr2Id || null,
      auditing: true,
      committeeStatusType: committeeFields.committeeStatusType || null,
      committeeFilingCatType: committeeFields.committeeFilingCatType,
      hasPenalities: committeeFields.hasPenalties, 
    });

    const userSecurity = new UserSecurity(userSecurityDTO);
    store.dispatch(UserSessionActions.update(userSecurity));
    return userSecurity;
  }

  public static ChangeDr2(dr2Id: string | null): UserSecurity {
    const userSecurityDTO = UserSecurityDTO.create({
      ...CurrentUser.Get(),
      dr2Id
    });
    const userSecurity = new UserSecurity(userSecurityDTO);
    store.dispatch(UserSessionActions.update(userSecurity));
    return userSecurity;
  }

  public static UpdateHasPenaltie(hasPenalties: boolean | null): UserSecurity {
    const userSecurityDTO = UserSecurityDTO.create({
      ...CurrentUser.Get(),
      hasPenalties
    });
    const userSecurity = new UserSecurity(userSecurityDTO);
    store.dispatch(UserSessionActions.update(userSecurity));
    return userSecurity;
  }

  public static Release(): UserSecurity {
    const userSecurityDTO = UserSecurityDTO.create({
      ...CurrentUser.Get(),
      committeeId: null,
      committeeName: null,
      committeeTypeName: null,
      committeeType: null,
      filerTypeId: null,
      auditing: false,
      committeeStatusType: null,
      committeeFilingCatType: null,
      hasPenalitie: null, 
    });

    const userSecurity = new UserSecurity(userSecurityDTO);
    store.dispatch(UserSessionActions.update(userSecurity));
    return userSecurity;
  }
}