import {
    Button,
    Space,
    Typography
} from 'antd';
import React from 'react';
import DataTableButtonUtil from '../../shared/DataTable/DataTableButtonUtil';
import { Content } from 'antd/lib/layout/layout';
import DataTable, { DataTableColumnProps } from '../../shared/DataTable/DataTable';
import AdminEscheatDTO from '../../../models/AdminEscheatDTO';
import DataTableColumnUtil from '../../shared/DataTable/DataTableColumnUtil';
import PublicReportsApiService from '../../../api/PublicReportsApiService';
import HistoryUtil from '../../../utils/HistoryUtil';
import { ArrowLeftOutlined } from '@ant-design/icons';
import Routes from '../../../consts/Routes';

interface EscheatReportsProps {
}

interface EscheatReportsState {
}

class EscheatReports extends React.Component<EscheatReportsProps, EscheatReportsState> {
    private dataTable: DataTable<AdminEscheatDTO> | undefined;

    constructor(props: EscheatReportsProps) {
        super(props);
    }

    render() {
        const actionButtons = [];
        actionButtons.push(DataTableButtonUtil.Reset());

        return (
            <Content className="content-pad">
                <DataTable
                    ref={(element: any) => (this.dataTable = element)}
                    serverSide={true}
                    tableProps={{
                        rowKey: 'Id',
                        sortDirections: ['descend', 'ascend']
                    }}
                    globalSearch={true}
                    buttonBar={actionButtons}
                    columns={this.getEscheatsTableColumns()}
                    styleOptions={{ compact: true, alternatingRowHighlight: true }}
                    fetchData={{
                        fetch: function (tableRequest) { return PublicReportsApiService.getEscheatReports(tableRequest); },
                        failureMessage: 'Failed to retrieve Escheat Data'
                    }}
                    title={
                        <Button type="link" onClick={() => HistoryUtil.push(Routes.generate(Routes.PUBLIC_REPORTS))}>
                            <Typography.Title level={4}>
                                <Space size="large">
                                    <ArrowLeftOutlined className="public-report-links" /> Escheats
                                </Space>
                            </Typography.Title>
                        </Button>
                    }
                />
            </Content>
        );
    }

    private getEscheatsTableColumns = (): DataTableColumnProps<AdminEscheatDTO>[] => {
        const columns: DataTableColumnProps<AdminEscheatDTO>[] = [];

        columns.push(DataTableColumnUtil.Text('Committee Or Individual', 'name'));
        columns.push(DataTableColumnUtil.Date('Filed Date', 'filedDate', null, { defaultSortOrder: 'descend' }));
        columns.push(DataTableColumnUtil.Currency('Amount', 'amount'));

        return columns;
    }
}

export default EscheatReports;