import { notification, Spin, Typography } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import moment from 'moment';
import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import DissolutionApiService from '../../api/DissolutionApiService';
import Dr2ApiService from '../../api/Dr2ApiService';
import FilingPeriodHistoryApiService from '../../api/FilingPeriodHistoryApiService';
import Routes from '../../consts/Routes';
import FilingPeriodDTO from '../../models/FilingPeriodDTO';
import CurrentUser from '../../utils/CurrentUser';
import DateUtil from '../../utils/DateUtil';
import HistoryUtil from '../../utils/HistoryUtil';
import DissolutionPage from '../dissolution/DissolutionPage';
import Stepper, { Step } from '../shared/Stepper';
import NewFilingPeriodTable from './NewFilingPeriodTable';
import OrganizationApiService from '../../api/OrganizationApiServices';
import DR1DTO from '../../models/DR1DTO';
import CommitteeTypeDTO from '../../models/CommitteeTypeDTO';
import ElectionInfoModal from './ElectionInfoModal';
import ElectionInfoRequiredUtil from '../../utils/ElectionInfoRequiredUtil';
import ElectionYear from '../../consts/ElectionYear';
import FilerType from '../../consts/FilerType';

interface DeleteFilingPeriodProps {
}

interface DeleteFilingPeriodState {
  filingPeriod: FilingPeriodDTO;
  currentStepKey: DeleteFilingStep;
  completedSteps: boolean[];
  dissolveCommittee: boolean;
  filingPeriodName: string;
  currDr2Id: string;
  saving: boolean;
  dr1: DR1DTO;
  loadingDR1Data: boolean;
  showElectionModal: boolean;
  commtype: CommitteeTypeDTO;
  isIncumbent: boolean | null;
}

enum DeleteFilingStep {
  STEP1,
  STEP2,
}


class DeleteFilingPeriod extends React.Component<RouteComponentProps<DeleteFilingPeriodProps>, DeleteFilingPeriodState>{
  private _newFilingPereiodTableRef: NewFilingPeriodTable | null = null;
  private _nextRequestedStepKey?: DeleteFilingStep;
  private steps: Step[] = [
    {
      key: DeleteFilingStep.STEP1,
      title: 'Select New Filing Period',
      hideNext: true,
    },
    {
      key: DeleteFilingStep.STEP1,
      title: 'Review',
    },
  ];

  constructor(props: RouteComponentProps<DeleteFilingPeriodProps>) {
    super(props);
    this.state = {
      filingPeriod: FilingPeriodDTO.create(),
      currentStepKey: DeleteFilingStep.STEP1,
      completedSteps: [false, false],
      dissolveCommittee: false,
      filingPeriodName: (this.props.location?.state as DeleteFilingPeriodState).filingPeriodName,
      currDr2Id: (this.props.location?.state as DeleteFilingPeriodState).currDr2Id,
      saving: false,
      dr1: DR1DTO.create(),
      loadingDR1Data: false,
      showElectionModal: false,
      commtype: CommitteeTypeDTO.create(),
      isIncumbent: false,
    };
  }

  componentDidMount = () => {
    this.loadDR1Data();
  }

  render() {
    const {
      currentStepKey,
      dissolveCommittee,
      filingPeriod,
      saving,
      dr1,
      loadingDR1Data,
      showElectionModal,
      commtype,
      currDr2Id
    } = this.state;
    const showElectionDate = ElectionInfoRequiredUtil.showElectionDate(commtype?.code || '');
    const electionDateRequired = ElectionInfoRequiredUtil.electionDateRequired(commtype?.code || '');
    let currentStep: React.ReactElement<any>;

    switch (currentStepKey) {
      case DeleteFilingStep.STEP1:
        currentStep = (
          <>
            <NewFilingPeriodTable
              selectFiling={true}
              deleteFiling={true}
              dr2Id={currDr2Id}
              onChange={this.handleSelectFiling}
              onDissolve={this.handleDissolve} />
            {(showElectionModal && (showElectionDate || CurrentUser.Get()?.filerTypeId == FilerType.CANDIDATE)) &&
              <ElectionInfoModal
              onClose={() => this.setState({ showElectionModal: false })}
              dateRequired={(showElectionDate && electionDateRequired)}
              dr1={dr1}
              dr2Id={currDr2Id}
              onSubmit={this.handleSubmit}
              filingPeriod={this.state.filingPeriod.electionType}            />
            }
          </>);
        break;

      case DeleteFilingStep.STEP2:
        currentStep =
          <Spin tip="Submitting" spinning={saving}>
            {dissolveCommittee ?
              <>
                <DissolutionPage inDr2Submit={true} />
              </> : <>
                <Typography.Text> The following filing will be permanently deleted: </Typography.Text>
                <br />
                <strong>&emsp;&emsp;{this.state.filingPeriodName}.</strong>
                <br/><br/>
                <Typography.Text>Please confirm new filing period:</Typography.Text>
                <br />
                <strong>&emsp;&emsp;{filingPeriod.description?.trimEnd()}.</strong>
                <br/><br/>
              </>
            }
          </Spin>;
        break;

      default:
        currentStep = <>Error</>;
        break;
    }
    return (
      <Content className="content-pad">
        <Typography.Title level={4}> Delete Filing </Typography.Title>
        {!loadingDR1Data && (
          <>
            <Stepper
              steps={this.steps}
              currentStepKey={currentStepKey}
              allowStepClick={true}
              onStepChangeRequested={this.handleStepChangeRequested}
              onSaveRequested={this.handleSave}
              minVerticalStepWidth={250}
            >
              {currentStep}
            </Stepper> </>)
        }
      </Content>
    );
  }

  private handleSubmit = (dr1: DR1DTO, isIncumbent: boolean | null) => {
    this.setState({ isIncumbent});
    this.goToStep(DeleteFilingStep.STEP2);
    this.setState({showElectionModal: false});
  }

  private handleSave = () => {
    this.setState({ saving: true });
    FilingPeriodHistoryApiService.deleteFph(this.state.currDr2Id || '', CurrentUser.Get()?.committeeId || '')
      .then(() => {
        if (this.state.dissolveCommittee) {
          DissolutionApiService.dissolveWithNoCommCheck(
            CurrentUser.Get()?.committeeId || '',
            DateUtil.toShortDateString(moment())).then((result) => {
              if (result.succeeded) {
                notification.success({ message: 'Committee was dissolved successfully.' });
                Dr2ApiService.getLastFiledDr2Id(CurrentUser.Get()?.committeeId || '')
                  .then((dr2Id) => {
                    CurrentUser.ChangeDr2(dr2Id);
                    HistoryUtil.push(Routes.generate(Routes.SCHEDULE_SUMMARY));
                  });
              }
              else {
                notification.error({ message: result.errors[0] });
              }
            }).catch(() => {
              notification.error({ message: 'Failed to dissolve committee.' });
            });
        }
        else {
          const isIncumbent = this.state.isIncumbent;
          Dr2ApiService.saveNewFilingPeriod(this.state.filingPeriod.periodMasterId, CurrentUser.Get()?.committeeId || '',
                                            this.state.currDr2Id || '', isIncumbent)
            .then((result) => {
              if (result.succeeded) {
                notification.success({
                  message: 'Committee filing successfully created'
                });
                Dr2ApiService.getLastFiledDr2Id(CurrentUser.Get()?.committeeId || '')
                  .then((dr2Id) => {
                    CurrentUser.ChangeDr2(dr2Id);
                    HistoryUtil.push(Routes.generate(Routes.SCHEDULE_SUMMARY));
                  });
              }
              else {
                notification.error({ message: result.errors[0] });
              }
            }).catch(() => {
              notification.error({
                message: 'Error while creating committee filing'
              });
            });
        }
        this.setState({ saving: false });
      });
  }

  private goToStep = (stepKey: DeleteFilingStep) => {
    this.setState({ currentStepKey: stepKey });
  }

  private setComplete = (stepKey: DeleteFilingStep) => {
    const completed = this.state.completedSteps;
    completed[stepKey] = true;
    this.setState({ completedSteps: completed });
  }

  private handleStepChangeRequested = (requestedStep: Step) => {
    this._nextRequestedStepKey = requestedStep.key;
    switch (this.state.currentStepKey) {
      case DeleteFilingStep.STEP1:
        if (!this._newFilingPereiodTableRef) {
          return;
        }

        if (requestedStep.key == DeleteFilingStep.STEP2) {
          this.setComplete(DeleteFilingStep.STEP1);
          this.goToStep(requestedStep.key);;
          return;
        }

        return;

      case DeleteFilingStep.STEP2:
        if (this.state.currentStepKey > requestedStep.key) {
          this.setComplete(DeleteFilingStep.STEP2);
          this.goToStep(requestedStep.key);
          break;
        }

        return;

      default:
        return;
    }
  }

  private handleDissolve = () => {
    DissolutionApiService.checkCommDissolution(CurrentUser.Get()?.committeeId || '', this.state.currDr2Id).then((result) => {
      if (result.succeeded) {
        this.setState({ dissolveCommittee: true });
        this.setComplete(DeleteFilingStep.STEP1);
        this.goToStep(DeleteFilingStep.STEP2);
      }
      else {
        notification.error({ message: result.errors[0] });
      }
    }).catch(() => {
      notification.error({ message: 'Failed to check if committee can dissolve' });
    });
  }

  private handleSelectFiling = (filingPeriod: FilingPeriodDTO) => {
    const showElectionDate = ElectionInfoRequiredUtil.showElectionDate(this.state.commtype?.code || '');
    if ((filingPeriod.electionType == ElectionYear.ELECTION || filingPeriod.electionType == ElectionYear.SPECIAL)
      && (showElectionDate || CurrentUser.Get()?.filerTypeId == FilerType.CANDIDATE)) {
      this.setState({ showElectionModal: true, filingPeriod });
    }
    else {
      this.setState({ filingPeriod, dissolveCommittee: false });
      this.goToStep(DeleteFilingStep.STEP2);
    }
  }

  private loadDR1Data = () => {
    this.setState({ loadingDR1Data: true });
    OrganizationApiService
      .getDr1OrgDetails(CurrentUser.Get()?.committeeId || '', DateUtil.toTimeStamp(moment()), CurrentUser.Get()?.filerTypeId || 0)
      .then(dr1 => {
        if (dr1.committeeInfo) {
          if (dr1.committeeInfo.electionDate) {
            dr1.committeeInfo.electionDate = moment(dr1.committeeInfo.electionDate);
          }
          if (dr1.committeeInfo.type) {
            this.setState({ commtype: CommitteeTypeDTO.create({ code: dr1.committeeInfo.type }) });
          }
        }
        this.setState({ dr1, loadingDR1Data: false });
      }).catch(() => {
        notification.error({ message: 'Failed to load DR1 data.' });
        this.setState({ loadingDR1Data: false });
      });
  }

}

export default DeleteFilingPeriod;
