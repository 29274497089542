import {
  Button,
  Col,
  ColProps,
  Descriptions,
  Divider,
  Layout,
  notification,
  Row,
  Space,
  Typography
} from 'antd';
import * as React from 'react';
import { connect } from 'react-redux';
import Dr2ApiService from '../../api/Dr2ApiService';
import NoteEntityParents from '../../consts/NoteEntityParents';
import Routes from '../../consts/Routes';
import Dr2SummaryDTO from '../../models/Dr2SummaryDTO';
import { StateStoreModel } from '../../redux/state/StateStoreModel';
import CurrentUser from '../../utils/CurrentUser';
import HistoryUtil from '../../utils/HistoryUtil';
import NotesModal from '../shared/NotesModal';
import Dr2StatusBar from './Dr2StatusBar';
import NewFilingPeriodTable from './NewFilingPeriodTable';
import NumberFormatUtil from '../../utils/NumberFormatUtil';
import FilerType from '../../consts/FilerType';
import VsrContributions from '../contribution/VsrContributions';
import Colors from '../../consts/Colors';
import FilingStatusType from '../../consts/FilingStatusType';

const { Content } = Layout;
const { Item } = Descriptions;

interface Dr2SummaryProps {
  showDr2NotFound: boolean;
  dr2Id: string;
}

interface Dr2SummaryState {
  summaryInfo: Dr2SummaryDTO;
  committeeId: string;
  dr2Id: string;
  filerType: number;
}

class Dr2Summary extends React.Component<Dr2SummaryProps, Dr2SummaryState> {
  constructor(props: Dr2SummaryProps) {
    super(props);
    this.state = {
      committeeId: CurrentUser.Get()?.committeeId || '',
      filerType: CurrentUser.Get()?.filerTypeId || 0,
      dr2Id: CurrentUser.Get()?.dr2Id || '',
      summaryInfo: Dr2SummaryDTO.create(),
    };
  }

  componentDidMount() {
    if (this.state.dr2Id) {
      this.getSummary(this.state.dr2Id);
    }
  }

  render() {
    const { showDr2NotFound, dr2Id } = this.props;
    const { summaryInfo, committeeId, filerType } = this.state;
    const columnSizingProps: ColProps = { xs: 24, sm: 24, md: 12 };

    return (
      <Content className="content-pad" key={dr2Id}>
        {dr2Id && (
          <>
            <Dr2StatusBar dr2Id={dr2Id} onAdjustBalance={() => this.getSummary(dr2Id)} />
            <Row gutter={24}>
              <Col {...columnSizingProps}>
                <Descriptions title="Summary" bordered={true} size="small">
                  <Item label="Starting Balance"
                    span={3}
                    style={{ backgroundColor: Colors.IOWA_BLUE, color: 'white', fontSize: 'large' }}
                  >
                    {NumberFormatUtil.currency(summaryInfo.startingBalance)}
                  </Item>
                  <Item label={this.label('Schedule A: Cash Contributions', summaryInfo.schA_isAmended, true)} span={3}>
                    {NumberFormatUtil.currency(summaryInfo.cashContributions)}
                  </Item>
                  <Item label={this.label('Schedule F1: Loans Received', summaryInfo.schF1_isAmended, true)} span={3}>
                    {NumberFormatUtil.currency(summaryInfo.loansReceived)}
                  </Item>
                  <Item label={this.label('Schedule H2: Campaign Property Sales', summaryInfo.schH2_isAmended, true)} span={3}>
                    {NumberFormatUtil.currency(summaryInfo.campaignPropertySales)}
                  </Item>
                  <Item label="Subtotal" span={3} style={{ fontWeight: 'bold', fontSize: 'medium' }}>
                    {NumberFormatUtil.currency(summaryInfo.subtotal)}
                  </Item>
                  <Item label={this.label('Schedule B: Expenditures', summaryInfo.schB_isAmended, true)} span={3}>
                    {NumberFormatUtil.currency(summaryInfo.expenditures)}
                  </Item>
                  <Item label={this.label('Schedule F2: Loan Repayments', summaryInfo.schF2_isAmended, true)} span={3}>
                    {NumberFormatUtil.currency(summaryInfo.loanRepayments)}
                  </Item>
                  <Item label="Ending Balance"
                    span={3}
                    style={{ backgroundColor: Colors.IOWA_BLUE, color: 'white', fontSize: 'large' }}
                  >
                    {NumberFormatUtil.currency(summaryInfo.endingBalance)}
                  </Item>
                </Descriptions>
                <br /><br />
                <Typography.Text style={{ color: Colors.PRIMARY }}>* = Amended</Typography.Text>
              </Col>
              <Col {...columnSizingProps}>
                <Descriptions title="General Assets and Liabilities" bordered={true} size="small">
                  <Item label="Schedule F1: Loans at Start of Period" span={3}>
                    {NumberFormatUtil.currency(summaryInfo.loansAtStartOfPeriod)}</Item>
                  <Item label={this.label('Schedule D: Unpaid Bills', summaryInfo.schD_isAmended)} span={3}>
                    {NumberFormatUtil.currency(summaryInfo.unpaidBills)}
                  </Item>
                  <Item label={this.label('Schedule E: In Kind Contributions', summaryInfo.schE_isAmended)} span={3}>
                    {NumberFormatUtil.currency(summaryInfo.inKindContributions)}
                  </Item>
                  <Item label="Schedule F2: Forgiven Loans" span={3}>
                    {NumberFormatUtil.currency(summaryInfo.forgivenLoans)}
                  </Item>
                  <Item label="Schedule F2: Outstanding Loans" span={3}>
                    {NumberFormatUtil.currency(summaryInfo.outstandingLoans)}
                  </Item>
                  <Item label={this.label('Schedule G: Consultant Breakdown?', summaryInfo.schG_isAmended)} span={3}>
                    {summaryInfo.consultantBreakdown ? 'Yes' : 'No'}
                  </Item>
                  <Item label={this.label('Schedule H1: Campaign Property Value', summaryInfo.schH1_isAmended)} span={3}>
                    {NumberFormatUtil.currency(summaryInfo.campaignPropertyValue)}
                  </Item>
                </Descriptions>
                <br />
                <Space>
                  {summaryInfo.filingStatusCode === FilingStatusType.NOT_FILED &&
                    <Button type="primary" onClick={() => this.fileReport(committeeId)}>File Report Here</Button>
                  }
                  <NotesModal parentId={dr2Id} parent={NoteEntityParents.DR2} auditorsNoteId={summaryInfo.auditorsNoteId || ''} />
                </Space>
              </Col>
            </Row>
            <Divider />
          </>
        )}
        <Row>
          {showDr2NotFound &&
            (filerType != FilerType.OSF ? <NewFilingPeriodTable /> : <VsrContributions />)
          }
        </Row>
      </Content>
    );
  }

  private getSummary = (dr2Id: string) => {
    Dr2ApiService.getDr2Summary(dr2Id)
      .then(result => {
        this.setState({ summaryInfo: result });
      }).catch(() => {
        notification.error({
          message: 'Error getting summary information',
          description: ''
        });
      });
  }

  private label = (display: string, isAmended: boolean, indent?: boolean): any => {
    return (
      <>
        <Typography.Text className={indent ? 'indent' : undefined}>{display}</Typography.Text>
        {isAmended && (
          <Typography.Text type="danger">&nbsp;*</Typography.Text>
        )}
      </>
    );
  }

  private fileReport = (committeeId: string) => {
    HistoryUtil.push(Routes.generate(Routes.DR2_SUBMIT_STEPPER, { id: committeeId }));
  }
}

function mapDispatchToProps() {
  return {};
}

function mapStateToProps(state: StateStoreModel) {
  return {
    dr2Id: state.UserSession.Value?.dr2Id || '',
    showDr2NotFound: state.UserSession.Value?.dr2Id ? false : true
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Dr2Summary);