import {
  Col,
  ColProps,
  FormInstance,
  FormProps,
  Input,
  InputNumber,
  Row,
  Select,
  Typography
} from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import TextArea from 'antd/lib/input/TextArea';
import * as React from 'react';
import LookupsApiService from '../../api/LookupsApiService';
import Dr1ApiService from '../../api/Dr1ApiService';
import CommitteeType from '../../consts/CommitteeType';
import FilerType from '../../consts/FilerType';
import FormInputLen from '../../consts/FormInputLengths';
import CodeLookupTableDTO from '../../models/CodeLookupTableDTO';
import CommitteeInfoDTO from '../../models/CommitteeInfoDTO';
import CommitteeTypeDTO from '../../models/CommitteeTypeDTO';
import FormValidationUtil from '../../utils/FormValidationUtil';
import CustomDatePicker from './CustomDatePicker';
import CustomForm from './CustomForm';
import ElectionInfoRequiredUtil from '../../utils/ElectionInfoRequiredUtil';

const Option = Select.Option;

interface CommitteeInfoProps {
  committeeInfo: CommitteeInfoDTO;
  filerType: FilerType;
  disabled?: boolean;
  filterTypes?: boolean;
  goToTopOnLoad?: boolean;
}

interface CommitteeInfoState {
  committeeTypes: CommitteeTypeDTO[];
  selectedCommitteeType?: CommitteeTypeDTO | null;
  politicalParties: CodeLookupTableDTO[];
  counties: CodeLookupTableDTO[];
  electionYears: number[];
  screenTitle?: string | null;
}

class CommitteeInfo extends React.Component<CommitteeInfoProps & FormProps, CommitteeInfoState> {
  private readonly _formRef = React.createRef<FormInstance>();

  constructor(props: CommitteeInfoProps & FormProps) {
    super(props);

    const electionYears = ElectionInfoRequiredUtil.getElectionYears();

    this.state = {
      committeeTypes: [],
      selectedCommitteeType: this.props.committeeInfo.type ? CommitteeTypeDTO.create({ code: this.props.committeeInfo.type }) : null,
      politicalParties: [],
      counties: [],
      electionYears,
      screenTitle: ''
    };
  }

  componentDidMount() {
    if (this.props.goToTopOnLoad) {
      window.scrollTo(0, 0);
    }
    this.loadLookups();
  }

  validate = async (): Promise<{ model: CommitteeInfoDTO, errorFields: any }> => {
    try {
      const fields = await this._formRef.current?.validateFields();
      return { model: fields, errorFields: null };
    }
    catch (errorInfo) {
      return Promise.reject({ model: errorInfo.values, errorFields: errorInfo.errorFields });
    }
  }

  getEmail = () => {
    return this._formRef.current?.getFieldValue('committeeEmail');
  }
  render() {
    const { filerType, committeeInfo, disabled, filterTypes, ...formProps } = this.props;
    const { committeeTypes, politicalParties, counties, electionYears, selectedCommitteeType } = this.state;
    const columnSizingProps: ColProps = { xs: 24, sm: 24, md: 12 };

    const showOfficeSought = filerType === FilerType.CANDIDATE;
    const showElectionYear = filerType === FilerType.CANDIDATE;
    let showElectionDate = filerType === FilerType.CANDIDATE;
    const showDistrictNum = filerType === FilerType.CANDIDATE;
    const descriptionRequired = filerType !== FilerType.CANDIDATE;
    if (selectedCommitteeType && ElectionInfoRequiredUtil.showElectionDate(selectedCommitteeType?.code || '')){
      showElectionDate = true;
    }
    const electionDateRequired = ElectionInfoRequiredUtil.electionDateRequired(selectedCommitteeType?.code || '');
    let cpyCommitteeTypes = committeeTypes.map(a => a);
    if (committeeInfo) {
      const commType = committeeTypes.filter(d => d.code == committeeInfo.committeeTypeCd)[0];
      if (filterTypes) {
        cpyCommitteeTypes = cpyCommitteeTypes.filter(d => d.commFilingPeriodCatTypeCode == commType.commFilingPeriodCatTypeCode);
      }
    }

    return (
      <CustomForm {...formProps}
        formRef={this._formRef}
        initialValues={committeeInfo}
        layout="vertical">
          <Typography>
            <strong>Committee Code: </strong> {committeeInfo.committeeCode != undefined ? committeeInfo.committeeCode : 'Autogenerated Upon Approval'}
          </Typography>
          <br/>
        <FormItem name="name" label="Committee Name"
          rules={[FormValidationUtil.Required('Committee Name is required'),
          FormValidationUtil.Custom(this.checkCommitteeName)]} >
          <Input disabled={disabled} maxLength={FormInputLen.COMMITTEENAME} />
        </FormItem>
        <FormItem name="type" label="Committee Type" rules={[FormValidationUtil.Required('Committee Type is required')]}>
          <Select onChange={(value) => this.handleCommitteeTypeChanged(value as CommitteeType | '')}
            disabled={disabled}
            showSearch
            optionFilterProp="children"
            allowClear={true} >
            <Option value="" disabled={true}>-- Select Committee Type --</Option>
            {cpyCommitteeTypes.map(ct => (
              <Option key={ct.code || ''} value={ct.code || ''}>{ct.name}</Option>
            ))}
          </Select>
        </FormItem>
        <FormItem
          name="description"
          label="Description/Purpose"
          rules={[FormValidationUtil.Required('Description/Purpose is required', descriptionRequired)]}>
          <TextArea disabled={disabled} />
        </FormItem>
        <Row gutter={24}>
          <Col {...columnSizingProps}>
            <FormItem
              name="politicalParty"
              label="Political Party"
              rules={[FormValidationUtil.Required('Political Party is required',
                selectedCommitteeType?.requiredPoliticalParty)]}>
              <Select disabled={disabled}
                showSearch
                optionFilterProp="children"
                allowClear={true} >
                <Option
                  value=""
                  disabled={selectedCommitteeType?.requiredPoliticalParty ?? true }
                >-- Select Your Party --</Option>
                {politicalParties.map(pp => (
                  <Option key={pp.code || ''} value={pp.code || ''}>{pp.name}</Option>
                ))}
              </Select>
            </FormItem>
              <FormItem name="county"
                label="County"
                rules={[FormValidationUtil.Required('County is required', selectedCommitteeType?.requiredCounty)]}>
              <Select disabled={disabled}
                showSearch
                optionFilterProp="children"
                allowClear={true}>
                <Option
                  value=""
                  disabled={selectedCommitteeType?.requiredCounty ?? true}
                >-- Select County --</Option>
                  {counties.map(c => (
                    <Option key={c.code || ''} value={c.code || ''}>{c.name}</Option>
                  ))}
                </Select>
              </FormItem>
            {showOfficeSought && (
              <FormItem
                name="nameOfOfficeSought"
                label="Name of Office Sought"
                rules={[FormValidationUtil.Required('Name of Office Sought is required')]}>
                <Input disabled={disabled} maxLength={FormInputLen.COMMITTEEOFFICENAME} />
              </FormItem>
            )}
            <FormItem
              name="committeeEmail"
              label="Committee Email"
              rules={[FormValidationUtil.Required('Committee Email is required'), FormValidationUtil.Email('Email is invalid')]}>
              <Input disabled={disabled} maxLength={FormInputLen.COMMITTEEEMAIL} />
            </FormItem>
          </Col>
          <Col {...columnSizingProps}>
            {showDistrictNum && (
              <FormItem
                name="districtNumber"
                label="District Number"
                rules={[FormValidationUtil.RequiredNumber('District Number is required', selectedCommitteeType?.requiredDistrict)]}>
                <InputNumber precision={0} disabled={disabled} />
              </FormItem>
            )}
            {showElectionYear && (
              <FormItem name="electionYear" label="Election Year">
                <Select disabled={disabled}
                  showSearch
                  optionFilterProp="children"
                  allowClear={true} >
                  <Option value="" disabled={false}>-- Select Year --</Option>
                  {electionYears.map(ey => (
                    <Option key={ey} value={ey}>{ey}</Option>
                  ))}
                </Select>
              </FormItem>
            )}
            {showElectionDate && (
              <FormItem
                name="electionDate"
                label="Election Date"
                rules={[FormValidationUtil.RequiredDate('Election Date is required', electionDateRequired)]}>
                <CustomDatePicker disabled={disabled} onChange={this.handleDateChange} />
              </FormItem>
            )}
          </Col>
        </Row>
        {/* {showCode ? (
          <Row>
            <Col {...columnSizingProps}>
              <FormItem name="code" label="Code">
                <Input className="short" />
              </FormItem>
            </Col>
          </Row>
        ) : undefined} */}
      </CustomForm>
    );
  }

  private handleDateChange = (value: moment.Moment | null) => {
    if (value?.year) {
      this._formRef.current?.setFieldsValue({ 'electionYear': value.year() });
    }
  }

  private checkCommitteeName = async (): Promise<any> => {
    const name = this._formRef.current?.getFieldValue('name');
    const type = this._formRef.current?.getFieldValue('type');
    const county = this._formRef.current?.getFieldValue('county');
    const committeeId = this.props.committeeInfo.id || '';

    if (name !== '' && name != null) {
      return Dr1ApiService.checkCommitteeNameExists(name, type, county, committeeId)
        .then(result => {
          if (!result) {
            return;
          }

          if (this.props.committeeInfo && this.props.committeeInfo.name !== name) {
            throw new Error(name + ' is already in use');
          }
        });
    }
  }

  private loadLookups = () => {
    const committeeTypesPromise = LookupsApiService.getCommitteeTypes();
    const politicalPartiesPromise = LookupsApiService.getPoliticalParties();
    const countiesPromise = LookupsApiService.getCounties();

    Promise.all([committeeTypesPromise, politicalPartiesPromise, countiesPromise])
      .then(results => {
        let [committeeTypes] = results;
        const [, politicalParties, counties] = results;
        if (this.props.filerType) {
          committeeTypes = committeeTypes.filter(ct => ct.filerTypeCd === this.props.filerType);
        }

        this.setState({ committeeTypes, politicalParties, counties });
      });
  }

  private handleCommitteeTypeChanged = async (committeeTypeCode: CommitteeType | '') => {
    if (committeeTypeCode === '') {
      this.setState({ selectedCommitteeType: null });
      return;
    }

    this.setState({ selectedCommitteeType: this.state.committeeTypes.filter(ct => ct.code === committeeTypeCode)[0] });
  }
}

export default CommitteeInfo;
