import * as moment from 'moment';

export default class DateUtil {
public static getDateFormatString = () => 'MM/DD/YYYY';
public static getDateTimeFormatString = () => 'M/D/YYYY hh:mm a';

  public static now() {
    // @ts-ignore
    return moment();
  }

  public static toShortDateString = (date: moment.Moment) => date.format(DateUtil.getDateFormatString());

  public static getShortMonthString(monthNumber: number | null): string {
    if (!monthNumber) {
      return '';
    }
    return moment.monthsShort(monthNumber - 1);
  }

  public static getLongMonthString(monthNumber: number | null): string {
    if (!monthNumber) {
      return '';
    }
    return moment.months(monthNumber - 1);
  }

  public static getDateStamp() {
    return this.now().format('YYYYMMDD');
  }

  public static toTimeStamp = (date: moment.Moment) => date.format('YYYY-MM-DDTHH:mm:ssZ');

  public static toTimeLocal = (date: moment.Moment) => date.local().format('YYYY-MM-DD hh:mm:ss a');
  
}
