// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import AdminPfdContactDepartmentDTO from './AdminPfdContactDepartmentDTO';
import EntitySelectorDTO from './EntitySelectorDTO';

interface AdminPfdContactDTO extends EntitySelectorDTO { 
  contactId: number | null;
  firstName: string | null;
  lastName: string | null;
  address1: string | null;
  address2: string | null;
  city: string | null;
  state: string | null;
  zip: string | null;
  email: string | null;
  phone: string | null;
  contactDepartmentDivisionPositition: AdminPfdContactDepartmentDTO[] | null;
  terminationDate: moment.Moment | string | null;
}
const AdminPfdContactDTO = {
  create: (initValues?: Partial<AdminPfdContactDTO> | Record<string, unknown> | null) => {
    return Object.assign(EntitySelectorDTO.create(),
    {
      contactId: null,
      firstName: null,
      lastName: null,
      address1: null,
      address2: null,
      city: null,
      state: null,
      zip: null,
      email: null,
      phone: null,
      contactDepartmentDivisionPositition: [],
      terminationDate: null,
    },
    initValues);
  }
};

export default AdminPfdContactDTO;