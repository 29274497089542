import {
  Button,
  Col,
  ColProps,
  Input,
  Layout,
  notification,
  Row,
  Typography,
  Space,
  Radio,
  Modal,
} from 'antd';
import { FormInstance, FormProps } from 'antd/lib/form/Form';
import FormItem from 'antd/lib/form/FormItem';
import moment from 'moment';
import { RouteComponentProps } from 'react-router-dom';
import * as React from 'react';
import CampaignApiService from '../../../api/CampaignApiService';
import Routes from '../../../consts/Routes';
import FormValidationUtil from '../../../utils/FormValidationUtil';
import OieExpendDTO from '../../../models/OieExpendDTO';
import HistoryUtil from '../../../utils/HistoryUtil';
import OieAdvocates from '../../../consts/OieAdvocates';
import OieContribution from './OieContribution';
import AddressFormFields from '../../shared/AddressFormFields';
import CommitteeAutoSearch from '../../shared/CommitteeSearch';
import CommitteeInfoDTO from '../../../models/CommitteeInfoDTO';
import OieContributionDTO from '../../../models/OieContributionDTO';
import FilingStatusType from '../../../consts/FilingStatusType';
import AdminOieApiService from '../../../api/AdminOieApiService';
import CurrencyInput from '../../shared/CurrencyInput';
import CustomDatePicker from '../../shared/CustomDatePicker';
import CustomForm from '../../shared/CustomForm';

const { Content } = Layout;
const { confirm } = Modal;

interface OieProps {
  id?: string;
}

interface OieState {
  id?: string;
  loading: boolean;
  submitLoading: boolean;
  contributions: OieContributionDTO[];
  oieExpend?: OieExpendDTO;
  radioValue: string;
  validateBoolean: boolean;
}

class Oie extends React.Component<RouteComponentProps<OieProps> & FormProps, OieState>
{
  private readonly formRef = React.createRef<FormInstance>();

  constructor(props: RouteComponentProps<OieProps> & FormProps) {
    super(props);

    this.state = {
      id: props.match.params.id,
      loading: true,
      submitLoading: false,
      contributions: [],
      radioValue: '',
      oieExpend: OieExpendDTO.create({ signedDate: moment().startOf('day'), dateOfExpenditure: null, amount: null, organizationState: 'IA' }),
      validateBoolean: true,
    };
  }

  componentDidMount() {
    this.getOieExpend();
  }

  render() {
    const columnSizingProps: ColProps = { xs: 24, sm: 24, md: 12 };
    const { loading, submitLoading, id, radioValue, oieExpend } = this.state;

    return (
      <Content className="content-pad">
        <Typography.Title level={4}>Independent Expenditure</Typography.Title>
        <Typography.Paragraph>This form may not be used by a candidate, candidate&apos;s committee, or a political committee.</Typography.Paragraph>
        {loading ? undefined :
          <CustomForm
            formRef={this.formRef}
            onFinish={submitLoading ? undefined : this.submitForm}
            layout="vertical"
            initialValues={oieExpend}
            validateTrigger={['onChange', 'onBlur']}>
            <Row gutter={24}>
              <Col {...columnSizingProps}>
                <Typography.Title level={4}>Organization Details</Typography.Title>
                <AddressFormFields
                  name={{ name: 'organizationName', label: 'Organization Making the Expenditure' }}
                  firstName={{ name: 'contactFirstName', label: 'Contact First Name' }}
                  middleInitial={{ name: 'contactMi' }}
                  lastName={{ name: 'contactLastName', label: 'Contact Last Name' }}
                  address1={{ name: 'organizationAddress1' }}
                  address2={{ name: 'organizationAddress2' }}
                  city={{ name: 'organizationCity' }}
                  state={{ name: 'organizationState' }}
                  zip={{ name: 'organizationZip' }}
                  email={{ name: 'organizationEmail' }}
                  phone={{ name: 'organizationPhone' }} />
              </Col>
              <Col {...columnSizingProps}>
                <Typography.Title level={4}>Expenditure Details</Typography.Title>
                <FormItem
                  name="dateOfExpenditure"
                  label="Date of Expenditure"
                  rules={[FormValidationUtil.RequiredDate('Date of the Expenditure is required')]}>
                  <CustomDatePicker onChange={(d) => d?.startOf('day')}/>
                </FormItem>
                <FormItem
                  name="amount"
                  label="Amount"
                  rules={[
                    FormValidationUtil.RequiredNumber('Amount of the Expenditure is required'),
                    FormValidationUtil.PositiveNumber('Amount of the Expenditure must be positive')
                  ]}
                  tooltip="Total amount of expenditure">
                  <CurrencyInput />
                </FormItem>
                <FormItem name="typeOfCommunication" label="Type of Communication"
                  rules={[FormValidationUtil.Required('type of communication is required')]}
                  tooltip="Newspaper, Ad, Radio, Televison, Flier, etc" >
                  <Input type="text" />
                </FormItem>
                <FormItem name="vendor" label="Vendors"
                  rules={[FormValidationUtil.Required('Vendor is required')]}
                  tooltip="To enter more than one separate by comma">
                  <Input type="text" />
                </FormItem>
                <FormItem
                  name="distDate"
                  label="Distribution Date"
                  rules={[FormValidationUtil.RequiredDate('Date of the Distribution is required')]} >
                  <CustomDatePicker onChange={(d) => d?.startOf('day')}/>
                </FormItem>
              </Col>
            </Row>
            <Col>
              <Typography.Title level={4}>Source of Funding for the purpose of furthering the expenditure.</Typography.Title>
              <br></br>
              < OieContribution
                contributionList={this.state.oieExpend?.contributions || []}
                onChange={this.handleContributionList} />
            </Col>
            <Row>
              <Col {...columnSizingProps}>
                <Typography.Title level={4}>This expenditure expressly advocates:</Typography.Title>
                <FormItem label="Select One" required={true} style={{ marginBottom: '0' }}>
                  <Radio.Group onChange={this.onChangeRadioButtons} style={{ width: '100%' }} value={radioValue} >
                    <Space direction="vertical" style={{ width: '100%' }}>
                      <Radio name="group" value={OieAdvocates.FORCANDIDATE}>{OieAdvocates.FORCANDIDATE}  </Radio>
                      <CommitteeAutoSearch
                        hidden={radioValue !== OieAdvocates.FORCANDIDATE}
                        name="forCandidate"
                        required={radioValue === OieAdvocates.FORCANDIDATE}
                        onSelectCommittee={this.handleSelectCommittee}
                        noMargin={true} />

                      <Radio name="group" value={OieAdvocates.AGAINSTCANDIDATE}>{OieAdvocates.AGAINSTCANDIDATE}</Radio>
                      <CommitteeAutoSearch
                        name="againstCandidate"
                        required={radioValue === OieAdvocates.AGAINSTCANDIDATE}
                        hidden={radioValue !== OieAdvocates.AGAINSTCANDIDATE}
                        onSelectCommittee={this.handleSelectCommittee}
                        noMargin={true} />

                      <Radio name="group" value={OieAdvocates.FORBALLOT}>{OieAdvocates.FORBALLOT}</Radio>
                      < CommitteeAutoSearch
                        name="forBallot"
                        required={radioValue === OieAdvocates.FORBALLOT}
                        hidden={radioValue !== OieAdvocates.FORBALLOT}
                        onSelectCommittee={this.handleSelectCommittee}
                        noMargin={true} />

                      <Radio name="group" value={OieAdvocates.AGAINSTBALLOT}>{OieAdvocates.AGAINSTBALLOT}</Radio>
                      <CommitteeAutoSearch
                        name="againstBallot"
                        required={radioValue === OieAdvocates.AGAINSTBALLOT}
                        hidden={radioValue !== OieAdvocates.AGAINSTBALLOT}
                        onSelectCommittee={this.handleSelectCommittee}
                        noMargin={true} />

                      {radioValue === '' ?
                        <FormItem
                          className="hide-input"
                          name="committeeName"
                          rules={[FormValidationUtil.Required('Select an option')]}
                          validateFirst={true}>
                          <Input type="text" />
                        </FormItem> : null}
                    </Space>
                  </Radio.Group>
                </FormItem>
                <FormItem name="committeeId" hidden={true} aria-hidden={true}>
                </FormItem>
              </Col>
            </Row>
            {
              oieExpend?.filingStatusType !== FilingStatusType.CERTIFIED &&
                oieExpend?.filingStatusType !== FilingStatusType.ADJUSTED ?
                <>
                  <Typography.Title level={4}>Review the Terms of Agreement</Typography.Title>
                  <Typography.Paragraph>Criteria to use this form:</Typography.Paragraph>
                  <ol>
                    <li>
                      The organization filing this statement made one or more expenditures in excess of $1000
                      in the aggregate for the communication that expressly advocates the nomination, election,
                      or defeat of a clearly identified candidate or the passage or defeat of a ballot issue
                      that is made without the prior approval or coordination with a candidate, candidate&apos;s committee,
                      or a ballot issue committee.
                    </li>
                    <li>
                      The organization&apos;s board of directors, executive council or similar organizational leadership body
                      authorized the disbursement of funds for the expenditure in the same calendar year in which the
                      expenditure was incurred.
                    </li>
                    <li>
                      The organization shall disclose any donor or other source of funding which was provided for the
                      purpose of furthering the expenditure.
                    </li>
                    <li>
                      The organization did not engage or retain an advertising firm or consultant that had also been engaged
                      or retained within the prior 6 months by the candidate, candidate&apos;s committee,
                      or ballot issue committee
                      that is benefited by the independent expenditure.
                    </li>
                    <li>
                      The communication which is the subject of this statement complies with the attribution requirements of
                      Iowa Code section 68A.405.
                    </li>
                    <li>
                      The organization is not a foreign national and no foreign national provided funding for this expenditure.
                      A &quot;foreign national&quot; means a person who is not a citizen of the United States and who is not lawfully admitted
                      for a permanent residence. &quot;Foreign national&quot; includes a foreign principal, such as a government of a foreign
                      country or a foreign political party, partnership, association, or other combination of persons that has its
                      primary place of business in or is organized under the laws of a foreign country.
                    </li>
                  </ol>
                  <Typography.Text>
                    <Typography.Title level={4}>Statement of Certification</Typography.Title>
                    I affirm that the independent expenditure reported above is accurate and complete. I also affirm that this expenditure
                    was made without the prior approval or coordination with a candidate, candidate&apos;s committee, or a ballot issue committee.
                    I understand that by filing this form, I am subject to the campaign laws in Iowa Code chapter 68A and rules in chapter
                    351 of the Iowa Administrative Code. I also understand that the failure to timely file this form leads to the imposition
                    of civil penalties and the intentional failure to file this form may lead to additional civil and criminal sanctions.
                    <br /><br />
                  </Typography.Text>
                  <Row>
                    <Col {...columnSizingProps}>
                      <FormItem name="signedName" label="Name" rules={[FormValidationUtil.Required('Signature is required')]}>
                        <Input type="text" />
                      </FormItem>
                      <FormItem
                        name="signedDate"
                        label="Date Signed">
                        <CustomDatePicker disabled={true} />
                      </FormItem>
                      {
                        id == null ?
                          <>
                            <Space>
                              <Button type="primary" htmlType="submit">I Agree</Button>
                              <Button onClick={this.cancelForm}>Cancel</Button>
                            </Space>
                          </> :
                          <>
                            <Space>
                              <Button
                                type="primary"
                                onClick={() => this.updateStatus(id || '', FilingStatusType.ADJUSTED)}>Adjust</Button>
                              <Button
                                type="primary"
                                onClick={() => this.updateStatus(id || '', FilingStatusType.CERTIFIED)}>Approve</Button>
                              <Button onClick={() => this.confirmReject(id || '', oieExpend?.organizationName || '')}>Reject</Button>
                              <Button onClick={this.cancelEdit}>Cancel</Button>
                            </Space>
                          </>
                      }
                    </Col>
                  </Row>
                </> :
                <>
                  <br /><br />
                  <Col {...columnSizingProps}>
                    < Row gutter={24} >
                      <Col span={12}>
                        <FormItem name="signedName" label="Name" rules={[FormValidationUtil.Required('Signature is required')]}>
                          <Input type="text" />
                        </FormItem>
                      </Col>
                      <Col span={12}>
                        <FormItem
                          name="signedDate"
                          label="Date Signed">
                          <CustomDatePicker disabled={true} />
                        </FormItem>
                      </Col>
                    </Row>
                  </Col>
                  <Space>
                    <Button type="primary" onClick={() => this.updateStatus(id || '', FilingStatusType.ADJUSTED)}>Adjust</Button>
                    <Button onClick={() => this.editPenalty(id || '')}>Penalty</Button>
                    <Button danger onClick={() => this.confirmDelete(id || '', oieExpend?.organizationName || '')}>Delete</Button>
                    <Button onClick={this.cancelEdit}>Cancel</Button>
                  </Space>
                </>
            }
          </CustomForm>
        }
      </Content>
    );
  }

  private handleSelectCommittee = (committee: CommitteeInfoDTO | undefined) => {
    if (committee) {
      this.formRef.current?.setFieldsValue({ committeeId: committee.id });
    }
  }

  onChangeRadioButtons = (e: any) => {
    this.setState({
      radioValue: e.target.value,
    }, () => {
      this.formRef.current?.setFieldsValue({
        forCandidate: '',
        againstCandidate: '',
        forBallot: '',
        againstBallot: '',
        committeeId: null,
      });
    });
  }

  private handleContributionList = (contributionList: OieContributionDTO[] | []) => {
    this.setState({ contributions: contributionList });
  }

  private submitForm = (values: any) => {
    if (this.state.contributions) {
      values.contributions = this.state.contributions;
    }
    else { values.contributions = []; }
    this.setState({ submitLoading: true });
    return CampaignApiService.addOieExpend({ ...this.state.oieExpend, ...values })
      .then(() => {
        if (!this.state.id) {
          this.setState({ submitLoading: false });
          HistoryUtil.push(Routes.LOGIN);
          notification.success({
            message: 'Your report submitted successfully.',
            description: 'You will be contacted by the IECDB staff upon approval. Thank You.'
          });
        }
      })
      .catch(error => {
        notification.error({ message: error.message });
      });
  }

  private updateStatus = (id: string, status: string) => {
    this.formRef.current?.validateFields().then(() => {
      this.submitForm(this.formRef.current?.getFieldsValue()).then(() =>
        AdminOieApiService.updateOieStatus(id, status)
          .then(() => {
            HistoryUtil.push(Routes.MANAGE_OIE);
            notification.success({
              message: 'Your changes were saved successfully',
            });
          }).catch(() => {
            notification.error({
              message: 'Failed to update status.',
            });
          }));
    }).catch(reason => {
      this.formRef?.current?.scrollToField(reason.errorFields[0].name);
      window.scrollBy(0, -150);
    });
  }

  private confirmReject = (expendId: string, orgName: string) => {
    confirm({
      title: 'Are you sure you want to reject this OIE?',
      content: 'The following record will be rejected: ' + orgName,
      onOk: () => {
        this.updateStatus(expendId, FilingStatusType.DELETED);
      },
    });
    notification.success({ message: 'OIE was rejected successfully.' });
  }

  private editPenalty = async (id: string) => {
    HistoryUtil.push(Routes.generate(Routes.OIE_PENALTY, { id: id }));
  }

  private confirmDelete = (id: string, orgName: string) => {
    confirm({
      title: 'Are you sure you want to delete this OIE?',
      content: 'The following record will be permanently deleted: ' + orgName,
      onOk: () => {
        this.deleteOie(id);
      },
    });
  }

  private deleteOie = (id: string) => {
    AdminOieApiService.deleteOie(id)
      .then(() => {
        HistoryUtil.push(Routes.MANAGE_OIE);
        notification.success({ message: 'OIE was deleted successfully.' });
      }).catch(() => {
        notification.error({ message: 'Failed to delete OIE.' });
      });
  }

  private getOieExpend = () => {
    if (!this.state.id) {
      this.setState({ loading: false });
      return;
    }

    this.setState({ loading: true });
    CampaignApiService.getOieExpend(this.state.id).then((oieExpend) => {
      if (oieExpend.dateOfExpenditure) {
        oieExpend.dateOfExpenditure = moment(oieExpend.dateOfExpenditure);
      }
      if (oieExpend.distDate) {
        oieExpend.distDate = moment(oieExpend.distDate);
      }
      if (oieExpend.signedDate) {
        oieExpend.signedDate = moment.utc(oieExpend.signedDate);
      }
      if (oieExpend.contributions) {
        oieExpend.contributions.map((contributions) => {
          if (contributions.date) {
            contributions.date = moment.utc(contributions.date);
          }
        });
      }

      let radioValue = '';
      if (oieExpend.forCandidate) {
        radioValue = OieAdvocates.FORCANDIDATE;
      }
      if (oieExpend.againstCandidate) {
        radioValue = OieAdvocates.AGAINSTCANDIDATE;
      }
      if (oieExpend.forBallot) {
        radioValue = OieAdvocates.FORBALLOT;
      }
      if (oieExpend.againstBallot) {
        radioValue = OieAdvocates.AGAINSTBALLOT;
      }
      this.setState({
        oieExpend,
        loading: false,
        radioValue,
        contributions: oieExpend.contributions || [],
      });
    }).catch(() => {
      this.setState({ loading: false });
    });
  }

  private cancelForm = () => {
    confirm({
      title: 'Are you sure you want to leave?',
      okText: 'Yes',
      cancelText: 'No',
      onOk: () => {
        HistoryUtil.push(Routes.LOGIN);
      }
    });
  }

  private cancelEdit = () => {
    confirm({
      title: 'Are you sure you want to leave?',
      okText: 'Yes',
      cancelText: 'No',
      onOk: () => {
        HistoryUtil.push(Routes.MANAGE_OIE);
      }
    });
  }
}

export default Oie;
