const reviewAccountKey = 'reviewAccountKey';
const dr2FilingReportKey = 'dr2FilingReportKey';
const addressReportKey = 'addressReportKey';
const gbReportKey = 'gbReportKey';
const pfdFilingReportKey = 'pfdFilingReportKey';
const perioodReportKey = 'perioodReportKey';
const personReportKey = 'personReportKey';
const supplementaryFilingReportKey = 'supplementaryFilingReportKeyy';

export const setReviewAccountColumnFilters = (columnFilter: string) => {
  sessionStorage.setItem(reviewAccountKey, columnFilter);
};

export const getReviewAccountColumnFilters  = (): string => {
  return sessionStorage.getItem(reviewAccountKey) ?? '{}';
};

export const setDR2FilingReportColumnFilters = (columnFilter: string) => {
  sessionStorage.setItem(dr2FilingReportKey, columnFilter);
};

export const getDR2FilingReportColumnFilters = (): string => {
  return sessionStorage.getItem(dr2FilingReportKey) ?? '{}';
};

export const getAddressReportColumnFilters = (): string => {
  return sessionStorage.getItem(addressReportKey) ?? '{}';
};

export const setAddressReportColumnFilters = (columnFilter: string) => {
  sessionStorage.setItem(addressReportKey, columnFilter);
};

export const getGBReportColumnFilters = (): string => {
  return sessionStorage.getItem(gbReportKey) ?? '{}';
};

export const setGBReportColumnFilters = (columnFilter: string) => {
  sessionStorage.setItem(gbReportKey, columnFilter);
};

export const getPFDFilingReportColumnFilters = (): string => {
  return sessionStorage.getItem(pfdFilingReportKey) ?? '{}';
};

export const setPFDFilingReportColumnFilters = (columnFilter: string) => {
  sessionStorage.setItem(pfdFilingReportKey, columnFilter);
};

export const getPeriodReportColumnFilters = (): string => {
  return sessionStorage.getItem(perioodReportKey) ?? '{}';
};

export const setPeriodReportColumnFilters = (columnFilter: string) => {
  sessionStorage.setItem(perioodReportKey, columnFilter);
};

export const getPersonReportColumnFilters = (): string => {
  return sessionStorage.getItem(personReportKey ) ?? '{}';
};

export const setPersonReportColumnFilters = (columnFilter: string) => {
  sessionStorage.setItem(personReportKey , columnFilter);
};

export const getSupplementaryFilingReportColumnFilters = (): string => {
  return sessionStorage.getItem(supplementaryFilingReportKey) ?? '{}';
};

export const setSupplementaryFilingReportColumnFilters = (columnFilter: string) => {
  sessionStorage.setItem(supplementaryFilingReportKey, columnFilter);
};