import { notification, Spin } from 'antd';
import moment from 'moment';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import Dr2ApiService from '../../api/Dr2ApiService';
import CommitteeTypeDTO from '../../models/CommitteeTypeDTO';
import FilingPeriodDTO from '../../models/FilingPeriodDTO';
import CurrentUser from '../../utils/CurrentUser';
import DateUtil from '../../utils/DateUtil';
import NewFilingPeriodTable from '../schedule/NewFilingPeriodTable';
import OrganizationApiService from '../../api/OrganizationApiServices';
import DR1DTO from '../../models/DR1DTO';
import ElectionInfoRequiredUtil from '../../utils/ElectionInfoRequiredUtil';
import ElectionInfoModal from '../schedule/ElectionInfoModal';
import FilerType from '../../consts/FilerType';
import ElectionYear from '../../consts/ElectionYear';


interface SelectFilingProps { }

interface SelectFilingState {
  dr1: DR1DTO;
  showElectionModal: boolean;
  commtype: CommitteeTypeDTO;
  periodMasterId: number;
  loading: boolean;
  currDr2Id: string;
  filingPeriod: string | null;
  isIncumbent: boolean | null;
}

class SelectFiling extends React.Component<RouteComponentProps<SelectFilingProps>, SelectFilingState> {
  private _newFilingPeriodTable: NewFilingPeriodTable | null = null;

  constructor(props: RouteComponentProps<SelectFilingProps>) {
    super(props);
    this.state = {
      dr1: DR1DTO.create(),
      currDr2Id: CurrentUser.Get()?.dr2Id || '',
      showElectionModal: false,
      commtype: CommitteeTypeDTO.create(),
      periodMasterId: 0,
      loading: false,
      filingPeriod: '',
      isIncumbent: false,
    };
  }

  componentDidMount = () => {
    this.loadDR1Data();
  }

  render() {
    const { dr1, showElectionModal, commtype, loading, currDr2Id } = this.state;
    const showElectionDate = ElectionInfoRequiredUtil.showElectionDate(commtype?.code || '');
    const electionDateRequired = ElectionInfoRequiredUtil.electionDateRequired(commtype?.code || '');

    return (
      <Spin spinning={loading}>
        <NewFilingPeriodTable
          ref={(element) => this._newFilingPeriodTable = element}
          selectFiling={true}
          dr2Id={currDr2Id}
          onChange={this.handleSelectFiling}
          CanSelectFiling={true}
          adminShowAll={true}
        />
        {(showElectionModal && (showElectionDate || CurrentUser.Get()?.filerTypeId == FilerType.CANDIDATE)) &&
            <ElectionInfoModal
              onClose={() => this.setState({ showElectionModal: false, loading: false })}
              dateRequired={(showElectionDate && electionDateRequired)}
              dr1={dr1}
              dr2Id={currDr2Id}
              onSubmit={this.handleSubmit}
              filingPeriod={this.state.filingPeriod}
            />
         }
      </Spin>
    );
  }

  private handleSubmit = (dr1: DR1DTO, isIncumbent: boolean | null) => {
    this.setState({ showElectionModal: false, dr1, loading: true, isIncumbent }, () => {
      Dr2ApiService.saveNewFilingPeriod(this.state.periodMasterId, CurrentUser.Get()?.committeeId || '', this.state.currDr2Id || '', isIncumbent)
        .then((result) => {
          if (result.succeeded) {
            notification.success({
              message: 'Committee filing created successfully.'
            });
            Dr2ApiService.getLastFiledDr2Id(CurrentUser.Get()?.committeeId || '')
              .then((dr2Id) => {
                CurrentUser.ChangeDr2(dr2Id);
                this._newFilingPeriodTable?.refreshTable();
              });
          }
          else {
            notification.error({ message: result.errors[0] });
          }
        }).catch(() => {
          notification.error({
            message: 'Error while creating committee filing'
          });
        }).finally(() => {
          this.setState({ loading: false });
        });
    });
  }

  private handleSelectFiling = (filingPeriod: FilingPeriodDTO) => {
    const showElectionDate = ElectionInfoRequiredUtil.showElectionDate(this.state.commtype?.code || '');
    const isIncumbent = this.state.isIncumbent;
    if ((filingPeriod.electionType == ElectionYear.ELECTION || filingPeriod.electionType == ElectionYear.SPECIAL) &&
      (showElectionDate || CurrentUser.Get()?.filerTypeId == FilerType.CANDIDATE)) {
      this.setState({
        showElectionModal: true, loading: true,
        periodMasterId: filingPeriod.periodMasterId,
        filingPeriod: filingPeriod.electionType
      });
    }
    else {
      this.setState({ periodMasterId: filingPeriod.periodMasterId, filingPeriod: filingPeriod.electionType }, () => {
        this.handleSubmit(this.state.dr1, isIncumbent);
      });
    }
  }

  private loadDR1Data = () => {
    OrganizationApiService
      .getDr1OrgDetails(CurrentUser.Get()?.committeeId || '', DateUtil.toTimeStamp(moment()), CurrentUser.Get()?.filerTypeId || 0)
      .then(dr1 => {
        if (dr1.committeeInfo) {
          if (dr1.committeeInfo.electionDate) {
            dr1.committeeInfo.electionDate = moment(dr1.committeeInfo.electionDate);
          }
          if (dr1.committeeInfo.type) {
            this.setState({ commtype: CommitteeTypeDTO.create({ code: dr1.committeeInfo.type }) });
          }
        }

        this.setState({ dr1});
      }).catch(() => {
        notification.error({ message: 'Failed to load DR1 data.' });
      });
  }

}

export default SelectFiling;