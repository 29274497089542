// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Constants.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
export default class CommitteeType {
    public static readonly STATE_SENATE: string = '1';
    public static readonly UNASSIGNED: string = '10';
    public static readonly OUT_OF_STATEFEDERAL_PAC_OR_CANDIDATE: string = '12';
    public static readonly STATE_CENTRAL_COMMITTEE: string = '13';
    public static readonly COUNTY_CENTRAL_COMMITTEE: string = '14';
    public static readonly IOWA_PAC: string = '15';
    public static readonly SCHOOL_BOARD_CANDIDATE: string = '16';
    public static readonly CITY_CANDIDATE__MAYOR: string = '17';
    public static readonly CITY_CANDIDATE__CITY_COUNCIL: string = '18';
    public static readonly CITY_CANDIDATE__OTHER: string = '19';
    public static readonly STATE_HOUSE: string = '2';
    public static readonly COUNTY_CANDIDATE__ATTORNEY: string = '20';
    public static readonly COUNTY_CANDIDATE__AUDITOR: string = '21';
    public static readonly COUNTY_CANDIDATE__TREASURER: string = '22';
    public static readonly COUNTY_CANDIDATE__SHERIFF: string = '23';
    public static readonly COUNTY_CANDIDATE__RECORDER: string = '24';
    public static readonly COUNTY_CANDIDATE__SUPERVISOR: string = '25';
    public static readonly COUNTY_CANDIDATE__OTHER: string = '26';
    public static readonly LOCAL_BALLOT_ISSUE: string = '27';
    public static readonly COUNTY_PAC: string = '28';
    public static readonly GOVERNOR: string = '3';
    public static readonly LOBBYIST: string = '30';
    public static readonly LOBBYIST_CLIENT: string = '31';
    public static readonly CITY_PAC: string = '36';
    public static readonly SCHOOL_BOARD_OR_OTHER_POLITICAL_SUBDIVISION_PAC: string = '37';
    public static readonly JUDGE_STANDING_FOR_RETENTION: string = '38';
    public static readonly OTHER_POLITICAL_SUBDIVISION_CANDIDATE: string = '39';
    public static readonly SECRETARY_OF_STATE: string = '4';
    public static readonly COUNTY_CANDIDATE__HOSPITAL_TRUSTEE: string = '40';
    public static readonly ATTORNEY_GENERAL: string = '5';
    public static readonly SECRETARY_OF_AGRICULTURE: string = '6';
    public static readonly TREASURER_OF_STATE: string = '7';
    public static readonly AUDITOR_OF_STATE: string = '8';
    public static readonly LT_GOVERNOR: string = '9';
}
