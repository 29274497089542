// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import EntitySelectorDTO from './EntitySelectorDTO';

interface OtcFunderDTO extends EntitySelectorDTO { 
  funderId: string | null;
  funderName: string | null;
  funderAddress1: string | null;
  funderAddress2: string | null;
  funderCity: string | null;
  funderState: string | null;
  funderZip: string | null;
  funderAmount: number | null;
}
const OtcFunderDTO = {
  create: (initValues?: Partial<OtcFunderDTO> | Record<string, unknown> | null) => {
    return Object.assign(EntitySelectorDTO.create(),
    {
      funderId: null,
      funderName: null,
      funderAddress1: null,
      funderAddress2: null,
      funderCity: null,
      funderState: null,
      funderZip: null,
      funderAmount: null,
    },
    initValues);
  }
};

export default OtcFunderDTO;