// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';

interface PayeeFormDTO { 
  id: number;
  payeeType: string | null;
  payee: string | null;
  contributorAutoFillId: string | null | null;
  date: moment.Moment | string | null;
  amount: number;
  checkNumber: string | null;
  inKindInd: boolean;
  explanation: string | null;
  postmarkDate: moment.Moment | string | null;
  filedDate: moment.Moment | string | null;
  amendDate: moment.Moment | string | null;
  firstName: string | null;
  middleInitial: string | null;
  lastName: string | null;
  addressLine1: string | null;
  addressLine2: string | null;
  city: string | null;
  state: string | null;
  zip: string | null;
  phoneNumber: string | null;
  relationshipCode: string | null;
  isLivingOrRevocableTrust: boolean;
  trustee: string | null;
  trustor: string | null;
  auditorsNoteId: string | null;
}
const PayeeFormDTO = {
  create: (initValues?: Partial<PayeeFormDTO> | Record<string, unknown> | null) => {
    return Object.assign(
    {
      id: 0,
      payeeType: null,
      payee: null,
      contributorAutoFillId: null,
      date: null,
      amount: 0,
      checkNumber: null,
      inKindInd: false,
      explanation: null,
      postmarkDate: null,
      filedDate: null,
      amendDate: null,
      firstName: null,
      middleInitial: null,
      lastName: null,
      addressLine1: null,
      addressLine2: null,
      city: null,
      state: null,
      zip: null,
      phoneNumber: null,
      relationshipCode: null,
      isLivingOrRevocableTrust: false,
      trustee: null,
      trustor: null,
      auditorsNoteId: null,
    },
    initValues);
  }
};

export default PayeeFormDTO;