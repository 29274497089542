import { Col, Layout, notification, Row, Modal } from 'antd';
import * as React from 'react';
import AdminDr3ApiService from '../../api/AdminDr3ApiService';
import LookupsApiService from '../../api/LookupsApiService';
import FilerType from '../../consts/FilerType';
import Routes from '../../consts/Routes';
import AdminDR3ListDTO from '../../models/AdminDr3ListDTO';
import CodeLookupTableDTO from '../../models/CodeLookupTableDTO';
import HistoryUtil from '../../utils/HistoryUtil';
import DataTable, { DataTableColumnProps } from '../shared/DataTable/DataTable';
import DataTableButtonUtil from '../shared/DataTable/DataTableButtonUtil';
import DataTableColumnUtil from '../shared/DataTable/DataTableColumnUtil';

const { Content } = Layout;
const { confirm } = Modal;

interface CertifyDR3Props {
}

interface CertifyDR3State {
  statuses: CodeLookupTableDTO[];
  types: CodeLookupTableDTO[];
}

class CertifyDR3 extends React.Component<CertifyDR3Props, CertifyDR3State> {
  constructor(props: CertifyDR3Props) {
    super(props);

    this.state = {
      statuses: [],
      types: []
    };
  }

  private dataTable: DataTable<AdminDR3ListDTO> | undefined;

  componentDidMount() {
    this.fetchStatuses();
    this.fetchTypes();
  }

  render() {
    const actionButtons = [];
    actionButtons.push(DataTableButtonUtil.Reset());

    return (
      <Content className="content-pad">
        <Row>
          <Col xs={24}>
            <DataTable
              buttonBar={actionButtons}
              columns={this.getTableColumns()}
              fetchData={{
                fetch: function (tableRequest) { return AdminDr3ApiService.getDR3List(tableRequest); },
                failureMessage: 'Failed to Retrieve DR3s.'
              }}
              globalSearch={true}
              ref={(element: any) => (this.dataTable = element)}
              serverSide={true}
              styleOptions={{ compact: true, alternatingRowHighlight: true }}
              tableProps={{
                rowKey: 'committeeFilingId',
                sortDirections: ['ascend', 'descend'],
                locale: { emptyText: 'Currently there are no committee DR3\'s that need to be certified.' }
              }}
              title="Certify DR3"
              stateSaving={{
                enabled: true,
                tableUniqueKey: 'certifyDr3',
                perSession: true,
              }}
            />
          </Col>
        </Row>
      </Content>
    );
  }

  private getTableColumns = (): DataTableColumnProps<AdminDR3ListDTO>[] => {
    return [
      DataTableColumnUtil.Date('Date', 'submittedDate', 100, { defaultSortOrder: 'descend' }),
      DataTableColumnUtil.Text('Code', 'committeeCode', 100),
      DataTableColumnUtil.DropdownMulti(
        'Type',
        'committeeTypeName',
        (this.state.types || []).map(s => ({ text: s.name || '', value: s.code || '' })),
        275),
      DataTableColumnUtil.Text('Name', 'committeeName'),
      DataTableColumnUtil.DropdownMulti(
        'Status',
        'status',
        (this.state.statuses || []).map(s => ({ text: s.name || '', value: s.code || '' }))),
      DataTableColumnUtil.Buttons(
        'committeeFilingId',
        [
          { text: 'Certify', onClick: (rowData) => this.certify(rowData.committeeFilingId) },
          {
            text: 'Reject', onClick: (rowData) => this.handleReject(
              rowData.committeeFilingId, rowData.committeeId || '',
              rowData.filerTypeCode, rowData.committeeName || '')
          }
        ],
        70)
    ];
  }

  private handleReject = (committeeFilingId: number, committeeId: string, filerTypeCode: number, committeeName: string) => {
    if (filerTypeCode == FilerType.OSF) {
      this.confirmReject(committeeId, committeeFilingId, filerTypeCode, committeeName);
      }
    else {
      this.checkUnfiledPeriods(committeeId, committeeFilingId, filerTypeCode, committeeName);
    }
  }

  private checkUnfiledPeriods = (committeeId: string, committeeFilingId: number, filerTypeCode: number, committeeName: string) => {
    AdminDr3ApiService.checkUnfiledPeriods(committeeId)
      .then(result => {
        if (result) {
          this.confirmReject(committeeId, committeeFilingId, filerTypeCode, committeeName);
        }
        else {
          HistoryUtil.push(Routes.generate(Routes.REJECT_DR3), { committeeFilingId, committeeId, filerTypeCode });
        }
      });
  }

  private confirmReject = (committeeId: string, committeeFilingId: number, filerTypeCode: number, committeeName: string) => {
    confirm({
      title: 'Are you sure you want to reject this DR3?',
      content: `${committeeName} will have to dissolve again.`,
      onOk: () => { this.reject(committeeId, committeeFilingId, filerTypeCode); }
    });
  }

  private reject = (committeeId: string, committeeFilingId: number, filerTypeCode: number) => {
    AdminDr3ApiService.rejectDr3(committeeId, committeeFilingId, filerTypeCode).
      then((result) => {
        if (result.succeeded) {
          notification.success({ message: 'DR3 was rejected successfully.' });
          this.dataTable?.refresh();
        }
      }).catch(() => {
        notification.error({ message: 'Failed to reject DR3.' });
      });
  }

  private certify = (committeeFilingId: number) => {
    AdminDr3ApiService.certifyDR3(committeeFilingId)
      .then((result) => {
        if (result.succeeded) {
          if (this.dataTable) {
            this.dataTable.refresh();
          }
        }
        else {
          notification.error({
            message: result.errors[0]
          });
        }
      }).catch(() => {
        notification.error({
          message: 'Failed to save filing.'
        });
      });
  }

  private fetchStatuses = () => {
    LookupsApiService.getCommitteeStatuses()
      .then((statuses) => {
        this.setState({ statuses });
      })
      .catch(() => {
        notification.error({
          message: 'Failed to retrieve committee statuses.',
        });
      });
  }

  private fetchTypes = () => {
    LookupsApiService.getCommitteeTypes()
      .then((types) => {
        this.setState({ types });
      })
      .catch((error: any) => {
        notification.error({
          message: error.message,
          description: error.description,
        });
      });
  }
}

export default CertifyDR3;
