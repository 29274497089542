import { Divider, Layout, Modal, notification, Typography } from 'antd';
import * as React from 'react';
import ScheduleApiService from '../../api/ScheduleApiService';
import SchF1LoansReceivedApiService from '../../api/SchF1LoansReceivedApiService';
import Dr2AmendedIndicators from '../../consts/Dr2AmendedIndicators';
import Dr2AmendedStatuses from '../../consts/Dr2AmendedStatuses';
import NoteEntityParents from '../../consts/NoteEntityParents';
import Role from '../../consts/Role';
import Routes from '../../consts/Routes';
import ScheduleAbbreviationCodes from '../../consts/ScheduleAbbreviationCodes';
import SchF1LoansReceivedDTO from '../../models/SchF1LoansReceivedDTO';
import CurrentUser from '../../utils/CurrentUser';
import HistoryUtil from '../../utils/HistoryUtil';
import NumberFormatUtil from '../../utils/NumberFormatUtil';
import DataTable, { DataTableColumnProps } from '../shared/DataTable/DataTable';
import DataTableButtonUtil from '../shared/DataTable/DataTableButtonUtil';
import DataTableColumnUtil from '../shared/DataTable/DataTableColumnUtil';
import NotesModal from '../shared/NotesModal';
import Dr2StatusBar from './Dr2StatusBar';

const { Content } = Layout;
const { Text } = Typography;
const { confirm } = Modal;

interface ManageDr2ScheduleF1Props {
}

interface ManageDr2ScheduleF1State {
  tableColumns: DataTableColumnProps<SchF1LoansReceivedDTO>[];
  numLoansReceived: number;
  totalOfSched: number;
  flagNoteId: string;
  loanRecId: string;
  showModal: boolean;
}

class ManageDr2ScheduleF1 extends React.Component<ManageDr2ScheduleF1Props, ManageDr2ScheduleF1State> {
  private dataTable: DataTable<SchF1LoansReceivedDTO> | undefined;

  constructor(props: ManageDr2ScheduleF1Props) {
    super(props);

    this.state = {
      tableColumns: this.getTableColumns(),
      numLoansReceived: 0,
      totalOfSched: 0,
      flagNoteId: '',
      loanRecId: '',
      showModal: false,
    };
  }

  componentDidMount() {
    this.getStatistics();
  }

  render() {
    const { tableColumns, numLoansReceived, totalOfSched, flagNoteId, loanRecId, showModal} = this.state;
    const actionButtons: any = [];
    actionButtons.push(DataTableButtonUtil.Reset());
    actionButtons.push(DataTableButtonUtil.Primary(
      'New Loan',
      () => HistoryUtil.push(Routes.generate(Routes.ADD_SCHEDULE_F1_LOAN_RECEIVED, {}))
    ));

    return (
      <>
        <Content className="content-pad">
          <Dr2StatusBar dr2Id={CurrentUser.Get()?.dr2Id || ''} />
          <Text strong> Number of Loans: {numLoansReceived}&emsp;|&emsp;Total of Schedule: {NumberFormatUtil.currency(totalOfSched)}&emsp;| </Text>
          <NotesModal
            parentId={CurrentUser.Get()?.dr2Id || ''}
            parent={NoteEntityParents.SCHEDULE}
            scheduleCd={ScheduleAbbreviationCodes.SCHEDULEF1}
          />
          <Divider />
          <DataTable
            buttonBar={actionButtons}
            columns={tableColumns}
            fetchData={{
              fetch: function (tableRequest) {
                return SchF1LoansReceivedApiService.getDr2LoanReceivedList(tableRequest, CurrentUser.Get()?.dr2Id || '');
              },
              failureMessage: 'Failed to retrieve Loans Received'
            }}
            globalSearch={true}
            ref={(element: any) => (this.dataTable = element)}
            serverSide={true}
            styleOptions={{ compact: true, alternatingRowHighlight: true }}
            tableProps={{
              rowKey: 'loanReceivedIdAndSeq',
              sortDirections: ['ascend', 'descend'],
              locale: { emptyText: 'Currently there are no loans to manage.' }
            }}
            title="Schedule F1 - Loans Received"
            stateSaving={{
                enabled: true,
                tableUniqueKey: 'manageSchF1',
                perSession: true,
            }}
          />
        </Content>
        {showModal &&
          <NotesModal
            parentId={loanRecId || ''}
            parent={NoteEntityParents.LOANRECEIVED}
            auditorsNoteId={flagNoteId}
            canFlag={true}
            onClose={() => { this.setState({ showModal: false }, () => this.dataTable?.refresh()); }} />
        }
      </>
    );
  }

  private getStatistics = () => {
    ScheduleApiService.getScheduleCount(CurrentUser.Get()?.dr2Id || '', ScheduleAbbreviationCodes.SCHEDULEF1)
      .then(numLoansReceived => {
        this.setState({ numLoansReceived });
      }).catch(error => {
        notification.error({
          message: 'Error while fetching the number of loans received',
          description: error.message
        });
      });

    ScheduleApiService.getScheduleTotal(CurrentUser.Get()?.dr2Id || '', ScheduleAbbreviationCodes.SCHEDULEF1)
      .then(totalOfSched => {
        this.setState({ totalOfSched });
      }).catch(error => {
        notification.error({
          message: 'Error while fetching the total of schedule',
          description: error.message
        });
      });
  }

  private getTableColumns() {
    const columns: DataTableColumnProps<SchF1LoansReceivedDTO>[] = [];

    const columnDate: DataTableColumnProps<SchF1LoansReceivedDTO> = DataTableColumnUtil.Date('Date', 'loanDt', 100, { defaultSortOrder: 'ascend' });
    columns.push(columnDate);
    const columnLender: DataTableColumnProps<SchF1LoansReceivedDTO> = DataTableColumnUtil.Address('Lender', 'lenderName',
      (lr) => ({
        name: lr.lenderName,
        line1: lr.lenderAddressLine1,
        line2: lr.lenderAddressLine2,
        city: lr.lenderCity,
        state: lr.lenderState,
        zip: lr.lenderZip
      })
    );
    columns.push(columnLender);

    const columnAmount: DataTableColumnProps<SchF1LoansReceivedDTO> = DataTableColumnUtil.Currency('Amount', 'loanAmt');
    columns.push(columnAmount);

    const scheduleF1DropDownChoices = [
      { text: Dr2AmendedStatuses.ORIGINAL, value: Dr2AmendedIndicators.ORIGINAL },
      { text: Dr2AmendedStatuses.AMENDED, value: Dr2AmendedIndicators.AMENDED },
      { text: Dr2AmendedStatuses.ADJUSTED, value: Dr2AmendedIndicators.ADJUSTED },
      { text: Dr2AmendedStatuses.DELETED, value: Dr2AmendedIndicators.DELETED }
    ];

    const columnStatus: DataTableColumnProps<SchF1LoansReceivedDTO> = DataTableColumnUtil.DropdownMulti(
      'Status',
      'status',
      scheduleF1DropDownChoices
    );
    columns.push(columnStatus);

    const columnDescription: DataTableColumnProps<SchF1LoansReceivedDTO> = DataTableColumnUtil.Description('Description', 'explanation');
    columns.push(columnDescription);

    const columnFlagged: DataTableColumnProps<SchF1LoansReceivedDTO> = DataTableColumnUtil.FlagButton(
      'Flagged',
      'flaggedNoteId',
      CurrentUser.Get()?.isInRole(Role.IECDB_ADMINISTRATOR) || false,
      [
        {
          onClick: (rowData) => this.openFlagModal(rowData.flaggedNoteId || '', rowData.loanReceivedId || ''),
          visible: (rowData) => rowData.flaggedNoteId != null
        }
      ]);
    columns.push(columnFlagged);

    const scheduleF1Buttons = [
      { text: 'Edit', onClick: (rowData: SchF1LoansReceivedDTO) => this.edit(rowData) },
      {
        text: 'Delete', onClick: (rowData: SchF1LoansReceivedDTO) => this.confirmDelete(
          rowData.loanReceivedId || '',
          rowData.seqNumber || 0,
          rowData.lenderName || '',
          rowData.loanAmt || 0)
      }
    ];
    const columnManage: DataTableColumnProps<SchF1LoansReceivedDTO> = DataTableColumnUtil.Buttons('loanReceivedId', scheduleF1Buttons, 150);
    columns.push(columnManage);

    return columns as DataTableColumnProps<SchF1LoansReceivedDTO>[];
  }

  private showTableAndRefresh = () => {
    if (this.dataTable) {
      this.dataTable.refresh();
    }
    this.getStatistics();
  }

  private openFlagModal = (flagNoteId: string, loanRecId: string) => {
    this.setState({ showModal: true, flagNoteId, loanRecId }, () => this.dataTable?.refresh());
  }

  private edit = async (rowData: SchF1LoansReceivedDTO) => {
    HistoryUtil.push(Routes.generate(
      Routes.EDIT_SCHEDULE_F1_LOAN_RECEIVED,
      {
        id: rowData.loanReceivedId || '',
        seqNum: rowData.seqNumber || ''
      })
    );
  }

  private confirmDelete = (loanReceivedId: string, seqNum: number, lenderName: string, amount: number) => {
    confirm({
      title: 'Are you sure you want to delete this loan received?',
      content: 'The following record will be permanently deleted: ' + lenderName + ' - $' + amount,
      onOk: () => {
        this.deleteLoanReceived(loanReceivedId, seqNum);
      },
    });
  }

  private deleteLoanReceived = (loanReceivedId: string, seqNum: number) => {
    SchF1LoansReceivedApiService.delete(loanReceivedId, seqNum)
      .then(() => {
        this.showTableAndRefresh();
        notification.success({
          message: 'Deleted Successfully'
        });
      }).catch(() => {
        notification.error({
          message: 'Error while deleting loan received',
          description: '',
        });
      });
  }

}

export default ManageDr2ScheduleF1;

