// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import EntitySelectorDTO from './EntitySelectorDTO';

interface IieDTO extends EntitySelectorDTO { 
  expendId: string | null;
  amount: number | null;
  individualFirstName: string | null;
  individualMi: string | null;
  individualLastName: string | null;
  individualAddress1: string | null;
  individualAddress2: string | null;
  individualCity: string | null;
  individualState: string | null;
  individualZip: string | null;
  individualEmail: string | null;
  individualPhone: string | null;
  date: moment.Moment | string | null;
  typeOfCommunication: string | null;
  vendor: string | null;
  distDate: moment.Moment | string | null;
  committeeId: string | null;
  committeeName: string | null;
  committeeCity: string | null;
  committeeState: string | null;
  committeeZip: string | null;
  committeeAddress1: string | null;
  committeeAddress2: string | null;
  forCandidate: string | null;
  againstCandidate: string | null;
  forBallot: string | null;
  againstBallot: string | null;
  signedName: string | null;
  signedDate: moment.Moment | string | null;
  filingStatusType: string | null;
  position: string | null;
}
const IieDTO = {
  create: (initValues?: Partial<IieDTO> | Record<string, unknown> | null) => {
    return Object.assign(EntitySelectorDTO.create(),
    {
      expendId: "00000000-0000-0000-0000-000000000000",
      amount: null,
      individualFirstName: null,
      individualMi: null,
      individualLastName: null,
      individualAddress1: null,
      individualAddress2: null,
      individualCity: null,
      individualState: null,
      individualZip: null,
      individualEmail: null,
      individualPhone: null,
      date: null,
      typeOfCommunication: null,
      vendor: null,
      distDate: null,
      committeeId: null,
      committeeName: null,
      committeeCity: null,
      committeeState: null,
      committeeZip: null,
      committeeAddress1: null,
      committeeAddress2: null,
      forCandidate: null,
      againstCandidate: null,
      forBallot: null,
      againstBallot: null,
      signedName: null,
      signedDate: null,
      filingStatusType: null,
      position: null,
    },
    initValues);
  }
};

export default IieDTO;