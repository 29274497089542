import {
  Layout,
  Row,
  Space,
  Typography,
} from 'antd';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import Routes from '../../consts/Routes';

const { Content } = Layout;

interface Dr2SubmitCompletionProps {

}

interface Dr2SubmitCompletionState {

}

class Dr2SubmitCompletion extends React.Component<RouteComponentProps<Dr2SubmitCompletionProps>, Dr2SubmitCompletionState>{
  constructor(props: RouteComponentProps<Dr2SubmitCompletionProps>) {
    super(props);
  }

  render() {
    return (
      <Content className="content-pad">
        <Typography.Title level={4}>DR-2 Submit</Typography.Title>
        <Typography.Paragraph>
          You have now filed your report. Filed reports are posted to the public view of the web site
          every hour.
        </Typography.Paragraph>
        <Row>
          <Space>
            <Typography.Paragraph>
              The public view can be accessed using this link:
            </Typography.Paragraph>
            <Typography.Paragraph>
              <Link to={Routes.PUBLIC_REPORTS}>View Filed Reports</Link>
            </Typography.Paragraph>
          </Space>
        </Row>
      </Content>
    );
  }
}

export default Dr2SubmitCompletion;

