// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import GbReceivingDepartmentDTO from './GbReceivingDepartmentDTO';
import RegisterDTO from './RegisterDTO';

interface DonorRegistrationDTO { 
  gbReceivingDepartment: GbReceivingDepartmentDTO | null;
  register: RegisterDTO | null;
}
const DonorRegistrationDTO = {
  create: (initValues?: Partial<DonorRegistrationDTO> | Record<string, unknown> | null) => {
    return Object.assign(
    {
      gbReceivingDepartment: null,
      register: null,
    },
    initValues);
  }
};

export default DonorRegistrationDTO;