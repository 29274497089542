// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import EntitySelectorDTO from './EntitySelectorDTO';

interface GiftBequestsDTO extends EntitySelectorDTO { 
  giftId: string | null;
  date: moment.Moment | string | null;
  amount: number | null;
  description: string | null;
  name: string | null;
  address1: string | null;
  address2: string | null;
  city: string | null;
  state: string | null;
  zip: string | null;
  email: string | null;
  phone: string | null;
  filingId: string | null;
  departmentId: number | null;
  divisionId: number | null;
}
const GiftBequestsDTO = {
  create: (initValues?: Partial<GiftBequestsDTO> | Record<string, unknown> | null) => {
    return Object.assign(EntitySelectorDTO.create(),
    {
      giftId: "00000000-0000-0000-0000-000000000000",
      date: null,
      amount: null,
      description: null,
      name: null,
      address1: null,
      address2: null,
      city: null,
      state: null,
      zip: null,
      email: null,
      phone: null,
      filingId: null,
      departmentId: null,
      divisionId: null,
    },
    initValues);
  }
};

export default GiftBequestsDTO;