import { Divider } from 'antd';
import * as React from 'react';
import SchF2LoansRepaidApiService from '../../api/SchF2LoansRepaidApiService';
import SchF2LoansRepaidDTO from '../../models/SchF2LoansRepaidDTO';
import DataTableColumnUtil from '../shared/DataTable/DataTableColumnUtil';
import DataTable, { DataTableColumnProps } from '../shared/DataTable/DataTable';
import Dr2AmendedStatuses from '../../consts/Dr2AmendedStatuses';
import Dr2AmendedIndicators from '../../consts/Dr2AmendedIndicators';

interface SchF2LoansRepaidHistoryTableProps {
  loanRepaidId: string;
}

interface SchF2LoansRepaidHistoryTableState {
}

class SchF2LoansRepaidHistoryTable extends React.Component<SchF2LoansRepaidHistoryTableProps, SchF2LoansRepaidHistoryTableState> {
  constructor(props: SchF2LoansRepaidHistoryTableProps) {
    super(props);

    this.state = {
    };
  }

  render() {
    const { loanRepaidId } = this.props;
    return (
      <>
        <Divider/>
        <DataTable
          columns={this.getTableColumns()}
          fetchData={{
            fetch: function (tableRequest) { return SchF2LoansRepaidApiService.getLoanRepaidHistory(tableRequest, loanRepaidId); },
            failureMessage: 'Failed to retrieve loan repaid history'
          }}
          serverSide={true}
          styleOptions={{ compact: true, alternatingRowHighlight: true }}
          tableProps={{
            rowKey: 'loanRepaidIdAndSeq',
            sortDirections: ['ascend', 'descend'],
            locale: { emptyText: 'Loan repaid history not available.' }
          }}
          title="Loan Repaid"
        />
      </>
    );
  }

  private getTableColumns = (): DataTableColumnProps<SchF2LoansRepaidDTO>[] => {
    const tableColumns: DataTableColumnProps<SchF2LoansRepaidDTO>[] = [
      DataTableColumnUtil.Date('Date', 'loanRepaidDt', 100),
      DataTableColumnUtil.Address('Payee', 'loanRepaidName',
        (c) => ({
          name: c.loanRepaidName,
          line1: c.loanRepaidAddressLine1,
          line2: c.loanRepaidAddressLine2,
          city: c.loanRepaidCity,
          state: c.loanRepaidState,
          zip: c.loanRepaidZip
        })
      ),
      DataTableColumnUtil.Currency('Amount', 'loanRepaidAmt'),
      DataTableColumnUtil.DropdownMulti('Status', 'status',
        [
          { text: Dr2AmendedStatuses.ORIGINAL, value: Dr2AmendedIndicators.ORIGINAL },
          { text: Dr2AmendedStatuses.AMENDED, value: Dr2AmendedIndicators.AMENDED },
          { text: Dr2AmendedStatuses.ADJUSTED, value: Dr2AmendedIndicators.ADJUSTED },
          { text: Dr2AmendedStatuses.DELETED, value: Dr2AmendedIndicators.DELETED }
        ])
    ];
    return tableColumns;
  }
}

export default SchF2LoansRepaidHistoryTable;
