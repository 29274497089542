import { Modal, notification } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import FilingPeriodHistoryApiService from '../../api/FilingPeriodHistoryApiService';
import DataTableColumnUtil from '../../components/shared/DataTable/DataTableColumnUtil';
import FilingStatus from '../../consts/FilingStatus';
import AdminFilingPeriodHistoryDTO from '../../models/AdminFilingPeriodHistoryDTO';
import TableRequestDTO from '../../models/TableRequestDTO';
import TableResponseDTO from '../../models/TableResponseDTO';
import CurrentUser from '../../utils/CurrentUser';
import DataTable, { DataTableColumnProps, FilterType } from '../shared/DataTable/DataTable';
import DataTableButtonUtil from '../shared/DataTable/DataTableButtonUtil';
import FilingStatusType from '../../consts/FilingStatusType';
import Routes from '../../consts/Routes';
import HistoryUtil from '../../utils/HistoryUtil';
import Role from '../../consts/Role';
import Dr2ApiService from '../../api/Dr2ApiService';
import DataTableCellRendererUtil from '../shared/DataTable/DataTableCellRendererUtil';
import moment from 'moment';
import SchDDebtsApiService from '../../api/SchDDebtsApiService';


const { confirm } = Modal;

const FilingStatusLookUp = [
    { text: FilingStatus.ADJUSTED, value: FilingStatus.ADJUSTED },
    { text: FilingStatus.AMENDED, value: FilingStatus.AMENDED },
    { text: FilingStatus.AUDITED, value: FilingStatus.AUDITED },
    { text: FilingStatus.FILED, value: FilingStatus.FILED },
    { text: FilingStatus.NEVER_FILED, value: FilingStatus.NEVER_FILED },
    { text: FilingStatus.NOT_FILED, value: FilingStatus.NOT_FILED },
];

interface FilingPeriodHistoryProps {
}

interface FilingPeriodHistoryState {
    filingDataColumns: DataTableColumnProps<AdminFilingPeriodHistoryDTO>[];
    loading: boolean;
    filingData: AdminFilingPeriodHistoryDTO[];
    unfiledFilings: AdminFilingPeriodHistoryDTO[];
}

class FilingPeriodHistory extends React.Component<RouteComponentProps<FilingPeriodHistoryProps>, FilingPeriodHistoryState> {
    private filingDataTable: DataTable<AdminFilingPeriodHistoryDTO> | undefined;

    constructor(props: RouteComponentProps<FilingPeriodHistoryProps>) {
        super(props);

        this.state = {
            filingDataColumns: this.getTableColoumns(),
            loading: false,
            filingData: [],
            unfiledFilings: [],
        };
    }

    render() {
        const manageActionButtons = [];
        manageActionButtons.push(DataTableButtonUtil.Reset());

        return (
            <Content className="content-pad">
                <DataTable
                    ref={(element: any) => (this.filingDataTable = element)}
                    serverSide={true}
                    tableProps={{
                        rowKey: 'dr2Id',
                        sortDirections: ['ascend', 'descend'],
                        locale: {
                            emptyText: 'Currently there is no Filing History to View'
                        },
                        loading: this.state.loading,
                        scroll: { x: '100vw', y: 600 }
                    }}
                    globalSearch={true}
                    title="Filing Period"
                    buttonBar={manageActionButtons}
                    columns={this.state.filingDataColumns}
                    fetchData={this.fetchTableData}

                    styleOptions={{ compact: true, alternatingRowHighlight: true }}
                />
            </Content>
        );
    }

    private getTableColoumns() {
        const columns: DataTableColumnProps<AdminFilingPeriodHistoryDTO>[] = [];

        const columnYear: DataTableColumnProps<AdminFilingPeriodHistoryDTO>
            = DataTableColumnUtil.Text('Year', 'year', 100);
        columns.push(columnYear);

        const columnFilingPeriod: DataTableColumnProps<AdminFilingPeriodHistoryDTO>
            = DataTableColumnUtil.LinkButton('Period', 'filingPeriodDesc', (record) => this.summary(record.dr2Id), 275);
        columns.push(columnFilingPeriod);

        const columnBeginDt: DataTableColumnProps<AdminFilingPeriodHistoryDTO>
            = DataTableColumnUtil.Date('Begin Date', 'beginDt', 100);
        columns.push(columnBeginDt);

        const columnEndDt: DataTableColumnProps<AdminFilingPeriodHistoryDTO>
            = DataTableColumnUtil.Date('End Date', 'endDt', 100);
        columns.push(columnEndDt);

    const columnDueDt: DataTableColumnProps<AdminFilingPeriodHistoryDTO>
      = DataTableColumnUtil.Date('Due Date', 'dueDt', 100, { defaultSortOrder: 'descend' });
    columnDueDt.render = DataTableCellRendererUtil.DateAndTime;
    columns.push(columnDueDt);

        const columnAdjustDt: DataTableColumnProps<AdminFilingPeriodHistoryDTO>
            = DataTableColumnUtil.Date('Adjusted Due Date', 'adjDueDt', 120);
        columns.push(columnAdjustDt);

        const columnFiledDt: DataTableColumnProps<AdminFilingPeriodHistoryDTO>
            = DataTableColumnUtil.Date('Filed Date', 'filedDt', 120);
        columnFiledDt.render = DataTableCellRendererUtil.DateAndTime;
        columns.push(columnFiledDt);

        const columnAmendedDt: DataTableColumnProps<AdminFilingPeriodHistoryDTO>
            = DataTableColumnUtil.Date('Amended Date', 'amendedDt', 120);
        columns.push(columnAmendedDt);

        const columnAuditedDt: DataTableColumnProps<AdminFilingPeriodHistoryDTO>
            = DataTableColumnUtil.Date('Audited Date', 'auditedDt', 120);
        columns.push(columnAuditedDt);

        const columnBeginBal: DataTableColumnProps<AdminFilingPeriodHistoryDTO>
            = DataTableColumnUtil.Currency('Beginning Balance', 'cashBeginBalance', 120);
        columns.push(columnBeginBal);

        const columnEndBal: DataTableColumnProps<AdminFilingPeriodHistoryDTO>
            = DataTableColumnUtil.Currency('Ending Balance', 'cashEndBalance', 120);
        columns.push(columnEndBal);

        const columnStatus: DataTableColumnProps<AdminFilingPeriodHistoryDTO>
            = DataTableColumnUtil.DropdownMulti('Status', 'filingStatusType', FilingStatusLookUp, 100);
        columns.push(columnStatus);

        const columnPenalty: DataTableColumnProps<AdminFilingPeriodHistoryDTO>
            = DataTableColumnUtil.BooleanCheckbox('Penalty', 'penaltyInd', 100, FilterType.BooleanRadio);
        columns.push(columnPenalty);

        const columnPenaltyAmount: DataTableColumnProps<AdminFilingPeriodHistoryDTO>
            = DataTableColumnUtil.Currency('Penalty Amount', 'penaltyAmount', 100);
        columns.push(columnPenaltyAmount);

        const columnPenaltyAmountPaid: DataTableColumnProps<AdminFilingPeriodHistoryDTO>
            = DataTableColumnUtil.Currency('Penalty Paid', 'penaltyAmountPaid', 120);
        columns.push(columnPenaltyAmountPaid);

        const columnIncumbent: DataTableColumnProps<AdminFilingPeriodHistoryDTO>
            = DataTableColumnUtil.YesNoEmpty('Incumbent', 'incumbentInd', 100, FilterType.BooleanRadio);
        columnIncumbent.filterType = FilterType.NONE;
        columns.push(columnIncumbent);


        const manageButtons = [
            {
                text: 'Unfile', visible: (rowData: AdminFilingPeriodHistoryDTO) => this.canUnfile(rowData.filingStatusTypeCd || ''),
                onClick: (rowData: AdminFilingPeriodHistoryDTO) => this.confirmUnfile(rowData.dr2Id || '', rowData.committeeId || ''),
            },
            {
                text: 'Delete', visible: (rowData: AdminFilingPeriodHistoryDTO) => this.canDeleteDr2(rowData.filingStatusTypeCd || ''),
                onClick: (rowData: AdminFilingPeriodHistoryDTO) => {
                    this.state.unfiledFilings.length > 1 ? this.confirmDelete(rowData.dr2Id || '', rowData.committeeId || '')
                        : HistoryUtil.push(
                            Routes.generate(Routes.DELETE_FILING),
                            { filingPeriodName: rowData.filingPeriodDesc, currDr2Id: rowData.dr2Id });
                },
            },
        ];

        const columnButtons: DataTableColumnProps<AdminFilingPeriodHistoryDTO>
            = DataTableColumnUtil.Buttons('dr2Id', manageButtons, 100);
        columns.push(columnButtons);

        return columns as DataTableColumnProps<AdminFilingPeriodHistoryDTO>[];
    };

    private confirmDelete = (dr2Id: string, committeeId: string) => {
        confirm({
            title: 'Are you sure you want to delete this filing?',
            content: 'Once deleted it will not be possible to retrieve it.',
            onOk: () => {
                this.deletePfd(dr2Id, committeeId);
            }
        });
    }

    private deletePfd = (dr2Id: string, committeeId: string) => {
        this.setState({ loading: true });
        FilingPeriodHistoryApiService.deleteFph(dr2Id, committeeId)
            .then(() => {
                if (this.filingDataTable) {
                    this.filingDataTable.refresh();
                    Dr2ApiService.getLastFiledDr2Id(CurrentUser.Get()?.committeeId || '')
                        .then((dr2Id) => {
                            CurrentUser.ChangeDr2(dr2Id);
                            notification.success({ message: 'Filing was deleted successfully.' });
                        });
                }
                this.setState({ loading: false });
            }).catch(() => {
                notification.error({ message: 'Failed to delete filing.' });
            });
    }

    private confirmUnfile = (dr2Id: string, committeeId: string) => {
        SchDDebtsApiService.getConnectedDebtRecordsForPeriod(dr2Id)
            .then(count => {
                confirm({
                    title: 'Are you sure you want to unfile this filing?',
                    content: `All debts and campaign properties created in this period will be deleted from future periods.
                    All future connected expenditures and in kind contributions (${count} total) will also be removed.`,
                    onOk: () => {
                        this.unfilePfd(dr2Id, committeeId);
                    },
                });
            });
    }

    private unfilePfd = (dr2Id: string, committeeId: string) => {
        FilingPeriodHistoryApiService.updateStatus(dr2Id, committeeId)
            .then(() => {
                if (this.filingDataTable) {
                    this.filingDataTable.refresh();
                    Dr2ApiService.getLastFiledDr2Id(CurrentUser.Get()?.committeeId || '')
                        .then((dr2Id) => {
                            CurrentUser.ChangeDr2(dr2Id);
                            notification.success({ message: 'Filing was unfiled successfully.' });
                        });
                }
            }).catch(() => {
                notification.error({ message: 'Failed to unfile filing.' });
            });
    }

    private canUnfile = (filingStatus: string): boolean => {
        const currentUser = CurrentUser.Get();

        if (!filingStatus || !currentUser) {
            return false;
        }

        return filingStatus != FilingStatusType.NOT_FILED
            && filingStatus != FilingStatusType.NEVER_FILED
            && filingStatus != FilingStatusType.DELETED
            && currentUser.isInRole(Role.IECDB_ADMINISTRATOR);
    }

    private canDeleteDr2 = (filingStatus: string): boolean => {
        const currentUser = CurrentUser.Get();

        if (!currentUser) {
            return false;
        }

        return filingStatus == FilingStatusType.NOT_FILED && currentUser.isInRole(Role.IECDB_ADMINISTRATOR);
    }

    private summary = (dr2Id: string | null) => {
        CurrentUser.ChangeDr2(dr2Id);
        HistoryUtil.push(Routes.generate(Routes.SCHEDULE_SUMMARY));
    }

    private fetchTableData = (
        requestState: TableRequestDTO,
        checkEcho: () => boolean,
        callback: (response: TableResponseDTO<AdminFilingPeriodHistoryDTO>) => void
    ) => {

        this.setState({ loading: true });

        FilingPeriodHistoryApiService.getPeriodHistory(requestState, CurrentUser.Get()?.committeeId || '')
            .then((results) => {
                if (!checkEcho()) {
                    return;
                }

                results.results?.forEach(r => {
                    if (r.filedDt) {
                        r.filedDt = moment.utc(r.filedDt).local();
                    }
                });

                this.setState({
                    filingData: results.results || [],
                    loading: false,
                    unfiledFilings: results.results?.filter((filing) => filing.filingStatusTypeCd == FilingStatusType.NOT_FILED) || [],
                });

                callback(results);
            })
            .catch((error: any) => {
                this.setState({ loading: false });
                notification.error({
                    message: error.message,
                    description: error.description,
                });
            });
    }
}

export default FilingPeriodHistory;
