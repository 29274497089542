import {
  Button,
  Col,
  FormInstance,
  FormProps,
  Input,
  Modal,
  notification,
  Row,
  Space,
  Spin,
  Typography
  } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import TextArea from 'antd/lib/input/TextArea';
import { Content } from 'antd/lib/layout/layout';
import * as React from 'react';
import CommitteeImportApiService from '../../api/CommitteeImportApiService';
import CommitteeImportFileDTO from '../../models/CommitteeImportFileDTO';
import CommitteeUploadReportDTO from '../../models/CommitteeUploadReportDTO';
import TableRequestDTO from '../../models/TableRequestDTO';
import TableResponseDTO from '../../models/TableResponseDTO';
import CurrentUser from '../../utils/CurrentUser';
import FileUploadUtil from '../../utils/FileUploadUtil';
import DataTable, { DataTableColumnProps } from '../shared/DataTable/DataTable';
import DataTableButtonUtil from '../shared/DataTable/DataTableButtonUtil';
import DataTableColumnUtil from '../shared/DataTable/DataTableColumnUtil';
import FileUpload from '../shared/FileUpload';
import CustomForm from '../shared/CustomForm';

const { confirm } = Modal;

interface CommitteeImportProps extends FormProps {
  disabled?: boolean;
}

interface CommitteeImportState {
  tableColumns: DataTableColumnProps<CommitteeUploadReportDTO>[];
  documentsList: CommitteeUploadReportDTO[];
  reportInfo: CommitteeUploadReportDTO;
  hasImportedData: boolean;
  showModal: boolean;
  pageLoading: boolean;
  importing: boolean;
  loadingDocuments: boolean;
}

class CommitteeImports extends React.Component<CommitteeImportProps, CommitteeImportState> {
  private readonly _importFormRef = React.createRef<FormInstance>();
  private readonly _modalFormRef = React.createRef<FormInstance>();
  private dataTable: DataTable<CommitteeImportFileDTO> | undefined;

  constructor(props: CommitteeImportProps) {
    super(props);
    this.state = {
      tableColumns: this.getTableColumns(),
      documentsList: [],
      reportInfo: CommitteeUploadReportDTO.create(),
      hasImportedData: false,
      showModal: false,
      pageLoading: false,
      importing: false,
      loadingDocuments: false
    };
  }

  render() {
    const { pageLoading, importing, loadingDocuments, showModal, reportInfo, documentsList, tableColumns } = this.state;
    const actionButtons = [];
    actionButtons.push(DataTableButtonUtil.Reset());

    return (
      <>
        {!pageLoading &&
          <Content>
            <Typography.Title level={3}>Import Documents</Typography.Title>
            <CustomForm formRef={this._importFormRef} layout="vertical">
              <Row>
                <Col style={{ maxWidth: 620 }}>
                  <Spin delay={300} spinning={importing}>
                    <Typography.Title level={5}>
                      This page allows you to upload supporting documents such as bank statements, receipts,
                      correspondence, etc. Accepted files are png, jpg, docx, and pdf.
                    </Typography.Title>
                    <FormItem name="file" label="Name/Path of File(s)">
                      <FileUpload multiple={true} fileType=".jpg, .pdf, .png, .docx" fileSize={20} />
                    </FormItem>
                    <Space>
                      <Button onClick={this.import}>Import</Button>
                    </Space>
                  </Spin>
                </Col>
              </Row>
            </CustomForm>
            <br /><br />
            <DataTable
              ref={(element: any) => (this.dataTable = element)}
              columns={tableColumns}
              fetchData={this.fetchTableData}
              data={documentsList}
              globalSearch={true}
              buttonBar={actionButtons}
              serverSide={true}
              styleOptions={{ compact: true, alternatingRowHighlight: true }}
              tableProps={{
                rowKey: 'id',
                sortDirections: ['ascend', 'descend'],
                locale: { emptyText: 'No documents.' },
                loading: loadingDocuments
              }}
              title="Committee Documents"
            />
            <br /><br />
            {showModal &&
              <Modal
                title="File Description"
                visible={true}
                onOk={() => this.saveDescription()}
                onCancel={this.cancelDescription}
                okText="Save"
                cancelText="Cancel"
                destroyOnClose={true}
              >
                <CustomForm formRef={this._modalFormRef} initialValues={reportInfo} layout="vertical" onFinish={this.saveDescription}>
                  <Row gutter={24}>
                    <Col span={24}>
                      <FormItem name="userFileName" label="File Name" >
                        <Input type="text" disabled={true} />
                      </FormItem>
                      <FormItem name="description" label="Description">
                        <TextArea rows={3} />
                      </FormItem>
                    </Col>
                  </Row>
                </CustomForm>
              </Modal>
            }
          </Content>
        }
      </>
    );
  }

  private import = (): void => {
    const files = this._importFormRef.current?.getFieldValue('file')?.newFiles;
    if (!files) {
      notification.info({
        message: 'Please select a file.'
      });
      return;
    }

    const fileDTO = CommitteeImportFileDTO.create();
    const request = FileUploadUtil.attatchFilesToModel('request', fileDTO, 'file', files);

    const currentUser = CurrentUser.Get();
    if (currentUser != null) {
      this.setState({ importing: true });

      CommitteeImportApiService.import(request, currentUser?.committeeId || '')
        .then(() => {
          if (this.dataTable) {
            this.dataTable.refresh();
            this._importFormRef.current?.resetFields();
            notification.success({ message: 'Success' });
          }
        }).catch(() => {
          notification.error({ message: 'Error importing file.' });
        })
        .finally(() => {
          this.setState({ importing: false });
        });
    }
  }

  private showDescription = (rowData: CommitteeUploadReportDTO) => {
    this.setState({ showModal: true, reportInfo: rowData });
  }

  private cancelDescription = () => {
    this.setState({ showModal: false });
  }

  private saveDescription = () => {
    const formData = this._modalFormRef.current?.getFieldsValue();

    if (this.state.reportInfo.id != null) {
      CommitteeImportApiService.saveDescription(this.state.reportInfo.id, formData.description || '')
        .then(() => {
          if (this.dataTable) {
            this.dataTable.refresh();
          }
          notification.success({ message: 'Success' });
          this.setState({ showModal: false });
        }).catch(() => {
          notification.error({
            message: 'Error saving description',
            description: '',
          });
        });
    }
  }

  private getTableColumns = (): DataTableColumnProps<CommitteeUploadReportDTO>[] => {
    const tableColumns: DataTableColumnProps<CommitteeUploadReportDTO>[] = [
      DataTableColumnUtil.Date('Uploaded Date', 'generatedOn', 100, { defaultSortOrder: 'descend' }),
      DataTableColumnUtil.Text('File Name', 'userFileName'),
      DataTableColumnUtil.Text('Description', 'description'),
      DataTableColumnUtil.Buttons('committeeId',
        [
          {
            text: 'View',
            onClick: (rowData) => this.viewFile(rowData),
          },
          {
            text: 'Edit',
            onClick: (rowData) => this.showDescription(rowData)
          },
          {
            text: 'Delete',
            onClick: (rowData) => this.confirmDelete(rowData)
          },
        ],
        169),
    ];

    return tableColumns;
  }

  private fetchTableData = (
    requestState: TableRequestDTO,
    checkEcho: () => boolean,
    callback: (response: TableResponseDTO<CommitteeUploadReportDTO>) => void
  ) => {
    this.setState({ loadingDocuments: true });

    CommitteeImportApiService.getUploadList(requestState, CurrentUser.Get()?.committeeId || '')
      .then((results) => {
        if (!checkEcho()) {
          return;
        }

        this.setState({
          documentsList: results.results || [],
          loadingDocuments: false
        });

        callback(results);
      })
      .catch((error: any) => {
        this.setState({ loadingDocuments: false });
        notification.error({
          message: error.message,
          description: error.description,
        });
      });
  }

  private confirmDelete = (rowData: CommitteeUploadReportDTO) => {
    confirm({
      title: 'Are you sure you want to delete this file?',
      content: 'The following file will be permanently deleted: ' + rowData.userFileName,
      onOk: () => {
        this.delete(rowData);
      },
    });
  }

  private delete = (rowData: CommitteeUploadReportDTO) => {
    if (rowData.blobFileName != null) {
      this.setState({ loadingDocuments: true });

      CommitteeImportApiService.deleteImport(rowData.id)
        .then(() => {
          if (this.dataTable) {
            this.dataTable.refresh();
          }

          notification.success({ message: 'Success' });
        }).catch(() => {
          notification.error({
            message: 'Failed to delete document.',
            description: '',
          });
        }).finally(() => {
          this.setState({ loadingDocuments: false });
        });
    }
  }

  private viewFile = (rowData: CommitteeUploadReportDTO) => {
    if (rowData.blobFileName != null) {
      CommitteeImportApiService.getBlob(rowData.blobFileName || '')
        .then(uri => window.open(uri, '_blank'))
        .catch(() => {
          notification.error({
            message: 'Error retrieving file.',
            description: '',
          });
        });
    }
    else {
      return '';
    }
  }
}

export default CommitteeImports;
