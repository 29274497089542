import { Button, FormInstance, notification, Select, Space} from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import * as React from 'react';
import CommitteeTypeDTO from '../../../models/CommitteeTypeDTO';
import DataTable, { DataTableColumnProps } from '../../shared/DataTable/DataTable';
import AddressReportDTO from '../../../models/AddressReportDTO';
import DataTableColumnUtil from '../../shared/DataTable/DataTableColumnUtil';
import AdminReportApiService from '../../../api/AdminReportApiService';
import DataTableButtonUtil from '../../shared/DataTable/DataTableButtonUtil';
import FormValidationUtil from '../../../utils/FormValidationUtil';
import CustomForm from '../../shared/CustomForm';
import moment from 'moment';
import * as StorageUtil from '../../../storage/StorageUtil';
import TableResponseDTO from '../../../models/TableResponseDTO';
import TableRequestDTO from '../../../models/TableRequestDTO';
import ReportTableExportDTO from '../../../models/ReportTableExportDTO';

const Option = Select.Option;

interface AddressReportProps {
  committeeTypes: CommitteeTypeDTO[];
}

interface AddressReportState {
  addressTableColumns: DataTableColumnProps<AddressReportDTO>[];
  addressData: AddressReportDTO[];
  addressLoading: boolean;
  addressExporting: boolean;
  formValues: any; 
}

class AddressReport extends React.Component<AddressReportProps, AddressReportState> {
  private readonly _addressFormRef = React.createRef<FormInstance>();
  constructor(props: AddressReportProps) {
    super(props);

    this.state = {
      addressTableColumns: this.getAddressTableColumns(),
      addressData: [],
      addressLoading: false,
      addressExporting: false,
        formValues: { committeeType: JSON.parse(StorageUtil.getAddressReportColumnFilters()).committeeType },
    };
  }

  private addressDataTable: DataTable<AddressReportDTO> | undefined;

  componentDidMount() {
    if (StorageUtil.getAddressReportColumnFilters() != '{}') {
      this.search();
    }
  }

  render() {
    const { addressTableColumns, addressLoading, addressExporting, formValues } = this.state;
    const { committeeTypes } = this.props;
    const dataTableButtons = [];

    dataTableButtons.push(DataTableButtonUtil.Reset());
    dataTableButtons.push(DataTableButtonUtil.Export(
      (tableRequest: TableRequestDTO) => {
        this.setState({ addressExporting: true });
        const exportDTO = ReportTableExportDTO.create({ TableRequest: tableRequest, CommitteeType: this.state.formValues.committeeType.toString() });

        return AdminReportApiService.exportAddressListDataTable(exportDTO)
          .then(() => {
            this.setState({ addressExporting: false });
          }).catch(() => {
            notification.error({
              message: 'Error while exporting address list'
            });
          });
      }, addressExporting));
       
    return (
      <>
        <CustomForm formRef={this._addressFormRef}
          layout="vertical"
          validateTrigger={['onChange', 'onBlur']}
          initialValues={{ ...formValues}}>
          <FormItem name="committeeType" label="Committee Type" rules={[FormValidationUtil.RequiredNumber('Committee Type is required')]}>
            <Select mode="multiple" showArrow={true} placeholder={'Select Committee Type(s)'} onChange={this.handleOnChange}>
              {committeeTypes.map(ct => (
                <Option key={ct.code || ''} value={ct.code?.toString() || ''}>{ct.name}</Option>
              ))}
            </Select>
          </FormItem>
          <Space>
            <Button type="primary" onClick={() => this.search()}>Search</Button>
          </Space>
        </CustomForm>
        <br />
        <br />
        <DataTable
          ref={(element: any) => (this.addressDataTable = element)}
          globalSearch={true}
          columns={addressTableColumns}
          buttonBar={dataTableButtons}
          serverSide={true}
          fetchData={this.fetchAddressReportData}
          tableProps={{
            rowKey: 'committeeName',
            sortDirections: ['ascend', 'descend'],
            locale: {
              emptyText: 'No records were found that match your criteria'
            },
            loading: addressLoading
          }}
          styleOptions={{ compact: true, alternatingRowHighlight: true }}
          title={'Address Report'}
          stateSaving={{
            enabled: true,
            tableUniqueKey: 'addressReports',
            perSession: true,
          }}
        />
      </>
    );
  }

  private handleOnChange = (values: any[]) => {
      this.setState({ formValues: { committeeType: [...values]}});
  }

  private getAddressTableColumns = (): DataTableColumnProps<AddressReportDTO>[] => {
    return [
      DataTableColumnUtil.Text('Committee', 'committeeNameAndCode', null, { defaultSortOrder: 'ascend' }),
      DataTableColumnUtil.Number('District', 'districtNumber'),
      DataTableColumnUtil.Text('Party', 'partyName'),
      DataTableColumnUtil.Number('Election Year', 'electionYear'),
      DataTableColumnUtil.Date('Election Date', 'electionDate'),
      DataTableColumnUtil.Text('Office Sought', 'officeSought'),
      DataTableColumnUtil.Text('County', 'countyName'),
      DataTableColumnUtil.AddressWithContact('Candidate', 'candidateName',
        (c) => ({
          name: c.candidateName,
          line1: c.candidateAddressLine1,
          line2: c.candidateAddressLine2,
          city: c.candidateCity,
          state: c.candidateState,
          zip: c.candidateZip,
          phone: c.candidatePhone,
          email: c.candidateEmail
        })
      ),
      DataTableColumnUtil.AddressWithContact('Chairholder', 'chairholderName',
        (c) => ({
          name: c.chairholderName,
          line1: c.chairholderAddressLine1,
          line2: c.chairholderAddressLine2,
          city: c.chairholderCity,
          state: c.chairholderState,
          zip: c.chairholderZip,
          phone: c.chairholderPhone,
          email: c.chairholderEmail
        })
      ),
      DataTableColumnUtil.AddressWithContact('Treasurer', 'treasurerName',
        (c) => ({
          name: c.treasurerName,
          line1: c.treasurerAddressLine1,
          line2: c.treasurerAddressLine2,
          city: c.treasurerCity,
          state: c.treasurerState,
          zip: c.treasurerZip,
          phone: c.treasurerPhone,
          email: c.treasurerEmail
        })
      ),
      DataTableColumnUtil.Address('Parent', 'parentName',
        (c) => ({
          name: c.parentName,
          line1: c.parentAddressLine1,
          line2: c.parentAddressLine2,
          city: c.parentCity,
          state: c.parentState,
          zip: c.parentZip,
        })
      ),
      DataTableColumnUtil.AddressWithContact('Contact', 'contactName',
        (c) => ({
          name: c.contactName,
          line1: c.contactAddressLine1,
          line2: c.contactAddressLine2,
          city: c.contactCity,
          state: c.contactState,
          zip: c.contactZip,
          phone: c.contactPhone
        })
      ),
    ];
  }

  private search = () => {
    this.addressDataTable?.refresh();
  }

  private fetchAddressReportData = (requestState: TableRequestDTO,
  checkEcho: () => boolean,
  callback: (response: TableResponseDTO<AddressReportDTO>) => void
  ) => {
    this.setState({ addressLoading: true });
    if (this.state.formValues.committeeType != null) {
      return AdminReportApiService.getAddressListDataTable(requestState, this.state.formValues.committeeType.toString())
        .then((res) => {
          if (!checkEcho()) {
            return;
          }
          callback(res);
            res.results?.forEach(a => {
            if (moment(a.electionDate).format('DD MMM YYYY') == moment('0001-01-01').format('DD MMM YYYY')) {
                a.electionDate = '';
             }
            if (a.electionYear == 0)
            {
               a.electionYear = null;
            }
            });
          this.setState({ addressLoading: false });
          const filterString = JSON.stringify(this.state.formValues);
          StorageUtil.setAddressReportColumnFilters(filterString);
        }).catch(() => {
          notification.error({
            message: 'Error while fetching address list'
          });
          this.setState({ addressLoading: false });
        });
    }
    else {
      this.setState({ addressLoading: false });
    }
  }
}

export default AddressReport;
