// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';

interface SchEInKindContributionsFormDTO { 
  contributorType: string | null;
  contributorName: string | null;
  firstName: string | null;
  middleInitial: string | null;
  lastName: string | null;
  contributorAutoFillId: string | null;
  date: moment.Moment | string | null;
  expenseType: string | null;
  amount: number;
  amountRemaining: number | null;
  isFromFundraiser: boolean;
  explanation: string | null;
  addressLine1: string | null;
  addressLine2: string | null;
  city: string | null;
  state: string | null;
  zip: string | null;
  phoneNumber: string | null;
  relationshipCode: string | null;
  isLivingOrRevocableTrust: boolean;
  trustee: string | null;
  trustor: string | null;
  auditorsNoteId: string | null;
}
const SchEInKindContributionsFormDTO = {
  create: (initValues?: Partial<SchEInKindContributionsFormDTO> | Record<string, unknown> | null) => {
    return Object.assign(
    {
      contributorType: null,
      contributorName: null,
      firstName: null,
      middleInitial: null,
      lastName: null,
      contributorAutoFillId: null,
      date: null,
      expenseType: null,
      amount: 0,
      amountRemaining: null,
      isFromFundraiser: false,
      explanation: null,
      addressLine1: null,
      addressLine2: null,
      city: null,
      state: null,
      zip: null,
      phoneNumber: null,
      relationshipCode: null,
      isLivingOrRevocableTrust: false,
      trustee: null,
      trustor: null,
      auditorsNoteId: null,
    },
    initValues);
  }
};

export default SchEInKindContributionsFormDTO;