import { Button, Col, Layout, Row, Space, Typography } from 'antd';
import React from 'react';
import DataTable, { DataTableColumnProps } from '../../shared/DataTable/DataTable';
import DataTableButtonUtil from '../../shared/DataTable/DataTableButtonUtil';
import DataTableColumnUtil from '../../shared/DataTable/DataTableColumnUtil';
import PublicReportsApiService from '../../../api/PublicReportsApiService';
import HistoryUtil from '../../../utils/HistoryUtil';
import Routes from '../../../consts/Routes';
import { ArrowLeftOutlined } from '@ant-design/icons';
import DualCompReportsDTO from '../../../models/DualCompReportsDTO';

const { Content } = Layout;

interface DualCompReportsProps {
}

interface DualCompReportsState {
    tableColumns: DataTableColumnProps<DualCompReportsDTO>[];
}

class DualCompReports extends React.Component<DualCompReportsProps, DualCompReportsState> {
    constructor(props: DualCompReportsProps) {
        super(props);

        this.state = {
            tableColumns: this.getTableColumns()
        };
    }

    render() {
        const actionButtons = [];
        actionButtons.push(DataTableButtonUtil.Reset());

        return (
            <Content className="content-pad">
                <Row>
                    <Col xs={24}>
                        <DataTable
                            serverSide={true}
                            tableProps={{
                                rowKey: 'id',
                                sortDirections: ['ascend', 'descend'],
                                locale: {
                                    emptyText: 'No Reports Currently Match Your Filter'
                                }
                            }}
                            globalSearch={true}
                            buttonBar={actionButtons}
                            columns={this.state.tableColumns}
                            fetchData={{
                                fetch: function (tableRequest) {
                                    return PublicReportsApiService.getDualCompReports(tableRequest);
                                },
                                failureMessage: 'Failed to retrieve public reports'
                            }}
                            styleOptions={{ compact: true, alternatingRowHighlight: true }}
                            title={
                                <Button type="link" onClick={() => HistoryUtil.push(Routes.generate(Routes.PUBLIC_REPORTS))}>
                                    <Typography.Title level={4}>
                                        <Space size="large">
                                            <ArrowLeftOutlined className="public-report-links" />Dual Executive Branch Compensation
                                        </Space>
                                    </Typography.Title>
                                </Button>
                            }
                        />
                    </Col>
                </Row>
            </Content>
        );
    }

    private getTableColumns = () => {
        const columns: DataTableColumnProps<DualCompReportsDTO>[] = [];

        columns.push(DataTableColumnUtil.Text('Official or Employee Name', 'officialName'));
        columns.push(DataTableColumnUtil.Text('Original Executive Agency', 'originalExecutiveAgencyName'));
        columns.push(DataTableColumnUtil.Text('Secondary Executive Agency', 'secondaryExecutiveAgency'));
        columns.push(DataTableColumnUtil.Currency('Amount', 'compensationAmount', 2));
        columns.push(DataTableColumnUtil.Text('Description', 'serviceDescription'));
        columns.push(DataTableColumnUtil.Date('Accepted Date', 'acceptanceDate'));
        columns.push(DataTableColumnUtil.Date('Filed Date', 'filedDate', null, { defaultSortOrder: 'descend' }));

        const buttonColumns = [
            { text: 'View', onClick: (rowData: DualCompReportsDTO) => window.open(rowData.fileUrl || '') }
        ];

        columns.push(DataTableColumnUtil.Buttons('Actions', buttonColumns, 150));

        return columns;
    }
}

export default DualCompReports;