import CurrentUser from './CurrentUser';

export default class RoleUtil {
  public static currentUserHasRoles(roles: number[]): boolean {
    const currentUser = CurrentUser.Get();

    if (currentUser) {
      for (const role of roles) {
        if (currentUser.isInRole(role)) {
          return true;
        }
      }
    }

    return false;
  }
}
