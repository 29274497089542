// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
import BaseApi from './BaseApi';
import BaseScheduleInfoDTO from '../models/BaseScheduleInfoDTO';
import CommitteeInfoDTO from '../models/CommitteeInfoDTO';
import ScheduleInfoDTO from '../models/ScheduleInfoDTO';

export class ScheduleApiService extends BaseApi {

  // get: api/schedule/person/${encodeURIComponent(perorgId)}?committeeId=${encodeURIComponent(committeeId)}
  public getPerson(perorgId: string, committeeId: string): Promise<BaseScheduleInfoDTO> {
    let url = `api/schedule/person/${encodeURIComponent(perorgId)}`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (committeeId != null) {
      url += `${prefix}committeeId=${encodeURIComponent(committeeId)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, BaseScheduleInfoDTO>(null, 'get', url, true, false);
  }

  // get: api/schedule/${encodeURIComponent(committeeId)}/candidate
  public getCandidate(committeeId: string): Promise<BaseScheduleInfoDTO> {
    let url = `api/schedule/${encodeURIComponent(committeeId)}/candidate`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, BaseScheduleInfoDTO>(null, 'get', url, true, false);
  }

  // get: api/schedule/organization/${encodeURIComponent(perorgId)}?committeeId=${encodeURIComponent(committeeId)}
  public getOrganization(perorgId: string, committeeId: string): Promise<BaseScheduleInfoDTO> {
    let url = `api/schedule/organization/${encodeURIComponent(perorgId)}`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (committeeId != null) {
      url += `${prefix}committeeId=${encodeURIComponent(committeeId)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, BaseScheduleInfoDTO>(null, 'get', url, true, false);
  }

  // get: api/schedule/${encodeURIComponent(committeeId)}/committee
  public getCommittee(committeeId: string): Promise<CommitteeInfoDTO> {
    let url = `api/schedule/${encodeURIComponent(committeeId)}/committee`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, CommitteeInfoDTO>(null, 'get', url, true, false);
  }

  // get: api/schedule/${encodeURIComponent(committeeId)}/committeeAddress
  public getCommitteeAddress(committeeId: string): Promise<BaseScheduleInfoDTO> {
    let url = `api/schedule/${encodeURIComponent(committeeId)}/committeeAddress`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, BaseScheduleInfoDTO>(null, 'get', url, true, false);
  }

  // get: api/schedule/${encodeURIComponent(dr2Id)}/${encodeURIComponent(scheduleAbbreviationCode)}/total
  public getScheduleTotal(dr2Id: string, scheduleAbbreviationCode: string): Promise<number> {
    let url = `api/schedule/${encodeURIComponent(dr2Id)}/${encodeURIComponent(scheduleAbbreviationCode)}/total`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, number>(null, 'get', url, true, false);
  }

  // get: api/schedule/${encodeURIComponent(dr2Id)}/${encodeURIComponent(scheduleAbbreviationCode)}/count
  public getScheduleCount(dr2Id: string, scheduleAbbreviationCode: string): Promise<number> {
    let url = `api/schedule/${encodeURIComponent(dr2Id)}/${encodeURIComponent(scheduleAbbreviationCode)}/count`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, number>(null, 'get', url, true, false);
  }

  // get: api/schedule/${encodeURIComponent(dr2Id)}/${encodeURIComponent(scheduleAbbreviationCode)}
  public getSchedule(dr2Id: string, scheduleAbbreviationCode: string): Promise<ScheduleInfoDTO> {
    let url = `api/schedule/${encodeURIComponent(dr2Id)}/${encodeURIComponent(scheduleAbbreviationCode)}`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, ScheduleInfoDTO>(null, 'get', url, true, false);
  }
}
var service = new ScheduleApiService();
export default service;
