import { notification } from 'antd';
import { FormInstance } from 'antd/lib/form/Form';
import * as React from 'react';
import Dr2ApiService  from '../../api/Dr2ApiService';
import CurrentUser from '../../utils/CurrentUser';
import AdjustBalanceForm from '../shared/AdjustBalanceForm';

interface AdjustBalanceProps {
  close: () => void;
}

interface AdjustBalanceState {
  beginningAmount: number;
}

class AdjustBalance extends React.Component<AdjustBalanceProps, AdjustBalanceState> {
  private readonly _formRef = React.createRef<FormInstance>();
  constructor(props: AdjustBalanceProps) {
    super(props);
    this.state = {
      beginningAmount: 0
    };
  }

  componentDidMount() {
    this.getBeginningAmount();
  }

  render() {
    const { beginningAmount } = this.state;
    return (
        <AdjustBalanceForm formRef={this._formRef} startingBalance={beginningAmount} onSave={this.handleSave} onClose={this.props.close}/>
    );
  }
  private getBeginningAmount = () => {
    Dr2ApiService.getBeginningBalance(CurrentUser.Get()?.dr2Id || '')
      .then(balance => {
        this.setState({ beginningAmount: balance });
        this._formRef.current?.setFieldsValue({ startingBalance: balance });
      });
  }

  private handleSave = () => {
    this._formRef.current?.validateFields()
      .then(values => {
        Dr2ApiService.saveAdjustBalance(values.startingBalance, CurrentUser.Get()?.dr2Id || '')
          .then(result => {
            if (result.succeeded) {
              notification.success({
                message: 'Saved successfully'
              });
              this.props.close();
            }
            else {
              notification.error({
                message: result.errors[0].toString()
              });
            }
          });
      });
  }
}

export default AdjustBalance;