// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Constants.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
export default class NoteEntityParents {
    public static readonly COMMITTEE: string = 'Committee';
    public static readonly SCHEDULE: string = 'Schedule';
    public static readonly DR2: string = 'DR2';
    public static readonly CONTRIBUTION: string = 'Contribution';
    public static readonly EXPENDITURE: string = 'Expenditure';
    public static readonly DEBT: string = 'Debt';
    public static readonly INKINDCONTRIBUTION: string = 'InKindContribution';
    public static readonly LOANRECEIVED: string = 'LoanReceived';
    public static readonly LOANREPAID: string = 'LoanRepaid';
    public static readonly CONSULTANTEXPENDITURE: string = 'ConsultantExpenditure';
    public static readonly CONSULTANTSUMMARY: string = 'ConsultantSummary';
    public static readonly CAMPAIGNPROPERTY: string = 'CampaignProperty';
    public static readonly CAMPAIGNPROPERTYSALE: string = 'CampaignPropertySale';
    public static readonly VSR: string = 'Vsr';
}
