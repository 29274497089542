// All globals must be added to the custom_typings\index.ts file so typescript knows about them.

const currency5 = new Intl.NumberFormat(undefined, {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 5,
  minimumFractionDigits: 5,
}).format;
const currency2 = new Intl.NumberFormat(undefined, {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
}).format;
const quantity0 = new Intl.NumberFormat(undefined, {
  maximumFractionDigits: 0,
  minimumFractionDigits: 0,
}).format;
const quantity2 = new Intl.NumberFormat(undefined, {
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
}).format;
const quantity5 = new Intl.NumberFormat(undefined, {
  maximumFractionDigits: 5,
  minimumFractionDigits: 5,
}).format;

export default function addGlobals() {
  // eslint-disable-next-line
  global['FORMAT'] = {
    currency5: (value: number) => currency5(roundTo(value, 5)),
    currency2: (value: number) => currency2(roundTo(value, 2)),
    quantity0: (value: number) => quantity0(roundTo(value, 0)),
    quantity2: (value: number) => quantity2(roundTo(value, 2)),
    quantity5: (value: number) => quantity5(roundTo(value, 5)),
  };

  // eslint-disable-next-line
  global['tryParseInt'] = function (value: any, defaultValue: number) {
    let retValue: number = defaultValue;
    if (value != null) {
      if (!isNaN(value) && typeof value === 'string' && value !== '') {
        retValue = parseInt(value, 10);
      } else if (typeof value === 'number') {
        retValue = Math.floor(value);
      }
      if (!isNaN(retValue)) {
        return retValue;
      }
    }
    return defaultValue;
  };

  // eslint-disable-next-line
  global['roundTo'] = function (num: number, digits: number) {
    digits = digits || 0;
    const multiplicator = Math.pow(10, digits);
    num = Math.round((num + 0.000001) * multiplicator) / multiplicator;
    return num;
  };

  // eslint-disable-next-line
  global['zeroPad'] = function (val: any, digits: number) {
    return ('0'.repeat(digits) + val).substr(-digits, digits);
  };
}
