// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
import BaseApi from './BaseApi';
import DR1DTO from '../models/DR1DTO';
import CommitteeInfoDTO from '../models/CommitteeInfoDTO';
import CommitteeFilingDTO from '../models/CommitteeFilingDTO';
import ResultDTO from '../models/ResultDTO';

export class OrganizationApiServices extends BaseApi {

  // get: api/organizations/${encodeURIComponent(committeeid)}/filingDate?filingDate=${encodeURIComponent(filingDate)}&filerType=${filerType}
  public getDr1OrgDetails(committeeid: string, filingDate: string, filerType: number): Promise<DR1DTO> {
    let url = `api/organizations/${encodeURIComponent(committeeid)}/filingDate`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (filingDate != null) {
      url += `${prefix}filingDate=${encodeURIComponent(filingDate)}`;
      prefix = '&';
    }
    if (filerType != null) {
      url += `${prefix}filerType=${filerType}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, DR1DTO>(null, 'get', url, true, false);
  }

  // get: api/organizations/${encodeURIComponent(committeeId)}
  public getCommitteeInfo(committeeId: string): Promise<CommitteeInfoDTO> {
    let url = `api/organizations/${encodeURIComponent(committeeId)}`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, CommitteeInfoDTO>(null, 'get', url, true, false);
  }

  // get: api/organizations/effectiveDate?committeeId=${encodeURIComponent(committeeId)}
  public getLastCommDetailDate(committeeId: string): Promise<string> {
    let url = `api/organizations/effectiveDate`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (committeeId != null) {
      url += `${prefix}committeeId=${encodeURIComponent(committeeId)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, string>(null, 'get', url, true, false);
  }

  // get: api/organizations/${encodeURIComponent(committeeId)}/filings
  public getCommitteeFilingList(committeeId: string): Promise<CommitteeFilingDTO[]> {
    let url = `api/organizations/${encodeURIComponent(committeeId)}/filings`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, CommitteeFilingDTO[]>(null, 'get', url, true, false);
  }

  // post: api/organizations/${encodeURIComponent(committeeId)}/save?filingStatus=${encodeURIComponent(filingStatus)}&filingDate=${encodeURIComponent(filingDate)}
  public editDR1(dR1: DR1DTO, committeeId: string, filingStatus: string, filingDate: string): Promise<ResultDTO> {
    let url = `api/organizations/${encodeURIComponent(committeeId)}/save`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (filingStatus != null) {
      url += `${prefix}filingStatus=${encodeURIComponent(filingStatus)}`;
      prefix = '&';
    }
    if (filingDate != null) {
      url += `${prefix}filingDate=${encodeURIComponent(filingDate)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, ResultDTO>(dR1, 'post', url, true, false);
  }

  // get: api/organizations?uId=${encodeURIComponent(uId)}
  public getCommitteeFilingById(uId: string): Promise<CommitteeFilingDTO> {
    let url = `api/organizations`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (uId != null) {
      url += `${prefix}uId=${encodeURIComponent(uId)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, CommitteeFilingDTO>(null, 'get', url, true, false);
  }

  // put: api/organizations/reject?committeeId=${encodeURIComponent(committeeId)}
  public reject(committeeId: string): Promise<void> {
    let url = `api/organizations/reject`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (committeeId != null) {
      url += `${prefix}committeeId=${encodeURIComponent(committeeId)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, void>(null, 'put', url, true, false);
  }

  // get: api/organizations/email?committeeId=${encodeURIComponent(committeeId)}
  public getDr1UserEmails(committeeId: string): Promise<string> {
    let url = `api/organizations/email`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (committeeId != null) {
      url += `${prefix}committeeId=${encodeURIComponent(committeeId)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, string>(null, 'get', url, true, false);
  }

  // get: api/organizations/printDr1?committeeId=${encodeURIComponent(committeeId)}&effectiveTs=${encodeURIComponent(String(effectiveTs))}
  public printDr1Report(committeeId: string, effectiveTs: string): Promise<void> {
    let url = `api/organizations/printDr1`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (committeeId != null) {
      url += `${prefix}committeeId=${encodeURIComponent(committeeId)}`;
      prefix = '&';
    }
    if (effectiveTs != null) {
      url += `${prefix}effectiveTs=${encodeURIComponent(effectiveTs)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, void>(null, 'get', url, true, true);
  }

  // get: api/organizations/deleteCommittee?committeeId=${encodeURIComponent(committeeId)}
  public deleteCommittee(committeeId: string): Promise<ResultDTO> {
    let url = `api/organizations/deleteCommittee`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (committeeId != null) {
      url += `${prefix}committeeId=${encodeURIComponent(committeeId)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, ResultDTO>(null, 'get', url, true, false);
  }

  // get: api/organizations/filingCount?committeeId=${encodeURIComponent(committeeId)}
  public filingCount(committeeId: string): Promise<number> {
    let url = `api/organizations/filingCount`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (committeeId != null) {
      url += `${prefix}committeeId=${encodeURIComponent(committeeId)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, number>(null, 'get', url, true, false);
  }
}
var service = new OrganizationApiServices();
export default service;
