import { Divider } from 'antd';
import * as React from 'react';
import SchBExpendituresApiService from '../../api/SchBExpendituresApiService';
import SchBExpendituresDTO from '../../models/SchBExpendituresDTO';
import DataTableColumnUtil from '../shared/DataTable/DataTableColumnUtil';
import DataTable, { DataTableColumnProps } from '../shared/DataTable/DataTable';
import Dr2AmendedStatuses from '../../consts/Dr2AmendedStatuses';
import Dr2AmendedIndicators from '../../consts/Dr2AmendedIndicators';

interface SchBExpenditureHistoryTableProps {
  expenditureId: string;
}

interface SchBExpenditureHistoryTableState {
}

class SchBExpenditureHistoryTable extends React.Component<SchBExpenditureHistoryTableProps, SchBExpenditureHistoryTableState> {
  constructor(props: SchBExpenditureHistoryTableProps) {
    super(props);

    this.state = {
    };
  }

  render() {
    const { expenditureId } = this.props;
    return (
      <>
        <Divider/>
        <DataTable
          columns={this.getTableColumns()}
          fetchData={{
            fetch: function (tableRequest) { return SchBExpendituresApiService.getExpenditureHistory(tableRequest, expenditureId); },
            failureMessage: 'Failed to retrieve expenditure history'
          }}
          serverSide={true}
          styleOptions={{ compact: true, alternatingRowHighlight: true }}

          tableProps={{
            rowKey: 'expenditureIdAndSeq',
            sortDirections: ['ascend', 'descend'],
            locale: { emptyText: 'Expenditure history not available.' }
          }}
          title= "Expenditure"
        />
      </>
    );
  }

  private getTableColumns = (): DataTableColumnProps<SchBExpendituresDTO>[] => {
    const tableColumns: DataTableColumnProps<SchBExpendituresDTO>[] = [
      DataTableColumnUtil.Date('Date', 'expenditureDt', 100),
      DataTableColumnUtil.Address('Payee', 'expenditureName',
        (c) => ({
          name: c.expenditureName,
          line1: c.expenditureAddressLine1,
          line2: c.expenditureAddressLine2,
          city: c.expenditureCity,
          state: c.expenditureState,
          zip: c.expenditureZip
        })
      ),
      DataTableColumnUtil.Currency('Amount', 'expenditureAmt'),
      DataTableColumnUtil.Text('Check #', 'checkNumber', 125, { align: 'center' }),
      DataTableColumnUtil.DropdownMulti('Status', 'status',
        [
          { text: Dr2AmendedStatuses.ORIGINAL, value: Dr2AmendedIndicators.ORIGINAL },
          { text: Dr2AmendedStatuses.AMENDED, value: Dr2AmendedIndicators.AMENDED },
          { text: Dr2AmendedStatuses.ADJUSTED, value: Dr2AmendedIndicators.ADJUSTED },
          { text: Dr2AmendedStatuses.DELETED, value: Dr2AmendedIndicators.DELETED }
        ])
    ];
    return tableColumns;
  }
}

export default SchBExpenditureHistoryTable;
