import {
  Button,
  Checkbox,
  Col,
  ColProps,
  FormInstance,
  Input,
  InputNumber,
  notification,
  Modal,
  Row,
  Select,
  Space,
  Typography,
} from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import TextArea from 'antd/lib/input/TextArea';
import AddressForm from '../shared/AddressFormFields';
import * as React from 'react';
import LookupsApiService from '../../api/LookupsApiService';
import SchBExpendituresApiService from '../../api/SchBExpendituresApiService';
import ScheduleApiService from '../../api/ScheduleApiService';
import CodeLookupTableDTO from '../../models/CodeLookupTableDTO';
import SchBExpendituresFormDTO from '../../models/SchBExpendituresFormDTO';
import CommitteeInfoDTO from '../../models/CommitteeInfoDTO';
import FormValidationUtil from '../../utils/FormValidationUtil';
import Dr2GroupTypes from '../../consts/Dr2GroupTypes';
import CurrentUser from '../../utils/CurrentUser';
import HistoryUtil from '../../utils/HistoryUtil';
import Routes from '../../consts/Routes';
import { RouteComponentProps } from 'react-router';
import { Content } from 'antd/lib/layout/layout';
import moment from 'moment';
import PersonSearch from '../shared/PersonSearch';
import BaseScheduleInfoDTO from '../../models/BaseScheduleInfoDTO';
import ExpenditureType from '../../consts/ExpenditureType';
import EarmarkInfoDTO from '../../models/EarmarkInfoDTO';
import ScheduleAbbreviationCodes from '../../consts/ScheduleAbbreviationCodes';
import ExpenditureTypeDTO from '../../models/ExpenditureTypeDTO';
import SchBExpenditureHistoryTable from './SchBExpenditureHistoryTable';
import CommitteeAutoSearch from '../shared/CommitteeSearch';
import NotesModal from '../shared/NotesModal';
import NoteEntityParents from '../../consts/NoteEntityParents';
import CurrencyInput from '../shared/CurrencyInput';
import CustomDatePicker from '../shared/CustomDatePicker';
import CustomForm from '../shared/CustomForm';
import OrganizationAutoSearch from '../shared/OrganizationSearch';
import CommitteeType from '../../consts/CommitteeType';

const Option = Select.Option;
const confirm = Modal.confirm;

interface SchBExpendituresFormProps {
  id?: string;
  seqNum?: string;
  debtId?: string;
}

interface SchBExpendituresFormState {
  relations: CodeLookupTableDTO[];
  expenseTypes: ExpenditureTypeDTO[];
  currPayeeType: string;
  currExpenseType: string;
  currComm: CommitteeInfoDTO;
  isEarmarked: boolean;
  earmarkOptions: EarmarkInfoDTO[];
  btnDisable: boolean;
  expenditureInfo: SchBExpendituresFormDTO;
  expenditureId?: string;
  debtId: string;
  startDt: moment.Moment;
  endDt: moment.Moment;
  debtAmt: number;
  loading: boolean;
  trusteeTrustorReq: boolean;
  seqNum: number;
  autoFillPerorg: boolean;
  hideRelationship: boolean;
}

class SchBExpendituresForm extends React.Component<RouteComponentProps<SchBExpendituresFormProps>, SchBExpendituresFormState> {
  private readonly _formRef = React.createRef<FormInstance>();

  constructor(props: RouteComponentProps<SchBExpendituresFormProps>) {
    super(props);
    this.state = {
      relations: [],
      expenseTypes: [],
      currPayeeType: '',
      currExpenseType: '',
      currComm: CommitteeInfoDTO.create(),
      isEarmarked: false,
      earmarkOptions: [],
      btnDisable: false,
      expenditureId: this.props.match.params.id,
      debtId: this.props.match.params.debtId || '',
      expenditureInfo: SchBExpendituresFormDTO.create({
        payeeType: '',
        state: 'IA',
        relationshipCode: '',
        amount: null,
        expenseType: '',
        earmarkedContributionId: ''
      }),
      startDt: moment.utc(),
      endDt: moment.utc(),
      debtAmt: 0,
      loading: !!this.props.match.params.id || !!this.props.match.params.debtId,
      trusteeTrustorReq: false,
      seqNum: Number(this.props.match.params.seqNum) || 0,
      autoFillPerorg: false,
      hideRelationship: false,
    };
  }

  componentDidMount() {
    this.loadLookups();
    this.getEarmarkDropdownOptions();
    this.getUserCommittee();
    this.getSchedule();
    if (this.props.match.params.id) {
      this.getExpenditure(this.props.match.params.id || '', this.props.match.params.seqNum || '');
    }
    else if (this.props.match.params.debtId) {
      this.getDebt(this.props.match.params.debtId || '', this.props.match.params.seqNum || '');
    }
  }

  render() {
    const { relations, expenseTypes, currPayeeType, currExpenseType, currComm, isEarmarked,
      earmarkOptions, btnDisable, expenditureId, expenditureInfo, startDt, endDt, loading,
      trusteeTrustorReq, seqNum, autoFillPerorg, hideRelationship } = this.state;

    const columnSizingProps: ColProps = { xs: 24, sm: 24, md: 12 };
    const isCandidateCommittee = currComm.filerTypeName == 'Candidate';
    const isDebt = !!this.props.match.params.debtId;
    const relationReq = currPayeeType === Dr2GroupTypes.INDIVIDUAL && isCandidateCommittee;
    const checkReq = currPayeeType === Dr2GroupTypes.COMMITTEE;

    const explantionReqCodeArr: string[] = [];
    expenseTypes.forEach(e => {
      if (e.explanationRequired) {
        explantionReqCodeArr.push(e.code || '');
      }
    });

    const explanationReq = (currExpenseType && explantionReqCodeArr.indexOf(currExpenseType || '') >= 0);
    const typeTooltip = expenseTypes.find(e => e.code === currExpenseType);
    const minAmount = isDebt ? 0 : Number.MIN_SAFE_INTEGER;

    const payeeTypes = [
      Dr2GroupTypes.INDIVIDUAL,
      Dr2GroupTypes.COMMITTEE,
      Dr2GroupTypes.TRUST,
      Dr2GroupTypes.COMPANYOTHER,
    ];

    return (
      <>
        {!loading &&
          <Content className="content-pad">
            <Typography.Title level={4}>Schedule B - Expenditures</Typography.Title>
            <CustomForm formRef={this._formRef} initialValues={expenditureInfo} layout="vertical" onFinish={this.handleSave}>
              <Row gutter={24}>
                <Col {...columnSizingProps}>
                  <FormItem name="payeeType" label="Payee Type"
                    rules={[FormValidationUtil.Required('Payee Type is required')]} >
                    <Select
                      showSearch={!isDebt}
                      open={isDebt ? !isDebt : undefined}
                      optionFilterProp="children"
                      onChange={this.handlePayeeTypeChange}>
                      <Option value="" disabled={true}>-- Select Type --</Option>
                      {payeeTypes.map(pt => (
                        <Option key={pt} value={pt}>{pt}</Option>
                      ))}
                    </Select>
                  </FormItem>
                  <FormItem name="date" label="Date"
                    rules={[FormValidationUtil.RequiredDate('Date is required'), FormValidationUtil.DateRange(startDt, endDt)]}>
                  <CustomDatePicker onChange={(d) => d?.startOf('day')}/>
                  </FormItem>
                  <FormItem name="expenseType" label="Type"
                    rules={[FormValidationUtil.Required('Type is required')]}
                    tooltip={typeTooltip ? typeTooltip.explanationDescription : 'Select a type'}
                  >
                    <Select
                      showSearch={!isDebt}
                      open={isDebt ? !isDebt : undefined}
                      optionFilterProp="children"
                      onSelect={this.handleExpenseTypeChange}
                    >
                      <Option value="" disabled={true}>-- Select Type --</Option>
                      {expenseTypes.map(et => (
                        <Option key={et.code || ''} value={et.code || ''}>{et.name}</Option>
                      ))}
                    </Select>
                  </FormItem>
                  {currExpenseType === ExpenditureType.MILEAGE && (
                    <>
                      <FormItem name="numberOfMiles" label="Number of Miles"
                        rules={[FormValidationUtil.RequiredNumber('Number of Miles is required')]}>
                        <InputNumber
                          style={{ width: '100%' }}
                          precision={0}
                          max={1000000}
                          min={0}
                          onChange={this.handleNumberOfMilesChange}
                        />
                      </FormItem>
                      <FormItem name="mileageRate" label="Mileage Rate" rules={[FormValidationUtil.RequiredNumber('Mileage Rate is required')]}>
                        <InputNumber style={{ width: '100%' }}
                          precision={3}
                          max={1000}
                          min={0}
                          onChange={this.handleMileageRateChange}
                        />
                      </FormItem>
                    </>
                  )}
                  <FormItem name="amount" label="Amount"
                    rules={[FormValidationUtil.RequiredNumber('Amount is required')]}>
                    <CurrencyInput min={minAmount} readOnly={currExpenseType === ExpenditureType.MILEAGE} onChange={this.handleAmountChange} />
                  </FormItem>
                  {isDebt && (
                    <FormItem name="amountRemaining" label="Amount Remaining"
                      rules={[FormValidationUtil.RequiredNumber('Amount Remaining is required', isDebt),
                      FormValidationUtil.NotNegativeNumber('Amount Remaining must not be negative')]}>
                      <CurrencyInput readOnly={true} />
                    </FormItem>
                  )}
                  <FormItem name="checkNumber" label="Check Number"
                    rules={[
                      FormValidationUtil.Number('Number is invalid'),
                      FormValidationUtil.Required('Check Number is required', checkReq)
                    ]}>
                    <Input maxLength={12} />
                  </FormItem>
                  <FormItem name="explanation" label="Explanation"
                    rules={[FormValidationUtil.Required(
                      'Explanation is required',
                      explanationReq || currExpenseType === ExpenditureType.MISCELLANEOUS_OR_UNITEMIZED
                    )]}>
                    <TextArea
                      placeholder={currExpenseType === ExpenditureType.MILEAGE ? 'Please enter the date range for mileage incurred' : ''}
                      maxLength={2000}
                    />
                  </FormItem>
                </Col>
                <Col {...columnSizingProps}>
                  {currPayeeType === Dr2GroupTypes.INDIVIDUAL && (
                    <>
                      <PersonSearch
                        name={'person'}
                        label={'Search for an Individual'}
                        hidden={isDebt}
                        required={false}
                        committeeId={currComm.id || ''}
                        onSelect={this.getPerson}
                        onChange={this.handlePayeeChange}
                      />
                      {isDebt && (
                        <Row gutter={8}>
                          <Col flex="1">
                            <FormItem name={'firstName'} label={'First Name'}>
                              <Input readOnly={true} />
                            </FormItem>
                          </Col>
                          <Col flex="50px">
                            <FormItem name={'middleInitial'} label={'MI'}>
                              <Input readOnly={true} />
                            </FormItem>
                          </Col>
                          <Col flex="1">
                            <FormItem name={'lastName'} label={'Last Name'}>
                              <Input readOnly={true} />
                            </FormItem>
                          </Col>
                        </Row>
                      )}
                      <AddressForm allDisabled={autoFillPerorg}
                        firstName={{ name: 'firstName', hidden: isDebt }}
                        middleInitial={{ name: 'middleInitial', hidden: isDebt }}
                        lastName={{ name: 'lastName', hidden: isDebt }}
                        address1={{ name: 'addressLine1', hidden: isDebt }}
                        address2={{ name: 'addressLine2', hidden: isDebt }}
                        city={{ name: 'city', hidden: isDebt }}
                        state={{ name: 'state', hidden: isDebt }}
                        zip={{ name: 'zip', hidden: isDebt }}
                        phone={{ name: 'phoneNumber', required: false, hidden: isDebt }}
                      />
                      <FormItem name="relationshipCode" label="Relationship"
                        hidden={ hideRelationship || isDebt}
                        rules={[FormValidationUtil.Required('Relationship is required', relationReq)]}>
                        <Select
                          showSearch disabled={autoFillPerorg}
                          optionFilterProp="children">
                          <Option value="" disabled={true}>-- Select Relationship --</Option>
                          {relations.map(rt => (
                            <Option key={rt.code || ''} value={rt.code || ''}>{rt.name}</Option>
                          ))}
                        </Select>
                      </FormItem>
                    </>
                  )}
                  {currPayeeType === Dr2GroupTypes.COMMITTEE && (
                    <>
                      {isDebt && (
                        <FormItem name={'payeeName'} label="Name">
                          <Input readOnly={true} />
                        </FormItem>
                      )}
                      <CommitteeAutoSearch
                        name="payeeName"
                        label="Name"
                        hidden={isDebt}
                        required={true}
                        requireSelection={true}
                        onSelectCommittee={this.handleCommitteeSelect}
                      />
                    </>
                  )}
                  {(currPayeeType === Dr2GroupTypes.COMPANYOTHER || currPayeeType === Dr2GroupTypes.TRUST) && (
                    <>
                      {isDebt && (
                        <FormItem name={'payeeName'} label="Name">
                          <Input readOnly={true} />
                        </FormItem>
                      )}
                      <OrganizationAutoSearch
                        name={'company'}
                        label={'Search for a Company'}
                        required={false}
                        hidden={isDebt}
                        committeeId={currComm.id || ''}
                        perorgType={currPayeeType}
                        onSelect={this.getOrganization}
                        onChange={this.handlePayeeChange}
                      />
                      <AddressForm allDisabled={autoFillPerorg}
                        name={{ name: 'payeeName', label: 'Company', hidden: isDebt }}
                        address1={{ name: 'addressLine1', hidden: isDebt }}
                        address2={{ name: 'addressLine2', hidden: isDebt }}
                        city={{ name: 'city', hidden: isDebt }}
                        state={{ name: 'state', hidden: isDebt }}
                        zip={{ name: 'zip', hidden: isDebt }}
                        phone={{ name: 'phoneNumber', required: false, hidden: isDebt }}
                      />
                    </>
                  )}
                  {currPayeeType === Dr2GroupTypes.TRUST &&
                    <>
                      <FormItem name="isLivingOrRevocableTrust" valuePropName="checked">
                        <Checkbox onChange={this.handleRevocableTrustCheckboxChange}>Living or Revocable Trust</Checkbox>
                      </FormItem>
                      <FormItem name="trustee" label="Trustee"
                        rules={[FormValidationUtil.Required('Trustee is required', trusteeTrustorReq)]}>
                        <Input disabled={autoFillPerorg} />
                      </FormItem>
                      <FormItem name="trustor" label="Trustor"
                        rules={[FormValidationUtil.Required('Trustor is required', trusteeTrustorReq)]}>
                        <Input disabled={autoFillPerorg} />
                      </FormItem>
                    </>
                  }
                </Col>
              </Row>
              <Row>
                <Col {...columnSizingProps}>
                  <FormItem name="isEarmarked" valuePropName="checked">
                    <Checkbox onChange={this.handleEarmarkCheckboxChange}>Earmarked</Checkbox>
                  </FormItem>
                  {isEarmarked && (
                    <FormItem name="earmarkedContributionId" rules={[FormValidationUtil.Required('Earmarked Contribution is required')]}>
                      <Select
                        showSearch
                        optionFilterProp="children">
                        <Option value="" disabled={true}>-- Select Earmarked Contribution --</Option>
                        {earmarkOptions.map(e => (
                          <Option key={e.earmarkId || ''} value={e.earmarkId || ''}>{e.text || ''}</Option>
                        ))}
                      </Select>
                    </FormItem>
                  )}
                  <FormItem name="payeeAutoFillId" hidden={true}>
                    <Input hidden={true} disabled={true} aria-hidden={true} />
                  </FormItem>
                </Col>
              </Row>
              <Space>
                <Button type="primary" disabled={btnDisable} htmlType="submit"> Save Expenditure </Button>
                <Button type="default" disabled={btnDisable} onClick={this.handleCancel}> Cancel </Button>
                {expenditureId &&
                  <NotesModal
                    parentId={expenditureId}
                    parent={NoteEntityParents.EXPENDITURE}
                    seqNum={seqNum || 0}
                    auditorsNoteId={expenditureInfo.auditorsNoteId || ''} />
                }
              </Space>
            </CustomForm >
            {expenditureId &&
              <SchBExpenditureHistoryTable
                expenditureId={expenditureId || ''}
              />
            }
          </Content >
        }
      </>
    );
  }

  private handlePayeeTypeChange = (payeeType: string) => {
    this.setState({
      currPayeeType: payeeType,
      autoFillPerorg: false,
      trusteeTrustorReq: payeeType == Dr2GroupTypes.TRUST
    });

    this._formRef.current?.setFieldsValue({
      'firstName': '',
      'middleInitial': '',
      'lastName': '',
      'relationshipCode': '',
      'addressLine1': '',
      'addressLine2': '',
      'city': '',
      'state': '',
      'zip': '',
      'phoneNumber': '',
      'trustee': '',
      'trustor': '',
      'person': null,
      'payeeName': null,
      'company': null,
      'isLivingOrRevocableTrust': false,
      'payeeAutoFillId': ''
    });

    if ([Dr2GroupTypes.COMPANYOTHER, Dr2GroupTypes.INDIVIDUAL, Dr2GroupTypes.TRUST].includes(payeeType)) {
      this._formRef.current?.setFieldsValue({ state: 'IA' });
    }
  }

  private handlePayeeChange = (value: string) => {
    this._formRef.current?.setFieldsValue({ payeeAutoFillId: '' });
    this.setState({ autoFillPerorg: !!value });
  }

  private handleCommitteeSelect = (committee: CommitteeInfoDTO | undefined) => {
    this._formRef.current?.setFieldsValue({ payeeAutoFillId: committee?.id });
  }

  private handleExpenseTypeChange = (expenseType: string) => {
    this.setState({ currExpenseType: expenseType });

    // Set the fields if the expense type is mileage
    if (expenseType == '015') {
      this._formRef.current?.setFieldsValue({ numberOfMiles: 0, mileageRate: 0.00, amount: 0 });
    }
  }

  private setCompanyFields(form?: BaseScheduleInfoDTO) {
    if (form) {
      this._formRef.current?.setFieldsValue({
        payeeName: form.committeeOrCompanyName,
        addressLine1: form.addressLine1,
        addressLine2: form.addressLine2,
        city: form.city,
        state: form.state,
        zip: form.zip,
        phoneNumber: form.phoneNumber,
        trustee: form.trustee,
        trustor: form.trustor
      });
    }
    else {
      this._formRef.current?.setFieldsValue({
        payeeName: '',
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        zip: '',
        phoneNumber: '',
        trustee: '',
        trustor: '',
        payeeAutoFillId: ''
      });
      this.setState({ autoFillPerorg: false });
    }
  }

  private setIndividualFields(form?: BaseScheduleInfoDTO) {
    if (form) {
      this._formRef.current?.setFieldsValue({
        payeeType: form.groupType,
        firstName: form.firstName,
        middleInitial: form.middleInitial,
        lastName: form.lastName,
        addressLine1: form.addressLine1,
        addressLine2: form.addressLine2,
        city: form.city,
        state: form.state,
        zip: form.zip,
        phoneNumber: form.phoneNumber,
        relationshipCode: form.relationshipCode,
        payeeAutoFillId: ''
      });
    }
    else {
      this._formRef.current?.setFieldsValue({
        payeeType: Dr2GroupTypes.INDIVIDUAL,
        firstName: '',
        middleInitial: '',
        lastName: '',
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        zip: '',
        phoneNumber: '',
        relationshipCode: '',
        payeeAutoFillId: ''
      });
      this.setState({ autoFillPerorg: false });
    }
    this.setState({ currPayeeType: Dr2GroupTypes.INDIVIDUAL });
  }

  private handleRevocableTrustCheckboxChange = (e: any) => {
    this.setState({ trusteeTrustorReq: !e.target.checked });
  }

  private handleEarmarkCheckboxChange = () => {
    this.setState({ isEarmarked: !this.state.isEarmarked });
  }

  private handleMileageRateChange = (mileageRate: number) => {
    const numMiles = this._formRef.current?.getFieldValue('numberOfMiles') || 0;
    const amt = (numMiles * mileageRate).toFixed(2);
    this._formRef.current?.setFieldsValue({
      amount: amt
    });

    // Set the amount remaining field if the expense type is mileage
    if (this.state.currExpenseType == '015') {
      this.handleAmountChange(amt);
    }
  }

  private handleNumberOfMilesChange = (numMiles: number) => {
    const mileageRate = this._formRef.current?.getFieldValue('mileageRate') || 0;
    const amt = (numMiles * mileageRate).toFixed(2);
    this._formRef.current?.setFieldsValue({
      amount: amt
    });

    // Set the amount remaining field if the expense type is mileage
    if (this.state.currExpenseType == '015') {
      this.handleAmountChange(amt);
    }
  }

  private handleAmountChange = (amount: any) => {
    if (this.state.debtId) {
      const newAmtRemaining = (this.state.debtAmt - amount).toFixed(2);
      this._formRef.current?.setFieldsValue({
        amountRemaining: newAmtRemaining
      });
    }
  }

  private loadLookups = () => {
    const relationPromise = LookupsApiService.getRelationTypes();
    const expTypesPromise = LookupsApiService.getExpenditureTypes();
    Promise.all([relationPromise, expTypesPromise])
      .then(result => {
        const relations = result[0];
        let expenseTypes = result[1];
        if (this.state.expenditureId) {
          // this.state.currExpenseType doesn't exist at this point...
          SchBExpendituresApiService.getExpenditure(this.props.match.params.id || '', Number(this.props.match.params.seqNum))
            .then(result => {
              expenseTypes = expenseTypes.filter(et => et.code != ExpenditureType.REVERSE_TRANSACTION);
              if (result && result.expenseType !== ExpenditureType.MISCELLANEOUS_OR_UNITEMIZED) {
                expenseTypes = expenseTypes.filter(et => et.code != ExpenditureType.MISCELLANEOUS_OR_UNITEMIZED);
                this.setState({ relations: relations, expenseTypes: expenseTypes });
              }
            });
        }
        else {
          expenseTypes = expenseTypes.filter(et => et.code != ExpenditureType.MISCELLANEOUS_OR_UNITEMIZED
            && et.code != ExpenditureType.REVERSE_TRANSACTION);
        }
        this.setState({ relations: relations, expenseTypes: expenseTypes });
      });
  }

  private getSchedule = () => {
    ScheduleApiService.getSchedule(CurrentUser.Get()?.dr2Id || '', ScheduleAbbreviationCodes.SCHEDULEB)
      .then(schedule => {
        this.setState({ startDt: moment.utc(schedule.startDate), endDt: moment.utc(schedule.endDate) });
      });
  }

  private getPerson = (perorgId: string) => {
    if (perorgId != '') {
      ScheduleApiService.getPerson(perorgId, CurrentUser.Get()?.committeeId || '')
        .then(personInfo => {
          if (personInfo) {
            this.setIndividualFields(personInfo);
            this._formRef.current?.setFieldsValue({ payeeAutoFillId: perorgId });
          }
        });
    }
    else {
      this.setIndividualFields();
    }
  }

  private getOrganization = (perorgId: string) => {
    if (perorgId != '') {
      ScheduleApiService.getOrganization(perorgId, CurrentUser.Get()?.committeeId || '')
        .then(orgInfo => {
          if (orgInfo) {
            this.setCompanyFields(orgInfo);
            this._formRef.current?.setFieldsValue({ payeeAutoFillId: perorgId });
          }
        });
    }
    else {
      this.setCompanyFields();
    }
  }

  private getUserCommittee = () => {
    ScheduleApiService.getCommittee(CurrentUser.Get()?.committeeId || '')
      .then(committee => {
        if (committee) {
          const hideRelationshipCodes = [
            CommitteeType.CITY_PAC,
            CommitteeType.COUNTY_PAC,
            CommitteeType.IOWA_PAC,
            CommitteeType.SCHOOL_BOARD_OR_OTHER_POLITICAL_SUBDIVISION_PAC,
            CommitteeType.COUNTY_CENTRAL_COMMITTEE,
            CommitteeType.STATE_CENTRAL_COMMITTEE,
            CommitteeType.LOCAL_BALLOT_ISSUE];
          this.setState({ currComm: { ...committee }, hideRelationship: hideRelationshipCodes.includes(committee.committeeTypeCd || '') });
        }
      });
  }

  private getEarmarkDropdownOptions = () => {
    SchBExpendituresApiService.getSchBEarmarkDropdownOptions(
      CurrentUser.Get()?.dr2Id || '',
      this.state.expenditureId || '')
      .then(earmarks => {
        this.setState({ earmarkOptions: earmarks });
      });
  }

  private getExpenditure = (expenditureId: string, seqNum: string) => {
    SchBExpendituresApiService.getExpenditure(expenditureId, Number(seqNum))
      .then(expenditure => {
        if (expenditure) {
          expenditure.date = moment(expenditure.date);

          const expenditureInfo: SchBExpendituresFormDTO & { person?: string, company?: string } = expenditure;
          if (expenditureInfo.firstName && expenditureInfo.lastName) {
            if (expenditureInfo.middleInitial) {
              expenditureInfo.person = expenditure.firstName + ' ' + expenditure.middleInitial + '. ' + expenditure.lastName;
            }
            else {
              expenditureInfo.person = expenditure.firstName + ' ' + expenditure.lastName;
            }
          }
          else if (expenditureInfo.payeeType == Dr2GroupTypes.TRUST || expenditureInfo.payeeType == Dr2GroupTypes.COMPANYOTHER) {
            if (expenditureInfo.payeeName) {
              expenditureInfo.company = expenditureInfo.payeeName;
            }
          }

          this.setState({
            expenditureInfo: expenditure,
            currPayeeType: expenditure.payeeType || '',
            currExpenseType: expenditure.expenseType || '',
            isEarmarked: expenditure.isEarmarked,
            trusteeTrustorReq: expenditure.payeeType == Dr2GroupTypes.TRUST && !expenditure.isLivingOrRevocableTrust,
            autoFillPerorg: expenditure.payeeType != Dr2GroupTypes.COMMITTEE
          });
          if (!expenditure.earmarkedContributionId) {
            expenditure.earmarkedContributionId = '';
          }
          this._formRef.current?.setFieldsValue(expenditureInfo);
          this.setState({ loading: false });
        }
        else {
          notification.error({
            message: 'Error while fetching expenditure',
            description: 'Not found'
          });
          this.setState({ loading: false });
        }
      }).catch(() => {
        notification.error({
          message: 'Error while fetching expenditure'
        });
        this.setState({ loading: false });
      });
  }

  private getDebt = (debtId: string, seqNum: string) => {
    SchBExpendituresApiService.getDebt(debtId, Number(seqNum))
      .then(result => {
        if (result) {
          this.setState({
            expenditureInfo: result,
            currPayeeType: result.payeeType || '',
            currExpenseType: result.expenseType || '',
            trusteeTrustorReq: result.payeeType == Dr2GroupTypes.TRUST && !result.isLivingOrRevocableTrust
          });
          this._formRef.current?.setFieldsValue(this.state.expenditureInfo);
          this.setState({ loading: false, debtAmt: result.amountRemaining || 0 });

          // Set the number of miles and mileage rate fields if the expense type is Mileage
          if (result.expenseType == '015') {
            this._formRef.current?.setFieldsValue({ numberOfMiles: 0, mileageRate: 0.00 });
          }
        }
        else {
          notification.error({
            message: 'Error while fetching debt',
            description: 'Not found'
          });
          this.setState({ loading: false });
        }
      }).catch(() => {
        notification.error({
          message: 'Error while fetching debt'
        });
        this.setState({ loading: false });
      });
  }

  private handleSave = (values: SchBExpendituresFormDTO) => {
    if (values.payeeAutoFillId === '') {
      values.payeeAutoFillId = null;
    }
    this.setState({ btnDisable: true });
    if (!this.state.expenditureId && !this.state.debtId) {
      SchBExpendituresApiService.add(values, CurrentUser.Get()?.committeeId || '', CurrentUser.Get()?.dr2Id || '')
        .then(() => {
          notification.success({
            message: 'Saved Successfully',
            description: ''
          });
          HistoryUtil.push(Routes.SCHEDULE_B_EXPENDITURES);
        }).catch(error => {
          notification.error({
            message: 'Your expenditure failed to save',
            description: error.message
          });
          HistoryUtil.push(Routes.SCHEDULE_B_EXPENDITURES);
        });
    }
    else if (this.state.expenditureId && !this.state.debtId) {
      SchBExpendituresApiService.edit(
        values,
        CurrentUser.Get()?.committeeId || '',
        CurrentUser.Get()?.dr2Id || '',
        this.props.match.params.id || '',
        Number(this.props.match.params.seqNum)
      )
        .then(() => {
          notification.success({
            message: 'Saved Successfully',
            description: ''
          });
          HistoryUtil.push(Routes.SCHEDULE_B_EXPENDITURES);
        }).catch(() => {
          notification.error({
            message: 'Your expenditure failed to save',
            description: ''
          });
          HistoryUtil.push(Routes.SCHEDULE_B_EXPENDITURES);
        });
    }
    else {
      SchBExpendituresApiService.addWithDebt(
        values,
        CurrentUser.Get()?.committeeId || '',
        CurrentUser.Get()?.dr2Id || '',
        this.props.match.params.debtId || '',
        Number(this.props.match.params.seqNum)
      )
        .then(() => {
          notification.success({
            message: 'Saved Successfully',
            description: ''
          });
          HistoryUtil.push(Routes.SCHEDULE_D_DEBTS);
        }).catch(() => {
          notification.error({
            message: 'Your expenditure failed to save',
            description: ''
          });
          HistoryUtil.push(Routes.SCHEDULE_D_DEBTS);
        });
    }
  }

  private handleCancel = () => {
    confirm({
      title: 'Are you sure you want to leave?',
      okText: 'Yes',
      cancelText: 'No',
      onOk: () => {
        HistoryUtil.push(this.state.debtId ? Routes.SCHEDULE_D_DEBTS : Routes.SCHEDULE_B_EXPENDITURES);
      }
    });
  }
}

export default SchBExpendituresForm;
