// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import EntitySelectorDTO from './EntitySelectorDTO';

interface AdminManageUsersListDTO extends EntitySelectorDTO { 
  userId: number;
  username: string | null;
  firstName: string | null;
  lastName: string | null;
  password: string | null;
  confirmPassword: string | null;
  email: string | null;
  status: string | null;
  statusId: number;
  committeeId: string | null;
  committeeCode: string | null;
  committeeName: string | null;
  role: number;
}
const AdminManageUsersListDTO = {
  create: (initValues?: Partial<AdminManageUsersListDTO> | Record<string, unknown> | null) => {
    return Object.assign(EntitySelectorDTO.create(),
    {
      userId: 0,
      username: null,
      firstName: null,
      lastName: null,
      password: null,
      confirmPassword: null,
      email: null,
      status: null,
      statusId: 0,
      committeeId: null,
      committeeCode: null,
      committeeName: null,
      role: 0,
    },
    initValues);
  }
};

export default AdminManageUsersListDTO;