import { FormProps, Modal, Typography } from 'antd';
import * as React from 'react';
import BankAccountDTO from '../../models/BankAccountDTO';
import BankAccountForm from './BankAccountForm';
import DataTableColumnUtil from './DataTable/DataTableColumnUtil';
import DataTable, { DataTableColumnProps, FilterType } from './DataTable/DataTable';
import Guid from '../../utils/Guid';
import DataTableButtonUtil from './DataTable/DataTableButtonUtil';

interface BankAccountProps {
  disabled?: boolean;
  committeeName?: string;
  bankAccounts: BankAccountDTO[];
}

interface BankAccountState {
  showBankAccountForm: boolean;
  editing: boolean;
  editBankAccount: BankAccountDTO;
  bankAccounts: BankAccountDTO[];
  bankAccountsId: string;
  tableColumns: DataTableColumnProps<BankAccountDTO>[];
}

class BankAccount extends React.Component<BankAccountProps & FormProps, BankAccountState> {
  private _bankAccountForm: BankAccountForm | null = null;
  private dataTable: DataTable<BankAccountDTO> | undefined;

  constructor(props: BankAccountProps & FormProps) {
    super(props);

    this.state = {
      bankAccounts: this.props.bankAccounts.map(ba => ({ ...ba })) || [],
      showBankAccountForm: false,
      editing: false,
      bankAccountsId: '',
      editBankAccount: BankAccountDTO.create({ state: 'IA', accountType: '' }),
      tableColumns: this.getTableColumns(),
    };
  }

  getBankAccounts = (): BankAccountDTO[] => {
    return this.state.bankAccounts;
  }

  render() {
    const { editBankAccount, showBankAccountForm } = this.state;
    const { disabled, committeeName } = this.props;
    const noBankAccountText = !disabled ?
      'Currently, no bank accounts are listed. Click on \'Add Bank Account\' button to add a bank account.' :
      'Currently, no bank accounts are listed. Navigate back to the bank account step to add a bank account.';
    const actionButtons = [];
    actionButtons.push(DataTableButtonUtil.Reset());

    if (!disabled) {
      actionButtons.push(DataTableButtonUtil.Primary(
        'Add Bank Account', this.toggleAddForm
      ));
    }

    return (
      <>
        <DataTable
          ref={(element: any) => this.dataTable = element}
          buttonBar={actionButtons}
          serverSide={false}
          data={this.state.bankAccounts}
          columns={this.state.tableColumns}
          tableProps={{
            rowKey: 'bankAccountId',
            locale: { emptyText: noBankAccountText }
          }}
          styleOptions={{ compact: true, alternatingRowHighlight: true }}
          title="Bank Accounts" />
        <br />
        {showBankAccountForm &&
          <Modal
            centered
            visible={showBankAccountForm}
            okText={'Save'}
            cancelText={'Cancel'}
            onOk={() => this.saveBankAccount()}
            onCancel={() => this.toggleAddForm()}
            width={'80%'}
            style={{ top: 50 }} >
            <>
              <Typography.Title level={4}>New Bank Account</Typography.Title>
              <BankAccountForm
              ref={(element) => this._bankAccountForm = element}
              bankAccount={editBankAccount}
              committeeName={committeeName} />
            </>
          </Modal>
        }
      </>
    );
  }

  private toggleAddForm = () => {
    this.setState({
      showBankAccountForm: !this.state.showBankAccountForm,
      editing: false,
      editBankAccount: BankAccountDTO.create({ state: 'IA', accountType: '' }),
    });
  }

  private saveBankAccount() {
    const { bankAccounts } = this.state;
    const bankAccountsCpy = bankAccounts.map(ba => ({ ...ba }));
    if (!this._bankAccountForm) {
      return;
    }

    this._bankAccountForm.validate().then(validResult => {
      if (this.state.editing) {
        if (bankAccountsCpy) {
          const bankAccounts = bankAccountsCpy.map(c => c.bankAccountId === validResult.model.bankAccountId ? { ...validResult.model } : c);
          this.setState({ editing: false, bankAccounts }, () => this.dataTable?.refresh());
        }
      }
      else {
        validResult.model.bankAccountId = Guid.NewGuid();
        const newBA = BankAccountDTO.create({ ...validResult.model });
        bankAccountsCpy?.push(newBA);
        this.setState({ bankAccounts: bankAccountsCpy });
      }
      this.setState({ editBankAccount: BankAccountDTO.create({ state: 'IA', accountType: '' }) }, () => {
        this.dataTable?.refresh();
      });
      this.toggleAddForm();
    }).catch(errorInfo => {
      console.log(errorInfo);
    });
  }

  private onEditClick(index: string) {
    const { bankAccounts } = this.state;
    if (!this.props.disabled) {
      if (this.state.bankAccounts) {
        const bankAccountCpy = bankAccounts.map(ba => ({ ...ba }))
          .find(ba => ba.bankAccountId === index) as BankAccountDTO;
        this.setState({ editBankAccount: bankAccountCpy, editing: true, showBankAccountForm: true, bankAccountsId: index }, () => {
          this.dataTable?.refresh();
        });
      }
    }
  }

  private onDeleteClick(index: string) {
    if (!this.props.disabled) {
      const { bankAccounts } = this.state;
      const bankAccountsCpy = bankAccounts.map(ba => ({ ...ba }))
        .filter(ba => ba.bankAccountId !== index);
      this.setState({ bankAccounts: bankAccountsCpy }, () => this.dataTable?.refresh());
    }
  }

  private getTableColumns() {
    const tableColumns: DataTableColumnProps<BankAccountDTO>[] = [
      DataTableColumnUtil.Text('Account Name', 'accountName'),
      DataTableColumnUtil.Text('Account Number', 'accountNumber'),
      DataTableColumnUtil.Text('Account Type', 'accountType'),
      DataTableColumnUtil.BooleanCheckbox('Primary', 'primaryAccount', 10, FilterType.BooleanRadio),
      DataTableColumnUtil.Address('Bank Name', 'bankName', (r) => ({
        name: r.bankName,
        line1: r.addressLine1,
        line2: r.addressLine2,
        city: r.city,
        state: r.state,
        zip: r.zipCode,
      }))
    ];

    if (!this.props.disabled) {
      tableColumns.push(
        DataTableColumnUtil.Buttons('bankAccountId',
          [
            { text: 'Edit', onClick: (rowData) => this.onEditClick(rowData.bankAccountId || '') },
            { text: 'Delete', onClick: (rowData) => this.onDeleteClick(rowData.bankAccountId || '') }
          ],
          150));
    }
    return tableColumns;
  }
}

export default BankAccount;