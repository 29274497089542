// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
import BaseApi from './BaseApi';


export class AdminInternalNoteApiService extends BaseApi {

  // get: api/organization/note/${encodeURIComponent(committeeId)}
  public getInternalNote(committeeId: string): Promise<string> {
    let url = `api/organization/note/${encodeURIComponent(committeeId)}`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, string>(null, 'get', url, true, false);
  }

  // post: api/organization/note/${encodeURIComponent(committeeId)}?noteText=${encodeURIComponent(noteText)}
  public saveInternalNote(committeeId: string, noteText: string): Promise<void> {
    let url = `api/organization/note/${encodeURIComponent(committeeId)}`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (noteText != null) {
      url += `${prefix}noteText=${encodeURIComponent(noteText)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, void>(null, 'post', url, true, false);
  }
}
var service = new AdminInternalNoteApiService();
export default service;
