// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
import BaseApi from './BaseApi';
import TableRequestDTO from '../models/TableRequestDTO';
import SchF2LoansRepaidFormDTO from '../models/SchF2LoansRepaidFormDTO';
import TableResponseDTO from '../models/TableResponseDTO';
import SchF2LoansRepaidDTO from '../models/SchF2LoansRepaidDTO';

export class SchF2LoansRepaidApiService extends BaseApi {

  // post: api/schF2LoansRepaid/table?dr2Id=${encodeURIComponent(dr2Id)}
  public getSchF2LoansRepaidList(tableRequest: TableRequestDTO, dr2Id: string): Promise<TableResponseDTO<SchF2LoansRepaidDTO>> {
    let url = `api/schF2LoansRepaid/table`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (dr2Id != null) {
      url += `${prefix}dr2Id=${encodeURIComponent(dr2Id)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, TableResponseDTO<SchF2LoansRepaidDTO>>(tableRequest, 'post', url, true, false);
  }

  // get: api/schF2LoansRepaid/${encodeURIComponent(loanRepaidId)}/${seqNum}
  public getLoanRepaid(loanRepaidId: string, seqNum: number): Promise<SchF2LoansRepaidFormDTO> {
    let url = `api/schF2LoansRepaid/${encodeURIComponent(loanRepaidId)}/${seqNum}`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, SchF2LoansRepaidFormDTO>(null, 'get', url, true, false);
  }

  // post: api/schF2LoansRepaid/${encodeURIComponent(loanRepaidId)}/history
  public getLoanRepaidHistory(tableRequest: TableRequestDTO, loanRepaidId: string): Promise<TableResponseDTO<SchF2LoansRepaidDTO>> {
    let url = `api/schF2LoansRepaid/${encodeURIComponent(loanRepaidId)}/history`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, TableResponseDTO<SchF2LoansRepaidDTO>>(tableRequest, 'post', url, true, false);
  }

  // post: api/schF2LoansRepaid?committeeId=${encodeURIComponent(committeeId)}&dr2Id=${encodeURIComponent(dr2Id)}
  public add(newLoanRepaid: SchF2LoansRepaidFormDTO, committeeId: string, dr2Id: string): Promise<void> {
    let url = `api/schF2LoansRepaid`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (committeeId != null) {
      url += `${prefix}committeeId=${encodeURIComponent(committeeId)}`;
      prefix = '&';
    }
    if (dr2Id != null) {
      url += `${prefix}dr2Id=${encodeURIComponent(dr2Id)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, void>(newLoanRepaid, 'post', url, true, false);
  }

  // put: api/schF2LoansRepaid?committeeId=${encodeURIComponent(committeeId)}&dr2Id=${encodeURIComponent(dr2Id)}&loanRepaidId=${encodeURIComponent(loanRepaidId)}&seqNum=${seqNum}
  public edit(newLoanRepaid: SchF2LoansRepaidFormDTO, committeeId: string, dr2Id: string, loanRepaidId: string, seqNum: number): Promise<void> {
    let url = `api/schF2LoansRepaid`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (committeeId != null) {
      url += `${prefix}committeeId=${encodeURIComponent(committeeId)}`;
      prefix = '&';
    }
    if (dr2Id != null) {
      url += `${prefix}dr2Id=${encodeURIComponent(dr2Id)}`;
      prefix = '&';
    }
    if (loanRepaidId != null) {
      url += `${prefix}loanRepaidId=${encodeURIComponent(loanRepaidId)}`;
      prefix = '&';
    }
    if (seqNum != null) {
      url += `${prefix}seqNum=${seqNum}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, void>(newLoanRepaid, 'put', url, true, false);
  }

  // delete: api/schF2LoansRepaid?loanRepaidId=${encodeURIComponent(loanRepaidId)}&seqNum=${seqNum}
  public delete(loanRepaidId: string, seqNum: number): Promise<void> {
    let url = `api/schF2LoansRepaid`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (loanRepaidId != null) {
      url += `${prefix}loanRepaidId=${encodeURIComponent(loanRepaidId)}`;
      prefix = '&';
    }
    if (seqNum != null) {
      url += `${prefix}seqNum=${seqNum}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, void>(null, 'delete', url, true, false);
  }
}
var service = new SchF2LoansRepaidApiService();
export default service;
