// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
import BaseApi from './BaseApi';
import TableRequestDTO from '../models/TableRequestDTO';
import AdminOiePenaltyDTO from '../models/AdminOiePenaltyDTO';
import TableResponseDTO from '../models/TableResponseDTO';
import AdminOieListDTO from '../models/AdminOieListDTO';
import ResultDTO from '../models/ResultDTO';

export class AdminOieApiService extends BaseApi {

  // post: api/admin/oie?filingStatusType=${encodeURIComponent(filingStatusType)}
  public getOieList(tableRequest: TableRequestDTO, filingStatusType: string): Promise<TableResponseDTO<AdminOieListDTO>> {
    let url = `api/admin/oie`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (filingStatusType != null) {
      url += `${prefix}filingStatusType=${encodeURIComponent(filingStatusType)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, TableResponseDTO<AdminOieListDTO>>(tableRequest, 'post', url, true, false);
  }

  // post: api/admin/oie/${encodeURIComponent(expendId)}/approve
  public approveOie(expendId: string): Promise<void> {
    let url = `api/admin/oie/${encodeURIComponent(expendId)}/approve`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, void>(null, 'post', url, true, false);
  }

  // post: api/admin/oie/${encodeURIComponent(expendId)}/reject
  public rejectOie(expendId: string): Promise<void> {
    let url = `api/admin/oie/${encodeURIComponent(expendId)}/reject`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, void>(null, 'post', url, true, false);
  }

  // delete: api/admin/oie/${encodeURIComponent(expendId)}
  public deleteOie(expendId: string): Promise<void> {
    let url = `api/admin/oie/${encodeURIComponent(expendId)}`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, void>(null, 'delete', url, true, false);
  }

  // get: api/admin/oie/${encodeURIComponent(expendId)}/penalty
  public getOiePenalty(expendId: string): Promise<AdminOiePenaltyDTO> {
    let url = `api/admin/oie/${encodeURIComponent(expendId)}/penalty`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, AdminOiePenaltyDTO>(null, 'get', url, true, false);
  }

  // post: api/admin/oie/${encodeURIComponent(expendId)}/penalty?sendNotice=${sendNotice}
  public saveOiePenalty(expendId: string, penaltyData: AdminOiePenaltyDTO, sendNotice: boolean): Promise<ResultDTO> {
    let url = `api/admin/oie/${encodeURIComponent(expendId)}/penalty`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (sendNotice != null) {
      url += `${prefix}sendNotice=${sendNotice}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, ResultDTO>(penaltyData, 'post', url, true, false);
  }

  // post: api/admin/oie/${encodeURIComponent(expendId)}/status?status=${encodeURIComponent(status)}
  public updateOieStatus(expendId: string, status: string): Promise<void> {
    let url = `api/admin/oie/${encodeURIComponent(expendId)}/status`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (status != null) {
      url += `${prefix}status=${encodeURIComponent(status)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, void>(null, 'post', url, true, false);
  }

  // post: api/admin/oie/penaltyNotice?penaltyId=${encodeURIComponent(penaltyId)}
  public sendOieLatePenaltyNotice(penaltyId: string): Promise<ResultDTO> {
    let url = `api/admin/oie/penaltyNotice`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (penaltyId != null) {
      url += `${prefix}penaltyId=${encodeURIComponent(penaltyId)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, ResultDTO>(null, 'post', url, true, false);
  }
}
var service = new AdminOieApiService();
export default service;
