import {
  FormInstance,
  ColProps,
  Col,
  FormProps
} from 'antd';
import * as React from 'react';
import AddressFormFields from '../shared/AddressFormFields';
import SecondaryCommiteeInfoDTO from '../../models/SecondaryCommitteeInfoDTO';
import CustomForm from '../shared/CustomForm';

interface ParentEntityProps extends FormProps {
  secondaryCommitteeInfo?: SecondaryCommiteeInfoDTO;
  disabled?: boolean;
}
interface ParentEntityState {
}

class ParentEntity extends React.Component<ParentEntityProps & FormProps, ParentEntityState>{
  private readonly _formRef = React.createRef<FormInstance>();

  constructor(props: ParentEntityProps & FormProps) {
    super(props);
  }

  validate = async (): Promise<{ model: SecondaryCommiteeInfoDTO, errorFields: any }> => {
    try {
      const fields = await this._formRef.current?.validateFields();
      return { model: fields, errorFields: null };
    }
    catch (errorInfo) {
      return Promise.reject({ model: errorInfo.values, errorFields: errorInfo.errorFields });
    }
  }


  checkEmptyFields = (): boolean => {
    const model = this._formRef.current?.getFieldsValue();
    if (!model.addressLine1 && !model.addressLine2 && !model.city &&
      !model.email && !model.name && !model.phone &&
      !model.zipCode) {
      return true;
    }
    return false;
  }

  render() {
    const { secondaryCommitteeInfo, disabled, ...formProps } = this.props;
    const columnSizingProps: ColProps = { xs: 24, sm: 24, md: 12 };

    return (
      <CustomForm {...formProps} layout="vertical" initialValues={secondaryCommitteeInfo} formRef={this._formRef}>
        <Col {...columnSizingProps}>
          <AddressFormFields
            name={{ name: 'name', required: true }}
            address1={{ name: 'addressLine1', required: true }}
            address2={{ name: 'addressLine2' }}
            city={{ name: 'city', required: true }}
            state={{ name: 'state', required: true }}
            zip={{ name: 'zipCode', required: true }}
            email={{ name: 'email', required: false }}
            phone={{ name: 'phoneNumber', required: true }}
            allDisabled={disabled}
            ariaPrefix="Parent Entity" />
        </Col>
      </CustomForm>
    );
  }
}
export default ParentEntity;