import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import Routes from '../../consts/Routes';
import { RedirectAs404 } from '../../utils/RouteErrors';
import BuildingFundsSummary from './BuildingFundSummary';
import BuildingFundContributionForm from './Contributions/BuildingFundContributionForm';
import BuildingFundExpenditureForm from './Expenditures/BuildingFundExpenditureForm';
import BuildingFundFilingHistoryTable from './BuildingFundFilingHistoryTable';

const BuildingFunds = () => (
    <Switch>
        <Route exact={true} path={Routes.BUILDINGFUNDS_SUMMARY} component={BuildingFundsSummary} />
        <Route exact={true} path={Routes.BUILDINGFUND_CONTRIBUTIONS} component={BuildingFundContributionForm} />
        <Route exact={true} path={Routes.BUILDINGFUND_EXPENDITURES} component={BuildingFundExpenditureForm} />
        <Route exact={true} path={Routes.BUILDINGFUNDS_HISTORY} component={BuildingFundFilingHistoryTable} />
        <Route component={RedirectAs404} />
    </Switch>
);

export default BuildingFunds;