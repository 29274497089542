import {
  Button,
  Checkbox,
  Col,
  ColProps,
  FormInstance,
  Input,
  Layout,
  Modal,
  notification,
  Row,
  Select,
  Space,
  Typography
} from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import TextArea from 'antd/lib/input/TextArea';
import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import LookupsApiService from '../../api/LookupsApiService';
import GiftBequestsDTO from '../../models/GiftBequestsDTO';
import Routes from '../../consts/Routes';
import HistoryUtil from '../../utils/HistoryUtil';
import LookupTableDTO from '../../models/LookupTableDTO';
import CodeLookupTableDTO from '../../models/CodeLookupTableDTO';
import FormValidationUtil from '../../utils/FormValidationUtil';
import PfdDivisionDTO from '../../models/PfdDivisionDTO';
import GiftBequestApiService from '../../api/GiftBequestApiService';
import moment from 'moment';
import AddressFormFields from '../shared/AddressFormFields';
import DonorProfileApiService from '../../api/DonorProfileApiService';
import CurrentUser from '../../utils/CurrentUser';
import RoleUtil from '../../utils/RoleUtil';
import Role from '../../consts/Role';
import CurrencyInput from '../shared/CurrencyInput';
import CustomDatePicker from '../shared/CustomDatePicker';
import CustomForm from '../shared/CustomForm';

const Option = Select.Option;
const { Title } = Typography;
const { Content } = Layout;
const { confirm } = Modal;

interface EditGiftBequestsRouteProps {
  giftId: string;
}

interface EditGiftBequestsDeepState {
  disabled: string;
}

interface EditGiftBequestsState {
  giftId: string;
  loadingLookups: boolean;
  loadingGiftBequests: boolean;
  loadingDivisions: boolean;
  saving: boolean;
  gbGift: GiftBequestsDTO;
  stateAgencies: LookupTableDTO[];
  divisionsOrDepartments: PfdDivisionDTO[];
  states: CodeLookupTableDTO[];
  checkBoxChecked: boolean;
}

class EditGiftBequests extends React.Component<RouteComponentProps<EditGiftBequestsRouteProps>, EditGiftBequestsState> {
  private readonly _formRef = React.createRef<FormInstance>();

  constructor(props: RouteComponentProps<EditGiftBequestsRouteProps>) {
    super(props);

    this.state = {
      giftId: this.props.match.params.giftId,
      loadingLookups: true,
      loadingGiftBequests: true,
      loadingDivisions: true,
      saving: false,
      gbGift: GiftBequestsDTO.create({ departmentId: '', divisionId: '', state: 'IA' }),
      stateAgencies: [],
      divisionsOrDepartments: [],
      states: [],
      checkBoxChecked: false
    };
  }

  componentDidMount() {
    this.loadLookups();
    this.loadGiftBequestData();
  }

  render() {
    const columnSizingProps: ColProps = { xs: 24, sm: 24, md: 24 };
    const {
      giftId,
      loadingLookups,
      loadingGiftBequests,
      loadingDivisions,
      saving,
      gbGift,
      stateAgencies,
      divisionsOrDepartments
    } = this.state;
    const isAdmin = RoleUtil.currentUserHasRoles([Role.IECDB_ADMINISTRATOR]);
    const loading = loadingLookups || loadingGiftBequests || loadingDivisions;
    const disabled = saving || (this.props.location.state as EditGiftBequestsDeepState)?.disabled === 'true';
    const buttonText = (!giftId) ? 'Submit' : 'Adjust';
    const rowGutterLen = 64;
    
    return (
      <Content className="content-pad">
        { !loading &&
          <CustomForm
          formRef={this._formRef}
          onFinish={(values) => this.adjustGbGift(values)}
          initialValues={gbGift}
          layout="vertical">
            <Row gutter={rowGutterLen}>
              <Col {...columnSizingProps}>
                <Title level={3}>Gift/Bequest</Title>
                <FormItem name="giftId" hidden={true}>
                  <Input />
                </FormItem>
                <FormItem name="departmentId" label="State Agency" rules={[FormValidationUtil.RequiredNumber('State agency is required')]}>
                  <Select disabled={true}>
                    <Option value="" disabled={true}>-- Select Agency / Department --</Option>
                    {stateAgencies.map(sa => (
                      <Option key={sa.id} value={sa.id}>{sa.name}</Option>
                    ))}
                  </Select>
                </FormItem>
                <FormItem
                  name="divisionId"
                  label="Division / Department"
                  rules={[FormValidationUtil.RequiredNumber('Division / Department is required')]}>
                  <Select disabled={disabled}>
                    <Option value="" disabled={true}>-- Select Division --</Option>
                    {divisionsOrDepartments.map(div => (
                      <Option key={div.divisionId} value={div.divisionId}>{div.name}</Option>
                    ))}
                  </Select>
                </FormItem>
                <FormItem name="date" label="Date Received" rules={[FormValidationUtil.RequiredDate('Date is required')]}>
                  <CustomDatePicker disabled={disabled} />
                </FormItem>
                <FormItem
                  name="amount"
                  label="Amount $"
                  rules={[
                    FormValidationUtil.RequiredNumber('Amount of the gift/bequest is required'),
                    FormValidationUtil.PositiveNumber('Amount of the gift/bequest must be positive')
                  ]}>
                  <CurrencyInput disabled={disabled}/>
                </FormItem>
                <FormItem
                  name="description"
                  label="Provide a description of the gift or bequest and purpose thereof"
                  rules={[FormValidationUtil.Required('Description is required')]}>
                  <TextArea maxLength={200} disabled={disabled} />
                </FormItem>
                <AddressFormFields
                  name={{ name: 'name', label: 'Donor Name' }}
                  address1={{ name: 'address1' }}
                  address2={{ name: 'address2' }}
                  city={{ name: 'city' }}
                  state={{ name: 'state' }}
                  zip={{ name: 'zip', label: 'Zip Code' }}
                  phone={{ name: 'phone', required: false }}
                  email={{ name: 'email', label: 'Email Address', required: false }}
                  allDisabled={disabled} />
              </Col>
            </Row>
            <Row>
              <Col>
                <FormItem label="By submitting this report the contact affirms the gift or bequest reported is accurate.
                    The contact further affirms the information concerning the donor and assessment of the fair market
                    value is correct and true to the best of his/her knowledge.">
                  <Checkbox name="checkbox" disabled={disabled} onChange={(value) => this.onChangeCheckBox(value)} />
                </FormItem>
                <Space>
                  <Button type="primary" disabled={!this.state.checkBoxChecked} htmlType="submit">{buttonText}</Button>
                  <Button type="default" onClick={() => this.confirmCancel()}>Cancel</Button>
                  { isAdmin &&
                    <Button danger={true} onClick={() => this.confirmDelete(giftId, gbGift.name || '')}>Delete</Button>
                  }
                </Space>
              </Col>
            </Row>
          </CustomForm>
        }
      </Content>
    );
  }

  private onChangeCheckBox = (value: any) => {
    this.setState({ checkBoxChecked: value.target.checked });
  }

  private loadLookups = () => {
    const stateAgenciesPromise = LookupsApiService.getDepartments();
    const statesPromise = LookupsApiService.getStates();

    Promise.all([stateAgenciesPromise, statesPromise])
      .then(results => {
        const [stateAgencies] = results;
        const [, states] = results;
        states.sort((a, b) => (((a.code || '') > (b.code || '')) ? 1 : -1));

        this.setState({ stateAgencies, states, loadingLookups: false });
      }).catch(() => {
        notification.error({ message: 'Failed to load Gift/Bequest Data.' });
        this.setState({ loadingLookups: false });
      });
  }

  private loadGiftBequestData = () => {
    this.setState({ loadingGiftBequests: true });

    if (!this.state.giftId) {

      const currentUser = CurrentUser.Get();
      if (currentUser && currentUser.receivingDepartmentId) {
        DonorProfileApiService.get(currentUser.receivingDepartmentId)
          .then(receivingDepartment => {
            const deptId = receivingDepartment.departmentId ? receivingDepartment.departmentId : null;
            const divId = receivingDepartment.divisionId ? receivingDepartment.divisionId : null;
            const gbGift: GiftBequestsDTO = GiftBequestsDTO.create({ departmentId: deptId, divisionId: divId, state: 'IA' });

            if (deptId) {
              this.loadDivisions(gbGift.departmentId);
            }
            else {
              this.setState({ loadingDivisions: false });
            }
            this.setState({ gbGift, loadingGiftBequests: false });
          }).catch(() => {
            notification.error({ message: 'Failed to load Gift/Bequest Data.' });
            this.setState({ loadingGiftBequests: false });
          });
      }
      else {
        this.setState({ loadingGiftBequests: false, loadingDivisions: false });
      }
    }
    else {
      GiftBequestApiService.get(this.state.giftId)
        .then(gbGift => {
          if (gbGift && gbGift.departmentId) {
            this.loadDivisions(gbGift.departmentId);
          }

          if (gbGift && gbGift.date) {
              gbGift.date = moment.utc(gbGift.date);
          }
          this.setState({ gbGift, loadingGiftBequests: false });
        }).catch(() => {
          notification.error({ message: 'Failed to load Gift/Bequest Data.' });
          this.setState({ loadingGiftBequests: false });
        });
    }
  }

  private loadDivisions = (value: number | null) => {
    if (!value) {
      this.setState({ loadingDivisions: false });
      return;
    }
    else {
      LookupsApiService.getDivisionsByDepartment(value)
        .then(results => {
          this.setState({ divisionsOrDepartments: results, loadingDivisions: false });
        }).catch(() => {
          notification.error({ message: 'Failed to load divisions.' });
          this.setState({ loadingDivisions: false });
        });
    }
  };

  private delete = (giftId: string) => {
    GiftBequestApiService.delete(giftId)
      .then(() => {
        HistoryUtil.push(Routes.generate(Routes.MANAGE_GIFT_BEQUEST));
        notification.success({
          message: 'The Gift/Bequest was deleted successfully.'
        });
      }).catch(() => {
        notification.error({
          message: 'Failed to delete the Gift/Bequest.'
        });
      });
  }

  private confirmDelete = (giftId: string, name: string) => {
    confirm({
      title: 'Are you sure you want to delete this gift bequest?',
      content: 'The following record will be permanently deleted: ' + name,
      onOk: () => {
        this.delete(giftId);
      },
    });
  }

  private adjustGbGift = (values: GiftBequestsDTO) => {
    this.setState({ saving: true });

    if (values.date) {
      values.date = moment.utc(values.date);
    }

    GiftBequestApiService.edit(values)
      .then(() => {
        if (RoleUtil.currentUserHasRoles([Role.IECDB_ADMINISTRATOR])) {
          HistoryUtil.push(Routes.MANAGE_GIFT_BEQUEST);
        }
        else if (RoleUtil.currentUserHasRoles([Role.GIFTBEQUEST_DONOR])) {
          HistoryUtil.push(Routes.DONOR_SUMMARY);
        }
        else {
          HistoryUtil.push(Routes.LOGIN);
        }
        notification.success({
          message: 'The Gift/Bequest was saved successfully.'
        });
        this.setState({ saving: false });
      })
      .catch(() => {
        notification.error({ message: 'Failed to save the Gift/Bequest.' });
        this.setState({ saving: false });
      });
  }

  private confirmCancel = () => {
    confirm({
      title: 'Are you sure you want to leave?',
      okText: 'Yes',
      cancelText: 'No',
      onOk: () => {
        this.cancel();
      },
    });
  }

  private cancel = () => {
    if (RoleUtil.currentUserHasRoles([Role.IECDB_ADMINISTRATOR])) {
      HistoryUtil.push(Routes.generate(Routes.MANAGE_GIFT_BEQUEST));
    }
    else if (RoleUtil.currentUserHasRoles([Role.GIFTBEQUEST_DONOR])) {
      HistoryUtil.push(Routes.generate(Routes.DONOR_SUMMARY));
    }
    else {
      HistoryUtil.push(Routes.generate(Routes.LOGIN));
    }
  }

}

export default EditGiftBequests;
