import { Layout } from 'antd';
import * as React from 'react';
import VsrContributionApiService from '../../api/VsrContributionApiService';
import VsrContributionsDTO from '../../models/VsrContributionsDTO';
import DataTableColumnUtil from '../shared/DataTable/DataTableColumnUtil';
import DataTable, { DataTableColumnProps } from '../shared/DataTable/DataTable';
import Dr2AmendedStatuses from '../../consts/Dr2AmendedStatuses';
import Dr2AmendedIndicators from '../../consts/Dr2AmendedIndicators';

const { Content } = Layout;

interface VsrContributionHistoryTableProps {
  vsrId: string;
}

interface VsrContributionHistoryTableState {
}

class VsrContributionHistoryTable extends React.Component<VsrContributionHistoryTableProps, VsrContributionHistoryTableState> {
  constructor(props: VsrContributionHistoryTableProps) {
    super(props);

    this.state = {
    };
  }

  render() {
    const { vsrId } = this.props;
    return (
      <Content className="content-pad">
        <DataTable
          columns={this.getTableColumns()}
          fetchData={{
            fetch: function (tableRequest) { return VsrContributionApiService.getContributionHistory(tableRequest, vsrId); },
            failureMessage: 'Failed to retrieve expenditure history'
          }}
          serverSide={true}
          styleOptions={{ compact: true, alternatingRowHighlight: true }}

          tableProps={{
            rowKey: 'contributorIdAndSeq',
            sortDirections: ['ascend', 'descend'],
            locale: { emptyText: 'Expenditure history not available.' }
          }}
        />
      </Content>
    );
  }

  private getTableColumns = (): DataTableColumnProps<VsrContributionsDTO>[] => {
    const tableColumns: DataTableColumnProps<VsrContributionsDTO>[] = [
      DataTableColumnUtil.Date('Date', 'contributionDt', 100),
      DataTableColumnUtil.Address('Payee', 'contributorName',
        (c) => ({
          name: c.contributorName,
          line1: c.contributorAddressLine1,
          line2: c.contributorAddressLine2,
          city: c.contributorCity,
          state: c.contributorState,
          zip: c.contributorZip
        })
      ),
      DataTableColumnUtil.Currency('Amount', 'contributionAmt'),
      DataTableColumnUtil.Text('Check #', 'checkNumber', 125, { align: 'center' }),
      DataTableColumnUtil.DropdownMulti('Status', 'status',
        [
          { text: Dr2AmendedStatuses.ORIGINAL, value: Dr2AmendedIndicators.ORIGINAL },
          { text: Dr2AmendedStatuses.AMENDED, value: Dr2AmendedIndicators.AMENDED },
          { text: Dr2AmendedStatuses.ADJUSTED, value: Dr2AmendedIndicators.ADJUSTED },
          { text: Dr2AmendedStatuses.DELETED, value: Dr2AmendedIndicators.DELETED }
        ])
    ];
    return tableColumns;
  }
}

export default VsrContributionHistoryTable;
