// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
import BaseApi from './BaseApi';
import TableRequestDTO from '../models/TableRequestDTO';
import AdminSalesByStateDTO from '../models/AdminSalesByStateDTO';
import TableResponseDTO from '../models/TableResponseDTO';

export class AdminSalesByStateService extends BaseApi {

  // post: api/admin/salesByState/table?filingStatus=${encodeURIComponent(filingStatus)}
  public getSalesByStateList(tableRequest: TableRequestDTO, filingStatus: string): Promise<TableResponseDTO<AdminSalesByStateDTO>> {
    let url = `api/admin/salesByState/table`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (filingStatus != null) {
      url += `${prefix}filingStatus=${encodeURIComponent(filingStatus)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, TableResponseDTO<AdminSalesByStateDTO>>(tableRequest, 'post', url, true, false);
  }

  // post: api/admin/salesByState/${saleByStateId}/approve
  public approve(saleByStateId: number): Promise<void> {
    let url = `api/admin/salesByState/${saleByStateId}/approve`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, void>(null, 'post', url, true, false);
  }

  // delete: api/admin/salesByState/${saleByStateId}
  public rejectSaleByState(saleByStateId: number): Promise<void> {
    let url = `api/admin/salesByState/${saleByStateId}`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, void>(null, 'delete', url, true, false);
  }

  // delete: api/admin/salesByState/delete/${saleByStateId}
  public delete(saleByStateId: number): Promise<void> {
    let url = `api/admin/salesByState/delete/${saleByStateId}`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, void>(null, 'delete', url, true, false);
  }

  // get: api/admin/salesByState/sbs/${saleByStateId}
  public getSaleByState(saleByStateId: number): Promise<AdminSalesByStateDTO> {
    let url = `api/admin/salesByState/sbs/${saleByStateId}`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, AdminSalesByStateDTO>(null, 'get', url, true, false);
  }

  // post: api/admin/salesByState/update
  public updateSaleByState(saleByState: AdminSalesByStateDTO): Promise<void> {
    let url = `api/admin/salesByState/update`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, void>(saleByState, 'post', url, true, false);
  }
}
var service = new AdminSalesByStateService();
export default service;
