import {
    Checkbox,
    Col,
    ColProps,
    FormInstance,
    Input,
    Row,
    Select,
    Space,
} from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import TextArea from 'antd/lib/input/TextArea';
import AddressForm from '../shared/AddressFormFields';
import * as React from 'react';
import ScheduleApiService from '../../api/ScheduleApiService';
import CommitteeInfoDTO from '../../models/CommitteeInfoDTO';
import FormValidationUtil from '../../utils/FormValidationUtil';
import Dr2GroupTypes from '../../consts/Dr2GroupTypes';
import CurrentUser from '../../utils/CurrentUser';
import PersonSearch from '../shared/PersonSearch';
import BaseScheduleInfoDTO from '../../models/BaseScheduleInfoDTO';
import CommitteeAutoSearch from '../shared/CommitteeSearch';
import Role from '../../consts/Role';
import CustomDatePicker from '../shared/CustomDatePicker';
import CurrencyInput from '../shared/CurrencyInput';
import OrganizationAutoSearch from '../shared/OrganizationSearch';
import LookupsApiService from '../../api/LookupsApiService';
import CodeLookupTableDTO from '../../models/CodeLookupTableDTO';
import moment from 'moment';
import PayeeFormDTO from '../../models/PayeeFormDTO';

const Option = Select.Option;

interface PaymentFormProps {
    formRef: React.RefObject<FormInstance>;
    paymentId?: string;
    payeeForm: PayeeFormDTO;
    payeeTypes?: Array<Dr2GroupTypes>;
    showInKindIndicator?: boolean;
    showCheckNum?: boolean;
}

interface PaymentFormState {
    relations: CodeLookupTableDTO[];
    candidate: BaseScheduleInfoDTO;
    currContrType: string;
    currComm: CommitteeInfoDTO;
    btnDisable: boolean;
    contributionId?: string;
    loading: boolean;
    isAdmin: boolean;
    trusteeTrustorReq: boolean;
    inKindChecked: boolean;
    autoFillPerorg: boolean;
    payeeTypes: Array<Dr2GroupTypes>;
    showInKindIndicator?: boolean;
    showCheckNum?: boolean;
    startDt: moment.Moment;
    endDt: moment.Moment;
}

class PaymentForm extends React.Component<PaymentFormProps, PaymentFormState> {
    private readonly _formRef = this.props.formRef; 

    constructor(props: PaymentFormProps) {
        super(props);
        this.state = {
            relations: [],
            candidate: BaseScheduleInfoDTO.create(),
            currContrType: this.props.payeeForm.payeeType || '',
            currComm: CommitteeInfoDTO.create(),
            btnDisable: false,
            loading: !!this.props.paymentId,
            isAdmin: CurrentUser.Get()?.isInRole(Role.IECDB_ADMINISTRATOR) || false,
            trusteeTrustorReq: false,
            inKindChecked: false,
            autoFillPerorg: this.props.paymentId != null,
            payeeTypes: this.props.payeeTypes ||
                [
                    Dr2GroupTypes.INDIVIDUAL,
                    Dr2GroupTypes.COMMITTEE,
                    Dr2GroupTypes.TRUST,
                    Dr2GroupTypes.COMPANYOTHER,
                ],
            showInKindIndicator: this.props.showInKindIndicator || true,
            showCheckNum: this.props.showCheckNum || true,
            startDt: moment.utc(),
            endDt: moment.utc(),
        };
    }

    componentDidMount() {
        this.getCandidate();
        this.getUserCommittee();
        this.loadLookups();
    }

    render() {
        const { currContrType, currComm, autoFillPerorg, trusteeTrustorReq, payeeTypes, showCheckNum, showInKindIndicator } = this.state;
        const columnSizingProps: ColProps = { xs: 24, sm: 24, md: 12 };
        const checkNumReq = currContrType === Dr2GroupTypes.COMMITTEE && this.state.inKindChecked === false;

        return (
            <>
                <Row gutter={24}>
                    <Col {...columnSizingProps}>
                        <FormItem name="payeeType" label="Payee Type"
                            rules={[FormValidationUtil.Required('Payee Type is required')]} >
                            <Select
                                showSearch
                                optionFilterProp="children"
                                onChange={this.handleContributorTypeChange}>
                                <Option value="" disabled={true}>-- Select Type --</Option>
                                {payeeTypes.map(ct => (
                                    <Option key={ct as string} value={ct}>{ct}</Option>
                                ))}
                            </Select>
                        </FormItem>
                        <FormItem name="date" label="Date"
                            rules={[FormValidationUtil.RequiredDate('Date is required')]}>
                            <CustomDatePicker onChange={(d) => d?.startOf('day')} />
                        </FormItem>
                        <FormItem name="amount" label="Amount"
                            rules={[FormValidationUtil.RequiredNumber('Amount is required')]}>
                            <CurrencyInput />
                        </FormItem>
                        <FormItem name="checkNumber" label="Check Number" hidden={showCheckNum}
                            rules={[FormValidationUtil.Number('Number is invalid'),
                            FormValidationUtil.Required('Check Number is required', checkNumReq)]}>
                            <Input maxLength={12} />
                        </FormItem>
                        <Row>
                            <Col >
                                <Space>
                                    <FormItem name="inKindInd" valuePropName="checked" hidden={showInKindIndicator}>
                                        <Checkbox onChange={this.handleInKindCheck}> In-Kind </Checkbox>
                                    </FormItem>
                                </Space>
                            </Col>
                        </Row>
                        <FormItem name="explanation" label="Explanation" >
                            <TextArea maxLength={2000} />
                        </FormItem>
                    </Col>
                    <Col {...columnSizingProps}>
                        {currContrType === Dr2GroupTypes.INDIVIDUAL && (
                            <>
                                <PersonSearch
                                    name={'person'}
                                    label={'Search for an Individual'}
                                    required={false}
                                    committeeId={currComm.id || ''}
                                    onSelect={this.getPerson}
                                    onChange={this.handleContributorChange}
                                />
                                {
                                    <AddressForm allDisabled={autoFillPerorg}
                                        firstName={{ name: 'firstName' }}
                                        middleInitial={{ name: 'middleInitial' }}
                                        lastName={{ name: 'lastName' }}
                                        address1={{ name: 'addressLine1' }}
                                        address2={{ name: 'addressLine2' }}
                                        city={{ name: 'city' }}
                                        state={{ name: 'state' }}
                                        zip={{ name: 'zip' }}
                                        phone={{ name: 'phoneNumber', required: false }}
                                    />
                                }
                            </>
                        )}
                        {currContrType === Dr2GroupTypes.COMMITTEE && (
                            <CommitteeAutoSearch
                                name="payee"
                                label="Payee"
                                required={true}
                                requireSelection={true}
                                onSelectCommittee={this.handleCommitteeSelect}
                            />
                        )}
                        {(currContrType === Dr2GroupTypes.COMPANYOTHER || currContrType === Dr2GroupTypes.TRUST) && (
                            <>
                                <OrganizationAutoSearch
                                    name={'company'}
                                    label={'Search for a Company'}
                                    required={false}
                                    committeeId={currComm.id || ''}
                                    perorgType={currContrType}
                                    onSelect={this.getOrganization}
                                    onChange={this.handleContributorChange}
                                />
                                <AddressForm allDisabled={autoFillPerorg}
                                    name={{ name: 'payee', label: 'Company' }}
                                    address1={{ name: 'addressLine1' }}
                                    address2={{ name: 'addressLine2' }}
                                    city={{ name: 'city' }}
                                    state={{ name: 'state' }}
                                    zip={{ name: 'zip' }}
                                    phone={{ name: 'phoneNumber', required: false }}
                                />
                            </>
                        )}
                        {currContrType === Dr2GroupTypes.TRUST &&
                            <>
                                <FormItem name="isLivingOrRevocableTrust" valuePropName="checked">
                                    <Checkbox onChange={this.handleRevocableTrustCheckboxChange}>Living or Revocable Trust</Checkbox>
                                </FormItem>
                                <FormItem name="trustee" label="Trustee"
                                    rules={[FormValidationUtil.Required('Trustee is required', trusteeTrustorReq)]}>
                                    <Input disabled={autoFillPerorg} />
                                </FormItem>
                                <FormItem name="trustor" label="Trustor"
                                    rules={[FormValidationUtil.Required('Trustor is required', trusteeTrustorReq)]}>
                                    <Input disabled={autoFillPerorg} />
                                </FormItem>
                            </>
                        }
                        </Col>
                </Row>
                <Row>
                    <Col {...columnSizingProps}>
                        <FormItem name="contributorAutoFillId" hidden={true}>
                            <Input hidden={true} disabled={true} />
                        </FormItem>
                    </Col>
                 </Row>
           </>
        );
    }

    private handleContributorTypeChange = (payeeType: string) => {
        this.setState({
            currContrType: payeeType,
            autoFillPerorg: false,
            trusteeTrustorReq: payeeType == Dr2GroupTypes.TRUST
        });

        this._formRef.current?.setFieldsValue({
            'firstName': '',
            'middleInitial': '',
            'lastName': '',
            'relationshipCode': '',
            'addressLine1': '',
            'addressLine2': '',
            'city': '',
            'state': '',
            'zip': '',
            'phoneNumber': '',
            'trustee': '',
            'trustor': '',
            'person': null,
            'payee': null,
            'company': null,
            'isLivingOrRevocableTrust': false,
            'contributorAutoFillId': ''
        });
    }

    private handleContributorChange = (value: string) => {
        this._formRef.current?.setFieldsValue({ contributorAutoFillId: '' });
        this.setState({ autoFillPerorg: !!value });
    }

    private handleCommitteeSelect = (committee: CommitteeInfoDTO | undefined) => {
        this._formRef.current?.setFieldsValue({ contributorAutoFillId: committee?.id });
    }

    private setCompanyFields(form?: BaseScheduleInfoDTO) {
        if (form) {
            this._formRef.current?.setFieldsValue({
                payee: form.committeeOrCompanyName,
                addressLine1: form.addressLine1,
                addressLine2: form.addressLine2,
                city: form.city,
                state: form.state,
                zip: form.zip,
                phoneNumber: form.phoneNumber,
                trustee: form.trustee,
                trustor: form.trustor
            });
        }
        else {
            this._formRef.current?.setFieldsValue({
                payee: '',
                addressLine1: '',
                addressLine2: '',
                city: '',
                state: '',
                zip: '',
                phoneNumber: '',
                trustee: '',
                trustor: '',
                contributorAutoFillId: ''
            });
            this.setState({ autoFillPerorg: false });
        }
    }

    private setIndividualFields(form?: BaseScheduleInfoDTO) {
        if (form) {
            this._formRef.current?.setFieldsValue({
                payeeType: form.groupType,
                firstName: form.firstName,
                middleInitial: form.middleInitial,
                lastName: form.lastName,
                addressLine1: form.addressLine1,
                addressLine2: form.addressLine2,
                city: form.city,
                state: form.state,
                zip: form.zip,
                phoneNumber: form.phoneNumber,
                relationshipCode: form.relationshipCode,
                contributorAutoFillId: ''
            });
        }
        else {
            this._formRef.current?.setFieldsValue({
                payeeType: Dr2GroupTypes.INDIVIDUAL,
                firstName: '',
                middleInitial: '',
                lastName: '',
                addressLine1: '',
                addressLine2: '',
                city: '',
                state: '',
                zip: '',
                phoneNumber: '',
                relationshipCode: '',
                contributorAutoFillId: ''
            });
            this.setState({ autoFillPerorg: false });
        }
        this.setState({ currContrType: Dr2GroupTypes.INDIVIDUAL });
    }

    private handleRevocableTrustCheckboxChange = (e: any) => {
        this.setState({ trusteeTrustorReq: !e.target.checked });
    }

    private handleInKindCheck = (e: any) => {
        this.setState({ inKindChecked: e.target.checked });
    }

    private getCandidate = () => {
        ScheduleApiService.getCandidate(CurrentUser.Get()?.committeeId || '')
            .then(personInfo => {
                if (personInfo.groupType == null) {
                    return;
                }

                this.setState({ candidate: personInfo });
            });
    }

    private getPerson = (perorgId: string) => {
        if (perorgId != '') {
            ScheduleApiService.getPerson(perorgId, CurrentUser.Get()?.committeeId || '')
                .then(personInfo => {
                    if (personInfo) {
                        this.setIndividualFields(personInfo);
                        this._formRef.current?.setFieldsValue({ contributorAutoFillId: perorgId });
                    }
                });
        }
        else {
            this.setIndividualFields();
        }
    }

    private getOrganization = (perorgId: string) => {
        if (perorgId != '') {
            ScheduleApiService.getOrganization(perorgId, CurrentUser.Get()?.committeeId || '')
                .then(orgInfo => {
                    if (orgInfo) {
                        this.setCompanyFields(orgInfo);
                        this._formRef.current?.setFieldsValue({ contributorAutoFillId: perorgId });
                    }
                });
        }
        else {
            this.setCompanyFields();
        }
    }

    private getUserCommittee = () => {
        ScheduleApiService.getCommittee(CurrentUser.Get()?.committeeId || '')
            .then(committee => {
                if (committee) {
                    this.setState({ currComm: { ...committee } });
                }
            });
    }

    private loadLookups = () => {
        const relationPromise = LookupsApiService.getRelationTypes();
        Promise.all([relationPromise])
            .then(result => {
                const [relations] = result;
                this.setState({ relations: relations });
            });
    }
}

export default PaymentForm;