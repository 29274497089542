import { notification, Steps, Space, Button, Modal, Layout } from 'antd';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import { FormInstance, FormProps } from 'antd/lib/form/Form';
import FormItem from 'antd/lib/form/FormItem';
import { RouteComponentProps } from 'react-router-dom';
import * as React from 'react';
import CampaignApiService from '../../../../api/CampaignApiService';
import Routes from '../../../../consts/Routes';
import PfdDTO from '../../../../models/PfdDTO';
import Stepper, { Step } from '../../../shared/Stepper';
import HistoryUtil from '../../../../utils/HistoryUtil';
import StartPfd from './StartPfd';
import DesignatedPosition from './DesignatedPosition';
import PoliticalPositions from './PoliticalPositions';
import IncomeSources from './IncomeSources';
import OtherBusiness from './OtherBusiness';
import NameTypeSources from './NameTypeSources';
import Trust from './Trust';
import Retirement from './Retirement';
import Commissions from './Commissions';
import PfdReview from './PfdReview';
import AffirmPfd from './AffirmPfd';
import DesignatedPositionDTO from '../../../../models/DesignatedPositionDTO';
import AdminPfdApiService from '../../../../api/AdminPfdApiService';
import moment from 'moment';
import FormValidationUtil from '../../../../utils/FormValidationUtil';
import FilingStatusType from '../../../../consts/FilingStatusType';
import CurrentUser from '../../../../utils/CurrentUser';
import Role from '../../../../consts/Role';
import CustomDatePicker from '../../../shared/CustomDatePicker';
import CustomForm from '../../../shared/CustomForm';
import PfdApiService from '../../../../api/PfdApiService';

const { Content } = Layout;
const { Step } = Steps;
const { confirm } = Modal;

interface PfdProps {
  id: string;
}

interface PfdState {
  currentStepKey: PfdStep;
  pfd: PfdDTO;
  id: string | null;
  loading: boolean;
  saving: boolean;
  noOtherBusinesses: boolean;
  noOtherSecurities: boolean;
  noOtherInstruments: boolean;
  noOtherTrusts: boolean;
  noOtherRealEstate: boolean;
  noOtherRetirements: boolean;
  noOtherSources: boolean;
  noOtherCommissions: boolean;
}

enum PfdStep {
  STEP1,
  STEP2,
  STEP3,
  STEP4,
  STEP5,
  STEP6,
  STEP7,
  STEP8,
  STEP9,
  STEP10,
  STEP11,
  STEP12,
  STEP13,
  STEP14,
}

class PfdPage extends React.Component<RouteComponentProps<PfdProps> & FormProps, PfdState>
{
  private _designatedPosition: DesignatedPosition | null = null;
  private _politicalPosition: PoliticalPositions | null = null;
  private _otherBusiness: OtherBusiness | null = null;
  private _securities: NameTypeSources | null = null;
  private _financialInstitutions: NameTypeSources | null = null;
  private _trusts: Trust | null = null;
  private _realEstates: NameTypeSources | null = null;
  private _retirements: Retirement | null = null;
  private _otherSources: NameTypeSources | null = null;
  private _commissions: Commissions | null = null;
  private _affirm: AffirmPfd | null = null;

  private _nextRequestedStepKey?: PfdStep;
  private editFormRef = React.createRef<FormInstance>();

  private steps: Step[] = [
    {
      key: PfdStep.STEP1,
      title: 'Welcome'
    },
    {
      key: PfdStep.STEP2,
      title: 'Designated Position',
    },
    {
      key: PfdStep.STEP3,
      title: 'Gov/Pol Position'
    },
    {
      key: PfdStep.STEP4,
      title: 'Other Position',
    },
    {
      key: PfdStep.STEP5,
      title: 'Income',
    },
    {
      key: PfdStep.STEP6,
      title: 'Securities'
    },
    {
      key: PfdStep.STEP7,
      title: 'Financial Institutions'
    },
    {
      key: PfdStep.STEP8,
      title: 'Trusts'
    },
    {
      key: PfdStep.STEP9,
      title: 'Real Estate'
    },
    {
      key: PfdStep.STEP10,
      title: 'Retirement'
    },
    {
      key: PfdStep.STEP11,
      title: 'Other Income'
    },
    {
      key: PfdStep.STEP12,
      title: 'Commissions'
    },
    {
      key: PfdStep.STEP13,
      title: 'Review'
    },
    {
      key: PfdStep.STEP14,
      title: 'Affirmation'
    }
  ];

  constructor(props: RouteComponentProps<PfdProps> & FormProps) {
    super(props);


    this.state = {
      currentStepKey: PfdStep.STEP1,
      loading: true,
      saving: false,
      id: props.match.params.id || null,
      pfd: PfdDTO.create(),
      noOtherBusinesses: false,
      noOtherSecurities: false,
      noOtherInstruments: false,
      noOtherTrusts: false,
      noOtherRealEstate: false,
      noOtherRetirements: false,
      noOtherSources: false,
      noOtherCommissions: false,
    };

  }

  componentDidMount() {
    this.getInitialValues();
  }

  render() {
    const { currentStepKey, pfd, loading, saving } = this.state;
    let currentStep: React.ReactElement<any>;
    switch (currentStepKey) {
      case PfdStep.STEP1:
        currentStep =
          <StartPfd
          />;
        break;
      case PfdStep.STEP2:
        currentStep =
          <DesignatedPosition
            ref={(element) => this._designatedPosition = element}
            designatedPosition={pfd.designatedPosition || DesignatedPositionDTO.create({ state: 'IA' })}
          />;
        break;
      case PfdStep.STEP3:
        currentStep =
          <PoliticalPositions
            ref={(element) => this._politicalPosition = element}
            politicalPositions={pfd.politicalPositions || []}
          />;
        break;
      case PfdStep.STEP4:
        currentStep =
          <OtherBusiness
            ref={(element) => this._otherBusiness = element}
            businesses={pfd.otherBusinesses || []}
            noOtherBusiness={this.state.noOtherBusinesses}
          />;
        break;
      case PfdStep.STEP5:
        currentStep =
          <IncomeSources
          />;
        break;
      case PfdStep.STEP6:
        currentStep =
          <NameTypeSources
            ref={(element) => this._securities = element}
            sources={pfd.securities || []}
            key={PfdStep.STEP6}
            noOtherSource={this.state.noOtherSecurities}
            description=
            {
              'Enter the name of each entity in which you held stocks, bonds, mutual funds or other types ' +
              'of securities that generated more than $1,000 in gross income for you in the covered year.'
            }
            nameLabel="Name of Security"
            typeLabel="Type of Security"
            typeToolTip="e.g. stocks, bonds, mutual funds"
            checkBoxLabel="Click here if you had no securities income in excess of $1,000"
            rejectMessage="You must list at least one security, or select no other securities"
            nameTitle="Name"
            pageTitle="Securities"
          />;
        break;
      case PfdStep.STEP7:
        currentStep =
          <NameTypeSources
            ref={(element) => this._financialInstitutions = element}
            sources={pfd.financialInstitutions || []}
            key={PfdStep.STEP7}
            noOtherSource={this.state.noOtherInstruments}
            description=
            {
              'Enter the name of each financial institution in which you held financial instruments ' +
              'that produced gross income in excess of $1,000 for you in the covered year.'
            }
            nameLabel="Name of Financial Institution"
            typeLabel="Type of Income Earned"
            nameToolTip="e.g. Verdidian Credit Union"
            typeToolTip="e.g. interest earned on a certificate of deposit or savings account"
            checkBoxLabel="Click here if you had no income in excess of $1,000 from instruments of financial institutions"
            rejectMessage="You must list at least one instrument, or select no other instruments"
            nameTitle="Name"
            pageTitle="Instruments of Financial Institutions"
          />;
        break;
      case PfdStep.STEP8:
        currentStep =
          <Trust
            ref={(element) => this._trusts = element}
            noOtherTrusts={this.state.noOtherTrusts}
            trusts={pfd.trusts || []}
          />;
        break;
      case PfdStep.STEP9:
        currentStep =
          <NameTypeSources
            ref={(element) => this._realEstates = element}
            sources={pfd.realEstate || []}
            key={PfdStep.STEP9}
            noOtherSource={this.state.noOtherRealEstate}
            description="Enter the type of each real estate that generated gross income in excess of $1,000 in the covered year."
            nameLabel="Source of Income"
            typeLabel="Type of Real Estate"
            nameToolTip="e.g. rent, sale, mortgage"
            typeToolTip="e.g. agricultural, commercial, residential"
            checkBoxLabel="Click here if you had no income from real estate in excess of $1,000"
            rejectMessage="You must list at least one real estate, or select no other real estate"
            nameTitle="Source"
            pageTitle="Real Estate"
          />;
        break;
      case PfdStep.STEP10:
        currentStep =
          <Retirement
            ref={(element) => this._retirements = element}
            noOtherRetirements={this.state.noOtherRetirements}
            retirements={this.state.pfd.retirements || []}
          />;
        break;
      case PfdStep.STEP11:
        currentStep =
          <NameTypeSources
            ref={(element) => this._otherSources = element}
            sources={pfd.otherSources || []}
            key={PfdStep.STEP11}
            noOtherSource={this.state.noOtherSources}
            description="Enter the type and source of any other income in excess of $1,000 not already disclosed in this statement."
            nameLabel="Source"
            typeLabel="Type"
            checkBoxLabel="Click here if you had no other income in excess of $1,000"
            rejectMessage="You must list at least one other source of income, or select no other source of income"
            nameTitle="Source"
            pageTitle="Other Source of Income"
          />;
        break;
      case PfdStep.STEP12:
        currentStep =
          <Commissions
            ref={(element) => this._commissions = element}
            commissions={pfd.commissions || []}
            noOtherCommission={this.state.noOtherCommissions}
          />;
        break;
      case PfdStep.STEP13:
        currentStep =
          <PfdReview
            pfd={this.state.pfd}
          />;
        break;
      case PfdStep.STEP14:
        currentStep =
          <AffirmPfd
            ref={(element) => this._affirm = element}
          />;
        break;
      default:
        currentStep = <>Error</>;
        break;
    }

    const editForm = (

      this.state.id && !loading &&
      <Content className="content-pad">
        <br />
        <CustomForm formRef={this.editFormRef} initialValues={pfd} name="PfdEdit" layout="vertical">

          {
            pfd.statusId !== FilingStatusType.FILED ?
              <>
                <FormItem name="filedDate" label="Filed Date" rules={[FormValidationUtil.RequiredDate('Filed Date is required')]}>
                  <CustomDatePicker onChange={(d) => d?.startOf('day')}/>
                </FormItem>
                <FormItem name="postmarkDate" label="Postmark Date">
                  <CustomDatePicker onChange={(d) => d?.startOf('day')}/>
                </FormItem>
                <FormItem name="amendedDate" label="Amended Date">
                  <CustomDatePicker onChange={(d) => d?.startOf('day')}/>
                </FormItem>
                <FormItem name="filedManually" valuePropName="checked">
                  <Checkbox>Filed Manually</Checkbox>
                </FormItem>
                <Space>
                  {this.state.currentStepKey == PfdStep.STEP14 &&
                    <>
                      <Button type="primary" disabled={saving} onClick={() => this.editPfd(FilingStatusType.ADJUSTED)}>Adjust</Button>
                      <Button type="primary" disabled={saving} onClick={() => this.editPfd(FilingStatusType.AMENDED)}>Amend</Button>
                      <Button
                        danger
                        disabled={saving}
                        onClick={() =>
                          this.confirmDelete(parseInt(this.state.id || ''),
                            this.state.pfd.designatedPosition?.firstName + ' ' +
                            this.state.pfd.designatedPosition?.lastName)}>
                        Delete
                      </Button>
                    </>
                  }
                </Space>
              </> :
              <>
                <Space>
                  {this.state.currentStepKey == PfdStep.STEP14 &&
                    <>
                      <Button type="primary" disabled={saving} onClick={() => this.editPfd(FilingStatusType.ADJUSTED)}>Adjust</Button>
                      <Button type="primary" disabled={saving} onClick={() => this.editPfd(FilingStatusType.AMENDED)}>Amend</Button>
                      <Button disabled={saving} onClick={() => this.editPfd(FilingStatusType.AUDITED)}>Approve</Button>
                      <Button
                        disabled={saving}
                        onClick={() =>
                          this.confirmReject(parseInt(this.state.id || ''),
                            this.state.pfd.designatedPosition?.firstName + ' ' +
                            this.state.pfd.designatedPosition?.lastName)}>
                        Reject
                      </Button>
                    </>
                  }
                </Space>
              </>
          }
        </CustomForm>
      </Content>
    );


    return (
      <>
        <Stepper
          steps={this.steps}
          currentStepKey={currentStepKey}
          allowStepClick={true}
          cancelReturnUrl={this.state.id && CurrentUser.Get()?.isInRole(Role.IECDB_ADMINISTRATOR) ?
            Routes.generate(Routes.MANAGE_PFD) : Routes.generate(Routes.HOME_ROUTE)}
          onStepChangeRequested={this.handleStepChangeRequested}
          onSaveRequested={this.checkContactHasCoveredYear}
          minVerticalStepWidth={275}
          hideSave={!!this.state.id}
          additionalContent={editForm}
          buttonsDisabled={saving}
        >
          {!loading && currentStep}
        </Stepper>

      </>
    );
  }

  private handleStepChangeRequested = (requestedStep: Step) => {
    this._nextRequestedStepKey = requestedStep.key;

    switch (this.state.currentStepKey) {
      case PfdStep.STEP1:
        if (requestedStep.key === PfdStep.STEP2) {
          this.goToStep(requestedStep.key);
          return;
        } else {
          return;
        }
      case PfdStep.STEP2:
        if (!this._designatedPosition) {
          return;
        }

        if (requestedStep.key === PfdStep.STEP1) {
          this.goToStep(requestedStep.key);
          break;
        }

        if (requestedStep.key === PfdStep.STEP3) {
          this._designatedPosition.validate().then(validResult => {
            const pfd = {
              ...this.state.pfd, designatedPosition: { ...validResult.model, contactId: Number(validResult.model.contactId) }
            } as PfdDTO;
            this.goToStep(requestedStep.key, pfd);
          });
          return;
        } else {
          return;
        }
      case PfdStep.STEP3:
        if (!this._politicalPosition) {
          return;
        }

        if (requestedStep.key === PfdStep.STEP2) {
          this._politicalPosition?.moveBack().then(validResult => {
            const pfd = {
              ...this.state.pfd, politicalPositions: validResult.model
            } as PfdDTO;
            this.goToStep(requestedStep.key, pfd);
          });

          return;
        }
        if (requestedStep.key === PfdStep.STEP4) {
          this._politicalPosition?.validate().then(validResult => {
            const pfd = {
              ...this.state.pfd, politicalPositions: validResult.model
            } as PfdDTO;
            this.goToStep(requestedStep.key, pfd);
          });
          return;
        } else {
          return;
        }
      case PfdStep.STEP4:
        if (!this._otherBusiness) {
          return;
        }

        if (requestedStep.key === PfdStep.STEP3) {
          this._otherBusiness?.moveBack().then(validResult => {
            this.setState({
              noOtherBusinesses: validResult.model.noOtherBusiness
            });
            const pfd = {
              ...this.state.pfd, otherBusinesses: validResult.model.businesses,
            } as PfdDTO;
            this.goToStep(requestedStep.key, pfd);
          });
          return;
        }

        if (requestedStep.key === PfdStep.STEP5) {
          this._otherBusiness.validate().then(validResult => {
            this.setState({
              noOtherBusinesses: validResult.model.noOtherBusiness
            });
            const pfd = {
              ...this.state.pfd, otherBusinesses: validResult.model.businesses,
            } as PfdDTO;
            this.goToStep(requestedStep.key, pfd);
          });
          return;
        } else {
          return;
        }
      case PfdStep.STEP5:
        if (requestedStep.key === PfdStep.STEP4 || requestedStep.key === PfdStep.STEP6) {
          this.goToStep(requestedStep.key);
          return;
        } else {
          return;
        }
      case PfdStep.STEP6:
        if (!this._securities) {
          return;
        }

        if (requestedStep.key === PfdStep.STEP5) {
          this._securities.moveBack().then(validResult => {
            this.setState({
              noOtherSecurities: validResult.model.noOtherSource
            });
            const pfd = {
              ...this.state.pfd, securities: validResult.model.sources,
            } as PfdDTO;
            this.goToStep(requestedStep.key, pfd);
          });
          return;
        }

        if (requestedStep.key === PfdStep.STEP7) {
          this._securities.validate().then(validResult => {
            this.setState({
              noOtherSecurities: validResult.model.noOtherSource
            });
            const pfd = {
              ...this.state.pfd, securities: validResult.model.sources,
            } as PfdDTO;
            this.goToStep(requestedStep.key, pfd);
          });
          return;
        } else {
          return;
        }
      case PfdStep.STEP7:
        if (!this._financialInstitutions) {
          return;
        }

        if (requestedStep.key === PfdStep.STEP6) {
          this._financialInstitutions.moveBack().then(validResult => {
            this.setState({
              noOtherInstruments: validResult.model.noOtherSource
            });
            const pfd = {
              ...this.state.pfd, financialInstitutions: validResult.model.sources,
            } as PfdDTO;
            this.goToStep(requestedStep.key, pfd);
          });
          return;
        }

        if (requestedStep.key === PfdStep.STEP8) {
          this._financialInstitutions.validate().then(validResult => {
            this.setState({
              noOtherInstruments: validResult.model.noOtherSource
            });
            const pfd = {
              ...this.state.pfd, financialInstitutions: validResult.model.sources,
            } as PfdDTO;
            this.goToStep(requestedStep.key, pfd);
          });
          return;
        } else {
          return;
        }
      case PfdStep.STEP8:
        if (!this._trusts) {
          return;
        }
        if (requestedStep.key === PfdStep.STEP7) {
          this._trusts.moveBack().then(validResult => {
            this.setState({
              noOtherTrusts: validResult.model.noOtherTrust
            });
            const pfd = {
              ...this.state.pfd, trusts: validResult.model.trusts
            } as PfdDTO;
            this.goToStep(requestedStep.key, pfd);
          });
          return;
        }

        if (requestedStep.key === PfdStep.STEP9) {
          this._trusts?.validate().then(validResult => {
            this.setState({
              noOtherTrusts: validResult.model.noOtherTrust
            });
            const pfd = {
              ...this.state.pfd, trusts: validResult.model.trusts
            } as PfdDTO;
            this.goToStep(requestedStep.key, pfd);
          });
          return;
        } else {
          return;
        }
      case PfdStep.STEP9:
        if (!this._realEstates) {
          return;
        }

        if (requestedStep.key === PfdStep.STEP8) {
          this._realEstates.moveBack().then(validResult => {
            this.setState({
              noOtherRealEstate: validResult.model.noOtherSource
            });
            const pfd = {
              ...this.state.pfd, realEstate: validResult.model.sources
            } as PfdDTO;
            this.goToStep(requestedStep.key, pfd);
          });
          return;
        }

        if (requestedStep.key === PfdStep.STEP10) {
          this._realEstates.validate().then(validResult => {
            this.setState({
              noOtherRealEstate: validResult.model.noOtherSource
            });
            const pfd = {
              ...this.state.pfd, realEstate: validResult.model.sources
            } as PfdDTO;
            this.goToStep(requestedStep.key, pfd);
          });
          return;
        } else {
          return;
        }
      case PfdStep.STEP10:
        if (!this._retirements) {
          return;
        }

        if (requestedStep.key === PfdStep.STEP9) {
          this._retirements.moveBack().then(validResult => {
            this.setState({
              noOtherRetirements: validResult.model.noOtherRetirements
            });
            const pfd = {
              ...this.state.pfd, retirements: validResult.model.retirements
            } as PfdDTO;
            this.goToStep(requestedStep.key, pfd);
          });
          return;
        }

        if (requestedStep.key === PfdStep.STEP11) {
          this._retirements.validate().then(validResult => {
            this.setState({
              noOtherRetirements: validResult.model.noOtherRetirements
            });
            const pfd = {
              ...this.state.pfd, retirements: validResult.model.retirements,
            } as PfdDTO;
            this.goToStep(requestedStep.key, pfd);
          });
          return;
        } else {
          return;
        }
      case PfdStep.STEP11:
        if (!this._otherSources) {
          return;
        }

        if (requestedStep.key === PfdStep.STEP10) {
          this._otherSources.moveBack().then(validResult => {
            this.setState({
              noOtherSources: validResult.model.noOtherSource
            });
            const pfd = {
              ...this.state.pfd, otherSources: validResult.model.sources,
            } as PfdDTO;
            this.goToStep(requestedStep.key, pfd);
          });
          return;
        }

        if (requestedStep.key === PfdStep.STEP12) {
          this._otherSources.validate().then(validResult => {
            this.setState({
              noOtherSources: validResult.model.noOtherSource
            });
            const pfd = {
              ...this.state.pfd, otherSources: validResult.model.sources,
            } as PfdDTO;
            this.goToStep(requestedStep.key, pfd);
          });
          return;
        } else {
          return;
        }
      case PfdStep.STEP12:
        if (!this._commissions) {
          return;
        }

        if (requestedStep.key === PfdStep.STEP11) {
          this._commissions.moveBack().then(validResult => {
            this.setState({
              noOtherCommissions: validResult.model.noOtherComission
            });
            const pfd = {
              ...this.state.pfd, commissions: validResult.model.commissions,
            } as PfdDTO;
            this.goToStep(requestedStep.key, pfd);
          });
          return;
        }

        if (requestedStep.key === PfdStep.STEP13) {
          this._commissions.validate().then(validResult => {
            this.setState({
              noOtherCommissions: validResult.model.noOtherComission
            });
            const pfd = {
              ...this.state.pfd, commissions: validResult.model.commissions,
            } as PfdDTO;
            this.goToStep(requestedStep.key, pfd);
          });
          return;
        } else {
          return;
        }
      case PfdStep.STEP13:
        this.goToStep(requestedStep.key);
        return;
      case PfdStep.STEP14:
        if (!this._affirm) {
          return;
        }
        if (requestedStep.key === PfdStep.STEP13) {
          this.goToStep(requestedStep.key);
          return;
        } else {
          return;
        }
    }
  }

  private goToStep = (stepKey: PfdStep, pfdUpdate?: PfdDTO) => {
    this.setState({ currentStepKey: stepKey, pfd: pfdUpdate || this.state.pfd });
  }

  private checkContactHasCoveredYear = () => {
    const { pfd } = this.state;

    PfdApiService.checkContactHasCoveredYear(
      pfd.designatedPosition?.contactId || 0,
      moment(pfd.designatedPosition?.yearCovered).year().toString() || '')
      .then(result => {
        if (result) {
          confirm({
            closable: false,
            title: 'A filing for the selected covered year already exists.',
            content: `By submitting a new filing, it will replace the previously entered filing.
            Are you sure you want to overwrite the previous filing?`,
            onOk: () => this.savePfd(),
          });
        }
        else {
          this.savePfd();
        }
      });
  }

  private savePfd = () => {
    if (this.state.id == null) {
      this._affirm?.validate().then(() => {
        this.setState({ saving: true }, () => {
          CampaignApiService.addPfd(this.state.pfd, FilingStatusType.FILED).then(() => {
            if (CurrentUser.Get()?.isInRole(Role.IECDB_ADMINISTRATOR)) {
              HistoryUtil.push(Routes.MANUAL_REGISTRATION);
            }
            else {
              HistoryUtil.push(Routes.LOGIN);
            }
            notification.success({
              message: 'Your report was submitted successfully.',
              description: 'You will be contacted by the IECDB staff upon approval. Thank you.'
            });
          }).catch(error => {
            notification.error({ message: error.message });
          }).finally(() => {
            this.setState({ saving: false });
          });
        });
      });
    }
  }

  private editPfd = (filingStatus: string) => {
    const editFieldsPromise = this.editFormRef.current?.validateFields();
    const affirmationPromise = this._affirm?.validate();

    Promise.all([editFieldsPromise, affirmationPromise]).then(() => {
      this.setState({ saving: true }, () => {
        CampaignApiService.addPfd(
          { ...this.state.pfd, ...this.editFormRef.current?.getFieldsValue() },
          filingStatus
        ).then(() => {
          if (filingStatus == FilingStatusType.AUDITED) {
            notification.success({
              message: 'Approved Successfully'
            });
          }
          else {
            notification.success({
              message: 'Saved Successfully'
            });
          }
          HistoryUtil.push(Routes.MANAGE_PFD);
        }).finally(() => {
          this.setState({ saving: false });
        });
      });
    });
  }

  private getInitialValues = () => {
    if (this.state.id != null) {
      AdminPfdApiService.getPfdFiling(Number(this.state.id)).then(pfd => {
        if (pfd.designatedPosition?.yearCovered) {
          pfd.designatedPosition.yearCovered = moment.utc(pfd.designatedPosition.yearCovered);
        }
        if (pfd.otherBusinesses === undefined || pfd.otherBusinesses === null || pfd.otherBusinesses.length === 0) {
          this.setState({ noOtherBusinesses: true });
        }

        if (pfd.securities === undefined || pfd.securities === null || pfd.securities.length === 0) {
          this.setState({ noOtherSecurities: true });
        }

        if (pfd.financialInstitutions === undefined || pfd.financialInstitutions === null || pfd.financialInstitutions.length === 0) {
          this.setState({ noOtherInstruments: true });
        }

        if (pfd.trusts == undefined || pfd.trusts.length < 1) {
          this.setState({ noOtherTrusts: true });
        }

        if (pfd.realEstate == undefined || pfd.realEstate.length < 1) {
          this.setState({ noOtherRealEstate: true });
        }

        if (pfd.retirements == undefined || pfd.retirements.length < 1) {
          this.setState({ noOtherRetirements: true });
        }

        if (pfd.otherSources == undefined || pfd.otherSources.length < 1) {
          this.setState({ noOtherSources: true });
        }

        if (pfd.commissions == undefined || pfd.commissions.length < 1) {
          this.setState({ noOtherCommissions: true });
        }

        if (pfd.filedDate) {
          pfd.filedDate = moment.utc(pfd.filedDate);
        }

        if (pfd.postmarkDate) {
          pfd.postmarkDate = moment.utc(pfd.postmarkDate);
        }

        if (pfd.amendedDate) {
          pfd.amendedDate = moment.utc(pfd.amendedDate);
        }

        this.setState({ pfd: pfd, loading: false });
      }).catch(error => {
        notification.error({ message: error.message });
      });
    } else {
      this.setState({ loading: false });
    }
  }

  private confirmDelete = (id: number, name: string) => {
    confirm({
      title: 'Are you sure you want to delete this PFD?',
      content: 'The following record will be permanently deleted: ' + name,
      onOk: () => {
        this.deletePfd(id);
      },
    });
  }

  private deletePfd = async (id: number) => {
    this.setState({ saving: true }, () => {
      AdminPfdApiService.deletePfd(id)
        .then(() => {
          notification.success({
            message: 'Deleted Successfully'
          });
          HistoryUtil.push(Routes.MANAGE_PFD);
        }).catch(() => {
          notification.error({ message: 'Failed to delete Pfd.' });
        }).finally(() => {
          this.setState({ saving: false });
        });
    });
  }

  private reject = async (id: number) => {
    this.setState({ saving: true }, () => {
      AdminPfdApiService.deletePfd(id)
        .then(() => {
          notification.success({
            message: 'Rejected Successfully'
          });
          HistoryUtil.push(Routes.MANAGE_PFD);
        }).catch(() => {
          notification.error({
            message: 'Failed to reject filing.'
          });
        }).finally(() => {
          this.setState({ saving: false });
        });
    });
  }

  private confirmReject = (id: number, name: string) => {
    confirm({
      title: 'Are you sure you want to reject this PFD?',
      content: 'The following record will be rejected: ' + name,
      onOk: () => {
        this.reject(id);
      },
    });
  }

}

export default PfdPage;
