import { FormInstance, Input, Layout, Modal, notification, Select } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import * as React from 'react';
import AdminHelpPageApiService from '../../api/AdminHelpPageApiService';
import Routes from '../../consts/Routes';
import HelpPageDTO from '../../models/HelpPageDTO';
import FormValidationUtil from '../../utils/FormValidationUtil';
import CustomForm from '../shared/CustomForm';
import DataTable, { DataTableColumnProps } from '../shared/DataTable/DataTable';
import DataTableButtonUtil from '../shared/DataTable/DataTableButtonUtil';
import DataTableColumnUtil from '../shared/DataTable/DataTableColumnUtil';

const Option = Select.Option;
const { Content } = Layout;
const { confirm } = Modal;

interface HelpPageProps {

}

interface HelpPageState {
  tableColumns: DataTableColumnProps<HelpPageDTO>[];
  loadingPageList: boolean;
  loadingHelpPage: boolean;
  showModal: boolean;
  saving: boolean;
  helpPage: HelpPageDTO;
  helpPageList: HelpPageDTO[];
  pageList: string[];
}

class HelpPage extends React.Component<HelpPageProps, HelpPageState> {
  private dataTable: DataTable<HelpPageDTO> | undefined;
  private readonly _formRef = React.createRef<FormInstance>();

  constructor(props: HelpPageProps) {
    super(props);

    this.state = {
      tableColumns: this.getTableColumns(),
      loadingPageList: false,
      loadingHelpPage: false,
      showModal: false,
      saving: false,
      helpPage: HelpPageDTO.create({ route: '' }),
      helpPageList: [],
      pageList: []
    };
  }

  componentDidMount() {
    this.loadPageList();
  }

  render() {
    const actionButtons: any = [];
    actionButtons.push(DataTableButtonUtil.Reset());
    actionButtons.push(DataTableButtonUtil.Primary('Add Help Page', () => this.edit(null)));

    const { loadingPageList, loadingHelpPage, helpPage, helpPageList, pageList } = this.state;
    const loading = loadingPageList || loadingHelpPage;
    const isEditPage = helpPage.helpUrl !== null;
    const editHelpPageTitle = isEditPage ? 'Edit Help Page' : 'Add Help Page';

    return (
      <Content className="content-pad">
        <DataTable
          ref={(element: any) => (this.dataTable = element)}
          tableProps={{
            rowKey: 'route',
            sortDirections: ['ascend', 'descend'],
            locale: {
              emptyText: 'Currently there are no Help Page\'s to review'
            }
          }}
          globalSearch={true}
          title="Help Pages"
          buttonBar={actionButtons}
          columns={this.state.tableColumns}
          data={helpPageList}
          styleOptions={{ compact: true, alternatingRowHighlight: true }}
          stateSaving={{
            enabled: true,
            tableUniqueKey: 'adminHelpPages',
            perSession: true,
          }}
        />
        { !loading &&
        <Modal
          title={editHelpPageTitle}
          visible={this.state.showModal}
          onOk={this.adjustHelpPage}
          onCancel={this.cancel}
          okText="Save"
          cancelText="Cancel"
        >
          <CustomForm formRef={this._formRef} initialValues={helpPage} layout="vertical">
            <FormItem name="route" label="Route" rules={[FormValidationUtil.RequiredNumber()]}>
              <Select
                showSearch
                optionFilterProp="children"
                disabled={isEditPage}>
                <Option value="" disabled={true}>-- Select Page --</Option>
                {pageList.map(p => (
                  <Option key={p} value={p}>{p}</Option>
                ))}
              </Select>
            </FormItem>
            <FormItem name="helpUrl" label="Help Url" rules={[FormValidationUtil.Required()]}>
              <Input />
            </FormItem>
          </CustomForm>
        </Modal>
        }
      </Content>
    );
  }

  private getTableColumns() {
    const columns: DataTableColumnProps<HelpPageDTO>[] = [];

    const columnRoute: DataTableColumnProps<HelpPageDTO> = DataTableColumnUtil.Text('Route', 'route');
    columns.push(columnRoute);

    const columnHelpUrl: DataTableColumnProps<HelpPageDTO> = DataTableColumnUtil.Text('Help Url', 'helpUrl');
    columns.push(columnHelpUrl);

    const manageButtons = [
      { text: 'Edit', onClick: (rowData: HelpPageDTO) => this.edit(rowData) },
      { text: 'Delete', onClick: (rowData: HelpPageDTO) => this.confirmDelete(rowData.route || '') }
    ];
    const columnManage: DataTableColumnProps<HelpPageDTO> = DataTableColumnUtil.Buttons('route', manageButtons, 150);
    columns.push(columnManage);

    return columns as DataTableColumnProps<HelpPageDTO>[];
  }

  private edit = async (helpPage: HelpPageDTO | null) => {
    if (!helpPage) {
      this.loadHelpPageData(HelpPageDTO.create({route: ''}));
    }
    else {
      this.loadHelpPageData(helpPage);
    }
  }

  private delete = (route: string) => {
    AdminHelpPageApiService.delete(route)
      .then(() => {
        this.loadPageList();
        this.dataTable?.refresh();
      }).catch(() => {
        notification.error({
          message: 'Failed to delete filing.'
        });
      });
  }

  private confirmDelete = (route: string) => {
    if (!route) {
      return;
    }

    confirm({
      title: 'Are you sure you want to delete this Help Page?',
      content: 'The following record will be permanently deleted: ' + route,
      onOk: () => {
        this.delete(route);
      },
    });
  }

  private loadHelpPageData = (helpPage: HelpPageDTO) => {
    this.setState({ helpPage, loadingHelpPage: true }, () => this.setInitialPageValue());
  }

  private loadPageList = () => {
    AdminHelpPageApiService.getHelpPageList()
      .then(helpPageList => {
        const helpRouteList = helpPageList.map(l => l.route || '');
        const routeList = Routes.getRoutes().filter(route => route !== '' && route !== '/' && !route.includes(':'));
        const pageList = routeList.filter(route => !helpRouteList.includes(route)).sort();
        this.setState({ helpPageList, pageList });
        this.dataTable?.refresh();
      });
  }

  private adjustHelpPage = () => {
    if (this._formRef.current) {

      this._formRef.current.validateFields().then(values => {

        this.setState({ saving: true });

        AdminHelpPageApiService.edit(values)
          .then(() => {
            notification.success({
              message: 'Saved Successfully'
            });
            this.setState({ saving: false, showModal: false });
            this.loadPageList();
          })
          .catch(() => {
            notification.error({ message: 'Failed to save the Help Page.' });
            this.setState({ saving: false, showModal: false });
            if (this.dataTable) {
              this.dataTable.refresh();
            }
          });
      });
    }
  }

  private cancel = () => {
    this.setState({ showModal: false });
  }

  private setInitialPageValue = () => {
    if (this._formRef && this._formRef.current) {
      this._formRef.current.setFieldsValue({ route: ''});
    }
    this.setState({ showModal: true, loadingHelpPage: false });
  }

}

export default HelpPage;

