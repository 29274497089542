import {Button,Layout,notification,Space} from 'antd';
import { FormInstance, FormProps } from 'antd/lib/form/Form';
import moment from 'moment';
import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import AdminOieApiService from '../../api/AdminOieApiService';
import Routes from '../../consts/Routes';
import AdminOiePenaltyDTO from '../../models/AdminOiePenaltyDTO';
import HistoryUtil from '../../utils/HistoryUtil';
import CustomForm from '../shared/CustomForm';
import PenaltyForm from './PenaltyForm';
import DateUtil from '../../utils/DateUtil';
import FormItem from 'antd/lib/form/FormItem';

const { Content } = Layout;

interface OiePenaltyProps {
  id: string;
}

interface OiePenaltyState {
  oiePenalty: AdminOiePenaltyDTO,
  id: string,
  loading: boolean,
  penaltyNoticeLoading: boolean,
}

class OiePenalty extends React.Component<RouteComponentProps<OiePenaltyProps> & FormProps, OiePenaltyState> {
  private readonly formRef = React.createRef<FormInstance>();
  constructor(props: RouteComponentProps<OiePenaltyProps> & FormProps) {
    super(props);
    this.state = {
      id: props.match.params.id,
      oiePenalty: AdminOiePenaltyDTO.create({ penaltyStatus: 'OPEN', expendId: props.match.params.id }),
      loading: true,
      penaltyNoticeLoading: false,
    };
  }

  componentDidMount() {
    this.getInitialValues();
  }

  render() {
    const { loading, oiePenalty, penaltyNoticeLoading } = this.state;

    return (
      <Content className="content-pad">
        <h3 className="ant-typography">OIE Penalties</h3>
        {loading ? undefined :
          <CustomForm name="Penalties" onFinish={this.submitForm} initialValues={oiePenalty} layout="vertical" formRef={this.formRef}>
            <PenaltyForm penaltyNoticeDate={oiePenalty.penaltyIssuedDate != null ? DateUtil.toTimeLocal(moment(oiePenalty.penaltyIssuedDate)) : ''}/>
              <Space>
                <Button type="primary" htmlType="submit">Save Penalty</Button>
              <FormItem noStyle shouldUpdate>
                {({ getFieldValue }) =>
                  getFieldValue('penaltyPaidDate') === undefined || getFieldValue('penaltyPaidDate') === null ? (
                    <Button type="primary" loading={penaltyNoticeLoading}
                      onClick={() => this.sendLateNotice(this.formRef.current?.getFieldsValue())}>Save and Send Penalty Notice</Button>
                  ) : undefined}
              </FormItem>
                <Button onClick={this.cancelForm}>Cancel</Button>
             </Space>
          </CustomForm>
        }
      </Content>
    );
  }

  private submitForm = (values: any) => {
    AdminOieApiService.saveOiePenalty(this.state.id, { ...this.state.oiePenalty, ...values }, false)
      .then(() => {
        HistoryUtil.push(Routes.MANAGE_OIE);
        notification.success({ message: 'Penalty saved successfully.' });
      })
      .catch(error => {
        notification.error({ message: error.message, description: error.description });
      });
  }

  private sendLateNotice = (values: any) => {
    this.setState({ penaltyNoticeLoading: true });
    AdminOieApiService.saveOiePenalty(this.state.id, { ...this.state.oiePenalty, ...values }, true)
      .then((result) => {
        if (result.succeeded) {
          HistoryUtil.push(Routes.MANAGE_OIE);
          notification.success({ message: 'Penalty notice sent successfully.' });
        }
        else {
          if (result.errors[0] != null)
          {
            notification.error({ message: result.errors[0]});
          }
          else
          {
            notification.error({ message: 'Failed to send penalty notice.' });
          }
        }
        this.setState({ penaltyNoticeLoading: false });
      })
      .catch(error => {
        notification.error({ message: error.message, description: error.description });
        this.setState({ penaltyNoticeLoading: false });
      });
  }

  private cancelForm = () => {
    HistoryUtil.push(Routes.MANAGE_OIE);
  }

  private getInitialValues = () => {
    AdminOieApiService.getOiePenalty(this.state.id).then(oiePenalty => {

      if (oiePenalty.penaltyIssuedDate) {
        oiePenalty.penaltyIssuedDate = moment.utc(oiePenalty.penaltyIssuedDate);
      }
      if (oiePenalty.penaltyDueDate) {
        oiePenalty.penaltyDueDate = moment.utc(oiePenalty.penaltyDueDate);
      }
      if (oiePenalty.penaltyPaidDate) {
        oiePenalty.penaltyPaidDate = moment.utc(oiePenalty.penaltyPaidDate);
      }
      if (oiePenalty.penaltyWaiverDate) {
        oiePenalty.penaltyWaiverDate = moment.utc(oiePenalty.penaltyWaiverDate);
      }
      this.setState({ oiePenalty, loading: false });
    }).catch(() => {
      this.setState({ loading: false });
    });
  }
}

export default OiePenalty;

