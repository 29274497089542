// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
import BaseApi from './BaseApi';
import TableRequestDTO from '../models/TableRequestDTO';
import VsrContributionsFormDTO from '../models/VsrContributionsFormDTO';
import TableResponseDTO from '../models/TableResponseDTO';
import VsrContributionsDTO from '../models/VsrContributionsDTO';

export class VsrContributionApiService extends BaseApi {

  // post: api/contributions/vsr/table?committeeId=${encodeURIComponent(committeeId)}
  public getVsrList(tableRequest: TableRequestDTO, committeeId: string): Promise<TableResponseDTO<VsrContributionsDTO>> {
    let url = `api/contributions/vsr/table`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (committeeId != null) {
      url += `${prefix}committeeId=${encodeURIComponent(committeeId)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, TableResponseDTO<VsrContributionsDTO>>(tableRequest, 'post', url, true, false);
  }

  // get: api/contributions/vsr/${encodeURIComponent(committeeId)}/total
  public getVsrTotal(committeeId: string): Promise<number> {
    let url = `api/contributions/vsr/${encodeURIComponent(committeeId)}/total`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, number>(null, 'get', url, true, false);
  }

  // get: api/contributions/vsr/${encodeURIComponent(committeeId)}/count
  public getVsrCount(committeeId: string): Promise<number> {
    let url = `api/contributions/vsr/${encodeURIComponent(committeeId)}/count`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, number>(null, 'get', url, true, false);
  }

  // delete: api/contributions/vsr/${encodeURIComponent(vsrId)}?vsrSeqNbr=${vsrSeqNbr}
  public deleteVsrContribution(vsrId: string, vsrSeqNbr: number): Promise<void> {
    let url = `api/contributions/vsr/${encodeURIComponent(vsrId)}`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (vsrSeqNbr != null) {
      url += `${prefix}vsrSeqNbr=${vsrSeqNbr}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, void>(null, 'delete', url, true, false);
  }

  // get: api/contributions/vsr/${encodeURIComponent(vsrId)}/${vsrSeqNum}
  public getContribution(vsrId: string, vsrSeqNum: number): Promise<VsrContributionsFormDTO> {
    let url = `api/contributions/vsr/${encodeURIComponent(vsrId)}/${vsrSeqNum}`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, VsrContributionsFormDTO>(null, 'get', url, true, false);
  }

  // post: api/contributions/vsr/${encodeURIComponent(vsrId)}/history
  public getContributionHistory(tableRequest: TableRequestDTO, vsrId: string): Promise<TableResponseDTO<VsrContributionsDTO>> {
    let url = `api/contributions/vsr/${encodeURIComponent(vsrId)}/history`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, TableResponseDTO<VsrContributionsDTO>>(tableRequest, 'post', url, true, false);
  }

  // post: api/contributions/vsr/${encodeURIComponent(vsrId)}/${vsrSeqNum}?committeeId=${encodeURIComponent(committeeId)}
  public editVsr(model: VsrContributionsFormDTO, committeeId: string, vsrId: string, vsrSeqNum: number): Promise<void> {
    let url = `api/contributions/vsr/${encodeURIComponent(vsrId)}/${vsrSeqNum}`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (committeeId != null) {
      url += `${prefix}committeeId=${encodeURIComponent(committeeId)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, void>(model, 'post', url, true, false);
  }

  // post: api/contributions/vsr?committeeId=${encodeURIComponent(committeeId)}
  public addVsr(model: VsrContributionsFormDTO, committeeId: string): Promise<void> {
    let url = `api/contributions/vsr`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (committeeId != null) {
      url += `${prefix}committeeId=${encodeURIComponent(committeeId)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, void>(model, 'post', url, true, false);
  }
}
var service = new VsrContributionApiService();
export default service;
