import {
  Button,
  Col,
  ColProps,
  Layout,
  notification,
  Row,
  Space,
  Typography
} from 'antd';
import { FormInstance, FormProps } from 'antd/lib/form/Form';
import FormItem from 'antd/lib/form/FormItem';
import moment from 'moment';
import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import Dr2ApiService from '../../api/Dr2ApiService';
import Routes from '../../consts/Routes';
import PeriodDueDateDTO from '../../models/PeriodDueDateDTO';
import CurrentUser from '../../utils/CurrentUser';
import DateUtil from '../../utils/DateUtil';
import FormValidationUtil from '../../utils/FormValidationUtil';
import HistoryUtil from '../../utils/HistoryUtil';
import CustomDatePicker from '../shared/CustomDatePicker';
import CustomForm from '../shared/CustomForm';

const { Content } = Layout;

interface AdjustPeriodDatesProps {
}

interface AdjustPeriodDatesState {
  dueDates: PeriodDueDateDTO;
  loading: boolean;
}

class AdjustPeriodDates extends React.Component<RouteComponentProps<AdjustPeriodDatesProps> & FormProps, AdjustPeriodDatesState> {
  private readonly _formRef = React.createRef<FormInstance>();
  constructor(props: RouteComponentProps<AdjustPeriodDatesProps> & FormProps) {
    super(props);
    this.state = {
      dueDates: PeriodDueDateDTO.create(
        {
              beginDueDate: moment.utc(),
              endDueDate: moment.utc(),
              adjustedDueDate: moment.utc(),
              filingDueDate: moment.utc(),
              filedDate: null,
              amendedDate: moment.utc()
        }),
      loading: true
    };
  }

  componentDidMount() {
    this.getPeriodDueDates(CurrentUser.Get()?.dr2Id || '');
  }

  render() {
    const { dueDates, loading } = this.state;
    const columnSizingProps: ColProps = { xs: 24, sm: 24, md: 12 };

    return (
      <Content className="content-pad">
        {!loading &&
          <>
            <Typography.Title level={3}>Adjust Period Dates</Typography.Title>
            <CustomForm formRef={this._formRef} initialValues={dueDates} layout="vertical" onFinish={this.handleSave} >
              <Row gutter={24}>
                <Col {...columnSizingProps}>
                  <FormItem name="beginDueDate" label="Beginning Date" rules={[FormValidationUtil.RequiredDate('Begin Due Date is required')]}>
                   <CustomDatePicker onChange={(d) => d?.startOf('day')} />
                  </FormItem>
                  <FormItem name="endDueDate" label="Ending Date" rules={[FormValidationUtil.RequiredDate('End Due Date is required')]}>
                  <CustomDatePicker onChange={(d) => d?.startOf('day')} />
                  </FormItem>
                  <FormItem name="filingDueDate" label="Due Date" rules={[FormValidationUtil.RequiredDate('Filing Due Date is required')]}>
                  <CustomDatePicker onChange={(d) => d?.startOf('day')} />
                  </FormItem>
                  <FormItem name="adjustedDueDate" label="Adjusted Due Date">
                   <CustomDatePicker onChange={(d) => d?.startOf('day')}/>
                  </FormItem>
                </Col>
                <Col {...columnSizingProps}>
                  <FormItem name="filedDate" label="Filed Date">
                   <CustomDatePicker showTime format={DateUtil.getDateTimeFormatString()} />
                  </FormItem>
                  <FormItem name="amendedDate" label="Amended Date">
                  <CustomDatePicker onChange={(d) => d?.startOf('day')}  />
                  </FormItem>
                </Col>
              </Row>
              <Space>
                <Button type="primary" htmlType="submit" > Save </Button>
                <Button type="default" onClick={this.handleCancel}> Cancel </Button>
              </Space>
            </CustomForm>
          </>
        }
      </Content>
    );
  }

  private handleCancel = () => {
    HistoryUtil.push(Routes.generate(Routes.SCHEDULE_SUMMARY));
  }

  private handleSave = (values: PeriodDueDateDTO) => {
    Dr2ApiService.saveAdjustPeriodDates(values, CurrentUser.Get()?.committeeId || '', CurrentUser.Get()?.dr2Id || '')
      .then(result => {
        if (result.succeeded) {
          notification.success({
            message: 'Saved Successfully'
          });
        }
        else {
          notification.error({
            message: result.errors[0].toString()
          });
        }
        HistoryUtil.push(Routes.generate(Routes.SCHEDULE_SUMMARY));
      }).catch(() => {
        notification.error({
          message: 'Failed to save'
        });
      });
  }

  private getPeriodDueDates = (dr2Id: string) => {
    Dr2ApiService.getDueDatesByDr2(dr2Id)
        .then(dueDatesU => {
        const dueDates = {
            beginDueDate: moment.utc(dueDatesU.beginDueDate),
            endDueDate: moment.utc(dueDatesU.endDueDate),
            filingDueDate: moment.utc(dueDatesU.filingDueDate),
            adjustedDueDate: dueDatesU.adjustedDueDate ? moment.utc(dueDatesU.adjustedDueDate): null,
            filedDate: dueDatesU.filedDate ? moment(moment.utc(dueDatesU.filedDate).local().format('M/D/YYYY h:mm a')) : null,
            amendedDate: dueDatesU.amendedDate ? moment.utc(dueDatesU.amendedDate) : null
        } as PeriodDueDateDTO;
        this._formRef.current?.setFieldsValue(dueDates);
        this.setState({ dueDates, loading: false });
      }).catch(() => {
        notification.error({
          message: 'Unable to obtain due dates'
        });
      });
  }
}

export default AdjustPeriodDates;