import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import Routes from '../../consts/Routes';
import { RedirectAs404 } from '../../utils/RouteErrors';
import UserSettings from '../account/UserSettings';

const Account = () => (
  <Switch>
    <Route exact={true} path={Routes.ACCOUNT_BASE} component={UserSettings} />
    <Route component={RedirectAs404} />
  </Switch>
);

export default Account;
