import { RadioChangeEvent, Steps, notification, FormInstance, Typography, Spin } from 'antd';
import * as React from 'react';
import FilerType from '../../../../consts/FilerType';
import DR1FormTypes from '../../../../consts/DR1FormTypes';
import Routes from '../../../../consts/Routes';
import FilerTypeDTO from '../../../../models/FilerTypeDTO';
import BankAccountDTO from '../../../../models/BankAccountDTO';
import CommitteeInfoDTO from '../../../../models/CommitteeInfoDTO';
import DR1DTO from '../../../../models/DR1DTO';
import PersonInfoDTO from '../../../../models/PersonInfoDTO';
import SecondaryCommitteeInfoDTO from '../../../../models/SecondaryCommitteeInfoDTO';
import BankAccount from '../../../shared/BankAccount';
import CommitteeInfo from '../../../shared/CommitteeInfo';
import PersonInfo from '../../../shared/PersonInfo';
import SecondaryCommitteeInfo from '../../../shared/SecondaryCommitteeInfo';
import Stepper, { Step } from '../../../shared/Stepper';
import StartDR1 from './StartDR1';
import UserIdAffirmation from '../../../shared/UserAffirmation';
import Dr1ApiService from '../../../../api/Dr1ApiService';
import HistoryUtil from '../../../../utils/HistoryUtil';
import RegisterFormItems from '../../../account/RegisterFormItems';
import CurrentUser from '../../../../utils/CurrentUser';
import Role from '../../../../consts/Role';
import CustomForm from '../../../shared/CustomForm';

const { Step } = Steps;

interface DR1Props {
}

interface DR1State {
  currentStepKey: DR1Step;
  dr1: DR1DTO;
  currCommitteeType: FilerType;
  completedSteps: boolean[];
  saving: boolean;
}

enum DR1Step {
  STEP1,
  STEP2,
  STEP3,
  STEP4,
  STEP5,
  STEP6,
  STEP7,
  STEP8
}

class DR1 extends React.Component<DR1Props, DR1State> {
  private _committeeTypeRef: StartDR1 | null = null;
  private _committeeInfoRef: CommitteeInfo | null = null;
  private _personRef: PersonInfo | null = null;
  private _secondaryCommitteeRef: SecondaryCommitteeInfo | null = null;
  private _bankAccountRef: BankAccount | null = null;
  private _registerFormRef = React.createRef<FormInstance>();
  private _userIDAffirmationRef: UserIdAffirmation | null = null;

  private _nextRequestedStepKey?: DR1Step;

  private steps: Step[] = [
    {
      key: DR1Step.STEP1,
      title: 'Committee Type'
    },
    {
      key: DR1Step.STEP2,
      title: 'Committee',
    },
    {
      key: DR1Step.STEP3,
      title: 'Candidate',
    },
    {
      key: DR1Step.STEP4,
      title: 'Chairperson',
    },
    {
      key: DR1Step.STEP5,
      title: 'Treasurer'
    },
    {
      key: DR1Step.STEP6,
      title: 'Bank Accounts'
    },
    {
      key: DR1Step.STEP7,
      title: 'Register User'
    },
    {
      key: DR1Step.STEP8,
      title: 'Review / Affirmation'
    }
  ];

  constructor(props: DR1Props) {
    super(props);

    this.state = {
      currentStepKey: DR1Step.STEP1,
      dr1: DR1DTO.create(),
      currCommitteeType: FilerType.CANDIDATE,
      completedSteps: [false, false, false, false, false, false, false],
      saving: false
    };
  }


  onCommitteeTypePicked = (e: RadioChangeEvent) => {
    this.setState({
      currCommitteeType: e.target.value,
    });
    if (e.target.value === FilerType.CANDIDATE) {
      this.steps[2].title = 'Candidate';
    }
    else {
      this.steps[2].title = 'Parent';
    }
  }

  render() {
    const { currentStepKey, dr1, currCommitteeType, saving } = this.state;
    let currentStep: React.ReactElement<any>;

    switch (currentStepKey) {
      case DR1Step.STEP1:
        currentStep =
          <StartDR1
            ref={(element) => this._committeeTypeRef = element}
            committeeTypeChange={this.onCommitteeTypePicked}
            initialValues={dr1.committeeFilerType || FilerTypeDTO.create({
              id: FilerType.CANDIDATE
            })} />;
        break;
      case DR1Step.STEP2:
        currentStep =
          <CommitteeInfo
            ref={(element) => this._committeeInfoRef = element}
            key={DR1Step.STEP2}
            filerType={currCommitteeType}
            disabled={false}
            committeeInfo={dr1.committeeInfo || CommitteeInfoDTO.create({
              type: '',
              politicalParty: '',
              county: '',
              electionYear: (new Date()).getFullYear()
            })}
            goToTopOnLoad={true} />;

        break;
      case DR1Step.STEP3:
        if (currCommitteeType === FilerType.CANDIDATE) {
          currentStep =
            <PersonInfo
              ref={(element) => this._personRef = element}
              key={DR1Step.STEP3}
              disabled={false}
              filerType={currCommitteeType}
              formType={DR1FormTypes.CANDIDATE}
              personValues={dr1.personInfo1 || PersonInfoDTO.create({
                state: 'IA'
              })}
              goToTopOnLoad={true} />;
        }
        else {
          currentStep =
            <SecondaryCommitteeInfo
              ref={(element) => this._secondaryCommitteeRef = element}
              key={DR1Step.STEP3}
              disabled={false}
              filerType={currCommitteeType}
              formType={DR1FormTypes.PARENT}
              committeeValues={dr1.secondaryCommittee1 || SecondaryCommitteeInfoDTO.create({
                state: 'IA'
              })}
              goToTopOnLoad={true} />;
        }
        break;
      case DR1Step.STEP4:
        currentStep =
          <PersonInfo
            ref={(element) => this._personRef = element}
            key={DR1Step.STEP4}
            disabled={false}
            filerType={currCommitteeType}
            formType={DR1FormTypes.CHAIRHOLDER}
            personValues={dr1.personInfo2 || PersonInfoDTO.create({
              state: 'IA'
            })}
            goToTopOnLoad={true} />;
        break;
      case DR1Step.STEP5:
        currentStep =
          <PersonInfo
            ref={(element) => this._personRef = element}
            key={DR1Step.STEP5}
            disabled={false}
            filerType={currCommitteeType}
            formType={DR1FormTypes.TREASURER}
            personValues={dr1.personInfo3 || PersonInfoDTO.create({
              state: 'IA'
            })}
            goToTopOnLoad={true} />;
        break;
      case DR1Step.STEP6:
        currentStep =
          <BankAccount
            ref={(element) => this._bankAccountRef = element}
            disabled={false}
            bankAccounts={dr1.bankAccounts || BankAccountDTO[0].add(BankAccountDTO.create({
              state: 'IA', accountType: ''
            }))}
            committeeName={this.state.dr1.committeeInfo?.name || ''}

          />;
        break;
      case DR1Step.STEP7:
        currentStep = (
          <>
            <Typography.Title level={3}>Register User Account</Typography.Title>
            <CustomForm
              formRef={this._registerFormRef}
              key={DR1Step.STEP7}
              initialValues={dr1.register || undefined}
              layout="vertical"
              validateTrigger={['onChange', 'onBlur']}>
              <RegisterFormItems />
            </CustomForm>
          </>
        );
        break;
      case DR1Step.STEP8:
        currentStep =
          <Spin tip="Submitting Statement of Organization" spinning={saving}>
            <UserIdAffirmation
              ref={(element) => this._userIDAffirmationRef = element}
              key={DR1Step.STEP8}
              DR1Ref={dr1}
              filerType={currCommitteeType}
              disable={false} />
          </Spin >;
        break;
      default:
        currentStep = <>Error</>;
        break;
    }

    return (
      <Stepper
        steps={this.steps}
        currentStepKey={currentStepKey}
        allowStepClick={true}
        cancelReturnUrl={CurrentUser.Get()?.isInRole(Role.IECDB_ADMINISTRATOR) ?
          Routes.generate(Routes.MANUAL_REGISTRATION) : Routes.generate(Routes.HOME_ROUTE)}
        onStepChangeRequested={this.handleStepChangeRequested}
        onSaveRequested={this.saveDR1}
        minVerticalStepWidth={250}
        buttonsDisabled={saving}
      >
        {currentStep}
      </Stepper>
    );
  }


  private handleStepChangeRequested = (requestedStep: Step) => {
    this._nextRequestedStepKey = requestedStep.key;

    switch (this.state.currentStepKey) {
      case DR1Step.STEP1:

        if (!this._committeeTypeRef) {
          return;
        }

        if (requestedStep.key === DR1Step.STEP2 || this.state.completedSteps[requestedStep.key - 1]) {
          this._committeeTypeRef.validate().then(validResult => {
            const dr1 = {
              ...this.state.dr1, committeeFilerType: { ...validResult.model }
            } as DR1DTO;
            this.setComplete(DR1Step.STEP1);
            this.goToStep(requestedStep.key, dr1);
          }).catch(errorInfo => {
            console.log(errorInfo);
          });
          return;
        }
        return;

      case DR1Step.STEP2:

        if (!this._committeeInfoRef) {
          return;
        }
        if (this.state.currentStepKey > requestedStep.key) {
          this.goToStep(requestedStep.key);
          break;
        }

        if (requestedStep.key === DR1Step.STEP3 || this.state.completedSteps[requestedStep.key - 1]) {
          if (this._committeeInfoRef) {
            this._committeeInfoRef.validate().then(validResult => {
              const dr1 = {
                ...this.state.dr1, committeeInfo: { ...validResult.model }
              } as DR1DTO;
              this.setComplete(DR1Step.STEP2);
              this.goToStep(requestedStep.key, dr1);
            }).catch(errorInfo => {
              console.log(errorInfo);
            });
          }
        }

        return;
      case DR1Step.STEP3:
        if (this._personRef) {
          if (this.state.currentStepKey > requestedStep.key) {
            this.goToStep(requestedStep.key);
            break;
          }

          if (requestedStep.key === DR1Step.STEP4 || this.state.completedSteps[requestedStep.key - 1]) {
            this._personRef.validate().then(validResult => {
              const dr1 = {
                ...this.state.dr1, personInfo1: { ...validResult.model }
              } as DR1DTO;
              this.setComplete(DR1Step.STEP3);
              this.goToStep(requestedStep.key, dr1);
            }).catch(errorInfo => {
              console.log(errorInfo);
            });
          }
          return;
        }
        else if (this._secondaryCommitteeRef) {
          if (this.state.currentStepKey > requestedStep.key) {
            this.goToStep(requestedStep.key);
          }

          if (requestedStep.key === DR1Step.STEP4 || this.state.completedSteps[requestedStep.key - 1]) {
            if (this._secondaryCommitteeRef.checkEmptyFields()) {
              this.setComplete(DR1Step.STEP3);
              this.goToStep(requestedStep.key);
            }
            else {
              this._secondaryCommitteeRef.validate().then(validResult => {
                const dr1 = {
                  ...this.state.dr1, secondaryCommittee1: { ...validResult.model }
                } as DR1DTO;
                this.setComplete(DR1Step.STEP3);
                this.goToStep(requestedStep.key, dr1);
              }).catch(errorInfo => {
                console.log(errorInfo);
              });
            }
          }
        }
        return;

      case DR1Step.STEP4:
        if (!this._personRef) {
          return;
        }
        if (this.state.currentStepKey > requestedStep.key) {
          this.goToStep(requestedStep.key);
          break;
        }

        if (requestedStep.key === DR1Step.STEP5 || this.state.completedSteps[requestedStep.key - 1]) {
          if (this.state.currCommitteeType === FilerType.CANDIDATE && this._personRef.checkEmptyFields()) {
            this.setComplete(DR1Step.STEP4);
            this.goToStep(requestedStep.key);
          }
          else {
            this._personRef.validate().then(validResult => {
              const dr1 = {
                ...this.state.dr1, personInfo2: { ...validResult.model }
              } as DR1DTO;
              this.setComplete(DR1Step.STEP4);
              this.goToStep(requestedStep.key, dr1);
            }).catch(errorInfo => {
              console.log(errorInfo);
            });
          }
        }
        return;

      case DR1Step.STEP5:
        if (!this._personRef) {
          return;
        }
        if (this.state.currentStepKey > requestedStep.key) {
          this.goToStep(requestedStep.key);
          break;
        }

        if (requestedStep.key === DR1Step.STEP6 || this.state.completedSteps[requestedStep.key - 1]) {
          this._personRef.validate().then(validResult => {
            const dr1 = {
              ...this.state.dr1, personInfo3: { ...validResult.model }
            } as DR1DTO;
            this.setComplete(DR1Step.STEP5);
            this.goToStep(requestedStep.key, dr1);
          }).catch(errorInfo => {
            console.log(errorInfo);
          });

        }

        return;

      case DR1Step.STEP6:
        if (this.state.currentStepKey > requestedStep.key) {
          this.goToStep(requestedStep.key);
          break;
        }
        if (requestedStep.key === DR1Step.STEP7) {
          if (this._bankAccountRef) {
            const bankAccountCpy = this._bankAccountRef.getBankAccounts();
            const dr1 = {
              ...this.state.dr1, bankAccounts: bankAccountCpy
            } as DR1DTO;
            this.setComplete(DR1Step.STEP6);
            this.goToStep(requestedStep.key, dr1);
            break;
          }
        }

        return;

      case DR1Step.STEP7:
        if (!this._registerFormRef) {
          return;
        }
        if (this.state.currentStepKey > requestedStep.key) {
          this.goToStep(requestedStep.key);
          break;
        }

        if (requestedStep.key === DR1Step.STEP8 || this.state.completedSteps[requestedStep.key - 1]) {
          this._registerFormRef.current?.validateFields().then(validResult => {
            const dr1 = {
              ...this.state.dr1, register: { ...validResult }
            } as DR1DTO;
            this.setComplete(DR1Step.STEP7);
            this.goToStep(requestedStep.key, dr1);
          }).catch(errorInfo => {
            console.log(errorInfo);
          });
        }

        return;

      case DR1Step.STEP8:
        if (!this._userIDAffirmationRef) {
          return;
        }
        if (this.state.currentStepKey > requestedStep.key) {
          this.setComplete(DR1Step.STEP8);
          this.goToStep(requestedStep.key);
          return;
        }
        return;

      default:
        return;
    }
  }

  private goToStep = (stepKey: DR1Step, dr1Update?: DR1DTO) => {
    this.setState({ currentStepKey: stepKey, dr1: dr1Update || this.state.dr1 });
  }

  private setComplete = (stepKey: DR1Step) => {
    const completed = this.state.completedSteps;
    completed[stepKey] = true;
    this.setState({ completedSteps: completed });
    console.log(this.state);
  }

  private saveDR1 = () => {
    this.setState({ saving: true });

    Dr1ApiService.add(this.state.dr1).then((result) => {
      if (result.succeeded) {
        if (CurrentUser.Get()?.isInRole(Role.IECDB_ADMINISTRATOR)) {
          HistoryUtil.push(Routes.MANUAL_REGISTRATION);
        }
        else {
          HistoryUtil.push(Routes.LOGIN);
        }
        notification.success({
          message: 'Your report was submitted successfully.',
          description: 'You will be contacted by the IECDB staff upon approval. Thank you.'
        });
      } else {
        notification.error({
          message: 'Submission failed',
          description: result.errors.length > 0 ? result.errors[0] : undefined
        });
      }
    }).catch(error => {
      notification.error({ message: error.message });
    }).finally(() => {
      this.setState({ saving: false });
    });
  }
}

export default DR1;
