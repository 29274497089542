import {
  Form,
  FormInstance,
  Modal,
  Radio,
  Space,
  Input,
  notification,
} from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import moment from 'moment';
import * as React from 'react';
import FilingStatusType from '../../consts/FilingStatusType';
import DR1DTO from '../../models/DR1DTO';
import DateUtil from '../../utils/DateUtil';
import FormValidationUtil from '../../utils/FormValidationUtil';
import CustomDatePicker from '../shared/CustomDatePicker';
import OrganizationApiService from '../../api/OrganizationApiServices';
import CurrentUser from '../../utils/CurrentUser';
import FilerTypeDTO from '../../models/FilerTypeDTO';
import Dr2ApiService from '../../api/Dr2ApiService';
import CommFilingPeriodCatType from '../../consts/CommFilingPeriodCatType';
import ElectionYear from '../../consts/ElectionYear';

interface ElectionInfoModalProps {
  onClose: () => void;
  onSubmit: (dr1: DR1DTO, isIncumbent:boolean | null) => void;
  dateRequired: boolean;
  dr1: DR1DTO;
  dr2Id: string;
  filingPeriod: string | null;
}

interface ElectionInfoModalState {
  showModal: boolean;
  dr1: DR1DTO;
  dr2Id: string;
  saving: boolean;
  IncumbentInd: boolean | null;
  committeeFilingCatType: string;
  filingPeriod: string | null;
  periodMasterId: number;
}

class ElectionInfoModal extends React.Component<ElectionInfoModalProps, ElectionInfoModalState>{
  private readonly _formRef = React.createRef<FormInstance>();

  constructor(props: ElectionInfoModalProps) {
    super(props);

      this.state = {
          showModal: true,
          dr1: this.props.dr1,
          dr2Id: this.props.dr2Id,
          saving: false,
          committeeFilingCatType: CurrentUser.Get()?.committeeFilingCatType || '',
          filingPeriod: this.props.filingPeriod,
          periodMasterId: 0,
          IncumbentInd: null,
        };
        this.loadDr2CommitteeFiling();
      }
    
    onChangeRadioButtons = (e: any) => {
        this.setState({
            IncumbentInd: e.target.value,
        });
    };

  render() {
    const { showModal, dr1, saving, IncumbentInd, filingPeriod } = this.state;
    const { dateRequired } = this.props;
    const initialValues = {
      electionYear: dr1.committeeInfo?.electionYear || moment().year(),
      electionDate: dr1.committeeInfo?.electionDate
    };
    return (
      <Modal
        title="Update Information"
        visible={showModal}
        confirmLoading={saving}
        onCancel={this.handleCancel}
        onOk={this.handleOk}>
        <Form ref={this._formRef} initialValues={initialValues}>
          <FormItem name="electionYear" label="Election Year" style={filingPeriod == ElectionYear.NONELECTION ? { display: 'none' } : {}}>
            <Input disabled={true}/>
          </FormItem>
          <FormItem
            name="electionDate"
            label="Election Date"
            style={filingPeriod == ElectionYear.NONELECTION ? { display: 'none' } : {}}
            rules={[FormValidationUtil.RequiredDate('Election Date is required', dateRequired)]}>
            <CustomDatePicker onChange={this.handleDateChange} />
          </FormItem>
          <FormItem label="Incumbent" required={false}
                    style={this.state.committeeFilingCatType !== CommFilingPeriodCatType.SWGA ? { display: 'none' } : {}}>
            <Radio.Group value={IncumbentInd} onChange={this.onChangeRadioButtons} style={{ width: '100%' }}>
                  <Space direction="vertical" style={{ width: '100%' }}>
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
                  </Space>
            </Radio.Group>
          </FormItem>
        </Form>
      </Modal>
    );
  }

  private handleDateChange = (value: moment.Moment | null) => {
    if (value?.year) {
      this._formRef.current?.setFieldsValue({ 'electionYear': value.year() });
    }
  }



  private handleCancel = () => {
    this.setState({ showModal: false });
    this.props.onClose();
  }

  private handleOk = () => {
    const IncumbentInd = this.state.IncumbentInd;
    const DR1 = { ...this.state.dr1 };
    if (DR1) {
      this.setState({ saving: true });
      this._formRef.current?.validateFields().then((values) => {
        const fileTypeDTO = FilerTypeDTO.create({});
        fileTypeDTO.id = CurrentUser.Get()?.filerTypeId || 0;
        DR1.committeeFilerType = { ...this.state.dr1.committeeFilerType, ...fileTypeDTO };
        DR1.committeeInfo = { ...this.state.dr1.committeeInfo, ...values };
        OrganizationApiService
          .editDR1(DR1, CurrentUser.Get()?.committeeId || '', FilingStatusType.AMENDED, DateUtil.toTimeStamp(moment()))
          .then(result => {
            if (result.succeeded) {
              notification.success({
                message: 'Your DR1 has been changed successfully.'
              });
              this.setState({ showModal: false });
              this.props.onSubmit(DR1, IncumbentInd);
            }
          }).catch(() => {
            notification.error({
              message: 'DR1 changes failed.'
            });
          });
      }).finally(() => {
        this.setState({ saving: false });
      });
    }
  }

  private loadDr2CommitteeFiling = () => {

    Dr2ApiService.getDr2CommFiling(this.props.dr2Id).then((commFiling) => {
      this.setState({ IncumbentInd: commFiling.incumbentInd ?? null });
    }).catch(() => {
      notification.error({ message: 'Failed to load Committee Filing data.' });
    });
  }

}

export default ElectionInfoModal;