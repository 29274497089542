// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import TableRequestDTO from './TableRequestDTO';
import StatementsOfOrganizationAndDissolutionDTO from './StatementsOfOrganizationAndDissolutionDTO';

interface Dr1HistoryDTO { 
  table: TableRequestDTO | null;
  row: StatementsOfOrganizationAndDissolutionDTO | null;
}
const Dr1HistoryDTO = {
  create: (initValues?: Partial<Dr1HistoryDTO> | Record<string, unknown> | null) => {
    return Object.assign(
    {
      table: null,
      row: null,
    },
    initValues);
  }
};

export default Dr1HistoryDTO;