import React from 'react';
import ReactDOM from 'react-dom';
import { LocalizeProvider } from 'react-localize-redux';
import { Provider } from 'react-redux';
import { Route, Router } from 'react-router-dom';
import App from './App';
import NotFoundComponent from './components/NotFoundComponent';
import './index.less';
import PublicLayout from './layouts/PublicLayout';
import { setStore } from './redux/getStore';
import { store } from './redux/Store';
import reportWebVitals from './reportWebVitals';
import addGlobals from './utils/Globals';
import GoogleAnalytics from './utils/GoogleAnalytics';
import History from './utils/HistoryUtil';
// @ts-ignore

addGlobals();
setStore(store);
if ($environment === 'Prod') {
  GoogleAnalytics.Initialize();
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <LocalizeProvider store={store}>
        <Router history={History}>
          <Route render={(props) =>
            // @ts-ignore
            props.location.state && props.location.state.is404 ? (
              <PublicLayout {...(props as any)}>
                <NotFoundComponent />
              </PublicLayout>
            ) : (
              <App />
            )
          }
          />
        </Router>
      </LocalizeProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
