import { Col, Layout, Modal, notification, Row } from 'antd';
import * as React from 'react';
import AdminOieApiService from '../../api/AdminOieApiService';
import FilingStatusType from '../../consts/FilingStatusType';
import Routes from '../../consts/Routes';
import AdminOieListDTO from '../../models/AdminOieListDTO';
import HistoryUtil from '../../utils/HistoryUtil';
import DataTable, { DataTableColumnProps, FilterType } from '../shared/DataTable/DataTable';
import DataTableButtonUtil from '../shared/DataTable/DataTableButtonUtil';
import DataTableColumnUtil from '../shared/DataTable/DataTableColumnUtil';

const { Content } = Layout;
const { confirm } = Modal;

interface ManageOieProps {

}

interface ManageOieState {
  tableManageColumns: DataTableColumnProps<AdminOieListDTO>[];
  tableApproveColumns: DataTableColumnProps<AdminOieListDTO>[];
}

class ManageOie extends React.Component<ManageOieProps, ManageOieState> {
  private dataManageTable: DataTable<AdminOieListDTO> | undefined;
  private dataApproveTable: DataTable<AdminOieListDTO> | undefined;

  constructor(props: ManageOieProps) {
    super(props);
    this.state = {
      tableManageColumns: this.getTableColoumns('manage'),
      tableApproveColumns: this.getTableColoumns('approve')
    };

  }
  render() {
    const manageActionButtons: any = [];
    manageActionButtons.push(DataTableButtonUtil.Reset());

    const approveActionButtons: any = [];
    approveActionButtons.push(DataTableButtonUtil.Reset());

    return (
      <Content className="content-pad">
        <Row>
          <Col xs={24}>
            <DataTable
              ref={(element: any) => (this.dataApproveTable = element)}
              serverSide={true}
              tableProps={{
                rowKey: 'expendId',
                sortDirections: ['ascend', 'descend'],
                locale: {
                  emptyText: 'Currently there are no OIE\'s that need to be approved '
                }
              }}
              globalSearch={true}
              title="Approve OIE"
              buttonBar={approveActionButtons}
              columns={this.state.tableApproveColumns}
              fetchData={{
                fetch: function (tableRequest) {
                  return AdminOieApiService.getOieList(tableRequest,
                    FilingStatusType.FILED );
                },
                failureMessage: 'Failed to retrieve Approved Expenditures'
              }}
              styleOptions={{ compact: true, alternatingRowHighlight: true }}
              stateSaving={{
                enabled: true,
                tableUniqueKey: 'adminApproveOie',
                perSession: true,
              }}
            />
          </Col>
        </Row>
        <br />
        <Row>
          <Col xs={24}>
            <DataTable
              ref={(element: any) => (this.dataManageTable = element)}
              serverSide={true}
              tableProps={{
                rowKey: 'expendId',
                sortDirections: ['ascend', 'descend'],
                locale: {
                  emptyText: 'Currently there are no OIE\'s that need to be reviewed',
                }
              }}
              globalSearch={true}
              title="Manage OIE"
              buttonBar={manageActionButtons}
              columns={this.state.tableManageColumns}
              fetchData={{
                fetch: function (tableRequest) {
                  return AdminOieApiService.getOieList(tableRequest, FilingStatusType.CERTIFIED + ',' + FilingStatusType.ADJUSTED);
                },
                failureMessage: 'Failed to retrieve Expenditures'
              }}
              styleOptions={{ compact: true, alternatingRowHighlight: true }}
              stateSaving={{
                enabled: true,
                tableUniqueKey: 'adminManageOie',
                perSession: true,
              }}
            />
          </Col>
        </Row>
      </Content>
    );
  }
  
  private getTableColoumns(tableName: string) {

    const columns: DataTableColumnProps<AdminOieListDTO>[] = [];

    const columnDateOfExpenditure: DataTableColumnProps<AdminOieListDTO> =
      DataTableColumnUtil.Date('Date', 'dateOfExpenditure', 100, { defaultSortOrder: 'descend' });
    columns.push(columnDateOfExpenditure);

    const columnAmount: DataTableColumnProps<AdminOieListDTO> = DataTableColumnUtil.Currency('Amount', 'amount', 10);
    columns.push(columnAmount);

    const columnOrganizationName: DataTableColumnProps<AdminOieListDTO> =
      DataTableColumnUtil.Address('Organization', 'organizationName', (r) => ({
        name: r.organizationName,
        line1: r.organizationAddress1,
        line2: r.organizationAddress2,
        city: r.organizationCity,
        state: r.organizationState,
        zip: r.organizationZip
      }));
    columns.push(columnOrganizationName);

    const columnCommitteName: DataTableColumnProps<AdminOieListDTO> = DataTableColumnUtil.Address('Committee', 'committeeName', (r) => ({
      name: r.committeeName,
      line1: r.committeeAddress1,
      line2: r.committeeAddress2,
      city: r.committeeCity,
      state: r.committeeState,
      zip: r.committeeZip
    }));
    columns.push(columnCommitteName);


    const columnPosition: DataTableColumnProps<AdminOieListDTO> = DataTableColumnUtil.Text('Position', 'position');
    columns.push(columnPosition);

    const columnAdvocate: DataTableColumnProps<AdminOieListDTO> = DataTableColumnUtil.Text('Advocate', 'advocate');
    columns.push(columnAdvocate);
    
    if (tableName === 'approve') {
      const approveButtons = [
        { text: 'View', onClick: (rowData: AdminOieListDTO) => this.editOie(rowData.expendId || '') },
        { text: 'Approve', onClick: (rowData: AdminOieListDTO) => this.approve(rowData.expendId || '') },
        { text: 'Reject', onClick: (rowData: AdminOieListDTO) => this.confirmReject(rowData.expendId || '', rowData.organizationName || '') },
      ];

      const columnButtons: DataTableColumnProps<AdminOieListDTO> = DataTableColumnUtil.Buttons('ExpendId', approveButtons, 210);
      columns.push(columnButtons);
    }
    else if (tableName === 'manage') {
      const columnPenalty: DataTableColumnProps<AdminOieListDTO> =
      DataTableColumnUtil.BooleanCheckbox('Penalty', 'penalty', 10, FilterType.BooleanRadio);
      columns.push(columnPenalty);

      const manageButtons = [
        { text: 'Edit', onClick: (rowData: AdminOieListDTO) => this.editOie(rowData.expendId || '') },
        { text: 'Delete', onClick: (rowData: AdminOieListDTO) => this.confirmDelete(rowData.expendId || '', rowData.organizationName || '') },
        { text: 'Penalty', onClick: (rowData: AdminOieListDTO) => this.penalty(rowData.expendId || '') },
      ];

      const columnButtons: DataTableColumnProps<AdminOieListDTO> = DataTableColumnUtil.Buttons('ExpendId', manageButtons, 210);
      columns.push(columnButtons);
    }
    return columns;
  }

  private approve = (ExpendId: string) => {
    AdminOieApiService.approveOie(ExpendId)
      .then(() => {
        if (this.dataApproveTable) {
          this.dataApproveTable.refresh();
        }
        if (this.dataManageTable) {
          this.dataManageTable.refresh();
        }
        notification.success({ message: 'Your changes were saved successfully.' });
      }).catch(() => {
        notification.error({
          message: 'Failed to approve filing.'
        });
      });
  }

  private reject = (ExpendId: string) => {
    AdminOieApiService.rejectOie(ExpendId)
      .then(() => {
        if (this.dataApproveTable) {
          this.dataApproveTable.refresh();
        }
        notification.success({ message: 'OIE was rejected successfully.' });
      }).catch(() => {
        notification.error({
          message: 'Failed to reject filing.'
        });
      });
  }

  private confirmReject = (ExpendId: string, organizationName: string) => {
    confirm({
      title: 'Are you sure you want to reject this OIE Approval?',
      content: 'The following record will be rejected: ' + organizationName,
      onOk: () => {
        this.reject(ExpendId);
      },
    });
  }

  private delete = (ExpendId: string) => {
    AdminOieApiService.deleteOie(ExpendId)
      .then(() => {
        if (this.dataManageTable) {
          this.dataManageTable.refresh();
        }
        notification.success({ message: 'OIE was deleted successfully.' });
      }).catch(() => {
        notification.error({
          message: 'Failed to delete filing.'
        });
      });
  }

  private confirmDelete = (ExpendId: string, organizationName: string) => {
    confirm({
      title: 'Are you sure you want to delete this OIE?',
      content: 'The following record will be permanently deleted: ' + organizationName,
      onOk: () => {
        this.delete(ExpendId);
      },
    });
  }

  private penalty = async (id: string) => {
    HistoryUtil.push(Routes.generate(Routes.OIE_PENALTY, { id: id }));
  }

  private editOie = async (expendId: string) => {
    HistoryUtil.push(Routes.generate(Routes.EDIT_OIE, { id: expendId }));
  }

}

export default ManageOie;