import {
  Button,
  Col,
  ColProps,
  FormInstance,
  FormProps,
  Input,
  Layout,
  notification,
  Row,
  Typography
  } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import AccountApiService from '../../api/AccountApiService';
import Routes from '../../consts/Routes';
import ChangePasswordDTO from '../../models/ChangePasswordDTO';
import UserSettingsDataDTO from '../../models/UserSettingsDataDTO';
import FormValidationUtil from '../../utils/FormValidationUtil';
import HistoryUtil from '../../utils/HistoryUtil';
import CustomForm from '../shared/CustomForm';

const { Title } = Typography;
const { Content } = Layout;

interface UserSettingsProps {
  
}

interface UserSettingsState {
  userSettings: UserSettingsDataDTO;
  loading: boolean;
}

class UserSettings extends React.Component<RouteComponentProps<UserSettingsProps> & FormProps, UserSettingsState> {
  private readonly _formRef = React.createRef<FormInstance>();

  constructor(props: RouteComponentProps<UserSettingsProps> & FormProps) {
    super(props);

    this.state = {
      userSettings: UserSettingsDataDTO.create(),
      loading: false
    };
  }

  componentDidMount() {
    this.loadUserSettingsData();
  }

  render() {
    const columnSizingProps: ColProps = { xs: 24, sm: 24, md: 12 };
    const { userSettings, loading } = this.state;
    const rowGutterLen = 64;

    return (
      <Content className="content-pad">
        {!loading && (
          <CustomForm
            formRef={this._formRef}
            validateTrigger={['onChange', 'onBlur']}
            onFinish={(values) => this.changePassword(values)}
            initialValues={userSettings}
            layout="vertical">
            <Title level={3}>User Settings</Title>
            <Title level={4}>General Committee & User Info</Title>
            <Row gutter={rowGutterLen}>
              <Col {...columnSizingProps}>
                <FormItem name="committeeName" label="Name">
                  <label>{userSettings.committeeName}</label>
                </FormItem>
                <FormItem name="committeeType" label="Type">
                  <label>{userSettings.committeeType}</label>
                </FormItem>
                <FormItem name="category" label="Category">
                  <label>{userSettings.category}</label>
                </FormItem>
              </Col>
              <Col {...columnSizingProps}>
                <FormItem name="committeeCode" label="Code">
                  <label>{userSettings.committeeCode}</label>
                </FormItem>
                <FormItem name="filingType" label="Filing Type">
                  <label>{userSettings.filingType}</label>
                </FormItem>
                <FormItem name="userName" label="Username">
                  <label>{userSettings.userName}</label>
                </FormItem>
              </Col>
            </Row>
            <Row gutter={rowGutterLen}>
              <Col {...columnSizingProps}>
                <Title level={4}>Password Change</Title>
                <FormItem
                  name="oldPassword"
                  label="Old Password"
                  rules={[
                    FormValidationUtil.Required('Old Password is required')
                  ]}>
                  <Input.Password />
                </FormItem>
                <FormItem
                  name="newPassword"
                  label="New Password"
                  rules={[
                    FormValidationUtil.Required('New password is required'),
                    { ...FormValidationUtil.ValidatePassword() }
                  ]}>
                  <Input.Password />
                </FormItem>
                <FormItem
                  name="confirmPassword"
                  label="Confirm Password"
                  rules={[
                    FormValidationUtil.Required('Confirm password is required'),
                    FormValidationUtil.CheckConfirmPassword('newPassword')
                  ]}>
                  <Input.Password />
                </FormItem>
                <Button type="primary" htmlType="submit">Save New Password</Button>
              </Col>
            </Row>
          </CustomForm>
        )}
      </Content>
    );
  }

  private loadUserSettingsData = () => {
    this.setState({ loading: true });

    AccountApiService.getCurrentUserSettings()
      .then((userSettings) => {
        if (!userSettings) {
          HistoryUtil.push(Routes.generate(Routes.HOME_ROUTE));
        }
        this.setState({ userSettings, loading: false });
      }).catch(() => {
        notification.error({ message: 'Failed to load user settings data.' });
        this.setState({ loading: false });
      });
  }

  private changePassword = (values: ChangePasswordDTO) => {
    AccountApiService.changePassword(values)
      .then((results) => {
        if (results.succeeded) {
          notification.success({ message: 'The password was changed successfully.' });
        }
        else {
          notification.error({ message: results.errors });
        }
      }).catch(() => {
        notification.error({ message: 'Failed to change the password.' });
        this.setState({ loading: false });
      });
  }
}

export default UserSettings;
