import { Button, Space, Typography } from 'antd';
import * as React from 'react';
import DataTableButtonUtil from '../../shared/DataTable/DataTableButtonUtil';
import { Content } from 'antd/lib/layout/layout';
import DataTable, { DataTableColumnProps } from '../../shared/DataTable/DataTable';
import DataTableColumnUtil from '../../shared/DataTable/DataTableColumnUtil';
import PublicReportsApiService from '../../../api/PublicReportsApiService';
import { ArrowLeftOutlined } from '@ant-design/icons';
import HistoryUtil from '../../../utils/HistoryUtil';
import Routes from '../../../consts/Routes';
import SalesByStateReportsDTO from '../../../models/SalesByStateReportsDTO';

interface SalesByStateReportsProps {

}

interface SalesByStateReportsState {
    tableColumns: DataTableColumnProps<SalesByStateReportsDTO>[];
}

class SalesByStateReports extends React.Component<SalesByStateReportsProps, SalesByStateReportsState> {
    private dataReportTable: DataTable<SalesByStateReportsDTO> | undefined;

    constructor(props: SalesByStateReportsProps) {
        super(props);
        this.state = {
            tableColumns: this.getTableColumns()
        };
    }
    render() {
        const actionButtons = [];
        actionButtons.push(DataTableButtonUtil.Reset());

        return (
            <Content className="content-pad">
                <DataTable
                    ref={(element: any) => (this.dataReportTable = element)}
                    serverSide={true}
                    tableProps={{
                        rowKey: 'salesByStateId',
                        sortDirections: ['ascend', 'descend'],
                        locale: {
                            emptyText: 'No Reports Currently Match Your Filter'
                        }
                    }}
                    globalSearch={true}
                    buttonBar={actionButtons}
                    columns={this.state.tableColumns}
                    fetchData={{
                    fetch: function (tableRequest) {
                        return PublicReportsApiService.getSalesByStateReports(tableRequest); },
                        failureMessage: 'Failed to retrieve Sales by Officials/Employees'
                    }}
                    styleOptions={{ compact: true, alternatingRowHighlight: true }}
                    title={
                        <Button type="link" onClick={() => HistoryUtil.push(Routes.generate(Routes.PUBLIC_REPORTS))}>
                            <Typography.Title level={4}>
                                <Space size="large">
                                    <ArrowLeftOutlined className="public-report-links" /> Sales by Officials/Employees to Agencies
                                </Space>
                            </Typography.Title>
                        </Button>
                    }
                />
            </Content>
        );
    }

    private getTableColumns() {
        const columns: DataTableColumnProps<SalesByStateReportsDTO>[] = [];

        const columnOfficialName: DataTableColumnProps<SalesByStateReportsDTO> = DataTableColumnUtil.Text('Official Name', 'officialName');
        columns.push(columnOfficialName);

        const columnOriginalExecutiveBranchAgencyName: DataTableColumnProps<SalesByStateReportsDTO> =
            DataTableColumnUtil.Text('Original Executive Branch Agency Name', 'executiveBranchAgencyName');
        columns.push(columnOriginalExecutiveBranchAgencyName);

        const columnDateOfSale: DataTableColumnProps<SalesByStateReportsDTO> = DataTableColumnUtil.Date('Date of Sale', 'dateOfSale');
        columns.push(columnDateOfSale);

        const columnSaleAmount: DataTableColumnProps<SalesByStateReportsDTO> = DataTableColumnUtil.Currency('Sale Amount', 'saleAmount');
        columns.push(columnSaleAmount);

        const columnSaleDescription: DataTableColumnProps<SalesByStateReportsDTO> = DataTableColumnUtil.Text('Description of Sale',
                                                                                                             'saleDescription');
        columns.push(columnSaleDescription);

        const columnFiledDate: DataTableColumnProps<SalesByStateReportsDTO> =
            DataTableColumnUtil.Date('Filed Date', 'filedDate', null, { defaultSortOrder: 'descend' });
        columns.push(columnFiledDate);

        const buttons = [
            { text: 'View', onClick: (rowData: SalesByStateReportsDTO) => window.open(rowData.fileUrl || null || '') },
        ];

        const columnButtons: DataTableColumnProps<SalesByStateReportsDTO> = DataTableColumnUtil.Buttons('salesByStateId', buttons, 220);
        columns.push(columnButtons);

        return columns as DataTableColumnProps<SalesByStateReportsDTO>[];
    }
}

export default SalesByStateReports;