// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import EntitySelectorDTO from './EntitySelectorDTO';

interface GbReceivingDepartmentDTO extends EntitySelectorDTO { 
  receivingDepartmentId: string | null;
  contactName: string | null;
  addressLine1: string | null;
  addressLine2: string | null;
  city: string | null;
  stateCd: string | null;
  zip: string | null;
  phone: string | null;
  contactEmail: string | null;
  departmentId: number | null;
  divisionId: number | null;
}
const GbReceivingDepartmentDTO = {
  create: (initValues?: Partial<GbReceivingDepartmentDTO> | Record<string, unknown> | null) => {
    return Object.assign(EntitySelectorDTO.create(),
    {
      receivingDepartmentId: "00000000-0000-0000-0000-000000000000",
      contactName: null,
      addressLine1: null,
      addressLine2: null,
      city: null,
      stateCd: null,
      zip: null,
      phone: null,
      contactEmail: null,
      departmentId: null,
      divisionId: null,
    },
    initValues);
  }
};

export default GbReceivingDepartmentDTO;