// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';

interface DissolutionInfoDTO { 
  dissolveDate: moment.Moment | string | null;
  filedDate: moment.Moment | string | null;
}
const DissolutionInfoDTO = {
  create: (initValues?: Partial<DissolutionInfoDTO> | Record<string, unknown> | null) => {
    return Object.assign(
    {
      dissolveDate: null,
      filedDate: null,
    },
    initValues);
  }
};

export default DissolutionInfoDTO;