// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import EntitySelectorDTO from './EntitySelectorDTO';

interface SchBExpendituresImportFormDTO extends EntitySelectorDTO { 
  combinedKey: string | null;
  batchNumber: number;
  transactionNumber: number;
  userID: number;
  payeeAutoFillId: string | null;
  committeeName: string | null;
  invalidCommitteeName: string | null;
  committeeCode: string | null;
  committeeNameAndCode: string | null;
  perorgId: string | null;
  contactType: string | null;
  contactFirstName: string | null;
  contactLastName: string | null;
  contactMiddleInitial: string | null;
  contactRelationship: string | null;
  invalidRelationshipString: string | null;
  contactName: string | null;
  trustee: string | null;
  trustor: string | null;
  isLivingOrRevocableTrust: boolean;
  trustAffirmationReceived: string | null;
  contactAddress1: string | null;
  contactAddress2: string | null;
  contactCity: string | null;
  contactState: string | null;
  invalidStateString: string | null;
  contactZip: string | null;
  contactPrimaryPhone: string | null;
  contactPhoneString: string | null;
  contactEmail: string | null;
  date: moment.Moment | string | null;
  dateString: string | null;
  amount: number | null;
  checkNumber: string | null;
  expenseType: string | null;
  invalidExpenseTypeString: string | null;
  explanation: string | null;
  numMiles: number | null;
  mileageRate: number | null;
  hasError: boolean;
  saved: boolean;
  totalTransactions: number;
  createdOn: moment.Moment | string;
  exception: string | null;
}
const SchBExpendituresImportFormDTO = {
  create: (initValues?: Partial<SchBExpendituresImportFormDTO> | Record<string, unknown> | null) => {
    return Object.assign(EntitySelectorDTO.create(),
    {
      combinedKey: null,
      batchNumber: 0,
      transactionNumber: 0,
      userID: 0,
      payeeAutoFillId: null,
      committeeName: null,
      invalidCommitteeName: null,
      committeeCode: null,
      committeeNameAndCode: null,
      perorgId: null,
      contactType: null,
      contactFirstName: null,
      contactLastName: null,
      contactMiddleInitial: null,
      contactRelationship: null,
      invalidRelationshipString: null,
      contactName: null,
      trustee: null,
      trustor: null,
      isLivingOrRevocableTrust: false,
      trustAffirmationReceived: null,
      contactAddress1: null,
      contactAddress2: null,
      contactCity: null,
      contactState: null,
      invalidStateString: null,
      contactZip: null,
      contactPrimaryPhone: null,
      contactPhoneString: null,
      contactEmail: null,
      date: null,
      dateString: null,
      amount: null,
      checkNumber: null,
      expenseType: null,
      invalidExpenseTypeString: null,
      explanation: null,
      numMiles: null,
      mileageRate: null,
      hasError: false,
      saved: false,
      totalTransactions: 0,
      createdOn: new Date(0).toISOString(),
      exception: null,
    },
    initValues);
  }
};

export default SchBExpendituresImportFormDTO;