// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
import BaseApi from './BaseApi';
import TableRequestDTO from '../models/TableRequestDTO';
import TableResponseDTO from '../models/TableResponseDTO';
import AdminConsentForSalesAndLeasesDTO from '../models/AdminConsentForSalesAndLeasesDTO';
import AdminConsentForSalesAndLeasesFormDTO from '../models/AdminConsentForSalesAndLeasesFormDTO';

export class AdminConsentForSalesAndLeasesApiService extends BaseApi {

  // post: api/admin/salesAndLease/table
  public getConsentForSaleAndLease(tableRequest: TableRequestDTO): Promise<TableResponseDTO<AdminConsentForSalesAndLeasesDTO>> {
    let url = `api/admin/salesAndLease/table`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, TableResponseDTO<AdminConsentForSalesAndLeasesDTO>>(tableRequest, 'post', url, true, false);
  }

  // get: api/admin/salesAndLease/form?cfsl=${cfsl}
  public getConsentForSalesAndLeasesForm(cfsl: number): Promise<AdminConsentForSalesAndLeasesFormDTO> {
    let url = `api/admin/salesAndLease/form`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (cfsl != null) {
      url += `${prefix}cfsl=${cfsl}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, AdminConsentForSalesAndLeasesFormDTO>(null, 'get', url, true, false);
  }

  // post: api/admin/salesAndLease/upload
  public upload(request: FormData): Promise<void> {
    let url = `api/admin/salesAndLease/upload`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, void>(request, 'post', url, false, false);
  }

  // delete: api/admin/salesAndLease/delete/${cfslId}
  public delete(cfslId: number): Promise<void> {
    let url = `api/admin/salesAndLease/delete/${cfslId}`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, void>(null, 'delete', url, true, false);
  }

  // get: api/admin/salesAndLease?blobFileName=${encodeURIComponent(blobFileName)}
  public getBlob(blobFileName: string): Promise<string> {
    let url = `api/admin/salesAndLease`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (blobFileName != null) {
      url += `${prefix}blobFileName=${encodeURIComponent(blobFileName)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, string>(null, 'get', url, true, false);
  }
}
var service = new AdminConsentForSalesAndLeasesApiService();
export default service;
