import { Col, Layout, Modal, notification, Row } from 'antd';
import * as React from 'react';
import AdminDrsfaApiService from '../../api/AdminDrsfaApiService';
import DrsfaPurposes from '../../consts/DrsfaPurposes';
import FilingStatusType from '../../consts/FilingStatusType';
import Routes from '../../consts/Routes';
import AdminDrsfaListDTO from '../../models/AdminDrsfaListDTO';
import HistoryUtil from '../../utils/HistoryUtil';
import DataTable, { DataTableColumnProps } from '../shared/DataTable/DataTable';
import DataTableButtonUtil from '../shared/DataTable/DataTableButtonUtil';
import DataTableColumnUtil from '../shared/DataTable/DataTableColumnUtil';

const { Content } = Layout;
const { confirm } = Modal;

const purposeOptions = [
  { text: DrsfaPurposes.ADVOCATECANDIDATE, value: DrsfaPurposes.ADVOCATECANDIDATE },
  { text: DrsfaPurposes.BALLOTAGAINST, value: DrsfaPurposes.BALLOTAGAINST },
  { text: DrsfaPurposes.BALLOTFOR, value: DrsfaPurposes.BALLOTFOR }
];

interface ManageDrsfaProps {
}

interface ManageDrsfaState {
  tableApproveColumns: DataTableColumnProps<AdminDrsfaListDTO>[];
  tableManageColumns: DataTableColumnProps<AdminDrsfaListDTO>[];
}

class ManageDrsfa extends React.Component<ManageDrsfaProps, ManageDrsfaState> {
  private dataApproveTable: DataTable<AdminDrsfaListDTO> | undefined;
  private dataManageTable: DataTable<AdminDrsfaListDTO> | undefined;

  constructor(props: ManageDrsfaProps) {
    super(props);

    this.state = {
      tableApproveColumns: this.getTableColumns('approve'),
      tableManageColumns: this.getTableColumns('manage')
    };
  }

  render() {
    const approveActionButtons: any = [];
    approveActionButtons.push(DataTableButtonUtil.Reset());

    const manageActionButtons: any = [];
    manageActionButtons.push(DataTableButtonUtil.Reset());

    return (
      <Content className="content-pad">
        <Row>
          <Col xs={24}>
            <DataTable
              ref={(element: any) => (this.dataApproveTable = element)}
              serverSide={true}
              tableProps={{
                rowKey: 'drfsaId',
                sortDirections: ['ascend', 'descend'],
                locale: {
                  emptyText: 'Currently there are no DRSFA\'s that need to be approved'
                }
              }}
              globalSearch={true}
              title="Approve DRSFA"
              buttonBar={approveActionButtons}
              columns={this.state.tableApproveColumns}
              fetchData={{
                fetch: function (tableRequest) { return AdminDrsfaApiService.getDrsfaList(tableRequest, false); },
                failureMessage: 'Failed to retrieve DRSFA'
              }}
              styleOptions={{ compact: true, alternatingRowHighlight: true }}
              stateSaving={{
                enabled: true,
                tableUniqueKey: 'adminApproveDrsfa',
                perSession: true,
              }}
            />
            <br />
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <DataTable
              ref={(element: any) => (this.dataManageTable = element)}
              serverSide={true}
              tableProps={{
                rowKey: 'drfsaId',
                sortDirections: ['ascend', 'descend'],
                locale: {
                  emptyText: 'Currently there are no DRSFA\'s to review'
                }
              }}
              globalSearch={true}
              title="Manage DRSFA"
              buttonBar={manageActionButtons}
              columns={this.state.tableManageColumns}
              fetchData={{
                fetch: function (tableRequest) { return AdminDrsfaApiService.getDrsfaList(tableRequest, true); },
                failureMessage: 'Failed to retrieve DRSFA'
              }}
              styleOptions={{ compact: true, alternatingRowHighlight: true }}
              stateSaving={{
                enabled: true,
                tableUniqueKey: 'adminManageDrsfa',
                perSession: true,
              }}
            />
          </Col>
        </Row>
      </Content>
    );
  }

  private getTableColumns(tableName: string) {
    const columns: DataTableColumnProps<AdminDrsfaListDTO>[] = [];

    const columnCommitteeName: DataTableColumnProps<AdminDrsfaListDTO> = DataTableColumnUtil.Text('Committee Name', 'committeeName');
    columns.push(columnCommitteeName);

    const columnCandidateName: DataTableColumnProps<AdminDrsfaListDTO> = DataTableColumnUtil.Text('Committee Chair/Candidate', 'candidateName');
    columns.push(columnCandidateName);

    const columnPurpose: DataTableColumnProps<AdminDrsfaListDTO> = DataTableColumnUtil.DropdownMulti('Purpose', 'purpose', purposeOptions, 250);
    columns.push(columnPurpose);

    const columnElectionYear: DataTableColumnProps<AdminDrsfaListDTO> = DataTableColumnUtil.Text('Election Year', 'electionYear', 150);
    columns.push(columnElectionYear);

    const columnSubmissionDate: DataTableColumnProps<AdminDrsfaListDTO> = DataTableColumnUtil.Date('Submission Date', 'submittedDate', 200);
    columnSubmissionDate.defaultSortOrder = 'descend';
    columns.push(columnSubmissionDate);

    if (tableName === 'approve') {
      const approveButtons = [
        { text: 'View', onClick: (rowData: AdminDrsfaListDTO) => this.view(rowData.drsfaId || '') },
        { text: 'Approve', onClick: (rowData: AdminDrsfaListDTO) => this.approve(rowData.drsfaId || '') },
        { text: 'Reject', onClick: (rowData: AdminDrsfaListDTO) => this.confirmReject(rowData.drsfaId || '', rowData.committeeName || '') }
      ];
      const columnApprove: DataTableColumnProps<AdminDrsfaListDTO> = DataTableColumnUtil.Buttons('drsfaId', approveButtons, 220);
      columns.push(columnApprove);
    }
    else if (tableName === 'manage') {
      const manageButtons = [
        { text: 'Edit', onClick: (rowData: AdminDrsfaListDTO) => this.edit(rowData.drsfaId || '') },
        { text: 'Delete', onClick: (rowData: AdminDrsfaListDTO) => this.confirmDelete(rowData.drsfaId || '', rowData.committeeName || '') }
      ];
      const columnManage: DataTableColumnProps<AdminDrsfaListDTO> = DataTableColumnUtil.Buttons('drsfaId', manageButtons, 150);
      columns.push(columnManage);
    }

    return columns as DataTableColumnProps<AdminDrsfaListDTO>[];
  }

  private edit = async (drsfaId: string) => {
    HistoryUtil.push(Routes.generate(Routes.EDIT_DRSFA, { 'drsfaId': drsfaId, 'filingStatusType': FilingStatusType.AUDITED }));
  }

  private delete = (drsfaId: string) => {
    AdminDrsfaApiService.delete(drsfaId)
      .then(() => {
        if (this.dataManageTable) {
          this.dataManageTable.refresh();
        }
        notification.success({
          message: 'Deleted Successfully'
        });
      }).catch(() => {
        notification.error({
          message: 'Failed to delete filing.'
        });
      });
  }

  private confirmDelete = (drsfaId: string, commiteeName: string) => {
    confirm({
      title: 'Are you sure you want to delete this DRSFA?',
      content: 'The following record will be permanently deleted: ' + commiteeName,
      onOk: () => {
        this.delete(drsfaId);
      },
    });
  }

  private view = async (drsfaId: string) => {
    HistoryUtil.push(Routes.generate(Routes.EDIT_DRSFA, { 'drsfaId': drsfaId, 'filingStatusType': FilingStatusType.FILED }));
  }

  private approve = (drsfaId: string) => {
    AdminDrsfaApiService.approve(drsfaId)
      .then(() => {
        if (this.dataApproveTable) {
          this.dataApproveTable.refresh();
        }
        if (this.dataManageTable) {
          this.dataManageTable.refresh();
        }
        notification.success({
          message: 'Approved Successfully'
        });
      }).catch(() => {
        notification.error({
          message: 'Failed to approve filing.'
        });
      });
  }

  private reject = (drsfaId: string) => {
    AdminDrsfaApiService.reject(drsfaId)
      .then(() => {
        if (this.dataApproveTable) {
          this.dataApproveTable.refresh();
        }
        notification.success({
          message: 'Rejected Successfully'
        });
      }).catch(() => {
        notification.error({
          message: 'Failed to reject filing.'
        });
      });
  }

  private confirmReject = (drsfaId: string, commiteeName: string) => {
    confirm({
      title: 'Are you sure you want to reject this DRSFA?',
      content: 'The following record will be rejected: ' + commiteeName,
      onOk: () => {
        this.reject(drsfaId);
      },
    });
  }
}

export default ManageDrsfa;

