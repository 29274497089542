import { ArrowLeftOutlined } from '@ant-design/icons';
import {
    Button,
    Divider,
    Layout,
    notification,
    Space,
    Tabs,
    Typography,
} from 'antd';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import LookupsApiService from '../../../api/LookupsApiService';
import PublicReportsApiService from '../../../api/PublicReportsApiService';
import Routes from '../../../consts/Routes';
import CommitteeLateReportsDTO from '../../../models/CommitteeLateReportsDTO';
import CommitteeTypeDTO from '../../../models/CommitteeTypeDTO';
import IndependentExpenditureLateReportsDTO from '../../../models/IndependentExpenditureLateReportsDTO';
import OTCLateReportsDTO from '../../../models/OTCLateReportsDTO';
import PFDLateReportsDTO from '../../../models/PFDLateReportsDTO';
import VSRLateReportsDTO from '../../../models/VSRLateReportsDTO';
import HistoryUtil from '../../../utils/HistoryUtil';
import DataTable, { DataTableColumnProps, FilterType } from '../../shared/DataTable/DataTable';
import DataTableButtonUtil from '../../shared/DataTable/DataTableButtonUtil';
import DataTableCellRendererUtil from '../../shared/DataTable/DataTableCellRendererUtil';
import DataTableColumnUtil from '../../shared/DataTable/DataTableColumnUtil';
import FilerTypes from '../../../consts/FilerType';
import TableRequestDTO from '../../../models/TableRequestDTO';
import TableResponseDTO from '../../../models/TableResponseDTO';
import TableExportDTO from '../../../models/TableExportDTO';
import LateReportFilterDTO from '../../../models/LateReportFilterDTO';
import TableRequestFilterDTO from '../../../models/TableRequestFilterDTO';
import LateReportTypes from '../../../consts/LateReportTypes';

const { Content } = Layout;
const { TabPane } = Tabs;

interface CampaignLateReportsProps {
    committeeId?: string;
    lateReportTypeId?: string;
}

interface CampaignLateReportsState {
    committeeTypes: CommitteeTypeDTO[];
    lateReportFilter: LateReportFilterDTO | null;
    committeeId?: string;
    lateReportTypeId?: string;
    exporting: boolean;
    loading: boolean;
    tabKey: string;
}

class CampaignLateReports extends React.Component<RouteComponentProps<CampaignLateReportsProps>, CampaignLateReportsState> {
    private _committeeDataTable: DataTable<CommitteeLateReportsDTO> | null = null;
    private _vsrDataTable: DataTable<VSRLateReportsDTO> | null = null;
    private _independentExpendituresDataTable: DataTable<IndependentExpenditureLateReportsDTO> | null = null;
    private _otcDataTable: DataTable<OTCLateReportsDTO> | null = null;
    private _pfdDataTable: DataTable<PFDLateReportsDTO> | null = null;

    constructor(props: RouteComponentProps<CampaignLateReportsProps>) {
        super(props);
        this.state = {
            committeeTypes: [],
            loading: false,
            exporting: false,
            tabKey: props.match.params.lateReportTypeId ?? '1',
            lateReportFilter: null,
            committeeId: props.match.params.committeeId,
            lateReportTypeId: props.match.params.lateReportTypeId,
        };
    }

    componentDidMount() {
        this.getInitialValues();
        this.fetchReportFilters();
    }

    render() {
        const {
            loading,
            tabKey,
        } = this.state;

        const committeeActionButtons = [];
        committeeActionButtons.push(DataTableButtonUtil.Reset());
        committeeActionButtons.push(DataTableButtonUtil.Export((tableRequest: TableRequestDTO) => {
        this.setState({ exporting: true });

        const exportDTO: TableExportDTO = TableExportDTO.create(
            {
                TableRequest: tableRequest,
                ConvertToCST: true,
            });

        return PublicReportsApiService.exportCommitteeLateReports(exportDTO)
            .then(() => {
                this.setState({ exporting: false });
            })
            .catch((err) => {
                notification.error({ message: err.message });
                this.setState({ exporting: false });
            });

        }, this.state.exporting));

        const vsrActionButtons = [];
        vsrActionButtons.push(DataTableButtonUtil.Reset());
        vsrActionButtons.push(DataTableButtonUtil.Export(
            (tableRequest: TableRequestDTO) => {
                this.setState({ exporting: true });
                const exportDTO: TableExportDTO = TableExportDTO.create(
                    {
                        TableRequest: tableRequest,
                        ConvertToCST: false,
                    });
                return PublicReportsApiService.exportVSRLateReports(exportDTO).then(() => {
                    this.setState({ exporting: false });
                });
            }, this.state.exporting));

        const independentExpendituresActionButtons = [];
        independentExpendituresActionButtons.push(DataTableButtonUtil.Reset());
        independentExpendituresActionButtons.push(DataTableButtonUtil.Export(
            (tableRequest: TableRequestDTO) => {
                this.setState({ exporting: true });
                const exportDTO: TableExportDTO = TableExportDTO.create(
                    {
                        TableRequest: tableRequest,
                        ConvertToCST: false,
                    });
                return PublicReportsApiService.exportIndependentExpenditureLateReports(exportDTO)
                    .then(() => {
                        this.setState({ exporting: false });
                    });
            }, this.state.exporting));


        const otcActionButtons = [];
        otcActionButtons.push(DataTableButtonUtil.Reset());
        otcActionButtons.push(DataTableButtonUtil.Export(
            (tableRequest: TableRequestDTO) => {
                this.setState({ exporting: true });
                const exportDTO: TableExportDTO = TableExportDTO.create(
                    {
                        TableRequest: tableRequest,
                        ConvertToCST: false,
                    });
                return PublicReportsApiService.exportOtcLateReports(exportDTO)
                    .then(() => {
                        this.setState({ exporting: false });
                    });
            }, this.state.exporting));

        const pfdActionButtons = [];
        pfdActionButtons.push(DataTableButtonUtil.Reset());
        pfdActionButtons.push(DataTableButtonUtil.Export(
            (tableRequest: TableRequestDTO) => {
                this.setState({ exporting: true });
                const exportDTO: TableExportDTO = TableExportDTO.create(
                    {
                        TableRequest: tableRequest,
                        ConvertToCST: false,
                    });
                return PublicReportsApiService.exportPfdLateReports(exportDTO)
                    .then(() => {
                        this.setState({ exporting: false });
                    });
            }, this.state.exporting));

        return (
            <Content className="content-pad">
                <Button type="link" onClick={() => HistoryUtil.push(Routes.generate(Routes.PUBLIC_REPORTS))}>
                    <Typography.Title level={4}>
                        <Space size="large">
                            <ArrowLeftOutlined className="public-report-links" /> Campaign Late Reports
                        </Space>
                    </Typography.Title>
                </Button>
                <Divider />
                <Tabs defaultActiveKey={tabKey}>
                    <TabPane tab="Committee Filings" key="1">
                        <DataTable
                            key="1"
                            ref={(element: any) => (this._committeeDataTable = element)}
                            globalSearch={true}
                            serverSide={true}
                            buttonBar={committeeActionButtons}
                            columns={this.getCommitteeTableColumns()}
                            fetchData={this.fetchLateCommitteeFilings}
                            tableProps={{
                                sortDirections: ['ascend', 'descend'],
                                locale: { emptyText: 'No Reports Currently Match Your Filter' },
                                loading: loading
                            }}
                            styleOptions={{ compact: true, alternatingRowHighlight: true }}
                        />
                    </TabPane>
                    <TabPane tab="VSR Contributions" key="2">
                        <DataTable
                            key="2"
                            ref={(element: any) => (this._vsrDataTable = element)}
                            globalSearch={true}
                            serverSide={true}
                            buttonBar={vsrActionButtons}
                            columns={this.getVSRTableColumns()}
                            fetchData={this.fetchVSRs}
                            tableProps={{
                                sortDirections: ['ascend', 'descend'],
                                locale: { emptyText: 'No Reports Currently Match Your Filter' },
                                loading: loading
                            }}
                            styleOptions={{ compact: true, alternatingRowHighlight: true }}
                        />
                    </TabPane>
                    <TabPane tab="Independent Expenditures" key="3">
                        <DataTable
                            key="3"
                            ref={(element: any) => (this._independentExpendituresDataTable = element)}
                            globalSearch={true}
                            serverSide={true}
                            buttonBar={independentExpendituresActionButtons}
                            columns={this.getIndependentExpendituresTableColumns()}
                            fetchData={this.fetchLateExpenditures}
                            tableProps={{
                                sortDirections: ['ascend', 'descend'],
                                locale: { emptyText: 'No Reports Currently Match Your Filter' },
                                loading: loading
                            }}
                            styleOptions={{ compact: true, alternatingRowHighlight: true }}
                        />
                    </TabPane>
                    <TabPane tab="One-Time Contributions" key="4">
                        <DataTable
                            key="4"
                            ref={(element: any) => (this._otcDataTable = element)}
                            globalSearch={true}
                            serverSide={true}
                            buttonBar={otcActionButtons}
                            columns={this.getOTCTableColumns()}
                            fetchData={this.fetchLateOTCs}
                            tableProps={{
                                sortDirections: ['ascend', 'descend'],
                                locale: { emptyText: 'No Reports Currently Match Your Filter' },
                                loading: loading
                            }}
                            styleOptions={{ compact: true, alternatingRowHighlight: true }}
                        />
                    </TabPane>
                </Tabs>
            </Content>
        );
    }

    private getCommitteeTableColumns = (): DataTableColumnProps<CommitteeLateReportsDTO>[] => {
        const committeeTypesFilter = [...this.state.committeeTypes];
        const amt = DataTableColumnUtil.Currency<CommitteeLateReportsDTO>('Amount', 'penaltyAmount');
        amt.sorter = (a: CommitteeLateReportsDTO, b: CommitteeLateReportsDTO) => {
            if (a.penaltyAmount && !b.penaltyAmount) {
                return 1;
            }
            if (!a.penaltyAmount && b.penaltyAmount) {
                return -1;
            }
            if ((a.penaltyAmount || 0) > (b.penaltyAmount || 0)) {
                return 1;
            }
            if ((a.penaltyAmount || 0) < (b.penaltyAmount || 0)) {
                return -1;
            }
            return 0;
        };
        const dueDt = DataTableColumnUtil.Date<CommitteeLateReportsDTO>('Due Date', 'dueDate', null, { defaultSortOrder: 'descend' });
        dueDt.render = DataTableCellRendererUtil.DateAndTime;
        const filedDt = DataTableColumnUtil.Date<CommitteeLateReportsDTO>('Date Filed', 'dateFiled');
        filedDt.render = DataTableCellRendererUtil.DateAndTime;

        return [
            DataTableColumnUtil.Text('Committee Code', 'committeeCode'),
            DataTableColumnUtil.Text<CommitteeLateReportsDTO>('Committee Name', 'committeeName'),
            DataTableColumnUtil.DropdownMulti('Committee Type', 'committeeType',
                committeeTypesFilter.filter(ct => ct.filerTypeCd != FilerTypes.OSF)
                    .map(ct => ({ text: ct.name || '', value: ct.name || '' }))),
            DataTableColumnUtil.Text('County', 'county'),
            dueDt,
            filedDt,
            amt,
            DataTableColumnUtil.BooleanCheckbox('Paid', 'isPaid', 10, FilterType.BooleanRadio),
            DataTableColumnUtil.DropdownMulti('Waived', 'waivedIndicator',
                [
                    { text: 'NA', value: '  ' },
                    { text: 'Waived', value: 'Waived' },
                    { text: 'Partial', value: 'Partial' }
                ]),
        ];
    }

    private getVSRTableColumns = (): DataTableColumnProps<VSRLateReportsDTO>[] => {
        const amt = DataTableColumnUtil.Currency<VSRLateReportsDTO>('Amount', 'penaltyAmount');
        amt.sorter = (a: VSRLateReportsDTO, b: VSRLateReportsDTO) => {
            if (a.penaltyAmount && !b.penaltyAmount) {
                return 1;
            }
            if (!a.penaltyAmount && b.penaltyAmount) {
                return -1;
            }
            if ((a.penaltyAmount || 0) > (b.penaltyAmount || 0)) {
                return 1;
            }
            if ((a.penaltyAmount || 0) < (b.penaltyAmount || 0)) {
                return -1;
            }
            return 0;
        };
        const dueDt = DataTableColumnUtil.Date<VSRLateReportsDTO>('Due Date', 'dueDate', null, { defaultSortOrder: 'descend' });
        dueDt.render = DataTableCellRendererUtil.ShortDate;
        const filedDt = DataTableColumnUtil.Date<VSRLateReportsDTO>('Date Filed', 'dateFiled');
        filedDt.render = DataTableCellRendererUtil.ShortDate;
        const contributionDt = DataTableColumnUtil.Date<VSRLateReportsDTO>('Contribution Date', 'contributionDate');
        contributionDt.render = DataTableCellRendererUtil.ShortDate;

        return [
            DataTableColumnUtil.Text('Committee Code', 'committeeCode'),
            DataTableColumnUtil.Text('Committee Name', 'committeeName'),
            DataTableColumnUtil.Text('Payee', 'payee'),
            contributionDt,
            DataTableColumnUtil.Currency('Contribution Amount', 'contributionAmount'),
            dueDt,
            filedDt,
            amt,
            DataTableColumnUtil.BooleanCheckbox('Paid', 'isPaid', 10, FilterType.BooleanRadio),
            DataTableColumnUtil.DropdownMulti('Waived', 'waivedIndicator',
                [
                    { text: 'NA', value: '  ' },
                    { text: 'Waived', value: 'Waived' },
                    { text: 'Partial', value: 'Partial' }
                ]),
        ];
    }

    private getIndependentExpendituresTableColumns = (): DataTableColumnProps<IndependentExpenditureLateReportsDTO>[] => {
        const amt = DataTableColumnUtil.Currency<IndependentExpenditureLateReportsDTO>('Penalty Amount', 'penaltyAmount');
        const dueDt = DataTableColumnUtil.Date<IndependentExpenditureLateReportsDTO>('Due Date', 'dueDate', null, { defaultSortOrder: 'descend' });
        dueDt.render = DataTableCellRendererUtil.ShortDate;
        const filedDt = DataTableColumnUtil.Date<IndependentExpenditureLateReportsDTO>('Date Filed', 'dateFiled');
        filedDt.render = DataTableCellRendererUtil.ShortDate;
        amt.sorter = (a: IndependentExpenditureLateReportsDTO, b: IndependentExpenditureLateReportsDTO) => {
            if (a.penaltyAmount && !b.penaltyAmount) {
                return 1;
            }
            if (!a.penaltyAmount && b.penaltyAmount) {
                return -1;
            }
            if ((a.penaltyAmount || 0) > (b.penaltyAmount || 0)) {
                return 1;
            }
            if ((a.penaltyAmount || 0) < (b.penaltyAmount || 0)) {
                return -1;
            }
            return 0;
        };

        return [
            DataTableColumnUtil.Text('Name', 'name'),
            DataTableColumnUtil.Text('Committee Name', 'committeeName'),
            DataTableColumnUtil.Date('Expenditure Date', 'expenditureDate'),
            DataTableColumnUtil.Currency('Expenditure Amount', 'amount'),
            dueDt,
            filedDt,
            amt,
            DataTableColumnUtil.BooleanCheckbox('Paid', 'isPaid', 10, FilterType.BooleanRadio),
            DataTableColumnUtil.DropdownMulti('Waived', 'waivedIndicator',
                [
                    { text: 'NA', value: '  ' },
                    { text: 'Waived', value: 'Waived' },
                    { text: 'Partial', value: 'Partial' }
                ]),
        ];
    }

    private getOTCTableColumns = (): DataTableColumnProps<OTCLateReportsDTO>[] => {
        const amt = DataTableColumnUtil.Currency<OTCLateReportsDTO>('Amount', 'penaltyAmount');
        amt.sorter = (a: OTCLateReportsDTO, b: OTCLateReportsDTO) => {
            if (a.penaltyAmount && !b.penaltyAmount) {
                return 1;
            }
            if (!a.penaltyAmount && b.penaltyAmount) {
                return -1;
            }
            if ((a.penaltyAmount || 0) > (b.penaltyAmount || 0)) {
                return 1;
            }
            if ((a.penaltyAmount || 0) < (b.penaltyAmount || 0)) {
                return -1;
            }
            return 0;
        };

        return [
            DataTableColumnUtil.Text('Name', 'name'),
            DataTableColumnUtil.Date('Due Date', 'dueDate', null, { defaultSortOrder: 'descend' }),
            DataTableColumnUtil.Date('Date Filed', 'dateFiled'),
            amt,
            DataTableColumnUtil.BooleanCheckbox('Paid', 'isPaid', 10, FilterType.BooleanRadio),
            DataTableColumnUtil.DropdownMulti('Waived', 'waivedIndicator',
                [
                    { text: 'NA', value: '  ' },
                    { text: 'Waived', value: 'Waived' },
                    { text: 'Partial', value: 'Partial' }
                ]),
        ];
    }

    private getPFDTableColumns = (): DataTableColumnProps<PFDLateReportsDTO>[] => {
        const amt = DataTableColumnUtil.Currency<PFDLateReportsDTO>('Amount', 'penaltyAmount');
        amt.sorter = (a: PFDLateReportsDTO, b: PFDLateReportsDTO) => {
            if (a.penaltyAmount && !b.penaltyAmount) {
                return 1;
            }
            if (!a.penaltyAmount && b.penaltyAmount) {
                return -1;
            }
            if ((a.penaltyAmount || 0) > (b.penaltyAmount || 0)) {
                return 1;
            }
            if ((a.penaltyAmount || 0) < (b.penaltyAmount || 0)) {
                return -1;
            }
            return 0;
        };

        return [
            DataTableColumnUtil.Text('Name', 'name'),
            DataTableColumnUtil.Date('Due Date', 'dueDate', null, { defaultSortOrder: 'descend' }),
            DataTableColumnUtil.Date('Date Filed', 'dateFiled'),
            amt,
            DataTableColumnUtil.BooleanCheckbox('Paid', 'isPaid', 10, FilterType.BooleanRadio),
            DataTableColumnUtil.DropdownMulti('Waived', 'waivedIndicator',
                [
                    { text: 'NA', value: '  ' },
                    { text: 'Waived', value: 'Waived' },
                    { text: 'Partial', value: 'Partial' }
                ]),
        ];
    }

    private getInitialValues() {
        const committeeTypePromise = LookupsApiService.getCommitteeTypes();

        Promise.all([committeeTypePromise])
            .then(results => {
                const [committeeTypes] = results;
                this.setState({ committeeTypes });
            });
    }

    private fetchVSRs = (
        requestState: TableRequestDTO,
        checkEcho: () => boolean,
        callback: (response: TableResponseDTO<VSRLateReportsDTO>) => void
    ) => {
        this.setState({ loading: true });
        return PublicReportsApiService.getVSRLateReports(requestState, false)
            .then((res) => {
                if (!checkEcho()) {
                    return;
                }
                callback(res);
                this.setState({ loading: false });
            })
            .catch((error: any) => {
                this.setState({ loading: false });
                notification.error({
                    message: error.message,
                    description: error.description,
                });
            });
    };

    private fetchLateCommitteeFilings = (
        requestState: TableRequestDTO,
        checkEcho: () => boolean,
        callback: (response: TableResponseDTO<CommitteeLateReportsDTO>) => void
    ) => {
        this.setState({ loading: true });
        return PublicReportsApiService.getCommitteeLateReports(requestState, true)
            .then((res) => {
                if (!checkEcho()) {
                    return;
                }
                callback(res);
                this.setState({ loading: false });
            })
            .catch((error: any) => {
                this.setState({ loading: false });
                notification.error({
                    message: error.message,
                    description: error.description,
                });
            });
    };

    private fetchLateExpenditures = (
        requestState: TableRequestDTO,
        checkEcho: () => boolean,
        callback: (response: TableResponseDTO<IndependentExpenditureLateReportsDTO>) => void
    ) => {
        this.setState({ loading: true });
        return PublicReportsApiService.getIndependentExpenditureLateReports(requestState, false)
            .then((res) => {
                if (!checkEcho()) {
                    return;
                }
                callback(res);
                this.setState({ loading: false });
            })
            .catch((error: any) => {
                this.setState({ loading: false });
                notification.error({
                    message: error.message,
                    description: error.description,
                });
            });
    };

    private fetchLatePFDs = (
        requestState: TableRequestDTO,
        checkEcho: () => boolean,
        callback: (response: TableResponseDTO<PFDLateReportsDTO>) => void
    ) => {
        this.setState({ loading: true });
        return PublicReportsApiService.getPFDLateReports(requestState, false)
            .then((res) => {
                if (!checkEcho()) {
                    return;
                }
                callback(res);
                this.setState({ loading: false });
            })
            .catch((error: any) => {
                this.setState({ loading: false });
                notification.error({
                    message: error.message,
                    description: error.description,
                });
            });
    };

    private fetchLateOTCs = (
        requestState: TableRequestDTO,
        checkEcho: () => boolean,
        callback: (response: TableResponseDTO<OTCLateReportsDTO>) => void
    ) => {
        this.setState({ loading: true });
        return PublicReportsApiService.getOTCLateReports(requestState, false)
            .then((res) => {
                if (!checkEcho()) {
                    return;
                }
                callback(res);
                this.setState({ loading: false });
            })
            .catch((error: any) => {
                this.setState({ loading: false });
                notification.error({
                    message: error.message,
                    description: error.description,
                });
            });
    };

    private fetchReportFilters = () => {
        if (this.props.match.params.committeeId) {
            PublicReportsApiService.getCommitteelateReportFilterInfo(this.props.match.params.committeeId)
                .then((lateReportFilterInfo) => {
                    const currFilters = this.state.lateReportTypeId == LateReportTypes.CommitteeFiling ?
                        [...this._committeeDataTable?.state.filters || []]
                        : [...this._vsrDataTable?.state.filters || []];

                    const columnState = this.state.lateReportTypeId == LateReportTypes.CommitteeFiling ?
                        [...this._committeeDataTable?.state.columnStates || []]
                        : [...this._vsrDataTable?.state.columnStates || []];

                    if (this.state.committeeId) {
                        currFilters.push(TableRequestFilterDTO.create({
                            columnProp: 'committeeCode', filter: lateReportFilterInfo.committeeCode
                        }));
                        columnState.forEach((cs) => {
                            if (cs.columnProp == 'committeeCode') {
                                cs.searchText = lateReportFilterInfo.committeeCode;
                                cs.filtered = true;
                            }
                        });
                        currFilters.push(TableRequestFilterDTO.create({
                            columnProp: 'committeeName', filter: lateReportFilterInfo.committeeName
                        }));
                        columnState.forEach((cs) => {
                            if (cs.columnProp == 'committeeName') {
                                cs.searchText = lateReportFilterInfo.committeeName;
                                cs.filtered = true;
                            }
                        });
                    }

                    if (this.state.lateReportTypeId == LateReportTypes.CommitteeFiling) {
                        this._committeeDataTable?.setState({
                            filters: currFilters,
                            columnStates: columnState
                        }, () => this._committeeDataTable?.refresh());
                    }
                    else {
                        this._vsrDataTable?.setState({
                            filters: currFilters,
                            columnStates: columnState
                        }, () => this._vsrDataTable?.refresh());
                    }
                });
         
        }
    }
}

export default CampaignLateReports;