import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import Routes from '../../consts/Routes';
import { RedirectAs404 } from '../../utils/RouteErrors';
import Dr2Summary from './Dr2Summary';
import FilingPeriodHistory from './FilingPeriodHistory';
import SchAContributions from './SchAContributions';
import SchAContributionsForm from './SchAContributionsForm';
import SchAContributionsImport from './SchAContributionsImport';
import SchBExpenditures from './SchBExpenditures';
import SchBExpendituresForm from './SchBExpendituresForm';
import SchBExpendituresImport from './SchBExpendituresImport';
import SchDDebts from './SchDDebts';
import SchDDebtsForm from './SchDDebtsForm';
import SchEInKindContributions from './SchEInKindContributions';
import SchEInKindContributionsForm from './SchEInKindContributionsForm';
import SchF1LoansReceived from './SchF1LoansReceived';
import SchF1LoansReceivedForm from './SchF1LoansReceivedForm';
import SchF2LoansRepaid from './SchF2LoanRepaid';
import SchF2LoansRepaidForm from './SchF2LoanRepaidForm';
import SchGConsultants from './SchGConsultants';
import SchGConsultantsForm from './SchGConsultantsForm';
import SchGConsultantExpenses from './SchGConsultantExpenses';
import SchGConsultantExpensesForm from './SchGConsultantExpensesForm';
import SchH1CampaignProperty from './SchH1CampaignProperty';
import SchH1CampaignPropertyForm from './SchH1CampaignPropertyForm';
import SchH2PropertySales from './SchH2PropertySales';
import SchH2PropertySalesForm from './SchH2PropertySalesForm';
import Dr2Penalty from './Dr2Penalty';
import Dr2Submit from './Dr2Submit';
import Dr2SubmitCompletion from './Dr2SubmitCompletion';
import AdjustEntirePeriod from './AdjustEntirePeriod';
import AdjustPeriodDates from './AdjustPeriodDates';
import Dr2SubmitStepper from './Dr2SubmitStepper';
import DeleteFiling from './DeleteFiling';


const Schedules = () => (
  <Switch>
    <Route exact={true} path={Routes.SCHEDULE_SUMMARY} component={Dr2Summary} />
    <Route exact={true} path={Routes.SCHEDULE_A_CONTRIBUTIONS} component={SchAContributions} />
    <Route exact={true} path={Routes.ADD_SCHEDULE_A_CONTRIBUTION} component={SchAContributionsForm} />
    <Route exact={true} path={Routes.EDIT_SCHEDULE_A_CONTRIBUTION} component={SchAContributionsForm} />
    <Route exact={true} path={Routes.IMPORT_SCHEDULE_A_CONTRIBUTIONS} component={SchAContributionsImport} />
    <Route exact={true} path={Routes.SCHEDULE_B_EXPENDITURES} component={SchBExpenditures} />
    <Route exact={true} path={Routes.ADD_SCHEDULE_B_EXPENDITURE} component={SchBExpendituresForm} />
    <Route exact={true} path={Routes.ADD_SCHEDULE_B_EXPENDITURE_FROM_DEBT} component={SchBExpendituresForm} />
    <Route exact={true} path={Routes.EDIT_SCHEDULE_B_EXPENDITURE} component={SchBExpendituresForm} />
    <Route exact={true} path={Routes.IMPORT_SCHEDULE_B_EXPENDITURES} component={SchBExpendituresImport} />
    <Route exact={true} path={Routes.SCHEDULE_D_DEBTS} component={SchDDebts} />
    <Route exact={true} path={Routes.ADD_SCHEDULE_D_DEBT} component={SchDDebtsForm} />
    <Route exact={true} path={Routes.EDIT_SCHEDULE_D_DEBT} component={SchDDebtsForm} />
    <Route exact={true} path={Routes.SCHEDULE_E_IN_KIND_CONTRIBUTIONS} component={SchEInKindContributions} />
    <Route exact={true} path={Routes.ADD_SCHEDULE_E_IN_KIND_CONTRIBUTION} component={SchEInKindContributionsForm} />
    <Route exact={true} path={Routes.ADD_SCHEDULE_E_IN_KIND_CONTRIBUTION_FROM_DEBT} component={SchEInKindContributionsForm} />
    <Route exact={true} path={Routes.EDIT_SCHEDULE_E_IN_KIND_CONTRIBUTION} component={SchEInKindContributionsForm} />
    <Route exact={true} path={Routes.SCHEDULE_F1_LOANS_RECEIVED} component={SchF1LoansReceived} />
    <Route exact={true} path={Routes.ADD_SCHEDULE_F1_LOAN_RECEIVED} component={SchF1LoansReceivedForm} />
    <Route exact={true} path={Routes.EDIT_SCHEDULE_F1_LOAN_RECEIVED} component={SchF1LoansReceivedForm} />
    <Route exact={true} path={Routes.SCHEDULE_F2_LOANS_REPAID} component={SchF2LoansRepaid} />
    <Route exact={true} path={Routes.ADD_SCHEDULE_F2_LOAN_REPAID} component={SchF2LoansRepaidForm} />
    <Route exact={true} path={Routes.EDIT_SCHEDULE_F2_LOAN_REPAID} component={SchF2LoansRepaidForm} />
    <Route exact={true} path={Routes.SCHEDULE_G_CONSULTANTS} component={SchGConsultants} />
    <Route exact={true} path={Routes.ADD_SCHEDULE_G_CONSULTANT} component={SchGConsultantsForm} />
    <Route exact={true} path={Routes.EDIT_SCHEDULE_G_CONSULTANT} component={SchGConsultantsForm} />
    <Route exact={true} path={Routes.SCHEDULE_G_CONSULTANT_EXPENSES} component={SchGConsultantExpenses} />
    <Route exact={true} path={Routes.ADD_SCHEDULE_G_CONSULTANT_EXPENSE} component={SchGConsultantExpensesForm} />
    <Route exact={true} path={Routes.EDIT_SCHEDULE_G_CONSULTANT_EXPENSE} component={SchGConsultantExpensesForm} />
    <Route exact={true} path={Routes.SCHEDULE_H1_CAMPAIGN_PROPERTIES} component={SchH1CampaignProperty} />
    <Route exact={true} path={Routes.ADD_SCHEDULE_H1_CAMPAIGN_PROPERTY} component={SchH1CampaignPropertyForm} />
    <Route exact={true} path={Routes.EDIT_SCHEDULE_H1_CAMPAIGN_PROPERTY} component={SchH1CampaignPropertyForm} />
    <Route exact={true} path={Routes.SCHEDULE_H2_PROPERTY_SALES} component={SchH2PropertySales} />
    <Route exact={true} path={Routes.ADD_SCHEDULE_H2_PROPERTY_SALE} component={SchH2PropertySalesForm} />
    <Route exact={true} path={Routes.EDIT_SCHEDULE_H2_PROPERTY_SALE} component={SchH2PropertySalesForm} />
    <Route exact={true} path={Routes.FILING_PERIOD_HISTORY} component={FilingPeriodHistory} />
    <Route exact={true} path={Routes.DR2_PENALTY} component={Dr2Penalty} />
    <Route exact={true} path={Routes.DR2_SUBMIT} component={Dr2Submit} />
    <Route exact={true} path={Routes.DR2_SUBMIT_COMPLETION} component={Dr2SubmitCompletion} />
    <Route exact={true} path={Routes.ADJUST_ENTIRE_PERIOD} component={AdjustEntirePeriod} />
    <Route exact={true} path={Routes.ADJUST_PERIOD_DATES} component={AdjustPeriodDates} />
    <Route exact={true} path={Routes.DR2_SUBMIT_STEPPER} component={Dr2SubmitStepper} />
    <Route exact={true} path={Routes.DELETE_FILING} component={DeleteFiling} />
    {/* This needs to be the last item */}
    <Route component={RedirectAs404} />
  </Switch>
);

export default Schedules;