import { Layout, Modal, notification} from 'antd';
import { Button } from 'antd/lib/radio';
import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import Routes from '../../consts/Routes';
import FilingPeriodDTO from '../../models/FilingPeriodDTO';
import HistoryUtil from '../../utils/HistoryUtil';
import NewFilingPeriodTable from '../schedule/NewFilingPeriodTable';
import AdminDr3ApiService from '../../api/AdminDr3ApiService';
import Dr2ApiService from '../../api/Dr2ApiService';
import CurrentUser from '../../utils/CurrentUser';

const { Content } = Layout;
const { confirm } = Modal;

interface RejectDR3Props {
}

interface RejectDR3State {
  committeeFilingId: number;
  committeeId: string;
  filerTypeCode: number;
  currDr2Id: string;
}

class RejectDr3 extends React.Component<RouteComponentProps<RejectDR3Props>, RejectDR3State>{
  private _newFilingPeriodTable: NewFilingPeriodTable | null = null;

  constructor(props: RouteComponentProps<RejectDR3Props>) {
    super(props);
    this.state = {
      committeeFilingId: (this.props.location?.state as RejectDR3State).committeeFilingId,
      committeeId: (this.props.location?.state as RejectDR3State).committeeId,
      filerTypeCode: (this.props.location?.state as RejectDR3State).filerTypeCode,
      currDr2Id: CurrentUser.Get()?.dr2Id || '',
    };
  }

  render() {
    const { committeeId, currDr2Id } = this.state;
    return (
      <Content className="content-pad">
        <h3 className="ant-typography">Reject DR3</h3>
        <NewFilingPeriodTable
        ref={(element) => this._newFilingPeriodTable = element}
        selectFiling={true}
        dr2Id={currDr2Id}
        committeeId={committeeId}
        onChange={this.confirmReject}
        CanSelectFiling={true} />
        <Button onClick={() => { HistoryUtil.push(Routes.CERTIFY_DR3);}}>Cancel</Button>
      </Content>
      );
  }

  private confirmReject = (filingPeriod: FilingPeriodDTO) => {
    confirm({
      title: 'Are you sure you want to reject this DR3?',
      content: filingPeriod.description + ' ' + ' filing period will be created and committee will need to dissolve again.',
      onOk: () => { this.rejectDr3(filingPeriod);},
    });
  }

  private rejectDr3 = (filingPeriod: FilingPeriodDTO) => {
    AdminDr3ApiService.rejectDr3(this.state.committeeId, this.state.committeeFilingId, this.state.filerTypeCode).
      then((result) => {
        if (result.succeeded) {
          Dr2ApiService.saveNewFilingPeriod(filingPeriod.periodMasterId, this.state.committeeId, this.state.currDr2Id || '')
            .then((result) => {
              if (result.succeeded) {
                notification.success({ message: 'DR3 was rejected and committee filing was created sucessfully.' });
                HistoryUtil.push(Routes.generate(Routes.CERTIFY_DR3));
              }
              else {
                notification.error({ message: result.errors[0] });
              }
            }).catch(() => {
              notification.error({
                message: 'Error while creating committee filing'
              });
            });   
          }
      }).catch(() => {
        notification.error({ message: 'Failed to reject DR3.' });
      });
  }
}

export default RejectDr3;