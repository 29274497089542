// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';

interface PeriodDueDateDTO { 
  beginDueDate: moment.Moment | string | null;
  endDueDate: moment.Moment | string | null;
  filingDueDate: moment.Moment | string | null;
  adjustedDueDate: moment.Moment | string | null;
  filedDate: moment.Moment | string | null;
  amendedDate: moment.Moment | string | null;
  updatedOnDate: moment.Moment | string | null;
}
const PeriodDueDateDTO = {
  create: (initValues?: Partial<PeriodDueDateDTO> | Record<string, unknown> | null) => {
    return Object.assign(
    {
      beginDueDate: null,
      endDueDate: null,
      filingDueDate: null,
      adjustedDueDate: null,
      filedDate: null,
      amendedDate: null,
      updatedOnDate: null,
    },
    initValues);
  }
};

export default PeriodDueDateDTO;