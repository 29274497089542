import UserSecurity from '../models/UserSecurity';
import { StateStoreModel } from './state/StateStoreModel';

const sessionKey = 'iecdb_global';

export const loadState = () => {
  try {
    const serializedState = sessionStorage.getItem(sessionKey);

    if (serializedState === null) {
      localStorage.setItem('REQUESTING_STORE', Date.now().toString());
      localStorage.removeItem('REQUESTING_STORE');
    }

    if (serializedState === null) {
      return undefined;
    }

    const parsed = JSON.parse(serializedState) as StateStoreModel;

    if (parsed && parsed.UserSession && parsed.UserSession.Value) {
      parsed.UserSession.Value = new UserSecurity(parsed.UserSession.Value as any);
    }

    return parsed;
  } catch (err) {
    return undefined;
  }
};

export const saveState = (state: StateStoreModel) => {
  try {
    // Choose what to save here, or just save the entire state by passing it into the JSON.stringify();
    const stateToSave = {
      localize: state.localize,
      UserSession: state.UserSession
    };

    const serializedState = JSON.stringify(stateToSave);
    sessionStorage.setItem(sessionKey, serializedState);
  } catch (err) {
    // Ignore write errors
  }
};

export const shareState = (window: any, props: any, event: any) => {
  const credentials = JSON.parse(window.sessionStorage.getItem(sessionKey));
  if (event.key === 'REQUESTING_STORE' && credentials) {
    window.localStorage.setItem('STORE_SHARING', window.sessionStorage.getItem(sessionKey));
    window.localStorage.removeItem('STORE_SHARING');
  }

  if (event.key === 'STORE_SHARING' && !credentials) {
    window.sessionStorage.setItem(sessionKey, event.newValue);
  }

  if (event.key === 'STORE_FLUSH' && credentials) {
    window.sessionStorage.removeItem(sessionKey);
    window.localStorage.removeItem(sessionKey);
    props.ClearStore();
  }

  if (event.key === 'LANGUAGE_CHANGE' && credentials) {
    window.sessionStorage.removeItem('CREDENTIALS_TOKEN');
  }
};
