import { getTranslate, TranslateOptions, TranslatePlaceholderData } from 'react-localize-redux';
import { store } from '../redux/Store';

export default class TranslationUtil {
  public static translate(
      id: string,
      data?: TranslatePlaceholderData | undefined,
      options?: TranslateOptions | undefined
  ): string | any {
    const translate = getTranslate(store.getState().localize);
    return translate(id, data, options);
  }
}
