// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
import BaseApi from './BaseApi';
import BuildingFundFilingDTO from '../models/BuildingFundFilingDTO';
import TableRequestDTO from '../models/TableRequestDTO';
import PayeeFormDTO from '../models/PayeeFormDTO';
import ResultDTO from '../models/ResultDTO';
import TableResponseDTO from '../models/TableResponseDTO';
import BuildingFundContributionListDTO from '../models/BuildingFundContributionListDTO';
import BuildingFundExpenditureListDTO from '../models/BuildingFundExpenditureListDTO';

export class BuildingFundApiService extends BaseApi {

  // post: api/buildingFund/getBuildingFundSumaryInfo?committeeId=${encodeURIComponent(committeeId)}&year=${encodeURIComponent(year)}
  public getBuildingFundSummaryInfo(committeeId: string, year: string): Promise<BuildingFundFilingDTO> {
    let url = `api/buildingFund/getBuildingFundSumaryInfo`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (committeeId != null) {
      url += `${prefix}committeeId=${encodeURIComponent(committeeId)}`;
      prefix = '&';
    }
    if (year != null) {
      url += `${prefix}year=${encodeURIComponent(year)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, BuildingFundFilingDTO>(null, 'post', url, true, false);
  }

  // post: api/buildingFund/saveBuildingFund
  public saveBuildingFund(buildingFund: BuildingFundFilingDTO): Promise<ResultDTO> {
    let url = `api/buildingFund/saveBuildingFund`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, ResultDTO>(buildingFund, 'post', url, true, false);
  }

  // post: api/buildingFund/getPrevFilingBalance?committeeId=${encodeURIComponent(committeeId)}&year=${encodeURIComponent(year)}
  public getPrevFilingBalance(committeeId: string, year: string): Promise<number> {
    let url = `api/buildingFund/getPrevFilingBalance`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (committeeId != null) {
      url += `${prefix}committeeId=${encodeURIComponent(committeeId)}`;
      prefix = '&';
    }
    if (year != null) {
      url += `${prefix}year=${encodeURIComponent(year)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, number>(null, 'post', url, true, false);
  }

  // post: api/buildingFund/getBuildingFundContributionList?buildingFundId=${buildingFundId}
  public getBuildingFundContributionList(tableRequest: TableRequestDTO, buildingFundId: number): Promise<TableResponseDTO<BuildingFundContributionListDTO>> {
    let url = `api/buildingFund/getBuildingFundContributionList`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (buildingFundId != null) {
      url += `${prefix}buildingFundId=${buildingFundId}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, TableResponseDTO<BuildingFundContributionListDTO>>(tableRequest, 'post', url, true, false);
  }

  // post: api/buildingFund/getBuildingFundContribution?contributionId=${contributionId}
  public getBuildingFundContribution(contributionId: number): Promise<PayeeFormDTO> {
    let url = `api/buildingFund/getBuildingFundContribution`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (contributionId != null) {
      url += `${prefix}contributionId=${contributionId}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, PayeeFormDTO>(null, 'post', url, true, false);
  }

  // post: api/buildingFund/saveBuildingFundContribution?committeeId=${encodeURIComponent(committeeId)}&buildingFundId=${buildingFundId}
  public saveBuildingFundContribution(payeeForm: PayeeFormDTO, committeeId: string, buildingFundId: number): Promise<ResultDTO> {
    let url = `api/buildingFund/saveBuildingFundContribution`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (committeeId != null) {
      url += `${prefix}committeeId=${encodeURIComponent(committeeId)}`;
      prefix = '&';
    }
    if (buildingFundId != null) {
      url += `${prefix}buildingFundId=${buildingFundId}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, ResultDTO>(payeeForm, 'post', url, true, false);
  }

  // post: api/buildingFund/deleteBuildingFundContribution?contributionId=${contributionId}
  public deleteBuildingFundContribution(contributionId: number): Promise<ResultDTO> {
    let url = `api/buildingFund/deleteBuildingFundContribution`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (contributionId != null) {
      url += `${prefix}contributionId=${contributionId}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, ResultDTO>(null, 'post', url, true, false);
  }

  // post: api/buildingFund/adjustBuildingFundStartingBalance?newBeginBal=${newBeginBal}&buildingFundId=${buildingFundId}
  public adjustBuildingFundStartingBalance(newBeginBal: number, buildingFundId: number): Promise<ResultDTO> {
    let url = `api/buildingFund/adjustBuildingFundStartingBalance`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (newBeginBal != null) {
      url += `${prefix}newBeginBal=${newBeginBal}`;
      prefix = '&';
    }
    if (buildingFundId != null) {
      url += `${prefix}buildingFundId=${buildingFundId}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, ResultDTO>(null, 'post', url, true, false);
  }

  // post: api/buildingFund/auditBuildingFund?buildingFundId=${buildingFundId}
  public auditBuildingFund(buildingFundId: number): Promise<ResultDTO> {
    let url = `api/buildingFund/auditBuildingFund`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (buildingFundId != null) {
      url += `${prefix}buildingFundId=${buildingFundId}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, ResultDTO>(null, 'post', url, true, false);
  }

  // post: api/buildingFund/getBuildingFundStartingBalance?buildingFundId=${buildingFundId}
  public getBuildingFundStartingBalance(buildingFundId: number): Promise<number> {
    let url = `api/buildingFund/getBuildingFundStartingBalance`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (buildingFundId != null) {
      url += `${prefix}buildingFundId=${buildingFundId}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, number>(null, 'post', url, true, false);
  }

  // post: api/buildingFund/fileBuildingFund?buildingFundId=${buildingFundId}
  public fileBuildingFund(buildingFundId: number): Promise<ResultDTO> {
    let url = `api/buildingFund/fileBuildingFund`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (buildingFundId != null) {
      url += `${prefix}buildingFundId=${buildingFundId}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, ResultDTO>(null, 'post', url, true, false);
  }

  // post: api/buildingFund/deleteBuildingFund?buildingFundId=${buildingFundId}
  public deleteBuildingFund(buildingFundId: number): Promise<ResultDTO> {
    let url = `api/buildingFund/deleteBuildingFund`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (buildingFundId != null) {
      url += `${prefix}buildingFundId=${buildingFundId}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, ResultDTO>(null, 'post', url, true, false);
  }

  // post: api/buildingFund/getBuildingFundExpenditureList?buildingFundId=${buildingFundId}
  public getBuildingFundExpenditureList(tableRequest: TableRequestDTO, buildingFundId: number): Promise<TableResponseDTO<BuildingFundExpenditureListDTO>> {
    let url = `api/buildingFund/getBuildingFundExpenditureList`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (buildingFundId != null) {
      url += `${prefix}buildingFundId=${buildingFundId}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, TableResponseDTO<BuildingFundExpenditureListDTO>>(tableRequest, 'post', url, true, false);
  }

  // post: api/buildingFund/getBuildingFundExpenditure?expenditureId=${expenditureId}
  public getBuildingFundExpenditure(expenditureId: number): Promise<PayeeFormDTO> {
    let url = `api/buildingFund/getBuildingFundExpenditure`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (expenditureId != null) {
      url += `${prefix}expenditureId=${expenditureId}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, PayeeFormDTO>(null, 'post', url, true, false);
  }

  // post: api/buildingFund/saveBuildingFundExpenditure?committeeId=${encodeURIComponent(committeeId)}&buildingFundId=${buildingFundId}
  public saveBuildingFundExpenditure(payeeForm: PayeeFormDTO, committeeId: string, buildingFundId: number): Promise<ResultDTO> {
    let url = `api/buildingFund/saveBuildingFundExpenditure`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (committeeId != null) {
      url += `${prefix}committeeId=${encodeURIComponent(committeeId)}`;
      prefix = '&';
    }
    if (buildingFundId != null) {
      url += `${prefix}buildingFundId=${buildingFundId}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, ResultDTO>(payeeForm, 'post', url, true, false);
  }

  // post: api/buildingFund/deleteBuildingFundExpenditure?expenditureId=${expenditureId}
  public deleteBuildingFundExpenditure(expenditureId: number): Promise<ResultDTO> {
    let url = `api/buildingFund/deleteBuildingFundExpenditure`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (expenditureId != null) {
      url += `${prefix}expenditureId=${expenditureId}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, ResultDTO>(null, 'post', url, true, false);
  }

  // get: api/buildingFund/printBuildingFundReport?buildingFundId=${buildingFundId}&commName=${encodeURIComponent(commName)}&year=${encodeURIComponent(year)}
  public printBuildingFundReport(buildingFundId: number, commName: string, year: string): Promise<void> {
    let url = `api/buildingFund/printBuildingFundReport`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (buildingFundId != null) {
      url += `${prefix}buildingFundId=${buildingFundId}`;
      prefix = '&';
    }
    if (commName != null) {
      url += `${prefix}commName=${encodeURIComponent(commName)}`;
      prefix = '&';
    }
    if (year != null) {
      url += `${prefix}year=${encodeURIComponent(year)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, void>(null, 'get', url, true, true);
  }

  // post: api/buildingFund/getBuildingFundFilings?committeeId=${encodeURIComponent(committeeId)}
  public getBuildingFundFilings(tableRequest: TableRequestDTO, committeeId: string): Promise<TableResponseDTO<BuildingFundFilingDTO>> {
    let url = `api/buildingFund/getBuildingFundFilings`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (committeeId != null) {
      url += `${prefix}committeeId=${encodeURIComponent(committeeId)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, TableResponseDTO<BuildingFundFilingDTO>>(tableRequest, 'post', url, true, false);
  }
}
var service = new BuildingFundApiService();
export default service;
