import { FormInstance, Row, Col, Tooltip, Typography, Input, notification } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import * as React from 'react';
import DesignatedPositionDTO from '../../../../models/DesignatedPositionDTO';
import FormValidationUtil from '../../../../utils/FormValidationUtil';
import AddressFormFields from '../../../shared/AddressFormFields';
import CustomDatePicker from '../../../shared/CustomDatePicker';
import CustomForm from '../../../shared/CustomForm';
import PfdContactSearch from '../../../public/campaigns/Pfd/PfdContactSearch';
import PfdApiService from '../../../../api/PfdApiService';
import moment from 'moment';

const { Title } = Typography;

interface DesignatedPositionProps {
  designatedPosition: DesignatedPositionDTO
}

interface DesignatedPositionState {
  disableFields: boolean;
}

class DesignatedPosition extends React.Component<DesignatedPositionProps, DesignatedPositionState> {
  private readonly _formRef = React.createRef<FormInstance>();

  constructor(props: DesignatedPositionProps) {
    super(props);

    this.state = {
      disableFields: !(this.props.designatedPosition.contactId)
    };
  }

  validate = async (): Promise<{ model: DesignatedPositionDTO, errorFields: any }> => {
    try {
      const fields = await this._formRef.current?.validateFields();
      return { model: fields, errorFields: null };
    }
    catch (errorInfo) {
      return Promise.reject({ model: errorInfo.values, errorFields: errorInfo.errorFields });
    }
  }

  render() {
    const { disableFields } = this.state;
    const { designatedPosition } = this.props;
    return (
      <>
        <Title level={4}>
          Designated Position(s) in the Executive Branch
        </Title>
        <Typography.Text style={{color: 'red'}}>
          If you cannot find your name in the search field below please contact IECDB&apos;s staff at (515) 281-4028 or ethicsboard@iowa.gov.
        </Typography.Text>
        <br /><br />
        <Row>
          <Col xs={24}>
            <CustomForm formRef={this._formRef} initialValues={designatedPosition} layout="vertical">
              <FormItem name="contactId" hidden={true}>
                <Input hidden={true} disabled={true} aria-hidden={true} />
              </FormItem>
              <PfdContactSearch
                name={'person'}
                label={'Contact Search'}
                onSelect={this.getContact}
                onChange={this.handleContactChange}
                initialValue={designatedPosition.contactId}
              />
              <AddressFormFields
                firstName={{ name: 'firstName' }}
                lastName={{ name: 'lastName' }}
                address1={{ name: 'address1', required: false }}
                address2={{ name: 'address2', required: false }}
                city={{ name: 'city', required: false }}
                state={{ name: 'state', required: false }}
                zip={{ name: 'zip', required: false }}
                email={{ name: 'email' }}
                phone={{ name: 'phone' }}
                allDisabled={disableFields}
              />
              <Tooltip
                title="The covered year is the year prior to the year the statement is due. 
                        e.g. A statement due April 30, 2012 is for the 2011 covered year."
              >
                <FormItem name="yearCovered" label="Covered Year" rules={[FormValidationUtil.RequiredDate('Covered Year is required')]}>
                  <CustomDatePicker
                    picker="year"
                    format="YYYY"
                    onChange={this.checkContactHasCoveredYear}
                    disabled={disableFields}
                    disabledDates={(current) => this.checkDisableDate(current)}
                  />
                </FormItem>
              </Tooltip>
            </CustomForm>
          </Col>
        </Row>
      </>
    );
  }

  private checkDisableDate = (current: moment.Moment) => {
    const now = moment();
    const currYear = current.year();
    const nowYear = now.year();

    return (currYear > nowYear || (now.isBefore(moment(`${nowYear.toString()}-05-01`)) && currYear === nowYear));
  }

  private checkContactHasCoveredYear = (selectedYearMoment: moment.Moment | null, dateString: string) => {
    const contactId = this._formRef.current?.getFieldValue('contactId');
    if (!contactId || !dateString) {
      return;
    }

    PfdApiService.checkContactHasCoveredYear(contactId, dateString)
      .then(result => {
        if (result) {
          notification.info({
            message: 'A filing for the selected covered year already exists.',
            description: 'By submitting a new filing it will replace the previously entered filing.',
            duration: 10
          });
        }
      });
  }

  private handleContactChange = () => {
    this._formRef.current?.setFieldsValue({ contactId: '' });
  }

  private getContact = (contactId: string) => {
    if (contactId != '') {
      PfdApiService.getPfdContact(Number(contactId))
        .then(personInfo => {
          if (personInfo) {
            this.setContactFields(personInfo);
            this._formRef.current?.setFieldsValue({ contactId: contactId });
            this.setState({ disableFields: false });
          }
        });
    }
    else {
      this.setContactFields();
      this.setState({ disableFields: true });
    }
  }

  private setContactFields(form?: DesignatedPositionDTO) {
    if (form) {
      this._formRef.current?.setFieldsValue({
        contactId: form.contactId,
        person: form.contactId,
        firstName: form.firstName,
        lastName: form.lastName,
        address1: form.address1,
        address2: form.address2,
        city: form.city,
        state: form.state,
        zip: form.zip,
        email: form.email,
        phone: form.phone
      });
    }
    else {
      this._formRef.current?.setFieldsValue({
        contactId: '',
        person: '',
        firstName: '',
        lastName: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        zip: '',
        email: '',
        phone: '',
        yearCovered: ''
      });
    }
  }

}

export default DesignatedPosition;