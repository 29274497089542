import { LoadingOutlined } from '@ant-design/icons';
import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  Input,
  Layout,
  Row,
  Spin
  } from 'antd';
import { FormInstance } from 'antd/lib/form';
import * as React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import AccountApiService from '../../api/AccountApiService';
import Routes from '../../consts/Routes';
import ResultDTO from '../../models/ResultDTO';
import FormValidationUtil from '../../utils/FormValidationUtil';
import CustomForm from '../shared/CustomForm';
import PageTitle from '../shared/PageTitle';

const FormItem = Form.Item;
const Content = Layout.Content;

interface ResetPasswordState {
  error: any;
  resetSuccess: boolean;
  linkVerified: boolean | null;
  loading: boolean;
}

interface ResetPasswordProps { }

class ResetPasswordPage extends React.Component<
  ResetPasswordProps & RouteComponentProps<RouteObject>,
  ResetPasswordState
> {
  private readonly _formRef = React.createRef<FormInstance>();
  private code: string | null = null;
  private userId: number | null = null;

  constructor(props: ResetPasswordProps & RouteComponentProps<RouteObject>) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      error: null,
      resetSuccess: false,
      linkVerified: null,
      loading: false,
    };
  }

  componentDidMount() {
    const urlParams = new URLSearchParams(location.search);
    this.code = urlParams.get('code');
    const userId = urlParams.get('Id');
    if (userId !== null) {
      this.userId = parseInt(userId);
    }

    if (this.userId !== null && this.code !== null) {
      AccountApiService.canResetPassword(this.userId, this.code).then(
        (result) => {
          const error = !result.succeeded ? result.errors : null;
          this.setState({ loading: false, linkVerified: result.succeeded, error: error });
        },
        (error: any) => {
          this.setState({ loading: false, error: error, linkVerified: false });
        }
      );
    } else {
      this.setState({
        loading: false,
        error: { message: 'Invalid link.' },
        linkVerified: false,
      });
    }
  }

  handleSubmit() {
    if (this.userId === null || this.code === null) {
      this.setState({
        loading: false,
        error: { message: 'Invalid link.' },
        resetSuccess: false,
      });
      return;
    }

    this.setState({ loading: true });

    const model = this._formRef ? (this._formRef.current as any).getFieldsValue() : null;
    model.url = location.href;

    AccountApiService.resetPassword(this.userId, this.code, model).then(
      (result) => {
        const error = !result.succeeded ? result.errors : null;
        this.setState({ loading: false, resetSuccess: result.succeeded, error: error });
      },
      (error) => {
        this.setState({ loading: false, error: error, resetSuccess: false });
      }
    );
  }

  validatePassword = (e: any) => {
    if (e.target.value !== null && e.target.value !== '') {
      AccountApiService.validatePassword(e.target.value).then((response: ResultDTO | null) => {
        if (response?.succeeded) {
          this.setState({ error: null });
        } else {
          this.setState({ error: { message: response?.errors.join('\n') } });
        }
      });
    }
  };

  render() {
    let alert: JSX.Element | null = null;
    const { error, resetSuccess } = this.state;
    if (error !== null) {
      let message = 'Invalid link.';
      if (error.message) {
        message = error.message;
      }
      alert = (
        <Alert
          message="Error"
          description={message}
          type="error"
          showIcon={true}
          style={{ marginBottom: '12px', whiteSpace: 'pre-wrap' }}
        />
      );
    }
    if (resetSuccess) {
      const message = 'Password reset successfully!';
      alert = (
        <Alert
          message="Success"
          description={message}
          type="success"
          showIcon={true}
          style={{ marginBottom: '12px' }}
        />
      );
    }

    const emptyCol = { xs: 0, sm: 3, md: 3 };
    const wrapperCol = { xs: 24, sm: 18, md: 18 };
    const wrapperColNoLabel = {};
    Object.keys(wrapperCol).forEach((val) => {
      wrapperColNoLabel[val] = {
        span: wrapperCol[val],
        offset: wrapperCol[val] + emptyCol[val] > 24 ? 0 : emptyCol[val],
      };
    });

    return (
      // type="flex"
      <Content className="content-background-login">
        {this.state.linkVerified == null ? (
          <Spin tip="Checking Link..." size="large">
            <div style={{ height: 150 }} />
          </Spin>
        ) : (
          <Row justify="space-between">
            <Col className="flex-grow" />
            <Col xxl={6} xl={8} lg={10} md={14} sm={18} xs={24}>
              <Card>
                <img src={process.env.PUBLIC_URL + '/iecdb-logo.svg'} alt="IECDB.Portal" className="full-logo" />
                <PageTitle title="Reset Password" style={{ textAlign: 'center' }} />
                {alert}
                {this.state.resetSuccess ? (
                  <Row justify="center">
                    <Button htmlType="button" size="large" disabled={this.state.loading} shape="round">
                      <Link to={Routes.LOGIN}>Go to Login</Link>
                    </Button>
                  </Row>
                ) : this.state.linkVerified ? (
                  <React.Fragment>
                    <p style={{ textAlign: 'center' }}>Please enter new password and confirm.</p>
                    <CustomForm
                      formRef={this._formRef}
                      validateTrigger={['onChange', 'onBlur']}
                      layout="vertical"
                      onFinish={this.handleSubmit}
                      requiredMark={false}>
                      <Form.Item
                        name="password"
                        label="Password"
                        rules={[
                          FormValidationUtil.Required('Password is required'),
                          { ...FormValidationUtil.ValidatePassword() }
                        ]}
                        labelCol={wrapperColNoLabel}
                        wrapperCol={wrapperColNoLabel}
                      >
                        <Input.Password />
                      </Form.Item>
                      <Form.Item
                        name="confirmPassword"
                        label="Confirm Password"
                        dependencies={['password']}
                        rules={[
                          FormValidationUtil.Required('Confirm password is required'),
                          FormValidationUtil.CheckConfirmPassword('password')
                        ]}
                        labelCol={wrapperColNoLabel}
                        wrapperCol={wrapperColNoLabel}
                      >
                        <Input.Password />
                      </Form.Item>
                      <FormItem wrapperCol={wrapperColNoLabel}>
                        <Button
                          type="primary"
                          htmlType="submit"
                          size="large"
                          disabled={this.state.loading}
                          shape="round"
                          block
                        >
                          {this.state.loading ? (
                            <span>
                              <LoadingOutlined /> Processing...
                            </span>
                          ) : (
                            <span>Reset Password</span>
                          )}
                        </Button>
                      </FormItem>
                    </CustomForm>
                  </React.Fragment>
                ) : (
                  <Row justify="center">
                    <Button htmlType="button" size="large" disabled={this.state.loading} shape="round">
                      <Link to={Routes.REQUEST_RESET_PASSWORD}>Request Password Reset</Link>
                    </Button>
                  </Row>
                )}
              </Card>
            </Col>
            <Col className="flex-grow" />
          </Row>
        )}
      </Content>
    );
  }
}

export default withRouter(ResetPasswordPage);
