import { Button, FormInstance, notification, Select, Row, Col, ColProps, Space } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import * as React from 'react';
import DataTable, { DataTableColumnProps, FilterType } from '../../shared/DataTable/DataTable';
import DataTableColumnUtil from '../../shared/DataTable/DataTableColumnUtil';
import AdminReportApiService from '../../../api/AdminReportApiService';
import DataTableButtonUtil from '../../shared/DataTable/DataTableButtonUtil';
import FormValidationUtil from '../../../utils/FormValidationUtil';
import PfdFilingReportDTO from '../../../models/PfdFilingReportDTO';
import CustomForm from '../../shared/CustomForm';
import * as StorageUtil from '../../../storage/StorageUtil';
import TableResponseDTO from '../../../models/TableResponseDTO';
import TableRequestDTO from '../../../models/TableRequestDTO';
import ReportTableExportDTO from '../../../models/ReportTableExportDTO';

const Option = Select.Option;

interface PfdFilingReportProps {
  yearOptions: number[];
}

interface PfdFilingReportState {
  pfdFilingTableColumns: DataTableColumnProps<PfdFilingReportDTO>[];
  pfdFilingData: PfdFilingReportDTO[];
  pfdForm: any;
  pfdFilingLoading: boolean;
  pfdFilingExporting: boolean;
}

class PfdFilingReport extends React.Component<PfdFilingReportProps, PfdFilingReportState> {
  private readonly _PfdFilingFormRef = React.createRef<FormInstance>();
  constructor(props: PfdFilingReportProps) {
    super(props);

    this.state = {
      pfdFilingTableColumns: this.getPfdFilingTableColumns(),
      pfdFilingData: [],
      pfdForm: {
          year: StorageUtil.getPFDFilingReportColumnFilters() != '{}'
          ? { ...JSON.parse(StorageUtil.getPFDFilingReportColumnFilters()) }.year : new Date().getFullYear()
      },
      pfdFilingLoading: false,
      pfdFilingExporting: false,
    };
  }
  private PfdFilingDataTable: DataTable<PfdFilingReportDTO> | undefined;

  componentDidMount() {
    if (StorageUtil.getPFDFilingReportColumnFilters() != '{}') {
      this.PfdFilingDataTable?.refresh();
    }
  }

  render() {
    const { pfdFilingTableColumns, pfdFilingLoading, pfdFilingExporting, pfdForm } = this.state;
    const { yearOptions } = this.props;
    const threeFieldColumnSizingProps: ColProps = { xs: 8, sm: 8, md: 8 };
    const dataTableButtons = [];
    dataTableButtons.push(DataTableButtonUtil.Reset());
    dataTableButtons.push(DataTableButtonUtil.Export(
      (tableRequest: TableRequestDTO) => {
        this.setState({ pfdFilingExporting: true });
        const exportDTO = ReportTableExportDTO.create({
          TableRequest: tableRequest,
          coveredYear: this._PfdFilingFormRef.current?.getFieldValue('year')
        });
        return AdminReportApiService.exportPfdFilingReportList(exportDTO)
          .finally(() => {
            this.setState({ pfdFilingExporting: false });
          }).catch(() => {
            notification.error({
              message: 'Error while exporting PFD filing reports'
            });
            this.setState({ pfdFilingExporting: false });
          });
      }, pfdFilingExporting));

    return (
      <>
        <CustomForm
          formRef={this._PfdFilingFormRef}
          layout="vertical"
          initialValues={pfdForm}
          onFinish={() => this.PfdFilingDataTable?.refresh()}
          validateTrigger={['onChange', 'onBlur']}>
          <Row gutter={24}>
            <Col {...threeFieldColumnSizingProps}>
              <FormItem name="year" label="Covered Year" rules={[FormValidationUtil.RequiredNumber('Covered Year is required')]}>
                <Select showSearch={true} optionFilterProp="children">
                  {yearOptions.map(y => (
                    <Option key={y} value={y}>{y}</Option>
                  ))}
                </Select>
              </FormItem>
            </Col>
          </Row>
          <Space>
            <Button type="primary" htmlType="submit">Search</Button>
          </Space>
        </CustomForm>
        <br /> <br />
        <DataTable
          ref={(element: any) => (this.PfdFilingDataTable = element)}
          globalSearch={true}
          columns={pfdFilingTableColumns}
          buttonBar={dataTableButtons}
          serverSide={true}
          tableProps={{
            rowKey: 'committeeName',
            sortDirections: ['ascend', 'descend'],
            locale: {
              emptyText: 'No records were found that match your criteria'
            },
            loading: pfdFilingLoading
          }}
          fetchData={this.fetchPfdFilingReportData}
          styleOptions={{ compact: true, alternatingRowHighlight: true }}
          title={'PFD Filing Report'}
          stateSaving={{
            enabled: true,
            tableUniqueKey: 'pfdFilingReports',
            perSession: true,
          }}
        />
      </>
    );
  }

  private getPfdFilingTableColumns = (): DataTableColumnProps<PfdFilingReportDTO>[] => {
    const addressCol = DataTableColumnUtil.AddressWithContact<PfdFilingReportDTO>('Contact', 'contactId',
      (c) => ({
        name: `${c.firstName} ${c.lastName}`,
        line1: c.addressLine1,
        line2: c.addressLine2,
        city: c.city,
        state: c.state,
        zip: c.zip,
        phone: c.phone,
        email: c.email
      }));
    addressCol.sortDirections = [];
    return [
      addressCol,
      DataTableColumnUtil.Text('State Agency', 'stateAgency'),
      DataTableColumnUtil.Date('Terminated Date', 'terminatedDate'),
      DataTableColumnUtil.BooleanCheckbox('Has Filed', 'hasFiled', 10, FilterType.BooleanRadio)
    ];
  }

  private fetchPfdFilingReportData = (requestState: TableRequestDTO,
    checkEcho: () => boolean,
    callback: (response: TableResponseDTO<PfdFilingReportDTO>) => void
  ) => {
    if (this._PfdFilingFormRef.current?.getFieldValue('year')) {
      this.setState({ pfdFilingLoading: true });
      AdminReportApiService.getPfdFilingReportList(requestState, this._PfdFilingFormRef.current?.getFieldValue('year') || 0)
        .then((res) => {
          if (!checkEcho()) {
            return;
          }
          callback(res);
          this.setState({pfdFilingLoading: false });
          const filterString = JSON.stringify({ ...this._PfdFilingFormRef.current?.getFieldsValue() });
          StorageUtil.setPFDFilingReportColumnFilters(filterString );
        }).catch(() => {
          notification.error({
            message: 'Error while fetching PFD filings'
          });
          this.setState({ pfdFilingLoading: false });
        });
    }
  }
}

export default PfdFilingReport;
