import ReactGA from 'react-ga4';

class GoogleAnalytics {
  public static Initialize = () => {
    ReactGA.initialize($gaKey);
  }

  public static PageView = (location: string) => {
    ReactGA.send({ hitType: 'pageview', page: location });
  }

  public static Event = (category: string, action: string, label: string) => {
    ReactGA.event({
      category: category,
      action: action,
      label: label
    });
  }
}

export default GoogleAnalytics;