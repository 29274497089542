import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import Routes from '../../consts/Routes';
import { RedirectAs404 } from '../../utils/RouteErrors';
import ManageContacts from '../contact/ManageContacts';
import EditContacts from '../contact/EditContacts';

const Contact = () => (
  <Switch>
    <Route exact={true} path={Routes.CONTACTS_BASE} component={ManageContacts} />
    <Route exact={true} path={Routes.ADD_CONTACT} component={EditContacts} />
    <Route exact={true} path={Routes.EDIT_CONTACT} render={(props) => <EditContacts {...props} key={props.match.params.perorgId} />} />

    <Route component={RedirectAs404} />
  </Switch>
);

export default Contact;
