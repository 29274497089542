import { Col, Layout, notification, Row } from 'antd';
import * as React from 'react';
import { connect } from 'react-redux';
import AdminReviewAccountApiService from '../../api/AdminReviewAccountApiService';
import LookupsApiService from '../../api/LookupsApiService';
import CommitteeStatusType from '../../consts/CommitteeStatusType';
import FilerType from '../../consts/FilerType';
import Routes from '../../consts/Routes';
import AdminOrganizationListDTO from '../../models/AdminOrganizationListDTO';
import CodeLookupTableDTO from '../../models/CodeLookupTableDTO';
import TableRequestDTO from '../../models/TableRequestDTO';
import UserSecurity from '../../models/UserSecurity';
import { UserSessionActions } from '../../redux/actions/UserSessionActions';
import CurrentUser from '../../utils/CurrentUser';
import HistoryUtil from '../../utils/HistoryUtil';
import DataTable, { DataTableColumnProps, FilterType } from '../shared/DataTable/DataTable';
import DataTableButtonUtil from '../shared/DataTable/DataTableButtonUtil';
import DataTableColumnUtil from '../shared/DataTable/DataTableColumnUtil';
import Dr2ApiService from '../../api/Dr2ApiService';
import moment from 'moment';
import DataTableCellRendererUtil from '../shared/DataTable/DataTableCellRendererUtil';

const Content = Layout.Content;

interface ReviewAccountProps {
  userSessionChangedAction: (model: UserSecurity) => void;
}

interface ReviewAccountState {
  exporting: boolean;
  statuses: CodeLookupTableDTO[];
  types: CodeLookupTableDTO[];
  counties: CodeLookupTableDTO[];
}

class ReviewAccountPage extends React.Component<ReviewAccountProps, ReviewAccountState> {
  constructor(props: ReviewAccountProps) {
    super(props);

    this.state = {
      exporting: false,
      statuses: [],
      types: [],
      counties: [],
    };
  }

  private dataTable: DataTable<AdminOrganizationListDTO> | undefined;

  private getTableColumns = (): DataTableColumnProps<AdminOrganizationListDTO>[] => {
    const dr1LastFiledDate = DataTableColumnUtil.Date<AdminOrganizationListDTO>(
      'DR1 Filed Date',
      'effectiveDate',
      150,
      { defaultSortOrder: 'descend', filterType: FilterType.NONE });
    dr1LastFiledDate.render = DataTableCellRendererUtil.DateAndTime;
    const dr2LastFiledDate = DataTableColumnUtil.Date<AdminOrganizationListDTO>(
        'DR2 Filed Date',
        'dr2LastFiledDate',
        150,
        { filterType: FilterType.NONE });
    dr2LastFiledDate.render = DataTableCellRendererUtil.DateAndTime;
    return [
      DataTableColumnUtil.Text('Code', 'code', 90),
      DataTableColumnUtil.Text('Name', 'committeeName'),
      DataTableColumnUtil.DropdownMulti(
        'Type',
        'committeeTypeName',
        (this.state.types || []).map(s => (
          { text: s.name || '', value: s.code || '' }))
      ),
      DataTableColumnUtil.DropdownMulti('County', 'county', (this.state.counties || []).map(c => ({ text: c.name || '', value: c.code || '' }))),
      DataTableColumnUtil.DropdownMulti('Status', 'status',
          (this.state.statuses || []).filter(s => s.code !== CommitteeStatusType.DELETED).map(s => ({ text: s.name || '', value: s.code || '' }))),
      dr1LastFiledDate,
      dr2LastFiledDate,
      DataTableColumnUtil.Buttons(
        'committeeId',
        [
          { text: 'Audit', onClick: (rowData) => this.impersonate(rowData) }
        ],
        145)
    ];
  };

  componentDidMount() {
    this.fetchStatuses();
    this.fetchTypes();
    this.fetchCounties();
  }

  render() {
    const actionButtons = [];
    actionButtons.push(DataTableButtonUtil.Reset());
    actionButtons.push(DataTableButtonUtil.Export(
      (tableRequest: TableRequestDTO) => {
        this.setState({ exporting: true });
        return AdminReviewAccountApiService.exportReviewAccountList(tableRequest).finally(() => {
          this.setState({ exporting: false });
        });
      },
      this.state.exporting
    ));

    return (
      <Content className="content-pad">
        <Row>
          <Col xs={24}>
            <DataTable
              ref={(element: any) => (this.dataTable = element)}
              serverSide={true}
              tableProps={{
                rowKey: 'committeeId',
                sortDirections: ['ascend', 'descend'],
              }}
              globalSearch={true}
              title="Committees"
              buttonBar={actionButtons}
              columns={this.getTableColumns()}
              fetchData={{
                fetch: function (tableRequest) {
               return AdminReviewAccountApiService.getReviewAccountList(tableRequest).then((res) => {
                    res.results?.forEach(r => {
                      if (r.effectiveDate) {
                        r.effectiveDate = moment.utc(r.effectiveDate).local();
                      }
                      if (r.dr2LastFiledDate) {
                        r.dr2LastFiledDate = moment.utc(r.dr2LastFiledDate).local();
                      }
                    });
                   return res;
                  });
                }
              }}
              styleOptions={{ compact: true, alternatingRowHighlight: true }}
              stateSaving={{
                enabled: true,
                tableUniqueKey: 'adminReviewAccount',
                perSession: true,
              }}
            />
          </Col>
        </Row>
      </Content>
    );
  }

  private impersonate = (committee: AdminOrganizationListDTO) => {
    AdminReviewAccountApiService.getLastFiledDr2Id(committee.committeeId || '')
      .then(dr2Id => {
          Dr2ApiService.checkForUnpaidPenalties(committee.committeeId || '', committee.committeeTypeCode || '')
          .then((result) => {
          const userSecurity: UserSecurity = CurrentUser.Impersonate({
            committeeId: committee.committeeId,
            committeeName: committee.committeeName || null,
            committeeTypeName: committee.committeeTypeName || null,
            committeeType: committee.committeeTypeCode || null,
            filerTypeId: committee.filerTypeId || null,
            dr2Id: dr2Id,
            committeeFilingCatType: committee.committeeFilingCatType,
            auditing: true,
          });
            CurrentUser.UpdateHasPenaltie(result);
          if (userSecurity.filerTypeId === FilerType.CANDIDATE || userSecurity.filerTypeId === FilerType.PAC) {
            HistoryUtil.push(Routes.generate(Routes.SCHEDULE_SUMMARY));
          }
          else if (userSecurity.filerTypeId === FilerType.OSF) {
            HistoryUtil.push(Routes.generate(Routes.ORGANIZATIONS_BASE));
          }
        });
      });
  }

  private fetchStatuses = () => {
    LookupsApiService.getCommitteeStatuses()
      .then((statuses) => {
        this.setState({ statuses });
      })
      .catch((error: any) => {
        notification.error({
          message: error.message,
          description: error.description,
        });
      });
  }

  private fetchTypes = () => {
    LookupsApiService.getCommitteeTypes()
      .then((types) => {
        this.setState({ types });
      })
      .catch((error: any) => {
        notification.error({
          message: error.message,
          description: error.description,
        });
      });
  }

  private fetchCounties = () => {
    LookupsApiService.getCounties()
      .then((counties) => {
        this.setState({ counties });
      })
      .catch((error: any) => {
        notification.error({
          message: error.message,
          description: error.description,
        });
      });
  }
}

function mapDispatchToProps(dispatch: any) {
  return {
    userSessionChangedAction: (model: UserSecurity) => dispatch(UserSessionActions.update(model)),
  };
}

function mapStateToProps() {
  return {
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ReviewAccountPage);
