// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import EntitySelectorDTO from './EntitySelectorDTO';

interface DualCompDTO extends EntitySelectorDTO { 
  id: number;
  officialFirstName: string | null;
  officialMiddleInitial: string | null;
  officialLastName: string | null;
  phone: string | null;
  email: string | null;
  originalAgencyId: number;
  originalAgencyLabel: string | null;
  secondaryAgencyName: string | null;
  compensationAmount: number;
  filingStatus: string | null;
  serviceDescription: string | null;
  acceptedDate: moment.Moment | string;
  signature: string | null;
  signatureDate: moment.Moment | string;
}
const DualCompDTO = {
  create: (initValues?: Partial<DualCompDTO> | Record<string, unknown> | null) => {
    return Object.assign(EntitySelectorDTO.create(),
    {
      id: 0,
      officialFirstName: "",
      officialMiddleInitial: "",
      officialLastName: "",
      phone: "",
      email: "",
      originalAgencyId: 0,
      originalAgencyLabel: "",
      secondaryAgencyName: "",
      compensationAmount: 0,
      filingStatus: "",
      serviceDescription: "",
      acceptedDate: new Date(0).toISOString(),
      signature: "",
      signatureDate: new Date(0).toISOString(),
    },
    initValues);
  }
};

export default DualCompDTO;