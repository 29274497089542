// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
import BaseApi from './BaseApi';
import TableRequestDTO from '../models/TableRequestDTO';
import TableResponseDTO from '../models/TableResponseDTO';
import AdminDualCompListDTO from '../models/AdminDualCompListDTO';
import DualCompDTO from '../models/DualCompDTO';

export class AdminDualCompAPIService extends BaseApi {

  // post: api/admin/dual-comp?filingStatus=${encodeURIComponent(filingStatus)}
  public getDualCompList(tableRequest: TableRequestDTO, filingStatus: string): Promise<TableResponseDTO<AdminDualCompListDTO>> {
    let url = `api/admin/dual-comp`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (filingStatus != null) {
      url += `${prefix}filingStatus=${encodeURIComponent(filingStatus)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, TableResponseDTO<AdminDualCompListDTO>>(tableRequest, 'post', url, true, false);
  }

  // post: api/admin/dual-comp/${id}/approve
  public approveDualComp(id: number): Promise<void> {
    let url = `api/admin/dual-comp/${id}/approve`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, void>(null, 'post', url, true, false);
  }

  // post: api/admin/dual-comp/${id}/reject
  public rejectDualComp(id: number): Promise<void> {
    let url = `api/admin/dual-comp/${id}/reject`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, void>(null, 'post', url, true, false);
  }

  // get: api/admin/dual-comp/${id}
  public getDualComp(id: number): Promise<DualCompDTO> {
    let url = `api/admin/dual-comp/${id}`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, DualCompDTO>(null, 'get', url, true, false);
  }

  // delete: api/admin/dual-comp/${id}/delete
  public deleteDualComp(id: number): Promise<void> {
    let url = `api/admin/dual-comp/${id}/delete`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, void>(null, 'delete', url, true, false);
  }
}
var service = new AdminDualCompAPIService();
export default service;
