// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import EntitySelectorDTO from './EntitySelectorDTO';

interface AdminPfdPenaltyDTO extends EntitySelectorDTO { 
  filingId: number;
  penaltyAmount: number | null;
  penaltyPaidDate: moment.Moment | string | null;
  penaltyIssuedDate: moment.Moment | string | null;
  penaltyDueDate: moment.Moment | string | null;
  waivedDate: moment.Moment | string | null;
  waiverRequested: boolean;
  waiverAmount: number | null;
  penaltyWaiverReason: string | null;
  penaltyDescription: string | null;
  waiverDenied: boolean | null;
}
const AdminPfdPenaltyDTO = {
  create: (initValues?: Partial<AdminPfdPenaltyDTO> | Record<string, unknown> | null) => {
    return Object.assign(EntitySelectorDTO.create(),
    {
      filingId: 0,
      penaltyAmount: null,
      penaltyPaidDate: null,
      penaltyIssuedDate: null,
      penaltyDueDate: null,
      waivedDate: null,
      waiverRequested: false,
      waiverAmount: null,
      penaltyWaiverReason: null,
      penaltyDescription: null,
      waiverDenied: null,
    },
    initValues);
  }
};

export default AdminPfdPenaltyDTO;