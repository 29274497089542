import { InfoCircleOutlined } from '@ant-design/icons';
import { Button, Menu } from 'antd';
import * as React from 'react';
import AdminHelpPageApiService from '../api/AdminHelpPageApiService';
import HelpPageDTO from '../models/HelpPageDTO';
import HistoryUtil from '../utils/HistoryUtil';


interface HelpButtonProps {
}

interface HelpButtonState {
  link: string;
  helpPageList: HelpPageDTO[];
}

class HelpButton extends React.Component<HelpButtonProps, HelpButtonState> {
  unlisten: any;
  constructor(props: HelpButtonProps) {
    super(props);
    this.state = {
      link: 'set to some value so menu can initialize correctly',
      helpPageList: []
    };
  }

  componentDidMount() {
    this.getHelpPages();
    this.unlisten = HistoryUtil.listen((location) => {
      this.getHelpLink(location.pathname);
    });
  }

  componentWillUnmount() {
    this.unlisten();
  }

  render() {
    const { link } = this.state;
    return (
      <>
        <Menu.Item key="help" hidden={link === ''}>
          <Button type="link" onClick={() => { link && window.open(link); }}>
            <InfoCircleOutlined /> Help
          </Button>
        </Menu.Item>
      </>
    );
  }

  private getHelpPages = () => {
    AdminHelpPageApiService.getHelpPageList()
      .then(helpPageList => {
        this.setState({ helpPageList });
        this.getHelpLink(window.location.pathname);
      });
  }

  private getHelpLink = (pathname: string) => {
    const { helpPageList } = this.state;
    if (helpPageList) {
      const matchingHelpPage = helpPageList.find(x => x.route == pathname);
      if (matchingHelpPage) {
        this.setState({ link: matchingHelpPage.helpUrl || '' });
      }
      else {
        this.setState({ link: '' });
      }
    }
  }
}

export default HelpButton;
