import { Layout, Modal, notification, Tabs } from 'antd';
import * as React from 'react';
import AdminPfdApiService from '../../api/AdminPfdApiService';
import Routes from '../../consts/Routes';
import AdminPfdListDTO from '../../models/AdminPfdListDTO';
import AdminPfdContactListDTO from '../../models/AdminPfdContactListDTO';
import HistoryUtil from '../../utils/HistoryUtil';
import DataTable, { DataTableColumnProps, FilterType } from '../shared/DataTable/DataTable';
import DataTableButtonUtil from '../shared/DataTable/DataTableButtonUtil';
import DataTableColumnUtil from '../shared/DataTable/DataTableColumnUtil';
import { RouteComponentProps } from 'react-router';
import TableRequestDTO from '../../models/TableRequestDTO';
import PfdContact from './PfdContact';
import AdminPfdContactDepartmentDTO from '../../models/AdminPfdContactDepartmentDTO';
import AdminPfdContactDTO from '../../models/AdminPfdContactDTO';

const { Content } = Layout;
const { confirm } = Modal;
const { TabPane } = Tabs;

interface ManagePfdProps {
  tabIndex?: string;
}

interface ManagePfdState {
  tableManageColumns: DataTableColumnProps<AdminPfdListDTO>[];
  tableApprovalColumns: DataTableColumnProps<AdminPfdListDTO>[];
  tableContactColumns: DataTableColumnProps<AdminPfdContactListDTO>[];
  exporting: boolean;
  showContactModal: boolean;
  requestedContactId?: number;
}

class ManagePfdPage extends React.Component<RouteComponentProps<ManagePfdProps>, ManagePfdState> {
  private dataManageTable: DataTable<AdminPfdListDTO> | undefined;
  private dataApproveTable: DataTable<AdminPfdListDTO> | undefined;
  private dataContactTable: DataTable<AdminPfdContactListDTO> | undefined;
  private _pfdContactForm: PfdContact | null = null;
  constructor(props: RouteComponentProps<ManagePfdProps>) {
    super(props);
    this.state = {
      tableManageColumns: this.getManageTableColumns(),
      tableApprovalColumns: this.getApprovalTableColumns(),
      tableContactColumns: this.getContactTableColumns(),
      exporting: false,
      showContactModal: false,
    };
  }

  render() {
    const { exporting, showContactModal, requestedContactId } = this.state;
    const manageActionButtons = [];
    manageActionButtons.push(DataTableButtonUtil.Reset());

    const manageContactButtons = [];
    manageContactButtons.push(DataTableButtonUtil.Reset());
    manageContactButtons.push(DataTableButtonUtil.Primary('Add Filer', () => {
      this.showModal(true);
    }));
    manageContactButtons.push(DataTableButtonUtil.Export((tableRequest: TableRequestDTO) => {
      this.setState({ exporting: true });

      return AdminPfdApiService.exportPfdContactList(tableRequest)
        .finally(() => {
          this.setState({ exporting: false });
        });
    }, exporting));

    return (
      <>
        <Content className="content-pad">
          <Tabs>
            <TabPane tab="Manage PFD" key="1">
              <Content className="content-pad">
                <DataTable
                  ref={(element: any) => (this.dataApproveTable = element)}
                  serverSide={true}
                  tableProps={{
                    rowKey: 'filingId',
                    sortDirections: ['descend', 'ascend']
                  }}
                  globalSearch={true}
                  title="Approve/Reject PFD"
                  buttonBar={manageActionButtons}
                  columns={this.state.tableApprovalColumns}
                  fetchData={{
                    fetch: function (tableRequest) { return AdminPfdApiService.getApprovalPfdList(tableRequest); },
                    failureMessage: 'Failed to retrieve Personal Financial Disclosures'
                  }}
                  styleOptions={{ compact: true, alternatingRowHighlight: true }}
                  stateSaving={{
                    enabled: true,
                    tableUniqueKey: 'adminApprovePfd',
                    perSession: true,
                  }}
                />
                <br />
                <DataTable
                  ref={(element: any) => (this.dataManageTable = element)}
                  serverSide={true}
                  tableProps={{
                    rowKey: 'filingId',
                    sortDirections: ['descend', 'ascend']
                  }}
                  globalSearch={true}
                  title="Manage PFD"
                  buttonBar={manageActionButtons}
                  columns={this.state.tableManageColumns}
                  fetchData={{
                    fetch: function (tableRequest) { return AdminPfdApiService.getManagePfdList(tableRequest); },
                    failureMessage: 'Failed to retrieve Personal Financial Disclosures'
                  }}
                  styleOptions={{ compact: true, alternatingRowHighlight: true }}
                  stateSaving={{
                    enabled: true,
                    tableUniqueKey: 'adminManagePfd',
                    perSession: true,
                  }}
                />
              </Content>
            </TabPane>
            <TabPane tab="Manage Filers" key="2">
              <Content className="content-pad">
                <DataTable
                  ref={(element: any) => (this.dataContactTable = element)}
                  serverSide={true}
                  tableProps={{
                    rowKey: 'contactId',
                    sortDirections: ['descend', 'ascend']
                  }}
                  globalSearch={true}
                  title="Manage Filers"
                  buttonBar={manageContactButtons}
                  columns={this.state.tableContactColumns}
                  fetchData={{
                    fetch: (tableRequest) => { return AdminPfdApiService.getPfdContactsList(tableRequest); },
                    failureMessage: 'Failed to retrieve contacts'
                  }}
                  styleOptions={{ compact: true, alternatingRowHighlight: true }}
                  stateSaving={{
                    enabled: true,
                    tableUniqueKey: 'adminManagePfdFilers',
                    perSession: true
                  }}
                />
              </Content>
            </TabPane>
          </Tabs>
        </Content>
        {showContactModal &&
          <Modal
            centered
            visible={showContactModal}
            onOk={() => this.handleContactSubmit()}
            onCancel={() => this.showModal(false)}
            okText="Save"
            cancelText="Cancel"
            destroyOnClose={true}
            width={'80%'}
            style={{ top: 50 }}>
            <PfdContact
              ref={(element) => this._pfdContactForm = element}
              id={requestedContactId} />
          </Modal>
        }
      </>
    );
  }

  private getTableColumns = (): DataTableColumnProps<AdminPfdListDTO>[] => {
    return [
      DataTableColumnUtil.Text('Year', 'year', 10, { defaultSortOrder: 'descend' }),
      DataTableColumnUtil.Address(
        'Person',
        'lastName',
        (r) => ({
          name: r.lastName + ', ' + r.firstName,
          line1: r.address1,
          line2: r.address2,
          city: r.city,
          state: r.state,
          zip: r.zip
        })
      )];
  };

  private getManageTableColumns = (): DataTableColumnProps<AdminPfdListDTO>[] => {
    return this.getTableColumns().concat([
      DataTableColumnUtil.Date('Filed Date', 'filedDate'),
      DataTableColumnUtil.BooleanCheckbox('Penalty', 'penalty', 10, FilterType.BooleanRadio),
      DataTableColumnUtil.Buttons(
        'filingId',
        [
          { text: 'Edit', onClick: (rowData) => this.editPfd(rowData.filingId) },
          {
            text: 'Delete',
            onClick: (rowData) => this.confirmDelete(rowData.filingId, rowData.firstName + ' ' + rowData.lastName, rowData.year || '')
          },
          { text: 'Penalty', onClick: (rowData) => this.editPenalty(rowData.filingId) },
        ],
        215
      )
    ]);
  };

  private getApprovalTableColumns = (): DataTableColumnProps<AdminPfdListDTO>[] => {
    return this.getTableColumns().concat([
      DataTableColumnUtil.Buttons(
        'filingId',
        [
          { text: 'View', onClick: (rowData) => this.editPfd(rowData.filingId) },
          { text: 'Approve', onClick: (rowData) => this.approve(rowData.filingId) },
          {
            text: 'Reject', onClick: (rowData) =>
              this.confirmReject(rowData.filingId, rowData.firstName + ' ' + rowData.lastName, rowData.year || '')
          },
        ],
        215
      )
    ]);
  };

  private getContactTableColumns = (): DataTableColumnProps<AdminPfdContactListDTO>[] => {
    return [
      DataTableColumnUtil.AddressWithContact('Contact', 'lastName', (c) => ({
        name: c.lastName + ', ' + c.firstName,
        line1: c.address1,
        line2: c.address2,
        city: c.city,
        state: c.state,
        zip: c.zip,
        email: c.email,
        phone: c.phone
      })),
      DataTableColumnUtil.Text('Department', 'departments'),
      DataTableColumnUtil.Text('Division', 'divisions'),
      DataTableColumnUtil.Text('Position', 'positions'),
      DataTableColumnUtil.Date('Termination Date', 'terminationDate'),
      DataTableColumnUtil.BooleanCheckbox('Terminated', 'isTerminated', 10, FilterType.BooleanRadio, { defaultSortOrder: 'ascend' }),
      DataTableColumnUtil.Buttons('contactId', [
        {
          text: 'Edit', onClick: (rowData) => {
            this.showModal(true, rowData.contactId);
          }
        }
      ], 70)
    ];
  }

  private showModal = (show: boolean, id?: number) => {
    this.setState({ showContactModal: show, requestedContactId: id });
  }

  private handleContactSubmit = () => {
    if (!this._pfdContactForm) {
      return;
    }

    this._pfdContactForm.validate().then(validResult => {
      let dept = undefined;
      if (validResult.model.department) {
        dept = {
          coveredYear: validResult.model.coveredYear?.year().toString(),
          departmentId: validResult.model.department
        } as AdminPfdContactDepartmentDTO;
      }

      const contact = {
        contactId: validResult.model.contactId,
        firstName: validResult.model.firstName,
        lastName: validResult.model.lastName,
        address1: validResult.model.address1,
        address2: validResult.model.address2,
        city: validResult.model.city,
        state: validResult.model.state,
        zip: validResult.model.zip,
        phone: validResult.model.phone,
        email: validResult.model.email,
        terminationDate: validResult.model.terminationDate,
        contactDepartmentDivisionPositition: dept && [dept],
      } as AdminPfdContactDTO;

      AdminPfdApiService.savePfdContact(contact)
        .then(() => {
          this.showModal(false);
          this.dataContactTable?.refresh();
          notification.success({
            message: 'Contact saved successfully'
          });
        })
        .catch(() => {
          this.showModal(false);
          notification.error({
            message: 'An error occured while saving contact'
          });
        });
    });
    
  }

  private editPenalty = async (id: number) => {
    HistoryUtil.push(Routes.generate(Routes.PFD_PENALTY, { id: id }));
  }

  private confirmDelete = (id: number, name: string, year: string) => {
    confirm({
      title: 'Are you sure you want to delete this PFD?',
      content: 'The following record will be permanently deleted: ' + name + ', ' + year,
      onOk: () => {
        this.deletePfd(id);
      },
    });
  }

  private deletePfd = async (id: number) => {
    AdminPfdApiService.deletePfd(id)
      .then(() => {
        if (this.dataManageTable) {
          this.dataManageTable.refresh();
        }
        if (this.dataApproveTable) {
          this.dataApproveTable.refresh();
        }
        notification.success({
          message: 'Deleted Successfully'
        });
      }).catch(() => {
        notification.error({ message: 'Failed to delete Pfd.' });
      });
  }

  private editPfd = async (id: number) => {
    HistoryUtil.push(Routes.generate(Routes.EDIT_PFD, { id: id }));
  }

  private approve = (id: number) => {
    AdminPfdApiService.approve(id)
      .then(() => {
        if (this.dataManageTable) {
          this.dataManageTable.refresh();
        }
        if (this.dataApproveTable) {
          this.dataApproveTable.refresh();
        }
        notification.success({
          message: 'Approved Successfully'
        });
      }).catch(() => {
        notification.error({
          message: 'Failed to approve filing.'
        });
      });
  }

  private reject = async (id: number) => {
    AdminPfdApiService.deletePfd(id)
      .then(() => {
        if (this.dataManageTable) {
          this.dataManageTable.refresh();
        }
        if (this.dataApproveTable) {
          this.dataApproveTable.refresh();
        }
        notification.success({
          message: 'Rejected Successfully'
        });
      }).catch(() => {
        notification.error({
          message: 'Failed to reject filing.'
        });
      });
  }

  private confirmReject = (id: number, name: string, year: string) => {
    confirm({
      title: 'Are you sure you want to reject this PFD?',
      content: 'The following record will be rejected: ' + name + ', ' + year,
      onOk: () => {
        this.reject(id);
      },
    });
  }
}

export default ManagePfdPage;