import { FormInstance, Modal, notification } from 'antd';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import DataTableColumnUtil from '../shared/DataTable/DataTableColumnUtil';
import DataTable, { DataTableColumnProps } from '../shared/DataTable/DataTable';
import Dr2ApiService from '../../api/Dr2ApiService';
import CurrentUser from '../../utils/CurrentUser';
import AdjustEntirePeriodDTO from '../../models/AdjustEntirePeriodDTO';
import ReportType from '../../consts/ReportType';
import ElectionYear from '../../consts/ElectionYear';
import CommFilingPeriodCatType from '../../consts/CommFilingPeriodCatType';
import { Content } from 'antd/lib/layout/layout';
import DataTableButtonUtil from '../shared/DataTable/DataTableButtonUtil';
import HistoryUtil from '../../utils/HistoryUtil';
import Routes from '../../consts/Routes';
import DueDateDTO from '../../models/DueDateDTO';
import FormItem from 'antd/lib/form/FormItem';
import moment from 'moment';
import CustomDatePicker from '../shared/CustomDatePicker';
import CustomForm from '../shared/CustomForm';
import FilingPeriodHistoryApiService from '../../api/FilingPeriodHistoryApiService';

interface AdjustEntirePeriodFormProps {
}

interface AdjustEntirePeriodFormState {
  showModal: boolean;
  selectedPeriod: number;
  dueDates: DueDateDTO;
  saving: boolean;
  loading: boolean;
}

class AdjustEntirePeriodForm extends React.Component<RouteComponentProps<AdjustEntirePeriodFormProps>, AdjustEntirePeriodFormState> {
  private readonly _formRef = React.createRef<FormInstance>();
  constructor(props: RouteComponentProps<AdjustEntirePeriodFormProps>) {
    super(props);
    this.state = {
      showModal: false,
      selectedPeriod: -1,
      dueDates: DueDateDTO.create(
        {
          beginDueDate: moment.utc(),
          endDueDate: moment.utc(),
          filingDueDate: moment.utc(),
          adjustedDueDate: moment.utc()
        }),
      saving: false,
      loading: false,
    };
  }

  render() {
    const { dueDates } = this.state;
    const actionButtons = [];
    actionButtons.push(DataTableButtonUtil.Reset());
    return (
      <Content className="content-pad">
        <DataTable
          buttonBar={actionButtons}
          columns={this.getTableColumns()}
          fetchData={{
            fetch: function (tableRequest) {
              return Dr2ApiService.getAdjustFilingTable(tableRequest, CurrentUser.Get()?.committeeId || '', CurrentUser.Get()?.dr2Id || '');
            },
            failureMessage: 'Failed to retrieve filing periods'
          }}
          globalSearch={true}
          serverSide={true}
          styleOptions={{ compact: true, alternatingRowHighlight: true }}
          tableProps={{
            rowKey: 'periodMasterId',
            sortDirections: ['ascend', 'descend'],
            locale: { emptyText: 'No Filing Periods Available' }
          }}
          title="Adjust Entire Period"
        />

        <Modal
          title="Select Due Dates for Committee Filing"
          visible={this.state.showModal}
          confirmLoading={this.state.saving}
          onOk={() => this._formRef.current?.submit()}
          onCancel={() => this.setState({ showModal: false, selectedPeriod: -1 })}
          okText="Save"
          cancelText="Cancel"
        >
          <CustomForm formRef={this._formRef} initialValues={dueDates} layout="vertical" onFinish={this.handleSave} >
            <FormItem name="beginDueDate" label="Beginning Date" tooltip={this.getTooltipText(dueDates.beginAdjFactor)}>
              <CustomDatePicker onChange={(d) => d?.startOf('day')}/>
            </FormItem>
            <FormItem name="endDueDate" label="Ending Date" tooltip={this.getTooltipText(dueDates.endAdjFactor)}>
              <CustomDatePicker onChange={(d) => d?.startOf('day')}/>
            </FormItem>
            <FormItem name="filingDueDate" label="Due Date" tooltip={this.getTooltipText(dueDates.dueDateAdjFactor)}>
              <CustomDatePicker onChange={(d) => d?.startOf('day')}/>
            </FormItem>
            <FormItem name="adjustedDueDate" label="Adjusted Due Date">
              <CustomDatePicker onChange={(d) => d?.startOf('day')}/>
            </FormItem>
          </CustomForm>
        </Modal>
      </Content>
    );
  }

  private getTooltipText = (adjFactor: number | null): string | null => {
    if (adjFactor) {
      return `The adjustment factor is ${adjFactor.toString()} days`;
    }
    return null;
  }

  private getTableColumns = (): DataTableColumnProps<AdjustEntirePeriodDTO>[] => {
    return [
      DataTableColumnUtil.Number('Year', 'year', undefined, { defaultSortOrder: 'descend' }),
      DataTableColumnUtil.Text('Filing Period', 'filingPeriodName', 800),
      DataTableColumnUtil.DropdownMulti('Report Type', 'reportType',
        [
          { text: ReportType.STANDARD, value: ReportType.STANDARD },
          { text: ReportType.SUPPLEMENTAL, value: ReportType.SUPPLEMENTAL },
          { text: ReportType.SPECIALELECTION, value: ReportType.SPECIALELECTION }
        ]),
      DataTableColumnUtil.DropdownMulti('Election Year', 'electionYear',
        [
          { text: ElectionYear.ELECTION, value: ElectionYear.ELECTION },
          { text: ElectionYear.NONELECTION, value: ElectionYear.NONELECTION },
          { text: ElectionYear.SPECIAL, value: ElectionYear.SPECIAL },
          { text: ElectionYear.NA, value: ElectionYear.NA }
        ]),
      DataTableColumnUtil.DropdownMulti('Type', 'periodType',
        [
          { text: CommFilingPeriodCatType.SWGA, value: CommFilingPeriodCatType.SWGA },
          { text: CommFilingPeriodCatType.SCPA, value: CommFilingPeriodCatType.SCPA },
          { text: CommFilingPeriodCatType.CENT, value: CommFilingPeriodCatType.CENT },
          { text: CommFilingPeriodCatType.CTY, value: CommFilingPeriodCatType.CTY },
          { text: CommFilingPeriodCatType.LOCL, value: CommFilingPeriodCatType.LOCL },
          { text: CommFilingPeriodCatType.NA, value: CommFilingPeriodCatType.NA }
        ]),
      DataTableColumnUtil.Buttons('periodMasterId',
        [
          { text: 'Select', onClick: (rowData) => this.handlePeriodSelected(rowData.periodMasterId) }
        ],
        80)
    ];
  }

  private handlePeriodSelected = (periodMasterId: number) => {
    Dr2ApiService.getDueDates(CurrentUser.Get()?.committeeId || '', periodMasterId)
      .then(dueDatesUnformatted => {
        const dueDates = {
          beginDueDate: moment.utc(dueDatesUnformatted.beginDueDate),
          beginAdjFactor: dueDatesUnformatted.beginAdjFactor,
          endDueDate: moment.utc(dueDatesUnformatted.endDueDate),
          endAdjFactor: dueDatesUnformatted.endAdjFactor,
          filingDueDate: moment.utc(dueDatesUnformatted.filingDueDate),
          dueDateAdjFactor: dueDatesUnformatted.dueDateAdjFactor,
          adjustedDueDate: dueDatesUnformatted.adjustedDueDate ? moment.utc(dueDatesUnformatted.adjustedDueDate) : null
        } as DueDateDTO;
        this.setState({ dueDates, selectedPeriod: periodMasterId, showModal: true });
        this._formRef.current?.setFieldsValue(dueDates);
      }).catch(() => {
        notification.error({
          message: 'Unable to obtain due dates'
        });
      });
  }

  private handleSave = (values: DueDateDTO) => {
    this.setState({ saving: true });
    Dr2ApiService.saveAdjustEntirePeriod(
      values,
      CurrentUser.Get()?.committeeId || '',
      CurrentUser.Get()?.dr2Id || '',
      this.state.selectedPeriod || -1)
      .then(result => {
        if (result.succeeded) {
          notification.success({
            message: 'Period successfully adjusted'
          });
          this.deleteFilingPeriod(CurrentUser.Get()?.dr2Id || '', CurrentUser.Get()?.committeeId || '');
        }
        else {
          notification.error({
            message: result.errors[0].toString()
          });
          this.setState({ saving: false });
        }
      }).catch(() => {
        notification.error({
          message: 'Failed to adjust period'
        });
        HistoryUtil.push(Routes.generate(Routes.SCHEDULE_SUMMARY));
        this.setState({ saving: false });
      });
  }

  private deleteFilingPeriod = (dr2Id: string, committeeId: string) => {
    FilingPeriodHistoryApiService.deleteFph(dr2Id, committeeId)
      .then(() => {
        Dr2ApiService.getLastFiledDr2Id(CurrentUser.Get()?.committeeId || '')
          .then((dr2Id) => {
            CurrentUser.ChangeDr2(dr2Id);
            notification.success({ message: 'Filing was transferred successfully.' });
            HistoryUtil.push(Routes.generate(Routes.SCHEDULE_SUMMARY));
          });
        this.setState({ saving: false });
      }).catch(() => {
        notification.error({ message: 'Failed to transfer filing.' });
      });
  }
}

export default AdjustEntirePeriodForm;