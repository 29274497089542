import moment from 'moment';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import AdminDr1ApiService from '../../api/AdminDr1ApiService';
import OrganizationApiService from '../../api/OrganizationApiServices';
import CommitteeStatusType from '../../consts/CommitteeStatusType';
import FilerType from '../../consts/FilerType';
import FilingStatusType from '../../consts/FilingStatusType';
import NoteEntityParents from '../../consts/NoteEntityParents';
import Role from '../../consts/Role';
import Routes from '../../consts/Routes';
import CommitteeFilingDTO from '../../models/CommitteeFilingDTO';
import CommitteeInfoDTO from '../../models/CommitteeInfoDTO';
import DR1DTO from '../../models/DR1DTO';
import FilerTypeDTO from '../../models/FilerTypeDTO';
import NoteEntryDTO from '../../models/NoteEntryDTO';
import CurrentUser from '../../utils/CurrentUser';
import DateUtil from '../../utils/DateUtil';
import HistoryUtil from '../../utils/HistoryUtil';
import BankAccount from '../shared/BankAccount';
import CommitteeInfo from '../shared/CommitteeInfo';
import Dr1StatusBar from '../shared/Dr1StatusBar';
import NoteEntryList from '../shared/NoteEntryList';
import CommitteeImports from './CommitteeImports';
import Dr1Role from './Dr1Role';
import Jurisdiction from './Jurisdiction';
import {
  Button,
  FormInstance,
  Layout,
  Modal,
  notification,
  Row,
  Space,
  Spin,
  Tabs,
  Typography,
} from 'antd';
import ParentEntity from './ParentEntity';
import CustomForm from '../shared/CustomForm';
import RoleType from '../../consts/RoleType';

const { Content } = Layout;
const { TabPane } = Tabs;
const { confirm } = Modal;

interface OrgDetailsProps {

}

interface OrgDetailsState {
  committeeId: string;
  committeeStatusType: string;
  filerType: number;
  committeeFilings: CommitteeFilingDTO[];
  noteEntries: NoteEntryDTO[];
  filingStatus: string;
  filingDate: string | moment.Moment;
  filerTypeDto: FilerTypeDTO;
  effectiveDate: string | moment.Moment;
  DR1: DR1DTO;
  committeeInfo: CommitteeInfoDTO;
  loadingCommitteeFilings: boolean;
  loadingDR1Data: boolean;
  loadingEmails: boolean;
  adjModal: boolean;
  showTabKey: string;
  isHistory: boolean;
  to: string;
  disablePrint: boolean;
  filingCount: number;
}

class OrgDetails extends React.Component<RouteComponentProps<OrgDetailsProps>, OrgDetailsState>
{
  private adjustFormRef = React.createRef<FormInstance>();
  private _committeeInfoRef: CommitteeInfo | null = null;
  private _teasurerRoleRef: Dr1Role | null = null;
  private _chairpersonRoleRef: Dr1Role | null = null;
  private _candidateRoleRef: Dr1Role | null = null;
  private _bankAccountRef: BankAccount | null = null;
  private _parentEntityRef: ParentEntity | null = null;
  private _contactPersonRef: Dr1Role | null = null;
  private _residentAgentRef: Dr1Role | null = null;
  private _jurisdictionInfoRef: Jurisdiction | null = null;

  constructor(props: RouteComponentProps<OrgDetailsProps>) {
    super(props);

    this.state = {
      committeeId: CurrentUser.Get()?.committeeId || '',
      committeeStatusType: CurrentUser.Get()?.committeeStatusType || '',
      filerType: CurrentUser.Get()?.filerTypeId || 0,
      filerTypeDto: FilerTypeDTO.create({ createdTs: Date.now }),
      filingStatus: '',
      DR1: DR1DTO.create({}),
      committeeInfo: CommitteeInfoDTO.create(),
      filingDate: '',
      committeeFilings: [],
      noteEntries: [],
      effectiveDate: '',
      loadingCommitteeFilings: true,
      loadingDR1Data: true,
      loadingEmails: true,
      adjModal: false,
      showTabKey: (this.props.location?.state as OrgDetailsState)?.showTabKey || 'committeeInfo',
      isHistory: false,
      to: '',
      disablePrint: false,
      filingCount: -1,
    };
  }

  componentDidMount() {
    this.loadcommitteeFilingData();
    this.fetchFilingCount(CurrentUser.Get()?.committeeId || '');
    if (this.state.committeeStatusType != CommitteeStatusType.PENDING_APPROVAL) {
      this.getCommitteeEmails();
    }
    else {
      this.setState({ loadingEmails: false });
    }
  }

  componentDidUpdate(prevProps: OrgDetailsProps, prevstate: OrgDetailsState) {
    if (prevstate.filingDate !== this.state.filingDate) {
      this.loadDR1Data();
    }
  }

  render() {
    const {
      committeeFilings,
      effectiveDate,
      filerType,
      loadingDR1Data,
      loadingCommitteeFilings,
      loadingEmails,
      adjModal,
      committeeInfo,
      DR1,
      showTabKey,
      isHistory,
      committeeId,
      to,
      disablePrint,
      filingCount
    } = this.state;
    const loading = loadingCommitteeFilings || loadingEmails || loadingDR1Data;
    const style: React.CSSProperties = { marginLeft: 20 };
    const inReviewAccount = this.state.committeeStatusType !== CommitteeStatusType.PENDING_APPROVAL;
    const isFiler = CurrentUser.Get()?.isInRole(Role.FILER);
    const isAdmin = CurrentUser.Get()?.isInRole(Role.IECDB_ADMINISTRATOR);
    const filingButton = isFiler ? 'Amend' : 'Adjust/Audit';

    return (
      <Content className="content-pad">
        <Spin tip="Loading Organization Details" spinning={loading}>
          <Row>
            {!loadingCommitteeFilings && (
              <Dr1StatusBar
                disabled={false}
                committeeFilings={committeeFilings}
                effectiveDate={effectiveDate}
                onSelect={this.handleCommitteeFile}
                onChange={this.handleIsHistory} />
            )}
            {
              (inReviewAccount && !isHistory) &&
              <Button type="primary" onClick={this.showModalAdjust} style={style}>{filingButton}</Button>
            }
            {(inReviewAccount && !loading) &&
              <>
                <Button onClick={this.printDr1} style={style} disabled={disablePrint} loading={disablePrint}> Print </Button>
              <a
                href={`mailto:${to}?subject=${encodeURIComponent(committeeInfo.name || '')}&body=${encodeURIComponent(committeeInfo.name || '')}`}
                onClick={() => {
                  window.open(
                    `mailto:${to}?subject=${encodeURIComponent(committeeInfo.name || '')}&body=${encodeURIComponent(committeeInfo.name || '')}`
                  );
                  event?.preventDefault();
                }}
                  style={style}
                >
                  <Button>Email Committee</Button>
              </a>
              {isAdmin && filingCount !== -1 && [0, 1].includes(filingCount) && (
                <Button danger style={style} onClick={() => this.confirmDeleteCommittee()}> Delete Committee </Button>
              )}
              </>
            }
          </Row>
          <Modal title="Save DR-1 Changes"
            visible={adjModal}
            onCancel={this.handleCancel}
            okButtonProps={{ style: { display: 'none' } }}
            centered
            width={600}>
            <CustomForm name="adjustform" layout="vertical"
              initialValues={{ fileAmendDate: moment() }}
              onFinish={this.submit} formRef={this.adjustFormRef}>
              {CurrentUser.Get()?.isInRole(Role.IECDB_ADMINISTRATOR) ?
                <>
                  <Typography.Paragraph>If all changes have been made,
                    please click either &quot;File Adjusted DR-1&quot; or &quot;File Audited DR-1&quot;. </Typography.Paragraph>
                  <Typography.Paragraph>If you are not done updating your information please continue to do so.
                    If changes are not audited or adjusted, changes will be lost upon logging out. </Typography.Paragraph>
                  <Space>
                    <Button onClick={() => this.handleButtonChange(FilingStatusType.AUDITED)} type="primary">File Audited DR-1</Button>
                    <Button onClick={() => this.handleButtonChange(FilingStatusType.ADJUSTED)} type="primary" >File Adjusted DR-1</Button>
                  </Space>
                </> :
                <>
                  <Typography.Paragraph>If all changes have been made,
                    please click &quot;File Amended DR-1&quot;. </Typography.Paragraph>
                  <Typography.Paragraph>If you are not done updating your information please continue to do so.
                    If changes are not amended, changes will be lost upon logging out. </Typography.Paragraph>
                  <Space>
                    <Button onClick={() => this.handleButtonChange(FilingStatusType.AMENDED)} type="primary" >File Amended DR-1</Button>
                  </Space>
                </>
              }
            </CustomForm>
          </Modal>
          <Tabs defaultActiveKey="10" size="large" tabBarGutter={24} activeKey={showTabKey} onChange={this.handleTabChange} >
            <TabPane tab="Committee" key="committeeInfo" forceRender={true}>
              {!this.state.loadingDR1Data &&
                <CommitteeInfo
                  ref={(element) => this._committeeInfoRef = element}
                  filerType={filerType}
                  disabled={!inReviewAccount || isHistory}
                  committeeInfo={committeeInfo}
                  filterTypes={true} />
              }
            </TabPane>
            {(filerType == FilerType.CANDIDATE || filerType == FilerType.PAC) &&
              <TabPane tab="Treasurer" key="personInfo3" forceRender={true} >
                {!this.state.loadingDR1Data &&
                  <Dr1Role
                    ref={(element) => this._teasurerRoleRef = element}
                    personInfo={DR1.personInfo3 || undefined}
                    dr1Role={RoleType.TREASURER}
                    disabled={!inReviewAccount || isHistory}
                    roleName="Treasurer"
                  />
                }
              </TabPane>
            }
            {(filerType == FilerType.PAC || filerType == FilerType.CANDIDATE) &&
              <TabPane tab="Chairperson" key="personInfo2" forceRender={true} >
                {!this.state.loadingDR1Data &&
                  <Dr1Role
                    ref={(element) => this._chairpersonRoleRef = element}
                    personInfo={DR1.personInfo2 || undefined}
                    dr1Role={RoleType.COMMITTEE_CHAIRPERSON}
                    disabled={!inReviewAccount || isHistory}
                    roleName="Chairperson" />
                }
              </TabPane>
            }
            {filerType == FilerType.CANDIDATE &&
              <TabPane tab="Candidate" key="personInfo1" forceRender={true}  >
                {!this.state.loadingDR1Data &&
                  <Dr1Role
                    ref={(element) => this._candidateRoleRef = element}
                    personInfo={DR1.personInfo1 || undefined}
                    dr1Role={RoleType.CANDIDATE}
                    disabled={!inReviewAccount || isHistory}
                    roleName="Candidate" />
                }
              </TabPane>
            }
            {(filerType == FilerType.CANDIDATE || filerType == FilerType.PAC) &&
              <TabPane tab="Bank Accounts" key="bankAccounts" forceRender={true} >
                {!this.state.loadingDR1Data &&
                <BankAccount
                    bankAccounts={DR1.bankAccounts || []}
                    disabled={!inReviewAccount || isHistory}
                    ref={(element) => this._bankAccountRef = element}
                    committeeName={DR1.committeeInfo?.name || ''} />
                }
              </TabPane>
            }
            {filerType == FilerType.OSF &&
              <TabPane tab="Contact Person" key="personInfo3" forceRender={true}>
                {!this.state.loadingDR1Data &&
                  <Dr1Role
                    ref={(element) => this._contactPersonRef = element}
                    personInfo={DR1.personInfo3 || undefined}
                    disabled={!inReviewAccount || isHistory}
                    roleName="Contact Person" />
                }
              </TabPane>
            }
            {filerType == FilerType.OSF &&
              <TabPane tab="Resident Agent" key="personInfo2" forceRender={true}>
                {!this.state.loadingDR1Data &&
                  <Dr1Role
                    ref={(element) => this._residentAgentRef = element}
                    personInfo={DR1.personInfo2 || undefined}
                    disabled={!inReviewAccount || isHistory}
                    roleName="Resident Agent" />
                }
              </TabPane>
            }
            {(filerType == FilerType.OSF || filerType == FilerType.PAC) &&
              <TabPane tab="Parent" key="parentEntity" forceRender={true} >
                {!this.state.loadingDR1Data &&
                  <ParentEntity
                    ref={(element) => this._parentEntityRef = element}
                    secondaryCommitteeInfo={DR1.secondaryCommittee1 || undefined}
                    disabled={!inReviewAccount || isHistory} />
                }
              </TabPane>
            }
            {filerType == FilerType.OSF &&
              <TabPane tab="Jurisdiction" key="jurisdictionInfo" forceRender={true} >
                {!this.state.loadingDR1Data &&
                  <Jurisdiction
                    ref={(element) => this._jurisdictionInfoRef = element}
                    jurisdictionInfo={DR1.jurisdictionInfo || undefined}
                    disabled={!inReviewAccount || isHistory} />
                }
              </TabPane>
            }
            <TabPane tab="Notes" key="notes" >
              {!this.state.loadingDR1Data &&
                <NoteEntryList
                  parent={NoteEntityParents.COMMITTEE}
                  parentId={committeeId}
                  auditorsNoteId={DR1.auditorsNoteId || null} />
              }
            </TabPane>
            {(filerType == FilerType.PAC || filerType == FilerType.CANDIDATE) &&
              <TabPane tab="Documents" key="committeeImports" forceRender={true} >
                {!this.state.loadingDR1Data &&
                  <CommitteeImports disabled={!inReviewAccount || isHistory} />
                }
              </TabPane>
            }
          </Tabs>
        </Spin>
        {!isFiler &&
          <>
            {!inReviewAccount &&
              <>
                <Space>
                  <Button type="primary" onClick={() => this.handleRedirect(committeeId || '')} > Approve</Button>
                  <Button danger={true} onClick={() => this.confirmReject(committeeId || '', DR1.committeeInfo?.name || '')}> Reject </Button>
                  <Button onClick={() => this.handleCancelRedirect(inReviewAccount)}> Cancel </Button>
                </Space>
              </>
            }
          </>
        }
      </Content>
    );
  }

  private fetchFilingCount = (committeeId: string) => {
    OrganizationApiService.filingCount(committeeId)
      .then(count => {
        this.setState({ filingCount: count });
      })
      .catch(err => {
        notification.error({ message: err.message });
      });
  }

  private confirmDeleteCommittee = () => {
    confirm({
      title: 'Are you sure you want to delete this committee?',
      content: 'This information will be deleted permanently.',
      onOk: () => this.handleDeleteCommittee(),
    });
  }

  private handleDeleteCommittee = () => {
    OrganizationApiService.deleteCommittee(this.state.committeeId)
      .then(result => {
        if (result && result.succeeded) {
          HistoryUtil.push(Routes.generate(Routes.ADMIN_REVIEWACCOUNT));
          notification.success({ message: 'Committee successfully deleted.' });
          CurrentUser.Release();
          return;
        }
        notification.error({ message: result.errors[0] });
      })
      .catch(err => {
        notification.error({ message: err.message });
      });
  }

  private handleCancelRedirect = (inReview: boolean) => {
    if (inReview) {
      HistoryUtil.push(Routes.ADMIN_REVIEWACCOUNT);
    }
    else {
      HistoryUtil.push(Routes.MANAGE_DR1);
    }
    CurrentUser.Release();
  }

  private printDr1 = () => {
    this.setState({ disablePrint: true });
    OrganizationApiService.printDr1Report(
      CurrentUser.Get()?.committeeId || '',
      moment(this.state.filingDate).utc().format('M/D/YYYY hh:mm:ss A') || '')
      .then(() => {
        this.setState({ disablePrint: false });
      }).catch(() => {
        this.setState({ disablePrint: false });
        notification.error({
          message: 'Error while generating report'
        });
      });
  }

  private getCommitteeEmails = () => {
    OrganizationApiService.getDr1UserEmails(this.state.committeeId).then((emails) => {
      this.setState({ to: emails, loadingEmails: false });
    });
  }

  private showModalAdjust = () => {
    this.validateForm().then(DR1 => {
      if (DR1 !== false) {
        this.setState({ adjModal: true });
      }
    });
  }

  private validateForm = () => {
    const DR1 = { ...this.state.DR1 };
    let valid = true;

    const jurisdictionPromise = this._jurisdictionInfoRef?.validate().then(validResult => {
      if (validResult.model) {
        DR1.jurisdictionInfo = { ...this.state.DR1.jurisdictionInfo, ...validResult.model };
      }
    }).catch(() => {
      valid = false;
      this.setState({ showTabKey: 'jurisdictionInfo' });
    });

    let parentEntityPromise = undefined;
    if (!this._parentEntityRef?.checkEmptyFields()) {
      parentEntityPromise =
        this._parentEntityRef?.validate().then(validResult => {
          if (validResult.model) {
            DR1.secondaryCommittee1 = { ...this.state.DR1.secondaryCommittee1, ...validResult.model };
          }
        }).catch(() => {
          valid = false;
          this.setState({ showTabKey: 'parentEntity' });
        });
    }
    else {
      parentEntityPromise = Promise.resolve(null).then(fields => {
        DR1.secondaryCommittee1 = fields;
      });
    }

    if (this._bankAccountRef) {
      const bankListCpy = this._bankAccountRef.getBankAccounts();
      DR1.bankAccounts = [...bankListCpy];
    }

    const residentAgentPromise = this._residentAgentRef?.validate().then(validResult => {
      if (validResult.model) {
        DR1.personInfo2 = { ...this.state.DR1.personInfo2, ...validResult.model };
      }
    }).catch(() => {
      valid = false;
      this.setState({ showTabKey: 'personInfo2' });
    });

    const contactpersonPromise = this._contactPersonRef?.validate().then(validResult => {
      if (validResult.model) {
        DR1.personInfo3 = { ...this.state.DR1.personInfo3, ...validResult.model };
      }
    }).catch(() => {
      valid = false;
      this.setState({ showTabKey: 'personInfo3' });
    });

    const candidatePromise = this._candidateRoleRef?.validate().then(validResult => {
      if (validResult.model) {
        DR1.personInfo1 = { ...this.state.DR1.personInfo1, ...validResult.model };
      }
    }).catch(() => {
      valid = false;
      this.setState({ showTabKey: 'personInfo1' });
    });

    let chairpersonPromise = undefined;
    if (!this._chairpersonRoleRef?.checkEmptyFields() || this.state.filerType != FilerType.CANDIDATE) {
      chairpersonPromise = this._chairpersonRoleRef?.validate().then(validResult => {
        if (validResult.model) {
          DR1.personInfo2 = { ...this.state.DR1.personInfo2, ...validResult.model };
        }
      }).catch(() => {
        valid = false;
        this.setState({ showTabKey: 'personInfo2' });
      });
    }
    else {
      chairpersonPromise = Promise.resolve(null).then(fields => {
        DR1.personInfo2 = fields;
      });
    }

    const treasurerPromise = this._teasurerRoleRef?.validate().then(validResult => {
      if (validResult.model) {
        DR1.personInfo3 = { ...this.state.DR1.personInfo3, ...validResult.model };
      }
    }).catch(() => {
      valid = false;
      this.setState({ showTabKey: 'personInfo3' });
    });

    const committeeInfoPromise = this._committeeInfoRef?.validate().then(validResult => {
      if (validResult.model) {
        DR1.committeeInfo = { ...DR1.committeeInfo, ...validResult.model };
      }
    }).catch(() => {
      this.setState({ showTabKey: 'committeeInfo' });
      valid = false;
    });

    return Promise.all([
      committeeInfoPromise,
      treasurerPromise,
      candidatePromise,
      chairpersonPromise,
      contactpersonPromise,
      parentEntityPromise,
      residentAgentPromise,
      jurisdictionPromise,
    ]).then(() => {
      if (!valid) { return false; }
      else { return DR1; }
    });
  }

  private handleCancel = () => {
    this.setState({ adjModal: false });
  }

  private handleIsHistory = (isHistoryText: string) => {
    this.setState({ isHistory: isHistoryText === 'History View' });
  }

  private handleCommitteeFile = (committeeFiling: CommitteeFilingDTO | undefined) => {
    if (committeeFiling) {
      this.setState({ filingDate: committeeFiling.submittedOn });
    }
  }

  private handleButtonChange = (filingStatus: string) => {
    this.setState({ filingStatus }, () => {
      this.adjustFormRef.current?.validateFields().then(() => {
        this.submit();
      });
    });
  }

  private handleTabChange = (key: string) => {
    this.setState({ showTabKey: key });
  }

  private handleRedirect = (committeeId: string) => {
    CurrentUser.Release();
    HistoryUtil.push(Routes.generate(Routes.APPROVE_DR1_EDIT, { committeeId: committeeId }));
  }

  private submit = () => {
    this.validateForm().then(values => {
      if (values != false) {
        const DR1 = { ...values };
        if (this.state.filerType) {
          const fileTypeDTO = { ...this.state.filerTypeDto };
          fileTypeDTO.id = this.state.filerType;
          this.setState({ filerTypeDto: fileTypeDTO });
          DR1.committeeFilerType = { ...this.state.DR1.committeeFilerType, ...fileTypeDTO };
        }
        if (this._bankAccountRef) {
          const bankListCpy = this._bankAccountRef.getBankAccounts();
          DR1.bankAccounts = [...bankListCpy];
        }

        this.setState({ DR1, adjModal: false, showTabKey: 'committeeInfo' });
        this.SaveDR1(this.state.filingStatus);
      }
    });
  }

  private loadDR1Data = () => {
    if (!this.state.committeeId || !this.state.filingDate || !this.state.filerType) {
      this.setState({ loadingDR1Data: false });
      return;
    }
    this.setState({ loadingDR1Data: true });
    OrganizationApiService
      .getDr1OrgDetails(this.state.committeeId, DateUtil.toTimeStamp(moment(this.state.filingDate)), this.state.filerType)
      .then(DR1 => {
        if (DR1.committeeInfo) {
          if (DR1.committeeInfo.electionDate) {
            DR1.committeeInfo.electionDate = moment(DR1.committeeInfo.electionDate);
          }
          this.setState({ committeeInfo: DR1.committeeInfo });
        }
        this.setState({ DR1, loadingDR1Data: false });
      }).catch(() => {
        notification.error({ message: 'Failed to load DR1 data.' });
        this.setState({ loadingDR1Data: false });
      });
  }

  private loadcommitteeFilingData = () => {
    if (!this.state.committeeId) {
      this.setState({ loadingCommitteeFilings: false });
      return;
    }
    this.setState({ loadingCommitteeFilings: true });
    OrganizationApiService.getCommitteeFilingList(this.state.committeeId)
      .then(committeeFilings => {
        if (committeeFilings && committeeFilings.length) {
          committeeFilings.forEach((filing: CommitteeFilingDTO) => {
            if (filing && filing.submittedOn) {
              filing.submittedOn = moment.utc(filing.submittedOn).local().format('M/D/YYYY hh:mm:ss A');
            }
          });
        }
        this.setState({ committeeFilings, loadingCommitteeFilings: false });
      }).catch(() => {
        notification.error({ message: 'Failed to load committee filing data.' });
        this.setState({ loadingCommitteeFilings: false });
      });
  }

  private SaveDR1 = (filingStatus: string) => {
    OrganizationApiService
      .editDR1(this.state.DR1, this.state.committeeId, filingStatus, DateUtil.toTimeStamp(moment(this.state.filingDate)))
      .then(result => {
        if (result.succeeded) {
          this.loadcommitteeFilingData();
          notification.success({
            message: 'Your DR1 has been changed successfully.'
          });
        }
      }).catch(() => {
        notification.error({
          message: 'DR1 changes failed.'
        });
        HistoryUtil.push(Routes.ORGANIZATIONS_BASE);
      });
    this.adjustFormRef.current?.resetFields();
  }

  private reject = (committeeId: string) => {
    AdminDr1ApiService.reject(committeeId)
      .then((result) => {
        if (result.succeeded) {
          CurrentUser.Release();
          notification.success({
            message: 'DR-1 was rejected successfully.'
          });
        }
        else {
          notification.error({ message: result.errors[0] });
        }
        HistoryUtil.push(Routes.MANAGE_DR1);
      }).catch(() => {
        notification.error({
          message: 'Failed to reject filing.'
        });
      });
  }

  private confirmReject = (committeeId: string, committeeName: string) => {
    confirm({
      title: 'Are you sure you want to reject this DR1?',
      content: 'The following record will be rejected: ' + committeeName,
      onOk: () => {
        this.reject(committeeId);
      },
    });
  }
}

export default OrgDetails;