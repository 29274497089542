import { Layout } from 'antd';
import * as React from 'react';
import SchGConsultantExpensesApiService from '../../api/SchGConsultantExpensesApiService';
import SchGConsultantExpensesDTO from '../../models/SchGConsultantExpensesDTO';
import DataTableColumnUtil from '../shared/DataTable/DataTableColumnUtil';
import DataTable, { DataTableColumnProps } from '../shared/DataTable/DataTable';
import Dr2AmendedStatuses from '../../consts/Dr2AmendedStatuses';
import Dr2AmendedIndicators from '../../consts/Dr2AmendedIndicators';

const { Content } = Layout;

interface SchGConsultantExpenseHistoryTableProps {
  consultantExpenditureId: string;
}

interface SchGConsultantExpenseHistoryTableState {
}

class SchGConsultantExpenseHistoryTable extends React.Component<SchGConsultantExpenseHistoryTableProps, SchGConsultantExpenseHistoryTableState> {
  constructor(props: SchGConsultantExpenseHistoryTableProps) {
    super(props);

    this.state = {

    };
  }

  render() {
    const { consultantExpenditureId } = this.props;
    return (
      <Content className="content-pad">
        <DataTable
          columns={this.getTableColumns()}
          fetchData={{
            fetch: function (tableRequest) {
              return SchGConsultantExpensesApiService.getConsultantExpenseHistory(tableRequest, consultantExpenditureId);
            },
            failureMessage: 'Failed to retrieve consultant history'
          }}
          serverSide={true}
          styleOptions={{ compact: true, alternatingRowHighlight: true }}

          tableProps={{
            rowKey: 'consultantExpenditureIdAndSeq',
            sortDirections: ['ascend', 'descend'],
            locale: { emptyText: 'Consultant history not available.' }
          }}
        />
      </Content>
    );
  }

  private getTableColumns = (): DataTableColumnProps<SchGConsultantExpensesDTO>[] => {
    const tableColumns: DataTableColumnProps<SchGConsultantExpensesDTO>[] = [
      DataTableColumnUtil.Date('Payment Date', 'paymentDate', 100),
      DataTableColumnUtil.Address('Given To', 'givenToName',
        (c) => ({
          name: c.givenToName,
          line1: c.consultantAddressLine1,
          line2: c.consultantAddressLine2,
          city: c.consultantCity,
          state: c.consultantState,
          zip: c.consultantZip
        })
      ),
      DataTableColumnUtil.Currency('Amount', 'paymentAmt'),
      DataTableColumnUtil.DropdownMulti('Status', 'status',
        [
          { text: Dr2AmendedStatuses.ORIGINAL, value: Dr2AmendedIndicators.ORIGINAL },
          { text: Dr2AmendedStatuses.AMENDED, value: Dr2AmendedIndicators.AMENDED },
          { text: Dr2AmendedStatuses.ADJUSTED, value: Dr2AmendedIndicators.ADJUSTED },
          { text: Dr2AmendedStatuses.DELETED, value: Dr2AmendedIndicators.DELETED }
        ])
    ];
    return tableColumns;
  }
}

export default SchGConsultantExpenseHistoryTable;
