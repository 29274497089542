// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';

interface BankAccountDTO { 
  bankAccountId: string | null;
  bankName: string | null;
  accountName: string | null;
  accountNumber: string | null;
  accountType: string | null;
  primaryAccount: boolean;
  addressLine1: string | null;
  addressLine2: string | null;
  city: string | null;
  state: string | null;
  zipCode: string | null;
  email: string | null;
  phoneNumber: string | null;
}
const BankAccountDTO = {
  create: (initValues?: Partial<BankAccountDTO> | Record<string, unknown> | null) => {
    return Object.assign(
    {
      bankAccountId: null,
      bankName: null,
      accountName: null,
      accountNumber: null,
      accountType: null,
      primaryAccount: false,
      addressLine1: null,
      addressLine2: null,
      city: null,
      state: null,
      zipCode: null,
      email: null,
      phoneNumber: null,
    },
    initValues);
  }
};

export default BankAccountDTO;