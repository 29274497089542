// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
import BaseApi from './BaseApi';
import TableRequestDTO from '../models/TableRequestDTO';
import TableResponseDTO from '../models/TableResponseDTO';
import AdminDr3ListDTO from '../models/AdminDr3ListDTO';
import ResultDTO from '../models/ResultDTO';

export class AdminDr3ApiService extends BaseApi {

  // post: api/admin/dr3
  public getDR3List(tableRequest: TableRequestDTO): Promise<TableResponseDTO<AdminDr3ListDTO>> {
    let url = `api/admin/dr3`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, TableResponseDTO<AdminDr3ListDTO>>(tableRequest, 'post', url, true, false);
  }

  // post: api/admin/dr3/certify?committeeFilingId=${committeeFilingId}
  public certifyDR3(committeeFilingId: number): Promise<ResultDTO> {
    let url = `api/admin/dr3/certify`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (committeeFilingId != null) {
      url += `${prefix}committeeFilingId=${committeeFilingId}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, ResultDTO>(null, 'post', url, true, false);
  }

  // post: api/admin/dr3/reject?committeeId=${encodeURIComponent(committeeId)}&committeeFilingId=${committeeFilingId}&filerTypeCode=${filerTypeCode}
  public rejectDr3(committeeId: string, committeeFilingId: number, filerTypeCode: number): Promise<ResultDTO> {
    let url = `api/admin/dr3/reject`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (committeeId != null) {
      url += `${prefix}committeeId=${encodeURIComponent(committeeId)}`;
      prefix = '&';
    }
    if (committeeFilingId != null) {
      url += `${prefix}committeeFilingId=${committeeFilingId}`;
      prefix = '&';
    }
    if (filerTypeCode != null) {
      url += `${prefix}filerTypeCode=${filerTypeCode}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, ResultDTO>(null, 'post', url, true, false);
  }

  // get: api/admin/dr3/checkUnfiledPeriods?committeeId=${encodeURIComponent(committeeId)}
  public checkUnfiledPeriods(committeeId: string): Promise<boolean> {
    let url = `api/admin/dr3/checkUnfiledPeriods`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (committeeId != null) {
      url += `${prefix}committeeId=${encodeURIComponent(committeeId)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, boolean>(null, 'get', url, true, false);
  }
}
var service = new AdminDr3ApiService();
export default service;
