import { Divider} from 'antd';
import * as React from 'react';
import SchF1LoansReceivedApiService from '../../api/SchF1LoansReceivedApiService';
import SchF1LoansReceivedDTO from '../../models/SchF1LoansReceivedDTO';
import DataTableColumnUtil from '../shared/DataTable/DataTableColumnUtil';
import DataTable, { DataTableColumnProps } from '../shared/DataTable/DataTable';
import Dr2AmendedStatuses from '../../consts/Dr2AmendedStatuses';
import Dr2AmendedIndicators from '../../consts/Dr2AmendedIndicators';

interface SchF1LoansReceivedHistoryTableProps {
  loanReceivedId: string;
}

interface SchF1LoansReceivedHistoryTableState {
}

class SchF1LoansReceivedHistoryTable extends React.Component<SchF1LoansReceivedHistoryTableProps, SchF1LoansReceivedHistoryTableState> {
  constructor(props: SchF1LoansReceivedHistoryTableProps) {
    super(props);

    this.state = {

    };
  }

  render() {
    const { loanReceivedId } = this.props;
    return (
      <>
        <Divider/>
        <DataTable
          columns={this.getTableColumns()}
          fetchData={{
            fetch: function (tableRequest) { return SchF1LoansReceivedApiService.getLoanReceivedHistory(tableRequest, loanReceivedId); },
            failureMessage: 'Failed to retrieve loan received history'
          }}
          serverSide={true}
          styleOptions={{ compact: true, alternatingRowHighlight: true }}
          tableProps={{
            rowKey: 'loansReceivedIdAndSeq',
            sortDirections: ['ascend', 'descend'],
            locale: { emptyText: 'Loans Received history not available.' }
          }}
          title="Loan Recieved"
        />
      </>
    );
  }

  private getTableColumns = (): DataTableColumnProps<SchF1LoansReceivedDTO>[] => {
    const tableColumns: DataTableColumnProps<SchF1LoansReceivedDTO>[] = [
      DataTableColumnUtil.Date('Date', 'loanDt', 100),
      DataTableColumnUtil.Address('Lender', 'lenderName',
        (c) => ({
          name: c.lenderName,
          line1: c.lenderAddressLine1,
          line2: c.lenderAddressLine2,
          city: c.lenderCity,
          state: c.lenderState,
          zip: c.lenderZip
        })
      ),
      DataTableColumnUtil.Currency('Amount', 'loanAmt'),
      DataTableColumnUtil.DropdownMulti('Status', 'status',
        [
          { text: Dr2AmendedStatuses.ORIGINAL, value: Dr2AmendedIndicators.ORIGINAL },
          { text: Dr2AmendedStatuses.AMENDED, value: Dr2AmendedIndicators.AMENDED },
          { text: Dr2AmendedStatuses.ADJUSTED, value: Dr2AmendedIndicators.ADJUSTED },
          { text: Dr2AmendedStatuses.DELETED, value: Dr2AmendedIndicators.DELETED }
        ])
    ];
    return tableColumns;
  }
}

export default SchF1LoansReceivedHistoryTable;
