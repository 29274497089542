// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Constants.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
export default class FilingStatus {
    public static readonly FILED: string = 'Filed';
    public static readonly NOT_FILED: string = 'Not Filed';
    public static readonly AMENDED: string = 'Amended';
    public static readonly AUDITED: string = 'Audited';
    public static readonly ADJUSTED: string = 'Adjusted';
    public static readonly DELETED: string = 'Deleted';
    public static readonly NEVER_FILED: string = 'Never Filed';
    public static readonly CERTIFIED: string = 'Certified';
}
