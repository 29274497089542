import { Divider } from 'antd';
import * as React from 'react';
import SchAContributionsApiService from '../../api/SchAContributionsApiService';
import SchAContributionsDTO from '../../models/SchAContributionsDTO';
import DataTableColumnUtil from '../shared/DataTable/DataTableColumnUtil';
import DataTable, { DataTableColumnProps } from '../shared/DataTable/DataTable';
import Dr2AmendedStatuses from '../../consts/Dr2AmendedStatuses';
import Dr2AmendedIndicators from '../../consts/Dr2AmendedIndicators';

interface SchAContributionHistoryTableProps {
  contributionId: string;
}

interface SchAContributionHistoryTableState {
}

class SchAContributionHistoryTable extends React.Component<SchAContributionHistoryTableProps, SchAContributionHistoryTableState> {
  constructor(props: SchAContributionHistoryTableProps) {
    super(props);

    this.state = {};
  }

  render() {
    const { contributionId } = this.props;
    return (
      <>
        <Divider/>
        <DataTable
          columns={this.getTableColumns()}
          fetchData={{
            fetch: function (tableRequest) { return SchAContributionsApiService.getContributionHistory(tableRequest, contributionId); },
            failureMessage: 'Failed to retrieve contribution history'
          }}
          serverSide={true}
          styleOptions={{ compact: true, alternatingRowHighlight: true }}
          tableProps={{
            rowKey: 'contributionIdAndSeq',
            sortDirections: ['ascend', 'descend'],
            locale: { emptyText: 'Contribution history not available.' }
          }}
          title= "Contributions"
        />
      </>
    );
  }

  private getTableColumns = (): DataTableColumnProps<SchAContributionsDTO>[] => {
    const tableColumns: DataTableColumnProps<SchAContributionsDTO>[] = [
      DataTableColumnUtil.Date('Date', 'contributionDt', 100),
      DataTableColumnUtil.Address('Contributor', 'contributorName',
        (c) => ({
          name: c.contributorName,
          line1: c.contributorAddressLine1,
          line2: c.contributorAddressLine2,
          city: c.contributorCity,
          state: c.contributorState,
          zip: c.contributorZip
        })
      ),
      DataTableColumnUtil.Currency('Amount', 'contributionAmt'),
      DataTableColumnUtil.Text('Check #', 'checkNumber', 125, { align: 'center' }),
      DataTableColumnUtil.DropdownMulti('Status', 'status',
        [
          { text: Dr2AmendedStatuses.ORIGINAL, value: Dr2AmendedIndicators.ORIGINAL },
          { text: Dr2AmendedStatuses.AMENDED, value: Dr2AmendedIndicators.AMENDED },
          { text: Dr2AmendedStatuses.ADJUSTED, value: Dr2AmendedIndicators.ADJUSTED },
          { text: Dr2AmendedStatuses.DELETED, value: Dr2AmendedIndicators.DELETED }
        ])
    ];
    return tableColumns;
  }
}

export default SchAContributionHistoryTable;
