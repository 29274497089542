import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Card, Col, ColProps, Row, Space, Typography } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import * as React from 'react';
import Routes from '../../../consts/Routes';
import HistoryUtil from '../../../utils/HistoryUtil';

interface NewFiledReportsProps {
}

interface NewFiledReportsState {
}

class NewFiledReports extends React.Component<NewFiledReportsProps, NewFiledReportsState> {

    constructor(props: NewFiledReportsProps) {
        super(props);
    }

    render() {
        const Reports = [
            {
                label: 'Campaign Reports',
                values: [
                    {
                        title: 'Searchable Database',
                        route: Routes.SEARCHABLE_DATABASE,
                        description: 'Search campaign data by transaction type, committee name, date, and more.'
                    },
                    {
                        title: 'State Reports',
                        route: Routes.STATE_REPORTS,
                        description: 'Reports for state parties, PAC\'s, and candidates for state office, or a judge standing for retention.'
                    },
                    {
                        title: 'County and Local Reports',
                        route: Routes.COUNTY_REPORTS,
                        description: 'Reports for county, city, school, and other political subdivision committees and all ballot issue committees.'
                    },
                    {
                        title: 'Statements of Organization and Dissolution',
                        route: Routes.REPORTS_STATEMENT_OF_ORGANIZATION_AND_DISSOLUTION,
                        description: 'Statements registering and dissolving committees.'
                    },
                    {
                        title: 'Verified Statements of Registration',
                        route: Routes.VSR_REPORTS,
                        description: 'Contributions in excess of $50 to Iowa committees from federal and out-of-state committees.'
                    },
                    {
                        title: 'One-Time Contributions',
                        route: Routes.OTC_REPORTS,
                        description: 'One-time contributions made by permanent organizations to Iowa committees.'
                    },
                    {
                        title: 'Independent Expenditures',
                        route: Routes.IE_REPORTS,
                        description: 'Independent Expenditures made by organizations or individuals not ' +
                            'done in coordination with candidates, PAC\'s, or parties.'
                    },
                    {
                        title: 'Escheats',
                        route: Routes.ESCHEAT_REPORTS,
                        description: 'Funds received by committees with unknown/unidentifiable sources that are ' +
                            'remitted to the State of Iowa.'
                    },
                    {
                        title: 'Building Funds',
                        route: Routes.BUILDING_FUND_REPORTS,
                        description: 'Reports that show the receipts and expenditures by state parties from their building ' +
                            'fund accounts pursuant to rule 351-4.24.'
                    },
                    {
                        title: 'Additional Resources',
                        route: Routes.DATASETS,
                        description: 'Links to campaign in data.iowa.gov and a downloadable spreadsheet with committee information.'
                    },
                    {
                        externalLink: 'https://ethics.iowa.gov/campaigns/campaign-reports-and-due-dates',
                        description: 'Learn about the different types of Campaign Reports, due dates, etc.'
                    }
                ]
            },
            {
                label: 'Ethics Reports',
                values: [
                    {
                        title: 'Personal Financial Disclosures',
                        route: Routes.PFD_REPORTS,
                        description: 'Personal Financial Disclosure Reports for required state executive branch officials and employees.'
                    },
                    {
                        title: 'Gifts and Bequests',
                        route: Routes.GB_REPORTS,
                        description: 'Agencies are required to report all gifts and bequests with a value of $50 or more within 20 days of receipt.'
                    },
                    {
                        title: 'Dual Executive Branch Compensation',
                        route: Routes.DUAL_COMP_REPORTS,
                        description: 'Officials and employees receiving compensation simultaneously from more than one executive branch agency.'
                    },
                    {
                        title: 'Sales by Officials/Employees to Agencies',
                        route: Routes.SALES_BY_STATE_REPORTS,
                        description: 'Officials and employees selling a good/service to a state agency in excess of $2,000.'
                    },
                    {
                        title: 'Consents for Sales/Leases',
                        route: Routes.CONSENT_FOR_SALES_AND_LEASES_REPORTS,
                        description: 'Consents given from the Governor\'s Office and regulatory agencies ' +
                            'for sales/leases made by their officials and employees'
                    },
                    {
                        externalLink: 'https://ethics.iowa.gov/ethics/ethics-reports-and-due-dates',
                        description: 'Learn about the different types of Ethics Reports, due dates, etc.'
                    }
                ]
            },
            {
                label: 'Late Reports',
                values: [
                    {
                        title: 'Campaign Late Reports',
                        route: Routes.CAMPAIGN_LATE_REPORTS,
                        description: 'List of late-filed campaign reports and associated penalties.'
                    },
                    {
                        title: 'Ethics Late Reports',
                        route: Routes.ETHICS_LATE_REPORTS,
                        description: 'List of late-filed ethics reports and associated penalties.'
                    },
                    //{
                    //    title: 'Petitions for Waiver',
                    //    route: Routes.PUBLIC_REPORTS,
                    //    description: 'Request for a reduction or waiver of penalties that ' +
                    //        'were issued for late-filed reports.'
                    //}
                ]
            }
        ];

        const columnSizingProps: ColProps = { xs: 24, sm: 24, md: 12 };

        return (
            <Content className="content-pad" >
                <Button type="link" onClick={() => HistoryUtil.push(Routes.generate(Routes.LOGIN))} >
                    <Typography.Title level={4}>
                        <Space size="large">
                            <ArrowLeftOutlined className="public-report-links" /> IECDB State/Local Campaign Disclosure Reports
                        </Space>
                    </Typography.Title>
                </Button>
                {Reports.map((r, index) => {
                    const half = Math.ceil(r.values.length / 2);
                    return (
                        <div
                            style={{
                                paddingTop: 16,
                                paddingBottom: 16
                            }}
                            key={index}
                        >
                            <Typography.Title level={4} className="bold-heading" style={{padding: '12px 24px 0px 24px'}}>
                                {r.label}
                            </Typography.Title>
                            <Row gutter={24} >
                                <Col {...columnSizingProps}>
                                    {r.values.splice(0, half).map((report) => (
                                        this.createReport(report)
                                    ))}
                                </Col>
                                <Col {...columnSizingProps}>
                                    {r.values.slice(-half).map((report) => (
                                        this.createReport(report)
                                    ))}
                                </Col>
                            </Row>
                        </div>
                    );
                })}
            </Content>
        );
    }

    private createReport = (report: any) => {
        return (
            <Card bordered={false} > 
                <Card.Meta
                    title={
                        <a
                            type="link"
                            onClick={() => {
                                if (report.externalLink) {
                                    window.open(report.externalLink);
                                }
                                else {
                                    HistoryUtil.push(Routes.generate(report.route));
                                }
                            }}
                            className="public-report-links"
                        >
                            {report.title}
                        </a>
                    }
                    description={
                        this.createDescription(report)
                    }
                />
            </Card>
        );
    }

    private createDescription = (report: any) => {
        let content = null;

        if (report.title) {
            content = report.description;
        } else {
            content =
                <a 
                    type="link"
                    onClick={() => {
                        if (report.externalLink) {
                            window.open(report.externalLink);
                        }
                        else {
                            HistoryUtil.push(Routes.generate(report.route));
                        }
                    }}
                    className="public-report-description-links"
                    >
                        {report.description}
                </a>;
        }

        return content;
    }
}

export default NewFiledReports;