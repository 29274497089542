import {
  Button,
  Col,
  ColProps,
  Input,
  Layout,
  notification,
  Row,
  Space,
  Typography
} from 'antd';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import { FormInstance, FormProps } from 'antd/lib/form/Form';
import FormItem from 'antd/lib/form/FormItem';
import moment from 'moment';
import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import Dr2ApiService from '../../api/Dr2ApiService';
import Routes from '../../consts/Routes';
import Dr2PenaltyDTO from '../../models/Dr2PenaltyDTO';
import CurrentUser from '../../utils/CurrentUser';
import DateUtil from '../../utils/DateUtil';
import HistoryUtil from '../../utils/HistoryUtil';
import CurrencyInput from '../shared/CurrencyInput';
import CustomDatePicker from '../shared/CustomDatePicker';
import CustomForm from '../shared/CustomForm';

const { Content } = Layout;
const { TextArea } = Input;

interface Dr2PenaltiesProps {
  id?: string;
  from?: string;
}

interface Dr2PenaltiesState {
  committeeDelinquencyMaster: Dr2PenaltyDTO,
  loading: boolean,
  checkValue: number;
  vsrId: string;
  title: string;
  penaltyNoticeLoading: boolean,
}

class Dr2Penalty extends React.Component<RouteComponentProps<Dr2PenaltiesProps> & FormProps, Dr2PenaltiesState> {
  private readonly _formRef = React.createRef<FormInstance>();
  constructor(props: RouteComponentProps<Dr2PenaltiesProps> & FormProps) {
    super(props);
    this.state = {
      vsrId: this.props.match.params.id || '',
      title: decodeURIComponent(this.props.match.params.from || ''),
      committeeDelinquencyMaster: Dr2PenaltyDTO.create({ penaltyStatus: 'OPEN', dr2Id: CurrentUser.Get()?.dr2Id || '' }),
      loading: true,
      checkValue: 0,
      penaltyNoticeLoading: false,
    };
  }

  componentDidMount() {
    this.getInitialValues();
  }

  onWaivedChanged = () => {
    this._formRef.current?.setFieldsValue({ reduced: false });
  }

  onReducedChanged = () => {
    this._formRef.current?.setFieldsValue({ waived: false });
  }

  render() {
    const columnSizingProps: ColProps = { xs: 24, sm: 24, md: 12 };
    const { loading, committeeDelinquencyMaster, title, penaltyNoticeLoading } = this.state;

    return (
      <Content className="content-pad">
        <h3 className="ant-typography">Audit - Penalties</h3>
        <h5 className="ant-typography">{title}</h5>
        {loading ? undefined :
          <CustomForm formRef={this._formRef}
            name="Penalties"
            onFinish={this.submitForm}
            initialValues={{ ...committeeDelinquencyMaster }}
            layout="vertical">
            <Row gutter={24}>
              <Col {...columnSizingProps}>
                <FormItem name="commissionActionTs" label="Board Action">
                  <CustomDatePicker />
                </FormItem>
                <FormItem name="datePaid" label="Date Paid">
                  <CustomDatePicker onChange={(d) => d?.startOf('day')}/>
                </FormItem>
                <FormItem name="amtPaidWaived" label="Amount Paid">
                  <CurrencyInput />
                </FormItem>
              </Col>
              <Col {...columnSizingProps}>
                <FormItem name="waiverRequested" label="Waiver Requested" valuePropName="checked">
                  <Checkbox />
                </FormItem>
                <FormItem name="waived" label="Waived" valuePropName="checked">
                  <Checkbox onChange={this.onWaivedChanged}/>
                </FormItem>
                <FormItem name="reduced" label="Reduced" valuePropName="checked">
                  <Checkbox onChange={this.onReducedChanged}/>
                </FormItem>
                <FormItem name="waiverDenied" label="Waiver Denied" valuePropName="checked">
                  <Checkbox />
                </FormItem>
              </Col>
            </Row>
            <br />
            <Typography.Title level={4}>Penalty Details</Typography.Title>
            <Row gutter={24}>
              <Col {...columnSizingProps}>
                <FormItem name="penaltyAmount" label="Penalty Amount">
                  <CurrencyInput />
                </FormItem>
              </Col>
              <Col>
                {(committeeDelinquencyMaster.penaltyIssuedDate != null) ?
                  <FormItem name="penaltyIssuedDate" label="Penalty Late Notice Issued Date">
                    <Typography.Text>{DateUtil.toTimeLocal(moment(committeeDelinquencyMaster.penaltyIssuedDate))}</Typography.Text>
                  </FormItem> : undefined
                }
              </Col>
            </Row>
            <FormItem name="note" label="Explanation">
              <TextArea rows={3} />
            </FormItem>
            <Row>
              <Space>
                <Col>
                  <Button type="primary" htmlType="submit">Save Penalty</Button>
                </Col>
                <FormItem noStyle shouldUpdate>
                  {({ getFieldValue }) =>
                    getFieldValue('datePaid') === undefined || getFieldValue('datePaid') === null ? (
                      <Button type="primary" loading={penaltyNoticeLoading}
                        onClick={() => this.sendLateNotice(this._formRef.current?.getFieldsValue())}>Save and Send Penalty Notice</Button>
                    ) : undefined}
                </FormItem>
                <Col>
                  <Button onClick={this.cancelForm}>Cancel</Button>
                </Col>
              </Space>
            </Row>
          </CustomForm>
        }
      </Content>
    );
  }

  private submitForm = (values: any) => {
    Dr2ApiService.saveDr2Penalty(
      CurrentUser.Get()?.committeeId || '',
      this.state.vsrId || CurrentUser.Get()?.dr2Id || '',
      CurrentUser.Get()?.dr2Id != undefined,
      { ...this.state.committeeDelinquencyMaster, ...values },
      false)
      .then(() => {
        Dr2ApiService.checkForUnpaidPenalties(CurrentUser.Get()?.committeeId ?? '', CurrentUser.Get()?.committeeType ?? '').then((hasPenalties) => {
          CurrentUser.UpdateHasPenaltie(hasPenalties);
        });
        HistoryUtil.push(Routes.SCHEDULE_SUMMARY);
        notification.success({ message: 'Penalty saved successfully.' });
      })
      .catch(error => {
        notification.error({ message: error.message, description: error.description });
      });
  }

  private sendLateNotice = (values: any) => {
    this.setState({ penaltyNoticeLoading: true });
    Dr2ApiService.saveDr2Penalty(
      CurrentUser.Get()?.committeeId || '',
      this.state.vsrId || CurrentUser.Get()?.dr2Id || '',
      CurrentUser.Get()?.dr2Id != undefined,
      { ...this.state.committeeDelinquencyMaster, ...values },
      true)
      .then((result) => {
        if (result.succeeded) {
          HistoryUtil.push(Routes.SCHEDULE_SUMMARY);
          notification.success({ message: 'Penalty notice sent successfully.' });
        }
        else {
          if (result.errors[0] != null) {
            notification.error({ message: result.errors[0] });
          }
          else {
            notification.error({ message: 'Failed to send penalty notice.' });
          }
        }
        this.setState({ penaltyNoticeLoading: false });
      })
      .catch(error => {
        notification.error({ message: error.message, description: error.description });
        this.setState({ penaltyNoticeLoading: false });
      });
  }

  private cancelForm = () => {
    HistoryUtil.push(Routes.SCHEDULE_SUMMARY);
  }

  private getInitialValues = () => {
    Dr2ApiService.getDr2Penalty(
      CurrentUser.Get()?.committeeId || '',
      this.state.vsrId || CurrentUser.Get()?.dr2Id || '',
      CurrentUser.Get()?.dr2Id != undefined)
      .then(dr2Penalty => {
      // Make date values moments so that the date pickers work properly
      if (dr2Penalty.commissionActionTs) {
        dr2Penalty.commissionActionTs = moment.utc(dr2Penalty.commissionActionTs);
      }
      if (dr2Penalty.datePaid) {
        dr2Penalty.datePaid = moment.utc(dr2Penalty.datePaid);
      }
      if (dr2Penalty.penaltyIssuedDate) {
        dr2Penalty.penaltyIssuedDate = moment.utc(dr2Penalty.penaltyIssuedDate);
      }
      this.setState({ committeeDelinquencyMaster: dr2Penalty, loading: false });
    }).catch(() => {
      this.setState({ loading: false });
    });
  }
}

export default Dr2Penalty; 
