// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
import BaseApi from './BaseApi';
import TableRequestDTO from '../models/TableRequestDTO';
import SchBExpendituresImportFormDTO from '../models/SchBExpendituresImportFormDTO';
import SchABImportBatchNumberDTO from '../models/SchABImportBatchNumberDTO';
import TableResponseDTO from '../models/TableResponseDTO';
import SchBExpendituresImportDTO from '../models/SchBExpendituresImportDTO';

export class SchBExpendituresImportApiService extends BaseApi {

  // post: api/schbexpenditures/import/import?committeeId=${encodeURIComponent(committeeId)}&dr2Id=${encodeURIComponent(dr2Id)}
  public import(request: FormData, committeeId: string, dr2Id: string): Promise<SchABImportBatchNumberDTO> {
    let url = `api/schbexpenditures/import/import`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (committeeId != null) {
      url += `${prefix}committeeId=${encodeURIComponent(committeeId)}`;
      prefix = '&';
    }
    if (dr2Id != null) {
      url += `${prefix}dr2Id=${encodeURIComponent(dr2Id)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, SchABImportBatchNumberDTO>(request, 'post', url, false, false);
  }

  // get: api/schbexpenditures/import/template
  public downloadTemplate(): Promise<void> {
    let url = `api/schbexpenditures/import/template`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, void>(null, 'get', url, true, true);
  }

  // put: api/schbexpenditures/import/importSave?batchNumber=${encodeURIComponent(batchNumber)}&userId=${userId}&committeeId=${encodeURIComponent(committeeId)}&dr2Id=${encodeURIComponent(dr2Id)}
  public saveImportedExpenditures(batchNumber: string, userId: number, committeeId: string, dr2Id: string): Promise<void> {
    let url = `api/schbexpenditures/import/importSave`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (batchNumber != null) {
      url += `${prefix}batchNumber=${encodeURIComponent(batchNumber)}`;
      prefix = '&';
    }
    if (userId != null) {
      url += `${prefix}userId=${userId}`;
      prefix = '&';
    }
    if (committeeId != null) {
      url += `${prefix}committeeId=${encodeURIComponent(committeeId)}`;
      prefix = '&';
    }
    if (dr2Id != null) {
      url += `${prefix}dr2Id=${encodeURIComponent(dr2Id)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, void>(null, 'put', url, true, false);
  }

  // put: api/schbexpenditures/import/sumAmount?batchNumber=${encodeURIComponent(batchNumber)}&userId=${userId}&committeeId=${encodeURIComponent(committeeId)}&dr2Id=${encodeURIComponent(dr2Id)}
  public sumImportedAmount(batchNumber: string, userId: number, committeeId: string, dr2Id: string): Promise<number> {
    let url = `api/schbexpenditures/import/sumAmount`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (batchNumber != null) {
      url += `${prefix}batchNumber=${encodeURIComponent(batchNumber)}`;
      prefix = '&';
    }
    if (userId != null) {
      url += `${prefix}userId=${userId}`;
      prefix = '&';
    }
    if (committeeId != null) {
      url += `${prefix}committeeId=${encodeURIComponent(committeeId)}`;
      prefix = '&';
    }
    if (dr2Id != null) {
      url += `${prefix}dr2Id=${encodeURIComponent(dr2Id)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, number>(null, 'put', url, true, false);
  }

  // post: api/schbexpenditures/import/table?batchNumber=${encodeURIComponent(batchNumber)}&userId=${userId}
  public getStagingExpenditures(tableRequest: TableRequestDTO, batchNumber: string, userId: number): Promise<TableResponseDTO<SchBExpendituresImportDTO>> {
    let url = `api/schbexpenditures/import/table`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (batchNumber != null) {
      url += `${prefix}batchNumber=${encodeURIComponent(batchNumber)}`;
      prefix = '&';
    }
    if (userId != null) {
      url += `${prefix}userId=${userId}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, TableResponseDTO<SchBExpendituresImportDTO>>(tableRequest, 'post', url, true, false);
  }

  // get: api/schbexpenditures/import?batchNumber=${encodeURIComponent(batchNumber)}&transNumber=${transNumber}&userId=${userId}
  public getStagingExpenditure(batchNumber: string, transNumber: number, userId: number): Promise<SchBExpendituresImportFormDTO> {
    let url = `api/schbexpenditures/import`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (batchNumber != null) {
      url += `${prefix}batchNumber=${encodeURIComponent(batchNumber)}`;
      prefix = '&';
    }
    if (transNumber != null) {
      url += `${prefix}transNumber=${transNumber}`;
      prefix = '&';
    }
    if (userId != null) {
      url += `${prefix}userId=${userId}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, SchBExpendituresImportFormDTO>(null, 'get', url, true, false);
  }

  // put: api/schbexpenditures/import?contactType=${encodeURIComponent(contactType)}&committeeId=${encodeURIComponent(committeeId)}&batchNumber=${encodeURIComponent(batchNumber)}&transNumber=${transNumber}&userId=${userId}
  public saveStagingExpenditure(stagingContr: SchBExpendituresImportFormDTO, contactType: string, committeeId: string, batchNumber: string, transNumber: number, userId: number): Promise<void> {
    let url = `api/schbexpenditures/import`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (contactType != null) {
      url += `${prefix}contactType=${encodeURIComponent(contactType)}`;
      prefix = '&';
    }
    if (committeeId != null) {
      url += `${prefix}committeeId=${encodeURIComponent(committeeId)}`;
      prefix = '&';
    }
    if (batchNumber != null) {
      url += `${prefix}batchNumber=${encodeURIComponent(batchNumber)}`;
      prefix = '&';
    }
    if (transNumber != null) {
      url += `${prefix}transNumber=${transNumber}`;
      prefix = '&';
    }
    if (userId != null) {
      url += `${prefix}userId=${userId}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, void>(stagingContr, 'put', url, true, false);
  }

  // delete: api/schbexpenditures/import?batchNumber=${encodeURIComponent(batchNumber)}&transNumber=${transNumber}&userId=${userId}
  public deleteStagingExpenditure(batchNumber: string, transNumber: number, userId: number): Promise<void> {
    let url = `api/schbexpenditures/import`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (batchNumber != null) {
      url += `${prefix}batchNumber=${encodeURIComponent(batchNumber)}`;
      prefix = '&';
    }
    if (transNumber != null) {
      url += `${prefix}transNumber=${transNumber}`;
      prefix = '&';
    }
    if (userId != null) {
      url += `${prefix}userId=${userId}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, void>(null, 'delete', url, true, false);
  }

  // delete: api/schbexpenditures/import/${userId}
  public deleteStagingExpendituresByUserId(userId: number): Promise<void> {
    let url = `api/schbexpenditures/import/${userId}`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, void>(null, 'delete', url, true, false);
  }

  // get: api/schbexpenditures/import/hasErrors?batchNumber=${encodeURIComponent(batchNumber)}&userId=${userId}
  public stagingExpenditureTableHasErrors(batchNumber: string, userId: number): Promise<boolean> {
    let url = `api/schbexpenditures/import/hasErrors`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (batchNumber != null) {
      url += `${prefix}batchNumber=${encodeURIComponent(batchNumber)}`;
      prefix = '&';
    }
    if (userId != null) {
      url += `${prefix}userId=${userId}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, boolean>(null, 'get', url, true, false);
  }
}
var service = new SchBExpendituresImportApiService();
export default service;
