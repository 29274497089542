import {
  FormInstance,
  ColProps,
  Col,
  FormProps
} from 'antd';
import * as React from 'react';
import AddressFormFields from '../shared/AddressFormFields';
import PersonInfoDTO from '../../models/PersonInfoDTO';
import CustomForm from '../shared/CustomForm';
import RoleType from '../../consts/RoleType';

interface Dr1RoleProps extends FormProps {
  personInfo?: PersonInfoDTO;
  disabled?: boolean;
  dr1Role?: string;
  roleName?: string;
}
interface Dr1RoleState {

}

class Dr1Role extends React.Component<Dr1RoleProps & FormProps, Dr1RoleState>{
  private readonly _formRef = React.createRef<FormInstance>();

  constructor(props: Dr1RoleProps & FormProps) {
    super(props);
  }

  validate = async (): Promise<{ model: PersonInfoDTO, errorFields: any }> => {
    try {
      const fields = await this._formRef.current?.validateFields();
      return { model: fields, errorFields: null };
    }
    catch (errorInfo) {
      return Promise.reject({ model: errorInfo.values, errorFields: errorInfo.errorFields });
    }
  }

  getFormValues = async (): Promise<{ model: PersonInfoDTO }> => {
    const fields = await this._formRef.current?.getFieldsValue();
    return { model: fields };
  }

  checkEmptyFields = (): boolean => {
    const model = this._formRef.current?.getFieldsValue();
    if (!model.addressLine1 && !model.addressLine2 && !model.city &&
      !model.email && !model.name && !model.phone &&
      !model.zipCode) {
      return true;
    }
    return false;
  }

  render() {
    const { personInfo, disabled, dr1Role, roleName, ...formProps } = this.props;
    const columnSizingProps: ColProps = { xs: 24, sm: 24, md: 12 };

    let emailReq = false;
    if (dr1Role === RoleType.COMMITTEE_CHAIRPERSON || dr1Role === RoleType.TREASURER || dr1Role === RoleType.CANDIDATE) {
      emailReq = true;
    }

    return (
      <CustomForm {...formProps} layout="vertical" initialValues={personInfo} formRef={this._formRef}>
        <Col {...columnSizingProps}>
            <AddressFormFields
            firstName={{ name: 'firstName', required: true }}
            middleInitial={{ name: 'middleInitial', required: false }}
            lastName={{ name: 'lastName', required: true }}
            address1={{ name: 'addressLine1', required: true }}
            address2={{ name: 'addressLine2', required: false }}
            city={{ name: 'city', required: true }}
            state={{ name: 'state', required: true }}
            zip={{ name: 'zipCode', required: true }}
            email={{ name: 'email', required: emailReq }}
            phone={{ name: 'phoneNumber', required: true }}
            allDisabled={disabled}
            ariaPrefix={roleName} />
            </Col>
          </CustomForm>
    );
  }
}
export default Dr1Role;