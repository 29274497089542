import { Dispatch } from 'redux';
import AccountApiService from '../../api/AccountApiService';
import Routes from '../../consts/Routes';
import LoginDTO from '../../models/LoginDTO';
import LoginResultDTO from '../../models/LoginResultDTO';
import UserSecurity from '../../models/UserSecurity';
import UserSecurityDTO from '../../models/UserSecurityDTO';
import { StateStoreModel } from '../../redux/state/StateStoreModel';
import History from '../../utils/HistoryUtil';
import BaseAction from './BaseAction';

export type UserSessionAction = BaseAction<UserSessionActionTypes, any>;

export enum UserSessionActionTypes {
  USER_SESSION_CHECKING = 'USER_SESSION_CHECKING',
  USER_SESSION_CHECK = 'USER_SESSION_CHECK',
  USER_SESSION_LOADING = 'USER_SESSION_LOADING',
  USER_SESSION_LOGIN = 'USER_SESSION_LOGIN',
  USER_SESSION_LOGIN_ERROR = 'USER_SESSION_LOGIN_ERROR',
  USER_SESSION_LOGOUT = 'USER_SESSION_LOGOUT',
  USER_SESSION_CHANGED = 'USER_SESSION_CHANGED',
}

export const UserSessionActions = {
  login: UserSessionLoginAction,
  logout: UserSessionLogoutAction,
  check: UserSessionCheckAction,
  refresh: UserSessionRefreshAction,
  update: UserSessionChangedAction,
};

function UserSessionLogoutAction() {
  return (dispatch: Dispatch<any>) => {
    AccountApiService.logout()
      .then(() => {
        dispatch(UserSessionChangedAction(null));
        History.push(Routes.LOGIN);
      })
      .catch(() => {
        dispatch(UserSessionLoadingAction(false));
        History.push(Routes.LOGIN);
      });
  };
}

function UserSessionLoginAction(model: LoginDTO) {
  return (dispatch: Dispatch<any>) => {
    dispatch(UserSessionLoadingAction(true));
    AccountApiService.login(model)
      .then((response: LoginResultDTO) => {
        if (response.succeeded) {
          _UserSessionCheckAction(dispatch, true);
        } else {
          if (response.isLockedOut) {
            dispatch(UserSessionLoginErrorAction({ message: 'User locked.' }));
          } else if (response.isNotAllowed) {
            dispatch(
              UserSessionLoginErrorAction({
                message: 'Login not allowed for user.',
              })
            );
          } else if (response.requiresTwoFactor) {
            dispatch(
              UserSessionLoginErrorAction({
                message: 'Requires two factor authentication.',
              })
            );
          } else {
            dispatch(UserSessionLoginErrorAction({ message: 'Invalid credentials.' }));
          }
          dispatch(UserSessionLoadingAction(false));
        }
      })
      .catch((results: any) => {
        dispatch(UserSessionLoginErrorAction(results));
        dispatch(UserSessionLoadingAction(false));
      })
      .then(() => {
        // .finally()
      });
  };
}

function UserSessionCheckAction() {
  return (dispatch: Dispatch<any>, getState: () => StateStoreModel) => {
    const current = getState().UserSession;
    if (current.Value) {
      dispatch(UserSessionChangedAction(current.Value));
      return;
    }
    dispatch(UserSessionCheckingAction(true));
    _UserSessionCheckAction(dispatch, false);
  };
}

function _UserSessionCheckAction(dispatch: Dispatch<any>, isLoading: boolean) {
  const userAction = isLoading ? UserSessionLoadingAction : UserSessionCheckingAction;
  AccountApiService.getCurrentUser()
    .then((results: UserSecurityDTO | null) => {
      if (results) {
        dispatch(UserSessionChangedAction(new UserSecurity(results)));
      } else {
        dispatch(UserSessionChangedAction(null));
      }
      dispatch(userAction(false));
    })
    .catch(() => {
      dispatch(userAction(false));
    });
}

function UserSessionRefreshAction(newUserSession: UserSecurityDTO | null) {
  return (dispatch: Dispatch<any>) => {
    // Start session loading
    if (newUserSession) {
      dispatch(UserSessionChangedAction(new UserSecurity(newUserSession)));
    } else {
      dispatch(UserSessionChangedAction(null));
    }
  };
}

function UserSessionLoginErrorAction(error: any): UserSessionAction {
  return {
    type: UserSessionActionTypes.USER_SESSION_LOGIN_ERROR,
    data: error,
  };
}

function UserSessionCheckingAction(isChecking: boolean): UserSessionAction {
  return {
    type: UserSessionActionTypes.USER_SESSION_CHECKING,
    data: isChecking,
  };
}

function UserSessionLoadingAction(isLoading: boolean): UserSessionAction {
  return {
    type: UserSessionActionTypes.USER_SESSION_LOADING,
    data: isLoading,
  };
}

function UserSessionChangedAction(model?: UserSecurity | null | undefined): UserSessionAction {
  return {
    type: UserSessionActionTypes.USER_SESSION_CHANGED,
    data: model,
  };
}
