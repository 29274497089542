import { LoadingOutlined } from '@ant-design/icons';
import { FormInstance } from 'antd/lib/form';
import FormItem from 'antd/lib/form/FormItem';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import AccountApiService from '../api/AccountApiService';
import LookupsApiService from '../api/LookupsApiService';
import ResultDTO from '../models/ResultDTO';
import UserSecurityDTO from '../models/UserSecurityDTO';
import { StateStoreModel } from '../redux/state/StateStoreModel';
import PageTitle from './shared/PageTitle';
import { Alert, Button, Col, Descriptions, Input, Layout, Row } from 'antd';
import CustomForm from './shared/CustomForm';

const Content = Layout.Content;

interface ProfileState {
  roleName: string | null;
  isChangingPassword: boolean;
  error: any;
  loading: boolean;
  successMessage: string | null;
}

interface ProfileProps {
  userName: string | null;
}

class ProfilePage extends React.Component<ProfileProps & RouteComponentProps<RouteObject>, ProfileState> {
  private readonly _formRef = React.createRef<FormInstance>();

  constructor(props: ProfileProps & RouteComponentProps<RouteObject>) {
    super(props);
    this.getRoleName = this.getRoleName.bind(this);
    this.showPasswordChangeForm = this.showPasswordChangeForm.bind(this);
    this.changePassword = this.changePassword.bind(this);

    this.state = {
      roleName: null,
      isChangingPassword: false,
      error: null,
      loading: false,
      successMessage: null,
    };
  }

  componentDidMount() {
    AccountApiService.getCurrentUser().then((results: UserSecurityDTO | null) => {
      if (results) {
        this.getRoleName(results.roles[0]);
      }
    });
  }

  //This function will be moved/updated eventually
  getRoleName(roleId: number) {
    const rolePromise = LookupsApiService.getRoles().then((result) => result);

    const updateRole = async () => {
      const roleList = await rolePromise;
      if (roleList !== null && roleList !== undefined) {
        roleList.forEach((r) => {
          if (r.id == roleId) {
            this.setState({ roleName: r.name });
          }
        });
      }
    };

    updateRole();
  }

  showPasswordChangeForm() {
    this.setState({ isChangingPassword: true });
  }

  validatePassword = (e: any) => {
    if (e.target.value !== null && e.target.value !== '') {
      AccountApiService.validatePassword(e.target.value).then((response: ResultDTO | null) => {
        if (response?.succeeded) {
          this.setState({ error: null });
        } else {
          this.setState({ error: { message: response?.errors.join('\n') } });
        }
      });
    }
  };

  changePassword() {
    this.setState({ loading: true });

    const model = this._formRef ? (this._formRef.current as any).getFieldsValue() : null;
    model.requestedRoleId = 0;
    model.requestUrl = null;

    AccountApiService.changePassword(model)
      .then((response: ResultDTO | null) => {
        if (response?.succeeded) {
          this.setState({
            loading: false,
            error: null,
            isChangingPassword: false,
            successMessage: 'Password updated successfully!',
          });
        } else {
          this.setState({
            loading: false,
            error: { message: response?.errors.join('\n') },
          });
        }
      })
      .catch((results: any) => {
        this.setState({
          loading: false,
          error: { message: results?.errors.join('\n') },
        });
      });
  }

  render() {
    let alert: JSX.Element | null = null;
    const { error, successMessage } = this.state;
    if (error !== null) {
      let message = 'An error occurred while trying to register new user.';
      if (error.message) {
        message = error.message;
      }
      alert = (
        <Alert
          message="Error"
          description={message}
          type="error"
          showIcon={true}
          style={{ marginBottom: '12px', whiteSpace: 'pre-wrap' }}
        />
      );
    }
    if (successMessage) {
      alert = (
        <Alert
          message="Success"
          description={successMessage}
          type="success"
          showIcon={true}
          style={{ marginBottom: '12px', whiteSpace: 'pre-wrap' }}
        />
      );
    }

    const emptyCol = { xs: 0, sm: 3, md: 3 };
    const wrapperCol = { xs: 24, sm: 18, md: 18 };
    const wrapperColNoLabel = {};
    Object.keys(wrapperCol).forEach((val) => {
      wrapperColNoLabel[val] = {
        span: wrapperCol[val],
        offset: wrapperCol[val] + emptyCol[val] > 24 ? 0 : emptyCol[val],
      };
    });

    return (
      // type="flex"
      <Content className="content-background-login">
        <Row justify="center" align="middle">
          <Col className="flex-grow" />
          <Col xxl={6} xl={8} lg={10} md={14} sm={18} xs={24}>
            <PageTitle
              title="Edit Profile"
              style={{
                whiteSpace: 'pre-wrap',
                marginBottom: '24px',
                textAlign: 'center',
              }}
            />
            {alert}
            <Descriptions layout="horizontal" style={{ marginBottom: '24px' }}>
              <Descriptions.Item label="Email Address" style={{ display: 'block' }} labelStyle={{ fontWeight: 'bold' }}>
                {this.props.userName}
              </Descriptions.Item>
              <Descriptions.Item label="Role" style={{ display: 'block' }} labelStyle={{ fontWeight: 'bold' }}>
                {this.state.roleName}
              </Descriptions.Item>
            </Descriptions>
            {this.state.isChangingPassword ? (
              <CustomForm formRef={this._formRef} layout="vertical" onFinish={this.changePassword} requiredMark={false}>
                <FormItem
                  name="oldPassword"
                  label="Current Password"
                  rules={[{ required: true, message: 'Current Password is required' }]}
                  labelCol={wrapperColNoLabel}
                  wrapperCol={wrapperColNoLabel}
                >
                  <Input.Password />
                </FormItem>
                <FormItem
                  name="newPassword"
                  label="New Password"
                  rules={[{ required: true, message: 'New Password is required' }]}
                  labelCol={wrapperColNoLabel}
                  wrapperCol={wrapperColNoLabel}
                >
                  <Input.Password onBlur={this.validatePassword} />
                </FormItem>
                <FormItem
                  name="confirmPassword"
                  label="Confirm New Password"
                  rules={[
                    {
                      required: true,
                      message: 'Confirm password is required',
                    },
                    ({ getFieldValue }) => ({
                      validator(rule: any, value: string | null) {
                        if (!value || getFieldValue('newPassword') === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject('The two new passwords that you entered do not match');
                      },
                    }),
                  ]}
                  labelCol={wrapperColNoLabel}
                  wrapperCol={wrapperColNoLabel}
                >
                  <Input.Password />
                </FormItem>
                <FormItem wrapperCol={wrapperColNoLabel}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    size="large"
                    disabled={this.state.loading}
                    shape="round"
                    onClick={this.changePassword}
                    block
                  >
                    {this.state.loading ? (
                      <span>
                        <LoadingOutlined /> Loading...
                      </span>
                    ) : (
                      <span>Change Password</span>
                    )}
                  </Button>
                </FormItem>
              </CustomForm>
            ) : (
              <Button
                htmlType="button"
                size="large"
                disabled={this.state.loading}
                shape="round"
                onClick={this.showPasswordChangeForm}
                block
              >
                Change Password
              </Button>
            )}
          </Col>
          <Col className="flex-grow" />
        </Row>
      </Content>
    );
  }
}

function mapStateToProps(state: StateStoreModel) {
  let userName: string | null = null;
  if (state.UserSession.Value !== null) {
    userName = state.UserSession.Value.userName;
  }
  return {
    loading: state.UserSession.Loading,
    error: state.UserSession.Error,
    userName: userName,
  };
}

export default withRouter(connect(mapStateToProps)(ProfilePage));
