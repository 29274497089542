import { notification } from 'antd';
import { FormInstance } from 'antd/lib/form/Form';
import * as React from 'react';
import BuildingFundApiService from '../../api/BuildingFundApiService';
import AdjustBalanceForm from '../shared/AdjustBalanceForm';

interface AdjustBuildingFundBalanceProps {
    close: () => void;
    buildingFundId: number;
}

interface AdjustBuildingFundBalanceState {
    beginningAmount: number;
}

class AdjustBuildingFundBalance extends React.Component<AdjustBuildingFundBalanceProps, AdjustBuildingFundBalanceState> {
    private readonly _formRef = React.createRef<FormInstance>();
    constructor(props: AdjustBuildingFundBalanceProps) {
        super(props);
        this.state = {
            beginningAmount: 0
        };
    }

    componentDidMount() {
        this.getBeginningAmount();
    }

    render() {
        const { beginningAmount } = this.state;
        return (
            <AdjustBalanceForm formRef={this._formRef} startingBalance={beginningAmount} onSave={this.handleSave} onClose={this.props.close} />
        );
    }
    private getBeginningAmount = () => {
        BuildingFundApiService.getBuildingFundStartingBalance(this.props.buildingFundId)
            .then(balance => {
                this.setState({ beginningAmount: balance });
                this._formRef.current?.setFieldsValue({ startingBalance: balance });
            });
    }

    private handleSave = () => {
        this._formRef.current?.validateFields()
            .then(values => {
                BuildingFundApiService.adjustBuildingFundStartingBalance(values.startingBalance, this.props.buildingFundId)
                    .then(result => {
                        if (result.succeeded) {
                            notification.success({
                                message: 'Saved successfully.'
                            });
                            this.props.close();
                        }
                        else {
                            notification.error({
                                message: result.errors[0].toString()
                            });
                        }
                    });
            });
    }
}

export default AdjustBuildingFundBalance;