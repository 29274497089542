import {
  Button,
  Layout,
  notification,
  Space,
  Typography,
} from 'antd';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import DataTable, { DataTableColumnProps } from '../../shared/DataTable/DataTable';
import DataTableButtonUtil from '../../shared/DataTable/DataTableButtonUtil';
import CountyReportsDTO from '../../../models/CountyReportsDTO';
import DataTableColumnUtil from '../../shared/DataTable/DataTableColumnUtil';
import CommitteeCategoryTypeNames from '../../../consts/CommitteeCategoryTypeNames';
import PublicReportsApiService from '../../../api/PublicReportsApiService';
import ReportTypeDesc from '../../../consts/ReportTypeDescriptions';
import HistoryUtil from '../../../utils/HistoryUtil';
import Routes from '../../../consts/Routes';
import { ArrowLeftOutlined } from '@ant-design/icons';
import DataTableCellRendererUtil from '../../shared/DataTable/DataTableCellRendererUtil';
import CodeLookupTableDTO from '../../../models/CodeLookupTableDTO';
import LookupsApiService from '../../../api/LookupsApiService';
import moment from 'moment';
import TableRequestDTO from '../../../models/TableRequestDTO';
import TableResponseDTO from '../../../models/TableResponseDTO';
import FilingPeriodColumnLookupDTO from '../../../models/FilingPeriodColumnLookupDTO';
import { isNullOrUndefined } from 'util';


const { Content } = Layout;

interface CountyReportsProps {

}

interface CountyReportsState {
  counties: CodeLookupTableDTO[];
  filterFilingPeriodDescs: FilingPeriodColumnLookupDTO[];
  year: string;
  loading: boolean;
}

class CountyReports extends React.Component<RouteComponentProps<CountyReportsProps>, CountyReportsState> {

  constructor(props: RouteComponentProps<CountyReportsProps>) {
    super(props);

    this.state = {
      counties: [],
      filterFilingPeriodDescs: [],
      year: '0',
      loading: false
    };
  }

  private _countyReportsDataTable: DataTable<CountyReportsDTO> | null = null;

  componentDidMount() {
    this.fetchCounties();
    this.fetchFilingPeriods(this.state.year, moment().year());
  }

  render() {
    const actionButtons = [];
    actionButtons.push(DataTableButtonUtil.Reset());
    return (
      <Content className="content-pad">
        <DataTable
          ref={(element: any) => (this._countyReportsDataTable = element)}
          globalSearch={true}
          serverSide={true}
          buttonBar={actionButtons}
          columns={this.getTableColumns()}
          tableProps={{
            rowKey: 'id',
            sortDirections: ['ascend', 'descend'],
            locale: { emptyText: 'No Reports Currently Match Your Filter' },
            loading: this.state.loading
          }}
          fetchData={this.fetchCountyReports}
          styleOptions={{ compact: true, alternatingRowHighlight: true }}
          title={
            <Button type="link" onClick={() => HistoryUtil.push(Routes.generate(Routes.PUBLIC_REPORTS))}>
              <Typography.Title level={4}>
                <Space size="large">
                  <ArrowLeftOutlined className="public-report-links" /> County and Local Reports
                </Space>
              </Typography.Title>
            </Button>
          }
        />
      </Content>
    );
  }

  private getTableColumns = (): DataTableColumnProps<CountyReportsDTO>[] => {
    const generatedOn = DataTableColumnUtil.Date<CountyReportsDTO>('Filed Date', 'filedDate');
    generatedOn.render = DataTableCellRendererUtil.DateAndTime;
    const treeFilingPeriods = new Map<string, any>();
    const filterFilingPeriods: any = [];

    this.state.filterFilingPeriodDescs.forEach((p) => {
      if (this.state.year == '0') {
        if (treeFilingPeriods.has(p.year || '')) {
          const column = treeFilingPeriods.get(p.year || '');
          column.children.push({ title: p.periodDescription || '', key: p.code || '' });
          treeFilingPeriods.set(p.year || '', column);
        }
        else {
          treeFilingPeriods.set(
            p.year || '',
            { title: p.year, key: p.year || '', children: [{ title: p.periodDescription || '', key: p.code || '' }] });
        }
      }
      else {
        filterFilingPeriods.push({ text: p.periodDescription || '', value: p.code || '' });
      }
    });

    const filingDescriptionFilters = (this.state.year == '0' ? Array.from(treeFilingPeriods.values()) : filterFilingPeriods);
    const filterMode = (this.state.year == '0' ? { filterMode: 'tree' } : undefined);

    return [
      DataTableColumnUtil.Text('Year', 'year', null, { defaultSortOrder: 'descend' }),
      DataTableColumnUtil.DropdownMulti(
        'Filing Period',
        'periodDescription',
        filingDescriptionFilters,
        null,
        filterMode as any
      ),
      DataTableColumnUtil.DropdownMulti('Report Type', 'reportType',
        [
          { text: ReportTypeDesc.DR2, value: ReportTypeDesc.DR2 },
        ]),
      DataTableColumnUtil.DropdownMulti('County', 'county', (this.state.counties || []).map(c => ({ text: c.name || '', value: c.code || '' }))),
      DataTableColumnUtil.DropdownMulti('Committee Type', 'categoryType',
        [
          { text: CommitteeCategoryTypeNames.CITYCANDIDATE, value: CommitteeCategoryTypeNames.CITYCANDIDATE },
          { text: CommitteeCategoryTypeNames.CITYPAC, value: CommitteeCategoryTypeNames.CITYPAC },
          { text: CommitteeCategoryTypeNames.COUNTYCANDIDATE, value: CommitteeCategoryTypeNames.COUNTYCANDIDATE },
          { text: CommitteeCategoryTypeNames.COUNTYPAC, value: CommitteeCategoryTypeNames.COUNTYPAC },
          { text: CommitteeCategoryTypeNames.COUNTYPARTY, value: CommitteeCategoryTypeNames.COUNTYPARTY },
          { text: CommitteeCategoryTypeNames.LOCALBALLOT, value: CommitteeCategoryTypeNames.LOCALBALLOT },
          { text: CommitteeCategoryTypeNames.OTHERCANDIDATE, value: CommitteeCategoryTypeNames.OTHERCANDIDATE },
          { text: CommitteeCategoryTypeNames.OTHERPAC, value: CommitteeCategoryTypeNames.OTHERPAC },
          { text: CommitteeCategoryTypeNames.SCHOOLBOARD, value: CommitteeCategoryTypeNames.SCHOOLBOARD }
        ]),
      DataTableColumnUtil.Text('Committee Code', 'committeeCode'),
      DataTableColumnUtil.Text('Committee', 'committeeName'),
      generatedOn,
      DataTableColumnUtil.Buttons('fileName',
        [
          { text: 'View', onClick: (rowData) => window.open(rowData.fileUrl || '') },
        ])
    ];
  }

  private fetchCounties = () => {
    LookupsApiService.getCounties()
      .then((counties) => {
        this.setState({ counties });
      })
      .catch((error: any) => {
        notification.error({
          message: error.message,
          description: error.description,
        });
      });
  }

  public fetchFilingPeriods = (year: string, startYear: any) => {
    PublicReportsApiService.getFilingPeriodsByYear(year, startYear.toString(), false)
      .then((filterFilingPeriodDescs) => {
        this.setState({ filterFilingPeriodDescs });
      })
      .catch((error: any) => {
        notification.error({
          message: error.message,
          description: error.description,
        });
      });
  }

  private fetchCountyReports = (
    requestState: TableRequestDTO,
    checkEcho: () => boolean,
    callback: (response: TableResponseDTO<CountyReportsDTO>) => void
  ) => {
    this.setState({ loading: true });
    let currFilters = [...this._countyReportsDataTable?.state.filters || []];
    let yearFilter = currFilters.find(f => f.columnProp == 'year')?.filter || ''; 
    let currSorters = { ...this._countyReportsDataTable?.state.sorter } || {};

    if (yearFilter == '') {
      yearFilter = '0';
    }

    if (!isNullOrUndefined(yearFilter)) {
      const columnState = [...this._countyReportsDataTable?.state.columnStates || []];
  
      if (yearFilter != this.state.year) {
        currFilters = currFilters?.filter(f => f.columnProp != 'periodDescription');
        columnState.forEach(c => {
          if (c.columnProp == 'periodDescription') {
            c.filtered = false;
            c.searchText = '';
          }
        });
        if (currSorters.columnKey == 'periodDescription') {
          currSorters = {};
        }
      }

      this._countyReportsDataTable?.setState({ filters: currFilters, columnStates: columnState, });
    }

    const newRequestState = { ...requestState };

    if (currFilters.length > 0) {
      newRequestState.filters = [...currFilters];
    }

    return PublicReportsApiService.getCountyReports(newRequestState)
      .then((res) => {
        if (!checkEcho()) {
          return;
          }

        res.results?.forEach(r => {
            if (r.filedDate) {
                r.filedDate = moment.utc(r.filedDate).local();
            }
        });
        callback(res);
        this.fetchFilingPeriods(yearFilter, 2000);
        this.setState({ loading: false, year: yearFilter });
      });
  };
}

export default CountyReports;