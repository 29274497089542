// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';

interface SchABImportFileDTO { 
  file: any | null;
}
const SchABImportFileDTO = {
  create: (initValues?: Partial<SchABImportFileDTO> | Record<string, unknown> | null) => {
    return Object.assign(
    {
      file: null,
    },
    initValues);
  }
};

export default SchABImportFileDTO;