// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';

interface SchBExpendituresFormDTO { 
  payeeType: string | null;
  payeeName: string | null;
  firstName: string | null;
  middleInitial: string | null;
  lastName: string | null;
  payeeAutoFillId: string | null;
  date: moment.Moment | string | null;
  expenseType: string | null;
  numberOfMiles: number | null;
  mileageRate: number | null;
  amount: number;
  amountRemaining: number | null;
  checkNumber: string | null;
  explanation: string | null;
  addressLine1: string | null;
  addressLine2: string | null;
  city: string | null;
  state: string | null;
  zip: string | null;
  phoneNumber: string | null;
  relationshipCode: string | null;
  isEarmarked: boolean;
  earmarkedContributionId: string | null;
  isLivingOrRevocableTrust: boolean;
  trustee: string | null;
  trustor: string | null;
  auditorsNoteId: string | null;
}
const SchBExpendituresFormDTO = {
  create: (initValues?: Partial<SchBExpendituresFormDTO> | Record<string, unknown> | null) => {
    return Object.assign(
    {
      payeeType: null,
      payeeName: null,
      firstName: null,
      middleInitial: null,
      lastName: null,
      payeeAutoFillId: null,
      date: null,
      expenseType: null,
      numberOfMiles: null,
      mileageRate: null,
      amount: 0,
      amountRemaining: null,
      checkNumber: null,
      explanation: null,
      addressLine1: null,
      addressLine2: null,
      city: null,
      state: null,
      zip: null,
      phoneNumber: null,
      relationshipCode: null,
      isEarmarked: false,
      earmarkedContributionId: null,
      isLivingOrRevocableTrust: false,
      trustee: null,
      trustor: null,
      auditorsNoteId: null,
    },
    initValues);
  }
};

export default SchBExpendituresFormDTO;