import { LoadingOutlined } from '@ant-design/icons';
import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  Input,
  Layout,
  Row
  } from 'antd';
import { FormInstance } from 'antd/lib/form';
import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import AccountApiService from '../../api/AccountApiService';
import CustomForm from '../shared/CustomForm';
import PageTitle from '../shared/PageTitle';

const FormItem = Form.Item;
const Content = Layout.Content;

interface RequestResetPasswordState {
  error: any;
  resetSuccess: boolean;
  loading: boolean;
}

interface RequestResetPasswordProps { }

class RequestResetPasswordPage extends React.Component<
  RequestResetPasswordProps & RouteComponentProps<RouteObject>,
  RequestResetPasswordState
> {
  private readonly _formRef = React.createRef<FormInstance>();

  constructor(props: RequestResetPasswordProps & RouteComponentProps<RouteObject>) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      error: null,
      resetSuccess: false,
      loading: false,
    };
  }

  handleSubmit() {
    this.setState({ loading: true });

    const model = this._formRef ? (this._formRef.current as any).getFieldsValue() : null;

    if (model.emailAddress && /@claim-doc.com\s*$/.test(model.emailAddress)) {
      if (typeof window !== undefined) {
        let url = 'https://passwordreset.microsoftonline.com/';
        const prefix = url.indexOf('?') > 0 ? '&' : '?';
        url += `${prefix}userName=${encodeURIComponent(model.emailAddress)}`;

        window.location.href = url;
      }
    } else {
      AccountApiService.forgotPassword(model.username, model.emailAddress).then(
        () => {
          this.setState({ loading: false, resetSuccess: true, error: null });
        },
        (error) => {
          this.setState({ loading: false, error: error, resetSuccess: false });
        }
      );
    }
  }

  render() {
    let alert: JSX.Element | null = null;
    const { error, loading, resetSuccess } = this.state;
    if (error !== null) {
      let message = 'An error occurred while trying to send the reset password email.';
      if (error.message) {
        message = error.message;
      }
      alert = (
        <Alert
          message="Error"
          description={message}
          type="error"
          showIcon={true}
          style={{ marginBottom: '12px', whiteSpace: 'pre-wrap' }}
        />
      );
    }
    if (resetSuccess) {
      const message = 'An email with a link to reset your password was sent to the email you provided.';
      alert = (
        <Alert
          message="Success"
          description={message}
          type="success"
          showIcon={true}
          style={{ marginBottom: '12px' }}
        />
      );
    }

    const emptyCol = { xs: 0, sm: 3, md: 3 };
    const wrapperCol = { xs: 24, sm: 18, md: 18 };
    const wrapperColNoLabel = {};
    Object.keys(wrapperCol).forEach((val) => {
      wrapperColNoLabel[val] = {
        span: wrapperCol[val],
        offset: wrapperCol[val] + emptyCol[val] > 24 ? 0 : emptyCol[val],
      };
    });

    return (
      // type="flex"
      <Content className="content-background-login">
        <Row justify="space-between">
          <Col className="flex-grow" />
          <Col xxl={6} xl={8} lg={10} md={14} sm={18} xs={24}>
            <Card>
              <img src={process.env.PUBLIC_URL + '/iecdb-logo.svg'} alt="IECDB.Portal" className="full-logo" />
              <span style={{ textAlign: 'center', marginBottom: '24px' }}>
                <PageTitle title="Reset Password" />
                <p>Please enter the username and email associated with your account.</p>
              </span>
              {alert}
              <CustomForm formRef={this._formRef} layout="vertical" onFinish={this.handleSubmit} requiredMark={false}>
                <FormItem
                  name="username"
                  label="Username"
                  rules={[{ required: true, message: 'Username is required' }]}
                  labelCol={wrapperColNoLabel}
                  wrapperCol={wrapperColNoLabel}
                >
                  <Input placeholder="Username" />
                </FormItem>
                <FormItem
                  name="emailAddress"
                  label="Email Address"
                  rules={[{ required: true, message: 'Email is required' }]}
                  labelCol={wrapperColNoLabel}
                  wrapperCol={wrapperColNoLabel}
                >
                  <Input placeholder="Email" />
                </FormItem>
                <FormItem wrapperCol={wrapperColNoLabel}>
                  <Button type="primary" htmlType="submit" size="large" disabled={loading} shape="round" block>
                    {loading ? (
                      <span>
                        <LoadingOutlined /> Processing...
                      </span>
                    ) : (
                      <span>Reset Password</span>
                    )}
                  </Button>
                </FormItem>
              </CustomForm>
            </Card>
          </Col>
          <Col className="flex-grow" />
        </Row>
      </Content>
    );
  }
}

export default withRouter(RequestResetPasswordPage);
