import {
  Button,
  Col,
  ColProps,
  Input,
  Layout,
  notification,
  Row,
  Space
} from 'antd';
import { FormInstance, FormProps } from 'antd/lib/form/Form';
import FormItem from 'antd/lib/form/FormItem';
import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import AdminInternalNoteApiService from '../../api/AdminInternalNoteApiService';
import CurrentUser from '../../utils/CurrentUser';
import CustomForm from '../shared/CustomForm';

const { Content } = Layout;
const { TextArea } = Input;

interface InternalNotesProps {
}

interface InternalNotesState {
  noteEntry: string,
  loading: boolean,
}

class InternalNotes extends React.Component<RouteComponentProps<InternalNotesProps> & FormProps, InternalNotesState> {
  private readonly _formRef = React.createRef<FormInstance>();
  constructor(props: RouteComponentProps<InternalNotesProps> & FormProps) {
    super(props);
    this.state = {
      noteEntry: '',
      loading: true,
    };
  }

  componentDidMount() {
    this.getInitialValues();
  }

  render() {
    const columnSizingProps: ColProps = { xs: 24, sm: 24, md: 12 };
    const { loading, noteEntry } = this.state;

    return (
      <Content className="content-pad">
        <h3 className="ant-typography">Internal Notes</h3>
        {!loading &&
          <CustomForm formRef={this._formRef}
            name="InternalNotes"
            onFinish={this.submitForm}
            initialValues={{ noteTxt: noteEntry }}
            layout="vertical">

            <Row gutter={24}>
              <Col {...columnSizingProps}>
                <FormItem name="noteTxt" label="Notes">
                  <TextArea autoSize={true} style={{ minHeight: 100 }}/>
                </FormItem>
              </Col>
            </Row>
            <Row>
              <Space>
                <Col>
                  <Button type="primary" htmlType="submit">Save</Button>
                </Col>
                <Col>
                  <Button onClick={this.cancelForm}>Cancel</Button>
                </Col>
              </Space>
            </Row>
          </CustomForm>
        }
      </Content>
    );
  }

  private submitForm = (values: any) => {
    AdminInternalNoteApiService.saveInternalNote(CurrentUser.Get()?.committeeId || '', values.noteTxt)
      .then(() => {
        this.setState({
          noteEntry: values.noteTxt
        });
        notification.success({ message: 'Note saved successfully.' });
      })
      .catch(() => {
        notification.error({ message: 'Error saving note' });
      });
  }

  private cancelForm = () => {
    this._formRef.current?.setFieldsValue({ noteTxt: this.state.noteEntry });
  }

  private getInitialValues = () => {

    this.setState({ loading: true });

    AdminInternalNoteApiService.getInternalNote(CurrentUser.Get()?.committeeId || '').then(noteEntry => {

      this.setState({
        noteEntry: noteEntry,
        loading: false
      });
    }).catch(() => {
      this.setState({ loading: false });
    });
  }
}

export default InternalNotes;

