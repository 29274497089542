// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Constants.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
export default class ExpenditureType {
    public static readonly ADVERTISING: string = '001';
    public static readonly BANK_CHARGES: string = '002';
    public static readonly CAMPAIGN_MERCHANDISE: string = '003';
    public static readonly CHARITABLE_CONTRIBUTIONS: string = '004';
    public static readonly CIVIL_PENALTY_HEARING_COSTS: string = '005';
    public static readonly CONSULTANT_SERVICES: string = '006';
    public static readonly CONSTITUENCY_EXPENSES: string = '007';
    public static readonly DISSOLUTION_DISTRIBUTION: string = '008';
    public static readonly FUNDRAISER_ATTENDED: string = '009';
    public static readonly FUNDRAISER_HOLDING: string = '010';
    public static readonly GIFTS_OR_MEALS_FOR_VOLUNTEERS: string = '011';
    public static readonly HQ_EXPENSES: string = '012';
    public static readonly INTEREST_ON_LOAN: string = '013';
    public static readonly MEALS_REIMBURSEMENT: string = '014';
    public static readonly MILEAGE: string = '015';
    public static readonly MISCELLANEOUS_OR_UNITEMIZED: string = '016';
    public static readonly OFFICE_EQUIPMENT: string = '017';
    public static readonly OFFICE_HOLDER_EXPENSES: string = '018';
    public static readonly OFFICE_SUPPLIES: string = '019';
    public static readonly OTHER_EXPENDITURE: string = '020';
    public static readonly PHOTOGRAPHY: string = '021';
    public static readonly POLITICAL_CONTRIBUTION: string = '022';
    public static readonly POSTAGE_SHIPPING_DELIVERY: string = '023';
    public static readonly PRINTING__REPRODUCTION: string = '024';
    public static readonly PROFESSIONAL_FEES: string = '025';
    public static readonly REVERSE_TRANSACTION: string = '026';
    public static readonly SALARY__GRATUITY: string = '027';
    public static readonly TRAVEL: string = '028';
    public static readonly LOANS_FORGIVEN: string = '030';
    public static readonly DEBT_FORGIVEN: string = '031';
    public static readonly PARADE_CANDY: string = '032';
    public static readonly WEB_FEES: string = '033';
    public static readonly CAMPAIGN_SIGNS: string = '034';
    public static readonly FUNDRAISER_FOOD: string = '035';
}
