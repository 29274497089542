import { UserSessionStoreState } from '../state/StateStoreModel';
import { UserSessionActionTypes, UserSessionAction } from '../actions/UserSessionActions';

export const InitialUserSessionStoreState: UserSessionStoreState = {
  Loading: false,
  Checking: true,
  Value: null,
  Error: null,
};

export function UserSession(
  state: UserSessionStoreState = InitialUserSessionStoreState,
  action: UserSessionAction
): UserSessionStoreState {
  let newState: UserSessionStoreState = state;
  let us: any = null;
  let error: any = null;
  switch (action.type) {
    case UserSessionActionTypes.USER_SESSION_CHANGED:
      us = action.data ? Object.freeze(action.data) : action.data;
      newState = { ...state, Value: us, Error: null };
      break;
    case UserSessionActionTypes.USER_SESSION_LOADING:
      error = state.Error;
      if (action.data) {
        error = null;
      }
      newState = { ...state, Loading: action.data, Error: error };
      break;
    case UserSessionActionTypes.USER_SESSION_CHECKING:
      newState = { ...state, Checking: action.data };
      break;
    case UserSessionActionTypes.USER_SESSION_LOGIN_ERROR:
      newState = { ...state, Error: action.data };
      break;
    default:
      return newState;
  }
  return Object.freeze(newState);
}
