import { UploadOutlined } from '@ant-design/icons';
import {notification, Typography, Upload } from 'antd';
import { RcFile, UploadChangeParam, UploadFile, UploadProps } from 'antd/lib/upload/interface';
import * as React from 'react';

export interface FileUploadValue {
  newFiles: any[];
  existingFiles: any[];
  removedFiles: string[];
}

interface FileUploadState {
  loading: boolean;
  error: boolean;
  success: boolean;

  newList: any[];
  existingList: any[];
  removedList: string[];
}

interface FileUploadProps extends Omit<UploadProps, 'onChange'> {
  value?: FileUploadValue;
  onChange?: (value: FileUploadValue) => void;
  cancel?: (canClose: boolean) => void;
  existingList?: any[];
  fileType?: string;
  fileSize?: number;
}

class FileUpload extends React.Component<FileUploadProps, FileUploadState> {
  constructor(props: FileUploadProps) {
    super(props);

    this.state = {
      loading: false,
      error: false,
      success: false,

      newList: [],
      existingList: [],
      removedList: []
    };
  }

  componentDidUpdate(prevProps: FileUploadProps) {
    if (this.props.value && this.props.value != prevProps.value) {
      this.setState({ existingList: this.props.value.existingFiles });
    }
  }


  private handleUpload = (info: UploadChangeParam) => {
    this.setState({
      newList: info.fileList
    });

    this.triggerChange();
  };

  private handleRemove = (file: UploadFile) => {
    const removedFiles = [...this.state.removedList];
    if (this.state.newList.some(x => x.uid == file.uid)) {
      removedFiles.push(file.uid);
    }

    this.triggerChange();
  };

  private beforeUpload = (file: RcFile) => {
    const count = [] as any[];
    const files = this.state.newList as any[];

    if (this.props.fileSize != null) {
      if (file.size > this.props.fileSize * 1000000) {
        notification.error({ message: `File must be smaller than' ${this.props.fileSize}MB.` });
        return true;
      }
    }

    this.setState({
      newList: [...files]
    });

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      if (files.length == this.props.maxCount) {
        files.splice(0, 1);
      }

      files.push(file);
      files.map((item, index) => {
        if (file.name === item.name) {
          count.push(index);
          if (count.length > 1) {
            files.splice(index, 1);
            return;
          }
        }
      });

      this.setState({
        newList: [...files]
      });

      this.triggerChange();
    };

    return false;
  }

  private triggerChange = () => {
    if (this.props.onChange) {
      const value = {
        newFiles: this.state.newList,
        existingFiles: this.state.existingList,
        removedFiles: this.state.removedList
      } as FileUploadValue;

      this.props.onChange(value);
    }
  }

  //this.setState({ existingList: (this.props.existingList ?? []) as UploadFile[] });
  render() {
    const existingList = (this.props.existingList ?? []) as UploadFile[];

    return (
      <Upload.Dragger
        {...this.props}
        name="file"
        defaultFileList={existingList}
        beforeUpload={this.beforeUpload}
        onChange={this.handleUpload}
        onRemove={this.handleRemove}
        accept={this.props.fileType}>
        <Typography.Text>
          <UploadOutlined className="app-icon" /> Click or drag file to this area to upload
        </Typography.Text>
      </Upload.Dragger >
    );
  }
}

export default FileUpload;