// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
import BaseApi from './BaseApi';
import TableRequestDTO from '../models/TableRequestDTO';
import TableResponseDTO from '../models/TableResponseDTO';
import AdminOrganizationListDTO from '../models/AdminOrganizationListDTO';

export class AdminReviewAccountApiService extends BaseApi {

  // post: api/admin/review
  public getReviewAccountList(tableRequest: TableRequestDTO): Promise<TableResponseDTO<AdminOrganizationListDTO>> {
    let url = `api/admin/review`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, TableResponseDTO<AdminOrganizationListDTO>>(tableRequest, 'post', url, true, false);
  }

  // get: api/admin/review/export
  public exportReviewAccountList(tableRequest: TableRequestDTO): Promise<void> {
    let url = `api/admin/review/export`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, void>(tableRequest, 'get', url, true, true);
  }

  // get: api/admin/review?committeeId=${encodeURIComponent(committeeId)}
  public getLastFiledDr2Id(committeeId: string): Promise<string | null> {
    let url = `api/admin/review`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (committeeId != null) {
      url += `${prefix}committeeId=${encodeURIComponent(committeeId)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, string | null>(null, 'get', url, true, false);
  }
}
var service = new AdminReviewAccountApiService();
export default service;
