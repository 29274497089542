import { notification, FormInstance, Row, Col, Input, Checkbox, Button, Table, Typography, Tooltip } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import * as React from 'react';
import FormValidationUtil from '../../../../utils/FormValidationUtil';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import PfdCommissionsDTO from '../../../../models/PfdCommissionsDTO';
import CustomForm from '../../../shared/CustomForm';

const { Paragraph, Link, Title } = Typography;
const { TextArea } = Input;
const { Column } = Table;

interface CommissionsProps {
  commissions: PfdCommissionsDTO[];
  noOtherCommission: boolean;
}

interface CommissionsState {
  commissions: PfdCommissionsDTO[];
  noOtherCommission: boolean;
}

class Commissions extends React.Component<CommissionsProps, CommissionsState>
{
  private readonly _formRef = React.createRef<FormInstance>();

  constructor(props: CommissionsProps) {
    super(props);

    this.state = {
      commissions: this.props.commissions || [],
      noOtherCommission: this.props.noOtherCommission,
    };
  }

  moveBack = async (): Promise<{ model: { commissions: PfdCommissionsDTO[], noOtherComission: boolean }, errorFields: any }> => {
    return {
      model: { commissions: this.state.commissions, noOtherComission: this.state.noOtherCommission }, errorFields: null
    };
  }

  validate = async (): Promise<{ model: { commissions: PfdCommissionsDTO[], noOtherComission: boolean }, errorFields: any }> => {
    if (this.state.commissions.length > 0 || this.state.noOtherCommission) {
      return {
        model: { commissions: this.state.commissions, noOtherComission: this.state.noOtherCommission }, errorFields: null
      };
    } else {
      notification.error({
        message: 'You must select at least one commission or select no other commission'
      });
      return Promise.reject();
    }
  }

  render() {
    return (
      <>
        <Title level={4}>
          Commissions from the sale of goods or services to a political division of the State
        </Title>
        <Paragraph>
          Iowa Code section&nbsp;
          <Link href="https://www.legis.iowa.gov/docs/code/68b.35.pdf" target="_blank">68B.35</Link>
          &nbsp;requires executive branch officials and employees who sell goods or services to a political subdivision of
          the state during the covered year to disclose whether income has been received from commissions from these sales.
        </Paragraph>
        <Paragraph>
          If you received a commission from the sale of goods or services to a political subdivision of the state during the
          covered year, enter the name of each purchasing political subdivision and a description of the goods or services sold.
        </Paragraph>
        <Row>
          <Col xs={24}>
            <CustomForm formRef={this._formRef} onFinish={this.addCommission} layout="vertical">
              <Tooltip title="e.g. Warren County, City of Marshalltown">
                <FormItem
                  name="name"
                  label="Name of Political Subdivision"
                  rules={[FormValidationUtil.Required('Name of Political Subdivision is required')]}
                >
                  <Input type="text" />
                </FormItem>
              </Tooltip>
              <FormItem
                name="description"
                label="Description of Goods or Service"
                rules={[FormValidationUtil.Required('Description of Goods or Service is required')]}
              >
                <TextArea rows={3} />
              </FormItem>
              <Button type="primary" htmlType="submit">Add</Button>
            </CustomForm>
          </Col>
        </Row>
        <br />
        {
          this.state.commissions.length > 0 ?
            <Row>
              <Col xs={24}>
                <Table dataSource={this.state.commissions}>
                  <Column title="Name" dataIndex="name" key="name" />
                  <Column title="Description" dataIndex="description" key="description" />
                  <Column
                    title="Delete"
                    dataIndex=""
                    render={(text, record, index) =>
                      <Button onClick={() => { this.deleteCommission(index); }}>Delete</Button>
                    }
                  />
                </Table>
              </Col>
            </Row>
            :
            <>
              <Row>
                <Col>
                  <Checkbox className="bold-checkbox" checked={this.state.noOtherCommission} onChange={this.toggleCommission}>
                    Click here if you did not receive a commission from the sale of goods
                    or services to a political subdivision during the covered year.
                  </Checkbox>
                </Col>
              </Row>
              <br />
            </>
        }
      </>
    );
  }

  private addCommission = (values: any) => {
    this._formRef.current?.resetFields();
    this.setState({ commissions: [...this.state.commissions, values] });
  }

  private toggleCommission = (checkBox: CheckboxChangeEvent) => {
    this.setState({ noOtherCommission: checkBox.target.checked });
  }

  private deleteCommission = (index: any) => {
    const dataSource = [...this.state.commissions];
    const value = dataSource[index];
    this.setState({ commissions: dataSource.filter(item => item !== value) });
  }
}

export default Commissions;