// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
import BaseApi from './BaseApi';
import TableRequestDTO from '../models/TableRequestDTO';
import SchF1LoansReceivedFormDTO from '../models/SchF1LoansReceivedFormDTO';
import TableResponseDTO from '../models/TableResponseDTO';
import SchF1LoansReceivedDTO from '../models/SchF1LoansReceivedDTO';

export class SchF1LoansReceivedApiService extends BaseApi {

  // post: api/schF1LoansReceived/table?dr2Id=${encodeURIComponent(dr2Id)}
  public getDr2LoanReceivedList(tableRequest: TableRequestDTO, dr2Id: string): Promise<TableResponseDTO<SchF1LoansReceivedDTO>> {
    let url = `api/schF1LoansReceived/table`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (dr2Id != null) {
      url += `${prefix}dr2Id=${encodeURIComponent(dr2Id)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, TableResponseDTO<SchF1LoansReceivedDTO>>(tableRequest, 'post', url, true, false);
  }

  // get: api/schF1LoansReceived/${encodeURIComponent(loanReceivedId)}/${seqNum}
  public getLoanReceived(loanReceivedId: string, seqNum: number): Promise<SchF1LoansReceivedFormDTO> {
    let url = `api/schF1LoansReceived/${encodeURIComponent(loanReceivedId)}/${seqNum}`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, SchF1LoansReceivedFormDTO>(null, 'get', url, true, false);
  }

  // post: api/schF1LoansReceived/${encodeURIComponent(loanReceivedId)}/history
  public getLoanReceivedHistory(tableRequest: TableRequestDTO, loanReceivedId: string): Promise<TableResponseDTO<SchF1LoansReceivedDTO>> {
    let url = `api/schF1LoansReceived/${encodeURIComponent(loanReceivedId)}/history`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, TableResponseDTO<SchF1LoansReceivedDTO>>(tableRequest, 'post', url, true, false);
  }

  // post: api/schF1LoansReceived?committeeId=${encodeURIComponent(committeeId)}&dr2Id=${encodeURIComponent(dr2Id)}
  public add(newLoanReceived: SchF1LoansReceivedFormDTO, committeeId: string, dr2Id: string): Promise<void> {
    let url = `api/schF1LoansReceived`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (committeeId != null) {
      url += `${prefix}committeeId=${encodeURIComponent(committeeId)}`;
      prefix = '&';
    }
    if (dr2Id != null) {
      url += `${prefix}dr2Id=${encodeURIComponent(dr2Id)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, void>(newLoanReceived, 'post', url, true, false);
  }

  // put: api/schF1LoansReceived?committeeId=${encodeURIComponent(committeeId)}&dr2Id=${encodeURIComponent(dr2Id)}&loanReceivedId=${encodeURIComponent(loanReceivedId)}&seqNum=${seqNum}
  public edit(newLoanReceived: SchF1LoansReceivedFormDTO, committeeId: string, dr2Id: string, loanReceivedId: string, seqNum: number): Promise<void> {
    let url = `api/schF1LoansReceived`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (committeeId != null) {
      url += `${prefix}committeeId=${encodeURIComponent(committeeId)}`;
      prefix = '&';
    }
    if (dr2Id != null) {
      url += `${prefix}dr2Id=${encodeURIComponent(dr2Id)}`;
      prefix = '&';
    }
    if (loanReceivedId != null) {
      url += `${prefix}loanReceivedId=${encodeURIComponent(loanReceivedId)}`;
      prefix = '&';
    }
    if (seqNum != null) {
      url += `${prefix}seqNum=${seqNum}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, void>(newLoanReceived, 'put', url, true, false);
  }

  // delete: api/schF1LoansReceived?loanReceivedId=${encodeURIComponent(loanReceivedId)}&seqNum=${seqNum}
  public delete(loanReceivedId: string, seqNum: number): Promise<void> {
    let url = `api/schF1LoansReceived`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (loanReceivedId != null) {
      url += `${prefix}loanReceivedId=${encodeURIComponent(loanReceivedId)}`;
      prefix = '&';
    }
    if (seqNum != null) {
      url += `${prefix}seqNum=${seqNum}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, void>(null, 'delete', url, true, false);
  }
}
var service = new SchF1LoansReceivedApiService();
export default service;
