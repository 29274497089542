import { Form, FormProps } from 'antd';
import * as React from 'react';

interface CustomFormProps {
  formRef?: any;
}

class CustomForm extends React.Component<CustomFormProps & FormProps, unknown> {
  constructor(props: CustomFormProps & FormProps) {
    super(props);
  }

  render() {
    const { formRef, ...formProps } = this.props;

    return (
      <Form
        {...formProps}
        ref={formRef}
        scrollToFirstError={undefined}
        onFinishFailed={this.onFinishFailed}
      >
        {this.props.children}
      </Form>
    );
  }

  private onFinishFailed = (errorInfo: any) => {
    this.scrollToErrorField(errorInfo);

    if (this.props.onFinishFailed) {
      this.props.onFinishFailed(errorInfo);
    }
  }

  private scrollToErrorField = (errorInfo: any) => {
    const namePath = this.toArray(errorInfo.errorFields[0].name);
    const fieldId = this.getFieldId(namePath, this.props.formRef.current.__INTERNAL__?.name);
    const node = document.getElementById(fieldId || '');
    node?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
  }

  private getFieldId = (namePath: any[], formName?: string) => {
    if (!namePath.length) {
      return undefined;
    }

    const mergedId = namePath.join('_');
    // This is antd implementation but it doesn't work as far as I can see?
    return formName ? /*`${formName}_${mergedId}`*/ mergedId : mergedId;
  }

  private toArray<T>(candidate?: T | T[] | false): T[] {
    if (candidate === undefined || candidate === false) {
      return [];
    }

    return Array.isArray(candidate) ? candidate : [candidate];
  }
}

export default CustomForm;
