import {
  FormInstance,
  ColProps,
  Col,
  FormProps
} from 'antd';
import * as React from 'react';
import AddressFormFields from '../shared/AddressFormFields';
import JurisdictionInfoDTO from '../../models/JurisdictionInfoDTO';
import FormItem from 'antd/lib/form/FormItem';
import Checkbox, { CheckboxChangeEvent } from 'antd/lib/checkbox/Checkbox';
import CustomForm from '../shared/CustomForm';

interface JurisdictionProps extends FormProps {
  jurisdictionInfo?: JurisdictionInfoDTO;
  disabled?: boolean;
}
interface JurisdictionState {
}

class Jurisdiction extends React.Component<JurisdictionProps & FormProps, JurisdictionState>{
  private readonly _formRef = React.createRef<FormInstance>();

  constructor(props: JurisdictionProps & FormProps) {
    super(props);
  }

  validate = async (): Promise<{ model: JurisdictionInfoDTO, errorFields: any }> => {
    try {
      const fields = await this._formRef.current?.validateFields();
      return { model: fields, errorFields: null };
    }
    catch (errorInfo) {
      return Promise.reject({ model: errorInfo.values, errorFields: errorInfo.errorFields });
    }
  }

  render() {
    const { jurisdictionInfo, disabled,...formProps } = this.props;
    const columnSizingProps: ColProps = { xs: 24, sm: 24, md: 12 };

    return (
      <CustomForm {...formProps} layout="vertical" initialValues={jurisdictionInfo} formRef={this._formRef}>
        <FormItem name="FECInd"  >
          <Checkbox onChange={this.handleCheckBox}> Committee is registed with the FEC.</Checkbox>
        </FormItem>
        <Col {...columnSizingProps}>
          <AddressFormFields
            name={{ name: 'name', required: true }}
            address1={{ name: 'addressLine1', required: true }}
            address2={{ name: 'addressLine2', required: false }}
            city={{ name: 'city', required: true }}
            state={{ name: 'state', required: true }}
            zip={{ name: 'zipCode', required: true }}
            email={{ name: 'email', required: false}}
            phone={{ name: 'phoneNumber', required: true }}
            allDisabled={disabled}
            ariaPrefix="Jurisdiction" />
        </Col>
      </CustomForm>
    );
  }
  private handleCheckBox = (FECInd: CheckboxChangeEvent) =>
  {
    if (FECInd.target.checked) {
      this._formRef.current?.setFieldsValue({
        name: 'Federal Election Commission',
        addressLine1: '999 E.Street,NW',
        addressLine2: '',
        city: 'Washington',
        state: 'DC',
        zipCode: '20463',
        phoneNumber: '8004249530',
      });
    }
    if(FECInd.target.checked == false){
      this._formRef.current?.setFieldsValue({
        name: '',
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        zipCode: '',
        phoneNumber:'',
      });
    }
  }
}
export default Jurisdiction;
