// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
import BaseApi from './BaseApi';
import TableRequestDTO from '../models/TableRequestDTO';
import GiftBequestsDTO from '../models/GiftBequestsDTO';
import TableResponseDTO from '../models/TableResponseDTO';
import AdminGiftBequestListDTO from '../models/AdminGiftBequestListDTO';

export class GiftBequestApiService extends BaseApi {

  // post: api/donor/giftBequest/table
  public getGiftBequestList(tableRequest: TableRequestDTO): Promise<TableResponseDTO<AdminGiftBequestListDTO>> {
    let url = `api/donor/giftBequest/table`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, TableResponseDTO<AdminGiftBequestListDTO>>(tableRequest, 'post', url, true, false);
  }

  // post: api/donor/giftBequest
  public add(newGiftBequestEntry: GiftBequestsDTO): Promise<void> {
    let url = `api/donor/giftBequest`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, void>(newGiftBequestEntry, 'post', url, true, false);
  }

  // get: api/donor/giftBequest/${encodeURIComponent(giftId)}
  public get(giftId: string): Promise<GiftBequestsDTO> {
    let url = `api/donor/giftBequest/${encodeURIComponent(giftId)}`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, GiftBequestsDTO>(null, 'get', url, true, false);
  }

  // put: api/donor/giftBequest
  public edit(giftBequestEntry: GiftBequestsDTO): Promise<void> {
    let url = `api/donor/giftBequest`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, void>(giftBequestEntry, 'put', url, true, false);
  }

  // delete: api/donor/giftBequest?giftBequestID=${encodeURIComponent(giftBequestID)}
  public delete(giftBequestID: string): Promise<void> {
    let url = `api/donor/giftBequest`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (giftBequestID != null) {
      url += `${prefix}giftBequestID=${encodeURIComponent(giftBequestID)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, void>(null, 'delete', url, true, false);
  }
}
var service = new GiftBequestApiService();
export default service;
