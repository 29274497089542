// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
import BaseApi from './BaseApi';
import TableRequestDTO from '../models/TableRequestDTO';
import SchBExpendituresFormDTO from '../models/SchBExpendituresFormDTO';
import TableResponseDTO from '../models/TableResponseDTO';
import SchBExpendituresDTO from '../models/SchBExpendituresDTO';
import EarmarkInfoDTO from '../models/EarmarkInfoDTO';

export class SchBExpendituresApiService extends BaseApi {

  // post: api/schbexpenditures/table?dr2Id=${encodeURIComponent(dr2Id)}
  public getSchBExpenditures(tableRequest: TableRequestDTO, dr2Id: string): Promise<TableResponseDTO<SchBExpendituresDTO>> {
    let url = `api/schbexpenditures/table`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (dr2Id != null) {
      url += `${prefix}dr2Id=${encodeURIComponent(dr2Id)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, TableResponseDTO<SchBExpendituresDTO>>(tableRequest, 'post', url, true, false);
  }

  // get: api/schbexpenditures/earmarks?dr2Id=${encodeURIComponent(dr2Id)}&expenditureId=${encodeURIComponent(expenditureId)}
  public getSchBEarmarkDropdownOptions(dr2Id: string, expenditureId: string | null): Promise<EarmarkInfoDTO[]> {
    let url = `api/schbexpenditures/earmarks`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (dr2Id != null) {
      url += `${prefix}dr2Id=${encodeURIComponent(dr2Id)}`;
      prefix = '&';
    }
    if (expenditureId != null) {
      url += `${prefix}expenditureId=${encodeURIComponent(expenditureId)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, EarmarkInfoDTO[]>(null, 'get', url, true, false);
  }

  // get: api/schbexpenditures/${encodeURIComponent(expenditureId)}/${seqNum}
  public getExpenditure(expenditureId: string, seqNum: number): Promise<SchBExpendituresFormDTO> {
    let url = `api/schbexpenditures/${encodeURIComponent(expenditureId)}/${seqNum}`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, SchBExpendituresFormDTO>(null, 'get', url, true, false);
  }

  // get: api/schbexpenditures/${encodeURIComponent(debtId)}/${seqNum}/debt
  public getDebt(debtId: string, seqNum: number): Promise<SchBExpendituresFormDTO> {
    let url = `api/schbexpenditures/${encodeURIComponent(debtId)}/${seqNum}/debt`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, SchBExpendituresFormDTO>(null, 'get', url, true, false);
  }

  // post: api/schbexpenditures/${encodeURIComponent(expenditureId)}/history
  public getExpenditureHistory(tableRequest: TableRequestDTO, expenditureId: string): Promise<TableResponseDTO<SchBExpendituresDTO>> {
    let url = `api/schbexpenditures/${encodeURIComponent(expenditureId)}/history`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, TableResponseDTO<SchBExpendituresDTO>>(tableRequest, 'post', url, true, false);
  }

  // post: api/schbexpenditures?committeeId=${encodeURIComponent(committeeId)}&dr2Id=${encodeURIComponent(dr2Id)}
  public add(newExpenditure: SchBExpendituresFormDTO, committeeId: string, dr2Id: string): Promise<void> {
    let url = `api/schbexpenditures`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (committeeId != null) {
      url += `${prefix}committeeId=${encodeURIComponent(committeeId)}`;
      prefix = '&';
    }
    if (dr2Id != null) {
      url += `${prefix}dr2Id=${encodeURIComponent(dr2Id)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, void>(newExpenditure, 'post', url, true, false);
  }

  // post: api/schbexpenditures/addDebt?committeeId=${encodeURIComponent(committeeId)}&dr2Id=${encodeURIComponent(dr2Id)}&debtId=${encodeURIComponent(debtId)}&debtSeqNum=${debtSeqNum}
  public addWithDebt(newExpenditure: SchBExpendituresFormDTO, committeeId: string, dr2Id: string, debtId: string | null, debtSeqNum: number): Promise<void> {
    let url = `api/schbexpenditures/addDebt`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (committeeId != null) {
      url += `${prefix}committeeId=${encodeURIComponent(committeeId)}`;
      prefix = '&';
    }
    if (dr2Id != null) {
      url += `${prefix}dr2Id=${encodeURIComponent(dr2Id)}`;
      prefix = '&';
    }
    if (debtId != null) {
      url += `${prefix}debtId=${encodeURIComponent(debtId)}`;
      prefix = '&';
    }
    if (debtSeqNum != null) {
      url += `${prefix}debtSeqNum=${debtSeqNum}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, void>(newExpenditure, 'post', url, true, false);
  }

  // put: api/schbexpenditures?committeeId=${encodeURIComponent(committeeId)}&dr2Id=${encodeURIComponent(dr2Id)}&expenditureId=${encodeURIComponent(expenditureId)}&seqNum=${seqNum}
  public edit(newExpenditure: SchBExpendituresFormDTO, committeeId: string, dr2Id: string, expenditureId: string, seqNum: number): Promise<void> {
    let url = `api/schbexpenditures`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (committeeId != null) {
      url += `${prefix}committeeId=${encodeURIComponent(committeeId)}`;
      prefix = '&';
    }
    if (dr2Id != null) {
      url += `${prefix}dr2Id=${encodeURIComponent(dr2Id)}`;
      prefix = '&';
    }
    if (expenditureId != null) {
      url += `${prefix}expenditureId=${encodeURIComponent(expenditureId)}`;
      prefix = '&';
    }
    if (seqNum != null) {
      url += `${prefix}seqNum=${seqNum}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, void>(newExpenditure, 'put', url, true, false);
  }

  // delete: api/schbexpenditures?expenditureId=${encodeURIComponent(expenditureId)}&seqNum=${seqNum}
  public delete(expenditureId: string, seqNum: number): Promise<void> {
    let url = `api/schbexpenditures`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (expenditureId != null) {
      url += `${prefix}expenditureId=${encodeURIComponent(expenditureId)}`;
      prefix = '&';
    }
    if (seqNum != null) {
      url += `${prefix}seqNum=${seqNum}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, void>(null, 'delete', url, true, false);
  }
}
var service = new SchBExpendituresApiService();
export default service;
