// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
import BaseApi from './BaseApi';
import TableRequestDTO from '../models/TableRequestDTO';
import SchEInKindContributionsFormDTO from '../models/SchEInKindContributionsFormDTO';
import TableResponseDTO from '../models/TableResponseDTO';
import SchEInKindContributionsDTO from '../models/SchEInKindContributionsDTO';

export class SchEInKindContributionsApiService extends BaseApi {

  // post: api/scheinkindcontributions/table?dr2Id=${encodeURIComponent(dr2Id)}
  public getSchEInKindContributions(tableRequest: TableRequestDTO, dr2Id: string): Promise<TableResponseDTO<SchEInKindContributionsDTO>> {
    let url = `api/scheinkindcontributions/table`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (dr2Id != null) {
      url += `${prefix}dr2Id=${encodeURIComponent(dr2Id)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, TableResponseDTO<SchEInKindContributionsDTO>>(tableRequest, 'post', url, true, false);
  }

  // get: api/scheinkindcontributions/${encodeURIComponent(contributionId)}/${seqNum}
  public getInKindContribution(contributionId: string, seqNum: number): Promise<SchEInKindContributionsFormDTO> {
    let url = `api/scheinkindcontributions/${encodeURIComponent(contributionId)}/${seqNum}`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, SchEInKindContributionsFormDTO>(null, 'get', url, true, false);
  }

  // get: api/scheinkindcontributions/${encodeURIComponent(debtId)}/${seqNum}/debt
  public getDebt(debtId: string, seqNum: number): Promise<SchEInKindContributionsFormDTO> {
    let url = `api/scheinkindcontributions/${encodeURIComponent(debtId)}/${seqNum}/debt`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, SchEInKindContributionsFormDTO>(null, 'get', url, true, false);
  }

  // post: api/scheinkindcontributions/${encodeURIComponent(contributionId)}/history
  public getInKindContributionHistory(tableRequest: TableRequestDTO, contributionId: string): Promise<TableResponseDTO<SchEInKindContributionsDTO>> {
    let url = `api/scheinkindcontributions/${encodeURIComponent(contributionId)}/history`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, TableResponseDTO<SchEInKindContributionsDTO>>(tableRequest, 'post', url, true, false);
  }

  // post: api/scheinkindcontributions?committeeId=${encodeURIComponent(committeeId)}&dr2Id=${encodeURIComponent(dr2Id)}
  public add(newContribution: SchEInKindContributionsFormDTO, committeeId: string, dr2Id: string): Promise<void> {
    let url = `api/scheinkindcontributions`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (committeeId != null) {
      url += `${prefix}committeeId=${encodeURIComponent(committeeId)}`;
      prefix = '&';
    }
    if (dr2Id != null) {
      url += `${prefix}dr2Id=${encodeURIComponent(dr2Id)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, void>(newContribution, 'post', url, true, false);
  }

  // post: api/scheinkindcontributions/addDebt?committeeId=${encodeURIComponent(committeeId)}&dr2Id=${encodeURIComponent(dr2Id)}&debtId=${encodeURIComponent(debtId)}&debtSeqNum=${debtSeqNum}
  public addWithDebt(newContribution: SchEInKindContributionsFormDTO, committeeId: string, dr2Id: string, debtId: string | null, debtSeqNum: number): Promise<void> {
    let url = `api/scheinkindcontributions/addDebt`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (committeeId != null) {
      url += `${prefix}committeeId=${encodeURIComponent(committeeId)}`;
      prefix = '&';
    }
    if (dr2Id != null) {
      url += `${prefix}dr2Id=${encodeURIComponent(dr2Id)}`;
      prefix = '&';
    }
    if (debtId != null) {
      url += `${prefix}debtId=${encodeURIComponent(debtId)}`;
      prefix = '&';
    }
    if (debtSeqNum != null) {
      url += `${prefix}debtSeqNum=${debtSeqNum}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, void>(newContribution, 'post', url, true, false);
  }

  // post: api/scheinkindcontributions/edit?committeeId=${encodeURIComponent(committeeId)}&dr2Id=${encodeURIComponent(dr2Id)}&expenditureId=${encodeURIComponent(expenditureId)}&seqNum=${seqNum}
  public edit(newContribution: SchEInKindContributionsFormDTO, committeeId: string, dr2Id: string, expenditureId: string, seqNum: number): Promise<void> {
    let url = `api/scheinkindcontributions/edit`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (committeeId != null) {
      url += `${prefix}committeeId=${encodeURIComponent(committeeId)}`;
      prefix = '&';
    }
    if (dr2Id != null) {
      url += `${prefix}dr2Id=${encodeURIComponent(dr2Id)}`;
      prefix = '&';
    }
    if (expenditureId != null) {
      url += `${prefix}expenditureId=${encodeURIComponent(expenditureId)}`;
      prefix = '&';
    }
    if (seqNum != null) {
      url += `${prefix}seqNum=${seqNum}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, void>(newContribution, 'post', url, true, false);
  }

  // post: api/scheinkindcontributions/delete?contributionId=${encodeURIComponent(contributionId)}&seqNum=${seqNum}
  public delete(contributionId: string, seqNum: number): Promise<void> {
    let url = `api/scheinkindcontributions/delete`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (contributionId != null) {
      url += `${prefix}contributionId=${encodeURIComponent(contributionId)}`;
      prefix = '&';
    }
    if (seqNum != null) {
      url += `${prefix}seqNum=${seqNum}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, void>(null, 'post', url, true, false);
  }
}
var service = new SchEInKindContributionsApiService();
export default service;
