import moment from 'moment';
import { Rule } from 'rc-field-form/lib/interface';
import AccountApiService from '../api/AccountApiService';
import ResultDTO from '../models/ResultDTO';
import DateUtil from './DateUtil';

/**
 * Use to populate the 'rules' array on FormItem components.
 * Ant documentation for rules - https://ant.design/components/form/#Rule
 */
class FormValidationUtil {
  public static Required = (message?: string | React.ReactElement, required?: boolean): Rule => ({
    required: (required === undefined) ? true : required,
    whitespace: true,
    message: message || ''
  });

  public static RequiredNumber = (message?: string | React.ReactElement, required?: boolean): Rule => ({
    required: (required === undefined) ? true : required,
    message: message || ''
  });

  public static RequiredDate = (message?: string | React.ReactElement, required?: boolean): Rule => ({
    required: (required === undefined) ? true : required,
    message: message || ''
  });

  public static DateBeforeEqual = (compareToFieldName: string): Rule => {
    return ({ getFieldValue }: any) => {
      return ({
        validator(rule: any, value: string | null) {
          if (!value || !getFieldValue(compareToFieldName)
            || moment(value).startOf('day').isSameOrBefore(moment(getFieldValue(compareToFieldName)).startOf('day'))) {
            return Promise.resolve();
          }
          return Promise.reject('Start date must be before end date');
        },
      });
    };
  }

  public static DateAfterEqual = (compareToFieldName: string): Rule => {
    return ({ getFieldValue }: any) => {
      return ({
        validator(rule: any, value: string | null) {
          if (!value || !getFieldValue(compareToFieldName)
            || moment(value).startOf('day').isSameOrAfter(moment(getFieldValue(compareToFieldName)).startOf('day'))) {
            return Promise.resolve();
          }
          return Promise.reject('End date must be after start date');
        },
      });
    };
  }

  public static Length = (max: number, message?: string | React.ReactElement): Rule => ({ len: max, message });

  public static Min = (min: number, message?: string | React.ReactElement): Rule => ({ min, message });

  public static Max = (max: number, message?: string | React.ReactElement): Rule => ({ max, message });

  public static Url = (message?: string | React.ReactElement): Rule => ({ type: 'url', message });

  public static Email = (message?: string | React.ReactElement): Rule => ({ type: 'email', message });

  public static ValidateUsername = (required?: boolean): Rule => ({
    validateTrigger: ['onBlur'],
    validator: (_, value, callback) => {
      if (!required) {
        return Promise.resolve();
      }

      if (!value) {
        return Promise.resolve();
      }

      AccountApiService.validateUsername(value).then(result => {
        if (result.succeeded) {
          callback();
        } else {
          callback(result.errors[0]);
        }
      }).catch(() => {
        callback('Username check failed');
      });
    }
  });

  public static ValidatePassword = (): Rule => ({
    validateTrigger: ['onBlur'],
    validator(rule: any, value: string | null) {
      if (value) {
        return AccountApiService.validatePassword(value).then((response: ResultDTO | null) => {
          if (response?.succeeded) {
            return Promise.resolve();
          } else {
            return Promise.reject(response?.errors);
          }
        });
      } else {
        return Promise.resolve();
      }
    }
  });

  public static CheckConfirmPassword = (passwordField: string): Rule => {
    return ({ getFieldValue }: any) => ({
      validator(rule: any, value: string | null) {
        if (!value || getFieldValue(passwordField) === value) {
          return Promise.resolve();
        }
        return Promise.reject('The two passwords that you entered do not match');
      },
    });
  };

  public static Phone = (): Rule => ({
    pattern: /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/,
    // validateTrigger: ['onBlur'], // Might want something like this...
    message: 'Must be in a valid phone number format.'
  });

  public static PhoneOrNumber = (): Rule => ({
    pattern: /^((\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}|\d{10})$/,
    validateTrigger: ['onBlur'],
    message: 'Must be in a valid phone number format.'
  });

  public static PositiveNumber = (message?: string | React.ReactElement): Rule => {
    return {
      validator: (_, value) => {
        if (value === '' || value === null || value > 0) {
          return Promise.resolve();
        } else {
          return Promise.reject(message);
        }
      }
    };
  }

  public static NotNegativeNumber = (message?: string | React.ReactElement): Rule => {
    return {
      validator: (_, value) => {
        if (value === '' || value === null || value >= 0) {
          return Promise.resolve();
        } else {
          return Promise.reject(message);
        }
      }
    };
  }

  public static Zip = (): Rule => ({
    pattern: /^\d{5}(-\d{4})?$/,
    validateTrigger: ['onBlur'],
    message: 'Invalid format'
  });

  public static Year = (): Rule => ({
    pattern: /^\d{4}$/,
    // validateTrigger: ['onBlur'], // Might want something like this...
    message: 'Must be a valid year.'
  });

  public static Number = (message?: string): Rule => ({ pattern: /^[0-9]*$/, message });

  public static Currency = (message?: string): Rule => ({ pattern: /^-?(?:0|[1-9]\d{0,2}(?:,?\d{3})*)(?:\.\d +)?$/, message });

  public static Custom = (validator: (value: any) => Promise<void>): Rule => ({ validator });

  public static DateRange = (startDate: moment.Moment | null, endDate: moment.Moment | null): Rule => {
    return {
      validator: (rule: Rule, selectedDate: moment.Moment) => {
        if (!selectedDate || !startDate || !endDate) {
          return Promise.resolve();
        }

        const convertedStartDate = moment.utc(selectedDate).startOf('day');

        if (convertedStartDate.isBefore(startDate.startOf('day'))) {
          return Promise.reject(new Error(`Date must be after ${DateUtil.toShortDateString(startDate)}.`));
        }

        if (endDate.startOf('day').isSameOrAfter(convertedStartDate)) {
          return Promise.resolve();
        }

        return Promise.reject(
          new Error(`Date must be before ${DateUtil.toShortDateString(endDate)}.`));
      }
    };
  }

  public static Checkbox = (checked: boolean, required: boolean, message: string): Rule => {
    return {
      validator: () => {
        if (required) {
          if (checked) {
            return Promise.resolve();
          }
          return Promise.reject(new Error(message));
        }
        return Promise.resolve();
      }
    };
  }
}

export default FormValidationUtil;