import {
    Button,
  Layout,
  Space,
  Typography,
} from 'antd';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import DataTable, { DataTableColumnProps } from '../../shared/DataTable/DataTable';
import DataTableButtonUtil from '../../shared/DataTable/DataTableButtonUtil';
import IndependentExpendituresDTO from '../../../models/IndependentExpendituresDTO';
import DataTableColumnUtil from '../../shared/DataTable/DataTableColumnUtil';
import PublicReportsApiService from '../../../api/PublicReportsApiService';
import ReportTypeDesc from '../../../consts/ReportTypeDescriptions';
import HistoryUtil from '../../../utils/HistoryUtil';
import Routes from '../../../consts/Routes';
import { ArrowLeftOutlined } from '@ant-design/icons';
import DataTableCellRendererUtil from '../../shared/DataTable/DataTableCellRendererUtil';

const { Content } = Layout;

interface IndependentExpendituresProps {

}

interface IndependentExpendituresState {
  tableColumns: DataTableColumnProps<IndependentExpendituresDTO>[];
}

class IndependentExpenditures extends React.Component<RouteComponentProps<IndependentExpendituresProps>, IndependentExpendituresState> {

  constructor(props: RouteComponentProps<IndependentExpendituresProps>) {
    super(props);

    this.state = {
      tableColumns: this.getTableColumns()
    };
  }

  render() {
    const actionButtons = [];
    actionButtons.push(DataTableButtonUtil.Reset());
    return (
      <Content className="content-pad">
        <DataTable
          globalSearch={true}
          serverSide={true}
          buttonBar={actionButtons}
          columns={this.state.tableColumns}
          tableProps={{
            rowKey: 'id',
            sortDirections: ['ascend', 'descend'],
            locale: { emptyText: 'No Reports Currently Match Your Filter' }
          }}
          fetchData={{
            fetch: function (tableRequest) {
              return PublicReportsApiService.getIE(tableRequest)
                .then(res => {
                  return res;
                });
            },
            failureMessage: 'Failed to retrieve public reports'
          }}
          styleOptions={{ compact: true, alternatingRowHighlight: true }}
          title={
            <Button type="link" onClick={() => HistoryUtil.push(Routes.generate(Routes.PUBLIC_REPORTS))}>
              <Typography.Title level={4}>
                <Space size="large">
                  <ArrowLeftOutlined className="public-report-links"/> Independent Expenditures
                </Space>
              </Typography.Title>
            </Button>
          }
        />
      </Content>
    );
  }

  private getTableColumns = (): DataTableColumnProps<IndependentExpendituresDTO>[] => {
    const generatedOn = DataTableColumnUtil.Date<IndependentExpendituresDTO>('Filed On', 'filedOn');
    generatedOn.render = DataTableCellRendererUtil.ShortDate;
    return [
      DataTableColumnUtil.DropdownMulti('Report Type', 'reportType',
        [
          { text: ReportTypeDesc.IIE, value: ReportTypeDesc.IIE },
          { text: ReportTypeDesc.OIE, value: ReportTypeDesc.OIE }
        ]),
      DataTableColumnUtil.Text('Contact Name', 'contactName'),
      DataTableColumnUtil.Date('Date', 'date', null, { defaultSortOrder: 'descend' }),
      generatedOn,
      DataTableColumnUtil.Text('Filing Status', 'filingStatus'),
      DataTableColumnUtil.Buttons('fileName',
        [
          { text: 'View', onClick: (rowData) => window.open(rowData.fileUrl || '') },
        ])
    ];
  }
}

export default IndependentExpenditures;