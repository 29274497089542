import {
    Descriptions,
    Typography
} from 'antd';
import * as React from 'react';
import Colors from '../../consts/Colors';
import NumberFormatUtil from '../../utils/NumberFormatUtil';
import BuildingFundFilingDTO from '../../models/BuildingFundFilingDTO';

const { Item } = Descriptions;

interface BuildingFundsSummaryDescriptionsProps {
    summaryInfo: BuildingFundFilingDTO;
}

interface BuildingFundsSummaryDescriptionsState {

}

class BuildingFundsSummaryDescriptions extends React.Component<BuildingFundsSummaryDescriptionsProps, BuildingFundsSummaryDescriptionsState> {
    constructor(props: BuildingFundsSummaryDescriptionsProps) {
        super(props);
        this.state = {};
    }

    render() {
        const { summaryInfo } = this.props;

        return (
            <Descriptions title="Summary" bordered={true} size="small">
                <Item label="Starting Balance"
                    span={3}
                    style={{ backgroundColor: Colors.IOWA_BLUE, color: 'white', fontSize: 'large' }}
                >
                    {NumberFormatUtil.currency(summaryInfo.startingBalance ?? 0.00)}
                </Item>
                <Item label={this.label('Total Contributions', false, true)} span={3}>
                    {NumberFormatUtil.currency(summaryInfo.totalContributions ?? 0.00)}
                </Item>
                <Item label="Subtotal" span={3} style={{ fontWeight: 'bold', fontSize: 'medium' }}>
                    {NumberFormatUtil.currency(summaryInfo.subtotal ?? 0.00)}
                </Item>
                <Item label={this.label('Total Expenditures', false, true)} span={3}>
                    {NumberFormatUtil.currency(summaryInfo.totalExpenditures ?? 0.00)}
                </Item>
                <Item label="Ending Balance"
                    span={3}
                    style={{ backgroundColor: Colors.IOWA_BLUE, color: 'white', fontSize: 'large' }}
                >
                    {NumberFormatUtil.currency(summaryInfo.endingBalance ?? 0.00)}
                </Item>
            </Descriptions>
        );
    }

    private label = (display: string, isAmended: boolean, indent?: boolean): any => {
        return (
            <>
                <Typography.Text className={indent ? 'indent' : undefined}>{display}</Typography.Text>
                {isAmended && (
                    <Typography.Text type="danger">&nbsp;*</Typography.Text>
                )}
            </>
        );
    }
}

export default BuildingFundsSummaryDescriptions;