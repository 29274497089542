// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import EntitySelectorDTO from './EntitySelectorDTO';

interface PetitionForWaiverDTO extends EntitySelectorDTO { 
  id: number;
  firstName: string | null;
  middleInitial: string | null;
  lastName: string | null;
  addressLine1: string | null;
  addressLine2: string | null;
  city: string | null;
  stateCd: string | null;
  zip: string | null;
  email: string | null;
  phone: string | null;
  reportDueDate: moment.Moment | string;
  reportDateFiled: moment.Moment | string;
  penaltyAmount: number;
  penaltyReceivedDate: moment.Moment | string;
  waiverDescription: string | null;
  signature: string | null;
  signedDate: moment.Moment | string;
  penaltyType: string | null;
  contributorName: string | null | null;
  pfdContactId: number | null | null;
  otcId: string | null | null;
  iieId: string | null | null;
  oieId: string | null | null;
  committeeId: string | null | null;
  clientAssessedPenalty: string | null;
}
const PetitionForWaiverDTO = {
  create: (initValues?: Partial<PetitionForWaiverDTO> | Record<string, unknown> | null) => {
    return Object.assign(EntitySelectorDTO.create(),
    {
      id: 0,
      firstName: "",
      middleInitial: "",
      lastName: "",
      addressLine1: "",
      addressLine2: "",
      city: "",
      stateCd: "",
      zip: "",
      email: "",
      phone: "",
      reportDueDate: new Date(0).toISOString(),
      reportDateFiled: new Date(0).toISOString(),
      penaltyAmount: 0,
      penaltyReceivedDate: new Date(0).toISOString(),
      waiverDescription: "",
      signature: "",
      signedDate: new Date(0).toISOString(),
      penaltyType: "",
      contributorName: null,
      pfdContactId: null,
      otcId: null,
      iieId: null,
      oieId: null,
      committeeId: null,
      clientAssessedPenalty: "",
    },
    initValues);
  }
};

export default PetitionForWaiverDTO;