// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';

interface FilerTypeDTO { 
  id: number;
  name: string | null;
  createdByUserNm: string | null;
  createdTs: moment.Moment | string | null;
  lastUpdatedByUserNm: string | null;
  lastUpdatedTs: moment.Moment | string | null;
}
const FilerTypeDTO = {
  create: (initValues?: Partial<FilerTypeDTO> | Record<string, unknown> | null) => {
    return Object.assign(
    {
      id: 0,
      name: null,
      createdByUserNm: null,
      createdTs: null,
      lastUpdatedByUserNm: null,
      lastUpdatedTs: null,
    },
    initValues);
  }
};

export default FilerTypeDTO;