import {
  Button,
  Layout,
  notification,
  Space,
  Typography,
} from 'antd';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import DataTable, { DataTableColumnProps } from '../../shared/DataTable/DataTable';
import DataTableButtonUtil from '../../shared/DataTable/DataTableButtonUtil';
import StateReportsDTO from '../../../models/StateReportsDTO';
import DataTableColumnUtil from '../../shared/DataTable/DataTableColumnUtil';
import ReportingOrganizationTypes from '../../../consts/ReportingOrganizationTypes';
import PublicReportsApiService from '../../../api/PublicReportsApiService';
import ReportTypeDesc from '../../../consts/ReportTypeDescriptions';
import HistoryUtil from '../../../utils/HistoryUtil';
import Routes from '../../../consts/Routes';
import { ArrowLeftOutlined } from '@ant-design/icons';
import DataTableCellRendererUtil from '../../shared/DataTable/DataTableCellRendererUtil';
import moment from 'moment';
import TableRequestDTO from '../../../models/TableRequestDTO';
import TableResponseDTO from '../../../models/TableResponseDTO';
import FilingPeriodColumnLookupDTO from '../../../models/FilingPeriodColumnLookupDTO';
import { isNullOrUndefined } from 'util';

const { Content } = Layout;

interface StateReportsProps {

}

interface StateReportsState {
  filterFilingPeriodDescs: FilingPeriodColumnLookupDTO[];
  year: string;
  loading: boolean;
}

class StateReports extends React.Component<RouteComponentProps<StateReportsProps>, StateReportsState> {

  constructor(props: RouteComponentProps<StateReportsProps>) {
    super(props);

    this.state = {
      filterFilingPeriodDescs: [],
      year: '0',
      loading: false
    };
  }

  private _stateReportsDataTable: DataTable<StateReportsDTO> | null = null;

  componentDidMount() {
    this.fetchFilingPeriods(this.state.year, moment().year());
  }

  render() {
    const actionButtons = [];
    actionButtons.push(DataTableButtonUtil.Reset());
    return (
      <Content className="content-pad">
        <DataTable
          ref={(element: any) => (this._stateReportsDataTable = element)}
          globalSearch={true}
          serverSide={true}
          buttonBar={actionButtons}
          columns={this.getTableColumns()}
          tableProps={{
            rowKey: 'id',
            sortDirections: ['ascend', 'descend'],
            locale: { emptyText: 'No Reports Currently Match Your Filter' },
            loading: this.state.loading
          }}
          fetchData={this.fetchStateReports}
          title={
            <Button type="link" onClick={() => HistoryUtil.push(Routes.generate(Routes.PUBLIC_REPORTS))}>
              <Typography.Title level={4}>
                <Space size="large">
                  <ArrowLeftOutlined className="public-report-links" /> State Reports
                </Space>
              </Typography.Title>
            </Button>
          }
          styleOptions={{ compact: true, alternatingRowHighlight: true }}
        />
      </Content>
    );
  }

  private getTableColumns = (): DataTableColumnProps<StateReportsDTO>[] => {
    const generatedOn = DataTableColumnUtil.Date<StateReportsDTO>('Filed On', 'filedOn');
    generatedOn.render = DataTableCellRendererUtil.DateAndTime;
    const treeFilingPeriods = new Map<string, any>();
    const filterFilingPeriods: any = [];

    this.state.filterFilingPeriodDescs.forEach((p) => {
      if (this.state.year == '0') {
        if (treeFilingPeriods.has(p.year || '')) {
          const column = treeFilingPeriods.get(p.year || '');
          column.children.push({ title: p.periodDescription || '', key: p.code || '' });
          treeFilingPeriods.set(p.year || '', column);
        }
        else {
          treeFilingPeriods.set(
            p.year || '',
            { title: p.year, key: p.year || '', children: [{ title: p.periodDescription || '', key: p.code || '' }] });
        }
      }
      else {
        filterFilingPeriods.push({ text: p.periodDescription || '', value: p.code || '' });
      }
    });

    const filingDescriptionFilters = (this.state.year == '0' ? Array.from(treeFilingPeriods.values()) : filterFilingPeriods);
    const filterMode = (this.state.year == '0' ? { filterMode: 'tree' } : undefined);

    return [
      DataTableColumnUtil.Text('Year', 'periodYear', null, { defaultSortOrder: 'descend'}),
      DataTableColumnUtil.DropdownMulti(
        'Filing Period',
        'periodDescription',
        filingDescriptionFilters,
        null,
        filterMode as any),
      DataTableColumnUtil.DropdownMulti('Report Type', 'reportType',
        [
          { text: ReportTypeDesc.DR2, value: ReportTypeDesc.DR2 },
        ]),
      DataTableColumnUtil.DropdownMulti('Organization Type', 'organizationType',
        [
          { text: ReportingOrganizationTypes.CANDIDATE, value: ReportingOrganizationTypes.CANDIDATE },
          { text: ReportingOrganizationTypes.PAC, value: ReportingOrganizationTypes.PAC },
          { text: ReportingOrganizationTypes.PARTY, value: ReportingOrganizationTypes.PARTY }
        ]),
      DataTableColumnUtil.Text('Candidate', 'candidateName'),
      DataTableColumnUtil.Text('Committee Code', 'committeeCode'),
      DataTableColumnUtil.Text('Committee', 'committeeName'),
      generatedOn,
      DataTableColumnUtil.Buttons('fileName',
        [
          { text: 'View', onClick: (rowData) => window.open(rowData.fileUrl || '') },
        ])
    ];
  }

  public fetchFilingPeriods = (year: any, startYear: any) => {
    PublicReportsApiService.getFilingPeriodsByYear(year, startYear.toString(), true)
      .then((filterFilingPeriodDescs) => {
        this.setState({ filterFilingPeriodDescs });
      })
      .catch((error: any) => {
        notification.error({
          message: error.message,
          description: error.description,
        });
      });
  }

  private fetchStateReports = (
    requestState: TableRequestDTO,
    checkEcho: () => boolean,
    callback: (response: TableResponseDTO<StateReportsDTO>) => void
  ) => {
    this.setState({ loading: true });
    let currFilters = [...this._stateReportsDataTable?.state.filters || []];
    let yearFilter = currFilters.find(f => f.columnProp == 'periodYear')?.filter || '';
    let currSorters = { ...this._stateReportsDataTable?.state.sorter } || {};

    if (yearFilter == '') {
      yearFilter = '0';
    }

    if (!isNullOrUndefined(yearFilter)) {
      const columnState = [...this._stateReportsDataTable?.state.columnStates || []];
      if (yearFilter != this.state.year) {
        currFilters = currFilters?.filter(f => f.columnProp != 'periodDescription');
        columnState.forEach(c => {
          if (c.columnProp == 'periodDescription') {
            c.filtered = false;
            c.searchText = '';
          }
        });
        if (currSorters.columnKey == 'periodDescription') {
          currSorters = {};
        }
      }

      this._stateReportsDataTable?.setState({ filters: currFilters, columnStates: columnState, });
    }

    const newRequestState = { ...requestState };

    if (currFilters.length > 0) {
      newRequestState.filters = [...currFilters];
    }

    return PublicReportsApiService.getStateReports(newRequestState)
      .then((res) => {
        if (!checkEcho()) {
          return;
        }
        res.results?.forEach(r => {
            if (r.filedOn) {
                r.filedOn = moment.utc(r.filedOn).local();
            }
        });
        callback(res);
        this.fetchFilingPeriods(yearFilter, 1999);
        this.setState({ loading: false, year: yearFilter });
      });
  };
}

export default StateReports;