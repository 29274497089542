import { Button, FormInstance, notification, Input, Row, Col, ColProps, Space } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import * as React from 'react';
import DataTable, { DataTableColumnProps } from '../../shared/DataTable/DataTable';
import PersonReportDTO from '../../../models/PersonReportDTO';
import DataTableColumnUtil from '../../shared/DataTable/DataTableColumnUtil';
import AdminReportApiService from '../../../api/AdminReportApiService';
import DataTableButtonUtil from '../../shared/DataTable/DataTableButtonUtil';
import FormValidationUtil from '../../../utils/FormValidationUtil';
import PersonReportFormDTO from '../../../models/PersonReportFormDTO';
import CustomForm from '../../shared/CustomForm';
import * as StorageUtil from '../../../storage/StorageUtil';
import TableRequestDTO from '../../../models/TableRequestDTO';
import TableResponseDTO from '../../../models/TableResponseDTO';
import ReportTableExportDTO from '../../../models/ReportTableExportDTO';

interface PersonReportProps {
}

interface PersonReportState {
  personForm: PersonReportFormDTO;
  personTableColumns: DataTableColumnProps<PersonReportDTO>[];
  personData: PersonReportDTO[];
  personLoading: boolean;
  personExporting: boolean;
}

class PersonReport extends React.Component<PersonReportProps, PersonReportState> {
  private readonly _personFormRef = React.createRef<FormInstance>();
  constructor(props: PersonReportProps) {
    super(props);

    this.state = {
      personForm: { ...JSON.parse(StorageUtil.getPersonReportColumnFilters()) } as PersonReportDTO,
      personTableColumns: this.getPersonTableColumns(),
      personData: [],
      personLoading: false,
      personExporting: false,
    };
  }
  private personDataTable: DataTable<PersonReportDTO> | undefined;

  componentDidMount() {
    if (StorageUtil.getPersonReportColumnFilters() != '{}') {
      this.personDataTable?.refresh();
    }
  }

  render() {
    const { personForm, personTableColumns, personLoading, personExporting } = this.state;
    const twoFieldColumnSizingProps: ColProps = { xs: 12, sm: 12, md: 12 };
    const dataTableButtons = [];
    dataTableButtons.push(DataTableButtonUtil.Reset());
    dataTableButtons.push(DataTableButtonUtil.Export(
      (tableRequest: TableRequestDTO) => {
        this.setState({ personExporting: true });
        const firstName = this._personFormRef.current?.getFieldValue('firstName');
        const lastName = this._personFormRef.current?.getFieldValue('lastName');
        const exportDTO = ReportTableExportDTO.create({
          TableRequest: tableRequest,
          firstName: firstName,
          lastName: lastName
        });
        return AdminReportApiService.exportPersonList(exportDTO)
          .finally(() => {
            this.setState({ personExporting: false });
          }).catch(() => {
            notification.error({
              message: 'Error while exporting person reports'
            });
            this.setState({ personExporting: false });
          });
      }, personExporting));

    return (
      <>
        <CustomForm
          formRef={this._personFormRef}
          layout="vertical"
          initialValues={personForm}
          onFinish={() => this.personDataTable?.refresh()}
          validateTrigger={['onChange', 'onBlur']}>
          <Row gutter={24}>
            <Col {...twoFieldColumnSizingProps}>
              <FormItem name="firstName" label="First Name" >
                <Input maxLength={50} />
              </FormItem>
            </Col>
            <Col {...twoFieldColumnSizingProps}>
              <FormItem name="lastName" label="Last Name" rules={[FormValidationUtil.Required('Last Name is required')]}>
                <Input maxLength={50} />
              </FormItem>
            </Col>
          </Row>
          <Space>
            <Button type="primary" htmlType="submit">Search</Button>
          </Space>
        </CustomForm>
        <br /> <br />
        <DataTable
          ref={(element: any) => (this.personDataTable = element)}
          globalSearch={true}
          columns={personTableColumns}
          buttonBar={dataTableButtons}
          serverSide={true}
          tableProps={{
            rowKey: 'nameRoleCode',
            sortDirections: ['ascend', 'descend'],
            locale: {
              emptyText: 'No records were found that match your criteria'
            },
            loading: personLoading
          }}
          fetchData={this.fetchPersonReportData}
          styleOptions={{ compact: true, alternatingRowHighlight: true }}
          title={'Person Report'}
          stateSaving={{
            enabled: true,
            tableUniqueKey: 'personReports',
            perSession: true,
          }}
        />
      </>
    );
  }

  private getPersonTableColumns = (): DataTableColumnProps<PersonReportDTO>[] => {
    return [
      DataTableColumnUtil.Text('First Name', 'firstName'),
      DataTableColumnUtil.Text('Last Name', 'lastName'),
      DataTableColumnUtil.Text('Role', 'role'),
      DataTableColumnUtil.Number('Committee Code', 'committeeCode'),
      DataTableColumnUtil.Text('Committee Name', 'committeeName')
    ];
  }

  private fetchPersonReportData = (requestState: TableRequestDTO,
    checkEcho: () => boolean,
    callback: (response: TableResponseDTO<PersonReportDTO>) => void
  ) => {
    const firstName = this._personFormRef.current?.getFieldValue('firstName');
    const lastName = this._personFormRef.current?.getFieldValue('lastName');
    if (lastName) {
      this.setState({ personLoading: true });
      return AdminReportApiService.getPersonList(requestState, firstName, lastName)
        .then((res) => {
          if (!checkEcho()) {
            return;
          }
          callback(res);
          this.setState({ personLoading: false });
          const filterString = JSON.stringify({ ...this._personFormRef.current?.getFieldsValue() });
          StorageUtil.setPersonReportColumnFilters(filterString);
        }).catch(() => {
          notification.error({
            message: 'Error while fetching person committees'
          });
          this.setState({ personLoading: false });
        });
    }
  }
}

export default PersonReport;
