// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
import BaseApi from './BaseApi';


export class FeatureManagementApiService extends BaseApi {

  // get: api/featureManagement/isFeatureFlagEnabled?flagName=${encodeURIComponent(flagName)}
  public isFeatureFlagEnabled(flagName: string): Promise<boolean> {
    let url = `api/featureManagement/isFeatureFlagEnabled`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (flagName != null) {
      url += `${prefix}flagName=${encodeURIComponent(flagName)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, boolean>(null, 'get', url, true, false);
  }

  // get: api/featureManagement/getEnabledFeatures
  public getEnabledFeatures(): Promise<string[]> {
    let url = `api/featureManagement/getEnabledFeatures`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, string[]>(null, 'get', url, true, false);
  }
}
var service = new FeatureManagementApiService();
export default service;
