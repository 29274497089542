import {
  Button,
  Layout,
  notification,
  Space
} from 'antd';
import { FormInstance, FormProps } from 'antd/lib/form/Form';
import FormItem from 'antd/lib/form/FormItem';
import moment from 'moment';
import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import AdminIieApiService from '../../api/AdminIieApiService';
import Routes from '../../consts/Routes';
import AdminIiePenaltyDTO from '../../models/AdminIiePenaltyDTO';
import DateUtil from '../../utils/DateUtil';
import HistoryUtil from '../../utils/HistoryUtil';
import CustomForm from '../shared/CustomForm';
import PenaltyForm from './PenaltyForm';

const { Content } = Layout;

interface IiePenaltiesProps {
  id: string;
}

interface IiePenaltiesState {
  iiePenalty: AdminIiePenaltyDTO,
  id: string,
  loading: boolean,
  penaltyNoticeLoading: boolean,
}

class IiePenalty extends React.Component<RouteComponentProps<IiePenaltiesProps> & FormProps, IiePenaltiesState> {
  private readonly formRef = React.createRef<FormInstance>();

  constructor(props: RouteComponentProps<IiePenaltiesProps> & FormProps) {
    super(props);
    this.state = {
      id: props.match.params.id,
      iiePenalty: AdminIiePenaltyDTO.create({ penaltyStatus: 'OPEN', expendId: props.match.params.id }),
      loading: true,
      penaltyNoticeLoading: false,
    };
  }

  componentDidMount() {
    this.getInitialValues();
  }

  render() {
    const { loading, iiePenalty, penaltyNoticeLoading } = this.state;

    return (
      <Content className="content-pad">
        <h3 className="ant-typography">IIE Penalties</h3>
        {loading ? undefined :
          <CustomForm name="Penalties" onFinish={this.submitForm} initialValues={iiePenalty} layout="vertical" formRef={this.formRef}>
            <PenaltyForm penaltyNoticeDate={iiePenalty.penaltyIssuedDate != null ? DateUtil.toTimeLocal(moment(iiePenalty.penaltyIssuedDate)) : ''}/>
            <Space>
              <Button type="primary" htmlType="submit">Save Penalty</Button>
              <FormItem noStyle shouldUpdate>
                {({ getFieldValue }) =>
                  getFieldValue('penaltyPaidDate') === undefined || getFieldValue('penaltyPaidDate') === null ? (
                    <Button type="primary" loading={penaltyNoticeLoading}
                      onClick={() => this.sendLateNotice(this.formRef.current?.getFieldsValue())}>Save and Send Penalty Notice</Button>
                  ) : undefined}
              </FormItem>
              <Button onClick={this.cancelForm}>Cancel</Button>
            </Space>
          </CustomForm>
        }
      </Content>
    );
  }

  private submitForm = (values: any) => {
    AdminIieApiService.saveIiePenalty(this.state.id, { ...this.state.iiePenalty, ...values }, false)
      .then((result) => {
        if (result.succeeded) {
          HistoryUtil.push(Routes.MANAGE_IIE);
          notification.success({ message: 'Penalty saved successfully.' });
        }
      })
      .catch(error => {
        notification.error({ message: error.message, description: error.description });
      });
  }

  private sendLateNotice = (values: any) => {
    this.setState({ penaltyNoticeLoading: true });
    AdminIieApiService.saveIiePenalty(this.state.id, { ...this.state.iiePenalty, ...values }, true)
      .then((result) => {
        if (result.succeeded) {
          HistoryUtil.push(Routes.MANAGE_IIE);
          notification.success({ message: 'Penalty notice sent successfully.' });
        }
        else {
          if (result.errors[0] != null) {
            notification.error({ message: result.errors[0] });
          }
          else {
            notification.error({ message: 'Failed to send penalty notice.' });
          }
        }
        this.setState({ penaltyNoticeLoading: false });
      })
      .catch(error => {
        notification.error({ message: error.message, description: error.description });
        this.setState({ penaltyNoticeLoading: false });
      });
  }

  private cancelForm = () => {
    HistoryUtil.push(Routes.MANAGE_IIE);
  }

  private getInitialValues = () => {
    AdminIieApiService.getIiePenalty(this.state.id).then(iiePenalty => {
      // Make date values moments so that the date pickers work properly
      if (iiePenalty.penaltyDueDate) {
        iiePenalty.penaltyDueDate = moment.utc(iiePenalty.penaltyDueDate);
      }

      if (iiePenalty.penaltyIssuedDate) {
        iiePenalty.penaltyIssuedDate = moment.utc(iiePenalty.penaltyIssuedDate);
      }

      if (iiePenalty.penaltyPaidDate) {
        iiePenalty.penaltyPaidDate = moment.utc(iiePenalty.penaltyPaidDate);
      }

      if (iiePenalty.waivedDate) {
        iiePenalty.waivedDate = moment.utc(iiePenalty.waivedDate);
      }

      this.setState({ iiePenalty, loading: false });
    }).catch(() => {
      this.setState({ loading: false });
    });
  }
}

export default IiePenalty;
