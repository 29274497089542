// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import OtcFunderDTO from './OtcFunderDTO';
import EntitySelectorDTO from './EntitySelectorDTO';

interface OtcDTO extends EntitySelectorDTO { 
  contributionId: string | null;
  organizationName: string | null;
  organizationAddress1: string | null;
  organizationAddress2: string | null;
  organizationCity: string | null;
  organizationState: string | null;
  organizationZip: string | null;
  organizationEmail: string | null;
  organizationPhone: string | null;
  contactFirstName: string | null;
  contactMi: string | null;
  contactLastName: string | null;
  contactAddress1: string | null;
  contactAddress2: string | null;
  contactCity: string | null;
  contactState: string | null;
  contactZip: string | null;
  contactEmail: string | null;
  contactPhone: string | null;
  committeeName: string | null;
  committeeAddress1: string | null;
  committeeAddress2: string | null;
  committeeCity: string | null;
  committeeState: string | null;
  committeeZip: string | null;
  committeeEmail: string | null;
  committeePhone: string | null;
  dateOfContribution: moment.Moment | string | null;
  amount: number | null;
  dateOfElection: moment.Moment | string | null;
  county: string | null;
  corpFundInd: boolean;
  inKindInd: boolean;
  inKindDescription: string | null;
  trusteeName: string | null;
  trusteeAddress1: string | null;
  trusteeAddress2: string | null;
  trusteeCity: string | null;
  trusteeState: string | null;
  trusteeZip: string | null;
  trustorName: string | null;
  trustorAddress1: string | null;
  trustorAddress2: string | null;
  trustorCity: string | null;
  trustorState: string | null;
  trustorZip: string | null;
  writtenFilingDate: moment.Moment | string | null;
  note: string | null;
  noteId: string | null;
  funderList: OtcFunderDTO[] | null;
  filingStatus: string | null;
}
const OtcDTO = {
  create: (initValues?: Partial<OtcDTO> | Record<string, unknown> | null) => {
    return Object.assign(EntitySelectorDTO.create(),
    {
      contributionId: "00000000-0000-0000-0000-000000000000",
      organizationName: null,
      organizationAddress1: null,
      organizationAddress2: null,
      organizationCity: null,
      organizationState: null,
      organizationZip: null,
      organizationEmail: null,
      organizationPhone: null,
      contactFirstName: null,
      contactMi: null,
      contactLastName: null,
      contactAddress1: null,
      contactAddress2: null,
      contactCity: null,
      contactState: null,
      contactZip: null,
      contactEmail: null,
      contactPhone: null,
      committeeName: null,
      committeeAddress1: null,
      committeeAddress2: null,
      committeeCity: null,
      committeeState: null,
      committeeZip: null,
      committeeEmail: null,
      committeePhone: null,
      dateOfContribution: null,
      amount: null,
      dateOfElection: null,
      county: null,
      corpFundInd: false,
      inKindInd: false,
      inKindDescription: null,
      trusteeName: null,
      trusteeAddress1: null,
      trusteeAddress2: null,
      trusteeCity: null,
      trusteeState: null,
      trusteeZip: null,
      trustorName: null,
      trustorAddress1: null,
      trustorAddress2: null,
      trustorCity: null,
      trustorState: null,
      trustorZip: null,
      writtenFilingDate: null,
      note: null,
      noteId: null,
      funderList: [],
      filingStatus: null,
    },
    initValues);
  }
};

export default OtcDTO;