import {
  Button,
  Checkbox,
  Col,
  Collapse,
  ColProps,
  Divider,
  FormInstance,
  Input,
  Layout,
  notification,
  Radio,
  Row,
  Select,
  Space,
  Tabs,
  Typography
} from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import * as React from 'react';
import SearchableDatabaseApiService from '../../api/SearchableDatabaseApiService';
import SearchableDatabaseDTO from '../../models/SearchableDatabaseDTO';
import DataTable, { ColumnType, DataTableColumnProps, FilterType } from '../shared/DataTable/DataTable';
import DataTableColumnUtil from '../shared/DataTable/DataTableColumnUtil';
import moment from 'moment';
import DataTableButtonUtil from '../shared/DataTable/DataTableButtonUtil';
import CommitteeAutoSearch from '../shared/CommitteeSearch';
import CommitteeInfoDTO from '../../models/CommitteeInfoDTO';
import TransactionTypeIndicators from '../../consts/TransactionTypeIndicators';
import TransactionOptions from '../../consts/TransactionOptions';
import AddressFormFields from '../shared/AddressFormFields';
import TransactionTypeStatuses from '../../consts/TransactionTypeStatuses';
import ReferenceOptions from '../../consts/ReferenceOptions';
import FiledOptions from '../../consts/FiledOptions';
import CrossReferenceModal from './CrossReferenceModal';
import CurrencyInput from '../shared/CurrencyInput';
import CustomForm from '../shared/CustomForm';
import CustomDatePicker from '../shared/CustomDatePicker';
import CurrentUser from '../../utils/CurrentUser';
import Role from '../../consts/Role';
import * as StorageUtil from '../../storage/StorageUtil';
import DateUtil from '../../utils/DateUtil';
import NumberFormatUtil from '../../utils/NumberFormatUtil';

const { Title } = Typography;
const { Content } = Layout;
const { TabPane } = Tabs;
const { Panel } = Collapse;
const Option = Select.Option;

interface SearchableDatabaseProps {
  title?: React.ReactElement | string;
}

interface SearchableDatabaseState {
  loadingContributions: boolean;
  loadingExpenditures: boolean;
  exportingContributions: boolean;
  exportingExpenditures: boolean;
  contributionTableColumns: DataTableColumnProps<SearchableDatabaseDTO>[];
  expenditureTableColumns: DataTableColumnProps<SearchableDatabaseDTO>[];
  contributionData: SearchableDatabaseDTO[];
  expenditureData: SearchableDatabaseDTO[];
  transactionType: string;
  transactionOption: number;
  transactionOptions: { text: string, value: number }[];
  activeTabKey: string;
  referenceId: string;
  referenceType: string;
  showModal: boolean;
  disableDuplicate: boolean;
  enforceRequirements: boolean;
  tofromWhoRequired: boolean;
}

class SearchableDatabase extends React.Component<SearchableDatabaseProps, SearchableDatabaseState> {
  private readonly _formRef = React.createRef<FormInstance>();
  private contributorDataTable: DataTable<SearchableDatabaseDTO> | undefined;
  private receiverDataTable: DataTable<SearchableDatabaseDTO> | undefined;

  constructor(props: SearchableDatabaseProps) {
    super(props);

    this.state = {
      loadingContributions: false,
      loadingExpenditures: false,
      exportingContributions: false,
      exportingExpenditures: false,
      contributionTableColumns: this.getTableColumns('contribution'),
      expenditureTableColumns: this.getTableColumns('expenditure'),
      contributionData: [],
      expenditureData: [],
      transactionType: { ...JSON.parse(StorageUtil.getReviewAccountColumnFilters()) }.transactionType ?? TransactionTypeIndicators.CONTRIBUTION,
      transactionOption: { ...JSON.parse(StorageUtil.getReviewAccountColumnFilters()) }.transactionOption ?? TransactionOptions.TO_A_COMMITTEE,
      transactionOptions: { ...JSON.parse(StorageUtil.getReviewAccountColumnFilters()) }.transactionType == TransactionTypeIndicators.EXPENDITURE ?
        [
            { text: 'From a committee', value: TransactionOptions.FROM_A_COMMITTEE },
            { text: 'Paid to a committee', value: TransactionOptions.TO_A_COMMITTEE },
            { text: 'Paid to an individual', value: TransactionOptions.TO_AN_INDIVIDUAL },
            { text: 'Paid to an organization', value: TransactionOptions.TO_AN_ORGANIZATION }
        ] :
        [
            { text: 'To a committee', value: TransactionOptions.TO_A_COMMITTEE },
            { text: 'From a committee', value: TransactionOptions.FROM_A_COMMITTEE },
            { text: 'From an individual', value: TransactionOptions.FROM_AN_INDIVIDUAL },
            { text: 'From an organization', value: TransactionOptions.FROM_AN_ORGANIZATION }
        ],
      activeTabKey: {
        ...JSON.parse(StorageUtil.getReviewAccountColumnFilters())
      }.transactionType == TransactionTypeIndicators.EXPENDITURE ? '2' : '1',
      referenceId: '',
      referenceType: '',
      showModal: false,
      disableDuplicate: true,
      enforceRequirements: !CurrentUser.Get()?.isInRole(Role.IECDB_ADMINISTRATOR) || false,
      tofromWhoRequired: !CurrentUser.Get()?.isInRole(Role.IECDB_ADMINISTRATOR) || false
    };
  }

  organizationAddressComponent() {
    const { tofromWhoRequired } = this.state;
    return <AddressFormFields
      name={{ name: 'organizationName', required: tofromWhoRequired, onChange: this.handleToFromChange }}
      address1={{ name: 'addressLine1', required: tofromWhoRequired, onChange: this.handleToFromChange }}
      address2={{ name: 'addressLine2', required: tofromWhoRequired, onChange: this.handleToFromChange }}
      city={{ name: 'city', required: tofromWhoRequired, onChange: this.handleToFromChange }}
      state={{ name: 'state', required: tofromWhoRequired, onChange: this.handleToFromChange }}
      zip={{ name: 'zip', required: tofromWhoRequired, onChange: this.handleToFromChange }}
    />;
  }

  individualAddressComponent() {
    const { tofromWhoRequired } = this.state;
    return <AddressFormFields
      firstName={{ name: 'firstName', required: tofromWhoRequired, onChange: this.handleToFromChange }}
      middleInitial={{ name: 'middleInitial', required: tofromWhoRequired }}
      lastName={{ name: 'lastName', required: tofromWhoRequired, onChange: this.handleToFromChange }}
      address1={{ name: 'addressLine1', required: tofromWhoRequired, onChange: this.handleToFromChange }}
      address2={{ name: 'addressLine2', required: tofromWhoRequired, onChange: this.handleToFromChange }}
      city={{ name: 'city', required: tofromWhoRequired, onChange: this.handleToFromChange }}
      state={{ name: 'state', required: tofromWhoRequired, onChange: this.handleToFromChange }}
      zip={{ name: 'zip', required: tofromWhoRequired, onChange: this.handleToFromChange }}
    />;
  }

  primaryCommitteeComponent(label: string) {
    const { tofromWhoRequired } = this.state;
    return <CommitteeAutoSearch
      name="primaryCommittee"
      label={label}
      required={tofromWhoRequired}
      onFocus={this.handlePrimaryFocus}
      value={this._formRef.current?.getFieldValue('primaryCommittee')}
      onBlur={this.handlePrimaryBlur}
      onSelectCommittee={this.handleSelectPrimaryCommittee}
    />;
  }

  secondaryCommitteeComponent(label: string) {
    const { tofromWhoRequired } = this.state;
    return <CommitteeAutoSearch
      name="secondaryCommittee"
      label={label}
      value={this._formRef.current?.getFieldValue('secondaryCommittee')}
      required={tofromWhoRequired}
      onFocus={this.handleSecondaryFocus}
      onBlur={this.handleSecondaryBlur}
      onSelectCommittee={this.handleSelectSecondaryCommittee}
    />;
  }

  componentDidMount() {
    const { transactionType } = this.state;
    if (transactionType === TransactionTypeIndicators.CONTRIBUTION && StorageUtil.getReviewAccountColumnFilters() != '{}') {
      this.fetchContributionTableData();
    }
    if (transactionType === TransactionTypeIndicators.EXPENDITURE && StorageUtil.getReviewAccountColumnFilters() != '{}') {
      this.fetchExpenditureTableData();
    }
  }

  render() {
    const columnSizingProps: ColProps = { xs: 24, sm: 24, md: 12 };
    const { loadingContributions, loadingExpenditures, contributionTableColumns,
      expenditureTableColumns, contributionData, expenditureData,
      transactionType, transactionOption, transactionOptions, activeTabKey,
      referenceId, referenceType, showModal, disableDuplicate, enforceRequirements,
      exportingContributions, exportingExpenditures
    } = this.state;
    const { title } = this.props;
    const rowGutterLen = 24;

    const contributionActionButtons = [];
    const expenditureActionButtons = [];

    contributionActionButtons.push(DataTableButtonUtil.Reset());
    expenditureActionButtons.push(DataTableButtonUtil.Reset());

    const transactionTypes = [
      { text: 'Contributions', value: TransactionTypeIndicators.CONTRIBUTION },
      { text: 'Expenditures', value: TransactionTypeIndicators.EXPENDITURE }
    ];

      const formValues = { ...JSON.parse(StorageUtil.getReviewAccountColumnFilters()) };
      formValues.dateFrom = formValues.dateFrom != null ?  moment(formValues.dateFrom) : null;
      formValues.dateTo = formValues.dateTo != null ? moment(formValues.dateTo) : null;
    return (
      <>
        <Content className="content-pad">
        <CustomForm formRef={this._formRef}
            layout="vertical"
            onFinish={this.search}
            initialValues={formValues}>
            <Row gutter={rowGutterLen}>
              <Col {...columnSizingProps}>
                {title || <Title level={3}>Search</Title>}
                <FormItem name="primaryCommitteeId" hidden={true}> {/*Used for the transaction belongs to*/}
                </FormItem>
                <FormItem name="secondaryCommitteeId" hidden={true}> {/*Used for the committee the transaction was going to*/}
                </FormItem>
              </Col>
            </Row>
            <Row gutter={rowGutterLen}>
              <Col {...columnSizingProps}>
                <FormItem name="transactionType" label="Transaction Type">
                    <Select onChange={this.handleTransactionTypeChange}
                        defaultValue={transactionType}>
                        {transactionTypes.map(t => (
                          <Option key={t.value} value={t.value}>{t.text}</Option>
                        ))}
                     </Select>
                </FormItem>
              </Col>
              <Col {...columnSizingProps}>
                <FormItem name="transactionOptions" label="Transaction Option">
                    <Select onChange={this.handleTransactionOptionChange}
                        defaultValue={transactionOption}>
                        {transactionOptions.map(t => (
                          <Option key={t.value} value={t.value}>{t.text}</Option>
                        ))}
                    </Select>
                </FormItem>
              </Col>
            </Row>
            <Row gutter={rowGutterLen}>
              {transactionType == TransactionTypeIndicators.CONTRIBUTION && (
                <Col {...columnSizingProps}>
                  {this.primaryCommitteeComponent('To what committee?')}
                </Col>
              )}
              {transactionType == TransactionTypeIndicators.CONTRIBUTION && transactionOption == TransactionOptions.FROM_A_COMMITTEE && (
                <Col {...columnSizingProps}>
                  {this.secondaryCommitteeComponent('From what committee?')}
                </Col>
              )}
              {transactionType == TransactionTypeIndicators.CONTRIBUTION && transactionOption == TransactionOptions.FROM_AN_INDIVIDUAL && (
                <Col {...columnSizingProps}>
                  {this.individualAddressComponent()}
                </Col>
              )}
              {transactionType == TransactionTypeIndicators.CONTRIBUTION && transactionOption == TransactionOptions.FROM_AN_ORGANIZATION && (
                <Col {...columnSizingProps}>
                  {this.organizationAddressComponent()}
                </Col>
              )}
              {transactionType == TransactionTypeIndicators.EXPENDITURE && (
                <Col {...columnSizingProps}>
                  {this.primaryCommitteeComponent('From what committee?')}
                </Col>
              )}
              {transactionType == TransactionTypeIndicators.EXPENDITURE && transactionOption == TransactionOptions.TO_A_COMMITTEE && (
                <Col {...columnSizingProps}>
                  {this.secondaryCommitteeComponent('Paid to what committee?')}
                </Col>
              )}
              {transactionType == TransactionTypeIndicators.EXPENDITURE && transactionOption == TransactionOptions.TO_AN_INDIVIDUAL && (
                <Col {...columnSizingProps}>
                  {this.individualAddressComponent()}
                </Col>
              )}
              {transactionType == TransactionTypeIndicators.EXPENDITURE && transactionOption == TransactionOptions.TO_AN_ORGANIZATION && (
                <Col {...columnSizingProps}>
                  {this.organizationAddressComponent()}
                </Col>
              )}
              <Divider />
            </Row>
            <Collapse defaultActiveKey={['1']}>
              <Panel header="Additional Filters" key="1">
                <Row gutter={rowGutterLen}>
                  <Col {...columnSizingProps}>
                    <FormItem name="dateFrom" label="Date From">
                      <CustomDatePicker onChange={(d) => d?.startOf('day')} />
                    </FormItem>
                  </Col>
                  <Col {...columnSizingProps}>
                    <FormItem name="dateTo" label="Date To">
                      <CustomDatePicker onChange={(d) => d?.startOf('day')} />
                    </FormItem>
                  </Col>
                </Row>
                <Row gutter={rowGutterLen}>
                  <Col {...columnSizingProps}>
                    <FormItem name="amountFrom" label="Amount From">
                      <CurrencyInput min={undefined} />
                    </FormItem>
                  </Col>
                  <Col {...columnSizingProps}>
                    <FormItem name="amountTo" label="Amount To">
                      <CurrencyInput min={undefined} />
                    </FormItem>
                  </Col>
                </Row>
                <Row gutter={rowGutterLen}>
                  <Col {...columnSizingProps}>
                    <FormItem name="checkNumber" label="Check Number">
                      <Input />
                    </FormItem>
                  </Col>
                  <Col {...columnSizingProps}>
                    <Space>
                      {!enforceRequirements && (
                        <FormItem name="filedOption" label="Filed">
                          <Radio.Group defaultValue={FiledOptions.ALL}>
                            <Radio value={FiledOptions.ALL}>All</Radio>
                            <Radio value={FiledOptions.FILED}>Filed</Radio>
                            <Radio value={FiledOptions.NOTFILED}>Not Filed</Radio>
                          </Radio.Group>
                        </FormItem>
                      )}
                      <FormItem name="referenceOption" label="Reference">
                        <Radio.Group defaultValue={ReferenceOptions.ALL}>
                          <Radio value={ReferenceOptions.ALL}>All</Radio>
                          <Radio value={ReferenceOptions.HASREFERENCE}>Reference</Radio>
                          <Radio value={ReferenceOptions.HASNOREFERENCE}>No Reference</Radio>
                        </Radio.Group>
                      </FormItem>
                    </Space>
                  </Col>
                </Row>
                {!enforceRequirements && (
                  <Row gutter={rowGutterLen}>
                    <Col {...columnSizingProps}>
                      <FormItem name="potentialDuplicates" label="Potential Duplicates" valuePropName="checked" >
                        <Checkbox disabled={disableDuplicate} />
                      </FormItem>
                    </Col>
                  </Row>
                )}
              </Panel>
            </Collapse>
            <br />
            <Row gutter={rowGutterLen}>
              <Col {...columnSizingProps}>
                <Space>
                  <Button type="primary" htmlType="submit"
                    disabled={loadingContributions || loadingExpenditures}
                    loading={loadingContributions || loadingExpenditures}> Search</Button>
                  <Button onClick={this.handleExport} loading={exportingContributions || exportingExpenditures}>Export</Button>
                  <Button onClick={this.reset}>Reset</Button>
                </Space>
              </Col>
            </Row>
            <br />
            <Row>
              <Col span={24}>
                <Tabs activeKey={activeTabKey} onChange={(activeTabKey) => this.setState({ activeTabKey })} >
                  <TabPane tab="Contributions" key="1">
                    <DataTable
                      ref={(element: any) => (this.contributorDataTable = element)}
                      serverSide={false}
                      tableProps={{
                        rowKey: 'transactionId',
                        sortDirections: ['ascend', 'descend'],
                        locale: {
                          emptyText: 'No transactions were found that meet your criteria.'
                        },
                        loading: loadingContributions
                      }}
                      globalSearch={false}
                      buttonBar={contributionActionButtons}
                      columns={contributionTableColumns}
                      data={contributionData}
                      styleOptions={{ compact: true, alternatingRowHighlight: true }}
                      stateSaving={{
                        enabled: true,
                        tableUniqueKey: 'searchContributions',
                        perSession: true,
                      }}
                    />
                  </TabPane>
                  <TabPane tab="Expenditures" key="2">
                    <DataTable
                      ref={(element: any) => (this.receiverDataTable = element)}
                      serverSide={false}
                      tableProps={{
                        rowKey: 'transactionId',
                        sortDirections: ['ascend', 'descend'],
                        locale: {
                          emptyText: 'No transactions were found that meet your criteria.'
                        },
                        loading: loadingExpenditures
                      }}
                      globalSearch={false}
                      buttonBar={expenditureActionButtons}
                      columns={expenditureTableColumns}
                      data={expenditureData}
                      styleOptions={{ compact: true, alternatingRowHighlight: true }}
                      stateSaving={{
                        enabled: true,
                        tableUniqueKey: 'searchExpenditures',
                        perSession: true,
                      }}
                    />
                  </TabPane>
                </Tabs>
              </Col>
            </Row>
          </CustomForm>
        </Content>
        {showModal &&
          <CrossReferenceModal referenceId={referenceId} referenceType={referenceType} onClose={() => this.setState({ showModal: false })} />
        }
      </>
    );
  }

  private getTableColumns(tableName: 'contribution' | 'expenditure') {
    const columns: DataTableColumnProps<SearchableDatabaseDTO>[] = [];

    const columnDate: DataTableColumnProps<SearchableDatabaseDTO> = DataTableColumnUtil.Date('Date', 'date');
    columnDate.filterType = FilterType.NONE;
    columns.push(columnDate);

    if (tableName === 'contribution') {
      const columnReceivedBy: DataTableColumnProps<SearchableDatabaseDTO> = DataTableColumnUtil.Address('Received By', 'committeeName',
        (search) => ({
          name: search.primaryName,
          line1: search.primaryAddress1,
          line2: search.primaryAddress2,
          city: search.primaryCity,
          state: search.primaryState,
          zip: search.primaryZip
        })
      );
      columnReceivedBy.filterType = FilterType.Text;
      columns.push(columnReceivedBy);

      const columnContributedBy: DataTableColumnProps<SearchableDatabaseDTO> = DataTableColumnUtil.Address('Contributed By', 'contactName',
        (search) => ({
          name: search.secondaryName,
          line1: search.secondaryAddress1,
          line2: search.secondaryAddress2,
          city: search.secondaryCity,
          state: search.secondaryState,
          zip: search.secondaryZip
        })
      );
      columnContributedBy.filterType = FilterType.Text;
      columns.push(columnContributedBy);
    }

    if (tableName === 'expenditure') {
      const columnExpendedBy: DataTableColumnProps<SearchableDatabaseDTO> = DataTableColumnUtil.Address('Expended By', 'committeeName',
        (search) => ({
          name: search.primaryName,
          line1: search.primaryAddress1,
          line2: search.primaryAddress2,
          city: search.primaryCity,
          state: search.primaryState,
          zip: search.primaryZip
        })
      );
      columnExpendedBy.filterType = FilterType.Text;
      columns.push(columnExpendedBy);

      const columnReceivedBy: DataTableColumnProps<SearchableDatabaseDTO> = DataTableColumnUtil.Address('Received By', 'contactName',
        (search) => ({
          name: search.secondaryName,
          line1: search.secondaryAddress1,
          line2: search.secondaryAddress2,
          city: search.secondaryCity,
          state: search.secondaryState,
          zip: search.secondaryZip
        })
      );
      columnReceivedBy.filterType = FilterType.Text;
      columns.push(columnReceivedBy);
    }

    const columnAmount: DataTableColumnProps<SearchableDatabaseDTO> = DataTableColumnUtil.Currency('Amount', 'amount');
    columnAmount.filterType = FilterType.Text;
    columns.push(columnAmount);

    const columnCheckNumber: DataTableColumnProps<SearchableDatabaseDTO> = DataTableColumnUtil.Text('Check Number', 'checkNumber');
    columnCheckNumber.filterType = FilterType.Text;
    columnCheckNumber.sorter = (a: SearchableDatabaseDTO, b: SearchableDatabaseDTO): number => {
      if (!a.checkNumber && b.checkNumber) {
        return -1;
      }
      if (a.checkNumber && !b.checkNumber) {
        return 1;
      }

      if ((a.checkNumber?.length || 0) > (b.checkNumber?.length || 0)) {
        return 1;
      }
      if ((b.checkNumber?.length || 0) > (a.checkNumber?.length || 0)) {
        return -1;
      }

      if (a.checkNumber?.length === b.checkNumber?.length) {
        if (a.checkNumber && b.checkNumber) {
          if (a.checkNumber > b.checkNumber) {
            return 1;
          }
          if (b.checkNumber > a.checkNumber) {
            return -1;
          }
        }
      }

      return 0;
    };
    columns.push(columnCheckNumber);

    const columnTransactionType: DataTableColumnProps<SearchableDatabaseDTO> = DataTableColumnUtil.DropdownSingle('Type', 'transactionType',
      [
        { text: TransactionTypeStatuses.CONTRIBUTION, value: TransactionTypeIndicators.CONTRIBUTION },
        { text: TransactionTypeStatuses.INKINDCONTRIBUTION, value: TransactionTypeIndicators.INKINDCONTRIBUTION },
        { text: TransactionTypeStatuses.EXPENDITURE, value: TransactionTypeIndicators.EXPENDITURE }
      ]);
    columns.push(columnTransactionType);

    const columnReference: DataTableColumnProps<SearchableDatabaseDTO> = DataTableColumnUtil.CheckmarkButton('referenceId',
      [
        {
          onClick: (rowData) => this.openReferenceModal(rowData.referenceId || '', rowData.referenceType || ''),
          visible: (rowData) => rowData.referenceId != null
        }
      ]);
    columnReference.filterType = FilterType.BooleanRadio;
    columnReference.columnType = ColumnType.Boolean;
    columnReference.renderDataTransform = (value, record) => !!record.referenceId;
    columnReference.sorter = (a: SearchableDatabaseDTO, b: SearchableDatabaseDTO) => {
      if ((a.referenceId && b.referenceId) || (!a.referenceId && !b.referenceId)) {
        return 0;
      }
      if (a.referenceId && !b.referenceId) {
        return -1;
      }
      if (!a.referenceId && b.referenceId) {
        return 1;
      }
      return 0;
    };
    columnReference.sortDirections = ['ascend', 'descend'];
    columnReference.title = 'Reference';
    columns.push(columnReference);

    return columns as DataTableColumnProps<SearchableDatabaseDTO>[];
  }

  private fetchContributionTableData = () => {
    this.setState({ loadingContributions: true });
    const filterCriteria = { ...this.getFilterCriteria(TransactionTypeIndicators.CONTRIBUTION) };
    this._formRef.current?.validateFields()
      .then(() => {
        SearchableDatabaseApiService.getSearchableDatabaseList(filterCriteria)
          .then((results) => {
            if (results?.length === 3000) {
              notification.info({
                message: 'Maximum (3000) records found',
                description: 'Search results may be incomplete. Please narrow your search criteria and try again.',
                duration: 5
              });
            }
            this.setState({
              contributionData: results || [],
              loadingContributions: false,
              activeTabKey: '1'
            });
            this.contributorDataTable?.refresh();
          })
          .catch((error: any) => {
            this.setState({ loadingContributions: false });
            if (error.message?.includes('Execution Timeout Expired')) {
              notification.error({
                message: 'Search timed out',
                description: 'Please narrow your search criteria and try again.',
                duration: 5
              });
            }
            else {
              notification.error({
                message: error.message,
                description: error.description,
              });
            }
          });
      });
  }

  private fetchExpenditureTableData = () => {
    this.setState({ loadingExpenditures: true });
    const filterCriteria = { ...this.getFilterCriteria(TransactionTypeIndicators.EXPENDITURE) };
    this._formRef.current?.validateFields()
      .then(() => {
        SearchableDatabaseApiService.getSearchableDatabaseList(filterCriteria)
          .then((results) => {
            if (results?.length === 3000) {
              notification.info({
                message: 'Maximum (3000) records found',
                description: 'Search results may be incomplete. Please narrow your search criteria and try again.',
                duration: 5
              });
            }
            this.setState({
              expenditureData: results || [],
              loadingExpenditures: false,
              activeTabKey: '2'
            });
            this.receiverDataTable?.refresh();
          })
          .catch((error: any) => {
            this.setState({ loadingExpenditures: false });
            if (error.message?.includes('Execution Timeout Expired')) {
              notification.error({
                message: 'Search timed out',
                description: 'Please narrow your search criteria and try again.',
                duration: 5
              });
            }
            else {
              notification.error({
                message: error.message,
                description: error.description,
              });
            }
          });
      });
  }

  private handleCSVExport = (filteredData: any) => {
    const dataCopy = filteredData.map((fd: any) => ({ ...fd }));
    const titleKeys = Object.keys(dataCopy[0]);
    const refinedData = [];

    refinedData.push(titleKeys);

    dataCopy.forEach((d: any) => {
      const values = Object.keys(d).map((e) => d[e]);
      for (let i = 0; i < values.length; i++)
      {
        let d = values[i];
        if (d === undefined || d === null) {
          d = '';
        } else if (typeof d === 'string' && d.indexOf(',') >= 0) {
          values[i] = '"' + d.replace('"', '\\"') + '"';
        }
      }
      refinedData.push(values);
      }
    );
    
    const csvContent = refinedData.map((e: any) => e.join(',')).join('\n');
      const encodedUri = encodeURI('data:text/csv;base64,77u/' + btoa(unescape(encodeURIComponent(csvContent))));
    const dateTS = moment().format('MMddyyyy-hhmm');
    const fileName = 'Search Results ' + dateTS;
    const link = document.createElement('a');

    link.setAttribute('href', encodedUri);
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
   }

  private UpdateDTOHeadersForExport = (dto: SearchableDatabaseDTO) => {
    const updated = {};
    updated['Date'] = DateUtil.toShortDateString(moment(dto.date));

    if (this.state.transactionType === TransactionTypeIndicators.CONTRIBUTION) {
      updated['ReceivedByCommitteeCode'] = dto.primaryCommitteeCode;
      updated['ReceivedByName'] = dto.primaryName;
      updated['ReceivedByAddress1'] = dto.primaryAddress1;
      updated['ReceivedByAddress2'] = dto.primaryAddress2;
      updated['ReceivedByCity'] = dto.primaryCity;
      updated['ReceivedByState'] = dto.primaryState;
      updated['ReceivedByZip'] = dto.primaryZip;
      updated['ContributedByCommitteeCode'] = dto.secondaryCommitteeCode;
      updated['ContributedByName'] = dto.secondaryName;
      updated['ContributedByAddress1'] = dto.secondaryAddress1;
      updated['ContributedByAddress2'] = dto.secondaryAddress2;
      updated['ContributedByCity'] = dto.secondaryCity;
      updated['ContributedByState'] = dto.secondaryState;
      updated['ContributedByZip'] = dto.secondaryZip;
    }
    else {
      updated['ExpendedByCommitteeCode'] = dto.primaryCommitteeCode;
      updated['ExpendedByName'] = dto.primaryName;
      updated['ExpendedByAddress1'] = dto.primaryAddress1;
      updated['ExpendedByAddress2'] = dto.primaryAddress2;
      updated['ExpendedByCity'] = dto.primaryCity;
      updated['ExpendedByState'] = dto.primaryState;
      updated['ExpendedByZip'] = dto.primaryZip;
      updated['ReceivedByCommitteeCode'] = dto.secondaryCommitteeCode;
      updated['ReceivedByName'] = dto.secondaryName;
      updated['ReceivedByAddress1'] = dto.secondaryAddress1;
      updated['ReceivedByAddress2'] = dto.secondaryAddress2;
      updated['ReceivedByCity'] = dto.secondaryCity;
      updated['ReceivedByState'] = dto.secondaryState;
      updated['ReceivedByZip'] = dto.secondaryZip;
    }

    updated['TransactionType'] = dto.transactionType;
    updated['Amount'] = dto.amount != null ? NumberFormatUtil.currency(dto.amount) : '$0.00';
    updated['CheckNumber'] = dto.checkNumber;
    updated['HasReference'] = dto.referenceId != null ? 'Yes' : 'No';

    return updated; 
  }

  private handleExport = () => {
    this._formRef.current?.validateFields()
      .then(() => {
        if (this.state.transactionType === TransactionTypeIndicators.CONTRIBUTION) {
          this.setState({ exportingContributions: true });
          const filteredData = this.contributorDataTable?.state.filteredData || this.contributorDataTable?.state.data ||[];
          this.handleCSVExport(filteredData.map(d => {
            return this.UpdateDTOHeadersForExport(d);
          }));
          this.setState({ exportingContributions: false });
        }
        else if (this.state.transactionType === TransactionTypeIndicators.EXPENDITURE) {
          this.setState({ exportingExpenditures: true });
          const filteredData = this.receiverDataTable?.state.filteredData || this.receiverDataTable?.state.data || [];
          this.handleCSVExport(filteredData.map(d => {
             return this.UpdateDTOHeadersForExport(d);
          }));
          this.setState({ exportingExpenditures: false });
        }
      });
  }

  private getFilterCriteria = (transactionType: string) => {
    const filterCriteria = { ...this._formRef.current?.getFieldsValue() };
    filterCriteria.transactionType = transactionType;
    filterCriteria.transactionOption = this.state.transactionOption;

    if (filterCriteria.DateFrom) {
      filterCriteria.DateFrom = moment.utc(filterCriteria.DateFrom);
    }

    if (filterCriteria.DateTo) {
      filterCriteria.DateTo = moment.utc(filterCriteria.DateTo);
    }

    if (!filterCriteria.primaryCommitteeId) {
      filterCriteria.primaryCommitteeId = null;
    }

    if (!filterCriteria.secondaryCommitteeId) {
      filterCriteria.secondaryCommitteeId = null;
      }

      const filterString = JSON.stringify({ ...filterCriteria });
    StorageUtil.setReviewAccountColumnFilters(filterString);

    if (filterCriteria.organizationName) {
      filterCriteria.organizationName = encodeURIComponent(filterCriteria.organizationName);
    }

    if (filterCriteria.firstName) {
      filterCriteria.firstName = encodeURIComponent(filterCriteria.firstName);
    }

    if (filterCriteria.lastName) {
      filterCriteria.lastName = encodeURIComponent(filterCriteria.lastName);
    }

    if (filterCriteria.addressLine1) {
      filterCriteria.addressLine1 = encodeURIComponent(filterCriteria.addressLine1);
    }

    if (filterCriteria.addressLine2) {
      filterCriteria.addressLine2 = encodeURIComponent(filterCriteria.addressLine2);
    }

    if (filterCriteria.city) {
      filterCriteria.city = encodeURIComponent(filterCriteria.city);
    }

    if (filterCriteria.state) {
      filterCriteria.state = encodeURIComponent(filterCriteria.state);
    }

    if (filterCriteria.zip) {
      filterCriteria.zip = encodeURIComponent(filterCriteria.zip);
    }

    // We could encode this so it doesn't conflict with payload but we don't need it.
    filterCriteria.primaryCommittee = null;
    filterCriteria.secondaryCommittee = null;

    return filterCriteria;
  }

  private search = () => {
    const { transactionType } = this.state;
    if (transactionType === TransactionTypeIndicators.CONTRIBUTION) {
      this.fetchContributionTableData();
    }
    if (transactionType === TransactionTypeIndicators.EXPENDITURE) {
      this.fetchExpenditureTableData();
    }
  }

  private reset = () => {
    this._formRef.current?.setFieldsValue({
      primaryCommitteeId: '',
      secondaryCommitteeId: '',
      primaryCommittee: '',
      secondaryCommittee: '',
      firstName: '',
      middleInitial: '',
      lastName: '',
      organizationName: '',
      addressLine1: '',
      addressLine2: '',
      city: '',
      state: '',
      zip: '',
      dateFrom: null,
      dateTo: null,
      amountFrom: null,
      amountTo: null,
      checkNumber: '',
      referenceOption: ReferenceOptions.ALL,
      filedOption: FiledOptions.ALL,
    });
  }

  private handleToFromChange = () => {
    const fields = this._formRef.current?.getFieldsValue([
      'primaryCommitteeId',
      'primaryCommittee',
      'secondaryCommitteeId',
      'secondaryCommittee',
      'firstName',
      'initialName',
      'lastName',
      'organizationName',
      'addressLine1',
      'city',
      'state',
      'zip'
    ]);

    this.setState({
      tofromWhoRequired: Object.values(fields).filter(s => s).length === 0
    });
  }

  private handleSelectPrimaryCommittee = (committee: CommitteeInfoDTO | undefined) => {
    this._formRef.current?.setFieldsValue({ primaryCommitteeId: committee?.id });
    this.setState({ disableDuplicate: false });
    this.handleToFromChange();
  }

  private handleSelectSecondaryCommittee = (committee: CommitteeInfoDTO | undefined) => {
    this._formRef.current?.setFieldsValue({ secondaryCommitteeId: committee?.id });
    this.handleToFromChange();
  }

  private handlePrimaryFocus = () => {
    if (this._formRef.current) {
      this._formRef.current.setFieldsValue({ 'primaryCommitteeId': '', 'primaryCommittee': '', 'potentialDuplicates': false });
      this.setState({ disableDuplicate: true });
      this.handleToFromChange();
    }
  }

  private handleSecondaryFocus = () => {
    if (this._formRef.current) {
      this._formRef.current.setFieldsValue({ 'secondaryCommitteeId': '', 'secondaryCommittee': '' });
      this.handleToFromChange();
    }
  }

  private handlePrimaryBlur = () => {
    if (this._formRef.current) {
      if (!this._formRef.current.getFieldValue('primaryCommitteeId')) {
        this._formRef.current.setFieldsValue({ 'primaryCommittee': '', 'potentialDuplicates': false });
        this.setState({ disableDuplicate: true });
        this.handleToFromChange();
      }
    }
  }

  private handleSecondaryBlur = () => {
    if (this._formRef.current) {
      if (!this._formRef.current.getFieldValue('secondaryCommitteeId')) {
        this._formRef.current.setFieldsValue({ 'secondaryCommittee': '' });
        this.setState({ disableDuplicate: true });
        this.handleToFromChange();
      }
    }
  }

  private handleTransactionTypeChange = (transactionType: any) => {
    const contributionOptions = [
      { text: 'To a committee', value: TransactionOptions.TO_A_COMMITTEE },
      { text: 'From a committee', value: TransactionOptions.FROM_A_COMMITTEE },
      { text: 'From an individual', value: TransactionOptions.FROM_AN_INDIVIDUAL },
      { text: 'From an organization', value: TransactionOptions.FROM_AN_ORGANIZATION }
    ];
    const expenditureOptions = [
      { text: 'From a committee', value: TransactionOptions.FROM_A_COMMITTEE },
      { text: 'Paid to a committee', value: TransactionOptions.TO_A_COMMITTEE },
      { text: 'Paid to an individual', value: TransactionOptions.TO_AN_INDIVIDUAL },
      { text: 'Paid to an organization', value: TransactionOptions.TO_AN_ORGANIZATION }
    ];

    if (transactionType == TransactionTypeIndicators.CONTRIBUTION) {
      this.setState({
        transactionType,
        transactionOption: TransactionOptions.TO_A_COMMITTEE,
        transactionOptions: contributionOptions,
        activeTabKey: '1'
      });
      this._formRef.current?.setFieldsValue({ transactionOptions: 1 });
    }
    else if (transactionType == TransactionTypeIndicators.EXPENDITURE) {
      this.setState({
        transactionType,
        transactionOption: TransactionOptions.FROM_A_COMMITTEE,
        transactionOptions: expenditureOptions,
        activeTabKey: '2'
      });
      this._formRef.current?.setFieldsValue({ transactionOptions: 2 });
    }
  }

  private handleTransactionOptionChange = (transactionOption: any) => {
    this.setState({ transactionOption });
    this.clearToFromFields();
  }

  private clearToFromFields = () => {
    this._formRef.current?.resetFields(
      [
        'secondaryCommitteeId',
        'secondaryCommittee',
        'firstName',
        'initialName',
        'lastName',
        'organizationName',
        'addressLine1',
        'addressLine2',
        'city',
        'state',
        'zip'
      ]
    );
    this.handleToFromChange();
  }

  private openReferenceModal = (referenceId: string, referenceType: string) => {
    if (referenceType && referenceId) {
      this.setState({ referenceId, referenceType, showModal: true });
    }
  }
}

export default SearchableDatabase;
