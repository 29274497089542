import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Card, Col, ColProps, Row, Space, Typography } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import * as React from 'react';
import Routes from '../../../consts/Routes';
import HistoryUtil from '../../../utils/HistoryUtil';

interface DatasetsProps {
}

interface DatasetsState {
}

class Datasets extends React.Component<DatasetsProps, DatasetsState> {

  constructor(props: DatasetsProps) {
    super(props);

    this.state = {
    };
  }

  render() {
    const Reports: { title: string, route?: string, externalLink?: string, description: string }[] = [
      {
        title: 'Campaign Contributions Dataset',
        externalLink: 'https://data.iowa.gov/Campaigns-Elections/Iowa-Campaign-Contributions-Received/smfg-ds7h/data',
        description: 'View campaign contributions data.'
      },
      {
        title: 'Campaign Expenditures Dataset',
        externalLink: 'https://data.iowa.gov/Campaigns-Elections/Iowa-Campaign-Expenditures/3adi-mht4/data',
        description: 'View campaign expenditures data.'
      },
      {
        title: 'Independent Expenditures Dataset',
        externalLink: 'https://data.iowa.gov/Campaigns-Elections/Independent-Expenditures-For-or-Against-State-Cand/8u5j-u74n/data',
        description: 'View independent expenditures data.'
      },
      {
        title: 'Registered Political Candidates, Committees, and Entities',
        externalLink: 'https://data.iowa.gov/Campaigns-Elections/Registered-Political-Candidates-Committees-and-Ent/5dtu-swbk/data',
        description: 'View address data for registered candidates, committees, and other entities.'
      },
      {
        title: 'Other State/Federal Political Action Committees',
        externalLink: 'https://data.iowa.gov/Campaigns-Elections/Other-State-Federal-Political-Action-Committees-Fi/t3k4-yym7/data',
        description: 'View address data for other state and federal political action committees.'
      },
      {
        title: 'Other Iowa Ethics & Campaign Disclosure Board Datasets',
        externalLink: 'https://data.iowa.gov/browse?category=Campaigns+%26+Elections',
        description: 'View other reports, filtered views, and datasets hosted on data.iowa.gov.'
      }
    ];

    const half = Math.ceil(Reports.length / 2);
    const columnSizingProps: ColProps = { xs: 24, sm: 24, md: 12 };

    return (
      <Content className="content-pad">
        <Button type="link" onClick={() => HistoryUtil.push(Routes.generate(Routes.PUBLIC_REPORTS))}>
          <Typography.Title level={4}>
            <Space size="large">
              <ArrowLeftOutlined className="public-report-links"/> Additional Resources
            </Space>
          </Typography.Title>
        </Button>
        <Row gutter={24}>
          <Col {...columnSizingProps} >
            {Reports.splice(0, half).map(r => (
              this.createReport(r)
            ))}
          </Col>
          <Col {...columnSizingProps}>
            {Reports.slice(-half).map(r => (
              this.createReport(r)
            ))}
          </Col>
        </Row>
      </Content>
    );
  }

  private createReport = (report: any) => {
    return (
      <Card bordered={false}>
        <Card.Meta
          title={
            <a
              type="link"
              onClick={() => {
                if (report.externalLink) {
                  window.open(report.externalLink);
                }
                else {
                  HistoryUtil.push(Routes.generate(report.route));
                }
              }}
              className="public-report-links"
            >
              {report.title}
            </a>
          }
          description={report.description}
        />
      </Card>
    );
  }
}

export default Datasets;