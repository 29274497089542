import { Content } from 'antd/lib/layout/layout';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import DataTableColumnUtil from '../../components/shared/DataTable/DataTableColumnUtil';
import FilingStatus from '../../consts/FilingStatus';
import CurrentUser from '../../utils/CurrentUser';
import DataTable, { DataTableColumnProps } from '../shared/DataTable/DataTable';
import DataTableButtonUtil from '../shared/DataTable/DataTableButtonUtil';;
import BuildingFundApiService from '../../api/BuildingFundApiService';
import BuildingFundFilingDTO from '../../models/BuildingFundFilingDTO';


const FilingStatusLookUp = [
    { text: FilingStatus.ADJUSTED, value: FilingStatus.ADJUSTED },
    { text: FilingStatus.AMENDED, value: FilingStatus.AMENDED },
    { text: FilingStatus.AUDITED, value: FilingStatus.AUDITED },
    { text: FilingStatus.FILED, value: FilingStatus.FILED },
    { text: FilingStatus.NEVER_FILED, value: FilingStatus.NEVER_FILED },
    { text: FilingStatus.NOT_FILED, value: FilingStatus.NOT_FILED },
];

interface BuildingFundFilingHistoryTableProps {
    id: string;
}

interface BuildingFundFilingHistoryTableState {
    filingDataColumns: DataTableColumnProps<BuildingFundFilingDTO>[];
    loading: boolean;
    filingData: BuildingFundFilingDTO[];
}

class BuildingFundFilingHistoryTable extends React.Component<RouteComponentProps<BuildingFundFilingHistoryTableProps>,
    BuildingFundFilingHistoryTableState> {
    private filingDataTable: DataTable<BuildingFundFilingDTO> | undefined;

    constructor(props: RouteComponentProps<BuildingFundFilingHistoryTableProps>) {
        super(props);

        this.state = {
            filingDataColumns: this.getTableColumns(),
            loading: false,
            filingData: [],
        };
    }

    render() {
        const manageActionButtons = [];
        manageActionButtons.push(DataTableButtonUtil.Reset());

        return (
            <Content className="content-pad">
                <DataTable
                    ref={(element: any) => (this.filingDataTable = element)}
                    serverSide={true}
                    tableProps={{
                        rowKey: 'buildingFundFilingId',
                        sortDirections: ['ascend', 'descend'],
                        locale: {
                            emptyText: 'Currently there is no Filing History to View'
                        }
                    }}
                    globalSearch={true}
                    title="Filing Period"
                    buttonBar={manageActionButtons}
                    columns={this.state.filingDataColumns}
                    fetchData={{
                        fetch: function (tableRequest) {
                            return BuildingFundApiService.getBuildingFundFilings(tableRequest, CurrentUser.Get()?.committeeId || '');
                        },
                        failureMessage: 'Failed to retrieve filing history'
                    }}
                    styleOptions={{ compact: true, alternatingRowHighlight: true }}
                />
            </Content>
        );
    }

    private getTableColumns() {
        const columns: DataTableColumnProps<BuildingFundFilingDTO>[] = [];

        const columnYear: DataTableColumnProps<BuildingFundFilingDTO>
            = DataTableColumnUtil.Text('Year', 'coveredYear', 100, { defaultSortOrder: 'descend' });
        columns.push(columnYear);

        const columnFiledDt: DataTableColumnProps<BuildingFundFilingDTO>
            = DataTableColumnUtil.Date('Filed Date', 'filedDate', 120);
        columns.push(columnFiledDt);

        const columnAuditedDt: DataTableColumnProps<BuildingFundFilingDTO>
            = DataTableColumnUtil.Date('Audited Date', 'auditDate', 120);
        columns.push(columnAuditedDt);

        const columnBeginBal: DataTableColumnProps<BuildingFundFilingDTO>
            = DataTableColumnUtil.Currency('Starting Balance', 'startingBalance', 120);
        columns.push(columnBeginBal);

        const columnEndBal: DataTableColumnProps<BuildingFundFilingDTO>
            = DataTableColumnUtil.Currency('Ending Balance', 'endingBalance', 120);
        columns.push(columnEndBal);

        const columnStatus: DataTableColumnProps<BuildingFundFilingDTO>
            = DataTableColumnUtil.DropdownMulti('Status', 'filingStatusNm', FilingStatusLookUp, 100);
        columns.push(columnStatus);

        return columns;
    };
}

export default BuildingFundFilingHistoryTable;
