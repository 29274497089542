import { Divider, Layout, Modal, notification, Typography } from 'antd';
import * as React from 'react';
import SchAContributionsApiService from '../../api/SchAContributionsApiService';
import ScheduleApiService from '../../api/ScheduleApiService';
import Dr2AmendedIndicators from '../../consts/Dr2AmendedIndicators';
import Dr2AmendedStatuses from '../../consts/Dr2AmendedStatuses';
import NoteEntityParents from '../../consts/NoteEntityParents';
import Role from '../../consts/Role';
import Routes from '../../consts/Routes';
import ScheduleAbbreviationCodes from '../../consts/ScheduleAbbreviationCodes';
import SchAContributionsDTO from '../../models/SchAContributionsDTO';
import CurrentUser from '../../utils/CurrentUser';
import HistoryUtil from '../../utils/HistoryUtil';
import NumberFormatUtil from '../../utils/NumberFormatUtil';
import CrossReferenceModal from '../shared/CrossReferenceModal';
import DataTable, { ColumnType, DataTableColumnProps, FilterType } from '../shared/DataTable/DataTable';
import DataTableButtonUtil from '../shared/DataTable/DataTableButtonUtil';
import DataTableColumnUtil from '../shared/DataTable/DataTableColumnUtil';
import NotesModal from '../shared/NotesModal';
import Dr2StatusBar from './Dr2StatusBar';

const { Content } = Layout;
const { Text } = Typography;
const { confirm } = Modal;

interface SchAContributionsProps {
}

interface SchAContributionsState {
    tableColumns: DataTableColumnProps<SchAContributionsDTO>[];
    numContributions: number;
    totalOfSched: number;
    flagNoteId: string;
    contributionId: string;
    showModal: boolean;
    showReferenceModal: boolean;
    referenceId: string;
    referenceType: string;
}

class SchAContributions extends React.Component<SchAContributionsProps, SchAContributionsState> {
    private dataTable: DataTable<SchAContributions> | undefined;

    constructor(props: SchAContributionsProps) {
        super(props);

        this.state = {
            tableColumns: this.getTableColumns(),
            numContributions: 0,
            totalOfSched: 0,
            flagNoteId: '',
            contributionId: '',
            showModal: false,
            showReferenceModal: false,
            referenceId: '',
            referenceType: '',
        };
    }

    componentDidMount() {
        this.getStatistics();
    }

    render() {
        const { numContributions, totalOfSched, showModal, flagNoteId, contributionId, referenceId, referenceType, showReferenceModal } = this.state;
        const actionButtons = [];
        actionButtons.push(DataTableButtonUtil.Reset());
        actionButtons.push(DataTableButtonUtil.Primary(
            'New Contribution',
            () => HistoryUtil.push(Routes.generate(Routes.ADD_SCHEDULE_A_CONTRIBUTION, {}))
        ));
        actionButtons.push(DataTableButtonUtil.Default(
            'Import',
            () => HistoryUtil.push(Routes.generate(Routes.IMPORT_SCHEDULE_A_CONTRIBUTIONS, {}))
        ));

        const currUser = CurrentUser.Get();

        return (
            <>
                <Content className="content-pad">
                    <Dr2StatusBar dr2Id={currUser?.dr2Id || ''} />
                    <Text strong>
                        Number of Contributions: {numContributions}&emsp;|&emsp;Total of Schedule: {NumberFormatUtil.currency(totalOfSched)} &emsp;|
                    </Text>
                    <NotesModal
                        parentId={currUser?.dr2Id || ''}
                        parent={NoteEntityParents.SCHEDULE}
                        scheduleCd={ScheduleAbbreviationCodes.SCHEDULEA} />
                    <Divider />
                    <DataTable
                        buttonBar={actionButtons}
                        columns={this.state.tableColumns}
                        fetchData={{
                            fetch: function (tableRequest) {
                                return SchAContributionsApiService.getSchAContributions(tableRequest, currUser?.dr2Id || '');
                            },
                            failureMessage: 'Failed to retrieve contributions'
                        }}
                        globalSearch={true}
                        ref={(element: any) => (this.dataTable = element)}
                        serverSide={true}
                        styleOptions={{ compact: true, alternatingRowHighlight: true }}
                        tableProps={{
                            rowKey: 'contributionIdAndSeq',
                            sortDirections: ['ascend', 'descend'],
                            locale: { emptyText: 'Currently there are no Contributions to manage.' }
                        }}
                        title="Schedule A - Contributions"
                        stateSaving={{
                            enabled: true,
                            tableUniqueKey: 'manageSchA',
                            perSession: true,
                        }}
                    />
                </Content>
                {showModal &&
                    <NotesModal
                        parentId={contributionId || ''}
                        parent={NoteEntityParents.CONTRIBUTION}
                        auditorsNoteId={flagNoteId}
                        canFlag={true}
                        onClose={() => { this.setState({ showModal: false }, () => this.dataTable?.refresh()); }} />
                }
                {showReferenceModal &&
                    <CrossReferenceModal
                    referenceId={referenceId}
                    referenceType={referenceType}
                    onClose={() => this.setState({ showReferenceModal: false }, () => this.dataTable?.refresh())} />
                }
            </>
        );
    }

    private getStatistics = () => {
        ScheduleApiService.getScheduleCount(CurrentUser.Get()?.dr2Id || '', ScheduleAbbreviationCodes.SCHEDULEA)
            .then(numContributions => {
                this.setState({ numContributions });
            }).catch(error => {
                notification.error({
                    message: 'Error while fetching the number of contributions',
                    description: error.message
                });
            });

        ScheduleApiService.getScheduleTotal(CurrentUser.Get()?.dr2Id || '', ScheduleAbbreviationCodes.SCHEDULEA)
            .then(totalOfSched => {
                this.setState({ totalOfSched });
            }).catch(error => {
                notification.error({
                    message: 'Error while fetching the total of schedule',
                    description: error.message
                });
            });
    }

    private getTableColumns = (): DataTableColumnProps<SchAContributionsDTO>[] => {
        const columnReference: DataTableColumnProps<SchAContributionsDTO> = DataTableColumnUtil.CheckmarkButton('referenceId',
            [
                {
                    onClick: (rowData) => this.openReferenceModal(rowData.referenceId || '', rowData.referenceType || ''),
                    visible: (rowData) => rowData.referenceId != null
                }
            ]);
        columnReference.filterType = FilterType.BooleanRadio;
        columnReference.columnType = ColumnType.Boolean;
        columnReference.title = 'Reference';
        return [
            DataTableColumnUtil.Date('Date', 'contributionDt', 100, { defaultSortOrder: 'ascend' }),
            DataTableColumnUtil.Address('Contributor', 'contributorName',
                (c) => ({
                    name: c.contributorName,
                    line1: c.contributorAddressLine1,
                    line2: c.contributorAddressLine2,
                    city: c.contributorCity,
                    state: c.contributorState,
                    zip: c.contributorZip
                })
            ),
            DataTableColumnUtil.Currency('Amount', 'contributionAmt'),
            DataTableColumnUtil.Text('Check #', 'checkNumber', 125, { align: 'center' }),
            DataTableColumnUtil.DropdownMulti('Status', 'status',
                [
                    { text: Dr2AmendedStatuses.ORIGINAL, value: Dr2AmendedIndicators.ORIGINAL },
                    { text: Dr2AmendedStatuses.AMENDED, value: Dr2AmendedIndicators.AMENDED },
                    { text: Dr2AmendedStatuses.ADJUSTED, value: Dr2AmendedIndicators.ADJUSTED },
                    { text: Dr2AmendedStatuses.DELETED, value: Dr2AmendedIndicators.DELETED }
                ]),
            DataTableColumnUtil.Description('Description', 'description'),
            columnReference,
            DataTableColumnUtil.FlagButton('Flagged', 'flaggedNoteId', CurrentUser.Get()?.isInRole(Role.IECDB_ADMINISTRATOR) || false, [
                {
                    onClick: (rowData) => this.openFlagModal(rowData.flaggedNoteId || '', rowData.contributionId || ''),
                    visible: (rowData) => rowData.flaggedNoteId != null
                }
            ]),
            DataTableColumnUtil.Buttons('contributionIdAndSeq',
                [
                    {
                        text: 'Edit',
                        onClick: (rowData) =>
                            HistoryUtil.push(Routes.generate(
                                Routes.EDIT_SCHEDULE_A_CONTRIBUTION,
                                {
                                    id: rowData.contributionId || '',
                                    seqNum: rowData.seqNumber || '',
                                }))
                    },
                    {
                        text: 'Delete',
                        onClick: (rowData) => this.confirmDelete(
                            rowData.contributionId || '',
                            rowData.seqNumber || 0,
                            rowData.contributorName || '',
                            rowData.contributionAmt || 0)
                    }
                ],
                150)
        ];
    }

    private openFlagModal = (flagNoteId: string, contributionId: string) => {
        this.setState({ showModal: true, flagNoteId, contributionId }, () => this.dataTable?.refresh());
    }

    private showTableAndRefresh = () => {
        if (this.dataTable) {
            this.dataTable.refresh();
        }
        this.getStatistics();
    }

    private confirmDelete = (contributionId: string, seqNum: number, contributorName: string, amount: number) => {
        confirm({
            title: 'Are you sure you want to delete this contribution?',
            content: 'The following record will be permanently deleted: ' + contributorName + ' - $' + amount,
            onOk: () => {
                this.deleteContribution(contributionId, seqNum);
            },
        });
    }

    private deleteContribution = (contributionId: string, seqNum: number) => {
        SchAContributionsApiService.delete(contributionId, seqNum)
            .then(() => {
                this.showTableAndRefresh();
                notification.success({
                    message: 'Deleted Successfully'
                });
            }).catch(() => {
                notification.error({
                    message: 'Error while deleting contribution',
                    description: '',
                });
            });
    }

    private openReferenceModal = (referenceId: string, referenceType: string) => {
        if (referenceType && referenceId) {
            this.setState({ referenceId, referenceType, showReferenceModal: true });
        }
    }
}

export default SchAContributions;