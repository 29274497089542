import { Button, Space, Typography } from 'antd';
import * as React from 'react';
import DataTable, { DataTableColumnProps } from '../../shared/DataTable/DataTable';
import BuildingFundReportDTO from '../../../models/BuildingFundReportDTO';
import { Content } from 'antd/lib/layout/layout';
import PublicReportsApiService from '../../../api/PublicReportsApiService';
import { ArrowLeftOutlined } from '@ant-design/icons';
import DataTableButtonUtil from '../../shared/DataTable/DataTableButtonUtil';
import HistoryUtil from '../../../utils/HistoryUtil';
import Routes from '../../../consts/Routes';
import DataTableColumnUtil from '../../shared/DataTable/DataTableColumnUtil';

interface BuildingFundReportsProps {

}
interface BuildingFundReportsState {
    tableColumns: DataTableColumnProps<BuildingFundReportDTO>[];
}

class BuildingFundReports extends React.Component<BuildingFundReportsProps, BuildingFundReportsState> {
    private dataReportTable: DataTable<BuildingFundReportDTO> | undefined;

    constructor(props: BuildingFundReportsProps) {
        super(props);
        this.state = {
            tableColumns: this.getTableColumns()
        };
    }

    render() {
        const actionButtons = [];
        actionButtons.push(DataTableButtonUtil.Reset());

        return (
            <Content className="content-pad">
                <DataTable
                    ref={(element: any) => (this.dataReportTable = element)}
                    serverSide={true}
                    tableProps={{
                        rowKey: 'buildingFundReports',
                        sortDirections: ['ascend', 'descend'],
                        locale: {
                            emptyText: 'No Reports Currently Match Your Filter'
                        }
                    }}
                    globalSearch={true}
                    buttonBar={actionButtons}
                    columns={this.state.tableColumns}
                    fetchData={{
                        fetch: function (tableRequest) {
                            return PublicReportsApiService.getBuildingFundReports(tableRequest);
                        },
                        failureMessage: 'Failed to retreive Building Fund'
                    }}
                    styleOptions={{ compact: true, alternatingRowHighlight: true }}
                    title={
                        <Button type="link" onClick={() => HistoryUtil.push(Routes.generate(Routes.PUBLIC_REPORTS))}>
                            <Typography.Title level={4}>
                                <Space size="large">
                                    <ArrowLeftOutlined className="public-report-links" /> Building Funds
                                </Space>
                            </Typography.Title>
                        </Button>
                    }
                />
            </Content>
        );
    }

    private getTableColumns() {
        const columns: DataTableColumnProps<BuildingFundReportDTO>[] = [];

        const columnCoveredYear: DataTableColumnProps<BuildingFundReportDTO>
            = DataTableColumnUtil.Text('Year', 'coveredYear', null, { defaultSortOrder: 'descend' });
        columns.push(columnCoveredYear);

        const columnCommitteeName: DataTableColumnProps<BuildingFundReportDTO> = DataTableColumnUtil.Text('Committee', 'committeeName');
        columns.push(columnCommitteeName);

        const columnFiledDate: DataTableColumnProps<BuildingFundReportDTO> = DataTableColumnUtil.Date('Filed On', 'filedOn');
        columns.push(columnFiledDate);

        const buttons = [
            { text: 'View', onClick: (rowData: BuildingFundReportDTO) => window.open(rowData.fileUrl || null || '') },
        ];

        const columnButtons: DataTableColumnProps<BuildingFundReportDTO> = DataTableColumnUtil.Buttons('id', buttons, 220);
        columns.push(columnButtons);

        return columns as DataTableColumnProps<BuildingFundReportDTO>[];
    }
}

export default BuildingFundReports;