import { ArrowLeftOutlined } from '@ant-design/icons';
import {
    Button,
    Divider,
    Layout,
    notification,
    Space,
    Tabs,
    Typography,
} from 'antd';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import PublicReportsApiService from '../../../api/PublicReportsApiService';
import Routes from '../../../consts/Routes';
import PFDLateReportsDTO from '../../../models/PFDLateReportsDTO';
import HistoryUtil from '../../../utils/HistoryUtil';
import DataTable, { DataTableColumnProps, FilterType } from '../../shared/DataTable/DataTable';
import DataTableButtonUtil from '../../shared/DataTable/DataTableButtonUtil';
import DataTableColumnUtil from '../../shared/DataTable/DataTableColumnUtil';
import TableRequestDTO from '../../../models/TableRequestDTO';
import TableResponseDTO from '../../../models/TableResponseDTO';
import TableExportDTO from '../../../models/TableExportDTO';
import LateReportFilterDTO from '../../../models/LateReportFilterDTO';

const { Content } = Layout;
const { TabPane } = Tabs;

interface EthicsLateReportsProps {
    committeeId?: string;
    lateReportTypeId?: string;
}

interface EthicsLateReportsState {
    lateReportFilter: LateReportFilterDTO | null;
    committeeId?: string;
    lateReportTypeId?: string;
    exporting: boolean;
    loading: boolean;
    tabKey: string;
}

class EthicsLateReports extends React.Component<RouteComponentProps<EthicsLateReportsProps>, EthicsLateReportsState> {
    constructor(props: RouteComponentProps<EthicsLateReportsProps>) {
        super(props);
        this.state = {
            loading: false,
            exporting: false,
            tabKey: props.match.params.lateReportTypeId ?? '1',
            lateReportFilter: null,
            committeeId: props.match.params.committeeId,
            lateReportTypeId: props.match.params.lateReportTypeId,
        };
    }

    render() {
        const {
            loading,
            tabKey
        } = this.state;

        const pfdActionButtons = [];
        pfdActionButtons.push(DataTableButtonUtil.Reset());
        pfdActionButtons.push(DataTableButtonUtil.Export(
            (tableRequest: TableRequestDTO) => {
                this.setState({ exporting: true });
                const exportDTO: TableExportDTO = TableExportDTO.create(
                    {
                        TableRequest: tableRequest,
                        ConvertToCST: false,
                    });
                return PublicReportsApiService.exportPfdLateReports(exportDTO)
                    .then(() => {
                        this.setState({ exporting: false });
                    });
            }, this.state.exporting));

        return (
            <Content className="content-pad">
                <Button type="link" onClick={() => HistoryUtil.push(Routes.generate(Routes.PUBLIC_REPORTS))}>
                    <Typography.Title level={4}>
                        <Space size="large">
                            <ArrowLeftOutlined className="public-report-links" /> Ethics Late Reports
                        </Space>
                    </Typography.Title>
                </Button>
                <Divider />
                <Tabs defaultActiveKey={tabKey}>
                    <TabPane tab="Personal Financial Disclosures" key="1">
                        <DataTable
                            key="1"
                            globalSearch={true}
                            serverSide={true}
                            buttonBar={pfdActionButtons}
                            columns={this.getPFDTableColumns()}
                            fetchData={this.fetchLatePFDs}
                            tableProps={{
                                sortDirections: ['ascend', 'descend'],
                                locale: { emptyText: 'No Reports Currently Match Your Filter' },
                                loading: loading
                            }}
                            styleOptions={{ compact: true, alternatingRowHighlight: true }}
                        />
                    </TabPane>
                </Tabs>
            </Content>
        );
    }

    private getPFDTableColumns = (): DataTableColumnProps<PFDLateReportsDTO>[] => {
        const amt = DataTableColumnUtil.Currency<PFDLateReportsDTO>('Amount', 'penaltyAmount');
        amt.sorter = (a: PFDLateReportsDTO, b: PFDLateReportsDTO) => {
            if (a.penaltyAmount && !b.penaltyAmount) {
                return 1;
            }
            if (!a.penaltyAmount && b.penaltyAmount) {
                return -1;
            }
            if ((a.penaltyAmount || 0) > (b.penaltyAmount || 0)) {
                return 1;
            }
            if ((a.penaltyAmount || 0) < (b.penaltyAmount || 0)) {
                return -1;
            }
            return 0;
        };

        return [
            DataTableColumnUtil.Text('Name', 'name'),
            DataTableColumnUtil.Date('Due Date', 'dueDate', null, { defaultSortOrder: 'descend' }),
            DataTableColumnUtil.Date('Date Filed', 'dateFiled'),
            amt,
            DataTableColumnUtil.BooleanCheckbox('Paid', 'isPaid', 10, FilterType.BooleanRadio),
            DataTableColumnUtil.DropdownMulti('Waived', 'waivedIndicator',
                [
                    { text: 'NA', value: '  ' },
                    { text: 'Waived', value: 'Waived' },
                    { text: 'Partial', value: 'Partial' }
                ]),
        ];
    }

    private fetchLatePFDs = (
        requestState: TableRequestDTO,
        checkEcho: () => boolean,
        callback: (response: TableResponseDTO<PFDLateReportsDTO>) => void
    ) => {
        this.setState({ loading: true });
        return PublicReportsApiService.getPFDLateReports(requestState, false)
            .then((res) => {
                if (!checkEcho()) {
                    return;
                }
                callback(res);
                this.setState({ loading: false });
            })
            .catch((error: any) => {
                this.setState({ loading: false });
                notification.error({
                    message: error.message,
                    description: error.description,
                });
            });
    };
}

export default EthicsLateReports;