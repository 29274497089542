import {
  Button,
  Checkbox,
  Col,
  ColProps,
  FormInstance,
  Input,
  notification,
  Modal,
  Row,
  Select,
  Space,
  Typography,
  Tooltip,
} from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import TextArea from 'antd/lib/input/TextArea';
import AddressForm from '../shared/AddressFormFields';
import * as React from 'react';
import LookupsApiService from '../../api/LookupsApiService';
import SchF1LoansReceivedApiService from '../../api/SchF1LoansReceivedApiService';
import ScheduleApiService from '../../api/ScheduleApiService';
import CodeLookupTableDTO from '../../models/CodeLookupTableDTO';
import SchF1LoansReceivedFormDTO from '../../models/SchF1LoansReceivedFormDTO';
import CommitteeInfoDTO from '../../models/CommitteeInfoDTO';
import FormValidationUtil from '../../utils/FormValidationUtil';
import Dr2GroupTypes from '../../consts/Dr2GroupTypes';
import CurrentUser from '../../utils/CurrentUser';
import HistoryUtil from '../../utils/HistoryUtil';
import Routes from '../../consts/Routes';
import { RouteComponentProps } from 'react-router';
import { Content } from 'antd/lib/layout/layout';
import SchF1LoansReceivedHistoryTable from './SchF1LoansReceivedHistoryTable';
import moment from 'moment';
import PersonSearch from '../shared/PersonSearch';
import BaseScheduleInfoDTO from '../../models/BaseScheduleInfoDTO';
import ScheduleAbbreviationCodes from '../../consts/ScheduleAbbreviationCodes';
import CommitteeAutoSearch from '../shared/CommitteeSearch';
import NotesModal from '../shared/NotesModal';
import NoteEntityParents from '../../consts/NoteEntityParents';
import CurrencyInput from '../shared/CurrencyInput';
import CustomDatePicker from '../shared/CustomDatePicker';
import CustomForm from '../shared/CustomForm';
import OrganizationAutoSearch from '../shared/OrganizationSearch';
import CommitteeType from '../../consts/CommitteeType';

const Option = Select.Option;
const confirm = Modal.confirm;

interface SchF1LoansReceivedFormProps {
  id?: string;
  seqNum?: string;
}

interface SchF1LoansReceivedFormState {
  relations: CodeLookupTableDTO[];
  candidate: BaseScheduleInfoDTO;
  currLoanType: string;
  currComm: CommitteeInfoDTO;
  btnDisable: boolean;
  loanReceivedInfo: SchF1LoansReceivedFormDTO;
  loanReceivedId?: string;
  startDt: moment.Moment;
  endDt: moment.Moment;
  loading: boolean;
  trusteeTrustorReq: boolean;
  seqNum: number;
  autoFillPerorg: boolean;
  candidatesOwnFunds: boolean;
  hideRelationship: boolean;
}

class SchF1LoansReceivedForm extends React.Component<RouteComponentProps<SchF1LoansReceivedFormProps>, SchF1LoansReceivedFormState> {
  private readonly _formRef = React.createRef<FormInstance>();

  constructor(props: RouteComponentProps<SchF1LoansReceivedFormProps>) {
    super(props);
    this.state = {
      relations: [],
      candidate: BaseScheduleInfoDTO.create(),
      currLoanType: '',
      currComm: CommitteeInfoDTO.create(),
      btnDisable: false,
      loanReceivedId: this.props.match.params.id,
      loanReceivedInfo: SchF1LoansReceivedFormDTO.create({ lenderType: '', state: 'IA', relationshipCode: '', amount: null }),
      startDt: moment.utc(),
      endDt: moment.utc(),
      loading: true,
      trusteeTrustorReq: false,
      autoFillPerorg: false,
      candidatesOwnFunds: false,
      seqNum: Number(this.props.match.params.seqNum) || 0,
      hideRelationship: false,
    };
  }

  componentDidMount() {
    this.loadLookups();
    this.getCandidate();
    this.getSchedule();

    if (this.props.match.params.id) {
      this.getLoanReceived(this.props.match.params.id || '', this.props.match.params.seqNum || '');
    }
    else {
      this.getUserCommittee();
    }
  }

  render() {
    const { relations, currLoanType, currComm, btnDisable, loanReceivedInfo, loanReceivedId,
      startDt, endDt, loading, trusteeTrustorReq, autoFillPerorg, candidatesOwnFunds,
      seqNum, hideRelationship } = this.state;
    const columnSizingProps: ColProps = { xs: 24, sm: 24, md: 12 };
    const isCandidateCommittee = currComm.filerTypeName == 'Candidate';
    const relationReq = currLoanType === Dr2GroupTypes.INDIVIDUAL && isCandidateCommittee;
    

    const lenderTypes = [
      Dr2GroupTypes.INDIVIDUAL,
      Dr2GroupTypes.COMMITTEE,
      Dr2GroupTypes.TRUST,
      Dr2GroupTypes.COMPANYOTHER
    ];

    return (
      <>
        {!loading &&
          <Content className="content-pad">
            <Typography.Title level={4}>Schedule F1 - Loan Received</Typography.Title>
            <CustomForm formRef={this._formRef} initialValues={loanReceivedInfo} layout="vertical" onFinish={this.handleSave}>
              <Row gutter={24}>
                <Col {...columnSizingProps}>
                  <FormItem name="lenderType" label="Lender Type"
                    rules={[FormValidationUtil.Required('Lender Type is required')]} >
                    <Select
                      showSearch
                      optionFilterProp="children"
                      disabled={candidatesOwnFunds}
                      onChange={this.handleLenderTypeChange}>
                      <Option value="" disabled={true}>-- Select Type --</Option>
                      {lenderTypes.map(ct => (
                        <Option key={ct} value={ct}>{ct}</Option>
                      ))}
                    </Select>
                  </FormItem>
                  <FormItem name="date" label="Date"
                    rules={[FormValidationUtil.RequiredDate('Date is required'), FormValidationUtil.DateRange(startDt, endDt)]}>
                  <CustomDatePicker onChange={(d) => d?.startOf('day')}/>
                  </FormItem>
                  <FormItem name="amount" label="Amount"
                    rules={[
                      FormValidationUtil.RequiredNumber('Amount is required'),
                      FormValidationUtil.NotNegativeNumber('Amount must be nonnegative')
                    ]}>
                    <CurrencyInput />
                  </FormItem>
                  <Row>
                    <Col >
                      <Space>
                        <Tooltip title="Loan received was the result of a fundraising event">
                          <FormItem name="isFromFundraiser" valuePropName="checked">
                            <Checkbox > From Fundraiser </Checkbox>
                          </FormItem>
                        </Tooltip>
                        {isCandidateCommittee &&
                          <Tooltip title="Loan received to bank account by candidate">
                            <FormItem name="isCandidatesOwnFunds" valuePropName="checked">
                              <Checkbox onChange={this.handleCandidateFundsCheckboxChange}> Candidate&apos;s Own Funds </Checkbox>
                            </FormItem>
                          </Tooltip>
                        }
                      </Space>
                    </Col>
                  </Row>
                  <FormItem name="explanation" label="Explanation" >
                    <TextArea maxLength={2000} />
                  </FormItem>
                </Col>
                <Col {...columnSizingProps}>

                  {currLoanType === Dr2GroupTypes.INDIVIDUAL && (
                    <>
                      <PersonSearch
                        name={'person'}
                        label={'Search for an Individual'}
                        required={false}
                        disabled={candidatesOwnFunds}
                        committeeId={currComm.id || ''}
                        onSelect={this.getPerson}
                        onChange={this.handleLenderChange}
                      />
                      <AddressForm allDisabled={autoFillPerorg || candidatesOwnFunds}
                        firstName={{ name: 'firstName' }}
                        middleInitial={{ name: 'middleInitial' }}
                        lastName={{ name: 'lastName' }}
                        address1={{ name: 'addressLine1' }}
                        address2={{ name: 'addressLine2' }}
                        city={{ name: 'city' }}
                        state={{ name: 'state' }}
                        zip={{ name: 'zip' }}
                        phone={{ name: 'phoneNumber', required: false }}
                    />
                      <FormItem name="relationshipCode" label="Relationship" hidden={hideRelationship}
                        rules={[FormValidationUtil.Required('Relationship is required', relationReq)]}>
                        <Select
                          showSearch
                          disabled={autoFillPerorg || candidatesOwnFunds}
                          optionFilterProp="children">
                          <Option value="" disabled={true}>-- Select Relationship --</Option>
                          {relations.map(rt => (
                            <Option key={rt.code || ''} value={rt.code || ''}>{rt.name}</Option>
                          ))}
                        </Select>
                      </FormItem>
                    </>
                  )}
                {currLoanType === Dr2GroupTypes.COMMITTEE && (
                  <CommitteeAutoSearch
                    name="lenderName"
                    label="Name"
                    required={true}
                    requireSelection={true}
                    onSelectCommittee={this.handleCommitteeSelect}
                  />
                  )}
                  {(currLoanType === Dr2GroupTypes.COMPANYOTHER || currLoanType === Dr2GroupTypes.TRUST) && (
                    <>
                      <OrganizationAutoSearch
                        name={'company'}
                        label={'Search for a Company'}
                        required={false}
                        committeeId={currComm.id || ''}
                        perorgType={currLoanType}
                        onSelect={this.getOrganization}
                        onChange={this.handleLenderChange}
                      />
                      <AddressForm allDisabled={autoFillPerorg}
                        name={{ name: 'lenderName', label: 'Company' }}
                        address1={{ name: 'addressLine1' }}
                        address2={{ name: 'addressLine2' }}
                        city={{ name: 'city' }}
                        state={{ name: 'state' }}
                        zip={{ name: 'zip' }}
                        phone={{ name: 'phoneNumber', required: false }}
                      />
                    </>
                  )}
                  {currLoanType === Dr2GroupTypes.TRUST &&
                    <>
                      <FormItem name="isLivingOrRevocableTrust" valuePropName="checked">
                        <Checkbox onChange={this.handleRevocableTrustCheckboxChange}>Living or Revocable Trust</Checkbox>
                      </FormItem>
                      <FormItem name="trustee" label="Trustee"
                        rules={[FormValidationUtil.Required('Trustee is required', trusteeTrustorReq)]}>
                        <Input disabled={autoFillPerorg} />
                      </FormItem>
                      <FormItem name="trustor" label="Trustor"
                        rules={[FormValidationUtil.Required('Trustor is required', trusteeTrustorReq)]}>
                        <Input disabled={autoFillPerorg} />
                      </FormItem>
                    </>
                  }
                </Col>
              </Row>
              <Row>
                <Col {...columnSizingProps}>
                  <FormItem name="lenderAutoFillId" hidden={true}>
                    <Input hidden={true} disabled={true} aria-hidden={true}/>
                  </FormItem>
                </Col>
              </Row>
              <Space>
                <Button type="primary" disabled={btnDisable} htmlType="submit">Save Loan</Button>
              <Button type="default" disabled={btnDisable} onClick={this.handleCancel}>Cancel</Button>
              {loanReceivedId &&
                <NotesModal
                  parentId={loanReceivedId}
                  parent={NoteEntityParents.LOANRECEIVED}
                  seqNum={seqNum || 0}
                  auditorsNoteId={loanReceivedInfo.auditorsNoteId || ''}
                />
              }
              </Space>
            </CustomForm >
            {loanReceivedId &&
              <SchF1LoansReceivedHistoryTable
                loanReceivedId={loanReceivedId || ''}
              />
            }
          </Content>
        }
      </>
    );
  }

  private handleLenderTypeChange = (loanType: string) => {
    this.setState({
      currLoanType: loanType,
      autoFillPerorg: false,
      trusteeTrustorReq: loanType == Dr2GroupTypes.TRUST
    });

    this._formRef.current?.setFieldsValue({
      'firstName': '',
      'middleInitial': '',
      'lastName': '',
      'relationshipCode': '',
      'addressLine1': '',
      'addressLine2': '',
      'city': '',
      'state': '',
      'zip': '',
      'phoneNumber': '',
      'trustee': '',
      'trustor': '',
      'person': null,
      'lenderName': null,
      'company': null,
      'isLivingOrRevocableTrust': false,
      'lenderAutoFillId': ''
    });

    if ([Dr2GroupTypes.COMPANYOTHER, Dr2GroupTypes.INDIVIDUAL, Dr2GroupTypes.TRUST].includes(loanType)) {
      this._formRef.current?.setFieldsValue({ state: 'IA' });
    }
  }

  private handleLenderChange = (value: string) => {
    this._formRef.current?.setFieldsValue({ lenderAutoFillId: '' });
    this.setState({ autoFillPerorg: !!value });
  }

  private handleCommitteeSelect = (committee: CommitteeInfoDTO | undefined) => {
    this._formRef.current?.setFieldsValue({ lenderAutoFillId: committee?.id });
  }

  private handleCandidateFundsCheckboxChange = (e: any) => {
    if (e.target.checked) {
      if (this._formRef.current != null) {
        const { candidate } = this.state;
        if (candidate.firstName !== null) {
          this.setIndividualFields(candidate);
        }
        else {
          this._formRef.current.setFieldsValue({
            lenderType: Dr2GroupTypes.INDIVIDUAL,
            lenderAutoFillId: ''
          });
          this.setState({ currLoanType: Dr2GroupTypes.INDIVIDUAL });
          notification.error({
            message: 'Candidate was not found',
            description: ''
          });
        }

      }
    }
    else {
      if (this._formRef.current != null) {
        this.setIndividualFields();
      }
    }
    this.setState({ candidatesOwnFunds: e.target.checked });
    this._formRef.current?.setFieldsValue({ 'person': null });
  }

  private setCompanyFields(form?: BaseScheduleInfoDTO) {
    if (form) {
      this._formRef.current?.setFieldsValue({
        lenderName: form.committeeOrCompanyName,
        addressLine1: form.addressLine1,
        addressLine2: form.addressLine2,
        city: form.city,
        state: form.state,
        zip: form.zip,
        phoneNumber: form.phoneNumber,
        trustee: form.trustee,
        trustor: form.trustor
      });
    }
    else {
      this._formRef.current?.setFieldsValue({
        lenderName: '',
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        zip: '',
        phoneNumber: '',
        trustee: '',
        trustor: '',
        lenderAutoFillId: ''
      });
      this.setState({ autoFillPerorg: false });
    }
  }

  private setIndividualFields(form?: BaseScheduleInfoDTO) {
    if (form) {
      this._formRef.current?.setFieldsValue({
        lenderType: form.groupType,
        firstName: form.firstName,
        middleInitial: form.middleInitial,
        lastName: form.lastName,
        addressLine1: form.addressLine1,
        addressLine2: form.addressLine2,
        city: form.city,
        state: form.state,
        zip: form.zip,
        phoneNumber: form.phoneNumber,
        relationshipCode: form.relationshipCode,
        lenderAutoFillId: ''
      });
    }
    else {
      this._formRef.current?.setFieldsValue({
        lenderType: Dr2GroupTypes.INDIVIDUAL,
        firstName: '',
        middleInitial: '',
        lastName: '',
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        zip: '',
        phoneNumber: '',
        relationshipCode: '',
        lenderAutoFillId: ''
      });
      this.setState({ autoFillPerorg: false });
    }
    this.setState({ currLoanType: Dr2GroupTypes.INDIVIDUAL });
  }

  private handleRevocableTrustCheckboxChange = (e: any) => {
    this.setState({ trusteeTrustorReq: !e.target.checked });
  }

  private loadLookups = () => {
    const relationPromise = LookupsApiService.getRelationTypes();
    Promise.all([relationPromise])
      .then(result => {
        const [relations] = result;
        this.setState({ relations });
      });
  }

  private getSchedule = () => {
    ScheduleApiService.getSchedule(CurrentUser.Get()?.dr2Id || '', ScheduleAbbreviationCodes.SCHEDULEF1)
      .then(schedule => {
        this.setState({ startDt: moment.utc(schedule.startDate), endDt: moment.utc(schedule.endDate) });
      });
  }

  private getCandidate = () => {
    ScheduleApiService.getCandidate(CurrentUser.Get()?.committeeId || '')
      .then(personInfo => {
        if (personInfo.groupType == null) {
          return;
        }

        this.setState({ candidate: personInfo });
      });
  }

  private getPerson = (perorgId: string) => {
    if (perorgId != '') {
      ScheduleApiService.getPerson(perorgId, CurrentUser.Get()?.committeeId || '')
        .then(personInfo => {
          if (personInfo) {
            this.setIndividualFields(personInfo);
            this._formRef.current?.setFieldsValue({ lenderAutoFillId: perorgId });
          }
        });
    }
    else {
      this.setIndividualFields();
    }
  }

  private getOrganization = (perorgId: string) => {
    if (perorgId != '') {
      ScheduleApiService.getOrganization(perorgId, CurrentUser.Get()?.committeeId || '')
        .then(orgInfo => {
          if (orgInfo) {
            this.setCompanyFields(orgInfo);
            this._formRef.current?.setFieldsValue({ lenderAutoFillId: perorgId });
          }
        });
    }
    else {
      this.setCompanyFields();
    }
  }

  private getUserCommittee = () => {
    ScheduleApiService.getCommittee(CurrentUser.Get()?.committeeId || '')
      .then(committee => {
        if (committee) {
          const hideRelationshipCodes = [
            CommitteeType.CITY_PAC,
            CommitteeType.COUNTY_PAC,
            CommitteeType.IOWA_PAC,
            CommitteeType.SCHOOL_BOARD_OR_OTHER_POLITICAL_SUBDIVISION_PAC,
            CommitteeType.COUNTY_CENTRAL_COMMITTEE,
            CommitteeType.STATE_CENTRAL_COMMITTEE,
            CommitteeType.LOCAL_BALLOT_ISSUE];
          this.setState({ currComm: { ...committee }, hideRelationship: hideRelationshipCodes.includes(committee.committeeTypeCd || '') });
        }
      }).finally(() => {
        this.setState({ loading: false });
      });
  }

  private getLoanReceived = (loanReceivedId: string, seqNum: string) => {
    const loanReceivedPromise = SchF1LoansReceivedApiService.getLoanReceived(loanReceivedId, Number(seqNum));
    const committeePromise = ScheduleApiService.getCommittee(CurrentUser.Get()?.committeeId || '');

    Promise.all([loanReceivedPromise, committeePromise])
      .then(results => {
        const [loanReceived, committee] = results;

        if (loanReceived) {
          loanReceived.date = moment(loanReceived.date);

          const loanReceivedInfo: SchF1LoansReceivedFormDTO & { person?: string, company?: string } = loanReceived;
          if (loanReceivedInfo.firstName && loanReceivedInfo.lastName) {
            if (loanReceivedInfo.middleInitial) {
              loanReceivedInfo.person = loanReceived.firstName + ' ' + loanReceived.middleInitial + '. ' + loanReceived.lastName;
            }
            else {
              loanReceivedInfo.person = loanReceived.firstName + ' ' + loanReceived.lastName;
            }
          }
          else if (loanReceivedInfo.lenderType == Dr2GroupTypes.TRUST || loanReceivedInfo.lenderType == Dr2GroupTypes.COMPANYOTHER) {
            if (loanReceivedInfo.lenderName) {
              loanReceivedInfo.company = loanReceivedInfo.lenderName;
            }
          }

          this.setState({
            loanReceivedInfo: loanReceived,
            currLoanType: loanReceived.lenderType || '',
            trusteeTrustorReq: loanReceived.lenderType == Dr2GroupTypes.TRUST && !loanReceived.isLivingOrRevocableTrust,
            autoFillPerorg: loanReceived.lenderType != Dr2GroupTypes.COMMITTEE,
            candidatesOwnFunds: loanReceived.isCandidatesOwnFunds
          });
          this._formRef.current?.setFieldsValue(loanReceivedInfo);
        }
        else {
          notification.error({
            message: 'Error while fetching loan received',
            description: 'Not found'
          });
        }

        if (committee) {
          this.setState({ currComm: { ...committee } });
        }

      }).catch(() => {
        notification.error({
          message: 'Error while fetching loan received',
          description: ''
        });
      }).finally(() => {
        this.setState({ loading: false });
      });
  }

  private handleSave = (values: SchF1LoansReceivedFormDTO) => {
    if (values.lenderAutoFillId === '') {
      values.lenderAutoFillId = null;
    }
    this.setState({ btnDisable: true });
    if (!this.state.loanReceivedId) {
      SchF1LoansReceivedApiService.add(values, CurrentUser.Get()?.committeeId || '', CurrentUser.Get()?.dr2Id || '')
        .then(() => {
          notification.success({
            message: 'Saved Successfully',
            description: ''
          });
          HistoryUtil.push(Routes.SCHEDULE_F1_LOANS_RECEIVED);
        }).catch(error => {
          notification.error({
            message: 'Your loan received failed to save',
            description: error.message
          });
          HistoryUtil.push(Routes.SCHEDULE_F1_LOANS_RECEIVED);
        });
    }
    else {
      SchF1LoansReceivedApiService.edit(
        values,
        CurrentUser.Get()?.committeeId || '',
        CurrentUser.Get()?.dr2Id || '',
        this.props.match.params.id || '',
        Number(this.props.match.params.seqNum)
      )
        .then(() => {
          notification.success({
            message: 'Saved Successfully',
            description: ''
          });
          HistoryUtil.push(Routes.SCHEDULE_F1_LOANS_RECEIVED);
        }).catch(error => {
          notification.error({
            message: 'Your loan received failed to save',
            description: error.message
          });
          HistoryUtil.push(Routes.SCHEDULE_F1_LOANS_RECEIVED);
        });
    }
  }

  private handleCancel = () => {
    confirm({
      title: 'Are you sure you want to leave?',
      okText: 'Yes',
      cancelText: 'No',
      onOk: () => {
        HistoryUtil.push(Routes.SCHEDULE_F1_LOANS_RECEIVED);
      }
    });
  }
}

export default SchF1LoansReceivedForm;