import { notification, FormInstance, Row, Col, Input, Checkbox, Button, Table, Typography, } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import * as React from 'react';
import FormValidationUtil from '../../../../utils/FormValidationUtil';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import OtherBusinessDTO from '../../../../models/OtherBusinessDTO';
import CustomForm from '../../../shared/CustomForm';

const { Paragraph, Link, Title } = Typography;
const { Column } = Table;

interface OtherBusinessProps {
  businesses: OtherBusinessDTO[];
  noOtherBusiness: boolean;
}

interface OtherBusinessState {
  noOtherBusiness: boolean;
  businesses: OtherBusinessDTO[];
}

class OtherBusiness extends React.Component<OtherBusinessProps, OtherBusinessState>
{
  private readonly _formRef = React.createRef<FormInstance>();

  constructor(props: OtherBusinessProps) {
    super(props);

    this.state = {
      businesses: this.props.businesses || [],
      noOtherBusiness: this.props.noOtherBusiness,
    };
  }

  moveBack = async (): Promise<{ model: { businesses: OtherBusinessDTO[], noOtherBusiness: boolean }, errorFields: any }> => {
    return {
      model: { businesses: this.state.businesses, noOtherBusiness: this.state.noOtherBusiness }, errorFields: null
    };
  }

  validate = async (): Promise<{ model: { businesses: OtherBusinessDTO[], noOtherBusiness: boolean }, errorFields: any }> => {
    if (this.state.businesses.length > 0 || this.state.noOtherBusiness) {
      return {
        model: { businesses: this.state.businesses, noOtherBusiness: this.state.noOtherBusiness }, errorFields: null
      };
    } else {
      notification.error({
        message: 'You must list at least one other business, occupation or profession, or select no other business, occupation or profession'
      });
      return Promise.reject();
    }
  }

  render() {
    return (
      <>
        <Title level={4}>
          Other Business, Occupation, or Profession
        </Title>
        <Paragraph>
          Iowa Code section&nbsp;
          <Link href="https://www.legis.iowa.gov/docs/code/68b.35.pdf" target="_blank">68B.35</Link>
          &nbsp;requires you to disclose each business, occupation, or profession in
          which you were engaged during the covered year and nature of that business,
          occupation, or profession, unless already apparent.
        </Paragraph>
        <Row>
          <Col xs={24}>
            <CustomForm formRef={this._formRef} onFinish={this.addBusiness} layout="vertical">
              <FormItem name="name" label="Name of Business" rules={[FormValidationUtil.Required('Name of Business is required')]}>
                <Input type="text" />
              </FormItem>
              <FormItem name="nature" label="Nature of Business" rules={[FormValidationUtil.Required('Nature of Business is required')]}>
                <Input type="text" />
              </FormItem>
              <FormItem name="position" label="Position Held" rules={[FormValidationUtil.Required('Position Held is required')]}>
                <Input type="text" />
              </FormItem>
              <Button type="primary" htmlType="submit">Add</Button>
            </CustomForm>
          </Col>
        </Row>
        <br />
        {
          this.state.businesses.length > 0 ?
            <Row>
              <Col xs={24}>
                <Table dataSource={this.state.businesses}>
                  <Column title="Name" dataIndex="name" key="name" />
                  <Column title="Nature" dataIndex="nature" key="nature" />
                  <Column title="Position" dataIndex="position" key="position" />
                  <Column
                    title="Delete"
                    dataIndex=""
                    render={(text, record, index) =>
                      <Button onClick={() => { this.deleteBusiness(index); }}>Delete</Button>
                    }
                  />
                </Table>
              </Col>
            </Row>
            :
            <>
              <Row>
                <Col>
                  <Checkbox className="bold-checkbox" checked={this.state.noOtherBusiness} onChange={this.toggleBusiness}>
                    If you had no other business, occupation or profession, click here.
                  </Checkbox>
                </Col>
              </Row>
              <br />
            </>
        }
      </>
    );
  }

  private addBusiness = (values: any) => {
    this._formRef.current?.resetFields();
    this.setState({ businesses: [...this.state.businesses, values] });
  }

  private toggleBusiness = (checkBox: CheckboxChangeEvent) => {
    this.setState({ noOtherBusiness: checkBox.target.checked });
  }

  private deleteBusiness = (index: any) => {
    const dataSource = [...this.state.businesses];
    const value = dataSource[index];
    this.setState({ businesses: dataSource.filter(item => item !== value) });
  }
}

export default OtherBusiness;