// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
import BaseApi from './BaseApi';
import TableRequestDTO from '../models/TableRequestDTO';
import ReportTableExportDTO from '../models/ReportTableExportDTO';
import TableResponseDTO from '../models/TableResponseDTO';
import AddressReportDTO from '../models/AddressReportDTO';
import PeriodReportDTO from '../models/PeriodReportDTO';
import DR2FilingReportDTO from '../models/DR2FilingReportDTO';
import PersonReportDTO from '../models/PersonReportDTO';
import PeriodInfoDTO from '../models/PeriodInfoDTO';
import AdminOrganizationListDTO from '../models/AdminOrganizationListDTO';
import SupplementaryFilingComplianceReportDTO from '../models/SupplementaryFilingComplianceReportDTO';
import PfdFilingReportDTO from '../models/PfdFilingReportDTO';
import GiftBequestReportDTO from '../models/GiftBequestReportDTO';

export class AdminReportApiService extends BaseApi {

  // post: api/admin/report/address-search?selectedCommitteeType=${encodeURIComponent(selectedCommitteeType)}
  public getAddressListDataTable(tableRequest: TableRequestDTO, selectedCommitteeType: string): Promise<TableResponseDTO<AddressReportDTO>> {
    let url = `api/admin/report/address-search`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (selectedCommitteeType != null) {
      url += `${prefix}selectedCommitteeType=${encodeURIComponent(selectedCommitteeType)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, TableResponseDTO<AddressReportDTO>>(tableRequest, 'post', url, true, false);
  }

  // get: api/admin/report/address-search-export
  public exportAddressListDataTable(dto: ReportTableExportDTO): Promise<void> {
    let url = `api/admin/report/address-search-export`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, void>(dto, 'get', url, true, true);
  }

  // post: api/admin/report/address?selectedCommitteeType=${encodeURIComponent(selectedCommitteeType)}
  public getAddressList(selectedCommitteeType: string): Promise<AddressReportDTO[]> {
    let url = `api/admin/report/address`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (selectedCommitteeType != null) {
      url += `${prefix}selectedCommitteeType=${encodeURIComponent(selectedCommitteeType)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, AddressReportDTO[]>(null, 'post', url, true, false);
  }

  // get: api/admin/report/address-export?selectedCommitteeType=${encodeURIComponent(selectedCommitteeType)}
  public exportAddressList(selectedCommitteeType: string): Promise<void> {
    let url = `api/admin/report/address-export`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (selectedCommitteeType != null) {
      url += `${prefix}selectedCommitteeType=${encodeURIComponent(selectedCommitteeType)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, void>(null, 'get', url, true, true);
  }

  // post: api/admin/report/single-period?selectedCommitteeType=${encodeURIComponent(selectedCommitteeType)}&periodMasterId=${periodMasterId}&districtCommitteeType=${districtCommitteeType}
  public getSinglePeriodList(tableRequest: TableRequestDTO, selectedCommitteeType: string, periodMasterId: number, districtCommitteeType: number): Promise<TableResponseDTO<PeriodReportDTO>> {
    let url = `api/admin/report/single-period`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (selectedCommitteeType != null) {
      url += `${prefix}selectedCommitteeType=${encodeURIComponent(selectedCommitteeType)}`;
      prefix = '&';
    }
    if (periodMasterId != null) {
      url += `${prefix}periodMasterId=${periodMasterId}`;
      prefix = '&';
    }
    if (districtCommitteeType != null) {
      url += `${prefix}districtCommitteeType=${districtCommitteeType}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, TableResponseDTO<PeriodReportDTO>>(tableRequest, 'post', url, true, false);
  }

  // post: api/admin/report/district-single-period?selectedCommitteeType=${selectedCommitteeType}&periodMasterId=${periodMasterId}
  public getDistrictSinglePeriodList(selectedCommitteeType: number, periodMasterId: number): Promise<PeriodReportDTO[]> {
    let url = `api/admin/report/district-single-period`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (selectedCommitteeType != null) {
      url += `${prefix}selectedCommitteeType=${selectedCommitteeType}`;
      prefix = '&';
    }
    if (periodMasterId != null) {
      url += `${prefix}periodMasterId=${periodMasterId}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, PeriodReportDTO[]>(null, 'post', url, true, false);
  }

  // get: api/admin/report/single-period-export
  public exportSinglePeriodList(dto: ReportTableExportDTO): Promise<void> {
    let url = `api/admin/report/single-period-export`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, void>(dto, 'get', url, true, true);
  }

  // get: api/admin/report/district-single-period-export?selectedCommitteeType=${selectedCommitteeType}&periodMasterId=${periodMasterId}&fileName=${encodeURIComponent(fileName)}
  public exportDistrictSinglePeriodList(selectedCommitteeType: number, periodMasterId: number, fileName: string): Promise<void> {
    let url = `api/admin/report/district-single-period-export`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (selectedCommitteeType != null) {
      url += `${prefix}selectedCommitteeType=${selectedCommitteeType}`;
      prefix = '&';
    }
    if (periodMasterId != null) {
      url += `${prefix}periodMasterId=${periodMasterId}`;
      prefix = '&';
    }
    if (fileName != null) {
      url += `${prefix}fileName=${encodeURIComponent(fileName)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, void>(null, 'get', url, true, true);
  }

  // post: api/admin/report/dr2-filing?commFilingPeriodCatType=${encodeURIComponent(commFilingPeriodCatType)}&periodMasterId=${periodMasterId}&unfiled=${unfiled}&isExport=${isExport}
  public getDR2FilingList(tableRequest: TableRequestDTO, commFilingPeriodCatType: string, periodMasterId: number, unfiled: boolean, isExport: boolean): Promise<TableResponseDTO<DR2FilingReportDTO>> {
    let url = `api/admin/report/dr2-filing`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (commFilingPeriodCatType != null) {
      url += `${prefix}commFilingPeriodCatType=${encodeURIComponent(commFilingPeriodCatType)}`;
      prefix = '&';
    }
    if (periodMasterId != null) {
      url += `${prefix}periodMasterId=${periodMasterId}`;
      prefix = '&';
    }
    if (unfiled != null) {
      url += `${prefix}unfiled=${unfiled}`;
      prefix = '&';
    }
    if (isExport != null) {
      url += `${prefix}isExport=${isExport}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, TableResponseDTO<DR2FilingReportDTO>>(tableRequest, 'post', url, true, false);
  }

  // get: api/admin/report/dr2-filing-export
  public exportDR2FilingList(dto: ReportTableExportDTO): Promise<void> {
    let url = `api/admin/report/dr2-filing-export`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, void>(dto, 'get', url, true, true);
  }

  // post: api/admin/report/person?firstName=${encodeURIComponent(firstName)}&lastName=${encodeURIComponent(lastName)}
  public getPersonList(tableRequest: TableRequestDTO, firstName: string, lastName: string): Promise<TableResponseDTO<PersonReportDTO>> {
    let url = `api/admin/report/person`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (firstName != null) {
      url += `${prefix}firstName=${encodeURIComponent(firstName)}`;
      prefix = '&';
    }
    if (lastName != null) {
      url += `${prefix}lastName=${encodeURIComponent(lastName)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, TableResponseDTO<PersonReportDTO>>(tableRequest, 'post', url, true, false);
  }

  // get: api/admin/report/person-export
  public exportPersonList(dto: ReportTableExportDTO): Promise<void> {
    let url = `api/admin/report/person-export`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, void>(dto, 'get', url, true, true);
  }

  // get: api/admin/report/filingPeriod?selectedCommitteeType=${selectedCommitteeType}&year=${encodeURIComponent(year)}
  public getFilingPeriods(selectedCommitteeType: number, year: string): Promise<PeriodInfoDTO[]> {
    let url = `api/admin/report/filingPeriod`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (selectedCommitteeType != null) {
      url += `${prefix}selectedCommitteeType=${selectedCommitteeType}`;
      prefix = '&';
    }
    if (year != null) {
      url += `${prefix}year=${encodeURIComponent(year)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, PeriodInfoDTO[]>(null, 'get', url, true, false);
  }

  // get: api/admin/report/filingPeriods?commFilingPeriodCatType=${encodeURIComponent(commFilingPeriodCatType)}&year=${encodeURIComponent(year)}
  public getFilingPeriodsFromFilingType(commFilingPeriodCatType: string, year: string): Promise<PeriodInfoDTO[]> {
    let url = `api/admin/report/filingPeriods`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (commFilingPeriodCatType != null) {
      url += `${prefix}commFilingPeriodCatType=${encodeURIComponent(commFilingPeriodCatType)}`;
      prefix = '&';
    }
    if (year != null) {
      url += `${prefix}year=${encodeURIComponent(year)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, PeriodInfoDTO[]>(null, 'get', url, true, false);
  }

  // get: api/admin/report/committeeInfo?committeeId=${encodeURIComponent(committeeId)}
  public getCommitteeInfo(committeeId: string): Promise<AdminOrganizationListDTO> {
    let url = `api/admin/report/committeeInfo`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (committeeId != null) {
      url += `${prefix}committeeId=${encodeURIComponent(committeeId)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, AdminOrganizationListDTO>(null, 'get', url, true, false);
  }

  // post: api/admin/report/supplementaryCompliance?periodMasterId=${periodMasterId}&startDate=${encodeURIComponent(String(startDate))}&endDate=${encodeURIComponent(String(endDate))}
  public getSupplementaryFilingComplianceList(tableRequest: TableRequestDTO, periodMasterId: number, startDate: string, endDate: string): Promise<TableResponseDTO<SupplementaryFilingComplianceReportDTO>> {
    let url = `api/admin/report/supplementaryCompliance`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (periodMasterId != null) {
      url += `${prefix}periodMasterId=${periodMasterId}`;
      prefix = '&';
    }
    if (startDate != null) {
      url += `${prefix}startDate=${encodeURIComponent(startDate)}`;
      prefix = '&';
    }
    if (endDate != null) {
      url += `${prefix}endDate=${encodeURIComponent(endDate)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, TableResponseDTO<SupplementaryFilingComplianceReportDTO>>(tableRequest, 'post', url, true, false);
  }

  // get: api/admin/report/supplementary-export
  public exportSupplementaryFilingComplianceList(dto: ReportTableExportDTO): Promise<void> {
    let url = `api/admin/report/supplementary-export`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, void>(dto, 'get', url, true, true);
  }

  // post: api/admin/report/pfdFiling?year=${year}
  public getPfdFilingReportList(tableRequest: TableRequestDTO, year: number): Promise<TableResponseDTO<PfdFilingReportDTO>> {
    let url = `api/admin/report/pfdFiling`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (year != null) {
      url += `${prefix}year=${year}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, TableResponseDTO<PfdFilingReportDTO>>(tableRequest, 'post', url, true, false);
  }

  // get: api/admin/report/pfdFiling-export
  public exportPfdFilingReportList(dto: ReportTableExportDTO): Promise<void> {
    let url = `api/admin/report/pfdFiling-export`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, void>(dto, 'get', url, true, true);
  }

  // get: api/admin/report/giftBequest-export
  public exportGiftBequestReportList(tableRequest: TableRequestDTO): Promise<void> {
    let url = `api/admin/report/giftBequest-export`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, void>(tableRequest, 'get', url, true, true);
  }

  // post: api/admin/report/giftBequest
  public getGiftBequestReportList(tableRequest: TableRequestDTO): Promise<TableResponseDTO<GiftBequestReportDTO>> {
    let url = `api/admin/report/giftBequest`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, TableResponseDTO<GiftBequestReportDTO>>(tableRequest, 'post', url, true, false);
  }
}
var service = new AdminReportApiService();
export default service;
