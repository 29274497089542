// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
import BaseApi from './BaseApi';
import TableRequestDTO from '../models/TableRequestDTO';
import AdminDR1ApproveDTO from '../models/AdminDR1ApproveDTO';
import TableResponseDTO from '../models/TableResponseDTO';
import Dr1TableDTO from '../models/Dr1TableDTO';
import ResultDTO from '../models/ResultDTO';

export class AdminDr1ApiService extends BaseApi {

  // post: api/admin/dr1/table
  public getDr1List(tableRequest: TableRequestDTO): Promise<TableResponseDTO<Dr1TableDTO>> {
    let url = `api/admin/dr1/table`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, TableResponseDTO<Dr1TableDTO>>(tableRequest, 'post', url, true, false);
  }

  // post: api/admin/dr1/${encodeURIComponent(committeeId)}
  public getApproveDR1(committeeId: string): Promise<AdminDR1ApproveDTO> {
    let url = `api/admin/dr1/${encodeURIComponent(committeeId)}`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, AdminDR1ApproveDTO>(null, 'post', url, true, false);
  }

  // post: api/admin/dr1/approve
  public approveDR1(adminDR1Approve: AdminDR1ApproveDTO): Promise<void> {
    let url = `api/admin/dr1/approve`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, void>(adminDR1Approve, 'post', url, true, false);
  }

  // post: api/admin/dr1/reject?committeeId=${encodeURIComponent(committeeId)}
  public reject(committeeId: string): Promise<ResultDTO> {
    let url = `api/admin/dr1/reject`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (committeeId != null) {
      url += `${prefix}committeeId=${encodeURIComponent(committeeId)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, ResultDTO>(null, 'post', url, true, false);
  }
}
var service = new AdminDr1ApiService();
export default service;
