import { Layout, Modal, notification } from 'antd';
import * as React from 'react';
import ContactApiService from '../../api/ContactApiService';
import ContactTableInfoDTO from '../../models/ContactTableInfoDTO';
import ContactTableExportDTO from '../../models/ContactTableExportDTO';
import DataTableColumnUtil from '../../components/shared/DataTable/DataTableColumnUtil';
import DataTableButtonUtil from '../shared/DataTable/DataTableButtonUtil';
import DataTable, { DataTableColumnProps } from '../shared/DataTable/DataTable';
import HistoryUtil from '../../utils/HistoryUtil';
import Routes from '../../consts/Routes';
import CurrentUser from '../../utils/CurrentUser';
import TableRequestDTO from '../../models/TableRequestDTO';

const { Content } = Layout;
const { confirm } = Modal;

interface ManageContactsProps {
}

interface ManageContactsState {
  tableColumns: DataTableColumnProps<ContactTableInfoDTO>[];
}

class ManageContacts extends React.Component<ManageContactsProps, ManageContactsState> {
  private dataTable: DataTable<ContactTableInfoDTO> | undefined;

  constructor(props: ManageContactsProps) {
    super(props);

    this.state = {
      tableColumns: this.getTableColumns()
    };
  }

  render() {
    const actionButtons = [];
    actionButtons.push(DataTableButtonUtil.Reset());
    actionButtons.push(DataTableButtonUtil.Export(
      (tableRequest: TableRequestDTO) => {
        const currentUser = CurrentUser.Get();
        if (currentUser && currentUser.committeeId) {

          const params = ContactTableExportDTO.create({
            TableRequest: tableRequest,
            CommitteeId: currentUser.committeeId
          });

          return ContactApiService.export(params)
            .catch(() => {
              notification.error({
                message: 'Export failed',
                description: ''
              });
            });
        }
      }
    ));

    return (
      <Content className="content-pad">
        <DataTable
          buttonBar={actionButtons}
          columns={this.state.tableColumns}
          fetchData={{
            fetch: function (tableRequest) { return ContactApiService.getContactList(tableRequest, CurrentUser.Get()?.committeeId || ''); },
            failureMessage: 'Failed to retrieve contacts'
          }}
          globalSearch={true}
          ref={(element: any) => (this.dataTable = element)}
          serverSide={true}
          styleOptions={{ compact: true, alternatingRowHighlight: true }}
          tableProps={{
            rowKey: 'giftId',
            sortDirections: ['ascend', 'descend'],
            locale: { emptyText: 'Currently the committee has no contacts.' }
          }}
          title="Contacts"
          stateSaving={{
              enabled: true,
              tableUniqueKey: 'manageContacts',
              perSession: true,
          }}
        />
      </Content>
    );
  }

  private getTableColumns = (): DataTableColumnProps<ContactTableInfoDTO>[] => {
    const columns: DataTableColumnProps<ContactTableInfoDTO>[] = [];

    const columnType: DataTableColumnProps<ContactTableInfoDTO> = DataTableColumnUtil.DropdownMulti('Type', 'type', [
        { text: 'Individual', value: 'Individual' },
        { text: 'Organization', value: 'Organization' },
        { text: 'Trust', value: 'Trust' }
      ],
      null,
      { defaultSortOrder: 'ascend' }
    );
    columns.push(columnType);

    const columnContact: DataTableColumnProps<ContactTableInfoDTO> = DataTableColumnUtil.Text('Contact', 'contactName');
    columns.push(columnContact);

    const columnAddress: DataTableColumnProps<ContactTableInfoDTO> = DataTableColumnUtil.Address('Address', 'addressLine1',
      (c) => ({
        name: null,
        line1: c.addressLine1,
        line2: c.addressLine2,
        city: c.addressCity,
        state: c.addressState,
        zip: c.addressZip
      })
    );
    columns.push(columnAddress);

    const columnPhone: DataTableColumnProps<ContactTableInfoDTO> = DataTableColumnUtil.Phone('Phone', 'phoneNumber');
    columns.push(columnPhone);

    const columnButtons: DataTableColumnProps<ContactTableInfoDTO> = DataTableColumnUtil.Buttons(
      'perorgId',
      [
        { text: 'Edit', onClick: (rowData: ContactTableInfoDTO) => this.edit(rowData.perorgId || '') },
        { text: 'Delete', onClick: (rowData: ContactTableInfoDTO) => this.confirmDelete(rowData.perorgId || '', rowData.contactName || '') }
      ],
      150
    );
    columns.push(columnButtons);

    return columns;
  }

  private edit = (perorgId: string) => {
    HistoryUtil.push(Routes.generate(Routes.EDIT_CONTACT, { 'perorgId': perorgId }));
  }

  private confirmDelete = (perorgId: string, contactName: string) => {
    confirm({
      title: 'Are you sure you want to delete this contact?',
      content: 'The following record will be permanently deleted: ' + contactName,
      onOk: () => {
        this.delete(perorgId);
      },
    });
  }

  private delete = (perorgId: string) => {
    const committeeId = CurrentUser.Get()?.committeeId;
    if (committeeId) {
      ContactApiService.delete(perorgId, committeeId)
        .then(() => {
          notification.success({
            message: 'The contact was deleted successfully',
            description: ''
          });
          if (this.dataTable) {
            this.dataTable.refresh();
          }
        })
        .catch(() => {
          notification.error({
            message: 'Failed to delete the contact',
            description: ''
          });
        });
    }
  }

}

export default ManageContacts;