import {
  Col,
  ColProps,
  Input,
  Row,
  Typography,
} from 'antd';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import FormItem from 'antd/lib/form/FormItem';
import * as React from 'react';
import CurrencyInput from '../shared/CurrencyInput';
import CustomDatePicker from '../shared/CustomDatePicker';

const { TextArea } = Input;

interface PenaltyFormProps {
  penaltyNoticeDate?: string;
}

interface PenaltyFormState {
}

class PenaltyForm extends React.Component<PenaltyFormProps, PenaltyFormState> {
  constructor(props: PenaltyFormProps) {
    super(props);
    this.state = {
    };
  }

  render() {
    const columnSizingProps: ColProps = { xs: 24, sm: 24, md: 12 };

    return (
      <Row gutter={8}>
        <Col {...columnSizingProps}>
          <FormItem name="penaltyAmount" label="Penalty Amount">
            <CurrencyInput />
          </FormItem>
          <FormItem name="penaltyPaidDate" label="Penalty Paid Date">
            <CustomDatePicker />
          </FormItem>
          <FormItem name="penaltyDescription" label="Description">
            <TextArea rows={3} />
          </FormItem>
          {this.props.penaltyNoticeDate != '' ?
            <FormItem name="penaltyIssuedDate" label="Penalty Late Notice Issued Date">
              <Typography.Text>{this.props.penaltyNoticeDate}</Typography.Text>
            </FormItem> : undefined
          }
        </Col>
        <Col {...columnSizingProps}>
          <FormItem name="waivedDate" label="Waived Date">
            <CustomDatePicker />
          </FormItem>
          <FormItem name="waiverAmount" label="Waived Amount">
            <CurrencyInput />
          </FormItem>
          <FormItem name="penaltyWaiverReason" label="Waiver Reason">
            <TextArea rows={3} />
          </FormItem>
          <FormItem name="waiverRequested" label="Waiver Requested" valuePropName="checked">
            <Checkbox />
          </FormItem>
          <FormItem name="waiverDenied" label="Waiver Denied" valuePropName="checked">
            <Checkbox />
          </FormItem>
        </Col>
      </Row>
    );
  }
}

export default PenaltyForm;