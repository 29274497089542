import {
  Button,
  Col,
  ColProps,
  Input,
  Layout,
  notification,
  Row,
  Typography,
  Space,
  Select,
  InputNumber,
} from 'antd';
import { FormInstance } from 'antd/lib/form/Form';
import FormItem from 'antd/lib/form/FormItem';
import moment from 'moment';
import { RouteComponentProps } from 'react-router-dom';
import * as React from 'react';
import PeriodMainApiService from '../../api/AdminPeriodMainApiService';
import Routes from '../../consts/Routes';
import FormValidationUtil from '../../utils/FormValidationUtil';
import AdminPeriodListDTO from '../../models/AdminPeriodListDTO';
import HistoryUtil from '../../utils/HistoryUtil';
import ReportTypes from '../../consts/ReportType';
import CommFilingPeriodCatType from '../../consts/CommFilingPeriodCatType';
import SuppSpecElecType from '../../consts/SuppSpecElecType';
import ElectionYear from '../../consts/ElectionYear';
import TextArea from 'antd/lib/input/TextArea';
import CustomDatePicker from '../shared/CustomDatePicker';
import CustomForm from '../shared/CustomForm';
import CommFilingPeriodCatTypeCode from '../../consts/CommFilingPeriodCatTypeCode';

const { Content } = Layout;
const { Option } = Select;

interface PeriodProps {
  id: string | undefined;
}

interface PeriodState {
  id?: number,
  loading: boolean;
  periodMaster: AdminPeriodListDTO;
  endReq: boolean;
  dueReq: boolean;
}

class Period extends React.Component<RouteComponentProps<PeriodProps>, PeriodState>{
  private readonly _formRef = React.createRef<FormInstance>();
  constructor(props: RouteComponentProps<PeriodProps>) {
    super(props);
    this.state = {
      id: props.match.params.id ? parseInt( props.match.params.id ): undefined,
      loading: true,
      periodMaster: AdminPeriodListDTO.create(),
      endReq: true,
      dueReq: true
    };
  }

  componentDidMount() {
    this.getInitialValues();
  }

  render() {
    const columnSizingProps: ColProps = { xs: 24, sm: 24, md: 12 };
    const { loading, periodMaster, endReq, dueReq } = this.state;
    const adjTooltip = 'Adjustment factor will add/subtract days to the Supplementary Special Election Date ' +
      'or the committee election date. A negative adjustment factor will subtract.';

    return (
      <Content className="content-pad">
        <Typography.Title level={2}>Filing Period</Typography.Title>
        {loading ? undefined :
          <CustomForm formRef={this._formRef} name="PeriodAdd" onFinish={this.submitForm} initialValues={periodMaster} layout="vertical">
            <Row gutter={24}>
              <Col {...columnSizingProps}>
                <FormItem name="year"
                  label="Filing Period Year"
                  rules={[FormValidationUtil.RequiredDate('Filing period year is required')]}>
                  <CustomDatePicker picker="year" format="YYYY" placeholder={'-- Select Year --'}/>
                </FormItem>
                <FormItem name="filingPeriodDesc" label="Filing Period Description"
                  rules={[FormValidationUtil.Required('Filing Period Description is required')]}>
                  <Input type="text" />
                </FormItem>
                <FormItem name="commFilingPeriodCatTypeCd" label="Committee Filing Type Category"
                  rules={[FormValidationUtil.Required('Committee Filing Type Category is required')]}>
                  <Select placeholder={'-- Select Category --'}>
                    <Option value={CommFilingPeriodCatTypeCode.CENT}>
                      {CommFilingPeriodCatType.CENT}
                    </Option>
                    <Option value={CommFilingPeriodCatTypeCode.CTY}>
                      {CommFilingPeriodCatType.CTY}
                    </Option>
                    <Option value={CommFilingPeriodCatTypeCode.LOCL}>
                      {CommFilingPeriodCatType.LOCL}
                    </Option>
                    <Option value={CommFilingPeriodCatTypeCode.BALLOT}>
                        {CommFilingPeriodCatType.BALLOT}
                    </Option>
                    <Option value={CommFilingPeriodCatTypeCode.NA}>
                      {CommFilingPeriodCatType.NA}
                    </Option>
                    <Option value={CommFilingPeriodCatTypeCode.SCPA}>
                      {CommFilingPeriodCatType.SCPA}
                    </Option>
                    <Option value={CommFilingPeriodCatTypeCode.SWGA}>
                      {CommFilingPeriodCatType.SWGA}
                    </Option>
                  </Select>
                </FormItem>
                <FormItem name="reportType" label="Report Type"
                  rules={[FormValidationUtil.Required('Report Type is required')]}>
                  <Select placeholder={'-- Select Report Type --'}>
                    <Option value={ReportTypes.SPECIALELECTION}>
                      {ReportTypes.SPECIALELECTION}
                    </Option>
                    <Option value={ReportTypes.STANDARD}>
                      {ReportTypes.STANDARD}
                    </Option>
                    <Option value={ReportTypes.SUPPLEMENTAL}>
                      {ReportTypes.SUPPLEMENTAL}
                    </Option>
                  </Select>
                </FormItem>
                <FormItem name="electionYearInd" label="Election Year Indicator"
                  rules={[FormValidationUtil.Required('Election Year Indicator is required')]}>
                  <Select placeholder={'-- Select Indicator --'}>
                    <Option value={ElectionYear.ELECTION}>
                      {ElectionYear.ELECTION}
                    </Option>
                    <Option value={ElectionYear.NA}>
                      {ElectionYear.NA}
                    </Option>
                    <Option value={ElectionYear.NONELECTION}>
                      {ElectionYear.NONELECTION}
                    </Option>
                    <Option value={ElectionYear.SPECIAL}>
                      {ElectionYear.SPECIAL}
                    </Option>
                  </Select>
                </FormItem>
                <FormItem name="suppSpecialElectionType" label="Supplementary Special Election Type">
                  <Select allowClear={true} placeholder={'-- Select Type --'}>
                    <Option value={SuppSpecElecType.GENERAL}>
                      {SuppSpecElecType.GENERAL}
                    </Option>
                    <Option value={SuppSpecElecType.PRIMARY}>
                      {SuppSpecElecType.PRIMARY}
                    </Option>
                    <Option value={SuppSpecElecType.SPECIAL}>
                      {SuppSpecElecType.SPECIAL}
                    </Option>
                  </Select>
                </FormItem>
                <FormItem
                  name="suppSpecialElectionTypeDt"
                  label="Supplementary Special Election Date">
                  <CustomDatePicker onChange={(d) => d?.startOf('day')}/>
                </FormItem>
                <FormItem
                  name="adjDueDt"
                  label="Adjusted Date">
                  <CustomDatePicker onChange={(d) => d?.startOf('day')}/>
                </FormItem>
                <FormItem name="note" label="Period Help Text">
                  <TextArea rows={3} />
                </FormItem>
              </Col>
              <Col {...columnSizingProps}>
                <FormItem
                  name="beginDt"
                  label="Beginning Date">
                  <CustomDatePicker onChange={(d) => d?.startOf('day')}/>
                </FormItem>
                <FormItem name="beginDateDesc" label="Beginning Date Description" >
                  <Input type="text" />
                </FormItem>
                <FormItem
                  name="beginDateAdjFactor"
                  label="Begin Date Adjustment Factor"
                  tooltip={adjTooltip} >
                  <InputNumber />
                  </FormItem>
                <FormItem
                  name="endDt"
                  label="Ending Date"
                  rules={[FormValidationUtil.RequiredDate('Ending Date is required', endReq)]}
                  tooltip="Only required when an adjustment factor is not given">
                  <CustomDatePicker onChange={(d) => d?.startOf('day')}/>
                </FormItem>
                  <FormItem name="endDateDesc" label="Ending Date Description">
                  <Input type="text" />
                </FormItem>
                <FormItem
                  name="endDateAdjFactor"
                  label="End Date Adjustment Factor"
                  tooltip={adjTooltip} >
                  <InputNumber onChange={this.onEndAdjChange} />
                </FormItem>
                <FormItem
                  name="dueDt"
                  label="Due Date"
                  rules={[FormValidationUtil.RequiredDate('Due Date is required', dueReq)]}
                  tooltip="Only required when an adjustment factor is not given">
                  <CustomDatePicker onChange={(d) => d?.startOf('day')}/>
                </FormItem>
                <FormItem name="dueDateDesc" label="Due Date Description">
                  <Input type="text" />
                </FormItem>
                <FormItem
                  name="dueDateAdjFactor"
                  label="Due Date Adjustment Factor"
                  tooltip={adjTooltip} >
                  <InputNumber onChange={this.onDueAdjChange} />
                </FormItem>
                </Col>
            </Row>
            <Row>
              <Space>
                <Col>
                  <Button type="primary" htmlType="submit">Save</Button>
                </Col>
                <Col>
                  <Button onClick={this.cancelForm}>Cancel</Button>
                </Col>
              </Space>
            </Row>
          </CustomForm>
        }

      </Content >
    );
  }

  private onEndAdjChange = (value: any) => {
    if (value != null) {
      this.setState({ endReq: false });
      return;
    }
    this.setState({ endReq: true });
  }

  private onDueAdjChange = (value: any) => {
    if (value != null) {
      this.setState({ dueReq: false });
      return;
    }
    this.setState({ dueReq: true });
  }

  private submitForm = (values: any) => {
    const period = { ...this.state.periodMaster, ...values };
    period.year = moment(period.year).format('YYYY');
    return PeriodMainApiService.addPeriod(period)
      .then(() => {
        if (this.state.id) {
          HistoryUtil.push(Routes.PERIOD_MAIN);
        } else {
          this._formRef.current?.resetFields();
         
          notification.success({
            message: 'Your report submitted successfully.',
          });
        }
      })
      .catch(error => {
        notification.error({ message: error.message });
      });
  }

  private getInitialValues = () => {
    if (!this.state.id) {
      this.setState({ loading: false });
      return;
    }

    this.setState({ loading: true });

    PeriodMainApiService.getPeriodEdit(this.state.id).then(period => {

      if (period.adjDueDt) {
        period.adjDueDt = moment(period.adjDueDt) as any;
      }

      if (period.beginDt) {
        period.beginDt = moment(period.beginDt) as any;
      }

      if (period.dueDt) {
        period.dueDt = moment(period.dueDt) as any;
      }

      if (period.endDt) {
        period.endDt = moment(period.endDt) as any;
      }
      if (period.suppSpecialElectionTypeDt) {
        period.suppSpecialElectionTypeDt = moment(period.suppSpecialElectionTypeDt) as any;
      }

      if (period.year) {
        period.year = moment(period.year) as any;
      }

      if (period.beginDateAdjFactor == 999) {
        period.beginDateAdjFactor = null;
      }

      if (period.endDateAdjFactor == 999) {
        period.endDateAdjFactor = null;
      }

      if (period.dueDateAdjFactor == 999) {
        period.dueDateAdjFactor = null;
      }

      this.setState({
        periodMaster: period,
        loading: false,
        endReq: !(period.endDateAdjFactor != null),
        dueReq: !(period.dueDateAdjFactor != null)
      });

    }).catch(() => {
      this.setState({ loading: false });
    });
  }

  private cancelForm = () => {
    HistoryUtil.push(Routes.PERIOD_MAIN);
  }
}
export default Period;


