import CommitteeType from '../consts/CommitteeType';

export default class ElectionInfoRequiredUtil {
   public static showElectionDateCommitteeTypeCodes = [
        CommitteeType.SCHOOL_BOARD_CANDIDATE,
        CommitteeType.CITY_CANDIDATE__MAYOR,
        CommitteeType.CITY_CANDIDATE__CITY_COUNCIL,
        CommitteeType.CITY_CANDIDATE__OTHER,
        CommitteeType.LOCAL_BALLOT_ISSUE,
        CommitteeType.SCHOOL_BOARD_OR_OTHER_POLITICAL_SUBDIVISION_PAC,
        CommitteeType.OTHER_POLITICAL_SUBDIVISION_CANDIDATE
    ];

  public static electionDateRequiredCommitteeTypeCodes = [
        CommitteeType.SCHOOL_BOARD_CANDIDATE,
        CommitteeType.CITY_CANDIDATE__MAYOR,
        CommitteeType.CITY_CANDIDATE__CITY_COUNCIL,
        CommitteeType.CITY_CANDIDATE__OTHER,
        CommitteeType.LOCAL_BALLOT_ISSUE,
        CommitteeType.SCHOOL_BOARD_OR_OTHER_POLITICAL_SUBDIVISION_PAC,
        CommitteeType.OTHER_POLITICAL_SUBDIVISION_CANDIDATE
    ];

    public static showElectionDate(typeCode?: string) {
        if (typeCode && this.showElectionDateCommitteeTypeCodes.indexOf(typeCode) >= 0) {
            return true;
        }
        return false;
    }

    public static electionDateRequired(typeCode?: string) {
        if ( !!typeCode && this.electionDateRequiredCommitteeTypeCodes.indexOf(typeCode) >= 0) {
            return true;
        }
        return false;
    }

    public static getElectionYears() {
        const electionYears: number[] = [];
        // TODO: Maybe this should be something like current year +- 10 years rather than starting from 1995?
        for (let i = (new Date()).getFullYear() + 10; i > 1995; i--) {
            electionYears.push(i);
        }

        return electionYears;
    }

}
