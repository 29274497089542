import { notification, FormInstance, Row, Col, Input, Checkbox, Button, Table, Typography, Tooltip, } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import * as React from 'react';
import FormValidationUtil from '../../../../utils/FormValidationUtil';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import PfdRetirementDTO from '../../../../models/PfdRetirementDTO';
import CustomForm from '../../../shared/CustomForm';


const { Paragraph, Title } = Typography;
const { Column } = Table;

interface RetirementProps {
  retirements: PfdRetirementDTO[];
  noOtherRetirements: boolean;
}

interface RetirementState {
  retirements: PfdRetirementDTO[];
  noOtherRetirements: boolean;
}

class Retirement extends React.Component<RetirementProps, RetirementState>
{
  private readonly _formRef = React.createRef<FormInstance>();

  constructor(props: RetirementProps) {
    super(props);

    this.state = {
      retirements: this.props.retirements || [],
      noOtherRetirements: this.props.noOtherRetirements,
    };
  }

  moveBack = async (): Promise<{ model: { retirements: PfdRetirementDTO[], noOtherRetirements: boolean }, errorFields: any }> => {
    return {
      model: { retirements: this.state.retirements, noOtherRetirements: this.state.noOtherRetirements }, errorFields: null
    };
  }

  validate = async (): Promise<{ model: { retirements: PfdRetirementDTO[], noOtherRetirements: boolean }, errorFields: any }> => {
    if (this.state.retirements.length > 0 || this.state.noOtherRetirements) {
      return {
        model: { retirements: this.state.retirements, noOtherRetirements: this.state.noOtherRetirements }, errorFields: null
      };
    } else {
      notification.error({
        message: 'Must list a least one retirement system or click no other retirement system.'
      });
      return Promise.reject();
    }
  }

  render() {
    return (
      <>
        <Title level={4}>
          Retirement Systems
        </Title>
        <Paragraph>
          Enter the name of each retirement sytem from which you received income in excess of $1,000 in the covered year.
        </Paragraph>
        <Paragraph>
          Do NOT list retirement systems that did not generate income for you in the covered year.
        </Paragraph>
        <Row>
          <Col xs={24}>
            <CustomForm formRef={this._formRef} onFinish={this.addRetirement} layout="vertical">
              <Tooltip title="e.g. IPERS, Social Security">
                <FormItem
                  name="name"
                  label="Name of Retirement System"
                  rules={[FormValidationUtil.Required('Name of Retirement System is required')]}
                >
                  <Input type="text" />
                </FormItem>
              </Tooltip>
              <Button type="primary" htmlType="submit">Add</Button>
            </CustomForm>
          </Col>
        </Row>
        <br />
        {
          this.state.retirements.length > 0 ?
            <Row>
              <Col xs={24}>
                <Table dataSource={this.state.retirements}>
                  <Column title="Name" dataIndex="name" key="name" />
                  <Column
                    title="Delete"
                    dataIndex=""
                    render={(text, record, index) =>
                      <Button onClick={() => { this.deleteRetirement(index); }}>Delete</Button>
                    }
                  />
                </Table>
              </Col>
            </Row>
            :
            <>
              <Row>
                <Col>
                  <Checkbox className="bold-checkbox" checked={this.state.noOtherRetirements} onChange={this.toggleRetirement}>
                    Click here if you had no income from any retirement systems in excess of $1,000
                  </Checkbox>
                </Col>
              </Row>
              <br />
            </>
        }
      </>
    );
  }
  private addRetirement = (values: any) => {
    this._formRef.current?.resetFields();
    this.setState({ retirements: [...this.state.retirements, values] });
  }

  private toggleRetirement = (checkBox: CheckboxChangeEvent) => {
    this.setState({ noOtherRetirements: checkBox.target.checked });
  }

  private deleteRetirement = (index: any) => {
    const dataSource = [...this.state.retirements];
    const value = dataSource[index];
    this.setState({ retirements: dataSource.filter(item => item !== value) });
  }
}

export default Retirement;