// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
import BaseApi from './BaseApi';
import TableRequestDTO from '../models/TableRequestDTO';
import AdminIiePenaltyDTO from '../models/AdminIiePenaltyDTO';
import TableResponseDTO from '../models/TableResponseDTO';
import AdminIieListDTO from '../models/AdminIieListDTO';
import ResultDTO from '../models/ResultDTO';
import IieDTO from '../models/IieDTO';

export class AdminIieApiService extends BaseApi {

  // post: api/admin/iie?filingStatusType=${encodeURIComponent(filingStatusType)}
  public getIieList(tableRequest: TableRequestDTO, filingStatusType: string): Promise<TableResponseDTO<AdminIieListDTO>> {
    let url = `api/admin/iie`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (filingStatusType != null) {
      url += `${prefix}filingStatusType=${encodeURIComponent(filingStatusType)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, TableResponseDTO<AdminIieListDTO>>(tableRequest, 'post', url, true, false);
  }

  // delete: api/admin/iie/${encodeURIComponent(expendId)}
  public deleteIie(expendId: string): Promise<void> {
    let url = `api/admin/iie/${encodeURIComponent(expendId)}`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, void>(null, 'delete', url, true, false);
  }

  // post: api/admin/iie/${encodeURIComponent(expendId)}/approve
  public approve(expendId: string): Promise<void> {
    let url = `api/admin/iie/${encodeURIComponent(expendId)}/approve`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, void>(null, 'post', url, true, false);
  }

  // post: api/admin/iie/${encodeURIComponent(expendId)}/reject
  public reject(expendId: string): Promise<void> {
    let url = `api/admin/iie/${encodeURIComponent(expendId)}/reject`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, void>(null, 'post', url, true, false);
  }

  // get: api/admin/iie/${encodeURIComponent(expendId)}/penalty
  public getIiePenalty(expendId: string): Promise<AdminIiePenaltyDTO> {
    let url = `api/admin/iie/${encodeURIComponent(expendId)}/penalty`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, AdminIiePenaltyDTO>(null, 'get', url, true, false);
  }

  // post: api/admin/iie/${encodeURIComponent(expendId)}/penalty?sendNotice=${sendNotice}
  public saveIiePenalty(expendId: string, penaltyData: AdminIiePenaltyDTO, sendNotice: boolean): Promise<ResultDTO> {
    let url = `api/admin/iie/${encodeURIComponent(expendId)}/penalty`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (sendNotice != null) {
      url += `${prefix}sendNotice=${sendNotice}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, ResultDTO>(penaltyData, 'post', url, true, false);
  }

  // post: api/admin/iie/${encodeURIComponent(expendId)}/status?status=${encodeURIComponent(status)}
  public updateStatus(expendId: string, status: string): Promise<void> {
    let url = `api/admin/iie/${encodeURIComponent(expendId)}/status`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (status != null) {
      url += `${prefix}status=${encodeURIComponent(status)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, void>(null, 'post', url, true, false);
  }

  // get: api/admin/iie/${encodeURIComponent(expendId)}
  public getIieExpend(expendId: string): Promise<IieDTO> {
    let url = `api/admin/iie/${encodeURIComponent(expendId)}`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, IieDTO>(null, 'get', url, true, false);
  }

  // post: api/admin/iie/penaltyNotice?penaltyId=${encodeURIComponent(penaltyId)}
  public sendIieLatePenaltyNotice(penaltyId: string): Promise<ResultDTO> {
    let url = `api/admin/iie/penaltyNotice`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (penaltyId != null) {
      url += `${prefix}penaltyId=${encodeURIComponent(penaltyId)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, ResultDTO>(null, 'post', url, true, false);
  }
}
var service = new AdminIieApiService();
export default service;
