// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
import BaseApi from './BaseApi';
import TableRequestDTO from '../models/TableRequestDTO';
import TableResponseDTO from '../models/TableResponseDTO';
import AdminGiftBequestListDTO from '../models/AdminGiftBequestListDTO';

export class AdminGiftBequestApiService extends BaseApi {

  // post: api/admin/giftbequests/table?isApproveTable=${isApproveTable}
  public getGiftBequestList(tableRequest: TableRequestDTO, isApproveTable: boolean): Promise<TableResponseDTO<AdminGiftBequestListDTO>> {
    let url = `api/admin/giftbequests/table`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (isApproveTable != null) {
      url += `${prefix}isApproveTable=${isApproveTable}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, TableResponseDTO<AdminGiftBequestListDTO>>(tableRequest, 'post', url, true, false);
  }

  // post: api/admin/giftbequests/approve?giftBequestId=${encodeURIComponent(giftBequestId)}
  public approve(giftBequestId: string): Promise<void> {
    let url = `api/admin/giftbequests/approve`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (giftBequestId != null) {
      url += `${prefix}giftBequestId=${encodeURIComponent(giftBequestId)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, void>(null, 'post', url, true, false);
  }

  // post: api/admin/giftbequests/reject?giftBequestId=${encodeURIComponent(giftBequestId)}
  public reject(giftBequestId: string): Promise<void> {
    let url = `api/admin/giftbequests/reject`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (giftBequestId != null) {
      url += `${prefix}giftBequestId=${encodeURIComponent(giftBequestId)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, void>(null, 'post', url, true, false);
  }
}
var service = new AdminGiftBequestApiService();
export default service;
