// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import EntitySelectorDTO from './EntitySelectorDTO';

interface DrsfaDTO extends EntitySelectorDTO { 
  drsfaId: string | null;
  committeeName: string | null;
  committeeType: string | null;
  firstName: string | null;
  middleInitial: string | null;
  lastName: string | null;
  address1: string | null;
  address2: string | null;
  city: string | null;
  state: string | null;
  zip: string | null;
  committeeEmail: string | null;
  phone: string | null;
  purpose: string | null;
  description: string | null;
  officeSought: string | null;
  politicalParty: string | null;
  district: string | null;
  electionYear: number | null;
  county: string | null;
  electionDate: moment.Moment | string | null;
}
const DrsfaDTO = {
  create: (initValues?: Partial<DrsfaDTO> | Record<string, unknown> | null) => {
    return Object.assign(EntitySelectorDTO.create(),
    {
      drsfaId: "00000000-0000-0000-0000-000000000000",
      committeeName: null,
      committeeType: null,
      firstName: null,
      middleInitial: null,
      lastName: null,
      address1: null,
      address2: null,
      city: null,
      state: null,
      zip: null,
      committeeEmail: null,
      phone: null,
      purpose: null,
      description: null,
      officeSought: null,
      politicalParty: null,
      district: null,
      electionYear: null,
      county: null,
      electionDate: null,
    },
    initValues);
  }
};

export default DrsfaDTO;