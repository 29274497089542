import {
  Button,
  FormInstance,
  Input,
  Layout,
  notification,
  Modal,
  Select,
  Typography,
  Space
} from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import * as React from 'react';
import LookupsApiService from '../../../api/LookupsApiService';
import Routes from '../../../consts/Routes';
import HistoryUtil from '../../../utils/HistoryUtil';
import LookupTableDTO from '../../../models/LookupTableDTO';
import CodeLookupTableDTO from '../../../models/CodeLookupTableDTO';
import FormValidationUtil from '../../../utils/FormValidationUtil';
import PfdDivisionDTO from '../../../models/PfdDivisionDTO';
import RegisterDonorApiService from '../../../api/RegisterDonorApiService';
import GbReceivingDepartmentDTO from '../../../models/GbReceivingDepartmentDTO';
import { RouteComponentProps } from 'react-router-dom';
import CurrentUser from '../../../utils/CurrentUser';
import DonorProfileApiService from '../../../api/DonorProfileApiService';
import AddressFormFields from '../../shared/AddressFormFields';
import RegisterFormItems from '../../account/RegisterFormItems';
import DonorRegistrationDTO from '../../../models/DonorRegistrationDTO';
import RegisterDTO from '../../../models/RegisterDTO';
import CustomForm from '../../shared/CustomForm';

const Option = Select.Option;
const { Title } = Typography;
const { Content } = Layout;
const { confirm } = Modal;

interface DonorRegistrationProps {

}

interface DonorRegistrationState {
  donorId?: string | null;
  loading: boolean;
  saving: boolean;
  donor: GbReceivingDepartmentDTO;
  stateAgencies: LookupTableDTO[];
  isStateAgencySelected: boolean;
  divisionsOrDepartments: PfdDivisionDTO[];
  states: CodeLookupTableDTO[];
}

class DonorRegistration extends React.Component<RouteComponentProps<DonorRegistrationProps>, DonorRegistrationState> {
  private readonly _formRef = React.createRef<FormInstance>();

  constructor(props: RouteComponentProps<DonorRegistrationProps>) {
    super(props);

    this.state = {
      donorId: CurrentUser.Get()?.receivingDepartmentId,
      loading: true,
      saving: false,
      donor: GbReceivingDepartmentDTO.create({ departmentId: '', divisionId: '', stateCd: 'IA' }),
      stateAgencies: [],
      isStateAgencySelected: false,
      divisionsOrDepartments: [],
      states: []
    };
  }

  componentDidMount() {
    this.loadLookups();
    this.loadDonor();
  }

  render() {
    const { donorId, loading, saving, donor, stateAgencies, isStateAgencySelected, divisionsOrDepartments } = this.state;

    return (
      <Content className="content-pad">
        {!loading &&
          <CustomForm
            formRef={this._formRef}
            onFinish={this.submitRegistration}
            initialValues={donor}
            layout="vertical"
            validateTrigger={['onChange', 'onBlur']}
          >
            <Title level={3}>Profile</Title>
            <FormItem name="receivingDepartmentId" hidden={true}>
              <Input hidden={true} disabled={true} aria-hidden={true}/>
            </FormItem>
            <FormItem name="departmentId" label="State Agency" rules={[FormValidationUtil.RequiredNumber()]}>
              <Select disabled={saving} onSelect={(value: number) => this.onChangeStateAgency(value as number, this._formRef)}>
                <Option value="" disabled={true}>-- Select Agency / Department --</Option>
                {stateAgencies.map(sa => (
                  <Option key={sa.id} value={sa.id}>{sa.name}</Option>
                ))}
              </Select>
            </FormItem>
            <FormItem
              name="divisionId"
              label="Division / Department"
              tooltip="If there is no division choose None/Not Applicable."
              rules={[FormValidationUtil.RequiredNumber()]}>
              <Select disabled={!isStateAgencySelected || saving}>
                <Option value="" disabled={true}>-- Select Division --</Option>
                {divisionsOrDepartments.map(div => (
                  <Option key={div.divisionId} value={div.divisionId}>{div.name}</Option>
                ))}
              </Select>
            </FormItem>
            <AddressFormFields
              name={{ name: 'contactName', label: 'Contact Name' }}
              address1={{ name: 'addressLine1', label: 'Address 1' }}
              address2={{ name: 'addressLine2', label: 'Address 2' }}
              city={{ name: 'city', label: 'City', maxLength: 20 }}
              state={{ name: 'stateCd', label: 'State' }}
              zip={{ name: 'zip', label: 'Zip Code' }}
              phone={{ name: 'phone', label: 'Contact Phone Number' }}
              email={{ name: 'contactEmail', label: 'Contact Email Address' }} />
            {!donorId &&
              <>
                <Title level={3}>Account</Title>
                <RegisterFormItems />
              </>
            }
            <Space>
              <Button type="primary" htmlType="submit" disabled={saving}>Submit</Button>
              <Button onClick={this.handleCancelClicked} disabled={saving}>Cancel</Button>
            </Space>
          </CustomForm>
        }
      </Content>
    );
  }

  private onChangeStateAgency = (value: number, form: React.RefObject<FormInstance<any>> | null) => {
    if (form && form.current) {
      form.current.setFieldsValue({ divisionId: '' });
    }

    LookupsApiService.getDivisionsByDepartment(value)
      .then(division => {
        this.setState({ divisionsOrDepartments: division, isStateAgencySelected: true });
      }).catch(() => {
        notification.error({ message: 'Failed to load divisions.' });
      });
  };

  private loadLookups = () => {
    const stateAgenciesPromise = LookupsApiService.getDepartments();
    const statesPromise = LookupsApiService.getStates();

    Promise.all([stateAgenciesPromise, statesPromise])
      .then(results => {
        const [stateAgencies] = results;
        const [, states] = results;
        states.sort((a, b) => (((a.code || '') > (b.code || '')) ? 1 : -1));

        this.setState({ stateAgencies, states });
      });
  }

  private loadDonor = () => {
    if (!this.state.donorId) {
      this.setState({ loading: false });
      return;
    }

    this.setState({ loading: true });

    DonorProfileApiService.get(this.state.donorId)
      .then(donor => {
        if (donor.departmentId) {
          this.onChangeStateAgency(donor.departmentId, null);
        }

        this.setState({ donor, loading: false });
      }).catch(() => {
        this.setState({ loading: false });
      });
  }

  private submitRegistration = (values: any) => {
    this.setState({ saving: true });

    const { username, firstName, lastName, email, password, confirmPassword, ...gbReceivingDepartment } = values;
    const model = DonorRegistrationDTO.create({
      gbReceivingDepartment,
      register: !this.state.donorId ? RegisterDTO.create({ username, firstName, lastName, email, password, confirmPassword }) : null
    });

    if (this.state.donorId) {
      DonorProfileApiService.edit(model)
        .then(() => {
          notification.success({
            message: 'Your profile has been updated.'
          });
          this.setState({ saving: false });
        })
        .catch(() => {
          notification.error({ message: 'Failed to update the profile.' });
          this.setState({ saving: false });
        });
    }
    else {
      RegisterDonorApiService.add(model)
        .then((result) => {
          if (!result.succeeded) {
            notification.error({ message: result.errors[0] });
          }
          HistoryUtil.push(Routes.HOME_ROUTE);
          notification.success({
            message: 'Your donor account has been created and an email has been sent to the '
              + 'email address you provided. Click the link in the email to confirm your '
              + 'account, and use your username and password to login.',
            duration: 20
          });
          this.setState({ saving: false });
        })
        .catch(() => {
          notification.error({ message: 'Failed to register the account.' });
          this.setState({ saving: false });
        });
    }
  }

  private handleCancelClicked = () => {
    confirm({
      title: 'Are you sure you want to leave?',
      okText: 'Yes',
      cancelText: 'No',
      onOk: () => {
        if (this.state.donorId) {
          HistoryUtil.push(Routes.DONOR_SUMMARY);
        }
        else {
          HistoryUtil.push(Routes.LOGIN);
        }
      }
    });
  }

}

export default DonorRegistration;
