import { Modal, FormInstance} from 'antd';
import * as React from 'react';
import FormValidationUtil from '../../utils/FormValidationUtil';
import CurrencyInput from '../shared/CurrencyInput';
import CustomForm from '../shared/CustomForm';
import FormItem from 'antd/lib/form/FormItem';

interface AdjustBalanceFormProps {
    onClose: () => void;
    onSave: (newBalance: number) => void;
    formRef?: React.RefObject<FormInstance>;
    startingBalance: number;
}

interface AdjustBalanceFormState {
    startingBalance: number;
}

class AdjustBalanceForm extends React.Component<AdjustBalanceFormProps, AdjustBalanceFormState> {
    private readonly _formRef = this.props.formRef;

    constructor(props: AdjustBalanceFormProps) {
        super(props);
        this.state = {
            startingBalance: 0
        };
    }

    render() {
        const { startingBalance } = this.state;
        const { onClose } = this.props;
        return (
            <Modal
                title="Adjust Beginning Balance"
                onOk={() => this.handleSave()}
                onCancel={() => onClose()}
                visible={true}
                okText="Save"
                cancelText="Cancel"
            >
                <CustomForm formRef={this._formRef} initialValues={{ startingBalance: startingBalance }} layout="vertical">
                    <FormItem
                        name="startingBalance"
                        label="New Beginning Cash Amount"
                        rules={[FormValidationUtil.RequiredNumber('Amount is required')]}
                    >
                        <CurrencyInput />
                    </FormItem>
                </CustomForm>
            </Modal>
        );
    }

    private handleSave = () => {
        if (this._formRef?.current && this.props.onSave) {
            const newBalance = this._formRef.current.getFieldValue('startingBalance');
            this.props.onSave(newBalance); 
        }
    }
}

export default AdjustBalanceForm;
