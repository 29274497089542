// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import FilerTypeDTO from './FilerTypeDTO';
import CommitteeInfoDTO from './CommitteeInfoDTO';
import PersonInfoDTO from './PersonInfoDTO';
import SecondaryCommitteeInfoDTO from './SecondaryCommitteeInfoDTO';
import JurisdictionInfoDTO from './JurisdictionInfoDTO';
import BankAccountDTO from './BankAccountDTO';
import UserIdAffirmationDTO from './UserIdAffirmationDTO';
import RegisterDTO from './RegisterDTO';

interface DR1DTO { 
  committeeFilerType: FilerTypeDTO | null;
  committeeInfo: CommitteeInfoDTO | null;
  personInfo1: PersonInfoDTO | null;
  personInfo2: PersonInfoDTO | null;
  personInfo3: PersonInfoDTO | null;
  secondaryCommittee1: SecondaryCommitteeInfoDTO | null;
  secondaryCommittee2: SecondaryCommitteeInfoDTO | null;
  jurisdictionInfo: JurisdictionInfoDTO | null;
  bankAccounts: BankAccountDTO[] | null;
  userId: UserIdAffirmationDTO | null;
  register: RegisterDTO | null;
  roleList: string[];
  postMarkDate: moment.Moment | string | null;
  fileAmendDate: moment.Moment | string | null;
  auditorsNoteId: string | null;
}
const DR1DTO = {
  create: (initValues?: Partial<DR1DTO> | Record<string, unknown> | null) => {
    return Object.assign(
    {
      committeeFilerType: null,
      committeeInfo: null,
      personInfo1: null,
      personInfo2: null,
      personInfo3: null,
      secondaryCommittee1: null,
      secondaryCommittee2: null,
      jurisdictionInfo: null,
      bankAccounts: [],
      userId: null,
      register: null,
      roleList: [],
      postMarkDate: null,
      fileAmendDate: null,
      auditorsNoteId: null,
    },
    initValues);
  }
};

export default DR1DTO;