// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import EntitySelectorDTO from './EntitySelectorDTO';

interface Dr2PenaltyDTO extends EntitySelectorDTO { 
  uid: number;
  committeeId: string | null;
  effectiveTs: moment.Moment | string;
  waiverRequested: boolean;
  commissionActionTs: moment.Moment | string | null;
  datePaid: moment.Moment | string | null;
  amtPaidWaived: number | null;
  waiverReductionInd: string | null;
  penaltyIssuedDate: moment.Moment | string | null;
  penaltyAmount: number | null;
  note: string | null;
  waived: boolean;
  reduced: boolean;
  penaltyTitle: string | null;
  committeeFilingId: number;
  waiverDenied: boolean | null;
}
const Dr2PenaltyDTO = {
  create: (initValues?: Partial<Dr2PenaltyDTO> | Record<string, unknown> | null) => {
    return Object.assign(EntitySelectorDTO.create(),
    {
      uid: 0,
      committeeId: "00000000-0000-0000-0000-000000000000",
      effectiveTs: new Date(0).toISOString(),
      waiverRequested: false,
      commissionActionTs: null,
      datePaid: null,
      amtPaidWaived: null,
      waiverReductionInd: null,
      penaltyIssuedDate: null,
      penaltyAmount: null,
      note: null,
      waived: false,
      reduced: false,
      penaltyTitle: null,
      committeeFilingId: 0,
      waiverDenied: null,
    },
    initValues);
  }
};

export default Dr2PenaltyDTO;