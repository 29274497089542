import { Layout, Tabs, Typography } from 'antd';
import * as React from 'react';
import CommitteeTypeDTO from '../../models/CommitteeTypeDTO';
import UserSecurity from '../../models/UserSecurity';
import LookupsApiService from '../../api/LookupsApiService';
import DataTableButtonUtil from '../shared/DataTable/DataTableButtonUtil';
import AddressReport from './Reports/AddressReport';
import PeriodReport from './Reports/PeriodReport';
import DR2FilingReport from './Reports/DR2FilingReport';
import PersonReport from './Reports/PersonReport';
import SupplementaryFilingComplianceReport from './Reports/SupplementaryFilingComplianceReport';
import PfdFilingReport from './Reports/PfdFilingReport';
import GiftBequestReport from './Reports/GiftBequestReport';

const Content = Layout.Content;
const { TabPane } = Tabs;

interface ReportsProps {
  userSessionChangedAction: (model: UserSecurity) => void;
}

interface ReportsState {
  committeeTypes: CommitteeTypeDTO[];
  yearOptions: number[];
}

class Reports extends React.Component<ReportsProps, ReportsState> {
  constructor(props: ReportsProps) {
    super(props);

    this.state = {
      committeeTypes: [],
      yearOptions: this.getYearOptions()
    };
  }

  componentDidMount() {
    this.loadLookups();
  }

  render() {
    const { committeeTypes, yearOptions } = this.state;
    const dataTableButtons = [];
    dataTableButtons.push(DataTableButtonUtil.Reset());

    return (
      <Content className="content-pad">
        <Typography.Title level={4}>Reports</Typography.Title>
        <Tabs>
          <TabPane tab="Address" key={1}>
            <AddressReport
              committeeTypes={committeeTypes}
            />
          </TabPane>
          <TabPane tab="Single Period Due Date" key={2}>
            <PeriodReport
              committeeTypes={committeeTypes}
              yearOptions={yearOptions}
            />
          </TabPane>
          <TabPane tab="DR2 Filings" key={3}>
            <DR2FilingReport
              committeeTypes={committeeTypes}
              yearOptions={yearOptions}
            />
          </TabPane>
          <TabPane tab="Person Committee" key={4}>
            <PersonReport />
          </TabPane>
          <TabPane tab="Supplementary Filing Compliance" key={5}>
            <SupplementaryFilingComplianceReport
              committeeTypes={committeeTypes}
              yearOptions={yearOptions}
            />
          </TabPane>
          <TabPane tab="PFD Filing" key={6}>
            <PfdFilingReport yearOptions={yearOptions} />
          </TabPane>
          <TabPane tab="Gift Bequest" key={7}>
            <GiftBequestReport />
          </TabPane>
        </Tabs>
      </Content>
    );
  }

  private loadLookups = () => {
    const committeeTypesPromise = LookupsApiService.getCommitteeTypes();

    Promise.all([committeeTypesPromise])
      .then(results => {
        const [committeeTypes] = results;
        this.setState({ committeeTypes });
      });
  }

  private getYearOptions = (): number[] => {
    const t: number[] = [];
    for (let i = 1996; i < new Date().getFullYear() + 10; i++) {
      t.push(i);
    }
    return t;
  }
}

export default Reports;
