// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';

interface RegisterDTO { 
  username: string;
  firstName: string | null;
  lastName: string | null;
  email: string;
  password: string;
  confirmPassword: string | null;
  requestedRoleId: number;
  statusId: number;
  committeeId: string | null;
  receivingDepartmentId: string | null;
}
const RegisterDTO = {
  create: (initValues?: Partial<RegisterDTO> | Record<string, unknown> | null) => {
    return Object.assign(
    {
      username: '',
      firstName: null,
      lastName: null,
      email: '',
      password: '',
      confirmPassword: null,
      requestedRoleId: 0,
      statusId: 0,
      committeeId: null,
      receivingDepartmentId: null,
    },
    initValues);
  }
};

export default RegisterDTO;