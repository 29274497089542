import {
    Button,
  Layout,
  Space,
  Typography,
} from 'antd';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import DataTable, { DataTableColumnProps } from '../../shared/DataTable/DataTable';
import DataTableButtonUtil from '../../shared/DataTable/DataTableButtonUtil';
import GbReportsDTO from '../../../models/GbReportsDTO';
import DataTableColumnUtil from '../../shared/DataTable/DataTableColumnUtil';
import PublicReportsApiService from '../../../api/PublicReportsApiService';
import HistoryUtil from '../../../utils/HistoryUtil';
import Routes from '../../../consts/Routes';
import { ArrowLeftOutlined } from '@ant-design/icons';
import DataTableCellRendererUtil from '../../shared/DataTable/DataTableCellRendererUtil';

const { Content } = Layout;

interface GbReportsProps {

}

interface GbReportsState {
  tableColumns: DataTableColumnProps<GbReportsDTO>[];
}

class GbReports extends React.Component<RouteComponentProps<GbReportsProps>, GbReportsState> {

  constructor(props: RouteComponentProps<GbReportsProps>) {
    super(props);

    this.state = {
      tableColumns: this.getTableColumns()
    };
  }

  render() {
    const actionButtons = [];
    actionButtons.push(DataTableButtonUtil.Reset());
    return (
      <Content className="content-pad">
        <DataTable
          globalSearch={true}
          serverSide={true}
          buttonBar={actionButtons}
          columns={this.state.tableColumns}
          tableProps={{
            rowKey: 'id',
            sortDirections: ['ascend', 'descend'],
            locale: { emptyText: 'No Reports Currently Match Your Filter' }
          }}
          fetchData={{
            fetch: function (tableRequest) {
              return PublicReportsApiService.getGbReports(tableRequest)
                .then(res => {
                  return res;
                });
            },
            failureMessage: 'Failed to retrieve public reports'
          }}
          styleOptions={{ compact: true, alternatingRowHighlight: true }}
          title={
            <Button type="link" onClick={() => HistoryUtil.push(Routes.generate(Routes.PUBLIC_REPORTS))}>
              <Typography.Title level={4}>
                <Space size="large">
                  <ArrowLeftOutlined className="public-report-links"/> Gifts/Bequests
                </Space>
              </Typography.Title>
            </Button>
          }
        />
      </Content>
    );
  }

  private getTableColumns = (): DataTableColumnProps<GbReportsDTO>[] => {
    const generatedOn = DataTableColumnUtil.Date<GbReportsDTO>('Filed On', 'filedOn');
    generatedOn.render = DataTableCellRendererUtil.ShortDate;
    return [
      DataTableColumnUtil.Text('Year', 'year', null, { defaultSortOrder: 'descend' }),
      DataTableColumnUtil.Text('Department', 'department'),
      DataTableColumnUtil.Text('Division', 'division'),
      DataTableColumnUtil.Text('Donor', 'donorName'),
      generatedOn,
      DataTableColumnUtil.Text('Filing Status', 'filingStatus'),
      DataTableColumnUtil.Buttons('fileName',
        [
          { text: 'View', onClick: (rowData) => window.open(rowData.fileUrl || '') },
        ])
    ];
  }
}

export default GbReports;