import { Layout, Modal, notification } from 'antd';
import * as React from 'react';
import { connect } from 'react-redux';
import AdminDr1ApiService from '../../api/AdminDr1ApiService';
import LookupsApiService from '../../api/LookupsApiService';
import Routes from '../../consts/Routes';
import CodeLookupTableDTO from '../../models/CodeLookupTableDTO';
import Dr1TableDTO from '../../models/Dr1TableDTO';
import UserSecurity from '../../models/UserSecurity';
import { UserSessionActions } from '../../redux/actions/UserSessionActions';
import CurrentUser from '../../utils/CurrentUser';
import HistoryUtil from '../../utils/HistoryUtil';
import DataTable, { DataTableColumnProps, FilterType } from '../shared/DataTable/DataTable';
import DataTableButtonUtil from '../shared/DataTable/DataTableButtonUtil';
import DataTableColumnUtil from '../shared/DataTable/DataTableColumnUtil';

const { Content } = Layout;
const { confirm } = Modal;

interface ManageDr1Props {
  userSessionChangedAction: (model: UserSecurity) => void;
}

interface ManageDr1State {
  tableColumns: DataTableColumnProps<Dr1TableDTO>[];
  loadingDr1: boolean;
  committeeTypes: CodeLookupTableDTO[];
}

class ManageDr1 extends React.Component<ManageDr1Props, ManageDr1State> {
  private dataTable: DataTable<Dr1TableDTO> | undefined;

  constructor(props: ManageDr1Props) {
    super(props);

    this.state = {
      tableColumns: [],
      loadingDr1: false,
      committeeTypes: []
    };
  }

  componentDidMount() {
    this.getInitialValues();
  }

  render() {
    const actionButtons: any = [];
    const { loadingDr1 } = this.state;
    actionButtons.push(DataTableButtonUtil.Reset());

    return (
      <Content className="content-pad">
        <DataTable
          ref={(element: any) => (this.dataTable = element)}
          serverSide={true}
          tableProps={{
            rowKey: 'committeeId',
            sortDirections: ['ascend', 'descend'],
            locale: {
              emptyText: 'Currently there are no DR1\'s that need to be approved'
            },
            loading:  loadingDr1,
          }}
          globalSearch={true}
          title="Approve DR1"
          buttonBar={actionButtons}
          columns={this.getTableColumns()}
          fetchData={{
            fetch: function (tableRequest) {return AdminDr1ApiService.getDr1List(tableRequest);},
            failureMessage: 'Failed to retrieve DR1',
            
          }}
          styleOptions={{ compact: true, alternatingRowHighlight: true }}
          stateSaving={{
            enabled: true,
            tableUniqueKey: 'adminManageDr1',
            perSession: true,
          }}
        />
      </Content>
    );
  }

  private getTableColumns() {
    const columns: DataTableColumnProps<Dr1TableDTO>[] = [];

    const columnDate: DataTableColumnProps<Dr1TableDTO> = DataTableColumnUtil.Date('Date', 'effectiveDate',100, {defaultSortOrder: 'descend'});
    columns.push(columnDate);

    const columnName: DataTableColumnProps<Dr1TableDTO> = DataTableColumnUtil.Text('Name', 'committeeName');
    columns.push(columnName);

    const columnType: DataTableColumnProps<Dr1TableDTO> = DataTableColumnUtil.DropdownMulti(
      'Type',
      'committeeTypeName',
      (this.state.committeeTypes || []).map(c => ({ text: c.name || '', value: c.code || '' }))
    );
    columns.push(columnType);

    const columnIsDuplicateName: DataTableColumnProps<Dr1TableDTO> = DataTableColumnUtil.BooleanCheckbox(
      'Is Duplicate Name',
      'isDuplicateName',
      150
    );
    columnIsDuplicateName.filterType = FilterType.NONE;
    columnIsDuplicateName.sorter = false;
    columns.push(columnIsDuplicateName);

    const dr1Buttons = [
      { text: 'View', onClick: (rowData: Dr1TableDTO) => this.view(rowData) },
      { text: 'Approve', onClick: (rowData: Dr1TableDTO) => this.approve(rowData.committeeId || '') },
      { text: 'Reject', onClick: (rowData: Dr1TableDTO) => this.confirmReject(rowData.committeeId || '', rowData.committeeName || '') }
    ];
    const columnApprove: DataTableColumnProps<Dr1TableDTO> = DataTableColumnUtil.Buttons('committeeId', dr1Buttons, 220);
    columns.push(columnApprove);

    return columns as DataTableColumnProps<Dr1TableDTO>[];
  }

  private view = async (rowData: Dr1TableDTO) => {
    CurrentUser.Release();
    CurrentUser.Impersonate({
      committeeId: rowData.committeeId,
      committeeName: rowData.committeeName || null,
      committeeTypeName: rowData.committeeTypeName || null,
      committeeType: rowData.committeeTypeCode || null,
      filerTypeId: rowData.filerTypeId || null,
      committeeStatusType: rowData.committeeStatusType || null,
      committeeFilingCatType: rowData.committeeFilingCatType,
      auditing: true,
    });

    HistoryUtil.push(Routes.generate(Routes.ORGANIZATIONS_BASE));
  }

  private approve = (committeeId: string) => {
    HistoryUtil.push(Routes.generate(Routes.APPROVE_DR1_EDIT, {committeeId:committeeId}));
  }

  private reject = (committeeId: string) => {
    this.setState({ loadingDr1: true }, () => {
      AdminDr1ApiService.reject(committeeId)
        .then((result) => {
          if (result.succeeded) {
            if (this.dataTable) {
              this.dataTable.refresh();
            }
            notification.success({
              message: 'DR-1 was rejected successfully.'
            });
          }
          else {
            notification.error({ message: result.errors[0] });
          }
          this.setState({ loadingDr1: false });
        }).catch(() => {
          notification.error({
            message: 'Failed to reject filing.'
          });
        });
    });
  }

  private confirmReject = (committeeId: string, committeeName: string) => {
    confirm({
      title: 'Are you sure you want to reject this DR1?',
      content: 'The following record will be rejected: ' + committeeName,
      onOk: () => {
        this.reject(committeeId);
      },
    });
  }

  private getInitialValues () {
    LookupsApiService.getCommitteeTypes()
      .then((committeeTypes) => {
        this.setState({ committeeTypes });
      })
      .catch((error: any) => {
        notification.error({
          message: error.message,
          description: error.description,
        });
      });
  }
}

function mapDispatchToProps(dispatch: any) {
  return {
    userSessionChangedAction: (model: UserSecurity) => dispatch(UserSessionActions.update(model)),
  };
}

function mapStateToProps() {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageDr1);

