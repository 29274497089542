// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Constants.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
export default class CommitteeCategoryTypeNames {
    public static readonly COUNTYPARTY: string = 'County Party';
    public static readonly SCHOOLBOARD: string = 'School Board';
    public static readonly CITYCANDIDATE: string = 'City Candidate';
    public static readonly COUNTYCANDIDATE: string = 'County Candidate';
    public static readonly LOCALBALLOT: string = 'Local Ballot';
    public static readonly COUNTYPAC: string = 'County PAC';
    public static readonly CITYPAC: string = 'City PAC';
    public static readonly OTHERPAC: string = 'Other PAC';
    public static readonly OTHERCANDIDATE: string = 'Other Candidate';
}
