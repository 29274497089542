// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Constants.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
export default class CommFilingPeriodCatType {
    public static readonly SWGA: string = 'Statewide and General Assembly';
    public static readonly SCPA: string = 'Statewide or County PAC';
    public static readonly CENT: string = 'State and County Cent. Comm.';
    public static readonly CTY: string = 'County Candidates';
    public static readonly LOCL: string = 'Local Cand';
    public static readonly NA: string = 'N/A';
    public static readonly BALLOT: string = 'Local Ballot Issue';
}
