import { Typography } from 'antd';
import * as React from 'react';

const { Paragraph, Title } = Typography;

interface IncomeSourcesProps {
}

interface IncomeSourcesState {
}

class IncomeSources extends React.Component<IncomeSourcesProps, IncomeSourcesState> {

  constructor(props: IncomeSourcesProps) {
    super(props);

    this.state = {
    };
  }

  render() {
    return (
      <>
        <Title level={4}>
          Income Sources
        </Title>
        <Paragraph>
          You will be asked to disclose by category every source of income not already
          disclosed in Part 1-4 that produced more than $1,000 in gross income in the covered year.
        </Paragraph>
        <Paragraph>
          You may enter multiple sources under each category if necessary.
        </Paragraph>
        <Paragraph>
          You are not required to disclose the amount of income, just the type of income and source.
        </Paragraph>
        <Paragraph>
          You are not required to disclose income received solely by your spouse or other family members.
        </Paragraph>
        <Paragraph>
          You shall disclose any source of income in excess of $1,000 if the gross income is subject to
          federal or state income tax during the covered year. If you had no income in excess of $1,000
          in a source, please check the box provided for that source of income.
        </Paragraph>
      </>
    );
  }
}

export default IncomeSources;