import {
  Button,
  Checkbox,
  Col,
  ColProps,
  FormInstance,
  Input,
  notification,
  Modal,
  Row,
  Select,
  Space,
  Typography,
} from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import TextArea from 'antd/lib/input/TextArea';
import AddressForm from '../shared/AddressFormFields';
import * as React from 'react';
import VsrContributionApiService from '../../api/VsrContributionApiService';
import ScheduleApiService from '../../api/ScheduleApiService';
import VsrContributionsFormDTO from '../../models/VsrContributionsFormDTO';
import CommitteeInfoDTO from '../../models/CommitteeInfoDTO';
import FormValidationUtil from '../../utils/FormValidationUtil';
import Dr2GroupTypes from '../../consts/Dr2GroupTypes';
import CurrentUser from '../../utils/CurrentUser';
import HistoryUtil from '../../utils/HistoryUtil';
import Routes from '../../consts/Routes';
import { RouteComponentProps } from 'react-router';
import { Content } from 'antd/lib/layout/layout';
import moment from 'moment';
import PersonSearch from '../shared/PersonSearch';
import BaseScheduleInfoDTO from '../../models/BaseScheduleInfoDTO';
import CommitteeAutoSearch from '../shared/CommitteeSearch';
import VsrContributionHistoryTable from './VsrContributionHistoryTable';
import Role from '../../consts/Role';
import CustomDatePicker from '../shared/CustomDatePicker';
import CustomForm from '../shared/CustomForm';
import CurrencyInput from '../shared/CurrencyInput';
import OrganizationAutoSearch from '../shared/OrganizationSearch';
import Dr2ApiService from '../../api/Dr2ApiService';

const Option = Select.Option;
const confirm = Modal.confirm;

interface ContributionFormProps {
  id?: string;
  seqNum?: string;
}

interface ContributionFormState {
  candidate: BaseScheduleInfoDTO;
  currContrType: string;
  currComm: CommitteeInfoDTO;
  btnDisable: boolean;
  contributionInfo: VsrContributionsFormDTO & { person?: string };
  contributionId?: string;
  loading: boolean;
  isAdmin: boolean;
  trusteeTrustorReq: boolean;
  inKindChecked: boolean;
  autoFillPerorg: boolean;
}

class ContributionForm extends React.Component<RouteComponentProps<ContributionFormProps>, ContributionFormState> {
  private readonly _formRef = React.createRef<FormInstance>();

  constructor(props: RouteComponentProps<ContributionFormProps>) {
    super(props);
    this.state = {
      candidate: BaseScheduleInfoDTO.create(),
      currContrType: '',
      currComm: CommitteeInfoDTO.create(),
      btnDisable: false,
      contributionId: this.props.match.params.id,
      contributionInfo: VsrContributionsFormDTO.create({
        payeeType: '',
        state: 'IA',
        relationshipCode: '',
        amount: null
      }),
      loading: !!this.props.match.params.id,
      isAdmin: CurrentUser.Get()?.isInRole(Role.IECDB_ADMINISTRATOR) || false,
      trusteeTrustorReq: false,
      inKindChecked: false,
      autoFillPerorg: this.props.match.params.id != null,
    };
  }

  componentDidMount() {
    this.getCandidate();
    this.getUserCommittee();
    if (this.props.match.params.id) {
      this.getContribution(this.props.match.params.id || '', this.props.match.params.seqNum || '');
    }
  }

  render() {
    const { currContrType, currComm, btnDisable, contributionId, contributionInfo,
      loading, autoFillPerorg, trusteeTrustorReq } = this.state;

    const columnSizingProps: ColProps = { xs: 24, sm: 24, md: 12 };
    const checkNumReq = currContrType === Dr2GroupTypes.COMMITTEE && this.state.inKindChecked === false;

    const contributorTypes = [
      Dr2GroupTypes.INDIVIDUAL,
      Dr2GroupTypes.COMMITTEE,
      Dr2GroupTypes.TRUST,
      Dr2GroupTypes.COMPANYOTHER,
    ];

    return (
      <>
        {!loading &&
          <Content className="content-pad">
            <Typography.Title level={4}>VSR - Expenditures</Typography.Title>
            <CustomForm formRef={this._formRef} initialValues={contributionInfo} layout="vertical" onFinish={this.handleSave}>
              <Row gutter={24}>
                <Col {...columnSizingProps}>
                  <FormItem name="payeeType" label="Payee Type"
                    rules={[FormValidationUtil.Required('Payee Type is required')]} >
                    <Select
                      showSearch
                      optionFilterProp="children"
                      onChange={this.handleContributorTypeChange}>
                      <Option value="" disabled={true}>-- Select Type --</Option>
                      {contributorTypes.map(ct => (
                        <Option key={ct} value={ct}>{ct}</Option>
                      ))}
                    </Select>
                  </FormItem>
                  <FormItem name="date" label="Date"
                    rules={[FormValidationUtil.RequiredDate('Date is required')]}>
                    <CustomDatePicker onChange={(d) => d?.startOf('day')} />
                  </FormItem>
                  <FormItem name="amount" label="Amount"
                    rules={[FormValidationUtil.RequiredNumber('Amount is required')]}>
                    <CurrencyInput />
                  </FormItem>
                  <FormItem name="checkNumber" label="Check Number"
                  rules={[FormValidationUtil.Number('Number is invalid'),
                  FormValidationUtil.Required('Check Number is required', checkNumReq)]}>
                    <Input maxLength={12} />
                  </FormItem>
                  <Row>
                    <Col >
                      <Space>
                        <FormItem name="inKindInd" valuePropName="checked">
                        <Checkbox onChange={this.handleInKindCheck}> In-Kind </Checkbox>
                        </FormItem>
                      </Space>
                    </Col>
                  </Row>
                  <FormItem name="explanation" label="Explanation" >
                    <TextArea maxLength={2000} />
                  </FormItem>
                  <FormItem name="postmarkDate" label="Postmark Date" hidden={!this.state.isAdmin}>
                    <CustomDatePicker onChange={(d) => d?.startOf('day')} />
                  </FormItem>
                  <FormItem name="filedDate" label="Filed Date" hidden={!this.state.isAdmin}>
                    <CustomDatePicker onChange={(d) => d?.startOf('day')} />
                  </FormItem>
                  <FormItem name="amendDate" label="Amend Date" hidden={!this.state.isAdmin}>
                    <CustomDatePicker onChange={(d) => d?.startOf('day')} />
                  </FormItem>
                </Col>
                <Col {...columnSizingProps}>
                  {currContrType === Dr2GroupTypes.INDIVIDUAL && (
                    <>
                      <PersonSearch
                        name={'person'}
                        label={'Search for an Individual'}
                        required={false}
                        committeeId={currComm.id || ''}
                        onSelect={this.getPerson}
                        onChange={this.handleContributorChange}
                      />
                      {
                        <AddressForm allDisabled={autoFillPerorg}
                          firstName={{ name: 'firstName' }}
                          middleInitial={{ name: 'middleInitial' }}
                          lastName={{ name: 'lastName' }}
                          address1={{ name: 'addressLine1' }}
                          address2={{ name: 'addressLine2' }}
                          city={{ name: 'city' }}
                          state={{ name: 'state' }}
                          zip={{ name: 'zip' }}
                          phone={{ name: 'phoneNumber', required: false }}
                        />
                      }
                    </>
                  )}
                  {currContrType === Dr2GroupTypes.COMMITTEE && (
                    <CommitteeAutoSearch
                      name="payee"
                      label="Payee"
                      required={true}
                      requireSelection={true}
                      onSelectCommittee={this.handleCommitteeSelect}
                    />
                  )}
                  {(currContrType === Dr2GroupTypes.COMPANYOTHER || currContrType === Dr2GroupTypes.TRUST) && (
                    <>
                      <OrganizationAutoSearch
                        name={'company'}
                        label={'Search for a Company'}
                        required={false}
                        committeeId={currComm.id || ''}
                        perorgType={currContrType}
                        onSelect={this.getOrganization}
                        onChange={this.handleContributorChange}
                      />
                      <AddressForm allDisabled={autoFillPerorg}
                        name={{ name: 'payee', label: 'Company' }}
                        address1={{ name: 'addressLine1' }}
                        address2={{ name: 'addressLine2' }}
                        city={{ name: 'city' }}
                        state={{ name: 'state' }}
                        zip={{ name: 'zip' }}
                        phone={{ name: 'phoneNumber', required: false }}
                      />
                    </>
                  )}
                  {currContrType === Dr2GroupTypes.TRUST &&
                    <>
                      <FormItem name="isLivingOrRevocableTrust" valuePropName="checked">
                        <Checkbox onChange={this.handleRevocableTrustCheckboxChange}>Living or Revocable Trust</Checkbox>
                      </FormItem>
                      <FormItem name="trustee" label="Trustee"
                        rules={[FormValidationUtil.Required('Trustee is required', trusteeTrustorReq)]}>
                        <Input disabled={autoFillPerorg} />
                      </FormItem>
                      <FormItem name="trustor" label="Trustor"
                        rules={[FormValidationUtil.Required('Trustor is required', trusteeTrustorReq)]}>
                        <Input disabled={autoFillPerorg} />
                      </FormItem>
                    </>
                  }
                </Col>
              </Row>
              <Row>
                <Col {...columnSizingProps}>
                  <FormItem name="contributorAutoFillId" hidden={true}>
                    <Input hidden={true} disabled={true} />
                  </FormItem>
                </Col>
              </Row>
              <Space>
                <Button type="primary" disabled={btnDisable} htmlType="submit" > Save Contribution </Button>
                <Button type="default" disabled={btnDisable} onClick={this.handleCancel}> Cancel </Button>
              </Space>
            </CustomForm >
            {contributionId &&
              <VsrContributionHistoryTable
                vsrId={contributionId || ''}
              />
            }
          </Content>
        }
      </>
    );
  }

  private handleContributorTypeChange = (payeeType: string) => {
    this.setState({
      currContrType: payeeType,
      autoFillPerorg: false,
      trusteeTrustorReq: payeeType == Dr2GroupTypes.TRUST
    });

    this._formRef.current?.setFieldsValue({
      'firstName': '',
      'middleInitial': '',
      'lastName': '',
      'relationshipCode': '',
      'addressLine1': '',
      'addressLine2': '',
      'city': '',
      'state': '',
      'zip': '',
      'phoneNumber': '',
      'trustee': '',
      'trustor': '',
      'person': null,
      'payee': null,
      'company': null,
      'isLivingOrRevocableTrust': false,
      'contributorAutoFillId': ''
    });
  }

  private handleContributorChange = (value: string) => {
    this._formRef.current?.setFieldsValue({ contributorAutoFillId: '' });
    this.setState({ autoFillPerorg: !!value });
  }

  private handleCommitteeSelect = (committee: CommitteeInfoDTO | undefined) => {
    this._formRef.current?.setFieldsValue({ contributorAutoFillId: committee?.id });
  }

  private setCompanyFields(form?: BaseScheduleInfoDTO) {
    if (form) {
      this._formRef.current?.setFieldsValue({
        payee: form.committeeOrCompanyName,
        addressLine1: form.addressLine1,
        addressLine2: form.addressLine2,
        city: form.city,
        state: form.state,
        zip: form.zip,
        phoneNumber: form.phoneNumber,
        trustee: form.trustee,
        trustor: form.trustor
      });
    }
    else {
      this._formRef.current?.setFieldsValue({
        payee: '',
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        zip: '',
        phoneNumber: '',
        trustee: '',
        trustor: '',
        contributorAutoFillId: ''
      });
      this.setState({ autoFillPerorg: false });
    }
  }

  private setIndividualFields(form?: BaseScheduleInfoDTO) {
    if (form) {
      this._formRef.current?.setFieldsValue({
        payeeType: form.groupType,
        firstName: form.firstName,
        middleInitial: form.middleInitial,
        lastName: form.lastName,
        addressLine1: form.addressLine1,
        addressLine2: form.addressLine2,
        city: form.city,
        state: form.state,
        zip: form.zip,
        phoneNumber: form.phoneNumber,
        relationshipCode: form.relationshipCode,
        contributorAutoFillId: ''
      });
    }
    else {
      this._formRef.current?.setFieldsValue({
        payeeType: Dr2GroupTypes.INDIVIDUAL,
        firstName: '',
        middleInitial: '',
        lastName: '',
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        zip: '',
        phoneNumber: '',
        relationshipCode: '',
        contributorAutoFillId: ''
      });
      this.setState({ autoFillPerorg: false });
    }
    this.setState({ currContrType: Dr2GroupTypes.INDIVIDUAL });
  }

  private handleRevocableTrustCheckboxChange = (e: any) => {
    this.setState({ trusteeTrustorReq: !e.target.checked });
  }

  private handleInKindCheck = (e: any) => {
    this.setState({ inKindChecked: e.target.checked });
  }

  private getCandidate = () => {
    ScheduleApiService.getCandidate(CurrentUser.Get()?.committeeId || '')
      .then(personInfo => {
        if (personInfo.groupType == null) {
          return;
        }

        this.setState({ candidate: personInfo });
      });
  }

  private getPerson = (perorgId: string) => {
    if (perorgId != '') {
      ScheduleApiService.getPerson(perorgId, CurrentUser.Get()?.committeeId || '')
        .then(personInfo => {
          if (personInfo) {
            this.setIndividualFields(personInfo);
            this._formRef.current?.setFieldsValue({ contributorAutoFillId: perorgId });
          }
        });
    }
    else {
      this.setIndividualFields();
    }
  }

  private getOrganization = (perorgId: string) => {
    if (perorgId != '') {
      ScheduleApiService.getOrganization(perorgId, CurrentUser.Get()?.committeeId || '')
        .then(orgInfo => {
          if (orgInfo) {
            this.setCompanyFields(orgInfo);
            this._formRef.current?.setFieldsValue({ contributorAutoFillId: perorgId });
          }
        });
    }
    else {
      this.setCompanyFields();
    }
  }

  private getUserCommittee = () => {
    ScheduleApiService.getCommittee(CurrentUser.Get()?.committeeId || '')
      .then(committee => {
        if (committee) {
          this.setState({ currComm: { ...committee } });
        }
      });
  }

  private getContribution = (vsrId: string, vsrSeqNum: string) => {
    VsrContributionApiService.getContribution(vsrId, Number(vsrSeqNum))
      .then(contribution => {
        if (contribution) {
          this.setState({ inKindChecked: contribution.inKindInd });
          if (contribution.date) {
            contribution.date = moment(contribution.date);
          }
          if (contribution.amendDate) {
            contribution.amendDate = moment(contribution.amendDate);
          }
          if (contribution.postmarkDate) {
            contribution.postmarkDate = moment(contribution.postmarkDate);
          }
          if (contribution.filedDate) {
            contribution.filedDate = moment(contribution.filedDate);
          }

          const contributionInfo: VsrContributionsFormDTO & { person?: string, company?: string } = contribution;
          if (contribution.firstName && contribution.lastName) {
            if (contribution.middleInitial) {
              contributionInfo.person = contribution.firstName + ' ' + contribution.middleInitial + '. ' + contribution.lastName;
            }
            else {
              contributionInfo.person = contribution.firstName + ' ' + contribution.lastName;
            }
          }
          else if (contributionInfo.payeeType == Dr2GroupTypes.TRUST || contributionInfo.payeeType == Dr2GroupTypes.COMPANYOTHER) {
            if (contributionInfo.payee) {
              contributionInfo.company = contributionInfo.payee;
            }
          }

          this.setState({
            contributionInfo,
            currContrType: contribution.payeeType || '',
            trusteeTrustorReq: contribution.payeeType == Dr2GroupTypes.TRUST && !contribution.isLivingOrRevocableTrust,
            autoFillPerorg: contribution.payeeType != Dr2GroupTypes.COMMITTEE
          });
          this._formRef.current?.setFieldsValue(contributionInfo);
          this.setState({ loading: false });
        }
        else {
          notification.error({
            message: 'Error while fetching contribution',
            description: 'Not found'
          });
          this.setState({ loading: false });
        }
      }).catch(() => {
        notification.error({
          message: 'Error while fetching contribution',
          description: ''
        });
        this.setState({ loading: false });
      });
  }

  private handleSave = (values: VsrContributionsFormDTO) => {
    if (values.contributorAutoFillId === '') {
      values.contributorAutoFillId = null;
    }
    this.setState({ btnDisable: true });
    if (!this.state.contributionId) {
      VsrContributionApiService.addVsr(values, CurrentUser.Get()?.committeeId || '')
        .then(() => {
          notification.success({
            message: 'Saved Successfully',
            description: ''
          });
          HistoryUtil.push(Routes.VSR_CONTRIBUTIONS);
        }).catch(error => {
          notification.error({
            message: 'Your contribution failed to save',
            description: error.message
          });
          HistoryUtil.push(Routes.VSR_CONTRIBUTIONS);
        });
    }
    else {
      VsrContributionApiService.editVsr(
        values,
        CurrentUser.Get()?.committeeId || '',
        this.props.match.params.id || '',
        Number(this.props.match.params.seqNum)
      )
         .then(() => {
             Dr2ApiService.checkForUnpaidPenalties(CurrentUser.Get()?.committeeId ?? '', CurrentUser.Get()?.committeeType ?? '')
                .then((hasPenalties) => {
                  CurrentUser.UpdateHasPenaltie(hasPenalties);
              });
          notification.success({
            message: 'Saved Successfully',
            description: ''
          });
          HistoryUtil.push(Routes.VSR_CONTRIBUTIONS);
        }).catch(() => {
          notification.error({
            message: 'Your contribution failed to save',
            description: ''
          });
          HistoryUtil.push(Routes.VSR_CONTRIBUTIONS);
        });
    }
  }

  private handleCancel = () => {
    confirm({
      title: 'Are you sure you want to leave?',
      okText: 'Yes',
      cancelText: 'No',
      onOk: () => {
        HistoryUtil.push(Routes.VSR_CONTRIBUTIONS);
      }
    });
  }
}

export default ContributionForm;