// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
import BaseApi from './BaseApi';
import HelpPageDTO from '../models/HelpPageDTO';

export class AdminHelpPageApiService extends BaseApi {

  // put: api/admin/help
  public edit(helpPageEntry: HelpPageDTO): Promise<void> {
    let url = `api/admin/help`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, void>(helpPageEntry, 'put', url, true, false);
  }

  // delete: api/admin/help?route=${encodeURIComponent(route)}
  public delete(route: string): Promise<void> {
    let url = `api/admin/help`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (route != null) {
      url += `${prefix}route=${encodeURIComponent(route)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, void>(null, 'delete', url, true, false);
  }

  // get: api/admin/help/table
  public getHelpPageList(): Promise<HelpPageDTO[]> {
    let url = `api/admin/help/table`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, HelpPageDTO[]>(null, 'get', url, true, false);
  }
}
var service = new AdminHelpPageApiService();
export default service;
