// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
import BaseApi from './BaseApi';
import DR1DTO from '../models/DR1DTO';
import SecondaryCommitteeInfoDTO from '../models/SecondaryCommitteeInfoDTO';
import ResultDTO from '../models/ResultDTO';

export class Dr1ApiService extends BaseApi {

  // post: api/dr1/checkCommitteeName?type=${encodeURIComponent(type)}&county=${encodeURIComponent(county)}&committeeId=${encodeURIComponent(committeeId)}
  public checkCommitteeNameExists(name: string, type: string, county: string, committeeId: string): Promise<boolean> {
    let url = `api/dr1/checkCommitteeName`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (type != null) {
      url += `${prefix}type=${encodeURIComponent(type)}`;
      prefix = '&';
    }
    if (county != null) {
      url += `${prefix}county=${encodeURIComponent(county)}`;
      prefix = '&';
    }
    if (committeeId != null) {
      url += `${prefix}committeeId=${encodeURIComponent(committeeId)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, boolean>(name, 'post', url, true, false);
  }

  // get: api/dr1/fec
  public getFECInfo(): Promise<SecondaryCommitteeInfoDTO> {
    let url = `api/dr1/fec`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, SecondaryCommitteeInfoDTO>(null, 'get', url, true, false);
  }

  // post: api/dr1/add
  public add(newDr1Entry: DR1DTO): Promise<ResultDTO> {
    let url = `api/dr1/add`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, ResultDTO>(newDr1Entry, 'post', url, true, false);
  }
}
var service = new Dr1ApiService();
export default service;
