// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Constants.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
export default class DR1FormTypes {
    public static readonly CANDIDATE: string = 'Candidate Information';
    public static readonly PARENT: string = 'Parent Information';
    public static readonly CHAIRHOLDER: string = 'Chairholder Information';
    public static readonly TREASURER: string = 'Treasurer Information';
    public static readonly RESIDENTAGENT: string = 'Iowa Resident Agent Information';
    public static readonly CONTACT: string = 'Contact Information';
    public static readonly JURISDICTION: string = 'Jurisdiction Information';
}
