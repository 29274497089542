import { Radio, FormProps, FormInstance, RadioChangeEvent } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import * as React from 'react';
import FilerType from '../../../../consts/FilerType';
import FilerTypeDTO from '../../../../models/FilerTypeDTO';
import CustomForm from '../../../shared/CustomForm';

interface StartDR1Props {
  committeeTypeChange: (e: RadioChangeEvent) => void,
}

interface StartDR1State {
  initialValues: FilerTypeDTO;
}

class StartDR1 extends React.Component<StartDR1Props & FormProps, StartDR1State> {
  private readonly _formRef = React.createRef<FormInstance>();

  constructor(props: StartDR1Props & FormProps) {
    super(props);

    this.state = {
      initialValues: this.props.initialValues as FilerTypeDTO,
    };
  }
  
  validate = async (): Promise<{ model: FilerTypeDTO, errorFields: any }> => {
    try {
      const fields = await this._formRef.current?.validateFields();
      return { model: fields, errorFields: null };
    }
    catch(errorInfo) {
      return Promise.reject({ model: errorInfo.values, errorFields: errorInfo.errorFields });
    }
  }
  
  render() {
    const { initialValues } = this.state;
    return (
      <CustomForm formRef={this._formRef} initialValues={initialValues} className="form-flex label-160">
        <FormItem name="id">
          <Radio.Group onChange={this.props.committeeTypeChange} >
            <Radio value={FilerType.CANDIDATE}>Candidate Committee</Radio>
            <br />
            <Radio value={FilerType.PAC}>PAC / Political Committee</Radio>
            <br />
          </Radio.Group>
        </FormItem>
      </CustomForm>
    );
  }

}

export default StartDR1;