// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
import BaseApi from './BaseApi';
import TableRequestDTO from '../models/TableRequestDTO';
import DrsfaDTO from '../models/DrsfaDTO';
import TableResponseDTO from '../models/TableResponseDTO';
import AdminDrsfaListDTO from '../models/AdminDrsfaListDTO';

export class AdminDrsfaApiService extends BaseApi {

  // post: api/admin/drsfa/table?isManageTable=${isManageTable}
  public getDrsfaList(tableRequest: TableRequestDTO, isManageTable: boolean): Promise<TableResponseDTO<AdminDrsfaListDTO>> {
    let url = `api/admin/drsfa/table`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (isManageTable != null) {
      url += `${prefix}isManageTable=${isManageTable}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, TableResponseDTO<AdminDrsfaListDTO>>(tableRequest, 'post', url, true, false);
  }

  // get: api/admin/drsfa/${encodeURIComponent(drsfaId)}
  public get(drsfaId: string): Promise<DrsfaDTO> {
    let url = `api/admin/drsfa/${encodeURIComponent(drsfaId)}`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, DrsfaDTO>(null, 'get', url, true, false);
  }

  // put: api/admin/drsfa?filingStatus=${encodeURIComponent(filingStatus)}
  public edit(editDrsfa: DrsfaDTO, filingStatus: string): Promise<void> {
    let url = `api/admin/drsfa`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (filingStatus != null) {
      url += `${prefix}filingStatus=${encodeURIComponent(filingStatus)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, void>(editDrsfa, 'put', url, true, false);
  }

  // delete: api/admin/drsfa/delete?drsfaID=${encodeURIComponent(drsfaID)}
  public delete(drsfaID: string): Promise<void> {
    let url = `api/admin/drsfa/delete`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (drsfaID != null) {
      url += `${prefix}drsfaID=${encodeURIComponent(drsfaID)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, void>(null, 'delete', url, true, false);
  }

  // post: api/admin/drsfa/approve?drsfaID=${encodeURIComponent(drsfaID)}
  public approve(drsfaID: string): Promise<void> {
    let url = `api/admin/drsfa/approve`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (drsfaID != null) {
      url += `${prefix}drsfaID=${encodeURIComponent(drsfaID)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, void>(null, 'post', url, true, false);
  }

  // post: api/admin/drsfa/reject?drsfaID=${encodeURIComponent(drsfaID)}
  public reject(drsfaID: string): Promise<void> {
    let url = `api/admin/drsfa/reject`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (drsfaID != null) {
      url += `${prefix}drsfaID=${encodeURIComponent(drsfaID)}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, void>(null, 'post', url, true, false);
  }
}
var service = new AdminDrsfaApiService();
export default service;
