import CodeLookupTableDTO from '../models/CodeLookupTableDTO';
import LookupTableDTO from '../models/LookupTableDTO';
import BaseApi from './BaseApi';
import LookupsApiBaseService from './LookupsApiBaseService';
/* eslint-disable */

export class LookupsApiBase extends BaseApi {
  protected fromLocal(cache: LookupTableDTO[], name: string, include?: number | null): Promise<LookupTableDTO[]> {
    let promise = Promise.resolve<LookupTableDTO[]>([]);
    if (include != null && !cache.find((a) => a.id === include)) {
      promise = LookupsApiBaseService.getOne(name, include).then((r) => {
        if (!cache.find((a) => a.id === include)) {
          cache.push(r);
        }
        return cache;
      });
    } else {
      promise = Promise.resolve<LookupTableDTO[]>(cache);
    }
    return promise.then((r) => this.filterAndSort(r, include));
  }
  protected codeLookupFromLocal(cache: CodeLookupTableDTO[], name: string, include?: string | null): Promise<CodeLookupTableDTO[]> {
    return Promise.resolve<CodeLookupTableDTO[]>(cache).then((r) => this.codeFilterAndSort(r, include) as CodeLookupTableDTO[]);
  }
  protected filterAndSort(r: LookupTableDTO[], include?: number | null): LookupTableDTO[] {
    return r
      .filter((f) => f.isActive || f.id === include)
      .sort((a, b) => {
        let s = a.displayOrder - b.displayOrder;
        if (s === 0) {
          s = a.name?.localeCompare(b.name ?? '') ?? 0;
        }
        return s;
      });
  }
  protected codeFilterAndSort(r: (CodeLookupTableDTO | { name: string })[], include?: string | null): (CodeLookupTableDTO | { name: string })[] {
    return r
      .filter((f) => !include || 'code' in f && f.code === include)
      .sort((a, b) => a.name?.localeCompare(b.name ?? '') ?? 0);
  }
  protected genericFromLocal(cache: any[], name: string): Promise<any[]> {
    return Promise.resolve<any[]>(cache).then((r) => this.codeFilterAndSort(r) as any[]);
  }
}
export default LookupsApiBase;
