import * as React from 'react';
import DataTable, { DataTableColumnProps } from '../../shared/DataTable/DataTable';
import DataTableColumnUtil from '../../shared/DataTable/DataTableColumnUtil';
import AdminReportApiService from '../../../api/AdminReportApiService';
import DataTableButtonUtil from '../../shared/DataTable/DataTableButtonUtil';
import TableRequestDTO from '../../../models/TableRequestDTO';
import GiftBequestReportDTO from '../../../models/GiftBequestReportDTO';

interface GiftBequestReportProps {
}

interface GiftBequestReportState {
  tableColumns: DataTableColumnProps<GiftBequestReportDTO>[];
  data: GiftBequestReportDTO[];
  loading: boolean;
  exporting: boolean;
  
}

class GiftBequestReport extends React.Component<GiftBequestReportProps, GiftBequestReportState> {
  constructor(props: GiftBequestReportProps) {
    super(props);

    this.state = {
      tableColumns: this.getTableColumns(),
      data: [],
      loading: false,
      exporting: false,
    };
  }
  private dataTable: DataTable<GiftBequestReportDTO> | undefined;

  render() {
    const { exporting, tableColumns, loading } = this.state;
    const dataTableButtons = [];
    dataTableButtons.push(DataTableButtonUtil.Reset());
    dataTableButtons.push(DataTableButtonUtil.Export((tableRequest: TableRequestDTO) => {
      this.setState({ exporting: true });

      return AdminReportApiService.exportGiftBequestReportList(tableRequest)
        .finally(() => {
          this.setState({ exporting: false });
        });
    }, exporting));

    return (
        <DataTable
        ref={(element: any) => (this.dataTable = element)}
          globalSearch={true}
          columns={tableColumns}
          buttonBar={dataTableButtons}
          serverSide={true}
          tableProps={{
            rowKey: 'nameCode',
            sortDirections: ['ascend', 'descend'],
            locale: {
              emptyText: 'No records were found that match your criteria'
            },
            loading: loading
          }}
          fetchData={{
            fetch: function (tableRequest) { return AdminReportApiService.getGiftBequestReportList(tableRequest); },
            failureMessage: 'Failed to retrieve Gift Reports'
          }}
          styleOptions={{ compact: true, alternatingRowHighlight: true }}
          title={'Gift Bequest Report'}
          stateSaving={{
            enabled: true,
            tableUniqueKey: 'dr2FilingReports',
            perSession: true,
          }}
        />
    );
  }

  private getTableColumns = (): DataTableColumnProps<GiftBequestReportDTO>[] => {
    return [
      DataTableColumnUtil.Text('Agency', 'department'),
      DataTableColumnUtil.Date('Gift Date', 'giftDate'),
      DataTableColumnUtil.Text('Contact Name', 'donorName'),
      DataTableColumnUtil.Text('Email', 'email')
    ];
  }
}

export default GiftBequestReport;
