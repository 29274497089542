import * as React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import UnauthorizedComponent from '../../components/Unauthorized';
import Routes from '../../consts/Routes';
import UserSecurity from '../../models/UserSecurity';
import { UserSessionActions } from '../../redux/actions/UserSessionActions';
import { StateStoreModel } from '../../redux/state/StateStoreModel';
import history from '../../utils/HistoryUtil';

interface AuthorizationProps {
  user: UserSecurity | null;
  checkAction: () => void;
}

const WithAuthorization = (allowedRoles?: number[] | null, allowedUsers?: string[] | null) => {
  return (InnerComponent: React.ComponentClass | React.StatelessComponent) => {
    class Authorization extends React.Component<AuthorizationProps> {
      constructor(props: AuthorizationProps) {
        super(props);
        if (!InnerComponent) {
          throw new Error('componentWrapper function was passed "null" for the "InnerComponent" argument.');
        }
      }

      componentDidMount() {
        this.props.checkAction();
      }

      render() {
        let authorized = false;
        const rolesSet = allowedRoles && allowedRoles.length > 0 ? true : false;
        const usersSet = allowedUsers && allowedUsers.length > 0 ? true : false;

        if (this.props.user) {
          if (!this.props.user) {
            authorized = false;
          } else {
            if (!rolesSet && !usersSet) {
              authorized = true;
            }
            if (!authorized && rolesSet) {
              for (let i = 0, len = allowedRoles?.length ?? 0; i < len; i++) {
                if (allowedRoles && this.props.user.isInRole(allowedRoles[i])) {
                  authorized = true;
                  break;
                }
              }
            }
            if (!authorized && usersSet) {
              for (let i = 0, len = allowedUsers?.length ?? 0; i < len; i++) {
                if (allowedUsers && this.props.user.userName === allowedUsers[i]) {
                  authorized = true;
                  break;
                }
              }
            }
          }
        } else {
          let url: string | undefined = history.location.pathname + history.location.search;
          url = url === '/' ? undefined : url;
          return <Redirect to={Routes.generate(Routes.LOGIN, {}, { returnUrl: url })} />;
        }

        if (authorized) {
          const props = { ...this.props };
          return <InnerComponent {...props} />;
        } else {
          return <UnauthorizedComponent />;
        }
      }
    }

    const mapStateToProps = (state: StateStoreModel) => {
      return {
        user: state.UserSession.Value,
      };
    };

    const mapDispatchToProps = (dispatch: any) => {
      return {
        checkAction: () => dispatch(UserSessionActions.check()),
      };
    };

    return connect(mapStateToProps, mapDispatchToProps)(Authorization);
  };
};

export default WithAuthorization;
