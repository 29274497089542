// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import EntitySelectorDTO from './EntitySelectorDTO';

interface CommitteeFilingDTO extends EntitySelectorDTO { 
  id: number;
  filingStatusTypeName: string | null;
  submittedOn: moment.Moment | string;
  filedDate: moment.Moment | string | null;
  amendedDate: moment.Moment | string | null;
  incumbentInd: boolean | null;
}
const CommitteeFilingDTO = {
  create: (initValues?: Partial<CommitteeFilingDTO> | Record<string, unknown> | null) => {
    return Object.assign(EntitySelectorDTO.create(),
    {
      id: 0,
      filingStatusTypeName: null,
      submittedOn: new Date(0).toISOString(),
      filedDate: null,
      amendedDate: null,
      incumbentInd: null,
    },
    initValues);
  }
};

export default CommitteeFilingDTO;