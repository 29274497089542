import {
  Button,
  Col,
  ColProps,
  Input,
  Layout,
  Modal,
  notification,
  Radio,
  Row,
  Typography,
  Space,
} from 'antd';
import { FormInstance } from 'antd/lib/form/Form';
import FormItem from 'antd/lib/form/FormItem';
import moment from 'moment';
import { RouteComponentProps } from 'react-router-dom';
import * as React from 'react';
import CampaignApiService from '../../../api/CampaignApiService';
import Routes from '../../../consts/Routes';
import FormValidationUtil from '../../../utils/FormValidationUtil';
import AdminIieApiService from '../../../api/AdminIieApiService';
import IieDTO from '../../../models/IieDTO';
import HistoryUtil from '../../../utils/HistoryUtil';
import IieAdvocates from '../../../consts/IieAdvocates';
import CommitteeAutoSearch from '../../shared/CommitteeSearch';
import AddressFormFields from '../../shared/AddressFormFields';
import FilingStatusType from '../../../consts/FilingStatusType';
import CommitteeInfoDTO from '../../../models/CommitteeInfoDTO';
import CurrencyInput from '../../shared/CurrencyInput';
import CustomDatePicker from '../../shared/CustomDatePicker';
import CustomForm from '../../shared/CustomForm';

const { Content } = Layout;
const { confirm } = Modal;

interface IieProps {
  id: string;
}

interface IieState {
  id: string,
  loading: boolean;
  submitLoading: boolean;
  trustInfo: boolean;
  funderInfo: boolean;
  indExpendIndiv: IieDTO;
  radioValue: string;
}

class IiePage extends React.Component<RouteComponentProps<IieProps>, IieState>{
  private readonly _formRef = React.createRef<FormInstance>();
  constructor(props: RouteComponentProps<IieProps>) {
    super(props);

    this.state = {
      id: props.match.params.id,
      loading: true,
      submitLoading: false,
      trustInfo: false,
      funderInfo: false,
      indExpendIndiv: IieDTO.create({ signedDate: moment().startOf('day'), individualState: 'IA' }),
      radioValue: ''
    };
  }

  componentDidMount() {
    this.getInitialValues();
  }

  onChangeRadioButtons = (e: any) => {
    this.setState({
      radioValue: e.target.value,
    }, () => {
      this._formRef.current?.setFieldsValue({
        forCandidate: '', againstCandidate: '',
        forBallot: '', againstBallot: '', committeeId: undefined
      });
    });
  };

  render() {
    const columnSizingProps: ColProps = { xs: 24, sm: 24, md: 12 };
    const { loading, submitLoading, indExpendIndiv, id } = this.state;
    const { radioValue } = this.state;

    return (
      <Content className="content-pad">
        <Typography.Title level={2}>Independent Expenditure</Typography.Title>
        <Typography.Paragraph>This form may not be used by a candidate, candidate’s committee, or a political committee.</Typography.Paragraph>
        {loading ? undefined :
          <CustomForm formRef={this._formRef}
            name="Iie" onFinish={submitLoading ? undefined : this.submitForm}
            initialValues={indExpendIndiv}
            layout="vertical"
            validateTrigger={['onChange', 'onBlur']}>
            <Row gutter={24}>
              <br /><br />
              <Col {...columnSizingProps}>
                <Typography.Title level={4}>Individual Making the Expenditure</Typography.Title>
                <AddressFormFields
                  firstName={{ name: 'individualFirstName' }}
                  middleInitial={{ name: 'individualMi' }}
                  lastName={{ name: 'individualLastName' }}
                  address1={{ name: 'individualAddress1' }}
                  address2={{ name: 'individualAddress2' }}
                  city={{ name: 'individualCity' }}
                  state={{ name: 'individualState' }}
                  zip={{ name: 'individualZip' }}
                  email={{ name: 'individualEmail' }}
                  phone={{ name: 'individualPhone' }} />
              </Col>
              <Col {...columnSizingProps}>
                <Typography.Title level={4}>Expenditure Details</Typography.Title>
                <FormItem
                  name="date"
                  label="Date of Expenditure"
                  rules={[FormValidationUtil.RequiredDate('Date of the expenditure is required')]}>
                  <CustomDatePicker onChange={(d) => d?.startOf('day')}/>
                </FormItem>
                <FormItem
                  name="amount"
                  label="Amount"
                  rules={[
                    FormValidationUtil.RequiredNumber('Amount of the expenditure is required'),
                    FormValidationUtil.PositiveNumber('Amount of the expenditure must be positive')
                  ]}
                  tooltip="Total amount of completed expenditure">
                  <CurrencyInput />
                </FormItem>
                <FormItem
                  name="typeOfCommunication"
                  label="Type of Communication"
                  rules={[
                    FormValidationUtil.Required('Type of Communication is required')]}
                  tooltip="(Newspaper ad, Radio, Television, Flier, etc)">
                  <Input type="text" />
                </FormItem>
                <FormItem name="vendor" label="Vendors"
                  rules={[FormValidationUtil.Required('Vendor is required')]}
                  tooltip="To enter more than one separate by comma.">
                  <Input type="text" />
                </FormItem>
                <FormItem
                  name="distDate"
                  label="Distribution Date"
                  rules={[FormValidationUtil.RequiredDate('Distribution Date is required')]}>
                  <CustomDatePicker onChange={(d) => d?.startOf('day')}/>
                </FormItem>
              </Col>
            </Row>
            <Row>
              <Col {...columnSizingProps}>
                <Typography.Title level={4}>This expenditure expressly advocates:</Typography.Title>
                <FormItem label="Select One" required={true} style={{ marginBottom: '0' }}>
                  <Radio.Group value={radioValue} onChange={this.onChangeRadioButtons} style={{ width: '100%' }}>
                    <Space direction="vertical" style={{ width: '100%' }}>

                      <Radio name="group" value={IieAdvocates.FORCANDIDATE}>{IieAdvocates.FORCANDIDATE}</Radio>
                      <CommitteeAutoSearch
                        hidden={radioValue !== IieAdvocates.FORCANDIDATE}
                        required={radioValue === IieAdvocates.FORCANDIDATE}
                        name="forCandidate"
                        noMargin={true}
                        onSelectCommittee={this.handleSelectCommittee} />

                      <Radio name="group" value={IieAdvocates.AGAINSTCANDIDATE}>{IieAdvocates.AGAINSTCANDIDATE}</Radio>
                      <CommitteeAutoSearch
                        hidden={radioValue !== IieAdvocates.AGAINSTCANDIDATE}
                        required={radioValue === IieAdvocates.AGAINSTCANDIDATE}
                        name="againstCandidate"
                        noMargin={true}
                        onSelectCommittee={this.handleSelectCommittee} />


                      <Radio name="group" value={IieAdvocates.FORBALLOT}>{IieAdvocates.FORBALLOT}</Radio>
                      <CommitteeAutoSearch
                        hidden={radioValue !== IieAdvocates.FORBALLOT}
                        required={radioValue === IieAdvocates.FORBALLOT}
                        name="forBallot"
                        noMargin={true}
                        onSelectCommittee={this.handleSelectCommittee} />


                      <Radio name="group" value={IieAdvocates.AGAINSTBALLOT}>{IieAdvocates.AGAINSTBALLOT}</Radio>
                      <CommitteeAutoSearch
                        hidden={radioValue !== IieAdvocates.AGAINSTBALLOT}
                        required={radioValue === IieAdvocates.AGAINSTBALLOT}
                        name="againstBallot"
                        noMargin={true}
                        onSelectCommittee={this.handleSelectCommittee} />

                      {radioValue === '' ?
                        <FormItem name="committeeName" className="hide-input" rules={[FormValidationUtil.Required('Select an option')]}>
                          <Input type="text" />
                        </FormItem> : null}

                    </Space>
                  </Radio.Group>
                </FormItem>
                <FormItem name="committeeId" hidden={true} aria-hidden={true}>
                </FormItem>
              </Col>
            </Row>

            {
              indExpendIndiv.filingStatusType !== FilingStatusType.CERTIFIED
                && indExpendIndiv.filingStatusType !== FilingStatusType.ADJUSTED ?
                <>
                  <Typography.Title level={4}>Review the Terms of Agreement</Typography.Title>
                  <Typography.Paragraph>Criteria to use this form:</Typography.Paragraph>

                  <ol>
                    <li>
                      <Typography.Text>
                        An independent expenditure in excess of $1000 in the aggregate
                        to expressly advocate the nomination, election, or defeat of a candidate
                        or the passage or defeat of a ballot issue
                      </Typography.Text>
                    </li>
                    <li>
                      <Typography.Text>
                        Independent expenditure complies with the attribution requirement of Iowa Code section 68A.405
                      </Typography.Text>
                    </li>
                  </ol>
                  <Typography.Text>
                    <Typography.Title level={4}>Statement of Certification</Typography.Title>
                    I affirm that the independent expenditure reported above is accurate.
                    I also affirm that this expenditure was made without the prior approval or
                    coordination with a candidate, candidate’s committee, or a ballot issue committee.
                    I affirm I was the only individual who paid for the costs of this expenditure.
                    I understand that by filing this form, I am subject to the campaign laws in Iowa Code chapter 68A
                    and rules in chapter 351 of the Iowa Administrative Code. I also understand that
                    the failure to timely file this form leads to the imposition of civil penalties
                    and the intentional failure to file this form may lead to additional civil and criminal sanctions.
                    <br /><br />
                  </Typography.Text>
                  <Row>
                    <Col {...columnSizingProps}>
                      <FormItem name="signedName" label="Signature" rules={[FormValidationUtil.Required('Signature is required')]}>
                        <Input type="text" />
                      </FormItem>
                      <FormItem
                        name="signedDate"
                        label="Date Signed">
                        <CustomDatePicker disabled={true} />
                      </FormItem>
                      {
                        id == null ?
                          <>
                            <Space>
                              <Button type="primary" htmlType="submit">I Agree</Button>
                              <Button onClick={this.cancelForm}>Cancel</Button>
                            </Space>

                          </> :
                          <>
                            <Space>
                              <Button type="primary" onClick={() =>
                                this.updateStatus(id || '', FilingStatusType.ADJUSTED)}>Adjust</Button>
                              <Button type="primary" onClick={() => this.updateStatus(id || '', FilingStatusType.CERTIFIED)}>Approve</Button>
                              <Button onClick={() => this.confirmReject(id || '', (indExpendIndiv.individualFirstName || '')
                                + ' ' + (indExpendIndiv.individualLastName || ''))}>Reject</Button>
                              <Button onClick={this.cancelEdit}>Cancel</Button>
                            </Space>

                          </>
                      }
                    </Col>
                  </Row>
                </> :
                <>
                  <br /><br />
                  <Col {...columnSizingProps} >
                    <Row gutter={24}>
                      <Col span={12}>
                        <FormItem name="signedName" label="Signature" rules={[FormValidationUtil.Required('Signature is required')]}>
                          <Input type="text" />
                        </FormItem>
                      </Col>
                      <Col span={12}>
                        <FormItem
                          name="signedDate"
                          label="Date Signed">
                          <CustomDatePicker disabled={true} />
                        </FormItem>
                      </Col>
                    </Row>
                  </Col>
                  <Space>
                    <Button type="primary" onClick={() => this.updateStatus(id || '', FilingStatusType.ADJUSTED)}>Adjust</Button>
                    <Button onClick={() => this.editPenalty(id)}>Penalty</Button>
                    <Button danger onClick={() => this.confirmDelete(id, (indExpendIndiv.individualFirstName || '')
                      + ' ' + (indExpendIndiv.individualLastName || ''))}>Delete</Button>
                    <Button onClick={this.cancelEdit}>Cancel</Button>
                  </Space>
                </>
            }
          </CustomForm>
        }
      </Content>
    );
  }

  private submitForm = (values: any) => {
    this.setState({ submitLoading: true });
    return CampaignApiService.addIie({ ...this.state.indExpendIndiv, ...values, })
      .then(() => {
        if (!this.state.id) {
          this.setState({ submitLoading: false });
          HistoryUtil.push(Routes.LOGIN);
          notification.success({
            message: 'Your report submitted successfully.',
            description: 'You will be contacted by the IECDB staff upon approval. Thank You.'
          });
        }
      })
      .catch(error => {
        notification.error({ message: error.message });
      });
  }

  private getInitialValues = () => {
    if (!this.state.id) {
      this.setState({ loading: false });
      return;
    }

    this.setState({ loading: true });

    AdminIieApiService.getIieExpend(this.state.id).then(iieExpend => {
      if (iieExpend.date) {
        iieExpend.date = moment(iieExpend.date);
      }

      if (iieExpend.distDate) {
        iieExpend.distDate = moment(iieExpend.distDate);
      }

      if (iieExpend.signedDate) {
        iieExpend.signedDate = moment.utc(iieExpend.signedDate);
      }
      let radioValue = '';

      if (iieExpend.forCandidate) {
        radioValue = IieAdvocates.FORCANDIDATE;
      }

      if (iieExpend.againstCandidate) {
        radioValue = IieAdvocates.AGAINSTCANDIDATE;
      }

      if (iieExpend.forBallot) {
        radioValue = IieAdvocates.FORBALLOT;
      }

      if (iieExpend.againstBallot) {
        radioValue = IieAdvocates.AGAINSTBALLOT;
      }

      this.setState({
        indExpendIndiv: iieExpend,
        loading: false,
        radioValue
      });

    }).catch(() => {
      this.setState({ loading: false });
    });
  }

  private handleSelectCommittee = (committee: CommitteeInfoDTO | undefined) => {
    this._formRef.current?.setFieldsValue({ committeeId: committee?.id });
  }

  private updateStatus = (expendId: string, status: string) => {
    this._formRef.current?.validateFields().then(() => {
      this.submitForm(this._formRef.current?.getFieldsValue()).then(() =>
        AdminIieApiService.updateStatus(expendId, status)
          .then(() => {
            HistoryUtil.push(Routes.MANAGE_IIE);
            notification.success({
              message: 'Your changes were saved sucessfully',
            });
          }).catch(() => {
            notification.error({
              message: 'Failed to update status.'
            });
          }));
    }).catch(reason => {
      this._formRef.current?.scrollToField(reason.errorFields[0].name);
      window.scrollBy(0, -150);
    });
  };

  private confirmReject = (id: string, individualName: string) => {
    confirm({
      title: 'Are you sure you want to reject this IIE?',
      content: 'The following record will be rejected: ' + individualName,
      onOk: () => {
        this.updateStatus(id, FilingStatusType.DELETED);
      },
    });
  }

  private editPenalty = async (id: string) => {
    HistoryUtil.push(Routes.generate(Routes.IIE_PENALTY, { id: id }));
  }

  private confirmDelete = (expendId: string, individualName: string) => {
    confirm({
      title: 'Are you sure you want to delete this IIE?',
      content: 'The following record will be permanently deleted: ' + individualName,
      onOk: () => {
        this.deleteIie(expendId);
      },
    });
  }

  private deleteIie = (expendId: string) => {
    AdminIieApiService.deleteIie(expendId)
      .then(() => {
        HistoryUtil.push(Routes.MANAGE_IIE);
        notification.success({ message: 'IIE was deleted successfully.' });
      }).catch(() => {
        notification.error({ message: 'Failed to delete IIE.' });
      });
  }

  private cancelForm = () => {
    confirm({
      title: 'Are you sure you want to leave?',
      okText: 'Yes',
      cancelText: 'No',
      onOk: () => {
        HistoryUtil.push(Routes.LOGIN);
      }
    });
  }

  private cancelEdit = () => {
    confirm({
      title: 'Are you sure you want to leave?',
      okText: 'Yes',
      cancelText: 'No',
      onOk: () => {
        HistoryUtil.push(Routes.MANAGE_IIE);
      }
    });
  }
}

export default IiePage;
