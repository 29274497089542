import { Descriptions, Divider, notification, Row, Space, Spin, Typography } from 'antd';
import moment from 'moment';
import * as React from 'react';
import Dr2ApiController from '../../api/Dr2ApiService';
import Dr2StatusBarDTO from '../../models/Dr2StatusBarDTO';
import DateUtil from '../../utils/DateUtil';
import Dr2StatusBarButtons from './Dr2StatusBarButtons';
import AdjustBalance from './AdjustBalance';
import FilingStatus from '../../consts/FilingStatus';
import Colors from '../../consts/Colors';
import { Content } from 'antd/lib/layout/layout';


const { Item } = Descriptions;

interface Dr2StatusBarProps {
  dr2Id: string;
  onAdjustBalance?: () => void;
}

interface Dr2StatusBarState {
  statusBarFields: Dr2StatusBarDTO;
  loading: boolean;
  showAdjust: boolean;
  extraSmallWindow: boolean;
}

class Dr2StatusBar extends React.Component<Dr2StatusBarProps, Dr2StatusBarState> {
  constructor(props: Dr2StatusBarProps) {
    super(props);

    this.state = {
      statusBarFields: Dr2StatusBarDTO.create(),
      loading: true,
      showAdjust: false,
      extraSmallWindow: this.isExtraSmallWindow()
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleWindowResize);
    this.getStatusBar(this.props.dr2Id);
  }

  componentWillUnmount() {
    window.onresize = null;
  }

  render() {
    const { statusBarFields, loading, showAdjust, extraSmallWindow } = this.state;
    const unfiled = statusBarFields.filingStatus == FilingStatus.NOT_FILED;
    const lblStyle: React.CSSProperties = { fontWeight: 'bold' };
    return (
      <>
        <Spin spinning={loading} delay={300}>
          <Descriptions
            title={extraSmallWindow ? (
                <Space size="large">
                  {this.checkUnfiled(statusBarFields.filingStatus || '', statusBarFields.filingPeriodName || '')}
                  <Dr2StatusBarButtons
                    showAdjust={() => this.setState({ showAdjust: true })}
                    unfiled={unfiled}
                    filingPeriodName={statusBarFields.filingPeriodName || ''}
                    refreshStatusBar={(dr2Id) => this.getStatusBar(dr2Id)}
                  />
                </Space>)
              : (<Content>
                <Row style={{ backgroundColor: Colors.IOWA_BLUE}}>
                  <Space size="large" style={{ margin: '8px'}}>
                    <Dr2StatusBarButtons
                      showAdjust={() => this.setState({ showAdjust: true })}
                      unfiled={unfiled}
                      filingPeriodName={statusBarFields.filingPeriodName || ''}
                      refreshStatusBar={(dr2Id) => this.getStatusBar(dr2Id)}
                    />
                  </Space>
                </Row>
                  <Row>
                      <Space size="large" style={{ marginTop: '8px' }}>
                    {this.checkUnfiled(statusBarFields.filingStatus || '', statusBarFields.filingPeriodName || '')}
                      </Space>
                  </Row>
                </Content >
                )
            }
            column={{ xs: 1, sm: 2, md: 3, lg: 3, xl: 4 }}
            size="small"
          >
            <Item className="dr2-status-items" labelStyle={lblStyle} label="Beginning Date">{statusBarFields.beginningDate}</Item>
            <Item className="dr2-status-items" labelStyle={lblStyle} label="Ending Date">{statusBarFields.endingDate}</Item>
            <Item className="dr2-status-items" labelStyle={lblStyle} label="Filing Due Date">{statusBarFields.filingDueDate}</Item>
            <Item className="dr2-status-items" labelStyle={lblStyle} label="Adjusted Due Date">{statusBarFields.adjustedDueDate}</Item>
            <Item className="dr2-status-items" labelStyle={lblStyle} label="Audited Date">{statusBarFields.auditedDate}</Item>
            <Item className="dr2-status-items" labelStyle={lblStyle} label="Filed Date">{statusBarFields.filedDate}</Item>
            <Item className="dr2-status-items" labelStyle={lblStyle} label="Changed By">{statusBarFields.changedBy}</Item>
            <Item className="dr2-status-items" labelStyle={lblStyle} label="Last Changed">{statusBarFields.lastChanged}</Item>
          </Descriptions>
        </Spin>
        <Divider />
        { showAdjust && <AdjustBalance close={() => this.onBalanceAdjusted()} />}
      </>
    );
  }

  private getStatusBar(dr2Id: string) {
    if (dr2Id !== '') {
      Dr2ApiController.getStatusBar(dr2Id)
        .then(result => {
          if (result) {
            const dateFormat = DateUtil.getDateFormatString();
            const sb = {
              filingPeriodName: result.filingPeriodName,
              beginningDate: result.beginningDate ? moment.utc(result.beginningDate).format(dateFormat) : null,
              endingDate: result.endingDate ? moment.utc(result.endingDate).format(dateFormat) : null,
              filingDueDate: moment(result.filingDueDate).format(dateFormat + ' h:mm a'),
              adjustedDueDate: result.adjustedDueDate ? moment.utc(result.adjustedDueDate).format(dateFormat) : null,
              auditedDate: result.auditedDate ? moment.utc(result.auditedDate).format(dateFormat) : null,
              filingStatus: result.filingStatus,
              filedDate: result.filedDate ? moment.utc(result.filedDate).local().format(dateFormat + ' h:mm a') : null,
              lastChanged: result.lastChanged ? moment.utc(result.lastChanged).local().format(dateFormat + ' h:mm a') : null,
              changedBy: result.changedBy,
            } as Dr2StatusBarDTO;

            this.setState({ statusBarFields: sb, loading: false });
          }
        }).catch(() => {
          notification.error({
            message: 'Error while fetching status bar'
          });
        });
    }
    else {
      this.setState({
        statusBarFields: Dr2StatusBarDTO.create({
          filingPeriodName: 'Filing period not found'
        }),
        loading: false
      });
    }
  }

  private checkUnfiled = (status: string, title: string): any => {
    if (status === 'Not Filed') {
      const newTitle = title + ' - Unfiled Report';
      return <Typography.Text style={{ color: Colors.PRIMARY }}>{newTitle}</Typography.Text>;
    }

    return <Typography.Text>{title || <>&nbsp;</>}</Typography.Text>;
  }

  private onBalanceAdjusted = () => {
    this.setState({ showAdjust: false });
    if (this.props.onAdjustBalance) {
      this.props.onAdjustBalance();
    }
  }

  private handleWindowResize = () => {
    if (this.state.extraSmallWindow !== this.isExtraSmallWindow()) {
      this.setState({ extraSmallWindow: !this.state.extraSmallWindow });
    }
  }

  private isExtraSmallWindow = () => {
    return !window.matchMedia('(min-width: 768px)').matches;
  }
}

export default Dr2StatusBar;
