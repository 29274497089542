// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Constants.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
export default class FormInputLengths {
    public static readonly COMMITTEENAME: number = 60;
    public static readonly COMMITTEEOFFICENAME: number = 40;
    public static readonly COMMITTEEEMAIL: number = 100;
    public static readonly SECONDARYCOMMITTEENAME: number = 60;
    public static readonly SECONDARYCOMMITTEEEMAIL: number = 50;
    public static readonly BANKNAME: number = 60;
    public static readonly BANKACCOUNTNAME: number = 50;
    public static readonly BANKACCOUNTNUMBER: number = 40;
    public static readonly BANKACCOUNTEMAIL: number = 50;
    public static readonly PERSONFIRSTNAME: number = 20;
    public static readonly PERSONMIDDLEINITIAL: number = 1;
    public static readonly PERSONLASTNAME: number = 25;
    public static readonly PERSONEMAIL: number = 50;
    public static readonly ADDRESSLINE: number = 35;
    public static readonly CITY: number = 20;
}
