import { Layout } from 'antd';
import * as React from 'react';
import SchDDebtsApiService from '../../api/SchDDebtsApiService';
import SchDDebtsDTO from '../../models/SchDDebtsDTO';
import DataTableColumnUtil from '../shared/DataTable/DataTableColumnUtil';
import DataTable, { DataTableColumnProps } from '../shared/DataTable/DataTable';
import Dr2AmendedStatuses from '../../consts/Dr2AmendedStatuses';
import Dr2AmendedIndicators from '../../consts/Dr2AmendedIndicators';

const { Content } = Layout;

interface SchDDebtHistoryTableProps {
  debtId: string;
}

interface SchDDebtHistoryTableState {
}

class SchDDebtHistoryTable extends React.Component<SchDDebtHistoryTableProps, SchDDebtHistoryTableState> {
  constructor(props: SchDDebtHistoryTableProps) {
    super(props);

    this.state = {
    };
  }

  render() {
    const { debtId } = this.props;
    return (
      <Content className="content-pad">
        <DataTable
          columns={this.getTableColumns()}
          fetchData={{
            fetch: function (tableRequest) { return SchDDebtsApiService.getDebtHistory(tableRequest, debtId); },
            failureMessage: 'Failed to retrieve debt history'
          }}
          serverSide={true}
          styleOptions={{ compact: true, alternatingRowHighlight: true }}

          tableProps={{
            rowKey: 'debtIdAndSeq',
            sortDirections: ['ascend', 'descend'],
            locale: { emptyText: 'Debt history not available.' }
          }}
        />
      </Content>
    );
  }

  private getTableColumns = (): DataTableColumnProps<SchDDebtsDTO>[] => {
    const tableColumns: DataTableColumnProps<SchDDebtsDTO>[] = [
      DataTableColumnUtil.Date('Date', 'debtDt', 100),
      DataTableColumnUtil.Address('Owed To', 'owedToName',
        (c) => ({
          name: c.owedToName,
          line1: c.owedToAddressLine1,
          line2: c.owedToAddressLine2,
          city: c.owedToCity,
          state: c.owedToState,
          zip: c.owedToZip
        })
      ),
      DataTableColumnUtil.Currency('Amount', 'debtAmount'),
      DataTableColumnUtil.DropdownMulti('Status', 'status',
        [
          { text: Dr2AmendedStatuses.ORIGINAL, value: Dr2AmendedIndicators.ORIGINAL },
          { text: Dr2AmendedStatuses.AMENDED, value: Dr2AmendedIndicators.AMENDED },
          { text: Dr2AmendedStatuses.ADJUSTED, value: Dr2AmendedIndicators.ADJUSTED },
          { text: Dr2AmendedStatuses.DELETED, value: Dr2AmendedIndicators.DELETED }
        ])
    ];
    return tableColumns;
  }
}

export default SchDDebtHistoryTable;
