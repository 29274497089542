import { Descriptions } from 'antd';
import * as React from 'react';
import TransactionTypeIndicators from '../../consts/TransactionTypeIndicators';
import SearchableDatabaseDTO from '../../models/SearchableDatabaseDTO';

interface TransactionRefDescProps {
    referenceType: string;
    transactionInfo: SearchableDatabaseDTO;
}

interface TransactionRefDescState {
}

class TransactionRefDesc extends React.Component<TransactionRefDescProps, TransactionRefDescState> {
    constructor(props: TransactionRefDescProps) {
        super(props);
        this.state = {};
    }

    render() {
        const { referenceType, transactionInfo } = this.props;
        let primaryLabel, secondaryLabel = '';

        if (referenceType === TransactionTypeIndicators.CONTRIBUTION) {
            primaryLabel = 'Receiving Committee';
            secondaryLabel = 'Contributor';
        }
        else if (referenceType === TransactionTypeIndicators.INKINDCONTRIBUTION) {
            primaryLabel = 'Receiving Committee';
            secondaryLabel = 'Contributor';
        }
        else {
            primaryLabel = 'Expending Committee';
            secondaryLabel = 'Receiving';
        }

        return (
            <>
                <Descriptions bordered layout="vertical" column={4} size="small">
                    <Descriptions.Item label={primaryLabel} span={2}>
                        {transactionInfo.primaryName}<br />
                        {transactionInfo.primaryAddress1}, {transactionInfo.primaryAddress2}<br />
                        {transactionInfo.primaryCity}, {transactionInfo.primaryState}, {transactionInfo.primaryZip}
                    </Descriptions.Item>
                    <Descriptions.Item label={secondaryLabel} span={2}>
                        {transactionInfo.secondaryName}<br />
                        {transactionInfo.secondaryAddress1}, {transactionInfo.secondaryAddress2}<br />
                        {transactionInfo.secondaryCity}, {transactionInfo.secondaryState}, {transactionInfo.secondaryZip}
                    </Descriptions.Item>
                    <Descriptions.Item label="Date">
                        {transactionInfo.date}
                    </Descriptions.Item>
                    <Descriptions.Item label="Amount">
                        ${transactionInfo.amount}
                    </Descriptions.Item>
                    <Descriptions.Item label="Check Number">
                        {transactionInfo.checkNumber}
                    </Descriptions.Item>
                </Descriptions>
            </>
        );
    }
}

export default TransactionRefDesc;
