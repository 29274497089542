// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';

interface BaseScheduleInfoDTO { 
  groupType: string | null;
  committeeOrCompanyName: string | null;
  firstName: string | null;
  middleInitial: string | null;
  lastName: string | null;
  addressLine1: string | null;
  addressLine2: string | null;
  city: string | null;
  state: string | null;
  zip: string | null;
  phoneNumber: string | null;
  relationshipCode: string | null;
  trustee: string | null;
  trustor: string | null;
}
const BaseScheduleInfoDTO = {
  create: (initValues?: Partial<BaseScheduleInfoDTO> | Record<string, unknown> | null) => {
    return Object.assign(
    {
      groupType: null,
      committeeOrCompanyName: null,
      firstName: null,
      middleInitial: null,
      lastName: null,
      addressLine1: null,
      addressLine2: null,
      city: null,
      state: null,
      zip: null,
      phoneNumber: null,
      relationshipCode: null,
      trustee: null,
      trustor: null,
    },
    initValues);
  }
};

export default BaseScheduleInfoDTO;